
export class Group
{
  public id = 0;
  public date !: Date;
  public displayDate = '';
  public name = '';
  public businessKey = '';
  public groupCryptKey = ''; // TODO: implement throughout the app
  public groupKey = '';
  public sitUserKey = '';
  public signedInUserId = 0;
  constructor() {
  }
}
