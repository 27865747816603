'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { ModalModel } from '../../../models/common/modalModel.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { Pager } from '../../../models/pagination/pager.model';
import { GlyphiconMenuService } from '../../../services/GlyphiconService/glypgiconMenuService.service';
import { GlyphiconServiceService } from '../../../services/GlyphiconService/glyphiconServiceService.service';
import { AppServiceService } from '../../../services/appServiceService/appServiceService.service';
import { BreakPointObserverService } from '../../../services/appServiceService/breakPointObserverService.service';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { CheckConnectionService } from '../../../services/coreServiceService/checkConnectionService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { LogRegService } from '../../../services/coreServiceService/logRegService.service';
import { DbServiceService } from '../../../services/dbServiceService/dbServiceService.service';
import { DictionaryService } from '../../../services/dictionaryServiceService/dictionaryService.service';
import { DictionaryServiceService } from '../../../services/dictionaryServiceService/dictionaryServiceService.service';
import { PagerService } from '../../../services/pagerServiceService/pagerService.service';
import { PagerServiceService } from '../../../services/pagerServiceService/pagerServiceService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { HammerServiceStatic } from '../../../services/staticServices/commonStaticServices/hammerServiceStatic.service';
import { ModuleComponentLoaderServiceStatic } from '../../../services/staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Component({
  selector: 'app-nav-sidebar',
  templateUrl: './navSidebar.component.html',
  styleUrls: ['./navSidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // default //OnPush
})
export class NavSidebarComponent implements OnInit, AfterViewInit, OnDestroy {
  public bias ! : BackgroundImageAnimationService;
  public breakpointObserverService!: BreakPointObserverService;
  public checkConnectionService! : CheckConnectionService;
  public dictionaryService! : DictionaryService;
  public glyphiconMenuService! : GlyphiconMenuService;
  public pagerService! : PagerService;
  public redirectionService! : RedirectionService;
  public rendererService!: RendererService;
  public renderer!: Renderer2;
  // ------------------------------------------
  public buddyCount = 0;
  public current: any;
  public currentRoute = '';
  public defaultPics: string[] = [];
  public deviceInfo = null;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public friendCount = 0;
  public gestureDivId = '';
  public isMobilevar = false;
  public isOnLine = false;
  public isPhotoMgmt = false;
  public isSingletap = true;
  public isTabletvar = false;
  public isDesktopvar = false;
  public isAuthorized = false;
  public isEmployee = false;
  public isMgmt = false;
  public isLoggedIn = false;
  public isLoginTasksExecuted = false;
  public isMouseIn = false;
  public isPremium = false;
  public isProfilePics = false;
  public isReviewer = false;
  public isSuperUser = false;
  public isValued = false;
  @Input() loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public message = '';
  public messages: string[] = [];
  public modalModel: ModalModel = new ModalModel();
  public pager : Pager = new Pager();
  public pagerBreadcrum : PagerBreadcrum = new PagerBreadcrum();
  public pagerV: Pager = new Pager();
  public pages: string[] = [];
  public returnUrl = '';
  @Input() signedInUserId = 0;
  public tempElem: any;
  public title = 'Slakez';
  public width = 0;
  public height = 0;
  constructor(
    private route : ActivatedRoute,
    public router: Router,
    public cdr: ChangeDetectorRef,
    private appServiceService : AppServiceService,
    private commonServiceService: CommonServiceService,
    private coreServiceService : CoreServiceService,
    private dbServiceService : DbServiceService,
    private dictionaryServiceService : DictionaryServiceService,
    private glyphiconServiceService : GlyphiconServiceService,
    private logRegService : LogRegService,
    private pagerServiceService: PagerServiceService,
    private rendererServiceService: RendererServiceService,
    private window: Window,
  ) {
    this.loginSuccess = new LoginSuccess();
    this.isAuthorized = false;
    this.isEmployee = false;
    this.isMgmt = false;
    this.signedInUserId = 0;
    // if (this.appServiceService) {
    //  this.breakpointObserverService = this.appServiceService.getBreakPointObserverService();
    // }
    if (this.coreServiceService) {
      this.checkConnectionService = this.coreServiceService.getCheckConnectionService();
    }
    if (this.dictionaryServiceService) {
      this.dictionaryService = this.dictionaryServiceService.getDictionaryService();
    }
    if ( this.pagerServiceService )
    {
      this.pagerService = this.pagerServiceService.getPagerService();
    }
    this.glyphiconMenuService = this.glyphiconServiceService.getGlyphiconMenuService() as GlyphiconMenuService;
    this.redirectionService = this.commonServiceService.getRedirectionService();
    // -----------------------------------------------------------------
    if (this.rendererServiceService) {
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
      this.rendererService = this.rendererServiceService.getRendererService() as RendererService;
      if (this.rendererService) {
        this.renderer = this.rendererService.getRenderer();
      }
    }    
    // -----------------------------------------------------------------
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
      || this.loginSuccess.signedInUserId === 0)
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
      this.executeLoginSuccessTasks();
    }
    if ( this.glyphiconMenuService )
    {
      this.pagerV = this.glyphiconMenuService.setKvGlyphPager( 1 );
    }
    // -----------------------------------------------------------------

    this.isOnLine = this.checkConnectionService.isOnline();
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.isOnLine ) )
    {
      this.isOnLine = false;
    }
    this.returnUrl = window.location.pathname + window.location.search;
    // -----------------------------------------------------------------
  }

  ngOnInit () : void
  {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.executeLoginSuccessTasks();
    // debugger;
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    //  -------------------------------------------------------------------------
    // TODO: remove once the issue is resolved
    // NOTE: This is a temporary solution to an unsubscribe error
    //       We do not emit loginSuccess upon successful login from the LoginComponent
    //       We use this emitter to indicate that login is successful, and any subsequent
    //       computation that are necessary upon successful login is carried out here.
    // --------------------------------------------------------------------------
    EmitterSubjectService.clearMessageEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        // debugger;
        this.message = '';
        this.ngAfterViewInit();
      } );
    // --------------------------------------------------------------------------
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        // debugger;
        this.loginSuccess = result as LoginSuccess; // JSON.parse(JSON.stringify(result));
        // debugger;
        this.isLoggedIn = true;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.isLoginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();
      });
    // --------------------------------------------------------------------------
    //EmitterSubjectService.logoutEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //  {
    //    debugger;
    //    this.loginSuccess = new LoginSuccess().resetThat(this.loginSuccess);
    //    EmitterSubjectService.setLoginSuccess(this.loginSuccess);
    //    EmitterSubjectService.emitLogout(this.loginSuccess);
    //    this.logRegService.executeLogoutSuccessTasks();
    //    this.signedInUserId = 0;
    //    debugger;
    //    this.isLoggedIn = false;
    //    this.ngAfterViewInit();
    //  });
    // --------------------------------------------------------------------------
    EmitterSubjectService.localStorageLoginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        this.loginSuccess = result as LoginSuccess; // JSON.parse(JSON.stringify(result));

        // debugger;
        this.executeLoginSuccessTasks();
        this.isLoginTasksExecuted = false;
      });
    // --------------------------------------------------------------------------
    EmitterSubjectService.centerCoordinatesEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        // this.centerCoordinates = result;
        this.isLoginTasksExecuted = false;
      });
    // --------------------------------------------------------------------------
    EmitterSubjectService.isMobileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        this.isMobilevar = result as boolean;
        this.isDesktopvar = !this.isMobilevar;
        // this.breakpointObserverService.breakpointObserving();
        // const hw = this.breakpointObserverService.determineHeightWidth();

        // this.width = hw.height;
        // this.height = hw.weight;
        this.isLoginTasksExecuted = false;
      });
    // --------------------------------------------------------------------------
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) || this.loginSuccess.signedInUserId === 0) {
      // debugger;
      // EmitterSubjectService.emitCheckLocalStorageForLoginSuccess(true);
      this.isLoginTasksExecuted = false;
    }
    // --------------------------------------------------------------------------
    // Note: We are using upgradedEmitter to emit LoginSuccess because emitting
    //        loginSuccess causes some errors.
    // --------------------------------------------------------------------------
    EmitterSubjectService.upgradedEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        debugger;
        this.loginSuccess = result as LoginSuccess; // JSON.parse(JSON.stringify(result));
        debugger;
        this.isLoggedIn = true;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();
      });
    // --------------------------------------------------------------------------
  }
  // ---------------------------------------------------------------
  ngAfterViewInit () {
    this.cdr.detectChanges();
    // debugger;
    this.getCounts();

    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // this.executeLoginSuccessTasks();
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess.signedInUserId ) && this.loginSuccess.signedInUserId > 0 )
    {
        // debugger;
        this.isLoggedIn = true;
    }
    else
    {
      // debugger;
      this.isLoggedIn = false;
    }

  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    /*
     * prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *       (2) https:// www.concretepage.com/angular-2/angular-4-renderer2-example
     *       (3) https:// stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *       (4) https:// stackblitz.com/edit/timer-with-pdestroyed
     */
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  // ----------------------------------------------------- 
  //  Login-Register-Nav-Header-Buttons-Management-System:
  // -----------------------------------------------------
  emitLoginButtonRenderedMsg () {
    // EmitterSubjectService.emitMessage('Render-Login-Button');
  }
  // ---------------------------------------------------------------
  emitRegisterButtonRenderedMsg () {
    // EmitterSubjectService.emitMessage('Render-Register-Button');
  }
  // ---------------------------------------------------------------------------------
  public executeLoginSuccessTasks () : any
  {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // debugger;
    this.isAuthorized = this.loginSuccess.isAuthorized;
    this.isEmployee = this.loginSuccess.isEmployee;
    this.isLoggedIn = this.loginSuccess.signedInUserId > 0 ? true : false;
    this.isMgmt = this.loginSuccess.isMgmt;
    this.isOnLine = navigator.onLine;
    this.isPremium = this.loginSuccess.isPremium;
    this.isReviewer = this.loginSuccess.isReviewer;
    this.isSuperUser = this.loginSuccess.isSuperUser;
    this.isValued = this.loginSuccess.isValued;
    // debugger;
    this.signedInUserId = this.loginSuccess.signedInUserId;

    return true;
  }
  // --------------------------------------------------------------
  public executeLogoutSuccessTasks () {
    // debugger;
    this.loginSuccess = new LoginSuccess();
    // debugger;
    EmitterSubjectService.setLoginSuccess(this.loginSuccess);
    EmitterSubjectService.setIsLoggedOutByUser(true);
    this.isAuthorized = false;
    this.isEmployee = false;
    this.isLoggedIn = false;
    this.isMgmt = false;
    this.isPremium = false;
    this.isReviewer = false;
    this.isSuperUser = false;
    this.isValued = false;
    this.signedInUserId = 0;
    // debugger;
    
    this.mcLoader.componentName = 'logout';
    this.mcLoader.moduleName = 'loginm';
    this.mcLoader.router = this.router;
    // this.ngAfterViewInit();
    this.redirectionService.setRoute(this.router, this.mcLoader.componentName, 0).subscribe(data => {
      this.pagerBreadcrum = data;
    });
  }
  // --------------------------------------------------------------
  public getCounts () : void
  {
    // debugger;
    this.buddyCount = this.dictionaryService.buddyDictionary.size;
    this.friendCount = this.dictionaryService.myFriendDictionary.size;
  }
  // --------------------------------------------------------------
  setupForEditProfile (route : string) : void {
    if (route.toLowerCase().indexOf('memberedit') !== -1) {
      // debugger;
      this.loginSuccess.isEditProfile = true;
      this.loginSuccess.isProfileInfoComplete = false;
      this.loginSuccess.isProfileContentComplete = false;
      this.loginSuccess.isPreferenceComplete = false;
      EmitterSubjectService.emitIsEditProfile(true);
    }
	}
  // --------------------------------------------------------------
  public setRouteBreadCrum (route : string, id : number) : void {
    // debugger;
    this.setupForEditProfile(route);
    this.redirectionService.setRouteforSignedInUser(this.router, route, id).then(data => {
      this.pagerBreadcrum = data;
    });

  }
  // --------------------------------------------------------------
  public setRoute ( route : string, id : number ) : any
  {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.mcLoader = new ModuleComponentLoader();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(route))
    {
      this.mcLoader.componentName = route;
      this.mcLoader.id = id;
      this.mcLoader.router = this.router;
      this.mcLoader.returnUrl = window.location.pathname + window.location.search;

      switch (route.toLowerCase()) {        
        case 'login':
          this.emitLoginButtonRenderedMsg();
          break;
        case 'logout':
          // debugger;
          this.executeLogoutSuccessTasks();
          break;
        //case 'signup':
        //  this.emitRegisterButtonRenderedMsg();
        //  break;
        case "memberedit":
          this.setupForEditProfile(route);
          break;
        case 'myphotomgmt':
          EmitterSubjectService.emitIsPhotoMgmt(true);
          EmitterSubjectService.emitIsShowPhotoUpload(true);
          EmitterSubjectService.setIsMyProfile(false);
          // debugger;
          break;
        case 'myprofileviewmgmt':
          EmitterSubjectService.setIsPhotoMgmt(false);
          EmitterSubjectService.setIsShowPhotoUpload(false);
          return this.viewMyProfile();
          // debugger;
         // break;
        default:
          ;// do nothing
          break;
      }
      // this.mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(this.mcLoader);
      // debugger;
      // this.router.navigateByUrl(this.mcLoader.url);
      // debugger;
      this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
          // debugger;
          this.pagerBreadcrum = data;
          return true;
      });

      EmitterSubjectService.emitCloseSidebar(true);
      EmitterSubjectService.emitIsSidebarOpen(false);

       
    }
    else {
      this.redirectionService.routeToHomePage(this.router).subscribe(data => {
        this.pagerBreadcrum = data;
        // debugger;
        return true;
      });
      EmitterSubjectService.emitCloseSidebar(true);
      EmitterSubjectService.emitIsSidebarOpen(false);
		}
  }

  // ---------------------------------------------------------------------------------
  public viewMyProfile () : any {
    this.mcLoader = new ModuleComponentLoader();
    this.mcLoader.router = this.router;
    this.mcLoader.moduleName = this.mcLoader.moduleName = 'mym';
    this.mcLoader.componentName = this.mcLoader.componentName = 'myProfileViewMgmt';
    // debugger;
    //this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
    //  this.mcLoader.id = this.loginSuccess.signedInUserId;
    //}
    this.mcLoader.returnUrl = this.redirectionService.getUrlAddress();
    // debugger;
    EmitterSubjectService.setMCLoader(this.mcLoader);
    // -----------------------------------------------
    // NOTE:  we do not want to run the executeProfileImagesTasks() in appComponent/avatarComponent
    //        until the page switches to the myProfileViewMgmt page!!
    //        Instead set the necessary variables on the EmitterSubjectService.
    // ----------------------------------------------------------------------------
    EmitterSubjectService.emitIsMyProfile(true);
    // -----------------------------------------------
    // ----------------------------------------------------------------------------
    // this.mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(this.mcLoader);
    this.redirectionService.setRoute(this.mcLoader.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
      this.pagerBreadcrum = data;
    });
    
    // ----------
    //debugger;
   
    //// debugger;
    //this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //this.redirectionService.setRoute(this.router, 'myProfileViewMgmt', this.loginSuccess.signedInUserId).subscribe(data => {
    //  this.pagerBreadcrum = data;

    //  debugger;
    //  EmitterSubjectService.emitIsMyProfile(true);
    //  return true;
    //});
    return true;
  }
  // --------------------------------------------------------------
  //  Begin of hammer/gesture recognition methods:
  // --------------------------------------------------------------
  onSwipe (event : Event) : void {
    // HammerServiceStatic.hammeredElement('wrapper', event);
    // HammerServiceStatic.hammeredElement('bodyId', event);
    // debugger;
    HammerServiceStatic.hammeredElement('navbarComponentId', event, this.router);
  }
  onSwipeLeft (event : Event) : void {
    // HammerServiceStatic.hammeredElement('wrapper', event);
    // HammerServiceStatic.hammeredElement('bodyId', event);
    HammerServiceStatic.hammeredElement('navbarComponentId', event, this.router);
  }
  onSwipeRight (event : Event) : void {
    // HammerServiceStatic.hammeredElement('wrapper', event);
    // HammerServiceStatic.hammeredElement('bodyId', event);
    HammerServiceStatic.hammeredElement('navbarComponentId', event, this.router);
  }
  onSwipeUp (event : Event) : void {
    // HammerServiceStatic.hammeredElement('wrapper', event);
    // HammerServiceStatic.hammeredElement('bodyId', event);
    HammerServiceStatic.hammeredElement('navbarComponentId', event, this.router);
  }
  onSwipeDown (event : Event) : void {
    // HammerServiceStatic.hammeredElement('wrapper', event);
    // HammerServiceStatic.hammeredElement('bodyId', event); page-content-wrapper
    HammerServiceStatic.hammeredElement('navbarComponentId', event, this.router);
  }
  // --------------------------------------------------------------
  //  (pan)="onPan($event)"
  //  (panstart) = "onPanStart($event)"
  //  (panmove) = "onPanMove($event)"
  //  (panend) = "onPanEnd($event)"
  //  (pancancel) = "onPanCancel($event)"
  //  (panleft) = "onPanLeft($event)"
  //  (panright) = "onPanRight($event)"
  //  (panup) = "onPanUp($event)"
  //  (pandown) = "onPanDown($event)"
  //
  //  (tap) = "onTap($event)"
  //  (press) = "onPress($event)"
  // --------------------------------------------------------------
  // onPan (event : Event) : void {
 // HammerServiceStatic.hammeredElement('wrapper', event);
 // HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPanStart (event : Event) : void {
// HammerServiceStatic.hammeredElement('wrapper', event);
// HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPanEnd (event : Event) : void {
 // HammerServiceStatic.hammeredElement('wrapper', event);
 // HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPanMove (event : Event) : void {
 // HammerServiceStatic.hammeredElement('wrapper', event);
// HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPanCancel (event : Event) : void {
 // HammerServiceStatic.hammeredElement('wrapper', event);
 // HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPanLeft (event : Event) : void {
// HammerServiceStatic.hammeredElement('wrapper', event);
// HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPanRight (event : Event) : void {
 // HammerServiceStatic.hammeredElement('wrapper', event);
 // HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPanUp (event : Event) : void {
 // HammerServiceStatic.hammeredElement('wrapper', event);
 // HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPanDown (event : Event) : void {
// HammerServiceStatic.hammeredElement('wrapper', event);
 // HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onTap (event : Event) : void {
 // HammerServiceStatic.hammeredElement('wrapper', event);
// HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // onPress (event : Event) : void {
// HammerServiceStatic.hammeredElement('wrapper', event);
// HammerServiceStatic.hammeredElement('bodyId', event);
  //  HammerServiceStatic.hammeredElement('navbarComponentId', event);
  // }
  // --------------------------------------------------------------
  //  Begin of hammer/gesture recognition methods:
  // --------------------------------------------------------------
  // --------------------------------------------------------------
}

// ====================================================================================
