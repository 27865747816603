'use-strict'
import { animate, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FileUploader } from 'ng2-file-upload';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { ChatFileUpload } from '../../../models/chat/chatFileUpload.model';
import { Bool } from '../../../models/common/bool.model';
import { Content } from '../../../models/common/content.model';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { ProfilePics } from '../../../models/profile/profilePics.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { MemberServiceService } from '../../../services/memberServiceService/memberServiceService.service';
import { ProfilePicsService } from '../../../services/profileServiceService/profilePicsService.service';
import { ProfileServiceService } from '../../../services/profileServiceService/profileServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { UploadServiceStatic } from '../../../services/staticServices/uploadServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';
//import { FileSelectDirective, FileDropDirective, FileUploader } from 'ng2-file-upload/ng2-file-upload';

// ref: https://www.bezkoder.com/angular-11-file-upload/

@Component( {
  selector: 'app-upload-files',
  templateUrl: './uploadFilesComponent.component.html',
  styleUrls: [ './uploadFilesComponent.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,

  animations: [ Animations, slideInOutAnimation, 
  
    trigger( 'growUp', [
      transition( ':enter', [
        style( { transform: 'translateY(275%) scale(0.1)' } ),
        // style({ transform: 'scale(0.1)'}),
        animate( '700ms ease-in', style( { transform: 'translateY(0%) scale(1)' } ) ),
        // animate('500ms ease-in', style({ transform: 'scale(1)' }))
      ] ),
      transition( ':leave', [
        animate( '700ms ease-in', style( { transform: 'translateX(275%) scale(0.1)' } ) ),
        // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
      ] ),
    ] ),
    trigger( 'growXshrink', [
      transition( ':enter', [ style( { transform: 'translateX(275%) scale(0.1)' } ),
        animate( '500ms ease-in-out', style( { transform: 'translateX(0%)' } ) ),
        animate( '500ms ease-in', style( { transform: 'scale(1)' } ) ) ] ),
      transition( ':leave', [ animate( '500ms ease-in-out', style( { transform: 'translateX(275%)' } ) ),
        animate( '500ms ease-in', style( { transform: 'scale(0.1)' } ) ) ] ),
    ] ),
  ],
  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
} )

export class UploadFilesComponent implements OnInit, OnDestroy, AfterViewInit
{
  public profilePicsService! : ProfilePicsService;
  // class FileSelectDirective
  @Directive({ selector: '[ng2FileSelect]' })
  // class FileDropDirective
  @Directive({ selector: '[ng2FileDrop]' })
  public url : string = '/api/FileUpload/ProfilePhotoUpload';
  public selectedFiles?: File[] = [];
  public currentFile?: File;
  public progress = 0;
  public message = '';

  public fileInfos?: Observable<any>;

  // -----------------------------------
  public httpService!: HttpService;
  // ------------------------------------------
  public baseUrl = '/api/FileUpload/ProfilePhotoUpload';
  public bgiIndex = -1;
  public bgiTimer: any;
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public content: Content = new Content();
  public deviceInfo = null;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public formData = new FormData();
  public hasBaseDropZoneOver : boolean = false;
  public hasAnotherDropZoneOver : boolean = false;
  public isCameraVisible = true;
  public isMobilevar = false;
  public isOnLine = false;
  public isFormValid = false;
  public isMyProfile = false;
  public isNewPhotoUploaded : boolean = false;
  public isPhotoMgmt = false;
  public isSubmitted = false;
  public isImageUploadFormVisible = false;
  public isShowPhotoUploadUi : Bool = new Bool();
  public isViewMember = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  @Input() marginTop = '-1rem';
  public messages : string[] = [];
  public options : object = {};
  public profilePics: ProfilePics = new ProfilePics();

  public readFileObservable$: Subject<any> = new Subject<any>();
  public returnUrl = '';
  public signedInUserId = 0;
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public tempStringFiles : string[] = [];
  public imageArrBuffer: Uint8Array = new Uint8Array();
  public tempElem: any;
  public tempFiles: File[] = [];
  public tempFileBlobs: Blob[] = [];
  public tempFilesString : string[] = [];
  public toggleCameraGlyphCounter = 0;

   public uploader : FileUploader = new FileUploader({ url: this.baseUrl, removeAfterUpload: false, autoUpload: true });
  
  // ------------------------------------------
  public description = '';
  public chatFileUpload: ChatFileUpload = new ChatFileUpload();
  // public files: File[] = [];
  public isCurrentFile = false;
  @ViewChild( 'imageUploadForm' ) imageUploadForm: any;

  constructor (
    public cdr: ChangeDetectorRef,
    private coreServiceService : CoreServiceService,
    public memberServiceService : MemberServiceService,
    public profileServiceService: ProfileServiceService,
    private router: Router)
  {
    if ( this.coreServiceService )
    {
      this.httpService = this.coreServiceService.getHttpService();
      this.profilePicsService = this.profileServiceService.getProfilePicsService();
    }
  }
  // -----------------------------------------------
  ngOnInit (): void
  {
   
    // this.fileInfos = this.uploadFilesService.getFiles();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
      this.signedInUserId = this.loginSuccess.signedInUserId;
    }
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    // EmitterSubjectService.emitMainTopCss( 3 ); // 3%

    this.isViewMember = EmitterSubjectService.getIsViewMember();

    // -------------------------------------------------------------------------
    //EmitterSubjectService.isCameraVisibleEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //  {
    //    // debugger;
    //    // Note:  in order to have ImageUpload visible if isCameraVisible===false && vise versa,
    //    //
    //    this.isImageUploadFormVisible = result;
    //    this.ngAfterViewInit();
    //  });

    // -------------------------------------------------------------------------
     EmitterSubjectService.toggleCameraGlyphEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;

        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          this.signedInUserId = this.loginSuccess.signedInUserId;
        }
        this.isCameraVisible = !this.isCameraVisible;
        this.isMyProfile = EmitterSubjectService.getIsMyProfile();
        this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
        this.ngAfterViewInit();
      });
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit (): void
  {
    // this.pagerV = this.getPagerVForCurrentPage();
    this.cdr.detectChanges();
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy (): void
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.toggleCameraGlyphCounter = 0;
  }

  // ---------------------------------------------------------------
   cancel (): void
   {
    this.chatFileUpload.fileMap = new Map();
    this.cancelFileUpload();
    // debugger;
     this.isImageUploadFormVisible= true;
    // Note:   isCameraVisible===true :camera-gluph-menu is visible && UploadFilesComponent is hidden.
    // ------------------------------------------------------------------------------------------------
     
    this.toggleCameraGlyphCounter++;
      EmitterSubjectService.emitToggleCameraGlyph(this.toggleCameraGlyphCounter );
      EmitterSubjectService.emitIsCameraClosedBool( true );
   }
  // ---------------------------------------------------------------
   cancelFileUpload (): void
   {
    // this.content = new Content();  // TPDO: restore
    this.selectedFiles = undefined;
   }
  // ---------------------------------------------------------------
  // this.uploader = new FileUploader({ url: this.baseUrl, removeAfterUpload: false, autoUpload: true });
  
  public fileOverBase (e : any) : void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother (e : any) : void {
    this.hasAnotherDropZoneOver = e;
  }
  // ---------------------------------------------------------------
  public getDescription ($event:any) : string {
    // debugger;
    if ($event && $event.target && $event.target.value && $event.target.value.length > 0) {
      // debugger;
      this.content.contents = $event.target.value as string;
    }
    // debugger;
    return this.content.contents;
  }
  // ---------------------------------------------------------------
  public uploadFiles ($event:any) : any {
    // debugger;
    let formData = new FormData();
    let files : any[] = [];
    if ($event && $event.target && $event.target.files && $event.target.files.length > 0) {
      // debugger;
      files = $event.target.files as File[]
      for (var i = 0; i < files.length; i++) {
        formData.append(files[ i ].name, files[ i ]);
        this.content.chatFileUpload.fileMap.set(files[ i ].name, files[ i ]);
      }

      this.content.chatFileUpload.fileArr = files;
      this.content.chatFileUpload.formData = formData;
      this.content.chatFileUpload.picArr.push(UploadServiceStatic.readThisFile(files[i]));
      this.isCurrentFile = true;


      // this.tempFilesString = StringServiceStatic.createArrayFromMap(this.content.chatFileUpload.fileMap);
      // debugger;     
    }
    // this.upload(this.content);
    return files;
  }
  // -----------------------------------------------
  public selectFile ( $event: any ): void
   {
    debugger;
    this.selectedFiles = $event.target.files;
  }
  
  // -----------------------------------------------
  public sendUploadedFile (content : Content): Observable<any>
  {
    // debugger;
    return new Observable<any>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(content)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(content.chatFileUpload)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(content.chatFileUpload.fileArr)
        && content.chatFileUpload.fileArr.length > 0 ) {
    
        this.selectedFiles = content.chatFileUpload.fileArr;
        var kvPhoto : any;
        this.progress = 0;
        // debugger;
        if (this.imageUploadForm.valid && this.selectedFiles) {
          // debugger;
          const file : File | null = this.selectedFiles[0];

          if (file) {
            this.currentFile = file;
            // debugger;
            UploadServiceStatic.sendWithGeoLocation(this.content, this.httpService).subscribe(data => {
              // debugger;
              subscriber.next(data);              
            });
            // debugger;
            // OR
            // Note: To send file using formaData with progress:
            // -------------------------------------------------
            // this.uploadFilesService.upload( this.currentFile ).subscribe(
            //  ( event : any ) =>
            //  {
            //    if ( event.type === HttpEventType.UploadProgress )
            //    {
            //      this.progress = Math.round( 100 * event.loaded / event.total );
            //    } else if ( event instanceof HttpResponse )
            //    {
            //      this.message = event.body.message;
            //      this.fileInfos = this.uploadFilesService.getFiles();
            //    }
            //  },
            //  ( err : any ) =>
            //  {
            //    console.log( err );
            //    this.progress = 0;

            //    if ( err.error && err.error.message )
            //    {
            //      this.message = err.error.message;
            //    } else
            //    {
            //      this.message = 'Could not upload the file!';
            //    }

            //    this.currentFile = undefined;
            //  } );
          }          
        }      
      }
    })
  }
  // -----------------------------------------------
  public upload (content : Content) : any {
    // start spinner:
    // --------------
    this.spinnerModel = new SpinnerModel();
    this.spinnerModel.message = 'Getting your profile pics...';
    EmitterSubjectService.emitShowSpinner(this.spinnerModel);
    // debugger;
    this.sendUploadedFile(content).subscribe(data => {
      this.isNewPhotoUploaded = true;
      EmitterSubjectService.emitIsNewPhotoUploaded(this.isNewPhotoUploaded);
      // debugger;
   
      // stop the spinner:
      // -----------------
      EmitterSubjectService.emitHideSpinner("");
      // debugger;
      EmitterSubjectService.emitUploadedKvPhoto(data);
    })
  }
  // ---------------------------------------------------------------
}
