export class AppFeature
{
  public description = ''; /*required*/
  public featureGroup = ''; /*optional*/
  public featureName = ''; /*required; also used as key*/
  public isPrimary = false; /*required*/
  public isValued = false; /*required*/
  public maxPrimaryCount = ''; /*required*/
  public maxValuedCount = ''; /*required*/
  public message : undefined; /*optional*/
  public note : undefined; /*optional*/
  constructor() {
    // ;
  }
}
