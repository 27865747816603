import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { PayPalItem_Total } from './payPalItemTotal.model';

export class PayPalBreakdown {
  public item_total = new PayPalItem_Total();
  // ----------------------------------------------------------------------------------
  constructor() {
  }
  // ----------------------------------------------------------------------------------
  public copy ( from : PayPalBreakdown ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.item_total.copy( from.item_total);
    }
  }

  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n' +
      ',\n item_total: ' +
      this.item_total.toString() +
      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
