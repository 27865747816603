//ref: https://stackoverflow.com/questions/50798383/button-directive-to-bind-disable-attribute-via-input-in-angular
'use-strict'
import { Directive, ElementRef, Input, OnChanges, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appDisableElem]',
})
export class DisableElemDirective implements OnInit, OnChanges {
  @Input() isDisable! : boolean;

  constructor (private renderer : Renderer2, private el : ElementRef) { }

  ngOnInit () {
    // debugger;
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', this.isDisable ? 'disabled' : '');
  }
  ngOnChanges () {
    this.renderer.setAttribute(this.el.nativeElement, 'disabled', this.isDisable ? 'disabled' : '');
  }
}
