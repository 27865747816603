<div id="accordionGroupComponentId" class="accordionPanel shadowGray">
  <div class="title" (click)="toggle.next(true);toggleFaderAnim()">
    <b style="float:left;">
      {{title}}
    </b>
    <b style="float:right;">
      <!--<span *ngIf="!opened" class="glyphicon glyphicon-chevron-up"></span>
      <span *ngIf="opened" class="glyphicon glyphicon-chevron-down"></span>-->
      <span [ngClass]="{'glyphicon bigger':true, 'glyphicon-chevron-up':opened, 'glyphicon-chevron-down':!opened}" style="font-size:1rem;float:right;margin:10px 20px 10px 10px;"></span>
    </b>
  </div>
  <div class="accrdBody" [ngClass]="{'hidden': !opened}">
    <ng-content></ng-content>
  </div>
</div>
<!--

<accordion style="margin: 0.25rem 0rem 0.5rem 0rem;width:100%;">
  <accordion-group class="col-sm-12" [title]="accrdTitle" style="width:103%;margin:0.05rem 0 0 -1.2rem;">
    <div [@blindsDown]></div>
  </accordion-group>
</accordion>
-->
