'use strict'
import { Injectable } from '@angular/core';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Communication } from '../../models/communication/communication.model';
import { UpgradedTile } from '../../models/profile/upgradedTile.model';
import { CoreServiceService } from '../coreServiceService/coreServiceService.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Injectable({
  providedIn: 'any',
})
export class UpgradedMemberService {
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public sitUser: SitUser = new SitUser(); // for sending data to the server
  public message = '';
  public timer: any;
  public timerArray: any[] = [];
  constructor ( private coreServiceService: CoreServiceService,
    // private triageAllDataForIndexedDbAndDictionaryService: TriageAllDataForIndexedDbAndDictionaryService,
    public httpService: HttpService,
  )
  {
    if ( this.coreServiceService ) this.httpService = this.coreServiceService.getHttpService();
  }
  // --------------------------------------------------------------
  createUpgradedTileShellArr ( sitIdArr: number[] ): UpgradedTile[]
  {
    const uTiles: UpgradedTile[] = [];

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sitIdArr ) && sitIdArr.length > 0 )
    {
      let uTile : any;

      sitIdArr.map((e : any ) =>
      {
        uTile = this.createUpgradedTileShell( e ) as UpgradedTile;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( uTile ) )
        {
          uTiles.push( uTile );
        }
      } );
    }

    return uTiles;
  }
  // ---------------------------------------------------------------
  createUpgradedTileShell ( sitId: number ): UpgradedTile
  {
    if ( sitId > 0 )
    {
      const pTile = new UpgradedTile();

      pTile.sitUserId = sitId;
      pTile.signedInUserId = this.loginSuccess.signedInUserId;
      return pTile;
    }
    else
    {
      return new UpgradedTile();
    }
  }
  // ---------------------------------------------------------------
  salt (model : any): BoxNonceEntity {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      // debugger;
      return (this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(model)));
    }
    return new BoxNonceEntity();
  }
  // ---------------------------------------------------------------
  // fetchSitUser(sitUser: SitUser): Promise<SitUser> {
  //  // debugger;
  //  return new Promise( ( resolve, reject ) =>
  //  {
  //    if ( sitUser.sitUserId > 0 )
  //    {
  //      // debugger;
  //      this.sitUser = sitUser;
  //      this.boxNonceEntity = this.salt( this.sitUser );
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.box )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.nonce ) )
  //      {
  //        // debugger;
  //        this.httpService.post( '/api/Member/GetSitUser',
  //          {
  //            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
  //            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
  //          }, 'json' ).subscribe( ( result ) =>
  //          {
  //            let tSitUser = this.processSitUserResult( sitUser, result );
  //            resolve( tSitUser );
  //          },
  //            ( error ) =>
  //            {
  //              this.message = 'Error occured in GetSitUser(sitUserId:\'' + sitUser.sitUserId + '\');\n Error message:  ' + error.message;
  //              console.log( this.message );
  //              reject( new Error( this.message ) );
  //            },
  //          );
  //      }
  //    }
  //  } );
  // }
  // ---------------------------------------------------------------
  // processSitUserResult(sitUser: any, result: any): any {
  //  if (result) {
  //    const bn = result as BoxNonceEntity;
  //    // debugger;

  //    if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
  //      const tSitUser = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as SitUser;
  //      // debugger;

  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tSitUser) && tSitUser.sitUserId > 0) {
  //        // debugger;
  //        this.sitUser = tSitUser;
  //        EmitterSubjectService.setSitUserModel(this.sitUser); // DO NOT EMIT sitUser

  //        // debugger;
  //        // EmitterSubjectService.emitSaveSitUserToIndexedDbAndDictionary( sitUser );
  //        this.boxNonceEntity = new BoxNonceEntity();
  //        this.boxNonceEntity.box = result.box;
  //        this.boxNonceEntity.nonce = result.nonce;
  //        this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
  //        this.boxNonceEntity.id = this.sitUser.sitUserId;
  //        this.boxNonceEntity.key = this.boxNonceEntity.id.toString();
  //        this.boxNonceEntity.entityName = 'SitUser';
  //        this.boxNonceEntity.sitUserId = this.sitUser.sitUserId;
  //        // this.triageAllDataForIndexedDbAndDictionaryService.saveSitUserDictionaryToIndexedDb( this.boxNonceEntity );

  //        return this.sitUser;
  //      }
  //    }
  //  } else {
  //    this.message = 'Error occured in GetSitUser(sitUserId:\'' + sitUser.sitUserId + '\');\n result: ' + result;
  //    console.log(this.message);
  //  }
  // }
  // ---------------------------------------------------------------
  getBatchOfUpgradedMemberIds ( beginIndex : number ) : Promise<any>
  {
    // debugger;
    let tpTiles: UpgradedTile[] = [];
    return new Promise<any>( ( resolve, reject ) =>
    {
      if ( beginIndex > -1 )
      {
        const bnComm: Communication = new Communication();

        bnComm.beginIndex = beginIndex;
        bnComm.sitUserId = 0;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
        this.boxNonceEntity = this.salt( bnComm );

        // debugger;
        if ( this.boxNonceEntity )
        {
          this.httpService.postObservable( '/Upgrade/GetBatchOfUpgradedMemberIds', JSON.stringify( {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
          } ),
            'json2text',
          ).subscribe( ( result ) =>
          {
            // debugger;
            tpTiles = this.processBatchOfUpgradedMemberIdsResult( result );
            // debugger;
            // this.boxNonceEntity = new BoxNonceEntity();
            // this.boxNonceEntity.box = result.box;
            // this.boxNonceEntity.nonce = result.nonce;
            // this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
            // this.boxNonceEntity.id = this.loginSuccess.signedInUserId;
            // this.boxNonceEntity.key = this.boxNonceEntity.id.toString();
            // this.boxNonceEntity.entityName = 'ProfileTileId';
            // this.boxNonceEntity.sitUserId = this.loginSuccess.signedInUserId;
            // this.triageAllDataForIndexedDbAndDictionaryService.saveProfileTileIdDictionaryToIndexedDb( this.boxNonceEntity );

            resolve( tpTiles );
            // debugger;
          }, ( error ) =>
          {
            // debugger;
            this.message = 'Error occured in GetBatchOfUserIds();\n Error-msg : ' + error.message;
            console.log( this.message );
            EmitterSubjectService.emitMyErrorLog( this.message );
            // debugger;
          } );
        }
      }
      else return this.nullPromise();
    } );
  }
  // -----------------------------------------------------------
  nullPromise (): any
  {
    this.timer = setTimeout( () =>
    {
      // debugger;
    }, 500);
    clearTimeout(this.timer);

    if ( this.timer )
    {
      this.timerArray.push( this.timer );
    }
  }
  // ---------------------------------------------------------------
  processBatchOfUpgradedMemberIdsResult ( result: any ): any
  {
    let tBatchOfIds: number[] = [];
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
    {
      const bn = result;
      tBatchOfIds = JSON.parse( SlakezSaltServiceStatic.boxUnsalt( result as BoxNonceEntity ) ) as number[];
      // debugger;
    }
    return tBatchOfIds;
  }

  // --------------------------------------------------------------
  getUpgradedTile ( sitUserId: number ): Promise<any>
  {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    let tUpgradedTile: any;
    // debugger;

    if ( sitUserId > 0 && this.loginSuccess.signedInUserId > 0 )
    {
      return new Promise( ( resolve, reject ) =>
      {
        // this.isOnLine &&
        const bnComm: Communication = new Communication();

        bnComm.sitUserId = sitUserId;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel( bnComm );
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity ) )
        {
          // debugger;
          this.httpService.postObservable( '/Upgrade/GetUpgradedTile',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
            },
            'json2text',
          ).subscribe( ( result ) =>
          {
            // debugger;
            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
            {
              this.boxNonceEntity = result;
              let tempTile : any;
              /*
                * do not directly assing to profileTile!! capture it in a let first
                */
              const unboxedTile = SlakezSaltServiceStatic.boxUnsalt( result as BoxNonceEntity );
              debugger;
              if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( unboxedTile ) )
              {
                tempTile = unboxedTile as UpgradedTile;
              }
              debugger;
              // if key 'sitUserId' exists, the unslating produced data
              if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tempTile ) )
              {
                tempTile = JSON.parse( tempTile ) as UpgradedTile; // convert string to model
              }
              debugger;
              // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tempTile ) && tempTile.sitUserId > 0 )
              // {
              //  /*
              //  * -------------------------------------------------------------------------
              //  * since the received data has been successfull unsalted into model, it is safe to save it to indexedDb
              //  * -------------------------------------------------------------------------
              //  */

                // debugger;
                // this.profileTile = this.executeBeforeViewInit( tempTile, 'profileTile-' );

                // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profileTile ) )
                // {
                //  this.boxNonceEntity = new BoxNonceEntity();
                //  this.boxNonceEntity.box = result.box;
                //  this.boxNonceEntity.nonce = result.nonce;
                //  this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
                //  this.boxNonceEntity.id = this.profileTile.sitUserId;
                //  this.boxNonceEntity.key = this.boxNonceEntity.id.toString();
                //  this.boxNonceEntity.entityName = 'ProfileTile';
                //  this.boxNonceEntity.sitUserId = this.profileTile.sitUserId;
                //  this.triageAllDataForIndexedDbAndDictionaryService.saveProfileTileDictionaryToIndexedDb( this.boxNonceEntity );

                //  // debugger;
                //  let tProfileTile = new ProfileTile();
                //  tProfileTile = CopyServiceStatic.copyProfileTileIfExists( tProfileTile, this.profileTile );
                  // debugger;
                  // update the Dictionary:
                  // ----------------------
                 // DictionaryServiceStatic.profileTileDictionary.setValue( tProfileTile.sitUserId, tProfileTile );
                  // debugger;

              resolve( tempTile );
                  // debugger;
                // }
                // else resolve( this.upgradedTile );
              // }
              // else resolve( tempTile );
            }
            else resolve( tUpgradedTile );
          } );
        }
        else resolve( tUpgradedTile );
      } );
    }
    else return this.nullPromise();
  }
  // ---------------------------------------------------------------
}
