'use-strict'
import { Directive, HostBinding, HostListener } from '@angular/core';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';

@Directive({
  selector: '[app-sidebar]',
  exportAs: 'appSidebar',
})
export class AppSideBarDirective {
  private count = 0;
  @HostBinding('class.is-open') click = false;
  /*@HostBinding('class.is-open') swipe = false;*/
  private mc : any;
  constructor () {
    //  -------------------------------------------------------------------------
    EmitterSubjectService.gestureEmitter.subscribe((result) => {
      // debugger;
      this.click = !this.click;

    });
    EmitterSubjectService.closeSidebarEmitter.subscribe((result) => {
      // debugger;
      if (this.click) {
        this.click = !this.click;
      }
      else {
        ;// do nothing; 
      }
    });
  }
  @HostListener('click') close () {
    if (this.click) {
      this.click = !this.click;
    }
    else {
      ;// do nothing; 
    }
  }

  @HostListener('click') onClic () {
    // debugger;
    this.click = !this.click;
    /*this.swipe = !this.swipe;*/
    /*
     * this.count++;
     * if (this.count % 2 == 0) {
     * this.click = !this.click;
     * this.count = 0;
     * }
     */
  }
}
