
import { Injectable, Renderer2 } from '@angular/core';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Content } from '../../models/common/content.model';
import { Photo } from '../../models/common/photo.model';
import { KV } from '../../models/keyValue/kv.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { KvAnyArr } from '../../models/keyValue/kvAnyArr.model';
import { KVKV } from '../../models/keyValue/kvKv.model';
import { KvKvAnyArr } from '../../models/keyValue/kvKvAnyArr.model';
import { Preference } from '../../models/profile/preference.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';

@Injectable({ providedIn: 'any'})
export class KvArrayService {
  public jsTypes = ['Boolean', 'BigInt', 'Function', 'NaN', 'Null', 'Number', 'Object', 'object', 'String', 'Symbol', 'Undefined'];
  public profilePics: string[] = [];
  public sitUserKey: any;
  public index = -1;
  public renderer!: Renderer2;
  public constructor(public rendererService: RendererService) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.rendererService)) {
      this.renderer = this.rendererService.getRenderer();
    }
  }

  // ---------------------------------------------------------------
  arrayToCsvString(inArr: any[]): any {
    if (inArr.length > 0) {
      let csvStr = '';

      for (let i = 0; i < inArr.length - 1; i++) {
        csvStr += csvStr + inArr[i].toString() + ', ';
      }
      csvStr += csvStr + inArr[inArr.length - 1].toString();
      return csvStr;
    }
    return '';
  }

  // ---------------------------------------------------------------
  getArrayFromCommaSeparatedData (data : any): any {
    let tArray = [];

    if (data) {
      tArray = data.split(',');
      if (tArray.length > 0) {
        // truncate the last empty index due to the last ',' in the data-string
        tArray.splice(tArray.length - 1, 1);
      }
    }
    return tArray;
  }
  /*
   * ------------------------------------------------------------------
   * Tested, works!
   */
  createArrayFromMap(inmap: Map<number, any>): any[] {
    const arr : any = [];

    if (inmap && inmap.size > 0) {
      inmap.forEach(function callback(value, key, map) {
        arr.push(value);
      });
    }
    return arr;
  }
  // --------------------------------------------------------------
  createMapFromArray(arr: any[]): Map<number, any> {
    const map = new Map<number, any>();

    if (arr && arr.length > 0) {
      arr.forEach(function callback(value, key, mp) {
        map.set(key, value);
      });
    }
    return map;
  }
  // --------------------------------------------------------------
  getIndexOfItemInArr(arr: any[], item: any): number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item)) {
      let isFound = false;

      for (let i = 0; i < arr.length; i++) {
        if (arr[i].toLowerCase().indexOf(item.toLowerCase()) !== -1) {
          isFound = true;
          if (isFound) {
            return i;
          }
        }
      }
    }
    return -1;
  }
  // -------------------------------------------------------------
  profilePicsToKvArr(profilePics: ProfilePics): any {
    // Note: To distinguosh Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
    const kvAny: KvAny = new KvAny();
    const images : any[] = [];

    if (profilePics && profilePics.profileImageArr && profilePics.profileImageArr.length > 0) {
      // debugger;
      if (profilePics.profileImageArr && profilePics.profileImageArr.length > 0) {
        kvAny.key = profilePics.sitUserId.toString();
        profilePics.profileImageArr.map((e) => {
          images.push(e.image);
        });
        kvAny.value = images;
      }
    }
    return kvAny;
  }
  // -------------------------------------------------------------
  // TODO: change the name to contentToKvPhotoArr:
  //
  getContentToKvArr(content: Content): any {
    const kvArr: KV[] = [];

    if (content) {
      /*
       * debugger;
       * Note: KV.v=string; to distinguish it from Kv.v=any, we chose to Capitalize 'V' in variable naming for KV
       */
      let kV: KV = new KV();

      if (content.heading && content.heading.length > 0) {
        kV = new KV();
        kV.key = 'Prfile name';
        kV.value = content.heading;
        kvArr.push(kV);
      }

      if (content.aBstract && content.aBstract.length > 0) {
        kV = new KV();
        kV.key = 'Highlight';
        kV.value = content.aBstract;
        kvArr.push(kV);
      }
      if (content.contents && content.contents.length > 0) {
        kV = new KV();
        kV.key = 'Description';
        kV.value = content.contents;
        kvArr.push(kV);
      }
    }
    return kvArr;
  }
  /*
   * -------------------------------------------------------------
   * Note: Order of the entries reflects as is on the view
   * -------------------------------------------------------------
   */
  // TODO: change the name to profileContentToKvPhotoArr:
  //
  getProfileContentToKvArrArr(profileContent: Content): any {
    const arrKvAny: KvAny[] = [];

    if (profileContent) {
      /*
       * debugger;
       * Note: To distinguosh Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
       */
      let kvAny: KvAny = new KvAny();

      if (profileContent.heading && profileContent.heading.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'ProfileName';
        kvAny.value = [profileContent.heading];
        arrKvAny.push(kvAny);
      }
      if (profileContent.aBstract && profileContent.aBstract.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'Highlight';
        kvAny.value = [profileContent.aBstract];
        arrKvAny.push(kvAny);
      }
      if (profileContent.contents && profileContent.contents.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'Description';
        kvAny.value = [profileContent.contents];
        arrKvAny.push(kvAny);
      }
    }
    // debugger;
    return arrKvAny;
  }
  /*
   * -------------------------------------------------------------
   * Note: Order of the entries reflects as is on the view
   * -------------------------------------------------------------
   */
  // TODO: change the name to preferenceToKvPhotoArr:
  //
  getPeferenceToArrKvAny(preference: Preference): any[] {
    const arrKvAny: KvAny[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference)) {
      /*
       * debugger;
       * Note: To distinguosh Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
       */
      let kvAny: KvAny = new KvAny();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.relationshipPreferences) && preference.relationshipPreferences.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'My relationship preference';
        kvAny.value = this.getArrayFromCommaSeparatedData(preference.relationshipPreferences);
        arrKvAny.push(kvAny);
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.intos) && preference.intos.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'I am into';
        kvAny.value = this.getArrayFromCommaSeparatedData(preference.intos);
        arrKvAny.push(kvAny);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.lookingFors) && preference.lookingFors.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'I am looking for';
        kvAny.value = this.getArrayFromCommaSeparatedData(preference.lookingFors);
        arrKvAny.push(kvAny);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.meetingLocations) && preference.meetingLocations.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'Meeting place';
        kvAny.value = this.getArrayFromCommaSeparatedData(preference.meetingLocations);
        arrKvAny.push(kvAny);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.hobbies) && preference.hobbies.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'My hobbies';
        kvAny.value = this.getArrayFromCommaSeparatedData(preference.hobbies);
        arrKvAny.push(kvAny);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.pets) && preference.pets.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'What about pet';
        kvAny.value = this.getArrayFromCommaSeparatedData(preference.pets);
        arrKvAny.push(kvAny);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.petPeeves) && preference.petPeeves.length > 0) {
        kvAny = new KvAny();
        kvAny.key = 'My pet peeves';
        kvAny.value = this.getArrayFromCommaSeparatedData(preference.petPeeves);
        arrKvAny.push(kvAny);
      }
    }
    // debugger;
    return arrKvAny;
  }
  /*
   * -------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/45411208/how-to-convert-key-value-pair-object-into-an-array-of-values-in-es6
   * array = Array.from(Object.keys(object), k => object[k]);
   * -------------------------------------------------------------
   */
  // TODO: change the name to setObjectModelToKv:
  //
  getObjectModelToKv(model: any): any {
    // Note: KV.v=string; to distinguish it from Kv.v=any, we chose to Capitalize 'V' in variable naming for KV
    let kV: KV = new KV();

    kV = JSON.parse(JSON.stringify(model)) as KV;
    // debugger;
    return kV;
  }
  // -------------------------------------------------------------
  objectModelToArrKv(model: any): any {
    // Note: KV.v=string; to distinguish it from Kv.v=any, we chose to Capitalize 'V' in variable naming for KV
    const arrKv: KV[] = JSON.parse(JSON.stringify(model)) as KV[];
    // debugger;

    return arrKv;
  }
  // -------------------------------------------------------------
  objectModelToArrKvAny(model: any): any {
    // Note: KV.v=string; to distinguish it from Kv.v=any, we chose to Capitalize 'V' in variable naming for KV
    const arrKvAny: KvAny[] = JSON.parse(JSON.stringify(model)) as KvAny[];
    // debugger;

    return arrKvAny;
  }
  // -------------------------------------------------------------
  // TODO: change the name to setObjectModelToKvKv:
  //
  getObjectModelToKvKv(model: any): any {
    /*
     * debugger;
     * Note: KV.v=string; to distinguish it from Kv.v=any, we chose to Capitalize 'V' in variable naming for KV
     */
    let kVkV: KVKV = new KVKV();
    /*
     * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
     * var arrayKeys = Array.from(Object.keys(model), k => {
     *   kv.key = k; // key
     * });
     * var arrayValues = Array.from(Object.values(model), v => {
     *   kv.value = v;
     * });
     * }
     */

    kVkV = JSON.parse(JSON.stringify(model)) as KVKV;
    // debugger;
    return kVkV;
  }
  // -------------------------------------------------------------
  // TODO: change the name to setObjectModelToArrKvKv:
  //
  getObjectModelToArrKvKv(model: any): any[] {
    /*
     * debugger;
     * Note: KV.v=string; to distinguish it from Kv.v=any, we chose to Capitalize 'V' in variable naming for KV
     */
    const arrkVkV: KVKV[] = JSON.parse(JSON.stringify(model)) as KVKV[];
    /*
     * var kvKv: KvKv = new KvKv;
     * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
     * var arrayKeys = Array.from(Object.keys(model));
     */

    /*
     * if (arrayKeys && arrayKeys.length > 0) {
     *   for (var i = 0; i < arrayKeys.length; i++) {
     *     kvKv = new KvKv;
     *     kvKv.key = arrayKeys[i];
     *     arrKvKv.push(kvKv);
     *   }
     * }
     */

    /*
     * var arrayValues = Array.from(Object.values(model));
     * if (arrayValues && arrKvKv.length === arrayValues.length) {
     *   for (var i = 0; i < arrKvKv.length; i++) {
     *     if (arrayValues[i].vlaue && arrayValues[i].vlaue.length)
     *       arrKvKv[i].value = this.getObjectModelToKv(arrayValues[i]);
     *   }
     * }
     * }
     * debugger;
     */
    return arrkVkV;
  }
  /*
   * -------------------------------------------------------------
   * TODO:Test,
   * -------------------------------------------------------------
   */
  getObjectModelToArrKv(model: any): any[] {
    /*
     * debugger;
     * Note: KV.v=string; to distinguish it from Kv.v=any, we chose to Capitalize 'V' in variable naming for KV
     */
    const arrkV: KV[] = [];
    let kV: KV = new KV();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const arrayKeys = Array.from(Object.keys(model));

      if (arrayKeys && arrayKeys.length > 0) {
        arrayKeys.map((e) => {
          kV = new KV();
          kV.key = e;
          arrkV.push(kV);
        });
      }

      const arrayValues = Array.from(Object.values(model));

      if (arrayValues && arrkV.length === arrayValues.length) {
        let i = 0;

        arrkV.map((e) => {
          e.value = arrayValues[i] as string;
          i++;
        });
      }
    }
    // debugger;
    return arrkV;
  }
  // -------------------------------------------------------------
  // TODO: change the name to setProfileImageToArrKvAny:
  //
  getProfileImageToArrKvAny(model: any): KvAny[] {
    /*
     * debugger;
     * Note: KV.v=string; to distinguish it from Kv.v=any, we chose to Capitalize 'V' in variable naming for KV
     */
    const arrKvAny: KvAny[] = [];
    let kvAny: KvAny = new KvAny();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const arrayKeys = Array.from(Object.keys(model));

      if (arrayKeys && arrayKeys.length > 0) {
        arrayKeys.map((e) => {
          kvAny = new KvAny();
          kvAny.key = e;
          arrKvAny.push(kvAny);
        });
      }

      const arrayValues = Array.from(Object.values(model));

      if (arrayValues && arrKvAny.length === arrayValues.length) {
        let i = 0;

        arrKvAny.map((e) => {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value.image) && e.value.image.length > 0) {
            // recursive-condition
            e.value = this.getObjectModelToArrKvAny(e.value); // use KV[]
            // debugger;
          } else {
            /*
             * if (arrayValues[i].toString().indexOf('data:image/png/svg;base64,') !== -1) {
             * debugger;
             * var image = 'url('' + arrayValues[i].toString() + '')';
             * arrKvAny[i].value = image;
             * }
             * else
             */
            e.value = arrayValues[i];
          }
          i++;
        });
      }
    }
    // debugger;
    return arrKvAny;
  }
  // -------------------------------------------------------------
  // -------------------------------------------------------------
  // TODO: change the name to setObjectModelToKvAny:
  //
  getObjectModelToKvAny(model: any): any {
    /*
     * debugger;
     * Note: To distinguosh Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
     */
    const kvAny: KvAny = new KvAny();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const arrKeys = Array.from(Object.keys(model), (k) => {
        kvAny.key = k;
      });
      const arrValues = Array.from(Object.values(model), (v) => {
        kvAny.value = v;
      });
      // debugger;

      return kvAny;
    }
    return null;
  }
  // -------------------------------------------------------------
  // TODO: change the name to setObjectModelToKvKvAnyArr:
  //
  getObjectModelToKvKvAnyArr(model: any): any {
    /*
     * debugger;
     * Note: To distinguosh Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
     */
    const kvKvAnyArr: KvKvAnyArr = new KvKvAnyArr();

    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const arrayKeys = Array.from(Object.keys(model), (k) => {
        kvKvAnyArr.key = k;
      });

      // later
      let i = 0;

      Array.from(Object.values(model), (v) => {
        kvKvAnyArr.value = this.getObjectModelToKvAnyArr(v);
        i++;
      });
    }
    return kvKvAnyArr;
  }
  // -------------------------------------------------------------
  // TODO: change the name to setObjectModelToArrKvKvAnyArr:
  //
  getObjectModelToArrKvKvAnyArr(model: any): any {
    /*
     * debugger;
     * Note: To distinguosh Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
     */
    const arrKvKvAnyArr: KvKvAnyArr[] = [];
    let kvKvAnyArr: KvKvAnyArr = new KvKvAnyArr();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const arrKeys = Array.from(Object.keys(model), (k) => {
        kvKvAnyArr = new KvKvAnyArr();
        kvKvAnyArr.key = k;
        arrKvKvAnyArr.push(kvKvAnyArr);
      });
      // debugger;
      let i = 0;
      const arrValues = Array.from(Object.values(model), (v) => {
        arrKvKvAnyArr[i].value = this.getObjectModelToKvAnyArr(v);
        i++;
      });
    }
    // debugger;
    return arrKvKvAnyArr;
  }
  // ---------------------------------------------------------------
  isImageData (data : any): boolean {
    /*
     * debugger;
     * var dataUriPattern = '/^data\:(?<type>image\/(png|tiff|jpg|gif));base64,(?<data>[A-Z0-9\+\/\=]+)$/g';
     */
    const dataUriPattern = '/data:(?image/(png|tiff|jpg|gif));base64,(?[A-Z0-9+/=]+)$/ig';

    if (data.match(dataUriPattern).length > 0) {
      // debugger;
      return true;
    }
    return false;
  }
  /*
   * -------------------------------------------------------------
   * Note: kvAnyArr => kv.v=[v]
   * -------------------------------------------------------------
   */
  // TODO: change the name to setObjectModelToKvAnyArr:
  //
  getObjectModelToKvAnyArr(model: any): any {
    // debugger;
    const kvAnyArr = new KvAnyArr();
    const tKeys :any[] = [];
    const tValues :any[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const modelType = StringServiceStatic.typeCheck(model);
      // Note: To distinguosh Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming

      const arrKeys = Array.from(Object.keys(model), (k) => {
        kvAnyArr.key = k; // key
        tKeys.push(k);
      });
      // debugger;

      if (modelType.toLowerCase().indexOf('array') !== -1) {
        // recursive-condition

        const arValues = Array.from(Object.values(model), (v) => {
          tValues.push(this.getObjectModelToKvAnyArr(v)); // recursive-call
          // debugger;
        });
      } else {
        // Base-condition
        const arValues = Array.from(Object.values(model), (v) => {
          tValues.push([v]);
          // debugger;
        });
      }
      // debugger;
      kvAnyArr.value = tValues;
    }
    // debugger;
    return kvAnyArr;
  }
  /*
   * -------------------------------------------------------------
   * Note:Tested, works. May 03, 2020
   * Note: kvAny => kv.v=kvAny
   * -------------------------------------------------------------
   */
  // TODO: change the name to setObjectModelToArrKvAny:
  //
  getObjectModelToArrKvAny(model: any): any {
    /*
     * debugger;
     * Note: To distinguish Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
     */
    const arrKvAny: KvAny[] = [];
    let kvAny: KvAny = new KvAny();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      let i = 0;
      const arrayKeys = Array.from(Object.keys(model));

      arrayKeys.map((e) => {
        kvAny = new KvAny();
        kvAny.key = e; // key
        arrKvAny.push(kvAny);
        if (e.indexOf('SITUserKey') !== -1) {
          this.index = i;
        }
        i++;
      });
      // debugger;

      i = 0;
      const arrayValues = Array.from(Object.values(model), (v) => {
        if (arrayKeys[this.index] && arrayKeys[this.index].indexOf('SITUserKey') !== -1) {
          this.sitUserKey = v;
        }

        const modelType = StringServiceStatic.typeCheck(v);

        if (modelType && modelType.toLowerCase().indexOf('array') !== -1) {
          // debugger;
          arrKvAny[i] = this.getObjectModelToArrKvAny(v);
          arrKvAny[i].value = arrKvAny[i].key;
        } else {
          /*
           * Note: added on July 19,2020; TODO:Test
           * else if (modelType.toLowerCase().indexOf('object') !== -1) {
           * arrKvAny[i] = this.getObjectModelToKvAny(v);
           * arrKvAny[i].value = arrKvAny[i].key;
           * }
           */
          arrKvAny[i].value = v;
        }
        i++;
      });
    }
    // debugger;
    return arrKvAny;
  }

  /*
   * -------------------------------------------------------------
   * Note: kvAny => kv.v=kvAny
   * -------------------------------------------------------------
   */
  // TODO: change the name to setObjectModelToArrKvAnyRecursively:
  //
  getObjectModelToArrKvAnyRecursively(model: any, parentId: any): any {
    /*
     * model=[KvAny], a single-valued-array
     * debugger;
     */
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const arrKvAny: KvAny[] = [];

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
        const modelType = StringServiceStatic.typeCheck(model);
        // Note: To distinguish Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
        let kvAny = new KvAny();
        /*
         * var tKeys = [];
         * var tValues = []
         */
        let i = 0;
        const arrKeys = Array.from(Object.keys(model), (k) => {
          kvAny = new KvAny();
          kvAny.id = parentId + '.' + i;
          kvAny.key = k; // key
          // tKeys.push(k);
          arrKvAny.push(kvAny);
          i++;
        });
        // debugger;

        i = 0;
        if (this.isModelTypeArray(modelType)) {
          // recursive-condition
          const arValues = Array.from(Object.values(model), (v) => {
            arrKvAny[i].value = this.getObjectModelToArrKvAnyRecursively(v, arrKvAny[i].id);
            // tValues.push(this.getObjectModelToArrKvAnyRecursively(v)); //recursive-call
            i++;
            // debugger;
          });
        } else {
          // Base-condition
          const arValues = Array.from(Object.values(model), (v) => {
            arrKvAny[i].value = v;
            // tValues.push([v]);
            i++;
            // debugger;
          });
        }
      }
      // debugger;
      return arrKvAny;
    }
    return null;
  }
  /*
   * -------------------------------------------------------------
   * Testing!!
   * -------------------------------------------------------------
   */
  // TODO: change the name to setProfilePicsToArrKvAny:
  //
  getProfilePicsToArrKvAny(model: any): any {
    // model = KvAny[]
    const profilePicArrKvAny: KvAny[] = [];
    // Note: To distinguish Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
    let kvAny = new KvAny();
    // var kvAnyArr = new KvAnyArr();
    const tKeys :any[] = [];
    const tValues :any[] = [];
    let i = 0;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const arrayKeys = Array.from(Object.keys(model), (k) => {
        kvAny = new KvAny();
        kvAny.id = i;
        kvAny.key = k;
        tKeys.push(k);
        profilePicArrKvAny.push(kvAny);
        i++;
        /*
         * TODO: test to see if it produces the same result as the later
         * kvKvArr.value = this.getObjectModelToKvArr(Array.from(Object.values(model)));
         */
      });

      // later
      i = 0;
      const arrayValues = Array.from(Object.values(model), (v) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(v)) {
          const vType = StringServiceStatic.typeCheck(v);

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(vType)) {
            if (vType.toString().toLowerCase().indexOf('array') === -1 && vType.toString().toLowerCase().indexOf('function') === -1) {
              // debugger;
              profilePicArrKvAny[i].value = v;
            } else if (vType.toLowerCase().indexOf('array') !== -1) {
              /*
               * Note: added on July 19,2020; TODO:Test
               * else if (vType.toString().toLowerCase().indexOf('object') !== -1) {
               * profilePicArrKvAny[i] = this.getObjectModelToKvAny(v);
               * profilePicArrKvAny[i].value = profilePicArrKvAny[i].key;
               * }
               */
              /*
               * debugger;
               * Note: on the value of kvAny, attach kvAnyArr
               */
              profilePicArrKvAny[i].value = this.getObjectModelToArrKvAnyRecursively(v, profilePicArrKvAny[i].id);
            }
            i++;
          }
        }
      });
    }
    // debugger;
    return profilePicArrKvAny;
  }
  /*
   * -------------------------------------------------------------
   * Note: Order of the entries reflects as is on the view
   * -------------------------------------------------------------
   */
  // TODO: change the name to setProfileInfoToKvArrArr:
  //
  getProfileInfoToKvArrArr(profileInfo: ProfileInfo): any {
    const arrKvAnyArr: KvAnyArr[] = [];

    if (profileInfo) {
      /*
       * debugger;
       * Note: To distinguish Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
       */
      let kvAnyArr: KvAnyArr = new KvAnyArr();

      if (profileInfo.gender && profileInfo.gender.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Gender';
        kvAnyArr.value = [profileInfo.gender];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.age > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Age';
        kvAnyArr.value = [profileInfo.age];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.sexuality && profileInfo.sexuality.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Sexuality';
        kvAnyArr.value = [profileInfo.sexuality];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.position && profileInfo.position.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Position';
        kvAnyArr.value = [profileInfo.position];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.dickSize && profileInfo.dickSize.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Dick Size';
        kvAnyArr.value = [profileInfo.dickSize];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.cutUncut && profileInfo.cutUncut.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Cut/Uncut';
        kvAnyArr.value = [profileInfo.cutUncut];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.highestEducation && profileInfo.highestEducation.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'My highest education level';
        kvAnyArr.value = [profileInfo.highestEducation];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.relationshipStatus && profileInfo.relationshipStatus.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'My relationship status';
        kvAnyArr.value = [profileInfo.relationshipStatus];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.religion && profileInfo.religion.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'My religious standing';
        kvAnyArr.value = [profileInfo.religion];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.smokingStatus && profileInfo.smokingStatus.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Smoking/Vaping status';
        kvAnyArr.value = [profileInfo.smokingStatus];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.away && profileInfo.away.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Away';
        kvAnyArr.value = [profileInfo.away];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.distance && profileInfo.distance.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Distance';
        kvAnyArr.value = [profileInfo.distance];
        arrKvAnyArr.push(kvAnyArr);
      }

      if (profileInfo.neighborhood && profileInfo.neighborhood.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Neighborhood';
        kvAnyArr.value = [profileInfo.neighborhood];
        arrKvAnyArr.push(kvAnyArr);
      }
      if (profileInfo.city && profileInfo.city.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'City';
        kvAnyArr.value = [profileInfo.city];
        arrKvAnyArr.push(kvAnyArr);
      }
      if (profileInfo.regionCode && profileInfo.regionCode.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'State/Profince/Territory';
        kvAnyArr.value = [profileInfo.regionCode];
        arrKvAnyArr.push(kvAnyArr);
      }
      if (profileInfo.countryCode && profileInfo.countryCode.length > 0) {
        kvAnyArr = new KvAnyArr();
        kvAnyArr.key = 'Country';
        kvAnyArr.value = [profileInfo.countryCode];
        arrKvAnyArr.push(kvAnyArr);
      }
    }
    return arrKvAnyArr;
  }
  // ---------------------------------------------------------------
  isModelArray(model: any): boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      const modelType = StringServiceStatic.typeCheck(model);

      if (modelType.toLowerCase().indexOf('array') !== -1) {
        return true;
      }
    }
    return false;
  }
  // ---------------------------------------------------------------
  isArray(model: any): boolean {
    return this.isModelArray(model);
  }
  // ---------------------------------------------------------------
  isModelTypeArray(modelType: any): boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelType)) {
      if (modelType.toLowerCase().indexOf('array') !== -1) {
        return true;
      }
    }
    return false;
  }
  // ---------------------------------------------------------------
  isImage(model: KvAny): boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.key)) {
      if (model.key.toLowerCase().indexOf('image') !== -1) {
        return true;
      }
    }
    return false;
  }
  // ---------------------------------------------------------------
  setPhotoToKvModel (photo : Photo) : KvAny {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.photoId > 0)) {
      let kvAny = new KvAny();
      kvAny.sitUserId = photo.sitUserId;
      kvAny.key = photo.photoId.toString();
      kvAny.modelName = 'Photo';
      kvAny.cryptUserKey = photo.cryptUserKey;
      kvAny.value = photo;

      return kvAny;
    }
    return new KvAny();
  }
  /*
   * -------------------------------------------------------------
   * Note: Order of the entries reflects as is on the view
   * -------------------------------------------------------------
   */
  sortArrKvAnyForView(inArrKvAny: KvAny[]): KvAny[] {
    const outArrKvAny: KvAny[] = [];

    if (inArrKvAny) {
      /*
       * debugger;
       * Note: To distinguish Kv.v=any from KV.v=string, we chose to capitalize 'K' for variable naming
       */
      let kvAny: KvAny = new KvAny();

      inArrKvAny.map((e) => {
        if (e && e.key && e.key.length > 0 && e.value && e.value.toString().length > 0) {
          switch (e.key.toLowerCase()) {
            case 'gender':
              kvAny = new KvAny();
              kvAny.key = 'Gender';
              kvAny.value = e.value;
              kvAny.index = 0;
              outArrKvAny.push(kvAny);
              break;
            case 'age':
              kvAny = new KvAny();
              kvAny.key = 'Age';
              kvAny.value = e.value;
              kvAny.index = 1;
              outArrKvAny.push(kvAny);
              break;
            case 'sexuality':
              kvAny = new KvAny();
              kvAny.key = 'Sexuality';
              kvAny.value = e.value;
              kvAny.index = 2;
              outArrKvAny.push(kvAny);
              break;
            case 'position':
              kvAny = new KvAny();
              kvAny.key = 'Position';
              kvAny.value = e.value;
              kvAny.index = 3;
              outArrKvAny.push(kvAny);
              break;
            case 'dicksize':
              kvAny = new KvAny();
              kvAny.key = 'Dick Size';
              kvAny.value = e.value;
              kvAny.index = 4;
              outArrKvAny.push(kvAny);
              break;
            case 'cutuncut':
              kvAny = new KvAny();
              kvAny.key = 'Cut/Uncut';
              kvAny.value = e.value;
              kvAny.index = 5;
              outArrKvAny.push(kvAny);
              break;
            case 'highesteducation':
              kvAny = new KvAny();
              kvAny.key = 'My highest education level';
              kvAny.value = e.value;
              kvAny.index = 6;
              outArrKvAny.push(kvAny);
              break;
            case 'relationshipstatus':
              kvAny = new KvAny();
              kvAny.key = 'My relationship status';
              kvAny.value = e.value;
              kvAny.index = 7;
              outArrKvAny.push(kvAny);
              break;
            case 'religion':
              kvAny = new KvAny();
              kvAny.key = 'My religious standing';
              kvAny.value = e.value;
              kvAny.index = 8;
              outArrKvAny.push(kvAny);
              break;
            case 'smokingstatus':
              kvAny = new KvAny();
              kvAny.key = 'Smoking/Vaping status';
              kvAny.value = e.value;
              kvAny.index = 9;
              outArrKvAny.push(kvAny);
              break;
            case 'away':
              kvAny = new KvAny();
              kvAny.key = 'Online/Offline';
              kvAny.value = e.value;
              kvAny.index = 10;
              outArrKvAny.push(kvAny);
              break;
            case 'distance':
              kvAny = new KvAny();
              kvAny.key = 'Distance';
              kvAny.value = e.value;
              kvAny.index = 11;
              outArrKvAny.push(kvAny);
              break;
            case 'unit':
              kvAny = new KvAny();
              kvAny.key = 'Unit';
              kvAny.value = e.value;
              kvAny.index = 12;
              outArrKvAny.push(kvAny);
              break;
            case 'neighborhood':
              kvAny = new KvAny();
              kvAny.key = 'Neighborhood';
              kvAny.value = e.value;
              kvAny.index = 13;
              outArrKvAny.push(kvAny);
              break;
            case 'city':
              kvAny = new KvAny();
              kvAny.key = 'City';
              kvAny.value = e.value;
              kvAny.index = 14;
              outArrKvAny.push(kvAny);
              break;
            case 'regioncode':
              kvAny = new KvAny();
              kvAny.key = 'State/Profince/Territory';
              kvAny.value = e.value;
              kvAny.index = 15;
              outArrKvAny.push(kvAny);
              break;
            case 'countrycode':
              kvAny = new KvAny();
              kvAny.key = 'Country';
              kvAny.value = e.value;
              kvAny.index = 16;
              outArrKvAny.push(kvAny);
              break;
          }
        }
      });
    }
    outArrKvAny.sort((a, b) => a.index - b.index);
    return outArrKvAny;
  }
  // --------------------------------------------------------------
  setBackgroundImage(placeId: string, imageData: string): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(placeId) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
      // debugger;
      const tempElem = document.getElementById(placeId);
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
        /*
         * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
         * var image = 'url('' + imageData + '')';
         * debugger;
         */
        this.renderer?.setStyle(tempElem, 'background-image', imageData);
        tempElem.remove();
        return true;
        /*
         * }
         * else this.renderer?.setStyle(this.tempElem, 'background-image', this.getPageImage(this.pbgCurrentPage));
         */
      }
    }
    return false;
  }
  /*
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
}
