'use strict'
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export abstract class WhenScrollEndsServiceStatic {
  static server = 'https:// localhost:44382/';
  static apiUrl = 'api/';
  static serverWithApiUrl = WhenScrollEndsServiceStatic.server + WhenScrollEndsServiceStatic.apiUrl;

 // ------------------------------------------------------------------------
  public constructor () {    
  }

 // ------------------------------------------------------------------------
 // Ref:https:// www.ibm.com/developerworks/community/blogs/3d274f0f-f47a-4bf0-91ec-8ebad479881b/
 //    entry/how_to_implement_a_table_with_lazy_loading_infinite_scroll_in_angularjs?lang=en
 static  whenScrollEnds()
   {
        return {
            restrict: ':A',
          link: (scope : any, element : any, attrs : any) => {
                const visibleHeight = element.height();
                const threshold = 100;

                element.scroll( () => {
                    const scrollableHeight = element.prop('scrollHeight');
                    const hiddenContentHeight = scrollableHeight - visibleHeight;

                    if (hiddenContentHeight - element.scrollTop() <= threshold) {
                        // Scroll is almost at the bottom. Loading more rows
                        scope.$apply(attrs.whenScrollEnds);
                    }
                });
            },
        };
    }
}
