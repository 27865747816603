import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

export class UpgradeCost
{
  public date = '';
  public id = 0;
  public key = '';
  public selectedOption = '';
  public sitUserKey = '';
  public subtotal = 0;
  public tax = 0;
  public total = 0
  constructor () { }
  // -----------------------------------------------
  public copy ( a : UpgradeCost ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( a ) )
    {
      this.date = a.date;
      this.id = a.id;
      this.key = a.key;
      this.selectedOption = a.selectedOption;
      this.sitUserKey = a.sitUserKey;
      this.subtotal = a.subtotal;
      this.tax = a.tax;
      this.total = a.total;
    }
  }
  // -----------------------------------------------
  // TODO:
  public copyIfExists ( a : UpgradeCost ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( a ) )
    {
      this.date = a.date && a.date.length > 0 ? a.date : this.date;
      this.id = a.id > 0 ? a.id : this.id;
      this.key = a.key && a.key.length > 0 ? a.key : this.key;
      this.selectedOption = a.selectedOption && a.selectedOption.length > 0 ? a.selectedOption : this.selectedOption;
      this.sitUserKey = a.sitUserKey && a.sitUserKey.length > 0 ? a.sitUserKey : this.sitUserKey;
      this.subtotal = a.subtotal > 0 ? a.subtotal : this.subtotal;
      this.tax = a.tax > 0 ? a.tax : this.tax;
      this.total = a.total > 0 ? a.total : this.total;
    }
  }
  // -----------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{,\n' +
      'date:' +
      this.date +
      ',\n' +
      'id: ' +
      this.id +
      ', \n' +
      'selectedOption: ' +
      this.selectedOption + 
      ',\n' +
      'sitUserKey: ' +
      this.sitUserKey +
      ',\n' +
      'subtotal: ' +
      this.subtotal +
      ',\n' +
      'tax: ' +
      this.tax +
      ',\n' +
      'total: ' +
      this.total +     
      '\n}';
    return outString;
  }
  // -----------------------------------------------
}
