
import { Injectable } from '@angular/core';
import { ProfileTileService } from './profileTileService.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileTileServiceService {
  constructor (
    private profileTileService : ProfileTileService,
  ) { }
  
  // ------------------------------------------------------------------
  setProfileTileService (pts : ProfileTileService) : void {
    this.profileTileService = pts;
  }
  getProfileTileService () : ProfileTileService {
    return this.profileTileService;
  }
  // ------------------------------------------------------------------
}
