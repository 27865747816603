<!--<div id="pagerVerticalGlyphForViewMemberComponentId" class="flex-pagerV-container decrease text-center"
     style="float:left;align-items:center; margin: 0.05rem; padding: 0.05rem;height:100%;width:14%; position: sticky;">--> 
<!--===========================================================================================================-->

  <!--Begin of Second version of ViewMember's Glyphicon-menu:-->

<div *ngIf="kvGlyphArrForViewMember && kvGlyphArrForViewMember.length > 0"
     id="pagerVerticalGlyphForViewMemberComponentId"
     class="flex-pagerV-item text-center"
     style="margin: 0.05rem; padding: 0.05rem; width: auto;height:auto;min-height:60%;">
  <div [ngClass]="{'flex-item-plain text-center bttn-xxxs-transparent': true }"
       style="width:2.5rem; height: auto; margin: 0.1rem; padding: 0.01rem;">
    <button (click)="setChatAction()" [ngClass]="{'hvr-underline-from-center flex-pagerV-item text-center bttn-xxxs-transparent':true}"
            style="width: 2rem;height:2rem;margin: 0.02rem;padding: 0.02rem;cursor: pointer;">
      <img id="pageVMAGlyphView-chat"
           class="img hvr-icon text-center drkRedGrnSB"
           src="assets/photos/chat.png"
           style="background:transparent; vertical-align: middle; margin: 0.15rem 0.01rem 0.02rem 0.01rem; z-index: 100; height: 1.55rem; width: 1.55rem;" aria-hidden="true" />
    </button>
    <div class="hvr-icon blueDrkRedxSB" style="margin:-0.02rem 0 0.1rem 0;cursor:pointer;">
      Chat
    </div>
  </div>

  <div [ngClass]="{'flex-item-plain text-center bttn-xxxs-transparent': true }"
       style="width: 2.5rem; height: auto; margin: 0.1rem; padding: 0.01rem;">
    <button (click)="setEmailAction()"
            [ngClass]="{'hvr-underline-from-center flex-pagerV-item text-center bttn-xxxs-transparent':true}"
            style="width: 2rem;height: 2rem;margin: 0.02rem;padding: 0.02rem;cursor: pointer;">
      <div id="pageVMAGlyphView-email"
           class="hvr-icon text-center drkRedGrnB glyphicon  glyphicon-envelope"
           style="width: 1.5rem; height: 1.5rem; font-size: 1.25rem" aria-hidden="true">
      </div>
    </button>
    <div class="hvr-icon blueDrkRedxSB" style="margin:-0.02rem 0 0.1rem 0;cursor: pointer;">
      Email
    </div>
  </div>
  <!------->
  <hr class="hr" style="background-color:deepskyblue;height:2.15px;width:190px;" />
  <!------->
  <div *ngIf="isCurrentlyUnlocked" (click)="setUnlockAction('lock')"
       [ngClass]="{'flex-item-plain text-center bttn-xxxs-transparent': true }"
       style="width: 2.5rem; height: auto; margin: 0.1rem; padding: 0.01rem;">
    <div [ngClass]="{'hvr-underline-from-center flex-pagerV-item text-center bttn-xxxs-transparent':true}"
         style="width: 2rem; height: 2rem; margin: 0.02rem; padding: 0.02rem; cursor: pointer;">
      <img id="pageVMAGlyphView-lock"
           class="img hvr-icon text-center drkRedGrnSB"
           src="assets/photos/lock.png"
           style="background: transparent; vertical-align: middle; margin: 0.15rem 0.01rem 0.02rem 0.01rem; z-index: 100; height: 1.55rem; width: 1.55rem;" aria-hidden="true" />
    </div>
    <div class="hvr-icon blueDrkRedxSB" style="margin:-0.02rem 0 0.1rem 0;cursor: pointer;">
      Lock
    </div>
  </div>

  <div *ngIf="!isCurrentlyUnlocked" (click)="setUnlockAction('unlock')"
       [ngClass]="{'flex-item-plain text-center bttn-xxxs-transparent': true }"
       style="width: 2.5rem; height: auto; margin: 0.1rem; padding: 0.01rem;">
    <div [ngClass]="{'hvr-underline-from-center flex-pagerV-item text-center bttn-xxxs-transparent':true}"
         style="width: 2rem; height: 2rem; margin: 0.02rem; padding: 0.02rem; cursor: pointer;">
      <img id="pageVMAGlyphView-unlock"
           class="img hvr-icon text-center drkRedGrnSB"
           src="assets/photos/unlock.png"
           style="background: transparent; vertical-align: middle; margin: 0.15rem 0.01rem 0.02rem 0.1rem; z-index: 100; height: 1.55rem; width: 1.55rem;" aria-hidden="true" />
    </div>
    <div class="hvr-icon blueDrkRedxSB" style="margin:-0.02rem 0 0.1rem 0;cursor: pointer;">
      Unlock
    </div>
  </div>
  <div *ngIf="!isCurrentlyBlocked" (click)="setBlockAction('block')"
       [ngClass]="{'flex-item-plain text-center bttn-xxxs-transparent': true }"
       style="width: 2.5rem; height: auto; margin: 0.1rem; padding: 0.01rem;">
    <div [ngClass]="{'hvr-underline-from-center flex-pagerV-item text-center bttn-xxxs-transparent':true}"
            style="width: 2rem;height: 2rem;margin: 0.02rem;padding: 0.02rem;cursor: pointer;">
      <div id="pageVMAGlyphView-block"
           class="hvr-icon text-center drkRedGrnB glyphicon  glyphicon-ban-circle"
           style="width: 1.5rem; height: 1.5rem; font-size: 1.25rem" aria-hidden="true">
      </div>
    </div>
    <div class="hvr-icon blueDrkRedxSB" style="margin:-0.02rem 0 0.1rem 0;cursor: pointer;">
      Block
    </div>
  </div>
  <div *ngIf="isCurrentlyBlocked" (click)="setBlockAction('unblock')"
       [ngClass]="{'flex-item-plain text-center bttn-xxxs-transparent': true }"
       style="width: 2.5rem; height: auto; margin: 0.1rem; padding: 0.01rem;">
    <div [ngClass]="{'hvr-underline-from-center flex-pagerV-item text-center bttn-xxxs-transparent':true}"
            style="width: 2rem;height: 2rem;margin: 0.02rem;padding: 0.02rem;cursor: pointer;">
      <div id="pageVMAGlyphView-unblock"
           class="hvr-icon text-center drkRedGrnB glyphicon  glyphicon-ok-circle"
           style="width: 1.5rem; height: 1.5rem; font-size: 1.25rem" aria-hidden="true">
      </div>
    </div>
    <div class="hvr-icon blueDrkRedxSB" style="margin:-0.02rem 0 0.1rem 0;cursor: pointer;">
      Unblock
    </div>
  </div>
  <!------->
  <hr class="hr" style="background-color:deepskyblue;height:2.15px;width:190px;" />
  <!------->
  <div *ngFor="let kvAny of kvGlyphArrForViewMember; let i=index;" class="text-center">
    <div [ngClass]="{'active': 'kvAny && kvAny.index >= 0 && kvAny.index === (i+1)', 'flex-item-plain text-center bttn-xxxs-transparent': true }"
         style="width: 2.5rem; height: auto; margin: 0.1rem; padding: 0.01rem;">

      <button (click)="setGlyphAction(i + 1, kvAny)"
              [ngClass]="{'hvr-underline-from-center flex-pagerV-item text-center bttn-xxxs-transparent':true}"
              style="width: 2rem;
                      height: 2rem;
                      margin: 0.02rem;
                      padding: 0.02rem;
                      cursor: pointer;">
        <!--Glyphicon case:-->
        <!--&& kvAny.value?.toString().toLowerCase().indexOf('camera') === -1"-->
        <div *ngIf="kvAny && kvAny.value && kvAny.value.toString().toLowerCase().indexOf('glyphicon') !== -1"
             id="pageVMAGlyphView-{{i + 1}}"
             class="hvr-icon text-center drkRedGrnB glyphicon  {{kvAny.value}}"
             style="width: 1.5rem;
                            height: 1.5rem;
                            font-size: 1.25rem" aria-hidden="true">
        </div>

        <!--image/pic case:-->
        <img *ngIf="kvAny && kvAny.value
               && kvAny.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1"
             id="pageVMAGlyphView-{{i + 1}}"
             [ngClass]="{'hvr-icon eNoteTilePic img-circle img-responsive increase': true}"
             src="{{kvAny.value}}"
             type="button"
             style="height: 1.55rem;
                      width: 1.55rem;
                      z-index: 80;
                      vertical-align: middle;
                      margin: 0.0rem;
                      display: inline;" />

        <!--image-file-name-case-->
        <!--an image-file-name in lieu of a glyphicon or raw image data: -->
        <img *ngIf="kvAny && kvAny.value &&
                    (kvAny.value.toString().toLowerCase().indexOf('.jpg') !== -1
                    || kvAny.value.toString().toLowerCase().indexOf('.png') !== -1)"
             id="pageVMAGlyphView-{{i + 1}}"
             class="img hvr-icon text-center drkRedGrnSB"
             src="{{kvAny.value}}"
             style="background: transparent;
                      vertical-align: middle;
                      margin: 0.15rem 0.01rem 0.02rem 0.01rem;
                      z-index: 100;
                      height: 1.55rem;
                      width: 1.55rem;" aria-hidden="true" />
      </button>

      <!--glyph/image/menu name:-->
      <div class="hvr-icon blueDrkRedxSB" style="margin:-0.02rem 0 0.1rem 0;cursor: pointer;">
        {{kvAny.key.indexOf('tala') !== -1 ? 'lock' : kvAny.key}}
      </div>
    </div>
  </div>
</div>
  <!--End of Second version of ViewMember's Glyphicon-menu-->
  <!--===========================================================================================================-->

