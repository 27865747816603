<div class="row">
	<div class="col-12 text-center increase" style="margin:0.05rem 10%;">
		<label class="col-12 btn btn-default text-center">
			<input type="file" (change)="selectFile($event)" style="padding:0.05rem;border-radius: 5px; font-size: 0.65rem;" class="col-10 text-center"/>
		</label>
	</div>

	<div class="col-12 text-center">
		<button [disabled]="!selectedFiles" (click)="upload()"
						[ngClass]="{'flex-button bttn-md-primary':true, 'mBttnFontWeight':isMobilevar}" style="margin:5px;display:inline-block;">
			<span class="glyphicon glyphicon-cloud-upload" aria-hidden="true"
						style="vertical-align:middle;padding:5px 10px 5px 10px;">
				Upload
			</span>
		</button>
		<button type="button" (click)="cancel();"
						class="flex-button"
						[ngClass]="{'bttn-md-invDefault':!isMobilevar, 'bttn-sm-invDefault mBttnFontWeight':isMobilevar}"
						style="margin:5px 5px 5px 0.5rem;display:inline-block;">
			<span class="glyphicon glyphicon-ban-circle" aria-hidden="true" style="vertical-align:middle;padding:5px 10px 5px 10px;">
				Cancel
			</span>
		</button>
	</div>
</div>

<div *ngIf="currentFile" class="progress my-3">
	<div class="progress-bar progress-bar-info progress-bar-striped"
			 role="progressbar"
			 attr.aria-valuenow="{{ progress }}"
			 aria-valuemin="0"
			 aria-valuemax="100"
			 [ngStyle]="{ width: progress + '%' }">
		{{ progress }}%
	</div>
</div>

<!-- <div *ngIf="message" class="alert alert-secondary" role="alert">{{ message }}</div> -->

<!--<div class="card mt-3">
	<div class="card-header">List of Files</div>
	<ul class="list-group list-group-flush">
		<li *ngFor="let file of fileInfos | async" class="list-group-item">
			<a href="{{ file.url }}">{{ file.name }}</a>
		</li>
	</ul>
</div>-->
