import { KvPhoto } from "../keyValue/kvPhoto.model";
import { Photo } from "./photo.model";
import { PhotoBn } from "./photoBn.model";

// Note:  This model is a container model for all photo-related models. 
//        So add other photo - related models in it on the need basis.
// -------------------------------------------------------------------
export class PhotoOmniModel {
  public photo : Photo = new Photo();
  public photoBn : PhotoBn = new PhotoBn();
  public kvPhoto : KvPhoto = new KvPhoto();
  public date = '';
  public id = 0;
  public sitUserId = 0;
  public message !: any;
  constructor() { }
}
