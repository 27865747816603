import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../../services/staticServices/stringServiceStatic.service';
import { SitUser } from '../account/sitUser.model';
import { Heartbeat } from '../common/heartbeat.model';
import { Person } from '../common/person';
import { Unit } from '../common/unit.model';
import { KvPhoto } from '../keyValue/kvPhoto.model';
import { OffOn } from '../offOn/offOn.model';
import { Profile } from './profile.model';
import { ProfileImage } from './profileImage.model';
import { ProfileInfo } from './profileInfo.model';

// --------------------------------------------------------------------------------------------------------------------
// Note:  ProfileTile model should indicate all about a member's Profile status in a single tile which includes the following:
//        1. ProfileName
//        2. Profile-Pic/Image
//        3. Online/Offline and away status via OffOn Model
//        4. Distance from the signedInUser
//        5. Other profile-matrix such as age/gender/sexuality/position/city/state-province/
// --------------------------------------------------------------------------------------------------------------------


export class ProfileTile {
  public age = 0;
  public beginIndex = 0;
  public bodyhair = '';
  public built = '';
  public city = '';
  public cutuncut = '';
  public cryptUserKey = '';
  public countryCode = '';
  public date = '';
  public dicksize = '';
  public distance = 0;
  public distanceStr = '';
  public distanceUnit: Unit = new Unit();
  public dob = '';
  public ethnicity = '';
  public eyes = '';
  public gender = '';
  public hair = '';
  public heading = '';
  public heartbeat : Heartbeat = new Heartbeat();
  public heartbeatTime = 0;
  public height = '';
  public id = '';
  public isImageRendered = false;
  public isIndexDbTile = false; // to indicate whether the model data was from indexedDb or not
  public isOnline = false;
  public isPremiumMember = false;
  public isPrimaryPhoto = false; // TODO: change the back-end
  public isTileSetupComplete = false;
  public imageWithoutUrl = '';
  public index = -0;
  public jsDateForCs = '';
  public kvPhoto: KvPhoto = new KvPhoto();
  public link = '';
  public latitude = '';
  public longitude = '';
  public neighborhood = '';
  public offOn : OffOn = new OffOn();
  public pElemId = '';
  public photoId = 0;
  public photoBnId = 0;
  public position = '';
  public primaryImage = '';
  public privacy = ''; // TODO: change the back-end
  public profileId = 0;
  public profileName = '';
  public pTileElement : any;
  public regionCode = '';
  public sexuality = '';
  public sitUserId = 0;
  public sitUserKey = '';
  public signedInUserId = 0;
  public signedInUserKey = '';
  public smokingStatus = '';
  public source = '';
  public unit = 'meter';
  public weight = 0;

  constructor ()
  {
    /*this.source = this.sitUserId.toString();*/
  }
  // ------------------------------------------------------------
  copyToKvPhoto (kvPhoto: KvPhoto, pTile: ProfileTile) : KvPhoto {
    let a : KvPhoto = new KvPhoto();
    // debugger;
    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      a = new KvPhoto().copySourceToDestination(a, kvPhoto);
    }

    a.date = pTile.date;
    a.elemId = pTile.pElemId;
    a.entityName = "ProfileTile"
    a.glyphName = pTile.isPrimaryPhoto ? 'Primary' : ''; // Note: or some other value
    a.isPrimary = pTile.isPrimaryPhoto;
    a.isPrivate = pTile.privacy?.toLowerCase().indexOf('public') !== -1 ? false : true;
    a.key = pTile.isPrimaryPhoto ? 'Primary' : pTile.sitUserId.toString(); // Note: or some other value
    a.photoId = pTile.photoId;
    a.photoBnId = pTile.photoBnId;
    a.privacy = pTile.isPrimaryPhoto ? 'Public' : '';
    a.profileId = pTile.profileId;
    a.signedInUserId = pTile.signedInUserId;
    a.sitUserId = pTile.sitUserId;
    a.sitUserKey = pTile.sitUserKey;
    a.sourceType = pTile.source,
      a.value = a.pic = a.image = pTile.primaryImage;

    a = new ProfileTile().setupPicImageAndValueOfKvPhoto(a);
    return a;
  }
  // --------------------------------------------------------

  copy (sourceTile : ProfileTile) : ProfileTile {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile)) {
      
      this.age = sourceTile.age;
      this.beginIndex = sourceTile.beginIndex;  
      this.bodyhair = sourceTile.bodyhair;
      this.built = sourceTile.built;      
     
      this.city = sourceTile.city;
      this.cutuncut = sourceTile.cutuncut;      
      this.countryCode = sourceTile.countryCode;
     
      this.date = sourceTile.date;
      this.dicksize = sourceTile.dicksize;      
      this.distance = sourceTile.distance;     
      this.distanceStr = sourceTile.distanceStr;
      this.distanceUnit = new Unit().copySourceToDestination(this.distanceUnit, sourceTile.distanceUnit);
      this.dob = sourceTile.dob;
      this.ethnicity = sourceTile.ethnicity;
      this.eyes = sourceTile.eyes;
     
      this.gender = sourceTile.gender;
      this.hair = sourceTile.hair;      
      this.heading = sourceTile.heading;
      this.height = sourceTile.height;
      this.heartbeatTime = sourceTile.heartbeatTime;
     
      this.id = sourceTile.id;
      
      this.primaryImage = sourceTile.primaryImage;

      this.index = sourceTile.index;

      this.isImageRendered = sourceTile.isImageRendered;

      this.isOnline = sourceTile.isOnline;// sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;

      this.isPremiumMember = sourceTile.isPremiumMember;

      this.isTileSetupComplete = sourceTile.isTileSetupComplete;
      
      this.latitude = sourceTile.latitude;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.link) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.link)) {
        this.link = sourceTile.link;
      } else {
        this.link = 'view/';
      }
      this.longitude = sourceTile.longitude;

      this.neighborhood = sourceTile.neighborhood;

      this.pElemId = sourceTile.pElemId;

      this.position = sourceTile.position;

      this.privacy = sourceTile.privacy;

      this.profileId = sourceTile.profileId;

      this.profileName = sourceTile.profileName;

      this.pTileElement = sourceTile.pTileElement;

      this.regionCode = sourceTile.regionCode;

      this.sexuality = sourceTile.sexuality;

      this.sitUserId = sourceTile.sitUserId;

      this.signedInUserId = sourceTile.signedInUserId;

      this.sitUserKey = sourceTile.sitUserKey;

      this.signedInUserId = sourceTile.signedInUserId;

      this.signedInUserKey = sourceTile.signedInUserKey;

      this.unit = sourceTile.unit;
      this.weight = sourceTile.weight;

      // must appear at the end but before CopyToKvPhoto() to capture ProfileTile's data
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeat)) {
        if (FrequentlyUsedFunctionsServiceStatic.typeCheck(sourceTile.heartbeat) == 'object' && sourceTile.heartbeat instanceof Heartbeat) {
          // debugger;
          if (sourceTile.heartbeat.heartbeatTime > 0) {
            // debugger;
            this.heartbeat = this.heartbeat.copy(sourceTile.heartbeat);
          }
        }
        else {
          // debugger;
          this.heartbeat = sourceTile.heartbeat
        }
      }
      // must appear at the end to capture ProfileTile's data for KvPhoto
      this.copyToKvPhoto(sourceTile.kvPhoto, sourceTile);
    }
    return this;
  }
  // --------------------------------------------------------

  public copySourceToDestination (destinationTile: ProfileTile, sourceTile : ProfileTile) : ProfileTile {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile)) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationTile)) {
        destinationTile = new ProfileTile();
			}
      destinationTile.age = sourceTile.age;
      destinationTile.beginIndex = sourceTile.beginIndex;
      destinationTile.bodyhair = sourceTile.bodyhair;
      destinationTile.built = sourceTile.built;

      destinationTile.city = sourceTile.city;
      destinationTile.cutuncut = sourceTile.cutuncut;
      destinationTile.countryCode = sourceTile.countryCode;

      destinationTile.date = sourceTile.date;
      destinationTile.dicksize = sourceTile.dicksize;
      destinationTile.distance = sourceTile.distance;
      destinationTile.distanceStr = sourceTile.distanceStr;
      destinationTile.distanceUnit = new ProfileTile().distanceUnit?.copySourceToDestination(destinationTile.distanceUnit, sourceTile.distanceUnit);
      destinationTile.dob = sourceTile.dob;
      destinationTile.ethnicity = sourceTile.ethnicity;
      destinationTile.eyes = sourceTile.eyes;

      destinationTile.gender = sourceTile.gender;
      destinationTile.hair = sourceTile.hair;
      destinationTile.heading = sourceTile.heading;
      destinationTile.height = sourceTile.height;


      destinationTile.city = sourceTile.city;

      destinationTile.countryCode = sourceTile.countryCode;

      destinationTile.date = sourceTile.date;

      destinationTile.distance = sourceTile.distance;

      destinationTile.distanceStr = sourceTile.distanceStr;

      destinationTile.dob = sourceTile.dob;

      destinationTile.gender = sourceTile.gender;

      destinationTile.heading = sourceTile.heading;

      destinationTile.heartbeatTime = sourceTile.heartbeatTime;

      destinationTile.id = sourceTile.id;

      destinationTile.primaryImage = sourceTile.primaryImage;

      destinationTile.index = sourceTile.index;

      destinationTile.isImageRendered = sourceTile.isImageRendered;

      destinationTile.isOnline = sourceTile.isOnline;// sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;

      destinationTile.isPremiumMember = sourceTile.isPremiumMember;

      destinationTile.isTileSetupComplete = sourceTile.isTileSetupComplete;

      destinationTile.latitude = sourceTile.latitude;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.link) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationTile.link)) {
        destinationTile.link = sourceTile.link;
      } else {
        destinationTile.link = 'view/';
      }
      destinationTile.longitude = sourceTile.longitude;

      destinationTile.neighborhood = sourceTile.neighborhood;

      destinationTile.pElemId = sourceTile.pElemId;

      destinationTile.position = sourceTile.position;

      destinationTile.privacy = sourceTile.privacy;

      destinationTile.profileId = sourceTile.profileId;

      destinationTile.profileName = sourceTile.profileName;

      destinationTile.pTileElement = sourceTile.pTileElement;

      destinationTile.regionCode = sourceTile.regionCode;

      destinationTile.sexuality = sourceTile.sexuality;

      destinationTile.sitUserId = sourceTile.sitUserId;

      destinationTile.signedInUserId = sourceTile.signedInUserId;

      destinationTile.sitUserKey = sourceTile.sitUserKey;

      destinationTile.signedInUserId = sourceTile.signedInUserId;

      destinationTile.signedInUserKey = sourceTile.signedInUserKey;

      destinationTile.unit = sourceTile.unit;
      destinationTile.weight = sourceTile.weight;

      // must appear at the end but before CopyToKvPhoto() to capture ProfileTile's data
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeat)) {
        if (FrequentlyUsedFunctionsServiceStatic.typeCheck(sourceTile.heartbeat) == 'object' && sourceTile.heartbeat instanceof Heartbeat) {
          // debugger;
          if (sourceTile.heartbeat.heartbeatTime > 0) {
            // debugger;
            destinationTile.heartbeat = destinationTile.heartbeat.copySourceToDestination(destinationTile.heartbeat, sourceTile.heartbeat);
          }
        }
        else {
          // debugger;
          destinationTile.heartbeat = sourceTile.heartbeat
        }
      }
      // must appear at the end to capture ProfileTile's data for KvPhoto
      destinationTile.kvPhoto = new ProfileTile().kvPhoto.copySourceToDestination(destinationTile.kvPhoto, sourceTile.kvPhoto);
    }
    return destinationTile;
  }
  // --------------------------------------------------------

  public copySourceIfExistsToDestination (destinationTile : ProfileTile, sourceTile : ProfileTile) : ProfileTile {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile)) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationTile)) {
        destinationTile = new ProfileTile();
      }
      destinationTile.age = sourceTile.age > 0 ? sourceTile.age : destinationTile.age;

      destinationTile.beginIndex = sourceTile.beginIndex > 0 ? sourceTile.beginIndex : destinationTile.beginIndex;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.bodyhair)) {
        destinationTile.bodyhair = sourceTile.bodyhair;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.built)) {
        destinationTile.built = sourceTile.built;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.city)) {
        destinationTile.city = sourceTile.city;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.countryCode)) {
        destinationTile.countryCode = sourceTile.countryCode;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.cutuncut)) {
        destinationTile.cutuncut = sourceTile.cutuncut;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.date)) {
        destinationTile.date = sourceTile.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.dicksize)) {
        destinationTile.dicksize = sourceTile.dicksize;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distance)) {
        destinationTile.distance = sourceTile.distance;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distanceStr)) {
        destinationTile.distanceStr = sourceTile.distanceStr;
      }

      destinationTile.distanceUnit = new ProfileTile().distanceUnit?.copySourceToDestination(destinationTile.distanceUnit, sourceTile.distanceUnit);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.dob)) {
        destinationTile.dob = sourceTile.dob;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.eyes)) {
        destinationTile.eyes = sourceTile.eyes;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.ethnicity)) {
        destinationTile.ethnicity = sourceTile.ethnicity;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.gender)) {
        destinationTile.gender = sourceTile.gender;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.hair)) {
        destinationTile.hair = sourceTile.hair;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heading)) {
        destinationTile.heading = sourceTile.heading;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeatTime)) {
        destinationTile.heartbeatTime = sourceTile.heartbeatTime;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.height)) {
        destinationTile.height = sourceTile.height;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.id)) {
        destinationTile.id = sourceTile.id;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.primaryImage)) {
        destinationTile.primaryImage = sourceTile.primaryImage;
      }

      destinationTile.index = sourceTile.index > -1 ? sourceTile.index : destinationTile.index;

      destinationTile.isImageRendered = sourceTile.isImageRendered;

      destinationTile.isOnline = sourceTile.isOnline;// sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;

      destinationTile.isPremiumMember = sourceTile.isPremiumMember;

      destinationTile.isTileSetupComplete = sourceTile.isTileSetupComplete;

      destinationTile.latitude = sourceTile.latitude.length > 0 ? sourceTile.latitude : destinationTile.latitude;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.link) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationTile.link)) {
        destinationTile.link = sourceTile.link;
      } else {
        destinationTile.link = 'view/';
      }
      destinationTile.longitude = sourceTile.longitude.length > 0 ? sourceTile.longitude: destinationTile.longitude;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.neighborhood)) {
        destinationTile.neighborhood = sourceTile.neighborhood;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.pElemId)) {
        destinationTile.pElemId = sourceTile.pElemId;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.position)) {
        destinationTile.position = sourceTile.position;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.privacy)) {
        destinationTile.privacy = sourceTile.privacy;
      }

      destinationTile.profileId = sourceTile.profileId > 0 ? sourceTile.profileId : destinationTile.profileId;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.profileName)) {
        destinationTile.profileName = sourceTile.profileName;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.pTileElement)) {
        destinationTile.pTileElement = sourceTile.pTileElement;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.regionCode)) {
        destinationTile.regionCode = sourceTile.regionCode;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.sexuality)) {
        destinationTile.sexuality = sourceTile.sexuality;
      }

      destinationTile.sitUserId = sourceTile.sitUserId > 0 ? sourceTile.sitUserId : destinationTile.sitUserId;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.sitUserKey)) {
        destinationTile.sitUserKey = sourceTile.sitUserKey;
      }

      destinationTile.signedInUserId = sourceTile.signedInUserId > 0 ? sourceTile.signedInUserId: destinationTile.signedInUserId;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.signedInUserKey)) {
        destinationTile.signedInUserKey = sourceTile.signedInUserKey;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.unit)) {
        destinationTile.unit = sourceTile.unit;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.weight)) {
        destinationTile.weight = sourceTile.weight;
      }

      // must appear at the end but before CopyToKvPhoto() to capture ProfileTile's data
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeat)) {
        if (FrequentlyUsedFunctionsServiceStatic.typeCheck(sourceTile.heartbeat) == 'object' && sourceTile.heartbeat instanceof Heartbeat) {
          // debugger;
          if (sourceTile.heartbeat.heartbeatTime > 0) {
            // debugger;
            destinationTile.heartbeat = destinationTile.heartbeat.copySourceToDestination(destinationTile.heartbeat, sourceTile.heartbeat);
          }
        }
        else {
          // debugger;
          destinationTile.heartbeat = sourceTile.heartbeat
        }
      }
      // must appear at the end to capture ProfileTile's data for KvPhoto
      destinationTile.kvPhoto = new ProfileTile().kvPhoto.copySourceToDestination(destinationTile.kvPhoto, sourceTile.kvPhoto);
    }
    return destinationTile;
  }
  // --------------------------------------------------------
  // Note: this is incomplete!!
  // --------------------------------------------------------
  copyIfExists (sourceTile : ProfileTile) : ProfileTile {
    let a : ProfileTile = new ProfileTile();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile)) {
      if (sourceTile.age > 0) {
        a.age = sourceTile.age;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.offOn)) {
        a.offOn = a.offOn.copy(sourceTile.offOn);
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distanceUnit)) {
        a.distanceUnit = new Unit().copySourceToDestination(a.distanceUnit, sourceTile.distanceUnit);
      }

      if (sourceTile.beginIndex && sourceTile.beginIndex > 0) {
        a.beginIndex = sourceTile.beginIndex;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.city) && sourceTile.city.length > 0) {
        a.city = sourceTile.city;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.countryCode) && sourceTile.countryCode.length > 0) {
        a.countryCode = sourceTile.countryCode;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.date) && sourceTile.date.length > 0) {
        a.date = sourceTile.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distance)) {
        a.distance = sourceTile.distance;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distanceStr)) {
        a.distanceStr = sourceTile.distanceStr;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distanceUnit)) {
        a.distanceUnit = sourceTile.distanceUnit;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.dob) && sourceTile.dob != null) {
        a.dob = sourceTile.dob;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.pElemId)) {
        a.pElemId = sourceTile.pElemId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.pTileElement)) {
        a.pTileElement = sourceTile.pTileElement;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.gender) && sourceTile.gender.length > 0) {
        a.gender = sourceTile.gender;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heading) && sourceTile.heading.length > 0) {
        a.heading = sourceTile.heading;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeatTime) && sourceTile.heartbeatTime > 0 )
      {
        a.heartbeatTime = sourceTile.heartbeatTime;
      }      

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.id) && sourceTile.id.length > 0) {
        a.id = sourceTile.id;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.primaryImage) && sourceTile.primaryImage.length > 0) {
        a.primaryImage = sourceTile.primaryImage;
      }
      if (sourceTile.index > -1) {
        a.index = sourceTile.index;
      }

      this.isImageRendered = sourceTile.isImageRendered;
      //if (sourceTile.isImageRendered) {
      //  this.isImageRendered = sourceTile.isImageRendered.toString().toLowerCase().indexOf('true') !== -1;
      //}

      a.isOnline = sourceTile.isOnline;
      //if (sourceTile.isOnline) {
      //  this.isOnline = sourceTile.isOnline;// sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;
      //}

      a.isPremiumMember = sourceTile.isPremiumMember;
      //if (sourceTile.isPremiumMember) {
      //  this.isPremiumMember = sourceTile.isPremiumMember.toString().toLowerCase().indexOf('true') !== -1;
      //}

      a.isTileSetupComplete = sourceTile.isTileSetupComplete;
      //if (sourceTile.isTileSetupComplete) {
      //  a.isTileSetupComplete = sourceTile.isTileSetupComplete.toString().toLowerCase().indexOf('true') !== -1;
      //}
      

      if (sourceTile.latitude != null && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.latitude)) {
        a.latitude = sourceTile.latitude;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.link) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.link)) {
        a.link = sourceTile.link;
      } else {
        a.link = 'view/';
      }

      if (sourceTile.longitude != null && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.longitude)) {
        a.longitude = sourceTile.longitude;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.neighborhood) && sourceTile.neighborhood.length > 0) {
        a.neighborhood = sourceTile.neighborhood;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.pElemId) && sourceTile.pElemId.length > 0) {
        a.pElemId = sourceTile.pElemId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.position) && sourceTile.position.length > 0 && this.position != null) {
        a.position = sourceTile.position;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.profileId) && sourceTile.profileId > 0) {
        a.profileId = sourceTile.profileId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.profileName) && sourceTile.profileName.length > 0) {
        a.profileName = sourceTile.profileName;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.pTileElement)) {
        a.pTileElement = sourceTile.pTileElement;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.regionCode) && sourceTile.regionCode.length > 0) {
        a.regionCode = sourceTile.regionCode;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.sexuality) && sourceTile.sexuality.length > 0) {
        a.sexuality = sourceTile.sexuality;
      }

      if (sourceTile.sitUserId != null && sourceTile.sitUserId > 0) {
        a.sitUserId = sourceTile.sitUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.signedInUserId) && sourceTile.signedInUserId > 0) {
        a.signedInUserId = sourceTile.signedInUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.sitUserKey)) {
        a.sitUserKey = sourceTile.sitUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.signedInUserId) && sourceTile.signedInUserId > 0) {
        a.signedInUserId = sourceTile.signedInUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.signedInUserKey)) {
        this.signedInUserKey = sourceTile.signedInUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.unit)) {
        a.unit = sourceTile.unit;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.weight)) {
        a.weight = sourceTile.weight;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeat)) {
        if (typeof sourceTile.heartbeat == 'object') {
          if (sourceTile.heartbeat.heartbeatTime > 0) {
            a.heartbeat = a.heartbeat.copy(sourceTile.heartbeat);
          }
        }
        else if (typeof sourceTile.heartbeat == 'number') {
          a.heartbeat = sourceTile.heartbeat
        }
      }

      a.copyToKvPhoto(sourceTile.kvPhoto, sourceTile);
    }
    return a;
  }
  // --------------------------------------------------------
  copyToProfileInfo (pTile:ProfileTile) : ProfileInfo {
    const profileInfo = new ProfileInfo();

    // debugger;
    profileInfo.age = pTile.age;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn.away)) {
      profileInfo.away = pTile.offOn.away;
    }
    profileInfo.city = pTile.city;
    profileInfo.countryCode = pTile.countryCode;
    profileInfo.date = pTile.date;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.distance) && pTile.distance > 0) {
      profileInfo.distance = pTile.distance + ' ' + pTile.unit;
    }
    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn.offOnArr) && pTile.offOn.offOnArr.length > 0) {
      profileInfo.distance = pTile.offOn.offOnArr[ 0 ];
    }
    else {
      profileInfo.distance = '0 m';
    }
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfo.distance) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.distanceStr)) {
      profileInfo.distance = pTile.distanceStr;
    }
    profileInfo.dob = pTile.dob;
    profileInfo.gender = pTile.gender;
    profileInfo.heading = pTile.heading;

    // must appear at the end but before CopyToKvPhoto() to capture ProfileTile's data
    //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heartbeat)) {
    //  if (FrequentlyUsedFunctionsServiceStatic.typeCheck(pTile.heartbeat) == 'object' && pTile.heartbeat instanceof Heartbeat) {
    //    // debugger;
    //    if (pTile.heartbeat.heartbeatTime > 0) {
    //      // debugger;
    //      profileInfo.heartbeat = pTile.heartbeat.copy(pTile.heartbeat);
    //    }
    //  }
    //  else {
    //    // debugger;
    //    pTile.heartbeat = this.heartbeat
    //  }
    //}

    // debugger;
    // !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heartbeat.prevHeartbeat) ? pTile.heartbeat.prevHeartbeat.toString() : new Date().toLocaleString();
    profileInfo.heartbeatTime = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heartbeatTime) ? pTile.heartbeatTime.toString() : "0";
      // debugger;

    let tPic : any
    if (pTile.primaryImage) {
      tPic = pTile.primaryImage;
    }
    else if (pTile.kvPhoto && pTile.kvPhoto.value) {
      tPic = pTile.kvPhoto.value;
    }
    else if (pTile.kvPhoto && pTile.kvPhoto.pic) {
      tPic = pTile.kvPhoto.pic;
    }
    profileInfo.image = tPic;
    const pImage = new ProfileImage();

    pImage.image = tPic;
    pImage.sitUserId = pTile.sitUserId;
    pImage.photoId = pTile.photoId > 0 ? pTile.photoId : !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.kvPhoto) ? pTile.kvPhoto.photoId : 0;
    pImage.photoBnId = pTile.photoBnId > 0 ? pTile.photoBnId : !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.kvPhoto) ? pTile.kvPhoto.photoBnId : 0;
    if (profileInfo.profilePics && profileInfo.profilePics.profileImageArr) {
      profileInfo.profilePics.profileImageArr.push(pImage);
    }
    // debugger;
    profileInfo.latitude = pTile.latitude;
    profileInfo.longitude = pTile.longitude;
    profileInfo.neighborhood = pTile.neighborhood;
    profileInfo.position = pTile.position;
    profileInfo.profileId = pTile.profileId;
    profileInfo.profileName = pTile.profileName;
    profileInfo.regionCode = pTile.regionCode;
    profileInfo.sexuality = pTile.sexuality;
    profileInfo.sitUserId = pTile.sitUserId;
    profileInfo.signedInUserId = pTile.signedInUserId;
    profileInfo.sitUserKey = pTile.sitUserKey;
    profileInfo.signedInUserKey = pTile.signedInUserKey;
    profileInfo.unit = pTile.unit;

    // debugger;
    return profileInfo;
  }
  // --------------------------------------------------------
  public copyFromSitUser (sitUser : SitUser, pTile : ProfileTile) : ProfileTile {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      pTile = new ProfileTile();
    }
    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)) {
      // this.age = sitUser.age;
      // this.beginIndex = sourceTile.beginIndex;
      // this.bodyhair = sourceTile.bodyhair;
      // this.built = sourceTile.built;

      // this.city = sitUser.city;
      // this.cutuncut = sourceTile.cutuncut;
      // this.countryCode = sourceTile.countryCode;

      pTile.date = sitUser.date;
      // this.dicksize = sitUser.dicksize;
      // this.distance = sitUser.distance;
      // this.distanceStr = sitUser.distanceStr;
      // this.distanceUnit = new Unit().copySourceToDestination(this.distanceUnit, sourceTile.distanceUnit);
      // this.dob = sitUser.dob;
      // this.ethnicity = sourceTile.ethnicity;
      // this.eyes = sourceTile.eyes;

      // this.gender = sourceTile.gender;
      // this.hair = sitUser.hair;
      // this.heading = sitUser.heading;
      // this.height = sourceTile.height;
      // this.heartbeatTime = sourceTile.heartbeatTime;

      // pTile.id = sitUser.id;

      pTile.primaryImage = sitUser.primaryImage;

      // this.index = sitUser.index;

     //  this.isImageRendered = sourceTile.isImageRendered;

     // this.isOnline = sourceTile.isOnline;// sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;

      // this.isPremiumMember = sitUser.isPremiumMember;

      // this.isTileSetupComplete = sourceTile.isTileSetupComplete;

      pTile.latitude = sitUser.latitude;
      pTile.longitude = sitUser.longitude;

      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.link) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.link)) {
      //  this.link = sourceTile.link;
      //} else {
      //  this.link = 'view/';
      //}

     // this.neighborhood = sitUser.neighborhood;

     // this.pElemId = sitUser.pElemId;

     //  this.position = sourceTile.position;

      // this.privacy = sitUser.privacy;

      pTile.profileId = sitUser.profileId;

      pTile.profileName = sitUser.profileName;

      // this.pTileElement = sitUser.pTileElement;

      // this.regionCode = sourceTile.regionCode;

     //  this.sexuality = sitUser.sexuality;

      pTile.sitUserId = sitUser.sitUserId;

      // this.signedInUserId = sitUser.signedInUserId;

      pTile.sitUserKey = sitUser.sitUserKey;

      // this.signedInUserId = sourceTile.signedInUserId;

      // this.signedInUserKey = sourceTile.signedInUserKey;

      // this.unit = sourceTile.unit;

      // must appear at the end but before CopyToKvPhoto() to capture ProfileTile's data
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeat)) {
      //  if (FrequentlyUsedFunctionsServiceStatic.typeCheck(sourceTile.heartbeat) == 'object' && sourceTile.heartbeat instanceof Heartbeat) {
      //    // debugger;
      //    if (sourceTile.heartbeat.heartbeatTime > 0) {
      //      // debugger;
      //      this.heartbeat = this.heartbeat.copy(sourceTile.heartbeat);
      //    }
      //  }
      //  else {
      //    // debugger;
      //    this.heartbeat = sourceTile.heartbeat
      //  }
      //}
      //// must appear at the end to capture ProfileTile's data for KvPhoto
      //this.copyToKvPhoto(sourceTile.kvPhoto, sourceTile);
    }
    return pTile;
  }
  // --------------------------------------------------------
  public copyFromPerson (p : Person, pTile : ProfileTile) : ProfileTile {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {

      pTile = new ProfileTile();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p)) {
      this.age = p.age;
      // this.beginIndex = p.beginIndex;
      pTile.bodyhair = p.bodyHair;
      pTile.built = p.built;

       // this.city = p.city;
      pTile.cutuncut = p.cutUncut;
       // this.countryCode = p.countryCode;

      pTile.date = p.date;
      pTile.dicksize = p.dickSize;
       // this.distance = p.distance;
       // this.distanceStr = p.distanceStr;
       // this.distanceUnit = new Unit().copySourceToDestination(this.distanceUnit, sourceTile.distanceUnit);
      pTile.dob = p.doB;
      pTile.ethnicity = p.ethnicity;
      pTile.eyes = p.eyes;

      pTile.gender = p.gender;
      pTile.hair = p.hair;
       // this.heading = p.heading;
      pTile.height = p.height;
       // this.heartbeatTime = p.heartbeatTime;

      // pTile.id = sitUser.id;

      // pTile.primaryImage = p.primaryImage;

      // this.index = sitUser.index;

      //  this.isImageRendered = sourceTile.isImageRendered;

      // this.isOnline = sourceTile.isOnline;// sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;

      // this.isPremiumMember = sitUser.isPremiumMember;

      // this.isTileSetupComplete = sourceTile.isTileSetupComplete;

      //pTile.latitude = p.latitude;
      //pTile.longitude = p.longitude;

      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.link) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.link)) {
      //  this.link = sourceTile.link;
      //} else {
      //  this.link = 'view/';
      //}

      // this.neighborhood = sitUser.neighborhood;

      // this.pElemId = sitUser.pElemId;

       // this.position = p.position;

      // this.privacy = sitUser.privacy;

      //pTile.profileId = sitUser.profileId;

      //pTile.profileName = sitUser.profileName;

      // this.pTileElement = sitUser.pTileElement;

      // this.regionCode = sourceTile.regionCode;

      //  this.sexuality = sitUser.sexuality;

      pTile.sitUserId = p.sitUserId;

      // this.signedInUserId = sitUser.signedInUserId;

      pTile.sitUserKey = p.sitUserKey;

      // this.signedInUserId = sourceTile.signedInUserId;

      // this.signedInUserKey = sourceTile.signedInUserKey;

      // this.unit = sourceTile.unit;

      // must appear at the end but before CopyToKvPhoto() to capture ProfileTile's data
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeat)) {
      //  if (FrequentlyUsedFunctionsServiceStatic.typeCheck(sourceTile.heartbeat) == 'object' && sourceTile.heartbeat instanceof Heartbeat) {
      //    // debugger;
      //    if (sourceTile.heartbeat.heartbeatTime > 0) {
      //      // debugger;
      //      this.heartbeat = this.heartbeat.copy(sourceTile.heartbeat);
      //    }
      //  }
      //  else {
      //    // debugger;
      //    this.heartbeat = sourceTile.heartbeat
      //  }
      //}
      //// must appear at the end to capture ProfileTile's data for KvPhoto
      //this.copyToKvPhoto(sourceTile.kvPhoto, sourceTile);
    }
    return pTile;
  }
  // --------------------------------------------------------
  public copyFromProfile (profile : Profile, pTile : ProfileTile) : ProfileTile {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      pTile = new ProfileTile();
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profile)) {
      // pTile.contentId = profile.contentId;
      pTile.position = profile.position;
      pTile.smokingStatus = profile.smokingStatus;

      //pTile.built = profile.built;

      //pTile.city = profile.city;
      //pTile.cutuncut = profile.cutuncut;
      //pTile.countryCode = profile.countryCode;

      //pTile.date = profile.date;
      //pTile.dicksize = profile.dicksize;
      //pTile.distance = profile.distance;
      //pTile.distanceStr = profile.distanceStr;
      // pTile.distanceUnit = new Unit().copySourceToDestination(pTile.distanceUnit, profile.distanceUnit);
      //pTile.dob = profile.dob;
      //pTile.ethnicity = profile.ethnicity;
      //pTile.eyes = profile.eyes;

      //pTile.gender = profile.gender;
      //pTile.hair = profile.hair;
      pTile.heading = profile.heading;
      //pTile.height = profile.height;
      //pTile.heartbeatTime = profile.heartbeatTime;

      // pTile.id = sitUser.id;

      // pTile.primaryImage = profile.primaryImage;

      //pTile.index = sitUser.index;

      //pTile.isImageRendered = sourceTile.isImageRendered;

      //pTile.isOnline = sourceTile.isOnline;// sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;

      // this.isPremiumMember = sitUser.isPremiumMember;

      // this.isTileSetupComplete = sourceTile.isTileSetupComplete;

      //pTile.latitude = profile.latitude;
      //pTile.longitude = profile.longitude;

      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.link) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.link)) {
      //  this.link = sourceTile.link;
      //} else {
      //  this.link = 'view/';
      //}

      //pTile.neighborhood = sitUser.neighborhood;

      //pTile.pElemId = sitUser.pElemId;

      //pTile.position = sourceTile.position;

      //pTile.privacy = sitUser.privacy;

      pTile.profileId = profile.profileId;

      //pTile.profileName = profile.profileName;

      //pTile.pTileElement = sitUser.pTileElement;

      //pTile.regionCode = sourceTile.regionCode;

      //pTile.sexuality = sitUser.sexuality;

      pTile.sitUserId = profile.sitUserId;

      //pTile.signedInUserId = sitUser.signedInUserId;

       pTile.sitUserKey = profile.sitUserKey;

      //pTile.signedInUserId = sourceTile.signedInUserId;

      //pTile.signedInUserKey = sourceTile.signedInUserKey;

      //pTile.unit = sourceTile.unit;

      // must appear at the end but before CopyToKvPhoto() to capture ProfileTile's data
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeat)) {
      //  if (FrequentlyUsedFunctionsServiceStatic.typeCheck(sourceTile.heartbeat) == 'object' && sourceTile.heartbeat instanceof Heartbeat) {
      //    // debugger;
      //    if (sourceTile.heartbeat.heartbeatTime > 0) {
      //      // debugger;
      //      this.heartbeat = this.heartbeat.copy(sourceTile.heartbeat);
      //    }
      //  }
      //  else {
      //    // debugger;
      //    this.heartbeat = sourceTile.heartbeat
      //  }
      //}
      //// must appear at the end to capture ProfileTile's data for KvPhoto
      //this.copyToKvPhoto(sourceTile.kvPhoto, sourceTile);
    }
    return pTile;
  }
  // --------------------------------------------------------
  setupPicImageAndValueOfKvPhoto (kvp : KvPhoto): KvPhoto {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      let isPicEmpty = false;
      // debugger;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.pic)) {
        isPicEmpty = true;
      }

      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.pic)) {
          kvp.value = kvp.pic;
      }
      else if (isPicEmpty) {
        kvp.pic = kvp.value;
      }

      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.pic)) {
        kvp.image = kvp.pic;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value) && kvp.value.indexOf('url(') !== -1) {
        // debugger;
        kvp.value = this.stripUrlForImageData(kvp.value);
      }
    }
    // debugger;
    return kvp;
  }
  // ---------------------------------------------------------------
  //  Note: Important!!!
  //        These methods,-normalizeImageData (), normalizeImageData() and stripUrlForImageData(()
  //        can accomplish almost all the tasks that ImageProcessing will require.
  // ---------------------------------------------------------------
  //  Note: Tested, and works! Date ?, but prior to 20220421
  // ---------------------------------------------------------------------------------
  public stripUrlForImageData (image : string) : any {
    let tImage2 : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      const pattern1 = /url\(\'/gi;
      const tImage = image.replace(pattern1, '');
      const pattern2 = /\'\)/gi;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tImage)) {
        tImage2 = tImage.replace(pattern2, '');
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tImage2)) {
        return tImage2;
      }
      return '';
    }
    return '';
  }
  // ---------------------------------------------------------------------------------
  // --------------------------------------------------------
}
