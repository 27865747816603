'use strict'
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Login } from '../../models/account/login.model';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { ClientData } from '../../models/common/clientData.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { DbDexieService } from './dbDexieService.service';

// ===========================================================================
// Note: Tested, works! May 15, 2020
// Note: LocalStorage's max capacity is 5MB. Hence only credentials
//       and user's profile info are stored there for Pwa.
// Note: Data is NOT encrypted/decrypted by this service-component.
//       However, most of its methods accepts a 'BoxNonce' or 'NaclPairClient' model,
//       and stores the model data after applying JSON.stringify(model),
//       and returns data after applying JSON.parse(model),
// ===========================================================================

@Injectable({
  providedIn: 'any'
})
export class TempFileSaverService implements OnDestroy {
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public clientData: ClientData = new ClientData();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public heartbeat: Heartbeat = new Heartbeat();
  public isMobilevar = false;
  public kvAny: KvAny = new KvAny();
  public login: Login = new Login();
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public message = '';
  public tempFileSubscription!: Subscription;
  public tempFiles: File[] = [];
  public tempFileStringArr: string[] = [];
  constructor (public dbDexieService: DbDexieService
  ) {
    this.initialize ();
  }
  // ---------------------------------------------------------------
  initialize () : void
  {

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.getLoginSuccess ) )
      {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.loginSuccessEmitter ) )
      {
        // debugger;
        EmitterSubjectService.loginSuccessEmitter
          .pipe( takeUntil( this.emitterDestroyed$ ) )
          .subscribe( result =>
          {
            // debugger;
            this.loginSuccess = result;
            this.emitterDestroyed$.next( true );
            this.emitterDestroyed$.complete();
            this.emitterDestroyed$.unsubscribe();
          } );
      }
      this.clientData.id = this.loginSuccess.signedInUserId;
      this.clientData.key = this.loginSuccess.signedInUserId.toString();
      this.clientData.date = DateStringServiceStatic.getTicks( new Date() ).toString();
      // ---------------------------------------------------
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.isMobileEmitter ) )
      {
        EmitterSubjectService.isMobileEmitter
          .pipe( takeUntil( this.emitterDestroyed$ ) )
          .subscribe( result =>
        {
            this.isMobilevar = result;
        } );
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.getIsMobile ) )
      {
        this.isMobilevar = EmitterSubjectService.getIsMobile();
      }
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }

  // ---------------------------------------------------------------
  // getTempFiles(): any {
  //  // debugger;
  //  // Note: this subscriber will emit the data upon receiving
  //  const fileData = this.tempFileSubscriber();
  // }
  // ---------------------------------------------------------------
  // returns an observable
  //getSaltedTempFilesPromise(id: number): any {
  //  if (id > 0) {
  //    // debugger;
      
  //         try {
  //          this.indexeddbService.getAll('TempFile').then(output => {
  //            setTimeout(() => {
  //              // debugger;
  //              subscriber.next(output);
  //              subscriber.complete();
  //            }, 1500);
  //          });
  //         } catch (e) {
  //            subscriber.error(e);
  //         }
  //      }

  //   );

  //    // -----------------------------------------------------------------
  //    /* this.tempFilesForServerObservable = Observable.create((observer) => {
  //      dbService.getAll('TempFile').then(output => {
  //        setTimeout(() => {
  //          //debugger;
  //          observer.next(output);
  //        }, 1500);
  //      });
  //    }, error => {
  //      //debugger;
  //      console.log(error);
  //      return false;
  //    }); */
  //  }
  //}
  // ---------------------------------------------------------------
  salt (model : any): BoxNonceEntity {
    if (model) {
      return this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(JSON.parse(JSON.stringify(model))));
    }
    return new BoxNonceEntity();
  }
  // ---------------------------------------------------------------
  // Note: salted boxNonce is converted into base64-string
  //     so to retrive the file, the indexedDb provided data first need to be JSON.parsed,
  //     and then unsalted.
  // ---------------------------------------------------------------
  saveTempFile(tFile: string) {
    const dbBoxNonxeArr: BoxNonceEntity[] = [];
    let dbBoxNonce: BoxNonceEntity = new BoxNonceEntity();
    dbBoxNonce = this.salt(tFile);

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dbBoxNonce)) {
      dbBoxNonce.id = this.loginSuccess.signedInUserId;
      dbBoxNonce.key = this.loginSuccess.signedInUserId.toString();
      dbBoxNonce.date = DateStringServiceStatic.getTicks(new Date()).toString();
      dbBoxNonxeArr.push(dbBoxNonce);
      dbBoxNonce.entityName = 'tempFile';

      // setup the slave
      // var kvAnyArr = new KvAnyArr();
      // kvAnyArr.modelName = 'TempFile';
      // kvAnyArr.value = dbBoxNonxeArr; // value is the payload of the user (any user)
      // and can possibly the signed-in-user) to be viewd
      // kvAnyArr.key = this.loginSuccess.signedInUserId.toString();
      // key is should be cryptUserId of the user (any user) and can possibly the signed-in-user) to be viewd
      // kvAnyArr.id = this.loginSuccess.signedInUserId;; // id is the id of the user (any user)
      // and can possibly the signed-in-user) to be viewd
      // kvAnyArr.date = DateStringServiceStatic.getTicks(new Date()).toString();

      this.dbDexieService.addModelObservable(dbBoxNonce).subscribe(data => {
        requestAnimationFrame(() => {
          console.log('saveTempFile() is successful for: ' + data);
        });
      });
    }
  }
  // ---------------------------------------------------------------
  saveTempFileBlob(tFileBlob: Blob) {
    const dbBoxNonxeArr: BoxNonceEntity[] = [];
    let dbBoxNonce: BoxNonceEntity = new BoxNonceEntity();
    dbBoxNonce = this.salt(tFileBlob);

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dbBoxNonce)) {
      dbBoxNonce.id = this.loginSuccess.signedInUserId;
      dbBoxNonce.key = this.loginSuccess.signedInUserId.toString();
      dbBoxNonce.date = DateStringServiceStatic.getTicks(new Date()).toString();
      dbBoxNonxeArr.push(dbBoxNonce);
      dbBoxNonce.entityName = 'tempFile';

      // var kvAnyArr = new KvAnyArr();
      // kvAnyArr.modelName = 'TempFile';
      // kvAnyArr.value = dbBoxNonxeArr; // value is the payload of the user (any user)
      // and can possibly the signed-in-user) to be viewd
      // kvAnyArr.key = this.loginSuccess.signedInUserId.toString();
      // key is should be cryptUserId of the user (any user) and can possibly the signed-in-user) to be viewd
      // kvAnyArr.id = this.loginSuccess.signedInUserId;; // id is the id of the user (any user)
      // and can possibly the signed-in-user) to be viewd
      // kvAnyArr.date = DateStringServiceStatic.getTicks(new Date()).toString();

      this.dbDexieService.addModelObservable(dbBoxNonce).subscribe(data => {
        requestAnimationFrame(() => {
          console.log('saveTempFileBlob() is successful for: ' + data);
        });
      });
    }
  }
  // ---------------------------------------------------------------
  // Note: Emits the data upon receiving.
  // tempFileSubscriber(): any {
  //  let model;
  //  this.tempFilesSubscription = this.indexedDbService.tempFileObservable$?.pipe(
  //    map(e => {
  //      // do something with data
  //      // debugger;
  //      model = e as unknown;
  //      this.tempFiles.push(model as File);
  //      EmitterSubjectService.emitFile(this.tempFiles[0]);
  //      return this.tempFiles;
  //      }
  //    ))
  //    .subscribe({
  //      next(output) {
  //        //this.profilePics = output;
  //        // debugger;
  //        return output;//this.tempFiles;
  //      }
  //    });
  // }
  // =================================================================================
}

