
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppFeatureComponent } from './appFeature/appFeature.component';

const appFeatureRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'appfeature', component: AppFeatureComponent, data: { animation: 'register' } },
    ],
  },
];

@NgModule({
  imports: [ RouterModule.forChild(appFeatureRoutes)],
  exports: [RouterModule],
})
export class AppFeatureRoutingModule { }
