export class ProfileImage {
  public approval = '';
  public cryptUserKey = '';
  public date = '';
  public description = '';
  public entityName = '';
  public imageBytes: [] = [];
  public imageFile: any;
  public image = '';
  public imageFromBytes = '';
  public isPrimary = false;
  public photoId = 0;
  public photoBnId = 0;
  public privacy = '';
  public profileId = 0;
  public sitUserId = 0;
  public sitUserKey = '';
  public sourceId = 0;
  public sourceType = '';
  public type: any;
  public title = '';

  constructor() { }

  compareNumbers (a : any, b : any) {
    return a - b;
  }
}
