// import { CryptoModel } from '../crypto/cryptoModel.model';

export class Login {
  public action : any = '';
  public age : any = 0;
  public backupPassword : any = ''; // real-password holder
  public confirmPassword : any = '';
  public controller : any = '';
  public code : any = '';
  public dob : any = 'YYYY-MM-DD';
  public dobTicks : any = 0;
  public email : any = '';
  public emojis : any = '';
  public firstName : any = '';
  public isAllowed: any = false;
  public isDobErr : any = false;
  public isEmailValid: any = false;
  public isFormValid: any = false;
  public isPasswordValid: any = false;
 //  public fWord = ''; // password-mask holder
  public password : any = '';
  public lastName : any = '';
  public latitude : any = '';
  public longitude : any = '';
  public message : any = '';
  public middleName : any = '';
  public sitUserId : any = 0;
  public date : any = '';
  public jsDateForCs : any = '';

  // public crypto: CryptoModel = new CryptoModel();
  constructor() { }
}
