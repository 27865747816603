import { FrequentlyUsedFunctionsServiceStatic } from "../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";


export class Heartbeat {
  public callerName = '';
  public city = '';
  public country = '';
  public date = '';
  public distance = 0;
  public distanceStr = '';
  public gender = '';
  public heartbeatTime = 0;
  public id = 0;
  public isOnline = false;
  public jsDateForCs = '';
  public latitude = 0;
  public longitude = 0;
  public neighborhood = '';
  public regionCode = '';
  public prevHeartbeat = 0;
  public sitUserId = 0;
  public signedInUserId = 0;
  public unit = '';
  constructor () { }

  // Note: CopyServiceStatic has the other copyHeartbeatIfExists(destination, source) : Heartbeat

  public copy (hb : Heartbeat) : Heartbeat | any {
    // debugger;
    if (!this.isNullOrEmpty(hb) && this.typeCheck(hb) === 'object' && hb instanceof Heartbeat) {
      // debugger;
      // if (typeof (this) == 'object') {
      if (this.typeCheck(this) === 'object' && this instanceof Heartbeat) {
        if (!this.isNullOrEmpty(hb.callerName)) {
          this.callerName = hb.callerName;
        }
        if (!this.isNullOrEmpty(hb.city)) {
          this.city = hb.city;
        }
        if (!this.isNullOrEmpty(hb.country)) {
          this.country = hb.country;
        }
        if (!this.isNullOrEmpty(hb.date)) {
          this.date = hb.date;
        }
        if (hb.distance > 0) {
          this.distance = hb.distance;
        }
        if (!this.isNullOrEmpty(hb.distanceStr)) {
          this.distanceStr = hb.distanceStr;
        }
        if (hb.heartbeatTime > 0) {
          this.heartbeatTime = hb.heartbeatTime;
        }
        if (!this.isNullOrEmpty(hb.gender)) {
          this.gender = hb.gender;
        }
        if (hb.id > 0) {
          this.id = hb.id;
        }

        // NOTE: this is very important because we compare it with now to get offOnStatusEnum value;
        if (hb.prevHeartbeat > 0) {
          this.prevHeartbeat = hb.prevHeartbeat;
        }

        this.isOnline = hb.isOnline;

        if (!this.isNullOrEmpty(hb.jsDateForCs)) {
          this.jsDateForCs = hb.jsDateForCs;
        }
        if (hb.latitude !== 0) {
          this.latitude = hb.latitude;
        }
        if (hb.longitude !== 0) {
          this.longitude = hb.longitude;
        }
        if (!this.isNullOrEmpty(hb.neighborhood)) {
          this.neighborhood = hb.neighborhood;
        }
        if (!this.isNullOrEmpty(hb.regionCode)) {
          this.regionCode = hb.regionCode;
        }
        if (hb.sitUserId > 0) {
          this.sitUserId = hb.sitUserId;
        }
      }
      else {
       return hb.heartbeatTime;
      }
    }
    else if (typeof (this) !== 'object') {
      return hb;
      // debugger;
    }    
  }
  public copySourceToDestination (destHb: Heartbeat, hb : Heartbeat) : Heartbeat | any {
    // debugger;
    if (!this.isNullOrEmpty(hb) && this.typeCheck(hb) === 'object' && hb instanceof Heartbeat) {
      // debugger;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destHb)) {
        destHb = new Heartbeat();
			}
      // if (typeof (this) == 'object') {
      if (this.typeCheck(destHb) === 'object' && destHb instanceof Heartbeat) {
        if (!this.isNullOrEmpty(hb.callerName)) {
          destHb.callerName = hb.callerName;
        }
        if (!this.isNullOrEmpty(hb.city)) {
          destHb.city = hb.city;
        }
        if (!this.isNullOrEmpty(hb.country)) {
          destHb.country = hb.country;
        }
        if (!this.isNullOrEmpty(hb.date)) {
          destHb.date = hb.date;
        }
        if (hb.distance > 0) {
          destHb.distance = hb.distance;
        }
        if (!this.isNullOrEmpty(hb.distanceStr)) {
          destHb.distanceStr = hb.distanceStr;
        }
        if (hb.heartbeatTime > 0) {
          destHb.heartbeatTime = hb.heartbeatTime;
        }
        if (!this.isNullOrEmpty(hb.gender)) {
          destHb.gender = hb.gender;
        }
        if (hb.id > 0) {
          destHb.id = hb.id;
        }

        // NOTE: this is very important because we compare it with now to get offOnStatusEnum value;
        if (hb.prevHeartbeat > 0) {
          destHb.prevHeartbeat = hb.prevHeartbeat;
        }

        destHb.isOnline = hb.isOnline;

        if (!this.isNullOrEmpty(hb.jsDateForCs)) {
          destHb.jsDateForCs = hb.jsDateForCs;
        }
        if (hb.latitude !== 0) {
          destHb.latitude = hb.latitude;
        }
        if (hb.longitude !== 0) {
          destHb.longitude = hb.longitude;
        }
        if (!this.isNullOrEmpty(hb.neighborhood)) {
          destHb.neighborhood = hb.neighborhood;
        }
        if (!this.isNullOrEmpty(hb.regionCode)) {
          destHb.regionCode = hb.regionCode;
        }
        if (hb.sitUserId > 0) {
          destHb.sitUserId = hb.sitUserId;
        }
      }
      else {
        return hb.heartbeatTime;
      }
    }
    else if (typeof (this) !== 'object') {
      return destHb;
      // debugger;
    }
  }
  // --------------------------------------------------------------
  public isNullOrEmpty (input : any) : boolean {
    if (input === null || input === '' || input === undefined || (input !== null && (input.toString().toLowerCase() === 'null' || input.toString().toLowerCase() === 'undefined'))) {
      return true;
    }
    return false;
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/4456336/finding-variable-type-in-javascript
   * ---------------------------------------------------------------
   */
  public typeCheck (obj : any) : any {
    if (!this.isNullOrEmpty(obj)) {
      // debugger;
      switch (typeof obj) {
        // object prototypes
        case 'undefined':
          return 'Undefined';
        case 'boolean':
          return 'Boolean';
        case 'number':
          return 'Number';
        case 'string':
          return 'String';
        case 'object':
          if (obj instanceof Array) {
            return 'Array';
          }
          else if (obj instanceof String) {
            return 'String';
          }
          else if (obj instanceof Number) {
            return 'Number';
          }
          else if (obj instanceof Date) {
            return 'Date';
          }
          else if (obj instanceof Object) {
            return 'Object';
          }
          else if (obj instanceof RegExp) {
            return 'RegExp';
          }
          else if (obj instanceof Function) {
            return 'Function';
          }
          else if (obj instanceof Symbol) {
            return 'Symbol';
          }
          else return 'Object';
        // object literals
        default:
          return typeof obj;
      }
    }
  }
  // ---------------------------------------------------------------
}
