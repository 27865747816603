'use-strict'
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AvatarComponent } from './avatar/avatar.component';
import { GlyphiconMembersComponent } from './glyphiconMembers/glyphiconMembers.component';
import { LandingPageComponent } from './landingPage/landingPage.component';
import { LeftRightLogoMenuComponent } from './leftRightLogoMenu/leftRightLogoMenu.component';
import { LogoComponent } from './logo/logo.component';
import { LogoMenuComponent } from './logoMenu/logoMenu.component';
import { LogRegButtonsAndNavArrowsComponent } from './logRegButtonsAndNavArrows/logRegButtonsAndNavArrows.component';
import { MessageBoardComponent } from './messageBoard/messageBoard.component';
import { MyStuffComponent } from './myStuff/myStuff.component';
import { NavHeaderComponent } from './navHeader/navHeader.component';
import { NavSidebarComponent } from './navSidebar/navSidebar.component';


export const appComponentsRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'avatar', component: AvatarComponent },    
      { path: 'glyphiconMembers', component: GlyphiconMembersComponent },
      { path: 'index', component: LandingPageComponent },
      { path: 'leftRightLogoMenu', component: LeftRightLogoMenuComponent },
      { path: 'logo', component: LogoComponent },
      { path: 'logoMenu', component: LogoMenuComponent },
      { path: 'logRegButtonsAndNavArrows', component: LogRegButtonsAndNavArrowsComponent },
      { path: 'messageBoard', component: MessageBoardComponent },
      { path: 'myStuff', component: MyStuffComponent },
      { path: 'navHeader', component: NavHeaderComponent },
      { path: 'navSidebar', component: NavSidebarComponent },
    ],
   },
];

@NgModule( {
  imports: [ RouterModule.forChild( appComponentsRoutes ) ],
  exports: [ RouterModule ],
} )

export class AppComponentsRoutingModule { }


