export class CommunicationMessage {
  public id = 0;
  public date = '';
  public groupId = 0;
  public groupKey = '';
  public messageBody = '';
  public messageBodyShort = '';
  public messageId = 0;
  public receiverEmail = '';
  public senderEmail = '';
  public signedInUserId = 0;
  public signedInUserKey = '';
  public subject = '';
  public subjectShort = '';
  constructor() { }
}
