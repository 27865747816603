import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { PayPalCapture } from './payPalCapture.model';
import { PayPalSellerProtection } from './payPalSellerProtection';

export class PayPalPayment {
  public reference_id = '';
  public payPalCapture : PayPalCapture = new PayPalCapture();
  public payPalSellerProtection : PayPalSellerProtection = new PayPalSellerProtection();
  public date!: Date;
  public sitUserKey = '';
   // ----------------------------------------------------------------------------------
  constructor() {
    this.date = new Date;
  }
   // ----------------------------------------------------------------------------------
  public copy ( from: PayPalPayment ): void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.reference_id = from.reference_id;
      this.payPalCapture.copy( from.payPalCapture );
      this.payPalSellerProtection.copy(from.payPalSellerProtection);
      this.date = from.date;
      this.sitUserKey = from.sitUserKey;
    }
  }
   // ----------------------------------------------------------------------------------
  public toStringCsv ()
  {
    let output = '';
    output = this.reference_id + ',' + this.date + ', ' + this.payPalCapture.toString() + ', ' + this.payPalSellerProtection.toString() + ',' + this.sitUserKey;
    return output;
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n reference_id: ' +
      this.reference_id +

      ',\n date: ' +
      this.date +

      ',\n' +
      'paypalCapture:' +
      this.payPalCapture.toString() +


      ',\n' +
      'payPalSellerProtection:' +
      this.payPalSellerProtection.toString() +


      ',\n' +
      'sitUserKey:' +
      this.sitUserKey +

      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
