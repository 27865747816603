
import { Injectable } from '@angular/core';
import { DbDexieService } from './dbDexieService.service';
import { DbDexieToDictionaryService } from './dbDexieToDictionaryService.service';
import { LocalStorageService } from './localStorageService.service';
import { TempFileSaverService } from './tempFileSaverService.service';
import { DictionaryIOService } from '../dictionaryServiceService/dictionaryIOService.service';

@Injectable({
  providedIn: 'root',
})
export class DbServiceService {
  constructor (public dbDexieToDictionaryService : DbDexieToDictionaryService,
    private dbDexieService : DbDexieService,
    private dictionaryIOService: DictionaryIOService,
    private localStorageService: LocalStorageService,
    private tempFileSaverService: TempFileSaverService,
  )
  {
  } 
  // ------------------------------------------------------------------
  setLocalStorageService(ls: LocalStorageService): void {
    this.localStorageService = ls;
  }
  // -----------------------------------------------------------------
  getLocalStorageService(): LocalStorageService {
    return this.localStorageService;
  }
  // ------------------------------------------------------------------
  setTempFileSaverService(tfss: TempFileSaverService): void {
    this.tempFileSaverService = tfss;
  }
  // -----------------------------------------------------------------
  getTempFileSaverService(): TempFileSaverService {
    return this.tempFileSaverService;
  }
  // ------------------------------------------------------------------
  setDbDexieService (ds : DbDexieService): void {
    this.dbDexieService = ds;
    return;
   }
  getDbDexieService () : DbDexieService {
    return this.dbDexieService;
  }
  // ------------------------------------------------------------------
  setDictionaryIOService (ds : DictionaryIOService) : void {
    this.dictionaryIOService = ds;
    return;
  }
  getDictionaryIOService () : DictionaryIOService {
    return this.dictionaryIOService;
  }
  // ------------------------------------------------------------------
  setDbDexieToDictionaryService (indxs : DbDexieToDictionaryService ) : void
   {
    this.dbDexieToDictionaryService = indxs;
   return;
   }
  getDbDexieToDictionaryService () : DbDexieToDictionaryService
   {
    return this.dbDexieToDictionaryService;
   }
  // ------------------------------------------------------------------
}
