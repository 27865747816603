'use-strict'
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { PagerPhotoMgmt } from '../../../models/pagination/pagerPhotoMgmt.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Animations, slideInOutAnimation } from '../../animation';

@Component({
  selector: 'app-incrementor',
  templateUrl: './incrementor.component.html',
  styleUrls: [ './incrementor.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncrementorComponent implements OnInit, OnDestroy, AfterViewInit
{
  @Input() pageSize = 6; 
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMobilevar! : boolean;
  public kvPhotoArr : KvPhoto[] = [];
  public loginSuccess! : LoginSuccess;
  public pagerVPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  // ---------------------------------------------------------------
  constructor (
    public cdr : ChangeDetectorRef,
    private coreServiceService : CoreServiceService,
    @Inject(DOCUMENT) document:any)
  {
  }
  ngOnInit ()
  {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.ngAfterViewInit();
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit ()
  {
    // debugger;
    this.cdr.detectChanges();
    // debugger;
    this.kvPhotoArr = EmitterSubjectService.getProfileImages();
    this.pagerVPhotoNav = EmitterSubjectService.getPagerPhotoMgmt();
    EmitterSubjectService.emitRunNgAfterViewInit(true);
  }
  // ---------------------------------------------------------------
  ngOnDestroy ()
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  // ---------------------------------------------------------
  public onPageSizeChange (event : (Event | KeyboardEvent), upOrDown : string) {
    // debugger;
    if (upOrDown.indexOf('up') !== -1) {
      if (this.kvPhotoArr.length > this.pageSize && this.pageSize < 11) {
        this.pageSize++;
      }
    }
    else {     
      if (this.pageSize < 11 && this.pageSize > 3) {
        this.pageSize--;
      }
    }
    // debugger;
    EmitterSubjectService.setPageSize(this.pageSize);
    EmitterSubjectService.emitPageSize(this.pageSize);
    this.ngAfterViewInit();
   
  }   
  // ---------------------------------------------------------------
}
