'use-strict'
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Animations, slideInOutAnimation } from '../../../app/animation';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';

@Component({
  selector: 'app-glyphicon-members',
  templateUrl: './glyphiconMembers.component.html',
  styleUrls: ['./glyphiconMembers.component.scss'],
  animations: [
    Animations, slideInOutAnimation
    /*
     * trigger('slideInOut', [
     * transition(':enter', [
     *   //style({ transform : 'translateX(-100%)' }),
     *   style({ transform : 'translateY(-100%)' }),
     *   //style({ transform : 'scale(0.1)'}),
     *   //animate('500ms ease-in', style({ transform : 'translateX(0%)' })),
     *   animate('1000ms ease-out', style({ transform : 'translateY(0%) rotateY(360deg)' })),
     *   //animate('500ms ease-in', style({ transform : 'scale(1)' }))
     * ]),
     * transition(':leave', [
     *   //animate('500ms ease-in', style({ transform : 'translateX(-100%)' })),
     *   animate('1000ms ease-out', style({ transform : 'translateY(-100%)' })),
     *   //animate('500ms ease-in', style({ transform : 'scale(0.1)' }))
     * ])
     * ])
     */

    /*
     * trigger('slideInOut', [
     *   transition(':enter', [
     *     style({ transform : 'scale(0.1)' }),
     *     animate('500ms ease-in', style({ transform : 'scale(1)' }))
     *   ]),
     *   transition(':leave', [
     *     animate('500ms ease-in', style({ transform : 'scale(0.1)' }))
     *   ])
     * ])
     */
  ],
})
export class GlyphiconMembersComponent implements OnInit, OnDestroy {
  public isDropdownOpen = false;
  public isMembersOpen = false;
  public isMobile = false;
  public dropdownItems: string[] = ['Your', 'First', 'Angular', 'Animation', 'With', 'Parameters'];
  public glyphs: number[] = [1, 2, 3, 4];
  private emitterDestroyed$: Subject<boolean> = new Subject();

  constructor(private activatedRoute: ActivatedRoute, private coreServiceService: CoreServiceService) { }

  ngOnInit() {
    EmitterSubjectService.isMobileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      this.isMobile = result;
    });
    this.isMobile = EmitterSubjectService.getIsMobile();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
}
