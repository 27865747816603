
import { Injectable, OnDestroy, OnInit } from "@angular/core";
import Dexie, { Table } from 'dexie';
import { Observable, Subject } from "rxjs";
import { db } from "../../db";
import { BinarySearchService } from "../searchSortServiceService/binarySearchService.service";
import { FrequentlyUsedFunctionsServiceStatic } from "../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";

/*************************************************************************/
/** Note: the declaration of the ObjectStores is in db,ts that HAS to be**/
/**       in the src folder i.e. where package.json is declared.        **/
/*************************************************************************/


/***
 * ref: https://www.npmjs.com/package/dexie
 * Supported operations:
 * ---------------------
 above(key): Collection;
aboveOrEqual(key): Collection;
add(item, key?): Promise;
and(filter: (x) => boolean): Collection;
anyOf(keys[]): Collection;
anyOfIgnoreCase(keys: string[]): Collection;
below(key): Collection;
belowOrEqual(key): Collection;
between(lower, upper, includeLower?, includeUpper?): Collection;
bulkAdd(items: Array): Promise;
bulkDelete(keys: Array): Promise;
bulkPut(items: Array): Promise;
clear(): Promise;
count(): Promise;
delete(key): Promise;
distinct(): Collection;
each(callback: (obj) => any): Promise;
eachKey(callback: (key) => any): Promise;
eachPrimaryKey(callback: (key) => any): Promise;
eachUniqueKey(callback: (key) => any): Promise;
equals(key): Collection;
equalsIgnoreCase(key): Collection;
filter(fn: (obj) => boolean): Collection;
first(): Promise;
get(key): Promise;
inAnyRange(ranges): Collection;
keys(): Promise;
last(): Promise;
limit(n: number): Collection;
modify(changeCallback: (obj: T, ctx:{value: T}) => void): Promise;
modify(changes: { [keyPath: string]: any } ): Promise;
noneOf(keys: Array): Collection;
notEqual(key): Collection;
offset(n: number): Collection;
or(indexOrPrimayKey: string): WhereClause;
orderBy(index: string): Collection;
primaryKeys(): Promise;
put(item: T, key?: Key): Promise;
reverse(): Collection;
sortBy(keyPath: string): Promise;
startsWith(key: string): Collection;
startsWithAnyOf(prefixes: string[]): Collection;
startsWithAnyOfIgnoreCase(prefixes: string[]): Collection;
startsWithIgnoreCase(key: string): Collection;
toArray(): Promise;
toCollection(): Collection;
uniqueKeys(): Promise;
until(filter: (value) => boolean, includeStopEntry?: boolean): Collection;
update(key: Key, changes: { [keyPath: string]: any }): Promise;
***/

@Injectable({ providedIn: 'root' })

export class DbDexieService implements OnInit, OnDestroy {
  // our Database constructor sets up an IndexedDB database with a
  // sticky notes object store, titled "notes".

  public _db : any;
  public oldVersion : number = 0;
  public dexieTableObjectStore : any; // Note: the objectStores has a post-fix of 'I' because they are derived from respective interfaces
  public dTable! : Table<any, string>;
  private emitterDestroyed$ : Subject<boolean> = new Subject();

  public objectStores : any;
  public objectStoreNameArr : string[] = [];
  public message = '';
  // ------------------------------------
  // begin of dbDexie-code:
  // ------------------------------------
  //public buddyObjectStore! : Table<string, number>;
  //public chatHistoryObjectStore! : Table<string, number>;
  //public chatHistoryIdObjectStore! : Table<string, number>;
  //public emailTileObjectStore! : Table<string, number>;
  //public emailTileIdObjectStore! : Table<string, number>;
  //public enoteTileObjectStore! : Table<string, number>;
  //public enoteTileIdObjectStore! : Table<string, number>;
  //public errorLogObjectStore! : Table<string, number>;
  //public kvPhotoObjectStore! : Table<string, number>;
  //public loginSuccessObjectStore! : Table<string, number>;
  //public myBlockObjectStore! : Table<string, number>;
  //public myChatObjectStore! : Table<string, number>;
  //public myChatIdObjectStore! : Table<string, number>;
  //public myEmailObjectStore! : Table<string, number>;
  //public myEmailIdObjectStore! : Table<string, number>;
  //public myFriendObjectStore! : Table<string, number>;
  //public myLikeObjectStore! : Table<string, number>;
  //public myMemberActivityObjectStore! : Table<string, number>;
  //public myMemberLockingActivityObjectStore! : Table<string, number>;
  //public myMemberStarTrackWinkActivityObjectStore! : Table<string, number>;
  //public myStarObjectStore! : Table<string, number>;
  //public myTrackObjectStore! : Table<string, number>;
  //public myWinkObjectStore! : Table<string, number>;
  //public myUnlockObjectStore! : Table<string, number>;
  //public myNotificationObjectStore! : Table<string, number>;
  //public myNotificationIdObjectStore! : Table<string, number>;
  //public photoObjectStore! : Table<string, number>;
  //public photoBnIdObjectStore! : Table<string, number>;
  //// public photoBnObjectStore! : Table<string, number>;
  //public preferenceObjectStore! : Table<string, number>;
  //public profileTileObjectStore! : Table<string, number>;
  //public profileInfoObjectStore! : Table<string, number>;
  //// public profilePicsObjectStore! : Table<string, number>;
  //public sitUserObjectStore! : Table<string, number>;
  //public sitUserIdObjectStore! : Table<string, number>;
  //public yourBlockObjectStore! : Table<string, number>;
  //public yourFriendObjectStore! : Table<string, number>;
  //public yourLikeObjectStore! : Table<string, number>;
  //public yourMemberActivityObjectStore! : Table<string, number>;
  //public yourMemberLockingActivityObjectStore! : Table<string, number>;
  //public yourMemberStarTrackWinkActivityObjectStore! : Table<string, number>;
  //public yourStarObjectStore! : Table<string, number>;
  //public yourTrackObjectStore! : Table<string, number>;
  //public yourWinkObjectStore! : Table<string, number>;
  //public yourUnlockObjectStore! : Table<string, number>;
  // end of dbDexie-code
  // ----------------------------------
  public versionCounter = 1;
  constructor (    
    private binarySearchService : BinarySearchService,) {
    this._db = db;

    // this = new Dexie(ConstantServiceStatic.dbName);

    //this.setupDictionaries(ConstantServiceStatic.version);
    // debugger;
    ////this.on('error', function (e) {
    ////  switch (e.name) {
    ////    // errnames.Constraint ==="ConstraintError"
    ////    case Dexie.errnames.Constraint:
    ////      console.error("Constraint error");
    ////      break;
    ////    default:
    ////      console.error("error: " + e);
    ////  }
    ////});
    //debugger;
  }

  ngOnInit () {
    debugger;

    // -----------------------------------------------------
    // DictionaryServiceStatic.populateAllDictionaries();
    // debugger;

  }
  //  ---------------------------------------------------------------
  ngOnDestroy () {
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    // debugger;
  }
 
  // -------------------------------------------------------------------
  // NOte: this method assumes that the particular ObjectStore exists.
  // -------------------------------------------------------------------
  public countObjectStoreItems (entityName : string) : Observable<any> | any {
    // debugger;

    return new Observable<any>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)) {
        debugger;
        var count = this.getTableByEntityName(entityName).count();
        debugger;
        subscriber.next(count);
      }
    })
  }
  // -------------------------------------------------------------------
  // Note:  this method will also check if a particular ObjectStore
  //        exists or not and if exists, return a promise with the count of the ObjectStore
  // -------------------------------------------------------------------
  public doesStoreHaveData (entityName : string) : Observable<any> | any {
    // debugger;    
    return new Observable<any>((subscriber) => {
      let key : string = '';
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)) {
        // debugger;
        //let table : Dexie.Table<string, number>;
        //table = this.getTable(entityName + 'ObjectStore');
        debugger;
         
          //this.countObjectStoreItems(entityName).subscribe(count => {
          //  debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(db)) {
          debugger;
          db.table(entityName + 'ObjectStore').toArray().then(data => {
            debugger;
            if (data.length > 0) {
              debugger;
              subscriber.next(true);
            }
            else {
              debugger;
              subscriber.next(false);
            }
            subscriber.complete();
          })
        }
      }
    })
  }

  // -------------------------------------------------------
  public getTableByEntityName (entityName : string) : any {
     debugger;
    let entityTable : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)) {
      for (var i = 0; i < this._db.tables.length; i++) {
        if (this._db.tables[ i ].name.indexOf(entityName + 'ObjectStore') !== -1) {
          debugger;
          entityTable = this._db.tables[ i ];
          break;
        }
      }
      return entityTable;
    }
  }  // ---------------------------------------------------------------
  public isFoundInStore (namedObjectStore, key) : boolean {
    var comparator = ((a, b) => {
      return a - b;
    });

    var num = this.binarySearchService.isFound(namedObjectStore.keys, key, comparator);

    return num === 0 ? true : false;
  }
  // ---------------------------------------------------------------
  public isObjectStoreNameFoundInArr (name : string) : boolean {
    var isFound = false;
    this.objectStoreNameArr.map(e => {
      if (e.indexOf(name) !== -1) {
        isFound = true;
      }
    })
    return isFound;
  }
  // ---------------------------------------------------------------
  public isObjectStoreNameFoundInTables (name : string) : boolean {
    var isFound = false;
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this._db) && this._db.tables.length > 0)
      for (var i = 0; i < this._db.tables.length; i++) {
        if (this._db.tables[ i ].name.indexOf(name) !== -1) {
          isFound = true;
          // debugger;
          break;
        }
      }
    return isFound;
  }
  // ---------------------------------------------------------------
  // ref: https://dzone.com/articles/dexis-js-holistic-guide
  // ---------------------------------------------------------------
  public getTable<T, IndexableType> (schema : string) : Table<T, IndexableType> {
    return db.table(schema + 'ObjectStore');
  }
  // ---------------------------------------------------------------
  // ref: https://dzone.com/articles/dexis-js-holistic-guide
  // ---------------------------------------------------------------
  public updateObjectStoreData (model : any) : Observable<any>| any {
    // debugger;    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      return new Observable(subscriber => {
        let result : any;
        // debugger;
        let table : Dexie.Table<string, number>; 
        table = this.getTable(model.entityName);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(table)) {
          if (model.id > 0) {
            table.put(model, model.id).then(keys => {
              // debugger;
              return subscriber.next(table.get(model.key));
            })
          }
        }
        else {
          console.log('dbDexieService->table not found: ' + model.entityName);
        }
      });
    }   
  }
  // ---------------------------------------------------------------
  public addModelObservable (model : any) : Observable<any> {
    return this.updateObjectStoreData(model);
  }
  // ---------------------------------------------------------------
  // ref: https://dzone.com/articles/dexis-js-holistic-guide
  // ---------------------------------------------------------------
  public getStoreData (entityName : any,  key: string) : Observable<any> {
    // debugger;
    return new Observable<any>((subscriber) => {
      let tableName = entityName; // oModel == observablesModel
      let tkey = key;
      this.message = 'store-data have been successfully fetched.';
      let result : any;
      let date = new Date();
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tableName)) {
        // debugger;
        let table : Dexie.Table<string, number> = this.getTable(tableName);
        // debugger;
        console.log('timestamp: ' + new Date().getTime() + '->in getStoreData(). tableName: ' + tableName + ' key: ' + tkey);

        table.get({ key: tkey }).then(data => {
          // debugger;
          console.log('timestamp: ' + new Date().getTime() + '->in getStoreData(). tableName: ' + tableName + ' key: ' + key + ' is successfull. Data: ' + data.toString());
          subscriber.next(data);
        })        
      }
      else {
        this.message = date.getTime() + ' getStoreData.doesStoreHaveData() returned a count that was 0 or null or undefined result.';
        console.log(this.message);
      }
    });
  }
}
        // ==========================================================
        //switch (model?.entityName?.toLowerCase()) {
        //  case 'chathistoryid':
        //    //objectStores.chatHistoryIdObjectStore?.count().then(async (c : any) => {
        //    //   debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.chatHistoryIdObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.chatHistoryIdObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.chatHistoryIdObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    // }
        //    // result = await this.chatHistoryIdObjectStore?.get(model.id);
        //    break;

        //  case 'chathistory':
        //    /*objectStores.chatHistoryObjectStore?.count().then(async (c : any) => {*/
        //    // debugger;
        //    /* if (c > 0) {*/
        //    // debugger;
        //    this._db.chatHistoryObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.chatHistoryObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    /* }*/
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.chatHistoryObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    //result = await this.chatHistoryObjectStore?.get(model.id);
        //    break;

        //  case 'emailtile':
        //    //objectStores.eMailTileObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.eMailTileObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.eMailTileObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.eMailTileObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    break;

        //  case 'emailtileid':
        //    //objectStores.eMailTileIdObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.eMailTileIdObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.eMailTileIdObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.eMailTileIdObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    break;

        //  //case 'enotetileid':
        //  //  objectStores.eNoteTileObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.eNoteTileObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.eNoteTile.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.eNoteTile.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.enoteTileIdObjectStore?.get(model.id);
        //  //  break;

        //  //case 'enotetile':
        //  //  objectStores.eNoteTileIdObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.eNoteTileIdObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.eNoteTileIdObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.eNoteTileIdObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.enoteTileObjectStore?.get(model.id);
        //  //  break;

        //  //case 'errorlog':
        //  //  objectStores.errorLogObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.errorLogObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.errorLogObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.errorLogObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.errorLogObjectStore?.get(model.id);
        //  //  break;

        //  case 'kvphoto':
        //    //objectStores.kvPhotodObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.kvPhotodObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.kvPhotodObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.kvPhotodObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    // result = await this.kvPhotodObjectStore?.get(model.id);
        //    break;

        //  case 'loginsuccess':
        //    //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccessObjectStore)) {
        //    //  this.stores({
        //    //    loginSuccessObjectStore: 'id, bneStr, key',
        //    //  });

        //    //}
        //    debugger;
        //    this.updateObjectStoreData(model).subscribe(
        //      val => { console.log(val) }, //next callback
        //      error => { console.log("error") }, //error callback
        //      () => { console.log("Completed") } //complete callback
        //    );
        //    debugger;
        //    //else {
        //    //  this.loginSuccessObjectStore?.count().then(async (c : any) => {
        //    //    // debugger;
        //    //    if (c > 0) {
        //    //      // debugger;
        //    //      await this.loginSuccessObjectStore?.get(model.id).then((data : any) => {
        //    //        // debugger;
        //    //        result = data;
        //    //      }).catch((error : any) => {
        //    //        this.message = date.getTime() + ' dbDexieService.db.loginSuccessObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //    //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //        console.log(this.message);
        //    //        // debugger;
        //    //      });
        //    //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //      //.toArray();
        //    //    }
        //    //  }).catch((error : any) => {
        //    //    this.message = date.getTime() + ' dbDexieService.db.loginSuccessObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //    console.log(this.message);
        //    //  });
        //    //}
        //    // result = await this.loginSuccessObjectStore?.get(model.id);
        //    break;
        
        //  //case 'myblock':
        //  //  objectStores.myBlockObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myBlockObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myBlockObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myBlockObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myBlockObjectStore?.get(model.key);
        //  //  break;

        //  //case 'mychatid':
        //  //  objectStores.myChatIdObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myChatIdObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myChatIdObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myChatIdObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myChatIdObjectStore?.get(model.key);
        //  //  break;

        //  //case 'mychat':
        //  //  objectStores.myChatObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myChatObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myChatObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myChatObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myChatObjectStore?.get(model.key);
        //  //  break;

        //  //case 'myemailid':
        //  //  objectStores.myEmailIdObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myEmailIdObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myWmailIdObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myEmailIdObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myEmailIdObjectStore?.get(model.key);
        //  //  //.where(model.id).between(1, this.myEmailTable.count(), true, true)
        //  //  //.toArray();
        //  //  break;

        //  //case 'myemail':
        //  //  objectStores.myEmailObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myEmailObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myEmailObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myEmailObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  //result = await this.myEmailObjectStore?.get(model.id);
        //  //  //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //  //.toArray();
        //  //  break;

        //  //case 'myfriend':
        //  //  objectStores.myFriendObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myFriendObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myFriendObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myFriendObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myFriendObjectStore?.get(model.key);
        //  //  break;

        //  //case 'mylike':
        //  //  objectStores.myLikeObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myLikeObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myLikeObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myLikeObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myLikeObjectStore?.get(model.key);
        //  //  break;

        //  //case 'mymemberactivity':
        //  //  objectStores.myMemberActivityObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myMemberActivityObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myMemberActivityObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myMemberActivityObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myMemberActivityObjectStore?.get(model.id);
        //  //  break;

        //  //case 'mymemberlockingactivity':
        //  //  objectStores.myMemberLockingActivityObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myMemberLockingActivityObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myMemberLockingActivityObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myMemberLockingActivityObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myMemberLockingActivityObjectStore?.get(model.id);
        //  //  break;

        //  //case 'mymemberstartrackwinkactivity':
        //  //  objectStores.myMemberStarTrackWinkActivityObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myMemberStarTrackWinkActivityObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myMemberStarTrackWinkActivityObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myMemberStarTrackWinkActivityObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myMemberStarTrackWinkActivityObjectStore?.get(model.id);
        //  //  break;

        //  //case 'mynotificationId':
        //  //  objectStores.myNotificationIdObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myNotificationIdObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db..myNotificationIdObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db..myNotificationIdObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myNotificationIdObjectStore?.get(model.id);
        //  //  break;

        //  //case 'mynotification':
        //  //  objectStores.myNotificationObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myNotificationObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db..myNotificationObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db..myNotificationObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  //result = await this.myNotificationObjectStore?.get(model.id);
        //  //  break;

        //  //case 'mystar':
        //  //  objectStores.myStarObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myStarObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myStarObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myStarObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myStarObjectStore?.get(model.key);
        //  //  break;

        //  //case 'mytrack':
        //  //  objectStores.myTrackObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myTrackObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myTrackObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myTrackObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myTrackObjectStore?.get(model.key);
        //  //  break;

        //  //case 'myunlock':
        //  //  objectStores.myUnlockObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myUnlockObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myUnlockObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myUnlockObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myUnlockObjectStore?.get(model.key);
        //  //  break;

        //  //case 'mywink':
        //  //  objectStores.myWinkObjectStore?.count().then(async (c : any) => {
        //  //    // debugger;
        //  //    if (c > 0) {
        //  //      // debugger;
        //  //      await objectStores.myWinkObjectStore?.get(model.id).then((data : any) => {
        //  //        // debugger;
        //  //        result = data;
        //  //      }).catch((error : any) => {
        //  //        this.message = date.getTime() + ' dbDexieService.db.myWinkObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //  //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //        console.log(this.message);
        //  //        // debugger;
        //  //      });
        //  //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //  //      //.toArray();
        //  //    }
        //  //  }).catch((error : any) => {
        //  //    this.message = date.getTime() + ' dbDexieService.db.myWinkObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //  //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //  //    console.log(this.message);
        //  //  });
        //  //  // result = await this.myWinkObjectStore?.get(model.key);
        //  //  break;

        //  case 'photo':
        //    //objectStores.photoObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.photoObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.photoObjectStore.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.photoObjectStore.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    // result = await this.photoObjectStore?.get(model.id);
        //    break;

        //  case 'photobn':
        //    //objectStores.photoBnObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.photoBnObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.photoBnObjectStore.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.photoBnObjectStore.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    break;

        //  case 'photobnid':
        //    //objectStores.photoBnIdObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.photoBnIdObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.photoBnIdObjectStore.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.photoBnIdObjectStore.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    break;

        //  case 'preference':
        //    //objectStores.preferenceObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.preferenceObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.preferenceObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.preferenceObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    // result = await this.preferenceObjectStore?.get(model.id);
        //    break;

        //  case 'profileInfo':
        //    //objectStores.profileInfoObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.profileInfoObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.profileInfoObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.profileInfoObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    // result = await this.profileInfoObjectStore?.get(model.id);
        //    break;

        //  case 'profilePics':
        //    //objectStores.profilePicsObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    // debugger;
        //    this._db.profilePicsDObjectStore?.get(model.id).then((data : any) => {
        //      // debugger;
        //      result = data;
        //    }).catch((error : any) => {
        //      this.message = date.getTime() + ' dbDexieService.db.profilePicsDObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      console.log(this.message);
        //      // debugger;
        //    });
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.profilePicsDObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    // result = await this.profilePicsObjectStore?.get(model.id);
        //    break;

        //  case 'profileTile':
        //    //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTileObjectStore)) {
        //    //  this.stores({
        //    //    profileTileObjectStore: 'id, bneStr, key',
        //    //  });

        //    //}
        //    debugger;
        //    this.updateObjectStoreData(model.id).subscribe(
        //      val => { console.log(val) }, //next callback
        //      error => { console.log("error") }, //error callback
        //      () => { console.log("Completed") } //complete callback
        //    );

        //    debugger;
        //    // this.profileTileObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    //    // debugger;
        //    //    await this.profileTileObjectStore?.get(model.id).then((data : any) => {
        //    //      // debugger;
        //    //      result = data;
        //    //    }).catch((error : any) => {
        //    //      this.message = date.getTime() + ' dbDexieService.db.profileTileObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //    //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //      console.log(this.message);
        //    //      // debugger;
        //    //    });
        //    //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.profileTileObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});

        //    break;

        //  case 'situserid':
        //    debugger;
        //    //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUserIdObjectStore)) {

        //    //  this.stores({
        //    //    sitUserIdObjectStore: 'id, bneStr, key',
        //    //  });
        //    //}
        //    debugger;
        //    result = this.updateObjectStoreData(model).subscribe(
        //      val => { console.log(val) }, //next callback
        //      error => { console.log("error") }, //error callback
        //      () => { console.log("Completed") } //complete callback
        //    );
        //    debugger;
        //    //this.profileTileObjectStore?.count().then(async (c : any) => {
        //    //  // debugger;
        //    //  if (c > 0) {
        //    //    // debugger;
        //    //    await this.profileTileObjectStore?.get(model.id).then((data : any) => {
        //    //      // debugger;
        //    //      result = data;
        //    //    }).catch((error : any) => {
        //    //      this.message = date.getTime() + ' dbDexieService.db.profileTileObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //    //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //      console.log(this.message);
        //    //      // debugger;
        //    //    });
        //    //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //    //.toArray();
        //    //  }
        //    //}).catch((error : any) => {
        //    //  this.message = date.getTime() + ' dbDexieService.db.profileTileObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //  console.log(this.message);
        //    //});
        //    //}
        //    //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUserIdObjectStore)) {
        //    //  this.sitUserIdObjectStore?.count().then(async (c : any) => {
        //    //    // debugger;
        //    //    if (c > 0) {
        //    //      // debugger;
        //    //      await this.sitUserIdObjectStore?.get(model.id).then((data : any) => {
        //    //        // debugger;
        //    //        result = data;
        //    //      }).catch((error : any) => {
        //    //        this.message = date.getTime() + ' dbDexieService.db.sitUserIdObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //    //        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //        console.log(this.message);
        //    //        // debugger;
        //    //      });
        //    //      //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //      //.toArray();
        //    //    }
        //    //  }).catch((error : any) => {
        //    //    this.message = date.getTime() + ' dbDexieService.db.sitUserIdObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //    //    // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //    //    console.log(this.message);
        //    //  });
        //    //}
        //    // result = await this.sitUserIdObjectStore?.get(model.id);
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    break;

        //  case 'situser':
        //    debugger;
        //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this._db.sitUserObjectStore)) {
        //      debugger;
        //      result = this.updateObjectStoreData(model).subscribe(
        //        val => { console.log(val) }, //next callback
        //        error => { console.log("error") }, //error callback
        //        () => { console.log("Completed") } //complete callback
        //      );
        //      debugger;
        //      //this.sitUserObjectStore?.count().then(async (c : any) => {
        //      //  // debugger;
        //      //  if (c > 0) {
        //      //    // debugger;
        //      //    await this.sitUserObjectStore?.get(model.id).then((data : any) => {
        //      //      // debugger;
        //      //      result = data;
        //      //    }).catch((error : any) => {
        //      //      this.message = date.getTime() + ' dbDexieService.db.sitUserObjectStore?.get() returned a null or undefined result. Error-mag: ' + error;
        //      //      // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      //      console.log(this.message);
        //      //      // debugger;
        //      //    });
        //      //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //      //    //.toArray();
        //      //  }
        //      //}).catch((error : any) => {
        //      //  this.message = date.getTime() + ' dbDexieService.db.sitUserObjectStore?.count() returned a null or undefined result. Error-mag: ' + error;
        //      //  // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        //      //  console.log(this.message);
        //      //});
        //    }
        //    // result = await this.sitUserObjectStore?.get(model.id);
        //    //.where(model.id).between(1, this.emailTileTable.count(), true, true)
        //    //.toArray();
        //    break;

        //  case 'yourblock':
        //    result =  this._db.yourBlockObjectStore?.get(model.key);
        //    break;

        //  case 'yourfriend':
        //    result =  this._db.yourFriendObjectStore?.get(model.key);
        //    break;
        //  case 'yourlike':
        //    result =  this._db.yourLikeObjectStore?.get(model.key);
        //    break;

        //  case 'yourmemberactivity':
        //    result =  this._db.yourMemberActivityObjectStore?.get(model.id);
        //    break;

        //  case 'yourmemberlockingactivity':
        //    result =  this._db.yourMemberLockingActivityObjectStore?.get(model.id);
        //    break;

        //  case 'yourmemberstartrackwinkactivity':
        //    result =  this._db.yourMemberStarTrackWinkActivityObjectStore?.get(model.id);
        //    break;

        //  case 'yourstar':
        //    result =  this._db.yourStarObjectStore?.get(model.key);
        //    break;

        //  case 'yourtrack':
        //    result =  this._db.yourTrackObjectStore?.get(model.key);
        //    break;

        //  case 'yourunlock':
        //    result =  this._db.yourUnlockObjectStore?.get(model.key);
        //    break;

        //  case 'yourwink':
        //    result =  this._db.yourWinkObjectStore?.get(model.key);
        //    break;
        //}

        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
        //  resolve(result);
        //}
        //else {
        //  reject('an error occured while getting data to table : ' + model.entityName)
        //}

        // subscriber.next(result);


 // -----------------------------------------------------
  //setupDictionaries (version ?: number) : any {
  //  debugger;
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(version) || version < 0) {
  //    version = 1;

  //  }
  //  // TODO: add logic for deleting the old stores:
  //  // --------------------------------------------
  //  //if (this.oldVersion > version) {
  //  //  this.message = 'dexie.db oldVersion= ' + this.oldVersion + 'cannot be >= version= ' + version;
  //  //  console.log(this.message);
  //  //  // this.resetDatabase();
  //  //  return this.message;
  //  //}
  //  //this.version(version).stores({
  //  //  // todoLists: '++id',
  //  //  // todoItems: '++id, todoListId',
  //  //  buddyObjectStore: 'id, bneStr, key',
  //  //  chatHistoryObjectStore: 'id, bneStr, key',
  //  //  chatHistoryIdObjectStore: 'id, bneStr, key',

  //  //  emailTileObjectStore: 'id, bneStr, key',
  //  //  emailTileIdObjectStore: 'id, bneStr, key',

  //  //  enoteTileObjectStore: 'id, bneStr, key',
  //  //  enoteTileIdObjectStore: 'id, bneStr, key',

  //  //  errorLogObjectStore: 'id, bneStr, key',
  //  //  kvPhotoObjectStore: 'id, bneStr, key',
  //  //  loginSuccessObjectStore: 'id, bneStr, key',

  //  //  myBlockObjectStore: 'id, bneStr, key',
  //  //  myChatObjectStore: 'id, bneStr, key',
  //  //  myChatIdObjectStore: 'id, bneStr, key',
  //  //  myEmailObjectStore: 'id, bneStr, key',
  //  //  myEmailIdObjectStore: 'id, bneStr, key',
  //  //  myFriendObjectStore: 'id, bneStr, key',
  //  //  myLikeObjectStore: 'id, bneStr, key',
  //  //  myMemberActivityObjectStore: 'id, bneStr, key',
  //  //  myMemberLockingActivityObjectStore: 'id, bneStr, key',
  //  //  myMemberStarTrackWinkActivityObjectStore: 'id, bneStr, key',
  //  //  myStarObjectStore: 'id, bneStr, key',
  //  //  myTrackObjectStore: 'id, bneStr, key',
  //  //  myWinkObjectStore: 'id, bneStr, key',
  //  //  myUnlockObjectStore: 'id, bneStr, key',

  //  //  myNotificationObjectStore: 'id, bneStr, key',
  //  //  myNotificationIdObjectStore: 'id, bneStr, key',

  //  //  // photoObjectStore: 'id, bneStr, key',
  //  //  photoBnIdObjectStore: 'id, bneStr, key',
  //  //  // photoBnObjectStore: 'id, bneStr, key',

  //  //  preferenceObjectStore: 'id, bneStr, key',
  //  //  profileTileObjectStore: 'id, bneStr, key',
  //  //  profileInfoObjectStore: 'id, bneStr, key',
  //  //  // profilePicsObjectStore: 'id, bneStr, key',

  //  //  sitUserObjectStore: 'id, bneStr, key',
  //  //  sitUserIdObjectStore: 'id, bneStr, key',

  //  //  yourBlockObjectStore: 'id, bneStr, key',
  //  //  yourFriendObjectStore: 'id, bneStr, key',
  //  //  yourLikeObjectStore: 'id, bneStr, key',
  //  //  yourMemberActivityObjectStore: 'id, bneStr, key',
  //  //  yourMemberLockingActivityObjectStore: 'id, bneStr, key',
  //  //  yourMemberStarTrackWinkActivityObjectStore: 'id, bneStr, key',
  //  //  yourStarObjectStore: 'id, bneStr, key',
  //  //  yourTrackObjectStore: 'id, bneStr, key',
  //  //  yourWinkObjectStore: 'id, bneStr, key',
  //  //  yourUnlockObjectStore: 'id, bneStr, key',
  //  //});
  //  //return this.version(version).stores;
  //}
  // ---------------------------------------------------------------
  //async resetDatabase () : Promise<any> {
  //  await this.transaction('rw', 'todoItems', 'todoLists', () => {
  //    // this.todoItems.clear();
  //    // this.todoLists.clear();
  //    // this.populate();
  //    this.buddyObjectStore.clear();
  //    this.chatHistoryObjectStore.clear();
  //    this.chatHistoryIdObjectStore.clear();

  //    this.emailTileObjectStore.clear();
  //    this.emailTileIdObjectStore.clear();

  //    this.enoteTileObjectStore.clear();
  //    this.enoteTileIdObjectStore.clear();

  //    this.errorLogObjectStore.clear();
  //    this.kvPhotoObjectStore.clear();
  //    this.loginSuccessObjectStore.clear();

  //    this.myBlockObjectStore.clear();
  //    this.myChatObjectStore.clear();
  //    this.myChatIdObjectStore.clear();
  //    this.myEmailObjectStore.clear();
  //    this.myEmailIdObjectStore.clear();
  //    this.myFriendObjectStore.clear();
  //    this.myLikeObjectStore.clear();
  //    this.myMemberActivityObjectStore.clear();
  //    this.myMemberLockingActivityObjectStore.clear();
  //    this.myMemberStarTrackWinkActivityObjectStore.clear();
  //    this.myStarObjectStore.clear();
  //    this.myTrackObjectStore.clear();
  //    this.myWinkObjectStore.clear();
  //    this.myUnlockObjectStore.clear();

  //    this.myNotificationObjectStore.clear();
  //    this.myNotificationIdObjectStore.clear();

  //    // this.photoObjectStore.clear();
  //    this.photoBnIdObjectStore.clear();
  //    //this.photoBnObjectStore.clear();

  //    this.preferenceObjectStore.clear();
  //    this.profileTileObjectStore.clear();
  //    this.profileInfoObjectStore.clear();
  //    // this.profilePicsObjectStore.clear();

  //    this.sitUserObjectStore.clear();
  //    this.sitUserIdObjectStore.clear();

  //    this.yourBlockObjectStore.clear();
  //    this.yourFriendObjectStore.clear();
  //    this.yourLikeObjectStore.clear();
  //    this.yourMemberActivityObjectStore.clear();
  //    this.yourMemberLockingActivityObjectStore.clear();
  //    this.yourMemberStarTrackWinkActivityObjectStore.clear();
  //    this.yourStarObjectStore.clear();
  //    this.yourTrackObjectStore.clear();
  //    this.yourWinkObjectStore.clear();
  //    this.yourUnlockObjectStore.clear();
  //  });
  //  return true;
  //}
   // ---------------------------------------------------------------
  // addStickyNote makes a sticky note object from the text passed
  // in and stores it in the database. Returns a promise that
  // resolves on success.
  // public addModelObservable (model : any) : Observable<any> {
  //  // debugger;
  //  return new Observable<any>((subscriber) => {
  //    this.message = 'sitUser has been successfully added.';
  //    let result : any = new Object;

  //    debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.bneStr)
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.entityName)
  //      && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.key) || model.id > 0)
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this)) {
  //       debugger;
  //      // let model.bneStr = JSON.stringify(model);

  //      var lowerCasedEntityName = model.entityName.toLowerCase();
  //      var objectStore : any;
  //      this.getObjectStore(model.entityName).subscribe(store => {
  //        objectStore = store;

  //        debugger;
  //        this.updateObjectStoreData(objectStore, model).subscribe(data => {
  //          result = data;
  //          debugger;
  //          subscriber.next(result);
  //          let timer = setTimeout(() => {

  //            subscriber.complete();
  //            clearTimeout(timer);
  //            debugger;
  //          }, 1000)
  //        });
  //        debugger;
  //      });
  //      // debugger;
  //      if (this.isObjectStoreNameFoundInTables(model.entityName + 'ObjectStore')) {
  //        // debugger;
  //        console.log(new Date().getTime() + '(Timestamp) : found object store name is: ' + model.entityName + 'ObjectStore');

  //        // debugger;
  //        switch (lowerCasedEntityName) {

  //          // TODO:
  //          // -----
  //          // transofrm all add() method to the following according to the respective dictionary.
  //          // For example:
  //          // if ((await this.sitUserObjectStore.where({ id: model.id }).count()) === 0) {
  //          //    result = await this.sitUser.add(model.id, model.bneStr, model.key);
  //          // }
  //          // -----------------------------------------
  //          //const todoListId = await db.todoLists.add({
  //          //  title: 'To Do Today',
  //          //});
  //          // -----------------------------------------

  //          case 'chathistory':
              
  //            debugger;
  //            this.updateObjectStoreData(db.chatHistoryObjectStore, model).subscribe(data => {
  //              result = data;
  //              debugger;
  //            });
  //            debugger;
  //            //this.chatHistoryObjectStore.get(model.id).then(data => {
  //            //  result = data;

  //            //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //            //    // debugger;
  //            //    this.chatHistoryObjectStore?.add({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      result = data;
  //            //      // debugger;
  //            //    }).catch('ConstraintError', e => {
  //            //      // Failed with ConstraintError
  //            //      console.error("Constraint error: " + e.message);
  //            //    }).catch(Error, e => {
  //            //      // Any other error derived from standard Error
  //            //      console.error("Error: " + e.message);
  //            //    }).catch(e => {
  //            //      // Other error such as a string was thrown
  //            //      console.error(e);
  //            //    });
  //            //  }
  //            //  else {
  //            //    // debugger;
  //            //    this.chatHistoryObjectStore?.put({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      result = data;
  //            //      // debugger;
  //            //    }).catch('ConstraintError', e => {
  //            //      // Failed with ConstraintError
  //            //      console.error("Constraint error: " + e.message);
  //            //    }).catch(Error, e => {
  //            //      // Any other error derived from standard Error
  //            //      console.error("Error: " + e.message);
  //            //    }).catch(e => {
  //            //      // Other error such as a string was thrown
  //            //      console.error(e);
  //            //    });
  //            //  }
  //            //})
              

  //            break;
  //          case 'chathistoryid':
  //            debugger;
  //            this.updateObjectStoreData(this.chatHistoryIdObjectStore, model).subscribe(data => {
  //              result = data;
  //              debugger;
  //            });
  //            debugger;
             
  //            // debugger;
  //            //this.chatHistoryIdObjectStore.get(model.id).then(data => {
  //            //  result = data;

  //            //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //            //    // debugger;
  //            //    this.chatHistoryIdObjectStore?.add({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      result = data;
  //            //    }).catch('ConstraintError', e => {
  //            //      // Failed with ConstraintError
  //            //      console.error("Constraint error: " + e.message);
  //            //    }).catch(Error, e => {
  //            //      // Any other error derived from standard Error
  //            //      console.error("Error: " + e.message);
  //            //    }).catch(e => {
  //            //      // Other error such as a string was thrown
  //            //      console.error(e);
  //            //    });
  //            //  }
  //            //  else {
  //            //    // debugger;
  //            //    this.chatHistoryIdObjectStore?.put({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      result = data;
  //            //    });
  //            //  }
  //            //})
              

  //            break;

  //          case 'emailtile':
  //            // debugger;
  //            this.emailTileObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.emailTileObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.emailTileObjectStore?.add(
  //                      model
  //                    ).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.emailTileObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.emailTileObjectStore?.put(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })

  //            break;
  //          case 'emailtileid':
  //            // debugger;
  //            this.emailTileIdObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.emailTileIdObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.emailTileIdObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.emailTileIdObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.emailTileIdObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
  //            /*}*/

  //            break;

  //          case 'enotetile':
             
  //            // debugger;
  //            this.enotTileObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.enotTileObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.enotTileObjectStore?.add({
  //                      id: model.id,
  //                      bneStr: model.bneStr,
  //                      key: model.key
  //                    }).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.enotTileObjectStore?.put({
  //                      id: model.id,
  //                      bneStr: model.bneStr,
  //                      key: model.key
  //                    }).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.enotTileObjectStore?.put({
  //                  id: model.id,
  //                  bneStr: model.bneStr,
  //                  key: model.key
  //                }).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'enotetileId':
             
  //            // debugger;
  //            this.enotTileIdObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.enotTileIdObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.enotTileIdObjectStore?.add({
  //                      id: model.id,
  //                      bneStr: model.bneStr,
  //                      key: model.key
  //                    }).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.enotTileIdObjectStore?.put({
  //                      id: model.id,
  //                      bneStr: model.bneStr,
  //                      key: model.key
  //                    }).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.enotTileIdObjectStore?.put({
  //                  id: model.id,
  //                  bneStr: model.bneStr,
  //                  key: model.key
  //                }).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;
  //          case 'errorlog':             
  //            // debugger;
  //            this.errorLogObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.errorLogObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.errorLogObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.errorLogObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.errorLogObjectStore?.put(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'kvphoto':
              
  //            // debugger;
  //            this.kvPhotoObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.kvPhotoObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.kvPhotoObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    }).catch('ConstraintError', e => {
  //                      // Failed with ConstraintError
  //                      console.error("Constraint error: " + e.message);
  //                    }).catch(Error, e => {
  //                      // Any other error derived from standard Error
  //                      console.error("Error: " + e.message);
  //                    }).catch(e => {
  //                      // Other error such as a string was thrown
  //                      console.error(e);
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.kvPhotoObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    }).catch('ConstraintError', e => {
  //                      // Failed with ConstraintError
  //                      console.error("Constraint error: " + e.message);
  //                    }).catch(Error, e => {
  //                      // Any other error derived from standard Error
  //                      console.error("Error: " + e.message);
  //                    }).catch(e => {
  //                      // Other error such as a string was thrown
  //                      console.error(e);
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.kvPhotoObjectStore?.put(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //            })
  //            /*}*/
  //            break;
  //          case 'loginsuccess':
  //            // debugger;
  //            debugger;
  //            this.updateObjectStoreData(this.loginSuccessObjectStore, model).subscribe(data => {
  //              result = data;
  //              debugger;
  //            });
  //            debugger;
              
  //            // debugger;
  //            //this.loginSuccessObjectStore.count().then(c => {
  //            //  if (c > 0) {
  //            //    this.loginSuccessObjectStore.get(model.id).then(data => {
  //            //      result = data;

  //            //      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //            //        // debugger;
  //            //        this.loginSuccessObjectStore.add({
  //            //          id: model.id,
  //            //          bneStr: model.bneStr,
  //            //          key: model.key
  //            //        }).then(data => {
  //            //          result = data;
  //            //        }).catch('ConstraintError', e => {
  //            //          // Failed with ConstraintError
  //            //          console.error("Constraint error: " + e.message);
  //            //        }).catch(Error, e => {
  //            //          // Any other error derived from standard Error
  //            //          console.error("Error: " + e.message);
  //            //        }).catch(e => {
  //            //          // Other error such as a string was thrown
  //            //          console.error(e);
  //            //        });
  //            //      }
  //            //      else {
  //            //        // debugger;
  //            //        this.loginSuccessObjectStore.put({
  //            //          id: model.id,
  //            //          bneStr: model.bneStr,
  //            //          key: model.key
  //            //        }).then(data => {
  //            //          result = data;
  //            //        }).catch('ConstraintError', e => {
  //            //          // Failed with ConstraintError
  //            //          console.error("Constraint error: " + e.message);
  //            //        }).catch(Error, e => {
  //            //          // Any other error derived from standard Error
  //            //          console.error("Error: " + e.message);
  //            //        }).catch(e => {
  //            //          // Other error such as a string was thrown
  //            //          console.error(e);
  //            //        });
  //            //      }
  //            //    })
  //            //  }
  //            //  else {
  //            //    // debugger;
  //            //    this.loginSuccessObjectStore.put({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      // debugger;
  //            //      result = data;
  //            //    }).catch('ConstraintError', e => {
  //            //      // Failed with ConstraintError
  //            //      console.error("Constraint error: " + e.message);
  //            //    }).catch(Error, e => {
  //            //      // Any other error derived from standard Error
  //            //      console.error("Error: " + e.message);
  //            //    }).catch(e => {
  //            //      // Other error such as a string was thrown
  //            //      console.error(e);
  //            //    });
  //            //  }
  //            //})
              
  //            break;
  //          case 'myblock':
             
  //            // debugger;
  //            this.myBlockObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myBlockObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myBlockObjectStore.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myBlockObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myBlockObjectStore.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'mychat':
              
  //            // debugger;
  //            this.myChatObjectStore.get(model.id).then(data => {
  //              result = data;
  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.myChatObjectStore?.add(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.myChatObjectStore?.put(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'mychatid':
             
  //            // debugger;
  //            this.myChatIdObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myChatIdObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myChatIdObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myChatIdObjectStore?.put(model.then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;

  //                this.myChatIdObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'myemail':
              
  //            // debugger;
  //            this.myEmailObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.myEmailObjectStore?.add(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.myEmailObjectStore?.put(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'myemailid':
              
  //            // debugger;
  //            this.myEmailIdObjectStore.get(model.id).then(data => {
  //              result = data;
  //              // debugger;
  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.myEmailIdObjectStore?.add(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.myEmailIdObjectStore?.put(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'myfriend':
             
  //            // debugger;
  //            this.myFriendObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myFriendObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myFriendObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myFriendObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myFriendObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'mylike':
              
  //            // debugger;
  //            this.myLikeObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myLikeObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myLikeObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myLikeObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myLikeObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'mymemberactivity':
             
  //            // debugger;
  //            this.myMemberActivityObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.myMemberActivityObjectStore?.add(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.myMemberActivityObjectStore?.put(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;
  //          case 'mymemberlockingactivity':
  //            (this.myMemberLockingActivityObjectStore)) {*/
  //            // debugger;
  //            this.myMemberLockingActivityObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myMemberLockingActivityObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myMemberLockingActivityObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myMemberLockingActivityObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myMemberLockingActivityObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'mymemberstartrackwinkactivity':
  //           (this.myMemberStarTrackWinkActivityObjectStore)) {*/
  //            // debugger;
  //            this.myMemberStarTrackWinkActivityObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myMemberStarTrackWinkActivityObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myMemberStarTrackWinkActivityObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myMemberStarTrackWinkActivityObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myMemberStarTrackWinkActivityObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'mynotification':
  //            // debugger;
             
  //            // debugger;
  //            this.myNotificationObjectStore.get(model.id).then(data => {
  //              result = data;
  //              // debugger;
  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.myNotificationObjectStore.add(model).then(data => {
  //                  result = data;
  //                  // debugger;
  //                });
  //                //              else {
  //                // debugger;
  //                this.myNotificationObjectStore.put(model).then(data => {
  //                  result = data;
  //                  // debugger;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'mynotificationid':
             
  //            // debugger;
  //            this.myNotificationIdObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.myNotificationIdObjectStore.add(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.myNotificationIdObjectStore.put(model).then(data => {
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'mystar':
              
  //            // debugger;
  //            this.myStarObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myStarObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myStarObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myStarObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myStarObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'mytrack':
              
  //            // debugger;
  //            this.myTrackObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myTrackObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myTrackObjectStore?.add(model).then(data => {
  //                      result = data
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myTrackObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myTrackObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'myunlock':
              
  //            // debugger;
  //            this.myUnlockObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myUnlockObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myUnlockObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myUnlockObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myUnlockObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'mywink':
              
  //            // debugger;
  //            this.myWinkObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myWinkObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myWinkObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myWinkObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myWinkObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'photo':
             
  //            // debugger;
  //            this.photoObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.photoObjectStore?.add(model).then(data => {
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.photoObjectStore?.put(model).then(data => {
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'photobn':
  //            // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoBnbjectStore)) {
  //            // debugger;
  //            this.photoBnObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.photoBnObjectStore?.add({
  //                  id: model.id,
  //                  bneStr: model.bneStr,
  //                  key: model.key
  //                }).then(data => {
  //                  result = data;
  //                  // debugger;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.photoBnObjectStore?.put({
  //                  id: model.id,
  //                  bneStr: model.bneStr,
  //                  key: model.key
  //                }).then(data => {
  //                  result = data;
  //                  // debugger;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //            })
  //            break;

  //          case 'photobnid':
              
  //            // debugger;
  //            this.photoBnIdObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.photoBnIdObjectStore?.add(model).then(data => {
  //                  result = data;
  //                  // debugger;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.photoBnIdObjectStore?.put(model).then(data => {
  //                  result = data;
  //                  // debugger;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'preference':              
  //            // debugger;
  //            this.preferenceObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.preferenceObjectStore?.add(model).then(data => {
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.preferenceObjectStore?.put(model).then(data => {
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //            })
  //            break;

  //          case 'profileinfo':
             
  //            // debugger;
  //            this?.profileInfoObjectStore?.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.profileInfoObjectStore.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.profileInfoObjectStore?.put(model).then(data => {
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'profilepics':
             
  //            // debugger;
  //            this.profilePicsObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.profilePicsObjectStore?.add({
  //                  id: model.id,
  //                  bneStr: model.bneStr,
  //                  key: model.key
  //                }).then(data => {
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.profilePicsObjectStore?.put({
  //                  id: model.id,
  //                  bneStr: model.bneStr,
  //                  key: model.key
  //                }).then(data => {
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'profiletile':
             
  //            // debugger;
  //            this.profileTileObjectStore.get(model.id).then(data => {
  //              result = data;

  //              if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                // debugger;
  //                this.profileTileObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //              else {
  //                // debugger;
  //                this.profileTileObjectStore?.put(model).then(data => {
  //                  result = data;
  //                }).catch('ConstraintError', e => {
  //                  // Failed with ConstraintError
  //                  console.error("Constraint error: " + e.message);
  //                }).catch(Error, e => {
  //                  // Any other error derived from standard Error
  //                  console.error("Error: " + e.message);
  //                }).catch(e => {
  //                  // Other error such as a string was thrown
  //                  console.error(e);
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'situser':
              
  //            // debugger;
  //            debugger;
  //            this.updateObjectStoreData(this.sitUserObjectStore, model).subscribe(data => {
  //              result = data;
  //            });
  //            debugger;
  //            //this.sitUserObjectStore.get(model.id).then(data => {
  //            //  result = data;

  //            //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //            //    // debugger;
  //            //    this.sitUserObjectStore?.add({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      result = data;
  //            //    }).catch('ConstraintError', e => {
  //            //      // Failed with ConstraintError
  //            //      console.error("Constraint error: " + e.message);
  //            //    }).catch(Error, e => {
  //            //      // Any other error derived from standard Error
  //            //      console.error("Error: " + e.message);
  //            //    }).catch(e => {
  //            //      // Other error such as a string was thrown
  //            //      console.error(e);
  //            //    });
  //            //  }
  //            //  else {
  //            //    // debugger;
  //            //    this.sitUserObjectStore?.put({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      result = data;
  //            //    }).catch('ConstraintError', e => {
  //            //      // Failed with ConstraintError
  //            //      console.error("Constraint error: " + e.message);
  //            //    }).catch(Error, e => {
  //            //      // Any other error derived from standard Error
  //            //      console.error("Error: " + e.message);
  //            //    }).catch(e => {
  //            //      // Other error such as a string was thrown
  //            //      console.error(e);
  //            //    });
  //            //  }
  //            //})
              
  //            break;

  //          case 'situserid':              
  //            // debugger;
  //            debugger;
  //            this.updateObjectStoreData(this.sitUserIdObjectStore, model).subscribe(data => {
  //              result = data;
  //            });
  //            debugger;
  //            //this.sitUserIdObjectStore.get(model.id).then(data => {
  //            //  result = data;

  //            //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //            //    // debugger;
  //            //    this.sitUserIdObjectStore?.add({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      result = data;
  //            //    }).catch('ConstraintError', e => {
  //            //      // Failed with ConstraintError
  //            //      console.error("Constraint error: " + e.message);
  //            //    }).catch(Error, e => {
  //            //      // Any other error derived from standard Error
  //            //      console.error("Error: " + e.message);
  //            //    }).catch(e => {
  //            //      // Other error such as a string was thrown
  //            //      console.error(e);
  //            //    });
  //            //  }
  //            //  else {
  //            //    // debugger;
  //            //    this.sitUserIdObjectStore?.put({
  //            //      id: model.id,
  //            //      bneStr: model.bneStr,
  //            //      key: model.key
  //            //    }).then(data => {
  //            //      result = data;
  //            //    }).catch('ConstraintError', e => {
  //            //      // Failed with ConstraintError
  //            //      console.error("Constraint error: " + e.message);
  //            //    }).catch(Error, e => {
  //            //      // Any other error derived from standard Error
  //            //      console.error("Error: " + e.message);
  //            //    }).catch(e => {
  //            //      // Other error such as a string was thrown
  //            //      console.error(e);
  //            //    });
  //            //  }
  //            //})
              
  //            break;

  //          case 'yourblock':
             
  //            // debugger;
  //            this.yourBlockObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.yourBlockObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.yourBlockObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.yourBlockObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.yourBlockObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'yourfriend':
              
  //            // debugger;
  //            this.myFriendObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myFriendObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myFriendObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myFriendObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myFriendObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'yourlike':
             
  //            // debugger;
  //            this.myLikeObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.myLikeObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.myLikeObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.myLikeObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.myLikeObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })              
  //            break;

  //          case 'yourmemberactivity':
              
  //            // debugger;
  //            this.yourMemberActivityObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.yourMemberActivityObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.yourMemberActivityObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.yourMemberActivityObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.yourMemberActivityObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'yourmemberlockingactivity':
              
  //            // debugger;
  //            this.yourMemberLockingActivityObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.yourMemberLockingActivityObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.yourMemberLockingActivityObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.yourMemberLockingActivityObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.yourMemberLockingActivityObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'yourmemberstartrackwinkactivity':
              
  //            // debugger;
  //            this.yourMemberStarTrackWinkActivityObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.yourMemberStarTrackWinkActivityObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.yourMemberStarTrackWinkActivityObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.yourMemberStarTrackWinkActivityObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.yourMemberStarTrackWinkActivityObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'yourstar':
              
  //            // debugger;
  //            this.yourStarObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.yourStarObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.yourStarObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.yourStarObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.yourStarObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'yourtrack':
              
  //            // debugger;
  //            this.yourTrackObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.yourTrackObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.yourTrackObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.yourTrackObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.yourTrackObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
             
  //            break;

  //          case 'yourunlock':
              
  //            // debugger;
  //            this.yourUnlockObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.yourUnlockObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.yourUnlockObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.yourUnlockObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.yourUnlockObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;

  //          case 'yourwink':
              
  //            // debugger;
  //            this.yourWinkObjectStore.count().then(c => {
  //              if (c > 0) {
  //                this.yourWinkObjectStore.get(model.id).then(data => {
  //                  result = data;

  //                  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //                    // debugger;
  //                    this.yourWinkObjectStore?.add(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                  else {
  //                    // debugger;
  //                    this.yourWinkObjectStore?.put(model).then(data => {
  //                      result = data;
  //                    });
  //                  }
  //                })
  //              }
  //              else {
  //                // debugger;
  //                this.yourWinkObjectStore?.add(model).then(data => {
  //                  // debugger;
  //                  result = data;
  //                });
  //              }
  //            })
              
  //            break;
  //        }
  //        // debugger;
  //        subscriber.next(result);
  //        subscriber.complete();
  //      }
  //    }
  //  })
  //}
  // -------------------------------------------------------
  
  // -------------------------------------------------------------------
  // NOte: this method assumes that the particular ObjectStore exists.
  // -------------------------------------------------------------------
  // public countObjectStoreItems (entityName : string) : Observable<any> | any {
  //  // debugger;
    
  //  return new Observable<any>((subscriber) => {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)) {
  //      debugger;
  //      var count = this.getTableByEntityName(entityName).count();
  //      debugger;
  //      subscriber.next(count);

        // switch (entityName.toLowerCase()) {
        //  case 'buddy':
        //    resolve(objectStores.buddyObjectStore?.count());
        //    this._db.tables.buddyObjectStore stores. .where(index).equalsIgnoreCase(entityName).count()
        //    break;

        //  case 'chathistory':
        //    resolve(objectStores.chatHistoryObjectStore?.count());
        //    break;

        //  case 'chathistoryid':
        //    resolve(objectStores.chatHistoryIdObjectStore?.count());
        //    break;

        //  case 'emailtileid':
        //    resolve(objectStores.emailTileIdObjectStore?.count());
        //    break;

        //  case 'emailtile':
        //    resolve(objectStores.emailTileObjectStore?.count());
        //    break;

        //  case 'enotetileid':
        //    resolve(objectStores.enoteTileIdObjectStore?.count());
        //    break;

        //  case 'enotetile':
        //    resolve(objectStores.enoteTileObjectStore?.count());
        //    break;

        //  case 'errorlog':
        //    resolve(objectStores.errorLogObjectStore?.count());
        //    break;

        //  case 'kvphoto':
        //    resolve(objectStores.kvPhotoObjectStore?.count());
        //    break;

        //  case 'loginsuccess':
        //    resolve(objectStores.loginSuccessObjectStore?.count());
        //    debugger;
        //    break;

        //  case 'myblock':
        //    resolve(objectStores.myBlockObjectStore?.count());
        //    break;

        //  case 'mychatid':
        //    resolve(objectStores.myChatIdObjectStore?.count());
        //    break;

        //  case 'mychat':
        //    resolve(objectStores.myChatObjectStore?.count());
        //    break;

        //  case 'myemailid':
        //    resolve(objectStores.myEmailIdObjectStore?.count());
        //    break;

        //  case 'myemail':
        //    resolve(objectStores.myEmailObjectStore?.count());
        //    break;
        //  case 'myfriend':
        //    resolve(objectStores.myFriendObjectStore?.count());
        //    break;
        //  case 'mylike':
        //    resolve(objectStores.myLikeObjectStore?.count());
        //    break;

        //  case 'mymemberactivity':
        //    resolve(objectStores.myMemberActivityObjectStore?.count());
        //    break;

        //  case 'mymemberlockingactivity':
        //    resolve(objectStores.myMemberLockingActivityObjectStore?.count());
        //    break;

        //  case 'mymemberstartrackwinkactivity':
        //    resolve(objectStores.myMemberStarTrackWinkActivityObjectStore?.count());
        //    break;

        //  case 'mynotificationid':
        //    resolve(objectStores.myNotificationIdObjectStore?.count());
        //    break;

        //  case 'mynotification':
        //    resolve(objectStores.myNotificationObjectStore?.count());
        //    break;

        //  case 'mytrack':
        //    resolve(objectStores.myTrackObjectStore?.count());
        //    break;

        //  case 'myunlock':
        //    resolve(objectStores.myUnlockObjectStore?.count());
        //    break;

        //  case 'mywink':
        //    resolve(objectStores.myWinkObjectStore?.count());
        //    break;

        //  case 'photo':
        //    resolve(objectStores.photoObjectStore?.count());
        //    break;

        //  case 'photobnid':
        //    resolve(objectStores.photoBnIdObjectStore?.count());
        //    break;

        //  //case 'photobn':
        //  //  resolve(this.photoBnObjectStore?.count());
        //  //  break;

        //  case 'preference':
        //    resolve(objectStores.preferenceObjectStore?.count());
        //    break;

        //  case 'profileinfo':
        //    resolve(objectStores.profileInfoObjectStore?.count());
        //    break;

        //  //case 'profilepics':
        //  //  resolve(this.profilePicsObjectStore?.count());
        //  //  break;

        //  case 'profiletile':
        //    resolve(objectStores.profileTileObjectStore?.count());
        //    debugger;
        //    break;

        //  case 'situserid':
        //    resolve(objectStores.sitUserIdObjectStore?.count());
        //    debugger;
        //    break;

        //  case 'situser':
        //    resolve(objectStores.sitUserObjectStore?.count());
        //    debugger;
        //    break;

        //  case 'yourblock':
        //    resolve(objectStores.yourBlockObjectStore?.count());
        //    break;

        //  case 'yourfriend':
        //    resolve(objectStores.yourFriendObjectStore?.count());
        //    break;

        //  case 'yourlike':
        //    resolve(objectStores.yourLikeObjectStore?.count());
        //    break;

        //  case 'yourmemberactivity':
        //    resolve(objectStores.yourMemberActivityObjectStore?.count());
        //    break;

        //  case 'yourmemberlockingactivity':
        //    resolve(objectStores.yourMemberLockingActivityObjectStore?.count());
        //    break;

        //  case 'yourmemberstartrackwinkactivity':
        //    resolve(objectStores.yourMemberStarTrackWinkActivityObjectStore?.count());
        //    break;

        //  case 'yourstar':
        //    resolve(objectStores.yourStarObjectStore?.count());
        //    break;

        //  case 'yourtrack':
        //    resolve(objectStores.yourTrackObjectStore?.count());
        //    break;

        //  case 'yourunlock':
        //    resolve(objectStores.yourUnlockObjectStore?.count());
        //    break;

        //  case 'yourwink':
        //    resolve(objectStores.yourWinkObjectStore?.count());
        //    break;
        //}
  //    }      
  //  })
  // }
  // -------------------------------------------------------------------
  // Note:  this method will create a particular ObjectStore if it does not exists
  // -------------------------------------------------------------------
  //public createObjectStore (db: any, entityName : string) : Observable<any> | any {
  //  debugger;    
  //  return new Observable<any>((subscriber) => {
  //    this = db;
  //    debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)) {
  //      if (!this.isObjectStoreNameFoundInTables(entityName)) {
  //        debugger;
  //        switch (entityName.toLowerCase()) {
  //          case 'buddy':
  //            db.stores({
  //              buddyObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.buddyObjectStore);
  //            break;
  //          case 'chathistory':
  //            db.stores({
  //              chatHistoryObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.chatHistoryObjectStore);
  //            break;
  //          case 'chathistoryid':
  //            db.stores({
  //              chatHistoryIdObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.chatHistoryIdObjectStore);
  //            break;

  //          case 'emailtileid':
  //            db.stores({
  //              emailTileIdObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.emailTileIdObjectStore);
  //            break;

  //          case 'emailtile':
  //            db.stores({
  //              emailTileObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.emailTileObjectStore);
  //            break;

  //          case 'enotetileid':
  //            db.stores({
  //              enoteTileIdObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.enoteTileIdObjectStore);
  //            break;

  //          case 'enotetile':
  //            db.stores({
  //              enoteTileObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.enoteTileObjectStore);
  //            break;

  //          case 'errorlog':
  //            db.stores({
  //              errorLogObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.errorLogObjectStore);
  //            break;

  //          case 'kvphoto':
  //            db.stores({
  //              kvPhotoObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.kvPhotoObjectStore);
  //            break;

  //          case 'loginsuccess':
  //            db.stores({
  //              loginSuccessObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.loginSuccessObjectStore);
  //            debugger;
  //            break;

  //          case 'myblock':
  //            db.stores({
  //              myBlockObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myBlockObjectStore);
  //            break;

  //          case 'mychatid':
  //            db.stores({
  //              myChatIdObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myChatIdObjectStore);
  //            break;

  //          case 'mychat':
  //            db.stores({
  //              myChatObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myChatObjectStore);
  //            break;

  //          case 'myemailid':
  //            db.stores({
  //              myEmailIdObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.buddyObjectStore);
  //            break;

  //          case 'myemail':
  //            db.stores({
  //              myEmailObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myEmailObjectStore);
  //            break;
  //          case 'myfriend':
  //            db.stores({
  //              myFriendObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myFriendObjectStore);
  //            break;

  //          case 'mylike':
  //            db.stores({
  //              myLikeObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myLikeObjectStore);
  //            break;

  //          case 'mymemberactivity':
  //            db.stores({
  //              myMemberActivityObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myMemberActivityOObjectStore);
  //            break;

  //          case 'mymemberlockingactivity':
  //            db.stores({
  //              myMemberLockingObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myMemberLockingObjectStore);
  //            break;

  //          case 'mymemberstartrackwinkactivity':
  //            db.stores({
  //              myMemberStarTrackWinkObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myMemberStarTrackWinkObjectStore);
  //            break;

  //          case 'mynotificationid':
  //            db.stores({
  //              myNotificationIdObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myNotificationIdObjectStore);
  //            break;

  //          case 'mynotification':
  //            db.stores({
  //              myNotificationObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myNotificationObjectStore);
  //            break;

  //          case 'mytrack':
  //            db.stores({
  //              myTrackObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myTrackObjectStore);
  //            break;

  //          case 'myunlock':
  //            db.stores({
  //              myUnlockObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myUnlockObjectStore);
  //            break;

  //          case 'mywink':
  //            db.stores({
  //              myWinkObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.myWinkObjectStore);
  //            break;

  //          case 'photo':
  //            db.stores({
  //              photoObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.photoObjectStore);
  //            break;

  //          case 'photobnid':
  //            db.stores({
  //              photoBnIdObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.photoBnIdObjectStore);
  //            break;

  //          case 'photobn':
  //            db.stores({
  //              photoBnObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.photoBnObjectStore);
  //            break;

  //          case 'preference':
  //            db.stores({
  //              preferenceObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.preferenceObjectStore);
  //            break;

  //          case 'profileinfo':
  //            db.stores({
  //              profileInfoObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.profileInfoObjectStore);
  //            break;

  //          case 'profilepics':
  //            db.stores({
  //              profilePicsObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.profilePicsObjectStore);
  //            break;

  //          case 'profiletile':
  //            db.stores({
  //              profileTileObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.profileTileObjectStore);
  //            debugger;
  //            break;

  //          case 'situserid':
  //            db.stores({
  //              sitUserIdObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.sitUserIdObjectStore);
  //            debugger;
  //            break;

  //          case 'situser':
  //            db.stores({
  //              sitUserObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.sitUserObjectStore);
  //            debugger;
  //            break;

  //          case 'yourblock':
  //           db.stores({
  //              yourBlockObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourBlockObjectStore);
  //            break;

  //          case 'yourfriend':
  //            db.stores({
  //              yourFriendObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourFriendObjectStore);
  //            break;

  //          case 'yourlike':
  //            db.stores({
  //              yourLikeObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourLikeObjectStore);
  //            break;

  //          case 'yourmemberactivity':
  //            db.stores({
  //              yourMemberActivityObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourMemberActivityObjectStore);
  //            break;


  //          case 'yourmemberlockingactivity':
  //            db.stores({
  //              yourMemberLockingActivityObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourMemberLockingActivityObjectStore);
  //            break;

  //          case 'yourmemberstartrackwinkactivity':
  //            db.stores({
  //              yourMemberStarTrackWinkActivityObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourMemberStarTrackWinkActivityObjectStore);
  //            break;

  //          case 'yourstar':
  //            db.stores({
  //              yourStarObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourStarObjectStore);
  //            break;

  //          case 'yourtrack':
  //            db.stores({
  //              yourTrackObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourTrackObjectStore);
  //            break;

  //          case 'yourunlock':
  //            db.stores({
  //              yourUnlockObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourUnlockObjectStore);
  //            break;

  //          case 'yourwink':
  //            db.stores({
  //              yourWinkObjectStore: 'id, bneStr, key',
  //            });
  //            subscriber.next(this.yourWinkObjectStore);
  //            break;
  //        }
  //      }
  //      else {
  //        debugger;
  //        subscriber.next(this.getObjectStore(entityName));
  //      }
  //    }
  //    else {
  //      this.message = StringServiceStatic.stringBuilder(new Date().getTime() + ' : could not create ObjectStore for: ' + entityName);
  //      console.log(this.message);
  //      debugger;
  //    }
  //  });
  //}
  // -------------------------------------------------------------------
  //public getObjectStore (entityName : string) : any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)) {
  //    debugger;
  //    this.createObjectStore(this, entityName).then(store => {
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(store)) {
  //        return store;
  //      }
  //      else return null;
  //    })
  //  }
  //  else return null;
  //}
  // TODO investigate
  
// ==========================================================
  // ---------------------------------------
  // getNotes retrieves all sticky notes from the IndexedDB
  // database, in forward or reverse chronological order. Returns
  // a promise that resolves on success, containing our array of
  //// sticky notes.
  //public getAll (model : any, reverseOrder : boolean) {

  //}


// ==========================================================

  // objectStoreNames:
  // -----------------
  //public chatHistoryTable! : Table<chatHistoryI, number>; // id is number in this case
  //public chatHistoryIdTable! : Table<chatHistoryIdI, number>;
  //public emailTileTable! : Table<emailTileI, string>;
  //public emailTileIdTable! : Table<emailTileIdI, string>;
  //public enoteTilyTable! : Table<enoteTileI, number>;
  //public enoteTileIdTable! : Table<enoteTileIdI, number>;
  //public errorLogTable! : Table<errorLogI, number>;
  //public kvPhotoTable! : Table<kvPhotoI, number>;
  //public loginSuccessTable! : Table<loginSuccessI, string>;
  //public myChatTable! : Table<myChatI, number>;
  //public myChatIdTable! : Table<myChatIdI, number>;
  //public myEmailTable! : Table<myEmailI, string>;
  //public myEmailIdTable! : Table<myEmailIdI, string>;
  //public myNotificationTable! : Table<myNotificationI, number>;
  //public myNotificationIdTable! : Table<myNotificationIdI, number>;

  //public sitUserTable! : Table<sitUserI, string>;
  //public sitUserIdTable! : Table<sitUserIdI, string>;

  //public emailTileTable! : Table<any, number>;
  //public emailTileIdTable! : Table<any, number>;

// ----------------------------------------------------------
//  Begin of interfaces of Tables in the database/i.e. models
// ----------------------------------------------------------
//interface chatHistoryI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}

//interface chatHistoryIdI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface emailTileI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface emailTileIdI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface enoteTileI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface enoteTileIdI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface errorLogI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface kvPhotoI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface loginSuccessI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface myChatI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface myChatIdI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface myEmailI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface myEmailIdI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface myNotificationI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface myNotificationIdI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface sitUserI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
//interface sitUserIdI {
//  id ?: number;
//  bneStr ?: BoxNonceEntity;
//  key ?: string;
//}
// ----------------------------------------------------------
//  Begin of interfaces of Tables in the database/i.e. models
// ----------------------------------------------------------

 // ---------------------------------------------------------
  //public createDexieTableDictionaries () {
  //  // debugger;
  //  ConstantServiceStatic.storeNames.map(s => {
  //    switch (s.toLowerCase()) {
  //      //case 'chathistory':
  //      //  this.chatHistoryTable = this.table(s);
  //      //  this.objectStores.push(this.chatHistoryTable);
  //      //  this.objectStoresDictionary.setValue((s + 'Table'), this.chatHistoryTable);
  //      //  break;

  //      //case 'chathistoryid':
  //      //  this.chatHistoryIdTable = this.table(s);
  //      //  this.objectStores.push(this.chatHistoryIdTable);
  //      //  this.objectStoresDictionary.setValue((s + 'Table'), this.chatHistoryIdTable);
  //      //  break;

  //      case 'emailtileid':
  //        // debugger;
  //        this.emailTileIdTable =  this.table(s);
  //        this.allTables.set([s], this.emailTileIdTable)
  //        this.objectStores.push(this.emailTileIdTable);
  //        this.dexieTableObjectStore.setValue((s + 'Table'), this.emailTileIdTable);
  //        break;

  //      case 'emailtile':
  //        // debugger;
  //        this.emailTileTable = this.table(s);
  //        this.objectStores.push(this.emailTileTable);
  //        this.allTables.set([ s ], this.emailTileTable)
  //        this.dexieTableObjectStore.setValue((s + 'Table'), this.emailTileTable);
  //        break;



  //      //case 'enotetile':
  //      //  this.enoteTilesI = this.table(s);
  //      //  this.objectStores.push(this.enoteTilesI);
  //      //  this.objectStoresDictionary.setValue((s + 'sI'), this.enoteTilesI);
  //      //  break;

  //      //case 'enotetileid':
  //      //  this.enoteTileIdsI = this.table(s);
  //      //  this.objectStores.push(this.enoteTileIdsI);
  //      //  this.objectStoresDictionary.setValue((s + 'sI'), this.enoteTileIdsI);
  //      //  break;

  //      //case 'errorlog':
  //      //  this.errorLogsI = this.table(s);
  //      //  this.objectStores.push(this.errorLogsI);
  //      //  this.objectStoresDictionary.setValue((s + 'I'), this.errorLogsI);
  //      //  break;

  //      //case 'kvphoto':
  //      //  this.kvPhotosI = this.table(s);
  //      //  this.objectStores.push(this.kvPhotosI);
  //      //  this.objectStoresDictionary.setValue((s + 'I'), this.kvPhotosI);
  //      //  break;

  //      case 'loginsuccess':
  //        this.loginSuccessTable = this.table(s);
  //        this.objectStores.push(this.loginSuccessTable);
  //        this.allTables.set([ s ], this.emailTileTable)
  //        this.dexieTableObjectStore.setValue((s + 'Table'), this.loginSuccessTable);
  //        break;

  //      //case 'mychat':
  //      //  this.myChatsI = this.table(s);
  //      //  this.objectStores.push(this.myChatsI);
  //      //  this.objectStoresDictionary.setValue((s + 'sI'), this.myChatsI);
  //      //  break;

  //      //case 'mychatid':
  //      //  this.myChatIdsI = this.table(s);
  //      //  this.objectStores.push(this.myChatIdsI);
  //      //  this.objectStoresDictionary.setValue((s + 'sI'), this.myChatIdsI);
  //      //  break;


  //      case 'myemailid':
  //        this.myEmailIdTable = this.table(s);
  //        this.objectStores.push(this.myEmailIdTable);
  //        this.allTables.set([ s ], this.emailTileTable)
  //        this.dexieTableObjectStore.setValue((s + 'Table'), this.myEmailIdTable);
  //        break;

  //      case 'myemail':
  //        this.myEmailTable = this.table(s);
  //        this.objectStores.push(this.myEmailTable);
  //        this.allTables.set([ s ], this.emailTileTable)
  //        this.dexieTableObjectStore.setValue((s + 'Table'), this.myEmailTable);
  //        break;


  //      //case 'mynotification':
  //      //  this.myNotificationsI = this.table(s);
  //      //  this.objectStores.push(this.myNotificationsI);
  //      //  this.objectStoresDictionary.setValue((s + 'sI'), this.myNotificationsI);
  //      //  break;

  //      //case 'mynotificationid':
  //      //  this.myNotificationIdsI = this.table(s);
  //      //  this.objectStores.push(this.myNotificationIdsI);
  //      //  this.objectStoresDictionary.setValue((s + 'sI'), this.myNotificationIdsI);
  //      //  break;

  //      case 'situserid':
  //        this.sitUserIdTable = this.table(s);
  //        this.objectStores.push(this.sitUserIdTable);
  //        this.dexieTableObjectStore.setValue((s + 'Table'), this.sitUserIdTable);
  //        break;

  //      case 'situser':
  //        this.sitUserTable = this.table(s);
  //        this.objectStores.push(this.sitUserTable);
  //        this.dexieTableObjectStore.setValue((s + 'Table'), this.sitUserTable);
  //        break;
  //    }
  //    // debugger;
  //  })
  //}
  // ---------------------------------------
