'use-strict'
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
    ElementRef,
    Inject, Input, OnDestroy, OnInit, Renderer2, ViewChild
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { Heartbeat } from '../../../models/common/heartbeat.model';
import { OffOn } from '../../../models/offOn/offOn.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { MemberServiceService } from '../../../services/memberServiceService/memberServiceService.service';
import { ProfileServiceService } from '../../../services/profileServiceService/profileServiceService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { CopyServiceStatic } from '../../../services/staticServices/commonStaticServices/copyServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { OffOnService } from '../../../services/coreServiceService/offOnService';
import { StringServiceStatic } from '../../../services/staticServices/stringServiceStatic.service';
import { ProfileNameService } from '../../../services/profileServiceService/profileNameService.service';


// *****************************************************************************************
// ref:1) https:// www.positronx.io/angular-checkbox-tutorial/
// ref:2) https:// www.sitepoint.com/replacing-radio-buttons-without-replacing-radio-buttons/
//
// *****************************************************************************************
// Note:  The offOn indicator variables are moved to ProfileTile from component.
// TODO:  Once the heatrbeat-systems is complete, this component need to be updated.
// *****************************************************************************************

@Component({
  selector: 'app-off-on-profile-name',
  templateUrl: './offOnProfileName.component.html',
  styleUrls: ['./offOnProfileName.component.scss'],
  // 1)OnPush->requires an Observable to refresh the view; 2)Default->automatic refresh
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class OffOnProfileNameComponent implements OnInit, OnDestroy, AfterViewInit
{
  public httpService! : HttpService;
  public offOnService! : OffOnService;
  public profileNameService! : ProfileNameService;
  public rendererService!: RendererService;
  public renderer: Renderer2;
  private emitterDestroyed$: Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  public hbCounter = 0;
  public heartbeat: Heartbeat = new Heartbeat();
  public isFormSubmitted = false;
  public isMouseIn = false;
  public isMobilevar = false;
  public isOnLine = false;
  public isRequired = false;
  public loginSuccess! : LoginSuccess;
  @Input() offOn : OffOn = new OffOn();
  @Input() profileTile : ProfileTile = new ProfileTile();
  @Input() profileName = '';
  public returnUrl = '';
  @Input() sitUser: SitUser = new SitUser();
  public tempElem!: HTMLElement;
  public timer : any;
  public timerMap : Map<any, any> = new Map();
  public tempElemName = '';
 // -----------------------------------------------------------
  @ViewChild('spanAway') spanAwayElem! : ElementRef;
  @ViewChild('spanRadio') spanRadioElem! : ElementRef;
 // ---------------------------------------------------------------------------------
  constructor(private cdr: ChangeDetectorRef,
    private coreServiceService : CoreServiceService,
    private memberServiceService : MemberServiceService,
    private profileServiceService : ProfileServiceService,
    private rendererServiceService: RendererServiceService,
    @Inject(Document) Document: any
     ) {
    if ( this.rendererServiceService ) { this.rendererService = this.rendererServiceService.getRendererService(); }
    if (this.coreServiceService) {
      this.httpService = this.coreServiceService.getHttpService();
      this.offOnService = this.coreServiceService.getOffOnService();
    }
    if (this.rendererService) { this.renderer = this.rendererService.getRenderer(); }
        
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
    }  
    if (this.profileServiceService) {
      this.profileNameService = this.profileServiceService.getProfileNameService();
    }

    // this.renderer = this.rendererFactory.createRenderer(null, null);
    // ref:https:// stackoverflow.com/questions/58989990/error-staticinjectorerrorappmodulesomecomponent-renderer2-when-creating
    this.renderer = this.rendererService?.getRenderer();
    // --------------------------------
  }
 // ---------------------------------------------------------------------------------
  ngOnInit () : void
  {      
    // --------------------------------------------------------------    
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isOnLine = EmitterSubjectService.getIsOnLine();


     // debugger;   
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.offOn) || this.offOn.sitUserId === 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile?.offOn) && this.profileTile?.offOn?.sitUserId > 0) {
        this.offOn = CopyServiceStatic.copyOffOnIfExists(this.offOn, this.profileTile.offOn);        
      }
    }  

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile.offOn)) {
        // debugger;       
        if (this.profileTile.offOn.sitUserId === 0 && this.profileTile.sitUserId > 0) {      // debugger;        
          this.profileTile.offOn.sitUserId = this.profileTile.sitUserId;

          // this.offOn = this.profileTile.offOn; 
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.offOn) && this.offOn.sitUserId === 0) {
            this.offOn.sitUserId = this.profileTile.sitUserId;
          }
        }
      }
      if (StringServiceStatic.isNullOrEmpty(this.profileName)) {
        this.profileName = this.profileTile.profileName;
      }
      if (StringServiceStatic.isNullOrEmpty(this.profileName)) {
        this.profileNameService.fetchProfileName(this.profileTile.sitUserId).subscribe(data => {
          this.profileName = data;
          this.ngAfterViewInit();
        });
      }
    }
    // 
    // --------------------------------------------------------------
    //EmitterSubjectService.checkOffOnStatusEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe(result => {
    //    // debugger;
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) && result.sitUserId > 0) {
          
    //      this.offOn = JSON.parse(JSON.stringify(result));
    //      debugger;
    //      // this.offOn = this.offOnService.setOffOnStatusColor(this.offOn);
    //      this.ngAfterViewInit();          
    //    }
    //  })
    // --------------------------------------------------------------
    //EmitterSubjectService.checkOffOnStatusFromProfileTileEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe(result => {
    //    debugger;
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) && result.sitUserId > 0) {          
    //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.offOn) && result.offOn.sitUserId > 0) {
    //        result.offOn = this.offOnService.setOffOnIds(result.offOn);

    //        this.offOn = result.offOn;
    //        this.offOn.offOnAwayElem = this.spanAwayElem;
    //        this.offOn.offOnRadioElem = this.spanRadioElem;
    //        console.log('checkOffOnStatusDiaApi.sitUserId = ' + this.offOn.sitUserId);
    //        this.offOnService.checkOffOnStatusDiaApi(this.offOn);            
    //      }
    //    }
    //  })
  }
  // ---------------------------------------------------------------
  ngAfterViewInit (): void
  {
    // debugger;
    // DO NOT get ProfileTile from Emitter here!
    
    this.cdr.detectChanges();
    this.offOn = this.refreshNgAfterViewInit(this.offOn);
  }
 // ---------------------------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    
    this.timerMap.forEach(timer => clearTimeout(timer));
    this.timerMap.clear();

    this.offOn = new OffOn();
    // debugger;
  }
  // --------------------------------------------------------------
  checkMouseEvent ( event : Event ) : boolean
  {
    if ( event ) { this.isMouseIn = event.type.indexOf( 'MouseEvent' ) !== -1 ? true : false; }
    this.isMouseIn = !this.isMouseIn;
    return this.isMouseIn;
  }
  // --------------------------------------------------------------
  public checkOffOnStatusDiaApi (offOn : OffOn) : any {
    this.offOnService.checkOffOnStatusDiaApi(this.offOn);  
  } 
  //---------------------------------------------------------------
  // Note: calling this method is fastest thus far to update the UI
  // ---------------------------------------------------------------
  refreshNgAfterViewInit (offOn : OffOn) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.sitUserId === this.offOn.sitUserId) {
      this.offOn = this.checkOffOnStatusDiaApi(offOn);

      // this.offOn = this.checkOffOnStatusDiaApi(offOn);
      // this.ngAfterViewInit();

      // debugger;
      return offOn;
    }
  }
  // ---------------------------------------------------------------
  // Not in use
  // ---------------------------------------------------------------
  updateOffOnForHeartbeat (result : Heartbeat) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId === result.sitUserId){
        this.profileTile.heartbeat = result;
			}
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.offOn) && this.offOn.sitUserId === result.sitUserId) {
        this.offOn.heartbeat = result;
      }
      this.offOn = this.setupOffOn(this.offOn);
      
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.offOn)) {
        // debugger;
        this.offOn = this.offOnService.setOffOnStatusColor(this.offOn);
        
      }
    }
  }
  // ---------------------------------------------------------------------------------
  clearTimerMap (): void {
    this.timerMap.clear();
  }
  // ---------------------------------------------------------------------------------
  isRequiredPassed () {
    return this.offOn.offOnResult.offOnSelection.length > 0 ? true : false;
  }
  
  // ---------------------------------------------------------------
  getOffOn (): OffOn {
    return this.offOn;
  }
  // ---------------------------------------------------------------
  setOffOn (offon: OffOn) : void {
    this.offOn = offon;
    // debugger;
    this.ngAfterViewInit();
  } 
  // ---------------------------------------------------------------
  setRadioElements () : void {
    this.offOnService.spanAwayElement = this.spanAwayElem;
    EmitterSubjectService.setOffOnElement(this.spanAwayElem as ElementRef, 'spanAway');
    this.offOnService.spanRadioElement = this.spanRadioElem;
    EmitterSubjectService.setOffOnElement(this.spanRadioElem as ElementRef, 'spanRadio');
  } 
  // ---------------------------------------------------------------
  // Not in use
  // ---------------------------------------------------------------
  setupOffOn (offOn : OffOn) : OffOn {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.sitUserId > 0) {
      // debugger;
      offOn = this.offOnService.setOffOnIds(offOn);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.spanAwayElem)) {
        // debugger;
        offOn.offOnAwayElem = this.spanAwayElem.nativeElement;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnAwayElem)) {
        // debugger;
        EmitterSubjectService.setOffOnElement(offOn.offOnAwayElem as ElementRef, 'spanAway');
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.spanRadioElem)) {
        // debugger;
        offOn.offOnRadioElem = this.spanRadioElem.nativeElement;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnRadioElem)) {
        // debugger;
        EmitterSubjectService.setOffOnElement(offOn.offOnRadioElem as ElementRef, 'spanRadio');
      }
    }
    return offOn;
  }
  // --------------------------------------------------------------
}

