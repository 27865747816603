export class ViewportDimension {
  public name = '';
  public id = 0;
  public vw = 0;
  public vh = 0;
  public centerX = 0;
  public centerY = 0;
  public date!: Date;
  constructor() {
  }
}
