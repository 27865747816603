'use-strict'
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { PagerButtonComponent } from './pagerButton/pagerButton.component';
import { PagerChatHistoryComponent } from './pagerChatHistory/pagerChatHistory.component';
import { PagerVerticalGlyphForViewMemberComponent } from './pagerVerticalGlyphForViewMember/pagerVerticalGlyphForViewMember.component';
import { PagerVerticalGlyphForPhotoMgmtComponent } from './pagerVerticalGlyphForPhotoMgmt/pagerVerticalGlyphForPhotoMgmt.component';
import { PagerVPhotoNavComponent } from './pagerVPhotoNav/pagerVPhotoNav.component';

const pagerRoutes : Routes = [
  {
    path: '',
    children: [
      
      { path: 'pagerButton', component: PagerButtonComponent },
      { path: 'pagerChatHistory', component: PagerChatHistoryComponent },
      { path: 'pagerVerticalPhotoNav', component: PagerVPhotoNavComponent },
      { path: 'pagerVerticalViewMember', component: PagerVerticalGlyphForViewMemberComponent },
      { path: 'pagerVerticalPhotoMgmt', component: PagerVerticalGlyphForPhotoMgmtComponent },
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild( pagerRoutes ),
    CommonModule,
    FormsModule,  ],
  exports: [RouterModule]
})
export class PagerRoutingModule { }
