'use-strict'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlyphiconMenuService } from '../../services/GlyphiconService/glypgiconMenuService.service';
import { PagerPhotoMgmtService } from '../../services/pagerServiceService/pagerPhotoMgmtService.service';
import { PagerService } from '../../services/pagerServiceService/pagerService.service';
import { PagerServiceChatHistory } from '../../services/pagerServiceService/pagerServiceChatHistory.service';
import { PagerServiceService } from '../../services/pagerServiceService/pagerServiceService.service';
import { PagerSupportService } from '../../services/pagerServiceService/pagerSupportService.service';
import { DirectiveFilterPipeModule } from '../directiveFilterPipeModule/directiveFilterPipe.module';
import { AGlyphiconComponent } from './aGlyphicon/aGlyphicon.component';
import { PagerRoutingModule } from './pager-routing.module';
import { PagerButtonComponent } from './pagerButton/pagerButton.component';
import { PagerChatHistoryComponent } from './pagerChatHistory/pagerChatHistory.component';
import { PagerVerticalGlyphForPhotoMgmtComponent } from './pagerVerticalGlyphForPhotoMgmt/pagerVerticalGlyphForPhotoMgmt.component';
import { PagerVerticalGlyphForViewMemberComponent } from './pagerVerticalGlyphForViewMember/pagerVerticalGlyphForViewMember.component';
import { PagerVPhotoNavComponent } from './pagerVPhotoNav/pagerVPhotoNav.component';



@NgModule({
  declarations: [
    AGlyphiconComponent,
    PagerButtonComponent,
    PagerChatHistoryComponent,
    PagerVPhotoNavComponent,
    PagerVerticalGlyphForViewMemberComponent,
    PagerVerticalGlyphForPhotoMgmtComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DirectiveFilterPipeModule,
    PagerRoutingModule,
  ],
  exports: [
    AGlyphiconComponent,
    PagerButtonComponent,
    PagerChatHistoryComponent,
    PagerVPhotoNavComponent,
    PagerVerticalGlyphForViewMemberComponent,
    PagerVerticalGlyphForPhotoMgmtComponent,
    PagerRoutingModule,
  ],
  providers: [
    { provide: GlyphiconMenuService },
    { provide: PagerService },
    { provide: PagerServiceService },
    { provide: PagerServiceChatHistory },
    { provide: PagerPhotoMgmtService },
    { provide: PagerSupportService },
  ]
})
export class PagerModule { }
