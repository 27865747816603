<!--(tap)="onTap($event)" -->
<div class="flex-container" style="top:0rem;position: relative; height: 60%; width:100%;">
  <div [@slideYRotateY] id="landingPageComponentId" class="flex-item-main increase"
       style="position:absolute; clear: both; display: block; width: auto; height: 4em; padding: 0.5rem;">
    <!--before-loggedin-->
    <div *ngIf="!isLoggedIn || signedInUserId === 0">
      <div class="flex-parent-col">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('about', 0);" class="colN boxShadowDark hvr-underline-from-center" style="margin-top: 43px; background: transparent;">
          
          <div class="inner" style="margin:39px 0 0 -5px;background:transparent;">
            <div class="inner-button2" style="background: transparent;">
              
            </div>
          </div>
        </a>
        <a routerLink="" routerLinkActive="active" (click)="setRoute('about', 0);" class="colS boxShadowDark hvr-underline-from-center" style="margin-top: 39px; background: transparent;">
          <div class="inner" style="margin: 39px 0 0 -11px; background: transparent;">
            <div class="inner-button2" style="background: transparent;">
              <img id="logoId" class=" smallerCircle img-circle coinHalf" src="/assets/photos/logo.png"
                   style="background: transparent;
                padding: 0rem;
                margin: 0.05rem;
                z-index: 13;
                left: 33%;
                right:33%;
                height: 33px;
                width: 33px;" />
            </div>
          </div>
        </a>
      </div>
      <div class="flex-parent-row" style="margin: -200px 0 0 -19px; position: absolute;">
        <!--routerLink="" routerLinkActive="active"-->
        <a routerLink="" routerLinkActive="active" (click)="setRoute('index', 0);" class="rowW boxShadowDark hvr-underline-from-center"
           style="background: transparent;"
           [ngClass]="{'loggedIn':isLoggedIn, 'loggedOut':!isLoggedIn}">
          <div class="inner" style="margin: 39px 0 0 0px; background: transparent;">
            <div class="inner-buttonGld2" style="background: transparent;">
              Welcome to Slakez
            </div>
          </div>
        </a>
        <a routerLink="" routerLinkActive="active" (click)="setRoute('index', 0);" class="rowE boxShadowDark hvr-underline-from-center" 
           style="background: transparent;"
           [ngClass]="{'loggedIn':isLoggedIn, 'loggedOut':!isLoggedIn}">
          <div class="inner" style="margin: 39px 0 0 -5px; background: transparent;">
            <div class="inner-buttonGld2" style="background: transparent;">
              Gay networking app
            </div>
          </div>
        </a>
      </div>
    </div>
    <!--end of before-loggedin-->
    <!--after-loggedin|| May need to remove?-->
    <div *ngIf="isLoggedIn || signedInUserId > 0">
      <div class="flex-parent-col">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('myChats', 0);" class="colN boxShadowDark hvr-underline-from-center" style="margin-top:43px;">
          <div class="inner" style="margin:39px 0 0 -5px;">
            <div class="inner-button2">
              Chat
            </div>
          </div>
        </a>
        <a routerLink="" routerLinkActive="active" (click)="setRoute('myEnotes', 0);" class="colS boxShadowDark hvr-underline-from-center" style="margin-top:39px;">
          <div class="inner" style="margin:39px 0 0 -11px;">
            <div class="inner-button2">
              Notification
            </div>
          </div>
        </a>
      </div>


      <div class="flex-parent-row" style="margin: -200px 0 0 -19px; position: absolute;">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('logout', 0);" class="rowW boxShadowDark hvr-underline-from-center"
           [ngClass]="{'loggedIn':isLoggedIn, 'loggedOut': !isLoggedIn}">
          <div class="inner" style="margin:39px 0 0 0px;">
            <div class="inner-buttonGld2">
              Logout
            </div>
          </div>
        </a>
        <a routerLink="" routerLinkActive="active" (click)="setRoute('memberscroll', 0);" class="rowE boxShadowDark hvr-underline-from-center"
           [ngClass]="{'loggedIn':isLoggedIn, 'loggedOut':!isLoggedIn}">
          <div class="inner" style="margin:39px 0 0 -5px;">
            <div class="inner-buttonGld2">
              Members
            </div>
          </div>
        </a>
      </div>
    </div>
    <!--end of after-loggedin-->
  </div>
</div>
