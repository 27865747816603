import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { PayPalBreakdown } from './payPalBreakdown.model';

export class AmountModel {
  public breakdown = new PayPalBreakdown();
  public currency_code = '';
  public date! : Date;
  public value = 0;
  public hst = 0;
  public subscriptionMonths = 0;
  public subtotal = 0;
  public total = 0;
  // ----------------------------------------------------------------------------------
  constructor() {
    this.date = new Date;
  }
  // ----------------------------------------------------------------------------------
  public copy ( from : AmountModel ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.currency_code = from.currency_code;
      this.value = from.value;      
      this.breakdown.copy( from.breakdown );
      this.date = from.date;
      this.hst = from.hst;
      this.subscriptionMonths = from.subscriptionMonths;
      this.subtotal = from.subtotal;
      this.total = from.total;
    }
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n' +
        'currency_code: ' +
        this.currency_code +

        ',\n' +
        'date:' +
        this.date +      

        ',\n' +
        'value: ' +
        this.value +

        ',\n' +
        'hst: ' +
        this.hst +

        ',\n' +
        'subscriptionMonths: ' +
        this.subscriptionMonths +

        ',\n' +
        'subtotal: ' +
        this.subtotal +

        ',\n' +
        'total: ' +
        this.total +

        ',\n' +
        'breakdown: ' +
        this.breakdown.toString() +     
      
        '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
