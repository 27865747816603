'use-strict'
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddressComponent } from './address/address.component';
import { ArrayPipe } from './array-pipe.pipe';
import { CheckButtonComponent } from './checkButton/checkButton.component';
import { DateInputComponent } from './dateInput/dateInput.component';
import { AppSideBarDirective } from './directives/appsidebar.directive';
// import { DirectiveFilterPipeRoutingModule } from './directiveFilterPipe-routing.module';
import { CenterXDirective } from './directives/centerX.directive';
import { DateInputFormControlDirective } from './directives/dateInputFormControl.directive';
import { DisableElemDirective } from './directives/disableElem.directive';
import { ExpandMenuDirective } from './directives/expandMenu.directive';
import { ExpandMenuHomeDirective } from './directives/expandMenuHome.directive';
import { CheckPasswordDirective } from './directives/password.directive';
import { RefDirective } from './directives/ref.directive';
import { DisableScrollComponent } from './disableScroll/disableScroll.component';
import { DistanceComponent } from './distance/distance.component';
// import { GestureDirective } from './directives/gestureDirective.directive';
// import { GestureService } from '../../services/appServiceService/gestureService.service';
import { DropdownBoxComponent } from './dropdownBox/dropdownBox.component';
import { LogPipe } from './filters/logPipe';
import { ProfileTilesPipe } from './filters/profileTiles.pipe';
import { SafeFilterPipe } from './filters/safeFilter.pipe';
import { SafeKvPipe } from './filters/safeKv.pipe';
import { Safe } from './filters/safePipe.pipe';
import { TextFilterPipe } from './filters/textFilter.pipe';
import { ToNumberPipe } from './filters/toNumber.pipe';
import { UpgradedTilesPipe } from './filters/upgradedTiles.pipe';
import { IncrementorComponent } from './incrementor/incrementor.component';
import { OffOnComponent } from './offOn/offOn.component';
import { PageNameComponent } from './pageName/pageName.component';
import { ProfileNameComponent } from './profileName/profileName.component';
import { RadioBoxComponent } from './radioBox/radioBox.component';
import { OffOnProfileNameComponent } from './offOnProfileName/offOnProfileName.component';

@NgModule({
  declarations: [
    AddressComponent,
    AppSideBarDirective,
    ArrayPipe,
    CheckButtonComponent,
    CenterXDirective,
    DateInputComponent,
    DateInputFormControlDirective,
    DisableElemDirective,
    DisableScrollComponent,
    DistanceComponent,
    DropdownBoxComponent,
    ExpandMenuDirective,
    ExpandMenuHomeDirective,
    IncrementorComponent,
    LogPipe,
    OffOnComponent,
    CheckPasswordDirective,
    PageNameComponent,
    ProfileNameComponent,
    ProfileTilesPipe,
    RadioBoxComponent,
    RefDirective,
    SafeKvPipe,
    Safe,
    SafeFilterPipe,
    TextFilterPipe,
    ToNumberPipe,
    UpgradedTilesPipe,
    OffOnProfileNameComponent,
  ],
  imports: [ CommonModule, FormsModule, RouterModule, ],
  exports: [
    AddressComponent,
    AppSideBarDirective,
    ArrayPipe,
    CheckButtonComponent,
    CenterXDirective,
    DateInputComponent,
    DateInputFormControlDirective,
    DisableElemDirective,
    DisableScrollComponent,
    DistanceComponent,
    DropdownBoxComponent,
    ExpandMenuDirective,
    ExpandMenuHomeDirective,
    IncrementorComponent,
    LogPipe,
    OffOnComponent,
    OffOnProfileNameComponent,
    PageNameComponent,
    ProfileNameComponent,
    ProfileTilesPipe,
    RadioBoxComponent,
    RefDirective,
    Safe,
    SafeFilterPipe,
    SafeKvPipe,
    TextFilterPipe,
    ToNumberPipe,
    UpgradedTilesPipe ],

  providers: [
    { provide: AppSideBarDirective },
    { provide: ArrayPipe },
    { provide: CenterXDirective }, // horizontally self-centering-pipe
    { provide: ExpandMenuDirective },
    { provide: ExpandMenuHomeDirective },
    { provide: DateInputFormControlDirective },
    { provide: DisableElemDirective },
    { provide: LogPipe },
    { provide: ProfileTilesPipe },
    { provide: RefDirective },
    { provide: Safe },
    { provide: SafeFilterPipe },
    { provide: SafeKvPipe },
    { provide: TextFilterPipe },
    { provide: ToNumberPipe },
    { provide: UpgradedTilesPipe },
  ],
})
export class DirectiveFilterPipeModule { }
