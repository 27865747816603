import { BoxNonceEntity } from '../boxNonce/boxNonceEntity.model';

export class BoxEmailBn {
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public communicationId = 0;
  public isInbox = true;
  public sitUserId = 0;
  public signedInUserId = 0;
  public boxType = '';
  constructor() { }
}
