"use strict";

import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as signalR from '@microsoft/signalr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Chat } from '../../models/chat/chat.model';
import { ChatMessage } from '../../models/chat/chatMessage.model';
import { ChatConnectionInfo } from '../../models/communication/chatConnectionInfo.model';
import { ChatService } from '../communicationServiceService/chatService';
import { CoreServiceService } from '../coreServiceService/coreServiceService.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { FR } from 'angular-mydatepicker';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
@Injectable({
  providedIn: 'any'
})
export class SignalRService implements OnInit, OnDestroy {
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  public cancellationToken : any;
  public chatConnectionInfo : ChatConnectionInfo = new ChatConnectionInfo();
  public isConnectionEstablished = false;
  public chat : Chat = new Chat();
  public newMessage : ChatMessage = new ChatMessage();
  public chatData : Chat[] = [];
  public count = 1;
  public delay = 500;
  public eventSource : any = null;
  public groupChatData : Chat[] = [];
  public hubConnection : signalR.HubConnection;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public senderEmail = '';
  public timer : any;
  public timerArr : any[] = [];
  // -----------------------------------------------------
  constructor (
    private activatedRoute : ActivatedRoute,
    private chatService : ChatService,
    private coreServiceService : CoreServiceService,
    @Inject(Window) window : Window) {
    // this.activatedRoute.params.subscribe(params => this.signedInUserId = params.id);

  }
  // -----------------------------------------------------------------------
  ngOnInit () {
    // Note: SignalR is initialized at ChatHistoryComponent.    
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.timerArr.map(e => clearInterval(e));
  }
  // -------------------------------------------------------------
  public connectAndJoin (): any {
    this.hubConnection = new signalR.HubConnectionBuilder()
      //.withUrl("https://slakez.com/chatHub", {
      //  withCredentials: false,
      //  skipNegotiation: true,
      //  transport: signalR.HttpTransportType.WebSockets })    
      .withUrl("/chatHub", {
        withCredentials: false,
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets      })
      .configureLogging(signalR.LogLevel.Information)
      .build();
    
    this.hubConnection.start().then(data => {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.hubConnection)) {
        this.hubConnection.invoke('Join', this.loginSuccess.signedInUserKey)
          .then((data : any) => {
            // debugger;
            console.log('user joined successfully: ' + data);
            // alert('user joined successfully: ' + data);
            if (data) {
              // debugger;
              this.loginSuccess.hasJoinedSignalR = true;
              EmitterSubjectService.setLoginSuccess(this.loginSuccess);
            }
          })
          .catch((err : any) => console.log(err));
      }
    }).catch(function (err) {
      console.error(err);
      this.reconnect; 
    })
    this.messageListeners(this.hubConnection);
    return this.hubConnection;   
  }
  // -------------------------------------------------------------
  public reconnect () {
  console.log('reconnecting...');
    setTimeout(this.connectAndJoin(), 2000);
}
  // -------------------------------------------------------------
  public messageListeners (hubConnection : any) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hubConnection)) {
      hubConnection.on('SendMessageToCaller', (function (data : any) {
        // debugger;
        var result1 : any;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.box)) {
          result1 = data;
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 1 ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 1 ].box)) {
          result1 = arguments[ 1 ];
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 0 ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 0 ].box)) {
          result1 = arguments[ 0 ];
          // debugger;
        }

        // debugger;

        EmitterSubjectService.emitChatMessage(result1);
        // console.log('Sent-message data: ' + result);
        // alert('Sent-message data: ' + result1);
        return true;
      }))

      // ----------------------------------------------------------
      hubConnection.on('SendMessage', (function (data : any) {
        // debugger;
        var result2 : any;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.box)) {
          result2 = data;
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 1 ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 1 ].box)) {
          result2 = arguments[ 1 ];
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 0 ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 0 ].box)) {
          result2 = arguments[ 0 ];
          // debugger;
        }

        // debugger;
        EmitterSubjectService.emitChatMessage(result2);
        // alert('Sent-message data: ' + result2);
        return true;
      }))

      // -------------------------------------------------------------
      hubConnection.on('SendNotification', (function (data : any) {
        // debugger;
        var result3 : any;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.box)) {
          result3 = data;
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 1 ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 1 ].box)) {
          result3 = arguments[ 1 ];
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 0 ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arguments[ 0 ].box)) {
          result3 = arguments[ 0 ];
          // debugger;
        }

        // debugger;
        EmitterSubjectService.emitNotificationActivity(result3);
        // alert('Sent-message data: ' + result2);
        return true;
      }))
    }
  }
  // -------------------------------------------------------------
  public sendMessage (messageBne : any) {

    if (StringServiceStatic.isNullOrEmpty(this.hubConnection)
      || signalR.HubConnectionState[ 'Connected' ] !== 'Connected') {
      this.hubConnection = this.connectAndJoin();
      // debugger;
    }

    if (!StringServiceStatic.isNullOrEmpty(this.hubConnection)
      && signalR.HubConnectionState[ 'Connected' ] === 'Connected') {
      // debugger;
      // alert('hub @SendMessage(): ');
      this.hubConnection.invoke("SendMessage", messageBne).catch(function (err) {
        return console.error(err);
      });
    }
    else {
      this.sendMessage(messageBne);
    }
  }
  // -------------------------------------------------------------
  public sendNotification (messageBne : any) {

    if (StringServiceStatic.isNullOrEmpty(this.hubConnection)
      || signalR.HubConnectionState[ 'Connected' ] !== 'Connected') {
      this.hubConnection = this.connectAndJoin();
      // debugger;
    }

    if (!StringServiceStatic.isNullOrEmpty(this.hubConnection)
      && signalR.HubConnectionState[ 'Connected' ] === 'Connected') {
      // debugger;
      // alert('hub @SendMessage(): ');
      this.hubConnection.invoke("SendNotification", messageBne).catch(function (err) {
        return console.error(err);
      });
    }
    else {
      this.sendNotification(messageBne);
    }
  }
  // -------------------------------------------------------------
}