'use strict'
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as nacl from 'tweetnacl';
import * as naclUtil from 'tweetnacl-util';
// import * as Enums from 'enums.json'; // ref:https:// www.techiediaries.com/angular-local-json-files/
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { NaclPairClient } from '../../../models/crypto/naclPairClient.model';
import { Salt } from '../../../models/crypto/salt.model';
import { KvAny } from '../../../models/keyValue/kvAny.model';
import { HttpService } from '../../coreServiceService/httpService.service';
import { DateStringServiceStatic } from './dateStringServiceStatic.service';
import { EmitterSubjectService } from '../../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { InMemoryDataServiceStatic } from './../inMemoryDataServiceStatic.service';
import { StaticServiceService } from './../staticServiceService.service';
// import { StringServiceStatic } from './../stringServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../stringServiceStatic.service';
import { PhotoBn } from '../../../models/common/photoBn.model';


// --------------------------------------


    // Documentation
    // nacl.util.decodeUTF8(string)

    // Decodes string and returns Uint8Array of bytes.
    //  nacl.util.encodeUTF8(array)

    // Encodes Uint8Array or Array of bytes into string.
    //  nacl.util.decodeBase64(string)

    // Decodes Base - 64 encoded string and returns Uint8Array of bytes.
    //  nacl.util.encodeBase64(array)

    // Encodes Uint8Array or Array of bytes into string using Base - 64 encoding.
    // --------------------------------------

@Injectable({
  providedIn: 'root',
})

/*
 * ======================================================================================
 * tweetnacl
 * Ref:http:// ed25519.cr.yp.to/
 * Ref:https:// medium.com/sharenowtech/high-speed-static-key-cryptography-in-javascript-part-2-digital-signatures-3e58876d1dff
 * Ref:https:// medium.com/sharenowtech/high-speed-static-key-cryptography-in-javascript-part-1-3eefb6f91f77
 * Note: Specifically, NaCl uses elliptic-curve cryptography, not RSA; it uses an elliptic curve, Curve25519,
 *       that has several advanced security features;
 *       it uses Salsa20, not AES(although it does include an AES implementation on the side); it uses Poly1305, not HMAC;
 *       and it uses EdDSA, not ECDSA.
 * --------------------------------------------------------------------------------------
 * The following are example of TextEncoder/TextDecoder:
 * var uint8array = new TextEncoder('utf-8').encode(string);
 * var string = new TextDecoder('utf-8').decode(uint8array);
 */
export abstract class SlakezSaltServiceStatic implements OnDestroy
{
 
;
  constructor (
    nacl, naclUtil
  )
  {
    nacl.util = require('tweetnacl-util');
    SlakezSaltServiceStatic.initialize();
  }
  static httpService : HttpService;
  static inMemoryDataServiceStatic : InMemoryDataServiceStatic;
  // -------------------------------------------
  public static chabi : 'KA8j/zeXyTPXt0+UTogm1tQk2r0+mUQ9G34PBtxYnHA=';
  public static chabiGopon : 'dJKh9zxbQquNHzKCIkyR11h9svmQBzazOcAuqmWPb/A=';
  static enums: any;
  static keyType: string[] = ['pair', 'sign', 'shared'];
  static saltType: string[] = ['Public', 'Secret', 'PassP'];
  static loginSuccess: LoginSuccess = new LoginSuccess();
  static passPhrase: any;
  static naclPairClient: NaclPairClient = new NaclPairClient();
  static saltBn: BoxNonceEntity = new BoxNonceEntity();
  static salt : Salt = new Salt();
  static signedInUserId = 0;
  static talaChabi : any;
  static timer : any;
  static timerArray : any[] = [];
  static unsalted : string = '';
  // -------------------------------------------
  private static emitterDestroyed$ : Subject<boolean> = new Subject();

  // ---------------------------------------------------------------
  ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    SlakezSaltServiceStatic.emitterDestroyed$.next(true);
    SlakezSaltServiceStatic.emitterDestroyed$.complete();
    SlakezSaltServiceStatic.timerArray.forEach((timer) => clearInterval(timer));
    return true;
  }
  // ---------------------------------------------------------------
  // ref: nacl-util.js:
  // apis:  1) util.decodeUTF8
  //        2) util.encodeUTF8
  //        3) util.encodeBase64
  //        4) util.decodeBase64

  public validateBase64 (s) {
    if (!(/^(?:[A-Za-z0-9+\/]{2}[A-Za-z0-9+\/]{2})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/.test(s))) {
      throw new TypeError('invalid encoding');
    }
  }
  // ---------------------------------------------------------------
   static processTwoChabi (): any
   {
     let twoChabi = InMemoryDataServiceStatic.getTwoChabi();
    // debugger;
    let salt = new Salt();
    // Jump-start the application's communication channel:
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( twoChabi ) && twoChabi.length > 1 )
    {
      // debugger;
      salt.publicKey = SlakezSaltServiceStatic.chabi = twoChabi[ 0 ].value;
      salt.secretKey = SlakezSaltServiceStatic.chabiGopon = twoChabi[ 1 ].value;
      salt = SlakezSaltServiceStatic.resetSaltShaker( salt );
      return salt;
    }
   }
  // ---------------------------------------------------------------
  //  Note: The Tala-chabi story:
  //        'chabi' can be found in InMemoryDataServiceStatic.
  //        This service always have a default value and in addition
  //        fetches the freshed one from the server.
  //        This should be the only place where other than in-memory-data chabi can be found.
  // ---------------------------------------------------------------
  static setSaltShaker(): any {


    // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( salt.pk ) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( salt.sk ) )
    // {
    //  SlakezSaltServiceStatic.naclPairClient.publicKey = salt.pk; // naclUtil.decodeBase64(this.salt.pk);
    //  SlakezSaltServiceStatic.naclPairClient.secretKey = salt.sk; // naclUtil.decodeBase64(this.salt.sk);
    //  SlakezSaltServiceStatic.naclPairClient.modelName = 'saltyPair';
    //  SlakezSaltServiceStatic.naclPairClient.id = SlakezSaltServiceStatic.loginSuccess.signedInUserId;
    //  SlakezSaltServiceStatic.naclPairClient.date = DateStringServiceStatic.getTicks( new Date() ).toString();
    // }
    // else {

      SlakezSaltServiceStatic.salt =  this.processTwoChabi();
      // debugger;

      const localSaltyPair = new NaclPairClient();
      localSaltyPair.publicKey = SlakezSaltServiceStatic.salt.publicKey;
      localSaltyPair.secretKey = SlakezSaltServiceStatic.salt.secretKey;
      localSaltyPair.id = SlakezSaltServiceStatic.loginSuccess.signedInUserId;
      localSaltyPair.modelName = 'saltyPair'; // key
      localSaltyPair.date = DateStringServiceStatic.getTicks(new Date()).toString();

      EmitterSubjectService.setSaltyPair( localSaltyPair ); // set it on emitterService
      // cdebugger;

    SlakezSaltServiceStatic.naclPairClient = localSaltyPair;
    return localSaltyPair;
    // }
    // return '';
  }
  // ---------------------------------------------------------------
  static resetSaltShaker (salt: Salt) : any
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( salt ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( salt.publicKey ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( salt.secretKey ) )
    {
      this.naclPairClient.publicKey = SlakezSaltServiceStatic.salt.publicKey = salt.publicKey; // naclUtil.decodeBase64(this.salt.pk);
      this.naclPairClient.secretKey = SlakezSaltServiceStatic.salt.secretKey = salt.secretKey; // naclUtil.decodeBase64(this.salt.sk);
    }
    else
    {
      this.naclPairClient.publicKey = SlakezSaltServiceStatic.salt.publicKey = this.chabi;
      this.naclPairClient.secretKey = SlakezSaltServiceStatic.salt.secretKey = this.chabiGopon;
      // debugger;
    }
    this.naclPairClient.id = SlakezSaltServiceStatic.loginSuccess.signedInUserId;
    this.naclPairClient.modelName = 'saltyPair'; // key
    this.naclPairClient.date = DateStringServiceStatic.getTicks( new Date() ).toString();

    EmitterSubjectService.setSaltyPair( this.naclPairClient ); // set it on emitterService

    return SlakezSaltServiceStatic.naclPairClient;
  }
  /*
   * =========================================================================
   * Note: the original 'NaclPair' has only static&private key fields, no date or other information
   *       hence if NaclPairClient 's date is null or empty, it could not copy from 'NaclPair',
   *       hence has to be considered 'Not-Expired'
   * -------------------------------------------------------------------------
   * TODO: Test!!
   */
  static hasPairExpired(pair: NaclPairClient): boolean {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pair)) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pair.date)) {
        return false;
      }
      return DateStringServiceStatic.compareTicks(pair.date, DateStringServiceStatic.getTicks(DateStringServiceStatic.addMonths(new Date(), -3))) <= 0;
    }
    return true;
  }
  // --------------------------------------------------------------------------------------
  // ref:https://stackoverflow.com/questions/11616630/how-can-i-print-a-circular-structure-in-a-json-like-format
  static getCircularReplacer = () =>
  {
    const seen = new WeakSet();
    return (key : any, value : any ) =>
    {
      if ( typeof value === 'object' && value !== null )
      {
        if ( seen.has( value ) )
        {
          return;
        }
        seen.add( value );
      }
      return value;
    };
  }  
  // usage: JSON.stringify ( circularReference, getCircularReplacer());
  // --------------------------------------------------------------------------------------

  static saltModel (model : any): BoxNonceEntity {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      return this.boxSalt( JSON.stringify( model, this.getCircularReplacer())) as BoxNonceEntity;
    }
    return null as any;
  }

  // -----------------------------------------------------------
  unSaltModel (model : any) : any {
    // debugger;
    if (model) {
      return SlakezSaltServiceStatic.boxUnsalt(model);
    }
    return null;
  }
  /*
 * --------------------------------------------------------------------------------------
 * To be used when cipherbox needs to be in bytes (box and nonce)
 */
  static boxUnsalt (cipherbox : BoxNonceEntity) : any {
    let payload : any;
    let tNaclPairClient : any;
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox.nonce) && cipherbox.box.length > 0) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient)
        || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient.publicKey)
        || SlakezSaltServiceStatic.naclPairClient.publicKey.length === 0) {
        this.naclPairClient = SlakezSaltServiceStatic.setSaltShaker();
      }

      // since the emitGetKeys may have been called, retrive the SaltyPair once again.
      // this.naclPairClient = (this.emitterService as EmitterService).getSaltyPair();
      // let boxString = cipherbox.boxStr// JSON.stringify(cipherbox.box, this.getCircularReplacer());
      // let nonceString = JSON.stringify(cipherbox.nonce, this.getCircularReplacer());
      // let boxAb = StringServiceStatic.b64ToArrBuff(cipherbox.box);
      // let boxStrAb = StringServiceStatic.b64ToArrBuff(cipherbox.boxStr);
      // let nonceAb = StringServiceStatic.b64ToArrBuff(cipherbox.nonce);
      // debugger;
      if (
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient.publicKey) &&
        this.naclPairClient.publicKey.toString().length > 0 &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient.secretKey) &&
        this.naclPairClient.secretKey.toString().length > 0
      ) {
        //let boxUnit8Arr = FrequentlyUsedFunctionsServiceStatic.b64ToArrBuff(cipherbox.box.toString());
        //let nonceUnit8Arr = FrequentlyUsedFunctionsServiceStatic.b64ToArrBuff(cipherbox.nonce.toString());
        let pkUnit8Arr = naclUtil.decodeBase64(SlakezSaltServiceStatic.naclPairClient.publicKey);
        let skUnit8Arr = naclUtil.decodeBase64(SlakezSaltServiceStatic.naclPairClient.secretKey);
        // debugger;
        payload = nacl.box.open(
          //naclUtil.decodeBase64(cipherbox.boxAb),
          //naclUtil.decodeBase64(cipherbox.nonceAb),

          //original-working:
          //naclUtil.decodeBase64(cipherbox.box),
          //naclUtil.decodeBase64(cipherbox.nonce),
          // ---------------------------------------------------
          // original:
          // ---------------------------------------------------
          naclUtil.decodeBase64(cipherbox.box.toString()),
          naclUtil.decodeBase64(cipherbox.nonce.toString()),
          //boxUnit8Arr,
          //nonceUnit8Arr,
          pkUnit8Arr,
          skUnit8Arr
          // ---------------------------------------------------
          //naclUtil.decodeBase64(boxString),
          //naclUtil.decodeBase64(nonceString),
          //naclUtil.decodeBase64( SlakezSaltServiceStatic.naclPairClient.publicKey),
          //naclUtil.decodeBase64( SlakezSaltServiceStatic.naclPairClient.secretKey),
        );
        try {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(payload)) {
            // const decoded1 = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(payload);
            const decoded1 = naclUtil.encodeUTF8(payload);
            // debugger;

            return decoded1;
          }
        }
        catch (e) {
          console.log('error occured during unsalting. error.message: ' + e.message);
          // debugger;
        }
      }
    }
    return '';
  }
  // -----------------------------------------------------------

  /*
   * --------------------------------------------------------------------------------------
   * To be used when cipherbox needs to be in bytes (box and nonce)
   */
  static isBoxUnsalt(cipherbox: BoxNonceEntity): any {
    let payload: any;
    let tNaclPairClient : any;
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox.nonce) && cipherbox.box.length > 0) {
      if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient )
        || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient.publicKey )
        || SlakezSaltServiceStatic.naclPairClient.publicKey.length === 0 )
      {
        this.naclPairClient = SlakezSaltServiceStatic.setSaltShaker();
      }

      // since the emitGetKeys may have been called, retrive the SaltyPair once again.
      // this.naclPairClient = (this.emitterService as EmitterService).getSaltyPair();
      // let boxString = cipherbox.boxStr// JSON.stringify(cipherbox.box, this.getCircularReplacer());
      // let nonceString = JSON.stringify(cipherbox.nonce, this.getCircularReplacer());
      // let boxAb = StringServiceStatic.b64ToArrBuff(cipherbox.box);
      // let boxStrAb = StringServiceStatic.b64ToArrBuff(cipherbox.boxStr);
      // let nonceAb = StringServiceStatic.b64ToArrBuff(cipherbox.nonce);
      // debugger;
      if (
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient.publicKey) &&
        this.naclPairClient.publicKey.toString().length > 0 &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient.secretKey) &&
        this.naclPairClient.secretKey.toString().length > 0
      ) {
        //let boxUnit8Arr = FrequentlyUsedFunctionsServiceStatic.b64ToArrBuff(cipherbox.box.toString());
        //let nonceUnit8Arr = FrequentlyUsedFunctionsServiceStatic.b64ToArrBuff(cipherbox.nonce.toString());
        let pkUnit8Arr = naclUtil.decodeBase64(SlakezSaltServiceStatic.naclPairClient.publicKey);
        let skUnit8Arr = naclUtil.decodeBase64(SlakezSaltServiceStatic.naclPairClient.secretKey);
        // debugger;
        payload = nacl.box.open(
          //naclUtil.decodeBase64(cipherbox.boxAb),
          //naclUtil.decodeBase64(cipherbox.nonceAb),

          //original-working:
          //naclUtil.decodeBase64(cipherbox.box),
          //naclUtil.decodeBase64(cipherbox.nonce),
          // ---------------------------------------------------
          // original:
          // ---------------------------------------------------
          naclUtil.decodeBase64(cipherbox.box.toString()),
          naclUtil.decodeBase64(cipherbox.nonce.toString()),
          //boxUnit8Arr,
          //nonceUnit8Arr,
          pkUnit8Arr,
          skUnit8Arr
          // ---------------------------------------------------
          //naclUtil.decodeBase64(boxString),
          //naclUtil.decodeBase64(nonceString),
          //naclUtil.decodeBase64( SlakezSaltServiceStatic.naclPairClient.publicKey),
          //naclUtil.decodeBase64( SlakezSaltServiceStatic.naclPairClient.secretKey),
        );
        try {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(payload)) {
            // const decoded1 = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(payload);
            const decoded1 = naclUtil.encodeUTF8(payload);
            // debugger;

            this.unsalted = decoded1;
            return true;
          }
        }
        catch (e) {
          console.log('error occured during unsalting. error.message: ' + e.message);
          // debugger;
          return false;
				}
      }
      else return false;
    }
    else return false;
  }
  /*
   * --------------------------------------------------------------------------------------
   * To be used when ciphertext needs to be in bytes i.e. cipherbox == {box, nonce}
   */
  static boxSalt(message: string): any {
    //  debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(message) && message.toString().length > 0) {
      if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient )
        || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient.publicKey )
        || SlakezSaltServiceStatic.naclPairClient.publicKey.length === 0 )
      {
        this.naclPairClient = SlakezSaltServiceStatic.setSaltShaker();
      }

      // since the emitGetKeys may have been called, retrive the SaltyPair once again.
      // this.naclPairClient = EmitterSubjectService.getSaltyPair();
      // debugger;
      if (
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient.publicKey) &&
        this.naclPairClient.publicKey.toString().length > 0 &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.naclPairClient.secretKey) &&
        this.naclPairClient.secretKey.toString().length > 0
      ) {
        // generating one time nonce for encryption
        const nonce = nacl.randomBytes(24);
        /*
         * for test only
         * var nonceStr = naclUtil.encodeBase64(nonce);
         * var nonceStr2 = new TextDecoder('utf-8').decode(nonce);
         * debugger;
         */
        //let pkUnit8Arr = FrequentlyUsedFunctionsServiceStatic.b64ToArrBuff(SlakezSaltServiceStatic.naclPairClient.publicKey);
        //let skUnit8Arr = FrequentlyUsedFunctionsServiceStatic.b64ToArrBuff(SlakezSaltServiceStatic.naclPairClient.secretKey);

        let pkUnit8Arr = naclUtil.decodeBase64(SlakezSaltServiceStatic.naclPairClient.publicKey);
        let skUnit8Arr = naclUtil.decodeBase64(SlakezSaltServiceStatic.naclPairClient.secretKey);
       
        // debugger;
        
        const box = nacl.box(naclUtil.decodeUTF8(JSON.stringify(message)), nonce,
        // const box = nacl.box(FrequentlyUsedFunctionsServiceStatic.strTobase64(message), nonce,
          pkUnit8Arr,
          skUnit8Arr);
          //naclUtil.decodeBase64( SlakezSaltServiceStatic.naclPairClient.publicKey ),
          //naclUtil.decodeBase64( SlakezSaltServiceStatic.naclPairClient.secretKey ) );
         // debugger;

        return { box, nonce};
      }
    }
    return '';
  }
  // ----------------------------------------------------------------
  static unsaltPrimaryPicPhotoBnJson (photoBn : PhotoBn) : any {
    let pic = '';
    let boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.box)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.nonce)
      && photoBn.box.length > 0 && photoBn.nonce.length > 0) {
      // debugger;
      boxNonceEntity.box = photoBn.box;
      boxNonceEntity.nonce = photoBn.nonce;
      photoBn = SlakezSaltServiceStatic.boxUnsalt(boxNonceEntity);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn)
        && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.image)
          || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.imageString)
          || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.pic))) {
        return photoBn;
      }
      else return null;
    }
    // debugger;

  }
  /*
   * --------------------------------------------------------------------------------------
   * To be used when cipherbox needs to be in bytes (box and nonce)
   */
  /*
  static boxUnsaltFromUnit8Arr (cipherbox : BoxNonceEntity) : any {
    let payload : any;
    let tNaclPairClient : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox.nonce) && cipherbox.box.length > 0) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient)
        || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient.publicKey)
        || SlakezSaltServiceStatic.naclPairClient.publicKey.length === 0) {
        this.naclPairClient = SlakezSaltServiceStatic.setSaltShaker();
      }

      // since the emitGetKeys may have been called, retrive the SaltyPair once again.
      // this.naclPairClient = (this.emitterService as EmitterService).getSaltyPair();
      // let boxString = cipherbox.boxStr// JSON.stringify(cipherbox.box, this.getCircularReplacer());
      // let nonceString = JSON.stringify(cipherbox.nonce, this.getCircularReplacer());
      // let boxAb = StringServiceStatic.b64ToArrBuff(cipherbox.box);
      // let boxStrAb = StringServiceStatic.b64ToArrBuff(cipherbox.boxStr);
      // let nonceAb = StringServiceStatic.b64ToArrBuff(cipherbox.nonce);
      // debugger;
      if (
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient.publicKey) &&
        this.naclPairClient.publicKey.toString().length > 0 &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(SlakezSaltServiceStatic.naclPairClient.secretKey) &&
        this.naclPairClient.secretKey.toString().length > 0
      ) {
        let tcipherbox = naclUtil.encodeUTF8(cipherbox.box);
        let tciphernonce = naclUtil.encodeUTF8(cipherbox.nonce);
        payload = nacl.box.open(
          //naclUtil.decodeBase64(cipherbox.boxAb),
          //naclUtil.decodeBase64(cipherbox.nonceAb),

          //original-working:
          //naclUtil.decodeBase64(cipherbox.box),
          //naclUtil.decodeBase64(cipherbox.nonce),

          naclUtil.decodeBase64(tcipherbox),
          naclUtil.decodeBase64(tcipherbox),
          // ---------------------------------------------------
          // original:
          // ---------------------------------------------------
          //naclUtil.decodeBase64(cipherbox.box.toString()),
          //naclUtil.decodeBase64(cipherbox.nonce.toString()),
          // ---------------------------------------------------
          //naclUtil.decodeBase64(boxString),
          //naclUtil.decodeBase64(nonceString),
          naclUtil.decodeBase64(SlakezSaltServiceStatic.naclPairClient.publicKey),
          naclUtil.decodeBase64(SlakezSaltServiceStatic.naclPairClient.secretKey),
        );
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(payload)) {
          const decoded1 = naclUtil.encodeUTF8(payload);
          // debugger;

          return decoded1;
        }
      }
    }
    return '';
  } */
  /*
   * --------------------------------------------------------------------------------------
   * To be used when ciphertext needs to be in string
   */
  /* static stringSalt(message: string): string {
    if (
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(message) &&
      message.toString().length > 0 &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.naclPairClient) &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.naclPairClient.publicKey) &&
      this.naclPairClient.publicKey.toString().length > 0 &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.naclPairClient.secretKey) &&
      this.naclPairClient.secretKey.toString().length > 0
    ) {
      // generating one time nonce for encryption
      const nonce = nacl.randomBytes(24);

      const box = nacl.box(naclUtil.decodeUTF8(message), nonce, this.naclPairClient.publicKey, this.naclPairClient.secretKey);

      return naclUtil.encodeUTF8(box) + '#' + naclUtil.encodeUTF8(nonce);
    }
    return '';
  }
  /*
   * --------------------------------------------------------------------------------------
   * To be used when ciphertext is in string
   */
  /* static stringUnsalt(ciphertext: string): string {
    let boxUnit8Arr: any;
    let nonceUnit8Arr: any;
    let payload: any;
    /*
     * if (this.staticKey.toString().length < 5) this.getSalt('Public');
     * if (this.secretKey.toString().length < 5) this.getSalt('Pecret');
     */

   /* if (
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ciphertext) &&
      ciphertext.length > 0 &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.naclPairClient) &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.naclPairClient.publicKey) &&
      this.naclPairClient.publicKey.toString().length > 0 &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.naclPairClient.secretKey) &&
      this.naclPairClient.secretKey.toString().length > 0
    ) {
      const parts = ciphertext.split('#');

      if (parts.length === 2) {
        boxUnit8Arr = naclUtil.decodeBase64(parts[0]);
        nonceUnit8Arr = naclUtil.decodeBase64(parts[1]);
      }
      payload = nacl.box.open(boxUnit8Arr, nonceUnit8Arr, this.naclPairClient.publicKey, this.naclPairClient.secretKey);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(payload)) {
        const decoded = naclUtil.encodeUTF8(payload);

        return decoded;
      }
    }
    return '';
  } */
  // ---------------------------------------------------------------------------------
    // debugger;: )
  private static initialize ()
  {
    


    // debugger;
    SlakezSaltServiceStatic.inMemoryDataServiceStatic = StaticServiceService.getInMemoryDataServiceStatic();
    SlakezSaltServiceStatic.httpService = EmitterSubjectService.getHttpService();
    // debugger;
    // SlakezSaltServiceStatic.enums = ( Enums as any).default; // ref:https:// www.techiediaries.com/angular-local-json-files/
    fetch('./json/products.json').then(res => res.json())
    .then(jsonData => {
      SlakezSaltServiceStatic.enums = jsonData;
    });

    SlakezSaltServiceStatic.salt = SlakezSaltServiceStatic.processTwoChabi();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.enums ) )
    {

      EmitterSubjectService.setEnums( SlakezSaltServiceStatic.enums );
      // debugger;
    }

    // debugger;
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
        // debugger;
        SlakezSaltServiceStatic.loginSuccess = result; // JSON.parse(JSON.stringify(result));
        this.emitterDestroyed$.unsubscribe();

      } );


    SlakezSaltServiceStatic.loginSuccess = EmitterSubjectService.getLoginSuccess();
    SlakezSaltServiceStatic.signedInUserId = SlakezSaltServiceStatic.loginSuccess.signedInUserId;

    debugger;
    EmitterSubjectService.resetSaltShakerEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
        debugger;
        SlakezSaltServiceStatic.resetSaltShaker( result as Salt ); // JSON.parse(JSON.stringify(result));
        this.emitterDestroyed$.unsubscribe();

      } );

    // this.salt = (this.emitterService as EmitterService).getSalty();
    // var tNaclPairClient = (this.emitterService as EmitterService).getSaltyPair();
    // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tNaclPairClient) && !tNaclPairClient) {
    // //debugger;
    // this.naclPairClient = tNaclPairClient;
    // }

  /*
   * debugger;
   * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.naclPairClient) && this.hasPairExpired(this.naclPairClient)) {
   * debugger;
   * this.getKeys(this.keyType[0]);
   * }
   */
  }
  /*
   * -------------------------------------------------------------------------
   * NOTE: DO NOT DELETE THIS.!!!
   * NOTE: This has been moved to httpService inorder to eliminate circular-di
   * -------------------------------------------------------------------------
   * getKeys(ktype): any {
   * this.salt.ktype = ktype;
   * var url = '/SlakezSalt/GetKeys';
   * this.httpService.post(url, this.salt, 'json')
   *   .subscribe((data: any) => {
   *     //debugger;
   *     if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
   *       this.salt = data as Salt; //Note: server-send variables are all lower-case
   *       if (this.salt.ktype.indexOf('pair') != -1) {
   *         this.naclPairClient.staticKey = this.salt.publicKey// naclUtil.decodeBase64(this.salt.publicKey);
   *         this.naclPairClient.secretKey = this.salt.secretKey// naclUtil.decodeBase64(this.salt.secretKey);
   *         var localSaltyPair = new NaclPairClient();
   *         localSaltyPair.staticKey = this.naclPairClient.staticKey;
   *         localSaltyPair.secretKey = this.naclPairClient.secretKey;;
   *         localSaltyPair.id = this.loginSuccess.signedInUserId;
   *         localSaltyPair.modelName = 'saltyPair'; // key
   *         localSaltyPair.date = DateStringServiceStatic.getTicks(new Date()).toString();
   */

  /*
   *         var message = 'the size of SaltyPair data is: ' + localSaltyPair.toString().length;
   *         //alert (message);
   *         console.log(message);
   *         //(this.emitterService as EmitterService).emitMyErrorLog(message);
   */

  //         (this.emitterService as EmitterService).setSaltyPair(localSaltyPair); //set it on emitterService
  //         this.localStorageService.setSaltyPair(localSaltyPair); // save to localStorage
  //         //debugger;
  //       }
  //       else if (this.salt.ktype.indexOf('sign') != -1) { /*TODO:capture sign-keys*/ }
  //       else if (this.salt.ktype.indexOf('shared') != -1) { /*TODO:capture shared-keys*/ }
  //     }
  //   });
  // return;
  // }

  // --------------------------------------------------------------------------------------
  // TODO: deprecate
  // --------------------------------------------------------------------------------------
  // static getEmitterSaltyPair () : any
  // {
  //  let tNaclPairClient = EmitterSubjectService.getSaltyPair();;
  //  if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( SlakezSaltServiceStatic.httpService ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.httpService ) )
  //  {
  //    SlakezSaltServiceStatic.httpService = EmitterSubjectService.getHttpService();
  //  }
  //  else
  //  {
  //    SlakezSaltServiceStatic.httpService.getEnumsFromServer();
  //  }
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tNaclPairClient ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tNaclPairClient.publicKey ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tNaclPairClient.secretKey ) )
  //  {
  //    // debugger;
  //    this.naclPairClient = tNaclPairClient as NaclPairClient;
  //  } else
  //  {
  //    debugger;

  //    this.timer = setTimeout( () =>
  //    {
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.enums ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.enums.talaChabi ) )
  //      {
  //        debugger;
  //        this.talaChabi = InMemoryDataServiceStatic.enums.talaChabi;
  //        this.naclPairClient.publicKey = InMemoryDataServiceStatic.enums.talaChabi.chabi;
  //        this.naclPairClient.secretKey = InMemoryDataServiceStatic.enums.talaChabi.chabiGopon;
  //        debugger;
  //      }
  //    }, 2000 );
  //    this.timerArray.push( this.timer );
  //    debugger;

  //    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.naclPairClient.secretKey ) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.naclPairClient.publicKey ) )
  //    {
  //      EmitterSubjectService.emitGetKeys( this.keyType[ 0 ] );
  //      // since the emitGetKeys may have been called, retrive the SaltyPair once again.
  //      this.timer = setTimeout( () =>
  //      {
  //        this.naclPairClient = EmitterSubjectService.getSaltyPair();

  //      }, 2000 );
  //      this.timerArray.push( this.timer );
  //      debugger;
  //    }
  //  }
  //  return this.naclPairClient;
  // }
  // --------------------------------------------------------------------------------------
}
