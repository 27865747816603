'use-strict'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DirectiveFilterPipeModule } from '../directiveFilterPipeModule/directiveFilterPipe.module';
import { AccordionComponent } from './accordion/accordion.component';
import { AccordionGroupComponent } from './accordionGroup/accordionGroup.component';
import { SharedModuleComponent } from './component/sharedModule.component';
import { SharedRoutingModule } from './shared-routing.module';

@NgModule({
  declarations: [AccordionComponent, AccordionGroupComponent, SharedModuleComponent],
  imports: [CommonModule, FormsModule, HttpClientModule, RouterModule,  DirectiveFilterPipeModule, SharedRoutingModule],
  exports: [ SharedRoutingModule],

  providers: [
    // { provide: SafeFilterPipe, },
  ],
})
export class SharedModule { }
