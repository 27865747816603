
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { Photo } from '../../models/common/photo.model';
import { PhotoMgmtAction } from '../../models/common/photoMgmtAction.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
// import { Pager } from '../../models/pagination/pager.model';
import { PagerMemberActivity } from '../../models/pagination/pagerMemberActivity.model';
import { SitUserService } from '../commonServiceService/sitUserService.service';
import { ActivityService } from '../memberServiceService/activityService.service';
import { BackgroundImageAnimationService } from '../rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { DomUtilsServiceStatic } from '../staticServices/domUtilsServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
import { GlyphiconMenuServiceInterface } from '../../interfaces/serviceInterfaces/pagerServicesInterfaces/glyphiconMenuServiceInterface';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { MemberActivity } from '../../models/member/memberActivity.model';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';

declare let jQuery: any;

declare let $ : any;

// ref: http://xahlee.info/js/javascript_range_array.html
const xah_range = ((min : any, max : any, step = 1,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)));
// const memberViewGlyphNamesHolder: string[] = [ 'wink', 'like', 'star', 'friend', 'block', 'unblock', 'lock', 'unlock', ]; 

@Injectable({ providedIn: 'any' })
export class GlyphiconMenuService implements OnDestroy, GlyphiconMenuServiceInterface {
  public actedGlyphNames = [];
  public actedUnlockAndBlockGlyphNames = [];
  public actedKvGlyphArr : KvMemberActivityGlyph[] = [];
  private allGlyphNameArr : string[] = [
    'block',
    'buddy',
    'camera',
    'chat',
    'delete',
    'deleted',
    'dislike',
    'email',
    'folderClosed',
    'folderOpen',
    'inbox',
    'like',
    'tala',
    'note',
    'private',
    'public',
    'saved',
    'sent',
    'starred',
    'track',
    'unlock',
    'video',
  ];
  public allGlyphKvArr : KvMemberActivityGlyph[] = [];
  public currentItem = 0;
  public currentItemKvGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public currentKvPhoto : KvPhoto = new KvPhoto();
  public currentPage = 1;
  public currentPageGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public currentPhoto : Photo = new Photo();
  public defaultViewMemberGlyphNameArr : string[] = [ 'chat', 'email', 'star', 'friend', 'unfriend', 'like', 'dislike', 'tala', 'unlock', 'block', 'unblock', 'private', 'public', 'wink',  ];
  public defaultGlyphKvArr : KvMemberActivityGlyph[] = [];
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public endIndex = 0;
  public firstItem = 0;
  public firstPage = 0;
  public glyphsForPhotoMgmt : KvMemberActivityGlyph[] = []; // Note : main array that pagination uses.
  public glyphsForViewMember : KvMemberActivityGlyph[] = []; // Note : main array that pagination uses.
  public glyphNames : string[] = [ 'camera', 'primary', 'private', 'public' ];
  public imageArr : string[] = [];
  public isBuddy = false;
  public isBlocked = false;
  public isFriend = false;
  public isFriendByDictionaryData = false;
  public isLiked = false;
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isStarred = false;
  public isToggleCamera = false;
  public isUnlocked = false;
  public isViewMember = false;
  public isWinked = false;
  public kvGlyphArr : KvMemberActivityGlyph[] = []; // Note : main array that pagination uses.
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public lastPage = 0;
  public lastItem = 0;
  public lastItemKvGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public memberViewGlyphNamesHolder : string[] = [ 'wink', 'like', 'star', 'friend', ]; 
  public memberViewGlyphNames : string[] = [];
  public memberViewMenuNames : string[] = [ 'chat', 'email', 'note', 'buddy', 'like', 'private', 'public', 'lock', 'block', 'unblock', 'dislike' ];

  public nextItem = 0;
  public nextItemKvGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public nextPage = 0;
  public pagedKvGlyphArr : KvMemberActivityGlyph[] = [];
  public page = 1;
  public pager : PagerMemberActivity = new PagerMemberActivity();
  public pagerVMemActGlyph : PagerMemberActivity = new PagerMemberActivity();
  public pages : any[] = [];
  public pageSize = 10;
  public previousItem = 0;
  public previousPage = 0;
  public previousItemKvGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public photoMgmtMenuNames : string[] = [ 'camera' ];
  public photoMgmtGlyphNames : string[] = [];
  public photoMgmtAction : PhotoMgmtAction = new PhotoMgmtAction();
  public startIndex = 0;
  public sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();
  public totalItems = 0;
  public totalPages = 0;
  public renderer! : Renderer2;
  public userMemberViewGlyphNames : string[] = [];
  // ---------------------------------------------------------------
  constructor (
    public activityService : ActivityService,
    private bias : BackgroundImageAnimationService,
    private rendererService : RendererService,
    private router : Router,
    private sitUserService : SitUserService,
    @Inject(DOCUMENT) document : any,
    @Inject(Window) window : any
  ) {
    if (this.rendererService) {
      this.renderer = this.rendererService.getRenderer();
    }
    // -----------------------------------------------------------
    // EmitterSubjectService.setMemberViewGlyphMenuEmitter
    //  .subscribe( result =>
    //  {
    //    debugger;
    //    this.setMemberViewGlyphMenu();
    //    // debugger;
    //  } );
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  /*
   * ===============================================================
   * ---------------------------------------------------------------
   */
  public clearGlyphMenu () : void {
    this.allGlyphKvArr = [];
    this.defaultGlyphKvArr = [];
  }
  // ---------------------------------------------------------------
  public clearPhotoMgmtGlyphMenu () : void {
    this.glyphsForPhotoMgmt = [];
    this.kvGlyphArr = [];
    EmitterSubjectService.emitRunNgAfterViewInit(true);
  }
  // ---------------------------------------------------------------
  public clearViewMemberGlyphMenu () : void {
    this.glyphsForViewMember = [];
    this.kvGlyphArr = [];
    EmitterSubjectService.emitRunNgAfterViewInit(true);
  }
  // ---------------------------------------------------------------
  public cases (action : any, index : number, glyphKvArr : KvMemberActivityGlyph[], receiverSitUserId: number) : KvMemberActivityGlyph[] {
    let gKvAny;
    
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    gKvAny = new KvMemberActivityGlyph();
    // ---------------------
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action)) {

      switch ((action || action.action || '').toLocaleLowerCase()) {
        case 'add':
          gKvAny.value = 'glyphicon-plus';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'add';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'block':
        case 'blocked':
          gKvAny.value = 'glyphicon-ban-circle';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'block';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'buddy':
          gKvAny.value = 'glyphicon-heart';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'buddy';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'camera':
          gKvAny.value = 'glyphicon-camera';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'camera';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'chat':
          gKvAny.value = 'assets/photos/chat.png';
          // gKvAny.value = 'glyphicon-comment';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'chat';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          // debugger;
          break;
        case 'delete':
          gKvAny.value = 'glyphicon-remove';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'delete';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'deleted':
          gKvAny.value = 'glyphicon-trash';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'deleted';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'dislike':
          gKvAny.value = 'glyphicon-thumbs-down';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'dislike';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'email':
          gKvAny.value = 'glyphicon-envelope';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'email';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'friend':
          gKvAny.value = 'glyphicon-heart';// glyph-members-transparent.png
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'friend';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'folderOpen':
          gKvAny.value = 'glyphicon-folder-open';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'folderOpen';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'folderClose':
          gKvAny.value = 'glyphicon-folder-close';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'folderClose';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'inbox':
          gKvAny.value = 'assets/photos/inbox-lock.png';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'inbox';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'like':
          gKvAny.value = 'glyphicon-thumbs-up';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'like';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        // Note:  the 'key' is used to display what the user intends to do, that is opposite of current state of the privacy.
        //        For example, user-A has unlocked the private pics of him for user-B. So when the user-A visits user-B's profile,
        //        user-A should see the key = 'unlock' pic, to inform the user the current state of his photo-privacy. 
        case 'tala':
        case 'locked':
        case 'lock':
          gKvAny.value = 'assets/photos/lock.png';
          gKvAny.action = gKvAny.key = 'tala'; // when lock key should say to unlock
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          // debugger;
          break;
        case 'note':
          gKvAny.value = 'glyphicon-bell';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'note';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'primary':
          gKvAny.value = 'glyphicon-picture';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'primary';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'private':
          gKvAny.value = 'assets/photos/private.png'; /* 'private-lock.png';*/
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'private';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'profile':
          gKvAny.value = 'glyphicon-user';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'profile';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'public':
          gKvAny.value = 'assets/photos/public.png'; /* 'public-unlock.png';*/
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'public';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'remove':
          gKvAny.value = 'glyphicon-minus';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'remove';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'save':
        case 'saved':
          gKvAny.value = 'glyphicon-save';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'save';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'sent':
          gKvAny.value = 'glyphicon-inbox';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'sent';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'star':
          gKvAny.value = 'glyphicon-star';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'star';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'track':
          gKvAny.value = 'glyphicon-eye-open';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'track';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'unblock':
        case 'unblocked':
          gKvAny.value = 'glyphicon-ok-circle';
          gKvAny.action = gKvAny.key = 'unblock';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          // debugger;
          break;
        case 'unfriend':
          gKvAny.value = 'glyphicon-remove-circle';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'unfriend';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'unlock':
        case 'unlocked':
          gKvAny.value = 'assets/photos/unlock.png';
          gKvAny.action = gKvAny.key = 'unlock'; // when unlocked, key should say to lock
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          // debugger;
          break;
        case 'video':
          gKvAny.value = 'glyphicon-facetime-video';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'video';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        case 'wink':
          gKvAny.value = 'assets/photos/wink-2.png';
          gKvAny.action = gKvAny.key = gKvAny.modelName = 'wink';
          gKvAny.receiverSitUserId = receiverSitUserId;
          gKvAny.senderSitUserId = this.loginSuccess.signedInUserId;
          gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
          gKvAny.index = index;
          glyphKvArr.push(gKvAny);
          break;
        default:
          /*
           * gKvAny.value = 'glyphicon-align-justify';
           * gKvAny.action = gKvAny.key = 'default';
           * gKvAny.receiverSitUserId = receiverSitUserId;
           * gKvAny.doerSitUserId = this.loginSuccess.signedInUserId;
           * gKvAny.signedInUserId = this.loginSuccess.signedInUserId;
           * gKvAny.index = index++;
           * glyphKvArr.push(gKvAny);
           */
          break;
      }
    }
    return glyphKvArr;
  }
  // ---------------------------------------------------------------
  public createGlyphMenu (names : string[], receiverSitUserId: number) : KvMemberActivityGlyph[] {
    // debugger;
    let glyphKvArr : any[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(names) && names.length > 0) {
      // debugger;
      for (var i = 0; i < names.length; i++){
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(names[i])) {
          // debugger;
          glyphKvArr = this.cases(names[ i ], i, glyphKvArr, receiverSitUserId);
        }
      }
    }
    return glyphKvArr;
  }
  // ---------------------------------------------------------------
  public getGlyphs () : KvMemberActivityGlyph[] {
    return this.kvGlyphArr;
  }
  public setGlyphs (value : KvMemberActivityGlyph[]) : void {
    this.kvGlyphArr = value;
  }
  // ---------------------------------------------------------------
  public getGlyphsForPhotoMgmt () : KvMemberActivityGlyph[] {
    return this.glyphsForPhotoMgmt;
  }
  public setGlyphsForPhotoMgmt (value : KvMemberActivityGlyph[]) : void {
    this.glyphsForPhotoMgmt = value;
  }
  // ---------------------------------------------------------------
  public getGlyphsForViewMember () : KvMemberActivityGlyph[] {
    return this.glyphsForViewMember;
  }
  public setGlyphsForViewMember (value : KvMemberActivityGlyph[]) : void {
    this.glyphsForViewMember = value;
  }
  // ---------------------------------------------------------------
  public getImageArr () : string[] {
    return this.imageArr;
  }
  // ---------------------------------------------------------------
  public setAllGlyphKvArr (kvArr : KvMemberActivityGlyph[]) : void {
    this.allGlyphKvArr = kvArr;
  }
  // ---------------------------------------------------------------
  //public getAllGlyphKvArr () : KvMemberActivityGlyph[] {
  //  return this.createAllGlyphiconArray();
  //}
  // ---------------------------------------------------------------------------------
  //public createAllGlyphiconArray () : KvMemberActivityGlyph[] {
  //  // debugger;
  //  return this.createGlyphMenu(this.allGlyphNameArr);
  //}
  // ---------------------------------------------------------------------------------
  //public createDefaultGlyphiconArray () : KvMemberActivityGlyph[] {
  //  // debugger;
  //  return this.createGlyphMenu(this.defaultViewMemberGlyphNameArr);
  //}
  // ---------------------------------------------------------------------------------
  public createMemberViewGlyphiconArray (sitUserId) : Observable<KvMemberActivityGlyph[]> {
    // debugger;
    return new Observable<any>(subscriber => {
      if (sitUserId > 0) {
        this.getMemberViewGlyphNames(sitUserId).subscribe(data => {
          this.memberViewGlyphNames = data;
          // debugger;
          // each time a new set of glyphs are created, we create acted glyphs as well:
          EmitterSubjectService.emitActedGlyphNames(this.actedGlyphNames); // important!
          EmitterSubjectService.emitActedUnlockAndBlockGlyphNames(this.actedUnlockAndBlockGlyphNames);

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewGlyphNames) && this.memberViewGlyphNames.length > 0) {
            this.glyphsForViewMember = this.createGlyphMenu(this.memberViewGlyphNames, sitUserId);
            // debugger;
            subscriber.next(this.glyphsForViewMember);
          }          
        })
      }
    })    
  }
  // ---------------------------------------------------------------------------------
  public createGlyphiconArrayFromNames (names: any[], sitUserId: number) : KvMemberActivityGlyph[] {    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(names) && names.length > 0) {
      // debugger;
      return this.createGlyphMenu(names, sitUserId);
    }
    else return [];
  }
  // ---------------------------------------------------------------------------------
  public setDefaultGlyphiconArray (dKvArr : KvMemberActivityGlyph[]) : void {
    // debugger;
    this.defaultGlyphKvArr = dKvArr;
  }
  // ---------------------------------------------------------------------------------
  //public getDefaultGlyphiconArray () : KvMemberActivityGlyph[] {
  //  // debugger;
  //  return this.createDefaultGlyphiconArray();
  //}
  /*
   * ---------------------------------------------------------------
   * Note : This is a special-case. Do NOT delete/change this! 2020/October/24
   * Note : a single kvGlyph is used to produce an array of glyphs on the basis of:
   *     1. camera, 2. isPrimary, 3. isPrivate
   * ---------------------------------------------------------------
   */
  public createPhotoMgmtGlyphMenuArrFromKvGlyph (kvG : KvMemberActivityGlyph) : KvMemberActivityGlyph[] {
    // debugger;
    const outKvGArr : KvMemberActivityGlyph[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvG)) {
      let index = 0;
      let primaryImage : any;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      this.signedInUser = EmitterSubjectService.getSignedInUser();
      // debugger;

      let kvg = new KvMemberActivityGlyph();

      kvg = new KvMemberActivityGlyph().copyIfExists(kvg, kvG); // = CopyServiceStatic.copyKvGlyph(kvG);
     // debugger;
      kvg.glyphName = 'camera';
      kvg.id = kvG.id;
      kvg.index = index++;
      kvg.isPrivate = false;
      kvg.isPrimary = false;
      kvg.key = 'camera';
      kvg.signedInUserId = this.loginSuccess.signedInUserId;
      kvg.value = 'glyphicon-camera';
      outKvGArr.push(kvg);
      /*
       * debugger;
       * if the given(param) kvGlyph's photoId==sitUser.primaryPhotoBnId, then no need to show glyph,
       * instead show the primary pic and caption 'Your Primary Pic'.
       */


      //  first test to see if the kvG.value contains raw-image-data
      //  if not then check if loginSuccess.primaryImage contains raw-image-data
      // -----------------------------------------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvG.glyphName)
        && kvG.privacy.toLowerCase().indexOf("primary") !== -1) {
        // debugger;
        let pattern = /\'*data:image(\/(jpeg|jpg|png|svg))+;base64,/;
        if (pattern.test(kvG.value)) {
          primaryImage = kvG.value;
        }
        else if (pattern.test(this.loginSuccess.primaryImage)
          && kvG.photoBnId === this.loginSuccess.primaryPhotoBnId) {
          primaryImage = this.loginSuccess.primaryImage;
        }
        // debugger;
        kvg = new KvMemberActivityGlyph();
        kvg = new KvMemberActivityGlyph().copyIfExists(kvg, kvG); //   = CopyServiceStatic.copyKvGlyph(kvG);  // TODO: restore
        // debugger;
        kvg.glyphName = 'primary';
        kvg.id = kvG.id;
        kvg.index = index++;
        kvg.isPrivate = false;
        kvg.isPrimary = true;
        kvg.key = 'primary';
        kvg.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
        kvg.value = JsRegExpServiceStatic.stripUrlForImageData(primaryImage);// imageProcessingServiceStatic.normalizeImageData(primaryImage);//
        outKvGArr.push(kvg);
        // debugger;
      }
      /*
       * if the given(param) kvGlyph's isPrivate==false, then show-isPrivate, else show-non-isPrivate
       * in this case this image is a candidate to be primary, so show the 'Make-Primary' glyphicon.
       * --------------------------------------------------------------------------------------------
       */

      else {
        kvg = new KvMemberActivityGlyph();
        kvg = new KvMemberActivityGlyph().copyIfExists(kvg, kvG);//  = CopyServiceStatic.copyKvGlyph(kvG);
        kvg.glyphName = 'primary';
        kvg.id = kvG.id;
        kvg.index = index++;
        kvg.isPrivate = kvG?.privacy?.toLowerCase()?.indexOf('private') !== -1 ? true : false;
        kvg.isPrimary = false; // important!
        kvg.key = 'primary';
        kvg.signedInUserId = this.loginSuccess.signedInUserId;
        kvg.value = 'glyphicon-user'; // or KvPhotoBn's pic 
        outKvGArr.push(kvg);
        // debugger;
      }
      // Note : always check 'private' condition first, that way 'else' will cover 'public/primary' cases.
      if (kvG?.privacy?.toString()?.toLowerCase()?.indexOf('private') !== -1) {
        kvg = new KvMemberActivityGlyph();
        kvg = new KvMemberActivityGlyph().copyIfExists(kvg, kvG); //   = CopyServiceStatic.copyKvGlyph(kvG);
        kvg.glyphName = 'public';
        kvg.id = kvG.id;
        kvg.index = index++;
        kvg.isPrivate = true; // should reflect the original value of 'privacy'
        kvg.isPrimary = false;
        kvg.key = 'public'; // should relfect what the user intends to do
        kvg.signedInUserId = this.loginSuccess.signedInUserId;
        kvg.value = 'public.png';
        outKvGArr.push(kvg);
        // debugger;
      } else {
        kvg = new KvMemberActivityGlyph();
        kvg = new KvMemberActivityGlyph().copyIfExists(kvg, kvG);//   = CopyServiceStatic.copyKvGlyph(kvG);
        kvg.glyphName = 'private';
        kvg.id = kvG.id;
        kvg.index = index++;
        kvg.isPrivate = false; // should reflect the original value of 'privacy'
        kvg.isPrimary = false;
        kvg.key = 'private'; // should relfect what the user intends to do
        kvg.signedInUserId = this.loginSuccess.signedInUserId;
        kvg.value = 'private.png';
        outKvGArr.push(kvg);
        // debugger;
      }
    }
    // debugger;
    return outKvGArr;
  }
  // =================================================================================
  // ---------------------------------------------------------------
  public clearViewMemberGlyphNames () : any {
    this.memberViewGlyphNames = [];
    this.clearGlyphMenu();
    return true;
  }
  // ---------------------------------------------------------------
  public clearPhotoMgmtGlyphNames () : any {
    this.photoMgmtGlyphNames = [];
    this.clearGlyphMenu();
    return true;
  }
  // ---------------------------------------------------------------
  // Note:  There are two types of glyphicon-menues: toggle and non-toggle.
  //        The toggle glyph-menue-pair(s) are: lock/unlock.
  //        The non-toggle-glyph-menues ate:  1. block  2. friend 3.like  4. star
  //
  //        Each non-toggle-glyph-menu has it's opposite action such as 'dislike opposing like',
  //        that can be performed on that 'Action-Page-List' of all the members that beelong to that list
  // --------------------------------------------------------------
  //public createUserGlyphNames (sitId : number) : Observable<any> {
  //  // debugger;
  //  this.userMemberViewGlyphNames = [];
  //  var myMemberActivities : MemberActivity[] = [];
  //  var myMemberLockingActivity : MemberActivity = null;
  //  var mAct : MemberActivity = new MemberActivity();
  //  var sitUserId = sitId;
  //  var k = -1;
  //  var jsExpDate : any;
  //  var jsNow : any;
  //  return new Observable<any>(subscriber => {
  //    //if (sitUserId === 0) {
  //    //  // debugger;
  //    //  this.sitUser = EmitterSubjectService.getSitUserModel();
  //    //  sitUserId = this.sitUser.sitUserId;
  //    //}

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

  //    // debugger;

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
  //      && sitUserId > 0
  //      && sitUserId !== this.loginSuccess.signedInUserId) {
  //      this.isToggleCamera = false;
  //      EmitterSubjectService.emitIsToggleCamera(false);

  //      // create a deep-copy of memberViewGlyphNames:
  //      this.userMemberViewGlyphNames = JSON.parse(JSON.stringify(this.memberViewGlyphNames))
  //      // debugger;

  //      this.activityService.getMyActivities(sitUserId, '').subscribe(data => // note: action='' will get all memberActivities
  //      {
  //        // debugger;
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //          myMemberActivities = data;
  //          // debugger;
  //          EmitterSubjectService.setMyActivityArr(myMemberActivities);
  //          // debugger;

  //          for (var c in myMemberActivities) {
  //            mAct = myMemberActivities[ c ];
  //            k = this.isGlyphInMenuArr(this.userMemberViewGlyphNames, mAct.action);

  //            // debugger;
  //            switch (mAct.action.toLowerCase()) {
  //              case 'friend':
  //              case 'block':
  //              case 'unblock':
  //              case 'like':
  //              case 'dislike':
  //                if (k >= 0 && k < this.userMemberViewGlyphNames.length) {
  //                  this.userMemberViewGlyphNames.splice(k, 1);
  //                }
  //                break;
  //            }
  //          }
  //          // debugger;
  //          // Buddying Behavior is not a glyph for V menu:

  //          subscriber.next(this.userMemberViewGlyphNames);
  //          subscriber.complete();
  //        }
  //        else {
  //          subscriber.next(this.userMemberViewGlyphNames);
  //          subscriber.complete();
  //        }
  //      })
  //    }
  //  })
  //}
  // --------------------------------------------------------------
  //public addUserUnlockGlyphNames (sitId : number) : Observable<any> {
  //  // debugger;
  //  this.userMemberViewGlyphNames = [];
  //  var myMemberActivities : MemberActivity[] = [];
  //  var myMemberLockingActivity : MemberActivity = null;
  //  var mAct : MemberActivity = new MemberActivity();
  //  var sitUserId = sitId;
  //  var k = -1;
  //  var jsExpDate : any;
  //  var jsNow : any;
  //  return new Observable<any>(subscriber => {
  //    if (sitUserId === 0) {
  //      // debugger;
  //      this.sitUser = EmitterSubjectService.getSitUserModel();
  //      sitUserId = this.sitUser.sitUserId;
  //    }

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

  //    // debugger;

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
  //      && sitUserId > 0
  //      && sitUserId !== this.loginSuccess.signedInUserId)
  //    {
  //      this.isToggleCamera = false;
  //      EmitterSubjectService.emitIsToggleCamera(false);

  //      // create a deep-copy of memberViewGlyphNames:
  //      this.userMemberViewGlyphNames = JSON.parse(JSON.stringify(this.memberViewGlyphNames))

  //      // debugger;
  //      this.activityService.getMyActivities(sitUserId, 'unlock').subscribe(data =>
  //      {
  //        // debugger;
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data))
  //        {

  //          // we take only the latest entry of locking data:
  //          myMemberLockingActivity = data[ data.length - 1 ];

  //          // merge the lockingActicity with the memberActivities and set:
  //          myMemberActivities.push(myMemberLockingActivity);
  //          EmitterSubjectService.setMyActivityArr(myMemberActivities);
  //          // debugger;

  //          mAct = myMemberLockingActivity;

  //          //consider expiry time for `unlocked`:
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct)
  //            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.action)
  //            && mAct.action.toLowerCase().indexOf('unlock') !== -1) {
  //            // debugger;
  //            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.expiryDate)) { 
  //              jsExpDate = DateStringServiceStatic.convertNetTicsToJsDateTicks(mAct.expiryDate);
  //            }
  //            else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.date)) {
  //              jsExpDate = DateStringServiceStatic.convertNetTicsToJsDateTicks(mAct.date);
  //            }
  //            else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.jsDateForCs)) {
  //              jsExpDate = mAct.jsDateForCs;
  //            }
  //            if(FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(jsExpDate))
  //            jsNow = new Date().getTime();

  //            // debugger;
  //            //if expired:
  //            if (jsExpDate - jsNow < 0)
  //            {
  //              // expired, so remove `unlock` & keep `lock` => show ing `locked`
  //              k = this.isGlyphInMenuArr(this.userMemberViewGlyphNames, 'unlock');

  //              if (k >= 0 && k < this.userMemberViewGlyphNames.length) {
  //                // debugger;
  //                this.userMemberViewGlyphNames.splice(k, 1);
  //                // debugger;
  //                subscriber.next(this.userMemberViewGlyphNames);
  //                subscriber.complete();
  //              }
  //            } // unexpired, so keep `unlock` & remove `lock`
  //            else { //if `unlock` still valid/unexpired, take out 'tala' =>  showing `unlocked`
  //              k = this.isGlyphInMenuArr(this.userMemberViewGlyphNames, 'tala');

  //              if (k >= 0 && k < this.userMemberViewGlyphNames.length) {
  //                // debugger;
  //                this.userMemberViewGlyphNames.splice(k, 1);
  //                // debugger;
  //                subscriber.next(this.userMemberViewGlyphNames);
  //                subscriber.complete();
  //              }
  //            }
  //          }
  //          // if lock, remove `unlock` from array => showing `locked`
  //          else if (mAct.action.toLowerCase().indexOf('tala') !== -1) {
  //            k = this.isGlyphInMenuArr(this.userMemberViewGlyphNames, 'unlock');

  //            if (k >= 0 && k < this.userMemberViewGlyphNames.length) {
  //              // debugger;
  //              this.userMemberViewGlyphNames.splice(k, 1);
  //              // debugger;
  //              subscriber.next(this.userMemberViewGlyphNames);
  //              subscriber.complete();
  //            }
  //          }
  //          else {
  //            subscriber.next(this.userMemberViewGlyphNames);
  //            subscriber.complete();
  //          }
  //        }
  //        else {
  //          subscriber.next(this.userMemberViewGlyphNames);
  //          subscriber.complete();
  //        }
  //      })        
  //    }
  //  })
  //}
  // --------------------------------------------------------------
  //public addUserTrackWinkGlyphNames (sitId : number) : Observable<any> {
  //  // debugger;
  //  this.userMemberViewGlyphNames = [];
  //  var myMemberActivities : MemberActivity[] = [];
  //  var myMemberLockingActivity : MemberActivity = null;
  //  var mAct : MemberActivity = new MemberActivity();
  //  var sitUserId = sitId;
  //  var k = -1;
  //  var jsExpDate : any;
  //  var jsNow : any;
  //  return new Observable<any>(subscriber => {
  //    if (sitUserId === 0) {
  //      // debugger;
  //      this.sitUser = EmitterSubjectService.getSitUserModel();
  //      sitUserId = this.sitUser.sitUserId;
  //    }

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

  //    // debugger;

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
  //      && sitUserId > 0
  //      && sitUserId !== this.loginSuccess.signedInUserId) {
  //      this.isToggleCamera = false;
  //      EmitterSubjectService.emitIsToggleCamera(false);

  //      // create a deep-copy of memberViewGlyphNames:
  //      this.userMemberViewGlyphNames = JSON.parse(JSON.stringify(this.memberViewGlyphNames))

  //       debugger;
  //      this.activityService.getMyActivities(sitUserId, 'trackwink').subscribe(data => {
  //         debugger;
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data))
  //        {

  //          // we take only the latest entry of locking data:
  //          myMemberLockingActivity = data[ data.length - 1 ];

  //          // merge the lockingActicity with the memberActivities and set:
  //          myMemberActivities.push(myMemberLockingActivity);
  //          EmitterSubjectService.setMyActivityArr(myMemberActivities);
  //          // debugger;

  //          mAct = myMemberLockingActivity;

  //          //consider expiry time for `unlocked`:
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct)
  //            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.action)
  //            && mAct.action.toLowerCase().indexOf('wink') !== -1)
  //          {
  //            // debugger;
  //            //if expired:
  //            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.expiryDate)) {
  //              jsExpDate = DateStringServiceStatic.convertNetTicsToJsDateTicks(mAct.expiryDate);
  //            }
  //            else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.date)) {
  //              jsExpDate = DateStringServiceStatic.convertNetTicsToJsDateTicks(mAct.date);
  //            }
  //            else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.jsDateForCs)) {
  //              jsExpDate = mAct.jsDateForCs;
  //            }
  //            jsNow = new Date().getTime();

  //            // debugger;
  //            if (jsExpDate - jsNow > 0) {
  //              //if `wink` still valid/unexpired, take out 'wink'
  //              k = this.isGlyphInMenuArr(this.userMemberViewGlyphNames, 'wink');

  //              if (k >= 0 && k < this.userMemberViewGlyphNames.length) {
  //                // debugger;
  //                this.userMemberViewGlyphNames.splice(k, 1);
  //                // debugger;
  //                subscriber.next(this.userMemberViewGlyphNames);
  //                subscriber.complete();
  //              }
  //            }
  //            else {
  //              subscriber.next(this.userMemberViewGlyphNames);
  //              subscriber.complete();
  //            }
  //          } 
  //          else {
  //            subscriber.next(this.userMemberViewGlyphNames);
  //            subscriber.complete();
  //          }
  //        }
  //        else {
  //          subscriber.next(this.userMemberViewGlyphNames);
  //          subscriber.complete();
  //        }
  //      })        
  //    }
  //  })
  //}
  // ---------------------------------------------------------------
  public createDefaultPhotoMgmtGlyphNames () : string[] {
    // debugger;
    this.photoMgmtGlyphNames = [];
    if (this.sitUser.sitUserId === this.loginSuccess.signedInUserId) {
      // debugger;
      if (this.isGlyphInMenuArr(this.photoMgmtGlyphNames, 'camera') === -1) {
        this.photoMgmtGlyphNames.push('camera');
      }
    }
    return this.photoMgmtGlyphNames;
  }
  // ---------------------------------------------------------------


  // ---------------------------------------------------------------
  // Note : a single kvGlyph is used to produce an array of glyphs on the basis of:
  //     1. camera, 2. isPrimary, 3. isPrivate
  // ---------------------------------------------------------------
  public createPhotoMgmtGlyphMenuArrFromKvPhoto (kvP : KvPhoto) : KvMemberActivityGlyph[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP)) {
      let kvg : KvMemberActivityGlyph = new KvMemberActivityGlyph();
      kvg = new KvMemberActivityGlyph().copyFromKvPhoto(kvP);
      kvg.kvPhoto = kvP;
        
      var i = 0;
        
      // debugger; //TODO: watch the kvg
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvg)) {         
        this.kvMemActGlyphArrForPhotoMgmt = this.createPhotoMgmtGlyphMenuArrFromKvGlyph(kvg);
        i = 0;
        this.kvMemActGlyphArrForPhotoMgmt.forEach(e => {
          e.elemId = "pagerVGlyphMgmt-" + i;
          i++;
        })
        this.bias.addPrimaryOrOtherClassToKvPhotoElement(kvP);
        return this.kvMemActGlyphArrForPhotoMgmt;          
      }      
    }
    else return [];
  }
  // ------------------------------------------------------------------------------
  // Should be deleted:
  // ------------------------------------------------------------------------------
  //public createViewMemberGlyphMenuArrFromKvPhoto (kvP : KvPhoto) : KvMemberActivityGlyph[] {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP)) {
  //    this.getMemberViewGlyphMenuKvArr(kvP.sitUserId).subscribe(data => {
  //      this.glyphsForViewMember = data;
  //      // debugger;
  //      return this.glyphsForViewMember
  //    })

  //  }
  //  else return [];
  //}
  // ------------------------------------------------------------------------------
  // Note:  This method does not display the selected...halo...highlight
  // ------------------------------------------------------------------------------
  //displayGlyphiconMenus (sitUserId : number) : Promise<KvMemberActivityGlyph[] | any> {
  //  return new Promise<any>((resolve, reject) => {
  //    // debugger;
  //    // ======================================================
  //    // Begin of VerticalMemActGlyphsMenus display: required!
  //    // ======================================================
  //    this.isMyProfile = EmitterSubjectService.getIsMyProfile();
  //    this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
  //    this.isViewMember = EmitterSubjectService.getIsViewMember();
  //    // debugger;
  //    if (sitUserId > 0) {
  //      if (this.isViewMember) {
  //        // display userActivity glyphs: like/friend/unlock...etc. only for non-signedInUser:
  //        // -----------------------------------------------------------------------------------
  //        // Note:  the singedInUser should not be able to view the glyphs for member-activities
  //        //        such as like/friend/unlock/wink/etc. because he cannot do this to himself.
  //        //        displayViewMemberGlyphMenu() checks for this condition.
  //        // -----------------------------------------------------------------------------------
  //        // debugger;
  //        this.kvMemActGlyphArrForViewMember = this.displayViewMemberGlyphMenu(sitUserId);
  //        EmitterSubjectService.emitDisplayViewMemberGlyphiconMenu(this.kvMemActGlyphArrForViewMember);
  //        // resolve(this.kvMemActGlyphArrForViewMember);
  //      }
  //      else  {
  //        // debugger;
  //        if (sitUserId === this.loginSuccess.signedInUserId) {
  //          // -----------------------------------------------------------
  //          // NOTE: the following displays the PhotoMgmtGlyphiconMenu:
  //          // -----------------------------------------------------------
  //          // debugger;
  //          this.kvMemActGlyphArrForPhotoMgmt = this.displaySignInUserPrimaryKvPhotoGlyphs();
  //          EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.kvMemActGlyphArrForPhotoMgmt);
  //          // -----------------------------------------------------------
  //        }          
  //      }
  //      resolve(this.kvMemActGlyphArrForPhotoMgmt);
  //    }
  //    else {
  //      reject(null);
  //    }
  //  })
  //}
  // ----------------------------------------------------------------------------------
  //  This method will cause the pagerGlyphMenu with respect to the KvPhoto to be displayed 
  //  and highlight the primaryImage if it exists in the pagerGlyphMenu.
  //
  //  this method is called after the member-acvity-glyphs-for-photo-mgmt are rendered.
  //  since prior to displaying the necessary conditions are checked, this does not
  //  recheck the condition.
  // ----------------------------------------------------------------------------------
  public displayPhotoMgmtGlyphMenuForKvPhoto (kvp : KvPhoto) : KvMemberActivityGlyph[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value) && kvp.value.length > 0) {
      EmitterSubjectService.setCurrentKvPhoto(kvp);
      this.glyphsForPhotoMgmt = this.createPhotoMgmtGlyphMenuArrFromKvPhoto(kvp);
      // debugger;
      EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.glyphsForPhotoMgmt); // 
      // debugger;
    }
    return this.glyphsForPhotoMgmt;
  }
  // ----------------------------------------------------------------
  // displaySignInUserPhotoMgmtGlyphs () : KvMemberActivityGlyph[] | any {
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
  //    debugger;
  //    let primaryKvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto)) {
  //      return this.displayPhotoMgmtGlyphMenuForKvPhoto(primaryKvPhoto);
  //    }
  //    else return null;
  //  }
  //  else return null;
  // }
  // ----------------------------------------------------------------
  public displaySignInUserPrimaryKvPhotoGlyphs () : KvMemberActivityGlyph[] | any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
      // debugger;
      let primaryKvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto)
        && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.value)
          || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.pic)
          || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.image))) {
        return this.getPhotoMgmtGlyphMenuOfKvPhotoAndHighlightPrimaryPhoto(primaryKvPhoto);
      }
      else return null;
    }
    else return null;
  }
  // -----------------------------------------------------------------------------------
  // Note: the singedInUser should not be able to view the glyphs for member-activities
  //        such as like/friend/unlock/wink/etc.
  // -----------------------------------------------------------------------------------
  public displayViewMemberGlyphMenu (sitUserId : number) : KvMemberActivityGlyph[] | any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
      && sitUserId > 0 && sitUserId !== this.loginSuccess.signedInUserId) {
      
      this.createMemberViewGlyphiconArray(sitUserId).subscribe(data => {
        this.glyphsForViewMember = data;
         debugger;
        EmitterSubjectService.emitDisplayViewMemberGlyphiconMenu(this.glyphsForViewMember);
         debugger;
        return this.glyphsForViewMember
      })
    }
    // else return this.displaySignInUserPrimaryKvPhotoGlyphs();    
  }
  // ---------------------------------------------------------------------------------
  public executeGlyphMenuArr (kvAnyArr : KvMemberActivityGlyph[]) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAnyArr) && kvAnyArr.length > 0) {
      this.kvGlyphArr = kvAnyArr;
      // debugger;
      this.setGlyphs(this.kvGlyphArr);
      if (this.kvGlyphArr.length > 10) { this.rotateArrowGlyphicons(); }
      // debugger; // TODO: inspect the glyphKvArr
      return true;
    }
    return false;
  }
  // ---------------------------------------------------------------------------------
  public findActedGlyph (glyphs : KvMemberActivityGlyph[], sitId : number, action : string) : KvMemberActivityGlyph {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyphs) && glyphs.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action) && sitId > 0) {
      glyphs.map(e => {
        if ((e.action.toLocaleLowerCase().indexOf(action) !== -1 || e.key.toLocaleLowerCase().indexOf(action) !== -1) && e.senderSitUserId === sitId) {
          return e;
        }
      })
    }
    else return null;
  }
  // ---------------------------------------------------------------------------------
  //  Note: DO NOT DELETE THIS!
  // ---------------------------------------------------------------------------------
  // executeDefaultGlyphMenu(): any {
  //  // debugger;
  //  this.glyphs = this.createDefaultGlyphiconArray();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.glyphs) && this.glyphs.length >= 0) {
  //    EmitterSubjectService.emitDisplayGlyphiconMenu(this.glyphs);
  //  }
  //  return this.glyphs;
  // }
  // ---------------------------------------------------------------
  // This should be only occurance of this method:
  //      Filter through myMemberActivitiesDictionary for
  //      like/dislike, lock/unlock, block/unblock status.
  // ---------------------------------------------------------------
  public getMemberViewGlyphNames (sitId : number) : Observable<KvMemberActivityGlyph[] | any> {
    // debugger;
    var sitUserId = sitId;
    // Note: This method filers the glyphNames according to the following entities in dbo
    //        1) myMemberActivities entity
    //        The following are treated separately:
    //        1) myMemberLockingActivities entity
    //        2) myMemberTrackWinkActivities entity
    // -----------------------------------------------------------------------------------------------------
    return new Observable<any>(subscriber => {
      this.memberViewGlyphNames = JSON.parse(JSON.stringify(this.memberViewGlyphNamesHolder)); // deepcopy
       
      // debugger;
      this.activityService.getMyActivities(sitId, 'unlock').subscribe(data => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data))
        {
          if (data instanceof Array) {
            data.map(e => {
              this.actedUnlockAndBlockGlyphNames.push(e?.memberActivity);
              // debugger;
              // we do not save lock/unlock in actedGlyphNames:
              // ----------------------------------------------
              // this.actedGlyphNames.push(e.memberActivity); 

              // Note:  Unlock/Lock status is determined if they are present in actedGlyphNames.
              //        The view treats them as it's own display unit.
              //        We do not create `memberViewGlyphNames` for unlock/lock
              //
              //        Similarly Unblock/Block status is determined if they are present in actedGlyphNames.
              //        Block / Unblock are treated as they have their own display unit
              //        We do not create `memberViewGlyphNames` for unblock/block

            })
          }
          else {
            this.actedUnlockAndBlockGlyphNames.push(data.memberActivity);
          }
        }
        // Like/dislike/friend/unfriend/star/wink:     
        // debugger;
        this.activityService.getMyActivities(sitId, 'like').subscribe(data =>
        {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0)
          {
            // debugger;
            data.map(e => {
              // debugger;
              if (e?.memberActivity?.toLowerCase().indexOf('unblock') === -1 && e?.memberActivity?.toLowerCase().indexOf('block') === -1) {
                this.actedGlyphNames.push(e.memberActivity);
              }
              else {
                this.actedUnlockAndBlockGlyphNames.push(e?.memberActivity);
              }
              switch ((e?.memberActivity || '').toLocaleLowerCase()) {
                case 'like':
                  // debugger;
                  var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.memberViewGlyphNames, 'like');// do nothing
                  if (index > -1) {
                    this.memberViewGlyphNames.splice(index, 1);
                  }
                  break;
                case 'friend':
                  var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.memberViewGlyphNames, 'friend');// do nothing
                  if (index > -1) {
                    this.memberViewGlyphNames.splice(index, 1);
                  }
                  break;
                case 'wink':
                  var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.memberViewGlyphNames, 'wink');
                  // debugger;
                  if (index > -1) {
                    this.memberViewGlyphNames.splice(index, 1);
                    // debugger;
                  }
                  break;
                case 'star':
                  var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.memberViewGlyphNames, 'star');
                  // debugger;
                  if (index > -1) {
                    this.memberViewGlyphNames.splice(index, 1);
                    // debugger;
                  }
                  break;
              }
            })
            // debugger;
          }
          subscriber.next(this.memberViewGlyphNames);
        })
      })
    })
  }
  // ---------------------------------------------------------------
  // This should be only occurance of this method:
  //      Filter through myMemberActivitiesDictionary for
  //      like/dislike, lock/unlock, block/unblock status.
  // ---------------------------------------------------------------
  //public getMemberViewGlyphNames2 (sitId : number) : Observable<KvMemberActivityGlyph[] | any> {
  //  // debugger;
  //  var sitUserId = sitId;
  //  // Note: This method filers the glyphNames according to the following entities in dbo
  //  //        1) myMemberActivities entity
  //  //        2) myMemberLockingActivities entity
  //  //        3) myMemberTrackWinkActivities entity
  //  // -----------------------------------------------------------------------------------------------------
  //  return new Observable<any>(subscriber => {      
  //    this.memberViewGlyphNames = this.memberViewGlyphNamesHolder;      
  //      this.activityService.getMyActivities(sitId, 'wink').subscribe(data => {
  //        // debugger;
  //        data.map(e => {
  //          switch ((e.action || '').toLocaleLowerCase()) {
  //            case 'star':
  //              var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.memberViewGlyphNames, 'star');
  //              // debugger;
  //              if (index > -1) {
  //                this.actedGlyphNames.push(e.action);
  //                this.memberViewGlyphNames.splice(index, 1);
  //                // debugger;
  //              }
  //              break;
  //          }
  //        })
           
  //        // debugger;
  //        this.activityService.getMyActivities(sitId, 'like').subscribe(data => {
  //          // debugger;
  //          data.map(e => {
  //            switch ((e.action || '').toLocaleLowerCase()) {
  //              case 'like':
  //                var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.memberViewGlyphNames, 'like');// do nothing
  //                if (index > -1) {
  //                  this.actedGlyphNames.push(e.action);
  //                  this.memberViewGlyphNames.splice(index, 1);
  //                }
  //                break;
  //              case 'friend':
  //                var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.memberViewGlyphNames, 'friend');// do nothing
  //                if (index > -1) {
  //                  this.actedGlyphNames.push(e.action);
  //                  this.memberViewGlyphNames.splice(index, 1);
  //                }
  //                break;
  //            }
  //          }) 
  //          // debugger;
  //          subscriber.next(this.memberViewGlyphNames); 
  //        // subscriber.next(this.memberViewGlyphNames);
  //      }) 
  //      // subscriber.next(this.memberViewGlyphNames);
  //   })              
  //  })
  //}
  // --------------------------------------------------------------------
  public getPagerVMemActGlyphMenuForViewMember (pageNum : number, sitUser : SitUser) : PagerMemberActivity | any {
    // debugger;
    if (pageNum > 0) {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0) {
        this.createMemberViewGlyphiconArray(sitUser.sitUserId).subscribe(data => {
          this.glyphsForViewMember = this.pagerVMemActGlyph.kvGlyphArr = data;
        })
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.glyphsForViewMember) && this.glyphsForViewMember.length > 0) {
        // debugger;
        this.pagerVMemActGlyph = this.setPagerWithKvGlyphs(this.glyphsForViewMember, pageNum);
        return this.pagerVMemActGlyph;
      }
      else return null;
    }
    else {
      return null;
    }
  }
  // ---------------------------------------------------------------
  public getPagerVMemActGlyphMenuForPhotoMgmt (kvp : KvPhoto) : PagerMemberActivity | any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp) &&
      (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value) && kvp.value.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && kvp.image.length > 0)) {

      // this is a temporary fix; The kvp should have been complete befoe it gets here!
      // ------------------------------------------------------------------------------
      // if (kvp.value.length == 0 && kvp.image.length > 0) {
      //  kvp.value = kvp.image;
      // }
      // else if (kvp.value.length > 0 && kvp.image.length == 0) {
      //  kvp.image = kvp.value;
      // }
      // debugger;
      this.glyphsForPhotoMgmt = this.pagerVMemActGlyph.kvGlyphArr = this.createPhotoMgmtGlyphMenuArrFromKvPhoto(kvp);
      // debugger;
      this.pagerVMemActGlyph = this.setPagerWithKvGlyphs(this.glyphsForPhotoMgmt, kvp.pageNum > 0 ? kvp.pageNum : 1);
      // debugger;
      return this.pagerVMemActGlyph;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  // Note: Only SignedInUser should have access to PhotoMgmt-related-activities:
  // ---------------------------------------------------------------
  // getPrimaryKvPhotoMgmtGlyphMenuForSignedInUserAndHighlightPrimaryImage (pkvPhoto : KvPhoto) : KvMemberActivityGlyph[] | any {
  public getPhotoMgmtGlyphMenuOfKvPhotoAndHighlightPrimaryPhoto (pkvPhoto : KvPhoto) : KvMemberActivityGlyph[] | any {
    let outGlyphs : KvMemberActivityGlyph[] = [];
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pkvPhoto) && (pkvPhoto.photoBnId > 0 || pkvPhoto.photoId > 0)) {
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();
      // debugger;
      if (this.isPhotoMgmt || this.isMyProfile) {
        if (pkvPhoto.photoBnId === this.loginSuccess?.primaryPhotoBnId) {
          // in case of the signedInUserProfile, we get the photoMgmtGlyphs:
          // ---------------------------------------------------------------
          let outGlyph = new KvMemberActivityGlyph();
          outGlyph = outGlyph.copyFromKvPhoto(pkvPhoto);
          outGlyphs = this.createPhotoMgmtGlyphMenuArrFromKvGlyph(outGlyph);
          this.bias.addPrimaryOrOtherClassToKvPhotoElement(pkvPhoto);
          // debugger;
          return outGlyphs;
        }
      }
    }
    return null;
  }
  // ---------------------------------------------------------------
  // Note: Only SignedInUser should have access to PhotoMgmt-related-activities:
  // ---------------------------------------------------------------
  public getViewMemberGlyphMenuOfKvPhotoAndHighlightPhoto (pkvP : KvPhoto) : Observable<KvMemberActivityGlyph[]> | any {
    var pkvPhoto = pkvP;
    let outGlyphs : KvMemberActivityGlyph[] = [];
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    return new Observable<any>(subscriber => {
      if (this.isViewMember) {
        // in case of the non-signedInUserProfile, we get the viewMemberGlyphs:
        // ---------------------------------------------------------------
        if (pkvPhoto.photoBnId === this.sitUser?.primaryPhotoBnId) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {

            //TODO:  NOTE: this is the wrong glyph-menu. it should be private/public/primary:
            this.createMemberViewGlyphiconArray(this.sitUser.sitUserId).subscribe(data => {
              outGlyphs = data;
            })
          }
          this.bias.addPrimaryOrOtherClassToKvPhotoElement(pkvPhoto);
          subscriber.next(outGlyphs);
          subscriber.complete();
        }
      }
    })
  }

  // ---------------------------------------------------------------
  // Deprecated!
  // ---------------------------------------------------------------
  //getPhotoMgmtKvArrGlyphMenuForKvPhoto (kvp : KvPhoto) : KvMemberActivityGlyph[] | any {
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp) &&
  //    (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value) && kvp.value.length > 0)
  //    || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && kvp.image.length > 0)) {

  //    // this is a temporary fix; The kvp should have been complete befoe it gets here!
  //    // ------------------------------------------------------------------------------
  //    // if (kvp.value.length == 0 && kvp.image.length > 0) {
  //    //  kvp.value = kvp.image;
  //    // }
  //    // else if (kvp.value.length > 0 && kvp.image.length == 0) {
  //    //  kvp.image = kvp.value;
  //    // }
  //    this.glyphsForPhotoMgmt = this.createPhotoMgmtGlyphMenuArrFromKvPhoto(kvp);
  //    // debugger;
  //    return this.glyphsForPhotoMgmt;
  //  }
  //  else return null;
  //}
  // ---------------------------------------------------------------
  // Deprecated!
  // ---------------------------------------------------------------
  //getPhotoMgmtGlyphMenuKvArr (photoMgmgGlyphNames : any[]) : KvMemberActivityGlyph[] | any {
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoMgmtGlyphNames) && this.photoMgmtGlyphNames.length > 0) {
  //    this.glyphsForPhotoMgmt = this.createGlyphMenu(this.photoMgmtGlyphNames);
  //  } else {
  //    this.glyphsForPhotoMgmt = this.createGlyphMenu(this.glyphNames);
  //  }
  //  // debugger;
  //  return this.glyphsForPhotoMgmt;
  //}
  // ---------------------------------------------------------------
  // Note: this method gets the glyphs of the primaryKvPhoto, and
  //        selects the primaryImage
  // TODO: need to Rename the function to refrect what it does/it's task
  //       OR deprecated!
  // ---------------------------------------------------------------
  public getPrimaryImageGlyphMenuArrAndHighlightPrimaryImage (pkvP : KvPhoto) : Observable<KvMemberActivityGlyph[]> | any {
    var pkvPhoto = pkvP;
    let outGlyphs : KvMemberActivityGlyph[] = [];
   
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
    this.isViewMember = EmitterSubjectService.getIsViewMember();
    this.isMyProfile = EmitterSubjectService.getIsMyProfile();
    return new Observable<any>(subscriber => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pkvPhoto) && pkvPhoto.photoBnId > 0) {          
        if (this.isPhotoMgmt || this.isMyProfile) {
          if (pkvPhoto.photoBnId === this.loginSuccess?.primaryPhotoBnId) {
            // in case of the signedInUserProfile, we get the photoMgmtGlyphs:
            // ---------------------------------------------------------------
            outGlyphs = this.createPhotoMgmtGlyphMenuArrFromKvGlyph(CopyServiceStatic.copyFromKvPhotoToKvGlyph(pkvPhoto));
            this.bias.addPrimaryOrOtherClassToKvPhotoElement(pkvPhoto);

          }
        }
        else if (this.isViewMember) {
          // in case of the non-signedInUserProfile, we get the viewMemberGlyphs:
          // ---------------------------------------------------------------
          if (pkvPhoto.photoBnId === this.sitUser?.primaryPhotoBnId) {
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {

              //TODO:  NOTE: this is the wrong glyph-menu. it should be private/public/primary:
              this.createMemberViewGlyphiconArray(this.sitUser.sitUserId).subscribe(data => {
                outGlyphs = data;
              })
            }
            this.bias.addPrimaryOrOtherClassToKvPhotoElement(pkvPhoto);
          }
        }      
      }
      subscriber.next(outGlyphs);
      subscriber.complete();
    })
  }

  // ---------------------------------------------------------------------------------
  public isGlyphInMenuArr (glyphArr : string[], glyphName : string) : number {
    // debugger;
    let i = 0;
    let index = -1;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyphArr) && glyphArr.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyphName)) {
      for (i = 0; i < glyphArr.length; i++) {
        if (glyphArr[ i ].toString().toLowerCase().indexOf(glyphName.toLowerCase()) !== -1 ) {
          index = i;
          // debugger;
          break;
        }       
      }
    }
    // debugger;
    return index;
  }

  // ---------------------------------------------------------------------------------
  public isGlyphInKvArr (glyphArr : KvMemberActivityGlyph[], glyph : KvMemberActivityGlyph) : number {
    // debugger;
    let i = 0;
    let index = -1;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyphArr) && glyphArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyph) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyph.key)) {
      for (i = 0; i < glyphArr.length; i++) {
        if (glyphArr[ i ].key.toString().toLowerCase().indexOf(glyph.key) !== -1) {
          index = i;
          // debugger;
          break;
        } else {
          i++;
        }
      }
    }
    // debugger;
    return index;
  }
  /*
   * ---------------------------------------------------------------------------------
   * Usage : use 'glyphicon glyphicon-camera' for example.
   * Note : The View does not require to programatically draw the images,
   *     just setting the glyph/image name will suffice. For image such as jpg/png/svg
   *     the view contains the name of the folder. So, for example, no need to add
   *     '/photos/' + chat.jpg, just simply 'chat.jpg' will suffice.
   * ---------------------------------------------------------------------------------
   */
  public setKvGlyphiconArray (glyphs : KvMemberActivityGlyph[], receiverSitUserId : number) : KvMemberActivityGlyph[] {
    let glyphKvArr: KvMemberActivityGlyph[] = [];
    let index = 0;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyphs) && glyphs.length > 0) {
      // debugger;
      glyphs.forEach((e) => {
        glyphKvArr = this.cases(e.key, index++, glyphKvArr, receiverSitUserId);  // TODO: restore
      });

      /*
       * this.displayGlyphiconMenu();
       * EmitterSubjectService.emitDisplayGlyphiconMenu(this.glyphKvArr);
       */
    }
    // debugger;
    return glyphKvArr;
  }
  
  
  /*
   * =================================================================================
   * Begin of Pagination (core):
   * =================================================================================
   */
  // ---------------------------------------------------------------------------------
  public setKvGlyphPager (page : number) : any {
    return this.setPagerWithKvGlyphs(this.kvGlyphArr, page);
  }
  // ---------------------------------------------------------------------------------
  public setPagerWithKvGlyphs (glyphs : KvMemberActivityGlyph[], page : number) : PagerMemberActivity {
    if (page < 1) {
      return null as any;
    }   

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyphs) && glyphs.length > 0) {
      this.totalItems = glyphs.length;
      this.kvGlyphArr = glyphs;
      this.currentPage = page > 0 && page <= glyphs.length + 1 ? page : 1;
    }

    // debugger;

    // calculate total pages
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);

    // debugger;
    this.getPagerOfAnySizeUpto10(this.totalItems, this.page);

    /*
     * get pager object
     * (totalItems, currentPage, pageSize)
     */

    this.pager = this.setupPagerMemberActivity(this.kvGlyphArr, this.currentPage);

    // debugger;
    this.pager.kvMemActGlyphArrForPhotoMgmt = this.kvGlyphArr.slice(this.pager.startItemNo, this.pager.endItemNo + 1);
    this.pager.kvMemActGlyphArrForViewMember = this.pager.kvMemActGlyphArrForPhotoMgmt;
    return this.pager;
  }

  // ---------------------------------------------------------------------------------
  public setupPagerMemberActivity (kvGlyphArr : KvMemberActivityGlyph[], page : number) : any {
    this.pager = new PagerMemberActivity();

    this.pager.pageSize = this.pageSize;

    // kvPhotoArr varoiables:
    // ----------------------
    this.pager.kvGlyphArr = kvGlyphArr;
    this.pager.startItemNo = 0;
    this.pager.firstItem = kvGlyphArr[ 0 ];
    this.pager.endItemNo = kvGlyphArr.length;
    this.pager.lastItem = kvGlyphArr[ kvGlyphArr.length - 1 ];

    // pages variables:
    // ----------------
    this.pager.pages = this.pages;
    this.pager.currentPageNo = this.page;
    this.pager.startPage = this.pages[ 0 ];
    this.pager.endPage = this.pages[ this.pages.length - 1 ];
    this.pager.nextPageNo = this.page + 1 <= this.totalPages ? this.page + 1 : this.totalPages;
    this.pager.previousPageNo = this.page - 1 >= 1 ? this.page - 1 : 1;
    this.pager.totalPages = this.totalPages;



    // pagedItems variables:
    // ---------------------
    this.pager.currentKvGlyphItem = this.kvGlyphArr[ this.page - 1 ];
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pager.currentItem)) {
      this.pager.currentItemNo = this.pager.currentItem.index;
    }

    this.pager.endItemNo = this.endIndex;
    if (this.endIndex < this.totalItems && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvGlyphArr[ this.endIndex ])) {
      this.pager.lastItem = this.kvGlyphArr[ this.endIndex ];
    }

    if (this.pager.nextPageNo > 0) {
      this.pager.nextKvGlyphItem = this.kvGlyphArr[ this.pager.nextPageNo - 1 ];
    }
    else {
      this.pager.nextKvGlyphItem = this.kvGlyphArr[ this.totalItems - 1 ];
    }

    if (this.pager.previousPageNo >= 1) {
      this.pager.previousKvGlyphItem = this.kvGlyphArr[ this.pager.previousPageNo - 1 ];
    }

    this.pager.startItemNo = this.startIndex;
    if (this.startIndex >= 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvGlyphArr[ this.startIndex ])) {
      this.pager.firstItem = this.kvGlyphArr[ this.startIndex ];
    }
    this.pager.totalItems = this.totalItems;

    return this.pager;
  }
  // ---------------------------------------------------------------------------------
  // Note:  Even though this method eliminates the need
  //        of it's counterParts = [GetPagerOfSize10(), GetPagerOfSize5()]
  //        we DO NOT delete it's counterParts
  // ---------------------------------------------------------------------------------
  public getPagerOfAnySizeUpto10 (totalItems : number, page : number) : void {
    let midCeilingOfPageSize = 0;
    let midFloorOfPageSize = 0;
    if (this.pageSize > 0) {
      midFloorOfPageSize = Math.floor(this.pageSize / 2);
      midCeilingOfPageSize = Math.ceil(this.pageSize / 2);
    }

    let startPage;
    let endPage;
    if (this.totalPages <= this.pageSize) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = this.totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages:
      // 1) lowerLimit of currentPageNo:
      if (this.page <= midCeilingOfPageSize) {
        startPage = 1;
        endPage = this.pageSize;
        // 2) upperLimit of currentPageNo:
      } else if (this.page + midFloorOfPageSize >= this.totalPages) {
        startPage = this.totalPages - this.pageSize - 1;
        endPage = this.totalPages;
        // 3) middleRange of currentPageNo
      } else {
        startPage = this.page - midCeilingOfPageSize - 1;
        endPage = this.page + midFloorOfPageSize;
      }
    }
    this.firstPage = startPage;
    this.lastPage = endPage;

    // ref: http://xahlee.info/js/javascript_range_array.html
    // const xah_range = ((min, max, step = 1,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)));

    // create an array of pages to ng-repeat in the pager control
    this.pages = xah_range(startPage, endPage, 1);

    // --------------------------------------------------------------------------
    // this.startIndex = 0;
    // var baseEndIndex = Math.min(this.startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize),
    //  (this.totalItems > 1 ? this.totalItems - 1 : this.totalItems));

    // this.startIndex = baseEndIndex - this.pageSize + 1;
    this.startIndex = (this.page - 1) * this.pageSize;
    this.endIndex = Math.min(this.startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize),
      (this.totalItems > 1 ? this.totalItems - 1 : this.totalItems));
  }
  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------
  public GetPager (totalItems : any, currentPage : any, pageSize : any): any {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = this.range(startPage, endPage, 1);

    // return object with all pager properties required by the view
    this.pager.totalItems = this.totalItems = totalItems;
    this.pager.currentPage = this.currentPage = currentPage;
    this.pager.nextPage = this.currentPage = currentPage + 1 <= endPage ? currentPage + 1 : endPage;
    this.pager.previousPage = this.previousPage = currentPage - 1 >= startPage ? currentPage - 1 : startPage;
    this.pager.pageSize = this.pageSize = pageSize;
    this.pager.totalPages = this.totalPages = totalPages;
    this.pager.startPage = this.firstPage = startPage;
    this.pager.endPage = this.lastPage = endPage;
    this.pager.startItemNo = this.startIndex = startIndex;
    this.pager.endItemNo = this.endIndex = endIndex;
    this.pager.pages = pages;
    return this.pager;
  }
 // ---------------------------------------------------------------------------------

  public GetPagerOfSize10 (totalItems : any, currentPage : any, pageSize : any): any {
    // default to first page
    this.currentPage = currentPage > 0 ? currentPage : 1;
    this.totalItems = totalItems > 0 ? totalItems : 1;
    // default page size is 10
    this.pageSize = pageSize > 0 ? pageSize : 10;

    // calculate total pages
    const totalPages = Math.ceil(this.totalItems / this.pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (this.currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (this.currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = this.currentPage - 5;
        endPage = this.currentPage + 4;
      }
    }

    /*
     * calculate start and end item indexes
     * Updated version:
     * ------------------
     * let startIndex = totalItems <= this.pageSize ? (this.currentPage - 1)
     *   : this.currentPage > this.pageSize && this.pageSize < totalPages ? (this.currentPage - 1) * this.pageSize  : 0;
     * let endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1
     *   : this.pageSize), (this.totalItems > 1 ? this.totalItems - 1  : this.totalItems));
     */

    /*
     * original version:
     * ------------------
     */
    const startIndex = this.currentPage * this.pageSize - 1;
    const endIndex = Math.min(startIndex + this.pageSize - 1, this.totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = this.range(startPage, endPage, 1);

    // return object with all pager properties required by the view
    this.pager.totalItems = totalItems;
    this.pager.currentPage = currentPage;
    this.pager.nextPage = currentPage + 1 <= endPage ? currentPage + 1 : endPage;
    this.pager.previousPage = currentPage - 1 >= startPage ? currentPage - 1 : startPage;
    this.pager.pageSize = pageSize;
    this.pager.totalPages = totalPages;
    this.pager.startPage = startPage;
    this.pager.endPage = endPage;
    this.pager.startItemNo = startIndex;
    this.pager.endItemNo = endIndex;
    this.pager.pages = pages;
    return this.pager;
  }
  // ---------------------------------------------------------------------------------
  public GetPagerOfSize5 (totalItems : any, currentPage : any, pageSize : any): any {
    // default to first page
    this.currentPage = currentPage > 0 ? currentPage : 1;
    this.totalItems = totalItems > 0 ? totalItems : 1;
    // default page size is 10
    this.pageSize = pageSize > 0 ? pageSize : 5;

    // calculate total pages
    const totalPages = Math.ceil(this.totalItems / this.pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (this.currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (this.currentPage + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = this.currentPage - 3;
        endPage = this.currentPage + 2;
      }
    }

    // calculate start and end item indexes
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize), this.totalItems > 1 ? this.totalItems - 1 : this.totalItems);

    // create an array of pages to ng-repeat in the pager control
    const pages = this.range(startPage, endPage, 1);

    // return object with all pager properties required by the view
    this.pager.totalItems = totalItems;
    this.pager.currentPage = currentPage;
    this.pager.nextPage = currentPage + 1 <= endPage ? currentPage + 1 : endPage;
    this.pager.previousPage = currentPage - 1 >= startPage ? currentPage - 1 : startPage;
    this.pager.pageSize = pageSize;
    this.pager.totalPages = totalPages;
    this.pager.startPage = startPage;
    this.pager.endPage = endPage;
    this.pager.startItemNo = startIndex;
    this.pager.endItemNo = endIndex;
    this.pager.pages = pages;
    return this.pager;
  }
  // ---------------------------------------------------------------------------------
  public range (min : any, max : any, delta : any): any {
    const arr : any[] = [];
    let stepCount;

    if (min && !max && !delta) {
      for (let i = 0; i < min; i++) {
        arr[i] = i + 1;
      }
    } else {
      if (min && max && !delta) {
        stepCount = max - min;
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i + min);
        }
      } else {
        stepCount = Math.floor((max - min) / delta);
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i * delta + min);
        }
      }
    }
    return arr;
  }
  /*
   * =================================================================================
   *   End of Pagination (core):
   * =================================================================================   
   */
  
  // ---------------------------------------------------------------
  public rotateArrowGlyphicons () : void
  {
    this.rotateThisGlyphicon( 'pageV-F' );
    this.rotateThisGlyphicon( 'pageV-prev' );
    this.rotateThisGlyphicon( 'pageV-L' );
    this.rotateThisGlyphicon('pageV-next');
    EmitterSubjectService.emitRunNgAfterViewInit(true);
  }
  // ---------------------------------------------------------------
  public rotateArrowGlyphiconOfIdPrefix (idPrefix : string) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(idPrefix)) {
      this.rotateGlyphiconForId(idPrefix + '-F');
      this.rotateGlyphiconForId(idPrefix + '-prev');
      this.rotateGlyphiconForId(idPrefix + '-L');
      this.rotateGlyphiconForId(idPrefix + '-next');
    }
    EmitterSubjectService.emitRunNgAfterViewInit(true);
  }
  /*
   * ---------------------------------------------------------------
   * Note : this method finds a substring in an array of string to do its task
   * ---------------------------------------------------------------
   */
  public rotateThisGlyphicon ( page : string ) : void
  {
    const navPageArr = [ 'pageV-F', 'pageV-L', 'pageV-prev', 'pageV-next' ];

    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( page ) && typeof page === 'string' )
    {
      navPageArr.forEach( ( a ) =>
      {
        if ( a.indexOf( page ) > -1 )
        {
          // debugger;
          let pElem = document.getElementById( page );

          if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pElem ) )
          {
            const pageId = 'pageV-' + page;

            pElem = document.getElementById( pageId );
            if (!DomUtilsServiceStatic.doesElementHasThisClass( pElem, 'pagerRotate' ) )
            {
              // debugger;
              this.renderer?.addClass(pElem, 'pagerRotate');
              pElem.remove();
            }
          }
        }
      } );
    }
  }
  // ---------------------------------------------------------------------------------
  public rotateGlyphiconForId (id : string) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) && typeof id === 'string') {     
       debugger;
      let pElem = DomUtilsServiceStatic.getElementById(id);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.renderer)) {
        if (!DomUtilsServiceStatic.doesElementHasThisClass(pElem, 'pagerRotate45Deg')) {
           debugger;
          this.renderer?.addClass(pElem, 'pagerRotate45Deg');
          pElem.remove();
        }
      }
    }
  }
  // ---------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------
  public setPagerPic(pics: string[], pElem: any, page: number): void {
    if (page > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pics) && pics.length > 0) {
      let i = 0;

      pics.map((e) => {
        ++i;
        if (i + 1 === page) {
          this.renderer?.setStyle(pElem, 'background-image', 'url(\'' + pics[i] + '\')');
        }
      });
    }
  }
  // ---------------------------------------------------------------------------------
  public setStyleProperties(properties:any = { }) : void {
    Object.keys(properties).forEach((k : any) => document.documentElement.style.setProperty(`--${k}`, properties[k])); // Sayeed: Experimental: 'k.id'
  }
  /*
   * ===============================================================
   * Note : Not used at the moment, and may need to be updated in accordance with setPage()
   * ---------------------------------------------------------------
   */
  public setPageOfProfilePics (profilePics : any, page : any): any {
    if (profilePics && profilePics.profileImageArr && profilePics.profileImageArr?.length > 0) {
      // this.profilePics = profilePics;  // TODOL restore
    }
    if (page < 0) {
      return;
    }

    this.currentPage = page;
    const totalItems = profilePics ? profilePics?.profileImageArr?.length : 0;
    // get pager object from service

    this.pager = this.GetPager(totalItems, page, 10); // (totalItems, currentPage, pageSize)

    // get current page of items
    if (profilePics && profilePics?.profileImageArr && profilePics?.profileImageArr?.length > 0) {
      const profileImageArr = profilePics.profileImageArr.slice(this.pager.startItem, this.pager.endItemNo + 1);

      if (profileImageArr && profileImageArr.length > 0) {
        profileImageArr.map((e : any) => {
          // this.profilePics.pagedProfilePics.push(e.image);  // TODOL restore
        });
      }
    }
    /*
     * let pElem = document.getElementById('page-' + page);
     * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pElem)) this.setPagerPic(this.pics, pElem, page);
     */
    return this.pager;
    /*
     * EmitterSubjectService.emitPager(this.pager);
     * EmitterSubjectService.emitPagedProfilePics(this.profilePics.pagedProfilePics);
     */
  }
  /*
   * ===============================================================
   * End of paginatoin-related-useful-functions:
   * ===============================================================
   */

 
  /*
   * =================================================================================
   * --------------------------------------------------
   * NOTE : All the image-setter methods are here:
   * Note : this method will simply set the background-image of all the numbered-pages (1...10 ->)
   * --------------------------------------------------
   * TODO : Test with setTimeout
   */
  public setPagerImagesByKvImageArr(kvImageArr: KvPhoto[]): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvImageArr) && kvImageArr.length > 0) {
      // debugger;
      kvImageArr.map((e) => {
        // debugger;
        const image = 'url(\'' + e.value + '\')';

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
          const pageId = 'pageB-' + (e.photoId + 1);
          // debugger;
          const pElem = document.getElementById(pageId);

          if (pElem) {
            // debugger;
            this.renderer?.setStyle(pElem, 'backgroumd-image', image);
            pElem.remove();
          }
        }
        // debugger;
      });
    }
  }
  /*
   * ---------------------------------------------------------------------------------
   * This method is not used and deprecated and used as a guide/referene.
   * ---------------------------------------------------------------------------------
   */
  public GetPagerOriginal (totalItems : any, currentPage : any, pageSize : any) : any {
    // default to first page
    this.currentPage = currentPage > 0 ? currentPage : 1;
    this.totalItems = totalItems > 0 ? totalItems : 1;
    // default page size is 10
    this.pageSize = pageSize > 0 ? pageSize : 10;

    // calculate total pages
    const totalPages = Math.ceil(this.totalItems / this.pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (this.currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (this.currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = this.currentPage - 5;
        endPage = this.currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize), this.totalItems > 1 ? this.totalItems - 1 : this.totalItems);

    // create an array of pages to ng-repeat in the pager control
    const pages = this.range(startPage, endPage, 1);

    // return object with all pager properties required by the view
    this.pager.totalItems = this.totalItems;
    this.pager.currentPageNo = this.currentPage;
    this.pager.pageSize = this.pageSize;
    this.pager.totalPages = totalPages;
    this.pager.startPage = startPage;
    this.pager.endPage = endPage;
    this.pager.startItemNo = startIndex;
    this.pager.endItemNo = endIndex;
    this.pager.pages = pages;
    return this.pager;
  }
  /*
   * ---------------------------------------------------------------------------------
   * This method is not used and deprecated and used as a guide/referene.
   * ---------------------------------------------------------------------------------
   */
  public rangeOriginal (min : any, max : any, delta : any): any {
    const arr : any[] = [];
    let stepCount;

    if (min && !max && !delta) {
      for (let i = 0; i < min; i++) {
        arr[i] = i + 1;
      }
    } else {
      if (min && max && !delta) {
        stepCount = max - min;
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i + min);
        }
      } else {
        stepCount = Math.floor((max - min) / delta);
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i * delta + min);
        }
      }
    }
    return arr;
  }
  /*
   * ===============================================================
   * End of Code-Reference-for-letious-pagination-tasks:
   * ===============================================================
   */
}
// ---------------------------------------------------------------------------------
  // Note : this method does not check whether it is a isPhotoMgmt or isViewMember.
  //       the caller should check/filter and call this only in case of isPhotoMgmt==true.
  // NOTE: Intend to deprecate!!
  // Note: instead of this method, user getPagerVGlyphMenuForKvPhoto (kvp).
  // ---------------------------------------------------------------------------------
  //getPagerVMemActGlyphForCurrentPage (input : any) : any {
  //  // Note:  IMPORTANT! it may be required that either photoId or PhotoBnId will be used but NOT both
  //  //        and the decision will depend on application wide whether Photo or PhotoBn will be used.
  //  // ----------------------------------------------------------------------------------------------
  //  if (input instanceof KvPhoto) {
  //    // debugger;
  //    let kvPhoto = input as KvPhoto;
  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(input) || kvPhoto.sitUserId === 0 || kvPhoto.photoId === 0 || kvPhoto.photoBnId == 0) {
  //      this.currentPageGlyph = EmitterSubjectService.getCurrentPage();
  //      // debugger;

  //      // if currentPageGlyph is still not complete, create it from currentPhoto or currentKvPhoto
  //      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph.value)) {
  //        this.currentPhoto = EmitterSubjectService.getCurrentPhoto();
  //        // debugger;

  //        //  if currentPhoto data exists:
  //        // -----------------------------
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPhoto) && this.currentPhoto.photoId > 0) {
  //          this.currentPageGlyph.photoId = this.currentPhoto.photoId;
  //          this.currentKvPhoto = CopyServiceStatic.copyFromPhotoToKvPhoto(this.currentPhoto);
  //          EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //          // debugger;
  //        }
  //        //  create currentKvPhoto from loginSuccess and kvPhoto:
  //        // -----------------------------------------------------
  //        else {
  //          this.currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPhoto)) {
  //            this.currentKvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
  //          }
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPhoto)) {
  //            this.currentKvPhoto = CopyServiceStatic.copyKvPhotoIfExists(this.currentKvPhoto, kvPhoto);
  //            this.currentPageGlyph.photoId = this.currentKvPhoto.photoId;
  //          }
  //        }

  //        // create the currentPageGlyph:
  //        // ----------------------------
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto) && this.currentKvPhoto.photoId > 0) {
  //          this.currentPageGlyph = CopyServiceStatic.copyFromKvPhotoToKvGlyph(this.currentKvPhoto);
  //          // debugger;
  //        }
  //      }
  //      // Note : currentKvPhoto does not have a pageNum, so we first create the pagination, and then get its number:
  //      // ----------------------------------------------------------------------------------------------------------
  //      if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph) && this.currentPageGlyph.pageNum <= 0)
  //        || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto) && this.currentKvPhoto.itemNum <= 0)) {

  //        this.pagerVMemActGlyph = this.setKvGlyphPager(1);
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyph)) {
  //          this.pagerVMemActGlyph.kvGlyphArr.map(e => {
  //              // debugger;
  //              if (e.photoId === this.currentKvPhoto.photoId && e.pageNum > 0) {
  //                this.currentKvPhoto.itemNum = e.pageNum;
  //                this.currentPageGlyph.pageNum = e.pageNum;
  //                this.currentPageGlyph.photoId = e.photoId;
  //                EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //                EmitterSubjectService.setCurrentPage(this.currentPageGlyph);
  //                // debugger;
  //              }
  //            });
  //          }
  //      }
  //      else {
  //        EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //        EmitterSubjectService.setCurrentPage(this.currentPageGlyph);
  //        // debugger;
  //      }
  //    }
  //    else {
  //      // debugger;
  //      if (this.currentKvPhoto.photoId === 0) {
  //        this.currentKvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
  //        this.currentKvPhoto = CopyServiceStatic.copyKvPhotoIfExists(this.currentKvPhoto, kvPhoto);
  //      }
  //      this.kvGlyphArr = this.createPhotoMgmtGlyphMenuArrFromKvPhoto(this.currentKvPhoto);
  //      // debugger; // TODO: watch for primary-photo-case
  //      this.setGlyphs(this.kvGlyphArr);
  //    }
  //  }
  //  else if (input instanceof KvMemberActivityGlyph) {
  //    // debugger;
  //    this.currentPageGlyph = input as KvMemberActivityGlyph;
  //  }

  //  // render the glyphicon-menu for the currentPageGlyph:
  //  // ----------------------------------------------
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph.value)
  //    && this.currentPageGlyph.value.length > 0) {
  //    // debugger;

  //    // var returnUrl = window.location.pathname + window.location.search;

  //    // debugger;
  //    // will display the glyphs
  //    this.kvGlyphArr = this.createPhotoMgmtGlyphMenuArrFromKvGlyph(this.currentPageGlyph);
  //    // debugger;


  //    // Note : The View does not require to programatically draw the images, just setting the glyph/image name will suffice
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvGlyphArr) && this.kvGlyphArr.length > 0) {
  //      // debugger;
  //      this.executeGlyphMenuArr(this.kvGlyphArr);
  //      // debugger;
  //    }
  //  }

  //  if (this.currentPageGlyph.pageNum > 0) {
  //    // debugger;
  //    this.pagerVMemActGlyph = this.setKvGlyphPager(this.currentPageGlyph.pageNum); // current-case
  //  }
  //  else {
  //    // debugger;
  //    this.pagerVMemActGlyph = this.setKvGlyphPager(1);  // default-case
  //  }
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyph)) {
  //    this.pagerVMemActGlyph.isPhotoMgmt = true;
  //    // debugger;
  //  }
  //  EmitterSubjectService.emitRunNgAfterViewInit(true);
  //  return this.pagerVMemActGlyph;
  //}
