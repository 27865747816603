'use-strict'
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../../../models/account/loginSuccess.model';
import { Bool } from '../../../../models/common/bool.model';
import { KeyValueResult } from '../../../../models/keyValue/keyValueResult.model';
import { KvAny } from '../../../../models/keyValue/kvAny.model';
import { CoreServiceService } from '../../../../services/coreServiceService/coreServiceService.service';
import { KvArrayService } from '../../../../services/coreServiceService/kvArrayService.service';
import { EmitterSubjectService } from '../../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Animations, slideInOutAnimation } from '../../../animation';

@Component({
  selector: 'app-key-value-any',
  templateUrl: './keyValueAny.component.html',
  styleUrls: ['./keyValueAny.component.scss'],
  animations: [Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush, // default //
})
export class KeyValueAnyComponent implements OnInit, OnDestroy, AfterViewInit {
  public kvArrayService!: KvArrayService;
  @Input() arrKvAny: KvAny[] = [];
  @Input() arrKvAnyLegend = '';
  @Input() arrKvAnyName = '';
  @Input() id = -1;
  @Input() sitUserId = -1;
  @Input() isRequired = false;
  @Input() isFormSubmitted = false;
  public isClosedBool: Bool = new Bool();
  public isDropdownOpen = false;
  public kvLegend: string[] = ['Profile description', 'About me', 'My preferences', 'What I am looking for', 'What I am into'];
  public kvName: string[] = ['Description', 'ProfileInfo', 'Preferences'];
  public arrKvForm!: FormGroup;
  public arrKvResult: KeyValueResult = new KeyValueResult();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public loginSuccess!: LoginSuccess;
  public selectedKv!: FormControl;
  public selectedKvIndex = -1;
  // ---------------------------------------------------------------
  constructor(
    private cdr: ChangeDetectorRef,
    private coreServiceService: CoreServiceService,
    private fb: FormBuilder,
    private kvArrService: KvArrayService,
    private router: Router,
    @Inject(DOCUMENT) document: any
  ) {
    if (coreServiceService) {
      this.kvArrayService = coreServiceService.getKvArrayService();
    }
    if (this.isRequired) {
      this.arrKvForm = this.fb.group({
        /*
         * 'radioSelection' : this.fb.control(this.radioboxName, [
         * Validators.required])
         */
      });
    }
    /*
     * else this.radioboxForm = this.fb.group({
     * 'radioSelection' : this.fb.control(this.radioboxName)
     * });
     */
    this.arrKvResult.kvName = this.arrKvAnyName;
  }
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    // debugger;
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit() {
    this.cdr.detectChanges();
    // debugger; kvAnyId-{{sitUserId}}-{{i}}
    const i = 0;
    let id = '';

    this.arrKvAny.map((e) => {
      if (e && e.key && e.key.indexOf('image') !== -1 && e.value) {
        id = 'kvAnyId-' + this.sitUserId.toString() + '-' + this.id.toString() + '-' + i.toString();
        this.kvArrService.setBackgroundImage(id, e.value);
      }
    });
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  isNumber(val:any): boolean {
    return typeof val === 'number';
  }
  /*
   * ---------------------------------------------------------------
   * isArray(val) : boolean { return typeof val === 'array'; }
   * ---------------------------------------------------------------
   * ref:https:// www.positronx.io/angular-checkbox-tutorial/
   */
  onKeyValueChange(e:any) {
    if (e.target.checked) {
      /*
       * this.selectedRadiobox = this.radioboxForm.get('radioSelection') as FormControl;
       * this.selectedRadiobox.patchValue('');
       * this.selectedRadiobox.patchValue(e.target.value);
       */

      this.arrKvResult.kvName = this.arrKvAnyName;
      this.arrKvResult.kvSelection = e.target.value;
      this.arrKvResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
      EmitterSubjectService.emitKeyValueResult(this.arrKvResult);
    }
  }
  // ---------------------------------------------------------------
  isRequiredPassed() {
    return this.arrKvResult.kvSelection.length > 0;
  }
  // ---------------------------------------------------------------
}
