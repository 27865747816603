import { CommunicationActivity } from './communicationActivity.model';

export class EnoteBox {
  public boxType = '';
  public communicationId = 0;
  public isInbox = true;

  public allPremiumEnotes: CommunicationActivity[] = [];
  public inboxPremiumEnotes: CommunicationActivity[] = [];
  public savedPremiumEnotes: CommunicationActivity[] = [];
  public sentPremiumEnotes: CommunicationActivity[] = [];
  public trashedPremiumEnotes : CommunicationActivity[] = [];

  public sitUserId = 0;
  public signedInUserId = 0;

  public allPremiumEnoteTotal = 0;
  public inboxPremiumEnoteTotal = 0;
  public savedPremiumEnoteTotal = 0;
  public sentPremiumEnoteTotal = 0;
  public trashedPremiumEnoteTotal = 0;
  constructor() { }
}
