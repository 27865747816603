
import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Dictionary } from 'dictionaryjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { EmitOffOnResult } from '../../models/common/emitOffOnResult.model';
import { SpinnerModel } from '../../models/common/spinnerModel.model';
import { Communication } from '../../models/communication/communication.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { Communicator } from '../../models/communication/communicator.model';
import { MailBox } from '../../models/communication/mailBox.model';
import { KV } from '../../models/keyValue/kv.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { CommunicationMessage } from '../../models/communication/communicationMessage.model';

@Injectable({ providedIn: 'any' })

export class EmailService implements OnDestroy {
  public blackListCredentialKvArr : KV[] = [];
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public commActivityIds : number[] = [];
  public email : CommunicationActivity = new CommunicationActivity();
  public emailCount = 0;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public getEmailObservable : Observable<any> = null;
  public isMobilevar = false;
  public isInbox = false;
  public isOnLine = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public mailBox: MailBox = new MailBox();
  public message = '';
  public myCommunicationActivityBns: BoxNonceEntity[] = [];
  public myCommunicationActivityIds: number[] = [];
  public myEmailDictionary : Dictionary<number, CommunicationActivity> = null; // DictionaryServiceStatic.myEmailDictionary;
  public myEmails : CommunicationActivity[] = [];
  public myStaticEmails: CommunicationActivity[] = [];
  public myEmialsMenuKvArr: KvAny[] = [];
  public myInboxEmails: CommunicationActivity[] = [];
  public mySavedEmails: CommunicationActivity[] = [];
  public mySentEmails: CommunicationActivity[] = [];
  public myTrashedEmails: CommunicationActivity[] = [];
  public myConversationsBoxNonceArr: BoxNonceEntity[] = [];
  public offOnResult: EmitOffOnResult = new EmitOffOnResult();
  public profileTile: ProfileTile = new ProfileTile();
  public receiverInfo: Communicator = new Communicator();
  public renderer!: Renderer2;
  public senderInfo: Communicator = new Communicator();
  public sitUserId = 0;
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public tempElem: any;
  public timerArray: any[] = [];
  public timer: any;
  // ---------------------------------
  constructor (
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public dictionaryService : DictionaryService,
    public httpService : HttpService,
  )
  {
    this.myEmailDictionary = this.dictionaryService.myEmailDictionary;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
        .subscribe(result => {
          this.loginSuccess = result as LoginSuccess;
          this.emitterDestroyed$.next( true );
          this.emitterDestroyed$.complete();
          this.emitterDestroyed$.unsubscribe();
        });

    EmitterSubjectService.isMobileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
        this.isMobilevar = result as boolean;
        this.emitterDestroyed$.next( true );
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
      });
      this.isMobilevar = EmitterSubjectService.getIsMobile();
      this.isOnLine = EmitterSubjectService.getIsOnLine();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  public fetchBlackListCredentials () : Observable<KV[]> {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.blackListCredentialKvArr)
      || this.blackListCredentialKvArr.length === 0) {
      // debugger;
      this.blackListCredentialKvArr = [];
      return new Observable(observer => {
        // this.httpService.get('/Home/GetBlackListCredentialsJson').subscribe(
        this.httpService.get('assets/json/blackListCredentials.json').subscribe(
          (result) => {
           // debugger;
            //if (result) {
            //  // debugger;
            //  result.map(e => {
            //    let kv = new KV();
            //    kv.key = (e as KV).key;
            //    kv.value = (e as KV).value;
            //    this.blackListCredentialKvArr.push(kv);
            //  });

            //  observer.next( this.blackListCredentialKvArr);
            //}
            observer.next(result);
          },
          (error) => {
            EmitterSubjectService.emitMyErrorLog('Error occured in GetBlackListCredentialsJson();\n Error-message: ' + error.message);
          },
        );
      })
    }
    else return null;
  }
  // ---------------------------------------------------------------
  public getEmailCount () : number {
    return this.emailCount;
  }
  // ---------------------------------------------------------------
  public setEmailCount (c: number) : void{
    this.emailCount = c;
  }
  // ---------------------------------------------------------------
  public getBlackListCredentialKvArr () : KV[]{
    this.blackListCredentialKvArr = null;
    this.fetchBlackListCredentials().subscribe(data => {
      this.blackListCredentialKvArr =  data;
    })
    return this.blackListCredentialKvArr;
	}
  // -----------------------------------------------------------
  nullPromise () : any
  {
    this.timer = setTimeout( () =>
    {
      // debugger;
    }, 500);
    clearTimeout(this.timer);
    if ( this.timer )
    {
      this.timerArray.push( this.timer );
    }
  }
  // ---------------------------------------------------------------
  processCommunicatorResult( sitUserId: number, result: any ): Promise<any>
  {
    let date = new Date();
    let commCator : Communicator = new Communicator();

    return new Promise<any>((resolve, reject) => {

      if (result) {
        // debugger;
        const bn = result;
        if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
          commCator = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Communicator;
          debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commCator)) {
            EmitterSubjectService.emitCommunicator(commCator);
            resolve(commCator);
          }
        }
      }
      else {
        this.message = date.getTime() +  'the result of processCommunicatorResult (sitUserId:\'' + sitUserId + '\') the promise was rejected;\n<br/> result: ' + result;
        // EmitterSubjectService.emitMyErrorLog(this.message);
        reject(this.message);
      }
      return this.nullPromise();
    }).catch((error) => {
      console.log(date.getTime() + 'emailService. processCommunicatorResult (sitUserId: ' + sitUserId + ') retrned a null promise.');
    })
  }
  // ---------------------------------------------------------------
  // Not in use
  // ---------------------------------------------------------------
  getEmailMessage (cActId : number, isinbox : boolean) : Observable<any> {
    let date = new Date();
    let email : CommunicationActivity = new CommunicationActivity();
    var cm : CommunicationMessage = new CommunicationMessage();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    var commActId = cActId;
    var isInbox = isinbox;
    var count = 0;
    var unsalted : any;
    // debugger;
    return new Observable<any>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
        const bnComm : Communication = new Communication();
        bnComm.communicationMessageId = commActId;
        bnComm.isInbox = isInbox; // TODO: May need to add isMyEmail property instead
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          this.httpService.postObservable('api/EmailNote/GetEmailMessage', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          }, 'json2text').subscribe(result => {
            const bn = result as BoxNonceEntity
            // debugger;
            if (SlakezSaltServiceStatic.isBoxUnsalt(bn)) {
              unsalted = SlakezSaltServiceStatic.unsalted;
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
                cm = JSON.parse(unsalted) as CommunicationMessage;
                subscriber.next(cm);
              }
            }
          })
        }
        else {
          console.log(date.getTime() + 'getEmail (sitUser.commActId: ' + commActId + ') box or nonce or both  were null or undefined.');
        }
      } else {
        console.log(date.getTime() + 'getEmail (sitUser.commActId: ' + commActId + ') was not sent becuse the loginSuccess.signedInUserId === 0.');
      }
    })
  }
  // ---------------------------------------------------------------
  // Not in use
  // ---------------------------------------------------------------
  getEmail (cActId : number, isinbox : boolean) : Observable<any>
  {
    let date = new Date();
    let email : CommunicationActivity = new CommunicationActivity();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    var commActId = cActId;
    var isInbox = isinbox;
    var count = 0;

    // debugger;
    return new Observable<any>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
        const bnComm : Communication = new Communication();
        bnComm.communicationActivityId = commActId;
        bnComm.isInbox = isInbox; // TODO: May need to add isMyEmail property instead
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          this.httpService.postObservable('api/EmailNote/GetEmail', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          }, 'json2text').subscribe(result => {    
            count++;
             // email = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result)) as CommunicationActivity;
              debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
              email = this.processEmail(email);
              this.dictionaryService.updateDictionary(email, "myEmail", email.communicationActivityId);
              debugger;
              let tMailBox = this.putInMailStorage(email);
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tMailBox)) {
                debugger;
                this.mailBox = tMailBox; // TODO: merge mailboxes 

               // EmitterSubjectService.emitMailBox(this.mailBox);
              }
              this.myEmails = this.dictionaryService.myEmailDictionary.values();
              subscriber.next(email);

              if (count == this.emailCount) {
                subscriber.complete();
                debugger;
              }
            }
          })
        }
        else {
          console.log(date.getTime() + 'getEmail (sitUser.commActId: ' + commActId + ') box or nonce or both  were null or undefined.');
				}
      } else {
        console.log(date.getTime() + 'getEmail (sitUser.commActId: ' + commActId + ') was not sent becuse the loginSuccess.signedInUserId === 0.');
      }
    })  
  }
  // ---------------------------------------------------------------
  // Note: This method gets all emails at once
  // TODO: write the backend GetEmailConversation method in EnoteNoteController.
  // ---------------------------------------------------------------
  getEmailConversation (commActIds : number[], isInbox: boolean) : Observable<any> {
    let conversation : CommunicationActivity[] = [];
    let date = new Date();
    let email : CommunicationActivity = new CommunicationActivity();
    let partStr = '';
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    this.spinnerModel.message = 'Getting batch email...';
    EmitterSubjectService.emitShowSpinner(this.spinnerModel);

    // debugger;
    return this.getEmailObservable = new Observable<any>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
        let bnComm : Communication = new Communication();
        bnComm.communicationActivityIds = commActIds;
        bnComm.isInbox = isInbox;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          this.httpService.postObservable('api/EmailNote/GetEmailConversation', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          }, 'json2text').subscribe(result => {
             debugger;
            if (result as BoxNonceEntity) {
              // SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity);
              // debugger;
              // let unsalted = SlakezSaltServiceStatic.boxUnsalt(result) as string;
              var unsalted = SlakezSaltServiceStatic.boxUnsalt(result);
              debugger;
              // Note:  The unsalted data comes as an array of json communicationActivity string. So we need to 
              //        parse this string in the following way:
              //        1. split with the marker 'communicationActivityId' of each communicationActivity json object.
              //        2. attach '{communicationActivityId' as prefix to the first part since that was cut off during split.
              //        3. rempve the last parts' closing bracket (']')
              //        3. now all but the first parts are missing the truncated '{communicationActivityId' property(i.e. key) as prefix,
              //            so, prefix to each part the same prefix as the first part.
              //        4. then create and CommunicationActivity object from the string that is in each part.
              // ---------------------------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted) ) {
                let convParts = unsalted.toString().split("%#%") as string[];
                debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(convParts) && convParts.length > 0) {
                  debugger;
                  for (var i = 0; i < convParts.length-1; i++) {
                    debugger;
                    
                    let eMail = JSON.parse(convParts[ i ]) as CommunicationActivity;
                    debugger;
                    this.processEmailObservable(eMail).subscribe(email => {
                      this.myEmails.push(email);

                      subscriber.next(email);
                    });
                  }
                  subscriber.complete();
                  this.spinnerModel.message = "";
                  EmitterSubjectService.emitHideSpinner(this.spinnerModel.message);
                }
              }
              else {
                console.log(date.getTime() + 'getBatchEmail (commActIds:[ ' + commActIds + ']) unsalted result.box or nonce was null or undefined.');
              }
            }
          })
        }
        else {
          console.log(date.getTime() + 'getBatchEmail (commActIds:[ ' + commActIds + ']) box or nonce or both  were null or undefined.');
        }
      } else {
        console.log(date.getTime() + 'getEmail (commActId: [' + commActIds + ']) was not sent becuse the loginSuccess.signedInUserId === 0.');
      }
    })
  }
  // ---------------------------------------------------------------
  getMyEmailIdsObservable (isInbox : any) : Observable<any> | any {
    let email : CommunicationActivity = new CommunicationActivity();
    // debugger;
    if (this.loginSuccess.signedInUserId > 0) {
      this.spinnerModel.message = 'Getting emails...';
      EmitterSubjectService.emitShowSpinner(this.spinnerModel);

      return new Observable<any>((subscriber) => {
        let bnComm : Communication = new Communication();
        bnComm.isInbox = isInbox;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        bnComm.sitUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          // EmitterSubjectService.emitShowSpinner('Getting your emails...');
          // debugger;
          this.httpService.postObservable('api/EmailNote/GetMyEmailIds',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            }, 'json2text').subscribe(result => {
              if (result) {
                // debugger;
                let bn = result;
                bn.entityName = 'myEmailId'
                if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
                  this.commActivityIds = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as number[];
                  // debugger;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.commActivityIds) && this.commActivityIds.length > 0) {
                    // debugger;
                    this.dictionaryService.updateDictionary(this.commActivityIds, 'myEmailId', this.loginSuccess.signedInUserId);
                    // DictionaryServiceStatic.myEmailIdDictionary.setValue(this.loginSuccess.signedInUserId, this.commActivityIds);
                    // debugger;
                    this.dbDexieToDictionaryService.saveDictionaryToDbDexie(this.dictionaryService.myEmailIdDictionary, 'myEmailId', this.loginSuccess.signedInUserId);
                  }
                  // debugger;

                  this.spinnerModel.message = "";
                  EmitterSubjectService.emitHideSpinner(this.spinnerModel.message);

                  subscriber.next(this.commActivityIds);
                  subscriber.complete();
                }
                else {
                  this.message = 'bn.box or bn.nonce was null or undefined in getMyEmailIds(sitUserId:"' + this.loginSuccess.signedInUserId + '"); result:  ' + result;
                  console.log(this.message);
                }
              }
              else {
                this.message = 'returned result in getMyEmailIds(sitUserId:"' + this.loginSuccess.signedInUserId + '"); was null or empty; result: ' + result;
                // EmitterSubjectService.emitMyErrorLog(this.message);
                console.log(this.message);
              }
            })
        }
        else {
          this.message = ' getMyEmailIds(sitUserId:"' + this.loginSuccess.signedInUserId + '") boxNonceEntity was either null or empty. Error-mag: ';
          console.log(this.message);
        }
      });
    }
    else {
      return null;
    }
  }
  // ---------------------------------------------------------------
  // Not in use
  // ---------------------------------------------------------------
  getMyEmailsObservable (commActivityIds : number[], isInBox : boolean) : Observable<any> {
    let date = new Date();
    this.isInbox = isInBox;
    let emailCount = 0;
    let email : CommunicationActivity = null;
    var counter = 0;
    return new Observable((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commActivityIds) && commActivityIds.length > 0) {
       
        this.commActivityIds = commActivityIds;
        // debugger;
        for (let i = 0; i < this.commActivityIds.length; i++)
        {
          // accumulate all unsalted emails for View in the emailTileEmitter
          // debugger;
          this.getEmail(this.commActivityIds[ i ], this.isInbox).subscribe(em => {
            debugger;
            email = em as CommunicationActivity;
            subscriber.next(email);
            if (this.commActivityIds.length === emailCount) {
              subscriber.complete();
              debugger;
            }

            // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email)) {
              // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( email.sender ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( email.sender.primaryImage ) )
              // {
              //  email.sender.primaryImage = JsRegExpServiceStatic.normalizeImageData( email.sender.primaryImage );
              // }

              // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( email.receiver ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( email.receiver.primaryImage ) )
              // {
              //  email.receiver.primaryImage = JsRegExpServiceStatic.normalizeImageData( email.receiver.primaryImage );
              // }

              // this.removeEarlierOrDuplicate( enote );
              // this.myEmailDictionary.set(email.communicationActivityId, email); // OR
              //this.dictionaryService.updateDictionary(email, "myEmail", email.communicationActivityId);
              //this.dbDexieToDictionaryService.saveDictionaryToDbDexie(this.dictionaryService.myEmailIdDictionary, 'myEmail', this.loginSuccess.signedInUserId);
              //// debugger;
              //let tMailBox = this.putInMailStorage(email);
              //// debugger;
              //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tMailBox)) {
              //   debugger;
              //  this.mailBox = tMailBox; // TODO: merge mailboxes
              //  EmitterSubjectService.emitMailBox(this.mailBox);
              //  subscriber.next(this.mailBox);
              //  emailCount++;
              //   debugger;                
              //}
            // }
          })
        }
        //if (this.commActivityIds.length === emailCount) {
        //  subscriber.complete();
        //  debugger;
        //}
      }
      else {
        this.message = ' getMyEmail(commActivityIds-count:"' + this.commActivityIds.length + '") is null or undefined result.';
        // alert('Error occured in UpdateParagraph();\n Error-mag:' + error);
        console.log(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  processEmail (email : CommunicationActivity) : any {
    let date = new Date(); 
    
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email))
    {
      let dateAssay : any;
      let senderSitUserId = 0;
      if (email.senderSitUserId > 0 && email.senderSitUserId !== this.loginSuccess.signedInUserId) {
        senderSitUserId = email.senderSitUserId;
      }
      else if (email.sender && email.sender.sitUserId > 0 && email.sender.sitUserId !== this.loginSuccess.signedInUserId) {
        senderSitUserId = email.sender.sitUserId;
      }
      if (email.receiverSitUserId > 0 && email.receiverSitUserId !== this.loginSuccess.signedInUserId) {
        senderSitUserId = email.receiverSitUserId;
      }
      else if (email.receiver && email.receiver.sitUserId > 0 && email.receiver.sitUserId !== this.loginSuccess.signedInUserId) {
        senderSitUserId = email.receiver.sitUserId;
      }

      // -------------------------------------------------------------------------

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email.pElemId)) {
          email.pElemId = email.sender.sitUserId.toString();
        }

        // Note: on enote.Date, sometimes there is only UNIX epoch time, so we use receiver.date since it's the latest timestamp of the communication
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email.date)) {
          dateAssay = DateStringServiceStatic.getDateAssayOfCsTicks(email.date);
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dateAssay)) {
          email.dateFormatted = dateAssay.dateFormatted;
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email.receiverModelString)) {
          email.receiver = JSON.parse(email.receiverModelString) as Communicator;
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email.senderModelString)) {
          email.sender = JSON.parse(email.senderModelString) as Communicator;
        }

        // if (email.subject.length > 150) email.subjectShort = email.subject.slice(0, 149) + '...';

        // if (email.messageBody.length > 200) email.messageBodyShort = email.messageBody.slice(0, 199) + '...';
        // email.messageBodyShort = email.messageBody;
        if (email?.communicationMessage?.subject?.length >= 100) {
          email.subjectShort = email?.communicationMessage?.subject?.slice(0, 99) + '...';
          email.communicationMessage.subjectShort = email?.communicationMessage?.subject?.slice(0, 99) + '...';
        }
        else {
          email.subjectShort = email.communicationMessage?.subject;
          email.communicationMessage.subjectShort = email.communicationMessage?.subject;
        }
        if (email.communicationMessage?.messageBody?.length >= 150) {
          email.messageBodyShort = email.communicationMessage?.messageBody?.slice(0, 149) + '...';
          email.communicationMessage.messageBodyShort = email.communicationMessage?.messageBody?.slice(0, 149) + '...';
        }
        else {
          email.messageBodyShort = email.communicationMessage?.messageBody;
          email.communicationMessage.messageBodyShort = email.communicationMessage?.messageBody;
        }
      }

      // debugger;
      //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.myEmails)) {
      //  this.myEmails = [];
      //}
      //const index = this.isEmailTileFound(this.myEmails, email);
      //debugger;
      //if (index < 0) {
      //  this.myEmails.push(email);
      //  this.sort(this.myEmails);
      //   debugger;
      //}
      //else {
      //  this.myEmails.splice(index, 1, email);
      //  debugger;
      //}
      // debugger; 
      return email;
    }
  }
  // ---------------------------------------------------------------
  processEmailObservable (email : CommunicationActivity) : Observable<any> {
    let date = new Date();
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.myEmails)) {
      this.myEmails = [];
    }
    return new Observable<any>(subscriber => {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email)) {
        let dateAssay : any;
        let senderSitUserId = 0;
        if (email.senderSitUserId > 0 && email.senderSitUserId !== this.loginSuccess.signedInUserId) {
          senderSitUserId = email.senderSitUserId;
        }
        else if (email.sender && email.sender.sitUserId > 0 && email.sender.sitUserId !== this.loginSuccess.signedInUserId) {
          senderSitUserId = email.sender.sitUserId;
        }
        if (email.receiverSitUserId > 0 && email.receiverSitUserId !== this.loginSuccess.signedInUserId) {
          senderSitUserId = email.receiverSitUserId;
        }
        else if (email.receiver && email.receiver.sitUserId > 0 && email.receiver.sitUserId !== this.loginSuccess.signedInUserId) {
          senderSitUserId = email.receiver.sitUserId;
        }


        // -------------------------------------------------------------------------
        // since the received data has been successfull unsalted into model, it is safe to save it to indexedDb
        // emit the tile as boxNonce to be stored in indexedDb by MembersComponent
        // this.boxNonceEntity = new BoxNonceEntity();
        // this.boxNonceEntity.box = result.box;
        // this.boxNonceEntity.nonce = result.nonce;
        // this.boxNonceEntity.id = email.commActivityId; // to be used by indexedDb
        // this.boxNonceEntity.key = email.commActivityId.toString(); // to be used by indexedDb
        // this.boxNonceEntity.sitUserId = senderSitUserId > 0 ? senderSitUserId : -1; // to uniquely identify a user's data
        // this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
        // // debugger;

        // this.boxNonceEntity.entityName = 'EmailTile';
        // this.triageAllDataForIndexedDbAndDictionaryService.saveEmailTileDictionaryToIndexedDb( this.boxNonceEntity );

        // -------------------------------------------------------------------------

        // this.myCommunicationActivityBns.push( boxedTile );
        // EmitterSubjectService.emitCommunicationActivityBoxNonce( boxedTile );

        // -------------------------------------------------------------------------

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email.pElemId)) {
            email.pElemId = email.sender.sitUserId.toString();
          }

          // Note: on enote.Date, sometimes there is only UNIX epoch time, so we use receiver.date since it's the latest timestamp of the communication
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email.date)) {
            dateAssay = DateStringServiceStatic.getDateAssayOfCsTicks(email.date);
          }
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dateAssay)) {
            email.dateFormatted = dateAssay.dateFormatted;
          }
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email.receiverModelString)) {
            email.receiver = JSON.parse(email.receiverModelString) as Communicator;
          }
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email.senderModelString)) {
            email.sender = JSON.parse(email.senderModelString) as Communicator;
          }

          // if (email.subject.length > 150) email.subjectShort = email.subject.slice(0, 149) + '...';

          // if (email.messageBody.length > 200) email.messageBodyShort = email.messageBody.slice(0, 199) + '...';

          if (email?.subject?.length >= 100) {
            email.subjectShort = email?.subject?.slice(0, 99) + '...';
          }
          else {
            email.subjectShort = email.subject;
          }
          if (email.messageBody?.length >= 150) {
            email.messageBodyShort = email.messageBody?.slice(0, 149) + '...';
          }
          else {
            email.messageBodyShort = email.messageBody;
          }
        }
        
        // debugger;
        const index = this.isEmailTileFound(this.myEmails, email);
        if (index < 0) {
          this.myEmails.push(email);
          this.sort(this.myEmails);
          // debugger;
        }
        else {
          this.myEmails.splice(index, 1, email);
          // this.myEmails[ index ] = CopyServiceStatic.copyCommunicationActivityIfExists( this.myEmails[ index ], email );
        }
        // debugger;
        // this.putInMailStorage( email ); //instead myEmails will process it and get a mailBox
        // EmitterSubjectService.emitEmailTile( email );  
        // debugger;
        subscriber.next(email);
        subscriber.complete();
      }
          
    })
  }
  // ---------------------------------------------------------------
  processEmailResult (result : BoxNonceEntity) :any {
    let date = new Date();
    let email : CommunicationActivity;
    let tEmail : any;
   
    if (result) {
      const bn = result as BoxNonceEntity;
      // debugger;
      if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
        email = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as CommunicationActivity;
        debugger;
        return this.processEmail(email);
      }
    }
  }
  // ---------------------------------------------------------------
  processEmailResultObservable (result : BoxNonceEntity ): Observable<any>
  {
    let date = new Date();
    let email : CommunicationActivity;
    let tEmail : any;
    return new Observable<any>(subscriber =>{
      if (result) {
        const bn = result as BoxNonceEntity;
        // debugger;
        if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
          try {
            tEmail = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as unknown;

             debugger;
            email = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as CommunicationActivity;
             debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email)) {
              this.processEmail(email).subscribe(e => {
                // debugger;
                // this.putInMailStorage( email ); //instead myEmails will process it and get a mailBox
                // EmitterSubjectService.emitEmailTile( email );            
                subscriber.next(e);
							})              
            }
          }
          catch (e) {
            this.message = date.getTime() + 'failed to unsalt email of (sitUserId:\'' + this.loginSuccess.signedInUserId + '\'); error: ' + e;
            console.log(this.message);
            // EmitterSubjectService.emitMyErrorLog(this.message);
          }
          finally {
            ;// do nothing
            subscriber.complete();
					}
        }
      }
      else {
        this.message = date.getTime() + 'Error occured in getBoxEmail(sitUserId:\'' + this.loginSuccess.signedInUserId + '\'); result: ' + result;
        console.log(this.message);
        // EmitterSubjectService.emitMyErrorLog(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  getMailBox(): MailBox {
    return this.mailBox;
  }
  // ---------------------------------------------------------------
  getMyEmails(): CommunicationActivity[] {
    return this.myEmails;
  }
  // ---------------------------------------------------------------
  getMyInboxEmails(): CommunicationActivity[] {
    return this.myInboxEmails;
  }
  // ---------------------------------------------------------------
  getMySentEmails(): CommunicationActivity[] {
    return this.mySentEmails;
  }
  // ---------------------------------------------------------------
  getMySavedEmails(): CommunicationActivity[] {
    return this.mySavedEmails;
  }
  // ---------------------------------------------------------------
  getMyTrashedEmails(): CommunicationActivity[] {
    return this.myTrashedEmails;
  }
  // ---------------------------------------------------------------
  setMyEmails(emails: CommunicationActivity[]): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(emails) && emails.length > 0) {
      this.myEmails = emails;
      this.myEmails.map(e => {
        this.putInMailStorage(e);
      });
      return true;
    }
    return false;
  }
  // ---------------------------------------------------
  // ref:https:// www.positronx.io/angular-8-es-6-typescript-promises-examples/
  ePromise (data : any): any {
    const promise = new Promise( (resolve, reject) => {
      let timer = setTimeout(() => {
        console.log('promise returning resolve!');
        resolve(data);
      }, 1000);
      clearTimeout(timer);
    });
    return promise;
  }
  // ---------------------------------------------------------------
  isEmailTaken (email : string) : Observable<boolean> {
    var url = 'api/Account/IsEmailTaken';
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email)) {
      return new Observable<boolean>(observer => {
        this.httpService.post(url, email, 'json').subscribe(bn => {
          // debugger;
          observer.next(SlakezSaltServiceStatic.boxUnsalt(bn).toLowerCase().indexOf('true') !== -1);
          observer.complete();
          observer.unsubscribe();
        })
      })
    }
  }
  // ---------------------------------------------------------------
  isEmailTileFound ( eTileArr : CommunicationActivity[], eTile : CommunicationActivity ) : number
  {
    let index = -1;
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTileArr)
      && eTileArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.communicationType)
      && eTile.communicationType.toLowerCase().indexOf('email') !== -1) {
      // debugger;
      for (let i = 0; i < eTileArr.length; i++) {
        if (eTileArr[ i ].communicationActivityId === eTile.communicationActivityId) {
          // debugger;
          index = i;
          break;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  // Note: Objective of this method is to return C# CommunicationActivity model's property names
  //       having thier(names') first character turned from an Upper case to a Lower case letter.
  //       Just add a model-specific conversion in the same way it is done for  model.
  // ---------------------------------------------------------------
  // firstCharToLowerCase(model: any): Communicator {
  // var tModel = model as Communicator;
  // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
  //   //debugger;
  //   var outModel: Communicator = new Communicator();
  //   if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
  //       outModel.id = tModel.id;
  //       date: model.receiver.Date,
  //       latitude: model.receiver.Latitude,
  //       longitude: model.receiver.Longitude,
  //       cryptUserKey: model.receiver.CryptUserKey,
  //       email: model.receiver.Email,
  //       profileId: model.receiver.ProfileId,
  //       profileName: model.receiver.ProfileName,
  //       primaryImage: model.receiver.PrimaryImage,
  //       primaryPhotoBnId: model.receiver.PrimaryPhotoBnId,
  //       sitUserId: model.receiver.SitUserId,
  //       sitUserKey: model.receiver.SitUserKey,
  //       communicatorType: model.receiver.CommunicatorType, // {receiver, sender}
  //     }
  // }
  // //debugger;
  // return model;
  // }
  // ---------------------------------------------------------------
  isSenderFound ( senders : Communicator[], sender : Communicator ) : number
  {
    let index = -1;
    if (senders.length > 0 && sender) {
      for (let i = 0; i < senders.length; i++) {
        if (senders[i].sitUserId === sender.sitUserId) {
          // debugger;
          index = i;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  putInMailsStorage (emails : CommunicationActivity[]) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(emails) && emails.length > 0 ){
      emails.map(e => {
        this.mailBox = this.putInMailStorage(e);
      })
		}
  }
  // ---------------------------------------------------------------
  // Note: Tested and works! August 15, 2020.
  //       Currently in use
  // ---------------------------------------------------------------
  putInMailStorage(email: CommunicationActivity): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(email)) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
       // debugger;

       if (DateStringServiceStatic.compareSitUserKeys(this.loginSuccess.signedInUserKey, email.receiverSITUserKey) === 0) {
         // debugger;
         switch (email?.receiverAction?.toString().toLowerCase()) {
            case 'received': case 'read': case 'madeunread':
              email.boxName = 'inBox';
              this.myInboxEmails = this.sortMailBoxByDate( this.uniqueAddEmailTile( this.myInboxEmails, email ) );
              this.mailBox.myInboxEmails = this.myInboxEmails;
              // debugger;
              break;
            case 'saved': case 'readandsaved':
              email.boxName = 'savedBox';
              this.mySavedEmails = this.sortMailBoxByDate( this.uniqueAddEmailTile( this.mySavedEmails, email ) );
              this.mailBox.mySavedEmails = this.mySavedEmails;
               debugger;
              break;
            case 'trashed': case 'readandtrashed':
              email.boxName = 'trashedBox';
              this.myTrashedEmails = this.sortMailBoxByDate( this.uniqueAddEmailTile( this.myTrashedEmails, email ) );
              this.mailBox.myTrashedEmails = this.myTrashedEmails;
               debugger;
              break;
         }
      }
      else if (DateStringServiceStatic.compareSitUserKeys(this.loginSuccess.signedInUserKey, email.senderSITUserKey) === 0) {
        // debugger;
        switch (email.senderAction.toString().toLowerCase()) {
          case 'sent': case 'replied':
            email.boxName = 'sentBox';
            this.mySentEmails = this.sortMailBoxByDate( this.uniqueAddEmailTile( this.mySentEmails, email ) );
            this.mailBox.mySentEmails = this.mySentEmails;
            // debugger;
            break;
        }
      }
      // debugger;
      return this.mailBox;
    }
    else {
      return null;
    }
  }
  // ---------------------------------------------------------------
  salt (model : any): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt( JSON.stringify( model ) );
      return this.boxNonceEntity;
    }
    else {
      return null;
    }
  }
  // ---------------------------------------------------------------
  // sendEmailBox(myEmails: CommunicationActivity[]): void {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myEmails) && myEmails.length > 0) {
  //    this.mailBox = new MailBox();
  //    this.mailBox.myEmails = myEmails;
  //    this.mailBox.myInboxEmails = myEmails;
  //    this.mailBox.mySentEmails = myEmails;
  //    this.mailBox.mySavedEmails = myEmails;
  //    this.mailBox.myTrashedEmails = myEmails;
  //    // debugger;
  //    EmitterSubjectService.emitMailBox(this.mailBox);
  //  }
  // }
  // ---------------------------------------------------------------
  sort(mailArr: CommunicationActivity[]): CommunicationActivity[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailArr) && mailArr.length > 0) {
      mailArr.sort( (a, b) => {
        return a.communicationActivityId - b.communicationActivityId;
      });
    }
    return mailArr;
  }
  // ---------------------------------------------------------------
  sortMailBoxByDate(mailArr: CommunicationActivity[]): CommunicationActivity[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mailArr) && mailArr.length > 0) {
      mailArr.sort( (a, b) => {
        // return a.date.localeCompare(b.date);
        return Number(b.date) - Number(a.date);
      });
    }
    return mailArr;
  }
  // ---------------------------------------------------------------
  uniqueAddEmailTile(arr: CommunicationActivity[], eTile: CommunicationActivity): CommunicationActivity[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eTile.communicationType)
      && eTile.communicationType.toLowerCase().indexOf('email') !== -1) {
      // debugger;
      const index = this.isEmailTileFound(arr, eTile);
      if (index < 0) {
        arr.push(eTile);
      }
      else {
         arr[index] = CopyServiceStatic.copyCommunicationActivityIfExists(arr[index], eTile);
        // arr.splice( index, 1, eTile );
      }
      return arr;
    }
    else {
      return [];
    }
  }
  // ---------------------------------------------------------------
  // Note: this does not work as desired, but can be used for something else similar
  // ---------------------------------------------------------------
  createMailStorage(myEmails: CommunicationActivity[]): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(myEmails) && myEmails.length > 0) {
      myEmails.map(item => {
        // debugger;
        if (item.receiverSitUserKey.toString().toLowerCase().indexOf(this.loginSuccess.signedInUserKey.toString().toLowerCase()) !== -1) {
          switch (item.receiverAction.toString().toLowerCase()) {
            case 'received': case 'read': case 'madeunread':
              this.myInboxEmails = this.sortMailBoxByDate(this.uniqueAddEmailTile(this.myInboxEmails, item));
              // debugger;
              break;
            case 'saved': case 'readandsaved':
              this.mySavedEmails = this.sortMailBoxByDate(this.uniqueAddEmailTile(this.mySavedEmails, item));
              // debugger;
              break;
            case 'trashed': case 'readandtrashed':
              this.myTrashedEmails = this.sortMailBoxByDate(this.uniqueAddEmailTile(this.myTrashedEmails, item));
              // debugger;
              break;
          }
        }
        else if (item.senderSitUserKey.toString().toLowerCase().indexOf(this.loginSuccess.signedInUserKey.toString().toLowerCase()) !== -1) {
          switch (item.senderAction.toString().toLowerCase()) {
            case 'sent': case 'replied':
              this.mySentEmails = this.sortMailBoxByDate(this.uniqueAddEmailTile(this.mySentEmails, item));
              // debugger;
              break;
          }
        }
      });
    }
  }
  // ---------------------------------------------------------------
  updateEmailReceiverAction ( eMail : CommunicationActivity ) : Promise<any>
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess ) || this.loginSuccess.signedInUserId === 0 )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    }
    // debugger;
    return new Promise( ( resolve, reject ) =>
    {
      if ( this.loginSuccess.signedInUserId > 0 )
      {
        eMail.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = this.salt( eMail );
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.box )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.nonce ) )
        {
          this.httpService.postObservable( 'api/EmailNote/UpdateEmailReceiverAction', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
          },
            'json2text').subscribe( result =>
          {
            // debugger;

            // debugger;
            let tEmail = this.processEmailResult( result );

            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tEmail ) )
            {
              // debugger;
              resolve( tEmail );
            }
            else {
              this.message = 'emailService. updateEmailReceiverAction(eNote: ' + this.loginSuccess.signedInUserId + ') promise returned a null or undefined result;';
              reject(this.message);
            }

            // return this.processEnoteResult( result );

          })
        }
        else {
          this.message = 'emailService. updateEmailReceiverAction(eNote: ' + this.loginSuccess.signedInUserId + ') promise is rejected;';
          reject(this.message);
        }
      }
    } );
  }
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
}
