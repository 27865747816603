<ul id="keyValueArrComponentId" [@slideInOut] style="width: 100%; margin: 0.15rem 0; list-style: none;" class="expandMenu">
  <li expandMenu>
    <a (click)="isClosed=!isClosed">
      <p class="kvBttn" style="width:100%;">
        <span style="float: left;">
          <span *ngIf="kVArrName.length > 0">
            {{kVArrName}}
          </span>
        </span>
        <span *ngIf="isClosed" class="glyphicon glyphicon-plus-sign hvr-icon" style="float:right;padding-top:0.2rem;font-size:1.2rem;" aria-hidden="true"></span>
        <span *ngIf="!isClosed" class="glyphicon glyphicon-minus-sign hvr-icon" style="float:right;padding-top:0.2rem;font-size:1.2rem;" aria-hidden="true"></span>
      </p>
    </a>
  </li>
  <li>
    <ul *ngIf="!isClosed" [ngClass]="{'shadow submenu':true}" align="left" style="width:100%;margin-left:-1rem;">
      <li>
        <div *ngIf="kVArrLegend.length > 0">
          <div *ngIf="kVArrLegend.length > 0" class="kvBttnSk">{{kVArrLegend}} <span *ngIf="kVArrLegend.length > 0 && isRequired" class="errSub" style="margin-left: -2px;">*</span></div>
          <!--<legend *ngIf="kvArrName.length > 0" class="nvyB">{{kVArrName}} <span *ngIf="kVArrName.length > 0" class="errSub" style="margin-left:-2px;">*</span></legend>-->
        </div>
          <div *ngIf="arrkVArr.length > 0">
            <div *ngFor="let kVArr of arrkVArr; let i=index">
              <div *ngIf="kVArr.value.length > 0">
                <div *ngIf="kVArr.value.length == 1" class="row text-left increase" style="width:100%;">
                  <div class="kvBttnxSk text-right" style="width:45%;text-transform:capitalize">{{kVArr.key}}:</div>
                  <div class="kvBttnxSv text-left  wordWrap" style="width:45%;padding-left:15px;">
                    {{kVArr.value}}
                  </div>
                </div>
                <div *ngIf="kVArr.value.length > 1" class="row text-left increase" style="width:100%;" align="left">
                  <div class="kvBttnxSk text-right" style="width:30%;text-transform:capitalize">{{kVArr.key}}:</div>
                  <div class="kvBttnxSv text-left wordWrap" style="width:65%;padding-left:15px;">
                    <span *ngFor="let v of kVArr.value; let i=index" style="padding:0px;">
                      {{v}}<span *ngIf="i != kVArr.value.length -1" style="color:gold;font-weight:600;padding:2px 2.5px 2px 2.5px">|</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div *ngIf="isRequired && ! isRequiredPassed() && isFormSubmitted">
          <span class="flex-text errxxS" style="text-transform: capitalize;color:orangered;"><em>{{kVArrName}}</em> is required, please select at leaset one value.</span>
        </div>
      </li>
    </ul>
  </li>
</ul>
<!--==========================================================================================================-->
