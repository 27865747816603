'use-strict'
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SpinnerComponent } from './spinner/spinner.component';

const modalRoutes: Routes = [{
path: '',
    children: [
      /*{ path: 'message', component: MessageModalComponent, data: { animation: 'login' } },*/
      { path: 'spinner', component: SpinnerComponent, data: { animation: 'login' } },
    ],
 }];

@NgModule({
  imports: [RouterModule.forChild(modalRoutes)],
  exports: [RouterModule],
})
export class ModalRoutingModule { }
