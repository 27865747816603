
<ul id="profileInfoKeyValueArrComponentId" [@rotateInSlideOutVanish] class="flex-item-main text-center shadowSilverInner"
    style="list-style: none;
    width: 90%;
    margin: 0.5rem 1rem 1rem 1rem;
    padding: 0px;
    border-radius:5px;
    opacity: 1;
    background-color: midnightblue; overflow: visible;">
  <!--ref:https:// www.syncfusion.com/kb/10416/how-to-get-started-easily-with-syncfusion-angular-7-accordion -->
  <li expandMenu style="margin:0;padding:0;opacity:1;" (click)="isProfInfoClosedBool.isClosed=!isProfInfoClosedBool.isClosed;isOpen(isProfInfoClosedBool.isClosed);">
      <!--Note: the classes: (neonBlueText, kvBttnUpload) their order is important!-->
      <p class="goldDrkRedSB kvBttnUpload" style="margin: 0.01rem;padding: 0.15rem 2rem 0.15rem 2rem;">
        <span *ngIf="kvLegend[0].length > 0" style="float: left; opacity: 1; font-size: 13px; font-weight: bold;">
          {{kvLegend[0]}}
        </span>
        <span *ngIf="isProfInfoClosedBool.isClosed" class="glyphicon glyphicon-plus-sign hvr-icon"
              style="float: right;
                      font-size: 13px;
                      padding-top: 0.2rem;
                      opacity: 1;"></span>
        <span *ngIf="!isProfInfoClosedBool.isClosed" class="glyphicon glyphicon-minus-sign hvr-icon"
              style="float: right; font-size: 13px; padding-top: 0.2rem; opacity: 1;"></span>
      </p>
      <hr class="hrGray" style="display:inline-block;width:100%;padding:0;margin:0 0 0 0rem;" />
  </li>
  <li></li>
  <li style="margin:0;padding:0;">
    <ul *ngIf="!isProfInfoClosedBool?.isClosed" [ngClass]="{'submenu':true}" align="left" style="width: 100%; margin-left: -1.5rem; opacity: 1">
      <li style="padding: 0 0 0 0.5rem;margin: 0;">
        <div *ngFor="let kvAny of profileInfoKvAnyArr let i=index">

          <!--NOTE: this section of the UI is NOT TO BE Changed anyway in the future!! It will display key-value paired data adjacent to each other in the center.-->
          <div *ngIf="kvAny.value.length > 0" class="increase" [ngStyle]="{width:'90%', padding: 'i===0? 0 0 0.5rem 0:0', margin:'0.05'}">
            <div *ngIf="kvAny.key.toLowerCase().indexOf('unit') === -1"
                 [ngStyle]="{padding: 'i===0? 0 0 0.5rem 0:0'}"
                 class="kvBttnSk"
                 style="width: 45%; float: left; font-size: 12px; text-align: right; opacity: 1;">
              {{kvAny.key}}:
            </div>
            <div *ngIf="kvAny.key.indexOf('image') === -1 && kvAny?.value" class="kvBttnxSv"
                 style="width: 45%;
                        float: right;
                        text-align: left;
                        text-transform: capitalize;">
              <span *ngIf="kvAny.value.toLowerCase().indexOf('askme') !== -1" [ngStyle]="{padding: 'i===0? 0 0 0.5rem 0:0'}" style="text-transform: capitalize; opacity: 1; font-size: 12px; ">{{askMe}}</span>

              <span *ngIf="kvAny.value.toLowerCase().indexOf('askme') === -1 && kvAny.key.toLowerCase().indexOf('unit') === -1"
                    [ngStyle]="{padding: 'i===0? 0 0 0.5rem 0:0'}"
                    style="text-transform: capitalize; opacity: 1; font-size: 12px; ">
                {{kvAny.value}}
              </span>
            </div>
            <div *ngIf="kvAny.key.indexOf('image') !== -1 && kvAny?.value" class="kvBttnxSv"
                 style="width: 45%;
                        float: right;
                        text-align: left;
                        text-transform: capitalize;">
              <img [src]="kvAny.value | safe: 'pic'" [ngStyle]="{padding: 'i===0? 0 0 0.5rem 0:0'}"
                   style="height: 9rem;
                          width: 9rem;
                          opacity: 1;" class="img-responsive img-rounded" />
            </div>

          </div>
          <hr *ngIf="kvAny.value.length > 0 && kvAny.key.toLowerCase().indexOf('unit') === -1" class="hrGray" style="display:inline-block;width:98%;padding:0;margin:0 0 0 0.5rem;" />
        </div>
      </li>
    </ul>
  </li>
</ul>
