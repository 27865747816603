<div *ngIf="signedInUserId == 0" style="position: relative; display: flex; top: 0.15rem; width: 100%; height: 1.25rem; margin: 0; justify-content: center; padding: 0; z-index: 7; opacity: 1; background-color: transparent; ">

  <!--Left side menu: to display badges of new {chatcount, emailcount, notificationcount}-->
  <a *ngIf="signedInUserId > 0" routerLink="" routerLinkActive="active" aria-expanded="true"
       (click)="setRoute('register', loginSuccess.signedInUserId);"
       class="hvr-underline-from-center whiteBlueInvSB increase"
       [ngClass]="{'bttn-xxs-transparent':!isMobilevar, 'bttn-xs-transparent':isMobilevar}"
       style="display: inline-block;
                  height: 1.25rem;
                  z-index: 9;
                  width: 40%;
                  right: 0.5rem;
                  margin: 0 0.15rem 0 0.25rem;
                  padding: 0.01rem 0.25rem 0.1rem 0.75rem;">
    <span class="glyphicon glyphicon-qrcode hvr-icon"
          style="display: inline; font-size: 0.55rem; align-content: flex-start; align-self: center; vertical-align: central; margin: 0 3rem 0.15rem 0; padding: 0.01rem 0rem 0.15rem 0.05rem; height: auto; width: auto; " aria-hidden="true">
      <span style="align-content: flex-start; vertical-align:central;padding-left:0.05rem;">SignupFree</span>
    </span>
  </a>

  <div style="width: 1.75rem; height: 1.25rem; background-color: transparent; "></div>

  <!--Right side menu: to display badges of new {winkcount, trackcount, likecount, unlockcount etc.}-->
  <a *ngIf="signedInUserId === 0" routerLink="" routerLinkActive="active" aria-expanded="true"
     (click)="setRoute('login', 0);"
     class="hvr-underline-from-center whiteBlueInvSB increase"
     [ngClass]="{'bttn-xxs-transparent':'!isMobilevar', 'bttn-xs-transparent': 'isMobilevar'}"
     style="display: inline-block; height: 1.25rem; width: auto; z-index: 9; left: 4rem; margin: 0; padding: 0.1rem 0.5rem 0.1rem 0.5rem;">
    <span class="glyphicon glyphicon-log-in hvr-icon"
          style="font-size: 0.55rem;
                    align-content:center;
                    vertical-align: central;
                    margin: 0 0 0.15rem 0;
                    padding: 0.01rem 0rem 0.15rem 0.05rem;
                    height: auto;
                    width: auto;" aria-hidden="true">
      <span style="align-content: center;
                      vertical-align: central;
                      padding-left: 0.05rem;">Login</span>
    </span>
  </a>
</div>
