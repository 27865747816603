'use-strict'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveFilterPipeModule } from '../directiveFilterPipeModule/directiveFilterPipe.module';
import { ModalRoutingModule } from './modal-routing.module';
import { SpinnerComponent } from './spinner/spinner.component';
import { SpinnerOverlayComponent } from './spinnerOverlay/spinnerOverlay.component';
import { UpgradePopupComponent } from './upgradePopup/upgradePopup.component';

@NgModule({
  declarations: [
    SpinnerComponent,
    SpinnerOverlayComponent,
    UpgradePopupComponent,
  ],
  imports: [
    CommonModule, // to get ngIf, ngFor, etc.
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DirectiveFilterPipeModule,
    ModalRoutingModule,
  ],
  exports: [
    SpinnerComponent,
    SpinnerOverlayComponent,
    // ModalRoutingModule,
    UpgradePopupComponent,
  ],
   entryComponents: [SpinnerComponent],
  providers: [
  ],
})
export class ModalModule { }
