'use-strict'
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { EmitCheckboxResult } from '../../../models/common/emitCheckboxResult.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Animations, slideInOutAnimation } from '../../animation';

@Component({
  selector: 'app-check-button',
  templateUrl: './checkButton.component.html',
  styleUrls: [ './checkButton.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckButtonComponent implements OnInit, OnDestroy, AfterViewInit
{
  @Input() checkboxArr : string[] = [];
  @Input() checkboxLegend = '';
  @Input() checkboxName = '';
  @Input() isFormSubmitted = false;
  @Input() minRequired = 0;
  @Input() selectedChkBoxArr : string[] = []; // needed to maintain checked-status
  public checkboxForm! : FormGroup;
  public checkboxResult : EmitCheckboxResult = new EmitCheckboxResult();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMobilevar! : boolean;
  public loginSuccess! : LoginSuccess;
  // ---------------------------------------------------------------
  constructor ( public cdr : ChangeDetectorRef, private coreServiceService : CoreServiceService,  @Inject( DOCUMENT ) document: any )
  {
    this.checkboxResult.checkboxName = this.checkboxName;
  }
  ngOnInit ()
  {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit ()
  {
    // debugger;
    this.cdr.detectChanges();
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy ()
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  // ---------------------------------------------------------------
  updateSelectedCheckBoxArray (selected:any, index:any )
  {
    if ( index > -1 )
    {
      // Is currently deselected
      this.selectedChkBoxArr.splice( index, 1 );
    } else
    {
      // Is newly selected
      this.selectedChkBoxArr.push( selected );
    }
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// www.positronx.io/angular-checkbox-tutorial/
   */
  onCheckboxChange ( e:any, index:any ):any
  {
    // debugger;
    if ( e.target.checked )
    {
      this.checkboxResult.checkboxSelectionsIndex = index;
      this.selectedChkBoxArr.push( e.target.value ); // needed to maintain checked-status
    } else
    {
      let i = 0;

      this.selectedChkBoxArr.forEach( ( item : any ) =>
      {
        // debugger;
        if ( item === e.target.value )
        {
          this.selectedChkBoxArr.splice( i, 1 ); // needed to maintain checked-status
        }
        i++;
      } );
    }
    this.checkboxResult.checkboxName = this.checkboxName;
    this.checkboxResult.selectedCheckboxsArr = this.selectedChkBoxArr;
    this.checkboxResult.isRequiredPassed = this.isRequiredPassed();
    // debugger;
    EmitterSubjectService.emitCheckboxResult( this.checkboxResult );
    this.ngAfterViewInit();
    return true;
  }
  // ---------------------------------------------------------------
  commaSeparatedCheckboxSelectionsString () : string
  {
    let str = '';

    this.selectedChkBoxArr.forEach( ( item ) =>
    {
      str += item + ',';
    } );
    return str;
  }
  // ---------------------------------------------------------------
  isChecked ( item: any ) : boolean
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.selectedChkBoxArr ) && this.selectedChkBoxArr.length > 0 )
    {
      for ( const e of this.selectedChkBoxArr )
      {
        if ( item.indexOf( e ) !== -1 )
        {
          // debugger;
          return true;
        }
      }
    }
    return false;
  }
  /*
   * ---------------------------------------------------------------
   * createCheckboxArrayGroup(groupName : string) {
   * this.dataChkBoxArr = this.checkboxForm.get('dataChkBoxArr') as FormArray;
   * for (var i = 0; i < this.checkboxArr.length; i++) {
   *   this.dataChkBoxArr.push(this.fb.control(this.checkboxArr[i]));
   * }
   * return this.dataChkBoxArr;
   * }
   * ---------------------------------------------------------------
   */

  // ---------------------------------------------------------------
  isRequiredPassed ()
  {
    return this.checkboxResult.selectedCheckboxsArr.length >= this.minRequired;
  }
  // ---------------------------------------------------------------
}
