import { Content } from '../common/content.model';

export class Profile {
  public abstract = '';
  public contentId = 0;
  public contents = '';
  public date = '';
  public email = '';
  public heading = '';
  public highestEducation = '';
  public image = '';
  public isProfileComplete = false;
  public isSuccess = false;
  public memberId = 0;
  public message = '';
  public name = 0;
  public playSafe = '';
  public photoId = 0;
  public position = '';
  public profileContent: Content = new Content();
  public profileId = 0;
  public primaryPhotoBnId = 0;
  public relationshipStatus = '';
  public religion = '';
  public signedInUserId = 0;
  public signedInUserKey = '';
  public sitUserId = 0;
  public sitUserKey = '';
  public smokingStatus = '';

  constructor() { }
}
