import { FrequentlyUsedFunctionsServiceStatic } from "../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";

export class Unit {
  public distance = 0;
  public distanceStr = '';
  public unit = 'meter';
  public id = 0;
  public computeCount = 0;
  public date!: Date;
  constructor() {
  }

  copy (sourceUnit: Unit) : Unit {
    if (!this.isNullOrEmpty(sourceUnit)) {
      if (sourceUnit.distance >= 0) {
        this.distance = sourceUnit.distance;
      }
      this.unit = sourceUnit.unit.length > 0 ? sourceUnit.unit : 'meter';
      this.distanceStr = sourceUnit.distanceStr.length > 0 ? sourceUnit.distanceStr : '';
      this.date = sourceUnit.date;
      this.id = sourceUnit.id;
    }
    return this;
  }
  copySourceToDestination (destinationUnit: Unit, sourceUnit : Unit) : Unit {
    if (!this.isNullOrEmpty(sourceUnit)) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationUnit)) {
        destinationUnit = new Unit();
			}
      if (sourceUnit.distance >= 0) {
        destinationUnit.distance = sourceUnit.distance;
      }
      destinationUnit.unit = sourceUnit.unit.length > 0 ? sourceUnit.unit : 'meter';
      destinationUnit.distanceStr = sourceUnit.distanceStr.length > 0 ? sourceUnit.distanceStr : '';
      destinationUnit.date = sourceUnit.date;
      destinationUnit.id = sourceUnit.id;
    }
    return destinationUnit;
  }
  // --------------------------------------------------------------
  public isNullOrEmpty (input : any) : boolean {
    if (input === null || input === '' || input === undefined || (input !== null && (input.toString().toLowerCase() === 'null' || input.toString().toLowerCase() === 'undefined'))) {
      return true;
    }
    return false;
  }
}
