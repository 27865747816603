'use-strict'
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../../../services/staticServices/stringServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

// TODO: create a new service named ProfileNameService!! 

@Component({
  selector: 'app-page-name',
  templateUrl: './pageName.component.html',
  styleUrls: [ './pageName.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  // 1)OnPush->requires an Observable to refresh the view; 2)Default->automatic refresh
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PageNameComponent implements OnInit, OnDestroy, AfterViewInit {  
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  // ----------------------------------------------------------------- 
  public isMouseIn = false;
  public isMobilevar = false; 
  public loginSuccess : LoginSuccess = new LoginSuccess();  
  public pageTitle = '';
  public sitUser : SitUser = new SitUser();
  public userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  // ----------------------------------------------------------------
  constructor (private cdr : ChangeDetectorRef,) {
  }
  // ----------------------------------------------------------------
  ngOnInit () : void {
    // --------------------------------------------------------------
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    
    //  -------------------------------------------------------------
    EmitterSubjectService.userAndPageTitleEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          this.userAndPageTitle = result as UserAndPageTitle;

          this.sitUser.sitUserId = this.userAndPageTitle.sitUserId;
          this.pageTitle = this.userAndPageTitle.pageTitle;
          // debugger;
          if (!this.userAndPageTitle.isPrepared && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.userAndPageTitle.pageTitle)) {
            this.pageTitle = StringServiceStatic.preparePageTitle(this.userAndPageTitle.pageTitle);
            // debugger;
          }
          // debugger;
        }
        this.ngAfterViewInit();
        // EmitterSubjectService.emitRunNgAfterViewInit(true);

    });
    // -----------------------------------------------------------    
  }
  // -------------------------------------------------------------
  ngAfterViewInit () : void {
    this.cdr.detectChanges();
  }
  // ------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    // debugger;
  }
  // ------------------------------------------------------------
}
