
import { Injectable } from '@angular/core';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { ImageCounter } from '../../models/misc/imageCounter.model';
import { HttpService } from '../coreServiceService/httpService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Injectable({
  providedIn: 'any',
})
export class ImageResizerService {
  public height = 400;
  public image: any;
  public width = 400;

  public imageCounter: ImageCounter = new ImageCounter();

  constructor (
    public httpService : HttpService, ) { }

  /*
   * --------------------------------------------------------------
   * Note: This method shold be called once the profileTile.image is rendered at least once.
   * --------------------------------------------------------------
   */
  // adjustImageSize(pTile: ProfileTile, width: number, height: number): any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.image)) {
  //      // check for default-images
  //      const result = this.getDefaultImageByCode(pTile.image);

  //      result.src = result.src.replace('url(\'url(\'data:', 'data:');

  //      // Note: should check 3 conditions on the result: null, empty-string, and image.length > 25
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.src)) {
  //        this.getDefaultUriPromise(result.src, width, height).then(
  //          (data) => {
  //            // get the image-data from url
  //            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //              return data;
  //            }
  //            return '/photos/avatar3.png';
  //          },
  //          (err) => {
  //            alert(err.message.toString());
  //          }
  //        );
  //      } else {
  //        return '/photos/avatar3.png';
  //      }

  //      // now process the non-defaut image data
  //      this.resizeImagePromise(pTile.image, width, height).then(
  //        (data) => {
  //          pTile.image = data;
  //          return pTile.image; // as 'image/png/jpg/svg';;
  //        },
  //        (err) => {
  //          alert(err.message.toString());
  //        }
  //      );
  //    } else {
  //      return '/photos/avatar3.png';
  //    }
  //  } else {
  //    return '/photos/avatar3.png';
  //  }
  // }
  /*
   * ---------------------------------------------------------------
   * Note: width and height should be sent in REM
   */
  createImageFromBase64Str(inb64Str: string, width: number, height: number): any {
    const image = new Image();

    image.src = inb64Str;
    image.src = image.src.replace('url(\'url(\'data:', 'data:');
    const canvas = document.createElement('canvas');

    canvas.width = width > 0 ? width * 16 : 48; // or 'width' if you want a special/scaled size
    canvas.height = height > 0 ? height * 16 : 48; // or 'height' if you want a special/scaled size

    // canvas.getContext('2d').drawImage(image as CanvasImageSource, 0, 0);  // TPDO: restore

    /*
     * Get raw image data
     * let imgData = canvas.toDataURL('image/png/jpg/svg').replace(/^data:image\/(png|svg):base64,/, '');
     * resolve(imgData);
     */

    // ... or get as Data URI
    return canvas.toDataURL('image/png');
  }
  // ---------------------------------------------------------------
  createImageFromByteArray(byteArr: Uint8Array): any {
    const image = new Image();

    image.src = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(byteArr);
    image.src = image.src.replace('url(\'url(\'data:', 'data:');
    const canvas = document.createElement('canvas');

    canvas.width = 400; // or 'width' if you want a special/scaled size
    canvas.height = 400; // or 'height' if you want a special/scaled size

    // canvas.getContext('2d').drawImage(image as CanvasImageSource, 0, 0);  // TPDO: restore

    /*
     * Get raw image data
     * let imgData = canvas.toDataURL('image/png/jpg/svg').replace(/^data:image\/(png|svg)base64,/, '');
     * resolve(imgData);
     */

    // ... or get as Data URI
    return canvas.toDataURL('image/png');
  }
  // ---------------------------------------------------------------
  // TODO: Test
  // ---------------------------------------------------------------
  static fileToString (file : File) : string {
    const myReader : FileReader = new FileReader();
    let tempStringFile : string = '';
    myReader.onerror = () => {
      myReader.abort();
      return new DOMException('Problem parsing input file.');
    };

    myReader.onloadend = (e) => {
      const fileStr = e;
      // debugger;
      // return fileStr;
      tempStringFile = JSON.stringify(fileStr);
      // debugger;
    };
    // debugger;
    myReader.readAsDataURL(file);
    return tempStringFile;
  }
  // ---------------------------------------------------------------
  static fileToStringArr (file : File) : string[] {
    const myReader : FileReader = new FileReader();
    let tempStringFiles : string[] = [];
    myReader.onerror = () => {
      myReader.abort();
      return new DOMException('Problem parsing input file.');
    };

    myReader.onloadend = (e) => {
      const fileStr = e;
      // debugger;

      tempStringFiles.push(JSON.stringify(fileStr));
      // debugger;
    };
    // debugger;
    myReader.readAsDataURL(file);
    return tempStringFiles;
  }
  // ---------------------------------------------------------------
  // Not in use:
  // ---------------------------------------------------------------
  static formDataToString (formData : FormData) : string[] {
    let tempStringFiles : string[] = [];
    formData.forEach(e => {
      tempStringFiles.push(e.valueOf().toString() /*JSON.stringify(e.valueOf)*/);
    });
    // debugger;
    return tempStringFiles;
  }
  // ---------------------------------------------------------------
  // Not in use:
  // ---------------------------------------------------------------
  static formDataToFileMap (formData : FormData) : Map<string, File> {
    let fileMap : Map<any, any> = new Map();
    formData.forEach((v, k) => {
      fileMap.set(k, v)
    });
    return fileMap;
  }
  // --------------------------------------------------------------
  // ---------------------------------------------------------------
  getBase64Image(img: any): string {
    const canvas = document.createElement('canvas');

    canvas.width = img.width > 0 ? img.width : 400;
    canvas.height = img.height > 0 ? img.height : 400;
    const ctx : any = canvas.getContext('2d');

    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');

    return dataURL.replace(/^data:image\/(png|jpg|svg);base64,/, '');
  }
  /*
   * ---------------------------------------------------------------
   * Ref;https:// turbofuture.com/computers/HTML5-Tutorial-How-To-Use-Canvas-toDataURL
   * Ref;https:// www.html5canvastutorials.com/tutorials/html5-canvas-images/
   * ---------------------------------------------------------------
   */
  getCanvasImage (pTile : any): string {
    let imgData = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.pElemId)) {
      const canvas = document.getElementById('canvas-' + (pTile?.pElemId?.length > 0 ? pTile?.pElemId : pTile?.index)) as HTMLCanvasElement;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(canvas)) {
        const ctx : any = canvas.getContext('2d');

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ctx)) {
          const imageObj = new Image();

          pTile.image = pTile.image.replace('url(\'url(\'data:', 'data:');
          imageObj.src = pTile.image;
          imageObj.onload = () => {
            // debugger;
            ctx.drawImage(imageObj, 400, 400);
          };
          imgData = canvas.toDataURL();
          // debugger;
        }
      }
    }
    return imgData;
  }
  /*
   * ---------------------------------------------------------------
   * Note: If the user does not have any profile picture, the server sends the profileTile.image field with
   *     one of the following codes for the View:
   *     1) image='Male' if the user is a male
   *     2) image='Female' if the user is a male
   *     3) image='DefaultImageEmoji' if the gender of the user is not known or transgender
   *     note: caller should check for the following conditions:
   *     1. if the result is null
   *     2. if the result is empty-string
   *     3. if the result is not the first two possibilities,
   *         i.e. not null and not empty-string. and if it has data, it's image must be more than 25 characters long.
   * ---------------------------------------------------------------
   */
  getDefaultImageByCode(image: string): any {
    let result: any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      if (image.length > 3 && image.length < 18) {
        const code = image.toLowerCase();
        let srce = image;
        let codeNum = -1;

        this.imageCounter = new ImageCounter();
        result = {
          src: srce,
          // 'imageCounter': this.imageCounter
        };
        if (code.indexOf('male') > -1) {
          codeNum = 1;
        } else if (code.indexOf('female') > -1) {
          codeNum = 2;
        } else if (code.indexOf('transgender') > -1) {
          codeNum = 3;
        } else if (code.indexOf('defaultimageemoji') > -1) {
          codeNum = 4;
        }

        switch (codeNum) {
          case 1:
            srce = '/assets/photos/DefaultImageMale.jpg'; // 28chars
            // src =  'url('' + ['/assets/photos/DefaultImageMale.jpg'] + '')';
            break;
          case 2:
            srce = '/assets/photos/DefaultImageFemale.jpg'; // 30chars
            // src =  'url('' + ['/assets/photos/DefaultImageFemale.jpg'] + '')';
            break;
          case 3:
          case 4:
            srce = '/assets/photos/DefaultImageEmoji.jpg'; // 29chars
            // src =  'url('' + ['/assets/photos/DefaultImageEmoji.jpg'] + '')';
            break;
          default:
            srce = ''; // code was not found: empty-string to indicate no-default image is applicable on the basis of the code.
            break;
        }
        result = {
          src: srce,
          // 'imageCounter': this.imageCounter
        };
      } // code could not be found & code.length >= 18
      else {
        result = {
          src: image,
          // 'imageCounter': this.imageCounter
        };
      }
    } // image is null/empty-string
    else {
      result = {
        src: '',
        // 'imageCounter': this.imageCounter
      };
    }
    return result;
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// davidwalsh.name/convert-image-data-uri-javascript
   * ---------------------------------------------------------------
   */
  getDefaultUriPromise (url : any, width : any, height : any): any {
    return new Promise((resolve) => {
      const image = new Image();

      url = url.replace('url(\'url(\'data:', 'data:');
      image.src = url;

      image.onload = () => {
        // debugger;
        const canvas = document.createElement('canvas');

        canvas.width = width > 0 ? width : 400; // or 'width' if you want a special/scaled size
        canvas.height = height > 0 ? width : 400; // or 'height' if you want a special/scaled size

        // canvas.getContext('2d').drawImage(image as CanvasImageSource, 0, 0);  // TPDO: restore

        /*
         * Get raw image data
         * let imgData = canvas.toDataURL('image/png/jpg/svg').replace(/^data:image\/(png|svg):base64,/, '');
         * resolve(imgData);
         */

        // ... or get as Data URI
        resolve(canvas.toDataURL('image/png/jpg'));
      };
    });
  }

  // ---------------------------------------------------------------
  getImage() {
    return this.image;
  }
  // ---------------------------------------------------------------
  getImageArrayFromArrayOfBytes(imageArrOfBytes: any[]): any {
    if (imageArrOfBytes && imageArrOfBytes.length > 0) {
      const images : any = [];
      // debugger;

      imageArrOfBytes.map((e) => {
        // images.push(this.createImageFromByteArray(e));  // TPDO: restore
      });
      return images;
    }
    return null;
  }
  /*
   * ---------------------------------------------------------------------------------
   * Note: This routine will look for comma separated 'image-file-name' in urls[], and if one exists, will fetch the image
   */
  // getImageDataFromUrlArr(urls: ProfileImage[]): string[] {
  //  const images = [];

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(urls) && urls.length > 0) {
  //    let i = 0;

  //    urls.map((e) => {
  //      if (e.image.toLowerCase().indexOf('.png') !== -1 || e.image.toLowerCase().indexOf('.jpg') !== -1) {
  //        const url = '/api/home/GetImageFromUrl';
  //        // debugger;

  //        this.httpService.post(url, { imageFileName: e.image}, 'image').subscribe((data) => {
  //          // debugger;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //            images.push(data);
  //          }
  //          // return images;
  //        });
  //        // debugger;
  //      } else {
  //        images.push(urls[i]);
  //      }
  //      i++;
  //    });
  //  }
  //  return images;
  // }
  /*
   * ---------------------------------------------------------------
   * Note: This routine will look for comma separated 'image-file-name' in urls[], and if one exists, will fetch the image
   */
  // getImageDataFromUrl(filePath: string): any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filePath)) {
  //    if (filePath.toLowerCase().indexOf('.png') !== -1 || filePath.toLowerCase().indexOf('.jpg') !== -1) {
  //      const url = '/api/home/GetImageFromUrl';
  //      let bnIn ! : any;// Communication =  new Communication();  // TPDO: restore
  //      // debugger;

  //      bnIn.fileName = filePath;
  //      const salted = SlakezSaltServiceStatic.saltModel(bnIn);
  //      // debugger;

  //      // debugger;
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(salted) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(salted.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(salted.nonce)) {
  //        this.httpService
  //          .post(
  //            url,
  //            {
  //              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(salted.box),
  //              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(salted.nonce),
  //            },
  //            'json'
  //          )
  //          .subscribe((result) => this.processImageDataFromUrlResult(result));
  //        // debugger;
  //      }
  //    }
  //  }
  //  return false;
  // }
  // ---------------------------------------------------------------
  processImageDataFromUrlResult (result : any): any {
    let imageFile: any;
    // debugger;

    if (result) {
      const bn = result as BoxNonceEntity;
      // debugger;

      if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
        imageFile = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn));
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageFile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageFile.FileContents)) {
          EmitterSubjectService.emitImage(imageFile.FileContents);
        }
        return false;
        /*
         * Keep the commented code. it works!
         * const canvasImage = await this.resizeImagePromise(filePath, 300, 300);
         * debugger;
         * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(canvasImage)) image = canvasImage;
         */
      }
    }
  }
  // ---------------------------------------------------------------
  async getImageDataFromUrlPromise (filePath : any): Promise<string> {
    let image = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filePath)) {
      const canvasImage = await this.resizeImagePromise(filePath, 300, 300);

      canvasImage.then((data : any) => (image = data));
    }
    return image;
  }
  /*
   * ---------------------------------------------------------------
   * toDataUrl(url, callback) {
   * let xhr = new XMLHttpRequest();
   * xhr.onload = function () {
   *   let reader = new FileReader();
   *   reader.onloadend = function () {
   *     callback(reader.result);
   *   }
   *   reader.readAsDataURL(xhr.response);
   * };
   * xhr.open('GET', url);
   * xhr.responseType = 'blob';
   * xhr.send();
   * }
   * Usage example
   * toDataUrl(imagepath, function(myBase64) {
   * console.log(myBase64); //myBase64 is the base64 string
   * });
   * ---------------------------------------------------------------
   */
  getResizedImage(img: any): string {
    this.image = img;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.image)) {
      this.image = this.resizeImage(this.image, 100, 100);
    }
    return this.image;
  }

  /*
   * ---------------------------------------------------------------------------------
   * Begin of ImageDataCleanupSystem:
   * API: normalizeImageData(image: string): string {}
   * ---------------------------------------------------------------------------------
   */
  public normalizeImageData(image: string): string {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      const pattern = /\'*data:image(\/(jpeg|jpg|png|svg))+;base64,/;
      let imageData = image;

      if (imageData.toLowerCase().indexOf('url(\'') !== -1) {
        imageData = this.stripUrlFromImageData(imageData);
        // debugger;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData) && pattern.test(imageData)) {
        imageData = this.setUrlForImageData(imageData);
        return imageData;
      }
    }
    return image;
  }
  // ---------------------------------------------------------------
  private prependToImageData(image: string): string {
    // debugger;
    let outImage = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      const pattern1 = /\'*data:image(\/(jpeg|jpg|png|svg))+;base64,/;
      const tImage = image.replace(pattern1, '');
      // tImage = tImage.toString().replace(/\'+/, '\''); //collapse multiple quotes ('+) to a single (')

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tImage)) {
        outImage = 'data:image/png;base64,' + tImage.toString();
        // debugger;
      }
      // Note: the above code accomplishes the same objective as the below code:
      // -----------------------------------------------------------------------------
      // if (image.toLowerCase().indexOf('jpeg') !== -1) image.replace('jpeg', 'jpg');
      // if ((image.toLowerCase().indexOf('data:image/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/jpg/png;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/jpg/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg/png;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/jpg/png/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/jpg/svg/png;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png/jpg/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png/svg/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg/png/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg/jpg/png;base64,') !== -1)
      // ){
      // //debugger;
      // return image;
      // }
    }
    return outImage;
  }
  // ---------------------------------------------------------------
  setUrlForImageData(image: string): string {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      const prependedImageData = this.prependToImageData(image); // check if its an image

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(prependedImageData)) {
        // debugger;
        if (prependedImageData.indexOf('url(\'') === -1) {
          /*
           * check if url linked
           * debugger;
           */
          return 'url(\'' + prependedImageData + '\')';
        }
        return prependedImageData;
      }
    }
    // debugger;
    return '';
  }
  // ---------------------------------------------------------------
  stripUrlFromImageData(image: string) {
    let tImage: any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      const pattern1 = /url\('/;

      tImage = image.replace(pattern1, '');
      const pattern2 = /'\)/;

      tImage = tImage.replace(pattern2, '');
      return tImage;
    }
  }
  /*
   * ---------------------------------------------------------------------------------
   * End of ImageDataCleanupSystem:
   * ---------------------------------------------------------------------------------
   * ---------------------------------------------------------------
   * does not work!!
   * ref:https:// gist.github.com/ORESoftware/ba5d03f3e1826dc15d5ad2bcec37f7bf
   */
  resizeImage (base64Str : any, maxW: number, maxH: number): string {
    const img = new Image();

    base64Str = base64Str.replace('url(\'url(\'data:', 'data:');
    img.src = base64Str;
    const canvas = document.createElement('canvas');
    const MAX_WIDTH = maxW > 0 ? maxW : 400;
    const MAX_HEIGHT = maxH > 0 ? maxH : 400;
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    const ctx : any = canvas.getContext('2d');

    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL();
  }

  /*
   * ---------------------------------------------------------------
   * THIS DOES NOT WORK!
   * ref:https:// gist.github.com/ORESoftware/ba5d03f3e1826dc15d5ad2bcec37f7bf
   */
  resizeImageInREM (base64Str : any, maxW: number, maxH: number): string {
    const img = new Image();

    base64Str = base64Str.replace('url(\'url(\'data:', 'data:');
    img.src = base64Str;
    const canvas = document.createElement('canvas');
    const MAX_WIDTH = maxW > 0 ? maxW * 16 : 48; // default==baseWidth==3rem==48px
    const MAX_HEIGHT = maxH > 0 ? maxH * 16 : 48; // default==baseHeight==3rem==48px
    let width = img.width;
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else {
      if (height > MAX_HEIGHT) {
        width *= MAX_HEIGHT / height;
        height = MAX_HEIGHT;
      }
    }
    canvas.width = width;
    canvas.height = height;
    const ctx : any = canvas.getContext('2d');

    ctx.drawImage(img, 0, 0, width, height);
    return canvas.toDataURL();
  }
  /*
   * ---------------------------------------------------------------
   * Tested, works! 2020/July/23
   * ref:https:// gist.github.com/ORESoftware/ba5d03f3e1826dc15d5ad2bcec37f7bf
   * Usage: const result = await resizeImagePromise(YOUR_BASE64, MAX_WIDTH, MAX_HEIGHT);
   * ---------------------------------------------------------------
   */
  resizeImagePromise (filePath : any, maxWidth = 400, maxHeight = 400): any {
    return new Promise((resolve) => {
      const img = new Image();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filePath)) {
        let width: any;
        let height: any;

        filePath = filePath.replace('url(\'url(\'data:', 'data:');
        if (filePath.indexOf('.jpg') !== -1 || filePath.indexOf('.png') !== -1 || filePath.indexOf('.svg') !== -1) {
          img.src = 'url(\'' + filePath + '\')';
          img.onload = () => {
            const canvas = document.createElement('canvas');
            const MAX_WIDTH = maxWidth;
            const MAX_HEIGHT = maxHeight;

            width = img.width;
            height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            const ctx : any = canvas.getContext('2d');

            ctx.drawImage(img, 0, 0, width, height);
            resolve(canvas.toDataURL());
          };
        } else {
          return filePath;
        }
      }
    });
  }
  // ---------------------------------------------------------------
  urlToImageData (image : any): string {
    return image;
  }
  // ---------------------------------------------------------------
}
