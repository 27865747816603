
'use-strict'
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';

// ref:https:// stackblitz.com/edit/angular-component-accordion-example

@Component({
  selector: 'app-accordion-group',
  templateUrl: './accordionGroup.component.html',
  styleUrls: ['./accordionGroup.component.scss'],
  animations: [
    trigger('fader', [
      state(
        'show',
        style({
          height: 400,
          // opacity : 1,
          transform: 'translateX(0%)',
        })
      ),
      state(
        'hide',
        style({
          height: 0,
          // opacity : 0,
          transform: 'translateX(-500%)',
        })
      ),

      transition('show => hide', animate('700ms ease-in')),
      transition('hide => show', animate('700ms ease-out')),
    ]),
  ],
})
export class AccordionGroupComponent implements OnInit, OnDestroy {
  private accrdTitle = 'Bangla and Sanskrit Vowels and Consonants';
  private emitterDestroyed$: Subject<boolean> = new Subject();

  constructor() {
    EmitterSubjectService.toggleFaderAnimEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      this.opened = !this.opened;
    });
  }
  /**
   * If the panel is opened or closed
   */
  @Input() opened = false;

  /**
   * Text to display in the group title bar
   */
  @Input() title!: string;

  /**
   * Emitted when user clicks on group titlebar
   */
  @Output() toggle : Subject<any> = new Subject();

  ngOnInit() { }
  /*
   * remove self from modal service when component is destroyed
   * ---------------------------------------------------------------
   */
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  stateName(): any {
    return this.opened ? 'show' : 'hide';
  }
  toggleFaderAnim(): void {
    EmitterSubjectService.emitToggleFaderAnim(true);
  }
}
