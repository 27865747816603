'use-strict'
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { LandingPageComponent } from 'src/pages/landingPage/landingPage.component';
import { SharedModuleComponent } from './component/sharedModule.component';

const sharedRoutes: Routes = [
  { path: '', component: SharedModuleComponent},
];

@NgModule({
  imports: [RouterModule.forChild(sharedRoutes)],
  exports: [RouterModule],
})
export class SharedRoutingModule { }
