'use-strict'
import {
    AfterViewInit, Component, Inject, Input, OnChanges,
    OnDestroy, OnInit,
    Renderer2, SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { Pager } from '../../../models/pagination/pager.model';
import { ProfilePics } from '../../../models/profile/profilePics.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { DbServiceService } from '../../../services/dbServiceService/dbServiceService.service';
import { DictionaryService } from '../../../services/dictionaryServiceService/dictionaryService.service';
import { DictionaryServiceService } from '../../../services/dictionaryServiceService/dictionaryServiceService.service';
import { PagerService } from '../../../services/pagerServiceService/pagerService.service';
import { PagerServiceService } from '../../../services/pagerServiceService/pagerServiceService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Component({
  selector : 'app-pager-button',
  templateUrl : './pagerButton.component.html',
  styleUrls : ['./pagerButton.component.scss'],
})
export class PagerButtonComponent implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  public dictionaryService! : DictionaryService;
  private pagerService!: PagerService;
  public rendererService!: RendererService;
  public renderer!: Renderer2;
  // ---------------------------------------------------------------
  public changeDetectStr = 'image';
  private emitterDestroyed$: Subject<boolean> = new Subject();
  @Input() isAll = true;
  @Input() isFirst = false;
  @Input() isLast = false;
  public isMobilevar = false;
  @Input() isPageButton = false;
  @Input() image = '';
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public profilePics : ProfilePics = new ProfilePics();
  @Input() page = 1;
  public pager: Pager = new Pager();
  // ---------------------------------------------------------------
  constructor(
    // private cdr : ChangeDetectorRef,
    private rendererServiceService: RendererServiceService,
    private coreServiceService : CoreServiceService,
    private dbServiceService : DbServiceService,
    private dictionaryServiceService : DictionaryServiceService,
    private pagerServiceService: PagerServiceService,
    @Inject(Document) document : any) {
    if (this.rendererServiceService) { this.rendererService = this.rendererServiceService.getRendererService() as RendererService; }
    if (this.pagerServiceService) { (this.pagerService as PagerService) = this.pagerServiceService.getPagerService() as PagerService; }
    if (this.rendererService) { this.renderer = this.rendererService.getRenderer() as Renderer2; }
    if (this.dictionaryServiceService) {

      this.dictionaryService = this.dictionaryServiceService.getDictionaryService();
    }
  }
  // ---------------------------------------------------------------
  ngOnInit(): void {
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    if (this.image && this.page > 0) { this.setPagerBttn(this.image, this.page); }

    EmitterSubjectService.currentPageEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
      debugger;
      if (result > 0) {
        this.page = result;
        this.pager = (this.pagerService as PagerService).setPager(this.page);
      }
    });
    // Note : here to get the pager, we are using an empty-pic-array since this component does not have the list of pics
    if ( this.page > 0 ) { this.pager = ( this.pagerService as PagerService ).setPager( this.page ); }

    // TODO: after testing how pager looks, delete this before deployment.
    // -------------------------------------------------------------------
    EmitterSubjectService.profileImagesEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
        // debugger;
        if ( result && result.length > 0 )
        {
          this.pager.pagedKvPhotoArr = this.pager.kvPhotoArr = result;
          this.pager = ( this.pagerService as PagerService ).setPager( 1 );
          this.ngAfterViewInit();
        }
      } );

    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.profilePics = this.dictionaryService.profilePicsDictionary.get( this.loginSuccess.signedInUserId ) as ProfilePics;
    this.setPagerBttn( this.loginSuccess.primaryImage, 1 );
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics.unFilteredKvPhotoArr ) )
    {
      this.pager.pagedKvPhotoArr = this.pager.kvPhotoArr = this.profilePics.unFilteredKvPhotoArr;
    }
  }
  // ---------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    // only run when property 'data' changed
    if (changes[this.changeDetectStr]) {
      // debugger;
      this.isMobilevar = EmitterSubjectService.getIsMobile();
      if (this.image && this.page > 0) { this.setPagerBttn(this.image, this.page); }
    }
    // this.cdr.detectChanges();
  }
 // ---------------------------------------------------------------------------------
  ngAfterViewInit(): void {
    // debugger;
    if ( this.image && this.page > 0 ) { this.setPagerBttn(this.image, this.page); }
    // this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
 // ---------------------------------------------------------------------------------
  setPage (page : any) {
    // debugger;
    if (page > 0) {
      this.pager = (this.pagerService as PagerService).setPager(page);
      // debugger;
      EmitterSubjectService.emitSetPage(page);
    }
  }
 // ---------------------------------------------------------------------------------
  setIsAll(isAll: boolean) {
    this.isAll = isAll;
  }
 // ---------------------------------------------------------------------------------
  setIsPageButton(isPageButton: boolean) {
    this.isPageButton = isPageButton;
  }
 // ---------------------------------------------------------------------------------
  setIsFirst(isFirst: boolean) {
    this.isFirst = isFirst;
  }
 // ---------------------------------------------------------------------------------
  setIsLast(isLast: boolean) {
    this.isLast = isLast;
  }
  // --------------------------------------------------------------
  setButtons (images : any) {
    // debugger;
    if (images && images.length > 0) {
      for (let i = 0; i < images.length; i++) {
        this.setPagerBttn(images[i], i + 1);
      }
    }
  }
  // --------------------------------------------------------------
  setPagerBttn(image: string, page: number) {
    // debugger;

    if (image && image.length > 0 && page > 0) {
      const elemId = 'pageBttnId' + page;
      const tImage = 'url(\'' + image + '\')';
      const bttnElem = document.getElementById(elemId);
      // debugger;
      if (bttnElem) {
        this.renderer?.setStyle(bttnElem, 'background-image', tImage);
        bttnElem.remove();
      }
    }
  }
 // ---------------------------------------------------------------------------------
}
