import { KvMemberActivityGlyph } from '../keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../keyValue/kvPhoto.model';
import { BasicPagerModel } from './basicPagerModel.model';
import { PagerMemberActivity } from './pagerMemberActivity.model';

// --------------------------------------------------------
// Note:  There are two basic types of KV based pagers:
//        1) KvPhoto  2) KvGlyph
//
//        PagerPhotoMgmt relates to KvPhoto category.
// --------------------------------------------------------


export class PagerPhotoMgmt extends BasicPagerModel{

  public pagedItems : KvPhoto[] = [];

  // Note: the following two variables are for hodling the glyphArr for photoMgmt and/or viewMember:
  // -----------------------------------------------------------------------------------------------
  // Note: variable/property naming convention: prefix as {container, model,class} ) + name (can be emptyString) + postfix (as type {primitives, custom-type} or action) == variable-name
  // -----------------------------------------------------------------------------------------------
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];

  // Note: and the respective pagers:
  public pagerVMemberActivityForPhotoMgmt : PagerMemberActivity = new PagerMemberActivity();
  public pagerVMemberActivityForViewMember : PagerMemberActivity = new PagerMemberActivity();
  
  constructor () { super(); }
}
