import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

export class PayPalCreditCardAddress {
  public id = 0;
  public addressStr = '';
  public aptUnit = '';
  public addressKey = '';
  public billingAddress = '';
  public billingAddressId = 0;
  public city = '';
  public country = '';
  public date = '';
  public email = '';
  public isBilling = true;
  public isSameAsBilling = false;
  public latitude = 0;
  public longitude = 0;
  public message = '';
  public nameOnCard = '';
  public shippingAddressId = 0;
  public sitUserId = 0;
  public sitUserKey = '';
  public state = '';
  public zipCode = '';
  // ---------------------------------------------------
  constructor () { }
  // ---------------------------------------------------
  public copy ( from : PayPalCreditCardAddress ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.id = from.id;
      this.addressStr = from.addressStr;
      this.aptUnit = from.aptUnit;
      this.addressKey = from.addressKey;
      this.billingAddress = from.billingAddress;
      this.billingAddressId = from.billingAddressId;
      this.city = from.city;
      this.country = from.country;
      this.date = from.date;
      this.email = from.email;
      this.isBilling = from.isBilling;
      this.isSameAsBilling = from.isSameAsBilling;
      this.latitude = from.latitude;
      this.longitude = from.longitude;
      this.message = from.message;
      this.nameOnCard = from.nameOnCard;
      this.shippingAddressId = from.shippingAddressId;
      this.sitUserId = from.sitUserId;
      this.sitUserKey = from.sitUserKey;
      this.state = from.state;
      this.zipCode = from.zipCode;
    }
  }
  // ---------------------------------------------------
  public toString (): string
  {
    let outputStr = 
      '{\n id:' +
      this.id +
      ',\n' +
      'addressStr:' +
      this.addressStr +
      ',\n' +
      'aptUnit:' +
      this.aptUnit +
      ',\n' +
      'addressKey:' +
      this.addressKey +
      ',\n' +
      'billingAddress:' +
      this.billingAddress +
      ',\n' +
      'billingAddressId:' +
      this.billingAddressId +
      ',\n' +
      'city:' +
      this.city +
      ',\n' +
      'country:' +
      this.country +
      ',\n' +
      'date:' +
      this.date +
      ',\n' +
      'email:' +
      this.email +
      ',\n' +
      'isBilling:' +
      this.isBilling +
      ',\n' +
      'isSameAsBilling:' +
      this.isSameAsBilling +
      ',\n' +
      'latitude:' +
      this.latitude +
      ',\n' +
      'longitude:' +
      this.longitude +
      ',\n' +
      'message:' +
      this.message +
      ',\n' +
      'nameOnCard:' +
      this.nameOnCard +
      ',\n' +
      'shippingAddressId:' +
      this.shippingAddressId +
      ',\n' +
      'sitUserId:' +
      this.sitUserId +
      ',\n' +
      'sitUserKey:' +
      this.sitUserKey +
      ',\n' +
      'state:' +
      this.state +
      ',\n' +
      'zipCode:' +
      this.zipCode +
      '\n}';
    return outputStr;
  }
  // ---------------------------------------------------
}
