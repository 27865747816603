
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveFilterPipeModule } from '../directiveFilterPipeModule/directiveFilterPipe.module';
import { AppFeatureRoutingModule } from './appFeature-routing.module';
import { AppFeatureComponent } from './appFeature/appFeature.component';
import { AppFeatureValueDisplayComponent } from './appFeatureValueDisplay/appFeatureValueDisplay.component';


@NgModule({
  declarations: [
    AppFeatureComponent,
    AppFeatureValueDisplayComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DirectiveFilterPipeModule,
    AppFeatureRoutingModule,
  ],
  exports: [
    AppFeatureComponent,
    AppFeatureValueDisplayComponent,
    AppFeatureRoutingModule,
  ],

  providers: [
  ],
})

export class AppFeatureModule { }
