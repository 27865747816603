<ul id="profilePicsKeyValueArrComponentId" [@slideInOut] style="list-style:none;width:94.5%;margin:0.15rem 1rem 0.15rem 1rem;padding:0px;">
  <li expandMenu style="margin:0;padding:0;">
    <a (click)="isClosedBool.isClosed=!isClosedBool.isClosed">
      <p class="kvBttnxS" style="margin:0.01rem;padding:0.15rem 1rem 0.2rem 1rem;">
        <span *ngIf="kvAnyName.length > 0" style="float:left;">
          {{kvAnyName}}
        </span>
        <span *ngIf="isClosedBool.isClosed" class="glyphicon glyphicon-plus-sign hvr-icon" style="float:right;font-size:0.9rem;padding:0.2rem 1rem 0.1rem 1rem;"></span>
        <span *ngIf="!isClosedBool?.isClosed" class="glyphicon glyphicon-minus-sign hvr-icon" style="float:right;font-size:0.9rem;padding:0.2rem 1rem 0.1rem 1rem;"></span>
      </p>
    </a>
  </li>

  <li style="margin:0;padding:0;">
    <ul *ngIf="!isClosedBool.isClosed" [ngClass]="{'submenu':true}" align="left" style="list-style:none;width:100%;margin:0;padding:0;">
      <li style="margin: 0;padding: 0;">
        <div *ngIf="profileImages.length > 0" style="width:100%;font-size:0.6rem;margin:0;padding:0;float:left;">
          <div *ngFor="let pImage of profileImages, let i=index;" class="flex-container">

            <app-profile-image-kv [profileImage]="pImage" [id]="i"></app-profile-image-kv>

          </div>
        </div>
        <div>
          <span class="flex-text errxxS" style="text-transform: capitalize;color:orangered;"><em>{{kvAnyLegend}}</em> is required, please select at leaset one value.</span>
        </div>
      </li>
    </ul>
  </li>
</ul>
<!--==========================================================================================================-->
<!--
<!--<div style="display:inline-block;width:94.5%;padding:0.15rem 1rem 0.15rem 1rem; margin:0.15rem 1rem 0.15rem 1rem;border-bottom:3px solid deepskyblue;"></div>

  <div class="kvxxSk wordWrap flex-button">
    {{pImage?.photoId}})::{{i}}
  </div>

  <div class="tilePic-desktop smaller img-responsive flex-button" style="background-image: url(\'pImage.image\')"></div>
  <img class="tilePic-desktop smaller img-responsive flex-button" src="pImage.image" />

  <div *ngIf="pImage?.date > 0" class="flex-button">Date: {{pImage?.date}}</div>
  <div *ngIf="pImage?.date > 0" class="flex-button">Is Primary Image?: {{pImage?.isPrimary}}</div>
  <div *ngIf="pImage?.modelName" class="flex-button">Private/Public: {{pImage?.privacy}}</div>
  <div *ngIf="pImage?.photoId > 0" class="flex-button">PhotoId: {{pImage?.photoId }}</div>
  <div *ngIf="pImage?.profileId > 0" class="flex-button">ProfileId: {{pImage?.profileId }}</div>
  <div *ngIf="pImage?.sitUserId > 0" class="flex-button">SitUserId: {{pImage?.sitUserId }}</div>
  <div *ngIf="pImage?.sitUserKey.length > 0" class="flex-button">SitUserKey: {{pImage?.sitUserKey }}</div>-->
-->
<!--<div *ngFor="let kvAny of arrKvAny; let i=index" style="width:100%;font-size:0.6rem;margin:0;padding:0;">
          <div *ngIf="kvAny?.value" style="width:95%;float:left;">-->
<!--First-level of key-value-->
<!--<div class="increase">
    <div class="kvxxSk wordWrap" style="width:40%;float:left;text-align:right;"> {{kvAny.id}}) {{kvAny.key}}:: </div>
    <div *ngIf="!isImageValue(kvAny.value) && !isArray(kvAny?.value)" class="kvxxSv" style="width: 55%; float: right; text-align: left; text-transform: capitalize;">
      {{kvAny.value}}
    </div>
    <div *ngIf="isArray(kvAny?.value)" class="kvxxSv" style="width:55%;float:right;text-align:left;text-transform:capitalize;">
      "Array Data Below:"
    </div>
    <div *ngIf="isImageValue(kvAny?.value)" class="kvxxSv" style="width:55%;float:right;text-align:left;text-transform:capitalize;">
      <img [src]="kvAny.value | safe: 'pic'" style="height:9rem; width:9rem;" class="img-responsive img-rounded" />
    </div>
  </div>
</div>-->
<!--<div *ngIf="kvAny?.value || isArray(kvAny?.value)" style="width:100%;padding:0 0 0 1rem;;margin:0.2rem;border-bottom:1.5px solid silver;"></div>-->
<!--if array-->
<!--Recursive-level of key-value[]-->
<!--<div *ngIf="isArray(kvAny?.value)">
  <key-value-any-template [arrKvAny]="kvAny?.value"></key-value-any-template>
</div>-->
<!--<div *ngIf="kvAny?.value ||  isArray(kvAny?.value)" style="display:inline-block;width:100%;padding:0 0 0 1rem;margin:0.2rem;border-bottom:1.5px solid silver;"></div>

<div *ngIf="kvAny?.value?.length > 0 && isArray(kvAny?.value)">
  <div *ngFor="let kvany of kvAny?.value; let i=index;">
    <profile-image-kv [kvAny]="kvany"></profile-image-kv>
  </div>
</div>
<profile-image-kv *ngIf="kvAny?.value && !isArray(kvAny?.value)" [kvAny]="kvAny?.value"></profile-image-kv>-->
<!--End of Recursive-level of key-value[]-->
<!--</div>-->
<!--===========================================================================================================-->
<!--<li>
    <ul *ngIf="!isClosed" [ngClass]="{'shadow submenu':true, 'about':arrKvAny?.length === 0, 'picBg':arrKvAny?.length > 0}" align="left" style="width:100%;margin-left:-1rem;">
      <li>
        <div *ngIf="kvAnyLegend?.length > 0">
          <div *ngIf="kvAnyLegend?.length > 0" class="kvBttnSk">{{kvAnyLegend}} <span *ngIf="kvAnyLegend?.length > 0 && isRequired" class="errSub" style="margin-left: -2px;">*</span></div>
        </div>

        <div *ngFor="let kvAny of arrKvAny; let i=index">
          <div *ngIf="kvAny?.value">
            <div *ngIf="kvAny?.value?.value.length > 0">
              <div class="kvBttnSk text-center" style="width:100%;text-transform:capitalize">{{kvAny?.value?.key}}:</div>
              <div class="kvBttnxSv text-center wordWrap increase" style="text-transform:capitalize;">
                <span *ngFor="let v of kvAny?.value?.value; let j=index">
                  {{v}} <span *ngIf="j != v?.value?.length -1" style="color:gold;font-weight:600;padding:2px 2.5px 2px 2.5px"> |</span>
                </span>
              </div>

            </div>
          </div>
        </div>

        <div *ngIf="isRequired && ! isRequiredPassed() && isFormSubmitted" ass="flex-container">
          <span class="flex-text errS" style="text-transform: capitalize;color:orangered;"><em>{{kvAnyName}}</em> is required, please select at leaset one value.</span>
        </div>
      </li>
    </ul>
  </li>-->
<!--<div *ngIf="kvAny?.key?.indexOf('profileImageArr') !== -1 && kvAny?.value?.length" class="kvxxSv" style="width:45%;float:right;text-align:left;">
<div *ngFor="let kvAnyL2 of kvAny?.value; let j=index">
  <div *ngIf="kvAnyL2?.value">
    <div class="half kvxxSk text-left wordWrap" style="width:45%;float:left;text-align:right;">{{kvAnyL2.key}} : </div>
    <div class="kvxxSv" style="width:45%;float:right;text-align:left;text-transform:capitalize;">
      {{kvAnyL2.value}} / {{kvAnyL2.value}}
    </div>
    <div *ngIf="kvAnyL2?.value" style="padding:0.1rem;border-bottom:1px solid silver;"></div>
      --Third-level of key-value--
      <div *ngFor="let kvAnyL3 of kvAnyL2?.value; let k=index">
        <div class="half kvxxSk text-left wordWrap" style="float:left;text-transform:capitalize;vertical-align:text-top;">{{kvAnyL3.key}} : </div>

        --Fourth-level of key-value-Arr->
        --if non-image--
        <div *ngIf="kvAnyL3?.value" class="half kvxxSv wordWrap text-right" style="float:right;vertical-align:text-top;">
          {{kvAnyL3.value[0]}} /  {{kvAnyL3.value}}
        </div>
        <div *ngIf="kvAnyL3?.value" style="padding:0.1rem;border-bottom:1px solid silver;"></div>

        <div *ngFor="let kvAnyArrL4 of kvAnyL3?.value; let p=index">
          <div class="half kvxxSk text-left wordWrap" style="float:left;text-transform:capitalize;vertical-align:text-top;">{{kvAnyArrL4.key}} : </div>
          --if non-image-
          <div *ngIf="kvAnyArrL4?.key?.indexOf('image') === -1 && kvAnyArrL3?.value" class="half kvxxSv wordWrap text-right" style="float:right;vertical-align:text-top;">
            {{kvAnyArrL4.value[0]}} /  {{kvAnyArrL4.value}}
          </div>
          --if image--
          <div *ngIf="kvAnyArrL4?.key?.indexOf('image') !== -1 && kvAnyArrL4?.value" class="half kvxxSv text-center">
            <img [src]="kvAnyArrL4.value | safeFilter: 'pic'" style="height:7rem;width:9rem;" class="img-responsive img-rounded" />
          </div>
          <div *ngIf="kvAnyArrL4?.value" style="padding:0.1rem;border-bottom:1px solid silver;"></div>
        </div>
      </div>
    </div>
  </div>
</div>-->
