
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BinarySearchService } from './binarySearchService.service';
import { GenericSearchSortFilterService } from './genericSearchSortFilterService.service';
import { QuicksortService } from './quicksort.service';

@Injectable({
  providedIn: 'root',
})
export class SearchSortServiceService implements OnDestroy {
  private emitterDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private binarySearchService : BinarySearchService,
    private quicksortService : QuicksortService,
    private genericSearchSortFilterService : GenericSearchSortFilterService,
  ) { }
  // -------------------------------------------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ------------------------------------------------------------------------------------------------
  setBinarySearchService (bs : BinarySearchService): void {
    this.binarySearchService = bs;
  }
  // ------------------------------------------------------------------------------------------------
  getBinarySearchService () : BinarySearchService {
    return this.binarySearchService;
  }
  // ------------------------------------------------------------------------------------------------
  setQuicksortService (qs : QuicksortService): void {
    this.quicksortService = qs;
   }
  getQuicksortService () : QuicksortService {
    return this.quicksortService;
   }
  // ------------------------------------------------------------------------------------------------
  setGenericSearchSortFilterService (gss : GenericSearchSortFilterService): void {
    this.genericSearchSortFilterService = gss;
   }
   // ------------------------------------------------------------------------------------------------
  getGenericSearchSortFilterService () : GenericSearchSortFilterService {
    return this.genericSearchSortFilterService;
   }
  // -------------------------------------------------------------------------------------------------
}
