<!--Ref:https:// www.sitepoint.com/replacing-radio-buttons-without-replacing-radio-buttons/ -->
<!--*ngIf="offOn && offOn.sitUserId > 0"-->
<!--==========================================================================================================-->
<fieldset class="text-center smaller"
          style="margin: 0.05rem; 
                  padding: 0 0 3px 5px;
                  border-radius:100%;
                  position:relative;
                  height: 1rem;
                  width: 1rem;">
  <legend *ngIf="offOn && offOn.offOnLegend && offOn.offOnLegend.length > 0" class="nvyxxSB">{{offOn.offOnLegend}} 
    <span *ngIf="offOn && offOn.offOnLegend.length > 0 && isRequired" class="errSub" style="margin-left:-2px;">*</span>
  </legend>
  <label id="offOn-{{offOn && offOn.sitUserId > 0 ? offOn.sitUserId : 0}}" for="offOn-{{offOn && offOn.sitUserId > 0 ? offOn.sitUserId : 0}}" class="row" [ngStyle]="{'text-align':'left', 'min-width':'3rem', ' line-height': '1rem'}">
    <span id="spanRadio-{{offOn && offOn.sitUserId > 0 ? offOn.sitUserId : 0}}" class="spanR" #spanRadio></span>
    <!--<span id="spanAway-{{offOn && offOn.sitUserId > 0 ? offOn.sitUserId : 0}}"
        class="spanA" #spanAway
        [ngClass]="{'away':!isMobilevar, 'awayMobilevar':isMobilevar}"
        [innerHTML]="offOn?.away | safe : 'html'">
    {{offOn?.away}}
  </span>-->
    <!-- <app-profile-name [profileName]="profileName" [sitUser]="sitUser"></app-profile-name> -->
    <span id="spanAway-{{offOn && offOn.sitUserId > 0 ? offOn.sitUserId : 0}}"
          class="spanA smaller bluGrnGldxxxSB wordwrap" #spanAway
          style="margin:3px; padding:1px 1px 7px 1px;vertical-align:central;"
          [ngStyle]="{'font-size':'isMobilevar ? 11px : 12px'}"
          [innerHTML]="profileName | safe : 'html'">
      {{profileName}} {{offOn.away}}
    </span>
  </label>
</fieldset>

<!--==========================================================================================================-->
<!---->
<!--fieldset *ngIf="profileTile"
          class="text-center decrease"
          style="width: auto;
            margin-left: 0.25rem;
            height: 2rem;
            font-size:0.35rem;
            position:relative;
            width: auto;min-width: 3rem;">
  <legend *ngIf="offOn.offOnLegend.length > 0" class="nvyxxSB">{{offOn.offOnLegend}} <span *ngIf="offOnLegend.length > 0 && isRequired" class="errSub" style="margin-left:-2px;">*</span></legend>
  <label id="offOn-{{profileTile.source}}" for="offOn-{{profileTile.source}}" [ngStyle]="{'text-align':'left', 'min-width':'3rem', ' max-height': '1rem'}">
    <span id="span-radio-{{profileTile.source}}" class="spanR"></span>
    <span id="span-away-{{profileTile.source}}" [ngClass]="{'spanA': true, 'away':!isMobile, 'awayMobile':isMobile}">{{profileTile && profileTile.offOn.away && profileTile.offOn.away.length > 0 ? profileTile.offOn.away : profileTile.offOn.isOnline ? 'on-line' : ''}}</span>
  </label>
</fieldset-->
<!--==========================================================================================================-->
<!--<span id="span-away-{{profileTile.source}}" [ngClass]="{'spanA': true, 'away':!isMobile, 'awayMobile':isMobile}">{{profileTile && profileTile.away && profileTile.away.length > 0 ? profileTile.away : onlineStatus}}</span>-->
<!--<span id="span-away-{{profileTile.source}}" [ngClass]="{'spanA': true, 'away':!isMobile, 'awayMobile':isMobile}">{{profileTile.away.length > 0 ? profileTile.away : profileTile.isOnline ? 'on-line' : 'off-line'}}</span>-->
