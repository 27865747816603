<ul id="contentKeyValueArrComponentId" [@slideInOut] class="flex-item-main text-center shadowSilverInner"
    style="list-style: none;
          width: 90%;
          margin: 0.5rem 1rem 1rem 1rem;
          padding: 0px;
          opacity: 1;
          border-radius: 5px;
          background-color: midnightblue;
          overflow: visible;">
  <li expandMenu style="margin: 0; padding: 0; opacity: 1;" (click)="isProfContentClosedBool.isClosed=!isProfContentClosedBool.isClosed;isOpen(isProfContentClosedBool.isClosed);">    
      <!--Note: the classes: (neonBlueText, kvBttnUpload) their order is important!-->
      <p class="kvBttnUpload goldDrkRedSB" style="margin:0.01rem;padding:0.15rem 2rem 0.2rem 2rem;opacity:1">
        <span *ngIf="kvAnyName.length > 0" style="float: left; opacity: 1; font-size: 13px; font-weight:bold;">
          {{kvAnyName}}
        </span>
        <span *ngIf="isProfContentClosedBool.isClosed" class="glyphicon glyphicon-plus-sign hvr-icon"
              style="float: right; padding-top: 0.2rem; font-size: 13px; opacity: 1;" aria-hidden="true"></span>
        <span *ngIf="!isProfContentClosedBool.isClosed" class="glyphicon glyphicon-minus-sign hvr-icon"
              style="float: right; padding-top: 0.2rem; font-size: 13px; opacity: 1;" aria-hidden="true"></span>
      </p>
    <hr class="hrGray" style="display:inline-block;width:100%;padding:0;margin:0 0 0 0rem;" />
  </li>
  <li></li>
  <li>
    <ul *ngIf="!isProfContentClosedBool.isClosed" [ngClass]="{'submenu':true}" align="left" style="width:100%;margin-left:-1.5rem;opacity:1;">
      <li style="padding: 0 0 0 0.25rem;opacity:1;">
        <div *ngIf="kvAnyLegend && kvAnyLegend.length > 0" class="kvBttnSk">
          {{kvAnyLegend}}
          <span *ngIf="isRequired" class="errSub" style="margin-left: -2px;opacity:1;">*</span>
        </div>
        <div *ngIf="contentKvAnyArr && contentKvAnyArr.length > 0">
          <div *ngFor="let kvAny of contentKvAnyArr; let i=index">
            <div *ngIf="kvAny?.value?.length > 0" class="row text-left increase">
              <!--<div *ngIf='kvAny?.key?.indexOf("ProfileName") !== -1 && kvAny.value.length > 0' class="neonVioletText" style="width:90%;margin:1.5rem 50% 0.5rem 50%;"></div>-->
              <!--<div *ngIf='kvAny.key.indexOf("ProfileName") !== -1 && kvAny.value.length > 0' class="neonVioletText" style="width:90%;margin:1.5rem 50% 0.5rem 50%;">{{kvAny.value}}</div>-->
              <div *ngIf='kvAny?.key?.indexOf("ProfileName") === -1 && kvAny.value.length > 0'
                   class="kvBttnxSk text-center"
                   style="width: 100%; text-transform: capitalize; opacity: 1; font-size: 12px;">
                {{kvAny.key}}:
              </div>
              <div *ngIf='kvAny?.key?.indexOf("ProfileName") === -1 && kvAny.value.length > 0'
                   class="text-center  wordWrap"
                   [ngClass]="{'kvBttnxSv': kvAny.key.indexOf('Highlight') !== -1 || kvAny.key.indexOf('Description') !== -1}"
                   style="width: 90%; padding: 0.25rem 0% 0.25rem -0.5%; opacity: 1; font-size: 12px;"
                   [innerHTML]="kvAny?.value | safe: 'html'">
                {{kvAny.value}}
              </div>
              <!--<hr  class="hrGray" style="display:inline-block;width:100%;padding:0;margin:0 0 0 0.5rem;" />-->
            </div>
            <hr *ngIf="kvAny?.value?.length > 0" class="hrGray" style="display:inline-block;width:98%;padding:0;margin:0 0 0 0.75rem;" />
          </div>
         
        </div>
       
        <div *ngIf="isRequired && !isRequiredPassed() && isFormSubmitted">
          <span class="flex-text errxxS"
                style="text-transform: capitalize;color:orangered;opacity:1;">
            <em>{{kvAnyName}}</em> is required, please select at leaset one value.
          </span>
        </div>
      </li>
    </ul>
  </li>
</ul>
<!--==========================================================================================================-->
