'use-strict'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveFilterPipeModule } from '../directiveFilterPipeModule/directiveFilterPipe.module';
import { KeyValueRoutingModule } from '../keyValueModule/keyValue-routing.module';
//import { RegisterModule } from '../registerModule/register.module';
import { SharedModule } from '../sharedModule/shared.module';
import { PreferenceComponent } from './preference/preference.component';
import { ProfileRoutingModule } from './profile-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { ProfileContentComponent } from './profileContent/profileContent.component';

@NgModule({
 
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DirectiveFilterPipeModule,
    KeyValueRoutingModule,
    SharedModule,
    ProfileRoutingModule
  ],
  declarations: [
    PreferenceComponent,
    ProfileComponent,
    ProfileContentComponent
  ],
  exports: [
    ProfileRoutingModule
  ],
  providers: [
    // { provide: PreferenceService },
    // { provide: ProfileContentService },
    // { provide: ProfilePicsService },
    // { provide: ProfileSupportService },
    // { provide: ProfileTileService },
  ],
})
export class ProfileModule { }
