import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

export class PayPalPayee
{
  public email_address = '';
  public merchant_id = '';
  // ----------------------------------------------------------------------------------
  constructor () { }
  // ----------------------------------------------------------------------------------
  public copy( from: PayPalPayee ): void
  {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(from))
    {
      this.email_address = from.email_address;
      this.merchant_id = from.merchant_id;
    }
  }

  // ----------------------------------------------------------------------------------
  public toString (): string
  {
    let outString = '' +
      '{\n merchant_id: ' +
      this.merchant_id +
      ',\n email_address: ' +
      this.email_address +     
      '\n}';
    return outString;
  }
}
