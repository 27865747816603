
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { AppFeature } from '../../../models/common/appFeture.model';
import { Bool } from '../../../models/common/bool.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { KvAny } from '../../../models/keyValue/kvAny.model';
import { AppFeatureService } from '../../../services/appServiceService/appFeatureService.service';
import { AppServiceService } from '../../../services/appServiceService/appServiceService.service';
// import { AppFeatureService } from '../../../services/appServiceService/appFeatureService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { ScrollTopServiceStatic } from '../../../services/staticServices/commonStaticServices/scrollTopServiceStatic.service';

@Component({
  selector: 'app-feature',
  templateUrl: './appFeature.component.html',
  styleUrls: ['./appFeature.component.scss'],
  animations: [ Animations, slideInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush, //  default //
})
export class AppFeatureComponent implements OnInit, OnDestroy, AfterViewInit
{
  public appFeatureService!: AppFeatureService;
  // public gestureService: GestureService;
  // -----------------------------------------------
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isLoggedIn = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  // --------------
  public askMe = 'Ask Me :)';
  public appFeatureArr : AppFeature[] = [];
  public arrKvAny : KvAny[] = [];
  public arrKvAnyLegend = 'Slakez Features';
  public arrKvAnyName = 'Features';

  public index = -1;
  public isRequired = false;
  public isAppFeatureClosedBool : Bool = new Bool();
  public isDropdownOpen = false;
  public kvLegend : string[] = [ 'Comparison: Premium Membership vs Valued Membership'];
  public kvName : string[] = [ 'Premium Membership vs Valued Membership' ];
  // public arrKvResult : KeyValueResult = new KeyValueResult();
  public selectedKvIndex = -1;

  constructor (
    private appServiceService : AppServiceService,
    private cdr : ChangeDetectorRef,
    private router : Router)
  {
    if ( this.appServiceService )
    {
      this.appFeatureService = this.appServiceService.getAppFeatureService();
    }
    this.mcLoader.componentName = 'appFeatureComponentId';
    this.mcLoader.moduleName = 'appfeaturem';
    this.mcLoader.callerName = 'appFeatureComponent';
    EmitterSubjectService.emitRemoveComponent( this.mcLoader );
  }

  ngOnInit ()
  {
    // debugger;
    EmitterSubjectService.emitIsAppFeaturePage(true);
    // debugger;
    this.appFeatureArr = this.appFeatureService.getAppFeatureArr();
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.appFeatureArr) || this.appFeatureArr.length === 0) {
      this.appFeatureService.getAppFeaturesFromServer().subscribe(data => {
        this.appFeatureArr = data;
        this.arrKvAny = this.appFeatureService.getArrKvAny();
        this.ngAfterViewInit();
        // debugger;
      })         
    }

    // this.setPageTitle();

    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if ( this.loginSuccess.signedInUserId > 0 )
    {
      this.isLoggedIn = true;
    }
    else
    {
      this.isLoggedIn = false;
    }
     // ---------------------------------------------------------------
    EmitterSubjectService.appFeatureArrEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        // this.appFeatureArr = result as AppFeature[]; // JSON.parse(JSON.stringify(result));
        // debugger;
        this.arrKvAny = this.appFeatureService.getArrKvAny();
        this.ngAfterViewInit();
      } );
     // ---------------------------------------------------------------
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        this.loginSuccess = result as LoginSuccess; // JSON.parse(JSON.stringify(result));
        // debugger;
        if ( this.loginSuccess.signedInUserId > 0 )
        {
          this.isLoggedIn = true;
        }
        else
        {
          this.isLoggedIn = false;
        }
      } );
    // ---------------------------------------------------------------
    let timer = setTimeout(() => {
      ScrollTopServiceStatic.scrollToTop('allScrollToTopId');
    }, 1000);
    clearTimeout(timer);
  }
  // ---------------------------------------------------------------
  ngAfterViewInit () {
    this.cdr.detectChanges();
    // 
    ScrollTopServiceStatic.scrollToTop('allScrollToTopId');
    let timer = setTimeout(() => {
      ScrollTopServiceStatic.scrollToTop('allScrollToTopId');
    }, 1000);
    clearTimeout(timer);
  }
  // ---------------------------------------------------------------------------------
  ngOnDestroy ()
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    EmitterSubjectService.emitIsAppFeaturePage(false);
  }
  /*
   * ---------------------------------------------------------------
   * Emit the title of the page:
   * ---------------------------------------------------------------
   */
  setPageTitle() {
    this.userAndPageTitle.isPrepared = true;
    this.userAndPageTitle.sitUserId = this.loginSuccess.signedInUserId;
    this.userAndPageTitle.pageTitle = '-:App Features:-';
    EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
  }
  // ---------------------------------------------------------------------------------
  isOpen ( isClosed: boolean ): void
  {
    // debugger;
    let value = !isClosed;
    let IsOpen = new Bool();
    IsOpen.isClosed = value;
    IsOpen.name = 'appFetureComponentId';
    EmitterSubjectService.emitIsOpen( IsOpen );
  }
  // ---------------------------------------------------------------
}
'use-strict'
