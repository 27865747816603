
import { Injectable } from '@angular/core';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { BrdCrmKvAny } from '../../../models/breadcrum/brdcrmKvAny.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { Chat } from '../../../models/chat/chat.model';
import { Content } from '../../../models/common/content.model';
import { DateAssay } from '../../../models/common/dateAssay.model';
import { Heartbeat } from '../../../models/common/heartbeat.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { Photo } from '../../../models/common/photo.model';
import { PhotoBn } from '../../../models/common/photoBn.model';
import { Unit } from '../../../models/common/unit.model';
import { CommunicationActivity } from '../../../models/communication/communicationActivity.model';
import { Communicator } from '../../../models/communication/communicator.model';
import { ENoteTile } from '../../../models/communication/eNoteTile.model';
import { KvAction } from '../../../models/keyValue/kvAction.model';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { MemberActivity } from '../../../models/member/memberActivity.model';
import { OffOn } from '../../../models/offOn/offOn.model';
import { Pager } from '../../../models/pagination/pager.model';
import { PagerMemberActivity } from '../../../models/pagination/pagerMemberActivity.model';
import { PagerPhotoMgmt } from '../../../models/pagination/pagerPhotoMgmt.model';
import { ProfileImage } from '../../../models/profile/profileImage.model';
import { ProfileInfo } from '../../../models/profile/profileInfo.model';
import { ProfilePics } from '../../../models/profile/profilePics.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { UpgradedTile } from '../../../models/profile/upgradedTile.model';
import { PayPalOrder } from '../../../models/sales/payPalOrder';
import { EmitterSubjectService } from '../../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { ArraySupportServiceStatic } from './../arraySupportServiceStatic.service';
//import { PayPalOrder } from '../../models/sales/payPalOrder.model';
// import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Dictionary } from 'dictionaryjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../stringServiceStatic.service';

// Since this file is too large we guide ourselves by this commented out Interface:
// -------------------------------------------------------------------------------:
//  static deepCloneProfileTileArray ( sTileArr : ProfileTile[] ): ProfileTile[]
//  static copyPhotoBnIfExists (dPbn : PhotoBn, sPbn : PhotoBn) : PhotoBn
//  static convertPhotoToPhotoBn ( photo : Photo, sitUser: SitUser ) : PhotoBn
//  static ConvertPhotoToProfileImage ( photo: Photo ) : ProfileImage
//  static convertUserPhotoToProfileImage(userPhoto: Photo): ProfileImage
//  static copyKvGlyph ( kvgIn : KvGlyph ) : KvGlyph
//  static copyChatIfExists(dChat: Chat, sChat: Chat): Chat
//  static copyFileBytesArr(dChat, sChat): any[]
//  static copyBoxNonceIfExists(destTile: BoxNonceEntity, sourceTile: BoxNonceEntity): boolean
//  static copyCommunicatorIfExists(dCommunicator: Communicator, sCommunicator: Communicator): Communicator
//  static copyCommunicationActivityIfExists(dCommunicationActivity: CommunicationActivity, sCommunicationActivity: CommunicationActivity): CommunicationActivity
//  static copyDateAssayIfExists ( destDateAssay : DateAssay, sourceDateAssay : DateAssay ) : DateAssay
//  static copyHeartbeatIfExists ( dHeartbeat : any, sHeartbeat : Heartbeat ) : Heartbeat
//  static copyLoginSuccessIfExists ( dLoginSuccess: LoginSuccess, sLoginSuccess: LoginSuccess ): LoginSuccess
//  static copyLoginSuccessToSitUser ( sLoginSuccess : LoginSuccess) : SitUser
//  static copyOrderIfExists ( destOrder : PayPalOrder, sourceOrder : PayPalOrder ) : PayPalOrder
//  static copyProfileInfoIfExists ( profileInfo : ProfileInfo, inPInfo : ProfileInfo ) : ProfileInfo
//  static copyProfileTileIfExists ( destTile : ProfileTile, sourceTile : ProfileTile ) : ProfileTile
//  static copyKvPhotoIfExists ( dKvPhoto : KvPhoto, sKvPhoto : KvPhoto ) : KvPhoto
//  static copyProfileImageIfExists ( dpImage : ProfileImage, spImage : ProfileImage ) : ProfileImage
//  static copyProfilePicsIfExists ( dpPics : ProfilePics, spPics : ProfilePics ) : ProfilePics
//  static copyUpgradedTileIfExists ( destTile : UpgradedTile, sourceTile : UpgradedTile ) : UpgradedTile
//  static copyUserPhotoIfExists ( dPhoto : Photo, sPhoto : any ) : Photo
//  static copyFromContentToProfileInfo ( content ) : ProfileInfo
//  static copyFromProfileInfoToContent ( profileInfo : ProfileInfo ) : any
//  static copyFromCommunicationActivityToENoteTile ( commMsg : CommunicationActivity ) : ENoteTile
//  static copyFromCommunicationActivityToProfileTile ( commMsg : CommunicationActivity ) : ProfileTile
//  static copyFromENoteTileToProfileTile(eNoteTile: ENoteTile): ProfileTile

//  static copyFromProfileTileToUpgradedTile ( destTile : UpgradedTile, sourceTile : ProfileTile ) : UpgradedTile
//  static copyFromHeartbeatToProfileTile(pTile: ProfileTile, hb: Heartbeat): ProfileTile
//  static copyFromPagerBreadcrumToPager(pbc: PagerBreadcrum): Pager
//  static copyFromPagerBreadcrumToPagerPhotoMgmt(pbc: PagerBreadcrum): PagerPhotoMgmt
//  static CopyFromBreadCrumKvToKvPhoto ( mcl: ModuleComponentLoader ): KvPhoto
//  static CopyFromBreadCrumKvToKvPhotoOld(bcKvAny: BrdCrmKvAny): KvPhoto
//  static copyFromProfileImageArrToKvPhotoArr(pImages: ProfileImage[]): KvPhoto[]
//  static copyFromProfileImageDictionaryToKvPhotoArr(pImages: Collections.Dictionary<number, ProfileImage>): KvPhoto[]
//  static copyFromProfileImageDictionaryToKvPhotoDictionary(pImages: Collections.Dictionary<number, ProfileImage>): Collections.Dictionary<number, KvPhoto>
//  static copyFromKvPhotoToPhoto(pkv: KvPhoto): Photo
//   static copyFromLoginSuccessToKvPhoto ( loginSuccess: LoginSuccess ): KvPhoto
//  static copyFromPhotoToKvPhoto(p: Photo): KvPhoto
//  static copyFromPhotoToKvPhoto(p: Photo): KvPhoto
//  static copyFromProfileImageToPhoto(pImage: ProfileImage): Photo
//  static copyFromServerPhotoToClientPhoto(to: Photo, that: any): Photo
//  static copyFromServerPhotoBnToClientPhoto ( to: Photo, that: any): Photo
//  static copyFromPhotoBnToClientPhoto ( to: Photo, that: any ): Photo
//  static copyFromPhotoBnToKvPhoto ( to : KvPhoto, that : PhotoBn ): KvPhoto
//  static copyFromProfileImageToKvPhoto(pImage: ProfileImage): KvPhoto
//  static copyFromProfilePicsToKvPhotoArr(pPics: ProfilePics, sitUser: SitUser): KvPhoto[]
//  static copyProfilePicsToKvPhotoArr ( profilePics : ProfilePics ) : KvPhoto[]
//  static copyFromKvGlyphToMemberActivity(kvg: KvGlyph): MemberActivity
//   static copyFromKvGlyphToMemberActivity(kvg: KvGlyph): MemberActivity
//  static copyFromMemberActivityToKvGlyph(mAct: MemberActivity): KvGlyph
//  static copyFromKvActionToMemberActivity(kva: KvAction): MemberActivity
//  static copyFromMemberActivityToKvAction(mAct: MemberActivity): KvAction
//  static copyFromKvGlyphToKvPhoto(kvg: KvGlyph): KvPhoto
//  static copyFromKvPhotoToKvGlyph(kvP: KvPhoto): KvGlyph
//  static copyFromProfileTileToKvPhoto(pTile: ProfileTile): KvPhoto
//  static copyFromProfileInfoToProfileInfoView(profile: ProfileInfo): any
//  static copyFromProfileInfoToProfileTile ( pInfo: ProfileInfo ): ProfileTile

//  static copyFromProfileTileToProfileInfo ( pTile ) : ProfileInfo
//  static copyFromProfileTileToProfileInfo(pTile: ProfileTile): ProfileInfo
//  static copyFromProfilePicsToProfileInfo(pPics: ProfilePics): ProfileInfo
//  static createProfilePicsFromProfileTile ( profileTile : ProfileTile ) : ProfilePics
//  static createProfilePicsFromProfileImageArr(profileImageArr: ProfileImage[]): ProfilePics
//  static createProfilePicsFromProfileImageDictionary(pImageD: Collections.Dictionary<number, ProfileImage>): ProfilePics
//  static copyPagerToPagerPhotoMgmt(pager: Pager): PagerPhotoMgmt
// ---------------------------------------------------------------------
// Note: Conditon for copyIfExists: if other than default value exists
// ---------------------------------------------------------------------


@Injectable({
  providedIn: 'root',
})
export abstract class CopyServiceStatic {
  static eNoteTile: ENoteTile = new ENoteTile();
  // static emailTile: CommunicationActivity = new CommunicationActivity();
  static loginSuccess: LoginSuccess = new LoginSuccess();
  static pager: Pager = new Pager();
  static pagerBrdCrm: PagerBreadcrum = new PagerBreadcrum();
  static pagerPhotoMgmt: PagerPhotoMgmt = new PagerPhotoMgmt();
  // Note: there could be multiple passes of 1, and 2 on profileInfoPreView before final copy to profileInfoView
  static profileInfoView: ProfileInfo = new ProfileInfo();
  static profileTile: any;
  static sitUser: SitUser = new SitUser();
  // --------------------------------------------------------------
  constructor () { }
  // ---------------------------------------------------------------
  //  Note: TODO: create a new static service called DeepCloneService
  // ---------------------------------------------------------------
  static deepCloneProfileTileArray ( sTileArr : ProfileTile[] ): ProfileTile[]
  {
    let dTileArr:any[] = [];
    let tpTile = new ProfileTile();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sTileArr ) && sTileArr.length > 0 )
    {
      sTileArr.map( s =>
      {
        tpTile = new ProfileTile();
        CopyServiceStatic.copyProfileTileIfExists( tpTile, s );
        dTileArr.push( tpTile );
      } );
    }
    return dTileArr;
  }
  // ---------------------------------------------------------------------
  // Note: Conditon for copyIfExists: if other than default value exists
  // ---------------------------------------------------------------------
  static copyPhotoBnIfExists (dPbn : PhotoBn, sPbn : PhotoBn) : PhotoBn
  {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn))
    {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dPbn))
      {
        dPbn = sPbn;
      }
      else
      {
        dPbn.copyIfExists(sPbn);

        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.box)) dPbn.box = sPbn.box;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.nonce)) dPbn.nonce = sPbn.nonce;

        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.approval)) dPbn.approval = sPbn.approval;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.contentType)) dPbn.contentType = sPbn.contentType;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.cryptUserKey)) dPbn.cryptUserKey = sPbn.cryptUserKey;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.date)) dPbn.date = sPbn.date;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.distance)) dPbn.distance = sPbn.distance;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.description)) dPbn.description = sPbn.description;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.enctype)) dPbn.enctype = sPbn.enctype;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.file)) dPbn.file = sPbn.file;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.id) && sPbn.id > 0) dPbn.id = sPbn.id;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.image) && sPbn.image.length > 0) dPbn.image = sPbn.image;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.image) && sPbn.imageBytes.length > 0) dPbn.imageBytes = sPbn.imageBytes;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.image)) dPbn.imageString = sPbn.imageString;
        //dPbn.isPrimary = sPbn.isPrimary;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.location)) dPbn.location = sPbn.location;
        //if (sPbn.latitude != 0) dPbn.latitude = sPbn.latitude;
        //if (sPbn.longitude != 0) dPbn.longitude = sPbn.longitude;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.message)) dPbn.message = sPbn.message;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.path)) dPbn.path = sPbn.path;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.pic) && sPbn.pic.length > 0) dPbn.pic = sPbn.pic;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.privacy)) dPbn.privacy = sPbn.privacy;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.photoId) && sPbn.photoId > 0) dPbn.photoId = sPbn.photoId;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.photoBnId) && sPbn.photoBnId > 0) dPbn.photoBnId = sPbn.photoBnId;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.sitUserId) && sPbn.sitUserId > 0) dPbn.sitUserId = sPbn.sitUserId;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.sitUserKey)) dPbn.sitUserKey = sPbn.sitUserKey;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.sourceId) && sPbn.sourceId > 0) dPbn.sourceId = sPbn.sourceId;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.sourceType)) dPbn.sourceType = sPbn.sourceType;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.title)) dPbn.title = sPbn.title;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sPbn.url)) dPbn.url = sPbn.url;
      }
    }
    return dPbn;
  }
  // --------------------------------------------------------------
  static copyPagerVKvPhotoNavFromPagerMemberActivity (pagerVMemberActivity : PagerMemberActivity) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVMemberActivity)) {
      let ppMgmt = new PagerPhotoMgmt();
      return pagerVMemberActivity.copyToPagerPhotoMgmt(ppMgmt);
    }      
  }
  // --------------------------------------------------------------
  /*
  * ---------------------------------------------------------------
  * Note: Since we get the Server-sent Photo data, all properties start with UpperCase letter,
  *     but the client-side Photo.model.ts has all properties in lowerCase letter
  *     Hence, this method converts the upper-case letters to lower-case letters.
  *
  *     Note: copyFromServerPhotoToClientPhoto() should be used instead of this method.
  *     Similar method: copyFromServerPhotoBnToClientPhotoBn()
  * ---------------------------------------------------------------
  */
  static copyPhotoToPhotoBn (dPhotoBn : PhotoBn, photo : Photo) : PhotoBn {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dPhotoBn)) {
      dPhotoBn = new PhotoBn();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo) && photo.photoId > 0) {
      dPhotoBn.copyIfExists(photo as PhotoBn);

      //dPhotoBn.isPrimary = photo.isPrimary;
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.approval)) {
      //  dPhotoBn.approval = photo.approval;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.contentType)) {
      //  dPhotoBn.contentType = photo.contentType;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.cryptUserKey)) {
      //  dPhotoBn.cryptUserKey = photo.cryptUserKey; // need to be changed by caller
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.date)) {
      //  dPhotoBn.date = photo.date;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.description)) {
      //  dPhotoBn.description = photo.description;
      //}
      //if (photo.id > 0) {
      //  dPhotoBn.id = photo.id;
      //}
      //if (photo.photoBnId > 0) {
      //  dPhotoBn.photoBnId = photo.photoBnId;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.image)) {
      //  dPhotoBn.imageBytes = photo.image;
      //}
      //if (photo.latitude !== 0) {
      //  dPhotoBn.latitude = photo.latitude;
      //}
      //if (photo.longitude !== 0) {
      //  dPhotoBn.longitude = photo.longitude;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.path)) {
      //  dPhotoBn.path = photo.path;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.pic)) {
      //  dPhotoBn.pic = photo.pic; // Note: pic is in lowercase in sPhoto !!! TODO: remove comment after testing
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.photoId)) {
      //  dPhotoBn.photoId = photo.photoId;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.privacy)) {
      //  dPhotoBn.privacy = photo.privacy;
      //}
      //dPhotoBn.sitUserId = photo.sitUserId > 0 ? photo.sitUserId : dPhotoBn.sitUserId; // Note: this one is camelCased
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.sitUserKey)) {
      //  dPhotoBn.sitUserKey = photo.sitUserKey;
      //}
      //if (photo.signedInUserId > 0) {
      //  dPhotoBn.signedInUserId = photo.signedInUserId;
      //}
      //if (photo.sourceId > 0) {
      //  dPhotoBn.sourceId = photo.sourceId;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.sourceType)) {
      //  dPhotoBn.sourceType = photo.sourceType;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.title)) {
      //  dPhotoBn.title = photo.title;
      //}
      // debugger;
    }
    return dPhotoBn;
  }
  // ---------------------------------------------------------------------  
  static convertPhotoToPhotoBn ( photo : Photo, sitUser: SitUser ) : PhotoBn
  {
    let photoBn : PhotoBn = new PhotoBn();
    if ( photo?.photoId > 0 )
    {

      if (
        ( sitUser == null || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sitUser.sitUserKey ) )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( photo.sitUserKey )
      )
      {
        photoBn.sitUserKey = photo.sitUserKey;

        sitUser = EmitterSubjectService.getSitUserModel();
      }
      else if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sitUser.sitUserKey ) ) photoBn.sitUserKey = photo.sitUserKey = sitUser?.sitUserKey;

      photoBn.copy(photo as PhotoBn);
      photoBn.isPrimary = photo.isPrimary = sitUser?.primaryPhotoBnId == photo.photoId ? true : false;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.privacy)) photoBn.privacy = sitUser?.primaryPhotoBnId == photo.photoId ? "Public" : photo.privacy;


      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( photo.approval ) ) photoBn.approval = photo.approval;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( photo.contentType ) ) photoBn.contentType = photo.contentType;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.cryptUserKey)) photoBn.cryptUserKey = photo.cryptUserKey = sitUser?.cryptUserKey;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.date)) photoBn.date = photo.date;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.file)) photoBn.file = photo.file;
      // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.formData)) photoBn.formData = photo.formData;
      if (photo.id >= 0) photoBn.id = photo.id;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.image)) photoBn.image = photo.image;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.imageBytes)) photoBn.imageBytes = photo.imageBytes;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.imageString)) photoBn.imageString = photo.imageString;
      photoBn.isPrimary = photo.isPrimary = sitUser?.primaryPhotoBnId == photo.photoId ? true : false;
      if ( photo.latitude.length != 1 ) photoBn.latitude = photo.latitude;
      if (photo.longitude.length != 1) photoBn.longitude = photo.longitude;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.path)) photoBn.path = photo.path;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.itemNum)) photoBn.itemNum = photo.itemNum;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.pic)) photoBn.pic = photo.pic;
      photoBn.photoId = photo.photoId;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.privacy)) photoBn.privacy = sitUser?.primaryPhotoBnId == photo.photoId ? "Public" : photo.privacy;
      photoBn.profileId = photo.profileId;
      if (photo.sitUserId > 0) photoBn.sitUserId = photo.sitUserId;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.sitUserKey)) photoBn.sitUserKey = photo.sitUserKey;
      if (photo.signedInUserId > 0) photoBn.signedInUserId = photo.signedInUserId;
      if ( photo.sourceId > 0 ) photoBn.sourceId = photo.sourceId;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( photo.sourceType ) ) photoBn.sourceType = photo.sourceType;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( photo.title ) ) photoBn.title = photo.title;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( photo.url ) ) photoBn.url = photo.url;

      if (photo.imageBytes != null && photo.imageBytes.length > 0 )
      {
        photoBn.imageBytes = photo.imageBytes; // photo will contain it in pic
      }

    }
    return photoBn;
  }
  // -----------------------------------------------------------
  static ConvertPhotoToProfileImage ( photo: Photo ) : ProfileImage
  {
    let profImage: ProfileImage = new ProfileImage();
    if ( photo?.photoId > 0 )
    {
      profImage.date = photo.date;
      profImage.image = photo.pic;
      profImage.profileId = photo.sourceId;
      profImage.sitUserKey = photo.sitUserKey;
      profImage.photoId = photo.photoId;
      profImage.isPrimary = photo.isPrimary;
      profImage.privacy = photo.privacy;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( photo.sitUserKey ) )
      {
        let sitUser = EmitterSubjectService.getSitUserModel(); //  photo.SITUserKey ).FirstOrDefault();
        if ( sitUser?.sitUserId > 0 ) profImage.sitUserId = sitUser.sitUserId;
      }
    }
    return profImage;
  }
  // --------------------------------------------------------------
  static convertUserPhotoToProfileImage(userPhoto: Photo): ProfileImage {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userPhoto)) {
      // debugger;
      const profileImage = new ProfileImage();
      profileImage.approval = userPhoto.approval;
      profileImage.cryptUserKey = userPhoto.cryptUserKey;
      profileImage.date = userPhoto.date;
      profileImage.description = userPhoto.description;
      profileImage.imageBytes = userPhoto.imageBytes;
      profileImage.image = userPhoto.pic;
      profileImage.imageFromBytes = profileImage.image;
      profileImage.entityName = 'Photo';
      // important!! NOTE: Compare photoId with sitUser.primaryPhotoBnId
      profileImage.isPrimary = userPhoto.photoId === this.sitUser.primaryPhotoBnId;
      profileImage.privacy = userPhoto.privacy;
      profileImage.photoId = userPhoto.photoId;
      profileImage.sitUserId = userPhoto.sitUserId;
      profileImage.sitUserKey = userPhoto.sitUserKey;
      profileImage.title = userPhoto.title;
      return profileImage;
    }
    return new ProfileImage();
  }
  // ---------------------------------------------------------------
  // static copyKvGlyph (kvgIn : KvMemberActivityGlyph) : KvMemberActivityGlyph
  // {
  //  // debugger; //In copyServcie
  //  const kvG = new KvMemberActivityGlyph();

  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( kvgIn ) )
  //  {
  //    kvG.action = kvgIn.action;
  //    kvG.cryptUserKey = kvgIn.cryptUserKey;
  //    kvG.date = kvgIn.date;
  //    kvG.doeeCryptUserKey = kvgIn.doeeCryptUserKey;
  //    kvG.doerCryptUserKey = kvgIn.doerCryptUserKey;
  //    kvG.doeeSitUserId = kvgIn.doeeSitUserId;
  //    kvG.doerSitUserId = kvgIn.doerSitUserId;
  //    kvG.glyphName = kvgIn.glyphName;
  //    kvG.id = kvgIn.id;
  //    kvG.index = kvgIn.index;
  //    kvG.isPrimary = kvgIn.isPrimary;
  //    kvG.isPrivate = kvgIn.isPrivate;
  //    kvG.key = kvgIn.key; // kvgIn.glyphName; //TODO: need to determine what value should key hold from KvPhoto
  //   // kvG.kvPhoto = kvG.kvPhoto.copy(kvgIn.kvPhoto);
  //    // -------------------------------
  //    // Begin of KvPhoto model:
  //    // -------------------------------
  //    this.description = a.description;
  //    this.elemId = a.elemId;
  //    this.entityName = a.entityName;
  //    this.file = a.file;
  //    this.formData = a.formData;
  //    this.glyphName = a.glyphName;
  //    this.id = a.id;
  //    this.image = a.image;
  //    this.imageBytes = a.imageBytes;
  //    this.imageString = a.imageString;
  //    this.index = a.index;
  //    this.isPrivate = a.isPrivate;
  //    this.isUnlocked = a.isUnlocked;
  //    this.isPrimary = a.isPrimary;
  //    this.itemNum = a.itemNum;
  //    this.key = a.key;
  //    this.latitude = a.latitude;
  //    this.location = a.location;
  //    this.longitude = a.longitude;
  //    this.path = a.path;
  //    this.pageNum = a.pageNum;
  //    this.pic = a.pic;
  //    this.privacy = a.privacy;
  //    this.profileId = a.profileId;
  //    this.returnUrl = a.returnUrl;
  //    this.title = a.title;
  //    this.value = a.value;
  //    this.url = a.url;
  //  // -------------------------------
  //  // End of KvPhoto model:
  //  // -------------------------------
  //    kvG.message = kvgIn.message;
  //    kvG.modelName = kvgIn.modelName;
  //    kvG.pageNum = kvgIn.pageNum;
  //    kvG.photoId = kvgIn.photoId;
  //    kvG.photoBnId = kvgIn.photoBnId;
  //    kvG.privacy = kvgIn.privacy;
  //    kvG.profileId = kvgIn.profileId;
  //    kvG.sitUserId = kvgIn.sitUserId;
  //    kvG.value = kvgIn.value;
  //    return kvG;
  //  }
  //  else
  //  {
  //    return new KvMemberActivityGlyph();
  //  }
  // }
  // ---------------------------------------------------------------
  static copyChatIfExists(dChat: Chat, sChat: Chat): Chat {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dChat)) {
      dChat = new Chat();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat)) {
      if (sChat.communicationActivityId > 0) {
        dChat.communicationActivityId = sChat.communicationActivityId;
      }
      if (sChat.communicationMessageId > 0) {
        dChat.communicationMessageId = sChat.communicationMessageId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.contentType)) {
        dChat.contentType = sChat.contentType;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.date)) {
        dChat.date = sChat.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.dateFormatted)) {
        dChat.dateFormatted = sChat.dateFormatted;
        dChat.fileBytesArr = this.copyFileBytesArr(dChat, sChat);
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.fromUser)) {
        dChat.fromUser = sChat.fromUser;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.groupName)) {
        dChat.groupName = sChat.groupName;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.groupId) && sChat.groupId > 0) {
        dChat.groupId = sChat.groupId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.groupKey)) {
        dChat.groupKey = sChat.groupKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.id)) {
        dChat.id = sChat.id;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.isPic)) {
        dChat.isPic = sChat.isPic;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.isReceiver)) {
        dChat.isReceiver = sChat.isReceiver;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.isSender)) {
        dChat.isSender = sChat.isSender;
      }

      dChat.latitude = sChat.latitude;
      dChat.longitude = sChat.longitude;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.messageBody)) {
        dChat.messageBody = sChat.messageBody;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.receiver)) {
        dChat.receiver = sChat.receiver;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.receiverAction)) {
        dChat.receiverAction = sChat.receiverAction;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.receiverPic)) {
        dChat.receiverPic = sChat.receiverPic;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.receiverProfileName)) {
        dChat.receiverProfileName = sChat.receiverProfileName;
      }
      if (sChat.receiverSitUserId > 0) {
        dChat.receiverSitUserId = sChat.receiverSitUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.receiverSitUserKey)) {
        dChat.receiverSitUserKey = sChat.receiverSitUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.sender)) {
        dChat.sender = sChat.sender;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.senderAction)) {
        dChat.senderAction = sChat.senderAction;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.senderPic)) {
        dChat.senderPic = sChat.senderPic;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.senderProfileName)) {
        dChat.senderProfileName = sChat.senderProfileName;
      }
      if (sChat.senderSitUserId > 0) {
        dChat.senderSitUserId = sChat.senderSitUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.senderSitUserKey)) {
        dChat.senderSitUserKey = sChat.senderSitUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.sentPic)) {
        dChat.sentPic = sChat.sentPic;
      }
      if (sChat.signedInUserId > 0) {
        dChat.signedInUserId = sChat.signedInUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.signedInUserKey)) {
        dChat.signedInUserKey = sChat.signedInUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.subject)) {
        dChat.subject = sChat.subject;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.timeLapsed)) {
        dChat.timeLapsed = sChat.timeLapsed;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.toUser)) {
        dChat.toUser = sChat.toUser;
      }
      // debugger;
    }
    return dChat;
  }
  // ---------------------------------------------------------------
  static copyFileBytesArr(dChat: any, sChat: any): any[] {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dChat)) {
      dChat = new Chat();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sChat.fileBytesArr) && sChat.fileBytesArr.length > 0) {
      sChat.fileBytesArr.map((e: any) => {
        dChat.fileBytesArr.push(e);
      });
    }
    return dChat.fileBytesArr;
  }
  // --------------------------------------------------------------
  static copyBoxNonceIfExists(destTile: BoxNonceEntity, sourceTile: BoxNonceEntity): boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.box)) {
        destTile.box = sourceTile.box;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.cryptUserKey)) {
        destTile.cryptUserKey = sourceTile.cryptUserKey;
      }
      if (sourceTile.date && sourceTile.date.length > 0) {
        destTile.date = sourceTile.date;
      }
      if (sourceTile.id > 0) {
        destTile.id = sourceTile.id;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.key)) {
        destTile.key = sourceTile.key;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.entityName)) {
        destTile.entityName = sourceTile.entityName;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.nonce)) {
        destTile.nonce = sourceTile.nonce;
      }
      if (sourceTile.sitUserId > 0) {
        destTile.sitUserId = sourceTile.sitUserId;
      }
      return true;
    }
    return false;
  }

  // ---------------------------------------------------------------
  static copyCommunicatorIfExists(dCommunicator: Communicator, sCommunicator: Communicator): Communicator {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dCommunicator)) {
      dCommunicator = new Communicator();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicator)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicator.cryptUserKey)) {
        dCommunicator.cryptUserKey = sCommunicator.cryptUserKey; // need to be changed by caller
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicator.communicatorType)) {
        dCommunicator.communicatorType = sCommunicator.communicatorType;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicator.date)) {
        dCommunicator.date = sCommunicator.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicator.email)) {
        dCommunicator.email = sCommunicator.email;
      }
      dCommunicator.latitude = sCommunicator.latitude;
      dCommunicator.longitude = sCommunicator.longitude;
      if (sCommunicator.primaryPhotoBnId > 0) {
        dCommunicator.primaryPhotoBnId = sCommunicator.primaryPhotoBnId;
      }
      if (sCommunicator.profileId > 0) {
        dCommunicator.profileId = sCommunicator.profileId;
      }
      if (sCommunicator.sitUserId > 0) {
        dCommunicator.sitUserId = sCommunicator.sitUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicator.primaryImage)) {
        dCommunicator.primaryImage = sCommunicator.primaryImage;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicator.profileName)) {
        dCommunicator.profileName = sCommunicator.profileName;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicator.sitUserKey)) {
        dCommunicator.sitUserKey = sCommunicator.sitUserKey;
      }
      // debugger;
    }
    return dCommunicator;
  }

  // ---------------------------------------------------------------
  static copyCommunicationActivityIfExists(dCommunicationActivity: CommunicationActivity, sCommunicationActivity: CommunicationActivity): CommunicationActivity {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dCommunicationActivity)) {
      dCommunicationActivity = new CommunicationActivity();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity)) {
      if (sCommunicationActivity.communicationActivityId > 0) {
        dCommunicationActivity.communicationActivityId = sCommunicationActivity.communicationActivityId;
      }
      if (sCommunicationActivity.communicationMessageId > 0) {
        dCommunicationActivity.communicationMessageId = sCommunicationActivity.communicationMessageId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.communicationType) )
      {
        dCommunicationActivity.communicationType = sCommunicationActivity.communicationType;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sCommunicationActivity.communicationSubType ) )
      {
        dCommunicationActivity.communicationSubType = sCommunicationActivity.communicationSubType;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.date)) {
        dCommunicationActivity.date = sCommunicationActivity.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.dateFormatted)) {
        dCommunicationActivity.dateFormatted = sCommunicationActivity.dateFormatted;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.heading)) {
        dCommunicationActivity.heading = sCommunicationActivity.heading; // need to be changed by caller
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.id)) {
        dCommunicationActivity.id = sCommunicationActivity.id;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.isOnline)) {
        dCommunicationActivity.isOnline = sCommunicationActivity.isOnline;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.isPic)) {
        dCommunicationActivity.isPic = sCommunicationActivity.isPic;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.isPremiumMember)) {
        dCommunicationActivity.isPremiumMember = sCommunicationActivity.isPremiumMember;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.image)) {
        dCommunicationActivity.image = sCommunicationActivity.image;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.index)) {
        dCommunicationActivity.index = sCommunicationActivity.index;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sCommunicationActivity.kvGlyph ) )
      {
        dCommunicationActivity.kvGlyph = sCommunicationActivity.kvGlyph;
      }
      dCommunicationActivity.latitude = sCommunicationActivity.latitude;
      dCommunicationActivity.longitude = sCommunicationActivity.longitude;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.link)) {
        dCommunicationActivity.link = sCommunicationActivity.link;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sCommunicationActivity.memberActivityTs ) )
      {
        dCommunicationActivity.memberActivityTs = sCommunicationActivity.memberActivityTs;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.membership)) {
        dCommunicationActivity.membership = sCommunicationActivity.membership;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.messageBody)) {
        dCommunicationActivity.messageBody = sCommunicationActivity.messageBody;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.pElemId)) {
        dCommunicationActivity.pElemId = sCommunicationActivity.pElemId;
      }
      //if (sCommunicationActivity.photoId > 0) {
      //  dCommunicationActivity.photoId = sCommunicationActivity.photoId;
      //}
      if ( sCommunicationActivity.photoBnId > 0 )
      {
        dCommunicationActivity.photoBnId = sCommunicationActivity.photoBnId;
      }
      if (sCommunicationActivity.replyToId > 0) {
        dCommunicationActivity.replyToId = sCommunicationActivity.replyToId;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.receiver)) {
        dCommunicationActivity.receiver = sCommunicationActivity.receiver;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.receiverProfileName)) {
        dCommunicationActivity.receiverProfileName = sCommunicationActivity.receiverProfileName;
      }
      if (sCommunicationActivity.receiverSitUserId > 0) {
        dCommunicationActivity.receiverSitUserId = sCommunicationActivity.receiverSitUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.receiverSitUserKey)) {
        dCommunicationActivity.receiverSitUserKey = sCommunicationActivity.receiverSitUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.sender)) {
        dCommunicationActivity.sender = sCommunicationActivity.sender;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.senderProfileName)) {
        dCommunicationActivity.senderProfileName = sCommunicationActivity.senderProfileName;
      }
      if (sCommunicationActivity.senderSitUserId > 0) {
        dCommunicationActivity.senderSitUserId = sCommunicationActivity.senderSitUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.senderSitUserKey)) {
        dCommunicationActivity.senderSitUserKey = sCommunicationActivity.senderSitUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.sentPic)) {
        dCommunicationActivity.sentPic = sCommunicationActivity.sentPic;
      }
      if (sCommunicationActivity.signedInUserId > 0) {
        dCommunicationActivity.signedInUserId = sCommunicationActivity.signedInUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.signedInUserKey)) {
        dCommunicationActivity.signedInUserKey = sCommunicationActivity.signedInUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.subject)) {
        dCommunicationActivity.subject = sCommunicationActivity.subject;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.timeLapsed)) {
        dCommunicationActivity.timeLapsed = sCommunicationActivity.timeLapsed;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sCommunicationActivity.unit)) {
        dCommunicationActivity.unit = sCommunicationActivity.unit;
      }
      // debugger;
    }
    return dCommunicationActivity;
  }

  // ---------------------------------------------------------------
  static copyDateAssayIfExists ( destDateAssay : DateAssay, sourceDateAssay : DateAssay ) : DateAssay
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destDateAssay ) )
    {
      return sourceDateAssay;
    }
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay ) )
    {
      return destDateAssay;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.csTicks ) )
    {
      destDateAssay.csTicks = sourceDateAssay.csTicks;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.jsTicks ) )
    {
      destDateAssay.csTicks = sourceDateAssay.jsTicks;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.csTicksNum ) && sourceDateAssay.csTicksNum > 0 )
    {
      destDateAssay.csTicksNum = sourceDateAssay.csTicksNum;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.csTicksNum ) && sourceDateAssay.jsTicksNum > 0 )
    {
      destDateAssay.jsTicksNum = sourceDateAssay.jsTicksNum;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.UTCoffset ) )
    {
      destDateAssay.UTCoffset = sourceDateAssay.UTCoffset;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.UTCoffsetT ) )
    {
      destDateAssay.UTCoffsetT = sourceDateAssay.UTCoffsetT;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.hasDST ) )
    {
      destDateAssay.hasDST = sourceDateAssay.hasDST;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.isBool ) )
    {
      destDateAssay.isBool = sourceDateAssay.isBool;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.date ) )
    {
      destDateAssay.date = sourceDateAssay.date;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.dateFormatted ) )
    {
      destDateAssay.dateFormatted = sourceDateAssay.dateFormatted;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.millss ) && sourceDateAssay.millss > 0 )
    {
      destDateAssay.millss = sourceDateAssay.millss;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.ss ) && sourceDateAssay.ss > 0 )
    {
      destDateAssay.ss = sourceDateAssay.ss;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.mm ) && sourceDateAssay.mm > 0 )
    {
      destDateAssay.mm = sourceDateAssay.mm;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.hh ) && sourceDateAssay.hh > 0 )
    {
      destDateAssay.hh = sourceDateAssay.hh;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.dd ) && sourceDateAssay.dd > 0 )
    {
      destDateAssay.dd = sourceDateAssay.dd;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.months ) && sourceDateAssay.months > 0 )
    {
      destDateAssay.months = sourceDateAssay.months;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDateAssay.yyyy ) && sourceDateAssay.yyyy > 0 )
    {
      destDateAssay.yyyy = sourceDateAssay.yyyy;
    }

    return destDateAssay;
  }
  // ---------------------------------------------------------------
  static copyHeartbeatIfExists ( dHeartbeat : any, sHeartbeat : Heartbeat ) : Heartbeat
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dHeartbeat ) )
    {
      dHeartbeat = new Heartbeat();
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat ) && typeof ( sHeartbeat ) == 'object' )
    {
      // debugger;
      if ( typeof ( dHeartbeat ) == 'object' )
      {
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.callerName ) )
        {
          dHeartbeat.callerName = sHeartbeat.callerName;
        }
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.city ) )
        {
          dHeartbeat.city = sHeartbeat.city;
        }
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.country ) )
        {
          dHeartbeat.country = sHeartbeat.country;
        }
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.date ) )
        {
          dHeartbeat.date = sHeartbeat.date;
        }
        if ( sHeartbeat.distance > 0 )
        {
          dHeartbeat.distance = sHeartbeat.distance;
        }
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.distanceStr ) )
        {
          dHeartbeat.distanceStr = sHeartbeat.distanceStr;
        }
        if ( sHeartbeat.heartbeatTime > 0 )
        {
          dHeartbeat.heartbeatTime = sHeartbeat.heartbeatTime;
        }
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.gender ) )
        {
          dHeartbeat.gender = sHeartbeat.gender;
        }
        if ( sHeartbeat.id > 0 )
        {
          dHeartbeat.id = sHeartbeat.id;
        }
        // NOTE: this is very important because we compare it with now to get offOnStatusEnum value;
        if ( sHeartbeat.prevHeartbeat > 0 )
        {
          dHeartbeat.prevHeartbeat = sHeartbeat.prevHeartbeat;
        }

        dHeartbeat.isOnline = sHeartbeat.isOnline;

        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.jsDateForCs ) )
        {
          dHeartbeat.jsDateForCs = sHeartbeat.jsDateForCs;
        }
        if ( sHeartbeat.latitude !== 0 )
        {
          dHeartbeat.latitude = sHeartbeat.latitude;
        }
        if ( sHeartbeat.longitude !== 0 )
        {
          dHeartbeat.longitude = sHeartbeat.longitude;
        }
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.neighborhood ) )
        {
          dHeartbeat.neighborhood = sHeartbeat.neighborhood;
        }
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sHeartbeat.regionCode ) )
        {
          dHeartbeat.regionCode = sHeartbeat.regionCode;
        }
        if ( sHeartbeat.sitUserId > 0 )
        {
          dHeartbeat.sitUserId = sHeartbeat.sitUserId;
        }
      }
      else
      {
        dHeartbeat = sHeartbeat.heartbeatTime;
      }
    }
    else if ( typeof ( dHeartbeat ) !== 'object' )
    {
      dHeartbeat = sHeartbeat;
      // debugger;
    }
    else
    {
      dHeartbeat.heartBeat = sHeartbeat;
      // debugger;
    }
    return dHeartbeat;
  }
  // ---------------------------------------------------------------
  static copyLoginSuccessIfExists ( dLoginSuccess: LoginSuccess, sLoginSuccess: LoginSuccess ): LoginSuccess
  {
    // debugger;
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dLoginSuccess ) )
    {
      dLoginSuccess = new LoginSuccess();
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sLoginSuccess ) )
    {
      dLoginSuccess.cryptUserKey = sLoginSuccess.cryptUserKey;
      dLoginSuccess.action = sLoginSuccess.primaryImageUrl;
      dLoginSuccess.blogId = sLoginSuccess.blogId;
      // debugger;
      dLoginSuccess.businessKey = sLoginSuccess.businessKey;
      // debugger;
      dLoginSuccess.code = sLoginSuccess.code;
      dLoginSuccess.controller = sLoginSuccess.controller;
      dLoginSuccess.email = sLoginSuccess.email;
      dLoginSuccess.gender = sLoginSuccess.gender;
      dLoginSuccess.hasJoinedSignalR = sLoginSuccess.hasJoinedSignalR;
      dLoginSuccess.heartbeat = sLoginSuccess.heartbeat;
      dLoginSuccess.id = sLoginSuccess.id;
      dLoginSuccess.imagePath = sLoginSuccess.imagePath;
      dLoginSuccess.isAuthorized = sLoginSuccess.isAuthorized;
      dLoginSuccess.isEditProfile = sLoginSuccess.isEditProfile;
      dLoginSuccess.isEmailConfirmed = sLoginSuccess.isEmailConfirmed;
      dLoginSuccess.isEmployee = sLoginSuccess.isEmployee;
      dLoginSuccess.isForgotPassword = sLoginSuccess.isForgotPassword;
      dLoginSuccess.isLogout = sLoginSuccess.isLogout;
      dLoginSuccess.isMgmt = sLoginSuccess.isMgmt;
      dLoginSuccess.isPic = sLoginSuccess.isPic;
      dLoginSuccess.isPreferenceComplete = sLoginSuccess.isPreferenceComplete;
      dLoginSuccess.isPremium = sLoginSuccess.isPremium;
      dLoginSuccess.isPreScreenComplete = sLoginSuccess.isPreScreenComplete;
      dLoginSuccess.isProfileComplete = sLoginSuccess.isProfileComplete;
      dLoginSuccess.isProfileContentComplete = sLoginSuccess.isProfileContentComplete;
      dLoginSuccess.isProfileInfoComplete = sLoginSuccess.isProfileInfoComplete;
      dLoginSuccess.isReviewer = sLoginSuccess.isReviewer;
      dLoginSuccess.isSuccess = sLoginSuccess.isSuccess;
      dLoginSuccess.isSuperUser = sLoginSuccess.isSuperUser;
      dLoginSuccess.isValued = sLoginSuccess.isValued;
      dLoginSuccess.iv = sLoginSuccess.iv;
      dLoginSuccess.key = sLoginSuccess.key;
      dLoginSuccess.latitude = sLoginSuccess.latitude;
      dLoginSuccess.likedArr = sLoginSuccess.likedArr;
      dLoginSuccess.longitude = sLoginSuccess.longitude;
      dLoginSuccess.membership = sLoginSuccess.membership;
      dLoginSuccess.message = sLoginSuccess.message;
      dLoginSuccess.myBlockedArr = sLoginSuccess.myBlockedArr;
      dLoginSuccess.myBuddyArr = sLoginSuccess.myBuddyArr;
      dLoginSuccess.myLikedArr = sLoginSuccess.myLikedArr;
      dLoginSuccess.myTrackedArr = sLoginSuccess.myTrackedArr;
      dLoginSuccess.myUnlockedArr = sLoginSuccess.myUnlockedArr;
      dLoginSuccess.primaryImage = sLoginSuccess.primaryImage;
      dLoginSuccess.primaryImageUrl = sLoginSuccess.primaryImageUrl;
      dLoginSuccess.primaryPhotoBnId = sLoginSuccess.primaryPhotoBnId;
      dLoginSuccess.profileName = sLoginSuccess.profileName;
      dLoginSuccess.returnUrl = sLoginSuccess.returnUrl;
      dLoginSuccess.sexuality = sLoginSuccess.sexuality;
      dLoginSuccess.signedInUserId = sLoginSuccess.signedInUserId;
      dLoginSuccess.signedInUserKey = sLoginSuccess.signedInUserKey;
      dLoginSuccess.trackedArr = sLoginSuccess.trackedArr;
      dLoginSuccess.unlockedArr = sLoginSuccess.unlockedArr;
    }

    return dLoginSuccess;
  }
  // ---------------------------------------------------------------
  static copyLoginSuccessToProfileTile (sLoginSuccess : LoginSuccess) : ProfileTile {
    // debugger;
    let dProfileTile = new ProfileTile();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sLoginSuccess)) {
      dProfileTile.cryptUserKey = sLoginSuccess.cryptUserKey;
      // debugger;
      dProfileTile.date = sLoginSuccess.date;
      dProfileTile.heartbeat = sLoginSuccess.heartbeat;
      dProfileTile.latitude = sLoginSuccess.latitude.toString();
      dProfileTile.longitude = sLoginSuccess.longitude.toString();

      dProfileTile.primaryImage = sLoginSuccess.primaryImage;
      dProfileTile.isPrimaryPhoto = true;
      dProfileTile.kvPhoto = this.copyLoginSuccessToKvPhoto(sLoginSuccess);
      dProfileTile.photoBnId = sLoginSuccess.primaryPhotoBnId;
      dProfileTile.profileName = sLoginSuccess.profileName;
      dProfileTile.sitUserId = sLoginSuccess.signedInUserId;
      dProfileTile.sitUserKey = sLoginSuccess.signedInUserKey;
      // debugger;
    }
    return dProfileTile;
  }
  // ---------------------------------------------------------------
  // Note:  This this method very carefully and sparingly.
  //        SitUser model is inteded to be mutually exclusive to LoginSuccess model
  // ---------------------------------------------------------------
  static copyLoginSuccessToSitUser ( sLoginSuccess : LoginSuccess) : SitUser {
    // debugger;
    let dSitUser = new SitUser();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sLoginSuccess)) {
      dSitUser.cryptUserKey = sLoginSuccess.cryptUserKey;
      // debugger;
      dSitUser.email = sLoginSuccess.email;
      dSitUser.heartbeat = sLoginSuccess.heartbeat;
      dSitUser.id = sLoginSuccess.id;
      dSitUser.isEmailConfirmed = sLoginSuccess.isEmailConfirmed;
      dSitUser.latitude = sLoginSuccess.latitude.toString();
      dSitUser.longitude = sLoginSuccess.longitude.toString();
      
      dSitUser.primaryImage = sLoginSuccess.primaryImage;
      dSitUser.primaryPhotoBnId = sLoginSuccess.primaryPhotoBnId;
      dSitUser.profileName = sLoginSuccess.profileName;
      dSitUser.sitUserId = sLoginSuccess.signedInUserId;
      dSitUser.sitUserKey = sLoginSuccess.signedInUserKey;
      dSitUser.userName = sLoginSuccess.email;
    }

    return dSitUser;
  }
  // ---------------------------------------------------------------
  static copyProfileTileToSitUser (tpTile : ProfileTile) : SitUser {
    // debugger;
    let dSitUser = new SitUser();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile)) {
      // debugger;
      dSitUser.heartbeat = tpTile.heartbeat;
      dSitUser.id = parseInt(tpTile.id);
      dSitUser.isEmailConfirmed = true;
      //dSitUser.latitude = StringServiceStatic.string2Number(tpTile.latitude);
      //dSitUser.longitude = StringServiceStatic.string2Number(tpTile.longitude);
      dSitUser.latitude = tpTile.latitude;
      dSitUser.longitude = tpTile.longitude;
      dSitUser.primaryImage = tpTile.primaryImage;
      dSitUser.primaryPhotoBnId = tpTile.photoBnId;
      dSitUser.profileName = tpTile.profileName;
      dSitUser.sitUserId = tpTile.sitUserId;
      dSitUser.sitUserKey = tpTile.sitUserKey;
      dSitUser.userName = tpTile.profileName;
    }

    return dSitUser;
  }
  // ---------------------------------------------------------------
  // Note:  This this method very carefully and sparingly.
  //        SitUser model is inteded to be mutually exclusive to LoginSuccess model
  // ---------------------------------------------------------------
  static copyLoginSuccessToKvPhoto (sLoginSuccess : LoginSuccess) : KvPhoto {
    // debugger;
    let kvp = new KvPhoto();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sLoginSuccess)) {
      kvp.cryptUserKey = sLoginSuccess.cryptUserKey;
      // debugger;
      kvp.isPrimary = true;
      kvp.isPrivate = false;
      kvp.key = "primary";
      kvp.value = kvp.image = sLoginSuccess.primaryImage;
      kvp.photoBnId = sLoginSuccess.primaryPhotoBnId;
      kvp.signedInUserId = kvp.sitUserId = sLoginSuccess.signedInUserId;
      kvp.sitUserKey = sLoginSuccess.signedInUserKey;
    }

    return kvp;
  }
  // ---------------------------------------------------------------
  static copySitUserToKvPhoto (sitUser : SitUser) : KvPhoto {
    let kvp = new KvPhoto();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)){
      kvp.cryptUserKey = sitUser.cryptUserKey;
      kvp.date = sitUser.date;
      // debugger;
      kvp.isPrimary = true;
      kvp.isPrivate = false;
      kvp.glyphName = "Primary";
      kvp.privacy = "Public";

      kvp.value = kvp.pic = kvp.image = sitUser.primaryImage;
      kvp.photoBnId = sitUser.primaryPhotoBnId;
      kvp.sitUserId = sitUser.sitUserId;
      kvp.sitUserKey = sitUser.sitUserKey;
    }

    return kvp;
  }
  // ---------------------------------------------------------------
  static copyKvPhotoToSitUser (kvp : KvPhoto) : SitUser {
    let sitUser = new SitUser();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      sitUser.cryptUserKey = kvp.cryptUserKey;
      sitUser.date = kvp.date;
      // debugger;
      sitUser.primaryImage = kvp.value;
      sitUser.primaryPhotoBnId = kvp.photoBnId;
      sitUser.sitUserId = kvp.sitUserId;
      sitUser.sitUserKey = kvp.sitUserKey;
    }

    return sitUser;
  }
  // ---------------------------------------------------------------
  static copyKvPhotoToProfilePics (kvp : KvPhoto) : ProfilePics {
    let pPics = new ProfilePics();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      pPics.cryptUserKey = kvp.cryptUserKey;
      pPics.date = kvp.date;
      pPics.entityName = kvp.entityName;
      // pPics.filteredKvPhotoArr = [];
      // pPics.unFilteredKvPhotoArr = [];
      pPics.id = kvp.id;
      pPics.sitUserId = kvp.sitUserId;
      pPics.sitUserKey = kvp.sitUserKey;
    }
    return pPics;
  }
  // ---------------------------------------------------------------
  static copyOrderIfExists ( destOrder : PayPalOrder, sourceOrder : PayPalOrder ) : PayPalOrder
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destOrder) )
    {
      destOrder = new PayPalOrder();
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceOrder ) )
    {
      destOrder.authorizedData = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceOrder.authorizedData ) ? sourceOrder.authorizedData : destOrder.authorizedData;

      destOrder.callerName = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.callerName) ? sourceOrder.callerName: destOrder.callerName;
      destOrder.create_time = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.create_time) ? sourceOrder.create_time : destOrder.create_time;
      destOrder.cryptUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceOrder.cryptUserKey ) ? sourceOrder.cryptUserKey : destOrder.cryptUserKey;
      destOrder.date = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceOrder.date ) ? sourceOrder.date : destOrder.date;
      destOrder.dateStr = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceOrder.dateStr ) ? sourceOrder.dateStr : destOrder.dateStr;
      destOrder.email = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.email) ? sourceOrder.email : destOrder.email;
      destOrder.fullName = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.fullName) ? sourceOrder.fullName: destOrder.fullName;
      destOrder.intent = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.intent) ? sourceOrder.intent : destOrder.intent;
      destOrder.ip = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.ip) ? sourceOrder.ip : destOrder.ip;
      destOrder.latitude = sourceOrder.latitude != 0 ? sourceOrder.latitude : destOrder.latitude;
      destOrder.longitude = sourceOrder.longitude != 0 ? sourceOrder.longitude : destOrder.longitude;
      destOrder.onApproved = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.onApproved) ? JSON.stringify(sourceOrder.onApproved) : destOrder.onApproved;
      destOrder.onApprovedDetails = JSON.stringify(sourceOrder.onApprovedDetails);
      destOrder.onCanceled = JSON.stringify(sourceOrder.onCanceled);
      destOrder.onClientAuthorization = sourceOrder.onClientAuthorization;
      destOrder.onError = sourceOrder.onError;
      destOrder.orderID = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.orderID) ? sourceOrder.orderID : destOrder.orderID;
      destOrder.orderNo = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.orderNo) ? sourceOrder.orderNo : destOrder.orderNo;
      destOrder.payPalCreditCardAddress.copy(sourceOrder.payPalCreditCardAddress);
      // destOrder.paypalPayee.copy( sourceOrder.paypalPayee );
      // TODO: for some reason, the copyFrom is not recognized as a function:( error
      //if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destOrder.paypalPayer ) )
      //{
      //  destOrder.paypalPayer.copyFrom( sourceOrder.paypalPayer );
      //}
      destOrder.paypalPayer.copyFrom( sourceOrder.paypalPayer );
      destOrder.paypalPurchaseUnits.copy( sourceOrder.paypalPurchaseUnits );
      // destOrder.paypalShipping.copy( sourceOrder.paypalShipping );
      destOrder.paypalPayment.copy( sourceOrder.paypalPayment );
      destOrder.paymentOption = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.paymentOption) ? sourceOrder.paymentOption : destOrder.paymentOption;
      destOrder.paymentOptionAmount = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.paymentOptionAmount) ? sourceOrder.paymentOptionAmount : destOrder.paymentOptionAmount;
      destOrder.personKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.personKey) ? sourceOrder.personKey : destOrder.personKey;
      destOrder.sitUserId = sourceOrder.sitUserId > 0 ? sourceOrder.sitUserId : destOrder.sitUserId;
      destOrder.sitUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.sitUserKey) ? sourceOrder.sitUserKey : destOrder.sitUserKey;
      destOrder.status = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.status) ? sourceOrder.status : destOrder.status;
      destOrder.subscriptionMonths = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.subscriptionMonths) ? sourceOrder.subscriptionMonths : destOrder.subscriptionMonths;
      destOrder.subtotal = sourceOrder.subtotal > 0 ? sourceOrder.subtotal : destOrder.subtotal;
      destOrder.tax = sourceOrder.tax > 0 ? sourceOrder.tax : destOrder.tax;
      destOrder.total = sourceOrder.total > 0 ? sourceOrder.total : destOrder.total;
      destOrder.totalStr = sourceOrder.totalStr;
      destOrder.upgrade.copy( sourceOrder.upgrade );
      destOrder.userName = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOrder.userName) ? sourceOrder.userName : destOrder.userName;
    }

    return destOrder;
  }
  // ---------------------------------------------------------------
  static copyProfileInfoIfExists ( profileInfo : ProfileInfo, inPInfo : ProfileInfo ) : ProfileInfo
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo ) )
    {
      // debugger;
      if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileInfo ) )
      {
        profileInfo = new ProfileInfo();
      }
      if ( inPInfo.age > 0 )
      {
        profileInfo.age = inPInfo.age;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.away ) )
      {
        profileInfo.away = inPInfo.away;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.aBstract ) )
      {
        profileInfo.aBstract = inPInfo.aBstract;
      }
      if ( inPInfo.contentId > 0 )
      {
        profileInfo.contentId = inPInfo.contentId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.city ) )
      {
        profileInfo.city = inPInfo.city;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.contents ) )
      {
        profileInfo.contents = inPInfo.contents;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.countryCode ) )
      {
        profileInfo.countryCode = inPInfo.countryCode;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.cutUncut ) )
      {
        profileInfo.cutUncut = inPInfo.cutUncut;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.date ) )
      {
        profileInfo.date = inPInfo.date;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.dickSize ) )
      {
        profileInfo.dickSize = inPInfo.dickSize;
      }
      if ( inPInfo.distance > 0 )
      {
        profileInfo.distance = inPInfo.distance;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.dob ) )
      {
        profileInfo.dob = inPInfo.dob;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.email ) )
      {
        profileInfo.email = inPInfo.email;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.gender ) )
      {
        profileInfo.gender = inPInfo.gender;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.heading ) )
      {
        profileInfo.heading = inPInfo.heading;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.heartbeatTime ) )
      {
        profileInfo.heartbeatTime = inPInfo.heartbeatTime;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.highestEducation ) )
      {
        profileInfo.highestEducation = inPInfo.highestEducation;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.image ) )
      {
        profileInfo.image = inPInfo.image;
      }
      profileInfo.isOnline = inPInfo.isOnline;
      profileInfo.isProfileComplete = inPInfo.isProfileComplete;
      profileInfo.isOnline = inPInfo.isOnline;
      profileInfo.isServer = inPInfo.isServer;
      profileInfo.isSuccess = inPInfo.isSuccess;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.link ) )
      {
        profileInfo.link = inPInfo.link;
      }
      if ( inPInfo.latitude.length !== 1 )
      {
        profileInfo.latitude = inPInfo.latitude;
      }
      if ( inPInfo.longitude.length !== 1 )
      {
        profileInfo.longitude = inPInfo.longitude;
      }
      if ( inPInfo.memberId > 0 )
      {
        profileInfo.memberId = inPInfo.memberId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.message ) )
      {
        profileInfo.message = inPInfo.message;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.name ) )
      {
        profileInfo.name = inPInfo.name;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.neighborhood ) )
      {
        profileInfo.neighborhood = inPInfo.neighborhood;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.pagedProfilePics ) && inPInfo.pagedProfilePics.length > 0 )
      {
        profileInfo.pagedProfilePics = inPInfo.pagedProfilePics;
      }
      if ( inPInfo.photoId > 0 )
      {
        profileInfo.photoId = inPInfo.photoId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.position ) )
      {
        profileInfo.position = inPInfo.position;
      }
      if ( inPInfo.profileId > 0 )
      {
        profileInfo.profileId = inPInfo.profileId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.profileName ) && inPInfo.profileName.length > 0 )
      {
        profileInfo.profileName = inPInfo.profileName;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.profilePics ) )
      {
        profileInfo.profilePics = this.copyProfilePicsIfExists( profileInfo.profilePics, inPInfo.profilePics );
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.pagedProfilePics ) )
      {
        profileInfo.pagedProfilePics = inPInfo.pagedProfilePics;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.regionCode ) )
      {
        profileInfo.regionCode = inPInfo.regionCode;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.relationshipStatus ) )
      {
        profileInfo.relationshipStatus = inPInfo.relationshipStatus;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.religion ) )
      {
        profileInfo.religion = inPInfo.religion;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.sexuality ) )
      {
        profileInfo.sexuality = inPInfo.sexuality;
      }
      if ( inPInfo.sitUserId > 0 )
      {
        profileInfo.sitUserId = inPInfo.sitUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.sitUserKey ) )
      {
        profileInfo.sitUserKey = inPInfo.sitUserKey;
      }
      if ( inPInfo.signedInUserId > 0 )
      {
        profileInfo.signedInUserId = inPInfo.signedInUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.signedInUserKey ) )
      {
        profileInfo.signedInUserKey = inPInfo.signedInUserKey;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.smokingStatus ) )
      {
        profileInfo.smokingStatus = inPInfo.smokingStatus;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inPInfo.unit ) )
      {
        profileInfo.unit = inPInfo.unit;
      } else
      {
        profileInfo.unit = 'm';
      }
    }
    // debugger;
    return profileInfo;
  }
  // --------------------------------------------------------------
  static copyOffOnIfExists (destOffOn : OffOn, sourceOffOn : OffOn) : OffOn {

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destOffOn)) {
      destOffOn = new OffOn();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn)) {
      destOffOn.heartbeat = this.copyHeartbeatIfExists(destOffOn.heartbeat, sourceOffOn.heartbeat);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.away)
        && sourceOffOn.away.length > 0) {
        destOffOn.away = sourceOffOn.away;
      }
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.distance) && sourceOffOn.distance > 0) {
      //  destOffOn.distance = sourceOffOn.distance;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.distanceStr) && sourceOffOn.distanceStr.length > 0) {
      //  destOffOn.distanceStr = sourceOffOn.distanceStr;
      //}
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnArr) && sourceOffOn.offOnArr.length > 0) {
        destOffOn.offOnArr = sourceOffOn.offOnArr;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnId) && sourceOffOn.offOnId.length > 0) {
        destOffOn.offOnId = sourceOffOn.offOnId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnElem)) {
        destOffOn.offOnElem = sourceOffOn.offOnElem;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnAwayElem)) {
        destOffOn.offOnAwayElem = sourceOffOn.offOnAwayElem;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnRadioElem)) {
        destOffOn.offOnRadioElem = sourceOffOn.offOnRadioElem;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnStatusEnum) && sourceOffOn.offOnStatusEnum > 0) {
        destOffOn.offOnStatusEnum = sourceOffOn.offOnStatusEnum;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnStatusColor) && sourceOffOn.offOnStatusColor.length > 0) {
        destOffOn.offOnStatusColor = sourceOffOn.offOnStatusColor;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnStatusColorTimer) && sourceOffOn.offOnStatusColorTimer.toString().length > 0) {
        destOffOn.offOnStatusColorTimer = sourceOffOn.offOnStatusColorTimer;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.selectedOffOnIndex) && sourceOffOn.selectedOffOnIndex > -1) {
        destOffOn.selectedOffOnIndex = sourceOffOn.selectedOffOnIndex;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.lastUpdatedOn) && sourceOffOn.lastUpdatedOn > 0) {
        destOffOn.lastUpdatedOn = sourceOffOn.lastUpdatedOn;
      }
    }
    return destOffOn;
  }
  // --------------------------------------------------------------
  static copyDistanceUnitIfExists (destUnit : Unit, sourceUnit : Unit) : Unit {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destUnit) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceUnit)) {
      if (sourceUnit.distance >= 0) {
        destUnit.distance = sourceUnit.distance;
      }
      destUnit.unit = sourceUnit.unit.length > 0 ? sourceUnit.unit : 'meter';
      destUnit.distanceStr = sourceUnit.distanceStr.length > 0 ? sourceUnit.distanceStr : '';
      destUnit.date = sourceUnit.date;
      destUnit.id = sourceUnit.id;
    }
    return destUnit;
  }
  // --------------------------------------------------------------
  static copyProfileTileIfExists ( destTile : ProfileTile, sourceTile : ProfileTile ) : ProfileTile
  {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destTile)) {
      destTile = new ProfileTile();
		}
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destTile ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile ) )
    {
      if ( sourceTile.age > 0 )
      {
        destTile.age = sourceTile.age;
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.offOn)) {
        destTile.offOn = this.copyOffOnIfExists(destTile.offOn, sourceTile.offOn);
      }
      
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distanceUnit)) {
        destTile.distanceUnit = this.copyDistanceUnitIfExists(destTile.distanceUnit, sourceTile.distanceUnit);
      }

      if ( sourceTile.beginIndex && sourceTile.beginIndex > 0 )
      {
        destTile.beginIndex = sourceTile.beginIndex;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.city ) && sourceTile.city.length > 0 )
      {
        destTile.city = sourceTile.city;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.countryCode ) && sourceTile.countryCode.length > 0 )
      {
        destTile.countryCode = sourceTile.countryCode;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.date ) && sourceTile.date.length > 0 )
      {
        destTile.date = sourceTile.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distance)) {
        destTile.distance = sourceTile.distance;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.distanceStr)) {
        destTile.distanceStr = sourceTile.distanceStr;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.dob ) && sourceTile.dob != null )
      {
        destTile.dob = sourceTile.dob;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.gender ) && sourceTile.gender.length > 0 )
      {
        destTile.gender = sourceTile.gender;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.heading ) && sourceTile.heading.length > 0 )
      {
        destTile.heading = sourceTile.heading;
      }
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.heartbeat.prevHeartbeat) && sourceTile.heartbeat.prevHeartbeat > 0 )
      //{
      //  destTile.heartbeat.prevHeartbeat = sourceTile.heartbeat.prevHeartbeat;
      //}
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.heartbeat ) && sourceTile.heartbeat.heartbeatTime > 0 )
      {
        destTile.heartbeat = this.copyHeartbeatIfExists(destTile.heartbeat, sourceTile.heartbeat);
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.id ) && sourceTile.id.length > 0 )
      {
        destTile.id = sourceTile.id;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.primaryImage) && sourceTile.primaryImage.length > 0 )
      {
        destTile.primaryImage = sourceTile.primaryImage;
      }
      if ( sourceTile.index > -1 )
      {
        destTile.index = sourceTile.index;
      }
      if ( sourceTile.isImageRendered )
      {
        destTile.isImageRendered = sourceTile.isImageRendered.toString().toLowerCase().indexOf( 'true' ) !== -1;
      }
      if ( sourceTile.isOnline )
      {
        destTile.isOnline = sourceTile.isOnline;// sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;
      }
      if ( sourceTile.isPremiumMember )
      {
        destTile.isPremiumMember = sourceTile.isPremiumMember.toString().toLowerCase().indexOf( 'true' ) !== -1;
      }
      if ( sourceTile.isTileSetupComplete )
      {
        destTile.isTileSetupComplete = sourceTile.isTileSetupComplete.toString().toLowerCase().indexOf( 'true' ) !== -1;
      }

      if (sourceTile.kvPhoto) {
        destTile.kvPhoto = new KvPhoto().copy(sourceTile?.kvPhoto);
      }

      if ( sourceTile.latitude != null && sourceTile.latitude.length !== 1 )
      {
        destTile.latitude = sourceTile.latitude;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.link ) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destTile.link ) )
      {
        destTile.link = sourceTile.link;
      } else
      {
        destTile.link = 'view/';
      }

      if ( sourceTile.longitude != null && sourceTile.longitude.length !== 1 )
      {
        destTile.longitude = sourceTile.longitude;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.neighborhood ) && sourceTile.neighborhood.length > 0 )
      {
        destTile.neighborhood = sourceTile.neighborhood;
      }
      
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.pElemId ) && sourceTile.pElemId.length > 0 )
      {
        destTile.pElemId = sourceTile.pElemId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.position ) && sourceTile.position.length > 0 && destTile.position != null )
      {
        destTile.position = sourceTile.position;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.profileId ) && sourceTile.profileId > 0 )
      {
        destTile.profileId = sourceTile.profileId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.profileName ) && sourceTile.profileName.length > 0 )
      {
        destTile.profileName = sourceTile.profileName;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.regionCode ) && sourceTile.regionCode.length > 0 )
      {
        destTile.regionCode = sourceTile.regionCode;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.sexuality ) && sourceTile.sexuality.length > 0 )
      {
        destTile.sexuality = sourceTile.sexuality;
      }
      
      if ( sourceTile.sitUserId != null && sourceTile.sitUserId > 0 )
      {
        destTile.sitUserId = sourceTile.sitUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.signedInUserId ) && sourceTile.signedInUserId > 0 )
      {
        destTile.signedInUserId = sourceTile.signedInUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.sitUserKey ) )
      {
        destTile.sitUserKey = sourceTile.sitUserKey;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.signedInUserId ) && sourceTile.signedInUserId > 0 )
      {
        destTile.signedInUserId = sourceTile.signedInUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.signedInUserKey ) )
      {
        destTile.signedInUserKey = sourceTile.signedInUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.unit)) {
        destTile.unit = sourceTile.unit;
      }
    }
    return destTile;
  }
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------
  static copyKvPhotoIfExists ( dKvPhoto : KvPhoto, sKvPhoto : KvPhoto ) : KvPhoto
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dKvPhoto ) )
    {
      dKvPhoto = new KvPhoto();
    }
    //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sKvPhoto)) {
    //  // debugger;
    //  dKvPhoto.copy(sKvPhoto);
    //  // debugger;
    //}
    //

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.cryptUserKey ) )
    {
      dKvPhoto.cryptUserKey = sKvPhoto.cryptUserKey; // need to be changed by caller
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.date ) )
    {
      dKvPhoto.date = sKvPhoto.date;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.description ) )
    {
      dKvPhoto.description = sKvPhoto.description;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.elemId ) )
    {
      dKvPhoto.elemId = sKvPhoto.elemId;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.entityName ) )
    {
      dKvPhoto.entityName = sKvPhoto.entityName;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.glyphName ) )
    {
      dKvPhoto.glyphName = sKvPhoto.glyphName;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.image ) )
    {
      dKvPhoto.image = sKvPhoto.image;
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sKvPhoto.imageBytes)) {
      dKvPhoto.imageBytes = sKvPhoto.imageBytes;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.index ) )
    {
      dKvPhoto.index = sKvPhoto.index;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.isPrimary ) )
    {
      dKvPhoto.isPrimary = sKvPhoto.isPrimary;
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sKvPhoto.isPrivate)) {
      dKvPhoto.isPrivate = sKvPhoto.isPrivate;
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sKvPhoto.isUnlocked)) {
      dKvPhoto.isUnlocked = sKvPhoto.isUnlocked;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.itemNum ) )
    {
      dKvPhoto.itemNum = sKvPhoto.itemNum;
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sKvPhoto.key)) {
      dKvPhoto.key = sKvPhoto.key;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.privacy ) )
    {
      dKvPhoto.privacy = sKvPhoto.privacy;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.photoId ) )
    {
      dKvPhoto.photoId = sKvPhoto.photoId;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.photoBnId ) )
    {
      dKvPhoto.photoBnId = sKvPhoto.photoBnId;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.profileId ) )
    {
      dKvPhoto.profileId = sKvPhoto.profileId;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.returnUrl ) )
    {
      dKvPhoto.returnUrl = sKvPhoto.returnUrl;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.sitUserId ) )
    {
      dKvPhoto.sitUserId = sKvPhoto.sitUserId;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.sitUserKey ) )
    {
      dKvPhoto.sitUserKey = sKvPhoto.sitUserKey;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.file ) )
    {
      dKvPhoto.file = sKvPhoto.file;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.title ) )
    {
      dKvPhoto.title = sKvPhoto.title;
    }      
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sKvPhoto.value ) )
    {
      dKvPhoto.value = sKvPhoto.value;
    }
    debugger;
    return dKvPhoto;
  }
  // ---------------------------------------------------------------
  static copyProfileImageIfExists ( dpImage : ProfileImage, spImage : ProfileImage ) : ProfileImage
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dpImage ) )
    {
      dpImage = new ProfileImage();
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage ) )
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.cryptUserKey ) )
      {
        dpImage.cryptUserKey = spImage.cryptUserKey;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.date ) )
      {
        dpImage.date = spImage.date;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.entityName ) )
      {
        dpImage.entityName = spImage.entityName;
      }

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.imageBytes ) )
      {
        dpImage.imageBytes = spImage.imageBytes;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.imageFile ) )
      {
        dpImage.imageFile = spImage.imageFile;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.imageFromBytes ) )
      {
        dpImage.imageFromBytes = spImage.imageFromBytes;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.imageFile ) )
      {
        dpImage.imageFile = spImage.imageFile;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.isPrimary ) )
      {
        dpImage.isPrimary = spImage.isPrimary;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.privacy ) )
      {
        dpImage.privacy = spImage.privacy;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.photoId ) )
      {
        dpImage.photoId = spImage.photoId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.photoBnId ) )
      {
        dpImage.photoBnId = spImage.photoBnId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.profileId ) )
      {
        dpImage.profileId = spImage.profileId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.sitUserId ) )
      {
        dpImage.sitUserId = spImage.sitUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.sitUserKey ) )
      {
        dpImage.sitUserKey = spImage.sitUserKey;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spImage.type ) )
      {
        dpImage.type = spImage.type;
      }
      // debugger;
    }
    return dpImage;
  }

  // ---------------------------------------------------------------
  static copyProfilePicsIfExists ( dpPics : ProfilePics, spPics : ProfilePics ) : ProfilePics
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics ) )
    {
      return dpPics;
    }
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dpPics ) )
    {
      return spPics;
    }
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.arrKv ) )
    {
      dpPics.arrKv = ArraySupportServiceStatic.mergeArraysUniq(dpPics.arrKv, spPics.arrKv);
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.arrKvAny ) )
    {
      dpPics.arrKvAny = ArraySupportServiceStatic.mergeArraysUniq(dpPics.arrKvAny, spPics.arrKvAny);
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.cryptUserKey ) )
    {
      dpPics.cryptUserKey = spPics.cryptUserKey;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.date ) )
    {
      dpPics.date = spPics.date;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.entityName ) )
    {
      dpPics.entityName = spPics.entityName;
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spPics.filteredKvPhotoArr)) {
      // uniquely merge
      dpPics.filteredKvPhotoArr = ArraySupportServiceStatic.mergeArraysUniq(dpPics.filteredKvPhotoArr, spPics.filteredKvPhotoArr);
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.id ) && spPics.id > 0 )
    {
      dpPics.id = spPics.id;
    }

    dpPics.isPhotoUploadSuccessful = spPics.isPhotoUploadSuccessful;    

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.images ) )
    {
      // uniquely merge
      dpPics.images = ArraySupportServiceStatic.mergeArraysUniq(dpPics.images, spPics.images);
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.model ) )
    {
      dpPics.model = spPics.model;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.pagedProfilePics ) )
    {
      dpPics.pagedProfilePics = ArraySupportServiceStatic.mergeArraysUniq(dpPics.pagedProfilePics, spPics.pagedProfilePics);
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.pagedGlyphArr ) )
    {
      dpPics.pagedGlyphArr = ArraySupportServiceStatic.mergeArraysUniq(dpPics.pagedGlyphArr, spPics.pagedGlyphArr);
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.pagedKvPhotoArr ) )
    {
      dpPics.pagedKvPhotoArr = ArraySupportServiceStatic.mergeArraysUniq(dpPics.pagedKvPhotoArr, spPics.pagedKvPhotoArr);
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.profileImageArr ) && spPics.profileImageArr.length > 0 )
    {
      dpPics.profileImageArr = ArraySupportServiceStatic.mergeArraysUniq(dpPics.profileImageArr, spPics.profileImageArr);
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.profileGlyphArr ) )
    {
      dpPics.profileGlyphArr = ArraySupportServiceStatic.mergeArraysUniq(dpPics.profileGlyphArr, spPics.profileGlyphArr);
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.profileKvPhotoArr ) )
    {
      // ArrayServiceStatic.mergeArrays(dpPics.profileKvPhotoArr , spPics.profileKvPhotoArr );
      // dpPics.profileKvPhotoArr = ArrayServiceStatic.removeDuplicates(dpPics.profileKvPhotoArr);
      dpPics.profileKvPhotoArr = ArraySupportServiceStatic.mergeArraysUniq(dpPics.profileKvPhotoArr, spPics.profileKvPhotoArr);
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spPics.profilePhotoDictionary)) {
      let values = spPics.profilePhotoDictionary.size > 0 ? spPics.profilePhotoDictionary.values() : [];
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(values) && values.length > 0) {
        values.forEach(e => {
          dpPics.profilePhotoDictionary.set(e.photoId, e);
          let dictionaryPhoto = dpPics.profilePhotoDictionary.get(e.photoId) as Photo;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryPhoto)) {
            dictionaryPhoto = CopyServiceStatic.copyFromServerPhotoToClientPhoto(dictionaryPhoto, e);
            dpPics.profilePhotoDictionary.remove(e.photoId);
            dpPics.profilePhotoDictionary.set(dictionaryPhoto.photoId, dictionaryPhoto);
          }
          else {
            dpPics.profilePhotoDictionary.set(e.photoId, e);
          }
        })
      }
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.profileId ) && spPics.profileId > 0 )
    {
      dpPics.profileId = spPics.profileId;
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.sitUserId ) && spPics.sitUserId > 0 )
    {
      dpPics.sitUserId = spPics.sitUserId;
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.sitUserKey ) )
    {
      dpPics.sitUserKey = spPics.sitUserKey;
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spPics.unFilteredKvPhotoArr)) {
      // uniquely merge
      // Note: tested on 20220411 and works!
      // debugger;
      dpPics.unFilteredKvPhotoArr = ArraySupportServiceStatic.mergeArraysUniq (dpPics.unFilteredKvPhotoArr, spPics.unFilteredKvPhotoArr);
      // debugger;
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spPics.unFilteredKvPhotoDictionary)) {
      let values = spPics.unFilteredKvPhotoDictionary.size > 0 ? spPics.unFilteredKvPhotoDictionary.values() : [];
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(values) && values.length > 0) {
        values.forEach(e => {
          dpPics.unFilteredKvPhotoDictionary.set(e.photoBnId, e);
          let dictionaryKvPhoto = dpPics.unFilteredKvPhotoDictionary.get(e.photoBnId) as KvPhoto;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryKvPhoto)) {
            dictionaryKvPhoto = CopyServiceStatic.copyKvPhotoIfExists(dictionaryKvPhoto, e);
            dpPics.unFilteredKvPhotoDictionary.remove(e.photoBnId);
            dpPics.unFilteredKvPhotoDictionary.set(dictionaryKvPhoto.photoBnId, dictionaryKvPhoto);
          }
          else {
            dpPics.unFilteredKvPhotoDictionary.set(e.photoBnId, e);
          }
        })
      }
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( spPics.unsalted ) )
    {
      dpPics.unsalted = spPics.unsalted;
    }

    // debugger;
    return dpPics;
  }
  // --------------------------------------------------------------
  static copyUpgradedTileIfExists ( destTile : UpgradedTile, sourceTile : UpgradedTile ) : UpgradedTile
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destTile ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile ) )
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.date ) && sourceTile.date.length > 0 )
      {
        destTile.date = sourceTile.date;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.dateStr ) && sourceTile.dateStr.length > 0 )
      {
        destTile.dateStr = sourceTile.dateStr;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.expDate ) && sourceTile.expDate.length > 0 )
      {
        destTile.expDate = sourceTile.expDate;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.expDateStr ) && sourceTile.expDateStr.length > 0 )
      {
        destTile.expDateStr = sourceTile.expDateStr;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.membership ) )
      {
        destTile.membership = sourceTile.membership;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.message ) && sourceTile.message.length > 0 )
      {
        destTile.message = sourceTile.message;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.promoCode ) && sourceTile.promoCode.length > 0 )
      {
        destTile.promoCode = sourceTile.promoCode;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.signedInUserId ) && sourceTile.signedInUserId > 0 )
      {
        destTile.signedInUserId = sourceTile.signedInUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.sitUserKey ) )
      {
        destTile.sitUserKey = sourceTile.sitUserKey;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.sitUserKeyBackup ) && sourceTile.sitUserKeyBackup.length > 0 )
      {
        destTile.sitUserKeyBackup = sourceTile.sitUserKeyBackup;
      }
    }
    return destTile;
  }
 
  // ===============================================================
  //to.approval = that.Approval?.length > 0 ? that.Approval : '';
  //to.contentType = that.ContentType;
  //to.cryptUserKey = that.CryptUserKey;
  //to.date = that.Date;
  //to.description = that.Description;
  //to.file = that.File; // TODO: warning for possible missing proterty
  //// to.formData = that IFormFile;
  //to.id = that.Id;
  //to.image = that.Image;
  //to.imageBytes = that.ImageBytes;
  //to.imageString = that.ImageString;
  //to.isPrimary = that.IsPrimary ? that.IsPrimary : false;
  //to.latitude = that.Latitude;
  //to.longitude = that.Longitude;
  //to.location = that.Location;
  //to.path = that.Path;
  //to.pageNo = that.pageNo > 0 ? that.pageNo : -1;
  //to.pic = that.pic; // Note: thisis the only lower case property on the server/back-end
  //to.photoId = that.PhotoId;
  //to.photoBnId = that.PhotoBnId;
  //to.privacy = that.Privacy?.length > 0 ? that.Privacy : '';
  //to.profileId = that.ProfileId;
  //to.sitUserId = that.SITUserId;
  //to.sitUserKey = that.SITUserKey;
  //// debugger;
  //to.signedInUserId = that.SignedInUserId;
  //to.sourceId = that.SourceId;
  //to.sourceType = that.SourceType;
  //to.title = that.Title;
  //to.url = that.Url;
  //    return to;
  // ---------------------------------------------------------------
  static copyFromContentToProfileInfo ( content: any ) : ProfileInfo
  {
    const profileInfo = new ProfileInfo();

    if ( content )
    {
      profileInfo.aBstract = content.aBstract;
      profileInfo.contentId = content.contentId;
      profileInfo.contents = content.contents;
      profileInfo.heading = content.heading;
      if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileInfo.date ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( content.date ) && content.date.length > 0 )
      {
        profileInfo.date = content.date;
      }
      if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileInfo.profileId ) || ( profileInfo.profileId === 0 && content.profileId > 0 ) )
      {
        profileInfo.profileId = content.profileId;
      }
      if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileInfo.sitUserId ) || ( profileInfo.sitUserId === 0 && content.sitUserId > 0 ) )
      {
        profileInfo.sitUserId = content.sitUserId;
      }
    }
    return profileInfo;
  }

  // ---------------------------------------------------------------
  static copyFromProfileInfoToContent ( profileInfo : ProfileInfo ) : any
  {
    const content = new Content();

    if (profileInfo) {
      content.aBstract = profileInfo.aBstract;
      content.contentId = profileInfo.contentId;
      content.contents = profileInfo.contents;
      content.heading = profileInfo.heading;
      content.date = profileInfo.date;
      content.profileId = profileInfo.profileId;
      content.sitUserId = profileInfo.sitUserId;
      content.sitUserKey = profileInfo.sitUserKey;
      content.signedInUserId = profileInfo.signedInUserId;
      content.signedInUserKey = profileInfo.signedInUserKey;


      // if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( content.date ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileInfo.date ) && profileInfo.date.length > 0 )
      // {
      //  content.date = profileInfo.date;
      // }
      // if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( content.profileId ) && profileInfo.profileId > 0 )
      // {
      //  content.profileId = profileInfo.profileId;
      // }
      // if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( content.sitUserId ) && profileInfo.sitUserId > 0 )
      // {
      //  content.sitUserId = profileInfo.sitUserId;
      // }
      // if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(content.sitUserKey) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfo.sitUserKey) && profileInfo.sitUserKey.length > 0) {
      //  content.sitUserKey = profileInfo.sitUserKey;
      // }
      // if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(content.signedInUserId) && profileInfo.signedInUserId > 0) {
      //  content.signedInUserId = profileInfo.signedInUserId;
      // }
      // if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(content.signedInUserKey) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfo.signedInUserKey) && profileInfo.signedInUserKey.length > 0) {
      //  content.signedInUserKey = profileInfo.signedInUserKey;
      // }
      return content;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  static copyFromCommunicationActivityToENoteTile ( commAct : CommunicationActivity ) : ENoteTile
  {
    // debugger; //In copyServcie
    const eTile = new ENoteTile();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct ) )
    {
      // debugger;
      // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.boxName ) )
      // {
      //  eTile.boxName = commMsg.boxName;
      // }
      // if ( commMsg.commActivityId > 0 )
      // {
      //  eTile.commActivityId = commMsg.commActivityId;
      // }
      // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commMsg.communicationType ))
      // {
      //  eTile.communicationType = commMsg.communicationSubType;
      // }
      // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.communicationSubType ) )
      // {
      //  eTile.communicationSubType = commMsg.communicationSubType;
      // }

      if (commAct.communicationMessageId > 0 )
      {
        eTile.communicationMessageId = commAct.communicationMessageId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.date ) )
      {
        eTile.date = commAct.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.dateFormatted ) )
      {
        eTile.dateFormatted = commAct.dateFormatted;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.isOnline ) )
      {
        eTile.isOnline = commAct.isOnline;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.heading ) )
      {
        eTile.heading = commAct.heading;
      }
      /*
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commMsg.isPrimaryMember)) {
       * eTile.isPrimaryMember = commMsg.isPrimaryMember;
       * }
       */
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.sentPic ) )
      {
        eTile.image = commAct.sentPic;
      }
      if (commAct.index >= 0 )
      {
        eTile.index = commAct.index;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.isOnline ) )
      {
        eTile.isOnline = commAct.isOnline;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.isPremiumMember ) )
      {
        eTile.isPremiumMember = commAct.isPremiumMember;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.isPic ) )
      {
        eTile.isPic = commAct.isPic;
      }
      if (commAct.latitude !== 0 )
      {
        eTile.latitude = commAct.latitude;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.link ) )
      {
        eTile.link = commAct.link;
      }
      if (commAct.longitude !== 0 )
      {
        eTile.longitude = commAct.longitude;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.messageBody ) )
      {
        eTile.neighborhood = commAct.messageBody;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.pElemId ) )
      {
        eTile.pElemId = commAct.pElemId;
      }
      //if (commAct.photoId > 0 )
      //{
      //  eTile.photoId = commAct.photoId;
      //}
      if (commAct.replyToId > 0 )
      {
        eTile.replyId = commAct.replyToId;
      }
      /*
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commMsg.receiverInfo)) {
       * eTile.receiverSitUserId = commMsg.receiverInfo.receiverSitUserId;
       * }
       */
      if (commAct.sender.sitUserId > 0 )
      {
        eTile.senderSitUserId = commAct.sender.sitUserId;
      }
      if (commAct.signedInUserId > 0 )
      {
        eTile.signedInUserId = commAct.signedInUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.unit ) )
      {
        eTile.unit = commAct.unit;
      }
    }

    return ( this.eNoteTile = eTile );
  }

  // ---------------------------------------------------------------
  static copyFromCommunicationActivityToProfileTile ( commMsg : CommunicationActivity ) : ProfileTile
  {
    // debugger; //In copyServcie
    const pTile = new ProfileTile();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg ) )
    {
      // debugger;
      // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.boxName ) )
      // {
      //  eTile.boxName = commMsg.boxName;
      // }
      // if ( commMsg.commActivityId > 0 )
      // {
      //  eTile.commActivityId = commMsg.commActivityId;
      // }
      // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commMsg.communicationType ))
      // {
      //  eTile.communicationType = commMsg.communicationSubType;
      // }
      // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.communicationSubType ) )
      // {
      //  eTile.communicationSubType = commMsg.communicationSubType;
      // }

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.date ) )
      {
        pTile.date = commMsg.date;
        pTile.heartbeat.date = commMsg.date;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.isOnline ) )
      {
        pTile.isOnline = commMsg.isOnline;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.heading ) )
      {
        pTile.heading = commMsg.heading;
      }

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.latitude ) )
      {
        pTile.heartbeat.latitude = commMsg.latitude;
        pTile.latitude = commMsg.latitude.toString();
      
        // pTile.latitude = JSON.stringify(commMsg.latitude);
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.longitude ) )
      {
        pTile.heartbeat.longitude = commMsg.longitude;
        pTile.longitude = commMsg.longitude.toString();
      }

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.sentPic ) )
      {
        pTile.primaryImage = commMsg.sentPic;
      }
      if ( commMsg.index >= 0 )
      {
        pTile.index = commMsg.index;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.isOnline ) )
      {
        pTile.isOnline = commMsg.isOnline;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.isPremiumMember ) )
      {
        pTile.isPremiumMember = commMsg.isPremiumMember;
      }

      if ( commMsg.latitude !== 0 )
      {
        pTile.latitude = commMsg.latitude.toString();
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.link ) )
      {
        pTile.link = commMsg.link;
      }
      if ( commMsg.longitude !== 0 )
      {
        pTile.longitude = commMsg.longitude.toString();
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.messageBody ) )
      {
        pTile.neighborhood = commMsg.messageBody;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.pElemId ) )
      {
        pTile.pElemId = commMsg.pElemId;
      }
      //if ( commMsg.photoId > 0 )
      //{
      //  pTile.photoId = commMsg.photoId;
      //}

      /*
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commMsg.receiverInfo)) {
       * eTile.receiverSitUserId = commMsg.receiverInfo.receiverSitUserId;
       * }
       */
      if (commMsg.sender.sitUserId > 0) {
        pTile.sitUserId = commMsg.sender.sitUserId;
        pTile.heartbeat.sitUserId = commMsg.sender.sitUserId;
      }
      else if (commMsg.sitUserId > 0) {
        pTile.sitUserId = commMsg.sitUserId;
      }
      if ( commMsg.signedInUserId > 0 )
      {
        pTile.signedInUserId = commMsg.signedInUserId;
        pTile.heartbeat.signedInUserId = commMsg.signedInUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commMsg.unit ) )
      {
        pTile.unit = commMsg.unit;
      }
    }

    return ( this.profileTile = pTile );
  }
  // ---------------------------------------------------------------
  static copyFromENoteTileToProfileTile(eNoteTile: ENoteTile): ProfileTile {
    // debugger; //In copyServcie
    const pTile = new ProfileTile();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile)) {
      // debugger;

      if (eNoteTile.age > 0) {
        pTile.age = eNoteTile.age;
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.away)) {
        pTile.offOn.away = eNoteTile.away;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.awayId)) {
        pTile.offOn.awayId = eNoteTile.awayId;
      }
      if (eNoteTile.beginIndex > 0) {
        pTile.beginIndex = eNoteTile.beginIndex;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.city)) {
        pTile.city = eNoteTile.city;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.countryCode)) {
        pTile.countryCode = eNoteTile.countryCode;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.date)) {
        pTile.date = eNoteTile.date;
      }
      if (eNoteTile.distance > 0) {
        pTile.distance = eNoteTile.distance;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.distanceStr)) {
        pTile.distanceStr = eNoteTile.distanceStr;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.heading)) {
        pTile.heading = eNoteTile.heading;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.id)) {
        pTile.id = eNoteTile.id;
      }
      pTile.isImageRendered = eNoteTile.isImageRendered;
      pTile.isOnline = eNoteTile.isOnline;
      pTile.isPremiumMember = eNoteTile.isPremiumMember;
      pTile.isTileSetupComplete = eNoteTile.isTileSetupComplete;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.image)) {
        pTile.primaryImage = eNoteTile.image;
      }
      if (eNoteTile.index >= 0) {
        pTile.index = eNoteTile.index;
      }
      if (eNoteTile.latitude !== 0) {
        pTile.latitude = eNoteTile.latitude.toString();
        pTile.heartbeat.latitude = eNoteTile.latitude;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.link)) {
        pTile.link = eNoteTile.link;
      }
      if (eNoteTile.longitude !== 0) {
        pTile.longitude = eNoteTile.longitude.toString();
        pTile.heartbeat.longitude = eNoteTile.longitude;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.neighborhood)) {
        pTile.neighborhood = eNoteTile.neighborhood;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.pElemId)) {
        pTile.pElemId = eNoteTile.pElemId;
      }
      if (eNoteTile.photoId > 0) {
        pTile.photoId = eNoteTile.photoId;
      }
      if (eNoteTile.profileId > 0) {
        pTile.profileId = eNoteTile.profileId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.profileName) && eNoteTile.profileName.length > 0) {
        pTile.profileName = eNoteTile.profileName;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.regionCode)) {
        pTile.regionCode = eNoteTile.regionCode;
      }
      if (eNoteTile.senderSitUserId > 0) {
        pTile.sitUserId = eNoteTile.senderSitUserId;
      }
      if (eNoteTile.signedInUserId > 0) {
        pTile.signedInUserId = eNoteTile.signedInUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(eNoteTile.unit)) {
        pTile.unit = eNoteTile.unit;
      }
    }

    this.eNoteTile = eNoteTile;
    return (this.profileTile = pTile);
  }
  // --------------------------------------------------------------
  static copyFromProfileTileToUpgradedTile ( destTile : UpgradedTile, sourceTile : ProfileTile ) : UpgradedTile
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destTile ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile ) )
    {
      if ( sourceTile.age > 0 )
      {
        destTile.age = sourceTile.age;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceTile.offOn)) {
        destTile.offOn = this.copyOffOnIfExists(destTile.offOn, sourceTile.offOn);
      }      
      if ( sourceTile.beginIndex && sourceTile.beginIndex > 0 )
      {
        destTile.beginIndex = sourceTile.beginIndex;
      }
      if ( sourceTile.city && sourceTile.city.length > 0 )
      {
        destTile.city = sourceTile.city;
      }
      if ( sourceTile.countryCode && sourceTile.countryCode.length > 0 )
      {
        destTile.countryCode = sourceTile.countryCode;
      }
      if ( sourceTile.date && sourceTile.date.length > 0 )
      {
        destTile.date = sourceTile.date;
      }
      
      if ( sourceTile.dob != null && sourceTile.dob != null )
      {
        destTile.dob = sourceTile.dob;
      }
      if ( sourceTile.gender && sourceTile.gender.length > 0 )
      {
        destTile.gender = sourceTile.gender;
      }
      if ( sourceTile.heading && sourceTile.heading.length > 0 )
      {
        destTile.heading = sourceTile.heading;
      }
      if ( sourceTile.heartbeat.prevHeartbeat > 0 )
      {
        destTile.heartbeat.prevHeartbeat = sourceTile.heartbeat.prevHeartbeat;
      }
      if ( sourceTile.heartbeat )
      {
        destTile.heartbeat = sourceTile.heartbeat;
      }
      if ( sourceTile.id && sourceTile.id.length > 0 )
      {
        destTile.id = sourceTile.id;
      }
      if (sourceTile.primaryImage && sourceTile.primaryImage.length > 0 )
      {
        destTile.primaryImage = sourceTile.primaryImage;
      }
      if ( sourceTile.index > -1 )
      {
        destTile.index = sourceTile.index;
      }
      if ( sourceTile.isImageRendered )
      {
        destTile.isImageRendered = sourceTile.isImageRendered.toString().toLowerCase().indexOf( 'true' ) !== -1;
      }
      if ( sourceTile.isOnline )
      {
        destTile.isOnline = sourceTile.isOnline.toString().toLowerCase().indexOf( 'true' ) !== -1;
      }
      if ( sourceTile.isPremiumMember )
      {
        destTile.isPremiumMember = sourceTile.isPremiumMember.toString().toLowerCase().indexOf( 'true' ) !== -1;
      }
      if ( sourceTile.isTileSetupComplete )
      {
        destTile.isTileSetupComplete = sourceTile.isTileSetupComplete.toString().toLowerCase().indexOf( 'true' ) !== -1;
      }
      if (sourceTile.latitude != null && sourceTile.latitude.length !== 1 )
      {
        destTile.latitude = sourceTile.latitude;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceTile.link ) && sourceTile.link.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destTile.link ) )
      {
        destTile.link = sourceTile.link;
      } else
      {
        destTile.link = 'view/';
      }

      if (sourceTile.longitude != null && sourceTile.longitude.length !== 1 )
      {
        destTile.longitude = sourceTile.longitude;
      }
      if ( sourceTile.neighborhood && sourceTile.neighborhood.length > 0 )
      {
        destTile.neighborhood = sourceTile.neighborhood;
      }

      if ( sourceTile.pElemId && sourceTile.pElemId.length > 0 )
      {
        destTile.pElemId = sourceTile.pElemId;
      }
      if ( sourceTile.position && sourceTile.position.length > 0 && destTile.position != null )
      {
        destTile.position = sourceTile.position;
      }
      if ( sourceTile.profileId && sourceTile.profileId > 0 )
      {
        destTile.profileId = sourceTile.profileId;
      }
      if ( sourceTile.profileName && sourceTile.profileName.length > 0 )
      {
        destTile.profileName = sourceTile.profileName;
      }
      if ( sourceTile.regionCode && sourceTile.regionCode.length > 0 )
      {
        destTile.regionCode = sourceTile.regionCode;
      }
      if ( sourceTile.sexuality && sourceTile.sexuality.length > 0 )
      {
        destTile.sexuality = sourceTile.sexuality;
      }
     
      if ( sourceTile.sitUserId != null && sourceTile.sitUserId > 0 )
      {
        destTile.sitUserId = sourceTile.sitUserId;
      }
      if ( sourceTile.signedInUserId != null && sourceTile.signedInUserId > 0 )
      {
        destTile.signedInUserId = sourceTile.signedInUserId;
      }
      if ( sourceTile.sitUserKey != null )
      {
        destTile.sitUserKey = sourceTile.sitUserKey;
      }
      if ( sourceTile.signedInUserId != null && sourceTile.signedInUserId > 0 )
      {
        destTile.signedInUserId = sourceTile.signedInUserId;
      }
      if ( sourceTile.signedInUserKey != null )
      {
        destTile.signedInUserKey = sourceTile.signedInUserKey;
      }      
    }
    return destTile;
  }
  // ---------------------------------------------------------------
  static copyFromHeartbeatToProfileTile(pTile: ProfileTile, hb: Heartbeat): ProfileTile {
    // debugger; //In copyServcie
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      pTile = new ProfileTile();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.city)) {
        pTile.city = hb.city;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.country)) {
        pTile.countryCode = hb.country;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.date)) {
        pTile.date = hb.date;
      }
      if (hb.distance > 0) {
        pTile.distance = hb.distance;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.distanceStr)) {
        pTile.distanceStr = hb.distanceStr;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.gender)) {
        pTile.gender = hb.gender;
      }

      pTile.heartbeat = hb;

      if (hb.id > 0) {
        pTile.id = hb.id.toString();
      }
      if (hb.prevHeartbeat > 0) {
        pTile.heartbeat.prevHeartbeat = hb.prevHeartbeat;
      }
      if ( hb.heartbeatTime > 0 )
      {
        pTile.heartbeat.prevHeartbeat = hb.heartbeatTime;
      }
      pTile.isOnline = hb.isOnline;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.jsDateForCs)) {
        pTile.jsDateForCs = hb.jsDateForCs;
      }
      if (hb.latitude !== 0) {
        pTile.latitude = hb.latitude.toString();
      }
      if (hb.longitude !== 0) {
        pTile.longitude = hb.longitude.toString();
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.neighborhood)) {
        pTile.neighborhood = hb.neighborhood;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.regionCode)) {
        pTile.regionCode = hb.regionCode;
      }
      if (hb.sitUserId > 0) {
        pTile.sitUserId = hb.sitUserId;
      }
      if ( hb.signedInUserId > 0 )
      {
        pTile.signedInUserId = hb.signedInUserId;
      }
    }

    return pTile;
  }
  // ---------------------------------------------------------------
  static copyFromPagerBreadcrumToPager(pbc: PagerBreadcrum): Pager {
    // debugger; //In copyServcie
    const pager = new Pager();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbc)) {
      // debugger;

      if (pbc.pageSize > 0) {
        pager.pageSize = pbc.pageSize;
      }
      if (pbc.currentPage > 0) {
        pager.currentPageNo = pbc.currentPage;
      }
      /*
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbc.currentBrdCrmPage)) {
       * pager.currentBrdCrmPage = pbc.currentBrdCrmPage;
       * }
       * if (pbc.nextPage > 0) {
       * pager.nextPage = pbc.nextPage;
       * }
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbc.nextBrdCrmPage)) {
       * pager.nextBrdCrmPage = pbc.nextBrdCrmPage;
       * }
       * if (pbc.previousPage > 0) {
       * pager.previousPage = pbc.previousPage;
       * }
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbc.previousBrdCrmPage)) {
       * pager.previousBrdCrmPage = pbc.previousBrdCrmPage;
       * }
       */
      if (pbc.totalPagedBlocks > 0) {
        pager.totalPages = pbc.totalPagedBlocks;
      }
      if ( pbc.pagedStartPage > 0) {
        pager.startPageNo = pbc.pagedStartPage;
      }
      if ( pbc.pagedEndPage > 0) {
        pager.endPageNo = pbc.pagedEndPage;
      }
      if ( pbc.pagedStartIndex > 0) {
        pager.startIndex = pbc.pagedStartIndex;
      }
      if ( pbc.pagedEndIndex > 0) {
        pager.endIndex = pbc.pagedEndIndex;
      }
      if (pbc.totalItems > 0) {
        pager.totalItems = pbc.totalItems;
      }
      if (pbc.pagedPages.length >= 0) {
        pager.pages = pbc.pagedPages;
      }
    }
    this.pagerBrdCrm = pbc;
    this.pager = pager;
    return this.pager;
  }

  // ---------------------------------------------------------------
  static copyFromPagerBreadcrumToPagerPhotoMgmt(pbc: PagerBreadcrum): PagerPhotoMgmt {
    // debugger; //In copyServcie
    const pagerPhotoMgmt = new PagerPhotoMgmt();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbc)) {
      // debugger;

      if (pbc.pageSize > 0) {
        pagerPhotoMgmt.pageSize = pbc.pageSize;
      }
      if (pbc.currentPage > 0) {
        pagerPhotoMgmt.currentPageNo = pbc.currentPage;
      }
      /*
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbc.currentBrdCrmPage)) {
       * pager.currentBrdCrmPage = pbc.currentBrdCrmPage;
       * }
       * if (pbc.nextPage > 0) {
       * pager.nextPage = pbc.nextPage;
       * }
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbc.nextBrdCrmPage)) {
       * pager.nextBrdCrmPage = pbc.nextBrdCrmPage;
       * }
       * if (pbc.previousPage > 0) {
       * pager.previousPage = pbc.previousPage;
       * }
       * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbc.previousBrdCrmPage)) {
       * pager.previousBrdCrmPage = pbc.previousBrdCrmPage;
       * }
       */
      if (pbc.totalPagedBlocks > 0) {
        pagerPhotoMgmt.totalPages = pbc.totalPagedBlocks;
      }
      if ( pbc.pagedStartPage > 0) {
        pagerPhotoMgmt.startPageNo = pbc.pagedStartPage;
      }
      if ( pbc.pagedEndPage > 0) {
        pagerPhotoMgmt.endPageNo = pbc.pagedEndPage;
      }
      if ( pbc.pagedStartIndex > 0) {
        pagerPhotoMgmt.startItemNo = pbc.pagedStartIndex;
      }
      if ( pbc.pagedEndIndex > 0) {
        pagerPhotoMgmt.endItemNo = pbc.pagedEndIndex;
      }
      if (pbc.totalItems > 0) {
        pagerPhotoMgmt.totalItems = pbc.totalItems;
      }
      if ( pbc.pagedPages.length >= 0) {
        pagerPhotoMgmt.pages = pbc.pagedPages;
      }
      pagerPhotoMgmt.currentItem = this.CopyFromBreadCrumKvToKvPhoto(pbc.currentBrdCrmPage);
      pagerPhotoMgmt.previousItem = this.CopyFromBreadCrumKvToKvPhoto(pbc.previousBrdCrmPage);
      pagerPhotoMgmt.nextItem = this.CopyFromBreadCrumKvToKvPhoto(pbc.nextBrdCrmPage);
    }
    this.pagerBrdCrm = pbc;
    this.pagerPhotoMgmt = pagerPhotoMgmt;
    return this.pagerPhotoMgmt;
  }
  // ---------------------------------------------------------------
  // TODO: the conversion may be faulty! Verify that its correct.
  // ---------------------------------------------------------------
  static CopyFromBreadCrumKvToKvPhoto ( mcl: ModuleComponentLoader ): KvPhoto
  {
    const kvPhoto = new KvPhoto();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mcl ) )
    {
      kvPhoto.index = mcl.index;
      kvPhoto.key = mcl.componentName;
      kvPhoto.sitUserId = mcl.id;
      kvPhoto.value = mcl.url;
    }
    return kvPhoto;
  }
  // ---------------------------------------------------------------
  static CopyFromBreadCrumKvToKvPhotoOld(bcKvAny: BrdCrmKvAny): KvPhoto {
    const kvPhoto = new KvPhoto();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bcKvAny)) {
      kvPhoto.date = bcKvAny.date;
      kvPhoto.index = bcKvAny.index;
      kvPhoto.key = bcKvAny.key;
      kvPhoto.sitUserId = bcKvAny.sitUserId;
      kvPhoto.value = bcKvAny.value;
    }
    return kvPhoto;
  }
  // ---------------------------------------------------------------
  static copyFromProfileImageArrToKvPhotoArr(pImages: ProfileImage[]): KvPhoto[] {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pImages ) && pImages.length > 0 )
    {
      const outKvPhotoArr: KvPhoto[] = [];

      pImages.map( ( e ) =>
      {
        outKvPhotoArr.push( this.copyFromProfileImageToKvPhoto( e ) );
      } );
      return outKvPhotoArr;
    }
    else
    {
      return [];
    }
  }
  // ---------------------------------------------------------------
  static copyFromProfileImageDictionaryToKvPhotoArr(pImages: Dictionary<number, ProfileImage>): KvPhoto[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pImages) && pImages.size > 0) {
      const outKvPhotoArr: KvPhoto[] = [];

      pImages.values().map((e) => {
        outKvPhotoArr.push(this.copyFromProfileImageToKvPhoto(e));
      });
      return outKvPhotoArr;
    }
    else
    {
      return [];
    }
  }
  // ---------------------------------------------------------------
  static copyFromProfileImageDictionaryToKvPhotoDictionary(pImages: Dictionary<number, ProfileImage>): Dictionary<number, KvPhoto> {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pImages ) && pImages.size > 0 )
    {
      const outKvPhotoDictionary: Dictionary<number, KvPhoto> = new Dictionary<number, KvPhoto>();

      pImages.values().map( ( e ) =>
      {
        outKvPhotoDictionary.set( e.sitUserId, this.copyFromProfileImageToKvPhoto( e ) );
      } );
      return outKvPhotoDictionary;
    }
    else
    {
      return new Dictionary<number, KvPhoto>();
    }
  }
  // ---------------------------------------------------------------
  static copyFromKvPhotoToPhoto(pkv: KvPhoto): Photo {
    // debugger; //In copyServcie
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pkv ) )
    {
      const photo = new Photo();
      // debugger;
      photo.copy(pkv);
      // debugger;
      // photo.copyIfExists(pkv);

      // photo.cryptUserKey = pkv.cryptUserKey; // need to be changed by caller
      // photo.date = pkv.date;
      // photo.entityName = pkv.entityName;
      // photo.description = pkv.description;
      // photo.imageBytes = pkv.imageBytes;
      // photo.imageString = pkv.image;
      // photo.pic = pkv.value;
      // photo.isPrimary = pkv.isPrimary;
      // photo.isUnlocked = pkv.isUnlocked;
      // photo.privacy = pkv.privacy;
      // photo.photoId = pkv.photoId;
      // photo.photoBnId = pkv.photoBnId;
      // photo.profileId = pkv.profileId;
      // photo.sitUserId = pkv.sitUserId;
      // photo.sitUserKey = pkv.sitUserKey;
      // photo.title = pkv.title;
      // debugger;

      return photo;
    }
    else
    {
      return new Photo();
    }
  }

  // ---------------------------------------------------------------
  static copyFromLoginSuccessToKvPhoto ( loginSuccess: LoginSuccess ): KvPhoto
  {
    // debugger; //In copyServcie
    this.sitUser = EmitterSubjectService.getSitUserModel();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( loginSuccess ) )
    {
      const kvPhoto = new KvPhoto();

      kvPhoto.cryptUserKey = loginSuccess.cryptUserKey;
      kvPhoto.entityName = 'primaryImage';
      kvPhoto.description = 'primaryImage';
      kvPhoto.image = loginSuccess.primaryImageUrl;
      kvPhoto.index = -1;
      kvPhoto.itemNum = 1;
      // debugger;
      kvPhoto.isPrimary = true;
      kvPhoto.isPrivate = false;
      kvPhoto.photoBnId = loginSuccess.primaryPhotoBnId; // TODO: watch the effect of changing photoId to photoBnId
      // debugger;
      kvPhoto.glyphName = 'primary';
      kvPhoto.privacy = 'public';
      kvPhoto.key = 'primary'; // kvPhoto.photoBnId.toString(); // 'primary';// kvPhoto.photoId.toString(); // loginSuccess.profileName;
      kvPhoto.sitUserId = loginSuccess.signedInUserId;
      kvPhoto.sitUserKey = loginSuccess.signedInUserKey;
      kvPhoto.title = 'primary image';
      kvPhoto.value = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(loginSuccess.primaryImage) ? loginSuccess.primaryImage
        : !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(loginSuccess.primaryImageUrl) ? loginSuccess.primaryImageUrl
          : '/assets/photos/DefaultImageMale.jpg';
      return kvPhoto;
    }
    else
    {
      return new KvPhoto();
    }
  }
  // ---------------------------------------------------------------
  static copyFromPhotoToKvPhoto(p: Photo): KvPhoto {
    // debugger; //In copyServcie
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( p ) )
    {
      let kvPhoto = new KvPhoto();
      // debugger;
      kvPhoto = kvPhoto.copySourceToDestination(kvPhoto, p as KvPhoto);
      // debugger;
      kvPhoto.entityName = 'Photo';
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.imageString)) {
        kvPhoto.imageString = p?.formData?.get('fileContents') as string;
			}
      
      kvPhoto.index = -1;
      kvPhoto.glyphName = p.isPrimary === true ? 'primary' : p.privacy; // Note: important!!
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.image) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.pic)) {
        kvPhoto.image = p.pic.length > 0 ? p.pic :  '';
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.pic) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.image)) {
        kvPhoto.pic = p.image.length > 0 ? p.image : '';
      }
      kvPhoto.key =  ''; // has to be provided by the PhotoBnService's getUserPhotoByPhotoBnId()
      kvPhoto.value = p.pic && p.pic.length > 5 ? p.pic : p.imageString && p.imageString.length > 0 ? p.imageString : kvPhoto.image;

      //kvPhoto.cryptUserKey = p.cryptUserKey; // need to be changed by caller
      //kvPhoto.date = p.date;
      //// kvPhoto.elemId = p.elemId;
      //kvPhoto.entityName = 'Photo';
      //kvPhoto.description = p.description;
      //kvPhoto.imageString = p.formData.get('fileContents') as string;
      //kvPhoto.glyphName = p.isPrimary === true ? 'primary' : p.privacy; // Note: important!!
      //kvPhoto.image = p.imageString && p.imageString.length > 0 ? p.imageString : p.pic && p.pic.length > 5 ? p.pic : '';
      //kvPhoto.index = -1;
      //kvPhoto.isPrimary = p.isPrimary;
      //kvPhoto.isPrivate = p.privacy.toLowerCase().indexOf('private') !== -1 ? true : false;
      //kvPhoto.key = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p.photoBnId) ? p.photoBnId.toString() : ''; // has to be provided by the PhotoBnService's getUserPhotoByPhotoBnId()
      //kvPhoto.photoId = p.photoId;
      //kvPhoto.photoBnId = p.photoBnId;
      //kvPhoto.privacy = p.privacy;
      //kvPhoto.profileId = p.profileId;
      //kvPhoto.sitUserId = p.sitUserId;
      //kvPhoto.sitUserKey = p.sitUserKey;
      //kvPhoto.title = p.title;
      //kvPhoto.value = p.pic && p.pic.length > 5 ? p.pic : p.imageString && p.imageString.length > 0 ? p.imageString : kvPhoto.image;
      // debugger;      
      return kvPhoto;
    }
    else
    {
      return new KvPhoto();
    }
  }
  // ---------------------------------------------------------------
  static copyFromProfileImageToPhoto(pImage: ProfileImage): Photo {
    // debugger; //In copyServcie
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pImage)) {
      const photo = new Photo();

      photo.approval = pImage.approval;
      photo.cryptUserKey = pImage.cryptUserKey; // need to be changed by caller
      photo.date = pImage.date;
      photo.description = pImage.description;
      photo.pic = pImage.image;
      photo.imageBytes = pImage.imageBytes;
      // photo.index = -1;
      photo.isPrimary = pImage.isPrimary;
      photo.privacy = pImage.privacy;
      // kvPhoto.key = pImage.photoId.toString(); //can be changed to something else by caller
      photo.photoId = pImage.photoId;
      // photo.profileId = pImage.profileId;
      photo.sitUserId = pImage.sitUserId;
      photo.sitUserKey = pImage.sitUserKey;
      photo.sourceId = pImage.sourceId;
      photo.sourceType = pImage.sourceType;
      photo.title = pImage.title;
      /*
       * kvPhoto.value = pImage.image;
       * photo.fileContents = pImage.imageFile?.FileContents?.toString().length > 0 ? pImage.imageFile.FileContents: '';
       * var file = StringServiceStatic.saveToFileSystem(pImage.imageFile.FileContents,
       * File(pImage.imageFile.FileContents, pImage.imageFile.FileDownloadName);
       *           pImage.imageFile.FileDownloadName, 'image/png');
       * localStorage.setItem(pImage.sitUserId.toString(), JSON.stringify(file));
       * debugger;
       */
      return photo;
    }
    else
    {
      return new Photo();
    }
  }
  // ---------------------------------------------------------------
  public static photoModelToCamelCasedString (m : string) : any {
    let camelCased = "";
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(m)) {
       camelCased = JSON.stringify(this.photoModelToCamelCasedModel(m));       
    }
    return camelCased;
  }
  // ---------------------------------------------------------------
  public static photoModelToCamelCasedModel (m : string) : any {
    let camelCased: any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(m)) {
      let photoModel = JSON.parse(m) as Photo;
      debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoModel)) {
        camelCased = CopyServiceStatic.camelCasedPhotoModel(photoModel);
        
      }
    }
    return camelCased;
  }
  // ---------------------------------------------------------------
  static camelCasedPhotoModel (inModel : Photo) : Photo {
    let outModel : Photo = new Photo;
    return this.copyFromServerPhotoToClientPhoto(outModel, inModel);
  }
  // ---------------------------------------------------------------
  static copyFromServerPhotoToClientPhoto(to: Photo, that: any): Photo {
    to.approval = that.Approval?.length > 0 ? that.Approval : '';
    to.contentType = that.ContentType;
    to.cryptUserKey = that.CryptUserKey;
    to.date = that.Date;
    to.description = that.Description;
    to.file = that.File; // TODO: warning for possible missing proterty
    // to.formData = that IFormFile;
    to.id = that.Id;
    to.image = that.Image;
    to.imageBytes = that.ImageBytes;
    to.imageString = that.ImageString;
    to.isPrimary = that.IsPrimary ? that.IsPrimary : false;
    to.latitude = that.Latitude;
    to.longitude = that.Longitude;
    to.location = that.Location;
    to.path = that.Path;
    to.itemNum = that.itemNum > 0 ? that.itemNum : -1;
    to.pic = that.pic; // Note: thisis the only lower case property on the server/back-end
    to.photoId = that.PhotoId;
    to.privacy = that.Privacy?.length > 0 ? that.Privacy : '';
    to.profileId = that.ProfileId;
    to.sitUserId = that.SITUserId;
    to.sitUserKey = that.SITUserKey;
    // debugger;
    to.signedInUserId = that.SignedInUserId;
    to.sourceId = that.SourceId;
    to.sourceType = that.SourceType;
    to.title = that.Title;
    to.url = that.Url;
    return to;
  }
  // ---------------------------------------------------------------
  static camelCasedPhotoBnModel (inModel : PhotoBn) : PhotoBn {
    let outModel : PhotoBn = new PhotoBn;
    return this.copyFromServerPhotoBnToClientPhotoBn(outModel, inModel);
  }
  // ---------------------------------------------------------------
  static copyFromServerPhotoBnToClientPhotoBn (to : PhotoBn, that : any) : PhotoBn {
    to.approval = that.Approval?.length > 0 ? that.Approval : '';
    to.box = that.Box;
    to.contentType = that.ContentType;
    to.cryptUserKey = that.CryptUserKey;
    to.date = that.Date;
    to.description = that.Description;
    to.distance = that.Distance;
    to.enctype = that.Enctype;
    to.file = that.File; // TODO: warning for possible missing proterty
    // to.formData = that IFormFile;
    to.id = that.Id;
    to.image = that.Image;
    to.imageBytes = that.ImageBytes;
    to.imageString = that.ImageString;
    to.isPrimary = that.IsPrimary ? that.IsPrimary : false;
    to.latitude = that.Latitude;
    to.longitude = that.Longitude;
    to.location = that.Location;
    to.nonce = that.Nonce;
    to.path = that.Path;
    to.itemNum = that.pageNo > 0 ? that.pageNo : -1;
    to.pic = that.pic; 
    to.photoId = that.PhotoId;
    to.photoBnId = that.PhotoBnId;
    to.privacy = that.Privacy?.length > 0 ? that.Privacy : '';
    to.profileId = that.ProfileId;
    to.sitUserId = that.SITUserId;
    to.sitUserKey = that.SITUserKey;
    // debugger;
    to.signedInUserId = that.SignedInUserId;
    to.sourceId = that.SourceId;
    to.sourceType = that.SourceType;
    to.title = that.Title;
    to.url = that.Url;
    return to;
  }
  // ---------------------------------------------------------------
  static copyFromServerPhotoBnToClientPhoto ( to: Photo, that: any): Photo
  {
    to.approval = that.Approval?.length > 0 ? that.Approval : '';
    to.contentType = that.ContentType;
    to.cryptUserKey = that.CryptUserKey;
    to.date = that.Date;
    to.description = that.Description;
    to.file = that.File; // TODO: warning for possible missing proterty
    // to.formData = that IFormFile;
    to.id = that.Id;
    to.imageBytes = that.Image;
    to.imageString = that.ImageString;
    to.isPrimary = that.IsPrimary ? that.IsPrimary : false;
    to.itemNum = that.itemNum > 0 ? that.itemNum : -1;
    to.latitude = that.Latitude;
    to.location = that.Location;
    to.longitude = that.Longitude;
    to.path = that.Path;
    to.photoId = that.PhotoId;
    to.pic = that.Pic;
    to.privacy = that.Privacy?.length > 0 ? that.Privacy : '';
    to.profileId = that.ProfileId;
    to.sitUserId = that.SITUserId;
    to.sitUserKey = that.SITUserKey;
    // debugger;
    to.signedInUserId = that.SignedInUserId;
    to.sourceId = that.SourceId;
    to.sourceType = that.SourceType;
    to.title = that.Title;
    to.url = that.Url;
    return to;
  }
  // ---------------------------------------------------------------
  static copyFromPhotoBnToClientPhoto ( to: Photo, that: PhotoBn ): Photo
  {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(to)) {
      to = new Photo();
    }
    //debugger;
    //to.cloneIfExists(that as Photo);
    //debugger;
    //return to;

    to.approval = that.approval?.length > 0 ? that.approval : '';
    to.contentType = that.contentType;
    to.cryptUserKey = that.cryptUserKey;
    to.date = that.date;
    to.description = that.description;
    to.file = that.file;
    to.formData = that.formData;
    to.id = that.id;
    to.image = that.image;
    to.imageBytes = that.imageBytes;
    to.imageString = that.imageString;
    to.isPrimary = that.isPrimary;
    to.latitude = that.latitude;
    to.longitude = that.longitude;
    to.location = that.location;
    to.path = that.path;
    to.itemNum = that.itemNum > 0 ? that.itemNum : -1;
    to.pic = that.pic;
    to.photoId = that.photoId;
    to.privacy = that.privacy?.length > 0 ? that.privacy : '';
    to.profileId = that.profileId;
    to.sitUserId = that.sitUserId;
    to.sitUserKey = that.sitUserKey;
    // debugger;
    to.signedInUserId = that.signedInUserId;
    to.sourceId = that.sourceId;
    to.sourceType = that.sourceType;
    to.title = that.title;
    to.url = that.url;
    return to;
  }
  // ---------------------------------------------------------------
  //  Deprecated!
  // ---------------------------------------------------------------
  //static copyFromPhotoBnToKvPhoto ( to : KvPhoto, that : PhotoBn ): KvPhoto
  //{
  //  let p : Photo = new Photo();
  //  let kvp : KvPhoto = new KvPhoto();
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(to)) {
  //    to = new KvPhoto();
  //  }
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(that)) {
  //    // debugger;
  //    p = p.copyIfExists(that as Photo);
  //    kvp = kvp.copyIfExists(p as KvPhoto);
  //    to.copyIfExists(kvp as KvPhoto);
  //    // debugger;
  //    to.glyphName = that.isPrimary === true ? 'primary' : that.privacy; // Note: important!! // to hold the glyphName for privacy
  //    to.isPrivate = that.privacy.toLowerCase().indexOf('private') !== -1 ? true : false;
  //    to.key = that.photoBnId > 0 ? that.photoBnId.toString() : '';
  //    to.value = that.pic && that.pic.length > 5 ? that.pic : that.image && that.image.length > 5 ? that.image : that.imageString.length > 5 ? that.imageString : to.image;

  //    //to.cryptUserKey = that.cryptUserKey;
  //    //to.date = that.date;
  //    //to.description = that.description;
  //    //to.elemId = '';
  //    //to.entityName = 'PhotoBn';
  //    //to.imageString =that.formData.get('fileContents') as string;
  //    //to.glyphName = that.isPrimary === true ? 'primary' : that.privacy; // Note: important!! // to hold the glyphName for privacy
  //    //to.image = that.image;
  //    //to.imageBytes = that.imageBytes;
  //    //to.isPrivate = that.privacy.toLowerCase().indexOf('private') !== -1 ? true : false;
  //    //to.isUnlocked = false;
  //    //to.index = -1;
  //    //to.isPrimary = that.isPrimary;
  //    //to.key = that.photoBnId > 0 ? that.photoBnId.toString() : '';
  //    //to.itemNum = 0;
  //    //to.photoId = that.photoId;
  //    //to.photoBnId = that.photoBnId;
  //    //to.profileId = that.profileId;
  //    //to.privacy = that.privacy;
  //    //to.returnUrl = '';
  //    //to.sitUserId = that.sitUserId;
  //    //to.sitUserKey = that.sitUserKey;
  //    //to.title = that.title;
  //    //to.value = that.pic && that.pic.length > 5 ? that.pic : that.image && that.image.length > 5 ? that.image : that.imageString.length > 5 ? that.imageString : to.image;
  //  }
  //  return to;
  // }
  // ---------------------------------------------------------------
  static copyFromProfileImageToKvPhoto(pImage: ProfileImage): KvPhoto {
    // debugger; //In copyServcie
    this.sitUser = EmitterSubjectService.getSitUserModel();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pImage)) {
      const kvPhoto = new KvPhoto();

      kvPhoto.cryptUserKey = pImage.cryptUserKey; // need to be changed by caller
      kvPhoto.date = pImage.date;
      kvPhoto.entityName = pImage.entityName;
      kvPhoto.description = pImage.description;
      kvPhoto.image = pImage.image;
      kvPhoto.imageBytes = pImage.imageBytes;
      kvPhoto.index = -1;
      // debugger;
      kvPhoto.isPrimary = pImage.isPrimary;
      // debugger;
      kvPhoto.glyphName = pImage.isPrimary === true ? 'primary' : pImage.privacy; // Note: important!!
      kvPhoto.privacy = pImage.privacy;
      kvPhoto.key = kvPhoto.glyphName; // TODO: confirm if it is correct/desired.
      kvPhoto.photoId = pImage.photoId;
      kvPhoto.photoBnId = pImage.photoBnId;
      kvPhoto.profileId = pImage.profileId;
      kvPhoto.sitUserId = pImage.sitUserId;
      kvPhoto.sitUserKey = pImage.sitUserKey;
      kvPhoto.title = pImage.title;
      kvPhoto.value = pImage.image;
      kvPhoto.imageString = pImage.imageFile?.FileContents?.toString().length > 0 ? pImage.imageFile.FileContents : '';
      /*
       * File(pImage.imageFile.FileContents, pImage.imageFile.FileDownloadName);
       * var file = StringServiceStatic.saveToFileSystem(pImage.imageFile.FileContents, pImage.imageFile.FileDownloadName, 'image/png');
       * localStorage.setItem(pImage.sitUserId.toString(), JSON.stringify(file));
       * debugger;
       */
      return kvPhoto;
    }
    else
    {
      return new KvPhoto();
    }
  }
  // ---------------------------------------------------------------
  static copyFromProfilePicsToKvPhotoArr(pPics: ProfilePics, sitUser: SitUser): KvPhoto[] {
    // debugger; //In copyServcie
    const kvPhotoD: KvPhoto[] = [];
    let i = 0;

    if (
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.images) &&
      pPics.images.length > 0 &&
      pPics.profileImageArr.length > 0 &&
      pPics.profileImageArr.length === pPics.images.length
    ) {
      const firstElem = pPics.profileImageArr[0];

      pPics.images.map((e) => {
        const kvPhoto = new KvPhoto();

        kvPhoto.cryptUserKey = pPics.sitUserId.toString(); // need to be changed by caller
        kvPhoto.date = pPics.date;
        kvPhoto.index = -1;
        kvPhoto.isPrimary = sitUser.primaryPhotoBnId === firstElem.photoId; // thisProfilePics.profileImageArr[i].isPrimary;
        kvPhoto.privacy = firstElem.privacy;
        kvPhoto.key = firstElem.photoId.toString(); // can be changed to something else by caller
        kvPhoto.photoId = firstElem.photoId;
        kvPhoto.photoBnId = firstElem.photoBnId;
        kvPhoto.profileId = pPics.profileId;
        kvPhoto.sitUserId = pPics.sitUserId;
        kvPhoto.value = e;
        // debugger;
        kvPhotoD.push(kvPhoto);
        i++;
      });
    }
    return kvPhotoD;
  }
  /*
 * ---------------------------------------------------------------
 * Note:  1) a similar method exists in stringService.profilePicsToKvPhotoArr()
 *        2) This method is duplicate in functionality as of  copyFromProfilePicsToKvPhotoArr()
 * ---------------------------------------------------------------
 */
  static copyProfilePicsToKvPhotoArr ( profilePics : ProfilePics ) : KvPhoto[]
  {
    // debugger; //In copyServcie
    const tKvPhotoD : KvPhoto[] = [];
    let kvPhoto = new KvPhoto();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profilePics ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profilePics.profileImageArr ) && profilePics.profileImageArr.length > 0 )
    {
      const pImages = profilePics.profileImageArr;

      for ( let i = 0; i < pImages.length; i++ )
      {
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pImages[ i ].image ) )
        {
          const item = pImages[ i ];

          kvPhoto = new KvPhoto();
          kvPhoto.cryptUserKey = item.cryptUserKey;
          kvPhoto.date = item.date;
          kvPhoto.description = item.description;
          kvPhoto.entityName = item.entityName;
          kvPhoto.index = i;
          kvPhoto.image = item.image;
          kvPhoto.imageBytes = item.imageBytes;
          kvPhoto.isPrimary = item.isPrimary;
          kvPhoto.privacy = item.privacy;
          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( item.photoId ) )
          {
            kvPhoto.key = item.photoId.toString();
          }
          kvPhoto.photoId = item.photoId;
          kvPhoto.photoBnId = item.photoBnId;
          kvPhoto.profileId = item.profileId;
          kvPhoto.sitUserId = item.sitUserId;
          kvPhoto.sitUserKey = item.sitUserKey;
          kvPhoto.title = item.title;
          kvPhoto.value = item.image; //JsRegExpServiceStatic.normalizeImageData( item.image ); // Note: Only destination/last method will manipulate the image for display
          // debugger;
          tKvPhotoD.push( kvPhoto );
        }
      }
    }
    return tKvPhotoD;
  }
  // ---------------------------------------------------------------
  // copyFromProfilePicsToKvPhotoDictionary(pPics: ProfilePics, sitUser: SitUser): Collections.Dictionary<number, KvPhoto> {
  // //debugger; //In copyServcie
  // var kvPhotoD: Collections.Dictionary<number, KvPhoto> = new Collections.Dictionary<number, KvPhoto>();
  // var i = 0;
  // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)
  //   && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.images)
  //   && pPics.images.length > 0
  //   && pPics.profileImageDictionary.size() > 0
  //   && pPics.profileImageDictionary.size() === pPics.images.length) {

  //   var firstElem = pPics.profileImageDictionary.values()[0];

  /*
   *   pPics.images.map(e => {
   *     var kvPhoto = new KvPhoto();
   *     kvPhoto.cryptUserKey = pPics.sitUserId.toString();//need to be changed by caller
   *     kvPhoto.date = pPics.date;
   *     kvPhoto.index = -1;
   *     kvPhoto.isPrimary = sitUser.primaryPhotoBnId == firstElem.photoId ? true: false;//thisProfilePics.profileImageArr[i].isPrimary;
   *     kvPhoto.privacy = firstElem.privacy;
   *     kvPhoto.key = firstElem.photoId.toString();//can be changed to something else by caller
   *     kvPhoto.photoId = firstElem.photoId;
   *     kvPhoto.profileId = pPics.profileId;
   *     kvPhoto.sitUserId = pPics.sitUserId;
   *     kvPhoto.value = e;
   *     //debugger;
   *     kvPhotoD.setValue(kvPhoto.photoId,kvPhoto);
   *     i++;
   *   });
   * }
   * return kvPhotoD;
   * }
   * ---------------------------------------------------------------
   */

  /*
   * ---------------------------------------------------------------
   * Begin of: Glyph-to-MemberActivity-to-Glyph
   * ---------------------------------------------------------------
   */
  static copyFromKvGlyphToCommunicationActivity (kvg : KvMemberActivityGlyph) : CommunicationActivity {
    // debugger; //In copyServcie
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvg)) {
      let mAct: CommunicationActivity = new CommunicationActivity();

      mAct.action = kvg.action;
      mAct.date = kvg.date;
      
      mAct.receiverSitUserId = kvg.receiverSitUserId;
      mAct.senderSitUserId = kvg.senderSitUserId;
      mAct.id = kvg.id.toString();
      mAct.kvGlyph.key = kvg.key; // pImage.photoId.toString(); //can be changed to something else by caller
      mAct.messageBody = kvg.message;
      mAct.kvGlyph.model = kvg.model;
      mAct.kvGlyph.modelName = kvg.modelName;
     // mAct.photoId = kvg.photoId;
      mAct.photoBnId = kvg.photoBnId;
      // mAct.profileId = kvg.profileId;
      mAct.signedInUserId = kvg.signedInUserId;
      mAct.kvGlyph.value = kvg.value;
      // debugger;
      return mAct;
    }
    else
    {
      return new CommunicationActivity();
    }
  }
  // ---------------------------------------------------------------
  static copyFromMemberActivityToKvGlyph (mAct : MemberActivity) : KvMemberActivityGlyph {
    // debugger; //In copyServcie
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct)) {
      const kvg = new KvMemberActivityGlyph();

      kvg.action = mAct.action;
      kvg.date = mAct.date;
      kvg.receiverCryptUserKey = mAct.doeeCryptUserKey;
      kvg.senderCryptUserKey = mAct.doerCryptUserKey;
      kvg.receiverSitUserId = mAct.doeeSitUserId;
      kvg.senderSitUserId = mAct.doerSitUserId;
      kvg.id = mAct.id;
      kvg.key = mAct.key; // can be changed to something else by caller
      kvg.message = mAct.message;
      kvg.model = mAct.model;
      kvg.modelName = mAct.modelName;
      // kvg.photoId = mAct.photoId;
      kvg.photoBnId = mAct.photoBnId;
      kvg.profileId = mAct.profileId;
      kvg.signedInUserId = mAct.signedInUserId;
      kvg.value = mAct.value;
      // debugger;
      return kvg;
    }
    else
    {
      return new KvMemberActivityGlyph();
    }
  }

  /*
   * ---------------------------------------------------------------
   * Begin of: KvAction-to-MemberActivity-to-KvAction
   * ---------------------------------------------------------------
   */
  static copyFromKvActionToMemberActivity(kva: KvAction): MemberActivity {
    // debugger; //In copyServcie
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kva)) {
      const mAct = new MemberActivity();
      // mAct.action = kva.action;

      mAct.date = kva.date;
      mAct.expiryDate = kva.expDate;
      // mAct.doeeCryptUserKey = kva.doeeCryptUserKey;
      mAct.doerCryptUserKey = kva.cryptUserKey;
      mAct.doeeSitUserId = kva.doeeSitUserId;
      mAct.doerSitUserId = kva.doerSitUserId;
      mAct.id = kva.memberActivityId;
      mAct.index = kva.index;
      mAct.key = kva.key; // pImage.photoId.toString(); //can be changed to something else by caller
      /*
       * mAct.message = kva.message;
       * mAct.model = kva.model;
       * mAct.modelName = kva.modelName;
       * mAct.photoId = kva.photoId;
       */
      mAct.receiverSitUserId = kva.receiverSitUserId;
      mAct.senderSitUserId = kva.senderSitUserId;
      mAct.profileId = kva.profileId;
      mAct.signedInUserId = kva.signedInUserId;
      mAct.value = kva.value;
      // debugger;
      return mAct;
    }
    else
    {
      return new MemberActivity();
    }
  }
  // ---------------------------------------------------------------
  static copyFromMemberActivityToKvAction(mAct: MemberActivity): KvAction {
    // debugger; //In copyServcie
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct)) {
      const kva = new KvAction();

      kva.date = mAct.date;
      kva.expDate = mAct.expiryDate;
      kva.cryptUserKey = mAct.doerCryptUserKey;
      kva.doeeSitUserId = mAct.doeeSitUserId;
      kva.doerSitUserId = mAct.doerSitUserId;
      kva.memberActivityId = mAct.id;
      kva.index = mAct.index;
      kva.key = mAct.key;
      kva.receiverSitUserId = mAct.receiverSitUserId;
      kva.senderSitUserId = mAct.senderSitUserId;
      kva.profileId = mAct.profileId;
      kva.signedInUserId = mAct.signedInUserId;
      kva.value = mAct.value;
      // debugger;
      return kva;
    }
    else
    {
      return new KvAction();
    }
  }
  /*
   * ---------------------------------------------------------------
   * End of: KvAction-to-MemberActivity-to-KvAction
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   * Begin of: Glyph-to-Photo-to-Glyph
   * ---------------------------------------------------------------
   */
  static copyFromKvGlyphToKvPhoto (kvg : KvMemberActivityGlyph): KvPhoto {
    // debugger; //In copyServcie
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvg)) {
      const kvPhoto = new KvPhoto();
      debugger;
      // kvPhoto.copy(kvg.kvPhoto);
      debugger;
      // Note: even thought kvg contains kvPhoto,
      // we still need the kvg's field values to update kvPhoto.
      // because kvPhoto may be empty or has holes meaing not all
      // values are present.
      // -------------------------------------------------------
      kvPhoto.cryptUserKey = kvg.cryptUserKey; // need to be changed by caller
      kvPhoto.date = kvg.date;
      kvPhoto.index = kvg.index;
      kvPhoto.isPrimary = kvg.isPrimary;
      kvPhoto.isPrivate = kvg.isPrivate;
      kvPhoto.key = kvg.key; // pImage.photoId.toString(); //can be changed to something else by caller
      kvPhoto.itemNum = kvg.pageNum;
      kvPhoto.photoId = kvg.photoId;
      kvPhoto.photoBnId = kvg.photoBnId;
      kvPhoto.privacy = kvg.privacy;
      kvPhoto.profileId = kvg.profileId;
      kvPhoto.sitUserId = kvg.sitUserId;
      kvPhoto.sitUserKey = kvg.sitUserKey;
      kvPhoto.pic = kvPhoto.value = kvPhoto.image = kvg.value;
      // debugger;
      return kvPhoto;
    }
    else
    {
      return new KvPhoto();
    }
  }
  // ---------------------------------------------------------------
  static copyFromKvPhotoToKvGlyph (kvP : KvPhoto) : KvMemberActivityGlyph {
    // debugger; //In copyServcie
    const kvG = new KvMemberActivityGlyph();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP)) {
      kvG.cryptUserKey = kvP.cryptUserKey;
      kvG.date = kvP.date;
      kvG.glyphName = kvP.glyphName;
      kvG.isPrimary = kvP.isPrimary;
      kvG.isPrivate = kvP.isPrivate;
      kvG.index = kvP.index;
      // debugger;
      kvG.key = kvP.key.toLowerCase().indexOf('primary') !== -1 ? kvP.key : kvP.glyphName; // kvP.key; //TODO: need to determine what value should key hold from KvPhoto

      // TODO: watch this value to be sure that it gets the deired data through casting:
      // debugger;
      // kvG.copy(kvP as KvMemberActivityGlyph);

      kvG.pageNum = kvP.itemNum;
      kvG.photoId = kvP.photoId;
      kvG.photoBnId = kvP.photoBnId;
      kvG.privacy = kvP.privacy;
      kvG.profileId = kvP.profileId;
      kvG.sitUserId = kvP.sitUserId;
      kvG.sitUserKey = kvP.sitUserKey;
      kvG.value = kvP.value;
      return kvG;
    }
    else
    {
      return new KvMemberActivityGlyph();
    }
  }
  /*
   * ---------------------------------------------------------------
   * End of: Glyph-to-Photo-to-Glyph
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
  static copyFromProfileTileToKvPhoto(pTile: ProfileTile): KvPhoto {
    // debugger; //In copyServcie
    const kvPhoto = new KvPhoto();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      kvPhoto.copy(pTile.kvPhoto);
      
      kvPhoto.date = pTile.date;
      kvPhoto.elemId = pTile.pElemId;
      kvPhoto.image = pTile.primaryImage;
      kvPhoto.isPrimary = pTile.isPrimaryPhoto;
      kvPhoto.key = pTile.isPrimaryPhoto ? 'Primary' : pTile.privacy; // Note: or some other value
      kvPhoto.glyphName = pTile.isPrimaryPhoto ? 'Primary' : pTile.privacy; // Note: or some other value
      kvPhoto.photoId = pTile.photoId;
      kvPhoto.photoBnId = pTile.photoBnId;
      kvPhoto.privacy = pTile.privacy;
      kvPhoto.profileId = pTile.profileId;
      kvPhoto.sitUserId = pTile.sitUserId;
      kvPhoto.sitUserKey = pTile.sitUserKey;
      kvPhoto.signedInUserId = pTile.signedInUserId;
      kvPhoto.sourceType = pTile.source,
        kvPhoto.value = pTile.primaryImage;

      // can be changed to something else by caller
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.kvPhoto)) {
        kvPhoto.cryptUserKey = pTile.kvPhoto.cryptUserKey && pTile.kvPhoto.cryptUserKey.length > 0 ? pTile.kvPhoto.cryptUserKey : pTile.sitUserId.toString(); // need to be changed by caller
        kvPhoto.index = kvPhoto.index > -1 ? kvPhoto.index : -1;
        kvPhoto.key = pTile.kvPhoto.key && pTile.kvPhoto.key.length > 0 ? pTile.kvPhoto.key : pTile.sitUserId.toString();
        kvPhoto.photoId = pTile.kvPhoto.photoId > 0 ? pTile.kvPhoto.photoId : pTile.photoId;
        kvPhoto.photoBnId = pTile.kvPhoto.photoBnId > 0 ? pTile.kvPhoto.photoBnId : pTile.photoBnId;
        kvPhoto.profileId = pTile.kvPhoto.profileId > 0 ? pTile.kvPhoto.profileId : pTile.profileId;
        kvPhoto.sitUserId = pTile.kvPhoto.sitUserId > 0 ? pTile.kvPhoto.sitUserId : pTile.sitUserId;
        kvPhoto.sitUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.kvPhoto.sitUserKey) ? pTile.kvPhoto.sitUserKey : pTile.sitUserKey;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.primaryImage) && pTile.primaryImage.length > 0) {
          // debugger;
          kvPhoto.pic = (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.pic) && kvPhoto.pic.length > 0) ? kvPhoto.pic : pTile.primaryImage;
          kvPhoto.value = (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value) && kvPhoto.value.length > 0) ? kvPhoto.value : pTile.primaryImage;
           kvPhoto.image = (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.image) && kvPhoto.image.length > 0) ? kvPhoto.image
            : (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.imageString) && kvPhoto.imageString.length > 0) ? kvPhoto.imageString
               : pTile.primaryImage;
        }
      }
    }
    return kvPhoto;
  }

  /*
   * ---------------------------------------------------------------
   * the model that displays the View for ProfileInfo
   * ---------------------------------------------------------------
   */
  static copyFromProfileInfoToProfileInfoView(profile: ProfileInfo): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profile)) {
      this.profileInfoView.age = profile.age;
      this.profileInfoView.away = profile.away;
      this.profileInfoView.city = profile.city;
      this.profileInfoView.countryCode = profile.countryCode;
      this.profileInfoView.cutUncut = profile.cutUncut;
      this.profileInfoView.dickSize = profile.dickSize;
      this.profileInfoView.distance = profile.distance;
      this.profileInfoView.dob = profile.dob;
      this.profileInfoView.gender = profile.gender;
      this.profileInfoView.heading = profile.heading;
      this.profileInfoView.highestEducation = profile.highestEducation;
      this.profileInfoView.image = profile.image;
      this.profileInfoView.latitude = profile.latitude;
      this.profileInfoView.longitude = profile.longitude;
      this.profileInfoView.message = profile.message;
      this.profileInfoView.name = profile.name;
      this.profileInfoView.neighborhood = profile.neighborhood;
      this.profileInfoView.photoId = profile.photoId;
      this.profileInfoView.position = profile.position;
      this.profileInfoView.profileId = profile.profileId;
      this.profileInfoView.profileName = profile.profileName;
      this.profileInfoView.regionCode = profile.regionCode;
      this.profileInfoView.relationshipStatus = profile.relationshipStatus;
      this.profileInfoView.religion = profile.religion;
      this.profileInfoView.sexuality = profile.sexuality;
      this.profileInfoView.sitUserId = profile.sitUserId;
      this.profileInfoView.signedInUserId = profile.signedInUserId;
      this.profileInfoView.sitUserKey = profile.sitUserKey;
      this.profileInfoView.signedInUserKey = profile.signedInUserKey;
      this.profileInfoView.smokingStatus = profile.smokingStatus;
      this.profileInfoView.unit = profile.unit;
      /*
       * this.profileInfoView.isProfileComplete = profile.isProfileComplete;
       * this.profileInfoView.photoId = profile.photoId;
       * this.profileInfoView.profileId = profile.profileID;
       * this.profileInfoView.sitUserId = profile.sitUserId;
       * this.profileInfoView.signedInUserId = profile.signedInUserId;
       * debugger;
       */
      return this.profileInfoView;
    }
    return null;
  }

  // ---------------------------------------------------------------
  static copyFromProfileInfoToProfileTile ( pInfo: ProfileInfo ): ProfileTile
  {
    let pTile = new ProfileTile();
    // debugger; //In copyServcie
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo ) )
    {
      // debugger;
      if (pInfo.age > 0) {
        pTile.age = pInfo.age;
      }
      pTile.offOn.away = pInfo.away;

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.city ) )
      {
        pTile.city = pInfo.city;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.countryCode ) )
      {
        pTile.countryCode = pInfo.countryCode;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.date ) )
      {
        pTile.date = pInfo.date;
      }
      if ( pInfo.distance > 0 )
      {
        pTile.distance = pInfo.distance;

        pTile.distanceStr = pInfo.distance.toString();
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.gender ) )
      {
        pTile.gender = pInfo.gender;
      }

      pTile.heartbeat.heartbeatTime = parseInt( pInfo.heartbeatTime, 10 );
      
      pTile.isOnline = pInfo.isOnline;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.dob ) )
      {
        pTile.dob = pInfo.dob;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo.heading)) {
        pTile.heading = pInfo.heading;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo.isOnline)) {
        pTile.isOnline = pInfo.isOnline;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo.image)) {
        pTile.primaryImage = pInfo.image;
      }
      if ( pInfo.latitude.length !== 1 )
      {
        pTile.latitude = pInfo.latitude;
      }
      if ( pInfo.longitude.length !== 1 )
      {
        pTile.longitude = pInfo.longitude;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.neighborhood ) )
      {
        pTile.neighborhood = pInfo.neighborhood;
      }
      if (pInfo.photoId > 0) {
        pTile.photoId = pInfo.photoId;
      }
      if (pInfo.profileId > 0) {
        pTile.profileId = pInfo.profileId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo.profileName)) {
        pTile.profileName = pInfo.profileName;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.regionCode ) )
      {
        pTile.regionCode = pInfo.regionCode;
      }
      if ( pInfo.sitUserId > 0 )
      {
        pTile.sitUserId = pInfo.sitUserId;

        pTile.source = pInfo.sitUserId.toString();
      }
      if ( pInfo.signedInUserId > 0 )
      {
        pTile.signedInUserId = pInfo.signedInUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.sitUserKey ) )
      {
        pTile.sitUserKey = pInfo.sitUserKey;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo.signedInUserKey ) )
      {
        pTile.signedInUserKey = pInfo.signedInUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo.unit)) {
        pTile.unit = pInfo.unit;
      }
    }
    this.profileTile = pTile
    return this.profileTile;
  }
  // ---------------------------------------------------------------
  // DO NOT call this method.
  // User ProfileTile Model's copyToProfileInfo() instead.
  // ---------------------------------------------------------------
  static copyFromProfileTileToProfileInfo(pTile: ProfileTile): ProfileInfo {
    const profileInfo = new ProfileInfo();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      // debugger;
      profileInfo.age = pTile.age;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn.away)) {
        profileInfo.away = pTile.offOn.away;
      }
      profileInfo.city = pTile.city;
      profileInfo.countryCode = pTile.countryCode;
      profileInfo.date = pTile.date;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.distance) && pTile.distance > 0) {
        profileInfo.distance = pTile.distance + ' ' + pTile.unit;
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn.offOnArr) && pTile.offOn.offOnArr.length > 0) {
        profileInfo.distance = pTile.offOn.offOnArr[ 0 ];
      }
      else {
        profileInfo.distance = '0 m';
      }
      
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfo.distance) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.distanceStr)) {
        profileInfo.distance = pTile.distanceStr;
      }
      profileInfo.dob = pTile.dob;
      profileInfo.gender = pTile.gender;
      profileInfo.heading = pTile.heading;
      
      // debugger;
      // !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heartbeat.prevHeartbeat) ? pTile.heartbeat.prevHeartbeat.toString() : new Date().toLocaleString();
      profileInfo.heartbeatTime = pTile.heartbeatTime.toString(); 
      // debugger;
      let tPic: any
      if (pTile.primaryImage) {
        tPic = pTile.primaryImage;
      }      
      else if (pTile.kvPhoto.value) {
        tPic = pTile.kvPhoto.value;
      }
      else if (pTile.kvPhoto.pic) {
        tPic = pTile.kvPhoto.pic;
      }
      profileInfo.image = tPic;
      const pImage = new ProfileImage();

      pImage.image = tPic;
      pImage.sitUserId = pTile.sitUserId;
      pImage.photoId = pTile.photoId > 0 ? pTile.photoId : !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.kvPhoto)? pTile.kvPhoto.photoId : 0;
      pImage.photoBnId = pTile.photoBnId > 0 ? pTile.photoBnId : !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.kvPhoto) ? pTile.kvPhoto.photoBnId : 0;
      if (profileInfo.profilePics && profileInfo.profilePics.profileImageArr) {
        profileInfo.profilePics.profileImageArr.push(pImage);
      }      
      // debugger;
      //profileInfo.latitude = StringServiceStatic.string2Number(pTile.latitude);
      //profileInfo.longitude = StringServiceStatic.string2Number(pTile.longitude);
      profileInfo.latitude = pTile.latitude;
      profileInfo.longitude = pTile.longitude;
      profileInfo.neighborhood = pTile.neighborhood;
      profileInfo.position = pTile.position;
      profileInfo.profileId = pTile.profileId;
      profileInfo.profileName = pTile.profileName;
      profileInfo.regionCode = pTile.regionCode;
      profileInfo.sexuality = pTile.sexuality;
      profileInfo.sitUserId = pTile.sitUserId;
      profileInfo.signedInUserId = pTile.signedInUserId;
      profileInfo.sitUserKey = pTile.sitUserKey;
      profileInfo.signedInUserKey = pTile.signedInUserKey;
      profileInfo.unit = pTile.unit;
    }
    // debugger;
    return profileInfo;
  }
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  static copyFromProfilePicsToProfileInfo(pPics: ProfilePics): ProfileInfo {
    const profileInfo = new ProfileInfo();

    if (pPics) {
      /*
       * debugger;
       * profileInfo.age = pPics.age;
       * profileInfo.away = pPics.away;
       * profileInfo.city = pPics.city;
       * profileInfo.countryCode = pPics.countryCode;
       */
      profileInfo.date = pPics.date;
      /*
       * profileInfo.distance = pPics.distance > 0 ? pPics.distance: pPics.offOnArr.length > 0 ? pPics.offOnArr[0]: 0;
       * if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfo.distance) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.distanceStr))
       * profileInfo.distance = pPics.distanceStr;
       * profileInfo.dob = pPics.dob;
       * profileInfo.gender = pPics.gender;
       * profileInfo.heading = pPics.heading;
       * profileInfo.heartbeat = pPics.prevHeartbeat.toString();
       */
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.profileImageArr) && pPics.profileImageArr.length > 0) {
        profileInfo.profilePics.profileImageArr = pPics.profileImageArr;
      }
      /*
       * debugger;
       * profileInfo.latitude = pPics.latitude;
       * profileInfo.longitude = pPics.longitude;
       * profileInfo.neighborhood = pPics.neighborhood;
       * profileInfo.position = pPics.position;
       */
      profileInfo.profileId = pPics.profileId;
      /*
       * profileInfo.profileName = pPics.profileName;
       * profileInfo.regionCode = pPics.regionCode;
       * profileInfo.sexuality = pPics.sexuality;
       */
      profileInfo.sitUserId = pPics.sitUserId;
      /*
       * profileInfo.isPrimaryMember = pTile.isPrimaryMember;
       * profileInfo.signedInUserId = pPics.signedInUserId;
       * profileInfo.unit = pPics.unit;
       */
    }
    // debugger;
    return profileInfo;
  }
  // ---------------------------------------------------------------
  static createProfilePicsFromProfileTile ( profileTile : ProfileTile ) : ProfilePics
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileTile ) && profileTile.primaryImage.length > 0 )
    {
      const dpPics = new ProfilePics();
      const pImage = new ProfileImage();

      pImage.image = profileTile.primaryImage
      pImage.sitUserId = profileTile.sitUserId;
      pImage.photoId = profileTile.photoId;
      if ( dpPics.profileImageArr )
      {
        dpPics.profileImageArr.push( pImage );
      }

      const kvPhoto = new KvPhoto();
      kvPhoto.image = profileTile.primaryImage;
      kvPhoto.sitUserId = profileTile.sitUserId;
      kvPhoto.sitUserKey = profileTile.sitUserKey;
      kvPhoto.key = 'primaryImage';
      kvPhoto.isPrimary = true;
      kvPhoto.isPrivate = false;
      kvPhoto.value = profileTile.primaryImage;

      dpPics.filteredKvPhotoArr.push( kvPhoto );

      dpPics.entityName = 'ProfileTile';

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileTile.date ) )
      {
        dpPics.date = profileTile.date;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileTile.profileId ) && profileTile.profileId > 0 )
      {
        dpPics.profileId = profileTile.profileId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileTile.sitUserId ) && profileTile.sitUserId > 0 )
      {
        dpPics.sitUserId = profileTile.sitUserId;
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profileTile.sitUserKey ) )
      {
        dpPics.sitUserKey = profileTile.sitUserKey;
      }

      return dpPics;
    }
    else
    {
      return new ProfilePics();
    }
  }
  // ---------------------------------------------------------------
  static createProfilePicsFromProfileImageArr(profileImageArr: ProfileImage[]): ProfilePics {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr) && profileImageArr.length > 0) {
      const dpPics = new ProfilePics();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr[0].cryptUserKey)) {
        dpPics.cryptUserKey = profileImageArr[0].cryptUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr[0].date)) {
        dpPics.date = profileImageArr[0].date;
      }    
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr[0].profileId) && profileImageArr[0].profileId > 0) {
        dpPics.profileId = profileImageArr[0].profileId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr[0].sitUserId) && profileImageArr[0].sitUserId > 0) {
        dpPics.sitUserId = profileImageArr[0].sitUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr[0].sitUserKey)) {
        dpPics.sitUserKey = profileImageArr[0].sitUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr[ 0 ].entityName)) {
        dpPics.entityName = profileImageArr[ 0 ].entityName;        
      }
      profileImageArr.map((e) => {
        dpPics.profileImageArr.push(e);
        let kvPhoto = this.copyFromProfileImageToKvPhoto(e);
        dpPics.profileKvPhotoArr.push(kvPhoto);
      });

      // NOTE: since we cannot inject services,
      //    the caller of this method need to filter profileKvPhotoArr
      // -------------------------------------------------------------
      // dpPics.filteredKvPhotoArr = this.photoPrivacyService.

      dpPics.profileImageArr = profileImageArr;
      return dpPics;
    }
    else
    {
      return new ProfilePics();
    }
  }
  // ---------------------------------------------------------------
  static createProfilePicsModelFromSitUser (sitUser : SitUser) : ProfilePics {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0) {
      const dpPics = new ProfilePics();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.cryptUserKey)) {
        dpPics.cryptUserKey = sitUser.cryptUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.date)) {
        dpPics.date = sitUser.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.profileId) && sitUser.profileId > 0) {
        dpPics.profileId = sitUser.profileId;
      }
      
      dpPics.sitUserId = sitUser.sitUserId;
 
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.sitUserKey)) {
        dpPics.sitUserKey = sitUser.sitUserKey;
      }     
      let tKvP = this.copySitUserToKvPhoto(sitUser) as KvPhoto;
      //  since we created the kvPhoto from sitUser, we push it in both
      //  filteredKvPhotoArr and unFilteredKvPhotoArr.
      // ---------------------------------------------------------------
      dpPics.unFilteredKvPhotoArr.push(tKvP);
      dpPics.filteredKvPhotoArr.push(tKvP);

      // NOTE: since we cannot inject services on Static Service,
      //    the caller of this method need to filter profileKvPhotoArr
      // -------------------------------------------------------------
      // dpPics.filteredKvPhotoArr = this.photoPrivacyService.
      return dpPics;
    }
    else {
      return new ProfilePics();
    }
  }
  // --------------------------------------------------------------
  static createProfilePicsFromProfileImageDictionary(pImageD: Dictionary<number, ProfileImage>): ProfilePics {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pImageD) && pImageD.values().length > 0) {
      const dpPics = new ProfilePics();
      const pImages = pImageD.values();
      const firstItem = pImages[0];

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(firstItem.cryptUserKey)) {
        dpPics.cryptUserKey = firstItem.cryptUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(firstItem.date)) {
        dpPics.date = firstItem.date;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(firstItem.entityName)) {
        dpPics.entityName = firstItem.entityName;
        pImages.map((e) => {
          dpPics.profileImageArr.push(e);
        });
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(firstItem.profileId) && firstItem.profileId > 0) {
        dpPics.profileId = firstItem.profileId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(firstItem.sitUserId) && firstItem.sitUserId > 0) {
        dpPics.sitUserId = firstItem.sitUserId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(firstItem.sitUserKey)) {
        dpPics.sitUserKey = firstItem.sitUserKey;
      }

      return dpPics;
    }
    else
    {
      return new ProfilePics();
    }
  }
  // ---------------------------------------------------------------
  // static copyPagerToPagerPhotoMgmt (pager : Pager) : PagerPhotoMgmt {

  // }
  /*
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
}
