import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

export class PayPalAddress {
  public address_line_1 = '';
  public address_line_2 = '';
  public admin_area_1 = '';
  public admin_area_2 = '';
  public country_code = '';
  public postal_code = '';
  public sitUserKey = '';
  public PayPalAddressId = 0;
  public PayPalOrderId = '';
  public date! : Date;
  // ----------------------------------------------------------------------------------
  constructor() {
    this.date = new Date;
  }
  // ----------------------------------------------------------------------------------
  public copy ( from : PayPalAddress ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.address_line_1 = from.address_line_1;
      this.address_line_2 = from.address_line_2;
      this.admin_area_1 = from.admin_area_1;
      this.admin_area_2 = from.admin_area_2;
      this.country_code = from.country_code;
      this.postal_code = from.postal_code;
      this.sitUserKey = from.sitUserKey;
      this.PayPalOrderId = from.PayPalOrderId;
      this.date = from.date;
    }
  }
  // ----------------------------------------------------------------------------------
  public toStringCsv (): string
  {
    let payPalAddressString = this.address_line_1 + ', ' + this.address_line_2 + ', ' + this.admin_area_1 + ', '
      + this.admin_area_2 + ', ' + this.country_code + ', ' + this.postal_code + ', ' + this.PayPalOrderId + ', ' + this.date;
    return payPalAddressString;
  }
  // ----------------------------------------------------------------------------------
  public toString (): string
  {
    let outString = '' +
      '{\n address_line_1: ' +
      this.address_line_1 +

      ',\n address_line_2: ' +
      this.address_line_2 +

      ',\n min_area_1: ' +
      this.admin_area_1+

      ',\n admin_area_2: ' +
      this.admin_area_2 +

      ',\n' +
      'country_code:' +
      this.country_code +

      ',\n' +
      'postal_code:' +
      this.postal_code +

      ',\n PayPalOrderId: ' +
      this.PayPalOrderId +

      ',\n date: ' +
      this.date +
      ',\n sitUserKey: ' +
      this.sitUserKey +

      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
