
<!--(tap)="onTap($event)" -->
<div class="flex-container" style="top:85px;position: relative; height: 60%; width:100%;">
  <div [@slideYRotateY] id="landingPageComponentId" class="flex-text-center increase"
       style="position:absolute; clear: both; display: block; width: auto; height: 4em; padding: 0.5rem;">
    <!--before-loggedin-->
    <div *ngIf="!isLoggedIn || signedInUserId === 0">
      <div class="flex-parent-col">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('about', 0);" class="colN boxShadowDark hvr-underline-from-center" style="margin-top:43px;">
          <div class="inner" style="margin:39px 0 0 -5px;">
            <div class="inner-button2">
              About
            </div>
          </div>
        </a>
        <a routerLink="" routerLinkActive="active" (click)="setRoute('terms', 0);" class="colS boxShadowDark hvr-underline-from-center" style="margin-top:39px;">
          <div class="inner" style="margin:39px 0 0 -11px;">
            <div class="inner-button2">
              Terms
            </div>
          </div>
        </a>
      </div>
      <div class="flex-parent-row" style="margin: -200px 0 0 -19px; position: absolute;">
        <!--routerLink="" routerLinkActive="active"-->
        <a routerLink="" routerLinkActive="active" (click)="setRoute('login', 0);" class="rowW boxShadowDark hvr-underline-from-center"
           [ngClass]="{'loggedIn':isLoggedIn, 'loggedOut':!isLoggedIn}">
          <div class="inner" style="margin:39px 0 0 0px;">
            <div class="inner-buttonGld2">
              Login
            </div>
          </div>
        </a>
        <a routerLink="" routerLinkActive="active" (click)="setRoute('signup', 0);" class="rowE boxShadowDark hvr-underline-from-center"
           [ngClass]="{'loggedIn':isLoggedIn, 'loggedOut':!isLoggedIn}">
          <div class="inner" style="margin:39px 0 0 -5px;">
            <div class="inner-buttonGld2">
              Signup free
            </div>
          </div>
        </a>
      </div>
    </div>
    <!--end of before-loggedin-->
    <!--after-loggedin-->
    <div *ngIf="isLoggedIn || signedInUserId > 0">
      <div class="flex-parent-col">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('myChats', signedInUserId);" class="colN boxShadowDark hvr-underline-from-center" style="margin-top:43px;">
          <div class="inner" style="margin:39px 0 0 -5px;">
            <div class="inner-button2">
              Chat
            </div>
          </div>
        </a>
        <a routerLink="" routerLinkActive="active" (click)="setRoute('myEnotes', signedInUserId);" class="colS boxShadowDark hvr-underline-from-center" style="margin-top:39px;">
          <div class="inner" style="margin:39px 0 0 -11px;">
            <div class="inner-button2">
              Notification
            </div>
          </div>
        </a>
      </div>


      <div class="flex-parent-row" style="margin: -200px 0 0 -19px; position: absolute;">
        <a routerLink="" routerLinkActive="active" (click)="setRoute('logout', signedInUserId);" class="rowW boxShadowDark hvr-underline-from-center"
           [ngClass]="{'loggedIn':isLoggedIn, 'loggedOut': !isLoggedIn}">
          <div class="inner" style="margin:39px 0 0 0px;">
            <div class="inner-buttonGld2">
              Logout
            </div>
          </div>
        </a>
        <a routerLink="" routerLinkActive="active" (click)="setRoute('profileTileScroll', signedInUserId);" class="rowE boxShadowDark hvr-underline-from-center"
           [ngClass]="{'loggedIn':isLoggedIn, 'loggedOut':!isLoggedIn}">
          <div class="inner" style="margin:39px 0 0 -5px;">
            <div class="inner-buttonGld2">
              Members
            </div>
          </div>
        </a>
      </div>
    </div>
    <!--end of after-loggedin-->
  </div>
</div>
