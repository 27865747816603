'use-strict'
import { AnimationBuilder, animate, animateChild, group, query, state, style, transition, trigger } from '@angular/animations';

/*
  * ==========================================================================
  *  slideInOut-Animations
  * --------------------------------------------------------------------------
  */
// import the required animation functions from the angular animations module
// ref: https://jasonwatmore.com/post/2019/11/04/angular-8-router-animation-tutorial-example
export const slideInOutAnimation =
  // trigger name for attaching this animation to an element using the [@triggerName] syntax
  trigger('slideInOutAnimation', [

    // end state styles for route container (host)
    state('*', style({
      // the view covers the whole screen with a semi tranparent background
      position: 'relative',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(255, 255, 255, 0)'
    })),

    // route 'enter' transition
    transition(':enter', [

      // styles at start of transition
      style({
        // start with the content positioned off the right of the screen, 
        // -400% is required instead of -100% because the negative position adds to the width of the element
        right: '-400%',

        // start with background opacity set to 0 (invisible)
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }),

      // animation and styles at end of transition
      animate('.5s ease-in-out', style({
        // transition the right position to 0 which slides the content into view
        right: 0,

        // transition the background opacity to 0.8 to fade it in
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
      }))
    ]),

    // route 'leave' transition
    transition(':leave', [
      // animation and styles at end of transition
      animate('.5s ease-in-out', style({
        // transition the right position to -400% which slides the content out of view
        right: '-400%',

        // transition the background opacity to 0 to fade it out
        backgroundColor: 'rgba(0, 0, 0, 0)'
      }))
    ])
  ]);
/*
  * ==========================================================================
  *  Animations
  * --------------------------------------------------------------------------
  */
export const Animations = [
  
  // <div class="dropdown-menu show" [@dropdownAnimation]="{
  //      value: isDropdownOpen, params: {numberOfDropdownItems: dropdownItems?.length * 45}}" >
  //  <a class="dropdown-item" *ngFor="let item of dropdownItems">{{ item }}</a>
  // </div>
   
  trigger('dropdownAnimation', [
    state(
      'true',
      style({
        maxHeight: '{{numberOfDropdownItems}}px',
        overflow: 'scroll',
        opacity: 1,
      }),
      { params: { numberOfDropdownItems: 1}},
    ),
    state(
      'false',
      style({
        maxHeight: '0px',
        overflow: 'hidden',
        opacity: 0,
      })
    ),
    transition('true => false', animate('600ms ease-in-out')),
    transition('false => true', animate('800ms ease-in-out')),
  ]),
  trigger('glideUpGrowShrink', [
    transition(':enter', [
      style({ transform: 'translateY(350%) scale(0.1)' }),
      animate('800ms ease-in', style({ transform: 'translateY(0%) scale(1)' })),
    ]),
    transition(':leave', [
      animate('800ms ease-in', style({ transform: 'translateX(300%) scale(0.1)' })),
    ]),
  ]),
  trigger('growShrink', [
    state(
      'true',
      style({
        minHeight: '{{scale}}rem',
        fontSize: '{{scale}}rem',
        overflow: 'hidden',
        opacity: 1,
      }),
      { params: { scale: 10}},
    ),
    state(
      'false',
      style({
        minHeight: '10rem',
        fontSize: '10rem',
        overflow: 'hidden',
        opacity: 0,
      })
    ),
    transition('true => false', animate('600ms ease-out')),
    transition('false => true', animate('1000ms ease-in')),
  ]),

  trigger('rotateInSlideOutVanish', [
    transition(':enter', [
      // style({ transform: 'translateX(-100%)' }),
      style({ transform: 'translateY(-100%)'}),
      
    // style({ transform: 'scale(0.1)'}),
    // animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
       
      animate('1000ms ease-in-out', style({ transform: 'translateY(-50%) rotateY(360deg)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      animate('900ms ease-in-out', style({ transform: 'translateX(-100%) scale(0.1)'})),
      
     // animate('900ms ease-out', style({ transform: 'translateY(-100%)' })),
     // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
       
    ]),
  ]),

  trigger('slideX', [
    transition(':enter', [
      style({ transform: 'translateX(-100%)'}),
      // style({ transform: 'scale(0.1)'}),
      animate('1000ms ease-in', style({ transform: 'translateX(0%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      animate('700ms ease-in', style({ transform: 'translateX(-100%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ]),
  ]),
  trigger('slide-X', [
    transition(':enter', [
      style({ transform: 'translateX(350%)'}),
      // style({ transform: 'scale(0.1)'}),
      animate('1000ms ease-in', style({ transform: 'translateX(0%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      animate('700ms ease-in', style({ transform: 'translateX(-100%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ]),
  ]),
  trigger('slideXRotateX', [
    transition(':enter', [
      // style({ transform: 'translateX(-100%)' }),
      style({ transform: 'translateX(-100%)'}),
      
     // style({ transform: 'scale(0.1)'}),
     // animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
       
      animate('1000ms ease-in-out', style({ transform: 'translateX(0%) rotateX(360deg)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      // animate('500ms ease-in', style({ transform: 'translateX(-100%)' })),
      animate('1000ms ease-in-out', style({ transform: 'translateX(-100%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ]),
  ]),
  trigger('slideXRotateY', [
    transition(':enter', [
      // style({ transform: 'translateX(-100%)' }),
      style({ transform: 'translateX(-100%)'}),
      
     // style({ transform: 'scale(0.1)'}),
     // animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
       
      animate('800ms ease-in-out', style({ transform: 'translateX(0%) rotateY(360deg)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      // animate('500ms ease-in', style({ transform: 'translateX(-100%)' })),
      animate('700ms ease-in-out', style({ transform: 'translateX(-100%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ]),
  ] ),
  trigger( 'slide-XRotateY', [
    transition( ':enter', [
      style( { transform: 'translateX(350%)' } ),
      // style({ transform: 'scale(0.1)'}),
      animate( '1000ms ease-in', style( { transform: 'translateX(0%) rotateY(360deg)' } ) ),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ] ),
    transition( ':leave', [
      animate( '700ms ease-in', style( { transform: 'translateX(-100%)' } ) ),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ] ),
  ] ),
  trigger('slideY', [
    transition(':enter', [
      // style({ transform: 'translateX(-100%)' }),
      style({ transform: 'translateY(-100%)'}),
      
     // style({ transform: 'scale(0.1)'}),
     // animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
       
      animate('700ms ease-out', style({ transform: 'translateY(0%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      // animate('500ms ease-in', style({ transform: 'translateX(-100%)' })),
      animate('500ms ease-out', style({ transform: 'translateY(300%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ]),
  ]),

  trigger('slide-Y', [
    transition(':enter', [
      // style({ transform: 'translateX(-100%)' }),
      style({ transform: 'translateY(200%)'}),
      
     // style({ transform: 'scale(0.1)'}),
     // animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
       
      animate('800ms ease-out', style({ transform: 'translateY(0%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      animate('500ms ease-in', style({ transform: 'translateX(-100%)'})),
      
     // animate('1000ms ease-out', style({ transform: 'translateY(300%)' })),
     // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
       
    ]),
  ]),
  trigger('slideYRotateY', [
    transition(':enter', [
      // style({ transform: 'translateX(-100%)' }),
      style({ transform: 'translateY(-100%)'}),
      
     // style({ transform: 'scale(0.1)'}),
     // animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
       
      animate('1000ms ease-out', style({ transform: 'translateY(0%) rotateY(360deg)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      // animate('500ms ease-in', style({ transform: 'translateX(-100%)' })),
      animate( '1000ms ease-out', style( { transform: 'translateY(-100%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ]),
  ]),
  trigger('slideYRotateX', [
    transition(':enter', [
      // style({ transform: 'translateX(-100%)' }),
      style({ transform: 'translateY(-100%)'}),
      
      // style({ transform: 'scale(0.1)'}),
      // animate('500ms ease-in', style({ transform: 'translateX(0%)' })),
       
      animate('800ms ease-out', style({ transform: 'translateY(0%) rotateX(360deg)'})),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      // animate('500ms ease-in', style({ transform: 'translateX(-100%)' })),
      animate('800ms ease-out', style({ transform: 'translateY(-100%)'})),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ]),
  ]),
  trigger('slideInYSlideOutX', [
    transition(':enter', [
      // style({ transform: 'translateX(-100%)' }),
      style({ transform: 'translateY(-100%)' }),

      // style({ transform: 'scale(0.1)'}),
      // animate('500ms ease-in', style({ transform: 'translateX(0%)' })),

      animate('800ms ease-out', style({ transform: 'translateY(0%) translateX(0%)' })),
      // animate('500ms ease-in', style({ transform: 'scale(1)' }))
    ]),
    transition(':leave', [
      // animate('500ms ease-in', style({ transform: 'translateX(-100%)' })),
      animate('800ms ease-out', style({ transform: 'translateX(-10%)' })),
      // animate('500ms ease-in', style({ transform: 'scale(0.1)' }))
    ]),
  ]),
  trigger( 'slideYGrow', [
    transition( ':enter', [
      // style({ transform : 'translateX(-100%)' }),
      style( { transform: 'translateY(-50%) scale(0.1)' } ),
      /*
       * style({ transform : 'scale(0.1)'}),
       * animate('500ms ease-in', style({ transform : 'translateX(0%)' })),
       */
      animate( '800ms ease-out', style( { transform: 'translateY(0%) scale(1)' } ) ),
      // animate('500ms ease-in', style({ transform : 'scale(1)' }))
    ] ),
    transition( ':leave', [
      // animate('500ms ease-in', style({ transform : 'translateX(-100%)' })),
      animate( '500ms ease-out', style( { transform: 'translateY(-100%) scale(0.1)' } ) ),
      // animate('500ms ease-in', style({ transform : 'scale(0.1)' }))
    ] ),
  ] ),
  trigger('slideYVanishX', [
    transition('* <=> home', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':enter', animateChild(), { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('900ms ease-out', style({ transform: 'translateX(70%) scale(0.01)'}))], { optional: true}),
        query(':enter', [animate('1200ms ease-in-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
    ]),
  ] ),
  trigger( 'yUp-XOut', [
    transition( ':enter', [
      // style({ transform : 'translateX(-100%)' }),
      style( { transform: 'translateY(125%)' } ),
      style( { transform: 'scale(0.1)' } ),
      animate( '800ms ease-in', style( { transform: 'translateY(0%)' } ) ),
      // animate('200ms ease', style({ transform : 'translateY(10%)' })),
      animate( '900ms ease-in', style( { transform: 'scale(1)' } ) ),
    ] ),
    transition( ':leave', [
      // animate('500ms ease-in', style({ transform : 'translateX(-100%)' })),
      animate( '900ms ease-in-out', style( { transform: 'translateX(300%)' } ) ),
      animate( '500ms ease-in', style( { transform: 'scale(0.1)' } ) ),
    ] ),
  ] ),
  trigger( 'slideInOut', [
    transition( ':enter', [
      style( { transform: 'translateY(-100%)' } ),
      
       // style({ transform : 'scale(0.1)'}),
       // animate('1000ms ease-in-out', style({ transform : 'translateX(0%)' })),
       
      animate( '500ms ease-out', style( { transform: 'translateY(0%)' } ) ),
      // animate('500ms ease-in', style({ transform : 'scale(1)' }))
    ] ),
    transition( ':leave', [
      animate( '400ms ease-in-out', style( { transform: 'translateX(-100%)' } ) ),
      
       // animate('500ms ease-out', style({ transform : 'translateY(-100%)' })),
       // animate('500ms ease-in', style({ transform : 'scale(0.1)' }))
       
    ] ),
  ] ),
  
    // <div [@blindsDown]="{value: isDropdownOpen, params: {heightInRem: paragraphs?.length * 1.5}}" >
    //  <a *ngFor="let paragraph of paragraphs" (click)="isDropdownOpen=!isDropdownOpen">{{ paragraph }}</a>
    // </div>
   
  trigger('blindsDown', [
    state(
      'show',
      style({
        height: '{{heightInRem}}rem',
        opacity: 1,
        transform: 'translateY(0%)',
      }),
      { params: { heightInRem: 1}},
    ),
    state(
      'hide',
      style({
        height: 0,
        opacity: 0,
        transform: 'translateY(-500%)',
      })
    ),

    transition('show => hide', animate('1500ms ease-in-out')),
    transition('hide => show', animate('1100ms ease-in-out')),
  ]),
  
   // <accordion style="margin: 0.25rem 0rem 0.5rem 0rem;width:100%;">
   // <accordion-group class="col-sm-12" [title]="accrdTitle" style="width:103%;margin:0.05rem 0 0 -1.2rem;">
   //   <div [@fader]="stateName"  class="col-lg-12 panel-border-primary"></div>
   // </accordion-group>
   // </accordion>
   //
   //angular-component
   // private opened: boolean = false;
   // get stateName() {
   //  return this.opened ? 'show' : 'hide'
   // }
   
  trigger('fader', [
    state(
      'show',
      style({
        height: 400,
        // opacity: 1
        transform: 'translateX(0%)',
      })
    ),
    state(
      'hide',
      style({
        height: 0,
        // opacity: 0
        transform: 'translateX(-500%)',
      })
    ),

    transition('show => hide', animate('700ms ease-in')),
    transition('hide => show', animate('700ms ease-out')),
  ]),
  /*
   * ==========================================================================
   *  Router-Animations
   * --------------------------------------------------------------------------
   */
  trigger('routeAnimations', [
    // ------------------------------------------------------------
    transition('* <=> about', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      query(':enter', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('900ms ease-out', style({ transform: 'translateX(50%) scale(0.1)'}))], { optional: true}),
        query(':enter', [animate('900ms ease-in-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
    ]),
    // ------------------------------------------------------------
    transition('* <=> home', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true}
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':enter', animateChild(), { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('900ms ease-out', style({ transform: 'translateX(70%) scale(0.01)'}))], { optional: true}),
        query(':enter', [animate('1200ms ease-in-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      // // query(':enter, :leave', [
      // //  // style({
      // //  //  position: 'absolute',
      // //  //  top: '-100%',
      // //  //  left: 0,
      // //  //  width: '100%'
      // //  // })
      // //  style({ transform: 'translateY(-100%) scale(0.1)' }),
      // // ], { optional: true }),
      // // query(':enter', [
      // //  // style({ top: '-100%', transform: 'rotateY(360deg)' })
      // //  animate('500ms ease-out', style({ transform: 'translateY(-100%) scale(0.1) rotateY(360deg)' })),
      // // ], { optional: true }),
      // // query(':leave', animateChild(), { optional: true }),
      // // group([
      // //  query(':leave', [
      // //    animate('500ms ease-out', style({ transform: 'translateX(-100%) rotateY(-360deg)' }))
      // //  ], { optional: true }),
      // //  query(':enter', [
      // //    animate('500ms ease-out', style({ transform: 'translateY(0%) rotateY(360deg)' }))
      // //  ], { optional: true })
      // // ]),
      // // query(':enter', animateChild(), { optional: true }),
    ]),
    
     // ------------------------------------------------------------
     //  Stable. Do not change.
     // ------------------------------------------------------------
    transition('* <=> login', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':enter', animateChild(), { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('900ms ease-out', style({ transform: 'translateX(70%) scale(0.01)'}))], { optional: true}),
        query(':enter', [animate('1200ms ease-in-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      query(':enter', animateChild(), { optional: true}),
      
       //  query(':leave', animateChild(), { optional: true }),
       //  group([
       //    query(':leave', [
       //      animate('500ms ease-out', style({ transform: 'translateX(50%) scale(0.1)' }))
       //    ], { optional: true }),
       //    query(':enter', [
       //      animate('500ms ease-out', style({ transform: 'translateY(0%) scale(1)' }))
       //    ], { optional: true })
       //  ]),
       //  query(':enter', animateChild(), { optional: true }),
       
    ]),
    
    // -------------------------------------------------------------
    // UnderConstruction!!
    // -------------------------------------------------------------
    transition('* <=> logout', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(
        ':enter',
        [
          // style({ top: '-100%', transform: 'rotateY(360deg)' })
          style({ transform: 'translateY(-1%) scale(0.1)'}),
        ],
        { optional: true},
      ),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('800ms ease-out', style({ transform: 'translateX(50%) scale(0.1)'}))], { optional: true}),
        query(':enter', [animate('900ms ease-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      query(':enter', animateChild(), { optional: true}),
    ]),

    
    // ------------------------------------------------------------
    //  Stable. Do not change.
    // ------------------------------------------------------------
     
    transition('* <=> membersIndividually', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('700ms ease-in-out', style({ transform: 'translateX(-50%) scale(0.1)'}))], { optional: true}),
        query(':enter', [animate('900ms ease-in-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      query(':enter', animateChild(), { optional: true}),
    ]),
    // ------------------------------------------------------------
    transition('* <=> memberView', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('700ms ease-out', style({ transform: 'translateX(50%) scale(0.1) '}))], { optional: true}),
        query(':enter', [animate('900ms ease-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      query(':enter', animateChild(), { optional: true}),
    ]),
    // ------------------------------------------------------------
    transition('* <=> preference', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('700ms ease-out', style({ transform: 'translateX(50%) scale(0.1)'}))], { optional: true}),
        query(':enter', [animate('900ms ease-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      query(':enter', animateChild(), { optional: true}),
    ]),
    // ------------------------------------------------------------
    transition('* <=> preScreen', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('700ms ease-out', style({ transform: 'translateX(50%) scale(0.1)'}))], { optional: true}),
        query(':enter', [animate('900ms ease-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      query(':enter', animateChild(), { optional: true}),
    ]),
    // ------------------------------------------------------------
    transition('* <=> profileInfo', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('700ms ease-out', style({ transform: 'translateX(50%) scale(0.1)'}))], { optional: true}),
        query(':enter', [animate('900ms ease-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      query(':enter', animateChild(), { optional: true}),
    ]),
    
    // ------------------------------------------------------------
    //  Stable. Do not change.
    // ------------------------------------------------------------
    transition('* <=> register', [
      style({ position: 'relative'}),
      query(
        ':enter, :leave',
        [
          style({
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
          }),
        ],
        { optional: true},
      ),
      query(':enter', [style({ transform: 'translateY(-1%) scale(0.1)'})], { optional: true}),
      query(':leave', animateChild(), { optional: true}),
      group([
        query(':leave', [animate('700ms ease-in-out', style({ transform: 'translateX(-50%) scale(0.1)'}))], { optional: true}),
        query(':enter', [animate('900ms ease-in-out', style({ transform: 'translateY(0%) scale(1)'}))], { optional: true}),
      ]),
      query(':enter', animateChild(), { optional: true}),
    ]),
    
     // -------------------------------------------------------------
     // Duplicate
     // transition('* <=> register2', [
     //  style({ position: 'relative' }),
     //  query(':enter, :leave', [
     //    style({
     //      position: 'absolute',
     //      top: 0,
     //      left: 0,
     //      width: '100%'
     //    })
     //  ], { optional: true }),
     //  query(':enter', [
     //    style({ left: '100%' })
     //  ], { optional: true }),
     //  query(':leave', animateChild(), { optional: true }),
     //  group([
     //    query(':leave', [
     //      animate('700ms ease-in-out', style({ left: '100%' }))
     //    ], { optional: true }),
     //    query(':enter', [
     //      animate('900ms ease-in-out', style({ left: '0%' }))
     //    ], { optional: true })
     //  ]),
     //  query(':enter', animateChild(), { optional: true }),
     // ]),
     // ------------------------------------------------------------
     
  ]),
];
