
export class ClientHttpDataModel {
  public action = '';
  public box : any;
  public commActIds : number[] = [];
  public cryptUserKey = '';
  public contentType = '';
  public date = '';
  public entityName = '';
  public key = '';
  public id = 0;
  public message = '';
  public modelName = '';
  public nonce: any;
  public profileId = 0;
  public photoBnId = 0;
  public signedInUserId = 0;
  public signedInUserKey = '';
  public sitUserId = 0;
  public sitUserKey = '';
  public value = '';
  constructor() {
  }
}
