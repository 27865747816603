import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Name } from './name.model';

export class PayPalPayer
{
  public country_code = '';
  public date = '';
  public email_address = '';
  public fullName = '';
  public payer_id = '';
  public orderID = '';
  public sitUserKey = '';
  public name : Name = new Name();
  // ----------------------------------------------------------------------------------
  constructor ()
  {
  }
  // ----------------------------------------------------------------------------------
  public copyFrom( from: PayPalPayer ): void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.country_code = from.country_code;
      this.date = from.date;
      this.email_address = from.email_address;
      this.fullName = from.fullName;
      this.payer_id = from.payer_id;
      this.sitUserKey = from.sitUserKey;
      this.name.copy( from.name );
    }
  }
  // ----------------------------------------------------------------------------------
  public toString (): string
  {
    // return JSON.stringify( this );
    // OR
    let outString = '' +
      '{\n country_code: ' +
      this.country_code +
      
       ',\n' +
       'name.given:' +
      this.name.given_name +

      ',\n' +
      'name.surname:' +
      this.name.surname +

      ',\n email_address: ' +
      this.email_address +

      ',\n' +
      'fullName:' +
      this.fullName +

      ',\n orderID: ' +
      this.orderID +

      ',\n payer_id: ' +
      this.payer_id +

      ',\n' +
      'sitUserKey:' +
      this.sitUserKey +

      ',\n' +
      'name' +
      JSON.stringify(this.name) +
      
      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
