
export class BoxNonceEntity {
  public box: any;
  public boxStr = '';
  public cryptUserKey = '';
  public date = '';
  public endMarker: any;
  public entityName = '';
  public id = 0;
  public key = '';
  public message = '';
  public nonce: any;
  public nonceStr = '';
  public sitUserId = 0;
  constructor() {
  }
  copy(from: BoxNonceEntity): any {
    return () => {
      this.box = from.box;
      this.boxStr = from.boxStr;
      this.cryptUserKey = from.cryptUserKey;
      this.date = from.date;
      this.endMarker = from.endMarker;
      this.entityName = from.entityName;
      this.id = from.id;
      this.key = from.key;
      this.message = from.message;
      this.nonce = from.nonce;
      this.nonceStr = from.nonceStr;
      this.sitUserId = from.sitUserId;
    };
  }
  copyIfExists(from: BoxNonceEntity): any {
    return () => {
      this.box = from.box ? from.box : this.box;
      this.boxStr = from.boxStr ? from.boxStr : this.boxStr;
      this.cryptUserKey = from.cryptUserKey ? from.cryptUserKey : this.cryptUserKey;
      this.date = from.date ? from.date : this.date;
      this.endMarker = from.endMarker;
      this.entityName = from.entityName ? from.entityName : this.entityName;
      this.id = from.id > 0 ? from.id : this.id;
      this.key = from.key ? from.key : this.key;
      this.message = from.message ? from.message : this.message;
      this.nonce = from.nonce ? from.nonce : this.nonce;
      this.nonceStr = from.nonceStr ? from.nonceStr : this.nonceStr;
      this.sitUserId = from.sitUserId > 0 ? from.sitUserId : this.sitUserId;
    };
  }

  toString(): string {
    return '{box:' + this.box + ',' +
      'boxStr:' + this.boxStr + ',' +
      'cryptUserKey:' + this.cryptUserKey + ',' +
      'date:' + this.date + ',' +
      'endMarker:' + this.endMarker + ',' +
      'entityName:' + this.entityName + ',' +
      'id:' + this.id + ',' +
      'key:' + this.key + ',' +
      'nonce:' + this.nonce + ',' +
      'nonceStr:' + this.nonceStr + ',' +
      'sitUserId:' + this.sitUserId + '}';
  }
}
