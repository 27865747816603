'use-strict'
import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

/*
 * import { SafePipe } from 'safe-pipe';
 * Ref;https:// medium.com/@swarnakishore/angular-safe-pipe-implementation-to-bypass-domsanitizer-stripping-out-content-c1bf0f1cc36b
 */

@Pipe({
  name: 'safe',
})
export class SafeKvPipe implements PipeTransform {
  constructor(protected sanitizer: DomSanitizer) { }

  public transform(value: any, type: any): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      case 'product':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      case 'pic':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
/*
 * =================================================================
 * public transform(value: any, type: string): any {
 * switch (type) {
 * Note: the above was transformed on the basis of this ref:https:// github.com/angular/angular/issues/33028
 * case 'html': return this.sanitizer.sanitize(SecurityContext.HTML, value);
 * case 'style': return this.sanitizer.sanitize(SecurityContext.STYLE, value);
 * case 'script': return this.sanitizer.sanitize(SecurityContext.SCRIPT, value);
 * case 'url': return this.sanitizer.sanitize(SecurityContext.URL, value);
 * case 'resourceUrl': return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
 * default: throw new Error(`Invalid safe type specified: ${type}`);
 */
