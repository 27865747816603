import { DictionaryService } from './dictionaryService.service';
import { DictionaryIOService } from './dictionaryIOService.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DictionaryServiceService {
  constructor (
    private dictionaryService : DictionaryService,
    private dictionaryIOService : DictionaryIOService,
  )
  {
  } 
  // ------------------------------------------------------------------
  setDictionaryService (ds : DictionaryService): void {
    this.dictionaryService = ds;
     return;
   }
  getDictionaryService () : DictionaryService {
    return this.dictionaryService;
   }
  // ------------------------------------------------------------------
  setDictionaryIOService (dios : DictionaryIOService) : void {
    this.dictionaryIOService = dios;
    return;
  }
  getDictionaryIOService () : DictionaryIOService {
    return this.dictionaryIOService;
  }
  // ------------------------------------------------------------------
}
