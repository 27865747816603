import {EPLStyleEventEmitter, Event, PropertyChangeEvent} from "./EPLStyleEventEmitter";
import { StringUtils } from "./StringUtils";

/**
 * Created by eric.perron on 19/07/2017.
 */

export class Key extends EPLStyleEventEmitter
{
  public id:string = '';
  public get label():string { return this._label;};
  public get value():any { return this._value;};

  public set label(v:string)
  {
    if(this._label == v)
      return;

    // prepare the change event
    let e:PropertyChangeEvent = new PropertyChangeEvent(PropertyChangeEvent.CHANGE, this);
    e.newValue = v;
    e.oldValue = this._label;
    e.propertyName = "label";

    // change the value
    this._label = v;

    // dispatch the event
    this.emit(e);

  };


  public set value(v:any)
  {
    if(this._value == v)
      return;

    // prepare the change event
    let e:PropertyChangeEvent = new PropertyChangeEvent(PropertyChangeEvent.CHANGE, this);
    e.newValue = v;
    e.oldValue = this._value;
    e.propertyName = "value";

    // change the value
    this._value = v;

   // dispatch the event
    this.emit(e);

  };



  private _label:string;
  private _value:any;

  constructor(label:string = '', value:any, id:string = '')
  {
    super()

    this._label = label;
    this._value = value;

    if(!StringUtils.isNullOrEmpty(id))
      this.id = id;

  }

}
