import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

export class PayPalItem_Total {
  public currency_code = '';
  public value = '';
  public date = '';
  public orderID = '';
  public sitUserKey = '';
  // ----------------------------------------------------------------------------------
  constructor() {
    this.currency_code = '';
    this.value = '';
  }
  // ----------------------------------------------------------------------------------
  public copy ( from : PayPalItem_Total ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.currency_code = from.currency_code;
      this.value = from.value;
      this.orderID = from.orderID;
      this.date = from.date;
      this.sitUserKey = from.sitUserKey;
    }
  }

  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n' +
      ',\n currency_code: ' +
      this.currency_code +

      ',\n' +
      'value: ' +
      this.value +

      ',\n' +
      'date: ' +
      this.date +

      ',\n' +
      'orderID: ' +
      this.orderID +

      ',\n' +
      'sitUserKey: ' +
      this.sitUserKey +

      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
