<ul id="myStuffComponentId"
    [ngClass]="{'myStuffOpen': '!isMyStuffClosedBool..isClosed', 'myStuffOpenHold': 'iisMyStuffClosedBool.isClosed'}"
    class="hvr-underline-from-center hvr-icon increase shadowSilverInner"
    style="padding: 0.01rem;border:none;list-style:none;font-size:0.6rem;width:4.5rem;height:auto;min-height:1.1rem;border-radius:7px;z-index:11;">  
  <li expandMenu style="margin:0;padding:0;">
    <a (click)="toggleIsClosed();">
      <div class="kvBttnxxS text-center" style="margin:0.01rem 0.25rem 0.01rem 0.01rem;padding:0.01rem;width:4.5rem;display:inline;">
        <div style="font-size: 0.6rem;margin:-0.05rem 0 0 -0.1rem;" align="center">
          <span *ngIf="loginSuccess && loginSuccess.profileName && loginSuccess.profileName.length === 0" class="coinV neonProfName">?</span>
          <span *ngIf="loginSuccess && loginSuccess.profileName && loginSuccess.profileName.length > 0" class="coinV neonProfName">
            {{loginSuccess.profileName}}

            <span *ngIf="!isMyStuffClosedBool.isClosed" class="glyphicon glyphicon-triangle-top hvr-icon" style="padding: 0; font-size: 0.5rem;" aria-hidden="true"></span>
            <span *ngIf="isMyStuffClosedBool.isClosed" class="glyphicon glyphicon-cog hvr-icon coin" style="padding:0;font-size:0.5rem;" aria-hidden="true"></span>
          </span>
        </div>
      </div>
    </a>
  </li>

  <li style="margin:0;padding:0;">
    <ul *ngIf="!isMyStuffClosedBool.isClosed" [ngClass]="{'submenu':true}" align="left"
        style="width: 4.5rem;
              height: auto;
              min-height: 3.5rem;
              list-style: none;
              margin:0rem;
              padding:0.15rem 0.05rem 0.15rem 0.05rem;
              z-index: 11;">
      <li class="increase text-center" style="margin: 10px 0 5px 10px; padding: 5px 0px 5px 0px;" >
        <!--routerLink="/memberm/myPhotos" routerLinkActive="active"-->
          <a [routerLink]="" aria-expanded="true" (click)="setRoute('myPhotos', signedInUserId);">
            <span class="glyphicon glyphicon-camera hvr-icon" style="font-size: 0.5rem; color: orange; padding: 1px;" aria-hidden="true"></span>
            <span class="hvr-icon" style="font-size: small;opacity: 1;" aria-hidden="true"> myPhotos</span>
          </a>
      </li>

      <li>
        <a [routerLink]="" aria-expanded="true"  (click)="setRoute('memberView', signedInUserId);">
          <div class="increase text-center" style="margin: 5px 0 5px 0px; padding: 2px 0 2px 0px;">
            <img class="img img-circle hvr-icon" src="/photos/profile.png"
                 style="background: transparent;
                          z-index: 11;
                          height: 0.65rem;
                          width: 0.65rem;
                          padding: 1px;" />
            <span class="hvr-icon" style="font-size: small; opacity: 1;" aria-hidden="true"> viewProfile</span>
          </div>            
        </a>
      </li>

      <li  class="increase text-center" style="margin:5px 0 5px 10px;padding: 5px 0px 5px 0px;" >
        <!--[routerLink]="['/profilem/memberEdit', loginSuccess.signedInUserId]" routerLinkActive="active" -->
        <a [routerLink]="" aria-expanded="true" (click)="setRoute('memberEdit',  loginSuccess.signedInUserId);">
            <!--img class="img img-circle" src="/photos/profile.png"
                 style="background: transparent;
                          z-index: 100;
                          height: 0.5rem;
                          width: 0.5rem;
                          text-align: center;" /-->
            <span class="glyphicon glyphicon-pencil hvr-icon" style="font-size: 0.5rem; color: orange; padding: 1px;" aria-hidden="true"></span>
            <span class="hvr-icon" style="font-size: small; opacity: 1;" aria-hidden="true"> editProfile </span>
        </a>
      </li>
    </ul>
  </li>
</ul>

<!--span class="glyphicon glyphicon-picture hvr-icon"
      style="font-size:0.5rem;
                           color: orange;" aria-hidden="true">
</span-->
