'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { GlyphiconMenuService } from '../../../services/GlyphiconService/glypgiconMenuService.service';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { SignedInUserService } from '../../../services/commonServiceService/signedInUserService.service';
import { PhotoAndPrimaryPhotoMgmtService } from '../../../services/photoServiceService/photoAndPrimaryPhotoMgmtService.service';
import { PhotoServiceService } from '../../../services/photoServiceService/photoServiceService.service';
import { ProfileTileService } from '../../../services/profileTileServiceService/profileTileService.service';
import { ProfileTileServiceService } from '../../../services/profileTileServiceService/profileTileServiceService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { CopyServiceStatic } from '../../../services/staticServices/commonStaticServices/copyServiceStatic.service';
import { ModuleComponentLoaderServiceStatic } from '../../../services/staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
// import { ProfileTileService } from '../../../services/profileTileServiceService/profileTileService.service';
// import { ProfileTileServiceService } from '../../../services/profileTileServiceService/profileTileServiceService.service';
import { GlyphiconServiceService } from '../../../services/GlyphiconService/glyphiconServiceService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { LogRegService } from '../../../services/coreServiceService/logRegService.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: [ './avatar.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvatarComponent implements OnInit, OnDestroy, AfterViewInit {
  public bias! : BackgroundImageAnimationService;
  public glyphiconMenuService! : GlyphiconMenuService;
  public logRegService : LogRegService;
  public profileTileService! : ProfileTileService;
  public photoAndPrimaryPhotoMgmtService! : PhotoAndPrimaryPhotoMgmtService;
  public redirectionService! : RedirectionService;
  public rendererService! : RendererService;
  public renderer! : Renderer2;
  public signedInUserService! : SignedInUserService;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isCameraVisible = true;
  public isEyeOpen = false;
  public isMobilevar = false;
  public isPhotoMgmt = false;
  public isMyProfile = true;
  public isSuccess = true;
  public kvMemActGlyphArrForPhotoMgmt: KvMemberActivityGlyph[] = [];
  @Input() loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public profileName = '';
  public profileTile : ProfileTile = new ProfileTile();
  public signedInUserId = 0;
  public signedInUser : SitUser = new SitUser();
  public sitUser : SitUser = new SitUser();
  constructor (
    private avatarElementRef : ElementRef,
    private cdr : ChangeDetectorRef,
    private commonServiceServie : CommonServiceService,
    private coreServiceServie : CoreServiceService,
    private glyphiconServiceService : GlyphiconServiceService,
    private photoServiceService : PhotoServiceService,
    public profileTileServiceService : ProfileTileServiceService,
    private rendererServiceService : RendererServiceService,
    private router : Router, )
  {
    // -----------------------------------------------------------------
    if (this.rendererServiceService) {
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
     
      this.rendererService = this.rendererServiceService.getRendererService() as RendererService;
      this.renderer = this.rendererService.getRenderer();
    }

    if (this.glyphiconServiceService) {
      this.glyphiconMenuService = this.glyphiconServiceService.getGlyphiconMenuService();
    }

    if (this.profileTileServiceService) {
      this.profileTileService = this.profileTileServiceService.getProfileTileService();
    }
    if (this.coreServiceServie) {
      this.logRegService = this.coreServiceServie.getLogRegService();
    }

    if (this.commonServiceServie) {
      this.redirectionService = this.commonServiceServie.getRedirectionService();
      this.signedInUserService = this.commonServiceServie.getSignedInUserService();
    }
    if (this.photoServiceService) {
      this.photoAndPrimaryPhotoMgmtService = this.photoServiceService.getPhotoAndPrimaryPhotoMgmtService();
		}
    this.bias.setAvatarElementRef(this.avatarElementRef);
  }

  //  -------------------------------------------------------------------------
  ngOnInit () : void
  {
    this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
    this.toggleUploadUi();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    // debugger
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) || this.loginSuccess.signedInUserId === 0) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
		}
    if (this.loginSuccess.signedInUserId > 0) {
      this.executeLoginSuccessTasks();
    }
   
    //  -------------------------------------------------------------------------
    EmitterSubjectService.setElementRef(this.avatarElementRef);
    
    //  -------------------------------------------------------------------------
    // debugger;
    EmitterSubjectService.loginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.loginSuccess = result as LoginSuccess; //  JSON.parse(JSON.stringify(result));  
        if (this.loginSuccess.signedInUserId > 0) {
          this.executeLoginSuccessTasks();
        }
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.isLoginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.logoutEmitter.subscribe((result) => {
      // debugger;
      if (result) {
        // debugger;
        this.loginSuccess = new LoginSuccess();
       
        this.signedInUserId = 0;
        this.bias.clearPrimaryImage();
        this.ngAfterViewInit();
        // -----------------------------------------------------------------------
        // NOTE: we do not emitToClearUserData in all logout-listeners, but only here
        //       logout-listener appears in appComponent, navHeaderComponent and navSidebarComponent.
        //       since signedInUser's primary-photo is located here, we make this the point from
        //       where the emitToClearUserData is emitted.
        // -----------------------------------------------------------------------

        // DefaultSetterServiceStatic.clearUserData();
      }
    });
    // -----------------------------------------------------------
    EmitterSubjectService.toggleThisEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        switch ((result as string).toLowerCase()) {
          case 'iseyeclose-memberviewmgmt':
          case 'iseyeclose-myphotomgmt':
          case 'iseyeclose-myprofileviewmgmt':
            this.isEyeOpen = true;
            break;
          default:
            this.isEyeOpen = false;
            break;
				}
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId > 0) {
      this.profileTile = this.setupAvatarProfileTile(this.profileTile);
      // debugger;
    }
    // -------------------------------------------------------------------------
    EmitterSubjectService.currentPageOnGlyphiconChangeEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger;
        var currentItemKvPhoto = result as KvPhoto;
        this.loginSuccess.primaryImage = currentItemKvPhoto.value;
        this.loginSuccess.primaryPhotoBnId = currentItemKvPhoto.photoBnId;
        EmitterSubjectService.setLoginSuccess(this.loginSuccess);
        this.bias.setPrimaryImage('primaryPhotoBnId', true);
        this.ngAfterViewInit();
        // debugger;
      });
    // --------------------------------------------------------------------------
    // Note: We are using upgradedEmitter to emit LoginSuccess because emitting
    //        loginSuccess causes some errors.
    // --------------------------------------------------------------------------
    EmitterSubjectService.upgradedEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        debugger;
        this.loginSuccess = result as LoginSuccess; // JSON.parse(JSON.stringify(result));
        debugger;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    // this.cdr.detectChanges();
  }
  //  ---------------------------------------------------------------------------------
  ngAfterViewInit () : any {
    this.cdr.detectChanges();
    // debugger;
    this.executeLoginSuccessTasks();
    
    return true;
  }
  //  ---------------------------------------------------------------
  ngOnDestroy (): void
  {
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  // ---------------------------------------------------------------------------------
  public executeLoginSuccessTasks () : void {
    // debugger;
    
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (this.loginSuccess.signedInUserId > 0) {
      this.isSuccess = this.loginSuccess.isSuccess;
      this.profileName = this.loginSuccess.profileName;
      this.signedInUserId = this.loginSuccess.signedInUserId;
      this.loginSuccess.primaryImage = this.photoAndPrimaryPhotoMgmtService.getPrimaryImageFromLoginSuccess();
      // debugger;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryImage)) {
        // debugger;
        this.signedInUserService.getSingedInSitUser().subscribe(data => {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)){
            this.signedInUser = data;
            EmitterSubjectService.setSignedInUser(this.signedInUser);
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUser.primaryImage)) {
              this.loginSuccess = new LoginSuccess().copyFromSitUser(this.loginSuccess, this.signedInUser);
              
              EmitterSubjectService.setLoginSuccess(this.loginSuccess);
            }
          }          
        })
      }
      this.bias.setPrimaryImage('primaryPhotoBnId', true);
    }
  }
  // --------------------------------------------------------
  public executeAvatarProfileTileTasks () : void {
    // debugger;
    this.bias.setPrimaryImage('primaryPhotoBnId', true);
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) || this.profileTile.sitUserId === 0) {
      this.profileTileService.getProfileTile(this.loginSuccess.signedInUserId).subscribe(data => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
          // debugger;
          this.profileTile = this.setupAvatarProfileTile(data);
          this.ngAfterViewInit();
        }
      });
    }
    else {
      this.profileTile = this.setupAvatarProfileTile(this.profileTile);
      this.ngAfterViewInit();
      // debugger;
		}
  }
  // ---------------------------------------------------------------------------------
   public executeLogoutSuccessTasks () : void {
    this.isSuccess = false;
    // debugger;
    // this.bias.clearPrimaryImage();
     this.signedInUserId = 0;
     this.ngAfterViewInit();
   }
  //  ---------------------------------------------------------------------------------
  setupAvatarProfileTile (pTile : ProfileTile) : ProfileTile {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) || pTile.sitUserId === 0) {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
        pTile = CopyServiceStatic.copyLoginSuccessToProfileTile(this.loginSuccess);
        this.profileTile = JSON.parse(JSON.stringify(pTile));
        EmitterSubjectService.setAvatarProfileTile(this.profileTile); // will setProfileTile also.
        // debugger;
        return this.profileTile;
      }
    }
    else if (pTile.sitUserId > 0){
      pTile.isPrimaryPhoto = true;
      // debugger;   

      // display the vertical-menues of this tile's kvPhoto if exists:
      // -------------------------------------------------------------
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.kvPhoto)
        || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.kvPhoto.value)
        || pTile.kvPhoto.value.length === 0) {
        pTile.kvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
      }
      // debugger;
      this.profileTile = new ProfileTile().copySourceToDestination(this.profileTile, pTile); // will cureate a KvPhoto model and populate its data from ProfileTile
      EmitterSubjectService.setAvatarProfileTile(this.profileTile); // will setProfileTile also.
      // debugger;
    }
    return this.profileTile;
  }
  // ---------------------------------------------------------------------------------
  toggleUploadUi () {
    // debugger;
    this.isCameraVisible = !this.isCameraVisible;
    // this.isShowPhotoUploadUi.isOpened = !this.isShowPhotoUploadUi.isOpened;

    // open/close the PhotoUploadUI:
    EmitterSubjectService.emitIsCameraVisible(this.isCameraVisible);

    this.mcLoader = new ModuleComponentLoader();
    this.mcLoader.router = this.router;
    this.mcLoader.returnUrl = this.redirectionService.getUrlAddress();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0)
    {
      if (this.isCameraVisible) {
        this.mcLoader.router = this.router;
        this.mcLoader.moduleName = this.mcLoader.moduleName = 'mym';
        this.mcLoader.componentName = this.mcLoader.componentName = 'myPhotoMgmt';

        // debugger;
        this.redirectionService.setRoute(this.mcLoader.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
          this.pagerBrdCrm = data;
        });
        EmitterSubjectService.emitIsShowPhotoUpload(true);    
      }
    }
    else {     
      // debugger;
      //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.mcLoader.componentName)) {
      //  this.mcLoader.moduleName = this.mcLoader.moduleName = 'app';
      //  this.mcLoader.componentName = this.mcLoader.componentName = 'index';
      //  debugger;
      //}
      this.redirectionService.setRoute(this.mcLoader.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
        this.pagerBrdCrm = data;
      });
		}
  }

  // ---------------------------------------------------------------------------------
  toggleIsEyeOpen () : any{
    // debugger;
    EmitterSubjectService.emitToggleThis('isEyeClose-avatar'); // avatar eye is closed
    return true;
  }
  //  ---------------------------------------------------------------------------------
  // Note: Whenever there is a 'my' on the method name, it is of/related to signedInUser:
  //  ---------------------------------------------------------------------------------
  public viewMyProfile () : void {
    // debugger;
    this.mcLoader = new ModuleComponentLoader();
    this.mcLoader.router = this.router;
    this.mcLoader.moduleName = this.mcLoader.moduleName = 'mym';
    this.mcLoader.componentName = this.mcLoader.componentName = 'myProfileViewMgmt';
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
    //  this.mcLoader.id = this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;
    //}
    this.mcLoader.returnUrl = this.redirectionService.getUrlAddress();
    //ndebugger;
    EmitterSubjectService.setMCLoader(this.mcLoader);
    // -----------------------------------------------
    // NOTE:  we do not want to run the executeProfileImagesTasks() in appComponent/avatarComponent
    //        until the page switches to the myProfileViewMgmt page!!
    //        Instead set the necessary variables on the EmitterSubjectService.
    // ----------------------------------------------------------------------------
    //EmitterSubjectService.setIsViewMember(false);
    //EmitterSubjectService.setIsPhotoMgmt(false);
    this.isMyProfile = true;
    EmitterSubjectService.setIsMyProfile(this.isMyProfile);
    // -----------------------------------------------
    this.profileTile = this.setupAvatarProfileTile(this.profileTile);
    
    this.redirectionService.setRoute(this.mcLoader.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
      this.pagerBrdCrm = data;
    });
    // debugger;
    EmitterSubjectService.emitAvatarProfileTile(this.profileTile);    
  }
  // ----------------------------------------------------------------
}
