import { StringUtils } from "./StringUtils";
export class Logger {

  public static TAG:string = "EPL";
  private static _offset:number = 0;
  public static padding:number = 8;

  public static offset(f:Function):void{
    this.offsetMore();
    try{f()} catch(e){
      this.offsetLess();
      throw e;
    }
    this.offsetLess();
  };

  public static async asyncOffset(f:Function):Promise<any>{
    this.offsetMore();
    try{await f()} catch(e){
      this.offsetLess();
      throw e;
    }
    this.offsetLess();
  };


  public static offsetMore(){

    this._offset += 2;
  }

  public static offsetLess(){

    this._offset -= 2;
  }

  private static tag(text:string = ""):string{

    return StringUtils.spacePadding(this.TAG + text, this.padding + this._offset)
  }

  public static title(msg:string):void
  {
    console.info(this.tag() + "--------------------------------");
    console.info(this.tag() + msg);
    console.info(this.tag() + "--------------------------------");
  }


  public static infoFormat(msg:string, values:Array<any>):void {
    msg = StringUtils.Format(msg, values);
    Logger.info(msg);
  }

  public static info(msg:string):void
  {
    console.info(this.tag("[I]") + msg);
  }

  public static debug(msg:string):void
  {
    console.debug(this.tag("[D]") + msg);
  }

  public static warning(msg:string):void
  {
    console.warn("%c"+ this.tag("[W]") + msg + " ", "color:white; background:gray;");
  }

  public static error(msg:string):void
  {
    console.error("%c"+ this.tag("[E]") + msg + " ", "color:white; background:red;");
  }

  public static errorFormat(msg:string, values:Array<any>):void {
    msg = StringUtils.Format(msg, values);
    Logger.error(msg);
  }

  public static debugFormat(msg:string, values:Array<any>):void {
    msg = StringUtils.Format(msg, values);
    Logger.debug(msg);
  }

  public static warningFormat(msg:string, values:Array<any>):void
  {
    msg = StringUtils.Format(msg, values);
    this.warning(msg);
  }

  public static titleFormat(msg:string, values:Array<any>):void
  {
    msg = StringUtils.Format(msg, values);
    this.title(msg);
  }

}
