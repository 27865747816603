'use strict'
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BreadcrumServiceStatic } from './breadcrumServiceStatic.service';
import { CopyServiceStatic } from './commonStaticServices/copyServiceStatic.service';
import { DateStringServiceStatic } from './commonStaticServices/dateStringServiceStatic.service';
import { ConstantServiceStatic } from './constantServiceStatic.service';

import { InMemoryDataServiceStatic } from './inMemoryDataServiceStatic.service';
import { JsRegExpServiceStatic } from './jsRegExpServiceStatic.service';
import { UnitConversionServiceStatic } from './unitConversionServiceStatic.service';

// import { WhenScrollEndsService } from './whenScrollEndsService.service';

@Injectable({
  providedIn: 'root',
})
export abstract  class StaticServiceService implements OnDestroy {
  constructor(

  ) { }
  static breadcrumServiceStatic : BreadcrumServiceStatic;
  static constantServiceStatic : ConstantServiceStatic;
  static copyServiceStatic : CopyServiceStatic;
  static dateStringServiceStatic : DateStringServiceStatic;
  static inMemoryDataServiceStatic : InMemoryDataServiceStatic;
  static jsRegExpServiceStatic : JsRegExpServiceStatic;
  static unitConverstionServiceStatic : UnitConversionServiceStatic;
  private emitterDestroyed$ : Subject<boolean> = new Subject();

  // ------------------------------------------------------------------
  static setBreadcrumServiceStatic ( bcs : BreadcrumServiceStatic): void {
    this.breadcrumServiceStatic = bcs;
  }
  static getBreadcrumServiceStatic () : BreadcrumServiceStatic {
    return this.breadcrumServiceStatic;
  }
  // ------------------------------------------------------------------
  static setConstantServiceStatic ( cs : ConstantServiceStatic): void {
    this.constantServiceStatic = cs;
    }
  static getConstantServiceStatic () : ConstantServiceStatic {
    return this.constantServiceStatic;
    }
  // ------------------------------------------------------------------
  static setCopyServiceStatic ( cps : CopyServiceStatic): void {
    this.copyServiceStatic = cps;
   }
  static getCopyServiceStatic () : CopyServiceStatic {
    return this.copyServiceStatic;
   }
  // ------------------------------------------------------------------
  static setDateStringServiceStatic ( dss : DateStringServiceStatic): void {
    this.dateStringServiceStatic = dss;
   }
  // -----------------------------------------------------------------
  static getDateStringServiceStatic () : DateStringServiceStatic {
    return this.dateStringServiceStatic;
   }  
  // ------------------------------------------------------------------
   static setInMemoryDataServiceStatic ( ims : InMemoryDataServiceStatic ) : void
   {
    this.inMemoryDataServiceStatic = ims;
   }
  static getInMemoryDataServiceStatic () : InMemoryDataServiceStatic
   {
    return this.inMemoryDataServiceStatic;
   }
  // ------------------------------------------------------------------
  static setJsRegExpServiceStatic ( ims : JsRegExpServiceStatic ) : void
  {
    this.jsRegExpServiceStatic = ims;
  }
  static getJsRegExpServiceStatic () : JsRegExpServiceStatic
  {
    return this.jsRegExpServiceStatic;
  }
  // ------------------------------------------------------------------
  //static setRedirectionServiceStatic ( rds : RedirectionServiceStatic): void {
  //  this.redirectionServiceStatic = rds;
  // }
  //static getRedirectionServiceStatic () : RedirectionServiceStatic {
  //  return this.redirectionServiceStatic;
  //} 
  // ------------------------------------------------------------------
  static setUnitConversionServiceStatic ( ucs : UnitConversionServiceStatic): void {
    this.unitConverstionServiceStatic = ucs;
   }
  static getUnitConversionServiceStatic () : UnitConversionServiceStatic {
    return this.unitConverstionServiceStatic;
   }
  // --------------------------------------------------------------

  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ------------------------------------------------------------------
}
