import { MemberActivity } from './memberActivity.model';

export class MemberActivitiesAndAction{
  public id = 0;
  public date!: Date;
  public memberActivity = new MemberActivity();
  public message = '';
  public memberActivities : MemberActivity[] = [];
  public action = '';  
  public sitUserId = 0;
  public signedInUserId = 0;
  public signedInUserKey = '';
  constructor() { }
}
