
import { ElementRef, Inject, Injectable, NgZone, Renderer2, RendererFactory2 } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { interval, Observable, Subject, Subscription, } from 'rxjs';
import { map, takeUntil, distinctUntilChanged, filter, take, startWith, tap } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { ModuleComponentLoader } from '../../models/common/moduleComponentLoader.model';
import { OffOn } from '../../models/offOn/offOn.model';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from './stringServiceStatic.service';

// =====================================================================
//  Use Case:
// ----------
//  In a Component:
//      import { Subject } from 'rxjs;
//      import { takeUntil } from 'rxjs/operators';
//        ...
//      private onDestroy$: Subject<void> = new Subject<void>();
//        ...
//      this.RandomNumberGeneratorObservableServiceStatic.getRandomNumber()
//      .pipe( takeUntil( this.onDestroy$ ) )
//      .subscribe( ( luckyNumber : number ) =>
//      {
//        this.number1 = luckyNumber;
//        ...
//      public ngOnDestroy(): void {
//        this.onDestroy$.next();
//      }
// =====================================================================
declare var $ : any;
@Injectable({ providedIn: 'root'})
export abstract class DomUtilsServiceStatic
{
  static renderer : Renderer2;
  static domUtilsElementRef : ElementRef;
  static domUtilsHTMLElement : HTMLElement;
  static randomNumberGenerator$ : Observable<number>;
  static randomNumber = 0;
  static loginSuccess : LoginSuccess = new LoginSuccess();
  static mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  static navigationStart$ : NavigationStart;
  static router : Router;
  static stable : any;
  static zone : NgZone;
  static document : Document;
  static timer : any;
  // ---------------------------------------------------------
  constructor (
    private renderer2Factory : RendererFactory2,
    private routerC : Router,
    private ngZone : NgZone,
    @Inject(Document) _document : Document,
  ) {
    DomUtilsServiceStatic.document = _document;
    DomUtilsServiceStatic.initService(renderer2Factory, routerC, ngZone);
  }

  // ---------------------------------------------------------------
  static triggerClick () {
    // let el : HTMLElement = this.pTileButton.nativeElement as HTMLElement;
    // Note:  before getting the angularElementById, we need to set the Document
    //        and ElementRef without which it will fail!
    let el = DomUtilsServiceStatic.getAngularElementById('pTileButton');
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(el)) {
      this.timer = setTimeout(() => {
        el.click();
        clearTimeout(this.timer);
      }, 5000);
    }

  }
  // ---------------------------------------------------------
  public static initService (renderer2Factory : RendererFactory2, routerI: Router, ngZoneI: NgZone) {
    this.renderer = renderer2Factory.createRenderer(null, null);
    this.router = routerI;
    this.zone = ngZoneI;
    // this.document = document;
  }
  // ---------------------------------------------------------
  public static getRandomNumber () : Observable<number> {
    this.randomNumber++;

    if (!this.randomNumberGenerator$) {
      this.randomNumberGenerator$ = Observable.create((subject : Subject<number>) => {
        let timer = setInterval(() => {
          const num = Math.floor(Math.random() * 10);
          subject.next(num);
          clearInterval(timer);
        }, 1000);

        clearInterval(timer);
      });
    }
    return this.randomNumberGenerator$;
  }
  // ---------------------------------------------------------
  public static getDomUtilsElementRef () : ElementRef {
    return this.domUtilsElementRef;
  }
  // ---------------------------------------------------------
  public static setDomUtilsElementRef (elemRef : ElementRef): void {
    this.domUtilsElementRef = elemRef;
  }
  // ---------------------------------------------------------
  public static setDomUtilsDocument (doc : Document) : void {
    this.document = doc;
  }
  // ---------------------------------------------------------
  // TODO: need to revisit for logic modification
  // ---------------------------------------------------------
  public static removeComponent (componentName : string) : any {
    let componentId : any;
    let domElements : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(componentName) ) {
      componentId = componentName + 'ComponentId';
      domElements = this.document.getElementById(componentId);

      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(domElements) || domElements.length === 0) {
        componentId = '#' + componentId;
        domElements = this.document.getElementById(componentId);
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(domElements) && domElements.length >= 1) {
        // debugger;
        for (let i = 0; i <= domElements.length - 2; i++) // remove all but the last one
        {
          // debugger;
          domElements[ i ].parentNode.removeChild(domElements[ i ]);
        }
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(domElements)) {
        domElements.remove();
      }
    }
  }
  // ----------------------------------------------------------------------
  public static getMcLoaderComponentId (mcLoader: any) : any {
    let mcLoaderComponentId = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.componentName)) {
      mcLoaderComponentId = mcLoader.componentName + 'ComponentId';
    }
    return mcLoaderComponentId;
  }
  // ----------------------------------------------------------------
  static getAngularElementById (id : string) : any {
    let tempElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      tempElem = this.document.getElementById(id);
      // debugger;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.domUtilsElementRef)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.domUtilsElementRef.nativeElement)) {
          tempElem = this.domUtilsElementRef.nativeElement.querySelector(id);
        }
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.domUtilsElementRef)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.domUtilsElementRef.nativeElement)      ) {
        tempElem = this.domUtilsElementRef.nativeElement.querySelector('#' + id);
      }
    }
    // debugger;
    
    return tempElem;   
  }
  // ----------------------------------------------------------------
  // TODO: Test
  // ----------------------------------------------------------------
  static getElementById (id : string) : any {
    let tempElem : any;
    // debugger;
    // get it from document-tree if available:
    // ---------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.document)) {
        this.document = EmitterSubjectService.getDocument();       
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.document)) {
        tempElem = this.document.getElementById(id) as HTMLElement;

        // debugger;
        // Note: throws error on both: id, and #id:
        // ----------------------------------------
        //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
        //  tempElem = this._document.querySelector(id);
        //}
        //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
        //  tempElem = this._document.querySelector('#' + id);
        //}
      }

      // get it from elemRef if still not found:
      // ---------------------------------------
      // if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      //  this.domUtilsElementRef = EmitterSubjectService.getElementRef();
      //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.domUtilsElementRef) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.domUtilsElementRef.nativeElement)) {

      //    // Note: throws error on id:
      //    // -------------------------------
      //    tempElem = this.domUtilsElementRef.nativeElement.querySelector(id);
      //  }
      // }

      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
        // debugger;
        this.domUtilsElementRef = EmitterSubjectService.getElementRef();
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.domUtilsElementRef) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.domUtilsElementRef.nativeElement)) {
          tempElem = this.domUtilsElementRef.nativeElement.querySelector('#' + id);
        }
      }
    }
    
    // debugger;
    return tempElem;    
  }

  // ---------------------------------------------------------------
  static getHtmlElementById (id : string) : HTMLElement {
    let tElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      // debugger;
      tElem = document.getElementById(id) as HTMLElement;
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
        // debugger;
        return tElem;
      }
      else {
        tElem = document.getElementById('#' + id) as HTMLElement;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
          // debugger;
          return tElem;
        }
        else {
          tElem = document.querySelector('#' + id) as HTMLElement;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
            // debugger;
            return tElem;
          }
          else {
            tElem = document.querySelectorAll('#' + id)[ 0 ] as HTMLElement;
          }
        }
      }
    }
    // debugger;
    return tElem;
  }
  // ---------------------------------------------------------------
  static getHtmlElementByClass (cName : string) : HTMLElement {
    let tElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cName)) {
      // debugger;
      tElem = document.getElementsByClassName(cName)[ 0 ];
    }
    // debugger;
    return tElem;
  }
  // ---------------------------------------------------------------
  static getHtmlElementByClassForOffOn (offOn : OffOn, name : string) : OffOn {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(name)) {
      let cName = '';
      let tElem : any;
      let parts = name.split('.');
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length > 1) {
        cName = parts[ 1 ];
      }
      else cName = name;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cName)) {
        tElem = document.getElementsByClassName(cName)[ 0 ];
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
        // debugger;

        if (cName.indexOf("spanA") !== -1) {
          // debugger;
          offOn.offOnAwayElem = tElem as HTMLElement;
          EmitterSubjectService.setOffOnElement(tElem as HTMLElement, 'spanAway');
        }
        else if (cName.indexOf("spanR") !== -1) {
          // debugger;
          offOn.offOnRadioElem = tElem as HTMLElement;
          EmitterSubjectService.setOffOnElement(tElem as HTMLElement, 'spanRadio');
        }
      }
    }
    return offOn;
  }
  // ----------------------------------------------------------------------
  public static doesElementWithIdHasThisClass (elemId : string, className : string) : boolean {
    let elem = this.getElementById(elemId);

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
        const outsideClasses = elem.classList;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outsideClasses)) {
          if (outsideClasses.contains(className)) {
            return true;
          }
        }
      }
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
      elem.remove();
    }
    return false;
  }
  // ----------------------------------------------------------------------
  static doesElementHasThisClass (elem : any, className : string) : boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
        const outsideClasses = elem.classList;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outsideClasses)) {
          if (outsideClasses.contains(className)) {
            return true;
          }
        }
      }
    }
    return false;
  }
  //  ---------------------------------------------------------------------
  public static removeClassForElementUsingElemId (elemId : string, className : string) : any {
    this.removeClass(elemId, className);
    return true;
  }
  //  ---------------------------------------------------------------
  public static removeClass (elemId :string, className : string) : any {
    let tempElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      tempElem = this.getElementById(elemId);
    }

    this.removeClassForElement(tempElem, className);
    tempElem.remove();
    return true;
  }
  //  ---------------------------------------------------------------
  public static removeClassForElement (elem : HTMLElement, className : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
      const outsideClasses = elem.classList;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outsideClasses)) {
        if (outsideClasses.contains(className)) {
          this.renderer?.removeClass(elem, className);
        }
      }
    }
  }
  //  ---------------------------------------------------------------
  public static addClass (elemId : string, className : string) : any {
    let tempElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      tempElem = document.getElementById(elemId);
    }

    this.addClassForElement(tempElem, className);
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      tempElem.remove();
    }
    return true;
  }
  //  ---------------------------------------------------------------
  public static addClassForElement (elem : HTMLElement, className : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
      const outsideClasses = elem.classList;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outsideClasses)) {
        if (!outsideClasses.contains(className)) {
          this.renderer?.addClass(elem, className);
        }
      }
    }
  }
  //  ---------------------------------------------------------------
  public static toggleClass (elemId : string, className : string) : any {
    let tempElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      tempElem = this.getElementById(elemId);
    }

    tempElem = document.getElementById(elemId) as HTMLElement;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      this.toggleClassForElement(tempElem, className);
    }
    tempElem.remove();
    return true;
  }
  //  ---------------------------------------------------------------
  public static toggleClassForElement (elemId : string, className : string) : any {
    let tempElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      tempElem = this.getElementById(elemId);
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      const outsideClasses = tempElem.classList;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outsideClasses)) {
        if (outsideClasses.contains(className)) {
          this.renderer?.removeClass(tempElem, className);
        }
        else {
          this.renderer?.addClass(tempElem, className);
        }
      }
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      tempElem.remove();
		}
    return true;
  }
  // ---------------------------------------------------------------------------
  //  Note: This method changes the style of the <body> element that is outise of Angular
  // ---------------------------------------------------------------------------
  static getElementOutsideAngular (elemId : string) : any {
    let tElem : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(document)) {
        tElem = document.getElementById(elemId) as HTMLElement;

        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
          tElem = this.getAngularElementById(elemId) as HTMLElement;
        }

        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
          tElem = this.getHtmlElementById(elemId) as HTMLElement;
        }
        // debugger;
        return tElem;
      }

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.zone)) {
        this.zone.runOutsideAngular(() => {
          // debugger;
          tElem = document.getElementById(elemId) as HTMLElement;

          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
            tElem = this.getHtmlElementById(elemId) as HTMLElement;
          }
          // debugger;
          return tElem;
        });
      }
    }
  }
  // ---------------------------------------------------------------
  static addCssClassToElement (elemId : string, cName : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      const tempElem = document.getElementById(elemId) as HTMLElement;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cName)) {
        if (this.elementHasThisClass(tempElem, cName)) {
          this.renderer?.removeClass(tempElem, cName);
        }
        // debugger;
        this.renderer?.addClass(tempElem, cName);
        tempElem.focus();
        tempElem.remove();
        return true;
      }
    }
    return false;
  }
  // ---------------------------------------------------------------
  static addFocusToElement (elemId : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      let tempElem = document.getElementById(elemId) as HTMLElement;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
        tempElem.focus();
        tempElem.remove();
        return true;
      }
      return false;
    }
  }
  // ---------------------------------------------------------------
  static addRemoveClasses (count : number) : any {
    switch (count) {
      case 0:
        this.addFocusToElement('profileMsgId');
        this.removeCssClassFromElement('profileMsgId', 'messageBannerBigger');
        this.addCssClassToElement('profileMsgId', 'messageBannerBigger');
        return true;
      case 1:
        this.addFocusToElement('profileMsgId');
        this.removeCssClassFromElement('profileMsgId', 'messageBannerBigger');
        this.addCssClassToElement('profileMsgId', 'messageBannerSmaller');
        return true;
      case 2:
        this.addFocusToElement('profileMsgId');
        this.removeCssClassFromElement('profileMsgId', 'messageBannerSmaller');
        this.addCssClassToElement('profileMsgId', 'messageBanner');
        return true;
    }
    return false;
  }
  // --------------------------------------------------------------
  static elementHasThisClass (pElem : any, className : string) : boolean {
    let isClassPresent = false;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className)) {
      if ($(this).hasClass(className)) {
        // debugger;
        isClassPresent = true;
        pElem.focus();
      } else if ($(pElem).hasClass(className)) {
        // debugger;
        isClassPresent = true;
        pElem.focus();
      }
    }
    // debugger;
    return isClassPresent;
  }
  // ---------------------------------------------------------------
  static removeCssClassFromElement (elemId : string, cName : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      const tempElem = document.getElementById(elemId) as HTMLElement;
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cName)) {
        if (this.elementHasThisClass(tempElem, cName)) {
          // debugger;
          this.renderer?.removeClass(tempElem, cName);
          tempElem.focus();
          tempElem.remove();
          return true;
        }
      }
    }
    return false;
  }
  // ----------------------------------------------------------------------
  static getValuefromInputElement (id:any) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      var inputElem = this.getAngularElementById(id);
      return inputElem.value;
    }
  }
  // ----------------------------------------------------------------------
  public static unloadComponent (componentId : string) : any {
    // debugger;
    let tElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(componentId) /*&& !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.elementRef )*/) {
      // debugger;
      tElem = this.getElementById(componentId);
      
      
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem.parentNode)) {
        // debugger;
        tElem.parentNode.removeChild(tElem); //  Tested, works!
      }
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
      tElem.remove();
    }
    return true;
  }
  // ------------------------------------------------------------------------
  //  Begin-of-Element-Rendering-Measurement-System:
  //  ref: https://dev.to/herodevs/route-fully-rendered-detection-in-angular-2nh4
  // ------------------------------------------------------------------------
  public static detectNavigationStart (router : Router) : Observable<any> {
    let navStartObservable = new Observable<any>(observer => {
      let navStart$ = router.events.pipe(
        filter(event => event instanceof NavigationStart),
        startWith(null), // Start with something, because the app doesn't fire this on appload, only on subsequent route changes
        tap(event => /* Place code to track NavigationStart here */ EmitterSubjectService.emitElementFullyRendered(true)),
      ).subscribe();
      return navStart$;
    });
    return navStartObservable;
  }
  // ------------------------------------------------------------------------

  public static isElementRendered (router: Router, zone: NgZone) : boolean {
    // Once NavigationStart has fired, start checking regularly until there are
    // no more pending macrotasks in the zone

    // Have to run this outside of the zone, because the call to `interval`
    // is itself a macrotask. Running that `interval` inside the zone will
    // prevent the macrotasks from ever reaching zero. Running this outside
    // of Angular will not track this `interval` as a macrotask. IMPORTANT!!
    let isStateStable = false;
    let timer : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(zone) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(router)) {
      debugger;
       zone.runOutsideAngular(() => {
        // Check very regularly to see if the pending macrotasks have all cleared
        timer = interval(50)
          .pipe(
            startWith(0), // So that we don't initially wait
            // To prevent a memory leak on two closely times route changes, take until the next nav start
            // takeUntil(this.navigationStart$),
            takeUntil(this.detectNavigationStart(router)),
            // Turn the interval number into the current state of the zone
            map(() => !zone.hasPendingMacrotasks),
            // Don't emit until the zone state actually flips from `false` to `true`
            distinctUntilChanged(),
            // Filter out unstable event. Only emit once the state is stable again
            filter(stateStable => stateStable === true),
            // Complete the observable after it emits the first result
            take(1),
            tap(stateStable => {
              // FULLY RENDERED!!!!
              // Add code here to report Fully Rendered
              debugger;
              isStateStable = true;
              // EmitterSubjectService.emitElementFullyRendered(true);
            })
        ).subscribe();

      });

      // clearTimeout(timer);
    }
    return isStateStable;
  }
  // ------------------------------------------------------------------------
  public static detectElementRendered2 (zone : NgZone) : Observable<any> {
    debugger;
    let isStableObservable = new Observable<any>(observer => {
      let stableSub : Subscription;
      let unstableSub : Subscription;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(zone)) {
        zone.runOutsideAngular(() => {
          stableSub = zone.onStable.subscribe(() => {
            NgZone.assertNotInAngularZone();
            if (!this.stable && !zone.hasPendingMacrotasks &&
              !zone.hasPendingMicrotasks) {
              this.stable = true;
              observer.next(true);
            }
          });
        });
      
        unstableSub = zone.onUnstable.subscribe(() => {
          NgZone.assertInAngularZone();
          if (this.stable) {
            this.stable = false;
            zone.runOutsideAngular(() => {
              observer.next(false);
            });
          }
        });
      }
      return () => {
        stableSub.unsubscribe();
        unstableSub.unsubscribe();
        debugger;
        isStableObservable;
      };
    });
    debugger;
    return isStableObservable;
  }
  /*
   * usage:
   * this.isStableObservable.pipe(debounceTime(1000)).subscribe(val => {
      console.log('completely stable');
     });
   */

  // -----------------------------------------------------------
  static nullPromise (data : any) : any {
    let timer = setTimeout(() => {
      // debugger;
      clearTimeout(timer);
      return data;
    }, 50);
    clearTimeout(timer);
  }
  // -----------------------------------------------------------
  static nullObservable () : Observable<any> {
    return new Observable<any>(observer => {
      observer.next(null);
      observer.complete();
    });
  }
  // ------------------------------------------------------------------------
}
