import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, Subject, takeUntil } from 'rxjs';
import { HttpService } from '../../../../services/coreServiceService/httpService.service';
import { EmitterSubjectService } from '../../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { LoginSuccess } from '../../../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../../../models/boxNonce/boxNonceEntity.model';
import { SlakezSaltServiceStatic } from '../../../../services/staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { StringServiceStatic } from '../../../../services/staticServices/stringServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Injectable({
  providedIn: 'any'
})
export class ImageUploadService {
  // private baseUrl = 'http://localhost:44334';
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  private loginSuccess : LoginSuccess = new LoginSuccess();
  private boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  // ---------------------------------------------------
  constructor (
    private http : HttpClient) {

    EmitterSubjectService.formDataEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(formData => {
        // debugger;
        
        // const req = new HttpRequest('POST', `${ this.baseUrl }/api/FileUpload/ImageUpload`, formData, {
        const req = new HttpRequest('POST', `/api/FileUpload/ImageUpload`, formData, {
          reportProgress: true,
          responseType: 'json'
        });

        this.http.request(req).subscribe(data => {
          // debugger;
          EmitterSubjectService.emitHttpEvent(data);
        })
      });


  }
  // ---------------------------------------------------

  upload (file : File) : void { // Observable<HttpEvent<any>>
    const formData : FormData = new FormData();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();  
    formData.append('file', file);
    formData.append('signedInUserId', this.loginSuccess.signedInUserId.toString());
    formData.append('date', new Date().getTime().toString());

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position : any) => {
          if (position) {
            // debugger;
            formData.append('latitude', position.coords.latitude);
            formData.append('longitude', position.coords.longitude);
            // debugger;
            EmitterSubjectService.emitFormData(formData);
          }
        })
    }
  }

  // ------------------------------------------------
  // Not used:
  // ------------------------------------------------
  //getFiles () : Observable<any> {
  //  debugger;
  // // return this.http.get(`${ this.baseUrl }/files`);
  //  debugger;
  //   return this.http.get(`/api/FileUpload/ImageUpload`);
  //}
  // ------------------------------------------------
}