
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { HttpService } from '../coreServiceService/httpService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Injectable({
  providedIn: 'root',
})
export abstract class InMemoryDataServiceStatic {

  static emitterDestroyed$ : Subject<boolean> = new Subject();
  constructor (
    private httpService : HttpService,
    private router: Router,
  )
  {
    this.initialize();
  }
  // ---------------------------------------------------------------
  ngOnDestroy () {
    // prevent memory leak when component destroyed
    InMemoryDataServiceStatic.emitterDestroyed$.next(true);
    InMemoryDataServiceStatic.emitterDestroyed$.complete();
    InMemoryDataServiceStatic.emitterDestroyed$.unsubscribe();
  }
  // ---------------------------------------------------------------
  public static authorization = {};
  // public static blackListCredentials: KV[] = []; //  Moved to BlackListCredentialsServiceStatic.ts 
  public static fonts = [];
  public static genderArr = ['male', 'female', 'transgender'];
  public static intoArr = [
    'bondage',
    'cross_dressing',
    'drag',
    'fetish',
    'groupSex',
    'leather',
    'married_men',
    'married_women',
    'massage',
    'one_on_one',
    'pig_play',
    'rough',
    's&M',
    'toys',
    'voyeurism',
    'water_sports',
    'polyamory',
  ];
  public static highestEducationArr = ['college', 'diploma', 'gED', 'graduate', 'high_school', 'less_than_high_school', 'Md', 'phD', 'some_college_credits', 'trades'];
  public static hobbyArr = [
    'acting',
    'antique',
    'biking',
    'body_building',
    'camping',
    'climbing',
    'cooking',
    'dancing',
    'none_at_the_moment',
    'extreme_sports',
    'hiking',
    'jogging',
    'kayaking',
    'knitting',
    'music',
    'other',
    'painting',
    'reading',
    'sailing',
    'shopping',
    'singing',
    'swimming',
    'walking',
    'yoga',
  ];
  public static lookingForArr = ['dating', 'fwb', 'fbuddy', 'friendship', '3_somes', 'group', 'marriage', 'monogamy', 'polygamy'];
  public static meetingLocationArr = ['coffee_shop', 'bar', 'my_place', 'public_place', 'restaurant', 'your_place'];
  public static petArr = ['have_dog', 'have_cat', 'have_other_pet', 'like_pet', 'dont_like_pet'];
  public static petPeevArr = ['douchBag', 'snoring', 'smoking', 'ignoring', 'clicks'];
  public static playSafeArr = ['never', 'sometimes', 'most_of_the_time', 'always'];
  public static positionArr = ['bottom', 'top', 'versatile'];
  public static relationshipStatusArr = ['single', 'divorced', 'seperated', 'open_relationship', 'married', 'polyamory'];
  public static relationshipPreferenceArr = ['casual_dating', 'cohabitation', 'dating', 'friendship', 'FB', 'FWB', 'LTR', 'marriage', 'no_string', 'polyamorous', 'sex'];
  public static religionArr = [ 'atheist', 'agnostic', 'bahai', 'bhuddist', 'christian', 'hindu', 'jewish', 'muslim', 'non_religious', 'spiritual', 'other'];
  public static sexualitieArr = ['gay', 'lesbian', 'bisexual', 'heterosexual'];
  public static smokingStatusArr = ['non_smoker', 'socially', 'occassionally', 'moderately', 'chain_smoker', 'chain_vaper'];
  public static whenLookingToMeetArr = ['now', 'morning', 'afternoon', 'evening', 'weekdays', 'weekday_evenings', 'weekends', 'anytime'];
  // -------------------------------------------
  public static chabi = 'KA8j/zeXyTPXt0+UTogm1tQk2r0+mUQ9G34PBtxYnHA=';
  public static chabiGopon = 'dJKh9zxbQquNHzKCIkyR11h9svmQBzazOcAuqmWPb/A=';
  // -------------------------------------------
  public static accountMessages = [];
  public static profilePics = new ProfilePics();
  public static boxNonceEntity = new BoxNonceEntity();
  // public static dictionary = new Collections.Dictionary<string, any>(); // key=sitUserId, value=PhotoIds[] //deprecated
  public static enums : any;
  public static isMobilevar = false;
  public static isOnLine = false;
  public static loginSuccess = new LoginSuccess();
  public static profileImageArr = [];
  public static sandBoxJson = [];
  public static sitUser = new SitUser();
  public static userPhotoIds = [];
  public static userPhotos = [];
  public static enumsObservable = new Observable<any[]>();
  public static accountMessagesObservable = new Observable<any[]>();
  public static blackListCredentialsObservable = new Observable<any[]>();
  public static sandBoxObservable = new Observable<any[]>();
  public static enumsCallCounter = 0;
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  // ---------------------------------------------------------------
  static getEnums(): any {
    return this.enums;
  }
  //  Moved to BlackListCredentialsServiceStatic.ts 
 // static getBlackListCredentials () {
 //    return this.blackListCredentials;
	//}
  // ---------------------------------------------------------------
  // The caller must use 'KvAny' model to get the key-value pair.
  // ---------------------------------------------------------------
  static getTwoChabi () : KvAny[]
  {
    // debugger;
    let twoChabi : KvAny[] = [];
    let chabi = new KvAny();
    chabi.key = 'chabi';
    chabi.value = this.chabi;
    twoChabi.push( chabi );
    // debugger;
    chabi = new KvAny();
    chabi.key = 'chabiGopon';
    chabi.value = this.chabiGopon;
    twoChabi.push( chabi );
    return twoChabi;
  }
  // ---------------------------------------------------------------
  private initialize ()
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.getEnums ) )
    {
      InMemoryDataServiceStatic.enums = EmitterSubjectService.getEnums();
    }
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.enums ) )
    {
      // debugger;
      this.httpService.getEnumsFromServer();
      this.getAuthorizationFromServer();
      this.getAccountMessagesFromServer();

      // debugger;
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.enums )
      && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.enums.authorization ) )
    {
      this.getAuthorizationFromServer();
      this.getAccountMessagesFromServer();
    }

    // InMemoryDataServiceStatic.blackListCredentials = this.fetchBlackListCredentials();
      
    EmitterSubjectService.runGetEnumsFromServerEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
        // debugger;
        this.httpService.getEnumsFromServer();
        this.emitterDestroyed$.next( true );
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
      });
    // -------------------------------------------------------------
    this.getSandBoxJsonFromServer();
  }
  // ---------------------------------------------------------------
 // public getBlackListCredentialKvArr () : KV[]{
 //   InMemoryDataServiceStatic.blackListCredentials = this.fetchBlackListCredentials();
 //   if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(InMemoryDataServiceStatic.blackListCredentials)
 //     && InMemoryDataServiceStatic.blackListCredentials.length > 0) {
 //     return InMemoryDataServiceStatic.blackListCredentials;
 //   }
 //   else return null;
	//}
  // ---------------------------------------------------------------
  getEnums ()
  {
    InMemoryDataServiceStatic.enums = this.httpService.getEnumsFromServer();
    return InMemoryDataServiceStatic.enums;
  }
  // ---------------------------------------------------------------
  // private fetchBlackListCredentials () : KV[] {
   //   if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(InMemoryDataServiceStatic.blackListCredentials)
   //     || InMemoryDataServiceStatic.blackListCredentials.length === 0) {
   //     debugger;

   //     this.httpService.get('/api/Home/GetBlackListCredentialsJson').subscribe(
   //       (result) => {
   //         debugger;
   //         if (result) {
   //           debugger;
   //           result.map(e => {
   //             let kv = new KV();
   //             kv.key = (e as KV).key;
   //             kv.value = (e as KV).value;
   //             InMemoryDataServiceStatic.blackListCredentials.push(kv);
   //           });

   //           return InMemoryDataServiceStatic.blackListCredentials;
   //         }
   //         //this.emitterDestroyed$.next(true);
   //         //this.emitterDestroyed$.complete();
   //         //this.emitterDestroyed$.unsubscribe();
   //       },
   //       (error) => {
   //         EmitterSubjectService.emitMyErrorLog('Error occured in GetBlackListCredentialsJson();\n Error-message: ' + error.message);
   //       },
   //     );
   //   }
   //   else return null;   
	 //}
  // ---------------------------------------------------------------
  private getSandBoxJsonFromServer () : any {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(InMemoryDataServiceStatic.accountMessages)) {
      this.httpService.get('api/Home/GetSandboxJson').subscribe(
        (result) => {
          // debugger;
          if (result) {
            InMemoryDataServiceStatic.sandBoxJson = result as [];
            // debugger;
          }
          this.emitterDestroyed$.next(true);
          this.emitterDestroyed$.complete();
          this.emitterDestroyed$.unsubscribe();
        },
        (error) => {
          EmitterSubjectService.emitMyErrorLog('Error occured in GetAccountMessagesJson();\n Error-message: ' + error.message);
        },
      );
    }
  }
  // ---------------------------------------------------------------
  static getSandBoxJson () :any {
      return InMemoryDataServiceStatic.sandBoxJson;
  }
  // ---------------------------------------------------------------
  private getAccountMessagesObservable(): any {
    return ( InMemoryDataServiceStatic.accountMessagesObservable = new Observable((observer) => {
      let timer = setTimeout(() => {
        // debugger;
        const model = ( InMemoryDataServiceStatic.accountMessages = this.getAccountMessagesFromServer());
        // debugger;

        observer.next( model );
        this.emitterDestroyed$.next( true );
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
      }, 500);
      clearTimeout(timer);
    }));
  }
  // ---------------------------------------------------------------
  private getAccountMessagesFromServer(): any {
    // debugger;
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.accountMessages)) {
      this.httpService.get('api/Home/GetAccountMessagesJson').subscribe(
        (result) => {
          // debugger;
          if (result) {
            InMemoryDataServiceStatic.accountMessages = result as [];
            // debugger;
          }
          this.emitterDestroyed$.next( true );
          this.emitterDestroyed$.complete();
          this.emitterDestroyed$.unsubscribe();
        },
        (error) => {
          EmitterSubjectService.emitMyErrorLog('Error occured in GetAccountMessagesJson();\n Error-message: ' + error.message);
        },
      );
    }
  }
  // ---------------------------------------------------------------
  private getAuthorizationFromServer(): any {
    // debugger;
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.accountMessages)) {
      this.httpService.get('api/Home/GetAuthorizationJson').subscribe(
        (result) => {
          // debugger;
          if (result) {
            InMemoryDataServiceStatic.authorization = result as [];
            // debugger;
          }
          this.emitterDestroyed$.next( true );
          this.emitterDestroyed$.complete();
          this.emitterDestroyed$.unsubscribe();
        },
        (error) => {
          EmitterSubjectService.emitMyErrorLog('Error occured in GetAuthorizationJson();\n Error-message: ' + error.message);
        },
      );
    }
  }
  // ---------------------------------------------------------------
  //  Moved to BlackListCredentialsServiceStatic.ts 
   // ---------------------------------------------------------------
  //private getBlackListCredentialsFromServer () : Observable<any> {
  //  // debugger;
  //  return (InMemoryDataServiceStatic.blackListCredentialsObservable = new Observable((observer) => {
  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(InMemoryDataServiceStatic.blackListCredentials)) {
  //      this.httpService.get('/api/Home/GetBlackListCredentialsJson').subscribe(
  //        (result) => {
  //          debugger;
  //          if (result) {
  //            InMemoryDataServiceStatic.blackListCredentials = result as [];
  //            debugger;
  //            observer.next(InMemoryDataServiceStatic.blackListCredentials);
  //          }
  //          this.emitterDestroyed$.next(true);
  //          this.emitterDestroyed$.complete();
  //          this.emitterDestroyed$.unsubscribe();
  //        },
  //        (error) => {
  //          EmitterSubjectService.emitMyErrorLog('Error occured in GetBlackListCredentialsJson();\n Error-message: ' + error.message);
  //        },
  //      );
  //    }
  //  }))
  //}
  // -------------------------------------------------------------------------
  public getEnumsObservable(): any {
    return ( InMemoryDataServiceStatic.enumsObservable = new Observable((observer) => {
      let timer = setTimeout(() => {
        // debugger;
        const model = (InMemoryDataServiceStatic.enums = this.httpService.getEnumsFromServer());
        // debugger;

        observer.next( model );
        this.emitterDestroyed$.next( true );
        this.emitterDestroyed$.complete();
        this.emitterDestroyed$.unsubscribe();
      }, 1500);
      clearTimeout(timer);
    }));
  }
  /*
   * ---------------------------------------------------------------
   * Tested, works! 2020/12/26
   * Moved to HttpService
   * ---------------------------------------------------------------
   */
  // private getEnumsFromServer(): any {
  //   debugger;
  //  if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.enums ) || InMemoryDataServiceStatic.enumsCallCounter === 0) {
  //     debugger;
  //    InMemoryDataServiceStatic.enumsCallCounter++;
  //    this.httpService.get('json/enums.json').subscribe(
  //      (result) => {
  //         debugger;
  //        if (result) {
  //          /*
  //           * debugger;
  //           * this.enumsCallCounter = 0;
  //           */
  //          InMemoryDataServiceStatic.enums = result;
  //          EmitterSubjectService.setEnums( InMemoryDataServiceStatic.enums);
  //          return InMemoryDataServiceStatic.enums;
  //          // var unsalted = ( this.slakezSaltService as SlakezSaltService ).boxUnsalt(result);
  //          // //debugger;
  //          // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
  //          // var tEnums = JSON.parse(unsalted);
  //          // tEnums = JSON.stringify(tEnums, null, '\n');
  //          // ref:https:// www.digitalocean.com/community/tutorials/js-json-parse-stringify
  //          // //debugger;

  //          // tEnums = JSON.parse(tEnums);
  //          // //debugger;
  //          // EmitterSubjectService.setEnums(tEnums);
  //          // this.enumsCallCounter = 0;
  //          // return this.enums = tEnums;
  //          // }
  //        }
  //      },
  //      (error) => {
  //        // debugger;
  //        EmitterSubjectService.emitMyErrorLog('Error occured in GetEnums();\n Error-message: ' + error.message);
  //      }
  //    );
  //  }
  // }

  // ---------------------------------------------------------------
  // private getFontsFromServer(): any {
  //  // debugger;
  //  if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( InMemoryDataServiceStatic.enums ) && InMemoryDataServiceStatic.enumsCallCounter === 0) {
  //    // debugger;
  //    InMemoryDataServiceStatic.enumsCallCounter++;
  //    this.httpService.get('/api/Home/GetFontsJson').subscribe(
  //      ( result ) => InMemoryDataServiceStatic.processFontsFromServerResult(result),
  //      // debugger;
  //      (error) => {
  //        // debugger;
  //        EmitterSubjectService.emitMyErrorLog('Error occured in GetEnums();\n Error-message: ' + error.message);
  //      }
  //    );
  //  }
  // }
  // ---------------------------------------------------------------
  // static processFontsFromServerResult(result: any): any {
  //  if (result) {
  //    // debugger;
  //    const unsalted = SlakezSaltServiceStatic.boxUnsalt(result);
  //    // debugger;

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
  //      this.fonts = JSON.parse(unsalted);
  //      // debugger;
  //      return this.fonts;
  //    }
  //  }
  // }
  // ---------------------------------------------------------------
}
