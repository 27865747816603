
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CenterCoordinates } from '../../models/misc/centerCoordinates.model';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';

@Injectable({
  providedIn: 'any',
})
export class BreakPointObserverService implements OnDestroy {
  public condition = false; // potential means "maybe never"
  public max = 1000000;
  public isMobilevar = false;
  public isTabletvar = false;
  public isDesktopvar = false;
  public width = 0;
  public height = 0;
  private centerCoordinates: CenterCoordinates = new CenterCoordinates();
  private subscription!: Subscription;

  /*
   * The Old way using private param breakpointObserver:
   * ref:https:// coryrylan.com/blog/angular-async-data-binding-with-ng-if-and-ng-else
   * private breakpointObserver: BreakpointObserver;
   */

  /*
   * The New way using DI observer:
   * ref: https:// www.prestonlamb.com/blog/angular-cdks-breakpoint-observer
   */
  constructor(private observer: BreakpointObserver) {
    this.breakpointObserving();
  }
  // ---------------------------------------------------------------------------------
  ngOnDestroy() {
    // Only need to unsubscribe if its a multi event Observable
    this.subscription.unsubscribe();
  }
  /*
   * ---------------------------------------------------------------------------------
   * ---------------------------------------------------------------------------------
   */
  breakpointObserving(): BreakpointObserver {
    /*
     * const matched = this.observer.isMatched( '(max-width: 700px)' )
     * OR
     * const matched = this.observer.isMatched( [ '(max-width: 700px)', '(min-width: 500px)' ] );
     * OR
     */
    this.observer.observe( [ Breakpoints.XSmall, Breakpoints.Small, Breakpoints.HandsetPortrait, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge]).subscribe((result) => {
      if ( result.breakpoints[ Breakpoints.XSmall ] || result.breakpoints[ Breakpoints.Small ] || result.breakpoints[ Breakpoints.HandsetPortrait ]) {
        // handle XSmall breakpoint
        this.isMobilevar = true;
        this.isDesktopvar = false;
      }
      // if (result.breakpoints[Breakpoints.Small]) {
      //  // handle Small breakpoint
      //  this.isMobilevar = true;
      //  this.isDesktopvar = false;
      // }
      if ( result.breakpoints[ Breakpoints.Medium ] || result.breakpoints[ Breakpoints.Large ] || result.breakpoints[ Breakpoints.XLarge ]) {
        // handle Medium breakpoint
        this.isMobilevar = false;
        this.isDesktopvar = true;
      }
      // if (result.breakpoints[Breakpoints.Large]) {
      //  // handle Large breakpoint
      //  this.isMobilevar = false;
      //  this.isDesktopvar = true;
      // }
      // if (result.breakpoints[Breakpoints.XLarge]) {
      //  // handle XLarge breakpoint
      //  this.isMobilevar = false;
      //  this.isDesktopvar = true;
      // }
    });

      EmitterSubjectService.emitIsMobile(this.isMobilevar);

    this.getCenterCoordinates();
    EmitterSubjectService.emitHeightWidth(this.determineHeightWidth());
    // return this.breakpointObserver;
    return this.observer;
  }
  // --------------------------------------------------------------
  determineHeightWidth(): any {
    if (this.isMobilevar) {
      this.height = 275;
      this.width = 275;
    } else {
      this.height = 400;
      this.width = 400;
    }
    return this.getHeightWidth();
  }
  // ---------------------------------------------------------------------------------
  getCenterCoordinates () : void
  {
    // debugger;
    this.centerCoordinates.centerX = document.documentElement.clientWidth / 2;
    this.centerCoordinates.centerY = document.documentElement.clientHeight / 2;
    // debugger;
    EmitterSubjectService.emitCenterCoordinates(this.centerCoordinates);
  }
  // ---------------------------------------------------------------------------------
  getHeightWidth(): any {
    const hw = {
      height: this.height,
      width: this.width,
    };

    return hw;
  }
  // ---------------------------------------------------------------------------------
}
