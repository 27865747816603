
import { Subject, Subscription } from 'rxjs';
import { LoginSuccess } from '../account/loginSuccess.model';
import { PayPalCreditCardAddress } from './payPalCreditCardAddress.model';
import { CreditCard } from './creditCard.model';
import { PayPalCapture } from './payPalCapture.model';
import { PayPalPayer } from './payPalPayer';
import { PayPalPayment } from './payPalPayment.model';
import { PayPalPurchaseUnits } from './payPalPurchaseUnits';
import { PayPalSellerProtection } from './payPalSellerProtection';
import { Upgrade } from '../upgrade/upgrade.model';
import { PayPalAddress } from './payPalAddress.model';
import { PayPalShipping } from './payPalShipping.model';
import { PayPalPayee } from './payPalPayee';
import { PayPalItems } from './payPalItems.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';


// TODO: prune unnecessary properties.

export class PayPalOrder
{
  public authorizedData : any;
  public billingToken = '';
  public callerName = '';
  public create_time = '';
  public cryptUserKey = '';
  public date = ''; // tick in string
  public dateStr = '';
  public email = '';
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public facilitatorAccessToken = '';
  public fullName = '';
  public id = '';
  public intent = '';
  public ip = '';
  public isOnApprovedComplete = false;
  public isOnClientAuthorizationComplete = false;
  public latitude = 0;
  public longitude = 0;
  public onApproved : any;
  public onApprovedDetails : any;
  // public onApprovedDetailsModel: OnApprovedDetailsModel = new OnApprovedDetailsModel();
  public onCanceled : any;
  public onClientAuthorization : any;
  public onError : any;
  public orderID = '';
  public orderNo = ''; // PayPalOrderId.orderID.C#-ticks
  public payerID = '';
  public payPalCreditCardAddress : PayPalCreditCardAddress = new PayPalCreditCardAddress(); // common to both payPay & creditCard processing
  // public payerAddressStr = '';
  public paypalAddress : PayPalAddress = new PayPalAddress(); //todo: remove since shipping has address
  public paypalCapture : PayPalCapture = new PayPalCapture(); 
  public paypalCreditCard : CreditCard = new CreditCard(); // to be implemented during creditCardProcessing implementation
  public paypalItems : PayPalItems = new PayPalItems();
  public paypalPayee : PayPalPayee = new PayPalPayee();
  public paypalPayer : PayPalPayer = new PayPalPayer();
  public paypalPayment : PayPalPayment = new PayPalPayment();
  public paypalPurchaseUnits : PayPalPurchaseUnits = new PayPalPurchaseUnits();
  public paypalSellerProtection : PayPalSellerProtection = new PayPalSellerProtection();
  public paypalShipping : PayPalShipping = new PayPalShipping();
  public paymentOption = '';
  public paymentOptionAmount = 0.0;
  public personKey = '';
  public sitUserId = 0;
  public sitUserKey = '';
  public status = '';
  public subscriptionMonths = '';
  public subscriptionMonthsNum = 0;
  public subtotal = 0;
  public tax = 0;
  public taxId = '71502 9088 RT0001';
  public total = 0;
  public totalStr = '';
  public upgrade : Upgrade = new Upgrade();
  public userName = '';
  public update_time = '';
  // ----------------------------------------------------------------------------------
  constructor ()
  {
    this.subscriptionMonthsNum = this.getSubscriptionMonthsNum( this.paymentOption );

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.upgrade ) )
    {
      this.upgrade.subscriptionMonths = this.subscriptionMonths;
    }
    // --------------------------------------------------------------
    // EmitterSubjectService.facilitatorAccessTokenEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( result =>
    //  {
    //     // debugger;
    //    this.facilitatorAccessToken = result.facilitatorAccessToken;
    //  } );
  }
  // ----------------------------------------------------------------------------------
  // ngOnInit ()
  // {
    
  // }
  // ---------------------------------------------------------------
  // ngAfterViewInit() {
  //   debugger;

  //  setTimeout( () =>
  //  {
  //    ScrollTopServiceStatic.scrollToTop( 'paymentComponentId' );
  //  }, 1000 );
  // }
  // ---------------------------------------------------------------
  // ngDestroy() {
  //  // prevent memory leak when component destroyed
  //  this.emitterDestroyed$.next( true );
  //  this.emitterDestroyed$.complete();
  //  // this.timerArray.forEach( timer => clearInterval( timer ) );
  //  // EmitterSubjectService.emitDisplayDefaultGlyphiconMenu();
  //  // this.resetPageTitle();
  // }
  // ----------------------------------------------------------------------------------
  public copy (from : PayPalOrder) : PayPalOrder
  {
    var that : PayPalOrder = new PayPalOrder();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      that.billingToken = from.billingToken;
      that.authorizedData = from.authorizedData;
      that.create_time = from.create_time;
      that.cryptUserKey = from.cryptUserKey;
      that.date = from.date;
      that.email = from.email;
      that.facilitatorAccessToken = from.facilitatorAccessToken;
      that.fullName = from.fullName;
      // that.paypalOrdId = from.paypalOrdId;
      that.intent = from.intent;
      that.ip = from.ip;
      that.isOnApprovedComplete = from.isOnApprovedComplete;
      that.isOnClientAuthorizationComplete = from.isOnClientAuthorizationComplete;
      that.latitude = from.latitude;
      that.longitude = from.longitude;
      that.orderID = from.orderID;
      that.payerID = from.payerID;
      that.onApproved = from.onApproved;
      that.onApprovedDetails = from.onApprovedDetails;
      // that.onApprovedDetailsModel.copy(from.onApprovedDetailsModel);
      that.onCanceled = from.onCanceled;
      that.onError = from.onError;
      that.onClientAuthorization = from.onClientAuthorization;
      that.payPalCreditCardAddress.copy( from.payPalCreditCardAddress );
      // that.payerAddressStr = this.payerAddressToString();
      that.paypalAddress.copy( from.paypalAddress );
      that.paypalCapture.copy( from.paypalCapture );
      that.paypalCreditCard.copy( from.paypalCreditCard );
      that.paypalItems.copy( from.paypalItems );
      // that.paypalPayee.copy( from.paypalPayee );
      that.paypalPayer.copyFrom( from.paypalPayer );
      that.paypalPayment.copy( from.paypalPayment );
      that.paypalPurchaseUnits.copy( from.paypalPurchaseUnits );
      that.paypalSellerProtection.copy( from.paypalSellerProtection );
      // that.paypalShipping.copy( from.paypalShipping );
      that.paymentOption = from.paymentOption;
      that.paymentOptionAmount = from.paymentOptionAmount;
      that.personKey = from.personKey;
      that.sitUserId = from.sitUserId;
      that.sitUserKey = from.sitUserKey;
      that.status = from.status;
      that.subscriptionMonths = from.subscriptionMonths;
      that.subscriptionMonthsNum = from.subscriptionMonthsNum;
      that.subtotal = from.subtotal;
      that.tax = from.tax;
      that.total = from.total;
      that.totalStr = from.totalStr;
      that.upgrade.copy( from.upgrade );
      that.userName = from.userName;
      that.update_time = from.update_time;
    }
    return that;
  }
  // ----------------------------------------------------------------------------------
  // TODO: apply ifExists condition:
  // ----------------------------------------------------------------------------------
  public copyIfExists ( from: PayPalOrder ): PayPalOrder
  { 
    var to : PayPalOrder = new PayPalOrder();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      to.authorizedData = from.authorizedData;
      to.billingToken = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(from.billingToken) ? from.billingToken : to.billingToken;
      to.create_time = from.create_time;
      to.cryptUserKey = from.cryptUserKey ? from.cryptUserKey : to.cryptUserKey;
      to.date = from.date ? from.date : to.date;
      to.email = from.email ? from.email : to.email;
      to.facilitatorAccessToken = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(from.facilitatorAccessToken) ? from.facilitatorAccessToken :to.facilitatorAccessToken;
      to.fullName = from.fullName ? from.fullName : to.fullName;
      // to.paypalOrdId = from.paypalOrdId && from.paypalOrdId.length > 0 ? from.paypalOrdId : to.paypalOrdId;
      to.intent = from.intent;
      to.ip = from.ip;
      to.isOnApprovedComplete = from.isOnApprovedComplete;
      to.isOnClientAuthorizationComplete = from.isOnClientAuthorizationComplete;
      to.latitude = from.latitude !== 0 ? from.latitude : to.latitude;
      to.longitude = from.longitude !== 0 ? from.longitude : to.longitude;
      to.orderID = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(from.orderID) ? from.orderID : to.orderID;
      to.orderNo = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(from.orderNo) ? from.orderNo : to.orderNo;
      to.onApproved = from.onApproved;
      to.onApprovedDetails = from.onApprovedDetails;
      // to.onApprovedDetailsModel.copy( from.onApprovedDetailsModel );
      to.onCanceled = from.onCanceled;
      to.onError = from.onError;
      to.onClientAuthorization = from.onClientAuthorization;
      to.payerID = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(from.payerID) ? from.orderID : to.payerID;
      to.payPalCreditCardAddress.copy( from.payPalCreditCardAddress );
      // to.payerAddressStr = this.payerAddressToString();
      // to.paypalAddress.copy( from.paypalAddress );
      to.paypalCapture.copy( from.paypalCapture );
      to.paypalCreditCard.copy( from.paypalCreditCard );
      // to.paypalPayee.copy( from.paypalPayee );
      to.paypalPayer.copyFrom( from.paypalPayer );
      to.paypalPayment.copy( from.paypalPayment );
      to.paypalPurchaseUnits.copy( from.paypalPurchaseUnits );
      to.paypalSellerProtection.copy( from.paypalSellerProtection );     
      // to.paypalShipping.copy( from.paypalShipping );
      to.paymentOption = from.paymentOption;
      to.paymentOptionAmount = from.paymentOptionAmount;
      to.personKey = from.personKey;
      to.sitUserId = from.sitUserId;
      to.sitUserKey = from.sitUserKey;
      to.status = from.status;
      to.subscriptionMonths = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(from.subscriptionMonths) ? from.subscriptionMonths : to.subscriptionMonths;
      to.subscriptionMonthsNum = from.subscriptionMonthsNum;
      to.subtotal = from.subtotal;
      to.tax = from.tax;
      to.total = from.total;
      to.totalStr = from.totalStr;
      to.upgrade.copy( from.upgrade );
      to.userName = from.userName ? from.userName : to.userName;
      to.update_time = from.update_time && from.update_time.length > 0 ? from.update_time : to.update_time;
    }
    return to;
  }
  // ----------------------------------------------------------------------------------
  getSubscriptionMonthsNum ( paymentOption : string ): number
  {
    let months = 0;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( paymentOption ) )
    {
      
      let parts = paymentOption.split( "months" );
      if ( parts != null )
      {
        months = parseInt( parts[ 0 ], 10 );
      }
      else
      {
        months = 12;
      }
    }
    return months;
  }
  // ----------------------------------------------------------------------------------
  public getSubscriptionMonths ( paymentOption : string ) : string
  {
    let subscriptionMonths = '';
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( paymentOption ) )
    {
      let parts = paymentOption.split( '-' );
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( parts ) && parts.length > 1 )
      {
        subscriptionMonths = parts[ 0 ];
      }
    }
    return subscriptionMonths;
  }
  // ----------------------------------------------------------------------------------
  public setSitUserKeyAndId (loginSuccess : LoginSuccess): void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( loginSuccess ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( loginSuccess.signedInUserKey ))
    {
      // this.onApprovedDetailsModel.sitUserKey = loginSuccess.signedInUserKey;
      this.sitUserId = this.payPalCreditCardAddress.sitUserId = loginSuccess.signedInUserId;
      this.sitUserKey = this.payPalCreditCardAddress.sitUserKey = loginSuccess.signedInUserKey;
      this.paypalPayer.sitUserKey = loginSuccess.signedInUserKey;
      this.paypalPayment.sitUserKey = loginSuccess.signedInUserKey;
      this.paypalSellerProtection.sitUserKey = loginSuccess.signedInUserKey;
      
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.paypalCapture ) )
      {
        this.paypalCapture.sitUserKey = loginSuccess.signedInUserKey;
        this.paypalCapture.payPalSellerProtection.sitUserKey = loginSuccess.signedInUserKey;
      }
      // this.paypalShipping.sitUserKey = loginSuccess.signedInUserKey; // TODO: may need to verify that sippingAddres===billingAddress or other value here
    }
  }
  // ----------------------------------------------------------------------------------
  // public payerAddressToString (): string
  // {
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.creditCardAddress ) )
  //  {

  //    this.payerAddressStr = this.creditCardAddress.toString();
  //  }
  //  else this.payerAddressStr = '';
  //  return this.payerAddressStr;
  // }
  // ----------------------------------------------------------------------------------
  public payPalPayerToString () : string
  {
    let paypalPayerStr = '';
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.paypalPayer ) )
    {

      paypalPayerStr = JSON.stringify( this.paypalPayer);

        //'{\n paypalPayer.address.country_code: ' +
        //this.paypalPayer.country_code +


        //',\n' +
        //'fullName:' +
        //this.fullName +

        //',\n payer_id: ' +
        //this.paypalPayer.payer_id +

        //',\n' +
        //'sitUserKey:' +
        //this.paypalPayer.sitUserKey +

        //'\n}';
    }
    return paypalPayerStr;
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{' +
      '\n authorizedData: ' +
      this.authorizedData +

      ',\n' +
      'billingToken:' +
      this.billingToken +

      ',\n' +
      'create_time:' +
      this.create_time +

      ',\n' +
      'cryptUserKey: ' +
      this.cryptUserKey +
      
      ',\n' +
      'date:' +
      this.date +

      ',\n' +
      'dateStr:' +
      this.dateStr +

      ',\n' +
      'email: ' +
      this.email +

      ',\n' +
      'facilitatorAccessToken:' +
      this.facilitatorAccessToken +

      ',\n' +
      'fullName: ' +
      this.fullName +

      // '\n paypalOrdId: ' +
      // this.paypalOrdId +

      '\n intent: ' +
      this.intent +

      '\n ip: ' +
      this.ip +

      ',\n' +
      'latitude: ' +
      this.latitude +

      ',\n' +
      'longitude: ' +
      this.longitude +     

      ',\n' +
      'orderID: ' +
      this.orderID +

      ',\n' +
      'payerID: ' +
        this.payerID +

      ',\n' +
      'onApproved: ' +
      this.onApproved +

      ',\n' +
      'onApprovedDetails: ' +
      this.onApprovedDetails +
       
      ',\n' +
      'onCanceled: ' +
      this.onCanceled +

      ',\n' +
      'onError: ' +
      this.onError +

      ',\n' +
      'onClientAuthorization: ' +
      this.onClientAuthorization +

      // ',\n' +
      // 'payerAddress: ' +
      // this.payerAddress.toString() +

      ',\n' +
      'payPalCreditCardAddressStr: ' +
      // this.payPalCreditCardAddress.toString() +
      JSON.stringify(this.payPalCreditCardAddress) +

      ',\n' +
      'paypalCapture: ' +
      // this.paypalCapture.toString() +
      JSON.stringify( this.paypalCapture ) +

      // ',\n' +
      // 'paypalPayee: ' +
      // this.paypalPayee.toString() +

      ',\n' +
      'paypalPayer: ' +
      // this.paypalPayer.toString() +
      JSON.stringify( this.paypalPayer ) +

      ',\n' +
      'paypalPurchaseUnits: ' +
       // this.paypalPurchaseUnits.toString() +
       JSON.stringify( this.paypalPurchaseUnits ) +

      ',\n' +
      'paypalSellerProtection: ' +
       // this.paypalSellerProtection.toString() +
       JSON.stringify( this.paypalSellerProtection ) +

      // ',\n' +
      // 'paypalShipping: ' +
      // this.paypalShipping.toString() +

      ',\n' +
      'paypalPayment: ' +
       // this.paypalPayment.toString() +
       JSON.stringify( this.paypalPayment ) +
      
      ',\n' +
      'paymentOption: ' +
      this.paymentOption +

      ',\n' +
      'paymentOptionAmount: ' +
      this.paymentOptionAmount +

      '\n personKey: ' +
      this.personKey +

      ',\n' +
      'sitUserId: ' +
      this.sitUserId +

      ',\n' +
      'sitUserKey: ' +
      this.sitUserKey +

      ',\n' +
      'status: ' +
      this.status +

      ',\n' +
      'subscriptionMonths: ' +
      this.subscriptionMonths +

      ',\n' +
      'subscriptionMonthsNum: ' +
      this.subscriptionMonthsNum +

      ',\n' +
      'subtotal: ' +
      this.subtotal +

      ',\n' +
      'tax: ' +
      this.tax +

      ',\n' +
      'total: ' +
      this.total +

      ',\n' +
      'totalStr: ' +
      this.totalStr +

      ',\n' +
      'upgrade: ' +
       // this.upgrade.toString() +
       JSON.stringify( this.upgrade ) +

      ',\n' +
      'userName: ' +
      this.userName +

      ',\n' +
      'update_time:' +
      this.update_time +

      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
