
// Note:  the Kv name where the first leter is capital and the second letter is lowercase, implies that the value is of type 'any'
//        this model is postfixed with 'glyph' because it is exclusively used to represent each activity with a unique glyph.
// ------------------------------------------------------------

import { FrequentlyUsedFunctionsServiceStatic } from "../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";
import { KvPhoto } from "./kvPhoto.model";

//
export class KvMemberActivityGlyph {
  // ----------------------------------
  // Begin of Photo model's properties:
  // ----------------------------------
  // public approval = '';
  //public contentType = '';
  //public cryptUserKey = '';
  //public date = '';
  //public description = '';
  //public file = File;
  //public formData : FormData = new FormData();
  //public id = 0;
  //public imageBytes : [] = [];
  //public image : any;
  //public imageString = '';
  //public isPrimary = false;
  //public isSelected = false;
  //public isUnlocked = false;
  //public itemNum = 0;
  //public latitude = 0;
  //public longitude = 0;
  //public location = {};
  //public path = '';
  //public pic = '';
  //public photoId = 0;
  //public photoBnId = 0;
  //public privacy = '';
  //public profileId = 0;
  //public sitUserId = 0;
  //public sitUserKey = '';
  //public signedInUserId = 0;
  //public sourceId = 0;
  //public sourceType = '';
  //public title = '';
  //public url = '';
  // ----------------------------------
  // End of Photo model's properties
  // ----------------------------------
  public action = '';
  public callerName = '';
  public cryptUserKey = '';
  public date = '';
  public receiverCryptUserKey = '';
  public senderCryptUserKey = '';
  public receiverSitUserId = 0;
  public senderSitUserId = 0;
  public receiverSitUserKey = '';
  public senderSitUserKey = '';
  public elemId = '';
  public glyphName = ''; // to hold the glyphName for privacy
  public glyphIndex = -1; // for primary, public, private
  public id = 0;
  public index = 0;
  public isBlocked = false;
  public isBuddied = false;
  public isFriend = false;
  public isLiked = false;
  public isPrimary = false;
  public isPrivate = false;
  public isStarred = false;
  public isTracked = false;
  public isUnlocked = false;
  public isVisible = false;
  public isWinked = false;
  public itemNum = 0;
  public key = '';
  public kvPhoto : KvPhoto = new KvPhoto();
  public message = '';
  public model : any;
  public modelName = '';
  public pageNum = 0;
  public pic = '';
  public photoId = 0;
  public photoBnId = 0;
  public privacy = '';
  public profileId = 0;
  public returnUrl = '';
  public sitUserId = 0;
  public sitUserKey = '';
  public signedInUserId = 0;
  public value : any;
  // ---------------------------------------------
  constructor () { }
  // ---------------------------------------------
  public copySourceToDestination (s : KvMemberActivityGlyph, a : KvMemberActivityGlyph) : KvMemberActivityGlyph {
    
    s.action = a.action;
    s.callerName = a.callerName;
    s.cryptUserKey = a.cryptUserKey;
    s.date = a.date;
    s.receiverCryptUserKey = a.receiverCryptUserKey;
    s.senderCryptUserKey = a.senderCryptUserKey;
    s.receiverSitUserId = a.receiverSitUserId;
    s.senderSitUserId = a.senderSitUserId;
    s.receiverSitUserKey = a.receiverSitUserKey;
    s.senderSitUserKey = a.senderSitUserKey;
    s.glyphName = a.glyphName;
    s.glyphIndex = a.glyphIndex;
    s.id = a.id;
    s.index = a.index;
    s.isBlocked = a.isBlocked;
    s.isBuddied = a.isBuddied;
    s.isFriend = a.isFriend;
    s.isLiked = a.isLiked;
    s.isPrimary = a.isPrimary;
    s.isPrivate = a.isPrivate;
    s.isStarred = a.isStarred;
    s.isTracked = a.isTracked;
    s.isUnlocked = a.isUnlocked;
    s.isWinked = a.isWinked;
    s.key = a.key;
    s.kvPhoto = new KvPhoto().copySourceToDestination(s.kvPhoto, a.kvPhoto);
    // -------------------------------
    // Begin of KvPhoto model:
    // -------------------------------
    //this.description = a.description;
    s.elemId = a.elemId;
    // this.entityName = a.entityName;
    // this.file = a.file;
    // this.formData = a.formData;
    s.glyphName = a.glyphName;
    s.glyphIndex = a.glyphIndex;
    s.id = a.id;
    //this.image = a.image;
    //this.imageBytes = a.imageBytes;
    //this.imageString = a.imageString;
    s.index = a.index;
    s.pic = a.pic;
    s.isPrivate = a.isPrivate;
    s.isUnlocked = a.isUnlocked;
    s.isPrimary = a.isPrimary;
    s.itemNum = a.itemNum;
    s.key = a.key;
    //this.latitude = a.latitude;
    //this.location = a.location;
    //this.longitude = a.longitude;
    //this.path = a.path;
    s.pageNum = a.pageNum;
    //this.pic = a.pic;
    s.privacy = a.privacy;
    s.profileId = a.profileId;
    s.returnUrl = a.returnUrl;
    //this.title = a.title;
    s.value = a.value;
    //this.url = a.url;
    // -------------------------------
    // End of KvPhoto model:
    // -------------------------------

    s.message = a.message;
    s.model = a.model;
    s.modelName = a.modelName;
    s.pageNum = a.pageNum;
    s.photoId = a.photoId;
    s.photoBnId = a.photoBnId;
    s.profileId = a.profileId;
    s.privacy = a.privacy;
    s.returnUrl = a.returnUrl;
    s.sitUserId = a.sitUserId;
    s.sitUserKey = a.sitUserKey;
    s.signedInUserId = a.signedInUserId;
    s.value = a.value;
    return this;
  }
  // ---------------------------------------------
  // Caution!!: if you call this you may loose the non-KvPhoto properties' data.
  // ---------------------------------------------
  public copyFromKvPhoto (a : KvPhoto) : KvMemberActivityGlyph {
    //this.action = a.action;
    //this.callerName = a.callerName;
    this.cryptUserKey = a.cryptUserKey;
    this.date = a.date;
    //this.doeeCryptUserKey = a.doeeCryptUserKey;
    //this.doerCryptUserKey = a.doerCryptUserKey;
    //this.doeeSitUserId = a.doeeSitUserId;
    //this.doerSitUserId = a.doerSitUserId;
    //this.doeeSitUserKey = a.doeeSitUserKey;
    //this.doerSitUserKey = a.doerSitUserKey;
    this.glyphName = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( a.privacy) ? a.privacy : a.glyphName;
    //this.isBlocked = a.isBlocked;
    //this.isBuddied = a.isBuddied;
    //this.isFriend = a.isFriend;
    //this.isLiked = a.isLiked;
    this.isPrimary = a.isPrimary;
    this.isPrivate = a.isPrivate;
    //this.isStarred = a.isStarred;
    //this.isTracked = a.isTracked;
    this.isUnlocked = a.isUnlocked;
    // this.isWinked = a.isWinked;
    this.key = a.privacy; // a.privacy.toLocaleLowerCase().indexOf('primary') !== -1 ? a.privacy : a.glyphName;
    this.kvPhoto = new KvPhoto().copySourceToDestination(this.kvPhoto, a);
    // -------------------------------
    // Begin of KvPhoto model:
    // -------------------------------
    //this.description = a.description;
    this.elemId = a.elemId;
    // this.entityName = a.entityName;
    //this.file = a.file;
    //this.formData = a.formData;
    this.glyphName = a.glyphName;
    // this.glyphIndex = a.glyphIndex;
    this.id = a.id;
    //this.image = a.image;
    //this.imageBytes = a.imageBytes;
    //this.imageString = a.imageString;
    this.index = a.index;
    this.pic = a.pic;
    this.isPrivate = a.isPrivate;
    this.isUnlocked = a.isUnlocked;
    this.isPrimary = a.isPrimary;
    this.itemNum = a.itemNum;
    this.key = a.key;
    //this.latitude = a.latitude;
    //this.location = a.location;
    //this.longitude = a.longitude;
    //this.path = a.path;
    this.pageNum = a.pageNum;
    //this.pic = a.pic;
    this.privacy = a.privacy;
    this.profileId = a.profileId;
    this.returnUrl = a.returnUrl;
    //this.title = a.title;
    this.value = a.value;
    //this.url = a.url;
    // -------------------------------
    // End of KvPhoto model:
    // -------------------------------

    //this.message = a.message;
    //this.model = a.model;
    // this.modelName = a.modelName;
    this.pageNum = a.pageNum;
    this.photoId = a.photoId;
    this.photoBnId = a.photoBnId;
    this.profileId = a.profileId;
    this.privacy = a.privacy;
    this.returnUrl = a.returnUrl;
    this.sitUserId = a.sitUserId;
    this.sitUserKey = a.sitUserKey;
    this.signedInUserId = a.signedInUserId;
    this.value = a.value;
    return this;
  }
  // ---------------------------------------------
  // Caution!!: if you call this you may loose the non-KvPhoto properties' data.
  // ---------------------------------------------
  public copyToKvPhoto (a : KvMemberActivityGlyph) : KvPhoto {
    var d : KvPhoto = new KvPhoto();
    //d.action = a.action;
    //d.callerName = a.callerName;
    d.cryptUserKey = a.cryptUserKey;
    d.date = a.date;
    //d.doeeCryptUserKey = a.doeeCryptUserKey;
    //d.doerCryptUserKey = a.doerCryptUserKey;
    //d.doeeSitUserId = a.doeeSitUserId;
    //d.doerSitUserId = a.doerSitUserId;
    //d.doeeSitUserKey = a.doeeSitUserKey;
    //d.doerSitUserKey = a.doerSitUserKey;
    d.glyphName = a.glyphName;
    d.id = a.id;
    d.index = a.index;
    //d.isBlocked = a.isBlocked;
    //d.isBuddied = a.isBuddied;
    //d.isFriend = a.isFriend;
    //d.isLiked = a.isLiked;
    d.isPrimary = a.isPrimary;
    d.isPrivate = a.isPrivate;
    // d.isStarred = a.isStarred;
    // d.isTracked = a.isTracked;
    d.isUnlocked = a.isUnlocked;
    // d.isWinked = a.isWinked;
    d.key = a.key.toLowerCase().indexOf('primary') !== -1 ? a.key : a.glyphName;
    d = a.kvPhoto;
    // -------------------------------
    // Begin of KvPhoto model:
    // -------------------------------
    // this.description = a.description;
    d.elemId = a.elemId;
    // d.entityName = a.entityName;
    //d.file = a.file;
    //d.formData = a.formData;
    d.glyphName = a.glyphName;
    d.id = a.id;
    // d.image = a.image;
    // d.imageBytes = a.imageBytes;
    // d.imageString = a.imageString;
    d.index = a.index;
    d.pic = a.pic;
    d.isPrivate = a.isPrivate;
    d.isUnlocked = a.isUnlocked;
    d.isPrimary = a.isPrimary;
    d.itemNum = a.itemNum;
    d.key = a.key;
    // d.latitude = a.latitude;
    // d.location = a.location;
    // d.longitude = a.longitude;
    // d.path = a.path;
    d.pageNum = a.pageNum;
    // d.pic = a.pic;
    d.privacy = a.privacy;
    d.profileId = a.profileId;
    d.returnUrl = a.returnUrl;
    //d.title = a.title;
    d.value = a.value;
    //d.url = a.url;
    // -------------------------------
    // End of KvPhoto model:
    // -------------------------------

    //d.message = a.message;
    //d.model = a.model;
    //d.modelName = a.modelName;
    d.pageNum = a.pageNum;
    d.photoId = a.photoId;
    d.photoBnId = a.photoBnId;
    d.profileId = a.profileId;
    d.privacy = a.privacy;
    d.returnUrl = a.returnUrl;
    d.sitUserId = a.sitUserId;
    d.sitUserKey = a.sitUserKey;
    d.signedInUserId = a.signedInUserId;
    d.value = a.value;
    return d;
  }
  // ---------------------------------------------
  public copyIfExists (s : KvMemberActivityGlyph, a : KvMemberActivityGlyph) : KvMemberActivityGlyph {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(s)) {
      s = new KvMemberActivityGlyph();
		}
    if (a.action) {
      s.action = a.action;
    }

    if (a.cryptUserKey) {
      s.cryptUserKey = a.cryptUserKey;
    }

    if (a.callerName) {
      s.callerName = a.callerName;
    }

    if (a.date) {
      s.date = a.date;
    }
    if (a.receiverCryptUserKey) {
      s.receiverCryptUserKey = a.receiverCryptUserKey;
    }
    if (a.senderCryptUserKey) {
      s.senderCryptUserKey = a.senderCryptUserKey;
    }

    if (a.receiverSitUserId) {
      s.receiverSitUserId = a.receiverSitUserId;
    }

    if (a.senderSitUserId) {
      s.senderSitUserId = a.senderSitUserId;
    }

    if (a.receiverSitUserKey) {
      s.receiverSitUserKey = a.receiverSitUserKey;
    }

    if (a.senderSitUserKey) {
      s.senderSitUserKey = a.senderSitUserKey;
    }

    if (a.glyphName) {
      s.glyphName = a.glyphName;
    }
    if (a.glyphIndex) {
      s.glyphIndex = a.glyphIndex;
    }
    if (a.id) {
      s.id = a.id;
    }
    if (a.index) {
      s.index = a.index;
    }
    if (a.isBlocked) {
      s.isBlocked = a.isBlocked;
    }

    if (a.isBuddied) {
      s.isBuddied = a.isBuddied;
    }

    if (a.isFriend) {
      s.isFriend = a.isFriend;
    }

    if (a.isLiked) {
      s.isLiked = a.isLiked;
    }
    if (a.pic) {
      s.pic = a.pic;
    }
    if (a.isPrimary) {
      s.isPrimary = a.isPrimary;
    }

    if (a.isPrivate) {
      s.isPrivate = a.isPrivate;
    }

    if (a.isStarred) {
      s.isStarred = a.isStarred;
    }

    if (a.isTracked) {
      s.isTracked = a.isTracked;
    }

    if (a.isUnlocked) {
      s.isUnlocked = a.isUnlocked;
    }

    if (a.isWinked) {
      s.isWinked = a.isWinked;
    }

    if (a.key) {
      s.key = a.key;
    }
    s.kvPhoto = new KvPhoto().copySourceToDestination(s.kvPhoto, a.kvPhoto);

    if (a.photoId > 0) {
      s.photoId = a.photoId;
    }

    if (a.photoBnId > 0) {
      s.photoBnId = a.photoBnId;
    }

    if (a.profileId > 0) {
      s.profileId = a.profileId;
    }
    if (a.privacy) {
      s.privacy = a.privacy;
    }

    if (a.returnUrl) {
      s.returnUrl = a.returnUrl;
    }

    if (a.sitUserId > 0) {
      s.sitUserId = a.sitUserId;
    }

    if (a.sitUserKey) {
      s.sitUserKey = a.sitUserKey;
    }

    if (a.signedInUserId > 0) {
      s.signedInUserId = a.signedInUserId;
    }

    if (a.value) {
      s.value = a.value;
    }
    // -----------------------------------
    //  Begin of KvPhoto model:
    // -----------------------------------

    //if (a.description) {
    //  s.description = a.description;
    //}

    if (a.elemId) {
      s.elemId = a.elemId;
    }

    // if (a.entityName) {
    //  s.entityName = a.entityName;
    // }

    //if (a.file) {
    //  s.file = a.file;
    //}

    //if (a.formData) {
    //  s.formData = a.formData;
    //}

  
    if (a.id) {
      s.id = a.id;
    }
    //if (a.image) {
    //  s.image = a.image;
    //}

    //if (a.imageString) {
    //  s.imageString = a.imageString;
    //}

    //if (a.imageBytes) {
    //  s.imageBytes = a.imageBytes;
    //}

    if (a.index) {
      s.index = a.index;
    }

    if (a.isPrimary) {
      s.isPrimary = a.isPrimary;
    }

    if (a.isPrivate) {
      s.isPrivate = a.isPrivate;
    }

    if (a.isUnlocked) {
      s.isUnlocked = a.isUnlocked;
    }

    if (a.itemNum) {
      s.itemNum = a.itemNum;
    }

    if (a.key) {
      s.key = a.key;
    }

    //if (a.latitude !== 0) {
    //  s.latitude = a.latitude;
    //}

    //if (a.location) {
    //  s.location = a.location;
    //}

    //if (a.longitude !== 0) {
    //  s.longitude = a.longitude;
    //}

    //if (a.path) {
    //  s.path = a.path;
    //}

    if (a.pageNum > 0) {
      s.pageNum = a.pageNum;
    }

    //if (a.pic) {
    //  s.pic = a.pic;
    //}

    if (a.returnUrl) {
      s.returnUrl = a.returnUrl;
    }

    //if (a.title) {
    //  s.title = a.title;
    //}

    if (a.value) {
      s.value = a.value;
    }
    //if (a.url) {
    //  s.url = a.url;
    //}
    // -----------------------------------
    //  End of KvPhoto model:
    // -----------------------------------
    return s;
  }
  // ---------------------------------------------
  //copyFromKvPhotoIfExists (s : KvMemberActivityGlyph, a : any) : KvMemberActivityGlyph {
  
  //  return this.copyIfExists(s,a);
  //}

  // ---------------------------------------------
  public cloneIfExists (a : KvMemberActivityGlyph) : KvMemberActivityGlyph {
    const that = new KvMemberActivityGlyph();

    if (a.action) {
      that.action = a.action;
    }

    if (a.cryptUserKey) {
      that.cryptUserKey = a.cryptUserKey;
    }

    if (a.callerName) {
      that.callerName = a.callerName;
    }

    if (a.date) {
      that.date = a.date;
    }
    if (a.receiverCryptUserKey) {
      that.receiverCryptUserKey = a.receiverCryptUserKey;
    }
    if (a.senderCryptUserKey) {
      that.senderCryptUserKey = a.senderCryptUserKey;
    }

    if (a.receiverSitUserId > 0) {
      that.receiverSitUserId = a.receiverSitUserId;
    }

    if (a.senderSitUserId > 0) {
      that.senderSitUserId = a.senderSitUserId;
    }

    if (a.receiverSitUserKey) {
      that.receiverSitUserKey = a.receiverSitUserKey;
    }

    if (a.senderSitUserKey) {
      that.senderSitUserKey = a.senderSitUserKey;
    }

    if (a.glyphName) {
      that.glyphName = a.glyphName;
    }
    //if (a.glyphIndex) {
    //  s.glyphIndex = a.glyphIndex;
    //}

    if (a.id) {
      that.id = a.id;
    }

    if (a.index) {
      that.index = a.index;
    }
    if (a.isBlocked) {
      that.isBlocked = a.isBlocked;
    }

    if (a.isBuddied) {
      that.isBuddied = a.isBuddied;
    }

    if (a.isFriend) {
      that.isFriend = a.isFriend;
    }

    if (a.isLiked) {
      that.isLiked = a.isLiked;
    }

    if (a.isPrimary) {
      that.isPrimary = a.isPrimary;
    }

    if (a.isPrivate) {
      that.isPrivate = a.isPrivate;
    }

    if (a.isStarred) {
      that.isStarred = a.isStarred;
    }

    if (a.isTracked) {
      that.isTracked = a.isTracked;
    }

    if (a.isUnlocked) {
      that.isUnlocked = a.isUnlocked;
    }

    if (a.isWinked) {
      that.isWinked = a.isWinked;
    }

    if (a.key) {
      that.key = a.key;
    }
    that.kvPhoto = new KvPhoto().copySourceToDestination(that.kvPhoto, a.kvPhoto);
    if (a.photoId > 0) {
      that.photoId = a.photoId;
    }

    if (a.photoBnId > 0) {
      that.photoBnId = a.photoBnId;
    }

    if (a.profileId > 0) {
      that.profileId = a.profileId;
    }
    if (a.privacy) {
      that.privacy = a.privacy;
    }

    if (a.returnUrl) {
      that.returnUrl = a.returnUrl;
    }

    if (a.sitUserId > 0) {
      that.sitUserId = a.sitUserId;
    }

    if (a.sitUserKey) {
      that.sitUserKey = a.sitUserKey;
    }

    if (a.signedInUserId > 0) {
      that.signedInUserId = a.signedInUserId;
    }

    if (a.value) {
      that.value = a.value;
    }
    // -----------------------------------
    //  Begin of KvPhoto model:
    // -----------------------------------

    //if (a.description) {
    //  that.description = a.description;
    //}

    if (a.elemId) {
      that.elemId = a.elemId;
    }

    // if (a.entityName) {
    //  that.entityName = a.entityName;
    // }

    //if (a.file) {
    //  that.file = a.file;
    //}

    //if (a.formData) {
    //  that.formData = a.formData;
    //}

    if (a.glyphName) {
      that.glyphName = a.glyphName;
    }

    if (a.id) {
      that.id = a.id;
    }
    //if (a.image) {
    //  that.image = a.image;
    //}

    //if (a.imageString) {
    //  that.imageString = a.imageString;
    //}

    //if (a.imageBytes) {
    //  that.imageBytes = a.imageBytes;
    //}

    if (a.index) {
      that.index = a.index;
    }

    if (a.isPrimary) {
      that.isPrimary = a.isPrimary;
    }

    if (a.isPrivate) {
      that.isPrivate = a.isPrivate;
    }

    if (a.isUnlocked) {
      that.isUnlocked = a.isUnlocked;
    }

    if (a.itemNum) {
      that.itemNum = a.itemNum;
    }

    if (a.key) {
      that.key = a.key;
    }

    //if (a.latitude !== 0) {
    //  that.latitude = a.latitude;
    //}

    //if (a.location) {
    //  that.location = a.location;
    //}

    //if (a.longitude !== 0) {
    //  that.longitude = a.longitude;
    //}

    //if (a.path) {
    //  that.path = a.path;
    //}

    if (a.pageNum > 0) {
      that.pageNum = a.pageNum;
    }

    if (a.pic) {
      that.pic = a.pic;
    }

    if (a.returnUrl) {
      that.returnUrl = a.returnUrl;
    }

    //if (a.title) {
    //  that.title = a.title;
    //}

    if (a.value) {
      that.value = a.value;
    }
    //if (a.url) {
    //  that.url = a.url;
    //}
    // -----------------------------------
    //  End of KvPhoto model:
    // -----------------------------------
    return that;
  }
  // ---------------------------------------------
  
}
