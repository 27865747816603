import { StringUtils } from "./StringUtils";
import { Logger } from "./Logger";
import {Key} from "./Key";
import {List} from "./List";
/**
 * Created by eric.perron on 18/07/2017.
 */
export class URLUtils {


  public static pathToURIParams(path:string = ''):List<Key> {

    if (path == null || path == "")
       path = window.location.href.split("?")[1];

    if (path == null || path == "")
      return new List<Key>();

    let vars:Array<string> = path.split("&");
    let list:List<Key> = new List<Key>();

    for (let i:number = 0; i < vars.length; i++)
    {
      let pair:Array<string> = vars[i].split("=");
      let key:Key = new Key(decodeURIComponent(pair[1]), pair[0], pair[0]);
      if (list.getItemById(key.id) == null)
        list.addItem(key);
    }

    return list;
  };


  public static addPaths(basePath:string, subFolderPath:string):string{

    let newPath:string = basePath;

    if(newPath.charAt(newPath.length-1) != "\\" && newPath.charAt(newPath.length-1) != "/")
    {
      newPath += "/";
    }

    return newPath+subFolderPath;

  }


  public static objToForm(obj:any):string{
    let str = "";


    for (let key in obj) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + StringUtils.replace(obj[key],"&","&amp;");
    }

    return str;
  }

  public static objToURIParams(obj:any):string{

    let str = "";


    for (let key in obj) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + encodeURIComponent(obj[key]);
    }

    return str;
  }

  public static pathToLocation (path:string):URLLocation
  {
    return new URLLocation(path);
  }

  public static  relativeToFullPath(path:string, defaultPath:string):string
  {
    path = StringUtils.replace(path, "\\", "/");

    let a:number = defaultPath.indexOf("://");
    let urlProtocol:string = "";
    let urlDomain:string = "";

    if(a != -1)
    {
      let arrPath:Array<string> = defaultPath.split("://");
      urlProtocol = arrPath[0];
      urlDomain = arrPath[1];
    }
    else
    {
      urlProtocol = "https";
      urlDomain = defaultPath;
    }

    var i:number = path.indexOf("/");
    var i2:number = path.indexOf("//");

    if(i2 == 0) // url starts with "//"
      return urlProtocol+":"+ path;

    if(i == 0) // url starts with "/"
      return urlProtocol + "://" +urlDomain + path;

    if(i == i2) // url has "//" before any "/"
      return path;

    var i3:number = path.indexOf(".");

    if(i3 < i) // there is a "." before a "/".
      return urlProtocol + "://"+path;

    return urlProtocol + "://" +urlDomain + "/" + path;
  }

}


export class URLLocation {

  public host:string = '';
  public hostName : string = '';
  public protocol : string = '';
  public pathName : string = '';
  public port : string = '';

  public get origin():string
  {

    if(StringUtils.isNullOrEmpty(this.protocol))
      return this.hostName;

    if(!StringUtils.isNullOrEmpty(this.port))
    {
      return StringUtils.Format("{0}:{1}//{2}",[
        this.protocol,
        this.port,
        this.hostName
      ]);
    }
    else
      return StringUtils.Format("{0}://{1}",[
        this.protocol,
        this.hostName
      ]);
  }

  public get fullPath():string
  {
    return StringUtils.Format("{0}{1}",[
      this.origin,
      this.pathName,
    ]);
  }

  public set fullPath(str:string)
  {
    str = StringUtils.replace(str,"\\","/");

    let iA:number = str.indexOf("//");

    if(iA != -1)
    {
      let strA: string = str.slice(0, iA);
      let arrA: Array<string> = strA.split(":");
      if (arrA[0]) this.protocol = arrA[0];
      if (arrA[1]) this.port = arrA[1];
      iA= iA + 2;
    }
    else
      iA = 0;

    let strB:string = str.slice(iA,str.length);

    let iB:number = strB.indexOf("/");
    this.hostName = strB.slice(0,iB);
    this.pathName = strB.slice(iB,strB.length);

  }


  constructor(fullPath:string = ''){
    this.fullPath = fullPath;
  }

}
