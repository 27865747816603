<ul id="keyValueAnyArrComponentId" [@slideYRotateX] style="width: 100%; margin: 0.25rem; list-style:none;" class="expandMenu">
  <li expandMenu style="margin: 0.25rem;">
    <a *ngIf="arrKvName.length > 0" (click)="isClosedBool.isClosed=!isClosedBool.isClosed">
      <div class="kvBttnS" style="width:100%;">
        <span style="float:left;width:75%;">
          {{arrKvName}}
        </span>
        <span style="float:right;width:25%;"  class="text-right">
          <span *ngIf="isClosedBool.isClosed" class="glyphicon glyphicon-plus-sign hvr-icon" style="padding-top:0.2rem; font-size: 1.2rem;"></span>
          <span *ngIf="!isClosedBool.isClosed" class="glyphicon glyphicon-minus-sign hvr-icon" style="padding-top:0.2rem;font-size:1.2rem;"></span>
        </span>
      </div>
    </a>
  </li>
  <li style="margin:0.25rem;">
    <ul *ngIf="!isClosedBool.isClosed" [ngClass]="{'submenu':true, 'about':arrKvAnyArr.length === 0, 'picBg':arrKvAnyArr.length > 0}" align="left" style="width:100%;margin:0.25rem 0.25rem 0.25rem -1rem;">
      <li style="margin:0.25rem;">
        <div *ngIf="arrKvName.length > 0">
          <div *ngIf="arrKvName.length > 0" class="kvBttnSk">{{kvLegend[2]}} : {{arrKvName}} <span *ngIf="arrKvLegend.length > 0 && isRequired" class="errSub" style="margin-left: -2px;">*</span></div>
        </div>
        <div class="col-md-10 col-lg-offset-1 text-left">
          <div *ngFor="let kvAnyArr of arrKvAnyArr; let i=index">
            <div *ngIf="kvAnyArr.value" >
              <div class="half kvxxSk text-left wordWrap" style="float:left;text-transform:capitalize;vertical-align:text-top;">{{kvAnyArr.key}} : </div>
              <div *ngIf="kvAnyArr.key.indexOf('image') === -1 && kvAnyArr?.value" class="half kvxxSv wordWrap text-right" style="float:right;vertical-align:text-top;">
                {{kvAnyArr.value}}
              </div>
              <div *ngIf="kvAnyArr.key.indexOf('image') !== -1 && kvAnyArr.value" class="half kvxxSv text-center">
                <img [src]="kvAnyArr.value | safe: 'pic'" style="height:7rem;width:9rem;" class="img-responsive img-rounded" />
              </div>
              <div style="padding:0.1rem;border-bottom:1px solid silver;"></div>
            </div>
          </div>
        </div>
        <div *ngIf="isRequired && ! isRequiredPassed() && isFormSubmitted">
          <span class="flex-text errxxS" style="text-transform: capitalize;color:orangered;"><em>{{kvLegend[2]}}</em> is required, please select at leaset one value.</span>
        </div>
      </li>
    </ul>
  </li>
</ul>
<!--==========================================================================================================-->
