
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { CheckConnectionService } from './checkConnectionService.service';
import { HeartbeatService } from './heartbeatService.service';
import { HttpService } from './httpService.service';
import { KvArrayService } from './kvArrayService.service';
import { LiaisonService } from './liaisonService.service';
import { LogRegService } from './logRegService.service';
import { OffOnService } from './offOnService';
import { TimerService } from './timerService.service';

// import { WhenScrollEndsService } from './whenScrollEndsService.service';

@Injectable({
  providedIn: 'root',
})
export class CoreServiceService implements OnDestroy {
  public emitterDestroyed$: Subject<boolean> = new Subject();
  constructor (
    public checkConnectionService : CheckConnectionService,
    public heartbeatService: HeartbeatService,
    public httpService : HttpService,
    public kvArrayService : KvArrayService,
    public liaisonService: LiaisonService,
    public logRegService : LogRegService,
    public offOnService : OffOnService,
    public timerService: TimerService,
  ) { }
  // --------------------------------------------------------------

  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ------------------------------------------------------------------
  setCheckConnectionService(ccs: CheckConnectionService): void {
    this.checkConnectionService = ccs;
  }
  getCheckConnectionService(): CheckConnectionService {
    return this.checkConnectionService;
  }
  // ------------------------------------------------------------------
    setHeartbeatService(hbs: HeartbeatService): void {
     this.heartbeatService = hbs;
    }
    getHeartbeatService(): HeartbeatService {
     return this.heartbeatService;
    }
  // ------------------------------------------------------------------
   setHttpService(ms: HttpService): void {
    this.httpService = ms;
   }
   getHttpService(): HttpService {
    return this.httpService;
   }
  // ------------------------------------------------------------------
   setKvArrayService(as: KvArrayService): void {
    this.kvArrayService = as;
   }
  // -----------------------------------------------------------------
   getKvArrayService(): KvArrayService {
    return this.kvArrayService;
  }
  // ------------------------------------------------------------------
  setLiaisonService (lr : LiaisonService) : void {
    this.liaisonService = lr;
  }
  // -----------------------------------------------------------------
  getLiaisonService () : LiaisonService {
    return this.liaisonService;
  }
  // ------------------------------------------------------------------
  setLogRegService (lr : LogRegService) : void {
    this.logRegService = lr;
  }
  // -----------------------------------------------------------------
  getLogRegService () : LogRegService {
    return this.logRegService;
  }

  // 
  
  // ------------------------------------------------------------------
   setTimerService(ts: TimerService): void {
    this.timerService = ts;
   }
   getTimerService(): TimerService {
    return this.timerService;
  }
  // ------------------------------------------------------------------
  setOffOnService (os : OffOnService) : void {
    this.offOnService = os;
  }
  getOffOnService () : OffOnService {
    return this.offOnService;
  }
  // ------------------------------------------------------------------
  // setWhenScrollEndsService(wss: WhenScrollEndsService): void {
  //  this.whenScrollEndsService = wss;
  // }
  // getWhenScrollEndsService(): WhenScrollEndsService {
  //  return this.whenScrollEndsService;
  // }
  // ------------------------------------------------------------------
}
