
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { ModuleComponentLoader } from '../../models/common/moduleComponentLoader.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { PagerMemberActivity } from '../../models/pagination/pagerMemberActivity.model';
import { PagerPhotoMgmt } from '../../models/pagination/pagerPhotoMgmt.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { RedirectionService } from '../../services/commonServiceService/redirectionService.service';
import { DisplayProfileService } from '../../services/displayProfileServiceService/displayProfileService.service';
import { AbstractPagerVPhotoNavService } from '../../services/pagerServiceService/abstractPagerVPhotoNavService.service';
import { GlyphiconMenuService } from '../../services/GlyphiconService/glypgiconMenuService.service';
import { PagerPhotoMgmtService } from '../../services/pagerServiceService/pagerPhotoMgmtService.service';
import { PagerSupportService } from '../../services/pagerServiceService/pagerSupportService.service';
import { PhotoPrivacyService } from '../../services/photoServiceService/photoPrivacyService.service';
import { BackgroundImageAnimationService } from '../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../services/rendererServiceService/rendererServiceService.service';
import { EmitterSubjectService } from '../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../../services/staticServices/jsRegExpServiceStatic.service';


// ------------------------------------------------------------------
//  This pager-service is the most recently updated one. If other
//  pagers need to be updated, this one should be used as a model for
//  them. Updated on 20220623.
// ------------------------------------------------------------------

// ref: http://xahlee.info/js/javascript_range_array.html
// const xah_range = ((min, max, step = 1,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)));

@Injectable({
  providedIn: 'any'
})
export class PagerVPhotoNavService extends AbstractPagerVPhotoNavService implements OnDestroy {
  // public override renderer = Renderer2;
  // ----------------------------------------------------------------
  public currentKvPhoto : KvPhoto = new KvPhoto(); 
  public isKvData = false;
  public isKvFile = false;
  public isKvNext = false;
  public isKvPrev = false;
  public isKvUrlData = false;
  public isMobilevar = false;
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isStripUrl = true;
  public isViewMember = false;
  public isWithoutUrl = false; 
  public kvPhotoArr : KvPhoto[] = [];
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public message = '';
  public pageSize = 6;
  public pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public pagerVMemberActivityForPhotoMgmt : PagerMemberActivity = new PagerMemberActivity(); // for primary/private/public photo 
  public pagerVMemberActivityForViewMember : PagerMemberActivity = new PagerMemberActivity();
  public profileTile : ProfileTile = new ProfileTile();
  public profilePics : ProfilePics = new ProfilePics();
  public signedInUser : SitUser = new SitUser();
  public sitUser : SitUser = new SitUser();
  
  // ---------------------------------------------------------------
  constructor (
    public bias : BackgroundImageAnimationService,
    public displayProfileService : DisplayProfileService,
    public glyphiconMenuService : GlyphiconMenuService,
    public pagerPhotoMgmtService : PagerPhotoMgmtService,
    public pagerSupportService : PagerSupportService,
    public photoPrivacyService : PhotoPrivacyService,
    public redirectionService : RedirectionService,
    public rendererServiceService : RendererServiceService,
    public rendererService : RendererService,
    public router : Router,
    @Inject(DOCUMENT) document : any,
    @Inject(Window) window : any
  ) {
    super(
      bias,
      glyphiconMenuService,
      pagerPhotoMgmtService,
      pagerSupportService,
      photoPrivacyService,
      redirectionService,
      rendererServiceService,
      rendererService,
      router,
      document,
      window
    );
  }
  // ---------------------------------------------------------------
  //ngOnDestroy () {
  //  // prevent memory leak when component destroyed
  //  //this.emitterDestroyed$.next(true);
  //  //this.emitterDestroyed$.complete();
  //}
  // ===============================================================

  
  public clearPagerVKvPhotoNavService (): void {
    this.kvPhotoArr = [];
    this.pagerVKvPhotoNav = new PagerPhotoMgmt();
    // debugger;
  }
  // ----------------------------------------------------------------
  public executeOnCurrentPictureSelection (currentKvP:KvPhoto) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvP)) {

		}
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getKvPhotoUrl (kvPhoto : KvPhoto) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
      // debugger;
      return 'url(\'' + kvPhoto.value + '\')';
    }
  }
  // --------------------------------------------------------------------
  public getKvPhotoWithoutUrl (kvPhoto : KvPhoto) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
      // debugger;
      kvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(kvPhoto.value);
      return kvPhoto.value;
    }
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public setPagerVerticalPhotoNav (value : PagerPhotoMgmt) : void {
    this.pagerVKvPhotoNav = value;
  }
  // --------------------------------------------------------------------
  // API:
  // ------------------------------------------------------------
  public isInPagedItems (kvPhoto : KvPhoto, pagerVKvPhotoNav: PagerPhotoMgmt) : boolean {
    let isFound = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)
      && pagerVKvPhotoNav.pagedItems.length > 0) {
      for (let i = 0; i < pagerVKvPhotoNav.pagedItems.length; i++) {
        if (pagerVKvPhotoNav.pagedItems[ i ].photoBnId === kvPhoto.photoBnId) {
          isFound = true;
          break;
        }
      }
    }
    // debugger;
    return isFound;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvFileWithUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    this.isKvFile = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') !== -1
      && (kvPhoto.value.toString().toLowerCase().indexOf('.png') !== -1 || kvPhoto.value.toString().toLowerCase().indexOf('.jpg') !== -1);
    return this.isKvFile;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvFileWithoutUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    this.isKvFile = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') === -1
      && (kvPhoto.value.toString().toLowerCase().indexOf('.png') !== -1 || kvPhoto.value.toString().toLowerCase().indexOf('.jpg') !== -1);
    return this.isKvFile;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvDataWithUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    this.isKvUrlData = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') !== -1;
    //&& kvPhoto.value.toString().toLowerCase().indexOf('.png') === -1
    //&& kvPhoto.value.toString().toLowerCase().indexOf('.jpg') === -1;
    return this.isKvUrlData;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvDataWithoutUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    this.isKvData = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('.png') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('.jpg') === -1;
    return this.isKvData;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public isNextOrLastPage (pagerVKvPhotoNav : PagerPhotoMgmt) : boolean {
    this.isKvNext = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav?.endPage)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav?.nextPage)
      && pagerVKvPhotoNav.currentPage >= pagerVKvPhotoNav?.startPage
      && (pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav?.nextPage
        || pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav?.endPage)
    return this.isKvNext;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public isPreviousOrFirstPage (pagerVKvPhotoNav : PagerPhotoMgmt) : boolean {
    this.isKvPrev = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.startPage)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.previousPage)
      && (pagerVKvPhotoNav.currentPage >= pagerVKvPhotoNav.startPage
        || pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav.previousPage)
    return this.isKvPrev;
  }
  
  // --------------------------------------------------------------------
  public getPagerVKvPhotoNavOnInit (kvPhotoArr: KvPhoto[], pageSize: number) : PagerPhotoMgmt {
    // debugger;
    let currentPageNum : number = 0;
    let currentItemKvPhoto : KvPhoto = new KvPhoto();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
       debugger;

      // mask the private photos if not unlocked:
      for (var i = 0; i < kvPhotoArr.length; i++) {
        if (kvPhotoArr[ i ].isPrivate && !kvPhotoArr[i].isUnlocked) {
          kvPhotoArr[ i ].value = 'assets/photos/private.png';
        }
      }
      this.pageSize = EmitterSubjectService.getPageSize();
      currentItemKvPhoto = this.kvPhotoArr[ 0 ];

      // First get the default pager for page#1:
      // ---------------------------------------
      this.pagerVKvPhotoNav = this.getPagerForVKvPhotoNav(this.kvPhotoArr, currentPageNum, pageSize); //get pager

      this.pagerVKvPhotoNav = this.updatePagerVPhotoNav(this.pagerVKvPhotoNav); // Note: may update the KvPhotoArr !!!important

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)
          && (this.pagerVKvPhotoNav.currentItem.photoBnId > 0
            || this.pagerVKvPhotoNav.currentItem.photoId > 0
            || this.pagerVKvPhotoNav.currentItem.sitUserId > 0)) {
          // debugger;
          this.executePagerVKvPhotoNavCurrentKvPhotoTasks(this.pagerVKvPhotoNav.currentItem, kvPhotoArr).subscribe(data => {
            // debugger;
            this.pagerVKvPhotoNav = data;
            EmitterSubjectService.setPagerPhotoMgmt(this.pagerVKvPhotoNav);

            // this should call the following method regarless:
            // ---------------------------------------------------------
            // this.pagerVKvPhotoNav.pagedItems = this.pagerVKvPhotoNavService.prepareKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems, this.isStripUrl);
            // this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavWithoutUrl(this.pagerVKvPhotoNav);
            // debugger;
            return this.pagerVKvPhotoNav;
          });
        }
      }

      EmitterSubjectService.setPagerPhotoMgmt(this.pagerVKvPhotoNav);
    }
    return this.pagerVKvPhotoNav;
  }

  // --------------------------------------------------------------------
  setMcLoader (sitUserId?: number) {
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isMyProfile = EmitterSubjectService.getIsMyProfile();
    this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
    this.isViewMember = EmitterSubjectService.getIsViewMember();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    this.mcLoader.moduleName = "memberm";

    if (this.isPhotoMgmt) {
      this.mcLoader.componentName = "myPhotoMgmt";
      this.mcLoader.id = this.loginSuccess.signedInUserId;
    }
    else if (this.isViewMember) {
      this.mcLoader.componentName = "memberViewMgmt";
      this.mcLoader.id = sitUserId > 0 ? sitUserId : this.loginSuccess.signedInUserId;
    }
    else if (this.isMyProfile) {
      this.mcLoader.componentName = "myProfileViewMgmt";
      this.mcLoader.id = this.loginSuccess.signedInUserId;
    }
  }
  // --------------------------------------------------------------------
}
