<ul class="hList" style="list-style:none;opacity:1;z-index:10;">
  <li>
    <div href="#click" class="menu">
      <div class="menu-title grnDrkRedxxxS" style="padding-top:0.2rem;">{{ddHeading}}<span *ngIf="isRequired" class="errSup" style="margin-left:2px;">*</span> :<span class="whiteBluexxxS"><br />{{selectedItem}}</span></div>
      <ul class="menu-dropdown nvyxxxS" style="list-style:none;z-index: 110;" autofocus>
        <li id="ddHading-i" *ngFor="let item of optionArr let i=index" > <div class="nvyxxxS" (click)="selected(ddHeading, item, i)" autofocus>{{item}}</div></li>        
      </ul>
    </div>
  </li>  
</ul>
