// ---------------------------------------------------------------
export class Filter<T> {
  property!: keyof T;
  constructor(public isTruthyPicked: boolean) { }
}
// ---------------------------------------------------------------
export class Sorter<T> {
  property!: Extract<keyof T, string | number | Date>;

  constructor(public isDescending: boolean) { }
}
// --------------------------------------------------------------
