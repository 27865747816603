import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { UpgradeCost } from './upgradeCost.model';

export class Upgrade
{
  public createTime = '';
  public date = '';
  public dateStr = '';
  public expDate = '';
  public expDateStr = '';
  public from : any;
  public fromStr = '';
  public key = '';
  public membership = '';
  public membershipExpMonthName = '';
  public message = '';
  public orderID = '';
  public orderNo = ''; // ticks.orderID
  public promoCode = '';
  public signedInUserKey = '';
  public signedInUserId = 0;
  public sitUserKey = '';
  public subscriptionMonths = '';
  public subtotal = 0;
  public tax = 0;
  public taxId = '71502 9088 RT0001';
  public till : any;
  public tillStr = '';
  public total = 0;
  public upgradeId = 0;
  public upgradeCost : UpgradeCost = new UpgradeCost();
  public paymentOption = this.upgradeCost.selectedOption;
  constructor () { }

  // -----------------------------------------------
  public copy (a : Upgrade) : Upgrade
  {
    var that : Upgrade = new Upgrade();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( a ) )
    {
      that.createTime = a.createTime;
      that.date = a.date;
      that.dateStr = a.dateStr;
      that.expDate = a.expDate;
      that.expDateStr = a.expDateStr;
      that.from = a.from;
      that.fromStr = a.fromStr;
      // that.invoiceNo = a.invoiceNo;
      that.key = a.key;
      that.membership = a.membership;
      that.membershipExpMonthName = a.membershipExpMonthName;
      that.message = a.message;
      that.orderID = a.orderID;
      that.orderNo = a.orderNo;
      that.paymentOption = a.paymentOption;
      that.promoCode = a.promoCode;
      that.signedInUserId = a.signedInUserId;
      that.signedInUserKey = a.signedInUserKey;
      that.sitUserKey = a.sitUserKey;
      that.subscriptionMonths = a.subscriptionMonths;
      that.subtotal = a.subtotal;
      that.tax = a.tax;
      that.taxId = a.taxId;
      that.till = a.till;
      that.tillStr = a.tillStr;
      that.total = a.total;
      that.upgradeId = a.upgradeId;
      that.upgradeCost.copy( a.upgradeCost );
    }
    return that;
  }
  // -----------------------------------------------
  // TODO: 
  public copyIfExists (a : Upgrade) : Upgrade
  {
    var d : Upgrade = new Upgrade();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( a ) )
    {
      d.createTime = a.createTime && a.createTime.length > 0 ? a.createTime : d.createTime;
      d.date = a.date && a.date.length > 0 ? a.date : d.date;
      d.dateStr = a.dateStr && a.dateStr.length > 0 ? a.dateStr : d.dateStr;
      d.expDate = a.expDate && a.expDate.length > 0 ? a.expDate : d.expDate;
      d.expDateStr = a.expDateStr && a.expDateStr.length > 0 ? a.expDateStr : d.expDate;
      d.from = a.from && a.from.length > 0 ? a.from : d.from;
      d.fromStr = a.fromStr && a.fromStr.length > 0 ? a.fromStr : d.fromStr; //
      // this.invoiceNo = a.invoiceNo && a.invoiceNo.length > 0 ? a.invoiceNo : this.invoiceNo;
      d.key = a.key && a.key.length > 0 ? a.key : d.key;
      d.membership = a.membership && a.membership.length > 0 ? a.membership : d.membership;
      d.membershipExpMonthName = a.membershipExpMonthName && a.membershipExpMonthName.length > 0 ? a.membershipExpMonthName : d.membershipExpMonthName;
      d.message = a.message && a.message.length > 0 ? a.message : d.message;
      d.orderID = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.orderID) ? a.orderID : d.orderID;
      d.orderNo = a.orderNo && a.orderNo.length > 0 ? a.orderNo : d.orderNo;
      d.paymentOption = a.paymentOption && a.paymentOption.length > 0 ? a.paymentOption : d.paymentOption;
      d.promoCode = a.promoCode && a.promoCode.length > 0 ? a.message : d.message;
      d.signedInUserId = a.signedInUserId > 0 ? a.signedInUserId : d.signedInUserId;
      d.signedInUserKey = a.signedInUserKey && a.signedInUserKey.length > 0 ? a.signedInUserKey : d.signedInUserKey;
      d.sitUserKey = a.sitUserKey && a.sitUserKey.length > 0 ? a.sitUserKey : d.sitUserKey;
      d.subscriptionMonths = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.subscriptionMonths) ? a.subscriptionMonths : d.subscriptionMonths;
      d.subtotal = a.subtotal > 0 ? a.subtotal : d.subtotal;
      d.tax = a.tax > 0 ? a.tax : d.tax;
      d.taxId = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.taxId) ? a.taxId : d.taxId;
      d.till = a?.till?.length > 0 ? a.till : d.till;
      d.tillStr = a.tillStr && a.tillStr.length > 0 ? a.tillStr : d.tillStr;
      d.total = a.total > 0 ? a.total : d.total;
      d.upgradeId = a.upgradeId > 0 ? a.upgradeId : d.upgradeId;
      d.upgradeCost.copyIfExists(a.upgradeCost);
    }
    return d;
  }
  // -----------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n createTime: ' +
      this.createTime +
      ',\n' +
      'dateStr: ' +
      this.dateStr +
      ',\n' +
      'date:' +
      this.date +
      ',\n expDate: ' +
      this.expDate +
      ',\n' +
      'expDateStr: ' +
      this.expDateStr +
      ',\n' +
      'from: ' +
      this.from +
      ',\n' +
      'fromStr: ' +
      this.fromStr +
      
      ',\n' +
      'key: ' +
      this.key +
      'membership: ' +
      this.membership +
      ',\n' +
      'membershipExpMonthName: ' +
      this.membershipExpMonthName +      
      ',\n' +
      'membershipMonths: ' +
      this.subscriptionMonths +
      ',\n' +
      'message: ' +
      this.message +
      ',\n' +
      'orderID: ' +
      this.orderID +
      ',\n' +
      'orderNo: ' +
      this.orderNo +
      ',\n' +
      'paymentOption: ' +
      this.paymentOption +
      ',\n' +
      'promoCode: ' +
      this.promoCode +
      ',\n' +
      'signedInUserId: ' +
      this.signedInUserId +
      ',\n' +
      'signedInUserKey: ' +
      this.signedInUserKey +
      ',\n' +
      'subtotal: ' +
      this.subtotal +
      ',\n' +
      'tax: ' +
      this.tax +
       ',\n' +
       'taxId: ' +
       this.taxId +
      ',\n' +
      'till: ' +
      this.till +
      ',\n' +
      'ftillStr: ' +
      this.tillStr +
      ',\n' +
      'total: ' +
      this.total +
      ',\n' +
      'upgradeId: ' +
      this.upgradeId +
      ',\n' +
      'upgradeCost: ' +
      JSON.stringify(this.upgradeCost) +
      '\n}';
    return outString;
  }
  // -----------------------------------------------
}
