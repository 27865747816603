'use-strict'
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit,
    Renderer2
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Chat } from '../../../models/chat/chat.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { PagerChat } from '../../../models/pagination/pagerChat.model';
import { ChatService } from '../../../services/communicationServiceService/chatService';
import { CommunicationServiceService } from '../../../services/communicationServiceService/communicationServiceService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { PagerServiceChatHistory } from '../../../services/pagerServiceService/pagerServiceChatHistory.service';
import { PagerServiceService } from '../../../services/pagerServiceService/pagerServiceService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Component({
  selector : 'app-pager-chat-history',
  templateUrl : './pagerChatHistory.component.html',
  styleUrls : ['./pagerChatHistory.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush
})
export class PagerChatHistoryComponent implements OnInit, /*OnChanges,*/ AfterViewInit, OnDestroy {
  public bias! : BackgroundImageAnimationService;
  public chatService!: ChatService;
  public pagerServiceChatHistory!: PagerServiceChatHistory;
  public rendererService!: RendererService;
  public renderer!: Renderer2;
  private emitterDestroyed$: Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  @Input() chats : Array<Chat> = []; // to detect changes on incoming chats; not used for anyting else
  public currentMod = 0;
  public currentPageNo = 1;
  public changeDetectStr = 'pagerChat';
  public isActive = false;
  public isMobilevar = false;
  public isMouseIn = false;
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public modNum = 0;
  public modPages : number = 0;
  public pageSize = 10; // Max-page-size. important!
  @Input() pagerChat : PagerChat = new PagerChat();
  public pagedChats = [];
  public pageCount = 0;
  @Input() sitUserId = 0;
  public tempElem : any;
  public totalPageNoArr : number[] = [];
  constructor(
    private cdr: ChangeDetectorRef,
    private communicationServiceService: CommunicationServiceService,
    private coreServiceService: CoreServiceService,
    private pagerServiceService : PagerServiceService,
    private rendererServiceService : RendererServiceService,
    @Inject(Document) document : any) {
    if ( this.rendererServiceService ) { this.rendererService = this.rendererServiceService.getRendererService() as RendererService; }
    if (this.communicationServiceService) { this.chatService = this.communicationServiceService.getChatService() as ChatService; }

    if (this.rendererService) {
      this.renderer = this.rendererService.getRenderer() as Renderer2;
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
    }
    this.isMobilevar = EmitterSubjectService.getIsMobile() as boolean;
    if (this.pagerServiceService) {
      this.pagerServiceChatHistory = this.pagerServiceService.getPagerServiceChatHistory() as PagerServiceChatHistory;
    }
    this.isMobilevar = EmitterSubjectService.getIsMobile();
  }

  // ------------------------------------------------------------------
  ngOnInit () {
    // debugger;
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerChat) 
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats) && this.chats.length > 0) {
      //Note: to get the last page, we need to create a pager first with page==1;
      this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, 1, this.pageSize);
      //  Now set the  page to the last page from the pager:
      // this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, this.pagerChat.totalPageNo, this.pageSize);
      this.currentPageNo = this.pagerChat.currentPageNo;
      // debugger;
      this.toggleSelected(this.currentPageNo);
      // debugger;
    }
    // --------------------------------------------------------------
    EmitterSubjectService.conversationEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          // debugger;
          this.chats = result as Chat[];
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats) && this.chats.length > 0) {
            // debugger;
            //  Note: New conversation Principal: We always select the last page of the conversation.
            //  How to do this?: First get a pagerChat using the page-1, then again  using the last page:
            // ------------------------------------------------------------------------------------------
            this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, 1, this.pageSize);
            // this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, this.pagerChat.totalPageNo, this.pageSize);
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerChat)) {
              // debugger;
              this.pagerChat = this.setPage((1).toString());// this.setPage(this.pagerChat.totalPageNo.toString());
              // debugger;
            }
          }
          this.ngAfterViewInit();
          // debugger;
          // Note: indexedDb code to save the chatMessages are in ChatService.ts
        }
      });
  }
 // ----------------------------------------------------------------
  ngAfterViewInit () {
    // debugger;
    this.cdr.detectChanges(); 
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.chats) && this.chats.length > 0) {
      this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, 1, this.pageSize);
      //  Now set the  page to the last page from the pager:
      // this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.chats, this.pagerChat.totalPageNo, this.pageSize);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerChat)) {
        this.currentPageNo = this.pagerChat.currentPageNo;
        // debugger;
        // this.totalPageNoArr = this.pagerChat.totalPageNoArr = this.createTotalPageNoArr(this.pagerChat.totalPageNo);
      }
    }
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    // this.signalRService.clearTimers();
    // this.timerArr.map(e => clearInterval(e));
    // ----------------------------------------
    // Note : for reference-purpose : uniqueAddTimerArr:
    // if (!this.dateStringService.isTimerFound(this.timerArr, this.timer)) { this.timerArr.push(this.timer); }
    // ----------------------------------------
  }
  // --------------------------------------------------------------
  checkMouseEvent(event: Event): boolean {
    if (event) { this.isMouseIn = event.type.indexOf('MouseEvent') !== -1 ? true  : false; }
    return this.isMouseIn = !this.isMouseIn;
  }
  // --------------------------------------------------------------
  createTotalPageNoArr (tPageN : number) : number[] {
    var tPageNArr = [];
    if (tPageN > 0) {
      for (var i = 1; i < tPageN + 1; i++)
      tPageNArr.push(i)
    }
    return tPageNArr;
  }
  // 
  // ----------------------------------------------------------------
  // TODO: incomplete!
  // ----------------------------------------------------------------
  toggleSelected (page : number) : void {
    // debugger;
    var anyElemId = "pageB-";
    var anyElem : any;
    var slectedElemId = "pageB-";
    var selectedElem : any;
    if (page >= 1) {
      switch (page.toString()) {
        case 'f':
          slectedElemId = "pageB-1";
          break;
        case 'prev':
          (page - 1) >= 0 ? slectedElemId = "pageB-" + (page - 1).toString() : slectedElemId = "pageB-1";
          break;
        case '1': case '2': case '3': case '4': case '5': case '6': case '7': case '8': case '9': case '10':
        case '11': case '12': case '13': case '14': case '15': case '16': case '17': case '18': case '19': case '20':
        case '21': case '22': case '23': case '24': case '25': case '26': case '27': case '28': case '29': case '30':
        case '31': case '32': case '33': case '34': case '35': case '36': case '37': case '38': case '39': case '340':
        case '41': case '42': case '43': case '44': case '45': case '46': case '47': case '48': case '49': case '50':
        case '51': case '52': case '53': case '54': case '55': case '56': case '57': case '58': case '59': case '60':
        case '61': case '62': case '63': case '64': case '65': case '66': case '67': case '68': case '69': case '70':
        case '71': case '72': case '73': case '74': case '75': case '76': case '77': case '78': case '79': case '80':
        case '81': case '82': case '83': case '84': case '85': case '86': case '87': case '88': case '89': case '90':
        case '91': case '92': case '93': case '94': case '95': case '96': case '97': case '98': case '99': case '100':
          slectedElemId = "pageB-" + (page - 1).toString();
          // debugger;
          break;        
        case 'next':
          // page <= (this.pagerChat.totalPageNo - 1) ? slectedElemId = "pageB-" + page.toString() : slectedElemId = "pageB-" + (this.pagerChat.totalPageNo - 1);
          page <= (this.pagerChat.totalPageNo - 1) ? slectedElemId = "pageB-" + page.toString() : slectedElemId = "pageB-" + (this.pagerChat.totalPageNo - 1);
          break;
        case 'l':
          slectedElemId = "pageB-" + (this.pagerChat.totalPageNo - 1);
          break;
        default:
          slectedElemId = "pageB-" + (this.pagerChat.totalPageNo - 1);
          break;
      }
      // debugger;
      for (var j = 0; j < this.pagerChat.pages.length; j++) {
        var index = j;
        anyElemId = "pageB-" + index.toString();
        anyElem = document.getElementById(anyElemId);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(anyElem)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(anyElem.classList)
          && anyElem.classList.contains("bttn-xxxs-transparent-selected")) {
          this.bias.removeClass(anyElemId, "bttn-xxxs-transparent-selected");
        }
      }

      selectedElem = document.getElementById(slectedElemId);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(selectedElem)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(selectedElem.classList)
        && !selectedElem.classList.contains("bttn-xxxs-transparent-selected")) {
        this.bias.addClass(slectedElemId, "bttn-xxxs-transparent-selected");
      }
      // debugger;
    }
	}
  // ----------------------------------------------------------------
  public setPage(page?: string): PagerChat {
     // debugger;
    
    // Note: The following is created from CodeMagnet-pager:
    // -----------------------------------------------------
    // add seleced status to class:
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(page)) {
     var pageNum = parseInt(page, 10);
      switch (page.toLowerCase()) {
        case 'f':
          this.currentPageNo = 1;
          break;
        case 'prev':
          this.currentPageNo = (this.pagerChat.currentPageNo - 1) > 0 ? this.pagerChat.currentPageNo - 1 : 1;
          break;
        case '1': case '2': case '3': case '4': case '5': case '6': case '7': case '8': case '9': case '10':
        case '11': case '12': case '13': case '14': case '15': case '16': case '17': case '18': case '19': case '20':
        case '21': case '22': case '23': case '24': case '25': case '26': case '27': case '28': case '29': case '30':
        case '31': case '32': case '33': case '34': case '35': case '36': case '37': case '38': case '39': case '340':
        case '41': case '42': case '43': case '44': case '45': case '46': case '47': case '48': case '49': case '50':
        case '51': case '52': case '53': case '54': case '55': case '56': case '57': case '58': case '59': case '60':
        case '61': case '62': case '63': case '64': case '65': case '66': case '67': case '68': case '69': case '70':
        case '71': case '72': case '73': case '74': case '75': case '76': case '77': case '78': case '79': case '80':
        case '81': case '82': case '83': case '84': case '85': case '86': case '87': case '88': case '89': case '90':
        case '91': case '92': case '93': case '94': case '95': case '96': case '97': case '98': case '99': case '100':
          this.currentPageNo = parseInt(page, 10);
          // debugger;
          break;
        case 'l':
          this.currentPageNo = this.pagerChat.totalPageNo;
          break;
        case 'next':
          this.currentPageNo = this.pagerChat.currentPageNo + 1 <= this.pagerChat.totalPageNo ? this.pagerChat.currentPageNo + 1 : this.pagerChat.totalPageNo;
          break;
        default:
          // this.currentPageNo;
          break;
			}
    }
    // debugger;
    this.pagerChat = this.pagerServiceChatHistory.setPagerChatDynamic(this.pagerChat.chats, this.currentPageNo, this.pageSize);
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerChat)) {
      EmitterSubjectService.emitPagerChat(this.pagerChat);
    }
    // debugger;
    this.toggleSelected(this.currentPageNo);

    // debugger;
    this.ngAfterViewInit();
    return this.pagerChat;
  }
  // ---------------------------------------------------------------
}
