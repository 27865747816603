import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'any'})
export class EncodingService {
  constructor() { }
  // ---------------------------------------------------------------
  encode (input : any) {
    if (input) {
      let output = input.replace(/\</g, '%&lt');

      return (output = output.replace(/\>/g, '%&gt'));
    }
  }
  // ---------------------------------------------------------------
  decode (input : any) {
    if (input) {
      let output = this.decodeCustomTags(input); // will transform all '&lt%' into '<'

      if (output.indexOf('&ltpre') !== -1 || output.indexOf('&lt/pre') !== -1 || output.indexOf('<pre') !== -1 || output.indexOf('</pre') !== -1) {
        if (output) {
          output = output.replace(/%&lt/g, '&lt');
        }
        if (output) {
          output = output.replace(/%</g, '&lt');
        }
        if (output) {
          output = output.replace(/%&gt/g, '&gt');
        }
        if (output) {
          output = output.replace(/%>/g, '&gt');
        }
        return output;
      } else if (input.indexOf('&ltpre') === -1 && input.indexOf('&lt-pre') === -1 && input.indexOf('<pre') === -1 && input.indexOf('<-pre') === -1) {
        if (output) {
          output = output.replace(/%&lt/g, '<');
        }
        if (output) {
          output = output.replace(/%</g, '<');
        }
        return (output = output.replace(/%&gt/g, '>'));
      }
    }
  }

  // ---------------------------------------------------------------
  decodeCustomTags (input : any) {
    if (input) {
      let output = this.decodeCustomParagraphTags(input);

      if (output) {
        output = this.decodeCustomPreTags(output);
      }
      return output;
    }
  }
  // ---------------------------------------------------------------
  decodeCustomLtTags (input : any) {
    if (input) {
      return input.replace(/&lt%/g, '<');
    }
  }
  // ---------------------------------------------------------------
  decodeCustomParagraphTags (input : any) {
    if (input) {
      const output = input.replace(/&lt%p%&gt/g, '<p>');

      return output.replace(/&lt%\/p%&gt/g, '</p>');
    }
  }
  // ---------------------------------------------------------------
  decodeCustomPreTags (input : any) {
    if (input) {
      const output = input.replace(/&lt%pre%&gt/g, '<pre>');

      return output.replace(/&lt%\/pre%&gt/g, '</pre>');
    }
  }
  // ---------------------------------------------------------------
  trimCustomParagraphTags (input : any) {
    if (input) {
      let output = this.decodeCustomLtTags(input); // will transform all '&lt%' into '<'

      if (output) {
        output = output.replace(/&lt%p%&gt/g, '');
      }
      return (output = output.replace(/&lt%\/p%&gt/g, ''));
    }
  }
  // ---------------------------------------------------------------
  trimCustomPreTags (input : any) {
    if (input) {
      let output = this.decodeCustomLtTags(input); // will transform all '&lt%' into '<'

      if (output) {
        output = output.replace(/&lt%pre%&gt/g, '');
      }
      return (output = output.replace(/&lt%\/pre%&gt/g, ''));
    }
  }
  // ---------------------------------------------------------------
  trimParagraphTags (input : any) {
    if (input) {
      let output = this.decodeCustomLtTags(input); // will transform all '&lt%' into '<'

      if (output) {
        output = output.replace(/<p>/g, '');
      }
      return (output = output.replace(/<\/p>/g, ''));
    }
  }
  // ---------------------------------------------------------------
  trimPreTags (input : any) {
    if (input) {
      let output = this.decodeCustomLtTags(input); // will transform all '&lt%' into '<'

      if (output) {
        output = output.replace(/<pre>/g, '');
      }
      return (output = output.replace(/<\/pre>/g, ''));
    }
  }
  // ---------------------------------------------------------------
}
