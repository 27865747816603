<!--Reactve Form Validation: Ref:https:// www.codingame.com/playgrounds/8012/angular-form-validation-example-tutorial-->
<div id="preferenceComponentId" [@rotateInSlideOutVanish] [ngClass]="{'login row':true}" style="width:95%;height:auto;min-height:100rem;margin:0.5rem 0.5rem 0.5rem 4.75%;padding:0.15rem;opacity:1;border-radius:7px;overflow:visible;">
  <div style="width:100%;height:16rem;background-image:url('/assets/photos/leatherAssembly-2.png');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="leatherAssembly image"></div>
  <div class="col-sm-12" style="margin-top:2rem;">
    <a routerLink="/index" class="col-sm-12" style="float:right;text-align:right;margin-top:-2rem;">
      <span class="glyphicon glyphicon-remove flex-button-primary increase" style="font-size:large;padding:0.5rem 0.25rem 0 0;" aria-hidden="true"></span>
    </a>
    <div>
      <div *ngIf="preference && preference.message && preference.message.length > 0" class="flex-container">
        <div class="flex-text" [ngClass]="{'errS':!preference.isSuccess, 'grnS':preference.isSuccess}">
          <span class="glyphicon glyphicon-alert align-center" aria-hidden="true"></span>
          <span>{{preference.message}}</span>
        </div>
      </div>

      <div class="flex-container" style="margin-top:0rem;">
        <div class="flex-text nvyxSB"><span style="color:darkred;margin-top:0;">Creating/Editing Profile: Step 3 of 4</span></div>
      </div>

      <div class="flex-container" style="margin-top:0rem;">
        <div class="flex-text grySB"> Required (You may choose multiple): <span class="err" style="margin:0.2rem;">* </span></div>
      </div>
      <form #preferenceForm="ngForm" style="margin-top:0.15rem;" novalidate>
        <!--====================================================================-->
        <!--LookingFor-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text nvyB"><span>What are you looking for?:<span class="errSub">*</span></span></div>
          </div>
          <!--<div class="flex-text" style="min-height:6rem;width:35%;background-image:url('/assets/photos/leatherCouple-1.png');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="puppies"></div>-->
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div style="padding:0.15rem 0 0.15rem 0;margin:0;">
              <app-check-button [checkboxArr]="lookingForArr" [selectedChkBoxArr]="selectedLookingForsArr" [minRequired]="1" [checkboxName]='checkboxNames[0]' [isFormSubmitted]="isFormSubmitted" class="flex-text-left" style="padding:0.5rem 0 0.5rem 0;"></app-check-button>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <!--====================================================================-->
        <!--RelationshipPreferences-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text nvyB"><span>My preferences for relationship?:<span class="errSub">*</span></span></div>
          </div>
          <!--<div class="flex-text" style="min-height:6rem;width:35%;background-image:url('/assets/photos/leatherCouple-1.png');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="puppies"></div>-->
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="twoColumn" style="padding:0.15rem 0 0.15rem 0;margin:0;">
              <app-check-button [checkboxArr]="relationshipPreferenceArr" [selectedChkBoxArr]="selectedRelationshipPreferencesArr" [minRequired]="1" [checkboxName]='checkboxNames[1]' [isFormSubmitted]="isFormSubmitted" class="flex-text-left" style="padding:0.5rem 0 0.5rem 0;"></app-check-button>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <!--====================================================================-->
        <!--MeetingLocation-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container increase" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text nvyB"><span>"Where would you like to meet up?:<span class="errSub">*</span></span></div>
          </div>
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text" style="min-height:10rem;width:50%;margin:0;background-image:url('/assets/photos/meeting-ladies-cartoon-1.png');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="cafe"></div>
            <div class="flex-text-left increase" style="width:45%;padding:0.15rem 0 0.15rem 0;">
              <app-check-button [checkboxArr]="meetingLocationArr" [selectedChkBoxArr]="selectedMeetingLocationsArr" [minRequired]=1 [checkboxName]='checkboxNames[2]' [isFormSubmitted]="isFormSubmitted"></app-check-button>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <!--====================================================================-->
        <!--WhenLookingToMeet-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container increase" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text nvyB"><span>When are you looking to meetup up?:<span class="errSub">*</span></span></div>
          </div>
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text" style="min-height: 10rem;
                                          width: 50%;
                                          margin: 0;
                                          background-image: url('/assets/photos/timeOfDay-cartoon-1.png');
                                          background-repeat: no-repeat;
                                          background-size: cover;
                                          border-radius: 5px;
                                          background-position: 50% 50%;" alt="clock">
            </div>
            <div class="flex-text-left increase" style="width:45%;padding:0.15rem 0 0.15rem 0;">
              <app-check-button [checkboxArr]="whenLookingToMeetArr" [selectedChkBoxArr]="selectedWhenLookingToMeetsArr" [minRequired]="1" [checkboxName]='checkboxNames[3]' [isFormSubmitted]="isFormSubmitted"></app-check-button>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <!--====================================================================-->
        <div class="flex-container" style="margin:0.5rem 0 0.5rem 0;">
          <div class="flex-text grySB" style="color:darkred;">Explore something about yourself:</div>
        </div>
        <!--====================================================================-->
        <!--Hobbies-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container" style="margin: 0.15rem 0 0.15rem 0;">
            <div class="flex-text nvyB"><span style="margin-top:0;">Hobbies:<span class="errSub">*</span></span></div>
          </div>
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="twoColumn flex-text-left" style="padding:0.15rem 0 0.15rem 0;margin:0;">
              <app-check-button [checkboxArr]="hobbyArr" [selectedChkBoxArr]="selectedHobbiesArr" [minRequired]="1" [checkboxName]='checkboxNames[4]' [isFormSubmitted]="isFormSubmitted"></app-check-button>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <!--====================================================================-->
        <!--Pet-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container increase" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text nvyB"><span style="margin-top:0;">What about the pet?:<span class="errSub">*</span></span></div>
          </div>
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text" style="min-height:6rem;width:50%;margin:0;background-image:url('/assets/photos/pupy-4.png');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="puppies"></div>
            <div class="flex-text-left increase" style="width: 50%; padding: 0.15rem 0 0.15rem 0;">
              <app-check-button [checkboxArr]="petArr" [selectedChkBoxArr]="selectedPetsArr" [minRequired]="1" [checkboxName]='checkboxNames[5]' [isFormSubmitted]="isFormSubmitted"></app-check-button>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <!--====================================================================-->
        <!--PetPeev-->
        <div style="margin:1rem 0 1rem 0;">
          <div class="flex-container increase" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text nvyB"><span style="margin-top:0;">What are your pet peevs?:<span class="errSub">*</span></span></div>
          </div>
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text" style="min-height:6rem;width:50%;margin:0;background-image:url('/assets/photos/petPeeves-cartoon-1.png');background-repeat:no-repeat;background-size:cover;border-radius:5px;background-position: 50% 50%;" alt="puppies"></div>
            <div class="flex-text-left increase" style="width:50%;padding:0.15rem 0 0.15rem 0;">
              <app-check-button [checkboxArr]="petPeevArr" [selectedChkBoxArr]="selectedPetPeevsArr" [minRequired]="1" [checkboxName]='checkboxNames[6]' [isFormSubmitted]="isFormSubmitted"></app-check-button>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <!--====================================================================-->
        <!--Intos-->
        <div (mouseout)="onMouseOut($event)" style="margin:1rem 0 1rem 0;">
          <div class="flex-container increase" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text nvyB"><span style="margin-top: 0;">I am into:<span class="errSub">*</span></span></div>
          </div>
          <div class="flex-container" style="margin:0.15rem 0 0.15rem 0;">
            <div class="flex-text"
                 style="min-height: 6rem;
                        width: 50%;margin:0;
                        background-image: url('/assets/photos/leatherCouple-1.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        border-radius: 5px;
                        background-position: 50% 50%;" alt="puppies">
            </div>
            <div class="flex-text-left increase" style="width:45%;padding:0.15rem 0 0.15rem 0;margin:0;">
              <app-check-button [checkboxArr]="intoArr" [selectedChkBoxArr]="selectedIntosArr" [minRequired]="1" [checkboxName]='checkboxNames[7]' [isFormSubmitted]="isFormSubmitted"></app-check-button>
            </div>
          </div>
        </div>
        <hr class="hr" />
        <!--====================================================================-->
        <!--SubmitButton-->
        <div *ngIf="isSubmitBttnFocus" class="flex-container" style="margin:0.5rem 0.25rem 0.5rem 0.25rem;">
          <div class="col-md-12 flex-text" style="font-size:12px;color:orangered">
            Please enter all the required (*) fileds to enable the SUBMIT button. This Form is incomplete, therefore, the SUBMIT button is disabled! Please complete the form to enable the SUBMIT button.
          </div>
        </div>
        <div class="flex-container" style="margin:0.25rem 0 0 0;">
          <div class="flex-text">
            <button *ngIf="!isFormValid" 
                    (mouseout)="onSubmitBttnMouseOut($event)" 
                    (click)="showInvalidFormMessage($event);" 
                    type="button" 
                    [ngClass]="{'bttn-md-primary':!isMobilevar, 'bttn-lg-primary mBttnFontWeight':isMobilevar, 'flex-button':true}" 
                    style="margin: 5px; padding: 5px 10px 5px 10px;">
                Submit
            </button>

            <button *ngIf="isFormValid"
                    (mouseout)="onSubmitBttnMouseOut($event)" type="button"
                    (click)="submitPreferenceForm();"
                    [disabled]="click || !isFormValid"
                    [ngClass]="{'bttn-md-primary':!isMobilevar, 'bttn-lg-primary mBttnFontWeight':isMobilevar, 'flex-button':true}"
                    [ngStyle]="{'cursor': isFormValid ? 'pointer' : 'not-allowed'}"
                    style="margin: 5px; padding: 5px 10px 5px 10px;">
              <span [ngClass]="{'glyphicon glyphicon-ban-circle errB': !isFormValid}" aria-hidden="true" style="font-size:20px;vertical-align:central"></span>
              Submit
            </button>
            <button type="button" (click)="cancel();" [ngClass]="{'bttn-md-invDefault':!isMobilevar, 'bttn-lg-invDefault mBttnFontWeight':isMobilevar, 'flex-button':true}" style="margin: 5px;
    padding: 5px 10px 5px 10px;
">
              Cancel
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<!--(mouseover)="onMouseOver($event)" (mouseout)="onMouseOut($event) !isFormValid && isFormSubmitted ||-->
