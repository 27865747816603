
import { Communicator } from "../communication/communicator.model";
import { ChatActivity } from "./chatActivity.model";
import { ChatMessage } from "./chatMessage.model";

export class ChatConversationModel {
  public chatConversationModelId = 0;
  public date = '';
  public receiver : Communicator = new Communicator();
  public sender : Communicator = new Communicator();
  public sitUserId = 0;
  public sitUserKey = '';
  public chatActivities :  ChatActivity[] = [];
  public chatMessages : ChatMessage[] = [];
}