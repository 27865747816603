
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { AbstractPagerVPhotoNavServiceInterface } from '../../interfaces/serviceInterfaces/pagerServicesInterfaces/abstractPagerVPhotoNavServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { ModuleComponentLoader } from '../../models/common/moduleComponentLoader.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { PagerMemberActivity } from '../../models/pagination/pagerMemberActivity.model';
import { PagerPhotoMgmt } from '../../models/pagination/pagerPhotoMgmt.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { RedirectionService } from '../../services/commonServiceService/redirectionService.service';
import { PagerPhotoMgmtService } from '../../services/pagerServiceService/pagerPhotoMgmtService.service';
import { PagerSupportService } from '../../services/pagerServiceService/pagerSupportService.service';
import { PhotoPrivacyService } from '../../services/photoServiceService/photoPrivacyService.service';
import { BackgroundImageAnimationService } from '../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../services/rendererServiceService/rendererServiceService.service';
import { ArrayServiceStatic } from '../../services/staticServices/arrayServiceStatic.service';
import { EmitterSubjectService } from '../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../../services/staticServices/jsRegExpServiceStatic.service';
import { GlyphiconMenuService } from '../GlyphiconService/glypgiconMenuService.service';


// ------------------------------------------------------------------
//  This pager-service is the most recently updated one. If other
//  pagers need to be updated, this one should be used as a model for
//  them. Updated on 20220623.
// ------------------------------------------------------------------

// ref: http://xahlee.info/js/javascript_range_array.html
// const xah_range = ((min, max, step = 1,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)));

@Injectable({
  providedIn: 'any'
})
export class AbstractPagerVPhotoNavService implements OnDestroy, AbstractPagerVPhotoNavServiceInterface {
  // public override renderer = Renderer2;
  // ----------------------------------------------------------------
  public currentKvPhoto : KvPhoto = new KvPhoto();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isViewMember = false;
  public isStripUrl = true;
  public kvPhotoArr : KvPhoto[] = [];
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public message = '';
  public pageSize = 6;
  public pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public pagerVMemberActivityForPhotoMgmt : PagerMemberActivity = new PagerMemberActivity(); // for primary/private/public photo 
  public pagerVMemberActivityForViewMember : PagerMemberActivity = new PagerMemberActivity();
  public profileTile : ProfileTile = new ProfileTile();
  public profilePics : ProfilePics = new ProfilePics();
  public signedInUser : SitUser = new SitUser();
  public sitUser : SitUser = new SitUser();
  public timer : any;
  // ---------------------------------------------------------------
  constructor (
    public bias : BackgroundImageAnimationService,
    public glyphiconMenuService : GlyphiconMenuService,
    public pagerPhotoMgmtService : PagerPhotoMgmtService,
    public pagerSupportService : PagerSupportService,
    public photoPrivacyService : PhotoPrivacyService,
    public redirectionService: RedirectionService,
    public rendererServiceService : RendererServiceService,
    public rendererService : RendererService,
    public  router : Router,
    @Inject(DOCUMENT) document : any,
    @Inject(Window) window : any
  ) {
  }
  // ---------------------------------------------------------------
  ngOnDestroy () {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ===============================================================

  
  public clearPagerVKvPhotoNavService (): void {
    this.kvPhotoArr = [];
    this.pagerVKvPhotoNav = new PagerPhotoMgmt();
    // debugger;
  }
  // ----------------------------------------------------------------
  determinePrivacyStatusOfKvPhoto (kvp : KvPhoto): KvPhoto {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      switch (kvp.privacy.toLocaleLowerCase()) {
        case 'primary':
          kvp.glyphName = 'primary';
          kvp.key = 'primary';
          kvp.isPrimary = true;
          kvp.isPrivate = false;
          kvp.isPublic = false;
          break;
        case 'public':
          kvp.glyphName = 'public';
          kvp.key = 'public';
          kvp.isPrimary = false;
          kvp.isPrivate = false;
          kvp.isPublic = true;
          break;
        case 'private':
          kvp.glyphName = 'private';
          kvp.key = 'private';
          kvp.isPrimary = false;
          kvp.isPrivate = true;
          kvp.isPublic = false;
          break;
        default:
          kvp.glyphName = 'public';
          kvp.key = 'public';
          kvp.isPrimary = false;
          kvp.isPrivate = false;
          kvp.isPublic = true;
          break;
      }
    }
    return kvp;
  }
  // ----------------------------------------------------------------
  public executeOnCurrentPictureSelection (currentKvP:KvPhoto) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvP)) {

		}
  }
  // ----------------------------------------------------------------
  public executeOnPictureSelectionChange (currentKvp: KvPhoto, kvpArr: KvPhoto[]) : Observable<any> {
    var pageSize = EmitterSubjectService.getPageSize() as number;
    var currentItemKvPhoto = currentKvp;
    var kvPhotoArr = kvpArr;
    var currentPageNum = EmitterSubjectService.getCurrentPage().pageNum;
    return new Observable(subscriber => {

      // get initial pager which willbe used to get the updated pagerVKvPhotoNav:
      this.pagerVKvPhotoNav = this.getPagerForVKvPhotoNav(kvPhotoArr, currentPageNum, pageSize);
      
      // Note:  the following line must be run right after the previous line/method: getPagerForVKvPhotoNav() !!
      this.pagerVKvPhotoNav = this.setupPagerVKvPhotoNav(kvPhotoArr, currentItemKvPhoto);


      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)) {
        // debugger;
        this.executePagerVKvPhotoNavTasks(this.pagerVKvPhotoNav).then(data => {
          this.pagerVKvPhotoNav = data;
          this.pagerVKvPhotoNav.pagedItems = this.prepareKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems);
          subscriber.next(this.pagerVKvPhotoNav);
          subscriber.complete();
        });
      }
      else {
        subscriber.next(null);
        subscriber.complete();
      }
    })
	}
  // ----------------------------------------------------------------
  // API: !!!!!!
  // ----------------------------------------------------------------

  //  Candidate-4: 1-stop-shop
  //  First-Way to display All Vertical menues:
  //
  //  Note: This method will execute the complete PagerVKvPhotoNav
  //        and vertical glyphcon-menu and display them as a one-stop-shop.
  // ----------------------------------------------------------------
  public executePagerVKvPhotoNavTasks (pagerVKvPhotoNav : PagerPhotoMgmt) : Promise<PagerPhotoMgmt | any> {
    return new Promise((resolve, reject) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItem)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)
        && pagerVKvPhotoNav.pagedItems.length > 0) {
        debugger;
        // task#1 display photoNavMenu:
        // ----------------------------
        this.executePagerVKvPhotoNavPagedItems(pagerVKvPhotoNav).then(result => {
          debugger;
          if (result) {
            // debugger;
            pagerVKvPhotoNav = result;
            debugger;
            EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(pagerVKvPhotoNav.currentItem);
          }
          else {
            debugger;
            reject('pagerVKvPhotoNav.pagedItems was empty or null: ' + pagerVKvPhotoNav);
          }
        });

        // ---------------------------------------------------
        // task#2 display glyphNavMenu of the currentKvPhoto:
        // ---------------------------------------------------
        if (pagerVKvPhotoNav.currentItem) {
          debugger;
          this.executePagerVKvPhotoNavCurrentKvPhotoTasks(pagerVKvPhotoNav.currentItem, pagerVKvPhotoNav.pagedItems).subscribe(data => {
            debugger;
            resolve(data);
          })
        }
        else {
          debugger;
          reject('pagerVKvPhotoNav.pagedItems failed to display kvPhotos: ' + pagerVKvPhotoNav);
        }
      }
      else {
        reject('executePagerVKvPhotoNavTasks.pagerVKvPhoto was null or empty.');
      }
    })
  }
  // ----------------------------------------------------------------
  // API: !!!!!!
  // ----------------------------------------------------------------
  //  Note: This methods prepares the Glyphicon menu(s) of a kvPhoto,
  //        and emits PagerVKvPhotoNav to render via appComponent.
  //        It also selects/highlights the appropriate Glyph-menu-item;
  // ----------------------------------------------------------------
  executePagerVKvPhotoNavKvPhotoTasks (pagerVKvPhotoNav : PagerPhotoMgmt) : Promise<PagerPhotoMgmt | any> {
    return new Promise<any>((resolve, reject) => {
      // debugger;
      
      // debugger;
      // kvPhoto's image as background:
      // ------------------------------
      this.bias.updateBackgroundImageOutsideAngular(pagerVKvPhotoNav.currentItem.value);
      this.sitUser = EmitterSubjectService.getSitUserModel();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();

      // Note: disply the glyphMenu for currentKvPhoto:
      // ----------------------------------------------
      if (this.isPhotoMgmt || this.isMyProfile) {
        // debugger;
        // glyphMenu for PhotoMgmt.currentKvPhoto:
        // --------------------------------------- 
        this.pagerVMemberActivityForPhotoMgmt = this.glyphiconMenuService.getPagerVMemActGlyphMenuForPhotoMgmt(pagerVKvPhotoNav.currentItem);
        // debugger;
        pagerVKvPhotoNav.pagerVMemberActivityForPhotoMgmt = this.pagerVMemberActivityForPhotoMgmt;
        // debugger;
        this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(pagerVKvPhotoNav.currentItem); // will display photo primary/private/public glyphs
        pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;
        this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, pagerVKvPhotoNav.currentItem);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForPhotoMgmt)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForPhotoMgmt.pagedItems)
          && this.pagerVMemberActivityForPhotoMgmt.pagedItems.length > 0) {
          // glyphMenu for PhotoMgmt.currentKvPhoto:
          // ---------------------------------------
          // debugger;
          this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemberActivityForPhotoMgmt.pagedKvGlyphItems, this.pagerVMemberActivityForPhotoMgmt.currentKvGlyphItem, "pagerVGlyphMgmt-");
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForPhotoMgmt) && this.kvMemActGlyphArrForPhotoMgmt.length > 0) {
          // glyphMenu for PhotoMgmt.currentKvPhoto:
          // ---------------------------------------
          // debugger;
          this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.kvMemActGlyphArrForPhotoMgmt, this.kvMemActGlyphArrForPhotoMgmt[ 0 ], "pagerVGlyphMgmt-");
        }
      }
      else if (this.isViewMember) {
        this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, pagerVKvPhotoNav.currentItem);
        // debugger;
        if (this.sitUser.sitUserId > 0) {
          // pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(this.sitUser.sitUserId);
          // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.sitUser.sitUserId); 
        }
        this.pagerVMemberActivityForViewMember = this.glyphiconMenuService.getPagerVMemActGlyphMenuForViewMember(pagerVKvPhotoNav.currentItem.pageNum > 0 ? pagerVKvPhotoNav.currentItem.pageNum : 1, this.sitUser);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForViewMember)) {
          pagerVKvPhotoNav.pagerVMemberActivityForViewMember = this.pagerVMemberActivityForViewMember;
          // debugger;
        }
      }
      EmitterSubjectService.setPagerPhotoMgmt(pagerVKvPhotoNav);
      // debugger;
      resolve(pagerVKvPhotoNav);
    })
  }

  // --------------------------------------------------------------------
  executePagerVKvPhotoNavAndBackgroundImageTasks (pagerVKvPhotoNav : PagerPhotoMgmt) : PagerPhotoMgmt {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)) {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.kvPhotoArr) && pagerVKvPhotoNav.kvPhotoArr.length > 0) {
        pagerVKvPhotoNav.kvPhotoArr = this.prepareKvPhotoArrForDisplay(pagerVKvPhotoNav.kvPhotoArr);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.kvPhotoArr) && pagerVKvPhotoNav.kvPhotoArr.length > 0) {
        pagerVKvPhotoNav.kvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr(pagerVKvPhotoNav.kvPhotoArr, 'pagerVKvPhotoNav-');
        // debugger;
        // TODO: see if it is still needed?
        // --------------------------------
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.kvPhotoArr) && pagerVKvPhotoNav.kvPhotoArr.length > 0) {
        //  pagerVKvPhotoNav = this.pagerVKvPhotoNavService.getPagerForVKvPhotoNav(pagerVKvPhotoNav.kvPhotoArr, 1, 6);
        //}

        var i = 0;
        pagerVKvPhotoNav.kvPhotoArr.forEach(e => {
          pagerVKvPhotoNav.pagedItems[ i ] = this.bias.setupKvPhotoIfPrimaryOrPrivate(pagerVKvPhotoNav.pagedItems[ i ]);


          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)
            && pagerVKvPhotoNav.pagedItems.length > 0) {
            this.pagerVKvPhotoNav.pagedItems[ i ] = this.bias.displayBackgroundAndNavImageForKvPhoto(pagerVKvPhotoNav.pagedItems[ i ], pagerVKvPhotoNav);

            this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, pagerVKvPhotoNav.pagedItems[ i ]);
          }
          i++;
        })
      }
      this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvPhoto(pagerVKvPhotoNav.currentItem);
      pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;



      // debugger;
      EmitterSubjectService.emitCurrentPage(pagerVKvPhotoNav.currentItem);


      pagerVKvPhotoNav.currentItem = this.bias.setupKvPhotoIfPrimaryOrPrivate(pagerVKvPhotoNav.currentItem);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)
        && pagerVKvPhotoNav.pagedItems.length > 0) {
        pagerVKvPhotoNav.currentItem = this.bias.displayBackgroundAndNavImageForKvPhoto(pagerVKvPhotoNav.currentItem, pagerVKvPhotoNav);

        this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, pagerVKvPhotoNav.currentItem);
      }
      // debugger;

      // EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt);
      pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(pagerVKvPhotoNav.currentItem); // will display photo primary/private/public glyphs
      this.kvMemActGlyphArrForPhotoMgmt = pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt;
      this.pagerVKvPhotoNav = pagerVKvPhotoNav;
      return pagerVKvPhotoNav;
    }
  }
  // ----------------------------------------------------------------

  //  Candidate-3: 1-stop-shop
  //  1) This method will simply print the pagerVKvPhotoNav.currentItem/currentKvPhoto
  //      and select the currentKvPhoto, and deselct others.
  //  2) This will set the KvPhoto as background-image.
  //  3) This will get the pagerVKvPhotoNav from Emitter and match with currentKvPhoto with pagerVKvPhotoNav.currentItem,
  //     and if they don't match, it will create a new pagerVKvPhotoNav on the basis of CurrentKvPhoto.
  //  4) this will also cover for the primaryImage case for pagerVKvPhotoNav.
  //  5) This will print the currentKvPhoto respective glyphMenu.
  //  6) This will highlight primaryImage if it finds it.
  // ---------------------------------------------------------------------------------
  executePagerVKvPhotoNavCurrentKvPhotoTasks (currentKvPhoto : KvPhoto, kvpArr : KvPhoto[]) : Observable<PagerPhotoMgmt> {
    return new Observable<any>((subscriber) => {
      // debugger;
      let pagerVKvPhotoNav = new PagerPhotoMgmt();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto) && currentKvPhoto.itemNum >= 0 &&
        (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto.value) && currentKvPhoto.value.length > 0)
        || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto.image) && currentKvPhoto.image.length > 0)) {
        // debugger;
        pagerVKvPhotoNav = EmitterSubjectService.getPagerPhotoMgmt();

        //  check to see that the Emitter's pagerPhotoMgmt is same as currentKvPhoto's pagerPhotoMgmt,
        //  otherwise create a new pagerPhotoMgmt for currentKvPhoto:
        //  Note: PhotoId or PhotoBnId or both may be 0, so need to check for currentKvPhoto.image:
        // -------------------------------------------------------------------------------------------
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItem)
          && (pagerVKvPhotoNav.currentItem.photoId !== currentKvPhoto.photoId
            || pagerVKvPhotoNav.currentItem.photoBnId !== currentKvPhoto.photoBnId)
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem.value)
            && this.pagerVKvPhotoNav.currentItem.value.length > 0)) {
          // debugger;
          pagerVKvPhotoNav = this.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(currentKvPhoto, kvpArr);         
        }

        // debugger;
        // kvPhoto's image as background:
        // ------------------------------
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItem)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem.value)
          && this.pagerVKvPhotoNav.currentItem.value.length > 0) {
          this.bias.updateBackgroundImageOutsideAngular(pagerVKvPhotoNav.currentItem.value);
        }
        this.sitUser = EmitterSubjectService.getSitUserModel();
        this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
        this.isViewMember = EmitterSubjectService.getIsViewMember();
        this.isMyProfile = EmitterSubjectService.getIsMyProfile();

        // Note: disply the glyphMenu for currentKvPhoto:
        // ----------------------------------------------
        if (this.isPhotoMgmt || this.isMyProfile) {
          // debugger;
          // glyphMenu for PhotoMgmt.currentKvPhoto:
          // --------------------------------------- 
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItem)) {
            this.pagerVMemberActivityForPhotoMgmt = this.glyphiconMenuService.getPagerVMemActGlyphMenuForPhotoMgmt(pagerVKvPhotoNav.currentItem);
            // debugger;
            pagerVKvPhotoNav.pagerVMemberActivityForPhotoMgmt = this.pagerVMemberActivityForPhotoMgmt;
            // debugger;
            this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(pagerVKvPhotoNav.currentItem); // will display photo primary/private/public glyphs

            pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;
            this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, pagerVKvPhotoNav.currentItem);
          }
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForPhotoMgmt)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForPhotoMgmt.pagedItems)
            && this.pagerVMemberActivityForPhotoMgmt.pagedItems.length > 0) {
            // glyphMenu for PhotoMgmt.currentKvPhoto:
            // ---------------------------------------
            debugger;
            this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemberActivityForPhotoMgmt.pagedKvGlyphItems, this.pagerVMemberActivityForPhotoMgmt.currentKvGlyphItem, "pagerVGlyphMgmt-");
          }
          else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForPhotoMgmt) && this.kvMemActGlyphArrForPhotoMgmt.length > 0) {
            // glyphMenu for PhotoMgmt.currentKvPhoto:
            // ---------------------------------------
            // debugger;
            this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.kvMemActGlyphArrForPhotoMgmt, this.kvMemActGlyphArrForPhotoMgmt[ 0 ], "pagerVGlyphMgmt-");
          }
        }
        else if (this.isViewMember) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItem)) {
            this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, pagerVKvPhotoNav.currentItem);
            // debugger;
            if (this.sitUser.sitUserId > 0) {
              // pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(this.sitUser.sitUserId);
              // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.sitUser.sitUserId); 
            }
            this.pagerVMemberActivityForViewMember = this.glyphiconMenuService.getPagerVMemActGlyphMenuForViewMember(currentKvPhoto.pageNum > 0 ? currentKvPhoto.pageNum : 1, this.sitUser);
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForViewMember)) {
              pagerVKvPhotoNav.pagerVMemberActivityForViewMember = this.pagerVMemberActivityForViewMember;
               // debugger;
            }
            
          }
        }
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)) {
        EmitterSubjectService.setPagerPhotoMgmt(pagerVKvPhotoNav);
        subscriber.next(pagerVKvPhotoNav);
      }
    })
  }
  // ----------------------------------------------------------------
  //  Candidate-2: 1-stop-shop
  //  This method will simply print the pagerVKvPhotoNav.pagedItems
  //  and select the currentKvPhoto, and deselct others:
  //  Will also cover for the primaryImage case for pagerVKvPhotoNav.
  // ----------------------------------------------------------------
  executePagerVKvPhotoNavPagedItems (pagerVKvPhotoNav : PagerPhotoMgmt) : Promise<PagerPhotoMgmt | any> {
    return new Promise<any>((resolve, reject) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.kvPhotoArr)
        && pagerVKvPhotoNav.kvPhotoArr.length > 0) {

        // -------------------------------------------------------------------------------------------
        let tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr(pagerVKvPhotoNav.kvPhotoArr, 'pagerVKvPhotoNav-');
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpKvPhotoArr) && tpKvPhotoArr.length > 0) {
          this.kvPhotoArr = tpKvPhotoArr;
          this.pagerVKvPhotoNav.kvPhotoArr = this.kvPhotoArr;
        }

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvPhotoArr) && this.kvPhotoArr.length > 0) {
          this.setPagerVKvPhotoArr(this.kvPhotoArr);
          EmitterSubjectService.setProfileImages(this.kvPhotoArr);

          // now capture the current-item:
          // -----------------------------
          let index = -1;
          this.currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto)) {
            index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr(pagerVKvPhotoNav.kvPhotoArr, this.currentKvPhoto);
          }
          if (index > -1) { // not found
            // debugger;
            this.currentKvPhoto = new KvPhoto().copySourceToDestination(this.currentKvPhoto, this.kvPhotoArr[ index ]);
          }
          else {
            this.currentKvPhoto = new KvPhoto().copySourceToDestination(this.currentKvPhoto, this.kvPhotoArr[ this.kvPhotoArr.length - 1 ]);
          }

          this.pagerVKvPhotoNav = this.setupPagerVKvPhotoNav(this.kvPhotoArr, this.currentKvPhoto);
          // debugger;
          this.currentKvPhoto = this.bias.setupKvPhotoIfPrimaryOrPrivate(this.currentKvPhoto);
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
            && this.pagerVKvPhotoNav.pagedItems.length > 0) {
            this.currentKvPhoto = this.bias.displayBackgroundAndNavImageForKvPhoto(this.currentKvPhoto, this.pagerVKvPhotoNav);

            this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.currentKvPhoto);
          }
        }

        EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);

        // debugger;
        this.timer = setTimeout(() => {
          // debugger;
          this.bias.setPagerVNavKvPhotoImage(this.pagerVKvPhotoNav.currentItem);
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)
            && pagerVKvPhotoNav.pagedItems.length > 0) {
            this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.pagerVKvPhotoNav.currentItem);
          }
          this.bias.addPrimaryOrOtherClassToKvPhotoElement(this.pagerVKvPhotoNav.currentItem);
          // debugger;
          resolve(this.pagerVKvPhotoNav);
          clearTimeout(this.timer);
        })
      }
      else {
        reject(null);
      }
    });
  }
  // ---------------------------------------------------------------
  //  Note: This method display All vertical menues and bgImage for a profilePics. !!!!
  // ---------------------------------------------------------------
  public executeProfilePicsKvPhotoAndGlyphMenuTasks (inpPics : ProfilePics) : Observable<any> {
    return new Observable<any>(subscriber => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpPics)) {
        this.isViewMember = EmitterSubjectService.getIsViewMember();
        this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
        this.isMyProfile = EmitterSubjectService.getIsMyProfile();
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();

        if (this.isViewMember) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpPics.filteredKvPhotoArr) && inpPics.filteredKvPhotoArr.length > 0) {
            this.pagerVKvPhotoNav = this.getPagerForVKvPhotoNav(inpPics.filteredKvPhotoArr, 1, 6);
            if (this.loginSuccess.signedInUserId !== inpPics.sitUserId) {
              // this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(inpPics.sitUserId); // will display photo primary/private/public glyphs
              // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(inpPics.sitUserId); 
              // debugger;            
            }
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
              // debugger;
              this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
            }

            EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
            subscriber.next(this.pagerVKvPhotoNav);
          }
        }
        else if (this.isPhotoMgmt || this.isMyProfile) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpPics.unFilteredKvPhotoArr) && inpPics.unFilteredKvPhotoArr.length > 0) {
            this.pagerVKvPhotoNav = this.getPagerForVKvPhotoNav(inpPics.unFilteredKvPhotoArr, 1, 6);
            this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.pagerVKvPhotoNav.currentItem); // will display photo primary/private/public glyphs
            this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;

            // debugger;
            EmitterSubjectService.emitDisplayMyPagerVKvPhotoNav(this.pagerVKvPhotoNav);
            subscriber.next(this.pagerVKvPhotoNav);
          }
        }
      }
    })
  }
  // ---------------------------------------------------------------
  public executeProfileTileKvPhotoAndGlyphMenuTasks2 (inpTile : ProfileTile) : Observable<any> {
    return new Observable<any>((subscriber) => {
      // ------------------------------------------------------------------
      // Note: all display of PhotoNav and GlyphArrs should go through
      //       pagerVKvPhotoNavComponent. They should not directly be
      //       implemented from anywhere else. Hence emit the following:
      //       EmitProfileImages();
      // ------------------------------------------------------------------
      // debugger;
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto) &&
        (
          (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.image) && inpTile.kvPhoto.image.length > 0)
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.value) && inpTile.kvPhoto.value.length > 0)
        )
      ) {
        this.profileTile = inpTile;
        let tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr([ this.profileTile.kvPhoto ], 'pagerVKvPhotoNav-');
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpKvPhotoArr) && tpKvPhotoArr.length > 0) {
          this.profileTile.kvPhoto = tpKvPhotoArr[ 0 ];
        }
        // debugger;
        //  EmitterSubjectService.emitProfileImages([ this.profileTile.kvPhoto ]);

        // debugger;
        if (this.isViewMember) {
          // debugger;
          let sitUser : SitUser = EmitterSubjectService.getSitUserModel();
          if (sitUser.sitUserId === this.profileTile.sitUserId) {
            // this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(sitUser.sitUserId); // will display photo primary/private/public glyphs
            // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(sitUser.sitUserId); 
            // debugger;
            //this.pagerPhotoMgmtService.setKvPhotoArr([ this.profileTile.kvPhoto ]);
            //this.pagerVKvPhotoNav = this.pagerVKvPhotoNavService.getPagerVKvPhotoForCurrentPage(this.profileTile.kvPhoto );
            //this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;

            this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(this.profileTile.kvPhoto, [ this.profileTile.kvPhoto ]);
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
              // debugger;
              this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
            }
          }
        }
        else if (this.isMyProfile) {
          debugger;
          // this.pagerVKvPhotoNav = this.photoMgmtService.setPageOfPhotoNav(1, [ this.profileTile.kvPhoto ]);
          // this.pagerVKvPhotoNav = this.pagerVKvPhotoNavService.getPagerVKvPhotoForCurrentPage(this.profileTile.kvPhoto);
          this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(this.profileTile.kvPhoto, [ this.profileTile.kvPhoto ]);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
            && this.pagerVKvPhotoNav.pagedItems.length > 0) {
            // debugger;
            this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displaySignInUserPrimaryKvPhotoGlyphs();
          }
        }
        else if (this.isPhotoMgmt) {
          // debugger;
          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.profileTile.kvPhoto); // will display photo primary/private/public glyphs
          this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;
        }
        subscriber.next(this.pagerVKvPhotoNav);
      }
    })
  }
  // ---------------------------------------------------------------
  //  Note: This method display All vertical menues and bgImage for a profileTile. !!!!
  // ---------------------------------------------------------------
  public executeProfileTileKvPhotoAndGlyphMenuTasks (inptile : ProfileTile) : Observable<any> {
    let inpTile = inptile
    return new Observable<any>((subscriber) => {
      // ------------------------------------------------------------------
      // Note: all display of PhotoNav and GlyphArrs should go through
      //       pagerVKvPhotoNavComponent. They should not directly be
      //       implemented from anywhere else. Hence emit the following:
      //       EmitProfileImages();
      // ------------------------------------------------------------------
      //// debugger;
      //this.isViewMember = EmitterSubjectService.getIsViewMember();
      //this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      //this.isMyProfile = EmitterSubjectService.getIsMyProfile();
      let tpKvPhotoArr : any[] = [];
      let kvp : KvPhoto = new KvPhoto();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile)) {
        this.profileTile = inpTile;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.primaryImage) && inpTile.primaryImage.length > 0) {
          kvp = new ProfileTile().copyToKvPhoto(kvp, inpTile);
          tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr([ kvp ], 'pagerVKvPhotoNav-');
          // debugger;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.image) && inpTile.kvPhoto.image.length > 0
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.value) && inpTile.kvPhoto.value.length > 0)) {
          tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr([ this.profileTile.kvPhoto ], 'pagerVKvPhotoNav-');
          // debugger;
        }       
       
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpKvPhotoArr) && tpKvPhotoArr.length > 0) {
          this.profileTile.kvPhoto = tpKvPhotoArr[ 0 ];
        }

        this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(this.profileTile.kvPhoto, [ this.profileTile.kvPhoto ]);

        // debugger;
        //if (this.isViewMember) {
        //  // debugger;
        //  // let sitUser : SitUser = EmitterSubjectService.getSitUserModel();
        //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        //  if (this.loginSuccess.signedInUserId !== this.profileTile.sitUserId) {
        //    // this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(this.profileTile.sitUserId); // will display photo primary/private/public glyphs
        //    // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.profileTile.sitUserId); 
        //    // debugger;            
        //  }
        //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
        //    // debugger;
        //    this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
        //  }
        //}
        //if (this.isPhotoMgmt || this.isMyProfile) {
        //  // debugger;

        //  this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.profileTile.kvPhoto); // will display photo primary/private/public glyphs
        //  this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;
        //}

        // debugger;
        EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
        // debugger;
        subscriber.next(this.pagerVKvPhotoNav);
      }
      else {
        this.message = 'pagerVKvPhotoNavService.executeProfileTileKvPhotoAndGlyphMenuTasks ->profileTile was null or it\'s image was null';
        console.log(this.message);
      }
    })
  }
  
  // ----------------------------------------------------------------
  // Used by MemberViewMgmtService && MyProfileViewMgmtComponent
  //
  // Candidate-1: 1-stop-shop
  //  Second-Way to display All Vertical menues:
  //
  //  Note: This method will execute the complete PagerVKvPhotoNav
  //        and vertical glyphcon-menu and display them as a one-stop-shop.
  // ----------------------------------------------------------------
  public executePagedItemsAndGlyphMenuTasks (pagerVKvPhotoNav : PagerPhotoMgmt) : Promise<any> {
    let tKvPhotoArr : any;
    // debugger;
    return new Promise<any>((resolve, reject) => {
      // ------------------------------------------------------------------
      // Note: all display of PhotoNav and GlyphArrs should go through
      //       pagerVKvPhotoNavComponent. They should not directly be
      //       implemented from anywhere else. Hence emit the following:
      //       EmitProfileImages();
      // ------------------------------------------------------------------
      // debugger;
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();


      // setup the kvPhotoArr for pagerVKvPhotoNav, if it isn't setup yet:
      // -----------------------------------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.kvPhotoArr)
        && pagerVKvPhotoNav.kvPhotoArr.length > 0
        && (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.kvPhotoArr[ 0 ].elemId) || pagerVKvPhotoNav.kvPhotoArr[ 0 ].elemId.length == 0)
      ) {
        this.pagerVKvPhotoNav = pagerVKvPhotoNav;
        // debugger;
        tKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr(pagerVKvPhotoNav.kvPhotoArr, 'pagerVKvPhotoNav-');
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tKvPhotoArr) && tKvPhotoArr.length > 0) {
          this.kvPhotoArr = pagerVKvPhotoNav.kvPhotoArr = tKvPhotoArr;
        }
      }
      else {
        // debugger;
        this.pagerVKvPhotoNav = pagerVKvPhotoNav;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.kvPhotoArr) 
          && this.pagerVKvPhotoNav.kvPhotoArr.length > 0) {
          this.kvPhotoArr = pagerVKvPhotoNav.kvPhotoArr;
        }
      }


      // executes the pagedItems:
      // ------------------------
      this.executePagerVKvPhotoNavPagedItems(this.pagerVKvPhotoNav).then(result => {
        this.pagerVKvPhotoNav = result;
      });

      // display the glyphicon menu:
      // ---------------------------
      this.sitUser = EmitterSubjectService.getSitUserModel();
      // debugger;
      if (this.isViewMember) {
        // debugger;
        if (this.sitUser?.sitUserId === pagerVKvPhotoNav?.kvPhotoArr[ 0 ]?.sitUserId) {
          // this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(this.sitUser?.sitUserId); // will display photo primary/private/public glyphs
          // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.sitUser.sitUserId); 
        }
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForViewMember)
          && this.kvMemActGlyphArrForViewMember.length > 0) {
          // debugger;
          this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
        }
      }
      else {
        if (this.isMyProfile) {
           debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
            && this.pagerVKvPhotoNav.pagedItems.length > 0) {
            // debugger;
            this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displaySignInUserPrimaryKvPhotoGlyphs();

            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForViewMember)
              && this.kvMemActGlyphArrForViewMember.length > 0) {
              // debugger;
              this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
            }
          }
        }
        if (this.isPhotoMgmt) {
          // debugger;
          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(pagerVKvPhotoNav.currentItem); // will display photo primary/private/public glyphs
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForViewMember)
            && this.kvMemActGlyphArrForViewMember.length > 0) {
            // debugger;
            this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
          }
        }
      }
       EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
      // debugger;
      resolve(this.pagerVKvPhotoNav);
    })
  }
  // ---------------------------------------------------------------
  public executeKvPhotoNavTasks (kvp : KvPhoto, kvpArr : KvPhoto[]) : Observable<any> {
    let kvP = kvp;
    let kvPArr = kvpArr;
    // debugger;
    return new Observable<any>((subscriber) => {
      // ------------------------------------------------------------------
      // Note: all display of PhotoNav and GlyphArrs should go through
      //       pagerVKvPhotoNavComponent. They should not directly be
      //       implemented from anywhere else. Hence emit the following:
      //       EmitProfileImages();
      // ------------------------------------------------------------------
      // debugger;
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP) &&
        (
          (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP.image) && kvP.image.length > 0)
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP.value) && kvP.value.length > 0)
        )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0)
      {
        let tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr(kvPArr, 'pagerVKvPhotoNav-');

        // TODO: check for accuracy, otherwise replace with previous:
        // this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(kvP, kvPArr);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpKvPhotoArr) && tpKvPhotoArr.length > 0) {
          this.pagerVKvPhotoNav = this.setupPagerVKvPhotoNav(tpKvPhotoArr, kvP);
           // debugger;
        }
        else {
          // debugger;
          this.pagerVKvPhotoNav = this.setupPagerVKvPhotoNav(kvPArr, kvP);
        }   
        // debugger;
        subscriber.next(this.pagerVKvPhotoNav);
        // debugger;
        subscriber.complete();
      }
      else {
        this.message = 'pagerVKvPhotoNavService.executeKvPhotoNavTasks ->KvPhotoArr was null or KvPhoto was null or it\'s image was null';
        console.log(this.message);
        subscriber.next(null);
        subscriber.complete();
      }
    })
  }
  // ---------------------------------------------------------------
  //  Used for memberViewMgmt:
  // TODO: separate out the KvPhoto display task from GlyphMenu display task.
  // ---------------------------------------------------------------
  public executeKvPhotoAndGlyphMenuTasks (kvp : KvPhoto, kvpArr : KvPhoto[]) : Observable<any> {
    let kvP = kvp;
    let kvPArr = kvpArr;
    // debugger;
    return new Observable<any>((subscriber) => {
      // ------------------------------------------------------------------
      // Note: all display of PhotoNav and GlyphArrs should go through
      //       pagerVKvPhotoNavComponent. They should not directly be
      //       implemented from anywhere else. Hence emit the following:
      //       EmitProfileImages();
      // ------------------------------------------------------------------
      // debugger;
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP) &&
        (
          (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP.image) && kvP.image.length > 0)
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvP.value) && kvP.value.length > 0)
        )

      ) {
        let tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr(kvPArr, 'pagerVKvPhotoNav-');

        // TODO: check for accuracy, otherwise replace with previous:
        // this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(kvP, kvPArr);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpKvPhotoArr) && tpKvPhotoArr.length > 0) {
          this.pagerVKvPhotoNav = this.setupPagerVKvPhotoNav(tpKvPhotoArr, kvP);
        }
        

        // debugger;
        // NOTE: TODO:  since this method is used by lot other methods, it is kept here instead of deleting
        //              in case it is needed or other pages behave undesirably.
        if (this.isViewMember) {
          // debugger;
          // let sitUser : SitUser = EmitterSubjectService.getSitUserModel();
          this.loginSuccess = EmitterSubjectService.getLoginSuccess();
          if (this.loginSuccess.signedInUserId !== kvP.sitUserId) {
            this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.displayViewMemberGlyphMenu(kvP.sitUserId); // will display photo primary/private/public glyphs
            // debugger;            
          }
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
            // debugger;
            this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
          }
        }
        if (this.isPhotoMgmt || this.isMyProfile) {
          // debugger;

          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(kvP); // will display photo primary/private/public glyphs
          this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;
        }

        // debugger;
        subscriber.next(this.pagerVKvPhotoNav);
        // debugger;
        // subscriber.complete();
      }
      else {
        this.message = 'pagerVKvPhotoNavService.executeProfileTileKvPhotoAndGlyphMenuTasks ->profileTile was null or it\'s image was null';
        console.log(this.message);
        subscriber.next(null);
        subscriber.complete();
      }
    })
  }
  // ------------------------------------------------------------
  // Note: This should be called when a pager is needed.
  // ------------------------------------------------------------
  executeSetPageTasks (currentItem : KvPhoto, kvpArr : KvPhoto[]) : PagerPhotoMgmt | any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentItem) && currentItem.pageNum >= 1) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
        this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(currentItem, kvpArr); //get pager

        // this should call the following method regarless:
        // ---------------------------------------------------------
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
          && this.pagerVKvPhotoNav.pagedItems.length > 0) {

          this.pagerVKvPhotoNav.pagedItems.forEach(e => {
            e.value = JsRegExpServiceStatic.setUrlForImageData(e.value);
          });

          // debugger;
          EmitterSubjectService.setPagerVKvPhotoNav(this.pagerVKvPhotoNav);
          // EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
          // debugger;
        }
        else {
          this.pagerVKvPhotoNav = EmitterSubjectService.getPagerVKvPhotoNav();
        }
      }
      else {
        this.pagerVKvPhotoNav = EmitterSubjectService.getPagerVKvPhotoNav();
      }
      // debugger;
      return this.pagerVKvPhotoNav;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  public getPagerVKvPhotoNav(): PagerPhotoMgmt {
    // debugger;
    return this.pagerVKvPhotoNav;
  }
  // ----------------------------------------------------------------

  // ---------------------------------------------------------------
  //  Note: API
  // Used by lot of components and services:
  // Objective: try to rely on this method
  // ----------------------------------------------------------------
  public getPagerVKvPhotoNavForKvPhotoInKvPhotoArr (kvp : KvPhoto, kvpArr : KvPhoto[]) : PagerPhotoMgmt | any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      //&& (kvp.photoBnId > 0 || kvp.photoId > 0
      //|| (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && kvp.image.length > 0))) {
      if (kvp.pageNum <= 0) {
        if (kvp.index >= 0) {
          kvp.pageNum = kvp.index + 1;
        }
        else if (kvp.itemNum >= 0) {
          kvp.pageNum = kvp.itemNum + 1;
        }
      }
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
      // debugger;
      // Note:  the following line must be run right after the previous line/method: getPager() !! 
      this.pagerVKvPhotoNav = this.setupPagerVKvPhotoNav(kvpArr, kvp);
      // debugger;
      return this.pagerVKvPhotoNav;
    }
    else return null;    
  }
  // ---------------------------------------------------------------
  //  Getter/Setter:
  //  `VKv`=Vertically displayed <Key, Value>; 
  //  `PhotoNav`=Photo Navigation;
  // ---------------------------------------------------------------
  public getPagerForVKvPhotoNav (kvpArr : KvPhoto[], page : number, pageSize : number) : PagerPhotoMgmt | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0
      && page > 0 && pageSize > 0) {
      // debugger;
      this.pagerVKvPhotoNav = this.pagerPhotoMgmtService.getBasicPagerDynamic(kvpArr, page, pageSize);
      // debugger;
      EmitterSubjectService.setPagerVKvPhotoNav(this.pagerVKvPhotoNav);

      // debugger;
      return this.pagerVKvPhotoNav;
    }
    else return null;
  }  
  // ---------------------------------------------------------------
  //  Note: API
  // ---------------------------------------------------------------
  prepareKvPhotoForDisplay (kvPhoto : KvPhoto) : KvPhoto | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
        if (kvPhoto.value.length > 0 ) {       
            kvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(kvPhoto.value);
        }        
      }
    }
    return kvPhoto;
  }
  // ---------------------------------------------------------------------
  // Note:  This method does not check whether the value has image-prefix:
  //        So only image-prefixed data should call this method.
  // ---------------------------------------------------------------------
  prefixUrlToKvPhotoForDisplay (kvPhoto : KvPhoto) : KvPhoto | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
        if (kvPhoto.value.length > 0 && !JsRegExpServiceStatic.photoUrlPrefixPattern.test(kvPhoto.value)) {
            kvPhoto.value = JsRegExpServiceStatic.prefixUrlToImageData(kvPhoto.value);          
        }        
      }
    }
    return kvPhoto;
  }
  // ---------------------------------------------------------------
  isPrivateAndUnlocked (kvp : KvPhoto) : boolean {
    var isPandUnlocked = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      this.photoPrivacyService.filterKvPhoto(kvp).subscribe(data => {
        isPandUnlocked = data.isPrivate && data.isUnlocked;
			})
    }
    return isPandUnlocked;
	}
  // ---------------------------------------------------------------
  // This method normalizes and image's raw data for displating on
  // an html element. It can be prefixed with a 'url()' around the
  // image-data, or can strip the 'url()' from the image-data.
  // ---------------------------------------------------------------
  prepareKvPhotoArrForDisplay (kvPhotoArr : KvPhoto[]) : KvPhoto[] | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      kvPhotoArr.forEach(e => {
        // debugger;
        // NOTE: this section contains an infinite-loop. 
        // TODO: remove the infinite-loop!!
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) && e.value.length > 0) {
          if (e.value.match(JsRegExpServiceStatic.allImageTypesDataPattern3) && !e.value.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
            // debugger;
            e.value = JsRegExpServiceStatic.normalizeImageData(e.value);
            e.value = JsRegExpServiceStatic.stripUrlForImageData(e.value);
            // debugger;
          }
          else if (e.value.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
            debugger;
            e.value = JsRegExpServiceStatic.normalizeImageFileNameWithoutUrl(e.value);
            debugger;
          }
          else {
            debugger;
            e.value = JsRegExpServiceStatic.normalizeImageData(e.value);
            e.value = JsRegExpServiceStatic.stripUrlForImageData(e.value);
					}
        }
      });
    }
    return kvPhotoArr;
  }
  // --------------------------------------------------------------------
  // API:
  // Note: This should be called when a pager is needed for any KvPhoto.
  // --------------------------------------------------------------------
  public setCurrentItemPage (currentitem : KvPhoto, kvpArr : KvPhoto[]) : Observable<any> {
    // debugger;
    var currentItem = currentitem;
    var kvPhotoArr = kvpArr;

    return new Observable(subscriber => { 
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentItem) && currentItem.pageNum > 0
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
        // debugger;
        // this.pagerVKvPhotoNav = this.pagerVKvPhotoNavService.executeSetPageTasks(currentItem, this.kvPhotoArr); // (pagerVKvPhotoNav
        this.executePagerVKvPhotoNavCurrentKvPhotoTasks(currentItem, kvPhotoArr).subscribe(data => {
          subscriber.next(data);
          subscriber.complete();
        })
      }
      else {
        subscriber.next(null);
        subscriber.complete();
      }
    })
  }
  // --------------------------------------------------------------------
  // API:
  // Note: This should be called when a pager is needed for a pageNum.
  // --------------------------------------------------------------------
  public setPageAndGetCurrentPageNum (page : string, pagerVKvPhotoNav : PagerPhotoMgmt) : number {
    debugger;
    let currentPageNum : number = 0;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(page)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)) {
      switch (page.toLowerCase()) {
        case 'f':
          currentPageNum = 1;
          break;
        case 'prev':
          currentPageNum = (this.pagerVKvPhotoNav.currentPageNo - 1) > 0 ? this.pagerVKvPhotoNav.currentPageNo - 1 : 1;
          break;
        case '1': case '2': case '3': case '4': case '5': case '6': case '7': case '8': case '9': case '10':
        case '11': case '12': case '13': case '14': case '15': case '16': case '17': case '18': case '19': case '20':
        case '21': case '22': case '23': case '24': case '25': case '26': case '27': case '28': case '29': case '30':
        case '31': case '32': case '33': case '34': case '35': case '36': case '37': case '38': case '39': case '340':
        case '41': case '42': case '43': case '44': case '45': case '46': case '47': case '48': case '49': case '50':
        case '51': case '52': case '53': case '54': case '55': case '56': case '57': case '58': case '59': case '60':
        case '61': case '62': case '63': case '64': case '65': case '66': case '67': case '68': case '69': case '70':
        case '71': case '72': case '73': case '74': case '75': case '76': case '77': case '78': case '79': case '80':
        case '81': case '82': case '83': case '84': case '85': case '86': case '87': case '88': case '89': case '90':
        case '91': case '92': case '93': case '94': case '95': case '96': case '97': case '98': case '99': case '100':
          currentPageNum = parseInt(page, 10);
          // debugger;
          break;
        case 'l':
          currentPageNum = pagerVKvPhotoNav.pages.length;
          break;
        case 'next':
          currentPageNum = currentPageNum + 1 >= pagerVKvPhotoNav.nextPageNo ? currentPageNum + 1 : pagerVKvPhotoNav.pages.length;
          break;
        default:
          // this.currentItemKvPhoto = EmitterSubjectService.getCurrentKvPhoto();;
          currentPageNum = pagerVKvPhotoNav.currentPageNo;
          this.executePagerVKvPhotoNavPagedItems(pagerVKvPhotoNav).then(data => {
            pagerVKvPhotoNav = data;
            debugger;
            currentPageNum = pagerVKvPhotoNav.currentPageNo;
          });
          // debugger;
          break;
      }
      return currentPageNum;
    }
  }
  // ----------------------------------------------------------------------
  
  // ----------------------------------------------------------------
  public setupForProfilePicsToPagerVPhotoNav (pPics : ProfilePics) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr) && pPics.unFilteredKvPhotoArr.length > 0) {
        this.pagerVKvPhotoNav = this.pagerPhotoMgmtService.getBasicPagerDynamic(pPics.unFilteredKvPhotoArr, 1, 5);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.kvPhotoArr)
            && this.pagerVKvPhotoNav.kvPhotoArr.length > 0) {
            // debugger;
            this.kvPhotoArr = this.pagerVKvPhotoNav.kvPhotoArr;
          }

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem.value)
            && this.redirectionService.isCurrentUrlAProfilePage()) {
            // debugger;
            // this.bias.setKvPhotoBackgroundImagePrimaryOrOtherwise(this.pagerVKvPhotoNav.currentItem);
            EmitterSubjectService.emitCurrentPage(this.pagerVKvPhotoNav.currentItem);
          }
        }
      }

    }
    // this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // this.signedInUser = CopyServiceStatic.copyLoginSuccessToSitUser(this.loginSuccess);

    EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
    return this.pagerVKvPhotoNav;
  }
  // --------------------------------------------------------------------
  // TODO: This method should be in respective component
  //       because more than one component will call it,
  //       but this knows only memberViewMgmt component.
  // --------------------------------------------------------------------
  setMcLoader (sitUserId ?: number) {
    this.isMyProfile = EmitterSubjectService.getIsMyProfile();
    this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
    this.isViewMember = EmitterSubjectService.getIsViewMember();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();


    if (this.isViewMember) {
      this.mcLoader.moduleName = "memberViewAndUpdgadedm";
      this.mcLoader.componentName = "memberViewMgmt";
      this.mcLoader.id = sitUserId > 0 ? sitUserId : 0;
    }
    //else if (this.isPhotoMgmt) {
    //  this.mcLoader.moduleName = "mym";
    //  this.mcLoader.componentName = "myPhotoMgmt";
    //  this.mcLoader.id = this.loginSuccess.signedInUserId;
    //}
    //else if (this.isMyProfile) {
    //  this.mcLoader.moduleName = "mym";
    //  this.mcLoader.componentName = "myProfileViewMgmt";
    //  this.mcLoader.id = this.loginSuccess.signedInUserId;
    //}
  }
  // --------------------------------------------------------------------
  // API:
  // Note : View calls this method.
  //        Stable and works; on 20220429 & 20220514 & 20220622 & 20230108
  // --------------------------------------------------------------------
  public setPhotoNavItemBase (iTem : KvPhoto, pVKvPhotoNav : PagerPhotoMgmt) : Observable<PagerPhotoMgmt> {
    // debugger;    
    let item = iTem;
    let pagerVKvPhotoNav = pVKvPhotoNav;
    // debugger;    
    return new Observable(subscriber => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)) {
        // debugger;
        this.executeKvPhotoAndGlyphMenuTasks(item, pagerVKvPhotoNav.kvPhotoArr).subscribe(data => {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
            pagerVKvPhotoNav = data;
            // debugger;
          }          
          // debugger;
          subscriber.next(pagerVKvPhotoNav);
          subscriber.complete();
        })
        
      }
      else {
        subscriber.next(pagerVKvPhotoNav);
        subscriber.complete();
      }
    })
  }
  // ----------------------------------------------------------------
  public setupForKvPhotoArrToPagerVPhotoNav (kvpArr : [KvPhoto]) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0){      
      this.pagerVKvPhotoNav = this.pagerPhotoMgmtService.getBasicPagerDynamic(kvpArr, 1, 5);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.kvPhotoArr)
          && this.pagerVKvPhotoNav.kvPhotoArr.length > 0) {
          // debugger;
          this.kvPhotoArr = this.pagerVKvPhotoNav.kvPhotoArr;
        }

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem.value)
          && this.redirectionService.isCurrentUrlAProfilePage()) {
          // debugger;
          // this.bias.setKvPhotoBackgroundImagePrimaryOrOtherwise(this.pagerVKvPhotoNav.currentItem);
          EmitterSubjectService.emitCurrentPage(this.pagerVKvPhotoNav.currentItem);
        }
      }

    }
    // this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // this.signedInUser = CopyServiceStatic.copyLoginSuccessToSitUser(this.loginSuccess);

    EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
    return this.pagerVKvPhotoNav;
  }

  // ---------------------------------------------------------------
  public setupPagerVKvPhotoNav (kvpArr : KvPhoto[], kvp : KvPhoto) : PagerPhotoMgmt | any {

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      var mFloor = Math.floor(kvp.pageNum / this.pageSize);
      var page = mFloor > 0 ? mFloor : 1;
      // debugger;
      return this.getPagerForVKvPhotoNav(kvpArr, page, this.pageSize)
    }
    else return null;
  }
  // ---------------------------------------------------------------
  public setPagerVKvPhotoArr (kvpArr : KvPhoto[]) : void {
    this.kvPhotoArr = kvpArr;
  }
  // ----------------------------------------------------------------
  public updatePagerVPhotoNav (pgrVPhotoNav : PagerPhotoMgmt) : PagerPhotoMgmt {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pgrVPhotoNav) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pgrVPhotoNav.kvPhotoArr) && pgrVPhotoNav.kvPhotoArr.length > 0) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      pgrVPhotoNav.kvPhotoArr = this.pagerSupportService.updateKvPhotoArr(pgrVPhotoNav.kvPhotoArr, pgrVPhotoNav.kvPhotoArr[ 0 ].sitUserId);

      // debugger;
      if (this.pageSize <= 0) {
        this.pageSize = EmitterSubjectService.getPageSize();
      }

      pgrVPhotoNav = this.pagerPhotoMgmtService.getBasicPagerDynamic(pgrVPhotoNav.kvPhotoArr, pgrVPhotoNav.currentItem.pageNum, this.pageSize); // get initial pager which willbe used to get the pageSize
      // debugger;
      // TODO: check to see if recompute pager is necessary
      // pgrVPhotoNav = (this.pagerServicePhotoMgmt as PagerServicePhotoMgmt).getPagerUpTo10(pgrVPhotoNav.kvPhotoArr, currentPage, this.pageSize); // recompute pager
      // debugger;
      // this.pagerVPhotoNav = pgrVPhotoNav;
    }
    return pgrVPhotoNav;
  }  
  // ------------------------------------------------------------
}
