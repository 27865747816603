import { Injectable, OnDestroy } from '@angular/core';
// import { Idle } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';
import { Subject } from 'rxjs';
// import { IDLE_TOKEN, KEEPALIVE_TOKEN } from '../staticServices/constantServiceStatic.service';
import { EncodingService } from './encodingService.service';
import { ImageResizerService } from './imageResizerService.service';
import { RedirectionService } from './redirectionService.service';
import { SignedInUserService } from './signedInUserService.service';
import { SitUserService } from './sitUserService.service';

@Injectable({
  providedIn: 'root',
})
export class CommonServiceService implements OnDestroy {
  private emitterDestroyed$: Subject<boolean> = new Subject();
  constructor(
    // private downloadService: DownloadService,
    private encodingService : EncodingService,
    private imageResizerService : ImageResizerService,
    private redirectionService: RedirectionService,
    // private rendererService: RendererService,
    // @Inject(KEEPALIVE_TOKEN) private keepAlive,
    // @Inject(IDLE_TOKEN) private idle : Idle,
    public signedInUserService : SignedInUserService,
    public sitUserService: SitUserService,
  )
  {
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ------------------------------------------------------------------

  // ------------------------------------------------------------------
  // setBreadcrumRingService(bcs: BreadcrumService): void {
  //  this.breadcrumService = bcs;
  // }
  // getBreadcrumRingService(): BreadcrumService {
  //  return this.breadcrumService;
  // }
  // // ------------------------------------------------------------------
  // setConstantService(cs: ConstantService): void {
  //  this.constantService = cs;
  // }
  // getConstantService(): ConstantService {
  //  return this.constantService;
  // }

  // // ------------------------------------------------------------------
  // setCopyService(cps: CopyService): void {
  //  this.copyService = cps;
  // }
  // getCopyService(): CopyService {
  //  return this.copyService;
  // }
  // ------------------------------------------------------------------
  // setDownloadService(dns: DownloadService): void {
  //  this.downloadService = dns;
  // }
  // getDownloadService(): DownloadService {
  //  return this.downloadService;
  // }
  // ------------------------------------------------------------------
  setEncodingService(es: EncodingService): void {
    this.encodingService = es;
  }
  getEncodingService(): EncodingService {
    return this.encodingService;
  }
  // ------------------------------------------------------------------------------------------------
  //setIdle(idl: Idle): void {
  //  this.idle = idl;
  //}
  // ------------------------------------------------------------------------------------------------
  getImageResizerService () : ImageResizerService {
    return this.imageResizerService;
  }
  // ------------------------------------------------------------------------------------------------
  setImageResizerService ( idl : ImageResizerService ) : void
  {
    this.imageResizerService = idl;
  }
  // ------------------------------------------------------------------------------------------------
  //getIdle () : Idle
  //{
  //  return this.idle;
  //}
  // ------------------------------------------------------------------------------------------------
  setRedirectionService (rds: RedirectionService): void {
    this.redirectionService =rds;
  }
  // ------------------------------------------------------------------------------------------------
  getRedirectionService(): RedirectionService {
    return this.redirectionService;
  }
  // ------------------------------------------------------------------
  // setRendererService(rs: RendererService): void {
  //  this.rendererService = rs;
  // }
  // getRendererService(): RendererService {
  //  return this.rendererService;
  // }
  // ------------------------------------------------------------------
  setSignedInUserService (ss : SignedInUserService) : void {
    this.signedInUserService = ss;
  }
  getSignedInUserService () : SignedInUserService {
    return this.signedInUserService;
  }
  // ------------------------------------------------------------------
  setSitUserService(ss: SitUserService): void {
    this.sitUserService = ss;
  }
  getSitUserService(): SitUserService {
    return this.sitUserService;
  }
  // ------------------------------------------------------------------
  //setHttpBackgroundService (hbr : HttpBackgroundService) : void {
  //  this.httpBackgroundService = hbr;
  //}
  //// -----------------------------------------------------------------
  //getHttpBackgroundService () : HttpBackgroundService {
  //  return this.httpBackgroundService;
  //}

  // ------------------------------------------------------------------
  // setUnitConversionService(ucs: UnitConversionService): void {
  //  this.unitConversionService = ucs;
  // }
  // getUnitConversionService(): UnitConversionService {
  //  return this.unitConversionService;
  // }
   
  // ------------------------------------------------------------------
  // ------------------------------------------------------------------
}
