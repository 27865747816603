'use-strict'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveFilterPipeModule } from '../directiveFilterPipeModule/directiveFilterPipe.module';
import { KeyValueModule } from '../keyValueModule/keyValue.module';
import { PagerModule } from '../pagerModule/pager.module';
import { ProfileModule } from '../profileModule/profile.module';
import { AppComponentsRoutingModule } from './appComponents-routing.module';
import { AvatarComponent } from './avatar/avatar.component';
import { GlyphiconMembersComponent } from './glyphiconMembers/glyphiconMembers.component';
import { HomePageComponent } from './homePage/homePage.component';
import { LandingPageComponent } from './landingPage/landingPage.component';
import { LeftRightLogoMenuComponent } from './leftRightLogoMenu/leftRightLogoMenu.component';
import { LogoComponent } from './logo/logo.component';
import { LogoMenuComponent } from './logoMenu/logoMenu.component';
import { LogRegButtonsAndNavArrowsComponent } from './logRegButtonsAndNavArrows/logRegButtonsAndNavArrows.component';
import { MessageBoardComponent } from './messageBoard/messageBoard.component';
import { MyStuffComponent } from './myStuff/myStuff.component';
import { NavHeaderComponent } from './navHeader/navHeader.component';
import { NavSidebarComponent } from './navSidebar/navSidebar.component';

@NgModule( {
  
  imports: [
    CommonModule, // to get ngIf, ngFor, etc.
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DirectiveFilterPipeModule,
    KeyValueModule,
    PagerModule,
    ProfileModule,
    AppComponentsRoutingModule
  ],
  declarations: [
    AvatarComponent,
    GlyphiconMembersComponent,
    HomePageComponent,
    LandingPageComponent,
    LeftRightLogoMenuComponent,
    LogoMenuComponent,
    LogoComponent,
    LogRegButtonsAndNavArrowsComponent,
    MessageBoardComponent,
    MyStuffComponent,
    NavHeaderComponent,
    NavSidebarComponent,
  ],
  exports: [
    CommonModule, // to get ngIf, ngFor, etc.
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,

    AvatarComponent,
    GlyphiconMembersComponent,
    HomePageComponent,
    LandingPageComponent,
    LeftRightLogoMenuComponent,
    LogoMenuComponent,
    LogoComponent,
    LogRegButtonsAndNavArrowsComponent,
    MessageBoardComponent,
    MyStuffComponent,
    NavHeaderComponent,
    NavSidebarComponent,

    AppComponentsRoutingModule,
  ],
 
  providers: [
  ],
})
export class AppComponentsModule { }
