
import { Injectable } from '@angular/core';
import { BackgroundImageAnimationService } from './backgroundImageAnimationService.service';
import { RendererService } from './rendererService.service';

@Injectable({
  providedIn: 'any',
})
export class RendererServiceService {
  constructor (
    private backgroundImageAnimationService : BackgroundImageAnimationService,
    private rendererService : RendererService,
  ) { }

  // ------------------------------------------------------------------
  setBackgroundImageAnimationService ( bias : BackgroundImageAnimationService ) : void
  {
    this.backgroundImageAnimationService = bias;
  }
  getBackgroundImageAnimationService () : BackgroundImageAnimationService
  {
    return this.backgroundImageAnimationService;
  }
  // ------------------------------------------------------------------
  setRendererService ( rs : RendererService ) : void
  {
    this.rendererService = rs;
  }
  getRendererService () : RendererService
  {
    return this.rendererService;
  }
  

  // ------------------------------------------------------------------
  // setImageResizerService(irs: ImageResizerService): void {
  //  this.imageResizerService = irs;
  // }
  // getImageResizerService(): ImageResizerService {
  //  return this.imageResizerService;
  // }
  // ------------------------------------------------------------------
}
