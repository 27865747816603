
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { HttpService } from '../coreServiceService/httpService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { SitUserService } from './sitUserService.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { MessageBuilderServiceStatic } from '../staticServices/messgeBuilderServiceStatic.service';

@Injectable({
  providedIn: 'root',
})
export class SignedInUserService {
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public sitUser : SitUser = new SitUser(); // for sending data to the server
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public message = '';
  public timer : any;
  public timerArray : any[] = [];
  constructor (
    public httpService : HttpService,
    public sitUserService : SitUserService,
  ) {  }

  // ---------------------------------------------------------------
  public ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach((timer) => clearInterval(timer));
    return true;
  }
  // -----------------------------------------------------------
  public nullPromise () : any {
    this.timer = setTimeout(() => {
      // debugger;
      return null;
    }, 10);
    clearTimeout(this.timer);
    if (this.timer) {
      this.timerArray.push(this.timer);
    }
  }
  // ---------------------------------------------------------------
  public salt (model : any) : BoxNonceEntity {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      // debugger;
      return (this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(model)));
    }
    return new BoxNonceEntity();
  }

  // ---------------------------------------------------------------------------------
  public resetLoginSuccess () : any {
    this.loginSuccess.action = '';
    this.loginSuccess.blogId = 0;
    this.loginSuccess.businessKey = '';
    this.loginSuccess.code = '';
    this.loginSuccess.controller = '';
    this.loginSuccess.email = '';
    this.loginSuccess.isAuthorized = false;
    this.loginSuccess.isEmailConfirmed = false;
    this.loginSuccess.isEmployee = false;
    this.loginSuccess.isForgotPassword = false;
    this.loginSuccess.isMgmt = false;
    this.loginSuccess.isPic = false;
    this.loginSuccess.isReviewer = false;
    this.loginSuccess.isSuperUser = false;
    this.loginSuccess.imagePath = '';
    this.loginSuccess.iv = '';
    this.loginSuccess.message = '';
    this.loginSuccess.profileName = '';
    this.loginSuccess.id = 0;
    this.loginSuccess.likedArr = []; // who liked me
    this.loginSuccess.myBlockedArr = []; // whom I blocked
    this.loginSuccess.myBuddyArr = [];
    this.loginSuccess.myTrackedArr = []; // whom I tracked
    this.loginSuccess.myUnlockedArr = []; // from whom I unlocked
    this.loginSuccess.signedInUserId = 0;
    this.loginSuccess.signedInUserKey = '';
    this.loginSuccess.trackedArr = []; // who tracked me
    this.loginSuccess.unlockedArr = []; // who unlocked for me
    return this.loginSuccess;
  }
  
  // ---------------------------------------------------------------
  public getSingedInSitUser () : Observable<any>{
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
      return new Observable((subscriber) => {
        // debugger;
        console.log('calling fetchSitUser() from signedInUserService@ln99')
        this.sitUserService.getSitUserFromDictionaryOrServer(this.loginSuccess.signedInUserId).subscribe(data => {
          subscriber.next(data);
          subscriber.complete();
        })
      });
    }
    else {
      this.message = MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, 'signedInUserService.getSignedInUser() signedInUserId === 0 ');
      // console.log(this.message);
      return null;
    }
  }
}
  
// ---------------------------------------------------------------
