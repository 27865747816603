
import { ElementRef, Injectable } from '@angular/core';
import { EmitterSubjectService } from './emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from './stringServiceStatic.service';

// =====================================================================
// NOE: check/findout what '*' means in JavaScript and C# with respect to RegExp:
// JS:  * Matches the preceding expression 0 or more times.  // ref: https://blog.bitsrc.io/a-beginners-guide-to-regular-expressions-regex-in-javascript-9c58feb27eb4
// C#:  * Matches the previous element zero or more times.    // ref: https://docs.microsoft.com/en-us/dotnet/standard/base-types/regular-expression-language-quick-reference
//
// ------------------------------------
// Some basic rules/grammers of RegExp:
// ref: https://blog.bitsrc.io/a-beginners-guide-to-regular-expressions-regex-in-javascript-9c58feb27eb4
// ------------------------------------
// Regular Expression Constructor—Syntax: new RegExp('pattern', 'flags')
// Regular Expression Literal—Syntax: /pattern/flags
// Character set: [xyz] — A character set is a way to match different characters in a single position.
// Negated character set: [^ xyz ] — It matches anything that is not enclosed in the brackets.
/*
    \d — Match any digit character ( same as [0-9] ).
    \w — Match any word character. A word character is any letter, digit, and underscore. (Same as [a-zA-Z0–9_] ) i.e alphanumeric character.
    \s — Match a whitespace character (spaces, tabs etc).
    \t — Match a tab character only.
    \b — Find a match at beginning or ending of a word. Also known as word boundary.
    . — (period) Matches any character except for newline.
    \D — Match any non digit character (same as [^0–9]).
    \W — Match any non word character (Same as [^a-zA-Z0–9_] ).
    \S — Match a non whitespace character.
*/
// + — Matches the preceding expression 1 or more times.
// * — Matches the preceding expression 0 or more times.
// ? — Matches the preceding expression 0 or 1 time, that is preceding pattern is optional.
// ^ — Matches the beginning of the RegExp string.
// $ — Matches the end of the RegExp string.
// {N} — Matches exactly N occurrences of the preceding regular expression.
// {N,} — Matches at least N occurrences of the preceding regular expression.
// {N,M} — Matches at least N occurrences and at most M occurrences of the preceding regular expression (where M > N).
// Alternation: X|Y — Matches either X or Y. e.g.: var regex = /(green|red) apple/;
// Escaping:  If you want to use any special character as a part of the expression, say for example you want to match literal + or .,
//            then you have to escape them with backslash(\).
// (x) — Matches x and remembers the match.
// \1 remembers and uses that match from first subexpression within parentheses. e.g.: var regex = /(?:foo)bar\1/;
// (?:x) — Matches x and does not remember the match.
// x(?=y) — Matches x only if x is followed by y. Also called positive look ahead.
// 
// =====================================================================

@Injectable({ providedIn: 'root'})
export abstract class JsRegExpServiceStatic
{
  // regExp petterns:
  // ---------------
  static allSpecialCharacters = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;

  static allImageTypesFilePattern = /\.(jpeg|jpg|png|svg)/gi; // checks image-file-name
  // need all 3 of allImageTypesDataPatterns to test to obtain image-data:
  // ---------------------------------------------------------------------
  static allImageTypesDataPattern1 = /\'*data:image(\/(jpeg|jpg|png|svg))+;base64,/gi;      // tested & works!
  static allImageTypesDataPattern2 = /\"*data:image(\/(jpeg|jpg|png|svg))+;base64,/gi;      // tested & works!
  static allImageTypesDataPattern3 = /[\'\"]*data:image(\/(jpeg|jpg|png|svg))+;base64,/gi;  // tested & works! Usee only this one!
  static allImageTypesDataPattern4 = /\'data\:image(\/(jpeg|jpg|png|svg))+\;base64\,/gi;    // !tested 
  static allImageTypesDataPattern5 = /\"data\:image(\/(jpeg|jpg|png|svg))+\;base64\,/gi;    // !tested 
  static allImageTypesDataPattern6 = /data\:image(\/(jpeg|jpg|png|svg))+\;base64\,/gi;      // !tested 

  static photoIdOrPhotoBnIdPattern = /\"*(photoId|photoBnId)\"*/ig; // checks photo model   // tested & works!
  static photoUrlPrefixPattern = /url\((\')*/gi; // checks if "url('" exists                   // tested & works!
  static photoUrlPostfixPattern = /(\')*\)/gi; // checks if "')" exists
  static camelCasePattern = /^[a-z]+((\d)|([A-Z0-9][a-z0-9]+))*([A-Z])?$/g;                 // not tested
  static pascalCasePattern0 = /^(\")*[A-Z]+((\d)|([A-Z0-9][a-z0-9]+))*([A-Z])?(\")*$/g;     // not tested
  static pascalCasePattern1 = /^(\")*[A-Z]+\w+(\")*$/g;                                     // not tested
  static bracketCurlyPatternGood = /[\{\}]*/g;                                              // tested & works!
  static bracketCurlyPattern = /(\{|\})*/g;                                                 // tested & works!
  static bracketArrayPattern = /^[\[\]]*$/g;
  static bracketFnPattern = /^[\(\)]*$/g;
  static escapeQuotePattern = /\"/g;
  static quotePattern = /^[\"\']\w*[\"\']$/gi;   //--> falty pattern?
  static quotedWordPattern1 = /^(\"|\').*(\"|\')$/gi;   //--> falty pattern?
  static quotedWordPattern2 = /^\".*\"$/gi;   //--> falty pattern?
  // static pascalCase2CamelCaseJoiner = /^\".*\"$/gi;

  // replacement strings:
  // --------------------
  static imagePngReplacement = 'data:image\/png;base64,'; // this is the `normalization string` for all images of slakez.com
  static quoteReplacement = '/^.*$/g';
  // ---------------------------------------------------------------
  private static angularElementRef : ElementRef
  constructor () { }
  // ----------------------------------------------------------------
  static createRegExpFromString (input : string) : any {
    if (typeof input !== 'string') {
      return 0;
    }
    input = (input) === '.' ? ('\\' + input) : input;
    // debugger;
    const regexp = new RegExp(input, 'g');
    return regexp;
  }
  // ----------------------------------------------------------------
  static getAngularElementById (id : string) : any {
    this.angularElementRef = EmitterSubjectService.getElementRef();
    let tempElem = document.getElementById(id);
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.angularElementRef)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.angularElementRef.nativeElement)) {
        tempElem = this.angularElementRef.nativeElement.querySelector(id);
      }
    }
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      tempElem = this.angularElementRef.nativeElement.querySelector('#' + id);
    }
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      return tempElem;
    }
    else return 'undefined';
  }
  // --------------------------------------------------------------
  static getCamelKey (k: string, isValue: boolean): any {
    let camelKey = "";
    if (!this.isNullOrEmpty(k)) {
      k = k.trim();
      // Note: important!!" remove JSON style \" quote at the front at least so that lowerFirstLetter() will work.

      // Note:  1: will trim the prefix \" JSON quote,
      //        2: will trim both prefix and  postfix \" JSON quote.
      //        3: will trim the postfix \" JSON quote,
      // -----------------------------------------------------------
      k = this.trimQuote(k, 1);
      k = this.trimBrackets(k, '{');
      
      // debugger;
      if (!this.isNullOrEmpty(k) && k.length > 0) {
        k = this.lowercaseFirstLetter(k);
        // debugger;
      }
    }
    if (!isValue) {
      camelKey = "\"" + k + "\" : ";
    }
    else {
      ;
    }
    return camelKey;
  }
  // --------------------------------------------------------------
  static getCamelValue (v : string): any {
    let camelValue = ",";
    if (!this.isNullOrEmpty(v)) {
        camelValue = v + ",";
    }
    else {
      camelValue = '"undefined",';
    }
    return camelValue;
  }
  // --------------------------------------------------------------
  // Note: here the KV = <camelCased-k, camelCased-v>
  // --------------------------------------------------------------
  static getCamelCasedFromKV (cck: string, ccv : string) : any {
    let camelCased = "";
    if (!this.isNullOrEmpty(cck) && !this.isNullOrEmpty(ccv)) {
      //debugger;
      camelCased = cck + ccv;
      // debugger;
    }
    return camelCased;
  }
  // --------------------------------------------------------------
  // Note: each word = <key, value> tuple
  // --------------------------------------------------------------
  static getCamelCasedWord (k : string, v : string) : string {
    let camelCased = "";
    if (!this.isNullOrEmpty(k) && !this.isNullOrEmpty(v)) {
      //debugger;
      camelCased +=  this.getCamelKey(k, false) + this.getCamelValue(v);
      // debugger;
    }
    return camelCased;
  } 
  // ---------------------------------------------------------------------------------
  //  Note: currently not in use, but keep it.
  // ---------------------------------------------------------------------------------
  static getRawImageData (image : string) : string {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      const pattern = /\'*data:image(\/(jpeg|jpg|png|svg))+;base64,/;
      let tImage = image.replace(pattern, '');

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tImage)) {
        tImage = this.stripUrlForImageData(tImage);
        return tImage;
      }
      return image;
    }
    return image;
  }
  // --------------------------------------------------------------
  static isNullOrEmpty (input : any) : boolean {
    if (input === null || input === '' || input === undefined || (input !== null && (input.toString().toLowerCase() === 'null' || input.toString().toLowerCase() === 'undefined'))) {
      return true;
    }
    return false;
  }
  // ---------------------------------------------------------------------------------
  // ref: https://www.codegrepper.com/code-examples/javascript/lowercase+first+character+javascript
  //  Note: Tested & works! on 20220423
  // ---------------------------------------------------------------------------------
  public static lowercaseFirstLetter (str : string) : string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }
  
  /*
  * ---------------------------------------------------------------
  * Note:  this method will normalize any imageData to  'data:image/png;base64,xxxxx...'
  *        for example, if the following exists in an imageData string:
  *        1) /\data:image(\/(jpeg|jpg|png|svg))+;base64,/
  *        2) /url(\'\data:image(\/(jpeg|jpg|png|svg))+;base64,/
  *
  *        it will strip url(' and also strip ( jpeg | jpg | svg ), but not png.
  *        and produce to  'data:image/png;base64,xxxxx...'
  *        which is called the 'normalized' format for all imageData for slakez.com
  *
  *        Othewise it retuns an empty string.
  *
  *        Tested & works! On 20220422
  * ---------------------------------------------------------------
  */
  // ---------------------------------------------------------------
  // Important: While testing normalizeImageData() method, the following
  //            RegExps were tested, and their results noted:
  //            1) [ /\data:image(\/(jpeg|jpg|png|svg))+;base64,/; //--> falty pattern]
  //            2) [/\'*data:image(\/(jpeg|jpg|png|svg))+;base64,/; //--> tested & works!]
  //            3) [/\"*data:image(\/(jpeg|jpg|png|svg))+;base64,/; //--> tested & works!]
  //            4) [/\'\"*data:image(\/(jpeg|jpg|png|svg))+;base64,/; // -->falty pattern]
  //            5) [/\'*\"*data:image(\/(jpeg|jpg|png|svg))+;base64,/; // -->falty pattern]
  // ---------------------------------------------------------------
  //  Note: Important!!!
  //        These methods,-
  //          JsRegExpServiceStatic.normalizeImageData (),
  //          JsRegExpServiceStatic.stripUrlForImageData (), and
  //          normalizeImageData ()
  //        can accomplish almost all the tasks that ImageProcessing will require.
  //
  // 
  // ***************************************************************
  //  Begin of Normalizing methods: Should be preferred to others
  // ***************************************************************
  // Tested & works! on 20230420
  // ---------------------------------------------------------------
  static normalizeImageDataWithRegExp (image : string) : any {
    // debugger;
    let outImageData : any = null;    

    if (!this.isNullOrEmpty(image) && image.length > 5) {
      // debugger;
      if (image.match(this.allImageTypesDataPattern3)) {
       // debugger;
        outImageData = image.replace(this.allImageTypesDataPattern3, this.imagePngReplacement);
      }
      else if (image.match(this.allImageTypesDataPattern2)) {
        // debugger;
        outImageData = image.replace(this.allImageTypesDataPattern2, this.imagePngReplacement);
      }
      else if (image.match(this.allImageTypesDataPattern1)) {
        // debugger;
        outImageData = image.replace(this.allImageTypesDataPattern1, this.imagePngReplacement);
      }
      else {
        outImageData = image;
      }
    }
    else {
      outImageData = image;
    }
    // else outImageData is already set to null
    // debugger;
    return outImageData;
  }
  // ---------------------------------------------------------------
  // Tested & works! on 20230420
  // Note: TODO:  This method name should be `normalizeImagedataWithoutUrl`
  //              Since it is used in a lot of places, care must be taken
  //              to avoid image not being rendered!!! 
  //              noted on : 20230605
  // ---------------------------------------------------------------
  public static normalizeImageData (image : string) : any {
    let outImage = '';
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image) && image.length > 5) {
      // debugger;
      outImage = this.normalizeImageDataWithRegExp(image);

    }
    // debugger;
    return outImage;
  }
  // ---------------------------------------------------------------
  //  Tested & works!: 20230605
  // ---------------------------------------------------------------
  public static normalizeImageDataWithUrl (image : string) : any {
    let outImage = image;
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image) && image.length > 5) {
      outImage = this.normalizeImageData(image);

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outImage) && outImage.length > 5) {
        outImage = this.setUrlForImageData(outImage);
        // debugger;
      }
    }
    return outImage;
  }
  // ---------------------------------------------------------------
  //  Tested & works! : 20230605
  // ---------------------------------------------------------------
  static normalizeImageFileNameWithoutUrl (imageFileName : string) : any {
    // debugger;
    let outImageFileName : any = null;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageFileName) && imageFileName.length >= 3) {
      // debugger;
      if (imageFileName.match(this.allImageTypesFilePattern)) {
        // debugger;       
        outImageFileName = imageFileName;
      }
      // else outImageFileName is already set to null
    }
    // else outImageFileName is already set to null

    return outImageFileName;
  }
  // ---------------------------------------------------------------
  // Tested: works! on 20230420
  // ---------------------------------------------------------------
  static normalizeImageFileNameWithUrl (imageFileName : string) : any {
    // debugger;
    let outImageFileName : any = null;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageFileName) && imageFileName.length >= 3) {
      // debugger;
      if (imageFileName.match(this.allImageTypesFilePattern)) {
        // debugger;
        if (!imageFileName.match(this.photoUrlPrefixPattern)) {
          // debugger;
          outImageFileName = 'url(\'' + imageFileName + '\')';
        }        
      }
      // else outImageFileName is already set to null
    }
    // else outImageFileName is already set to null

    return outImageFileName;
  }
  // ---------------------------------------------------------------
  // ***************************************************************
  //  End of Normalizing methods: Should be preferred to others
  // ***************************************************************


  // ---------------------------------------------------------------------------------
  // Note:  though it is similar to setUrlWithoutPrepending() method, 
  //        this one's name is more accurate to it's intended result that it produces
  // ---------------------------------------------------------------------------------
  static prefixUrlToImageData (image : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      if (image.indexOf('url(\'') === -1) {
        return 'url(\'' + image + '\')';
      }
      else return image;
    }
    return '';
  }
  // ---------------------------------------------------------------------------------
  //  Note: Since this method is written earlier to the normalizeRawImageData() method,
  //        and is used throught the application, it is kept here until the application is 
  //        refactored with normalizeRawImageData() method.
  // ---------------------------------------------------------------------------------
  //  Note: Tested, and works! Date ?, but prior to 20220421
  // ---------------------------------------------------------------------------------
  static prefixPngToImageData (image : string) : string {
    // debugger;
    let outImage : any;
    let tImage2 = '';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image) && image.length > 5) {
      const pattern1 = /\data:image(\/(jpeg|jpg|png|svg))+;base64,/;
      const pattern2 = /data:image\/png;base64,/;
      const tRawImage = image.replace(pattern1, '');
      // TODO: ***check to see if tImage is empty-string when no match is found?***
      // debugger;
      // tImage = tImage.toString().replace(/\'+/, '\'');//collapse multiple quotes ('+) to a single (')
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tRawImage)) {
        if (tRawImage.toString().toLowerCase().indexOf('url(\'') !== -1) {

          tImage2 = this.stripUrlForImageData(tRawImage);

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tImage2)) {
            if (!tImage2.match(pattern2)) {
              outImage = 'data:image/png;base64,' + tImage2.toString();
              // debugger;
            }
            else {
              // debugger;
              outImage = tImage2;
            }
          }
        }
        else {
          if (!tRawImage.match(pattern2)) {
            // debugger;
            outImage = 'data:image/png;base64,' + tRawImage.toString();
          }
          else {
            // debugger;
            outImage = tRawImage;
          }
        }
        return outImage;
      }
      else {
        outImage = image;
        return outImage;
      }
      // Note: the above code accomplishes the same objective as the below code:
      // -----------------------------------------------------------------------------
      // if (image.toLowerCase().indexOf('jpeg') !== -1) image.replace('jpeg', 'jpg');
      // if ((image.toLowerCase().indexOf('data:image/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/jpg/png;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/jpg/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg/png;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/jpg/png/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/jpg/svg/png;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png/jpg/svg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/png/svg/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg/png/jpg;base64,') !== -1)
      // || (image.toLowerCase().indexOf('data:image/svg/jpg/png;base64,') !== -1)
      // ){
      // //debugger;
      // return image;
      // }
    }
    else return null;
  }
  // ---------------------------------------------------------------
  //  Testing: 20230605
  // ---------------------------------------------------------------
  static replaceJpegOrSvgWithPngInRawImageData (image : string) : any {
     debugger;
    const pattern1 = /\'*data:image(\/(jpeg|jpg|png|svg))+;base64,/;
    const pattern2 = /\"*data:image(\/(jpeg|jpg|png|svg))+;base64,/;
    const allImageTypesPattern = /[\'|\"]*data:image(\/(jpeg|jpg|png|svg))+;base64,/;
    const replacement = 'data:image\/png;base64,';
    let outImageData : any = null;

    // NOTE: check/findout what '*' means in JavaScript and C# with respect to RegExp:
    // JS:  * —Matches the preceding expression 0 or more times.  // ref: https://blog.bitsrc.io/a-beginners-guide-to-regular-expressions-regex-in-javascript-9c58feb27eb4
    // C#:  * Matches the previous element zero or more times.    // ref: https://docs.microsoft.com/en-us/dotnet/standard/base-types/regular-expression-language-quick-reference

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image) && image.length > 5) {
      debugger; 
      if (allImageTypesPattern.test(image)) {
        debugger;
        outImageData = image.replace(allImageTypesPattern, replacement);
      }
      else if (pattern1.test(image)) {
          debugger;
        outImageData = image.replace(pattern1, replacement);
      }
      else if (pattern2.test(image)) {
        debugger;
        outImageData = image.replace(pattern2, replacement);
      }
      // else outImageData is already set to null
    }
    // else outImageData is already set to null
    debugger;
    return outImageData;
  }
  // ---------------------------------------------------------------------------------
  static setUrlForImageData (image : string) : string {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image) && image.length > 5) {
      if (!image.match(this.allImageTypesDataPattern3) && !image.match(this.allImageTypesFilePattern)) {
        const prependedImageData = this.prefixPngToImageData(image); // check if its an image
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(prependedImageData)) {
          // debugger;
          if (prependedImageData.indexOf('url(\'') === -1) {
            // debugger;
            return 'url(\'' + prependedImageData + '\')';
          }
          return prependedImageData;
        }
        else {
          return image;
				}
      }
      else if (image.indexOf('url(\'') === -1) 
        {
          return 'url(\'' + image + '\')';
        }
        else return image;
    }
  }
  // ---------------------------------------------------------------------------------
  static stripUrlForImageData (image : string) : any {
    let tImage2 : any;
    let tImage : any;
     const pattern1 = /url\((\')*/gi;
     const pattern2 = /(\')*\)/gi;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      /* tImage = image.replace(this.photoUrlPrefixPattern, '');*/
      tImage = image.replace(pattern1, '');
       // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tImage)) {
        /*tImage2 = tImage.replace(this.photoUrlPostfixPattern, '');*/
        tImage2 = tImage.replace(pattern2, '');
        // debugger;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tImage2)) {
        // debugger;
        return tImage2;
      }
      else {
        debugger;
        return image;
      }
    }
    else {
      debugger;
      return image;
    }
  }
  // ----------------------------------------------------------------------
  //  Note: tested & works! on 20220423
  // ----------------------------------------------------------------------
  static trimBrackets (input : string, bracketType: string) : any {
    if (!this.isNullOrEmpty(input) && input.length > 0 && !this.isNullOrEmpty(bracketType) && bracketType.length >= 1) {
      // debugger;
      switch (bracketType.toLowerCase()){
        case "{": case "}": case "curly":
          return input.replace(this.bracketCurlyPattern, "");
        case "[": case "]": case "array":
          return input.replace(this.bracketArrayPattern, "");
        case "(": case ")": case "fn":
          return input.replace(this.bracketFnPattern, "");
        default:
          return "";
      }     
    }
    else return "";
  }
  // ----------------------------------------------------------------------
  static addBrackets (input : string, bracketType : string) : any {
    if (!this.isNullOrEmpty(input) && input.length >= 0 && !this.isNullOrEmpty(bracketType) && bracketType.length >= 1) {
      // debugger;
      switch (bracketType.toLowerCase()) {
        case "{": case "}": case "curly":
          return "{" + input + "}";
        case "[": case "]": case "array":
          return "[" + input + "]";
        case "(": case ")": case "fn":
          return "(" + input + ")";
        default:
          return '';
      }
    }
    else return '';
  }
  // ----------------------------------------------------------------------
  // Note:  1: will trim the prefix \" JSON quote,
  //        2: will trim both prefix and  postfix \" JSON quote.
  //        3: will trim the postfix \" JSON quote,
  // ----------------------------------------------------------------------
  static trimQuote (input : string, num: number) : any {
    // let trimmed : any;
    // let trimmed1 : any;
    // let trimmed2 : any;
    // trimmed = input.replace(this.quotePattern, this.quoteReplacement);         // doesn't wprk      
    // trimmed1 = input.replace(this.quotedWordPattern1, this.quoteReplacement);  // doesn't wprk
    // trimmed2 = input.replace(this.quotedWordPattern2, this.quoteReplacement);  // doesn't wprk

    let trimmed3: any;
    if (!this.isNullOrEmpty(input) && input.length > 0) {
      // debugger;
      if (num == 1) {
        trimmed3 = input.replace("\"", "");
      }
      else if (num == 2) {
        trimmed3 = input.replace("\"", "");
        trimmed3 = trimmed3.replace("\"", "");
      }
      else if (num == 3) {
        trimmed3 = input.replace("\"", "\"");   // preserve the prefix
        trimmed3 = trimmed3.replace("\"", "");  // remove the postfix
      }
    }
    // debugger;
    return trimmed3;
  }
  // ----------------------------------------------------------------------
  static prepareInputForJsonParse (input : string) : any {
    let output = '';
    if (!this.isNullOrEmpty(input) && input.length > 0) {
      // debugger;
      let firstChar = input[ 0 ];
      // debugger;
      // let pattern = "{"; // this.createRegExpFromString(firstChar);
      // debugger;
      output = input.replace(/{/, "\{");
      // debugger;
      let lastChar = output[ output.length - 1 ]
      // debugger;
      // pattern = this.createRegExpFromString(lastChar);
      // debugger;
      output = output.replace(/}/, "\}");
      // debugger;
    }
    // debugger;
    return output;
  }
  // ----------------------------------------------------------------------
  //  Tested & passed on: 20220422
  //  Explanation: Steps:
  //  1) create an array of (PascalCased)Word in a given string using the
  //    `JsRegExpServiceStatic.camerCasePattern` and  RegExp's `match` function on the given-string
  //  2) iterate over the array of words and convert each item's first character tp lowerCase on the given string.
  // ----------------------------------------------------------------------
  // ---------------------------------------------------------------------------------
  // public static photoModelStringToCamelCase (str : string) : string {
  // ---------------------------------------------------------------------------------
  //  Note: this method is currently NOT in use.
  // ---------------------------------------------------------------------------------
  public static toCamelCasedPhotoModelString (input : string) : any {
    let camelCased = "";
    let kvWord = "";
    let kvWordArr : any[] = [];
    if (!this.isNullOrEmpty(input) && input.length > 0) {
      kvWordArr = input.split(',') as Array<any>;
      // debugger;
      if (!this.isNullOrEmpty(kvWordArr) && kvWordArr.length > 0) {
        camelCased = "{";
        for (var i = 0; i < kvWordArr.length; i = i + 1) {
          let word = kvWordArr[ i ];
          let parts = word.split(':');
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length === 2) {
            kvWord = this.getCamelCasedWord(parts[ 0 ], parts[ 1 ]);

            if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelCased)) {
              camelCased = kvWord;
            }
            else {
              camelCased += kvWord;
            }
            // debugger;
          }          
        }
        camelCased += "}";
      }
    }
    // debugger;
    return camelCased;
  }
  // ---------------------------------------------------------------
  // Note: to test the performace time of any function:
  // ref: https://stackoverflow.com/questions/313893/how-to-measure-time-taken-by-a-function-to-execute
  // ---------------------------------------------------------------
  static performanceTimed = (f: any) => (...args : any) => {
    let start = performance.now();
    let ret = f(...args);
    let message = `JsExpServiceStatic.Function ${ f.name } took ${ (performance.now() - start).toFixed(3) }ms`;
    // console.log(`function ${ f.name } took ${ (performance.now() - start).toFixed(3) }ms`);
    console.log(message);
    alert(message);
    return ret;
  }
  // Usage:
  //  let test = () => { /* does something */ }
  //  test = timed(test)   // turns the function into a timed function in one line
  //  test ()
  // --------------------------------------------------------------
  // Usage of performanceTimed: currently not used:
  // --------------------------------------------------------------
  static getCamelCasedWithPerformanceTimed (input : string) : any {
    let camelCased : any;
    // debugger;
    let test = () => {
      /* does something */
      camelCased = this.toCamelCasedPhotoModelString(input);
      // debugger;
      test = this.performanceTimed(test)   // turns the function into a timed function in one line
      test();
      // debugger;
      return camelCased;
    }
  }
  // --------------------------------------------------------------
}
