
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Dictionary } from 'dictionaryjs';
import { Subject } from 'rxjs';
import { PagerSupportServiceInterface } from '../../interfaces/serviceInterfaces/pagerServicesInterfaces/pagerSupportServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { PagerPhotoMgmt } from '../../models/pagination/pagerPhotoMgmt.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { SitUserService } from '../commonServiceService/sitUserService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { PhotoPrivacyService } from '../photoServiceService/photoPrivacyService.service';
import { ArrayServiceStatic } from '../staticServices/arrayServiceStatic.service';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';

// declare let jQuery: any;

// declare let $: any;

@Injectable({ providedIn: 'any' })
export class PagerSupportService implements OnDestroy, PagerSupportServiceInterface {
  // ---------------------------------------------------------------
  public currentKvPhoto : KvPhoto = new KvPhoto();
  public currentPageNum = 0;
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isStripUrl = true;
  public isViewMember = false;
  public kvPhotoArr : KvPhoto[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public pageSize = 6;
  public sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();
  // public renderer! : Renderer2;
  // ---------------------------------------------------------------
  constructor (
    public dictionaryService : DictionaryService,
    private photoPrivacyService : PhotoPrivacyService,
    private sitUserService : SitUserService,
    private router : Router,
    @Inject(DOCUMENT) document : any,
    // @Inject(Window) window
  ) {
    EmitterSubjectService.setPageSize(this.pageSize);
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }

  // ------------------------------------------------------------
  public createKvPhotoDictionaryFromKvPhotoArray (kvpD : Dictionary<number, KvPhoto>, kvpArr : KvPhoto[]) : Dictionary<number, KvPhoto> | any {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpD)) {
      kvpD = new Dictionary<number, KvPhoto>();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
      kvpArr.map(e => {
        if (e && e.photoId > 0) {
          kvpD.set(e.photoId, e);
        }
      })
      return kvpD;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  getCurrentKvPhotoByPageNumFromArr (kvPhotoArr : KvPhoto[], pageNum : number) {
    let currentPage : any;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      kvPhotoArr.forEach(e => {
        if (e.pageNum === pageNum) {
          currentPage = e;
        }
      });

    }
    return currentPage;
  }
  // ---------------------------------------------------------------
  public getCurrentKvPhotoByItemNumFromArr (kvPhotoArr : KvPhoto[], currPageItem : number = 0) : KvPhoto {
    let currentPage : any;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      kvPhotoArr.map(e => {
        if (e.itemNum == currPageItem) {
          currentPage = e;
        }
      });

    }
    return currentPage;
  }
  // ---------------------------------------------------------------
  public getCurrentKvPhotoByPhotoBnIdFromArr (kvPhotoArr : KvPhoto[], pBnId : number = 0) : KvPhoto {
    // prevent memory leak when component destroyed
    let currentPage : any;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      kvPhotoArr.map(e => {
        if (e.photoBnId == pBnId) {
          currentPage = e;
        }
      });

    }
    return currentPage;
  }

  // ---------------------------------------------------------------------------------
  public getPageNumByItemNumFromKvPhotoItemArrDictionary (pNumKvPhotoDictionary : Dictionary<number, KvPhoto[]>, kvp : KvPhoto, pageSize : number) : number {
    let pageNum = 1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pNumKvPhotoDictionary)) {
      if (pNumKvPhotoDictionary.size > 0) {
        let pagedItemsArr = pNumKvPhotoDictionary.values(); // [[]...[]]
        let keys = pNumKvPhotoDictionary.keys();
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp) && kvp.itemNum >= 0 && pageSize > 0) {
          let iDiv = Math.floor(kvp.itemNum / pageSize);
          if (iDiv >= 0 && iDiv < 1) {
            pageNum = 1;
            // debugger;
          }
          else {
            // debugger;
            let pagedItems = pNumKvPhotoDictionary.get(iDiv + 1) as KvPhoto[];
            if (kvp.itemNum > 0 && (pagedItems[ kvp.itemNum ] as KvPhoto).itemNum > 0) {
              pageNum = iDiv + 1;
            }
            else {
              pagedItems.map(ikvp => {
                if (kvp.itemNum > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ikvp) && (ikvp as KvPhoto).itemNum >= 0) {
                  if ((ikvp as KvPhoto).itemNum === kvp.itemNum) {
                    pageNum = iDiv + 1;
                    // debugger;
                  }
                }
              })
            }
          }
        }
      }
    }
    // debugger;
    return pageNum;
  }
  // ---------------------------------------------------------------------------------
  //public getPageNumFromKvPhotoItemNum (pgrVPhotoNav : PagerPhotoMgmt, kvp : KvPhoto) : number {

  //  let pageNum = 1;

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pgrVPhotoNav)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pgrVPhotoNav.kvPhotoArr)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pgrVPhotoNav.kvPhotoArr)
  //    && pgrVPhotoNav.kvPhotoArr.length > 0 && pgrVPhotoNav.pageSize > 0
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)
  //    && kvp.itemNum > 0
  //  ) {
  //    // debugger;
  //    if (pgrVPhotoNav.pageSize <= 0) {
  //      pgrVPhotoNav.pageSize = this.pageSize;
  //    }

  //    let iNum = 0;
  //    pgrVPhotoNav.pages.forEach(p => {
  //      pageNum = p;
  //      let pagedItems = pgrVPhotoNav.kvPhotoArr.slice(iNum, pageNum * pgrVPhotoNav.pageSize) as []
  //      DictionaryServiceStatic.kvPhotoDictionary.setValue(pageNum, pagedItems);
  //      iNum = iNum + pgrVPhotoNav.pageSize;
  //    });

  //    pageNum = this.getPageNumByItemNumFromKvPhotoItemArrDictionary(DictionaryServiceStatic.kvPhotoDictionary, kvp, this.pageSize);
  //  }
  //  // debugger;
  //  return pageNum;
  //}  
  // ----------------------------------------------------------------
  // public getPagerVKvPhotoNavWithoutUrl (pagerVKvPhotoNav : PagerPhotoMgmt) : KvPhoto[] | any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems) && pagerVKvPhotoNav.pagedItems.length > 0) {
  //    return DisplayProfileServiceStatic.prepareKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems, this.isStripUrl);
  //  }
  //  else return null;
  // }
  // ----------------------------------------------------------------
  // Note: This method has been replaced with PrepareKvPhotoForDisplay()
  //       and it is in DisplayProfileServiceStatic.
  // ----------------------------------------------------------------
  // public getKvPhotoWithoutUrl (kvPhoto : KvPhoto) : any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
  //    // debugger;
  //    kvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(kvPhoto.value);
  //    return kvPhoto.value;
  //  }
  // }
  // ----------------------------------------------------------------
  // Note: This method has been replaced with PrepareKvPhotoArrForDisplay()
  //       and it is in DisplayProfileServiceStatic.
  // ----------------------------------------------------------------
  // public getPagedItemsWithoutUrl (pagedItems : KvPhoto[]) : KvPhoto[] | any {
  //  // this.pagerVKvPhotoNav.pagedItems.map(e => {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagedItems) && pagedItems.length > 0) {
  //    pagedItems.forEach(e => {
  //      e.value = this.getKvPhotoWithoutUrl(e);
  //    })
  //  }
  //  return pagedItems;
  // }
  // ============================================================
  //  Note: KvPhotoDictionary related functions:
  // ------------------------------------------------------------
  public getPrimaryKvPhotoFromDictionary (sitUser? : SitUser|any, photoId?: number|any) : KvPhoto | any {
    let primaryKvPhoto : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoId) && photoId > 0) {
      primaryKvPhoto = this.dictionaryService.kvPhotoDictionary.get(photoId);
    }    
    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0 && sitUser.primaryPhotoBnId > 0) {
      let kvpArr = this.dictionaryService.kvPhotoDictionary.size > 0 ? this.dictionaryService.kvPhotoDictionary.values() : [];
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
        kvpArr.forEach((e : any) => {
          if (e.photoBnId == sitUser.primaryPhotoBnId) {
            primaryKvPhoto = e;
          }
        });
      }
      return primaryKvPhoto
    }
    else return null;
  }
  // ------------------------------------------------------------
  public getPrimaryKvPhotoForSitUser (sitUser : SitUser, kvPhotoArr : KvPhoto[]) : KvPhoto | any {
    let primaryKvPhoto = new KvPhoto();
    let index = -1;
    if (sitUser.sitUserId > 0) {
      if (sitUser.sitUserId === this.loginSuccess.signedInUserId && this.loginSuccess.primaryPhotoBnId > 0) {
        index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr((kvPhotoArr as any), this.loginSuccess.primaryPhotoBnId);
        if (index === -1) {
          // debugger;
          primaryKvPhoto = CopyServiceStatic.copyFromLoginSuccessToKvPhoto(this.loginSuccess);
          // primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(primaryKvPhoto.value);
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.value)) {
            primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageData(primaryKvPhoto.value);
          }
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.value)) {
            primaryKvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(primaryKvPhoto.value);
          }
          primaryKvPhoto.index = primaryKvPhoto.itemNum = 0;
        }
        else {
          // debugger;
          primaryKvPhoto = kvPhotoArr[ index ] as KvPhoto;
        }
        return primaryKvPhoto;
      }
      else if (sitUser.sitUserId !== this.loginSuccess.signedInUserId && sitUser.primaryPhotoBnId > 0) {
        index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr((kvPhotoArr as any), sitUser.primaryPhotoBnId);
        if (index === -1) {
          // debugger;
          primaryKvPhoto = CopyServiceStatic.copySitUserToKvPhoto(sitUser);
          // primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(primaryKvPhoto.value);
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.value)) {
            primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageData(primaryKvPhoto.value);
          }
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.value)) {
            primaryKvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(primaryKvPhoto.value);
          }
          primaryKvPhoto.index = primaryKvPhoto.itemNum = 0;
        }
        else {
          // debugger;
          primaryKvPhoto = kvPhotoArr[ index ] as KvPhoto;
        }
        return primaryKvPhoto;
      }
      else return null;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  // This method normalizes and image's raw data for displating on
  // an html element. It can be prefixed with a 'url()' around the
  // image-data, or can strip the 'url()' from the image-data.
  //
  // Note: this method has been moved to DisplayProfileServiceStatic().
  // ---------------------------------------------------------------
  //public prepareKvPhotoArrForDisplay (kvPhotoArr : KvPhoto[]) : KvPhoto[] | any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
  //    kvPhotoArr.forEach(e => {
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) && e.value.length > 0 && JsRegExpServiceStatic.photoUrlPrefixPattern.test(e.value)) {
  //        e.value = JsRegExpServiceStatic.stripUrlForImageData(e.value);
  //      }
  //    });

  //  }
  //  return kvPhotoArr;
  //}
  // ---------------------------------------------------------------
  //  this method will fill in the following properties of kvp:
  //  1. index  2.pagenum 3.elemId
  // -------------------------------------------------------------- 
  public setIndexAndPageNumAndElemIdForKvPhotoArr (kvPhotoArr : KvPhoto[], idPrefix : string) : KvPhoto[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      var i = 0;
      for (i; i < kvPhotoArr.length; i++) {
        kvPhotoArr[ i ].index = i;
        kvPhotoArr[ i ].elemId = idPrefix + i;
        // debugger;
        // Note:  do not overwrite the key.
        //        If needed, uncomment it here for appropriate key:
        // --------------------------------------------------------
        // kvPhotoArr[ i ].key = kvPhotoArr[ i ].photoBnId.toString();
      }
    }
    // debugger;
    return kvPhotoArr;
  }
  // --------------------------------------------------------------
  public setIndexAndPageNumAndElemIdForPagerPhotoMgmt (pgrVPhotoNav : PagerPhotoMgmt, idPrefix : string) : PagerPhotoMgmt {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pgrVPhotoNav.kvPhotoArr) && pgrVPhotoNav.kvPhotoArr.length > 0) {
      pgrVPhotoNav.kvPhotoArr = this.setIndexAndPageNumAndElemIdForKvPhotoArr(pgrVPhotoNav.kvPhotoArr, idPrefix);
    }
    return pgrVPhotoNav;
  }
  // ============================================================
  //  Note: KvPhotoArray related functions:
  // ------------------------------------------------------------
  //  This is a general/generic KvPhotoArr's method
  //  to update with PrimaryKvPhoto if missing.
  // ------------------------------------------------------------
  // This method will obtain the PrimaryKvPhoto using sitUser,
  // and if necessary, by getting it from the backend-server.
  // ------------------------------------------------------------
  public updateKvPhotoArr (kvPhotoArr : KvPhoto[], sitUserId : number) : KvPhoto[] | any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {      
      let i = 0;
      let index = -1;
      let sitUser = new SitUser();
      let primaryKvPhoto = new KvPhoto();

      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      // get Emitter-sitUser's primaryKvPhoto:
      // -------------------------------------
      sitUser = EmitterSubjectService.getSitUserModel();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0
        && sitUser.sitUserId === sitUserId
        && sitUser.primaryPhotoBnId > 0) {
        // debugger;
        primaryKvPhoto = this.getPrimaryKvPhotoForSitUser(sitUser, kvPhotoArr);
      }

      // if emitter-situser's primaryKvPhoto is null or empty.
      // get it from the dictionary-sitUser's primaryKvPhoto
      // ---------------------------------------------------
      if (sitUserId > 0) {
        sitUser = this.dictionaryService.sitUserDictionary.get(sitUserId);
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) || sitUser.sitUserId === 0) {
          debugger;
          console.log('calling fetchSitUser() from pagerSupportService@ln367')
          this.sitUserService.getSitUserFromDictionaryOrServer(sitUserId).subscribe(data => {
            sitUser = data;
            debugger;
          })
        }
        
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0 && sitUser.primaryPhotoBnId > 0) {
          primaryKvPhoto = this.getPrimaryKvPhotoForSitUser(sitUser, kvPhotoArr);
        }


        // if dictionary-situser's primaryKvPhoto is null or empty.
        // get it from the sitUser from the backend-server
        // and then get it's primaryKvPhoto
        // ---------------------------------------------------        
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto) || primaryKvPhoto.sitUserId === 0) {          
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0 && sitUser.primaryPhotoBnId > 0) {
            primaryKvPhoto = this.getPrimaryKvPhotoForSitUser(sitUser, kvPhotoArr);
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto) && primaryKvPhoto.sitUserId > 0) {
              return this.updateKvArrForPrimaryKvPhoto(primaryKvPhoto, kvPhotoArr); // must be used step!!
            }
          }        
        }
        else {
          return this.updateKvArrForPrimaryKvPhoto(primaryKvPhoto, kvPhotoArr); // must be used step!!
        }
      }
    }
    return kvPhotoArr;
  }
  // ============================================================
  //  Note: KvPhotoDictionary related functions:
  // ------------------------------------------------------------
  // ------------------------------------------------------------
  //  this method gets the kvPhoto of PrimaryImage from KvPhotoDictionary
  //  then sets this in KvPhotoArrays if missing, 
  //  and then updates the kvPhotoDictionaries of profilePics.
  //
  //  Not in use:
  // ------------------------------------------------------------
  // public updateKvPhotoArraysAndDictionariesOfProfilePics (pPics : ProfilePics , sitUser : SitUser) : any /* Promise<ProfilePics | any>*/ {
  //  let kvPhotoArr: KvPhoto[] = [];
  //  let filteredKvPhotoArr : any;
  //  let primaryKvPhoto : any;
  //  let fkvp : any;
  //  // debugger;
    
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr)
  //    && pPics.unFilteredKvPhotoArr.length > 0) {
  //    kvPhotoArr = pPics.unFilteredKvPhotoArr;
  //    kvPhotoArr.sort((a, b) => {
  //      return a.photoBnId - b.photoBnId;
  //    });
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0) {
  //      primaryKvPhoto = this.getPrimaryKvPhotoForSitUser(sitUser, kvPhotoArr);
  //    }
  //    // ----------------------------
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto) && primaryKvPhoto.sitUserId > 0) {
  //      // debugger;
  //      kvPhotoArr = this.updateKvArrForPrimaryKvPhoto(primaryKvPhoto, kvPhotoArr) as KvPhoto[]; // must be used step!!

  //    }
  //    // update dictionaries and Arrays with updated-kvPhotoArr:
  //    // -----------------------------------------------------------
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {        
        
  //      kvPhotoArr.forEach(e => {
  //        pPics.unFilteredKvPhotoArr.push(e);

  //        pPics.filteredKvPhotoArr.map(f => {
  //          if (f.photoBnId === e.photoBnId) {
  //            f.elemId = e.elemId;
  //            pPics.filteredKvPhotoArr.push(f);
  //          }
  //        })
  //      });
        
  //      // debugger;
  //      return pPics;
  //    }
  //    else {
  //      // debugger;
  //      return null;
  //    }       
  //  }
  //}
  // ------------------------------------------------------------
  //  get the kvPhoto of PrimaryImage from DictionaryServiceStatic.kvPhotoDictionary
  //  and set this in the provided KvPhotoDictionary in the parameter.
  // ------------------------------------------------------------
  public updateKvPhotoDictionary (kvpD : Dictionary<number, KvPhoto>, sitUser : SitUser, isFiltered : boolean) : Dictionary<number, KvPhoto> | any {
    let kvPhotoArr = [];
    let filteredKvPhotoArr : any;
    let primaryKvPhoto : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)) {
      primaryKvPhoto = this.getPrimaryKvPhotoFromDictionary(sitUser);
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto) && primaryKvPhoto.sitUserId > 0) {
      kvPhotoArr = this.dictionaryService.getUserKvPhotoArrFromKvPhotoDictionary(sitUser.sitUserId)
      kvPhotoArr = this.updateKvArrForPrimaryKvPhoto(primaryKvPhoto, kvPhotoArr);
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpD)) {
        if (isFiltered) {
          // TODO: temporary Unsage solution:
          // --------------------------------
          kvpD = this.createKvPhotoDictionaryFromKvPhotoArray(kvpD, filteredKvPhotoArr);

          // TODO: check the filtration process. It is failing now!!!!
          // ----------------------------------------------------------
          // this.photoPrivacyService.filterKvPhotoArr(kvPhotoArr).then(result => {
          //  if (result) {
          //    filteredKvPhotoArr = result;
          //    debugger;
          //    kvpD = this.createKvPhotoDictionaryFromKvPhotoArray(kvpD, filteredKvPhotoArr);
          //    return kvpD;
          //  }
          //  else {
          //    console.log(result);
          //    return null;
          //  }
          // });
        }
        else {
          debugger;
          kvpD = this.createKvPhotoDictionaryFromKvPhotoArray(kvpD, kvPhotoArr);
          return kvpD;
        }
      }
      else return null;
    }
    else return null;
  }
  // ----------------------------------------------------------------------
  // Note: This must be used for filtered/unFiltered array beofe rendering:
  // ----------------------------------------------------------------------
  public updateKvArrForPrimaryKvPhoto (primaryKvPhoto : KvPhoto, kvPhotoArr : KvPhoto[]) : KvPhoto[] | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto) && primaryKvPhoto.index >= 0 && primaryKvPhoto.photoBnId > 0) {
        primaryKvPhoto.isPrimary = true;
        // primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageData(primaryKvPhoto.value); // will add 'url(...)' to image-data
        primaryKvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(primaryKvPhoto.value);

        let index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr((kvPhotoArr as any), primaryKvPhoto.photoBnId);
       
        if (index > -1) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr[ index ])) {
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr[ index ].value)) {
              kvPhotoArr[ index ] = kvPhotoArr[ index ].copyIfExists(primaryKvPhoto);
              // debugger;
            }
            else {
              kvPhotoArr[ index ] = kvPhotoArr[ index ].copy(primaryKvPhoto);
              // debugger;
            }
          }
        }
        else {
          // debugger;
          kvPhotoArr.push(primaryKvPhoto);
        }
      }

      kvPhotoArr = ArraySupportServiceStatic.makeArrayOfUniqElements(kvPhotoArr);

      // debugger;
      kvPhotoArr = this.setIndexAndPageNumAndElemIdForKvPhotoArr(kvPhotoArr, "pagerVKvPhotoNav-");
      kvPhotoArr.sort((a, b) => {
        return a.elemId.localeCompare(b.elemId)
      });
      // debugger;
      kvPhotoArr = ArraySupportServiceStatic.mergeArraysUniq(kvPhotoArr, kvPhotoArr);
      // debugger;
      return kvPhotoArr;
    }
    else return kvPhotoArr;
  }
  // -----------------------------------------------------------------------
  // Note: this will update KvPhotoArr of both filtered and unFiltered type
  // -----------------------------------------------------------------------
  public updateProfilePicsArraysWithPrimaryKvPhotoIfMissing (pPics : ProfilePics) : ProfilePics {
    let tKvPhotoArr : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr) && pPics.filteredKvPhotoArr.length > 0) {
        tKvPhotoArr = this.updateKvPhotoArr(pPics.filteredKvPhotoArr, pPics.sitUserId);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tKvPhotoArr) && tKvPhotoArr.length > 0) {
          pPics.filteredKvPhotoArr = tKvPhotoArr;
        }
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr) && pPics.unFilteredKvPhotoArr.length > 0) {
        tKvPhotoArr = this.updateKvPhotoArr(pPics.unFilteredKvPhotoArr, pPics.sitUserId);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tKvPhotoArr) && tKvPhotoArr.length > 0) {
          pPics.unFilteredKvPhotoArr = tKvPhotoArr;
        }
      }
    }
    return pPics;
  }
  // ----------------------------------------------------------------
  // Note:  this will update the kvPhotoArr(s) and KvPhotoDictionary(s)
  //        on the basis of the values of isPhotoMgmt and isViewMember.
  //
  //        This method will update KvPhotoDictionary and
  //        the KvPhotoArr (filtered /unfiltered) pf ProfilePics
  //        with the primaryKvPhoto.
  // ----------------------------------------------------------------
  public updatePrimaryKvPhotoIfMissingOnProfilePics (pPics : ProfilePics) : ProfilePics {
    this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
    this.isMyProfile = EmitterSubjectService.getIsMyProfile();
    this.isViewMember = EmitterSubjectService.getIsViewMember();
    let isFiltered = false;
    let fkvpD: any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId > 0) {
      let sitUser = this.dictionaryService.sitUserDictionary.get(pPics.sitUserId) as SitUser;

      if (this.isPhotoMgmt || this.isMyProfile) {
        isFiltered = false;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoDictionary) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)) {
          fkvpD = this.updateKvPhotoDictionary(pPics.unFilteredKvPhotoDictionary, sitUser, isFiltered);
          if (fkvpD) {
            pPics.unFilteredKvPhotoDictionary = fkvpD;
          }
          // debugger;
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr) && pPics.unFilteredKvPhotoArr.length > 0) {
          pPics.unFilteredKvPhotoArr = this.updateKvPhotoArr(pPics.unFilteredKvPhotoArr, pPics.sitUserId);
          // debugger;
        }
      }
      else if (this.isViewMember) {
        isFiltered = true;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoDictionary) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)) {
          fkvpD = this.updateKvPhotoDictionary(pPics.filteredKvPhotoDictionary, sitUser, isFiltered);
          if (fkvpD) {

            pPics.filteredKvPhotoDictionary = fkvpD;
          }
          // debugger;
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr) && pPics.filteredKvPhotoArr.length > 0) {
          pPics.filteredKvPhotoArr = this.updateKvPhotoArr(pPics.filteredKvPhotoArr, pPics.sitUserId);
        }
      }
    }
    return pPics;
  }  
  // ------------------------------------------------------------
}


  // ================================================================
  // Deprecated methods:
  // ----------------------------------------------------------------
  // TODO: check why primaryKvPhoto.value == '';
  // TODO: Test!
  // Deprecated: 2022/06/12
  // ----------------------------------------------------------------
  // getPrimaryKvPhoto (sitUser : SitUser, pPics : ProfilePics) : any {
  //  let primaryKvPhoto : KvPhoto = new KvPhoto();
  //  let index = -1;

  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();

  //  // get the primaryKvPhoto for singedInUser:
  //  // ----------------------------------------
  //  if (pPics.sitUserId === this.loginSuccess.signedInUserId
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr)
  //    && pPics.unFilteredKvPhotoArr.length > 0) {

  //    index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr((pPics.unFilteredKvPhotoArr as any), this.loginSuccess.primaryPhotoBnId);
  //    if (index === -1) {
  //       debugger;
  //      primaryKvPhoto = CopyServiceStatic.copyFromLoginSuccessToKvPhoto(this.loginSuccess);
  //      // primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(primaryKvPhoto.value); // TODO: this may return an empty value, so check!
  //      primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageData(primaryKvPhoto.value); 
  //      primaryKvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(primaryKvPhoto.value);
  //      primaryKvPhoto.index = primaryKvPhoto.itemNum = 0;
  //    }
  //    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr[ index ])
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr[ index ].value)) {
  //       debugger;
  //      primaryKvPhoto = pPics.unFilteredKvPhotoArr[ index ] as KvPhoto;
  //    }
  //  }

  //  // get the primaryKvPhoto for the non-signedInUser:
  //  // ------------------------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)
  //    && sitUser.sitUserId > 0
  //    && sitUser.sitUserId !== this.loginSuccess.signedInUserId
  //    && pPics.sitUserId === sitUser.sitUserId) {

  //    index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr((pPics.filteredKvPhotoArr as any), sitUser.primaryPhotoBnId);

  //    if (index === -1) {
  //       debugger;
  //      primaryKvPhoto = CopyServiceStatic.copySitUserToKvPhoto(sitUser);
  //      primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(primaryKvPhoto.value);
  //      primaryKvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(primaryKvPhoto.value);
  //      primaryKvPhoto.index = primaryKvPhoto.itemNum = 0;
  //    }
  //    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr[ index ])
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr[ index ].value)) {
  //       debugger;
  //      primaryKvPhoto = pPics.filteredKvPhotoArr[ index ] as KvPhoto;
  //    }
  //  }
  //  debugger;
  //  return primaryKvPhoto;
  // }
  // ------------------------------------------------------------
  // TODO: test!
  // ------------------------------------------------------------
  //  Deprecated! 2022/06/12
  // ------------------------------------------------------------
  // updateProfilePicsKvPhotoArr (pPics : ProfilePics) : ProfilePics {
  //  // debugger; 
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId > 0) {
  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    let index = -1;
  //    let i = 0;
  //    let primaryKvPhoto : any = new KvPhoto();
  //    let result : any;

  //    // get the sitUser from the dictionary, or fetch sitUser:
  //    // 20220604
  //    // ------------------------------------------------------
  //    let tSitUser = DictionaryServiceStatic.sitUserDictionary.getValue(pPics.sitUserId) as SitUser;

  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tSitUser) || tSitUser.sitUserId === 0) {

  //      // if the dictionary sitUser is empty or sitUserId == 0, get it from the Emitter: 
  //      // (should check for both signedInUser and non-signedInUser)
  //      // ------------------------------------------------------------------------------
  //      if (pPics.sitUserId === this.loginSuccess.signedInUserId) {
  //        tSitUser = EmitterSubjectService.getSignedInUser();
  //      }
  //      else {
  //        tSitUser = EmitterSubjectService.getSitUserModel();
  //      }

  //      // debugger;
  //      // if the Emitter's sitUser is empty or sitUserId == 0, get it from the backend-server:
  //      // -------------------------------------------------------------------------------------
  //      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tSitUser) || tSitUser.sitUserId === 0) {
  //        this.sitUserService.fetchSitUser(pPics.sitUserId).then(data => {
  //          tSitUser = data;
  //          debugger;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tSitUser) && tSitUser.sitUserId > 0 && tSitUser.primaryPhotoBnId > 0) {
  //            debugger;
  //            primaryKvPhoto = this.getPrimaryKvPhoto(tSitUser, pPics); 
  //          }
  //        });
  //      }
  //      // Emitter's sitUser is found:
  //    // -----------------------------
  //      else if (tSitUser.primaryPhotoBnId > 0) {
  //        debugger;
  //        primaryKvPhoto = this.getPrimaryKvPhoto(tSitUser, pPics); 
  //      }
  //    }
  //    // dictionary's sitUser is found:
  //    // ------------------------------
  //    else if(tSitUser.primaryPhotoBnId > 0) {
  //      debugger;
  //      primaryKvPhoto = this.getPrimaryKvPhoto(tSitUser, pPics); 
  //    }
  //    debugger;

  //    let tProfilePics : any;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto)
  //      && primaryKvPhoto.sitUserId > 0
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.value)) {
  //      debugger;
  //      tProfilePics = this.updateProfilePicsWithPrimaryKvPhoto(pPics, primaryKvPhoto);
  //    }
      
  //    debugger;
  //    return tProfilePics;
  //  }
  //  // debugger; // watch-
  //  else return pPics;
  // }
  
  // ----------------------------------------------------------------
  //  Deprecated! 2022/06/12
  // ------------------------------------------------------------
  // updateProfilePicsWithPrimaryKvPhoto (pPics : ProfilePics, pkvPhoto : KvPhoto) : any {
  //  let primaryKvPhoto = pkvPhoto; 
  //  let index = -1;

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(primaryKvPhoto.value)
  //    && primaryKvPhoto.index >= 0
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)) {
  //      // primaryKvPhoto.elemId = 'pagerVKvPhotoNav-' + primaryKvPhoto.index;
  //      primaryKvPhoto.isPrimary = true;
  //      primaryKvPhoto.value = JsRegExpServiceStatic.normalizeImageData(primaryKvPhoto.value); // will add 'url(...)' to image-data
  //      primaryKvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(primaryKvPhoto.value);

  //    if (this.isPhotoMgmt) {
  //      index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr(pPics.unFilteredKvPhotoArr, primaryKvPhoto.photoBnId);
  //      if (index > -1 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr[ index ])) {
  //        pPics.unFilteredKvPhotoArr[ index ] = pPics.unFilteredKvPhotoArr[ index ].copyIfExists(primaryKvPhoto);
  //        // debugger;
  //      }
  //      else {
  //        // debugger;
  //        pPics.unFilteredKvPhotoArr.push(primaryKvPhoto);
  //      }
  //    }
  //    if (this.isViewMember) {
  //      index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdInKvPhotoArr(pPics.filteredKvPhotoArr, primaryKvPhoto.photoBnId);
  //      if (index > -1 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr[ index ])) {
  //        pPics.filteredKvPhotoArr[ index ] = pPics.filteredKvPhotoArr[ index ].copyIfExists(primaryKvPhoto);
  //      }
  //      else {
  //        pPics.filteredKvPhotoArr.push(primaryKvPhoto);
  //      }
  //    }
  //  }

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)) {
  //    pPics.filteredKvPhotoArr = ArraySupportServiceStatic.makeUniq(pPics.filteredKvPhotoArr);
  //    pPics.unFilteredKvPhotoArr = ArraySupportServiceStatic.makeUniq(pPics.unFilteredKvPhotoArr);

  //    // debugger;
  //    pPics.filteredKvPhotoArr = this.setIndexAndPageNumAndElemIdForKvPhotoArr(pPics.filteredKvPhotoArr, "pagerVKvPhotoNav-");
  //    pPics.filteredKvPhotoArr.sort((a, b) => {
  //      return a.elemId.localeCompare(b.elemId)
  //    });

  //    pPics.unFilteredKvPhotoArr = this.setIndexAndPageNumAndElemIdForKvPhotoArr(pPics.unFilteredKvPhotoArr, "pagerVKvPhotoNav-");
  //    pPics.unFilteredKvPhotoArr.sort((a, b) => {
  //      return a.elemId.localeCompare(b.elemId)
  //    });
  //  }
  //  // debugger; // watch-
  //  return pPics;
  // }
  // ----------------------------------------------------------------
  // Deprecated:
  // -------------------------------------------------------------- 
  // setIndexAndPageNumAndElemId (kvPhotoArr : KvPhoto[], idPrefix : string) : KvPhoto[]  {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
  //    var i = 0;
  //    for (i; i < kvPhotoArr.length; i++) {
  //      kvPhotoArr[ i ].index = i;
  //      kvPhotoArr[ i ].itemNum = i;
  //      kvPhotoArr[ i ].pageNum = i < this.pageSize ? 1 : Math.floor(i / this.pageSize) + 1; // this.getPageNumFromKvPhotoItemNum(pgrVPhotoNav, pgrVPhotoNav.kvPhotoArr[ i ]);
  //      kvPhotoArr[ i ].elemId = idPrefix + i;
  //      kvPhotoArr[ i ].key = kvPhotoArr[ i ].photoBnId.toString();
  //    }
  //  }
  //  return kvPhotoArr;
  // }
  // ----------------------------------------------------------------
  // This has been moved to BIAS service
  // ----------------------------------------------------------------
  // selectCurrentElemDeselctOthers (kvpArr : KvPhoto[], item : KvPhoto) : void {
  //  let currentPageElemId = '';
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item.elemId)) {
  //    if (item.itemNum >= 0) {
  //      // debugger;
  //      currentPageElemId = 'pagerVPhotoNav-' + item.itemNum.toString();
  //    }    
  //    else {
  //      // debugger;
  //      currentPageElemId = 'pagerVPhotoNav-0';
  //    }
  //  }

  //  let currentItemElem : any;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentPageElemId)) {
  //    currentItemElem = document.getElementById(currentPageElemId) as HTMLElement;
  //  }

  //  // debugger;
  //  // highlight PrimaryImage in deepskyblue color:
  //  // --------------------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentItemElem)) {
  //    // debugger;
  //    // this.bias.removeClassForElement(currentItemElem, 'smallerCircle');
  //    this.bias.addClassForElement(currentItemElem, 'smallerPrimary');
  //  }
  //  // debugger;
  //  // remove highlight active-Image in array in gold color:
  //  // ----------------------------------------------
  //  let elemId = 'pagerVPhotoNav-';
  //  let tempElemIdStr = '';
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
  //    for (let i = 0; i < kvpArr.length; i++) {
  //      if (kvpArr[ i ].photoBnId !== item.photoBnId) {
  //        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr[ i ].elemId)) {
  //          tempElemIdStr = elemId + kvpArr[ i ].itemNum.toString();
  //        }
  //        else {
  //          tempElemIdStr = kvpArr[ i ].elemId;
  //        }
  //        let tempElem = document.getElementById(tempElemIdStr) as HTMLElement;
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
  //          // this.bias.removeClassForElement(tempElem, 'smallerPrimary');
  //          this.bias.addClassForElement(tempElem, 'smallerCircle');
  //          // debugger;
  //          break;
  //        }
  //      }
  //    }
  //  }
  //  // will draw the body-bg with item.value:
  //  // --------------------------------------
  //  // EmitterSubjectService.emitCurrentPage(item);
  // }
  // ---------------------------------------------------------------------------------
  //  Note: This has been moved to GlyphiconMenuService
  //
  //  Note :  this method does not check whether it is a isPhotoMgmt or isViewMember.
  //          the caller should check/filter and call this only in case of isPhotoMgmt==true.
  // ---------------------------------------------------------------------------------
  // getPagerVForCurrentPage (input : any) : any {
  //  // Note:  IMPORTANT! it may be required that either photoId or PhotoBnId will be used but NOT both
  //  //        and the decision will depend on application wide whether Photo or PhotoBn will be used.
  //  // ----------------------------------------------------------------------------------------------
  //  if (input instanceof KvPhoto)
  //  {
  //    // debugger;
  //    let kvPhoto = input as KvPhoto;
  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(input) || kvPhoto.sitUserId === 0 || kvPhoto.photoId === 0 || kvPhoto.photoBnId == 0) {
  //      this.currentPageGlyph = EmitterSubjectService.getCurrentPage();
  //       // debugger;
  //
  //      // if currentPageGlyph is still not complete, create it from currentPhoto or currentKvPhoto
  //      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph.value)) {
  //        this.currentPhoto = EmitterSubjectService.getCurrentPhoto();
  //        // debugger;
  //
  //        //  if currentPhoto data exists:
  //        // -----------------------------
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPhoto) && this.currentPhoto.photoId > 0) {
  //          this.currentPageGlyph.photoId = this.currentPhoto.photoId;
  //          this.currentKvPhoto = CopyServiceStatic.copyFromPhotoToKvPhoto(this.currentPhoto);
  //          EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //          // debugger;
  //        }
  //        //  create currentKvPhoto from loginSuccess and kvPhoto:
  //        // -----------------------------------------------------
  //        else {
  //          this.currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPhoto)) {
  //            this.currentKvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
  //          }
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPhoto)) {
  //            this.currentKvPhoto = CopyServiceStatic.copyKvPhotoIfExists(this.currentKvPhoto, kvPhoto);
  //            this.currentPageGlyph.photoId = this.currentKvPhoto.photoId;
  //          }
  //        }
  //
  //        // create the currentPageGlyph:
  //        // ----------------------------
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto) && this.currentKvPhoto.photoId > 0) {
  //          this.currentPageGlyph = CopyServiceStatic.copyFromKvPhotoToKvGlyph(this.currentKvPhoto);
  //          // debugger;
  //        }
  //      }
  //      // Note : currentKvPhoto does not have a pageNum, so we first create the pagination, and then get its number:
  //      // ----------------------------------------------------------------------------------------------------------
  //      if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph) && this.currentPageGlyph.pageNum <= 0)
  //        || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto) && this.currentKvPhoto.itemNum <= 0)) {
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.glyphiconMenuService)) {
  //          this.pagerV = this.glyphiconMenuService.setKvGlyphPager(1);
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerV)) {
  //            this.pagerV.kvGlyphsArr.map(e => {
  //              // debugger;
  //              if (e.photoId === this.currentKvPhoto.photoId && e.pageNum > 0) {
  //                this.currentKvPhoto.itemNum = e.pageNum;
  //                this.currentPageGlyph.pageNum = e.pageNum;
  //                this.currentPageGlyph.photoId = e.photoId;
  //                EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //                EmitterSubjectService.setCurrentPage(this.currentPageGlyph);
  //                // debugger;
  //              }
  //            });
  //          }
  //        }
  //      }
  //      else {
  //        EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //        EmitterSubjectService.setCurrentPage(this.currentPageGlyph);
  //        // debugger;
  //      }
  //    }
  //    else {
  //      // debugger;
  //      if (this.currentKvPhoto.photoId === 0) {
  //        this.currentKvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
  //        this.currentKvPhoto = CopyServiceStatic.copyKvPhotoIfExists(this.currentKvPhoto, kvPhoto);
  //      }
  //      this.glyphKvArr = this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvPhoto(this.currentKvPhoto);
  //      // debugger; // TODO: watch for primary-photo-case
  //      this.glyphiconMenuService.setGlyphs(this.glyphKvArr);
  //    }
  //  }
  //  else if (input instanceof KvMemberActivityGlyph) {
  //    // debugger;
  //    this.currentPageGlyph = input as KvMemberActivityGlyph;
  //  }
  //
  //  // render the glyphicon-menu for the currentPageGlyph:
  //  // ----------------------------------------------
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph.value)
  //    && this.currentPageGlyph.value.length > 0) {
  //    // debugger;
  //
  //    // var returnUrl = window.location.pathname + window.location.search;
  //
  //    // debugger;
  //    // will display the glyphs
  //    this.glyphKvArr = this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvGlyph(this.currentPageGlyph);
  //    // debugger;
  //
  //    // Note : The View does not require to programatically draw the images, just setting the glyph/image name will suffice
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.glyphKvArr) && this.glyphKvArr.length > 0) {
  //      // debugger;
  //      this.glyphiconMenuService.executeGlyphMenuArr(this.glyphKvArr);
  //      // debugger;
  //    }
  //  }
  //
  //  if (this.currentPageGlyph.pageNum > 0) {
  //    // debugger;
  //    this.pagerV = this.glyphiconMenuService.setKvGlyphPager(this.currentPageGlyph.pageNum); // current-case
  //  }
  //  else {
  //    // debugger;
  //    this.pagerV = this.glyphiconMenuService.setKvGlyphPager(1);  // default-case
  //  }
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerV)) {
  //    this.pagerV.isPhotoMgmt = true;
  //    // debugger;
  //  }
  //  EmitterSubjectService.emitRunNgAfterViewInit(true);
  //  return this.pagerV;
  // }
  // ---------------------------------------------------------------------------------
  //  Note: this has been moved to GlyphiconMenuService
  // ---------------------------------------------------------------------------------
  //executeGlyphMenuArr (kvAnyArr : KvMemberActivityGlyph[]) : any {
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAnyArr) && kvAnyArr.length > 0) {
  //    this.glyphKvArr = kvAnyArr;
  //    // debugger;
  //    this.glyphiconMenuService.setGlyphs(this.glyphKvArr);
  //    if (this.glyphKvArr.length > 10) { this.glyphiconMenuService.rotateArrowGlyphicons(); }
  //    // debugger; // TODO: inspect the glyphKvArr
  //    return true;
  //  }
  //  return false;
  //}
  // ----------------------------------------------------------------
  // Deprecated:
  // ----------------------------------------------------------------
  // selectCurrentElemDeselctOthersOld (kvpArr : KvPhoto[], currPage : KvPhoto) : void {
    //  OLD scheme:
    // -------------
    //  This works as well, in fact better,
    //  but it is too verbose and with redundant codes
    // -------------------------------------------------
    // first: remove highlight of all elements:
    // ---------------------------------------
    // elemId = 'pagerVPhotoNav-';

    // if (currPageNo > 0) {
    //  elemId = elemId + currPageNo;
    //  this.tempElem = document.getElementById(elemId); // for the div-for-backgroundImage
    //  // debugger;
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem)
    //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem.id)
    //    && this.tempElem.id.indexOf('pagerVPhotoNav-') !== -1) {
    //    // debugger;
    //    this.bias.removeClassForElement(this.tempElem, 'smallerSelected');
    //  }

    //  tempImgElems = document.querySelectorAll('img');
    //  // debugger;
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempImgElems) && tempImgElems.length > 0) {
    //    for (let i = 0; i < tempImgElems.length; i++) {
    //      // debugger;
    //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempImgElems[ i ])
    //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempImgElems[ i ].id)
    //        && tempImgElems[ i ].id.indexOf('pagerVPhotoNav-') !== -1) {
    //        // debugger;
    //        this.bias.removeClassForElement(tempImgElems[ i ], 'smallerSelected');
    //      }
    //    }
    //  }
    // }

    // second: highlight the selected element:
    // ---------------------------------------
    // if (currPageNo > 0) {
    //  // debugger;
    //  this.currentPageElemId = 'pagerVPhotoNav-' + currPageNo;
    // }
    // else {
    //  // debugger;
    //  this.currentPageElemId = 'pagerVPhotoNav-1';
    // }

    // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageElemId)) {
    //  this.tempElem = document.getElementById(this.currentPageElemId);

    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem)
    //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem.id)
    //    && this.tempElem.id.indexOf(this.currentPageElemId) !== -1) {
    //    // debugger;
    //    this.bias.addClassForElement(this.tempElem, 'smallerSelected');
    //  }
    // }
    // tempImgElems = document.querySelectorAll('img');
    // // debugger;
    // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempImgElems) && tempImgElems.length > 0) {
    //  for (let i = 0; i < tempImgElems.length; i++) {
    //    // debugger;
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempImgElems[ i ])
    //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempImgElems[ i ].id)
    //      && tempImgElems[ i ].id.indexOf(this.currentPageElemId) !== -1) {
    //      // debugger;
    //      this.bias.addClassForElement(tempImgElems[ i ], 'smallerSelected');
    //    }
    //  }
    // }

     // debugger;
    // this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // let primaryKvPhoto = new KvPhoto();
    // let primaryElemIdStr = '';
    // let primaryElem : any;

    // for (let i = 0; i < this.kvPhotoArr.length; i++) {
    //  let pageNum = i + 1;

    //   let elemIdStr = elemId + (this.kvPhotoArr[ i ].pageNum.toString());

    //   if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvPhotoArr[ i ].pageNum) || this.kvPhotoArr[ i ].pageNum < 1) {
    //    this.kvPhotoArr[ i ].pageNum = pageNum;
    //    if (this.kvPhotoArr[ i ].sitUserId === this.loginSuccess.signedInUserId) {
    //      primaryKvPhoto = this.kvPhotoArr[ i ];
    //      primaryElemIdStr = elemId + primaryKvPhoto.pageNum.toString();
    //      primaryElem = document.getElementById(primaryElemIdStr) as HTMLElement;
    //    }
    //   }
    // }
  // }
  // ---------------------------------------------------------------------------------
