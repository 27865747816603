
import { ElementRef, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { DateAssay } from '../../models/common/dateAssay.model';
import { EmitOffOnResult } from '../../models/common/emitOffOnResult.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { MessageModel } from '../../models/common/messageModel.model';
import { OffOn } from '../../models/offOn/offOn.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { CoreServiceService } from '../coreServiceService/coreServiceService.service';
import { HeartbeatService } from '../coreServiceService/heartbeatService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { DomUtilsServiceStatic } from '../staticServices/domUtilsServiceStatic.service';

@Injectable({ providedIn: 'root'})
export class OffOnService implements OnDestroy {
  //public heartbeatService : HeartbeatService;
  //public dictionaryService : DictionaryService;
  // public document : any;
  public counter = 0;
  public emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isOnLine = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public messageModel: MessageModel = new MessageModel();
  public offOn : any;
  public offOnMap : Map<any, any> = new Map();
  public offOnDistance = 'm'; // should contain the distance value and will be set
  public renderer: Renderer2;
  public sitUser : SitUser = new SitUser();
  public spanAwayElement : ElementRef;
  public spanRadioElement : ElementRef;
  public heartbeatMap : Map<any, any> = new Map();
  public hbDateAssay : DateAssay = new DateAssay();
  public timer : any;
  public timerMap : Map<any, any> = new Map();
  // ---------------------------------
  constructor (
    /*@Inject(Document) document,*/
    public heartbeatService : HeartbeatService,
    public dictionaryService : DictionaryService,
    private rendererService : RendererService,
  ) {
    // this.document = Document;
    if (rendererService) {
      this.renderer = rendererService.getRenderer();
    }
    
    this.initialize();
  }
  // ---------------------------------------------------------------
  public initialize () : void {
    // this.document = Document;
    // this.offOnComponent = EmitterSubjectService.getOffOnComponent();
    //this.dictionaryService = _dictionaryService;
    //this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //this.sitUser = EmitterSubjectService.getSitUserModel();
    //this.heartbeatService = coreServiceService.getHeartbeatService();
    // ----------------------------------------------------
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        this.loginSuccess = result;
        this.emitterDestroyed$.next( true );
        this.emitterDestroyed$.complete();
        // this.emitterDestroyed$.unsubscribe();
    });
    // ----------------------------------------------------
    EmitterSubjectService.isMobileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        this.isMobilevar = result;
    });
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    // ----------------------------------------------------
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    // this.emitterDestroyed$.unsubscribe();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.clearTimerMap();
  }
  /*
   * --------------------------------------------------------------
   * Observable for OffOnIndicator :
   * --------------------------------------------------------------
   */
  // setupOffOnStatusColorObserver ( pTile : ProfileTile ) : any
  // {
  //  return ( this.setupOffOnStatusColorObservable$ = new Observable( ( observer ) =>
  //  {
  //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pTile ) )
  //    {
  //      pTile.offOnStatusEnum = this.getAwayStatusEnum( pTile );
  //      // debugger;
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pTile.offOnStatusEnum ) )
  //      {
  //        pTile = this.getTimeAwayStatusValueAndColor( pTile );
  //        // debugger;
  //      }
  //      const tpTile = this.setOffOnStatus( pTile );

  //      CopyServiceStatic.copyProfileTileIfExists( pTile, tpTile );
  //      if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pTile.unit ) )
  //      {
  //        pTile.unit = 'Meter';
  //      }
  //      observer.next( [ pTile ] );
  //    }

  //    // When the consumer unsubscribes, clean up data ready for next subscription.
  //    return {
  //      unsubscribe ()
  //      {
  //        // nothing to do here
  //      },
  //    };
  //  } ) );
  // }
  // =================================================================================
  public clearTimerMap (): void {
    this.timerMap.forEach(timer => clearInterval(timer));
  }
  // ---------------------------------------------------------------------------------
  public copyFromOffOnStatusResult(pTile: ProfileTile, offOnResult: EmitOffOnResult): ProfileTile {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOnResult))
    {
      debugger; // TODO investigate
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOnResult.offOnStatusEnum)) {
        pTile.offOn.offOnStatusEnum = offOnResult.offOnStatusEnum;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOnResult.offOnStatusColor)) {
        this.offOn.offOnStatusColor = pTile.offOn.offOnStatusColor = offOnResult.offOnStatusColor;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOnResult.away)) {
        this.offOn.away = pTile.offOn.away = offOnResult.away;
      }
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOnResult.offOnDistance)) {
      //  this.offOn.distanceStr = pTile.offOn.distanceStr = offOnResult.offOnDistance;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOnResult.unit)) {
      //  this.offOn.unit = pTile.offOn.unit = offOnResult.unit;
      //}
      // debugger;
    }
    return pTile;
  }
  
  // ---------------------------------------------------------------
  //         'Time-away-status-mgmt'
  // ---------------------------------------------------------------
  //  Note :  this is where off-on-time-away indicator is controlled at
  // ---------------------------------------------------------------
  //  Note :  The following four funtions constitutes the detemination of
  //          Time-away-status for users
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------------------------
  //        This method is to find the online status of all including signedInUser.
  // Note:  This method does not check for spanAwayElem or spanRadioElem.
  //        The caller of this method should set these ElementRefs.
  //
  //        This method does NOT make an XHR api call
  //
  //        Therefore, the offOn data values must be provided for it to work.
  // ---------------------------------------------------------------------------------
  public checkOffOnStatusWithoutApi (offOn : OffOn) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.heartbeat)
      && offOn.sitUserId > 0) {

      // (backend computes this using Newtonsoft lib )
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.heartbeat)) {
          offOn.isOnline = this.isUserOnLine(offOn?.heartbeat); // client-side isOnline
          // debugger;
          offOn.isOnline = this.isUserOnLine(offOn?.heartbeat); // client-side isOnline
          // debugger;
          // Note: this will calculate the diff-minutes = (now - heartbeatTime) 
          offOn.awayTime = this.getMinutesSinceHeartbeat(this.offOn?.heartbeat);

          EmitterSubjectService.setIsOnLine(offOn.heartbeat.isOnline);
          // debugger;
          offOn = this.setOffOnStatusColor(offOn);
      }
    }
    // debugger;
    return offOn;
  }
  // --------------------------------------------------------------
  // Note: Not in  user, but do not delete this
  // --------------------------------------------------------------
  public checkOffOnStatusDiaApi (offOn : OffOn) : any {
    // debugger;

    // before xhr call to make sure it is the right caller
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.sitUserId > 0 ) {
      // debugger;
      // first get the heartbeat, then check the offOnStatus:
      // this.getHeartbeatForOnlineStatus(offOn).subscribe(data => {
      // this.heartbeatService.getHeartbeatFromDictionaryOrServer(offOn.sitUserId, 'OffOnService').subscribe(data => {
      this.heartbeatService.getHeartbeatObservable(offOn.sitUserId, 'offOnService').subscribe(data => {
        // debugger;
        // after data returns, make sure it is the right recipient
        if (data.sitUserId == offOn.sitUserId) {
          // debugger;
          offOn.heartbeat = data;
          // debugger;  
          offOn = this.setOffOnStatusColor(offOn);
          return offOn;
        }
      });
    }
    // debugger;
    // else return offOn;

  }
  // ----------------------------------------------------------------------------------
  // JS time will be of 13 digits: 17xxxxxxxxxxx => 13-digits.
  // CS time will be of 18 digits: 6xxxxxxxxxxxxxxxxx.
  // ----------------------------------------------------------------------------------
  isTimeInCsTicks (heartbeatTime : number) : boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(heartbeatTime) && heartbeatTime > 0) {
      let now : number = new Date().getTime(); //now-in-js-ticks
      const unixEpochTicks = 621355968000000000; // CS unix-epoch-time in ticks
      if (now - heartbeatTime > 0) {
        return false;
      }
      else if (heartbeatTime - unixEpochTicks > 0) {
        return true;
      }
    }
  }
  // ----------------------------------------------------------------------------------
  // JS time will be of 13 digits: 17xxxxxxxxxxx => 13-digits.
  // CS time will be of 18 digits: 6xxxxxxxxxxxxxxxxx.
  // ----------------------------------------------------------------------------------
  public convertCsTimeToJsTicks (heartbeatTime : number) : number {
    var hbTimeInJsTicks = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(heartbeatTime) && heartbeatTime > 0) {
      hbTimeInJsTicks = DateStringServiceStatic.convertNetTicsToJsDateTicks(heartbeatTime.toString());
    }
    return hbTimeInJsTicks;
  }
  // ----------------------------------------------------------------------------------
  public getHeartBeatTimeDifferenceInMinutes (hbdiff : number) : number {
    var hbDiffMinutes = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hbdiff) && hbdiff > 0) {
      var hbDiffSeconds = Math.ceil(hbdiff / 1000); // millsecs to sec
      // debugger;
      if (hbDiffSeconds > 0) {
        hbDiffMinutes = hbDiffSeconds / 60;
      }
    }
    return hbDiffMinutes;
  }
  // ----------------------------------------------------------------------------------
  public getOffOnStatusEnumOfMinutes (minutes : number) : number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(minutes) && minutes > 0) {
      if (minutes <= 5) {
        return 0;
      }
      else if (minutes > 5 && minutes < 10) {
        return 1;
      }
      else return 2;
    }
  }
  // ----------------------------------------------------------------------------------
  // Note:  1. The backend-heartbeat-time may be in JS or CS
  //        2. In case of CS time, it may contain unix-epoch time in ticks, or the actual heartbeat-time in CS-ticks
  //        3. If the backend-heartbeat-time is greater than unix-epoch, then we convert the CS-ticks to js-ticks
  //        4. Then we take the difference of now-in-js-ticks and js-ticks
  //        5. Then we convert the diffence into diffMinutes and 
  //        6. the diffMinutes is used to determine the `offOnStatusEnum`
  // ---------------------------------------------------------------------------------
  public computeAwayTimeFromHeartbeat (offOn : OffOn) : any {
    let hbDiff : number = -1;
    let hbDiffSeconds : number = -3;
    let hbDiffMinutes : number = -3;  

    let hbTimeInJsTicks = 0;

    let now : number = new Date().getTime(); //now-in-js-ticks

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.heartbeat)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.heartbeat.heartbeatTime)
        && offOn.heartbeat.heartbeatTime > 0) {
        if (this.isTimeInCsTicks(offOn.heartbeat.heartbeatTime))
        {
          
            hbTimeInJsTicks = this.convertCsTimeToJsTicks(offOn.heartbeat.heartbeatTime);
            if (hbTimeInJsTicks > 0) {
              hbDiff = Math.floor(now - hbTimeInJsTicks);
              debugger;
            }
            // hbTimeInJsTicks===0 =>unix-epoch-time, so we need to treat user as offline's minimum munites of 11
            else {
              hbDiff == 11 * 60 * 1000; // 11 minutes in miliseconds, offline base ticks in js (minutes * secconds * milliseconds)
              debugger;
            }
        }
        // heartbeatTime in Js-ticks
        else {
          hbDiff = Math.floor(now - offOn.heartbeat.heartbeatTime);
          /*hbDiff = Math.floor(now - offOn.heartbeat.prevHeartbeat );*/
          // debugger;
        }
      }
      else {
        hbDiff = -1; 
        // debugger;
      }
    }
    // debugger;
    // -------------------------------------------------------------------
    //  Note: var nanValue = NaN;
    //        if ( nanValue !== nanValue) // Returns true!
    //        therefore, if(!nanValue === !nanValue) ==> if not NanValue
    // -------------------------------------------------------------------
    if ((!hbDiff === !hbDiff)) // if hbDiff !== nan
    {
      offOn.offOnStatusEnum = this.getOffOnStatusEnum(hbDiff);
      offOn.awayTime = hbDiff;
      offOn = this.getAwayStatusValueAndColor(offOn);
    }
    // debugger;
    return offOn;
  }
  //  --------------------------------------------------------------
  public drawOffOnRadioColor (offOn :OffOn) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn)) {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnStatusColor)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnRadioElem)) {
          // debugger;
          if (offOn.offOnRadioElem instanceof ElementRef) {
            // this.renderer.setStyle(offOn.offOnRadioElem, 'background', offOn.offOnStatusColor);
            offOn.offOnRadioElem.nativeElement.style.backgroundColor = offOn.offOnStatusColor;
            // this.renderer.setStyle(offOn.offOnRadioElem, 'color', offOn.offOnStatusColor);
            offOn.offOnRadioElem.nativeElement.style.color = offOn.offOnStatusColor;
            if (offOn.offOnStatusColor.toLowerCase().indexOf('gold') !== -1) {
              // this.renderer.setStyle(offOn.offOnRadioElem, 'border', '0.08rem solid deepskyblue');
              offOn.offOnRadioElem.nativeElement.style.border = '0.08rem solid deepskyblue';
            } else {
              // this.renderer.setStyle(offOn.offOnRadioElem, 'border', '0.08rem solid gold');
              offOn.offOnRadioElem.nativeElement.style.border = '0.08rem solid gold';
            }
          }
          else if (offOn.offOnRadioElem instanceof HTMLElement) {
            // debugger;
            // this.renderer.setStyle(offOn.offOnRadioElem, 'background', offOn.offOnStatusColor);
            offOn.offOnRadioElem.style.backgroundColor = offOn.offOnStatusColor;
            // this.renderer.setStyle(offOn.offOnRadioElem, 'color', offOn.offOnStatusColor);
            offOn.offOnRadioElem.style.color = offOn.offOnStatusColor;
            if (offOn.offOnStatusColor.toLowerCase().indexOf('gold') !== -1) {
              // this.renderer.setStyle(offOn.offOnRadioElem, 'border', '0.08rem solid deepskyblue');
              offOn.offOnRadioElem.style.border = '0.08rem solid deepskyblue';
            } else {
              // this.renderer.setStyle(offOn.offOnRadioElem, 'border', '0.08rem solid gold');
              offOn.offOnRadioElem.style.border = '0.08rem solid gold';
            }
          }
        }
      }
      // debugger;
    }
    return offOn;
  }
  /*
   * --------------------------------------------------------------
   * Note : this is where off-on-time-away indicator is controlled at
   * --------------------------------------------------------------
   */
  public drawOffOnAwayColor (offOn : OffOn ) : any
  {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn))
    {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnStatusColor ) )
      {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnAwayElem ) )
        {
          if (offOn.offOnAwayElem instanceof ElementRef) {
            // this.renderer.setStyle(offOn.offOnAwayElem, 'color', offOn.offOnStatusColor );
            offOn.offOnAwayElem.nativeElement.style.color = offOn.offOnStatusColor;
            // debugger;
            if (offOn.offOnStatusColor.toLowerCase().indexOf('orange') !== -1) {
              // this.renderer.setStyle(offOn.offOnAwayElem, 'text-shadow', '0 -.1em .1em deepskyblue' );
              offOn.offOnAwayElem.nativeElement.style.textShadow = '0 -.1em .1em  black'; // deepskyblue
            } else {
              // this.renderer.setStyle(offOn.offOnAwayElem, 'text-shadow', '0 -.1em .1em yellow' );
              offOn.offOnAwayElem.nativeElement.style.textShadow = '0 -.1em .1em yellow';
            }
          }
          else if (offOn.offOnAwayElem instanceof HTMLElement) {
            // this.renderer.setStyle(offOn.offOnAwayElem, 'color', offOn.offOnStatusColor );
            offOn.offOnAwayElem.style.color = offOn.offOnStatusColor;
            // debugger;
            if (offOn.offOnStatusColor.toLowerCase().indexOf('orange') !== -1) {
              // this.renderer.setStyle(offOn.offOnAwayElem, 'text-shadow', '0 -.1em .1eme deepskyblue' );
              offOn.offOnAwayElem.style.textShadow = '0 -.1em .1em black'; // deepskyblu
            } else {
              // this.renderer.setStyle(offOn.offOnAwayElem, 'text-shadow', '0 -.1em .1em yellow' );
              offOn.offOnAwayElem.style.textShadow = '0 -.1em .1em yellow';
            }
          }
        }
      }
    }
    return offOn;
  }
  /*
   * --------------------------------------------------------------
   * Note : this is where off-on-time-away indicator is controlled at
   * --------------------------------------------------------------
   */
  public drawOffOnRadioAwayColor (offOn : OffOn ) : OffOn
  {
     debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnStatusColor))
    {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnRadioElem)) {
        offOn.offOnRadioElem = EmitterSubjectService.getOffOnElement("spanRadio");
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnAwayElem)) {
        offOn.offOnAwayElem = EmitterSubjectService.getOffOnElement("spanAway");
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnRadioElem)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnAwayElem))
      {
        // debugger;
        if (offOn.offOnRadioElem instanceof ElementRef) {
          // debugger;
          offOn.offOnRadioElem.nativeElement.style.backgroundColor = offOn.offOnStatusColor;
          if (offOn.offOnStatusColor.toLowerCase().indexOf('gold') !== -1) {
            offOn.offOnRadioElem.nativeElement.style.border = '0.08rem solid deepskyblue';
          } else {
            offOn.offOnRadioElem.nativeElement.style.border = '0.08rem solid gold';
          }
        }
        else if (offOn.offOnRadioElem instanceof HTMLElement) {
          offOn.offOnRadioElem.style.backgroundColor = offOn.offOnStatusColor;
          if (offOn.offOnStatusColor.toLowerCase().indexOf('gold') !== -1) {
            offOn.offOnRadioElem.style.border = '0.08rem solid deepskyblue';
          } else {
            offOn.offOnRadioElem.style.border = '0.08rem solid gold';
          }
        }
         debugger;
        if (offOn.offOnAwayElem instanceof ElementRef) {
          offOn.offOnAwayElem.nativeElement.style.color = offOn.offOnStatusColor;
          // debugger;
          if (offOn.offOnStatusColor.toLowerCase().indexOf('gold') !== -1) {
            offOn.offOnAwayElem.nativeElement.style.textShadow = '0 -.1em .1em black'; // deepskyblue
          } else {
            offOn.offOnAwayElem.nativeElement.style.textShadow = '0 -.1em .1em yellow';
          }
        }
        else if (offOn.offOnAwayElem instanceof HTMLElement) {
          offOn.offOnAwayElem.style.color = offOn.offOnStatusColor;
          // debugger;
          if (offOn.offOnStatusColor.toLowerCase().indexOf('gold') !== -1) {
            offOn.offOnAwayElem.style.textShadow = '0 -.1em .1em black'; // deepskyblue
          } else {
            offOn.offOnAwayElem.style.textShadow = '0 -.1em .1em yellow';
          }
        }
      }
      // debugger;
    }
    return offOn;
  } 
  // ---------------------------------------------------------------
  public getAwayStatusValueAndColor (offOn : OffOn) : OffOn {
    // debugger;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn)) {
      offOn.awayMap = this.setOffOnAwayMap(offOn.away);
    }
    switch (offOn.offOnStatusEnum) {
      case 0:
        offOn.offOnStatusColor = 'forestgreen'; // on-line -- 'checked'
        offOn.away = 'online';
        // debugger;          
        break;

      case 1:
        offOn.offOnStatusColor = 'orange'; // 10-min-away --'disabled'
        offOn.away = 'away'; // 'away:10 mins'; 
        // debugger;
        break;
      case 2:
        offOn.offOnStatusColor = 'darkred'; // off-line
        // offOn.away = offOn.awayMap.get('offline'); //'off-line'; //
        offOn.away = 'offline';
        // debugger;
        break;     

      default:
         debugger;
        offOn.offOnStatusColor = 'darkred';
        offOn.away = 'offline';
        // debugger;
        offOn.offOnStatusEnum = 2; // if value were -1, now it should be 1
        break;
    }
    // debugger;
    return offOn;
  }
  // ---------------------------------------------------------------
  public getOffOnStatusEnum (milliSecs : number) : number {
    
    var offlineTimeBeginsAt = 6600000; //  11min = 11*60*1000ms;
    var awayTimeBeginsAt = 3000000; // 5m = 5*60*1000ms
    // debugger; 
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(milliSecs) && milliSecs > 0) {
      if (milliSecs > offlineTimeBeginsAt) {
        return 2; // offline
      }
      else if (milliSecs < offlineTimeBeginsAt && milliSecs > awayTimeBeginsAt ) {
        return 1; // 'disabled', '10 mins away =>deepskyblue'
      }
      else if (milliSecs < awayTimeBeginsAt && milliSecs > 0 ) {
        return 0; // 'checked', on-line => forestgreen'
      }
      else {
        return 2;
      }
    }
    else {
      return 2;
    }
  }
  // ---------------------------------------------------------------------------------
  public isHeartbeatStale (hb : Heartbeat) : boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && hb.heartbeatTime > 0) {
      let now = new Date().getTime();
      let diffTicks = now - hb.heartbeatTime;
      this.hbDateAssay = DateStringServiceStatic.dateInYYYYMMDDhhmmss(diffTicks);

      let diffInMinutes = this.hbDateAssay.mm;

      return diffInMinutes > 5 ? true : false;
    }
    else return false;
  }
  // ---------------------------------------------------------------------------------
  public getMinutesSinceHeartbeat (hb : Heartbeat) : number {
    let diffInMinutes = -1;
    let now = new Date().getTime();
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && hb.heartbeatTime > 0) {
      let diffTicks = now - hb.heartbeatTime;
      this.hbDateAssay = DateStringServiceStatic.dateInYYYYMMDDhhmmss(diffTicks);

      diffInMinutes = this.hbDateAssay.mm;
    }
    return diffInMinutes;
  }
  // ---------------------------------------------------------------------------------
  //        This method is to find the online status of all including signedInUser.
  // Note:  This method does not check for spanAwayElem or spanRadioElem.
  //        The caller of this method should set these ElementRefs.
  //
  //        Angular causes to change detect on XHR api call, event and async operations.
  //        But we must have the latest heartbeat, so this method will trigger a
  //        top to bottom change detection. But there is no work-around to this,
  //        so this method is used.
  //
  //        This method makes an XHR call to the backend
  // ---------------------------------------------------------------------------------
  public getHeartbeatForOnlineStatus (offon : OffOn) : Observable<Heartbeat> {
    // debugger;
    var offOn = offon;
    return new Observable((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.sitUserId > 0/* && !offOn.isStatusUpdated*/) {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.heartbeatService)) {
          // debugger;
          this.heartbeatService.getHeartbeatFromDictionaryOrServer(offOn.sitUserId, 'OffOnService').subscribe(hb => {
            // debugger;
            // (backend computes this using Newtonsoft lib )
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
              // debugger;
              // uniquely save/update the heartbeatMap
              if (!this.heartbeatMap.has(hb.sitUserId)) {
                this.heartbeatMap.set(hb.sitUserId, hb);
              }
              else { // replace the old with the new
                let tHeartbeat = this.heartbeatMap.get(hb.sitUerId);
                this.heartbeatMap.delete(hb.sitUserId);
                tHeartbeat = CopyServiceStatic.copyHeartbeatIfExists(tHeartbeat, hb);

                this.heartbeatMap.set(tHeartbeat, hb.sitUserId);
              }
              // debugger;
              subscriber.next(hb);   
              subscriber.complete();
            }
          });
        }
      }
    });
  
    // debugger;
  }
  // ---------------------------------------------------------------
  public getHeartbeatMap (): any {
    return this.heartbeatMap;
  }
  // ---------------------------------------------------------------
  public setHeartbeatMap (hbMap: Map<any, Heartbeat>): void {
    this.heartbeatMap = hbMap;
  }
  // ---------------------------------------------------------------
  public getOffOnMap () : any {
    return this.offOnMap;
  }
  // ---------------------------------------------------------------
  public setOffOnMap (offOnMap : Map<any, OffOn>) : void {
    this.offOnMap = offOnMap;
  }
  
  
  // ---------------------------------------------------------------
  public isUserOnLine (hb: Heartbeat): boolean {
    let isOnline = this.heartbeatService?.isOnlineFromHeartbeat(hb.heartbeatTime);
    // debugger;
    return isOnline;
  }
  
  
  // --------------------------------------------------------------
  public setOffOnIds (offOn : OffOn) : OffOn {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.sitUserId)
      && offOn.sitUserId > 0) {
      offOn.awayId = 'spanAway-' + offOn.sitUserId;
      offOn.awayRadioId = 'spanRadio-' + offOn.sitUserId;

      //offOn.awayId = 'spanAway-' + ( offOn.sitUserId > 0 ? offOn.sitUserId : 1);
      //offOn.awayRadioId = 'spanRadio-' + (offOn.sitUserId > 0 ? offOn.sitUserId : 1);
      // debugger;
    }
    return offOn;
  }
  // ----------------------------------------------------------------
  // Note:  This method is in use
  // ----------------------------------------------------------------
  public setOffOnStatusColor ( offOn: OffOn ): OffOn
  {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.sitUserId > 0) {
     
      offOn = this.computeAwayTimeFromHeartbeat(offOn);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnRadioElem)) {
        // debugger;
        offOn = this.drawOffOnRadioColor(offOn);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnAwayElem)) {
        // debugger;
        offOn = this.drawOffOnAwayColor(offOn);
      }
      // OR
       //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnAwayElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn.offOnRadioElem)) {
       // offOn = this.drawOffOnRadioAwayColor(offOn);
       //}

      offOn.lastUpdatedOn = new Date().getTime();

      // Note: this map is the one that membersIndivisuallyComponent will use to update selectively
      this.updateOffOnMap(offOn);
    }
    // debugger;
    
    return offOn;
  }
  
  // ---------------------------------------------------------------------------------
  public updateOffOnMap (offOn : OffOn) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.sitUserId > 0) {
      if (!this.offOnMap.has(offOn.away)) {
        this.setOffOnAwayMap(offOn.away);
      }
    }
    return this.offOnMap;
  }
  // ---------------------------------------------------------------
  public setOffOnAwayMap (offOnAway : string) : Map<string, string> {
    let awayMap = new Map<string, string>();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOnAway)) {
      switch (offOnAway.toLowerCase()) {

        case "online": case "on-line":
          awayMap.set("online", "online");
          break;
        case "offline": case "off-line":
          awayMap.set("offline", "offline");
          break;
        case "10mins": case "10 mins": case "away":
          awayMap.set("10mins", "away");
          break;
        //case "15mins": case "15 mins": case "away-15mins":
        //  awayMap.set("15mins", "away-15mins");
        //  break;
        //case "20mins": case "20 mins": case "away-20mins":
        //  awayMap.set("20mins", "away-20mins");
        //  break;
        default:
          awayMap.set("offline", "offline");
          break;
      }
    }
    //else {
    //  awayMap.set('offline', 'offline');
    //}
    return awayMap;
  }
  // ---------------------------------------------------------------
  // ===============================================================
  //    Begin of Unused Methods
  // ===============================================================
  // ---------------------------------------------------------------
  //  Not in use
  // ---------------------------------------------------------------
  public setOffOnStatusEnumAndAwayAndStatusColor (offOn : OffOn) : any {
    // debugger;
    let minutes : number = -9;
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn)) {
    //    offOn.awayMap = this.setOffOnAwayMap("");
    //  }
    //  else {
    //    offOn = new OffOn();
    //}
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.awayTime >= 0) {
      /*
       * minutes = parseInt(minutes);
       * debugger;
       */
      minutes = offOn.awayTime;

      offOn.awayMap = this.setOffOnAwayMap("");
      if (minutes > 10) {
        offOn.offOnStatusEnum = 2;
        offOn.offOnStatusColor = 'darkred'; // off-line
        offOn.away = 'offline';
        offOn.awayMap = this.setOffOnAwayMap('offline');
        // debugger;
      }
      else if (minutes <= 10 && minutes > 5) {
        offOn.offOnStatusEnum = 1; // 'disabled', '10 mins away => deepskyblue'
        offOn.offOnStatusColor = 'orange'; // 10-min-away --'disabled'
        offOn.away = 'away'; // 'away:10 mins'; //
        offOn.awayMap = this.setOffOnAwayMap('10mins');
        // debugger;
      }
      else if (minutes <= 5 && minutes >= 0) {
        offOn.offOnStatusEnum = 0; // 'checked', on-line => forestgreen'
        offOn.offOnStatusColor = 'forestgreen'; // on-line -- 'checked'
        offOn.away = 'online';
        offOn.awayMap = this.setOffOnAwayMap('online');
        // debugger;
      }
      else {
        offOn.offOnStatusEnum = 2;
        offOn.offOnStatusColor = 'darkred'; // off-line
        offOn.away = 'offline';
        offOn.awayMap = this.setOffOnAwayMap('offline');
      }
    }
    else {
      offOn.offOnStatusEnum = 2;
      offOn.offOnStatusColor = 'darkred'; // off-line
      offOn.away = 'offline';
      offOn.awayMap = this.setOffOnAwayMap('offline');
    }
    return offOn;
  }


  // ---------------------------------------------------------------
  // Note:  This will be  called by the calling-component and service
  //       but the setupOffOnElements() must be called by the calling-component.
  // ---------------------------------------------------------------
  public setupOffOnElement (offOn : OffOn, childElem ?: ElementRef | HTMLElement) : OffOn {
    // debugger;
    let id = '';
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(childElem) || childElem instanceof HTMLElement) {
      let tElem = DomUtilsServiceStatic.getHtmlElementByClass("spanA");
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
        // debugger;
        offOn.offOnAwayElem = tElem as HTMLElement;
        EmitterSubjectService.setOffOnElement(tElem as HTMLElement, 'spanAway');
      }

      tElem = DomUtilsServiceStatic.getHtmlElementByClass("spanR");
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
        // debugger;
        offOn.offOnRadioElem = tElem as HTMLElement;
        EmitterSubjectService.setOffOnElement(tElem as HTMLElement, 'spanRadio');
      }
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(childElem)
      && childElem instanceof ElementRef
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(childElem?.nativeElement)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(childElem?.nativeElement.id)) {
      id = childElem?.nativeElement.id;
      let sitUserid = parseInt(id.split('-')[ 1 ]);
      // debugger;
      if (id.indexOf('spanAway-') !== -1 && sitUserid > 0 && sitUserid === offOn.sitUserId && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(childElem)) {
        offOn.offOnAwayElem = childElem as ElementRef;
        EmitterSubjectService.setOffOnElement(childElem as ElementRef, 'spanAway');
        // debugger;
      }
      else if (id.indexOf('spanRadio-') !== -1 && sitUserid > 0 && sitUserid === offOn.sitUserId && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(childElem)) {
        offOn.offOnRadioElem = childElem as ElementRef;
        EmitterSubjectService.setOffOnElement(childElem as ElementRef, 'spanRadio');
        // debugger;
      }
    }
    return offOn;
  }
  // ---------------------------------------------------------------
  // Note:  This will be  called by the calling-component
  //        since service does not have access to ElementRef
  // ---------------------------------------------------------------
  public setupOffOnElements (offOn : OffOn, awayElem : ElementRef, radioElem : ElementRef) : OffOn {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.sitUserId > 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(awayElem)) {
        // debugger;
        offOn = this.setupOffOnElement(offOn, awayElem as ElementRef);
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(radioElem)) {
        // debugger;
        offOn = this.setupOffOnElement(offOn, radioElem as ElementRef);
      }
    }
    return offOn;
  }
  // ---------------------------------------------------------------
  public setupOffOnElementFromEmitterService (offOn : OffOn, childelem ?: ElementRef) : OffOn {
    // debugger;
    offOn.offOnAwayElem = EmitterSubjectService.getOffOnElement('spanAway');
    offOn.offOnRadioElem = EmitterSubjectService.getOffOnElement('spanRadio');
    return offOn;
  }
 
  // ---------------------------------------------------------------
  //  Note: Thsi is not used at the moment

  // ---------------------------------------------------------------
  //  NOTE: THE FOLLOWING METHODS ARE NOT IN USE
  // ---------------------------------------------------------------
  // Note:  This method does not check for spanAwayElem or spanRadioElem.
  //        The caller of this method should set these ElementRefs.
  //
  //  Note2: Since async, event and XHR calll to api causes angular to
  //          do changedetection from root to bottom,
  //          we do not use this method. otherwise all the offOn
  //          indicator will also do change detection on any
  //          parent page.
  //          Therefore, this is deprecated/NOT used.
  // --------------------------------------------------------------
   //public checkOffOnStatusOfMembers (offOn : OffOn) : Promise<any> {
   // // ------------------------------------------------------------
   // // Note: Self Contained OnLine Watcher:
   // // ------------------------------------------------------------
   // // debugger;
   //  return new Promise((resolve, reject) => {
   //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(offOn) && offOn.sitUserId > 0) {
   //      // debugger;
   //      let returnUrl = window.location.pathname + window.location.search;
   //      if (returnUrl.toLowerCase().indexOf('members') !== -1 || returnUrl.toLowerCase().indexOf('membersindividually') !== -1) {
   //        // debugger;
   //        // async operation!!
   //        // ------------------
   //        this.timer = setTimeout(() => {
   //          // debugger;
   //          clearTimeout(this.timer);
   //          this.getHeartbeatForOnlineStatus(offOn).subscribe(data => {
   //            offOn.heartbeat = data;
   //            resolve(offOn);
   //          })
   //        }, 240000); // every 4 minutes
   //        clearTimeout(this.timer);
   //        offOn.timer = this.timer;
   //        if (!this.timerMap.has(this.timer)) {
   //          this.timerMap.set(this.timer, this.timer);
   //        }
   //      }
   //      else {
   //        this.clearTimerMap();
   //        reject(null);
   //      }
   //    }
   //    else {
   //      reject(null);
   //    }
   //  })
   //}
  // ---------------------------------------------------------------
}
