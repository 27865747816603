import { ProfileTile } from './profileTile.model';

export class UpgradedTile extends ProfileTile {
  public dateStr : any = '';
  public expDate : any = '';
  public expDateStr : any = '';
  public membership : any = '';
  public message : any = '';
  public promoCode : any = '';
  public sitUserKeyBackup : any = '';

  constructor () { super(); }
}
