<div id="glyphiconMembersComponentId">
  <div class="flex-parent-col center">
    <div class="colN" style="margin-top:43px;"><div class="inner" style="margin:10px 0 0 8px;"><span class="glyphicon glyphicon-user inner-button" aria-hidden="true" [@growShrink]="{value: true, params: {scale: 0.5}}"></span></div></div>
    <div class="colS" style="margin-top:18px;"><div class="inner" style="margin:0px 0 0 -8px;"><span class="glyphicon glyphicon-user inner-button" aria-hidden="true" [@growShrink]="{value: true, params: {scale: 1}}"></span></div></div>
  </div>
  <div class="flex-parent-row center">
    <div class="rowW"><div class="inner" style="margin:0 0 0 -5px;"><span class="glyphicon glyphicon-user inner-button" aria-hidden="true" [@growShrink]="{value: true, params: {scale: 0.75}}"></span></div></div>
    <div class="rowE"><div class="inner" style="margin:0px 0px 0 -5px;"><span class="glyphicon glyphicon-user inner-button" aria-hidden="true" [@growShrink]="{value: true, params: {scale: 0.75}}"></span></div></div>
  </div>
  <hr style="height: 2px;color:darkred;" />
  <div class="flex-container col-lg-12">
    <div *ngFor="let glyph of glyphs; let i = index;">
      <span class="glyphicon glyphicon-user inner-button" aria-hidden="true" [@growShrink]="{value: true, params: {scale: 0.25 * glyph}}"></span>
    </div>
  </div>
</div>
