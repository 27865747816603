'use-strict'
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Animations, slideInOutAnimation } from '../../../app/animation';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { CenterCoordinates } from '../../../models/misc/centerCoordinates.model';
import { AppServiceService } from '../../../services/appServiceService/appServiceService.service';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { LogRegService } from '../../../services/coreServiceService/logRegService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { ScrollTopServiceStatic } from '../../../services/staticServices/commonStaticServices/scrollTopServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';


@Component({
  selector: 'app-home-page',
  templateUrl: './homePage.component.html',
  styleUrls : ['./homePage.component.scss'],
  animations: [
    Animations, slideInOutAnimation
  ],
})
export class HomePageComponent implements OnInit, OnDestroy, AfterViewInit {
  // public breakpointObserverService!: BreakPointObserverService;
  public redirectionService! : RedirectionService;
  public rendererService! : RendererService;
  // ---------------------------------------------------------
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  private centerCoordinates : CenterCoordinates = new CenterCoordinates();
  public centerWidth = 0;
  public centerHeight = 0;
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isHome : boolean = true;
  public isLoggedIn: boolean = false;
  public isMobilevar : boolean = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public mcLoader: ModuleComponentLoader = new ModuleComponentLoader();
  public message: any;
  // public modalModel : ModalModel = new ModalModel();
  // ---------------------------------------------------------
  public signedInUserId : number = 0;
  private showDialog : boolean = false;
  private visible : boolean = false;
  private closable : boolean = true;
  public userAndPageTitle: UserAndPageTitle = new UserAndPageTitle();
  // ---------------------------------------------------------
  constructor (private cdr : ChangeDetectorRef,
    private appServiceService : AppServiceService,
    private bias: BackgroundImageAnimationService,
    private commonServiceService: CommonServiceService,
    private coreServiceService : CoreServiceService,
    private logRegService : LogRegService,

    private rendererServiceService: RendererServiceService,
    private router: Router,
  ) {
     this.redirectionService = this.commonServiceService.getRedirectionService();
     if (this.rendererServiceService ){
      this.rendererService = this.rendererServiceService.getRendererService();
     }

     // ------------------------------------------------

      // this.isMobilevar = EmitterSubjectService.getIsMobile();
      // this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      // ------------------------------------------------

      // selfCenter()
      // ------------
    let point = EmitterSubjectService.getCenteringHeightWidth('landingPageComponentId'); // 'landingPageComponentId'
       if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(point))
       {
        this.centerHeight = point.height;
        this.centerWidth = point.width;
        // alert( 'in LandingPageComponent! \n centerWidth: ' + this.centerWidth + '\n centerHeight: ' + this.centerHeight );
       }

      // ------------------------------------------------
      // if (this.loginSuccess.signedInUserId > 0) {
      //  this.isLoggedIn = true;
      // }
      // EmitterSubjectService.loginSuccessEmitter
      //  .pipe( takeUntil( this.emitterDestroyed$ ) )
      //  .subscribe( ( result ) =>
      // {
      //    this.loginSuccess = result; // JSON.parse(JSON.stringify(result));
      //    // debugger;
      //    if (this.loginSuccess.signedInUserId > 0) {
      //      this.isLoggedIn = true;
      //    }
      //  } );
      // ------------------------------------------------
  }

  ngOnInit() {
    // debugger;
    this.isMobilevar = EmitterSubjectService.getIsMobile();

    // EmitterSubjectService.emitResetDbgi(true);
    this.setPageTitle();
    /*
     * ---------------------------------------------------
     * Note : to achieve this simple objective of clearing all glyphicons from the dynamyc-graphicon-menu,
     *       it requires these following to be complied:
     *       1. have the ChangeDetection run in here at the PagerVerticalComponent.
     *       2. have the ChangeDetection run at the AppComponent.ts which the PagerVerticalComponent.ts is the child of,
     *       3. have the component which wants to clear the glyphicon menu such as this LandingPageComponent.ts,
     *       emit a message to crear the glyphicon menu.
     * ---------------------------------------------------
     */
   EmitterSubjectService.emitClearGlyphiconMenu(true);

    EmitterSubjectService.emitSetBreadcrumForPageSwitch(true);
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.signedInUserId = this.loginSuccess.signedInUserId;
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
      // debugger;
      this.isLoggedIn = true;
    }

    // EmitterSubjectService.centerCoordinatesEmitter.subscribe((result) => {
    //  this.centerCoordinates = result;
    // });


    // TODO: test
    // ScrollTopServiceStatic.scrollToBottom( 'routerOutletId' );
    // ScrollTopServiceStatic.scrollToLocation( 'allScrollTotId', 'start' );

    // EmitterSubjectService.removeComponentEmitter.subscribe((result) => {
    //  this.isHome = false;
    //  this.ngAfterViewInit();
    // });

    /*
     * EmitterSubjectService.toggleModalDialogEmitter.subscribe(result => {
     * if (result) {
     *   this.showDialog = result;
     *   let inputs = {
     *     isMobile : false
     *   }
     *   this.modalService.init(ModalDialogComponent, inputs, {});
     * }
     * else this.modalService.destroy();
     */

    // });
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngAfterViewInit ()
  {
    this.cdr.detectChanges();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
      // debugger;
      this.isLoggedIn = true;
      EmitterSubjectService.emitSetSignedInUserBackgroundImage(true);
     
    }
    else {
      this.bias.resetDefaultBias();
    }
    // debugger;
    EmitterSubjectService.emitHideSpinner("");
    ScrollTopServiceStatic.scrollToTop('allScrollToTopId');
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();

    // EmitterSubjectService.emitClearGlyphiconMenu(true);
  }
  // ---------------------------------------------------------------
  // ----------------------------------------------------- 
  //  Login-Register-Nav-Header-Buttons-Management-System:
  // -----------------------------------------------------
  emitLoginButtonRenderedMsg () {
    // EmitterSubjectService.emitMessage('Render-Login-Button');
  }
  // ---------------------------------------------------------------
  emitRegisterButtonRenderedMsg () {
   //  EmitterSubjectService.emitMessage('Render-Register-Button');
  }
  // --------------------------------------------------------------
  public executeLogoutSuccessTasks () {
    debugger;
    this.loginSuccess = new LoginSuccess();
    debugger;
    EmitterSubjectService.setLoginSuccess(this.loginSuccess);
    EmitterSubjectService.setIsLoggedOutByUser(true);
    this.logRegService.executeLogoutSuccessTasks();
    this.signedInUserId = 0;
    debugger;
    this.isLoggedIn = false;
    EmitterSubjectService.emitLogout(this.loginSuccess);
    this.ngAfterViewInit();
  }
  // ---------------------------------------------------------------
  setRoute (route : string, id : number) : void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(route)) {
      switch (route?.toString().toLowerCase()) {
        case 'login': 
          this.emitLoginButtonRenderedMsg();
          break;
        case 'logout':
          this.executeLogoutSuccessTasks();
          break;
        case 'signup':
          // this.emitRegisterButtonRenderedMsg();
          // debugger;
          break;
        default:
          break;
      }
      // debugger;
      this.redirectionService.setRoute(this.router, route, id).subscribe(data => {
        // debugger;
        this.pagerBrdCrm = data;
        this.ngAfterViewInit();
        EmitterSubjectService.emitCloseSidebar(true);
      });
      // debugger;
    }
    else {
      if (this.loginSuccess.signedInUserId > 0) {
        this.redirectionService.routeToMembersPage(this.router).subscribe(data => {
          this.pagerBrdCrm = data;
          this.ngAfterViewInit();
          EmitterSubjectService.emitCloseSidebar(true);
        });
      }
      else {
        this.redirectionService.routeToHomePage(this.router).subscribe(data => {
          this.pagerBrdCrm = data;
          this.ngAfterViewInit();
          EmitterSubjectService.emitCloseSidebar(true);
        });
      }
    }
  }
  // ---------------------------------------------------------------
  //setRouteOld(route: string, id: number): void {
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(route)) {
  //    if (route?.toString().toLowerCase().indexOf('login') !== -1) {
  //      this.emitLoginButtonRenderedMsg();
  //    }
  //    else if (route?.toString().toLowerCase().indexOf('register') !== -1) {
  //      this.emitRegisterButtonRenderedMsg();
  //      this.redirectionService.setRoute(this.router, route, id).subscribe(data => {
  //        debugger;
  //        this.pagerBrdCrm = data;
  //      });
  //    }
  //    else if (route?.toString().toLowerCase().indexOf('logout') !== -1) {
  //      var timer = setTimeout(() => {
  //        this.redirectionService.setRoute(this.router, route, id).subscribe(data => {
  //          this.pagerBrdCrm = data;
  //        });
  //        // debugger;
  //        clearTimeout(timer);
  //      }, 1000);
  //    }
  //    else {
  //      debugger;
  //      this.redirectionService.setRoute(this.router, route, id).subscribe(data => {
  //        debugger;
  //        this.pagerBrdCrm = data;
  //      });
  //      // this.setRouteBreadCrum(route, id);
		//	}
  //  }
  //  else {
  //    if (this.loginSuccess.signedInUserId > 0) {
  //      this.redirectionService.routeToMembersPage(this.router).subscribe(data => {
  //        this.pagerBrdCrm = data;
  //      });
  //      // this.setRouteBreadCrum(route, id);
  //    }
  //    else {
  //      this.redirectionService.routeToHomePage(this.router).subscribe(data => {
  //        this.pagerBrdCrm = data;
  //      });
  //      // this.setRouteBreadCrum(route, id);
  //    }
  //  }
  //}
  // --------------------------------------------------------------
  public setRouteBreadCrum (route : string, id : number) : void {
    // debugger;
    this.redirectionService.setRouteforSignedInUser(this.router, route, id).then(data => {
      this.pagerBrdCrm = data;
    });

  }
  /*
   * ---------------------------------------------------------------
   * Emit the title of the page:
   * ---------------------------------------------------------------
   */
  setPageTitle() {
    this.userAndPageTitle.sitUserId = this.loginSuccess.signedInUserId;
    this.userAndPageTitle.isPrepared = true;

    /*
     * line#1
     * this.userAndPageTitle.pageTitle = '<span class=\'neonRedText\' style=\'font-size:0.55rem;padding-top:-0.8rem;\'>-:Home:-</span><br/>';
     */

    //  line#2
    const welcomePre = '<span class=\'grnDrkRedxSB\'  style=\'padding-top:-0.9rem;\'>';
    const welcomePost = '</span><br/>';
    //  line#1 + line#2

    this.userAndPageTitle.pageTitle = welcomePre + 'Welcome to Slakez!' + welcomePost;

    //  line#3
    const gayPre = '<span class=\'outlineDeepSkyBlueText\'  style=\'font-size:0.6rem;padding-top:-6rem;\'>';
    const gayPost = '</span>';
    //  line#1 + line#2 + line#3

    this.userAndPageTitle.pageTitle += gayPre + 'A new social networking app!' + gayPost;

    // debugger;
    EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
  }
  /*
   * mouseEnter(page) {
   * setTimeout(() => {
   *   this.router.navigate([page]);
   * }, 500);
   * }
   */

  onTap(event: any) { }
  /*
   * ---------------------------------------------------------------------------------
   * openModal() {
   * EmitterSubjectService.emitIsModal(true);
   * let inputs = {
   *   isMobile : false
   * }
   * this.modalService.init(ModalDialogComponent, inputs, {});
   */

  /*
   * EmitterSubjectService.emitToggleModalDialog();
   * }
   */

  /*
   * closeModal() {
   * this.modalService.destroy();
   * EmitterSubjectService.emitIsModal(false);
   * EmitterSubjectService.emitToggleModalDialog();
   * }
   * ---------------------------------------------------------------------------------
   * toggleDialog() {
   * EmitterSubjectService.emitToggleModalDialog();
   * }
   * removeModal() {
   * this.modalService.destroy();
   * }
   * ---------------------------------------------------------------------------------
   */
}
