export class Address {
  public id = 0;
  public addressStr = '';
  public aptUnit = '';
  public addressKey = '';
  public billingAddressId = 0;
  public businessCardId = 0;
  public cartId = 0;
  public city = '';
  public country = '';
  public email = '';
  public firstName = '';
  public isBilling = true;
  public isSameAsBilling = false;
  public lastName = '';
  public message = '';
  public outsideVendorCardId = 0;
  public shippingAddressId = 0;
  public sitUserId = 0;
  public streetNum = '';
  public streetName = '';
  public state = '';
  public zipCode = '';
  constructor() { }
}
