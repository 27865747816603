<div id="fileUploadComponentId" [@growXshrink] *ngIf="isToggleFileUpload || (isViewMember && content.sitUserId === loginSuccess.signedInUserId)" style="margin:2rem 0 1rem 2rem;">
  <div class="flex-container text-center" style="margin:-1rem 1rem 0rem -1rem;" [ngStyle]="{'margin-top': 'marginTop'}">
    <!--message-->
    <div *ngIf="content && content.message && content.message.length > 0" class="flex-container notexS">
      <span class="errxS alert alert-secondary" tyle="padding:0.25rem;" role="alert"
            [innerHtml]="content.message  | safe: 'html'">
      </span>
    </div>
    <div class="flex-container" style="margin:1.25rem 0.25rem -0.5rem 0.25rem;">
      <div *ngIf="!selectedFiles || (selectedFiles && selectedFiles.length === 0)" class="flex-button whiteBlueInvSB" style="width: 100%;margin: 0.5rem;">
        Upload Pictures:
      </div>
      <div class="flex-button nvyxSB"
           style="text-shadow: 0 -.1em .1em white;
                  width: 100%;
                  margin: 0.5rem;">
        Select File :
      </div>

      <div *ngIf="selectedFiles && selectedFiles.length > 0" class="flex-button nvyxSB"
           style="text-shadow: 0 -.1em .1em white;
                  width: 100%;
                  margin: 0.5rem;">
        Selected File(s) : <div *ngFor="let f of selectedFiles;  let i=index" class="grnBluGldxSB">{{f.name}}</div>
      </div>
    </div>
    <!---->
    <form #fUpForm="ngForm" class="text-center" novalidate>
      <input *ngIf="!selectedFiles || (selectedFiles && selectedFiles.length === 0)" type="file" align="center"
             (change)="uploadFiles($event)"
             class="flex-item-plain notexS text-center"
             name="chatfiles"
             style="max-height: 1.5rem;
                    height: auto;
                    width: auto;
                    left:8%;
                    margin: 0.1rem 0 0.1rem 0;
                    border-radius: 7px;"
             autofocus multiple required />
      <div *ngIf="isSubmitted && selectedFiles && selectedFiles.length === 0" class="flex-text errxxS increase" style="margin:0.25rem;width:95%;">
        Image required!
      </div>

      <div class="form-group" [ngClass]="{
              'has-danger': heading.invalid && heading.dirty,
              'has-success': heading.valid && heading.dirty
            }">
        <div class="flex-container">
          <div class="whiteBluexSB" style="margin:0.25rem;width:95%;">
            Description(<span class="errxS">optional</span>):
          </div>
        </div>
        <div class="flex-container">
          <div class="flex-button" style="clear:both;">
            <textarea name="heading"
                      [(ngModel)]="content.heading"
                      class="textarea text-center;"
                      type="text" style="width: 12rem; height: 4rem; color: lightslategray; border-radius: 7px;"
                      #heading="ngModel"
                      (change)="getDescription($event)" required>
            </textarea>
          </div>
        </div>
        <div *ngIf="content.heading.length == 0 && heading.touched" class="flex-container errorxS">
          <span class="flex-text" style="color:#e80c4d;font-size:0.5rem"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> A file description is required</span>
        </div>
      </div>

      <!--div *ngIf="isCurrentFile  && content.isPic || content.chatFileUpload.fileMap.size > 0">

        <div class="flex-container">
          <div class="flex-text nvyxxS">
            This is your current file:
          </div>
        </div>
        <div *ngIf="tempStringFiles.length > 0" class="flex-container">
          <div [ngClass]="{'img-responsive flex-button goldShadow':true}" [ngStyle]="{'background-image':getUrl(tempStringFiles[tempStringFiles.length - 1])}" style="background-repeat:no-repeat;background-position:center;background-size:75%;padding:4px;height:auto;min-height:6.25rem;width:10rem;border:2px solid beige;border-radius:5px;"> </div>
        </div>
        <div *ngIf="content && content.contents && content.contents.length > 0" class="flex-container">
          <div [innerHTML]="content.heading | safe: 'html'" class="flex-text"></div>
        </div>
        <div *ngIf="isCurrentFile" class="flex-container">
          <button type="button" [ngClass]="{'bttn-xs-info':!isMobilevar, 'bttn-sm-info mBttnFontWeight':isMobilevar, 'flex-button':true}" value="ChangeCurrenntFile" style="margin:10px;font-size:medium;" (click)="isCurrentFile = !isCurrentFile;">Change current file</button>
        </div>
      </div>
      <div *ngIf="!isCurrentFile">
        <div *ngIf="content.isPic || content.chatFileUpload.fileMap.size > 0" class="flex-container">
          <button type="button" [ngClass]="{'bttn-xs-info':!isMobilevar, 'bttn-sm-info mBttnFontWeight':isMobilevar, 'flex-button':true}" value="ChangeCurrenntFile" style="margin:10px;font-size:medium;" (click)="isCurrentFile = !isCurrentFile;">Keep current file</button>
        </div>
      </div


      <div class="flex-container notexS" style="margin: 1rem 0 1rem 12%; width: 75%;">
        <div class="errxSB flex-text-center">Uploaded Percent: {{percentDone}}% <br /></div>
        <div class="errxSB flex-text-center" *ngIf="isUploadSuccess">
          <span style="color:forestgreen;"> Upload Successful</span>
        </div>
      </div-->

      <hr style="height:1.15px;background-color:#d9edf7;margin:-0.25rem 0 0.5rem 1.75rem;width: 75%;border-radius:1px;" />

      <div class="flex-container"
           style="margin: 0.25rem;
                  width: 95%;
                  display: inline-block;">
        <!--&& !isCurrentFile  [disabled]="tempPicStrArr.length === 0" -->
        <button type="button"
                (click)="uploadFileArr();"
                [ngClass]="{'bttn-xs-info':!isMobilevar, 'bttn-sm-info mBttnFontWeight':isMobilevar, 'flex-button':true}"
                style="margin:5px;display:inline-block;">
          <span class="glyphicon glyphicon-cloud-upload" aria-hidden="true"
                style="vertical-align:middle;padding:5px 10px 5px 10px;">
            Upload Picture
          </span>
        </button>

        <button type="button" (click)="cancel();"
                class="flex-button"
                [ngClass]="{'bttn-md-invDefault':!isMobilevar, 'bttn-sm-invDefault mBttnFontWeight':isMobilevar}"
                style="margin:5px 5px 5px 0.5rem;display:inline-block;">
          <span class="glyphicon glyphicon-ban-circle" aria-hidden="true" style="vertical-align:middle;padding:5px 10px 5px 10px;">
            Cancel
          </span>
        </button>
      </div>
    </form>
  </div>
</div>
    
    <!--<div class="flex-container">
      <div class="flex-text" style="background-image:linear-gradient(#d9edf7, #dff0d8);padding:1rem;">
        <div class="nvyxxS">Select a File to send : </div>
        <div style="padding:0 0 0 3rem;">
          <input name="fileUpload" type="file" (change)="upload($event.target.files)" autofocus required />
        </div>
        <button type="button" class="btn btn-primary flex-button" value="Cancel" style="margin:10px;font-size:medium;" (click)="isSendFile=!isSendFile;">
          <span class="glyphicon glyphicon-ban-circle" aria-hidden="true"> Cancel</span>
        </button>
      </div>
    </div>-->
