export class ChatFileUpload {
  public cryptUserKey = '';
  public date = '';
  public description = '';
  public fileMap : Map<any, any> = new Map<any, any>();
  public filesToStringArr : string[] = [];
  public formData = new FormData();
  public formDataArr : FormData[] = [];
  public fileArr : File[] = [];
  
  public id = 0;
 
  public longitude = 0;
  public latitude = 0;
  public picArr:any[] =  [];
  public sitUserId = 0;
  constructor() { }
}
