export class UserAndPageTitle {
  public pageTitle = '';
  public sitUserId = 0;
  public cryptUserKey = '';
  public date = '';
  public profileName = '';
  public userName = '';
  public isPrepared = false;
  constructor() { }
}
