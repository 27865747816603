'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { Bool } from '../../../models/common/bool.model';
import { KvAny } from '../../../models/keyValue/kvAny.model';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

@Component({
  selector: 'app-upload-files-key-value-arr',
  templateUrl: './uploadFilesKeyValueArr.component.html',
  styleUrls: ['./uploadFilesKeyValueArr.component.scss'],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFilesKeyValueArrComponent implements AfterViewInit, OnInit, OnDestroy {
 
  public rendererService!: RendererService;
  public renderer!: Renderer2;
  // -----------------------------------------------
  public askMe = 'Ask Me :)';
  public isCameraVisible = true;
  public isShowPhotoUploadUi : Bool = new Bool();
  public isShowAccordionUi : Bool = new Bool();
  public kvLegend: string[] = ['Profile description', 'About me', 'My preferences', 'What I am looking for', 'What I am into', "Upload Photos"];
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  @Input() isEyeOpen = true;
  public isMobilevar = false;
  public loginSuccess! : LoginSuccess;
  public toggleCameraGlyphCounter = 0;

  constructor(
    private cdr: ChangeDetectorRef,
  ) {

  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.isShowAccordionUi.isOpened = true;
    // debugger;
    // -------------------------------------------------------------------------
    // showPhtotoUpload listener:
    // -------------------------------------------------------------------------
      EmitterSubjectService.isShowPhotoUploadEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger;
        // PhotoUpload UI's default state is closed.
        // will open only if isPhotoMgmt is true.
        // -----------------------------------------
        this.isShowPhotoUploadUi.isOpened = result;
        // debugger;
        this.ngAfterViewInit();
      });
    // -------------------------------------------------------------------------
    EmitterSubjectService.isCameraVisibleEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.isCameraVisible = !this.isCameraVisible;
        this.ngAfterViewInit();
      });
     // -------------------------------------------------------------------------
    EmitterSubjectService.toggleAccordionEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.isShowAccordionUi.isOpened != this.isShowAccordionUi.isOpened;
        this.ngAfterViewInit();
      });
    // -----------------------------------------------------------
    EmitterSubjectService.toggleThisEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        if ((result as string).toLowerCase().indexOf('iseyeclose-avatar') !== -1) {
          // debugger;
          this.isEyeOpen = true;
        }
        else {
          // debugger; 
          this.isEyeOpen = false;
        }
        this.ngAfterViewInit();
      });

    // -------------------------------------------------------------------------
    /*
     * debugger;
     * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
     *   this.preferenceKvArrArr = StringServiceStatic.getPeferenceToKvArrArr(this.preference);
     * }
     * debugger;
     */
  }
  // ---------------------------------------------------------------
  // TODO: remove before deployment
  // ngOnChanges(changes: SimpleChanges): void {
  //  /*
  //   * only run when property 'data' changed
  //   * debugger;
  //   * debugger;
  //   * TODO : check profileinfoArrKcAny
  //   */

  // }
  // ---------------------------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.toggleCameraGlyphCounter = 0;
  }
  // ---------------------------------------------------------------------------------
  public ngAfterViewInit () : void {
    // debugger;
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------------------------
  toggleAccordion () {
    // debugger;
    this.isShowAccordionUi.isOpened = !this.isShowAccordionUi.isOpened;
    this.ngAfterViewInit();
  }
  // ----------------------------------------------------------------
  //toggleIsEyeOpen () : any {
  //  debugger;
  //  EmitterSubjectService.emitToggleThis('isEyeClose-uploadFilesKv'); // uploadFilesKv eye is closed
  //}
  // ---------------------------------------------------------------------------------
}
