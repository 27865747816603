'use-strict'
import { Directive, HostBinding, HostListener } from '@angular/core';

@Directive({
  selector: '[ref]',
  exportAs: 'ref',
})
export class RefDirective {
  private count = 0;
  @HostBinding('class.is-open') click = false;
  constructor() { }

  @HostListener('click') onClic () {
    // debugger;
    this.click = !this.click;
    /*
     * this.count++;
     * if (this.count % 2 == 0) {
     * this.click = !this.click;
     * this.count = 0;
     * }
     */
  }
}
