'use-strict'
import { OnDestroy } from '@angular/core';
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { SpinnerOverlayService } from '../../../services/modalServiceService/spinnerOverlayService';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

// ref: https://christianlydemann.com/four-ways-to-create-loading-spinners-in-an-angular-app/

@Component({
  selector: 'app-spinner-overlay',
  templateUrl: './spinnerOverlay.component.html',
  styleUrls: [ './spinnerOverlay.component.scss' ],
  animations: [ Animations, slideInOutAnimation, ],
  changeDetection: ChangeDetectionStrategy.OnPush, //  default //
  host: { '[@slideInOutAnimation]': '' }
})
export class SpinnerOverlayComponent implements OnInit, AfterViewInit, OnDestroy
{

  public emitterDestroyed$ : Subject<boolean> = new Subject();
  @Input() spinnerModel : SpinnerModel = new SpinnerModel();
  public showSpinner = false;
  // public timer : any;
  constructor (private spinnerLoader: SpinnerOverlayService,
    public cdr : ChangeDetectorRef,
  )
  {
  }

  ngOnInit () : void
  {
     
     if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.cdr)) {
      this.cdr.detectChanges();
     }
     // -------------------------------------------------------------------------
     EmitterSubjectService.hideSpinnerEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        if (result instanceof SpinnerModel) {
          this.spinnerModel = result as SpinnerModel;
         
        }
        else {
          this.spinnerModel.clear();
        }
        this.showSpinner = false;
         debugger;
        this.ngAfterViewInit();
      });
     // -------------------------------------------------------------------------
     EmitterSubjectService.showSpinnerEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        this.spinnerModel = result as SpinnerModel;
        debugger;
        // when spinner is spinning for 5 seconds or more, turn it off
        this.showSpinner = true;
          
        this.ngAfterViewInit();
      });
  }
  ngAfterViewInit () {
   // debugger;
  }
  //  ---------------------------------------------------------------
  ngOnDestroy () : void {
    /*
     *  prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *         (2) https://www.concretepage.com/angular-2/angular-4-renderer2-example
     *         (3) https://stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *         (4) https://stackblitz.com/edit/timer-with-pdestroyed
     */
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // --------------------------------------------------------------------------
  getSpinnerLoading () : any {
    debugger;
    return this.spinnerLoader.getLoading();
  }
  // --------------------------------------------------------------------------
  setSpinnerLoading (value : boolean) : void {
    debugger;
    this.spinnerLoader.setLoading(value);
  }
  //  ---------------------------------------------------------------
}
