'use-strict'
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { LoginSuccess } from '../../../../models/account/loginSuccess.model';
import { Bool } from '../../../../models/common/bool.model';
import { KeyValueResult } from '../../../../models/keyValue/keyValueResult.model';
import { KvAny } from '../../../../models/keyValue/kvAny.model';
import { ProfileImage } from '../../../../models/profile/profileImage.model';
import { CoreServiceService } from '../../../../services/coreServiceService/coreServiceService.service';
import { KvArrayService } from '../../../../services/coreServiceService/kvArrayService.service';
import { RendererService } from '../../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../../services/rendererServiceService/rendererServiceService.service';
import { EmitterSubjectService } from '../../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { StringServiceStatic } from '../../../../services/staticServices/stringServiceStatic.service';

@Component({
  selector: 'app-profile-image-kv',
  templateUrl: './profileImageKv.component.html',
  styleUrls: ['./profileImageKv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // default //
})
export class ProfileImageKvComponent implements OnInit, OnDestroy, AfterViewInit {
  public kvArrayService!: KvArrayService;
  public rendererService!: RendererService;
  public renderer!: Renderer2;
  @Input() id = -1;
  @Input() sitUserId = -1;
  @Input() profileImage: ProfileImage = new ProfileImage();
  @Input() kvAnyLegend = 'ProfileImage';
  @Input() kvAnyName = 'ProfileImage';
  @Input() isFormSubmitted = false;
  @Input() isRequired = false;
  public kvAnyResult: KeyValueResult = new KeyValueResult();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isClosedBool: Bool = new Bool();
  public loginSuccess!: LoginSuccess;
  public selectedKvAny!: FormControl;
  public selectedKvAnyIndex = -1;
  public timers : any = [];
  // ---------------------------------------------------------------
  constructor(
    private cdr: ChangeDetectorRef,
    private coreServiceService: CoreServiceService,
    private rendererServiceService: RendererServiceService,
    private router: Router,
    @Inject(DOCUMENT) document : any
  ) {
    if (coreServiceService) {
      this.kvArrayService = coreServiceService.getKvArrayService();
    }
    if ( this.rendererServiceService ) { this.rendererService = this.rendererServiceService.getRendererService(); }

    this.kvAnyResult.kvName = this.kvAnyName;
    // debugger;
  }
  ngOnInit(): void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit() {
    this.cdr.detectChanges();
    let timer : any;
    timer = setTimeout(() => {
      if (this.profileImage && this.profileImage.sitUserId > 0 && this.id > -1) {
        this.setProfileImage('pImage-' + this.profileImage.sitUserId.toString() + '-' + this.id.toString());
      }
    }, 2000);

    clearTimeout(timer);

    this.timers.push(timer);
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.timers.map((e : any) => {
      clearInterval(e);
    });
  }

  // ---------------------------------------------------------------------------------
  public setProfileImage(id: string): void {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      const pElem = document.getElementById(id);
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileImage.image)) {
        this.renderer?.setStyle(pElem, 'background-image', 'url(\'' + this.profileImage.image + '\')');
        this.renderer?.setStyle(pElem, 'z-index', '999');
        // debugger;
      }
      pElem.remove();
    }
  }
  // ---------------------------------------------------------------
  getUrl(): string {
    return this.profileImage.image;
    // return'url('' + this.profileImage.image + '')';
  }
  // ---------------------------------------------------------------
  isArray(model: any): boolean {
    return this.kvArrayService.isModelArray(model);
  }
  // ---------------------------------------------------------------
  isImageValue(model: KvAny): boolean {
    return this.kvArrayService.isImage(model);
  }
  /*
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   * ref:https:// www.positronx.io/angular-checkbox-tutorial/
   */
  onKeyValueChange (e : any): void {
    if (e.target.checked) {
      /*
       * this.selectedRadiobox = this.radioboxForm.get('radioSelection') as FormControl;
       * this.selectedRadiobox.patchValue('');
       * this.selectedRadiobox.patchValue(e.target.value);
       */

      this.kvAnyResult.kvName = this.kvAnyName;
      this.kvAnyResult.kvSelection = e.target.value;
      this.kvAnyResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
      EmitterSubjectService.emitKeyValueResult(this.kvAnyResult);
    }
  }
  // ---------------------------------------------------------------
  isRequiredPassed(): any {
    return this.kvAnyResult.kvSelection.length > 0;
  }
  // ---------------------------------------------------------------
}
