import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { PayPalSellerProtection } from './payPalSellerProtection';

export class PayPalCapture {
  public amount_currency_code = '';
  public amount_value = '';
  public create_time = '';
  public currency_code = '';
  public date = '';
  public final_capture = false;
  public status = '';
  public sitUserKey = '';
  public update_time = '';
  public value = '';
  public orderID = '';
  public PayPalOrderId = 0;
  public payPalSellerProtection: PayPalSellerProtection = new PayPalSellerProtection();
  constructor() {
  }
  public copy ( from: PayPalCapture ): void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.amount_currency_code = from.amount_currency_code;
      this.amount_value = from.amount_value;
      this.create_time = from.create_time;
      this.currency_code = from.currency_code;
      this.date = from.date;
      this.final_capture = from.final_capture;
      this.update_time = from.update_time;
      this.status = from.status;
      this.sitUserKey = from.sitUserKey;
      this.orderID = from.orderID;
      this.PayPalOrderId = from.PayPalOrderId;
      this.payPalSellerProtection.copy( from.payPalSellerProtection );
      this.value = from.value;
    }
  }
  public toStringCsv (): string
  {
    let payPalCaptureString = this.amount_currency_code + ', '
      + this.amount_value + ', '
      + this.create_time + ', '
      + this.currency_code + ', '
      + this.date + +', '
      + this.final_capture + ', '
      + this.orderID + ', '
      + this.PayPalOrderId + ', '
      + this.payPalSellerProtection.toString() + ', '
      + this.sitUserKey + ', ' 
      + this.status + ', '
      + this.update_time + ', '
      + this.value + ', ';
    return payPalCaptureString;
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n amount_currency_code: ' +
      this.amount_currency_code +     

      ',\n amount_value: ' +
      this.amount_value +

      ',\n' +
      'currency_code:' +
      this.currency_code +

      ',\n' +
      'create_time:' +
      this.create_time +

      ',\n date: ' +
      this.date +

      ',\n final_capture: ' +
      this.final_capture +

      ',\n orderID: ' +
      this.orderID +

      ',\n PayPalOrderId: ' +
      this.PayPalOrderId +
     
      ',\n payPalSellerProtection: ' +
      this.payPalSellerProtection.toString() +     

      ',\n sitUserKey: ' +
      this.sitUserKey +

      ',\n status: ' +
      this.status +

      ',\n' +
      'update_time:' +
      this.update_time +

      ',\n value: ' +
      this.value +

      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
