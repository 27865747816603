import { BoxNonceEntity } from '../boxNonce/boxNonceEntity.model';

export class ClientData {
  public id = 0;
  public date = '';
  public key = '';
  public preference: BoxNonceEntity = new BoxNonceEntity();
  public profileInfo: BoxNonceEntity = new BoxNonceEntity();
  public profilePics: BoxNonceEntity = new BoxNonceEntity();
  public profileTiles: BoxNonceEntity[] = [];
  constructor() { }
}
