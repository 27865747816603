
import { Router } from "@angular/router";
import { LoginSuccess } from "../account/loginSuccess.model";

export class ModuleComponentLoader {
  public action = '';
  public componentName = '';
  public controller = '';
  public callerName = ''; // importnat! to be used in conjusction with componentName, but not with moduleName
  public isModalVisible = false;
  public id = 0; // is meant to contain angular-generated-id-for-routing
  public index = 0;
  public loginSuccess = new LoginSuccess();
  public message = '';
  public moduleName = '';
  public origin = '';
  public paramMap : Map<any, any> = new Map<any, any>();
  public returnUrl = '';
  public router!: any;
  public sitUserId = 0; // Caution: not to use for routing, but for other purposes
  public url = '';

  // -----------------------------------------------
  constructor () {
  }
}
