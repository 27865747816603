export class Communication {
  public beginIndex = 0;
  public chatActivityId = 0;
  public chatMessageId = 0;
  public communicationType = '';
  public communicationActivityId = 0;
  public communicationActivityIds : number[] = [];
  public communicationMessageId = 0;
  public date = '';
  public isInbox = true;
  public isFreshDataRequested = false;
  public jsDateForCs = '';
  public sitUserId = 0;
  public SitUserKey = 0;
  public signedInUserId = 0;
  public signedInUserKey = '';
  public fileName = '';
  constructor() { }
}
