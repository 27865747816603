
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AppFeatureService } from './appFeatureService.service';
import { BreakPointObserverService } from './breakPointObserverService.service';
import { SWUpdateService } from './swUpdateService.service';
// import { SWUpdateService } from './swUpdateService.service';
import { UpgradedMemberService } from './upgradedMemberService.service';
// import { ErrorLogService } from './errorLogServoce.service';
// import { GestureService } from './gestureService.service';
// import { LogEngineService } from './logEngineService.service';

@Injectable({
  providedIn: 'root',
})
export class AppServiceService implements OnDestroy {
  private emitterDestroyed$: Subject<boolean> = new Subject();
  constructor (
    private appFeatureService : AppFeatureService,
    private breakpointObserverService: BreakPointObserverService,
    // private errorLogService: ErrorLogService,
    // private logEngineService: LogEngineService,    
    private swUpdateService: SWUpdateService,
    private upgradedMemberService : UpgradedMemberService,
  ) { }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ------------------------------------------------------------------------------------------------
  setAppFeatureService ( af : AppFeatureService ) : void
  {
    this.appFeatureService = af;
  }
  // ------------------------------------------------------------------------------------------------
  getAppFeatureService () : AppFeatureService
  {
    return this.appFeatureService;
  }
  // ------------------------------------------------------------------
  setBreakPointObserverService(bps: BreakPointObserverService): void {
    this.breakpointObserverService = bps;
  }
  getBreakPointObserverService(): BreakPointObserverService {
    return this.breakpointObserverService;
  }
  // ------------------------------------------------------------------
  // setErrorLogService(errs: ErrorLogService): void {
  //  this.errorLogService = errs;
  // }
  // getErrorLogService(): ErrorLogService {
  //  return this.errorLogService;
  // }
  // ------------------------------------------------------------------
   setSWUpdateService(sws: SWUpdateService): void {
     this.swUpdateService = sws;
   }
   getSWUpdateService () : SWUpdateService {
    return this.swUpdateService;
   }
  // ------------------------------------------------------------------------------------------------
  setUpgradedMemberService ( up : UpgradedMemberService): void {
    this.upgradedMemberService = up;
    }
  // ------------------------------------------------------------------------------------------------
  getUpgradedMemberService () : UpgradedMemberService {
    return this.upgradedMemberService;
  }
  // ------------------------------------------------------------------
  // setUploadService (us : UploadService) : void {
  //  this.uploadService = us;
  // }
  // getUploadService () : UploadService {
  //  return this.uploadService;
  // }
  // ------------------------------------------------------------------

}
