
import { Injectable } from '@angular/core';
import { PreferenceService } from './preferenceService.service';
import { ProfileContentService } from './profileContentService.service';
import { ProfileInfoService } from './profileInfoService.service';
import { ProfileNameService } from './profileNameService.service';
import { ProfilePicsService } from './profilePicsService.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileServiceService {
  
  constructor (
    private preferenceService : PreferenceService,
    private profileContentService : ProfileContentService,
    private profileNameService : ProfileNameService,
    private profilePicsService : ProfilePicsService,
    private profileInfoService : ProfileInfoService,
  ) { }
  // ------------------------------------------------------------------
  setPreferenceService (ps : PreferenceService): void {
    this.preferenceService = ps;
   }
  getPreferenceService () : PreferenceService {
    return this.preferenceService;
  }
  // ------------------------------------------------------------------
  setProfileContentService (pcs : ProfileContentService): void {
    this.profileContentService = pcs;
  }
  getProfileContentService () :ProfileContentService {
    return this.profileContentService;
  }
  // ------------------------------------------------------------------
  setProfileInfoService (ps : ProfileInfoService) : void {
    this.profileInfoService = ps;
  }
  getProfileInfoService () : ProfileInfoService {
    return this.profileInfoService;
  }
  // ------------------------------------------------------------------
  setProfileNameService (pis : ProfileNameService) : void {
    this.profileNameService = pis;
  }
  getProfileNameService () : ProfileNameService {
    return this.profileNameService;
  }
  // ------------------------------------------------------------------
  setProfilePicsService ( ps : ProfilePicsService ) : void
  {
    this.profilePicsService = ps;
  }
  getProfilePicsService () : ProfilePicsService
  {
    return this.profilePicsService;
  }
  // ------------------------------------------------------------------
}
