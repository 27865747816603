import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';

@Component({
  selector: 'app-a-glyphicon-component',
  templateUrl: './aGlyphicon.component.html',
  styleUrls: [ './aGlyphicon.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AGlyphiconComponent implements OnInit {
  // ----------------------------------------------
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  @Input() kvGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  @Input() i = 0;
  // ----------------------------------------------
  constructor (private router : Router,
    private cdr : ChangeDetectorRef,
    @Inject(Document) document : any) {
  }

  ngOnInit () : void {
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------------------------
  setGlyphAction (kvg : KvMemberActivityGlyph) : void {
    EmitterSubjectService.emitKvMemberActivityGlyph(kvg);
  }
}
