
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DomService } from './domService.service';
import { SpinnerOverlayService } from './spinnerOverlayService';

@Injectable({
  providedIn: 'root',
})
export class ModalServiceService implements OnDestroy {
  private emitterDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private domService : DomService,
    private spinnerOverlayService: SpinnerOverlayService
  ) { }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ------------------------------------------------------------------------------------------------
  setDomService (ds : DomService): void {
    this.domService = ds;
  }
  // ------------------------------------------------------------------------------------------------
  getDomService () : DomService {
    return this.domService;
  }
  // ------------------------------------------------------------------------------------------------
   setSpinnerOverlayService (sos : SpinnerOverlayService): void {
    this.spinnerOverlayService = sos;
   }
  // ------------------------------------------------------------------------------------------------
   getSpinnerOverlayService () : SpinnerOverlayService {
    return this.spinnerOverlayService;
   }
  // ------------------------------------------------------------------
  // setPagerServiceChatHistory(chps: PagerServiceChatHistory): void {
  // this.pagerServiceChatHistory = chps;
  // }
  // getPagerServiceChatHistory(): PagerServiceChatHistory {
  // return this.pagerServiceChatHistory;
  // }
  // ------------------------------------------------------------------------------------------------
  // setPagerServicePhotoMgmt(psp: PagerServicePhotoMgmt): void {
  //  this.pagerServicePhotoMgmt = psp;
  // }
  // // ------------------------------------------------------------------------------------------------
  // getPagerServicePhotoMgmt(): PagerServicePhotoMgmt {
  //  return this.pagerServicePhotoMgmt;
  // }
  // ------------------------------------------------------------------
}
