'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { StringServiceStatic } from '../../../services/staticServices/stringServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';

@Component({
  selector: 'app-logo-menu',
  templateUrl: './logoMenu.component.html',
  styleUrls: [ './logoMenu.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoMenuComponent implements OnInit, OnDestroy, AfterViewInit
{
  public redirectionService! : RedirectionService;
  public deviceInfo : any;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isPhotoMgmt = false;
  public isSuccess = true;
  public isViewMember = false;
  public index = -1;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message : string = '';
  public pageTitle = '';
  @Input() pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public sitUser : SitUser = new SitUser();
  public signedInUserId = 0;
  public timer : any;
  public timerArray : any[] = [];
  public userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  constructor (
    private commonServiceService: CommonServiceService,
    private cdr : ChangeDetectorRef,
    private router : Router,  )
  {
    this.redirectionService = this.commonServiceService.getRedirectionService();
  }

   //  -------------------------------------------------------------------------
  ngOnInit () : void {

    this.isMobilevar = EmitterSubjectService.getIsMobile();
    //  -------------------------------------------------------------------------
    // debugger;
    EmitterSubjectService.loginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        this.loginSuccess = result as LoginSuccess; //  JSON.parse(JSON.stringify(result));
        // debugger;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();

      });
  }
  //  ---------------------------------------------------------------------------------
  ngAfterViewInit () : any
  {
    this.cdr.detectChanges();
    return true;
  }
  //  ---------------------------------------------------------------
  ngOnDestroy (): void
  {
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );
  }
  // ---------------------------------------------------------------------------------
  public executeLoginSuccessTasks () : any
  {
    if (this.loginSuccess.signedInUserId > 0) {
      this.isSuccess = this.loginSuccess.isSuccess;
      this.signedInUserId = this.loginSuccess.signedInUserId;
      // debugger;
      return this.loginSuccess;
    }
  }
  // --------------------------------------------------------------
  public setRoute (route : string, id : number) : void {
     debugger;
    this.redirectionService.setRoute(this.router, route, id).subscribe(data => {
      this.pagerBrdCrm = data;
    });

  }
  // ----------------------------------------------------------------
}
