
import { ElementRef, Injectable, NgZone, OnDestroy, Renderer2 } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { Photo } from '../../models/common/photo.model';
import { PhotoMgmtAction } from '../../models/common/photoMgmtAction.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { DefaultSetterServiceStatic } from '../staticServices/defaultSetterServiceStatic.service';
import { DomUtilsServiceStatic } from '../staticServices/domUtilsServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { MessageBuilderServiceStatic } from '../staticServices/messgeBuilderServiceStatic.service';
import { RendererServiceService } from './rendererServiceService.service';
import { PagerPhotoMgmt } from '../../models/pagination/pagerPhotoMgmt.model';

 /*
  *  ===============================================================
  *   Begin of background-image-animation-system' aka BIAS
  *  ---------------------------------------------------------------
  *   BIAS has three main components to it which are:
  *   1.  Time-Rotater-Component
  *   2.  Various control-mechanism-methods
  *   3.  Image-Setter-Component
  *  ---------------------------------------------------------------
  */


// TODO: Turn this class into a Static class. Follow the example of DomUtilServiceStatic & RedirectionServiceStatic

@Injectable({
  providedIn: 'root',
})
export class BackgroundImageAnimationService implements OnDestroy {
  public renderer! : Renderer2;

  public elementRef! : ElementRef; // partOf Lazy-UnLoading
  // public animations : any[] = [ Animations, slideInOutAnimation ];
  public animationDelayMs = 8000;
  public avatarElementRef! : ElementRef;
  // public biasImagesModel: BiasImagesModel = new BiasImagesModel();
  // public biasImagesModelArr : BiasImagesModel[] = [];
  public biasImages : any[] = [];
  public biasKvPhotoArr : KvPhoto[] = [];
  public bottom = 'photos/gay-couple-1.jpg'; // default value
  public currentPageNo = 0;
  // these images are in 'wwwroot/assets/photos/' folder
  public defaultImageNameArr : string[] = [
    '/assets/photos/gay-couple-1.jpg',
    '/assets/photos/gay3.jpg',
    '/assets/photos/leatherCouple-2.png',
    '/assets/photos/gay1.1.png',
    '/assets/photos/leatherMan-2.png',
    '/assets/photos/gay2.1.png',
    '/assets/photos/leatherMan-4.png',
    '/assets/photos/leatherMasterSlave.png',
  ];
  public defaultKvPhotoArr : KvPhoto[] = [];
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public enums : any;
  public enumDataObservable : any;
  public indx = 0;
  public isDefault = true;
  public isMobilevar = false;
  public isMouseIn = false;
  public isOnLine = false;
  public isPhotoMgmt = false;
  public isSetPage = false;
  public isStop = false;
  public isUserDataCleared = false;
  public isViewMember = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public pbgTimer : any;
  public photoMgmtAction : PhotoMgmtAction = new PhotoMgmtAction();
  public pauseTimer : any;
  public profileImageKvPhotos : KvPhoto[] = [];
  public profileTile : ProfileTile = new ProfileTile();
  public signedInUserId = 0;
  public sitUser : SitUser = new SitUser();
  public tempElem : any;
  public timer : any;
  public timerArray : any[] = [];
  public top = 'photos/leatherCouple-2.png'; // default value
  public window ! : Window;
  public yourLoginSuccess : LoginSuccess = new LoginSuccess();

  constructor (
    private rendererService : RendererService,
    private ngZone : NgZone,
    public document : Document,
  ) {
    if (this.rendererService) {
      this.renderer = this.rendererService.getRenderer();
    }
    this.document = EmitterSubjectService.getDocument();
    this.window = EmitterSubjectService.getWindow();
    this.defaultKvPhotoArr = this.setDefaultImageNamesToKvPhotoArr(this.defaultImageNameArr);
    //  -----------------------------------------------------------------
    this.defaultKvPhotoArr = this.setDefaultImageNamesToKvPhotoArr(this.defaultImageNameArr);
    // this.startRotateBiasAnimation( this.defaultKvPhotoArr);
    // ---------------------------------------------------
    this.elementRef = EmitterSubjectService.getElementRef();
    // ---------------------------------------------------
    EmitterSubjectService.currentPageEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        if (result instanceof KvPhoto) {
          // debugger;
          // this.setKvPhotoBackgroundImagePrimaryOrOtherwise(result);
          result = this.setupKvPhotoIfPrimaryOrPrivate(result);
          this.updateBackgroundImageOutsideAngular(result.value);
          if (result?.elemId?.indexOf('bodyId') === -1 && result?.elemId?.indexOf('pagerVKvPhotoNav') !== -1) {
            this.updateBackgroundImageOutsideAngularById(result.elemId, result.value);
          }
        }
        this.emitterDestroyed$.unsubscribe();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.isPhotoMgmtEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.isPhotoMgmt = result as boolean;
        
        this.emitterDestroyed$.unsubscribe();
        //  debugger;
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.isViewMemberEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.isViewMember = result as boolean;
       
        this.emitterDestroyed$.unsubscribe();
        //  debugger;
      });
    // --------------------------------------------------------------------------
    EmitterSubjectService.setSignedInUserBackgroundImageEmitter
     .pipe( takeUntil( this.emitterDestroyed$ ) )
     .subscribe( ( result ) =>
     {
       // debugger;
       this.setSignedInUserBackgroundImage();

       this.emitterDestroyed$.unsubscribe();
     } );

    // --------------------------------------------------------------------------
    //EmitterSubjectService.profileImagesEmitter
    // .pipe( takeUntil( this.emitterDestroyed$ ) )
    // .subscribe( ( result ) =>
    // {
    //   // debugger;
    //   if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
    //   {
    //     this.isDefault = false;
    //     // debugger;
    //     this.startBiasKvPhotoArrAnim( result );
    //   }

    //   this.emitterDestroyed$.unsubscribe();
    // } );

    // --------------------------------------------------------------
    EmitterSubjectService.profileTileEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger;
        this.profileTile = result as ProfileTile;
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile.primaryImage)) {
          this.isDefault = false;
          // debugger;
          this.setProfileTileBackgroundImage(this.profileTile);

          this.emitterDestroyed$.unsubscribe();
        }
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.resetDbgiEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        if (this.isDefault || this.loginSuccess.signedInUserId === 0) {
          // debugger;
          this.isDefault = this.startDefaultAnimation();;

        }

        // this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // this.sitUser = EmitterSubjectService.getSitUserModel();
        // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0)
        // {
        //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0)
        //  {
        //    if (this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
        //      // debugger;
        //      //let pic = CopyServiceStatic.copyFromLoginSuccessToKvPhoto(this.loginSuccess);
        //      //this.isDefault = false;
        //      //this.stopBiasAnimation();
        //      //this.startBiasKvPhotoArrAnim([ pic ]);
        //      this.setSitUserBackgroundImage(this.sitUser);
        //    }
        //    else {
        //      // debugger;
        //      this.setSignedInUserBackgroundImage();
        //    }
        //  }
        //  else if(this.isDefault) {
        //    // debugger;
        //    this.isDefault = this.startDefaultAnimation();;

        //  }
        // }
        // else
        // {
        //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)
        //    && this.sitUser.sitUserId > 0
        //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser.primaryImage)
        //    && this.sitUser.primaryImage.length > 0) {
        //    // debugger;
        //    this.setSitUserBackgroundImage(this.sitUser);
        //  }
        //  // else if (this.isDefault){
        //  //  // debugger;
        //  //  this.isDefault = this.startDefaultAnimation();

        //  // }         
        // }
        this.emitterDestroyed$.unsubscribe();
        //  debugger;
      });
    // --------------------------------------------------------------
    // this.biasImages = this.getPicArrFromKvPhotoArr( this.biasKvPhotoArr);
  }
  //  ---------------------------------------------------------------
  ngOnDestroy () {
    /*
     *  prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *         (2) https://www.concretepage.com/angular-2/angular-4-renderer2-example
     *         (3) https://stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *         (4) https://stackblitz.com/edit/timer-with-pdestroyed
     */
    clearInterval(this.pauseTimer);
    clearInterval(this.pbgTimer);
    this.timerArray.forEach((timer) => clearInterval(timer));
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();   

    this.emitterDestroyed$.unsubscribe();
    // debugger;
  }

  // ===================================================================================
  // BEGIN of  Background Image Animation System  or BIAS
  // ===================================================================================
  // ----------------------------------------------------------------
  // BIAS: Background Image Animation System
  // Author: Sayeed Rahman
  // Date: 2021/03/22;7:41pm; Ottawa, ON, Canada
  // (c) Copyright.
  // ===========================================

  // ----------------------------------------------------------------
  getNavHeaderElementById (id : string) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      this.elementRef = EmitterSubjectService.getElementRef();
      this.tempElem = document.getElementById(id);
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem)) {

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.elementRef)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.elementRef.nativeElement)) {
          this.tempElem = this.elementRef.nativeElement.querySelector(id);
        }
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem)) {
        this.tempElem = this.elementRef.nativeElement.querySelector('#' + id);
      }
      // debugger;
      return this.tempElem;
    }
  }
  // ----------------------------------------------------------------
  getCurrentBackgroundPageNo () : number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.biasKvPhotoArr) && this.biasKvPhotoArr.length > 0) {
      // to calculate for a new page, consider the current page as stale, so increment to get the next page
      this.currentPageNo++;
      if (this.currentPageNo % (this.biasKvPhotoArr.length + 1) === 0) {
        this.currentPageNo = 1;
      }
    }
    return this.currentPageNo;
  }
  // ----------------------------------------------------------------
  getCurrentBackgroundPageIndex () : number {
    let currPageNo = this.getCurrentBackgroundPageNo();
    // since we are sending the index, decrement the the currentPageNumber
    return --currPageNo;
  }

  // ----------------------------------------------------------------
  public getBiasImage (index : number) : any {
    if (index > -1 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.biasKvPhotoArr)) {
      let bgi;
      if (this.biasKvPhotoArr.length === 0
        || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.biasKvPhotoArr[ index ])
        || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.biasKvPhotoArr[ index ].value)) {
        this.biasKvPhotoArr = this.defaultKvPhotoArr = this.setDefaultImageNamesToKvPhotoArr(this.defaultImageNameArr);
      }
      if (this.biasKvPhotoArr.length > index) {
        // bgi = 'url(\'' + this.bgis[ index ].value + '\')';
        // return  JsRegExpServiceStatic.normalizeImageData( this.biasKvPhotoArr[ index ].value );
        // debugger;
        return this.biasKvPhotoArr[ index ].value;
      }
      else {
        // debugger;
        return 'url(\'/assets/photos/leatherCouple-1.png\')';
      }
    }
    else {
      // debugger;
      return 'url(\'/assets/photos/leatherCouple-1.png\')';
    }
  }
  // -----------------------------------------------------------
  nullPromise (data : any) : any {
    this.timer = setTimeout(() => {
      // debugger;
      return data;
    }, 500);
    clearTimeout(this.timer);
    if (this.timer) {
      this.timerArray.push(this.timer);
    }
  }
  // ----------------------------------------------------------------
  rotateBias (i : number, kvPhotoArr : KvPhoto[]) : number {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0 && i <= kvPhotoArr.length
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr[ i ]) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr[ i ].value)) {
      let imageDataUrl = kvPhotoArr[ i ].value; // StringServiceStatic.deepCloneJson( bKvPhotoArr[ i ].value ) as string;
      // debugger;
      this.updateBackgroundImageOutsideAngular(imageDataUrl);

      i === kvPhotoArr.length - 1 ? i = 0 : i++;
    }
    return i;
  }
  // ----------------------------------------------------------------
  startBiasAnimation (pics : KvPhoto[]) : number {
    this.indx = 0;

    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pics) && pics.length > 0) {
      this.timer = setTimeout(() => {
        pics.map(e => {
          this.indx++;
          this.updateBackgroundImageOutsideAngular(e.value);

          return this.indx;
          // debugger;
          // this.indx = this.rotateBias( this.indx, this.biasKvPhotoArr );
          // EmitterSubjectService.emitRunBiasModuleComponentNgAfterViewInit( true ); //TODO: uncomment if biasModule is used. Currently it is not in use      
        });
      }, 15000);
      clearTimeout(this.timer);
      this.timerArray.push(this.timer);
    }
    return this.indx;
  }
  // ----------------------------------------------------------------
  private startBiasKvPhotoArrAnim (pics : KvPhoto[]) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pics) && pics.length > 0) {
      // rotate the profileImages:      
      // set the first image right away, as others are intervaled with time

      // debugger;
      // let imageDataUrl = pics[ pics.length - 1 ].value; // always show the latest on the array
      let i = 0;
      let imageDataUrl = '';
      this.timer = setInterval(() => {
        imageDataUrl = pics[ i ].value;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageDataUrl)) {
          this.updateBackgroundImageOutsideAngular(imageDataUrl);
          // debugger;
        }
        i === pics.length - 1 ? i = 0 : i++;
        // debugger;
        clearInterval(this.timer);
      }, 15000);

      clearInterval(this.timer);
      this.timerArray.push(this.timer);

      // Remove before deployment:
      //if ( pics.length > 1 )
      //{
      //  // debugger;
      //  this.rotateBias( pics );
      //}
      //else
      //{
      //  // debugger;
      //  this.timerArray.map( t => clearInterval( t ) );
      //}
    }
  }
  // ---------------------------------------------------------------
  //  Note: Independent-Image-Setting-System (IISS): Begin
  //  This system will display any image centered and fully covered
  //  Caution: PagerVKvPhotoNav UI is not visible unless isViewMember == true !!
  // ---------------------------------------------------------------
  setPagerVNavKvPhotoImage (kvp : KvPhoto) : void {
    // debugger;
    this.isViewMember = EmitterSubjectService.getIsViewMember();

    // ----------------------------------------------------
    // if PagerVKvPhotoNav is not visible, make it visible.
    // othgerwise domUtilsService will not be able to find
    // the respective html-Element.
    //
    //  isViewMember may have not been emitted by other services/components,
    //  instead they might have been set, in which case
    //  appComponent minght not have the updated isValueMemer's value
    //  and open the UI because it only listens to the emitter
    //  and acts accordingly.
    // ----------------------------------------------------
    if (!this.isViewMember) {
      this.isViewMember = true;
    }
    EmitterSubjectService.setIsViewMember(this.isViewMember);    
    // ----------------------------------------------------

    DomUtilsServiceStatic.setDomUtilsElementRef(this.elementRef);
    let elem : any;
    let tImageData = '';
    let imageData: any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.elemId)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
      // debugger;
      elem = document.getElementById(kvp.elemId);
      // debugger;

      // debugger;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
        // debugger;
        elem = DomUtilsServiceStatic.getElementById(kvp.elemId);
        // debugger;
      }
      // debugger;
      if (StringServiceStatic.typeCheck(kvp.value).indexOf("Object") !== -1) {
        tImageData = kvp.value.pic;
      }
      else {
        tImageData = kvp.value;
      }

      //let imageData = JsRegExpServiceStatic.normalizeImageData(tImageData); // test-2

      if (tImageData.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1) {
        tImageData = JsRegExpServiceStatic.normalizeImageData(tImageData);
        tImageData = JsRegExpServiceStatic.setUrlForImageData(tImageData);
      }
      else tImageData = 'url(' + tImageData + ')';

      let imageData = tImageData;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
          // debugger
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.renderer)) {
            // debugger;
            this.renderer.setStyle(elem, 'background-image', imageData);
          }
          else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem.nativeElement)) {
            // debugger;
            elem.nativeElement.style.background = imageData;
          }
        }
      }
    }
  }
  // ----------------------------------------------------------------
  selectCurrentElemDeselctOthersForKvMAGlyph (kvgArr : KvMemberActivityGlyph[], item : KvMemberActivityGlyph, prefix : any) : void {
    let currentPageElemId = '';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item.elemId) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(prefix)) {
      if (item.itemNum >= 0) {
        // debugger;
        currentPageElemId = prefix + item.itemNum.toString();
      }
      else if (item.index >= 0) {
        // debugger;
        currentPageElemId = prefix + item.index.toString();
      }
      else {
        // debugger;
        currentPageElemId = prefix + '0';
      }
    }

    let currentItemElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentPageElemId)) {
      currentItemElem = document.getElementById(currentPageElemId) as HTMLElement;
    }

    // debugger;
    // highlight PrimaryImage in deepskyblue color:
    // --------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentItemElem)) {
      // debugger;
      // this.bias.removeClassForElement(currentItemElem, 'smallerCircle');
      this.addClassForElement(currentItemElem, 'smallerPrimary');
      // currentItemElem.remove();
    }
    // debugger;
    // remove highlight active-Image in array in gold color:
    // ----------------------------------------------
    let elemId = prefix;
    let tempElemIdStr = '';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvgArr) && kvgArr.length > 0) {
      for (let i = 0; i < kvgArr.length; i++) {
        if (kvgArr[ i ].photoBnId !== item.photoBnId) {

          // try getting the element using itemNum:
          // --------------------------------------
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvgArr[ i ].elemId)) {
            tempElemIdStr = elemId + kvgArr[ i ].itemNum.toString();
          }
          else {
            tempElemIdStr = kvgArr[ i ].elemId;
          }
          let tempElem = document.getElementById(tempElemIdStr) as HTMLElement;

          // try getting the element using index if element is still null or undefined:
          // --------------------------------------------------------------------------
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
            tempElemIdStr = elemId + kvgArr[ i ].index.toString();
          }
          else {
            tempElemIdStr = kvgArr[ i ].elemId;
          }
          tempElem = document.getElementById(tempElemIdStr) as HTMLElement;

          // now add the class:
          // ------------------
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
            // this.bias.removeClassForElement(tempElem, 'smallerPrimary');
            this.addClassForElement(tempElem, 'smallerCircle');
            // debugger;
            break;
          }
        }
      }
    }
    // will draw the body-bg with item.value:
    // --------------------------------------
    // EmitterSubjectService.emitCurrentPage(item);
  }
  // ----------------------------------------------------------------
  addPrimaryOrOtherClassToKvPhotoElement (item : KvPhoto) : KvPhoto {
    let currentPageElemId = '';
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item)) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item.elemId)) {
        if (item.itemNum >= 0) {
          // debugger;
          currentPageElemId = 'pagerVKvPhotoNav-' + item.itemNum.toString();
        }
        else if (item.index >= 0) {
          // debugger;
          currentPageElemId = 'pagerVKvPhotoNav-' + item.index.toString();
        }
        else {
          // debugger;
          currentPageElemId = 'pagerVKvPhotoNav-0';
        }
        item.elemId = currentPageElemId;
      }
      else {
        currentPageElemId = item.elemId;
      }

      let currentItemElem : any;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentPageElemId)) {
        currentItemElem = document.getElementById(currentPageElemId) as HTMLElement;
      }

      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      this.sitUser = EmitterSubjectService.getSitUserModel();
      let isPrimary = false;
      // case: signedInUser:
      // -------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
        && (item.photoBnId === this.loginSuccess.primaryPhotoBnId || item.photoBnId === this.sitUser.primaryPhotoBnId
          || item.photoId === this.loginSuccess.primaryPhotoBnId || item.photoId === this.sitUser.primaryPhotoBnId)) {
        item.isPrimary = true;
        isPrimary = true;
      }

      // debugger;
      // highlight PrimaryImage in deepskyblue color:
      // --------------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentItemElem)) {
        if (isPrimary) {
          // debugger;
          // this.removeClassForElement(currentItemElem, 'smallerCircle');
          this.addClassForElement(currentItemElem, 'smallerPrimary');
        }
        else {
          // this.removeClassForElement(currentItemElem, 'smallerPrimary');
          this.addClassForElement(currentItemElem, 'smallerCircle');
        }
        // currentItemElem.remove();
      }
    }
    return item;
  }
  // ----------------------------------------------------------------
  selectCurrentElemDeselctOthersForKvPhoto (kvpArr : KvPhoto[], item : KvPhoto) : void {
    let currentPageElemId = '';
    let tElemId = 'pagerVKvPhotoNav-';
    let tempElemIdStr = '';

    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.sitUser = EmitterSubjectService.getSitUserModel();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item)) {

      // remove highlight of any active-Image in array :
      // -----------------------------------------------     
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
        for (let i = 0; i < kvpArr.length; i++) {          
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr[ i ])) {
            if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr[ i ].elemId)) {
              tempElemIdStr = tElemId +  i.toString();
            }
            else {
              tempElemIdStr = kvpArr[ i ].elemId;
            }
          }
          let tempElem = document.getElementById(tempElemIdStr) as HTMLElement;
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
            this.removeClassForElement(tempElem, 'smallerCircle');
            // debugger;
            this.removeClassForElement(tempElem, 'smallerPrimary');
          }         
        }
      }

      if (item.photoBnId > 0)
      {
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item.elemId))
        {
          if (item.index >= 0) {
            // debugger;
            currentPageElemId = 'pagerVKvPhotoNav-' + item.index.toString();
          }
          else {
            // debugger;
            currentPageElemId = 'pagerVKvPhotoNav-0';
          }
        }
        else {
          currentPageElemId = item.elemId;
			  }
        // debugger;
        let currentItemElem : any;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentPageElemId)) {
          currentItemElem = document.getElementById(currentPageElemId) as HTMLElement;
        }

        // debugger;
        // highlight PrimaryImage in deepskyblue color:
        // --------------------------------------------
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentItemElem)) {
          if (item.isPrimary && item.privacy.toLocaleLowerCase().indexOf('primary') !== -1) {
            // debugger;
            this.addClassForElement(currentItemElem, 'smallerPrimary');
          }
          else {
            this.addClassForElement(currentItemElem, 'smallerCircle');
          }
        }        
      }      
    }
  }

  // ---------------------------------------------------------------------------
  public toggleClassOutsideAngular (elemId : string, className : string) : any {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      this.tempElem = document.getElementById(elemId);
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem)) {
      const outsideClasses = this.tempElem.classList;

      if (outsideClasses.contains(className)) {
        this.renderer?.removeClass(this.tempElem, className);
        //if (!outsideClasses.contains('backImage')) {
        //  this.renderer?.addClass(this.tempElem, 'backImage');
        //}
      } else {
        this.renderer?.addClass(this.tempElem, className);
        //if (outsideClasses.contains('backImage')) {
        //  this.renderer?.removeClass(this.tempElem, 'backImage');
        //}
      }
    }
    return true;
  }
  // ---------------------------------------------------------------------------
  //  Note: This method changes the style of the <body> element that is outise of Angular
  // ---------------------------------------------------------------------------
  public updateStyleOutsideAngular ( elemId: string, styleName: string, styleValue:string ): void
  {
    let tElem: any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(styleName)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(styleValue)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId))
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( document ) )
      {
        tElem = document.getElementById( elemId ) as HTMLElement;
      }

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.ngZone)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.renderer))
      {
        this.ngZone.runOutsideAngular( () =>
        {
          // debugger;
          (this.renderer as Renderer2).setStyle(tElem, styleName, styleValue );
          // ( this.renderer as Renderer2 ).removeClass( tElem, 'bgImageCentered' );
          // ( this.renderer as Renderer2 ).addClass( tElem, 'bgImageStillCentered' );
          // -----------------------------------------------------------------------------
          // Note: DO NOT Delete the below commented code!
          // This moves the background pic like a pendulam when 'bgImageCentered' is added
          // otherwise the image is not moving or doing any animation
          // -----------------------------------------------------------------------------
          //if ( !this.isDefault )
          //{
          //  // ( this.renderer as Renderer2 ).removeClass( tElem, 'bgImageCentered' );
          //  ( this.renderer as Renderer2 ).addClass( tElem, 'bgImageStillCentered' );
          //}
          //else
          //{
          //  ( this.renderer as Renderer2 ).removeClass( tElem, 'bgImageStillCentered' );
          //  ( this.renderer as Renderer2 ).addClass( tElem, 'bgImageCentered' );
          //}
        });
      }
    }
  }
  // ---------------------------------------------------------------------------
  public updateBackgroundImageOutsideAngular (image : string) : void {
    let imageData = image;
    let tElem : any;
    let elemId : string = 'bodyId';
    
    tElem = DomUtilsServiceStatic.getElementById(elemId) as HTMLElement;
    
    // debugger;
    if(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      if (!image.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
        imageData = JsRegExpServiceStatic.setUrlForImageData(image);
      }
      else if (!image.match(JsRegExpServiceStatic.photoUrlPrefixPattern)) {
        imageData = 'url(\'' + image + '\')';
      }
      else {
        imageData = image;
      }
      if (!image.match(JsRegExpServiceStatic.photoUrlPrefixPattern)) {
        imageData = 'url(\'' + image + '\')';
      }
    }
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
      
    }
    this.renderer = this.rendererService.getRenderer();
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.ngZone)) {
      this.ngZone.runOutsideAngular(() => {
        this.renderer.setStyle(tElem, 'background-image', imageData);
        // this.addClassForElement(tElem, 'fade-in');
        // debugger;
      });
    } else {
      MessageBuilderServiceStatic.printMessage(0, 'in updateBackgroundImageOutsideAngular() imageData==null or tElem==null or ngZone == null');
    }
  }
  // ---------------------------------------------------------------------------
  public updateBackgroundImageOutsideAngularById (id : string, image : string) : void {
    let imageData : any;
    let tElem : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      tElem = DomUtilsServiceStatic.getElementById(id);
    }
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
      if (image.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
        imageData = image;
      }
      else {
        if (!image.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
          imageData = JsRegExpServiceStatic.setUrlForImageData(image);
        }
        else if (!image.match(JsRegExpServiceStatic.photoUrlPrefixPattern)) {
          imageData = 'url(\'' + image + '\')';
        }
        else {
          imageData = image;
        }
      }
    }
    else {
      imageData = 'url(\' \')'; // single white space in string
    }
    this.renderer = this.rendererService.getRenderer();
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
      imageData = 'url(\' \')'; // single white space in string
    }
    if( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.ngZone)) {
      this.ngZone.runOutsideAngular(() => {
        this.renderer.setStyle(tElem, 'background-image', imageData);
        // this.addClassForElement(tElem, 'fade-in');
        // debugger;
      });
    }
    else {
      MessageBuilderServiceStatic.printMessage(0, 'in updateBackgroundImageOutsideAngularById() imageData==null or tElem==null or ngZone == null');
		}
  }
  // -----------------------------------------------------------------------------------
  // ===================================================================================
  // END of Background Image Animation System  or BIAS
  // ===================================================================================

  // ===================================================================================
  // BEGIN of Default Background Image setting mechanism
  // ===================================================================================
  // -----------------------------------------------------------------------------------
  public setPhotoToKvPhoto (photo : Photo) : KvPhoto {
    const kvP = new KvPhoto();
    kvP.index = 0;
    kvP.itemNum = 1;
    kvP.key = 'defaultImage-' + 0; // TODO: check if it needs to use only the file names
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photo.imageString)) {
      
      // TODO: may make a composite key like this: "photoId-" + photo.photoId.toString()+ ",photoBnId-" + photo.photoBnId.toString():
      kvP.key = photo.photoId.toString();

      kvP.value = JsRegExpServiceStatic.normalizeImageData(photo.imageString);
      kvP.value = JsRegExpServiceStatic.setUrlForImageData(kvP.value);
    }
    else {
      kvP.value = 'url(\'\/assets\/photos\/DefaultImageMale.jpg\')';
    }
    return kvP;
  }
  // -----------------------------------------------------------------------------------
  public setImageStringToKvPhoto ( imageStr: string ): KvPhoto
  {
    const kvP = new KvPhoto();
    kvP.index = 0;
    kvP.itemNum = 1;
    kvP.key = 'defaultImage-' + 0; // TODO: check if it needs to use only the file names
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( imageStr ) )
    {
      kvP.value = JsRegExpServiceStatic.normalizeImageData(imageStr);

      kvP.value = JsRegExpServiceStatic.setUrlForImageData(kvP.value);      
    }
    else
    {
      kvP.value = 'url(\'\/assets\/photos\/DefaultImageEmoji.jpg\')';
    }
    return kvP;
  }
  // -----------------------------------------------------------------------------------
  public setDefaultImageNameArrToKvPhotoArr (): KvPhoto[]
  {
    return this.setDefaultImageNamesToKvPhotoArr( this.defaultImageNameArr );
  }
  // -----------------------------------------------------------------------------------
  public setDefaultImageNamesToKvPhotoArr ( dImageNames: string[] ): KvPhoto[]
  {
    const tBgis: KvPhoto[] = [];

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dImageNames ) && dImageNames.length > 0 )
    {
      let i = 0;

      dImageNames.map( ( e ) =>
      {
        const kvP = new KvPhoto();

        kvP.index = i;
        kvP.itemNum = i + 1;
        kvP.key = 'defaultImage-' + i.toString(); // TODO: check if it needs to use only the file names
        kvP.value = e;
        tBgis.push( kvP );
        i++;
      } );
    }
    // debugger;
    this.biasKvPhotoArr = tBgis;
    return this.biasKvPhotoArr;
  }
  // ===================================================================================
  // END of Default Background Image setting mechanism
  // ===================================================================================


  // ===================================================================================
  // Note: This is a non-default single image renderer.
  // -----------------------------------------------------------------------------------
  public setBackgroundStillImageForHTMLElement ( elem: HTMLElement, imageUrl: string ): any
  {
    let imageData : any;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( elem ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( imageUrl ) )
    {
      // let image = JsRegExpServiceStatic.normalizeImageData( imageUrl ) as string;
      if (imageUrl.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1) {
        imageData = JsRegExpServiceStatic.normalizeImageData(imageUrl);
        imageData = JsRegExpServiceStatic.setUrlForImageData(imageData);
      }
      else imageData = 'url(' + imageData + ')';

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( imageData.toString() ) )
      {
        // debugger;
        // this.stopBiasAnimation();
        this.biasKvPhotoArr = [ this.setImageStringToKvPhoto( imageData ) ];
        // debugger;
        this.startBiasKvPhotoArrAnim( this.biasKvPhotoArr );

        //  ( this.renderer as Renderer2 ).setStyle( elem, 'background-image', image );
      }
      return true;
    }
    else return false;
  }
  // -----------------------------------------------------------------------------------

  public setBackgroundStillImage ( imageUrl: string ): any
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( imageUrl ) )
    {
      // debugger;
      return this.setBackgroundStillImageForElemId( 'bodyId', imageUrl);
    }
    else return false;
  }
  // -----------------------------------------------------------------------------------
  public setBackgroundStillImageForElemId ( elemId: string, imageUrl: string ): any
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( elemId ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( imageUrl ) )
    {
      let tElem = document.getElementById( elemId ) as HTMLElement;
      return this.setBackgroundStillImageForHTMLElement(tElem, imageUrl);
    }
    else return false;
  }
  // ------------------------------------------------------------------------------------
  getPicArrFromKvPhotoArr ( kvPhotoArr : KvPhoto[] ) : any
  {
    let picArr:any[] = [];
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( kvPhotoArr ) && kvPhotoArr.length > 0 )
    {
      kvPhotoArr.map( e =>
      {
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e.value ) )
        {
          picArr.push( e.value as string);
        }
      } );
    }
    return picArr;
  }
   // ===================================================================================
  // ===================================================================================
  // BEGIN of BIAS API
  // ===================================================================================
  //  ----------------------------------------------------------------------------------
  getDefaultImageNameArr (): any[]
  {
    return this.defaultImageNameArr;
  }
  //  ---------------------------------------------------------------
  public setBiasKvPhotoArr ( kvPhotoArr: KvPhoto[] ): any
  {
    // debugger;
    this.biasKvPhotoArr = kvPhotoArr;
  }
  // ----------------------------------------------------------------
  // Note:  Do not use it for a single KvPhoto, instead if possible,
  //        use setSitUserBackgroundImage(profileTile) or
  //        setSignedInUserBackgroundImage() or
  //        setProfileTileBackgroundImage()
  // ----------------------------------------------------------------
  // public startBiasAnimation ( kvPhotoArr: KvPhoto[] ): void
  // {
  //  // debugger;
  //  this.startBiasKvPhotoArrAnim( kvPhotoArr ); 
  //  this.isDefault = false;

  // }
  // ----------------------------------------------------------------
  // Note: This is the only stop animation method.
  // ----------------------------------------------------------------
  public stopBiasAnimation (): boolean
  {
    // debugger;
    this.isDefault = false;
    this.timer = -1;
    this.biasKvPhotoArr = [];
    this.timerArray.forEach((timer) => clearInterval(timer));
    return this.isDefault;
  }
  //  ---------------------------------------------------------------
  //  Note: API: for default-case
  //  Note: Whoever calls this must also run the ngAfterViewInit()
  // ----------------------------------------------------------------
  public startDefaultAnimation (): boolean
  {
    // debugger;
    this.isDefault = true;
    this.defaultKvPhotoArr = this.resetDefaultBias();
    // debugger;
    // ----------------------------------------------------------------------------

    return this.isDefault;
  }
  //  ----------------------------------------------------------------------------------
  public resetDefaultBias (): KvPhoto[]
  {
    // debugger;
    this.isDefault = true;
    if ( this.defaultKvPhotoArr.length === 0 )
    {
      // debugger;
      this.defaultKvPhotoArr = this.setDefaultImageNamesToKvPhotoArr( this.defaultImageNameArr );
    }


    DefaultSetterServiceStatic.clearUserData();

    // debugger;
    this.startBiasKvPhotoArrAnim(this.defaultKvPhotoArr );

    return this.defaultKvPhotoArr;
  }
  // ---------------------------------------------------------------
  //  Note: Whoever calls this must also run the ngAfterViewInit()
  //        API: Observable returns  biasImages or nullPromise
  //
  //  This method is not used by any other methods
  // ----------------------------------------------------------------
  //public startKvPhotoArrAnimation ( kvPhotos: KvPhoto[] ): void
  //{
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( kvPhotos ) && kvPhotos.length > 0 )
  //  {
  //    this.isDefault = false;
  //    this.startBiasKvPhotoArrAnim( kvPhotos );
  //    // debugger;
  //  }
  //}
  //  ---------------------------------------------------------------
  //  Note: API: for any-case: [default, photoMgmt, memberView]
  //  Note: Onbserable returns biasImages or nullPromise.
  //  All-References: none;
  // ----------------------------------------------------------------
  public startKvPhotoAnimation ( pics: KvPhoto[], actionType: string ): void
  {
     // debugger;

    if ( pics && pics.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( actionType ) )
    {
      // debugger;
      switch ( actionType.toLowerCase() )
      {
        case 'profilepics':
        case 'memberview':
        case 'myphotos':
          // debugger;
          this.isDefault = false;
          this.startBiasKvPhotoArrAnim( pics );
          break;
        default:
          // debugger;
          this.isDefault = false;
          this.startBiasKvPhotoArrAnim(pics);
          //this.startDefaultAnimation(); // the default animaton behavior.
          break;
      }
    }
  }
  // ----------------------------------------------------------------
  setAvatarElementRef (aElemRef : ElementRef) :void {
    this.avatarElementRef = aElemRef;
  }
  // ----------------------------------------------------------------
  getAvatarElementById (id : string) : any {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.avatarElementRef)) {
      this.avatarElementRef = EmitterSubjectService.getElementRef();
    }    
    return this.avatarElementRef;
  }
  // -----------------------------------------------------------------
  public setImage (id : string, image: string) : void {
     debugger;
    let imageData : any;
    let tempElem : any;
    let elemRef = EmitterSubjectService.getElementRef();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      tempElem = document.getElementById(id);
    }

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemRef)) {
        tempElem = elemRef.nativeElement.querySelector(id);
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
          tempElem = elemRef.nativeElement.querySelector('#' + id);
          // debugger;
        }
      }
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {

      // imageData = JsRegExpServiceStatic.normalizeImageData(image);
      if (!image.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
        /*imageData = JsRegExpServiceStatic.normalizeImageData(image);*/
        imageData = JsRegExpServiceStatic.setUrlForImageData(imageData);
      }
      //else if (!image.match(JsRegExpServiceStatic.photoUrlPrefixPattern)) {
      //  imageData = JsRegExpServiceStatic.setUrlForImageData(imageData);
      //}

      // setting background-image
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.renderer)) {
        // debugger
        // this.renderer?.setStyle(tempElem, 'background-image', imageData);
        this.updateBackgroundImageOutsideAngularById(id, imageData);
        // EmitterSubjectService.emitRunNgAfterViewInit(true);
      }
    }
  }
  // -----------------------------------------------------------------
  public clearPrimaryImage () : void {
    this.updateBackgroundImageOutsideAngularById('primaryPhotoBnId', ' ');    
  }
  // -----------------------------------------------------------------
  public setPrimaryImage (id : string, display: boolean) : void {
    // debugger;
    let imageData : any;
    let tempElem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
    

      this.loginSuccess = EmitterSubjectService.getLoginSuccess(); //  get the latest loginSuccess value
      if (display) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryImage)
              && this.loginSuccess.primaryImage.length > 0) {
            imageData = this.loginSuccess.primaryImage;
            // debugger;
          }
          else {
            imageData = '/assets/photo/DefaultImageMale.jpg'
            // debugger;
          }
        }
        else {
          imageData = '/assets/photo/DefaultImageMale.jpg'
          // debugger;
        }
      }
      else imageData = '/assets/photo/DefaultImageMale.jpg';

      // setting background-image:        
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
        // debugger
        this.updateBackgroundImageOutsideAngularById(id, imageData);
      }
    }
  }
  // -----------------------------------------------------------------------------------
  public setBodyElementBackgroundImage (imageData : string) : void {
    let elem = document.getElementById('bodyId');
    // debugger;
    this.renderer = this.rendererService.getRenderer();
    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.renderer)) {
      // debugger
      if (!imageData.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
        imageData = JsRegExpServiceStatic.setUrlForImageData(imageData); // this is  the only method that shoudl be used because it self-normalizes 
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)
        && !imageData.match(JsRegExpServiceStatic.photoUrlPrefixPattern)) {
        imageData = 'url(\'' + imageData.trim() + '\')'; 
      }
      
     
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
        this.renderer.setStyle(elem, 'background-image', imageData);
        EmitterSubjectService.emitRunNgAfterViewInit(true);
			}
      // debugger;
      // this.updateBackgroundImageOutsideAngular(imageData);
    }
  }
  // -----------------------------------------------------------------------------------
  public setElementBackgroundImage (id : string, imageData : string) : void {
    let elem : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)) {
      elem = this.getNavHeaderElementById(id); // this.getAvatarElementById(id); //
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.renderer)) {
      // debugger
      imageData = JsRegExpServiceStatic.setUrlForImageData(imageData); // this is  the only method that shoudl be used because it self-normalizes 

      
      this.renderer = this.rendererService.getRenderer();
      // debugger;
      // this.renderer.setStyle(elem, 'background-image', imageData);
      this.updateBackgroundImageOutsideAngularById(id, imageData);

      // TODO: watch for relevant components number to fire change detection.
      EmitterSubjectService.emitRunNgAfterViewInit(true);
    }
  }
  // -----------------------------------------------------------------------------------
  chooseLockOrPicforKvPhoto (comparator : string) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(comparator)) {
      switch (comparator.toLowerCase()) {
        case '':
          break;
			}
		}
  }
  // -----------------------------------------------------------------------------------
  public setKvPhotoBackgroundImage (kvp : KvPhoto) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)
      && kvp.value.length > 0) {
      this.isDefault = false;
      // this.stopBiasAnimation();
      this.updateBackgroundImageOutsideAngular(kvp.value);
      // debugger;
      return true;
    }
    else {
      return false;
    }
  }
  
  // -----------------------------------------------------------------------------------
  public setupKvPhotoIfPrimaryOrPrivate (kvp : KvPhoto) : any {
    // debugger;
    let imageStr = '';
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      if (kvp.isUnlocked || this.loginSuccess.signedInUserId == kvp.sitUserId
        || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.privacy)
          && kvp.privacy.toLowerCase().indexOf('public') !== -1)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
          ; // do nothing
        }
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.privacy)
        && kvp.privacy.toLowerCase().indexOf('private') !== -1) {
        imageStr = 'assets/photos/lock.jpg';
        // debugger;
      }
      else
        imageStr = 'assets/photos/lock.jpg';
			}
    return kvp;
  }
  // -----------------------------------------------------------------------------------
  displayBackgroundAndNavImageForKvPhoto (kvp : KvPhoto, pagerVKvPhotoNav?: PagerPhotoMgmt) : KvPhoto {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      // debugger;
      kvp = this.setupKvPhotoIfPrimaryOrPrivate(kvp);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
        this.updateBackgroundImageOutsideAngular(kvp.value);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.elemId)
          && kvp.elemId.indexOf('bodyId') === -1
          && kvp.elemId.indexOf('pagerVKvPhotoNav') !== -1) {
          this.updateBackgroundImageOutsideAngularById(kvp.elemId, kvp.value);
        }
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)
        && pagerVKvPhotoNav.pagedItems.length > 0      ) {
        this.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, kvp.value);
			}
    }
    return kvp;
	}
  // -----------------------------------------------------------------------------------
  // Note: when the user is singedIn, this should be called instead of defaultAnimation.
  // -----------------------------------------------------------------------------------
  public setSignedInUserBackgroundImage ( ): void
  {
    // debugger;
    var primaryImage = '';
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (this.loginSuccess.signedInUserId > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryImage)
      && this.loginSuccess.primaryImage.length > 0) {
      primaryImage = this.loginSuccess.primaryImage;
    }
    else {
      primaryImage = 'assets/photos/DefaultImageMale.jpg'
    }

    this.updateBackgroundImageOutsideAngular(primaryImage);
    
  }
  // -----------------------------------------------------------------------------------
  public setSitUserBackgroundImage (sitUser : SitUser) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser.primaryImage)
      && sitUser.primaryImage.length > 0) {
      this.isDefault = false;
      // this.stopBiasAnimation();
      this.updateBackgroundImageOutsideAngular(sitUser.primaryImage);
      // debugger;
    }
  }
  
  // -----------------------------------------------------------------------------------
  public setProfilePicsBackgroundImage (pPics : ProfilePics) : any
  {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)  
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr) && pPics.filteredKvPhotoArr.length > 0) {
      let imageStr = '';
      for ( var e of pPics.filteredKvPhotoArr){
        if (e && e.privacy == 'public') {
          imageStr = e.value;
          break;
        }
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageStr)) {
        this.isDefault = false;
        // this.stopBiasAnimation();
        this.updateBackgroundImageOutsideAngular(imageStr);
        // debugger;
      }
    }
  }
  // -----------------------------------------------------------------------------------
  // Note: when the user is singedIn, this should be called instead of defaultAnimation.
  // -----------------------------------------------------------------------------------
  public setProfileTileBackgroundImage (pTile: ProfileTile): any
  {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.primaryImage ) )
    {
      this.isDefault = false;
      // this.stopBiasAnimation();
      this.updateBackgroundImageOutsideAngular(pTile.primaryImage);
      // debugger;
    }
  }

  // ===================================================================================
  // END of BIAS API
  // ===================================================================================

 
  //  ---------------------------------------------------------------
  setViewMemberPage ( photo: KvPhoto ): any
  {
    this.isDefault = false;
    this.biasKvPhotoArr = [ photo ];
    // set the first image right away, as others are intervaled with time
    let imageDataUrl = this.biasKvPhotoArr[ 0 ].value;
    this.updateBackgroundImageOutsideAngular( imageDataUrl );
    //this.startRotateBiasAnimation();
  }

  /*
   *  --------------------------------------------------------------
   *   Refactored on 2020/12/29
   *
   *   Set the background image by elemId and KvPhoto for PhotoNavComponent. //  elemId=bodyBackgroundImageId
   *   ref: https:// developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test
   *
   *   TODO: deprecate: as of 20210627
   *  --------------------------------------------------------------
   */
  public setPhotoNavigationPageBackground ( page: KvPhoto ): any
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( page ) )
    {
      this.isDefault = false;
      this.biasKvPhotoArr = [ page ];
      // set the first image right away, as others are intervaled with time
      let imageDataUrl = this.biasKvPhotoArr[ 0 ].value;
      this.updateBackgroundImageOutsideAngular( imageDataUrl );
      // this.startRotateBiasAnimation();
    }
    return true;
  }
  /*
   *  ---------------------------------------------------------------
   *   3)  Image-Setter-Component:
   *   Various image-getter-functions:
   *   END:
   *  ---------------------------------------------------------------
   */

  //  ---------------------------------------------------------------
  public removeClass ( elemId: string, className: string ): any
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( elemId ) )
    {
      this.tempElem = document.getElementById( elemId );
    }

    this.tempElem = document.getElementById( elemId );
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.tempElem ) )
    {
      const outsideClasses = this.tempElem.classList;

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( className ) && outsideClasses.contains( className ) )
      {
        this.renderer?.removeClass( this.tempElem, className );
      }
    }
    return true;
  }
  //  ---------------------------------------------------------------
  public removeClassForElement (elem : HTMLElement, className : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
      const outsideClasses = elem.classList;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outsideClasses)) {
        if (outsideClasses.contains(className)) {
          this.renderer?.removeClass(elem, className);
        }
      }
    }
  }
  //  ---------------------------------------------------------------
  public addClass ( elemId: string, className: string ): any
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( elemId ) )
    {
      this.tempElem = document.getElementById( elemId );
    }

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.tempElem ) )
    {
      const outsideClasses = this.tempElem.classList;

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( className ) && !outsideClasses.contains( className ) )
      {
        this.renderer?.addClass( this.tempElem, className );
      }
    }
    return true;
  }
  //  ---------------------------------------------------------------
  public addClassForElement (elem : HTMLElement, className : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
      const outsideClasses = elem.classList;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outsideClasses)) {
        if (!outsideClasses.contains(className)) {
          this.renderer?.addClass(elem, className);
        }
      }
    }
  }
  //  ---------------------------------------------------------------
  public toggleClass (elemId : string, className : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      this.tempElem = document.getElementById(elemId);
    }

    let tempElem = document.getElementById(elemId) as HTMLElement;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem)) {
      this.toggleClassForElement(tempElem, className);
    }
    return true;
  }
  //  ---------------------------------------------------------------
  public toggleClassForElement (elem : HTMLElement, className : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
      const outsideClasses = elem.classList;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(className) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outsideClasses)) {
        if (outsideClasses.contains(className)) {
          this.renderer?.removeClass(elem, className);
        }
        else {
          this.renderer?.addClass(elem, className);
        }
      }
    }
    return true;
  }
  /*
   *  ---------------------------------------------------------------
   *  Note: This method will stop movement of background if it is moving, otherwise it will start-moving
   */
  public toggleBackgroundMovement (): any
  {
    this.toggleClassOutsideAngular( 'bodyBackgroundImageId', 'dynamicBackImage' );
    return true;
  }
  
/*
 *  ---------------------------------------------------------------
 *   End of background-image-rotation-timer-system'
 *  ===============================================================
 */
  // -----------------------------------------------------------------------------------
  // depracated:
  // -----------------------------------------------------------------------------------
  public setKvPhotoBackgroundImagePrimaryOrOtherwise (kvp : KvPhoto) : any {
    // debugger;
    let imageStr = '';
    /* || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.privacy)*/
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.glyphName)
        && kvp.glyphName?.toLowerCase().indexOf('primary') !== -1) && !kvp.isPrimary) {
        imageStr = 'photos/lock.jpg';
        // debugger;
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.privacy) && kvp.privacy.toLowerCase().indexOf('private') !== -1) {
        imageStr = 'photos/lock.jpg';
        // debugger;
      }
      //else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.isUnlocked) && !kvp.isUnlocked) {
      //  imageStr = 'photos/lock.jpg';
      //  debugger;
      //}
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value) && kvp.value.length > 0) {
        imageStr = kvp.value;
        // debugger;
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image)) {
        imageStr = kvp.image;
        // debugger;
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.pic)) {
        imageStr = kvp.pic;
        // debugger;
      }
      else {
        'photo/DefaultImageEmojii.jpg';
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageStr) && kvp.isPrimary) {
        // this.stopBiasAnimation();
        // debugger;
        // this.setElementBackgroundImage(kvp.elemId, kvp.value);

        // let bEelemId : string = 'bodyId';
        // this.renderer.setStyle(elem, 'background-image', imageData);
        this.updateBackgroundImageOutsideAngular(imageStr);
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.elemId)) {
        this.updateBackgroundImageOutsideAngularById(kvp.elemId, imageStr);
        return true;
      }
      // debugger;
      return false;
    }
    else {
      console.log(MessageBuilderServiceStatic.printMessage(kvp.sitUserId, 'setKvPhotoBackgroundImage(kvp.sitUserId(' + kvp.sitUserId + '). `imageStr` is null or undefined; could not set the image.'));
      return false;
    }
  }
  //  ---------------------------------------------------------------------------------
}

  // ----------------------------------------------------------------
  //private startBiasKvPhotoArrAnimOld ( pics: KvPhoto[]): void
  //{
  //  // this.stopBiasAnimation();
  //   debugger;
  //  //if ( !this.isDefault )
  //  //{
  //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pics ) && pics.length > 0 )
  //    {
  //      // rotate the profileImages:
  //       debugger;
  //      this.biasKvPhotoArr = pics;
  //      // set the first image right away, as others are intervaled with time
  //      let imageDataUrl = this.biasKvPhotoArr[ pics.length - 1 ].value; // always show the latest on the array
  //      this.updateStyleOutsideAngular( 'bodyId', imageDataUrl );
  //      this.startRotateBiasAnimation();
  //    }
  //    //else if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess ) || this.loginSuccess.signedInUserId > 0 )
  //    //{
  //    //  // show the primaryImage on the background:
  //    //   debugger;
  //    //  this.setSignedInUserBackgroundImage();
  //    //}
  //    //else
  //    //{
  //    //  // rotate default images:
  //    //  // debugger;
  //    //  this.biasKvPhotoArr = this.defaultKvPhotoArr;
  //    //  this.startRotateBiasAnimation();
  //    //}
  //  // }
  //  //else
  //  //{
  //  //  // rotate default images:
  //  //  // debugger;
  //  //  this.biasKvPhotoArr = this.defaultKvPhotoArr;
  //  //  this.startRotateBiasAnimation();
  //  //}
  //}
  // ---------------------------------------------------------------------------------
  // Note: This method assumes that the bgis[] contains target-users' photos in KvPhoto[] format
  // ----------------------------------------------------------------------------------
  // getPhotoMgmtImage ( page: any ): any
  // {
  //  let image;

  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( page ) )
  //  {
  //    //  debugger;
  //    if ( typeof page === 'number' )
  //    {
  //      // normalize-page-no:
  //      // -----------------
  //      if ( page > 0 )
  //      {
  //        if ( page > this.profileImageKvPhotos.length )
  //        {
  //          page = 1;
  //        }
  //      }
  //      else page = 1;

  //      // get the page's image:
  //      // ---------------------
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profileImageKvPhotos[ page - 1 ] )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profileImageKvPhotos[ page - 1 ].value ) )
  //      {
  //        if ( this.profileImageKvPhotos[ page - 1 ].value.toString().toLowerCase().indexOf( 'url(\'' ) === -1 )
  //        {
  //          image = 'url(\'' + this.profileImageKvPhotos[ page - 1 ].value + '\')';
  //        } else
  //        {
  //          image = this.profileImageKvPhotos[ page - 1 ].value;
  //        }
  //      }
  //    }
  //    // page==KvPhoto:
  //    // --------------
  //    else if ( ( page.pageNum > 0 || page.photoId > 0 ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( page.value ) )
  //    {
  //      this.biasKvPhotoArr.map( ( e ) =>
  //      {
  //        if ( e.pageNum === page.pageNum || e.photoId === page.photoId )
  //        {
  //          /*
  //           *  debugger;
  //           * image = page.value;
  //           * image = 'url('' + page.value + '')';
  //           */
  //          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( page.value && page.value.toString().toLowerCase().indexOf( 'url(\'' ) === -1 ) )
  //          {
  //            image = 'url(\'' + page.value + '\')';
  //          } else
  //          {
  //            image = page.value;
  //          }
  //        }
  //      } );
  //    }
  //  }
  //  return image;
  // }
/*
 *  ---------------------------------------------------------------
 *  Note:  1)  MemberView and rotateBackgroundImage() use this method
 *         2)  This should be the only background-image rendereing method for these two (mentioned at 1)!
 *  ---------------------------------------------------------------
 */
  // public setPageBackgroundImage ( pageNo: number ): any
  // {
  //    // debugger;
  //    let imageUrl = '';

  //    if ( pageNo > 0 )
  //    {
  //      imageUrl = this.getPhotoMgmtImage( pageNo );
  //    }
  //    else
  //    {
  //      imageUrl = this.getPhotoMgmtImage( 1 );
  //    }

  //    let kvPhoto = this.setImageStringToKvPhoto( imageUrl );
  //    this.isDefault = false;
  //    this.biasKvPhotoArr = [ kvPhoto ];
  //    // set the first image right away, as others are intervaled with time
  //    let imageDataUrl = this.biasKvPhotoArr[ 0 ].value;
  //    this.updateStyleOutsideAngular( 'bodyId', imageDataUrl );
  //    // this.startRotateBiasAnimation();
  //    return true;
  // }

  // ===================================================================================
  // BEGIN of BIAS Fading-In-Out mechanism
  // ===================================================================================
  // ----------------------------------------------------------------
  // BIAS: Background Image Animation System with Fading-In-Out mechanism
  // Author: Sayeed Rahman
  // Date: 2021/03/22;7:41pm; Ottawa, ON, Canada
  // (c) Copyright.
  // ===========================================
  // Ref: http://css3.bradshawenterprises.com/cfimg/
/*
 * For "n" images You must define:
 * a=presentation time for one image
 * b=duration for cross fading
 * Total animation-duration is of course t=(a+b)*n
 *
 * animation-delay = t/n or = a+b
 *
 * Percentage for keyframes:
 *
 * 0%
 * a/t*100%
 * (a+b)/t*100% = 1/n*100%
 * 100%-(b/t*100%)
 * 100%
 */
 // -----------------------------------------------------------
  // getDefaultBiasImagesModel (): any
  // {
  //  this.biasImagesModel.top = 'url(\'photos/leatherCouple-1.png\')'; // JsRegExpServiceStatic.normalizeImageData( 'photos/leatherCouple-1.png' );
  //  this.biasImagesModel.bottom = 'url(\'photos/gay-couple-1.png\')'; // JsRegExpServiceStatic.normalizeImageData( 'photos/gay-couple-1.jpg' );

  //  return this.biasImagesModel;
  // }
  // ----------------------------------------------------------------
  // public getBiasImagesModel (): any
  // {
  //  if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.biasImagesModel ) )
  //  {
  //    this.biasImagesModel = new BiasImagesModel();
  //  }
  //  if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.biasImagesModel.top ) )
  //  {
  //    this.biasImagesModel.top = 'url(\'photos/leatherCouple-1.png\')';
  //  }
  //  if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.biasImagesModel.bottom ) )
  //  {
  //    this.biasImagesModel.bottom = 'url(\'photos/gay-couple-1.jpg\')';
  //  }
  //  return this.biasImagesModel;
  // }

  // -----------------------------------------------------------
  // setBiasImagesModel ( biasImages: BiasImagesModel ): any
  // {
  //  this.biasImagesModel.top = biasImages.top;
  //  this.biasImagesModel.bottom = biasImages.bottom;

  //  return this.biasImagesModel;
  // }

  // ===================================================================================
  // END of BIAS Fading-In-Out mechanism
  // ===================================================================================


