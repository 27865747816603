'use strict'
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ModalServiceService } from '../modalServiceService/modalServiceService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';


@Injectable({
  providedIn: 'root',
})
export class TimerService implements OnDestroy
{
  constructor (private modalServiceService : ModalServiceService) {
  }
  public index = -1;
  public messageTimer : any;
  public timer : any;
  public timerMap : Map<any, any> = new Map();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  
  // ---------------------------------------------------------------------------------
  public getIndexInArr (timer : any, timerArray : any) : number
  {
    this.index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(timerArray) && timerArray.length > 0)
    {
      let i = 0;
      for ( let t in timerArray )
      {
        if ( t === timer )
        {
          this.index = i;
          break;
        }
        i++;
      }
    }
    return this.index;
  }
  // ---------------------------------------------------------------------------------
  public getIndexInTimerMap (timer : any) : number {
    this.index = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.timerMap) && this.timerMap.size > 0) {
      let i = 0;
      this.timerMap.forEach(t => {
        if (t.key === timer) {
          this.index = i;
        }
        i++;
      });
    }
    return this.index;
  }
  //  ---------------------------------------------------------------------------------

  public  resetMessageTimer (interval : number) : any
  {
    // debugger; // in TimerService
    interval = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(interval) && interval > 0 ? interval : 10000;
    this.messageTimer = setTimeout( () =>
    {
      // debugger;
      clearTimeout(this.timerMap.get(this.messageTimer));
      this.timerMap.delete(this.messageTimer)
      EmitterSubjectService.emitClearMessage(true);
    }, interval);

    clearTimeout(this.messageTimer);
    //if (!this.timerMap.has(this.messageTimer)) {
    //  this.timerMap.set(this.messageTimer, this.messageTimer);
    //}
    return true;
  }

  //  ---------------------------------------------------------------
  ngOnDestroy (): void
  {
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.timerMap.forEach(t => clearTimeout(t));
    this.timerMap.clear();
  }

  // ---------------------------------------------------------------------------------
  public clearTimerService () {
    this.timerMap.forEach((timer) => clearInterval(timer));
    this.timerMap.clear();
  }
  // ---------------------------------------------------------------------------------
}

// export class NgZone
// {
//  readonly hasPendingMicrotasks : boolean = false;
//  readonly hasPendingMacrotasks : boolean = false;

//  /**
//   * Whether there are no outstanding microtasks or macrotasks.
//   */
//  readonly isStable : boolean = true;

//  /**
//   * Notifies when code enters Angular Zone. This gets fired first on VM Turn.
//   */
//  readonly onUnstable : Subject<any> = new Subject(  );

//  /**
//   * Notifies when there is no more microtasks enqueued in the current VM Turn.
//   * This is a hint for Angular to do change detection, which may enqueue more microtasks.
//   * For this reason this event can fire multiple times per VM Turn.
//   */
//  readonly onMicrotaskEmpty : Subject<any> = new Subject(  );

//  /**
//   * Notifies when the last `onMicrotaskEmpty` has run and there are no more microtasks, which
//   * implies we are about to relinquish VM turn.
//   * This event gets called just once.
//   */
//  readonly onStable : Subject<any> = new Subject( );

//  /**
//   * Notifies that an error has been delivered.
//   */
//  readonly onError : Subject<any> = new Subject(  );

//  constructor ( { enableLongStackTrace = false } )
//  {
//    if ( typeof Zone == 'undefined' )
//    {
//      throw new Error( 'Angular requires Zone.js prolyfill.' );
//    }

//    Zone.assertZonePatched();
//    const self = this as any as NgZonePrivate;
//    self._nesting = 0;

//    self._outer = self._inner = Zone.current;

//    if ( ( Zone as any )[ 'wtfZoneSpec' ] )
//    {
//      self._inner = self._inner.fork( ( Zone as any )[ 'wtfZoneSpec' ] );
//    }

//    if ( enableLongStackTrace && ( Zone as any )[ 'longStackTraceZoneSpec' ] )
//    {
//      self._inner = self._inner.fork( ( Zone as any )[ 'longStackTraceZoneSpec' ] );
//    }

//    forkInnerZoneWithAngularBehavior( self );
//  }

//  static isInAngularZone () : boolean { return Zone.current.get( 'isAngularZone' ) === true; }

//  static assertInAngularZone () : void
//  {
//    if ( !NgZone.isInAngularZone() )
//    {
//      throw new Error( 'Expected to be in Angular Zone, but it is not!' );
//    }
//  }

//  static assertNotInAngularZone () : void
//  {
//    if ( NgZone.isInAngularZone() )
//    {
//      throw new Error( 'Expected to not be in Angular Zone, but it is!' );
//    }
//  }

//  /**
//   * Executes the `fn` function synchronously within the Angular zone and returns value returned by
//   * the function.
//   *
//   * Running functions via `run` allows you to reenter Angular zone from a task that was executed
//   * outside of the Angular zone (typically started via {@link #runOutsideAngular}).
//   *
//   * Any future tasks or microtasks scheduled from within this function will continue executing from
//   * within the Angular zone.
//   *
//   * If a synchronous error happens it will be rethrown and not reported via `onError`.
//   */
//  run<T> ( fn : ( ...args : any[] ) => T, applyThis ?: any, applyArgs ?: any[] ) : T
//  {
//    return ( this as any as NgZonePrivate )._inner.run( fn, applyThis, applyArgs ) as T;
//  }

//  /**
//   * Executes the `fn` function synchronously within the Angular zone as a task and returns value
//   * returned by the function.
//   *
//   * Running functions via `run` allows you to reenter Angular zone from a task that was executed
//   * outside of the Angular zone (typically started via {@link #runOutsideAngular}).
//   *
//   * Any future tasks or microtasks scheduled from within this function will continue executing from
//   * within the Angular zone.
//   *
//   * If a synchronous error happens it will be rethrown and not reported via `onError`.
//   */
//  runTask<T> ( fn : ( ...args : any[] ) => T, applyThis ?: any, applyArgs ?: any[], name ?: string ) : T
//  {
//    const zone = ( this as any as NgZonePrivate )._inner;
//    const task = zone.scheduleEventTask( 'NgZoneEvent: ' + name, fn, EMPTY_PAYLOAD, noop, noop );
//    try
//    {
//      return zone.runTask( task, applyThis, applyArgs ) as T;
//    } finally
//    {
//      zone.cancelTask( task );
//    }
//  }

//  /**
//   * Same as `run`, except that synchronous errors are caught and forwarded via `onError` and not
//   * rethrown.
//   */
//  runGuarded<T> ( fn : ( ...args : any[] ) => T, applyThis ?: any, applyArgs ?: any[] ) : T
//  {
//    return ( this as any as NgZonePrivate )._inner.runGuarded( fn, applyThis, applyArgs ) as T;
//  }

//  /**
//   * Executes the `fn` function synchronously in Angular's parent zone and returns value returned by
//   * the function.
//   *
//   * Running functions via {@link #runOutsideAngular} allows you to escape Angular's zone and do
//   * work that
//   * doesn't trigger Angular change-detection or is subject to Angular's error handling.
//   *
//   * Any future tasks or microtasks scheduled from within this function will continue executing from
//   * outside of the Angular zone.
//   *
//   * Use {@link #run} to reenter the Angular zone and do work that updates the application model.
//   */
//  runOutsideAngular<T> ( fn : ( ...args : any[] ) => T ) : T
//  {
//    return ( this as any as NgZonePrivate )._outer.run( fn ) as T;
//  }
// }

// function noop () { }
// const EMPTY_PAYLOAD = {};


// interface NgZonePrivate extends NgZone
// {
//  _outer : Zone;
//  _inner : Zone;
//  _nesting : number;

//  hasPendingMicrotasks : boolean;
//  hasPendingMacrotasks : boolean;
//  isStable : boolean;
// }

// function checkStable ( zone : NgZonePrivate )
// {
//  if ( zone._nesting == 0 && !zone.hasPendingMicrotasks && !zone.isStable )
//  {
//    try
//    {
//      zone._nesting++;
//      zone.onMicrotaskEmpty.next( null );
//    } finally
//    {
//      zone._nesting--;
//      if ( !zone.hasPendingMicrotasks )
//      {
//        try
//        {
//          zone.runOutsideAngular( () => zone.onStable.next( null ) );
//        } finally
//        {
//          zone.isStable = true;
//        }
//      }
//    }
//  }
// }

// function forkInnerZoneWithAngularBehavior ( zone : NgZonePrivate )
// {
//  zone._inner = zone._inner.fork( {
//    name: 'angular',
//    properties: <any> { 'isAngularZone': true },
//    onInvokeTask: ( delegate : ZoneDelegate, current : Zone, target : Zone, task : Task, applyThis : any,
//      applyArgs : any ) : any =>
//    {
//      try
//      {
//        onEnter( zone );
//        return delegate.invokeTask( target, task, applyThis, applyArgs );
//      } finally
//      {
//        onLeave( zone );
//      }
//    },


//    onInvoke: ( delegate : ZoneDelegate, current : Zone, target : Zone, callback : Function,
//      applyThis : any, applyArgs : any[], source : string ) : any =>
//    {
//      try
//      {
//        onEnter( zone );
//        return delegate.invoke( target, callback, applyThis, applyArgs, source );
//      } finally
//      {
//        onLeave( zone );
//      }
//    },

//    onHasTask:
//      ( delegate : ZoneDelegate, current : Zone, target : Zone, hasTaskState : HasTaskState ) =>
//      {
//        delegate.hasTask( target, hasTaskState );
//        if ( current === target )
//        {
//          // We are only interested in hasTask events which originate from our zone
//          // (A child hasTask event is not interesting to us)
//          if ( hasTaskState.change == 'microTask' )
//          {
//            zone.hasPendingMicrotasks = hasTaskState.microTask;
//            checkStable( zone );
//          } else if ( hasTaskState.change == 'macroTask' )
//          {
//            zone.hasPendingMacrotasks = hasTaskState.macroTask;
//          }
//        }
//      },

//    onHandleError: ( delegate : ZoneDelegate, current : Zone, target : Zone, error : any ) : boolean =>
//    {
//      delegate.handleError( target, error );
//      zone.runOutsideAngular( () => zone.onError.next( error ) );
//      return false;
//    }
//  } );
// }

// function onEnter ( zone : NgZonePrivate )
// {
//  zone._nesting++;
//  if ( zone.isStable )
//  {
//    zone.isStable = false;
//    zone.onUnstable.next( null );
//  }
// }

// function onLeave ( zone : NgZonePrivate )
// {
//  zone._nesting--;
//  checkStable( zone );
// }

/// **
// * Provides a noop implementation of `NgZone` which does nothing. This zone requires explicit calls
// * to framework to perform rendering.
// *
// * @internal
// */
// export class NoopNgZone implements NgZone
// {
//  readonly hasPendingMicrotasks : boolean = false;
//  readonly hasPendingMacrotasks : boolean = false;
//  readonly isStable : boolean = true;
//  readonly onUnstable : Subject<any> = new Subject();
//  readonly onMicrotaskEmpty : Subject<any> = new Subject();
//  readonly onStable : Subject<any> = new Subject();
//  readonly onError : Subject<any> = new Subject();

//  run ( fn : () => any ) : any { return fn(); }

//  runGuarded ( fn : () => any ) : any { return fn(); }

//  runOutsideAngular ( fn : () => any ) : any { return fn(); }

//  runTask<T> ( fn : () => any ) : any { return fn(); }
// }
