
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { GlyphiconMenuService } from '../GlyphiconService/glypgiconMenuService.service';
import { PagerService } from './pagerService.service';
import { PagerServiceChatHistory } from './pagerServiceChatHistory.service';
import { PagerPhotoMgmtService } from './pagerPhotoMgmtService.service';
import { PagerSupportService } from './pagerSupportService.service';
import { AbstractPagerVPhotoNavService } from './abstractPagerVPhotoNavService.service';
import { PagerVPhotoNavService } from './pagerVPhotoNavService.service';
// import { PagerPhotoNavService } from './pagerPhotoNavService.service';

@Injectable({
  providedIn: 'any',
})
export class PagerServiceService implements OnDestroy {
  private emitterDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private glyphiconMenuService: GlyphiconMenuService,
    private pagerService: PagerService,
    private pagerServiceChatHistory: PagerServiceChatHistory,
    private pagerPhotoMgmtService : PagerPhotoMgmtService,
    private pagerVPhotoNavService : PagerVPhotoNavService,
    private pagerSupportService : PagerSupportService,
    private abstractPagerVPhotoNavService : AbstractPagerVPhotoNavService,
  ) { }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ------------------------------------------------------------------------------------------------
  setGlyphiconMenuService(gms: GlyphiconMenuService): void {
    this.glyphiconMenuService = gms;
  }
  // ------------------------------------------------------------------------------------------------
  getGlyphiconMenuService(): GlyphiconMenuService {
    return this.glyphiconMenuService;
  }
  // ------------------------------------------------------------------------------------------------
  setPagerService(gd: PagerService): void {
    this.pagerService = gd;
  }
  // ------------------------------------------------------------------------------------------------
  getPagerService(): PagerService {
    return this.pagerService;
  }
  // ------------------------------------------------------------------
   setPagerServiceChatHistory(chps: PagerServiceChatHistory): void {
    this.pagerServiceChatHistory = chps;
   }
   getPagerServiceChatHistory(): PagerServiceChatHistory {
    return this.pagerServiceChatHistory;
  }
  // ------------------------------------------------------------------------------------------------
  getAbstractPagerVPhotoNavService () : AbstractPagerVPhotoNavService {
    return this.abstractPagerVPhotoNavService;
   }
  setAbstractPagerVPhotoNavService (pvs : AbstractPagerVPhotoNavService) : void {
    this.abstractPagerVPhotoNavService = pvs;
   }
  // ------------------------------------------------------------------------------------------------
  setPagerPhotoMgmtService (pps : PagerPhotoMgmtService): void {
    this.pagerPhotoMgmtService = pps;
  }  
  // ------------------------------------------------------------------------------------------------
  getPagerPhotoMgmtService () : PagerPhotoMgmtService {
    return this.pagerPhotoMgmtService;
  }
  // ------------------------------------------------------------------------------------------------
  setPagerVPhotoNavService (pns : PagerVPhotoNavService) : void {
    this.pagerVPhotoNavService = pns;
  }
  // ------------------------------------------------------------------------------------------------
  getPagerVPhotoNavService () : PagerVPhotoNavService {
    return this.pagerVPhotoNavService;
  }
  // ------------------------------------------------------------------------------------------------
  setPagerSupportService (pvs : PagerSupportService) : void {
    this.pagerSupportService = pvs;
  }
  // ------------------------------------------------------------------------------------------------
  getPagerSupportService () : PagerSupportService {
    return this.pagerSupportService;
  }
  // ------------------------------------------------------------------
}
