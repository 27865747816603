
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { RemoteDataModel } from '../../models/data/remoteDataModel.model';
import { SitUserService } from '../commonServiceService/sitUserService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { ProfileTileService } from '../profileTileServiceService/profileTileService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ChatService } from './chatService';
import { EmailService } from './emailService';
import { EnoteService } from './enoteService';

// --------------------------------------------------------------------------------------------------------------
// Background-Processing-System-for-(Component /& Service)
// There are two types of Background-Processing-Systems:
//  A) Background-Processing-System-for-Component
//  B) Background-Processing-System-for-Service
//
//  There are two parts of each system which are publisher and subscriber. 
//  In order to use this system any component/service must utilize the 
//  following two publisher-subscriber-methods in EmitterSubjectService:
//    1. publisher: 
//      1.1: emitBackgroundProcessMethodData (for both component & service vai `RemoteDataModel.isForComponent` property boolean flag)
//      1.2: emitRunBackgroundProcessMethod
//
//    2. subscriber:
//      2.1: backgroundProcessMethodDataEmitter-for-Component, -for-Service
//      2.2: runBackgroundProcessMethodEmitter
//
//  This service (HttpBackgroundService) will publish via emitBackgroundProcessingMethod.
//  and listen to / (subscribe to) runBackgroundProcessMethodEmitter.
//  
//
//  Any component/service will publish their jobs via the emitRunBackgroundProcessMethod publisher.
//  The respective component/service will listen to backgroundProcessMethodEmitter to obtaine the data.
//
//  The two systems has a common model for data-flow which is RemoteDataModel. When it is used for Component, 
//  the `isForModel` property is set to true, which is the default value for the property, and
//  for Service the property is set to false. Setting this property is very important! otherwise the processed-data
//  will not arrive at the expected destination(s).
//
//
//  This service contains the channel to the background processing via it's loadRemoteDataInBackground() method.
//  This serivce has it's own HttpService copy injected to it, and it can provide the HttpService to others.
//  A possible scenario would be a component that wants to use both HttpService and HttpBackgroundService.
//  In this case, the component must obtain it's copy of the HttpService from HttpBackgroundService.
// --------------------------------------------------------------------------------------------------------------
@Injectable({
  providedIn: 'root',
})
export class SwBackgroundFetchService implements OnDestroy {
 
  // ----------------------------------------
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public rdm : RemoteDataModel = new RemoteDataModel();
  
  constructor (
    public chatService: ChatService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public emailService : EmailService,
    public enoteService : EnoteService,
    public profileTileService : ProfileTileService,
    public sitUserService : SitUserService,
    public router: Router,
  ) {
    // --------------------------------------------------------------
    // Backup example copy:
    // --------------------------------------------------------------
    //EmitterSubjectService.backgroundProcessMethodEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe((result : RemoteDataModel) => {
    //    debugger;
    //    switch (result.methodName) {
    //      case 'populateSitUserIdDictionariesFromIndexedDb':
    //        this.dbDexieToDictionaryService.populateSitUserIdDictionariesFromIndexedDb();
    //        break;
    //      case 'getProfileTileIndividuallyFromServer':
    //        this.profileTileService.getProfileTileIndividuallyFromServer(result.data);
    //        debugger;
    //        break;
    //      default:
    //        break;
    //    }        
    //  });
    // --------------------------------------------------------------
  //  EmitterSubjectService.runBackgroundProcessMethodEmitter
  //    .pipe(takeUntil(this.emitterDestroyed$))
  //    .subscribe((rdm : RemoteDataModel) => {
  //       debugger;
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm)) {

  //       // this.loadRemoteDataInBackground(rdm).then(result => {
  //        this.runBackgroundProcessingMethod(rdm).subscribe(_rdm => {
  //           //   debugger;
  //           //   if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //           //     debugger;
  //           //     if (_rdm. instanceof RemoteDataModel) {
  //           //       debugger;
  //           //       rdm.data = result.data; // input data is replaced with output data with model's other data intact
  //           //     }
  //           //     else {
  //           //       debugger;
  //           //       rdm.data = result;
		//			        //}
  //           //   }
  //           //   else {
  //           //     // debugger;
  //           //     rdm.message = MessageBuilderServiceStatic.printMessage(rdm.id, ' loadRemoteDataInBackground for method: ' + rdm.methodName + ' returned a null promise. ');
  //           //   }
  //          debugger;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(_rdm)) {
  //            debugger;
  //            if (_rdm.isForComponent) {
  //              debugger;
  //              EmitterSubjectService.emitBackgroundProcessMethodDataForComponent(_rdm);
  //            }
  //            else {
  //              debugger;
  //              EmitterSubjectService.emitBackgroundProcessMethodDataForService(_rdm);
  //            }
  //          }
  //        })
  //      }        
  //    });
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  // ------------------------------------------------------------------------------------------------
  public sendJobToBackgroundSw (rdm : RemoteDataModel): any {
    if ('serviceWorker' in navigator) {

      // ensure service worker is ready
      navigator.serviceWorker.ready.then(function (reg) {
        debugger;
        // PING to service worker, later we will use this ping to identifies our client.
        navigator.serviceWorker.controller.postMessage("jobToSW(#%#" + JSON.stringify(rdm) + "#%#)");

        // listening for messages from service worker
        navigator.serviceWorker.addEventListener('jobDataPromiseFromSw', (event: MessageEvent) => {
          debugger;
          var _rdm: RemoteDataModel = new RemoteDataModel();
          _rdm = event.data; // TODO: get the data from the event.

          console.log("swBackgroundFetchService->jobDataPromise from SW: " + JSON.stringify(_rdm));
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(_rdm)) {
            debugger;
            if (_rdm.isForComponent) {
              debugger;
              EmitterSubjectService.emitBackgroundProcessMethodDataForComponent(_rdm);
            }
            else {
              debugger;
              EmitterSubjectService.emitBackgroundProcessMethodDataForService(_rdm);
            }
          }
          // you can also send a stringified JSON and then do a JSON.parse() here.
        });
      })
    }
	}
  // ------------------------------------------------------------------------------------------------
  // Ref: https://www.bennadel.com/blog/4099-brute-force-refreshing-view-data-in-the-background-in-angular-11-0-5.htm
  // ------------------------------------------------------------------------------------------------
  // I load the remote data quietly in the background without changing the ready-state.
 // public loadRemoteDataInBackground (_rdm : RemoteDataModel) : Observable<RemoteDataModel> {
 //   debugger;
 //   return new Observable((subscriber) => {
 //     var rdm = _rdm;
 //     var dataLoadID = ++(EmitterSubjectService.remoteDateLoadID);
 //     debugger;
 //     try {
 //         if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm)
 //           && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.methodName)
 //             || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.url))) {

 //           switch (rdm.methodName) {
 //             case 'displayUserDataFromServer':
 //               // debugger;
 //               // EmitterSubjectService.emitDisplayUserPicsFromServer(rdm.methodName);
 //               if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.id) && rdm.id > 0) {
 //                 this.displayProfileService.displayUserDataFromServer(rdm.id).subscribe(data => {
 //                   rdm.data = data;
 //                   subscriber.next(rdm);
 //                 });
 //               }
 //               break;

 //             case 'displayUserPicsFromServer': // TODO
 //               // debugger;
 //               // EmitterSubjectService.emitDisplayUserPicsFromServer(rdm.methodName);
 //               //this.httpBackgroundService.loadRemoteDataInBackground(this.rdm).then(data => {
 //               // debugger;
 //               if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.id) && rdm.id > 0) {
 //                 this.displayProfileService.displayUserPicsFromServer(rdm.id).subscribe(data => {
 //                   rdm.data = data;
 //                   subscriber.next(rdm);
 //                 });
 //               }
 //               break;
 //             case 'getProfileTileIndividuallyFromServer':
 //               // debugger;
 //               if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.data)) {
 //                 this.profileTileService.getProfileTileIndividuallyFromServer(rdm.data).subscribe(data => {
 //                   rdm.data = data;
 //                   subscriber.next(rdm);
 //                 });
 //               }
 //               break;

 //             case 'getProfileTile':
 //               // debugger;
 //               if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.data)) {
 //                 this.profileTileService.getProfileTile(rdm.data).subscribe(data => {
 //                   rdm.data = data;
 //                   subscriber.next(rdm);
 //                 });
 //               }
 //               break;

 //             case 'getBatchOfSitUserIdsFromServer':
 //               // debugger;
 //               if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.data)) {
 //                 this.sitUserService.getBatchOfSitUserIds(rdm.data).subscribe(data => {
 //                   rdm.data = data;
 //                   subscriber.next(rdm);
 //                 });
 //               }
 //               break;

 //             case 'getMyConversations':
 //               this.chatService.getMyConversations().subscribe(data => {
 //                 rdm.data = data;
 //                 subscriber.next(rdm);
 //               });
 //               break;

 //             case 'populateSitUserIdDictionariesFromIndexedDb':
 //               this.dbDexieToDictionaryService.populateSitUserIdDictionariesFromIndexedDb().subscribe(data => {
 //                 rdm.data = data;
 //                 subscriber.next(rdm);
 //               });
 //               break;

 //             case 'setupSlicedChatHistory':
 //               // debugger;
 //               if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.id) && rdm.id > 0) {
 //                 this.chatService.setupSlicedChatHistory(rdm.id).subscribe(data => {
 //                   rdm.data = data;
 //                   subscriber.next(rdm);
 //                 });
 //               }
 //               break;

 //             case 'getMyEmailIdsObservable':
 //               debugger;
 //               this.emailService.getMyEmailIdsObservable(rdm.isInbox).subscribe(data => {
 //                 rdm.data = data;
 //                 subscriber.next(rdm);
 //               });
 //               break;

 //             case 'getMyEmailsObservable':
 //               debugger;
 //               if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rdm.data)) {
 //                 this.emailService.getMyEmailsObservable(rdm.data, rdm.isInbox).subscribe(data => {
 //                   rdm.data = data;
 //                   subscriber.next(rdm);
 //                 });
 //               }
 //               break;

 //             case 'getMyEnoteIds':
 //               debugger;
 //               if (!rdm.isMethodCalled) {
 //                 this.enoteService.getMyEnoteIds(rdm).subscribe(data => {
 //                   rdm = _rdm;
 //                   debugger;
 //                   rdm.data = data;
 //                   rdm.isMethodCalled = true; // to prevent multiple calls to the methodName
 //                   EmitterSubjectService.emitRunBackgroundProcessMethod(rdm);
 //                 })
 //               }
 //               else {

	//							}
 //               subscriber.next(rdm);
 //               debugger;       
 //               break;

 //             case 'getMyEnoteTiles':
 //               // Note: since getMyEnoteIds sent the 
 //               debugger;               
 //               subscriber.next(rdm);                            
 //               break;

 //             default:
 //               // debugger;
 //               // EmitterSubjectService.emitBackgroundProcessingMethod(rdm);
 //               // observable.next(_rdm);
 //               // observable.complete();
 //               break;
 //           }
 //           debugger;
          
 //           // If this request has returned out-of-order, ignore it - defer to the newer
 //           // request to update the view-model.
 //           if (dataLoadID !== EmitterSubjectService.remoteDateLoadID) {
 //             this.message = MessageBuilderServiceStatic.printMessage(0, 'Ignoring request that returned out of order. ' + dataLoadID + ' vs ' + EmitterSubjectService.getRemoteDateLoadID().toString());
 //             console.warn(this.message);
 //            // reject(this.message);

 //           }
 //           this.message = MessageBuilderServiceStatic.printMessage(rdm.id, this.message);
 //           console.log(this.message);
 //           // reject(this.message);
 //         }
 //         else {
 //           this.message = MessageBuilderServiceStatic.printMessage(rdm.id, 'he rdp.methodName was null or undefined at loadRemoteDataInBackground()');
 //           console.log(this.message);
 //           // reject(this.message);
	//			  }
 //       } catch (error) {
 //         this.message = "Error loading data in the background.";
 //         this.message = MessageBuilderServiceStatic.printMessage(rdm.id, this.message);
 //         console.warn(this.message);
 //         console.error(error);

 //       }
 //       finally {
 //         ; //do nothing
 //       }
 //   })
 // }    
 // // ---------------------------------------------------------------
 // public runBackgroundProcessingMethod (_rdm : RemoteDataModel) : Observable<any> {
 //   debugger;    
 //   return new Observable<any>((observable) => {
 //     var rdm = _rdm;
 //     debugger;
 //     this.loadRemoteDataInBackground(_rdm).subscribe(result => {
 //       debugger;
 //       observable.next(result);
 //     })
 //     //  .catch((error) => {
 //     //  this.message = MessageBuilderServiceStatic.printMessage(_rdm.id, ' promise of runBackgroundProcessingMethod(_rdm) has some errors. Error-mag: ' + error);
 //     //  console.log(this.message);
 //     //});
 //   })
	//}
  /*
   * ---------------------------------------------------------------
   * ================================================================================================
   * ------------------------------------------------------------------------------------------------
   * ref:https:// www.htmlgoodies.com/html5/tutorials/determine-an-images-type-using-the-javascript-filereader.html
   */
  //public fileChunk (File : any): any {
  //  if (!File.prototype.slice) {
  //    const newSlice = File.prototype.mozSlice || File.prototype.webkitSlice;

  //    if (newSlice) {
  //      File.prototype.slice = (() => (startingByte : any, length : any) => newSlice.call(this, startingByte, length + startingByte))();
  //    } else {
  //      throw Error('File.slice() not supported.');
  //    }
  //  }
  //}
  
}
