<div *ngIf="isMyProfile || isPhotoMgmt"
     id="pagerVGlyphForPhotoMgmtComponentId" class="flex-pagerV-container"
     style="margin: 0.05rem; padding:0; z-index: 9; width: 100%; min-height:20%; height:auto; overflow: auto; position: sticky;border:1.5px solid transparent;">
  <!--===========================================================================================================-->
  <!--NOTE: in the following case a single KvGlyph is used to produce an array of KvGlyphs on the basis of camera/isPrimary/isPrivate-->
  <!--=================================================*ngIf="sitUser.sitUserId === signedInUserId" ==========================================================-->
  <div *ngIf="kvMemActGlyphArrForPhotoMgmt && kvMemActGlyphArrForPhotoMgmt.length > 0"
       style="margin:0.01rem;padding:0.05rem; width:auto;">
    <!--Repeater Pages| kvMemActGlyphArrForPhotoMgmt | pagerVMemActGlyphForPhotoMgmt-->
    <div *ngFor="let kvAny of kvMemActGlyphArrForPhotoMgmt; let i=index;"
         [ngClass]="{'active':kvAny.currentItemNo === i, 'flex-button': true }"
         style="margin: 0.07rem; padding: 0.02rem;">

      <!--------------------------------------------------------------->
      <div *ngIf="kvAny && kvAny.value && kvAny.isPrimary &&  kvAny.key && kvAny.key.toString().toLowerCase().indexOf('camera') === -1">
        <!--Primary pic case:-->
        <a (click)="setGlyphAction(i + 1, kvAny)"
           [ngClass]="{'hvr-underline-from-center  flex-button text-center bttn-xxxs-transparent smallerPrimary': true}"
           style="width: 1.7rem;
                          height: 1.7rem;
                          margin: 0.05rem;
                          padding: 0rem;">
          <!--Primary pic case:-->
          <img *ngIf="kvAny && kvAny.value && kvAny.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1"
               id="pageVGlyphMgmt-{{i + 1}}"
               [ngClass]="{'hvr-icon imgBg eNoteTilePic': true}"
               src="{{kvAny.value}}"
               type="button"
               style="height: 1.6rem;
                      width: 1.6rem;
                      z-index: 10;
                      vertical-align: middle;
                      margin: 0rem;
                      cursor: pointer;
                      display: inline;" />
        </a>
        <!--Primary pic case:-->
        <div class="hvr-icon blueDrkRedxSB" style="margin: 0.02rem;cursor: pointer;">
          Your {{kvAny?.key}} picture
        </div>
      </div>

      <!--------------------------------------------------------------->
      <div *ngIf="kvAny && !kvAny.isPrimary &&  kvAny.value
           && (kvAny.value && kvAny.value.toString().toLowerCase().indexOf('.jpg') !== -1
            || kvAny.value.toString().toLowerCase().indexOf('.png') !== -1
            ||  kvAny.value.toString().toLowerCase().indexOf('glyphicon') !== -1)
           && kvAny.key.toString().toLowerCase().indexOf('camera') === -1 ">

        <a *ngIf="kvAny.value.toString().toLowerCase().indexOf('glyphicon') !== -1
           && kvAny.value && kvAny.value.toString().toLowerCase().indexOf('.jpg') === -1
           && kvAny.value.toString().toLowerCase().indexOf('.png') === -1
           && kvAny.key.toString().toLowerCase().indexOf('camera') === -1 "
           (click)="setGlyphAction(i + 1, kvAny)"
           [ngClass]="{'hvr-underline-from-center  flex-button text-center bttn-xxxs-transparent': true}"
           style="width: 1.7rem;
                  height: 1.7rem;
                  margin: 0.05rem;
                  padding: 0rem;">

          <!--Glyphicon non-camera case:0.35rem 0.05rem 0 0.05rem-->
          <div id="pageVGlyphMgmt-{{i + 1}}"
               class="hvr-icon text-center drkRedGrnB glyphicon  {{kvAny?.value}}"
               style="height: 1.6rem; width: 1.6rem; margin: 0.25rem 0 0 0.0rem; cursor: pointer; "
               aria-hidden="true">
          </div>
        </a>
        <a *ngIf="kvAny.value.toString().toLowerCase().indexOf('glyphicon') === -1
           && (kvAny.value.toString().toLowerCase().indexOf('.jpg') !== -1 || kvAny.value.toString().toLowerCase().indexOf('.png') !== -1)
           && kvAny.key.toString().toLowerCase().indexOf('camera') === -1 "
           (click)="setGlyphAction(i + 1, kvAny)"
           [ngClass]="{'hvr-underline-from-center  flex-button text-center bttn-xxxs-transparent': true}"
           style="width: 1.7rem;
                  height: 1.7rem;
                  margin: 0.05rem;
                  padding: 0rem;">
          <!--Private/Public pic case: 0.15rem 0.01rem 0.02rem 0.01rem-->
          <!--an image-file-name in lieu of a glyphicon as private/public and non-camera case: -->
          <img id="pageVGlyphMgmt-{{i + 1}}"
               class="img hvr-icon text-center drkRedGrnSB"
               src="photos/{{kvAny?.value}}"
               style="background: transparent;
                    vertical-align: middle;
                    margin: 0.0rem;
                    z-index: 9;
                    height: 1.6rem;
                    width: 1.6rem;
                    cursor: pointer;" aria-hidden="true" />
        </a>
        <!--Non-Primary pic case:-->
        <!--Private/Public pic case:-->
        <div *ngIf="kvAny && !kvAny.isPrimary
              && kvAny.key.toString().toLowerCase().indexOf('camera') === -1
              && (kvAny.key && kvAny.key.toString().toLowerCase().indexOf('private') !== -1
                      || kvAny.key.toString().toLowerCase().indexOf('public') !== -1
                      || kvAny.key.toString().toLowerCase().indexOf('primary') !== -1)"
             class="hvr-icon blueDrkRedxSB" style="margin: 0.02rem;cursor: pointer;">
          Make {{kvAny?.key}}
        </div>
      </div>
      <!--------------------------------------------------------------->
    </div>
  </div>
  <!--===========================================================================================================-->
</div>

<!--------------------------------------------------------------->
<!--div *ngIf="kvAny && kvAny.value && !kvAny.isPrimary && isCameraVisible
   && kvAny.value.toString().toLowerCase().indexOf('glyphicon-camera') !== -1">
<a *ngIf="kvAny && kvAny.key && kvAny.key.toString().toLowerCase().indexOf('camera') !== -1"
   (click)="setGlyphAction(i + 1, kvAny)"
   [ngClass]="{'hvr-underline-from-center  flex-button text-center bttn-xxxs-transparent':true}"
   style="width: 1.7rem;
          height: 1.7rem;
          margin: 0.05rem;
          padding: 0rem;">
  <!--Glyphicon camera case:-->
<!--div id="pageVGlyphMgmt-{{i + 1}}"
   class="hvr-icon text-center drkRedGrnB glyphicon  {{kvAny?.value}}"
   style="height: 1.6rem; width: 1.6rem; margin: 0.35rem 0.05rem 0.05rem 0.05rem; cursor: pointer;"
   aria-hidden="true">
  </!--div>
</a>
<!--Camera case:-->
<!--div *ngIf="kvAny && kvAny.key && kvAny.key.toString().toLowerCase().indexOf('camera') !== -1 && isCameraVisible"
     class="hvr-icon blueDrkRedxSB" style="margin: 0.02rem 0 0.1rem 0;cursor: pointer;">
  {{kvAny?.key}}
</!--div>
  </div>
  <!--------------------------------------------------------------->
<!--<div *ngIf="kvAny && kvAny.value && !kvAny.isPrimary && kvAny.value.toString().toLowerCase().indexOf('glyphicon-camera') === -1 && !isCameraVisible">
<a (click)="setGlyphAction(i + 1, kvAny)"
   [ngClass]="{'hvr-underline-from-center  flex-button text-center bttn-xxxs-transparent':true}"
   style="width: 1.7rem;
          height: 1.7rem;
          margin: 0.1rem;
          padding: 0rem;">-->
<!--Glyphicon non-camera case:-->
<!--<div id="pageVGlyphMgmt-{{i + 1}}"
       class="hvr-icon text-center drkRedGrnB glyphicon  {{kvAny?.value}}"
       style="height: 1.6rem; width: 1.6rem; margin: 0.35rem 0.05rem 0.05rem 0.05rem; cursor: pointer;"
       aria-hidden="true">
  </div>
</a>-->
<!--Camera non-case:-->
<!--<div *ngIf="kvAny && kvAny.key && kvAny.key.toString().toLowerCase().indexOf('camera') === -1 && !isCameraVisible"
       class="hvr-icon blueDrkRedxSB" style="margin: 0.02rem 0 0.1rem 0;cursor: pointer;">
    {{kvAny?.key}}
  </div>
</div>-->
