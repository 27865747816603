// db.ts
import Dexie, { Table } from 'dexie';
import { ConstantServiceStatic } from './services/staticServices/constantServiceStatic.service';

export interface TodoList {
  id ?: number;
  title : string;
}
export interface TodoItem {
  id ?: number;
  todoListId : number;
  title : string;
  done ?: boolean;
}

export class AppDB extends Dexie {
  //todoItems! : Table<TodoItem, number>;
  //todoLists! : Table<TodoList, number>;

  constructor () {
    super(ConstantServiceStatic.dbName);
    this.version(ConstantServiceStatic.version).stores({
      buddyObjectStore: 'id, bneStr, key',
      // -----------------------------------------------
      chatActivityObjectStore: 'id, bneStr, key', // this is to hold all chatActivities with chatActicityId as key

      // this is to hold conversations amongst many persons, each person's chatActivities are in an array and stored with 'yourSitUserId'
      chatConversationObjectStore: 'id, bneStr, key',
      chatConversationIdObjectStore: 'id, bneStr, key',

      emailTileObjectStore: 'id, bneStr, key',
      // emailTileIdObjectStore: 'id, bneStr, key',

      //enoteTileObjectStore: 'id, bneStr, key',
      //enoteTileIdObjectStore: 'id, bneStr, key',

      errorLogObjectStore: 'id, bneStr, key',
      heartbeatObjectStore: 'id, bneStr, key',

      // Note: either data will be in kvPhotoObjectStore
      //        or in first_ through sixth_ kvPhotObjectStores
      //        These two sets are mutually exclusive.
      // --------------------------------------------
      // kvPhotoObjectStore: 'id, bneStr, key',
      // --------------------------------------------
      loginSuccessObjectStore: 'id, bneStr, key',

      
      myChatObjectStore: 'id, bneStr, key',
      myChatIdObjectStore: 'id, bneStr, key',
      
      myEmailObjectStore: 'id, bneStr, key',
      // myEmailIdObjectStore: 'id, bneStr, key',

      myAllEnoteObjectStore: 'id, bneStr, key',
      myMemberActivityObjectStore: 'id, bneStr, key',
      myMemberLockingActivityObjectStore: 'id, bneStr, key',
      myMemberTrackingActivityObjectStore: 'id, bneStr, key',

      myNotificationObjectStore: 'id, bneStr, key',
      // myNotificationIdObjectStore: 'id, bneStr, key',

      //myBlockObjectStore: 'id, bneStr, key',
      //myFriendObjectStore: 'id, bneStr, key',
      //myLikeObjectStore: 'id, bneStr, key',
      //myStarObjectStore: 'id, bneStr, key',
      //myTrackObjectStore: 'id, bneStr, key',
      //myUnlockObjectStore: 'id, bneStr, key',
      //myWinkObjectStore: 'id, bneStr, key',

      photoBnIdObjectStore: 'id, bneStr, key',
      // photoBnObjectStore: 'id, bneStr, key',

      // ------------------------------------------------------
      preferenceObjectStore: 'id, bneStr, key',
      profileContentObjectStore: 'id, bneStr, key',
      profileInfoObjectStore: 'id, bneStr, key',
      profilePicsObjectStore: 'id, bneStr, key',
      profileTileObjectStore: 'id, bneStr, key',
      // ------------------------------------------------------      

      sitUserObjectStore: 'id, bneStr, key',
      sitUserIdObjectStore: 'id, bneStr, key',

      yourAllEnoteObjectStore: 'id, bneStr, key',
      //yourBlockObjectStore: 'id, bneStr, key',
      //yourFriendObjectStore: 'id, bneStr, key',
      //yourLikeObjectStore: 'id, bneStr, key',
      //yourMemberActivityObjectStore: 'id, bneStr, key',
      //yourMemberLockingActivityObjectStore: 'id, bneStr, key',
      //yourMemberStarTrackWinkActivityObjectStore: 'id, bneStr, key',
      //yourStarObjectStore: 'id, bneStr, key',
      //yourTrackObjectStore: 'id, bneStr, key',
      //yourWinkObjectStore: 'id, bneStr, key',
      //yourUnlockObjectStore: 'id, bneStr, key',
    });
    // this.on('populate', () => this.populate());
  }

  //async populate () {
  //  const todoListId = await db.todoLists.add({
  //    title: 'To Do Today',
  //  });
  //  await db.todoItems.bulkAdd([
  //    {
  //      todoListId,
  //      title: 'Feed the birds',
  //    },
  //    {
  //      todoListId,
  //      title: 'Watch a movie',
  //    },
  //    {
  //      todoListId,
  //      title: 'Have some sleep',
  //    },
  //  ]);
  //}
}

export const db = new AppDB();
