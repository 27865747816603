
import { ElementRef, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonce } from '../../models/boxNonce/boxNonce.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Content } from '../../models/common/content.model';
import { EmitOffOnResult } from '../../models/common/emitOffOnResult.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { ModuleComponentLoader } from '../../models/common/moduleComponentLoader.model';
import { Unit } from '../../models/common/unit.model';
import { Communication } from '../../models/communication/communication.model';
import { RemoteDataModel } from '../../models/data/remoteDataModel.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { MemberViewMgmtModel } from '../../models/member/memberViewMgmtModel.model';
import { PagerPhotoMgmt } from '../../models/pagination/pagerPhotoMgmt.model';
import { Preference } from '../../models/profile/preference.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { GlyphiconMenuService } from '../GlyphiconService/glypgiconMenuService.service';
import { CoreServiceService } from '../coreServiceService/coreServiceService.service';
import { DbServiceService } from '../dbServiceService/dbServiceService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { MemberServiceService } from '../memberServiceService/memberServiceService.service';
import { AbstractPagerVPhotoNavService } from '../pagerServiceService/abstractPagerVPhotoNavService.service';
import { PagerServiceService } from '../pagerServiceService/pagerServiceService.service';
import { PhotoServiceService } from '../photoServiceService/photoServiceService.service';
import { AbstractProfileService } from '../profileServiceService/abstractProfileService.service';
import { PreferenceService } from '../profileServiceService/preferenceService.service';
import { ProfileContentService } from '../profileServiceService/profileContentService.service';
import { RendererServiceService } from '../rendererServiceService/rendererServiceService.service';
import { ArrayServiceStatic } from '../staticServices/arrayServiceStatic.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { PagerSupportService } from '../pagerServiceService/pagerSupportService.service';


@Injectable({
  providedIn: 'root',
})
export class ProfileTileService extends AbstractProfileService  implements OnDestroy {
  
  // -----------------------------------------------------------
  public batchOfIds : number[] = [];
  public override boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public override emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isSingletonUsed = false;
  public isViewMember = false;
  // public isProfileTileDictionaryCreatedFromIndexDb = false;
  public incompleteTilesArr : ProfileTile[] = [];
  public override kvAnyArr : KvAny[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public override loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public override memberViewMgmtModel : MemberViewMgmtModel = new MemberViewMgmtModel();
  public override message = '';
  public offOnArr : string[] = [ '0' ];
  public offOnResult : EmitOffOnResult = new EmitOffOnResult();
  public pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public preference : Preference = new Preference();
  public preferenceKvAnyArr : KvAny[] = [];
  public profileContent : Content = new Content();
  public profileContentKvAnyArr : KvAny[] = [];
  public override profileInfo : ProfileInfo = new ProfileInfo();
  // Note: to be used to accumulate data from 1. profileTile, 2. profileInfo  to 3.profileInfoView
  // Note: there could be multiple passes of 1, and 2 on profileInfoPreView before final copy to profileInfoView
  public override profileInfoView : ProfileInfo = new ProfileInfo();
  public profileTile : ProfileTile = new ProfileTile();
  public profileTilesArr : ProfileTile[] = [];
  public rdm : RemoteDataModel = new RemoteDataModel()
  public override sitUser : SitUser = new SitUser(); // for sending data to the server
  public timer : any;
  public timerArray : any[] = [];
  public unit = '';


  // --------------------------------------------------------------- 
  constructor (
    public abstractPagerVPhotoNavService : AbstractPagerVPhotoNavService,
    public coreServiceService : CoreServiceService,
    public dbServiceService : DbServiceService,
    public dictionaryService : DictionaryService,
    public glyphiconMenuService : GlyphiconMenuService,
    public memberServiceService : MemberServiceService,
    public pagerSupportService : PagerSupportService,
    public photoServiceService : PhotoServiceService,
    public preferenceService : PreferenceService,
    public profileContentService : ProfileContentService,
    public rendererServiceService : RendererServiceService,
    public router : Router,
  ) {
    
    super(
      abstractPagerVPhotoNavService,
      coreServiceService,
      dbServiceService,
      dictionaryService,
      glyphiconMenuService,
      memberServiceService,
      photoServiceService,
      preferenceService,
      profileContentService,
      rendererServiceService,
      router
    );
    //this.bias = rendererServiceService.getBackgroundImageAnimationService();
    //this.heartbeatService = this.coreServiceService.getHeartbeatService();
    //this.liaisonService = this.coreServiceService.getLiaisonService();
    //this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel();
    //this.memberService = memberServiceService.getMemberService();

    //this.pagerSupportService = pagerServiceService.getPagerSupportService();
    //this.pagerVKvPhotoNavService = pagerServiceService.getPagerVKvPhotoNavService();

    //this.preferenceService = profileServiceService.getPreferenceService();
    //this.profileContentService = profileServiceService.getProfileContentService();
    //this.photoBnService = photoServiceService.getPhotoBnService();
  }

  // ---------------------------------------------------------------
  override ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach((timer) => clearInterval(timer));
    return true;
  }
  // -----------------------------------------------------------
  // public nullPromise () : any {
  //  this.timer = setTimeout(() => {
  //    // debugger;
  //    return null;
  //  }, 500);
  //  if (this.timer) {
  //    this.timerArray.push(this.timer);
  //  }
  // }
  // ---------------------------------------------------------------
  // public saltModel (model) : BoxNonceEntity {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
  //    // debugger;
  //    return (this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(model)));
  //  }
  //  return new BoxNonceEntity();
  // }
  // --------------------------------------------------------------
  public createProfileTileShellArr (sitIdArr : number[]) : ProfileTile[] {
    const pTiles : ProfileTile[] = [];
    let i = 0;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitIdArr) && sitIdArr.length > 0) {
      let pTile : any;

      for (let e of sitIdArr) {
        pTile = this.createProfileTileShell(e) as ProfileTile;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
          pTiles.push(pTile);
          if (i === sitIdArr.length) {
            return pTiles;
          }
        }
        i++;
      };
    }
    return pTiles;
  }
  // ---------------------------------------------------------------
  public createProfileTileShell (sitId : number) : ProfileTile {
    if (sitId > 0) {
      const pTile = new ProfileTile();

      pTile.sitUserId = sitId;
      pTile.signedInUserId = this.loginSuccess.signedInUserId;
      return pTile;
    }
    else {
      return new ProfileTile();
    }
  }

  // ---------------------------------------------------------------
  checkAndSetProfileTileImage (inTile : ProfileTile) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inTile)) {
      if (inTile.sitUserId > 0) {
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inTile.primaryImage) || inTile.primaryImage.length < 5) {
          this.photoBnService.getPrimaryPhoto(inTile.sitUserId).then(p => {
            // debugger;
            if (p && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p.image)) {
              inTile.primaryImage = JsRegExpServiceStatic.normalizeImageData(p.image);
              // debugger;
            }
            else {
              inTile.primaryImage = "url('/assets/photos/DefaultImageMale.jpg')";
            }
          }, (err) => {
            console.log('GetUserPrimaryPhoto failed! @ checkAndSerProfileTimeImage in memberService.');
            inTile.primaryImage = "url('/assets/photos/DefaultImageMale.jpg')";
            // debugger;
          })
        }
        else {
          inTile.primaryImage = JsRegExpServiceStatic.normalizeImageData(inTile.primaryImage);
        }
      }
      else {
        inTile.primaryImage = "url('/assets/photos/DefaultImageMale.jpg')";
        // debugger;
      }
    }
    return inTile;
  }
  // ---------------------------------------------------------------
  // create ProfileInfoView from both ProfileTile | ProfileInfo,
  // and create profileContentArrKvAny from ProfileInfo.
  // ---------------------------------------------------------------
  // the following function cover all cases of UserData (not pics) capture including offOn and distance indicators
  // and sets all the required UserData into the MemberViewMgmtModel, that is used to populate View.html
  // ---------------------------------------------------------------------------------------------------
  public createProfileInfoViewAndUpdateProfileTile (pInfo ?: ProfileInfo | any, pTile ?: ProfileTile | any) : any {
    let hb : any;
    let unit : any;
    let index = -1;
    let i = 0;
    let distanceUnit = new Unit();
    this.profileInfo = new ProfileInfo();
    this.profileTile = new ProfileTile();

    let model : any;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
      pInfo = new ProfileInfo();
    }
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      pTile = new ProfileTile();
    }

    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.signedInUserId === 0) {
        pInfo.signedInUserId = this.loginSuccess.signedInUserId;
        pInfo.signedInUserKey = this.loginSuccess.signedInUserKey;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.signedInUserId === 0) {
        pTile.signedInUserId = this.loginSuccess.signedInUserId;
        pTile.signedInUserKey = this.loginSuccess.signedInUserKey;
      }
    }
    // debugger;
    // Case: Both ProfileInfo & ProfileTile are present:
    // -------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) {
      hb = this.dictionaryService.heartbeatDictionary.get(pInfo.sitUserId);
       // debugger;

      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && pTile.sitUserId > 0) {
        hb = this.dictionaryService.heartbeatDictionary.get(pTile.sitUserId);
         // debugger;
      }
      pTile = this.captureHeartbeatDataInProfileTile(hb, pTile);
       // debugger;
      
      this.profileTile.copySourceToDestination(this.profileTile, pTile); // CopyServiceStatic.copyProfileTileIfExists(this.profileTile, pTile);
      this.profileInfo.copySourceToDestination(this.profileInfo, pInfo);
    }

    // Case: only ProfileInfo is present:
    // ----------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId == 0) {
      hb = this.dictionaryService.heartbeatDictionary.get(pInfo.sitUserId);
      // debugger;
      pTile = CopyServiceStatic.copyFromProfileInfoToProfileTile(pInfo);
      pTile = this.captureHeartbeatDataInProfileTile(hb, pTile);


      // debugger;
      this.profileTile = this.profileTile.copySourceToDestination(this.profileTile, pTile);
      this.profileInfo = this.profileInfo.copySourceToDestination(this.profileInfo, pInfo);
    }

    // Case: only ProfileTile is present:
    // --------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId === 0) {
      hb = this.dictionaryService.heartbeatDictionary.get(pTile.sitUserId);
      // for the offOn indicator:
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && hb.sitUSerId > 0) {
        debugger;
        pTile = this.captureHeartbeatDataInProfileTile(hb, pTile);
      }

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
        this.profileTile.copySourceToDestination(this.profileTile, pTile);

        pInfo = new ProfileTile().copyToProfileInfo(this.profileTile); // CopyServiceStatic.copyFromProfileTileToProfileInfo(pTile);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
          this.profileInfo.copySourceToDestination(this.profileInfo, pInfo);
          // debugger;
        }
      }
    }

    // For any of the above cases:
    // ===========================

    // we need the profile tile for OffOnComponent.
    // So if it is not created or empty, create it from profileInfo:
    // -------------------------------------------------------------
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) || this.profileTile.sitUserId === 0) {
      this.profileTile = CopyServiceStatic.copyFromProfileInfoToProfileTile(this.profileInfo);
       // debugger;

      // update ProfileTile's distance and unit:
      // ----------------------------------------
      this.profileTile = this.captureDistanceUnitInProfileTile(hb, this.profileTile);
      // debugger;
      this.profileTile = this.executeBeforeViewInit(this.profileTile, 'profileTile-');
    }

    // if Heartbeat's distance is not already calculated:
    // --------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
      // debugger;
      this.profileTile = this.captureDistanceUnitInProfileTile(hb, this.profileTile);
    }

    // for the offOn indicator, profileContentArrKvAny, & create profileInfoView:
    // --------------------------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo) && this.profileInfo.sitUserId > 0) {

      this.kvAnyArr = this.createKVModelArrFromProfileInfoView(this.profileInfo);
      // debugger;
      // create profileContentArrKvAny for ContentKeyValueArrComponent:
      // --------------------------------------------------------------
      this.profileContent = CopyServiceStatic.copyFromProfileInfoToContent(this.profileInfo);

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent)) {
        // debugger;
        this.profileContentKvAnyArr = this.kvArrayService.getProfileContentToKvArrArr(this.profileContent); // TODO: restore
      }
    }

    // capture data for the preference only from the dictionary!
    // we do not get any data from the backend-server in this function.
    // ----------------------------------------------------------------
    this.preference = this.dictionaryService.preferenceDictionary.get(this.profileInfo.sitUserId) as Preference;
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
      // debugger;
      // isPreferenceSuccess = true;
      this.memberService.setPreference(this.preference);
      this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);
    }

    // capture data for the profileInfoView:
    // -------------------------------------     
    this.profileInfoView = this.profileInfoView.copySourceToDestination(this.profileInfoView, pInfo);
    // or
    // uncomment the line below if a subset of profileInfo is required to be displayed:
    // --------------------------------------------------------------------------------
    // this.profileInfoView = CopyServiceStatic.copyFromProfileInfoToProfileInfoView(pInfo);

    let tSitUser = EmitterSubjectService.getSitUserModel();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tSitUser)
      && tSitUser.sitUserId === this.profileTile.sitUserId) {
      // debugger;
      this.sitUser = tSitUser;
      this.sitUser.heartbeat = hb;
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId === 0) {
      this.sitUser = CopyServiceStatic.copyProfileTileToSitUser(this.profileTile);
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)
      && this.sitUser.sitUserId == hb.sitUserId
      && this.sitUser.heartbeat instanceof Heartbeat
      && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser.heartbeat) && this.sitUser.heartbeat.sitUserId === 0)) {
      // debugger;
      this.sitUser.heartbeat = hb;
    }
    
    // store the computed values into the memberViewMgmtModel:
    // -------------------------------------------------------
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel) || this.memberViewMgmtModel.sitUserId == 0) {
      // debugger;
      this.memberViewMgmtModel = new MemberViewMgmtModel();
    }
    this.memberViewMgmtModel.populateModelData(this.profileContent, this.preference, this.profileInfo, this.profileInfoView, this.profileTile, this.sitUser, this.preferenceKvAnyArr, this.profileContentKvAnyArr, this.kvAnyArr);

    this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel(); 
    // debugger;
    return this.memberViewMgmtModel;
  }
  // ---------------------------------------------------------------
  public captureDataToProfileInfoView (hb : Heartbeat, pTile : ProfileTile, pInfoView : ProfileInfo) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfoView)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
        pInfoView.distance = hb.distance.toFixed(2);
        pInfoView.unit = hb.unit.length > 0 ? hb.unit : 'm'; // meter
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.signedInUserId > 0) {
        pInfoView.signedInUserId = pTile.signedInUserId;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.signedInUserKey)) {
          pInfoView.signedInUserKey = pTile.signedInUserKey;
        }

        pTile.distance = pInfoView.distance;
        pTile.distanceStr = pInfoView.distance + ' ' + pInfoView.unit;
      }
    }
    return pInfoView;
  }
  //  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heartbeat) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heartbeatTime) && pTile.heartbeatTime === 0)
  // ---------------------------------------------------------------
  public captureHeartbeatDataInProfileTile (hb : Heartbeat, pTile : ProfileTile) : any {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      pTile = new ProfileTile();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {

      // copy only when heartbeat is a model, not a timestamp:
      // -----------------------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heartbeat) && StringServiceStatic.typeCheck(pTile.heartbeatTime) === 'object') {
        pTile.heartbeat = CopyServiceStatic.copyHeartbeatIfExists(pTile.heartbeat, hb);
        // debugger;
      }

      // copy the heartbeatTime if old:
      // ------------------------------
      if (pTile.heartbeatTime >= 0 && hb?.heartbeatTime >= 0
        && pTile.heartbeatTime < hb?.heartbeatTime) {
        pTile.heartbeatTime = hb?.heartbeatTime;
      }
    }
    return pTile;
  }
  // ---------------------------------------------------------------
  public captureDistanceUnitInProfileTile (hb : Heartbeat, pTile : ProfileTile) : any {
    // debugger;
    let distanceUnit = new Unit();
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      pTile = new ProfileTile();
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
      pTile = this.captureHeartbeatDataInProfileTile(hb, pTile);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.distance) && hb.distance === 0) {
        // compute distance and unit:
          distanceUnit = this.heartbeatService.calculateDistance(hb, this.loginSuccess.heartbeat, true); // (dest, origin, isKm)

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit)) {
          pTile.distanceUnit = distanceUnit;

          // debugger;
          this.processDistanceUnitForProfileTileAndProfileInfoView(distanceUnit);

          hb.distance = distanceUnit.distance;
          distanceUnit.distanceStr = hb.distanceStr = hb.distance.toFixed(2) + ' ' + distanceUnit.unit;
          hb.unit = distanceUnit.unit;
        }
      }
    }
    return pTile;
  }
  // ---------------------------------------------------------------
  public computeDistanceUnitFromHeartbeatForAfterViewInit (pTile : ProfileTile) : any {
    let distanceUnit : Unit = new Unit();
    let heartbeat : Heartbeat = new Heartbeat();

    // get the heartbeat of pTile or loginSuccess:
    // -------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.heartbeat)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.heartbeatService)) {
        heartbeat = pTile.heartbeat;
      }
      else if (pTile.sitUserId === this.loginSuccess.signedInUserId) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.heartbeat)
          && this.loginSuccess.heartbeat.heartbeatTime > 0) {
          heartbeat = this.loginSuccess.heartbeat;
        }
      }
      // debugger;

      distanceUnit = this.heartbeatService.computeDistanceAndUnit(heartbeat);

      // TODO: experimental
      pTile.distanceUnit = distanceUnit;

      // if heartbeat is taken from loginSuccess.heartbeat:
      // --------------------------------------------------
      if (pTile.sitUserId === this.loginSuccess.signedInUserId) {
        this.loginSuccess.heartbeat = pTile.heartbeat;
      }

      // debugger;

      this.processDistanceUnitForProfileTileAndProfileInfoView(pTile.distanceUnit);

      // debugger;
       // Note: update the offOn-indicator and distance for accordionComponent:
      EmitterSubjectService.emitUpdateDistanceAndProfileNameAndOffOn(pTile);

      return pTile;
    }
    else return null;
  }
  // ===============================================================
  // End of All ProfileTile/ProfileInfo methods related to:
  //  1) memberViewMgmtComponent
  //  2) myProfileViewMgmtComponent
  // ===============================================================
  // ---------------------------------------------------------------
  // create ProfileInfoView from both ProfileTile | ProfileInfo,
  // and create profileContentArrKvAny from ProfileInfo.
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  //  NOT in user now, but DO NOT DELETE THIS.
  // ---------------------------------------------------------------
  //public createProfileInfoViewAndUpdateProfileTile3 (pInfo : ProfileInfo, pTile : ProfileTile) : any {
  //  let hb : any;
  //  let unit : any;
  //  let index = -1;
  //  let i = 0;
  //  let distanceUnit = new Unit();

  //  let model : any;
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
  //    if (pInfo.signedInUserId === 0) {
  //      pInfo.signedInUserId = this.loginSuccess.signedInUserId;
  //      pInfo.signedInUserKey = this.loginSuccess.signedInUserKey;
  //    }
  //    if (pTile.signedInUserId === 0) {
  //      pTile.signedInUserId = this.loginSuccess.signedInUserId;
  //      pTile.signedInUserKey = this.loginSuccess.signedInUserKey;
  //    }
  //  }

  //  // Case: Both ProfileInfo & ProfileTile are present:
  //  // -------------------------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) {

  //    hb = this.dictionaryService.heartbeatDictionary.getValue(pInfo.sitUserId);
  //    // debugger;

  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && pTile.sitUserId > 0) {
  //      hb = this.dictionaryService.heartbeatDictionary.getValue(pTile.sitUserId);
  //      // debugger;
  //    }
  //    this.profileInfoView.copy(pInfo); // = CopyServiceStatic.copyFromProfileInfoToProfileInfoView(pInfo);
  //    this.profileTile = this.profileTile.copy(pTile); // CopyServiceStatic.copyProfileTileIfExists(this.profileTile, pTile);
  //  }

  //  // Case: only ProfileInfo is present:
  //  // ----------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId == 0) {
  //    hb = this.dictionaryService.heartbeatDictionary.getValue(pInfo.sitUserId);
  //    // debugger;
  //    this.profileTile = CopyServiceStatic.copyFromProfileInfoToProfileTile(pInfo);
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)) {
  //      this.profileTile.heartbeat = CopyServiceStatic.copyHeartbeatIfExists(this.profileTile.heartbeat, hb);
  //      if (this.profileTile.heartbeat.prevHeartbeat >= 0 && hb?.heartbeatTime >= 0
  //        && this.profileTile.heartbeat.prevHeartbeat < hb?.heartbeatTime) {
  //        this.profileTile.heartbeat.prevHeartbeat = hb?.heartbeatTime;
  //      }
  //    }
  //    this.profileTile.signedInUserId = pTile.signedInUserId;
  //    this.profileTile.signedInUserKey = pTile.signedInUserKey;

  //    this.profileInfoView.copy(pInfo); // = CopyServiceStatic.copyFromProfileInfoToProfileInfoView(pInfo);
  //  }

  //  // Case: only ProfileTile is present:
  //  // --------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) // && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo ) && pInfo.sitUserId === 0 
  //  {
  //    hb = this.dictionaryService.heartbeatDictionary.getValue(pTile.sitUserId);
  //    // for the offOn indicator:
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
  //      if (pTile.heartbeat.prevHeartbeat >= 0 && hb.heartbeatTime >= 0 && pTile.heartbeat.prevHeartbeat < hb.heartbeatTime) {
  //        pTile.heartbeat.prevHeartbeat = hb.heartbeatTime;
  //      }
  //    }
  //    // debugger;
  //    pInfo = this.profileTile.copyToProfileInfo(); // CopyServiceStatic.copyFromProfileTileToProfileInfo(pTile);
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
  //      this.profileInfoView.copy(pInfo); // = CopyServiceStatic.copyFromProfileInfoToProfileInfoView(pInfo);
  //    }
  //    this.profileTile = this.profileTile.copy(pTile); // CopyServiceStatic.copyProfileTileIfExists(this.profileTile, pTile);

  //    if (pTile.signedInUserId > 0) {
  //      this.profileTile.signedInUserId = pInfo.signedInUserId = this.profileInfoView.signedInUserId = pTile.signedInUserId;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.signedInUserKey)) {
  //      this.profileTile.signedInUserKey = pInfo.signedInUserKey = this.profileInfoView.signedInUserKey = pTile.signedInUserKey;
  //    }
  //  }

  //  // for the offOn indicator, profileContentArrKvAny, & create profileInfoView:
  //  // TODO: Check if the pInfo is valid()?
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0) {
  //    // create profileContentArrKvAny for ContentKeyValueArrComponent:
  //    // --------------------------------------------------------------
  //    this.profileContent = CopyServiceStatic.copyFromProfileInfoToContent(pInfo);
  //    // debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileContent)) {
  //      this.profileContentKvAnyArr = this.kvArrayService.getProfileContentToKvArrArr(this.profileContent); // TODO: restore
  //    }
  //  }

  //  // if Heartbeat's distance is not already calculated:
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.distance)) {
  //    if (hb.distance === 0) {
  //      // compute distance and unit:
  //      distanceUnit = this.heartbeatService.calculateDistance(hb, this.loginSuccess.heartbeat, true); // (dest, origin, isKm)
  //      // debugger;
  //      this.processDistanceUnitForProfileTileAndProfileInfoView(distanceUnit);

  //      hb.distance = distanceUnit.distance;
  //      hb.distanceStr = hb.distance.toFixed(2) + ' ' + distanceUnit.unit;
  //      hb.unit = distanceUnit.unit;
  //      this.profileTile.heartbeat = hb;
  //      this.profileTile.distanceUnit = distanceUnit;
  //    }
  //  }
  //  else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
  //    this.profileInfoView.distance = hb.distance.toFixed(2);
  //    this.profileInfoView.unit = hb.unit.length > 0 ? hb.unit : 'meter';
  //    this.profileTile.distance = this.profileInfoView.distance;
  //    this.profileTile.distanceStr = this.profileInfoView.distance + ' ' + this.profileInfoView.unit;
  //    this.profileTile.heartbeat = hb;
  //    this.profileTile.distanceUnit = distanceUnit;
  //    // debugger;
  //  }

  //  // we need the profile tile for OffOnComponent. So if it is not created or empty, create it from profileInfo:
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) || this.profileTile.sitUserId === 0) {
  //    this.profileTile = CopyServiceStatic.copyFromProfileInfoToProfileTile(pInfo); // TODO: from CopySeriviceStatic to  ProfileInfo model
  //    // debugger;

  //    // TODO: update ProfileTile's distance and unit:
  //    // debugger;
  //    let tpTile = this.executeBeforeViewInit(this.profileTile, 'profileTile-');
  //    this.profileTile = this.profileTile.copyIfExists(pTile); //CopyServiceStatic.copyProfileTileIfExists(this.profileTile, tpTile);


  //    // debugger;
  //    this.memberViewMgmtModel.populateModelData(this.profileInfo, this.profileInfoView, this.profileTile, this.profileContentKvAnyArr, this.preferenceKvAnyArr, this.kvAnyArr);
  //  }
  //  // debugger;
  //  return this.profileInfoView;
  //}

  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  // Note:  This method handles following multiple tasks:
  //        1) sets up ProfileTile model with heartbeat info and emits the profileTile
  //        2) sets up ProfileInfoView model with heartbeat info and emits the profileInfoView
  //        4) creates ProfileContentArrKayValue array and emits the profileContentArrKayValue array
  //        5) it returns only ProfileInfoView at the end
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  //  NOT in user now, but DO NOT DELETE THIS.
  // ---------------------------------------------------------------
  //public createProfileInfoViewAndUpdateProfileTile2 (pInfo : ProfileInfo, pTile : ProfileTile) : any {
  //  let tArrKvAny : any;
  //  let hb : any;
  //  let unit : any;
  //  let index = -1;
  //  let i = 0;
  //  let distanceUnit = new Unit();

  //  let model : any;
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
  //    if (pInfo.signedInUserId === 0) {
  //      pInfo.signedInUserId = this.loginSuccess.signedInUserId;
  //      pInfo.signedInUserKey = this.loginSuccess.signedInUserKey;
  //    }
  //    if (pTile.signedInUserId === 0) {
  //      pTile.signedInUserId = this.loginSuccess.signedInUserId;
  //      pTile.signedInUserKey = this.loginSuccess.signedInUserKey;
  //    }
  //  }

  //  // Case: Both ProfileInfo & ProfileTile are present:
  //  // -------------------------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) {

  //    hb = this.dictionaryService.heartbeatDictionary.getValue(pInfo.sitUserId);
  //    // debugger;

  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && pTile.sitUserId > 0) {
  //      hb = this.dictionaryService.heartbeatDictionary.getValue(pTile.sitUserId);
  //      // debugger;
  //    }
  //    this.profileInfoView = CopyServiceStatic.copyFromProfileInfoToProfileInfoView(pInfo);
  //    this.profileTile = CopyServiceStatic.copyProfileTileIfExists(this.profileTile, pTile);
  //  }

  //  // Case: only ProfileInfo is present:
  //  // ----------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId == 0) {
  //    hb = this.dictionaryService.heartbeatDictionary.getValue(pInfo.sitUserId);
  //    // debugger;
  //    this.profileTile = CopyServiceStatic.copyFromProfileInfoToProfileTile(pInfo);
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)) {
  //      this.profileTile.heartbeat = CopyServiceStatic.copyHeartbeatIfExists(this.profileTile.heartbeat, hb);
  //      if (this.profileTile.heartbeat.prevHeartbeat >= 0 && hb?.heartbeatTime >= 0 && this.profileTile.heartbeat.prevHeartbeat < hb?.heartbeatTime) {
  //        this.profileTile.heartbeat.prevHeartbeat = hb?.heartbeatTime;
  //      }
  //    }
  //    this.profileTile.signedInUserId = pTile.signedInUserId;
  //    this.profileTile.signedInUserKey = pTile.signedInUserKey;

  //    this.profileInfoView = CopyServiceStatic.copyFromProfileInfoToProfileInfoView(pInfo);
  //  }

  //  // Case: only ProfileTile is present:
  //  // --------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) // && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pInfo ) && pInfo.sitUserId === 0 
  //  {
  //    hb = this.dictionaryService.heartbeatDictionary.getValue(pTile.sitUserId);
  //    // for the offOn indicator:
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
  //      if (pTile.heartbeat.prevHeartbeat >= 0 && hb.heartbeatTime >= 0 && pTile.heartbeat.prevHeartbeat < hb.heartbeatTime) {
  //        pTile.heartbeat.prevHeartbeat = hb.heartbeatTime;
  //      }
  //    }
  //    // debugger;
  //    pInfo = this.profileTile.copyToProfileInfo(); // CopyServiceStatic.copyFromProfileTileToProfileInfo(pTile);
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
  //      this.profileInfoView.copy(pInfo); // CopyServiceStatic.copyFromProfileInfoToProfileInfoView(pInfo);
  //    }
  //    this.profileTile = this.profileTile.copy(pTile); // CopyServiceStatic.copyProfileTileIfExists(this.profileTile, pTile);

  //    if (pTile.signedInUserId > 0) {
  //      this.profileTile.signedInUserId = pInfo.signedInUserId = this.profileInfoView.signedInUserId = pTile.signedInUserId;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.signedInUserKey)) {
  //      this.profileTile.signedInUserKey = pInfo.signedInUserKey = this.profileInfoView.signedInUserKey = pTile.signedInUserKey;
  //    }
  //  }

  //  // for profileContentArrKvAny, & create profileInfoView:
  //  this.profileContentKvAnyArr = this.profileContentService.createProfileContentKvAnyArr(pInfo); // will emit profileContentKvAnyArr

  //  let tProfileInfoView = this.liaisonService.processProfileInfoForHeartbeat(hb);  // will emit profileInfoView
  //  this.profileInfoView.copy(tProfileInfoView); //  = CopyServiceStatic.copyProfileInfoIfExists(this.profileInfoView, tProfileInfoView);

  //  // debugger;
  //  let tpTile = this.executeBeforeViewInit(this.profileTile, 'profileTile-');
  //  this.profileTile = this.profileTile.copy(tpTile); // CopyServiceStatic.copyProfileTileIfExists(this.profileTile, tpTile);

  //  let tProfileTile = this.processProfileTileForHeartbeat(hb);  // will emit profileTile
  //  this.profileTile = this.profileTile.copy(tProfileTile); // CopyServiceStatic.copyProfileTileIfExists(this.profileTile, tProfileTile);

  //  // debugger;
  //  this.memberViewMgmtModel.populateModelData(this.profileInfo, this.profileInfoView, this.profileTile, this.profileContentKvAnyArr, this.preferenceKvAnyArr, this.kvAnyArr);
  //  // debugger;
  //  return this.profileInfoView;
  //}
  // --------------------------------------------------------------
    public determineUnit (pTile : ProfileTile) : string {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn)) {
      if (pTile.distance > 999) {
        pTile.distance = pTile.distance / 1000;
        pTile.unit = this.unit = 'km';
      } else {
        pTile.unit = this.unit = 'm';
      }
      return this.unit;
    }
    else return 'm';
  }
  // ===============================================================
  // Begin of All ProfileTile/ProfileInfo methods related to:
  //  1) memberViewMgmtComponent
  //  2)myProfileViewMgmtComponent
  // ===============================================================
  // ---------------------------------------------------------------
  // Note: This method also has a copy in MemberViewMgmtComponent
  //       It is kept there because from the service, this method does not
  //       update/display user's profileInfo data.
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------
  //  NOT in user now, but DO NOT DELETE THIS.
  // ---------------------------------------------------------------
  public executeAllTasks (pInfo : ProfileInfo, pTile : ProfileTile) : MemberViewMgmtModel | any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
      this.profileInfo = pInfo;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo) && this.profileInfo.sitUserId > 0) {
        // debugger;
        this.executeProfileInfoTasks(this.profileInfo).subscribe(data => {
          this.profileInfo = data;
          this.kvAnyArr = this.liaisonService.createKVModelArrFromProfileInfoView(this.profileInfo);
          // debugger;
          this.profileContentKvAnyArr = this.profileContentService.createProfileContentKvAnyArr(this.profileInfo);

          // debugger;
          this.memberViewMgmtModel.populateModelData(this.profileInfo, this.profileContentKvAnyArr, this.kvAnyArr);
          this.profileInfoView.copy(this.profileInfo);
        });
        // debugger;
        this.preference = this.dictionaryService.preferenceDictionary.get(this.profileInfo.sitUserId) as Preference;
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {
          // debugger;
          this.memberService.setPreference(this.preference);
          this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);

          // debugger;
          this.memberViewMgmtModel.populateModelData(null, this.preference, this.profileInfo, this.profileInfoView, this.profileTile, null, this.profileContentKvAnyArr, this.preferenceKvAnyArr, this.kvAnyArr);
        }
      }
      return this.memberViewMgmtModel;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  
  // ----------------------------------------------------------------------------------------------
  // Note: this method will capture all UserData ( not pics) via the
  //        promise of executeProfileInfoTasks():
  // ----------------------------------------------------------------------------------------------
  // Note:  This does NOT violate SOLID principal in a sense that preference is obtained from the dictionary in
  //        executeAllNonProfileTileTasks()  instead of getting it from the backend-server.
  //        However, if the user's preference data is not in the dictionary, it fails at this task.
  //
  //        It is noteworthy that both server-based data as well as dictionary-based data use executeAllNonProfileTileTasks().
  //        Since server-data upon arrival is saved into the respective dictionary, it successfully executes all tasks
  //        for the server-data, as well as dictionary-data when present.
  //
  //        When executeAllNonProfileTileTasks() succesfully executes all tasks, 
  //        it retuns a ProfileInfoView, otherwise it returns ProfileInfo.
  //
  //  we check if the preference was successfully processed to determine what was the return value:
  //  if preferenceKvAnyArr was created, then it returned profileInfoView, else profileInfo:
  // ----------------------------------------------------------------------------------------------
  //            ProfileTileTasks are stand-alone and should be called only for ProfileTile-data.
  //            This method should NOT call/use executeProfileTileTasks(), which renders user's pics only
  //            via VerticalMenues.
  //
  //            In this method the following tasks are executed:
  //            profileInfoTasks, profileContentTasks and preferencesTasks.
  //
  //            executeProfileTileTasks() and exexuteasAllNonProfileTileTasks() are
  //            mutually exclusive/complementary.
  // ---------------------------------------------------------------
  public executeAllNonProfileTileTasks (pinfo : ProfileInfo) : Observable<MemberViewMgmtModel | any> {
   
    return new Observable<any>((subscriber) => {
      let pInfo = pinfo;
      let date = new Date();

      // debugger;
      this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel();
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
        this.profileInfo = pInfo;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo) && this.profileInfo.sitUserId > 0) {
          // debugger;
          this.executeProfileInfoTasks(this.profileInfo).subscribe(data => {
            this.memberViewMgmtModel = data;
            subscriber.next(this.memberViewMgmtModel);
            subscriber.complete();
            // debugger;
          });
          // try to get the preference data from the dictionary only, NOT from the server:
          // -----------------------------------------------------------------------------
          if (this.dictionaryService.preferenceDictionary.has(this.profileInfo.sitUserId)) {
            this.preference = this.dictionaryService.preferenceDictionary.get(this.profileInfo.sitUserId) as Preference;

            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference) && this.preference.sitUserId > 0) {
              // debugger;
              this.memberService.setPreference(this.preference);
              this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);

              // debugger;
              this.memberViewMgmtModel.populateModelData(null, this.preference, pInfo, pInfo, null, null, this.preferenceKvAnyArr, null, null);
              subscriber.next(this.memberViewMgmtModel);
              subscriber.complete();
            }
          }
          // TODO: uncomment after testing
          //else {
          //  this.preferenceService.getPreference(pInfo.sitUserId).subscribe(result => {
          //    this.preference = result;
          //    this.preferenceService.executePreferenceTasks(pInfo.sitUserId).subscribe(data => {
          //      this.preferenceKvAnyArr = data;
          //    });
          //    // debugger;
          //    this.memberViewMgmtModel.populateModelData(null, this.preference, pInfo, pInfo, null, null, this.preferenceKvAnyArr, null, null);
          //    subscriber.next(this.memberViewMgmtModel);
          //    subscriber.complete();
          //  })
          //}
          // debugger;
          // ------------------------------------------------------
          this.profileContentService.fetchAndExecuteProfileContentTasks(this.profileInfo).then(result => {
            // debugger;
            if (result) {
              // debugger;
              this.memberViewMgmtModel = result;
              subscriber.next(this.memberViewMgmtModel);
              subscriber.complete();
            }
            else {
              // debugger;
              console.log(date.getTime() + ': displayUserPicsFromDictionary()=>profileContentService.fetchAndExecuteProfileContentTasks() was rejected.-1');
            }
          }).catch(error => {
            // debugger;
            console.log(date.getTime() + ': displayUserPicsFromDictionary()=>profileContentService.fetchAndExecuteProfileContentTasks() returned a null result.');
          })
				  // ------------------------------------------------------
          //subscriber.next(this.memberViewMgmtModel);
          //subscriber.complete();
        }
        
      }     
    })
  } 
  // ---------------------------------------------------------------
  public executeBeforeViewInit (pTile : ProfileTile, sourcePrefix : string) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) {
      pTile.pElemId = sourcePrefix + pTile.sitUserId.toString();

      pTile = this.oneTimeProfileTileSetup(pTile);

      // setup for offOn indicator:
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.source)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourcePrefix)
      ) { pTile.source = sourcePrefix + pTile.sitUserId; }

      // TODO: place this code-segment to image-rendering-phase at the profileTile:
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.primaryImage)) {
        pTile.isImageRendered = true;
        pTile.isTileSetupComplete = true;
        // debugger;
      }
      // debugger;
      return pTile;
    }
    else { return null; }
  }
  // ---------------------------------------------------------------
  // Objective: asyncly arrived ProfileInfo should create profileInfoView, and
  //            update ProfileTile's { distance, unit & source }.
  //            Then it should create arrKvAny and profileContentArrKvAny that
  //            are used in the view-template.
  // ---------------------------------------------------------------
  //  Note: this method could not be moved to ProfileInfoService.ts
  //        because it is tightly coupled with ProfileTileService.
  //----------------------------------------------------------------
  // Note: This method also has a copy in MemberViewMgmtComponent and ProfileInfoService
  //       It is kept there because from the service, this method does not
  //       update/display user's profileInfo data.
  // ---------------------------------------------------------------
  public executeProfileInfoTasks (pinfo : ProfileInfo) : Observable<any> {
    let pInfo = pinfo;
    return new Observable<any>((subscriber) => {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo) && pInfo.sitUserId > 0) {

        // The folloing method is good and fast:
        // -------------------------------------
        this.memberViewMgmtModel = this.createProfileInfoViewAndUpdateProfileTile(pInfo, new ProfileTile());
        // debugger;       

        // EmitterSubjectService.emitProfileInfoView(this.profileInfo);
        // debugger;
        subscriber.next(this.memberViewMgmtModel);
        subscriber.complete();
      }
    });
  }
  // ---------------------------------------------------------------
  // refactored on 2020/12/29, 2021/05/24
  // ---------------------------------------------------------------
  // Objective: asyncly arrived ProfileTile should create profileInfoView, and
  //            update it's { distance, unit & source }.
  //            Then it should create arrKvAny and profileContentArrKvAny that
  //            are used in the view-template.
  //
  //            ProfileTileTasks are stand-alone and should be called only for ProfileTile-data.
  //            This method also exexuteasAllTasks(), where
  //            profileInfoTasks, profileContentTasks and preferencesTasks are executed
  // ---------------------------------------------------------------
  //  NOTE: Creating memberView from ProfileTile while rest of user data arrives is
  //
  //  Note: This method will be used by both MemberViewMgmt and PhotoMgmt components.
  // ---------------------------------------------------------------
   // Note: This method also has a copy in MemberViewMgmtComponent
  //       It is kept there because from the service, this method does not
  //       update/display user's profileTiles related data.
  // ---------------------------------------------------------------
  // In use now. 
  // ---------------------------------------------------------------
/*public executeProfileTileTasks (inpTile : ProfileTile) : Observable<any> {*/
  public executeProfileTileTasks (inpTile : ProfileTile) : MemberViewMgmtModel | any {
    // return new Observable<any>((subscriber) => {
      // debugger;
      let pTile : any;
      let tpTile : any;
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsPhotoMgmt();
      this.isViewMember = EmitterSubjectService.getIsPhotoMgmt();      

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile) &&  inpTile.sitUserId > 0) {       
        this.profileTile.copySourceToDestination(this.profileTile, inpTile)
      }
      else if (this.isViewMember) {
        this.profileTile = EmitterSubjectService.getSelectedProfileTile();
      }
      else if (this.isPhotoMgmt || this.isMyProfile) {
        this.profileTile = EmitterSubjectService.getAvatarProfileTile();
      }
      else {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
          this.profileTile = this.dictionaryService.getProfileTileBySitUserId(this.sitUser.sitUserId);
        }
      }
      // debugger;
      // ----------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId > 0) {

        // debugger;
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile.image) && this.profileTile.image.length > 0) {
        //  // debugger;
        //  this.bias.setProfileTileBackgroundImage(this.profileTile);
        //}

        pTile = this.executeBeforeViewInit(this.profileTile, 'profileTile-');
      }
      this.profileTile = this.oneTimeProfileTileSetup(pTile);

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId > 0) {
        // debugger;
        EmitterSubjectService.setProfileTile(this.profileTile);
        // debugger;

        this.memberViewMgmtModel = this.createProfileInfoViewAndUpdateProfileTile(new ProfileInfo(), this.profileTile);
        this.memberViewMgmtModel.sitUserId = this.profileTile.sitUserId;
        // debugger;
        // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.profileInfo)) {
        //  debugger;
        //  this.memberViewMgmtModel = this.executeAllTasks(this.memberViewMgmtModel.profileInfo, this.profileTile);
        // }
        

        // if KvContent does not show, uncomment these lines:
        // --------------------------------------------------
        // this.executeProfileInfoTasks(this.profileInfoView).then(data => {
        //  this.profileInfo = data;
       
        //  EmitterSubjectService.emitProfileInfo(this.profileInfo); // will be onbtained by memberService.getDictionaryMemberProfile()
        //  this.profileInfoView = this.profileInfo;

        //  // Note: this may be redundant:
        //  // ----------------------------
        //  this.kvAnyArr = this.profileSupportService.createKVModelArrFromProfileInfoView(this.profileInfo);
        //  this.profileContentKvAnyArr = ArrayServiceStatic.mergeArraysUniq(this.profileContentKvAnyArr, this.kvAnyArr as KvAny[]);
        //  // debugger;
        //   EmitterSubjectService.emitProfileContentKvAnyArr(this.profileContentKvAnyArr);
        //   // ----------------------------------
        //  this.profileInfo = CopyServiceStatic.copyFromProfileTileToProfileInfo(this.profileTile);


        //  // debugger;
        //  this.memberViewMgmtModel = this.executeAllTasks(this.memberViewMgmtModel.profileInfo, this.profileTile);
        // })
        
      }
      // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile.primaryImage) && this.profileTile.primaryImage.length > 0) {
        // debugger;
        this.bias.setProfileTileBackgroundImage(this.profileTile);
      }

      // debugger;
      EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel); // will also set the model
      //subscriber.next(this.memberViewMgmtModel);
      //subscriber.complete();
    return this.memberViewMgmtModel;
    //})
  }
  // ---------------------------------------------------------------
  // Note: TODO: inspect for difference between the 2:
  //       This one is moved from AbstractPagerVPhotoNavService:
  //       P.S. This has a commented out line which is essential for this method to work!!
  // ---------------------------------------------------------------
  public executeProfileTileKvPhotoAndGlyphMenuTasks2 (inpTile : ProfileTile) : Observable<any> {
    return new Observable<any>((subscriber) => {
      // ------------------------------------------------------------------
      // Note: all display of PhotoNav and GlyphArrs should go through
      //       pagerVKvPhotoNavComponent. They should not directly be
      //       implemented from anywhere else. Hence emit the following:
      //       EmitProfileImages();
      // ------------------------------------------------------------------
      // debugger;
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto) &&
        (
          (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.image) && inpTile.kvPhoto.image.length > 0)
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.value) && inpTile.kvPhoto.value.length > 0)
        )

      ) {
        this.profileTile = inpTile;
        let tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr([ this.profileTile.kvPhoto ], 'pagerVKvPhotoNav-');
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpKvPhotoArr) && tpKvPhotoArr.length > 0) {
          this.profileTile.kvPhoto = tpKvPhotoArr[ 0 ];
        }

       // this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(this.profileTile.kvPhoto, [ this.profileTile.kvPhoto ]);

        // debugger;
        if (this.isViewMember) {
          // debugger;
          // let sitUser : SitUser = EmitterSubjectService.getSitUserModel();
          this.loginSuccess = EmitterSubjectService.getLoginSuccess();
          if (this.loginSuccess.signedInUserId !== this.profileTile.sitUserId) {
            // this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu
            // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.profileTile.sitUserId); (this.profileTile.sitUserId); // will display photo primary/private/public glyphs
            // debugger;            
          }
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
            // debugger;
            this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
          }
        }
        if (this.isPhotoMgmt || this.isMyProfile) {
          // debugger;

          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.profileTile.kvPhoto); // will display photo primary/private/public glyphs
          this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;
        }

        // debugger;
        EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
        // debugger;
        subscriber.next(this.pagerVKvPhotoNav);
      }
      else {
        this.message = 'pagerVKvPhotoNavService.executeProfileTileKvPhotoAndGlyphMenuTasks ->profileTile was null or it\'s image was null';
        console.log(this.message);
      }
    })
  }
  // ---------------------------------------------------------------
  public executeProfileTileKvPhotoAndGlyphMenuTasks (inpTile : ProfileTile) : Observable<any> {
    return new Observable<any>((subscriber) => {
      // ------------------------------------------------------------------
      // Note: all display of PhotoNav and GlyphArrs should go through
      //       pagerVKvPhotoNavComponent. They should not directly be
      //       implemented from anywhere else. Hence emit the following:
      //       EmitProfileImages();
      // ------------------------------------------------------------------
      // debugger;
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto) &&
        (
          (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.image) && inpTile.kvPhoto.image.length > 0)
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.value) && inpTile.kvPhoto.value.length > 0)
        )
      ) {
        this.profileTile = inpTile;
        let tpKvPhotoArr = this.pagerSupportService.setIndexAndPageNumAndElemIdForKvPhotoArr([ this.profileTile.kvPhoto ], 'pagerVKvPhotoNav-');
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpKvPhotoArr) && tpKvPhotoArr.length > 0) {
          this.profileTile.kvPhoto = tpKvPhotoArr[ 0 ];
        }
       // debugger;
       //  EmitterSubjectService.emitProfileImages([ this.profileTile.kvPhoto ]);

        // debugger;
        if (this.isViewMember) {
          // debugger;
          let sitUser : SitUser = EmitterSubjectService.getSitUserModel();
          if (sitUser.sitUserId === this.profileTile.sitUserId) {
            // this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(sitUser.sitUserId); // will display photo primary/private/public glyphs
            // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(sitUser.sitUserId); 
            // debugger;
            //this.pagerPhotoMgmtService.setKvPhotoArr([ this.profileTile.kvPhoto ]);
            //this.pagerVKvPhotoNav = this.pagerVKvPhotoNavService.getPagerVKvPhotoForCurrentPage(this.profileTile.kvPhoto );
            //this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;

            this.pagerVKvPhotoNav = this.abstractPagerVPhotoNavService.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(this.profileTile.kvPhoto, [ this.profileTile.kvPhoto ]);
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
              // debugger;
              this.pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember;
            }
          }
        }
        else if (this.isMyProfile) {
           debugger;
          // this.pagerVKvPhotoNav = this.photoMgmtService.setPageOfPhotoNav(1, [ this.profileTile.kvPhoto ]);
          // this.pagerVKvPhotoNav = this.pagerVKvPhotoNavService.getPagerVKvPhotoForCurrentPage(this.profileTile.kvPhoto);
          this.pagerVKvPhotoNav = this.abstractPagerVPhotoNavService.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(this.profileTile.kvPhoto, [ this.profileTile.kvPhoto ]);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
            && this.pagerVKvPhotoNav.pagedItems.length > 0) {
            // debugger;
            this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displaySignInUserPrimaryKvPhotoGlyphs();
          }
        }
        else if (this.isPhotoMgmt) {
          // debugger;
          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.profileTile.kvPhoto); // will display photo primary/private/public glyphs
          this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;
        }
        subscriber.next(this.pagerVKvPhotoNav);
      }
    })
  }
  // ---------------------------------------------------------------
  //public executeProfileTileOnInit2 () : void {
  //  // ----------------------------------------------------------------
  //  // Begin: avatar's ProfileTile case:
  //  // ----------------------------------------------------------------
  //  // If ProfileTile is available (since emitted and saved on emitter),
  //  // display it's information first:
  //  // tested on 20220525, 20220610
  //  // ----------------------------------------------------------------
  //  // debugger;
  //  this.profileTile = EmitterSubjectService.getAvatarProfileTile();
  //  // debugger;

  //  // experimental:
  //  // this.ngAfterViewInit();

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId > 0) {
  //    // debugger;
  //    this.profileTile = this.profileTile.copy(this.profileTile);

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile.image)
  //      && this.profileTile.image.length > 0) {
  //      // debugger;
  //      this.bias.setProfileTileBackgroundImage(this.profileTile);
  //    }
  //    // debugger;
  //    this.profileInfo = this.profileTile.copyToProfileInfo();
  //    // debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.image)
  //      && this.profileInfo.image.length === 0
  //      && this.profileTile.image.length > 0) {
  //      this.profileInfo.image = this.profileTile.image;
  //      // debugger;
  //    }

  //    // debugger;      
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)) {
  //      // debugger;
  //      this.executeProfileTileTasks(this.profileTile).then(result => {
  //        // debugger;
  //        this.memberViewMgmtModel = result;
  //        // this.extractDataFromMemberViewMgmtModel();

  //        // emit the profileImages here and set the glyphMenu here:
  //        // -------------------------------------------------------
  //        this.executeProfileTileKvPhotoAndGlyphMenuTasks(this.profileTile).then(data => {
  //          this.pagerVKvPhotoNav = data;
  //          this.pagerVKvPhotoNavService.executePagedItemsAndGlyphMenuTasks(this.pagerVKvPhotoNav);
  //          // debugger;
  //          // -----------------------------------------------------------
  //          // NOTE: the following displays the PhotoMgmtGlyphiconMenu:
  //          // -----------------------------------------------------------
  //          // debugger;
  //          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displaySignInUserPrimaryKvPhotoGlyphs();
  //          EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.kvMemActGlyphArrForPhotoMgmt);
  //          // this.ngAfterViewInit();
  //        })
  //        // this.ngAfterViewInit();
  //      });
  //    }
  //    // this.ngAfterViewInit();
  //  }
  //}
  // ---------------------------------------------------------------
  //public executeProfileTileOnInit (sitUserId : number) : Promise<any> {
  //  // ----------------------------------------------------------------
  //  // Begin: avatar's ProfileTile case:
  //  // ----------------------------------------------------------------
  //  // If ProfileTile is available (since emitted and saved on emitter),
  //  // display it's information first:
  //  // tested on 20220525, 20220610
  //  // ----------------------------------------------------------------
  //  debugger;
  //  this.profileTile = EmitterSubjectService.getAvatarProfileTile();
  //  return new Promise<any>((resolve, reject) => {
  //    // experimental:
  //    // this.ngAfterViewInit();
  //    debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile) && this.profileTile.sitUserId > 0) {

  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile.image)
  //        && this.profileTile.image.length > 0) {
  //         debugger;
  //        // EmitterSubjectService.emitDisplayBgiOutsideAngular(this.profileTile.image);
  //        this.bias.setProfileTileBackgroundImage(this.profileTile);
  //      }

  //      this.executeProfileTileTasks(this.profileTile).subscribe(result => {
  //        debugger;
  //        if (result) {
  //          this.memberViewMgmtModel = result;
  //          EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //        // this.extractDataFromMemberViewMgmtModel();
  //        }          
  //      });

  //      // debugger;
  //      this.profileInfo = this.profileTile.copyToProfileInfo();

  //      // debugger;
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.image)
  //        && this.profileInfo.image.length === 0
  //        && this.profileTile.image.length > 0) {
  //        this.profileInfo.image = this.profileTile.image;
  //        // debugger;
  //      }

  //      // debugger;      
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)) {
  //        // debugger;
  //        // this.executeAllNonProfileTileTasks(this.profileInfo).then(result => {
  //        //  this.memberViewMgmtModel = result;
  //        //  debugger;
  //        //  EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //        // });
  //        debugger;

  //        this.memberViewMgmtModel = this.createProfileInfoViewAndUpdateProfileTile(this.profileInfo, this.profileTile);
  //        debugger;
  //        EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);


  //        // emit the profileImages here and set the glyphMenu here:
  //        // -------------------------------------------------------
  //        this.pagerVKvPhotoNavService.executeProfileTileKvPhotoAndGlyphMenuTasks(this.profileTile).subscribe(data => {
  //          if (data) {
  //            this.pagerVKvPhotoNav = data as PagerPhotoMgmt;
  //            EmitterSubjectService.emitDisplayPagerVKvPhotoNav(this.pagerVKvPhotoNav);
  //          }
  //          else {
  //            reject('executeProfileTileOnInit().pagerVKvPhotoNavService.executeProfileTileKvPhotoAndGlyphMenuTasks() failed;');
  //          }

  //          // todo: check if it is needed?
  //          // ----------------------------
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
  //            // this.pagerVKvPhotoNavService.executePagedItemsAndGlyphMenuTasks(this.pagerVKvPhotoNav).then(data1 => {
  //            //  this.pagerVKvPhotoNav = data1 as PagerPhotoMgmt;
  //            //  debugger;
  //            // });
  //            debugger;
  //            this.pagerVKvPhotoNavService.executePagerVKvPhotoNavKvPhotoTasks(this.pagerVKvPhotoNav).then(data1 => {
  //              debugger;
  //              if (data1) {
  //                debugger;
  //                resolve(data1);
  //              }
  //              else {
  //                reject('pagerVKvPhotoNavService.executePagerVKvPhotoNavKvPhotoTasks() failed;');
  //              }
  //            });
  //          }
  //          else {
  //            debugger;
  //            resolve(data);
  //          }
  //          // debugger;
  //          // -----------------------------------------------------------
  //          // NOTE: the following displays the PhotoMgmtGlyphiconMenu:
  //          // -----------------------------------------------------------
  //          // debugger;
  //          //this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displaySignInUserPrimaryKvPhotoGlyphs();
  //          //EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.kvMemActGlyphArrForPhotoMgmt);
  //          //this.ngAfterViewInit();
  //        })
  //        // this.ngAfterViewInit();
  //      }
  //      else {
  //        reject('executeProfileTileOnInit().profileInfo is null or undefined');
  //      }
  //      // this.ngAfterViewInit();
  //    }
  //    else {
  //      reject(console.log('Error occured in profileTileService.executeProfileTileOnInit (sitUserId :  ' + sitUserId ));
  //    }
  //  }).catch((error) => {
  //    console.log('Error occured in profileTileService.executeProfileTileOnInit (sitUserId :  ' + sitUserId + '); error: ' + error);
  //  });
  //}
  // --------------------------------------------------------------------------
  // Note: uncomment if view does not update properly for content & preference:
  // --------------------------------------------------------------------------
  //  NOT in user now, but DO NOT DELETE THIS.
  // --------------------------------------------------------------------------
  //public fetchAndExecuteAllTasks (pInfo : ProfileInfo) : ProfileInfo {
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
  //    this.profileInfo = pInfo;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo) && this.profileInfo.sitUserId > 0) {
  //      // debugger;
  //      this.executeProfileInfoTasks(this.profileInfo).subscribe(data => {
  //        this.profileInfo = data;
  //      });
  //      // debugger;
  //      this.profileContentService.fetchAndExecuteProfileContentTasks(this.profileInfo).then(data => {
  //        this.profileContentKvAnyArr = data;
  //        // debugger;
  //      });
  //      this.preferenceService.executePreferenceTasks(this.profileInfo.sitUserId).subscribe(data => {
  //        // debugger;
  //        this.preference = data;
  //      })
  //    }
  //  }
  //  return pInfo;
  //}
  // ---------------------------------------------------------------
  //public setIsObjectStoreNameFoundInTables (value : boolean) : void {
  //  this.isProfileTileDictionaryCreatedFromIndexDb = value;
  //}
  // ---------------------------------------------------------------
  //public getIsObjectStoreNameFoundInTables () : boolean {
  //  return this.isProfileTileDictionaryCreatedFromIndexDb;
  //}
  // ---------------------------------------------------------------
  public getIncompleteTiles () : any[] {
    return this.incompleteTilesArr;
  }
  // ---------------------------------------------------------------
  public processDistanceUnitForProfileTileAndProfileInfoView (distanceUnit : Unit) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.distance)
      && distanceUnit.distance >= 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.unit)) {
      let result = this.heartbeatService.processDistanceUnit(distanceUnit);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)) {
          this.profileInfoView.distance = distanceUnit.distance.toFixed(2);
          this.profileInfoView.unit = distanceUnit.unit;
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)) {
          this.profileTile.distance = distanceUnit.distance;
          this.profileTile.unit = distanceUnit.unit;
          this.profileTile.distanceStr = distanceUnit.distance.toFixed(2) + ' ' + distanceUnit.unit;
          this.profileTile.distanceUnit = distanceUnit;
          // debugger;
        }
      }
      // debugger;
    }
  }
  // ---------------------------------------------------------------
  public setMessageTimer () : void {
    let intervalMsgTime = setTimeout(() => {
      this.message = '';
      clearInterval(intervalMsgTime);
    }, 1000);
    clearTimeout(intervalMsgTime);
  }
  // ---------------------------------------------------------------------------------
  public setupKvPhotoModel (kvp : KvPhoto) : KvPhoto | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
        kvp.value = kvp.image;
      }
      else if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.image) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.value)) {
        kvp.image = kvp.value;
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp.pic)) {
        kvp.pic = kvp.image;
      }

      if (kvp.isPrimary) {
        kvp.key = kvp.glyphName = "Primary";
        kvp.privacy = "Public";
      }
    }
    return kvp;
  }

  // ---------------------------------------------------------------
  public setupProfileTileImageAndDisplay (inpTile : ProfileTile) : Observable<ProfileInfo | any> {
    return new Observable<any>((subscriber) => {
      // ------------------------------------------------------------------
      // Note: all display of PhotoNav and GlyphArrs should go through
      //       pagerVKvPhotoNavComponent. They should not directly be
      //       implemented from anywhere else. Hence emit the following:
      //       EmitProfileImages();
      // ------------------------------------------------------------------
      //  Note: below code is needed to display the VerticalMenues to be displayed
      //        VerticalMenues are rendered by appComponent's profileImagesEmitter.
      // ------------------------------------------------------------------
      // ======================================================
      // Begin of VerticalMemActGlyphsMenus display: required!
      // ======================================================
      this.isMyProfile = EmitterSubjectService.getIsMyProfile();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();

      // debugger;     
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto) &&
        (
          (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.image) && inpTile.kvPhoto.image.length > 0)
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.pic) && inpTile.kvPhoto.pic.length > 0)
          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inpTile.kvPhoto.value) && inpTile.kvPhoto.value.length > 0)
        )
      ) {
        debugger;

        //let tKvPhoto = new KvPhoto();
        //tKvPhoto = tKvPhoto.copy(inpTile.kvPhoto);
        //tKvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(tKvPhoto.value);

        // Note: this is the emitter that displays the All VerticalMenus:
        // --------------------------------------------------------------
        EmitterSubjectService.emitProfileImages([ inpTile.kvPhoto ]);
        debugger;
        if (inpTile.sitUserId === this.loginSuccess.signedInUserId) {
          debugger;
          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(inpTile.kvPhoto);
          EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.kvMemActGlyphArrForPhotoMgmt);
          subscriber.next(this.kvMemActGlyphArrForPhotoMgmt);
          subscriber.complete();
        }
        else {
          // this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.createAndDisplayViewMemberGlyphMenu(inpTile.sitUserId);
          EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.kvMemActGlyphArrForPhotoMgmt);
          subscriber.next(this.kvMemActGlyphArrForPhotoMgmt);
          subscriber.complete();
        }        
      }
    })
  }
  // ---------------------------------------------------------------
  // Note:  This should be called after all tiles are rendered
  // ---------------------------------------------------------------
  public setupProfileTilesArrHeartbeats (pTileArr : ProfileTile[]) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTileArr) && pTileArr.length > 0) {
      pTileArr.map(e => {
        e = this.setupProfileTileHeartbeat(e);
      });
    }
  }
  // ---------------------------------------------------------------
  // This method's heartbeatTime is considered as initHearbeat
  // ---------------------------------------------------------------
  public setupProfileTileHeartbeat (pTile : ProfileTile) : ProfileTile {
    debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) {
      this.heartbeatService.getHeartbeatObservable(pTile.sitUserId, 'MembersComponent.setupProfileTileHeartbeat').subscribe(hb => {
        if (hb.sitUserId === pTile.sitUserId) {
          pTile.heartbeat = hb;
          pTile.heartbeat.heartbeatTime = pTile.heartbeat.prevHeartbeat = hb.heartbeatTime;
        }
      });
    }
    return pTile;
  }
  // ---------------------------------------------------------------
  public isProfileTileValid (pTile : ProfileTile) : boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.gender) &&
        // !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.dob) &&
        // !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.image) &&
        // !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.profileName) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.sexuality) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.sitUserId) && pTile.sitUserId > 0
      ) {
        return true;
    }
    else {
      debugger;
      return false;
    }
  }
  // ---------------------------------------------------------------
  public setupMissingProfileTileProperties (pTile : ProfileTile) : ProfileTile {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.profileName)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.sitUserKey)) {
        // debugger;
        pTile.profileName = DateStringServiceStatic.getProfileNameFromSitUserKey(pTile.sitUserKey);
      }
    }
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.primaryImage) || pTile.primaryImage.length < 20) {
      pTile.primaryImage = "url('/photos/avatar2.png')";
    }    
    return pTile;
  }

  // ---------------------------------------------------------------
  public isProfileTileValidForOffOn (pTile : ProfileTile) : boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      if (
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn.away) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.sitUserId) &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.source) &&
        pTile.sitUserId > 0
      ) {
        return true;
      }
      else return false;
    }
    else return false;
  }
  // ---------------------------------------------------------------
  public isProfileTileArrayComplete (profileTiles : ProfileTile[]): boolean {
    let isTilesArrComplete = true;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileTiles) && profileTiles.length > 0) {
      profileTiles.map(e => {
        if ( !this.isProfileTileValid( e ) )
        {
          isTilesArrComplete = false;
        }
        // isTilesArrComplete = false; // TODO: restore
      });
    }
    return isTilesArrComplete;
  }
  // ---------------------------------------------------------------
  public periodicProfileTileSetup (pTile : ProfileTile) : EmitOffOnResult {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      if (pTile.isTileSetupComplete) {
        /*
         * Note : this heartbeat-related segment is for dev-&-testing-purpose only!
         * TODO : get the heatbeat from the server. This is for dev-&-testing-purpose only.
         */

        let thb = DateStringServiceStatic.getTicks(new Date());

        if (thb) {
          thb = thb - 10;
          pTile.heartbeat.heartbeatTime = thb;
        }
        /*
         * debugger;
         * setup off-on-color-indicator
         * this.offOnResult = this.offOnService.setOffOnStatus(pTile);
         */

        return this.offOnResult;
      }
    }
    return new EmitOffOnResult();
  }
  // ---------------------------------------------------------------
  // Note:  This is the one-stop-shop for a complete ProfileTile ready to be rendered
  //        with it's image to be used as a background-image.
  //
  //        To use the image as src, transform the image as follows:
  //        image = JsRegExpServiceStatic.normalizeImageData(image);
  // ---------------------------------------------------------------
  public prepareProfileTile (pTile : ProfileTile) : any {
    let outTile : ProfileTile;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) {
      outTile = this.executeBeforeViewInit(pTile, 'profileTile-');
      outTile = this.checkAndSetProfileTileImage(outTile);      
    }
    else {
      outTile = pTile;
    }
    return outTile;
  }

  // ---------------------------------------------------------------
  // Note: This is not called by any other method or component
  // ---------------------------------------------------------------
  public prepareProfileTiles (dTiles : ProfileTile[]) : any {
    let tProfileTilesArr : ProfileTile[] = []; //CopyServiceStatic.deepCloneProfileTileArray( dTiles );
    let i = 0;
    let item : ProfileTile;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dTiles) && dTiles.length > 0) {
      for (let tile of dTiles) {
        item = this.prepareProfileTile(tile);
        tProfileTilesArr.push(item);
      }
      // debugger;
      tProfileTilesArr = ArrayServiceStatic.sortProfileTileArrByDistance(tProfileTilesArr);
      EmitterSubjectService.setProfileTilesArr(tProfileTilesArr);
      // debugger;
      return tProfileTilesArr;
    }
    else return dTiles;
  }
  // ---------------------------------------------------------------
  public processDistanceUnitForProfileTile (distanceUnit : Unit) : ProfileTile {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.distance)
      && distanceUnit.distance >= 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.unit)) {
      let result = this.heartbeatService.processDistanceUnit(distanceUnit);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)) {
          this.profileTile.distance = distanceUnit.distance;
          this.profileTile.unit = distanceUnit.unit;
          this.profileTile.distanceStr = distanceUnit.distance.toFixed(2) + ' ' + distanceUnit.unit;
          this.profileTile.distanceUnit = distanceUnit;
          // debugger;
        }
      }
      // debugger;
    }
    return this.profileTile;
  }
  // ---------------------------------------------------------------
  public processProfileTileForHeartbeat (hb : Heartbeat) : ProfileTile {
    let distanceUnit = new Unit();
    // if Heartbeat's distance is not already calculated:
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.distance)) {
      if (hb.distance === 0) {
        // compute distance and unit:
        distanceUnit = this.heartbeatService.calculateDistance(hb, this.loginSuccess.heartbeat, true); // (dest, origin, isKm)
        // debugger;

        let tProfileTile = this.processDistanceUnitForProfileTile(distanceUnit) as ProfileTile;
        this.profileTile = CopyServiceStatic.copyProfileTileIfExists(this.profileTile, tProfileTile);
      }
    }
    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
      this.profileTile.distance = this.profileInfoView.distance;
      this.profileTile.distanceStr = this.profileInfoView.distance + ' ' + this.profileInfoView.unit;
      this.profileTile.heartbeat = hb;
      this.profileTile.distanceUnit = distanceUnit;
      // debugger;
    }
    EmitterSubjectService.setProfileTile(this.profileTile);
    return this.profileTile;
  }
  // ---------------------------------------------------------------
  /*
 * ---------------------------------------------------------------
 * ProfileTileSetupManager
 * ---------------------------------------------------------------
 */
  public oneTimeProfileTileSetup (pTile : ProfileTile) : ProfileTile {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && pTile.sitUserId > 0) {

      // distance unit
      pTile.unit = this.determineUnit(pTile);

      // TODO : distance and isOnline provided by the server. If server does not provide, compute here
      
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.offOn)) {  
        // set the offOnArr value for the child-component
        this.offOnArr = pTile.offOn.offOnArr;
      }

      // Age:
      // TODO: does not work. Need to fix it!
      // ------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.dob)) {
        pTile.age = StringServiceStatic.ageFromDobTicks(parseFloat(pTile.dob));
      }


      // if the profileTile.image.length < 32, these are default images
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.primaryImage) && pTile.primaryImage.length < 32 && pTile.sitUserId === this.loginSuccess.signedInUserId) {
        EmitterSubjectService.messageEmitter.next('You do not have a primary profile picture. Please add a picture to your profile.');
      }

      //should trigger changedetection in offOnComponent.ts
      // this.profileTile.copy(pTile); 
      // debugger;      
    }
    return pTile;
  }
  // ---------------------------------------------------------------
  // Note: TODO: Depracate
  // ---------------------------------------------------------------
  public setupProfileTileElement (pTile : ProfileTile, pTileElem ?: ElementRef) : ProfileTile {
    // debugger;
    let id = '';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTileElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTileElem?.nativeElement.id)) {
      id = pTileElem?.nativeElement.id;
      let sitUserid = parseInt(id.split('-')[ 1 ]);
      // debugger;
      if (id.indexOf('pTileSource') !== -1 && sitUserid === pTile.sitUserId && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTileElem)) {
        pTile.pTileElement = pTileElem as ElementRef; // this.getHtmlElementById(this.offOn.awayId);
        //EmitterSubjectService.setOffOnElement(childElem as ElementRef, 'spanAway');
         debugger;
      }
    }
    return pTile;
  }
  // ---------------------------------------------------------------
  // Note: returns a sorted array of profileTiles
  // TODO: test
  // ---------------------------------------------------------------
  public uniquelyAddInProfileTileArray (arr : ProfileTile[], pTile : ProfileTile) : ProfileTile[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr)) {
      if (ArrayServiceStatic.getIndexOfTileInArr(arr, pTile) < 0) {
        arr.push(pTile);
      }
      // debugger;
    }
    return arr;
  }
  // ---------------------------------------------------------------
  // Note : This method will get 25 ProfileTileIds at a time
  // Note: This is calles by MemberTilesComponent
  // TODO: cannot user FrequentlyUsedFunctionsServiceStatic.nullPromise();
  // ---------------------------------------------------------------
  public getBatchOfProfileTileIds (beginIndex : number) : Observable<any> {
     debugger;
    return new Observable((subscriber) => {
      if (beginIndex > -1) {
        const bnComm : Communication = new Communication();

        bnComm.beginIndex = beginIndex;
        bnComm.sitUserId = 0;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);

        // debugger;
        if (this.boxNonceEntity) {
          this.httpService.postObservable('/api/Member/GetBatchOfProfileTileIds', JSON.stringify({
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          }),
            'json',
          ).subscribe((result) => {
            debugger;
            let batchOfTileIds = result as number[];
            this.dictionaryService.updateDictionary(batchOfTileIds, 'profileTileId', this.loginSuccess.signedInUserId);
            this.dbDexieToDictionaryService
              .saveDictionaryToDbDexie(
                this.dictionaryService.sitUserDictionary, 'sitUser', this.loginSuccess.signedInUserId);

            EmitterSubjectService.emitHideSpinner(true);
            subscriber.next(batchOfTileIds);
            subscriber.complete();
            // debugger;
          }, (error) => {
            // debugger;
            this.message = 'Error occured in GetBatchOfProfileTileIds();\n Error-msg : ' + error.message;
            console.log(this.message);
            EmitterSubjectService.emitMyErrorLog(this.message);
            // debugger;
            console.log(this.message);
          });
        }        
      }      
    });
  }
  // ---------------------------------------------------------------
  //  Note: This method is in use
  //  Note: This method is calle by MembersComponent & MemberTilesComponent
  // TODO: cannot user FrequentlyUsedFunctionsServiceStatic.nullPromise();
  // --------------------------------------------------------------
  public getBatchOfProfileTiles (beginIndex : number) : Observable<any> {
    debugger;
    let anyTile : any;
    let tpTile : ProfileTile;
    let tpTiles : ProfileTile[] = [];
    let tIncompleteTiles : ProfileTile[] = [];
    return Observable.create((observer : any) => {
      // debugger;
      if (beginIndex > -1) {
        const bnComm : Communication = new Communication();

        bnComm.beginIndex = beginIndex;
        bnComm.sitUserId = 0;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);

        // debugger;
        if (this.boxNonceEntity) {
          this.httpService.postPromise('Member/GetBatchOfProfileTiles', {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          },
            'json',
          ).then((result) => {
            // debugger;
            let boxNonceArr : BoxNonce[] = JSON.parse(result);
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonceArr) && boxNonceArr.length > 0) {
              boxNonceArr.map(e => {
                // debugger;
                this.boxNonceEntity = new BoxNonceEntity();
                this.boxNonceEntity.box = e.box;
                this.boxNonceEntity.nonce = e.nonce;
                this.boxNonceEntity.date = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.date) ? e.date.toString() : DateStringServiceStatic.getTicks(new Date()).toString();
                this.boxNonceEntity.id = e.sitUserId;
                this.boxNonceEntity.key = e.sitUserId.toString();
                this.boxNonceEntity.entityName = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.entityName) ? e.entityName : 'ProfileTile';
                this.boxNonceEntity.sitUserId = e.sitUserId;
                // debugger;

                anyTile = SlakezSaltServiceStatic.boxUnsalt(this.boxNonceEntity);
                // debugger;
                tpTile = JSON.parse(anyTile) as ProfileTile;
                // debugger;
                if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile.id)) tpTile.id = this.boxNonceEntity.id.toString();
                if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile.date)) tpTile.date = this.boxNonceEntity.date.toString();
                if (tpTile.sitUserId == 0) tpTile.sitUserId = this.boxNonceEntity.sitUserId;

                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile)) {
                  // debugger;

                  tpTile = this.prepareProfileTile(tpTile);
                  if (this.isProfileTileValid(tpTile)) {
                    tpTile.isTileSetupComplete = true;
                    this.dictionaryService.updateDictionary(tpTile, 'ProfileTile', tpTile.sitUserId);
                    // debugger;                                 
                    observer.next(this.dictionaryService.profileTileDictionary.size > 0 ? this.dictionaryService.profileTileDictionary.values(): []);
                  }
                  else {
                    tIncompleteTiles.push(tpTile);
                    // debugger;
                  }
                }
              });
            }
            // debugger;
          }, (error) => {
            // debugger;
            this.message = 'Error occured in GetBatchOfProfileTiles();\n Error-msg : ' + error.message;
            console.log(this.message);
            // EmitterSubjectService.emitMyErrorLog(this.message);
            // debugger;
          });
        }
      }
      else return FrequentlyUsedFunctionsServiceStatic.nullPromise();
    });
  }
  // --------------------------------------------------------------
  //  Note: This method is in use
  //  Note: This method is calle by MembersComponent, MemberTilesComponent & MembersIndividuallyComponent
  // --------------------------------------------------------------
  public getProfileTile (situserid : number) : Observable<any> {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //  debugger;
    let tpTile : ProfileTile = new ProfileTile();
    let sitUserId : number = situserid;

    return new Observable((subscriber) => {     

      if (sitUserId > 0 && this.loginSuccess.signedInUserId > 0) {
        const bnComm : Communication = new Communication();
        // debugger;
        bnComm.sitUserId = sitUserId;
        bnComm.signedInUserId = this.loginSuccess.signedInUserId;
        this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(bnComm));
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
       
          // debugger;
          this.httpService.post('Member/GetProfileTile',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json',
          ).subscribe((result) => {
            // debugger;
            let cipherbox = result as BoxNonceEntity;
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox) &&
              !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox.box) &&
              !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cipherbox.nonce)) {
              this.boxNonceEntity = new BoxNonceEntity();
              this.boxNonceEntity.box = cipherbox.box;
              this.boxNonceEntity.nonce = cipherbox.nonce;

              let tempTile : ProfileTile = new ProfileTile();
              /*
              * do not directly assing to profileTile!! capture it in a let first
              */
              // debugger;
              const unboxedTile = SlakezSaltServiceStatic.boxUnsalt(cipherbox);
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unboxedTile)) {
                // debugger;
                tempTile = JSON.parse(unboxedTile) as ProfileTile;
              }
              // debugger;
              // if key 'sitUserId' exists, the unslating produced data
              // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempTile)) {
              //  tempTile = JSON.parse(tempTile); // convert string to model
              // }
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempTile) && tempTile.sitUserId > 0) {
                // debugger;
                if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempTile.sitUserKey) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.sitUserKey)) {
                  tempTile.sitUserKey = result.sitUserKey;
                  // debugger;
                }
                if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempTile.profileName) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.profileName)) {
                  tempTile.profileName = result.profileName;
                  // debugger;
                }               
                tpTile = tpTile.copySourceToDestination(tpTile, tempTile);
                // debugger;
                tpTile = this.prepareProfileTile(tpTile);

                // debugger;
                // -----------------------------------------------------------------------------
                // IMPORTANT: DO NOT check tile validity,
                //            as anyone providing an incomplete tile will break the application!
                // -----------------------------------------------------------------------------
                // if (!this.isProfileTileValid(tpTile)) {
                //   debugger;
                //  let index = ArrayServiceStatic.getIndexOfTileInArr(this.incompleteTilesArr, tpTile);
                //  if (index === -1) {
                //    this.incompleteTilesArr.push(tpTile); // will set image to avatar2
                //  }
                //  this.message = 'getProfileTile(sitUserId: ' + sitUserId + ') returned a null or undefined value.';
                //  console.log(this.message);
                //  reject(this.message);
                //  /*resolve(this.incompleteTilesArr.length); //if the tile is incomplete, we send the length of incomplete tiles array*/
                //  // reject(this.incompleteTilesArr); //if the tile is incomplete, we send the length of incomplete tiles array
                // }
                // -----------------------------------------------------------------------------

                tpTile.pElemId = 'memberTileComponentId-' + tpTile.sitUserId;
                // debugger;
                this.dictionaryService.updateDictionary(tpTile, 'ProfileTile', tpTile.sitUserId);
               
                // debugger;
                subscriber.next(tpTile);     
              }
              else {
                // debugger;
                this.message = 'tempTile in  profileTileService.getProfileTiley() after unsalting was null or undefined for sitUserId: ' + sitUserId;
                console.log(this.message);
              }
            }
            else {
              // debugger;
              this.message = ' profileTileService.getProfileTile(sitUserId: ' + sitUserId + ') cipherbox/cipherbox.box/cipherbox.nonce is null or undefined.';
              console.log(this.message);
            }
          })
        }
        else {
          // debugger;
          this.message = 'boxNonceEntity in  profileTileService.getProfileTile() was null or undefined for sitUserId: ' + sitUserId; // tempTile being null message
          console.log(this.message);
        }
      }
      else {
        // debugger;
        this.message = 'sitUserId in  profileTileService.getProfileTileIndividually() was null or undefined; sitUserId: ' + sitUserId;
        console.log(this.message);
      }
    })
  }
  // ----------------------------------------------------------------------------------------
  //  This method is not in use:
  // ----------------------------------------------------------------------------------------
  getProfileTileIndividuallyFromServerInBackground (batchOfIds : number[]) : Observable<any> | any{
   
    debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(batchOfIds) && batchOfIds.length > 0) {

        for (var i = 0; i < batchOfIds.length; i++) {
          // NOTE: BACKGROUND-PROCESSING:
          debugger;
          this.rdm = new RemoteDataModel();
          this.rdm.data = batchOfIds[ i ];
          this.rdm.methodName = 'getProfileTile';
          this.rdm.id = batchOfIds[ i ];
          EmitterSubjectService.emitRunBackgroundProcessMethod(this.rdm);
          return true;
        }
      }
  }
  // ----------------------------------------------------------------------------------------
  //  This method is not in use:
  // ----------------------------------------------------------------------------------------
  getProfileTileIndividuallyFromServer (batchOfIds : number[]) : Observable<any> {

    return new Observable<any>((subscriber) => {
       debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(batchOfIds) && batchOfIds.length > 0) {

        for (var i = 0; i < batchOfIds.length; i++)
        {
          this.getProfileTile(batchOfIds[ i ]).subscribe(data => {
             debugger;

            // get all tiles from the disctionary and chnage-detection will display them:
            // --------------------------------------------------------------------------
            this.profileTilesArr = this.dictionaryService.profileTileDictionary.size > 0 ? this.dictionaryService.profileTileDictionary.values() : [];

            // Note:  since the tiles are saved in dictionary,
            //        we save the dictionary in indexedDb.
            // -----------------------------------------------
            // Note: This scheme of saving the whole dictionary runs into the problem of maximum-size of the data allowed to be stored in indexedDb.
            //       For large dictionaries, there has to be a separate shceme where each entry of the dictionary will be saved and will be retrieved accordingly
            // ---------------------------------------------------------------------------------------------------------------------------------------------------
            // get the variable in service to false for a singleton use:
            this.isSingletonUsed = EmitterSubjectService.getIsObjectStoreNameFoundInTables();
            if (this.batchOfIds.length === this.profileTilesArr.length && !this.isSingletonUsed) {
               debugger;
              // set the singleton use of this profileTileDictionary creation to true:
              this.isSingletonUsed = true;
              EmitterSubjectService.setIsObjectStoreNameFoundInTables(true);
              // debugger;
              // subscriber.next(this.profileTilesArr);           
            }
            else {
              debugger;
              // this.profileTilesArr = ArraySupportServiceStatic.mergeArraysUniq(this.profileTilesArr, [ data ]);
              // subscriber.next(this.profileTilesArr);
						}
          })
        }
        debugger;
        this.profileTilesArr = this.dictionaryService.profileTileDictionary.size > 0 ? this.dictionaryService.profileTileDictionary.values() : [];

        this.dbDexieToDictionaryService
          .saveDictionaryToDbDexie(
            this.dictionaryService.profileTileDictionary, 'profileTile', this.loginSuccess.signedInUserId);

        subscriber.next(this.profileTilesArr);

         this.timer = setTimeout(() => {
          subscriber.complete();
          clearTimeout(this.timer);
         }, 1000);
      }
      else {
        // debugger;
        this.message = 'batchOfIds.length === 0 or null or undefined in getProfileTileIndividuallyFromServer()';
        console.log(this.message);
      }
    })
  }
   // --------------------------------------------------------------
  // getProfileTile ( sitUserId : number ) : Observable<any>
  // {
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  let tProfileTile : any;
  //  // debugger;
  //  return Observable.create( ( observer ) =>
  //  {
  //    if ( sitUserId > 0 && this.loginSuccess.signedInUserId > 0 )
  //    {
  //      this.sitUser.sitUserId = sitUserId;
  //      // this.isOnLine &&
  //      const bnComm : Communication = new Communication();

  //      bnComm.sitUserId = sitUserId;
  //      bnComm.signedInUserId = this.loginSuccess.signedInUserId;
  //      this.boxNonceEntity = this.salt( bnComm );
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity ) )
  //      {
  //        // debugger;
  //        this.httpService.post( '/api/Member/GetProfileTile',
  //          {
  //            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
  //            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
  //          },
  //          'json'
  //        ).subscribe( ( result ) =>
  //        {
  //          // debugger;
  //          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
  //          {
  //            this.boxNonceEntity = result;
  //            let tempTile;
  //            /*
  //              * do not directly assing to profileTile!! capture it in a let first
  //              */
  //            const unboxedTile = SlakezSaltServiceStatic.boxUnsalt( result as BoxNonceEntity );

  //            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( unboxedTile ) )
  //            {
  //              tempTile = unboxedTile as ProfileTile;
  //            }

  //            // if key 'sitUserId' exists, the unslating produced data
  //            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tempTile ) )
  //            {
  //              tempTile = JSON.parse( tempTile ); // convert string to model
  //            }

  //            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tempTile ) && tempTile.sitUserId > 0 )
  //            {
  //              /*
  //                * -------------------------------------------------------------------------
  //                * since the received data has been successfull unsalted into model, it is safe to save it to indexedDb
  //                * emit the tile as boxNonce to be stored in indexedDb by MembersComponent
  //                * -------------------------------------------------------------------------
  //                */
  //              const boxedTile = result as BoxNonceEntity;
  //              if ( sitUserId > 0 )
  //              {
  //                boxedTile.id = sitUserId; // to be used by indexedDb
  //                boxedTile.key = sitUserId.toString(); // to be used by indexedDb
  //                boxedTile.sitUserId = sitUserId; // to uniquely identify a user's data
  //                boxedTile.date = DateStringServiceStatic.getTicks( new Date() ).toString();
  //                boxedTile.entityName = 'ProfileTile';
  //                // boxedTile.box = result.box;
  //                // boxedTile.nonce = result.nonce;
  //                // debugger;


  //                // EmitterSubjectService.emitBoxNonce( boxedTile );

  //                // -------------------------------------------------------------------------
  //                // debugger;
  //                tProfileTile = this.executeBeforeViewInit( tempTile, sitUserId, tempTile.id );


  //                // debugger;

  //                if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfileTile ) )
  //                {
  //                  // debugger;
  //                  this.profileTile = tProfileTile as ProfileTile;
  //                  this.sitUser.sitUserId = sitUserId;

  //                  // update the Dictionary:
  //                  // ----------------------
  //                  // this.dictionaryService.updateDictionary( this.profileTile, 'ProfileTile', this.profileTile.sitUserId ); //DO NOT USE THIS. Remove after testing
  //                  // this.dictionaryService.updateProfileTilesDictionary( [ tProfileTile]);

  //                  debugger; // TODO: step-into here!!!

  //                  observer.next( this.profileTile );
  //                }
  //              }
  //            }
  //          }
  //        })
  //      }
  //    }
  //  });
  // }

  // ---------------------------------------------------------------
  // --------------------------------------------------------------
  // Note : this method is to be used ONLY when Viewing a tile, i.e.memberView/:id
  //       Not to be listened to by MembersComponent, rather by MemberViewComponent
  // --------------------------------------------------------------
   // emitProfileTile ( pTile ) : any
   //{
   // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pTile ) )
   // {
   //    this.mcLoader = new ModuleComponentLoader();
   //    this.mcLoader.componentName = 'memberView';
   //   this.mcLoader.moduleName = 'memberm';
   //   this.mcLoader.router = this.router;
   //    this.mcLoader.sitUserId = pTile?.sitUserId;
   //    EmitterSubjectService.setMCLoader(this.mcLoader);
   //   // debugger;
   //   RedirectionServiceStatic.fireMcLoaderRoute(this.mcLoader);
   //   return true;
   // }
   // return false;
   // }
  // ---------------------------------------------------------------
}
