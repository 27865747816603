import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { AmountModel } from './amountModel.model';
import { PayPalPayee } from './payPalPayee';
import { PayPalShipping } from './payPalShipping.model';
export class PayPalPurchaseUnits
{
  public amount = new AmountModel();
  public amount_currency_code = '';
  public amount_value = '';
  public date = '';
  public items : any[] = []; // new PayPalItems();
  public payee = new PayPalPayee();
  public shipping = new PayPalShipping();
  public reference_id = '';
  public sitUserKey = '';
  // ----------------------------------------------------------------------------------
  constructor () { }
  // ----------------------------------------------------------------------------------
  public copy ( from: PayPalPurchaseUnits ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.amount.copy( from.amount );
      this.date = from.date;
      this.items = from.items;
      this.reference_id = from.reference_id;
      this.payee.copy( from.payee );
      this.shipping.copy( from.shipping );
    }
  }
  // ----------------------------------------------------------------------------------
  public toStringCsv ()
  {
    let output = '';
    output = this.amount.toString() + ',' + this.date + ',' + this.items.toString() + ', ' + this.payee.toString() + ', ' + this.shipping.toString() + ',' + this.reference_id;
    return output;
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n reference_id: ' +
      this.reference_id +

      ',\n payee: ' +
      this.payee.toString() +

      ',\n shipping: ' +
      this.shipping.toString() +

      ',\n items: ' +
      this.items.toString() +

      ',\n' +
      'date:' +
      this.date +

      ',\n' +
      'amount:' +
      this.amount.toString() +

      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
