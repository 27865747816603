
<div id="incrementorComponentId"
     class="text-center"
     style="display: inline !important; width: 5rem; height: 1.75rem; justify-content: space-between; vertical-align: central; margin: 0.05rem; padding: 0.05rem;">

  <span *ngIf="pageSize === 3"
        (click)="onPageSizeChange($event, 'down')"
        class="glyphicon glyphicon-ban-circle hvr-icon errSB  smallerPrimary increase text-center"
        style="width: 1.45rem; height: 1.45rem; margin: 0.05em; padding: 0.05rem; border-radius: 100%; float: left; justify-content: center; border: 2px solid gold; font-size:1.3rem;" aria-hidden="true">
  </span>
  <span *ngIf="pageSize > 3 &&  pageSize < 11"
        (click)="onPageSizeChange($event, 'down');"
        class="glyphicon glyphicon-minus-sign hvr-icon grnDrkRedB  smallerPrimary increase text-center"
        style="width: 1.45rem; height: 1.45rem; margin: 0.05em; padding: 0.05rem; border-radius: 100%; float: left; justify-content: center; border: 2px solid gold; font-size:1.25rem;" aria-hidden="true">
  </span>

  <span id="incrementorTextId" class="whiteBlueInvxxSB text-center" style="width: 60%; height: 1rem; border: none;">
    <span style="vertical-align:middle;">pics per page: {{pageSize}}</span>
  </span>

  <span *ngIf="pageSize === 10"
        (click)="onPageSizeChange($event, 'up');"
        class="glyphicon glyphicon-ban-circle hvr-icon errSB  smallerPrimary increase text-center"
        style="width: 1.45rem; height: 1.45rem; margin: 0.05rem; padding: 0.05rem; border-radius: 100%; float: right; border: 2px solid gold; font-size: 1.3rem;" aria-hidden="true">
  </span>
  <span *ngIf="pageSize >= 3 && pageSize < 10"
        (click)="onPageSizeChange($event, 'up');"
        class="glyphicon glyphicon-plus-sign hvr-icon grnDrkRedB  smallerPrimary increase text-center"
        style="width: 1.45rem; height: 1.45rem; margin: 0.05rem; padding: 0.05rem; border-radius: 100%; float: right; border: 2px solid gold; font-size: 1.25rem;" aria-hidden="true">
  </span>
</div>


