
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DbServiceService } from '../dbServiceService/dbServiceService.service';
import { PhotoPrivacyService } from '../photoServiceService/photoPrivacyService.service';
import { PhotoServiceService } from '../photoServiceService/photoServiceService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from './stringServiceStatic.service';

// =====================================================================

// =====================================================================

@Injectable({ providedIn: 'root'})
export abstract class MessageBuilderServiceStatic
{
  static randomNumberGenerator$ : Observable<number>;
  static randomNumber = 0;
  static photoPrivacyService : PhotoPrivacyService;
  static dbDexieToDictionaryService : DbDexieToDictionaryService;
  static loginSuccess : LoginSuccess;
  constructor (public photoServiceService : PhotoServiceService,
               public dbServiceService: DbServiceService  ) {
	}
	// ---------------------------------------------------------------
	static printMessage (sitUserId : number, message: string) : string | any {
		// debugger;
    var outMessage = StringServiceStatic.stringBuilder('timestamp: ' + new Date().getTime() + ' sitUserId: ' + (sitUserId > 0 ? sitUserId : 0) + '; message: ' + (message.length <2048 ? message : ''));
    // console.log(outMessage);
    return outMessage;
	}
  // ------------------------------------------------------------------------------
  // Shell method:
  static  getProfilePicsFromDictionary (sitUserId : number) : ProfilePics {
    // debugger;
    let date = new Date();
    let pPics : ProfilePics;
    return pPics;
  }
  // ------------------------------------------------------------------------------
  // Shell method:
  static populateSitUserIdDictionaryFromIndexedDb () : any {
    
  }
  // ----------------------------------------------------------------------

  // ----------------------------------------------------------------------

  // ------------------------------------------------------------------------
}
