
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { GlyphiconMenuService } from './glypgiconMenuService.service';

@Injectable({
  providedIn: 'any',
})
export class GlyphiconServiceService implements OnDestroy {
  private emitterDestroyed$: Subject<boolean> = new Subject();
  constructor(
    private glyphiconMenuService: GlyphiconMenuService,
    //private pagerService: PagerService,
    //private pagerServiceChatHistory: PagerServiceChatHistory,
    //private pagerPhotoMgmtService : PagerPhotoMgmtService,
    //private pagerPhotoNavService : PagerPhotoNavService,
    //private pagerSupportService : PagerSupportService,
    //private abstractPagerVPhotoNavService : AbstractPagerVPhotoNavService,
  ) { }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ------------------------------------------------------------------------------------------------
  setGlyphiconMenuService(gms: GlyphiconMenuService): void {
    this.glyphiconMenuService = gms;
  }
  // ------------------------------------------------------------------------------------------------
  getGlyphiconMenuService(): GlyphiconMenuService {
    return this.glyphiconMenuService;
  }
  // ------------------------------------------------------------------------------------------------
  //setPagerService(gd: PagerService): void {
  //  this.pagerService = gd;
  //}
  // ------------------------------------------------------------------------------------------------
  //getPagerService(): PagerService {
  //  return this.pagerService;
  //}
  // ------------------------------------------------------------------
  // setPagerServiceChatHistory(chps: PagerServiceChatHistory): void {
  //  this.pagerServiceChatHistory = chps;
  // }
  // getPagerServiceChatHistory(): PagerServiceChatHistory {
  //  return this.pagerServiceChatHistory;
  //}
  // ------------------------------------------------------------------------------------------------
  //getAbstractPagerVPhotoNavService () : AbstractPagerVPhotoNavService {
  //  return this.abstractPagerVPhotoNavService;
  // }
  //setAbstractPagerVPhotoNavService (pvs : AbstractPagerVPhotoNavService) : void {
  //  this.abstractPagerVPhotoNavService = pvs;
  // }
  // ------------------------------------------------------------------------------------------------
  //setPagerPhotoMgmtService (pps : PagerPhotoMgmtService): void {
  //  this.pagerPhotoMgmtService = pps;
  //}  
  // ------------------------------------------------------------------------------------------------
  //getPagerPhotoMgmtService () : PagerPhotoMgmtService {
  //  return this.pagerPhotoMgmtService;
  //}
  // ------------------------------------------------------------------------------------------------
  //setPagerPhotoNavService (pns : PagerPhotoNavService) : void {
  //  this.pagerPhotoNavService = pns;
  //}
  // ------------------------------------------------------------------------------------------------
  //getPagerPhotoNavService () : PagerPhotoNavService {
  //  return this.pagerPhotoNavService;
  //}
  // ------------------------------------------------------------------------------------------------
  //setPagerSupportService (pvs : PagerSupportService) : void {
  //  this.pagerSupportService = pvs;
  //}
  // ------------------------------------------------------------------------------------------------
  //getPagerSupportService () : PagerSupportService {
  //  return this.pagerSupportService;
  //}
  // ------------------------------------------------------------------
}
