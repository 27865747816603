'use-strict'
// Ref:https://medium.com/@Idan_Co/angular-print-service-290651c721f9
import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { EmitDropDownResult } from '../../../models/common/emitDropDownResult.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';

@Component({
    selector: 'app-dropdown-box',
    templateUrl: './dropdownBox.component.html',
  styleUrls: ['./dropdownBox.component.scss'],
})
export class DropdownBoxComponent implements OnInit, OnDestroy, AfterViewInit
{
  public dropdownBoxResult = new EmitDropDownResult();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  @Input() isRequired = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public signedInUserId = 0;
  public selectedItem: any;
  @Input() ddHeading = '';
  @Input() optionArr : string[] = [];

  constructor() {
  }

  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.signedInUserId = this.loginSuccess.signedInUserId;
  }
  // ---------------------------------------------------------------
  ngAfterViewInit ()
  {
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy ()
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  // ---------------------------------------------------------------
  public selected ( dHeading : string, item : string, index : number ): any
  {
    this.selectedItem = item;
    this.dropdownBoxResult.index = index;
    this.dropdownBoxResult.dropdownBoxName = dHeading;
    this.dropdownBoxResult.dropdownSelection = item;
    this.dropdownBoxResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
    EmitterSubjectService.emitDropDownResult( this.dropdownBoxResult );
    return true;
  }

  // ---------------------------------------------------------------
  isRequiredPassed ()
  {
    return this.dropdownBoxResult.dropdownSelection.length > 0;
  }
  // ---------------------------------------------------------------
}

