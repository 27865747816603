import { ChatFileUpload } from '../chat/chatFileUpload.model';

export class Content {
  public aBstract = '';
  public authorSitUserId = 0;
  public contentId = 0;
  public contents = '';
  public date = '';
  public chatFileUpload: ChatFileUpload = new ChatFileUpload();
  public fileType = '';
  public heading = '';
  public isCode = false;
  public isSuccess = false;
  public isPic = false;
  public latitude = 0;
  public longitude = 0;
  public message = '';
  public paragraphNo = 0;
  public photoId = 0;
  public profileId = 0;
  public sitUserId = 0;
  public signedInUserId = 0;
  public sitUserKey = '';
  public signedInUserKey = '';
  constructor(
  ) { }


  copy(source: Content) {
    this.authorSitUserId = source.authorSitUserId;
    this.contentId = source.contentId;
    this.profileId = source.profileId;
    this.contents = source.contents;
    this.date = source.date;
    this.chatFileUpload = source.chatFileUpload;
    this.fileType = source.fileType;
    this.heading = source.heading;
    this.isCode = source.isCode;
    this.isPic = source.isPic;
    this.latitude = source.latitude;
    this.longitude = source.longitude;
    this.message = source.message;
    this.paragraphNo = source.paragraphNo;
    this.photoId = source.photoId;
    this.sitUserId = source.sitUserId;
    this.sitUserKey = source.sitUserKey;
    this.signedInUserId = source.signedInUserId;
    this.signedInUserKey = source.signedInUserKey;
  }

  copyTo(destination: Content, source: Content) {
    if (source && destination) {
      destination.authorSitUserId = source.authorSitUserId;
      destination.contentId = source.contentId;
      destination.profileId = source.profileId;
      destination.contents = source.contents;
      destination.date = source.date;
      destination.chatFileUpload = source.chatFileUpload;
      destination.fileType = source.fileType;
      destination.heading = source.heading;
      destination.isCode = source.isCode;
      destination.isPic = source.isPic;
      destination.latitude = source.latitude;
      destination.longitude = source.longitude;
      destination.message = source.message;
      destination.paragraphNo = source.paragraphNo;
      destination.photoId = source.photoId;
      destination.sitUserId = source.sitUserId;
      destination.sitUserKey = source.sitUserKey;
      destination.signedInUserId = source.signedInUserId;
      destination.signedInUserKey = source.signedInUserKey;
    }
  }

  reset() {
    this.authorSitUserId = 0;
    this.contentId = 0;
    this.profileId = 0;
    this.contents = '';
    this.date = '';
    this.chatFileUpload = new ChatFileUpload();
    this.fileType = '';
    this.heading = '';
    this.isCode = false;
    this.isPic = false;
    this.latitude = 0;
    this.longitude = 0;
    this.message = '';
    this.paragraphNo = 0;
    this.photoId = 0;
    this.sitUserId = 0;
    this.sitUserKey = '';
    this.signedInUserId = 0;
    this.signedInUserKey = '';
  }
}
