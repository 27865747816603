'use-strict'
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayPipe'
})
export class ArrayPipe implements PipeTransform {

  transform ( arr : any[] )
  {
    return arr.map( value =>
    {
      alert( 'value.toString()=>' + value.toString() );
      return value;
    } );
  }

}
