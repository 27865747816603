export class DateAssay {
  public millss = -1;
  public ss = -1;
  public mm = -1;
  public hh = -1;
  public dd = -1;
  public months = -1;
  public yyyy = -1;

  public jsTicks = '';
  public jsTicksNum = -1;
  public csTicks = '';
  public csTicksNum = -1;

  public UTCoffset = '';
  public hasDST = false;
  public UTCoffsetT = '';

  public isBool = true;
  public id = 0;
  public date!: Date;
  public dateFormatted = '';
  public csTicks2JsDateFormatted!: string;
  public csTicks2JsDate!: Date;
  constructor() { }
}
