
<span 
      id="profileNameComponentId"
      class="flex-text-right bluGrnGldB increase"
      style="top:3px;right:3px;width:3rem; height: 1rem; margin: 0.1rem;text-align: center;font-size:15px;"     
      [innerHTML]="profileName | safe: 'html'">
  {{profileName}}
</span>
<!-- <span class="increase bluGrnGldxxxSB flex-text-right"
      style="padding: 0.05rem;margin:0;font-size:15px;position:relative;top:0;right:0;"
      [innerHTML]="profileName | safe: 'html'">
  {{profileName}}
</span> -->
<!--(click)="viewProfile();"-->
