
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PagerPhotoMgmtServiceInterface } from '../../interfaces/serviceInterfaces/pagerServicesInterfaces/pagerPhotoMgmtServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { PagerBreadcrum } from '../../models/breadcrum/pagerBreadcrum.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { PagerPhotoMgmt } from '../../models/pagination/pagerPhotoMgmt.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { BasicPagerService } from './basicPagerService.service';
import { GlyphiconMenuService } from '../GlyphiconService/glypgiconMenuService.service';
import { PagerServiceService } from './pagerServiceService.service';
import { PagerSupportService } from './pagerSupportService.service';

// ------------------------------------------------------------------
//  This pager-service is the most recently updated one. If other
//  pagers need to be updated, this one should be used as a model for
//  them. Updated on 20220420.
// ------------------------------------------------------------------

// ref: http://xahlee.info/js/javascript_range_array.html
const xah_range = ((min : any, max : any, step = 1,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)));

@Injectable({
  providedIn: 'root'
})
export class PagerPhotoMgmtService extends BasicPagerService implements OnDestroy, PagerPhotoMgmtServiceInterface {
  /*public renderer! : Renderer2;*/
  // ----------------------------------------------------------------
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public filteredKvPhotoArr : KvPhoto[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public kvPhotoArr : KvPhoto[] = [];

  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public pagerPhotoMgmt : PagerPhotoMgmt = new PagerPhotoMgmt();
  public pics : string[] = [];
  public profilePics : ProfilePics = new ProfilePics();
  /*public timer : any;*/
  // ---------------------------------------------------------------
  constructor (
    public glyphiconMenuService : GlyphiconMenuService,
    public pagerSupportService: PagerSupportService,
    public rendererService : RendererService,
    public router : Router,
    @Inject(DOCUMENT) document : any,
    @Inject(Window) window : any
  ) {
    super();
    if (this.rendererService) {
      this.renderer = this.rendererService.getRenderer();
    }
  }
  // ---------------------------------------------------------------
  ngOnDestroy () {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    clearInterval(this.timer);
  }
  // ================================================================
  // ----------------------------------------------------------------
  //  API: Begin
  // ---------------------------------------------------------------
  public clearPagerServicePhotoMgmtData (): void {
    this.kvPhotoArr = [];
    this.pagerPhotoMgmt = new PagerPhotoMgmt();
    // debugger;
  }
  // ---------------------------------------------------------------
  public getPagerPhotoMgmt(): PagerPhotoMgmt {
    // debugger;
    return this.pagerPhotoMgmt;
  }
  // ===============================================================
  // TODO : Separate out PagerBreadCrum into its own PagerServiceBreadCrum,
  // ---------------------------------------------------------------
  public getPagerBreadcrum(): PagerBreadcrum {
    return this.pagerBrdCrm;
  }
  // ---------------------------------------------------------------
  getPagerVKvPhoto (page : number) : any {
    // returnthis.setPagerUpTo15(this.kvPhotoArr, page);
    return this.getBasicPagerDynamic(this.kvPhotoArr, page, this.pageSize);
  }
  
  // ---------------------------------------------------------------
  public setKvPhotoArr (kvpArr : KvPhoto[]) : void {
    this.kvPhotoArr = kvpArr;
  }
  // ---------------------------------------------------------------
  public setupPagerBrdCrm () :any {
    // return object with all the pager properties required by the view
    this.pagerBrdCrm = new PagerBreadcrum();
    this.pagerBrdCrm.totalItems = this.pagerPhotoMgmt.totalItems;
    this.pagerBrdCrm.currentPage = this.pagerPhotoMgmt.page;
    // this.pagerBrdCrm.currentBrdCrmPage = this.breadcrumRing[this.currentPage - 1];
    this.pagerBrdCrm.previousPage = this.pagerPhotoMgmt.page - 1 >= 1 ? this.pagerPhotoMgmt.page - 1 : this.pagerPhotoMgmt.page;
    // if (this.pagerBrdCrm.previousPage >= 1) {
    //   this.pagerBrdCrm.previousBrdCrmPage = this.breadcrumRing[this.pagerBrdCrm.previousPage - 1];
    // }
    this.pagerBrdCrm.nextPage = this.pagerPhotoMgmt.page + 1 <= this.pagerPhotoMgmt.totalItems ? this.pagerPhotoMgmt.page + 1 : this.pagerPhotoMgmt.page;
    // if (this.pagerBrdCrm.nextPage <= this.totalItems) {
    //   this.pagerBrdCrm.nextBrdCrmPage = this.breadcrumRing[this.pagerBrdCrm.nextPage - 1];
    // }

    this.pagerBrdCrm.pageSize = this.pageSize;
    this.pagerBrdCrm.totalPagedBlocks = this.pagerPhotoMgmt.totalPages;
    //this.pagerBrdCrm.pagedStartPage = this.pagerPhotoMgmt.startPage;
    //this.pagerBrdCrm.pagedEndPage = this.pagerPhotoMgmt.endPage;
    this.pagerBrdCrm.pagedStartIndex = this.pagerPhotoMgmt.startIndex;
    this.pagerBrdCrm.pagedEndIndex = this.pagerPhotoMgmt.endIndex;
    this.pagerBrdCrm.pagedPages = this.pagerPhotoMgmt.pages;

    EmitterSubjectService.setPagerBreadcrum(this.pagerBrdCrm);
    return this.pagerBrdCrm;
  }
  // --------------------------------------------------
  public setPagerButtonImagByKvPhoto(kvImage: KvPhoto): any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvImage) && kvImage.value && kvImage.value.length > 0 && kvImage.photoId > 0) {
      const image = 'url(\'' + kvImage.value + '\')';
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
        const pageId = 'pagePmgmt-' + kvImage.itemNum.toString();
        const pElem = document.getElementById(pageId);
        // debugger;
        if (pElem) {
          // debugger;
          this.timer = setTimeout(() => {
            // debugger;
            this.renderer?.setStyle(pElem, 'background-image', image);
          }, 500);
          clearTimeout(this.timer);
          pElem.remove();
        }
      }
    }
  }
  // --------------------------------------------------
  // NOTE : All the image-setter methods are here:
  // Note : this method will simply set the background-image of all the numbered-pages (1...10 ->)
  // --------------------------------------------------
  // TODO : Test with setTimeout
  public setPagerButtonImagesByKvPhotoArr(kvImageArr: KvPhoto[]): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvImageArr) && kvImageArr.length > 0) {
      // debugger;
      kvImageArr.map(e => {
        this.setPagerButtonImagByKvPhoto(e);
      });
    }
  }
  // ------------------------------------------------------------
  public drawPagerButtons (pgrmgmt : any) : any{
    if (pgrmgmt && pgrmgmt.length > 0) {
      // debugger;
      return this.setPagerButtonImagesByKvPhotoArr(pgrmgmt.pagedKvPhotoArr);
    }
  }
  // ------------------------------------------------------------
  //  API: End
  // ---------------------------------------------------------------
}


