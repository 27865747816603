import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { PayPalItem } from './payPalItem.model';

export class PayPalItems
{
  public date = '';
  public orderID = '';
  public payerID = '';
  public sitUserKey = '';
  public items : PayPalItem[] = [];
  // ----------------------------------------------------------------------------------
  constructor() {
  }
  // ----------------------------------------------------------------------------------
  public copy ( from : PayPalItems ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.date = from.date;
      this.orderID = from.orderID;
      this.payerID = from.payerID;
      this.sitUserKey = from.sitUserKey;
      this.items = from.items;
    }
  }
  // ----------------------------------------------------------------------------------
  public itemsToString ( items : any[] ): string
  {
    let itemsStr = '';
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( items ) && items.length > 0 )
    {
      itemsStr += this.date + ',' + this.orderID + ',' + this.payerID + ',' + this.sitUserKey + ',';
      items.map( e =>
      {
        itemsStr += e.toString() + ", ";
      } );
    }
    return "[" + itemsStr + " ]";
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n' +
      'orderID: ' +
      this.orderID +

      '\n' +
      'payerID: ' +
      this.payerID +

      '\n' +
      'date: ' +
      this.date +

      '\n' +
      'sitUserKey: ' +
      this.sitUserKey +

      '\n' +
      'items: ' +
      this.itemsToString(this.items) +
      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
