'use-strict'
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, Inject, Input, OnDestroy, OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { Bool } from '../../../models/common/bool.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { Photo } from '../../../models/common/photo.model';
import { PhotoMgmtAction } from '../../../models/common/photoMgmtAction.model';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { MemberActivity } from '../../../models/member/memberActivity.model';
import { PagerMemberActivity } from '../../../models/pagination/pagerMemberActivity.model';
import { GlyphiconMenuService } from '../../../services/GlyphiconService/glypgiconMenuService.service';
import { GlyphiconServiceService } from '../../../services/GlyphiconService/glyphiconServiceService.service';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { SitUserService } from '../../../services/commonServiceService/sitUserService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { ActivityService } from '../../../services/memberServiceService/activityService.service';
import { MemberService } from '../../../services/memberServiceService/memberService.service';
import { MemberServiceService } from '../../../services/memberServiceService/memberServiceService.service';
import { PagerPhotoMgmtService } from '../../../services/pagerServiceService/pagerPhotoMgmtService.service';
import { PagerServiceService } from '../../../services/pagerServiceService/pagerServiceService.service';
import { PagerSupportService } from '../../../services/pagerServiceService/pagerSupportService.service';
import { PhotoAndPrimaryPhotoMgmtService } from '../../../services/photoServiceService/photoAndPrimaryPhotoMgmtService.service';
import { PhotoServiceService } from '../../../services/photoServiceService/photoServiceService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { CopyServiceStatic } from '../../../services/staticServices/commonStaticServices/copyServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

// --------------------------------------------------------------------------------------
//  This PagerVerticalGlyphForPhotoMgmtComponent should NOT be concerned with any other
//  PagerVertical...Components, such as
//  1) PagerVerticalGlyphForViewMemberComponent or
//  2) PagerVerticalKvPhotoNavomponent
// --------------------------------------------------------------------------------------
// Note : pagerVerticle glyphicon-menu-system serve navigation/actions for two scenarios:
//     1.  memberView path during viewing any member.
//           Under this option the all but signedInMember can:
//         1.1 Select  memberActicities which are
//           {block, dislike, like, lock, star, track, unblock, unlock, unstar}
//         1.2 Can start a chat conversation with the viewee or
//         1.3 Send email notification to the viewee.
//         1.4 Send notification to the viewee.
//     p.s the above is no longer effective since MemmberView is split into two:
//         p.s.1: MemberViewPhotoMgmt for non-signed-in-user
//         p.s.2: myMemberViewPhotoMgmt for signed-in-user
// 
//     2.  myPhotoMgmt path when signedInUser chooses to add/update personal photos.
//           Under this option the signedInUser can:
//           2.1 Take new pics or upload new pics
//           2.2 Change the privacy of any pic
//           2.3 Make current PrimaryPic a private pic in whihc case it will not longer be a primary pic
//           2.4 Make any pic the PrimaryPic. PrimaryPic is always public.
// ---------------------------------------------------
// Note : The View does not require to programatically draw the images,
//        just setting the glyph / image name will suffice
// -------------------------------------------------------------------------------------------------------

@Component({
  selector: 'app-pager-vertical-glyph-for-photo-mgmt',
  templateUrl: './pagerVerticalGlyphForPhotoMgmt.component.html',
  styleUrls: ['./pagerVerticalGlyphForPhotoMgmt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush, // default

  animations: [ Animations, slideInOutAnimation ],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class PagerVerticalGlyphForPhotoMgmtComponent implements OnInit, OnDestroy, AfterViewInit
{
  public bias! : BackgroundImageAnimationService;
  public glyphiconMenuService!: GlyphiconMenuService;
  public activityService! : ActivityService;
  public memberService! : MemberService;
  public pagerPhotoMgmtService! : PagerPhotoMgmtService;
  public photoAndPrimaryPhotoMgmtService! : PhotoAndPrimaryPhotoMgmtService;
  public sitUserService! : SitUserService;
  public pagerSupportService! : PagerSupportService;
  // -----------------------------------------------
  public accrdTitle = '';
  public breadcrumRing: ModuleComponentLoader[] = [];
  public currentKvPhoto : KvPhoto = new KvPhoto();
  public currentPage : any;
  @Input() currentPageGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public currentPhoto : Photo = new Photo();

  public emitterDestroyed$ : Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  public isCameraVisible = true;
  public isCurrentlyLocked = false;
  public isGlypActionCamera = false;
  public isMobilevar = false;
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isShowPhotoUploadUi : Bool = new Bool();
  public isToggleFileUpload = false;
  @Input() kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public mcLoader: ModuleComponentLoader = new ModuleComponentLoader();
  public memberActivity: MemberActivity = new MemberActivity();
  private opened = false;
  @Input() pagerVMemActGlyphForPhotoMgmt : PagerMemberActivity = new PagerMemberActivity(); // for primary/private/public photo selection by signedInUser
  public photoMgmtAction: PhotoMgmtAction = new PhotoMgmtAction();
  public returnUrl = '';
  public signedInUserId = 0;
  @Input() sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();
  public toggleCameraGlyphCounter = 0;
  // public type = require( 'type-detect' );
  // ----------------------------------------------------------
  constructor (
    private router : Router,
    private cdr : ChangeDetectorRef,
    private commonServiceService : CommonServiceService,
    private coreServiceService : CoreServiceService,
    private glyphiconServiceService : GlyphiconServiceService,
    private memberServiceService : MemberServiceService,
    private rendererServiceService: RendererServiceService,
    private pagerServiceService : PagerServiceService,
    private photoServiceService: PhotoServiceService,
    @Inject(Document) document : any )
  {
    if ( this.commonServiceService )
    {
      this.sitUserService = this.commonServiceService.getSitUserService() as SitUserService;
    }

    if ( this.memberServiceService )
    {
      this.activityService = this.memberServiceService.getActivityService();
    }

    if (this.rendererServiceService) {
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
    }
    this.glyphiconMenuService = this.glyphiconServiceService.getGlyphiconMenuService() as GlyphiconMenuService;
    if ( this.pagerServiceService )
    {
      
      this.pagerPhotoMgmtService = this.pagerServiceService.getPagerPhotoMgmtService();
      this.pagerSupportService = this.pagerServiceService.getPagerSupportService();
    }
    if (photoServiceService) this.photoAndPrimaryPhotoMgmtService = this.photoServiceService.getPhotoAndPrimaryPhotoMgmtService();

    this.isMobilevar = EmitterSubjectService.getIsMobile() as boolean;
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    // debugger;
  }
  // ----------------------------------------------------------
  ngOnInit () : void
  {
    this.isMyProfile = EmitterSubjectService.getIsMyProfile();
    this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
    // debugger;
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
      this.signedInUserId = this.loginSuccess.signedInUserId;
    }
   
    // debugger;
    if ((FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyphForPhotoMgmt)
      || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyphForPhotoMgmt.kvMemActGlyphArrForPhotoMgmt))
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForPhotoMgmt)
      && this.kvMemActGlyphArrForPhotoMgmt.length > 0) {
      debugger;
      this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvMemActGlyphArrForPhotoMgmt, 1);
    }
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyphForPhotoMgmt) && this.pagerVMemActGlyphForPhotoMgmt.pagedItems.length > 0) {
      this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemActGlyphForPhotoMgmt.pagedKvGlyphItems, this.pagerVMemActGlyphForPhotoMgmt.currentKvGlyphItem, "pagerVGlyphMgmt-");
    }
    // ----------------------------------------------------------
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe(result => {
        this.loginSuccess = result;
        this.signedInUserId = this.loginSuccess.signedInUserId;
        this.signedInUser = EmitterSubjectService.getSignedInUser(); // this.sitUserService.getSingedInSitUser();
        // debugger;
      });   
    // ---------------------------------------------------
    // Note: Old description of a situation that might not be applicable anymore.
    // Note : to achieve this simple objective of clearing all glyphicons from the dynamyc-graphicon-menu,
    //       it requires these following to be complied:
    //       1. have the ChangeDetection run in here at the PagerVerticalComponent.
    //       2. have the ChangeDetection run at the AppComponent.ts which the PagerVerticalComponent.ts is the child of,
    //       3. have the component which wants to clear the glyphicon menu such as the LandingPageComponent.ts,
    //       emit a message to crear the glyphicon menu.
    // ---------------------------------------------------
    EmitterSubjectService.clearGlyphiconMenuEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( data =>
      {
        // debugger;
        // this.executeClearGlyphAction();
      });
    // ----------------------------------------------------------
     EmitterSubjectService.displayPhotoMgmtGlyphiconMenuEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( data =>
      {
        // TODO: check the steps: tested and works:
        // ----------------------------------------
        debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0) {
          this.kvMemActGlyphArrForPhotoMgmt = data;
          // Note : The View does not require to programatically draw the images,
          //       just setting the glyph/image name will suffice
          debugger;
          this.glyphiconMenuService.executeGlyphMenuArr(this.kvMemActGlyphArrForPhotoMgmt);

          this.currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto)) {
            if (this.currentKvPhoto.pageNum <= 0) {
              if (this.currentKvPhoto.index >= 0) {
                this.currentKvPhoto.pageNum = this.currentKvPhoto.index + 1;
              }
              else if (this.currentKvPhoto.itemNum >= 0) {
                this.currentKvPhoto.pageNum = this.currentKvPhoto.itemNum + 1;
              }
            }
            this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvMemActGlyphArrForPhotoMgmt, this.currentKvPhoto.pageNum);
          }
          else {
            this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvMemActGlyphArrForPhotoMgmt, 1);
          }
           debugger;
          this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemActGlyphForPhotoMgmt.kvMemActGlyphArrForPhotoMgmt, this.pagerVMemActGlyphForPhotoMgmt.currentKvGlyphItem, "pagerVGlyphMgmt-");

          this.ngAfterViewInit();
        }
      } );
    // -------------------------------------------------------------------------
    // Note: Date: 20220508
    //       isCameraVisibleEmitter && isCameraGlypgVisibleEmitter work as a pair / tuple
    //       emitters that alters the isCameraVisible' value.
    //       the isCameraGlypgVisibleEmitter is emitted by FileUploadComponent and listened to by PagerVerticalPhotoMgmtComponent
    //       and isCameraVisibleEmitter is emitter by PagerVerticalPhotoMgmtComponent and listened to by FileUploadComponent
    //       so that there is no cyclic published and subscriber.
    // -------------------------------------------------------------------------
    //EmitterSubjectService.isCameraGlyphVisibleEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe((result) => {
    //     debugger;
    //    this.isCameraVisible = result as boolean;
    //    this.ngAfterViewInit();
    //  });    
    // -------------------------------------------------------------------------
    //EmitterSubjectService.isToggleFileUploadEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe((result) => {
    //    // debugger;
    
    //    this.isToggleFileUpload = result as boolean;
    //    this.ngAfterViewInit();
    //  });
    
    // -------------------------------------------------------------------------
    EmitterSubjectService.toggleCameraGlyphEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.toggleCameraGlyphCounter = result as number;
        let modValue = this.toggleCameraGlyphCounter % 2 === 0 ? 0 : 1;
        switch (this.toggleCameraGlyphCounter) {
          case modValue:
            if (modValue === 1) {
              // debugger;
              if (this.isGlypActionCamera) {
                this.isCameraVisible = this.isCameraVisible;
                this.toggleCameraGlyphCounter = 0;
                this.isGlypActionCamera = false;
              }
              else {
                this.isCameraVisible = !this.isCameraVisible;
                this.toggleCameraGlyphCounter = 0;                
              }
            }
            else if(modValue === 0) {
              debugger;
              // this.isCameraVisible = !this.isCameraVisible;
              // this.toggleCameraGlyphCounter = 0;
              if (this.isGlypActionCamera) {
                this.isCameraVisible = this.isCameraVisible;
                this.toggleCameraGlyphCounter = 0;
                this.isGlypActionCamera = false;
              }
              else {
                this.isCameraVisible = !this.isCameraVisible;
                this.toggleCameraGlyphCounter = 0;
              }
            }           
            break;

          default:
            // debugger;
            this.isCameraVisible = !this.isCameraVisible;
            this.toggleCameraGlyphCounter = 0;
            break;
        }

        this.ngAfterViewInit();
      });
    // -------------------------------------------------------------------------
    // Note: Should be related to PrimaryKvPhoto & regular KvPhoto based glyphs:
    // -------------------------------------------------------------------------
    EmitterSubjectService.isPhotoMgmtEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
        .subscribe(result => {
          // debugger;
          this.isPhotoMgmt = result;
          this.signedInUser = EmitterSubjectService.getSignedInUser();
          this.loginSuccess = EmitterSubjectService.getLoginSuccess();
          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displaySignInUserPrimaryKvPhotoGlyphs();
          this.ngAfterViewInit();
          // debugger;
        });
    
    // ---------------------------------------------------
    // Note: tried using 'type-def' lib;
    //       but got error.
    // ref:https://www.npmjs.com/package/type-detect
    // ---------------------------------------------------
    EmitterSubjectService.currentMemActGlyphForPhotoMgmtEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
        debugger;
        if (typeof result === 'number' && result > 0) {
          // debugger;
          this.currentPageGlyph.pageNum = result;
          this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.setKvGlyphPager(result);
        }
        else if (result instanceof KvMemberActivityGlyph) {
           debugger;
          this.currentPageGlyph = result;
          this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.currentPage.kvPhoto); // will display photo primary/private/public glyphs          
          this.ngAfterViewInit();
        }
          // TODO: delete before deployment:
          // -------------------------------
          // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPage.kvPhoto) && this.currentPage.kvPhoto.sitUserId > 0) {
          //  // debugger;
          //  this.pagerVKvPhotoNav = this.photoMgmtService.executePagerVKvPhotoNavKvPhotoTasks(this.currentPage.kvPhoto);
          // }
          // this.glyphiconMenuService.getPrimaryImageGlyphMenuArrAndHighlightPrimaryImage(this.currentPage.kvPhoto);
            // this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVKvPhotoNav.pagedItems, this.pagerVKvPhotoNav.currentItemKvGlyph, "pagerVGlyphMgmt-");
           
           
          // debugger;
            

            // let currentItemKvGlyph = CopyServiceStatic.copyFromKvPhotoToKvGlyph(this.currentKvPhoto);

            // let pageNum = this.currentKvPhoto.pageNum > 0 ? this.currentKvPhoto.pageNum
            //  : this.currentKvPhoto.itemNum >= 0 ? this.currentKvPhoto.itemNum + 1
            //    : this.currentKvPhoto.index >= 0 ? this.currentKvPhoto.index + 1
            //      : 1;

            //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
            //  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.kvPhotoArr)
            //  && this.pagerVKvPhotoNav.kvPhotoArr.length > 0) {
            //  this.pagerVKvPhotoNav = this.pagerServicePhotoMgmt.getPager(this.pagerVKvPhotoNav.kvPhotoArr, pageNum, this.pagerVKvPhotoNav.pageSize);
            //}

            //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForPhotoMgmt)
            //  && this.kvMemActGlyphArrForPhotoMgmt.length > 0) {
            //  debugger;
            //  // let currentItemKvGlyphs = this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvPhoto(this.currentPage.kvPhoto);
            //  // this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.currentKvPhoto);
            //  // this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.kvMemActGlyphArrForPhotoMgmt, currentItemKvGlyph, "pagerVGlyphMgmt-");
            //  // this.kvMemberActivityGlyphArr = this.glyphiconMenuService.getPrimaryImageGlyphMenuArrAndHighlightPrimaryImage(this.pagerVKvPhotoNav.pagedItems); // will highlight primaryImage if exists
            //}
          
           

          // this actually displays the pagerVerticalGlyphForPhotoMgmt:
          // --------------------------------------------------------
        this.currentKvPhoto = JSON.parse(JSON.stringify(result));
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto)) {
          debugger;
          this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvPhoto(this.currentKvPhoto);

          if (this.currentKvPhoto.pageNum > 0) {
            // debugger;
            // TODO: delete after testing/before deployment:
            // ---------------------------------------------
            this.pagerVMemActGlyphForPhotoMgmt  = this.glyphiconMenuService.setKvGlyphPager(this.currentKvPhoto.pageNum);
            this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemActGlyphForPhotoMgmt.kvGlyphArr, CopyServiceStatic.copyFromKvPhotoToKvGlyph( this.pagerVMemActGlyphForPhotoMgmt.currentItem), "pagerVGlyphMgmt-");

            this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.getPagerVMemActGlyphMenuForPhotoMgmt(this.currentKvPhoto);
            // this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemberActivityForPhotoMgmt.pagedItems, this.pagerVMemberActivityForPhotoMgmt.currentItemKvGlyph, "pagerVGlyphMgmt-");
            this.bias.addPrimaryOrOtherClassToKvPhotoElement(this.currentKvPhoto);
            // this.pagerVPhotoMgmt = this.pagerServicePhotoMgmt.getPager(this.kvPhotoArr, this.currentKvPhoto.pageNum, this.pagerVPhotoMgmt.pageSize);
          }
        }
      });
    
     // -------------------------------------------------------------------------
      EmitterSubjectService.runNgAfterViewInitEmitter
        .subscribe(result => {
          // debugger;
           this.ngAfterViewInit();
        } );
      
    // --------------------------------------------------------------------------
    debugger;
    this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displaySignInUserPrimaryKvPhotoGlyphs();
    // this.cdr.detectChanges();
  }
  // debugger;  
  // ---------------------------------------------------------------------------------
  ngAfterViewInit(): void {
    // this.pagerV = this.getPagerVForCurrentPage();
    this.isCurrentlyLockedGlyphMsg();
     this.cdr.detectChanges();
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
    //       (2) https:// www.concretepage.com/angular-2/angular-4-renderer2-example
    //       (3) https:// stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
    //       (4) https:// stackblitz.com/edit/timer-with-pdestroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.executeClearGlyphAction();
    this.toggleCameraGlyphCounter = 0;
    this.isGlypActionCamera = false;
  }

 // ---------------------------------------------------------------------------------
  executeClearGlyphAction(): any {
    this.kvMemActGlyphArrForPhotoMgmt = [];
    this.glyphiconMenuService.setGlyphs(this.kvMemActGlyphArrForPhotoMgmt );
    this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.setKvGlyphPager( 1 );
    this.pagerVMemActGlyphForPhotoMgmt.isPhotoMgmt = false;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyphForPhotoMgmt)) {
      this.pagerVMemActGlyphForPhotoMgmt.kvGlyphArr = this.pagerVMemActGlyphForPhotoMgmt.pagedItems = [];
      // debugger;
    }
    this.ngAfterViewInit();
    // debugger;
    return true;
  }
  
  // ---------------------------------------------------------------------------------
  // Note :  When 'currnt-state-of-PRIVATE-pics' are 'unlocked', current glyph image is
  //        'lock.png' (KvGlyph.value = 'lock.png'), because that is what the user intends to do.
  //
  //       The user can only toggle the locked state to unlocked state or vice versa.
  //
  //       Therefore, the KvGlyph.value = 'lock.png', the info under the glyph is
  //       KvGlyph.key = 'tala', which is what the user intends to do.
  //
  //       Therefore, in the case of (KvGlyph.value = 'lock.png'), 'isCurrentlyLocked'==false,
  //       because 'currnt-state-of-PRIVATE-pics' are 'unlocked'.
  // ---------------------------------------------------------------------------------
  isCurrentlyLockedGlyphMsg () : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForPhotoMgmt) && this.kvMemActGlyphArrForPhotoMgmt.length > 0) {
      const isUnlocked = EmitterSubjectService.getIsUnlocked();
      if (isUnlocked) { this.isCurrentlyLocked = false; }
      else { this.isCurrentlyLocked = true; }
      return this.isCurrentlyLocked;
    }
  }
  // ---------------------------------------------------------------------------------
  stateName(): any {
    return this.opened ? 'show' : 'hide';
  }

  // ---------------------------------------------------------------------------------
  setGlyphAction( page: number, kvg: KvMemberActivityGlyph ): void {
     debugger;
    let tPath: any;
    this.returnUrl = window.location.pathname + window.location.search;

    // --------------------------------------------------------------------------------
    //  pagerPhotoMgmt on the left-hand-side will display the
    //  currently-selected-photo-specific glyphicons menues( primary/make (primary if not, private/public).
    //  And the pagerVarticalPhotoNav will display the user's all photo-buttons on the right-hand-side.
    //  the currelty-selected-photo will have a deepskyblue circular hue on it to distiguish other pics.
    //  So, we get hold of the currentKvPhoto from the pagerPhotoMgmt.currentPhoto
    // --------------------------------------------------------------------------------

    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( kvg ) )
    {
      if (page > 0) {
        this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.setKvGlyphPager(page);
      }
      // debugger;
      let compName = kvg?.key?.toString()?.toLowerCase();
      switch ( compName )
      {
        case 'camera':
          // debugger;
          this.isGlypActionCamera = true;
          this.isCameraVisible = !this.isCameraVisible;
          this.toggleCameraGlyphCounter++;

          this.isShowPhotoUploadUi.isOpened = !this.isShowPhotoUploadUi.isOpened;
          EmitterSubjectService.emitIsShowPhotoUpload(this.isShowPhotoUploadUi.isOpened)
          // EmitterSubjectService.emitToggleCameraGlyph(this.toggleCameraGlyphCounter);
          // -------------------------------------------------------------------------
          // Note: Date: 20220508
          //       isCameraVisibleEmitter && isCameraGlypgVisibleEmitter work as a pair / tuple
          //       emitters that alters the isCameraVisible' value.
          //       the isCameraGlypgVisibleEmitter is emitted by FileUploadComponent and listened to by PagerVerticalPhotoMgmtComponent
          //       and isCameraVisibleEmitter is emitter by PagerVerticalPhotoMgmtComponent and listened to by FileUploadComponent
          //       so that there is no cyclic published and subscriber.
          // -------------------------------------------------------------------------
          // this.isShowPhotoUploadUi.isOpened = !this.isShowPhotoUploadUi.isOpened;
          // -------------------------------------------------------------------------------------------------------------------------
          // camera-glyph-button should be always visible and it toggles ImageUploadComponent via isToggleFileUpload.
          // because camera-glyph-button is always visible, clicking cancel-button on ImageUploadComponent does not effect the visibility of camera-glyph-button;          
          // however, camera-glyph-button is clicked, it will toggle (show/hide) the ImageUploadComponent.
          // -------------------------------------------------------------------------------------------------------------------------
          EmitterSubjectService.emitIsToggleFileUpload(this.isToggleFileUpload );
          // EmitterSubjectService.emitIsPhotoMgmt(true);
          // EmitterSubjectService.emitIsViewMember(false);
          this.ngAfterViewInit();
          break;
        case 'primary': 
           debugger;

          // note: photo status change does not require a nre page launch, but may need to update the memberView page's data upon successful action.
          // EmitterSubjectService.emitIsPhotoMgmt( true );
          this.photoMgmtAction = new PhotoMgmtAction();
          this.photoMgmtAction.action = kvg.key;
          this.photoMgmtAction.photoId = kvg.photoId > 0 ? kvg.photoId : this.currentKvPhoto.photoId;
          this.photoMgmtAction.sitUserId = kvg.sitUserId;
          let tPhotoBn = null;
          
          this.photoAndPrimaryPhotoMgmtService.sendPhotoMgmtAction(this.photoMgmtAction);// .subscribe( data =>
          //{
          //  // debugger;
          //  let photo = new Photo();
          //  photo = CopyServiceStatic.copyFromPhotoBnToClientPhoto( photo, data );

          //  this.photoAndPrimaryPhotoMgmtService.executePhotoMgmtPrimaryAction();

          //  let kvPhoto = CopyServiceStatic.copyFromPhotoToKvPhoto( photo );
          //  if ( kvPhoto instanceof KvPhoto )
          //  {
          //    // debugger;
          //    this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.getPagerVMemActGlyphMenuForPhotoMgmt(kvPhoto);
          //    // TODO: create an emitter for this:
          //    // this.pagerVKvPhotoNav = this.photoMgmtService.executePagerVKvPhotoNavKvPhotoTasks(kvPhoto);
          //  }
          //});
          this.ngAfterViewInit();
          break;
        case 'private': case 'public':
           debugger;
          // note: photo status change does not require a nre page launch, but may need to update the memberView page's data upon successful action.
          // EmitterSubjectService.emitIsPhotoMgmt( true );
          this.photoMgmtAction = new PhotoMgmtAction();
          this.photoMgmtAction.action = kvg.key;
          this.photoMgmtAction.photoId = kvg.photoId > 0 ? kvg.photoId : this.currentKvPhoto.photoId;
          this.photoMgmtAction.sitUserId = kvg.sitUserId;
          tPhotoBn = null;

          this.photoAndPrimaryPhotoMgmtService.sendPhotoMgmtAction(this.photoMgmtAction); //.subscribe( data =>
          //{
          //  // debugger;
          //  let photo = new Photo();
          //  photo = CopyServiceStatic.copyFromPhotoBnToClientPhoto( photo, data );
          //  let tProfilePics: any;
          //  let kvPhoto = CopyServiceStatic.copyFromPhotoToKvPhoto(photo);           
            
          //  // debugger;
          //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) &&  kvPhoto instanceof KvPhoto )
          //  {
          //    this.photoAndPrimaryPhotoMgmtService.executeOnNonPrimaryPhotoMgmtAction(kvPhoto).subscribe(result => {
          //      tProfilePics = result;
          //    });
          //    // debugger;
          //    this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.getPagerVMemActGlyphMenuForPhotoMgmt(kvPhoto);
          //    // TODO: create an emitter for this:
          //    //this.pagerVKvPhotoNav = this.photoMgmtService.executePagerVKvPhotoNavKvPhotoTasks(kvPhoto);
          //  }
          //});
          this.ngAfterViewInit();
          break;        
        default:
          // debugger;
          // this.mcLoader.componentName = 'index';
          // this.mcLoader.moduleName = 'app';
          // this.mcLoader.sitUserId = this.sitUser.sitUserId;

          //this.pagerBrdCrm = RedirectionServiceStatic.fireMcLoaderRoute( this.mcLoader, this.router );
          break;
      }
      // debugger;
    }
  }
}
// ================================================================
    // ---------------------------------------------------------------
    // ngOnChanges(changes : SimpleChanges) {
    //  //only run when property 'data' changed
    //  if (changes['images']) {
    //    //debugger;
    //    this.isMobilevar = EmitterSubjectService.getIsMobile();
    //    if (this.glyphKvArr.length > 0) { this.pagerV = (this.pagerServiceV as PagerServiceVertical).setPage(1);
    //  }
    //  this.cdr.detectChanges();
    // }
    //  -------------------------------------------------------------------------
    // EmitterSubjectService.sitUserEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //  {
    //    // debugger;
    //    this.sitUser = result as SitUser;
    //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //    this.signedInUserId = this.loginSuccess.signedInUserId;
    //    this.ngAfterViewInit();
    //  } );
    // --------------------------------------------------- 
    // EmitterSubjectService.setPhotoMgmtPageEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe(result => {
    //    // debugger;
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {

    //      this.photoMgmtService.executePagerVKvPhotoNavKvPhotoTasks(result);
    //      this.ngAfterViewInit();
    //    }

    //    // TODO: remove after testing:
    //    // ---------------------------
    //    // this.pagerVMemberActivityForPhotoMgmt = this.glyphiconMenuService.getPagerVMemActGlyphMenuForPhotoMgmt(result);
    //    //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForPhotoMgmt)
    //    //  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForPhotoMgmt.pagedItems)
    //    //  && this.pagerVMemberActivityForPhotoMgmt.pagedItems.length > 0 ) {
    //     // debugger;
    //      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVPhotoMgmt)) {

    //      //  // this will execute: updateBackground, 
    //      //  // this.pagerVPhotoMgmt = this.photoMgmtService.executeDuringNgAfterViewInit(this.pagerVPhotoMgmt);// currentPage & displayGlyphMenu
    //      //  // this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVPhotoMgmt.kvPhotoArr, this.pagerVPhotoMgmt.currentItemKvPhoto);
    //      //  // debugger;

    //      //}          
    //      // this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemberActivityForPhotoMgmt.pagedItems, this.pagerVMemberActivityForPhotoMgmt.currentItemKvGlyph, "pagerVGlyphMgmt-");
    //   // }
    //  });
    // ---------------------------------------------------
    // TODO: experimnt to display glyphs without this emitter.
    // ---------------------------------------------------
    // EmitterSubjectService.displayPhotoMgmtGlyphiconMenuEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( data =>
    //  {
    //    // TODO: check the steps: tested and works:
    //    // ----------------------------------------
    //    // debugger;
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0) {
    //      this.kvMemActGlyphArrForPhotoMgmt = data;
    //      // Note : The View does not require to programatically draw the images,
    //      //       just setting the glyph/image name will suffice
    //      this.glyphiconMenuService.executeGlyphMenuArr(this.kvMemActGlyphArrForPhotoMgmt);

    //      this.currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();

    //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto)) {
    //        if (this.currentKvPhoto.pageNum <= 0) {
    //          if (this.currentKvPhoto.index >= 0) {
    //            this.currentKvPhoto.pageNum = this.currentKvPhoto.index + 1;
    //          }
    //          else if (this.currentKvPhoto.itemNum >= 0) {
    //            this.currentKvPhoto.pageNum = this.currentKvPhoto.itemNum + 1;
    //          }
    //        }
    //        this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvMemActGlyphArrForPhotoMgmt, this.currentKvPhoto.pageNum);
    //      }
    //      else {
    //        this.pagerVMemActGlyphForPhotoMgmt = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvMemActGlyphArrForPhotoMgmt, 1);
    //      }
    //      // debugger;
    //      this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemActGlyphForPhotoMgmt.pagedItems, this.pagerVMemActGlyphForPhotoMgmt.currentItemKvGlyph, "pagerVGlyphMgmt-");

    //      this.ngAfterViewInit();
    //    }
    //  } );

  // ---------------------------------------------------------------------------------
  // getPagerVForDefaultPage () : any
  // {
  //  this.pagerForPhotoMgmt = this.glyphiconMenuService.setKvGlyphPager( 1 ); // default-case

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVGlyphForPhotoMgmt)) {
  //    this.pagerForPhotoMgmt.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
  //    // debugger;
  //  }
  //  this.ngAfterViewInit();
  //  return this.pagerVGlyphForPhotoMgmt;
  // }
  // ---------------------------------------------------------------------------------
  // Note : this method does not check whether it is a isPhotoMgmt or isViewMember.
  //       the caller should check/filter and call this only in case of isPhotoMgmt==true.
  // ---------------------------------------------------------------------------------
  // getPagerVForCurrentPage ( kvPhoto : KvPhoto ) : any
  // {
  //  // Note:  IMPORTANT! it may be required that either photoId or PhotoBnId will be used but NOT both
  //  //        and the decision will depend on application wide whether Photo or PhotoBn will be used.
  //  // ----------------------------------------------------------------------------------------------
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) || kvPhoto.sitUserId === 0 || kvPhoto.photoId === 0 || kvPhoto.photoBnId == 0)
  //  {
  //    this.currentPageGlyph = EmitterSubjectService.getCurrentPage();
  //    // debugger;

  //    // if currentPageGlyph is still not complete, create it from currentPhoto or currentKvPhoto
  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph.value)) {
  //      this.currentPhoto = EmitterSubjectService.getCurrentPhoto();
  //      // debugger;

  //      //  if currentPhoto data exists:
  //      // -----------------------------
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPhoto) && this.currentPhoto.photoId > 0) {
  //        this.currentPageGlyph.photoId = this.currentPhoto.photoId;
  //        this.currentKvPhoto = CopyServiceStatic.copyFromPhotoToKvPhoto(this.currentPhoto);
  //        EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //        // debugger;
  //      }
  //      //  create currentKvPhoto from loginSuccess and kvPhoto:
  //      // -----------------------------------------------------
  //      else {
  //        this.currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();
  //        this.currentKvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
  //        this.currentKvPhoto = CopyServiceStatic.copyKvPhotoIfExists(this.currentKvPhoto, kvPhoto);
  //        this.currentPageGlyph.photoId = this.currentKvPhoto.photoId;
  //      }

  //      // create the currentPageGlyph:
  //      // ----------------------------
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto) && this.currentKvPhoto.photoId > 0) {
  //        this.currentPageGlyph = CopyServiceStatic.copyFromKvPhotoToKvGlyph(this.currentKvPhoto);
  //        // debugger;
  //      }
  //    }
  //    // Note : currentKvPhoto does not have a pageNum, so we first create the pagination, and then get its number:
  //    // ----------------------------------------------------------------------------------------------------------
  //    if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph) && this.currentPageGlyph.pageNum <= 0)
  //      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto) && this.currentKvPhoto.pageNum <= 0)) {
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.glyphiconMenuService)) {
  //        this.pagerV = this.glyphiconMenuService.setKvGlyphPager(1);
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerV)) {
  //          this.pagerV.kvGlyphsArr.map(e => {
  //            // debugger;
  //            if (e.photoId === this.currentKvPhoto.photoId && e.pageNum > 0) {
  //              this.currentKvPhoto.pageNum = e.pageNum;
  //              this.currentPageGlyph.pageNum = e.pageNum;
  //              this.currentPageGlyph.photoId = e.photoId;
  //              EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //              EmitterSubjectService.setCurrentPage(this.currentPageGlyph);
  //              // debugger;
  //            }
  //          });
  //        }
  //      }
  //    }
  //    else {
  //      EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
  //      EmitterSubjectService.setCurrentPage(this.currentPageGlyph);
  //      // debugger;
  //    }      
  //  }
  //  else {
  //    // debugger;
  //    if (this.currentKvPhoto.photoId === 0) {
  //      this.currentKvPhoto = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
  //      this.currentKvPhoto = CopyServiceStatic.copyKvPhotoIfExists(this.currentKvPhoto, kvPhoto);       
  //    }
  //    this.glyphKvArr = this.glyphiconMenuService.createGlyphMenuArrFromKvPhoto(this.currentKvPhoto);
  //    // debugger; // TODO: watch for primary-photo-case
  //    this.glyphiconMenuService.setGlyphs(this.glyphKvArr);
  //  }


  //  // render the glyphicon-menu for the currentPageGlyph:
  //  // ----------------------------------------------
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPageGlyph.value)
  //    && this.currentPageGlyph.value.length > 0) {
  //    // debugger;

  //    // debugger;
  //    // will display the glyphs
  //    this.glyphKvArr = this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvGlyph(this.currentPageGlyph);
  //    // debugger;


  //    // Note : The View does not require to programatically draw the images, just setting the glyph/image name will suffice
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.glyphKvArr) && this.glyphKvArr.length > 0) {
  //      // debugger;
  //      this.pagerVerticalService.executeGlyphMenuArr(this.glyphKvArr);
  //      // debugger;
  //    }
  //  }

  //  if (this.currentPageGlyph.pageNum > 0) {
  //    // debugger;
  //    this.pagerV = this.glyphiconMenuService.setKvGlyphPager( this.currentPageGlyph.pageNum ); // current-case
  //  }
  //  else {
  //    // debugger;
  //    this.pagerV = this.glyphiconMenuService.setKvGlyphPager( 1 );  // default-case
  //  }
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerV)) {
  //    this.pagerV.isPhotoMgmt = true;
  //    // debugger;
  //  }
  //  this.ngAfterViewInit();
  //  return this.pagerV;
  // }
  // ---------------------------------------------------------------------------------
  // executeCurrentKvPhotoTasks (currentKvp : KvPhoto) : void {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvp)) {
  //    // this.pagerVKvPhotoNav = this.pagerServicePhotoMgmt.getPagerVPhotoMgmtForCurrentPage(currentKvp);
  //    this.currentKvPhoto = currentKvp;
  //    this.currentPageGlyph.photoId = currentKvp.photoId;
  //  }
  // }
  // ---------------------------------------------------------------------------------
  // getPagerVForCurrentKvPhoto (kvp: KvPhoto) : any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp) && kvp.photoBnId > 0 ) {
  //    this.pagerVKvPhotoNav = this.pagerServicePhotoMgmt.getPager(this.pagerVKvPhotoNav.kvPhotoArr, kvp.pageNum, this.pagerVKvPhotoNav.pageSize);
  //  }
  // }


