
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject, Subscription, interval } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, take, takeUntil, tap } from 'rxjs/operators';
import { URLUtils } from '../../app/directiveFilterPipeModule/epl/URLUtils';
// import { URLUtils } from "../../../src/eplLib/URLUtils";
import { DOCUMENT } from '@angular/common';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { PagerBreadcrum } from '../../models/breadcrum/pagerBreadcrum.model';
import { ModuleComponentLoader } from '../../models/common/moduleComponentLoader.model';
import { SpinnerModel } from '../../models/common/spinnerModel.model';
import { BackgroundImageAnimationService } from '../rendererServiceService/backgroundImageAnimationService.service';
import { BreadcrumServiceStatic } from '../staticServices/breadcrumServiceStatic.service';
import { ModuleComponentLoaderServiceStatic } from '../staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { MessageBuilderServiceStatic } from '../staticServices/messgeBuilderServiceStatic.service';

@Injectable({ providedIn: 'root'})
export class RedirectionService implements OnDestroy
{
  public activatedRoute : any;
  public boxNonce : BoxNonceEntity = new BoxNonceEntity();
  public document : Document;
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public isMobilevar = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public router: Router;
  public returnUrl : string = '';
  public sitUser : SitUser = new SitUser();
  public urlString = '';
  public urlPathStr = '';
  public urlSearchStr = '';
  public elementRef: any;
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public message : any;
  public stable : any;
  public timer : any;
  public timerArray : any[] = [];
  // -----------------------------------------
  public constructor (
    public bias : BackgroundImageAnimationService,
    @Inject(DOCUMENT) _document,
    @Inject(Window) window,) {
    this.document = _document;
    this.initialize();

    // this.appInitUrlEmitter.subscribe((result) => {
    //  debugger;
    //  this.appStartUrlStringParser();
    // });

    //
    // -----------------------------------------------------------
    EmitterSubjectService.mcLoaderToNavigateEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.mcLoader = result;
        return this.fireMcLoaderRoute(this.mcLoader);
      });
  }
  // ---------------------------------------------------------------
  ngOnDestroy () {
    /*
     * prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *       (2) https:// www.concretepage.com/angular-2/angular-4-renderer2-example
     *       (3) https:// stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *       (4) https:// stackblitz.com/edit/timer-with-pdestroyed
     */
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach((timer) => clearInterval(timer));
  }
  // -----------------------------------------
  initialize () {
    // debugger;
    //  -------------------------------------------------------------------------
    EmitterSubjectService.fireMcLoaderRouteEmitter.subscribe((result) => {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
        // ---------------------------------------------------
        result = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(result);
        
        this.fireMcLoaderRoute(result as ModuleComponentLoader).subscribe(data => {
          this.pagerBrdCrm = data
        });
        EmitterSubjectService.emitPagerBreadcrum(this.pagerBrdCrm);
      }
    });
    //  -------------------------------------------------------------------------
  } 

  /*
   * -----------------------------------------------------------------
   *
   * The following will reroute on the basis of url string on startup
   *
   * -----------------------------------------------------------------
   */
  public appStartUrlStringParser (router : Router) : any {
    
    let action = '';
    let controller = '';
    let id : any;
    let origin : any;
    let path = '';

    let tRoute = '';
    let urlParamMap : Map<any, any> = new Map<any, any>();
    let urlparts : any[] = [];
    let mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
    this.urlString = window.location.pathname + window.location.search;
    this.urlPathStr = window.location.pathname;
    this.urlSearchStr = window.location.search;

    this.returnUrl = this.urlString;
    EmitterSubjectService.setReturnUrl(this.returnUrl);

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.router) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(router)) {
      this.router = router;
    }

    // debugger;
    let paramArr = URLUtils.pathToURIParams(this.urlString);
    origin = URLUtils.pathToLocation(this.urlString).origin;
    urlParamMap.set('origin', origin);
    // -------------------------------------------------------------------------------------------------------------
    // TODO: if this paramArr contains all the attributes of the url such as origin, controller, action, paramMap, 
    //       then use this to extract them all, otherwise, there is no need to use this Utility file.
    // -------------------------------------------------------------------------------------------------------------
    // debugger;

    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.urlString))
    {
      if (this.urlString.indexOf('ResetPassword') !== -1) {
        // debugger;
        return this.resetPassword(router, this.urlString);
      }
      else {
        if (this.urlString.length > 1) {
          // api case:
          // Note: api cases are when serviceWorker makes the api calls for getting data, not for page load/reload
          //       But the non-api cases, the url contains the backend's 'controllerName/action' after '/api/'
          //       such as 'https://slakez/api/controller/action'
          // -------------------------------------------------------------------------------------------------------                  
          // non-api cases when the client's router makes redirections or server-reirection is invoked by swipe-down
          // -------------------------------------------------------------------------------------------------------
          if (this.urlString.indexOf('/api') === -1)
          {
             // parameter's case:
             // -----------------
            if (this.urlString.includes('?'))
            {
              // debugger;
              urlparts = this.urlString.split('?');
              if (urlparts.length > 1) {
                this.urlString = urlparts[ 0 ];
                id = urlparts[ 1 ];

                // ---------------------------------------------------------------------------
                // TODO: follow the pattern/example of a complex url with multiple parameters:
                // ---------------------------------------------------------------------------
                if (id.includes('&')) {
                  // debugger;
                  var idParts = id.split('&');
                  if (idParts.length > 1) {
                    // debugger;
                    mcLoader.id = idParts[ 0 ];

                    urlParamMap.set(idParts[ 0 ], idParts[ 1 ]);
                    // ---------------------------------------------               
                    // need to convert to number for sitUserId:
                    // ---------------------------------------------
                    if (parseInt(idParts[ 0 ]) > 0) {
                      mcLoader.id = parseInt(idParts[ 0 ]);
                    }
                    // debugger;
                  }
                  var paramParts = idParts[ 1 ].split('&');
                  if (paramParts.length > 1) {
                    paramParts.map((p : any) => {
                      var paramStr = p; // p = param kv pair
                      var paramStrParts = paramStr.split('=');
                      if (paramStrParts.length > 1 && !urlParamMap.has(paramStrParts[ 0 ])) {
                        urlParamMap.set(paramStrParts[ 0 ], paramStrParts[ 1 ]); // p = <k, v> = <paramStrParts[ 0 ], paramStrParts[ 1 ]>
                        // debugger;
                        if (paramStrParts[ 0 ].toString().indexOf('code') !== -1) {
                          EmitterSubjectService.setResetPasswordCode(paramStrParts[ 1 ]);
                          EmitterSubjectService.emitResetPasswordCode(paramStrParts[ 1 ]);
                        }
                      }
                    })
                    mcLoader.paramMap = urlParamMap;
                  }
                }
              }
            }
          }
          // componentName(aka action) & moduleName (aka controller) extraction:
          // -------------------------------------------------------------------
          if (this.urlString.includes('/')) {
            // debugger;
            urlparts = this.urlString.split('/');

            if (urlparts.length > 0) {
              // debugger;
              mcLoader.action = mcLoader.componentName = action = urlparts[ urlparts.length - 1 ]; // Take the last item for componentName i.e. action
              mcLoader.controller = mcLoader.moduleName = controller = urlparts[ urlparts.length - 2 ]; // Take the last item for moduleName i.e. controller
              if (action.length > 0) {
                // debugger;
                // -----------------------------------------------------------------------------------
                // TODO:  If the request is due to an pageRefresh such as swipe down,
                //        preserve the existing component's data on the service and
                //        reuse them at init() so that the page load is fast.
                // Note:  The page on refresh will go though its api calls if relevant regardless
                //        of previously saved values.
                // -----------------------------------------------------------------------------------
                this.setRoute(this.router, mcLoader.componentName, mcLoader.id).subscribe(data => {
                  return data;
                });

                // hide vertical menues if applicable:
                // -----------------------------------
                // this.hideVerticalMenues();
                // this.hideVerticalMenues(mcLoader);

              }
            } else {
               debugger;
              return this.setRoute(this.router, 'index', 0).subscribe(data => {
                return data;
              });
            }
          } else {
             debugger;
            return this.setRoute(this.router, 'index', 0).subscribe(data => {
              return data;
            });
          }
        }
        else {
           debugger;
          return this.setRoute(this.router, 'index', 0).subscribe(data => {
            return data;
          });
        }
      }
    }
    else {
       debugger;
      return this.setRoute(this.router, 'index', 0).subscribe(data => {
        return data;
      });
    }
  }
  // -----------------------------------------------------------------
  //  Note: the activatedComponentRouteDictionary gets populated with
  //        mcLoader model when each time a calling function emits
  //        the mcLoader.
  // -----------------------------------------------------------------
  public clearRoutes ( router : Router ) : Promise<any> | any
  {
    BreadcrumServiceStatic.clearRoutes();
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.router) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(router)) {
      this.router = router;
    }
    return this.setRoute(this.router, 'index', 0 );
  }
  // --------------------------------------------------------------
  // NOte:  This appplication creates the respective dictionary(ies) 
  //        that each component needs when it comes in scope via routing
  //        Therefore, all the DbDexie to Dictionarty nethods that
  //        reads the indexedDb and put the results in the respectie
  //        dictionary here.
  // --------------------------------------------------------------
  public createDictionary (mcLoader : ModuleComponentLoader) : any {
    let path = '';
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    let dbDexieToDictionaryService = EmitterSubjectService.getDbDexieToDictionaryService();
    debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.moduleName) && mcLoader.moduleName.length > 0
      && mcLoader.componentName && mcLoader.componentName.length > 0) {
      switch (mcLoader.componentName.toLowerCase()) {
        case 'buddies':
        case 'iblocked':
        case 'myfriends':
        case 'iliked':
        case 'itracked':
        case 'utracked':
        case 'iwinked':
        case 'uwinked':
          // debugger;
          mcLoader.moduleName = 'activitym';
          break;
        case 'mychats':
        case 'chat':
          mcLoader.moduleName = 'chatm';
          // debugger;
          break;
        case 'myemails':
        case 'myenotes':
        case 'viewemail':
        case 'viewenote':
        case 'composeemail':
        case 'composeenote':
          mcLoader.moduleName = 'communicationm';
          break;
        case 'about':
        case 'features':
        case 'comparememberships':
        case 'contactus':
        case 'notfound':
        case 'privacy':
        case 'terms':
          mcLoader.moduleName = 'publicm';
          break;
        case 'forgot':
        case 'login':
          mcLoader.moduleName = 'loginm';          
          break;
        case 'logout':
        case 'resetpassword':
          // debugger;
          mcLoader.moduleName = 'loginm';
          break;

        case 'register':
        case 'prescreen':
          mcLoader.moduleName = 'registerm';
          break;
        case 'members':
        case 'membersindividually':
          debugger;
          mcLoader.moduleName = 'membersm';
          break;
        case 'memberscroll':
          debugger;
          mcLoader.moduleName = 'memberscrollm';
          break;
        case 'memberviewmgmt':
        case 'upgradedmembers':
          mcLoader.moduleName = 'memberviewandupgradedm';
          break;
        case 'memberedit':
          mcLoader.moduleName = 'profilem';
          break;

        case 'dialog':
        case 'message':
        case 'spinner':
          mcLoader.moduleName = 'modalm';
          break;
        case 'myprofileviewmgmt':
        case 'myphotomgmt':
          mcLoader.moduleName = 'mym';
          // debugger;
          break;
        case 'gayatriyantra':
        case 'sandbox':
          mcLoader.moduleName = 'sandboxm';
          break;
        case 'payment':
        case 'upgraded':
          mcLoader.moduleName = 'salesm';
          break;
        case 'animation':
        case 'boxnoncetest':
        case 'errorlog':
        case 'gesturetest':
        case 'imageresizer':
          mcLoader.moduleName = 'staffm';
          break;
        default:
          // debugger;
          mcLoader.moduleName = 'app';
          mcLoader.componentName = 'index';
          break;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dbDexieToDictionaryService)) {
        debugger;
        dbDexieToDictionaryService.createAllSitUserRelatedDictionariesFromDbDexie();
        debugger;
			}
    }
  }
  // --------------------------------------------------------------
  /*
   * ---------------------------------------------------------------
   *  Note: here we hold the Path information on 'mcLoader'
   */
  public determinePathFromLoginSuccess () : any {
    let mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();


    this.urlString = window.location.pathname + window.location.search;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.isEmailConfirmed) {
      mcLoader.id = this.loginSuccess.signedInUserId;
      // debugger;
      if (this.loginSuccess.signedInUserId > 0)
      {
        if (this.loginSuccess.controller === 'member' || this.urlString.toLowerCase().indexOf('member') !== -1) {
          if (!this.loginSuccess.isProfileComplete || this.loginSuccess.isEditProfile) {
            debugger;
            mcLoader.moduleName = 'profilem';
            mcLoader.componentName = 'memberEdit';

          } else {
            debugger;
            mcLoader.moduleName = 'memberscrollm';
            mcLoader.componentName = 'memberscroll';
          }
        }
        else if (this.loginSuccess.controller === 'index' || this.urlString.toLowerCase().indexOf('index') !== -1) { // if
          debugger;
          mcLoader.moduleName = 'memberscrollm';
          mcLoader.componentName = 'memberscroll';
        }
        else {
          mcLoader.moduleName = 'app';
          mcLoader.componentName = 'index';
        }
      }
      else {
        mcLoader.moduleName = 'app';
        mcLoader.componentName = 'index';
      }
    }
    // debugger;
    return mcLoader;
  }
  
  // --------------------------------------------------------------
  // Note: important aspect of this method is that is updates the
  //       PagerBreadCrum as opposed to BreadcrumService.setBreadcrumPage() that
  //       does not, and instead simply navigates back and forth over breadcrumRingArr entries.
  // --------------------------------------------------------------
  public fireMcLoaderRoute ( mcl:ModuleComponentLoader ) : Observable<any> 
  {
    let path = '';
    let isBtb = false;
    let Pgr : any;
    let mcLoader : ModuleComponentLoader = mcl
    return new Observable((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.router)) {
        this.router = mcLoader.router;
        // debugger;
      }
      else {
        mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(mcLoader);
        this.router = mcLoader.router;
		  }
      // debugger;
      if (ModuleComponentLoaderServiceStatic.isValidModuleComponentLoader(mcLoader))
      {
        // debugger;
        // must be called before rerouting!
        // --------------------------------
        //
        // debugger;
        // will check if the same component is back to back & update pagerBreadcrum at EmitterService:
        // -------------------------------------------------------------------------------------------
        Pgr = BreadcrumServiceStatic.setNewPath(mcLoader);

        // debugger;
        // if PagerBreadCrum was returned:
        // -------------------------------
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(Pgr) && Pgr.currentPage > 0)
        {
          // debugger;
          this.pagerBrdCrm = Pgr as PagerBreadcrum;
          EmitterSubjectService.setPagerBreadcrum(this.pagerBrdCrm);

          // EmitterSubjectService.emitPagerBreadcrum(this.pagerBrdCrm);

          // TODO:  if something does not render, uncomment this. 
          //        Not sure which code this can block, but it seems redundant:
          // ------------------------------------------------------------------
       //   if (!BreadcrumServiceStatic.emitProfilePageSelectorBoolean(mcLoader.componentName) && !this.isCurrentUrlAProfilePage(mcLoader.url)) {
       //     switch (mcLoader.componentName.toLowerCase()) {
       //       case 'myphotomgmt':
       //         EmitterSubjectService.setIsPhotoMgmt(true);
       //         break;
       //       case 'memberviewmgmt':
       //         EmitterSubjectService.setIsViewMember(true);
       //         break;
       //       case 'myprofileviewmgmt':
       //         EmitterSubjectService.setIsMyProfile(true);
       //         break;
       //       default:
       //         // this.fireMcLoaderRoute(mcLoader); // TODO: check if it works, otherwise delete this!
       //         break;
					  //}          
       //     // debugger;
       //   }
          // EmitterSubjectService.emitRunNgAfterViewInit(true);

          //  debugger;          
          // ---------------------------------------------------------------------------------------------------
          // if the user clicked 'myProfileView' while viewing someone else's profile, 
          // if the current page is memberView and previous page was also memberView but of different user
          // i.e. back-to-back-memberView:
          // ---------------------------------------------------------------------------------------------------
          // if (mcLoader.componentName.toLowerCase().indexOf('memberviewmgmt') !== -1) {
          // if (BreadcrumServiceStatic.isDifferentMemberView()) {
          //   // emit the fact that this user is different than the previous user
          //   EmitterSubjectService.emitDisplayDifferentMemberView(mcLoader);
          //   // debugger;              
          // }
          // }
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.url) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.router)) {
            // debugger;
            if (mcLoader.url.length >= 1 && mcLoader.url[ 0 ].indexOf("/") !== -1) {
              // debugger;
              try {
                this.document = EmitterSubjectService.getDocument();
                console.log('navigateByUrl : ' + mcLoader.url);
                this.router.navigateByUrl(mcLoader.url).then(data => {
                  console.log(data);
                  // debugger;
                  MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, 'routed url:' + mcLoader.url);
                  subscriber.next(this.pagerBrdCrm);
                  // subscriber.complete();
                }).catch(error => {
                  // debugger;
                  console.log('redirectionService.navigateByUrl.error=' + error);
                });
              }
              catch {
                console.log('redirectionService.navigateByUrl.error');
              }
            }
            else {
              // debugger;
              try {
                this.document = EmitterSubjectService.getDocument();
                console.log('navigate : ');
                this.router.navigate([ mcLoader.url ]).then(data => {
                  console.log(data);
                  //this.loginSuccess = EmitterSubjectService.getLoginSuccess();
                  //MessageBuilderServiceStatic.printMessage(this.loginSuccess.signedInUserId, 'routed url:' + mcLoader.url);
                  // debugger;
                  subscriber.next(this.pagerBrdCrm);
                  // subscriber.complete();
                }).catch(error => {
                  // debugger;
                  console.log('redirectionService.navigate.error=' + error);
                });
              }
              catch {
                console.log('redirectionService.navigate.error');
              }
            }
           
            // hide vertical menues if applicable:
            // -----------------------------------
            // this.hideVerticalMenues();
            //if (!this.hideVerticalMenues(mcLoader)) {
            // // debugger;
            //  this.hideVerticalMenues2(mcLoader)
            //}           
          }
          else // mcl was returned, which is most likely invalid, meaning lacks either moduleName or componentName
          {
            // debugger;
            let message = "mcl is incomplete. moduleName = " + mcLoader.moduleName + ", componentName = " + mcLoader.componentName;
            // EmitterSubjectService.emitMessage(message);
            subscriber.next(this.pagerBrdCrm);
            subscriber.complete();
          }
        }
        else // mcl was returned, which is most likely invalid, meaning lacks either moduleName or componentName
        {
          // debugger;
          let message = "mcl is incomplete. moduleName = " + mcLoader.moduleName + ", componentName = " + mcLoader.componentName;
          // EmitterSubjectService.emitMessage(message);
          subscriber.next(this.pagerBrdCrm);
          subscriber.complete();
        }
      }
      else {
        subscriber.next(this.pagerBrdCrm);
        subscriber.complete();
      }
    })
  }
  // --------------------------------------------------------------
  public fireMcLoaderforSignedInUser (mcLoader : ModuleComponentLoader) : any {
    let path = '';
    let isBtb = false;
    let Pgr : any;   
     
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.router)) {
      this.router = mcLoader.router;
      // debugger;
    }
    else {
      mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(mcLoader);
      this.router = mcLoader.router;
    }

    if (ModuleComponentLoaderServiceStatic.isValidModuleComponentLoader(mcLoader)) {

      // must be called before rerouting!
      // --------------------------------
      //
      // debugger;
      // will check if the same component is back to back & update pagerBreadcrum at EmitterService:
      // -------------------------------------------------------------------------------------------
      Pgr = BreadcrumServiceStatic.setNewPath(mcLoader);

      // debugger;
      // if PagerBreadCrum was returned:
      // -------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(Pgr) && Pgr.currentPage > 0) {
        // debugger;
        this.pagerBrdCrm = Pgr as PagerBreadcrum;
        EmitterSubjectService.setPagerBreadcrum(this.pagerBrdCrm);
        EmitterSubjectService.emitPagerBreadcrum(this.pagerBrdCrm);

      }
      
      // debugger;
      // if emitPageSelectorBoolean == false, then:
      // ------------------------------------------
      if (!BreadcrumServiceStatic.emitProfilePageSelectorBoolean(mcLoader.componentName) && !this.isCurrentUrlAProfilePage(mcLoader.url)) {
        switch (mcLoader.componentName.toLowerCase()) {
          case 'myphotomgmt':
            EmitterSubjectService.emitIsMyProfile(false);
            break;
          case 'memberviewmgmt':
            EmitterSubjectService.emitIsPhotoMgmt(false);
            break;
          case 'myprofileviewmgmt':
            EmitterSubjectService.emitIsViewMember(false);
            break;
          default:
            ; // do nothing
            break;
        }
      }
        
      // debugger;
      EmitterSubjectService.emitRunNgAfterViewInit(true);
      return this.pagerBrdCrm;
    }      
    else // mcl was returned, which is most likely invalid, meaning lacks either moduleName or componentName
    {
      // debugger;
      let message = "mcl is incomplete. moduleName = " + mcLoader.moduleName + ", componentName = " + mcLoader.componentName;
      // EmitterSubjectService.emitMessage(message);
    }
  }
  // ---------------------------------------------------------------------------------
  public hideVerticalMenues3 (mcl : ModuleComponentLoader) : void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!this.isMemberViewAndMyPhotosPagesBackToBack()
      &&( mcl.url.toLowerCase().indexOf('myprofileviewmgmt') === -1
      && mcl.url.toLowerCase().indexOf('myphotomgmt') === -1)) {
      // debugger;

      EmitterSubjectService.emitIsPhotoMgmt(false);
      EmitterSubjectService.emitIsMyProfile(false);
    }
    else  if (mcl.url.toLowerCase().indexOf('memberviewmgmt') !== -1) {
      EmitterSubjectService.emitIsViewMember(true);
      // debugger;
      if (mcl.id > 0 && mcl.id === this.loginSuccess.signedInUserId) {
        // debugger;
        EmitterSubjectService.emitIsPhotoMgmt(true);
        EmitterSubjectService.emitIsMyProfile(false);
      }
    }
    else if (mcl.url.toLowerCase().indexOf('myphotomgmt') !== -1) {
      // debugger;
      EmitterSubjectService.emitIsPhotoMgmt(true);
      EmitterSubjectService.emitIsMyProfile(false);
    }    
  }
  // ---------------------------------------------------------------------------------
  // Note: Keep until this process is stable. Delete before deployment:
  // ---------------------------------------------------------------------------------
  public hideVerticalMenues2 (mcl : ModuleComponentLoader) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl.componentName)) {
      // debugger;

      if (mcl.returnUrl.toLowerCase().indexOf('myprofileviewmgmt') !== -1 && !this.isMemberViewAndMyPhotosPagesBackToBack()) {
        // debugger;
        EmitterSubjectService.emitIsMyProfile(false);
        return true;
      }
      else if (mcl.returnUrl.toLowerCase().indexOf('myphotomgmt') !== -1 && !this.isMemberViewAndMyPhotosPagesBackToBack()) {
        // debugger;
        EmitterSubjectService.emitIsPhotoMgmt(false);
        EmitterSubjectService.emitIsMyProfile(false);
        return true;
      }
    }
    else return false;
  }
   // ---------------------------------------------------------------------------------
  //public hideVerticalMenuesExp (mcl : ModuleComponentLoader) : any {
  //  // debugger;
  //  let prevPageComponentName : any;
  //  let prevPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 2 ];

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl.componentName)) {
  //    if (!this.isMemberViewAndMyPhotosPagesBackToBack()) {
  //      EmitterSubjectService.emitIsViewMember(false);
  //      EmitterSubjectService.emitIsPhotoMgmt(false);
  //    }

  //    // debugger;
  //    else if (mcl.url.toLowerCase().indexOf('memberviewmgmt') === -1) { // current page is NOT memberViewMgmt
  //      // debugger;
  //      EmitterSubjectService.emitIsViewMember(false);
  //      return true;
  //    }
  //    else if (prevPage.sitUserId === this.loginSuccess.signedInUserId) { // curent page is memberViewMgmt and previous page was also memberViewMgmt but of signedInUser's
  //      EmitterSubjectService.emitIsPhotoMgmt(false);
  //    }
  //    else if (mcl.url.toLowerCase().indexOf('memberviewmgmt') !== -1
  //      && prevPage.sitUserId !== this.loginSuccess.signedInUserId
  //      && mcl.sitUserId === this.loginSuccess.signedInUserId) { // previous page was memeberViewMgmt of non-signedInUser & current page is also memberViewMgmt of signedInUser's
  //      EmitterSubjectService.emitIsPhotoMgmt(true);
  //    }
  //  }
  //}
  // ---------------------------------------------------------------------------------
  //  TODO: To avoid the malfunctioning of the MyPhotos's and MemberViewMgmt using a single MembreViewMgmtComponent, we need to eliminate the
  //        following scenarios: !!!!!
  //  Note: The follwoing function attempts to address  the following scenarious based problems of displaying wrong information and unauthorized access to private information:
  //        1)  MyPhotos page is implemented through the reuse of MemberViewMgmt and MyPhotos's functionalities are handeled within the MemberViewMgmt page.
  //            This causes a non-signedInUser's information to be accessibe to the signedInUser during page switches in the following way(s):
  //
  //            When signedInUser swithes from someone else's profile to view his profile, incorrect information may be displayed. 
  //            And more importantly, the  photoMgmt functionalities of the MyPhotos being handled by MemberViewMgmt page that are the exclusive right to only sigedInUser,
  //            may be compromised in the following way:
  //            After viewing a non-signedInUser's page ( a memberViewMgmt page), singedInUser switching to his own page ( a memberViewMgmt page),
  //            may find previous non-signedInUser's information accessible to the him.
  //            Having access to the previous user's privilaged and private information inadvertently causes a serious violation of privacy of all non-singedInUsers,
  //            since this one non-signedInUser can be any of the non-signedInUsers.
  //
  //            The implementation of MyPhotos is done through the member-router.module, where the url address reflects 'myphotos' that uses MemberViewMgmtComponent,
  //            obstructing the fact that the router switches back to the same page internally, instead of using its own component. Even though visually MyPhotos and
  //            MemberViewMgmt pages are very similar, eith MyPhotos is having extra two child components (PhotoUploadComponent and PagerVerticalPhotoMgmtComponent)
  //            that are not needed by the MemberViewMgmt, they are kept hidden during MemberViewMgmt page and shown during MyPhotos page. However, MemberViewMgmt page
  //            contains and controls the code on behalf of MyPhotos.
  //            
  //            Solution:   There are two possible solutions:
  //                        1) Completely separate MyPhotos functionalities into its own component, including necessary code to run all functionalities of MemberViewMgmt.
  //                        2) Use the MemberViewMgmtComponent as MyPhotosComponent as a child component, but keep the photoMgmt functionalities in the parent MyPhotosComponent
  //                            ans it's respective services separate.
  // 
  //        
  // 
  //        2) The following scenario though related to the currenly mingled use of MemberViewMgmtComponent, poses it's own problems.
  //
  //        3) For the singedInUser, both current and previous pages can be memberViewMgmt back-to-back under only two conditions:
  //            3.1) If signedInUser clicked his MemberTile on the members' page ( where all the membersTiles are located) first,
  //            that launched the memberViewMgmt page with the signedInUser's information, -
  //            and then clicks the avatar-image or profileName below avatar-image, this also causes the memberViewMgmt
  //            page to be launched the second time.
  //
  //            3.2) In fact if both avatar and avatar's profile name can be clicked enumerably many times back-to-back,
  //            causing the memberViewMgmt page to be launched for each click back-to-back.
  //
  //            Possible solution:  Eliminate the separate click for the profileName under the Avatar, and disable the click of Avatar once it's clicked,
  //                                and restore clickablility only after page switch away from the MemberViewMgmt page.
  //                                Also important to take into account that during any non-signedInUser's profile view, Avatar should be clickable.
  //                                It should be disabled ONLY during viewing of the signedInUser's profile.
  // ---------------------------------------------------------------------------------
  public isMemberViewAndMyPhotosPagesBackToBack () : boolean {
    return this.isSignedInUserPagesBackToBack1();
  }
  // ---------------------------------------------------------------------------------
  public isSignedInUserPagesBackToBack () : boolean {
    // -------------------------------------------------//
    // Back-to-Back-memberView-pges-recognition-system: //
    // -------------------------------------------------//
    let prevPageComponentName : any;
    let currentPageComponentName : any;

    let prevPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 2 ];
    let currentPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 1 ];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(prevPage)) {
      prevPageComponentName = prevPage.componentName;
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentPage)) {
      currentPageComponentName = currentPage.componentName;
    }
    if (
      (currentPageComponentName.toLowerCase().indexOf('myphotomgmt') !== -1 // if current page is myPhotos
        && prevPageComponentName.toLowerCase().indexOf('mymemberviewmgmt') !== -1) // and if previous page was memberView
      ||
      (currentPageComponentName.toLowerCase().indexOf('mymemberviewmgmt') !== -1 // if current page is memberView
        && prevPageComponentName.toLowerCase().indexOf('myphotomgmt') !== -1) // and if previous page was myPhotos
      // ||
      // (currentPageComponentName.toLowerCase().indexOf('mymemberviewmgmt') !== -1 // if current page is my memberView ( can happen if top-right-profileImage is clicked )
      //  && prevPageComponentName.toLowerCase().indexOf('mymemberviewmgmt') !== -1) // and if previous page was my memberView ( can happen if top-right-profileImage is clicked )
     )
    {
      return true;
      // debugger;
    }
    else {
      // debugger;
      return false;
    }
  }

  // ---------------------------------------------------------------------------------
  public isCurrentUrlAProfilePage (url ?: string) : boolean {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)){
      url = window.location.pathname + window.location.search;
		}
    let isAProfilePage = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
      if (url.toLowerCase().indexOf('memberviewmgmt') !== -1 || url.toLowerCase().indexOf('myprofileviewmgmt') !== -1 || url.toLowerCase().indexOf('myphotomgmt') !== -1) {
        isAProfilePage = true;
      }
    }
    return isAProfilePage;
  }
  // ---------------------------------------------------------------------------------
	public isSignedInUserPagesBackToBack1 (): boolean {
		// -------------------------------------------------//
		// Back-to-Back-memberView-pges-recognition-system: //
		// -------------------------------------------------//
		let prevPageComponentName : any;
		let currentPageComponentName : any;

		let prevPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 2 ];
		let currentPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 1 ];

		if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(prevPage)) {
			prevPageComponentName = prevPage.componentName;
		}
		if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentPage)) {
			currentPageComponentName = currentPage.componentName;
		}
		if (
			(currentPageComponentName.toLowerCase().indexOf('myphotosmgmt') !== -1 // if current page is myPhotos
        && prevPageComponentName.toLowerCase().indexOf('mymemberviewmgmt') !== -1) // and if previous page was memberView
			||
			(currentPageComponentName.toLowerCase().indexOf('mymemberviewmgmt') !== -1 // if current page is memberView
        && prevPageComponentName.toLowerCase().indexOf('myphotosmgmt') !== -1) // and if previous page was myPhotos
			||
			(currentPageComponentName.toLowerCase().indexOf('mymemberviewmgmt') !== -1 // if current page is my memberView ( can happen if top-right-profileImage is clicked )
				&& prevPageComponentName.toLowerCase().indexOf('mymemberviewmgmt') !== -1
				&& prevPage.id == currentPage.id
				&& currentPage.id === this.loginSuccess.signedInUserId) // and if previous page was my memberView ( can happen if top-right-profileImage is clicked )
		) {
			return true;
			// debugger;
		}
		else {
			// debugger;
			return false;
		}		
	}
  
  // ----------------------------------------------------------------
	public getUrlAddress (): any {
		return window.location.pathname + window.location.search;
	}

  // ---------------------------------------------------------------
  public redirectToAction ( rOuter : Router ) : Observable<any>
  {
    let mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.returnUrl = EmitterSubjectService.getReturnUrl();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    let path = '';
    let router = rOuter;
    
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.router) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(router)) {
      this.router = router;
    }
    // debugger;
    return new Observable(subscriber => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
        // debugger;
        mcLoader = this.determinePathFromLoginSuccess();
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader)) {
          this.setRoute(mcLoader.router, mcLoader.componentName, mcLoader.id).subscribe(data => {
            this.pagerBrdCrm = data;
            subscriber.next(this.pagerBrdCrm);
            subscriber.complete();
          });;
        }
        else return null;
      }
      else {
        this.returnUrl = window.location.pathname + window.location.search;
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.returnUrl) && this.returnUrl.length >= 1) {
          // debugger;
          this.appStartUrlStringParser(router).then(data => {
            this.pagerBrdCrm = data;
            subscriber.next(this.pagerBrdCrm);
            subscriber.complete();
          });;
        }
        else {
          // debugger;
          mcLoader.moduleName = 'app';
          mcLoader.componentName = 'index';
          // ---------------------------------------------------
          mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(mcLoader);
          this.fireMcLoaderRoute(mcLoader).subscribe(data => {
            this.pagerBrdCrm = data;
            subscriber.next(this.pagerBrdCrm);
            subscriber.complete();
          });
        }
      }
    })
    // window.location.reload(); //ref: https://github.com/angular/components/issues/15231
  }
  
  // ---------------------------------------------------------------
   
  public resetPassword ( router : Router , UrlStr: string): any {
    // debugger;
    let id: any;
    const sitUserId = 0;
    let mcLoader : ModuleComponentLoader = new ModuleComponentLoader();

    if (UrlStr) {
      // if the url contains '?', get the first half and hold the second half on id
      if (UrlStr.length > 5 && UrlStr.includes('?')) {
        const urlparts = UrlStr.split('?');

        if (urlparts.length > 1) {
          this.urlString = urlparts[ 0 ];
          id = urlparts[ 1 ];
          EmitterSubjectService.setResetPasswordCode(id);
          EmitterSubjectService.emitResetPasswordCode(id);
          const tReturnUrl = '/loginm/resetPassword/' + id;

          EmitterSubjectService.setReturnUrl(tReturnUrl);

          mcLoader = new ModuleComponentLoader();
          mcLoader.componentName = 'resetPassowrd';
          mcLoader.moduleName = 'loginm';
          mcLoader.id = id;
          // ---------------------------------------------------
          mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(mcLoader);
          EmitterSubjectService.setMCLoader(mcLoader);
          return this.fireMcLoaderRoute(mcLoader).subscribe(data => {
            this.pagerBrdCrm = data;
            return this.pagerBrdCrm;
          });;
        }
        else return null;
      }
      else return null;
    }
    else return null;
  }  
  //  ---------------------------------------------------------------
  public routeToHomePage (rouTer : Router) : Observable<any> {
    let mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
    mcLoader.componentName = 'index';
    mcLoader.moduleName = 'app';
    let router = rouTer;
    // ---------------------------------------------------
    return new Observable(subscriber => {
      mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(mcLoader);
      this.fireMcLoaderRoute(mcLoader).subscribe(data => {
        this.pagerBrdCrm = data;
        subscriber.next(this.pagerBrdCrm);
        subscriber.complete();
      });
    })
  }
  //  ---------------------------------------------------------------
  public routeToMembersPage (rouTer : any) : Observable<any> {
    let mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
    mcLoader.componentName = 'profileTileScroll';
    mcLoader.moduleName = 'profileTileScrollm';
    let router = rouTer;
    // ---------------------------------------------------
    return new Observable(subscriber => {
      mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(mcLoader);
      this.fireMcLoaderRoute(mcLoader).subscribe(data => {
        this.pagerBrdCrm = data;
        subscriber.next(this.pagerBrdCrm);
        subscriber.complete();
      });
    })
  }
  // --------------------------------------------------------------
  public setRoute (rOuter : Router, rOute : string, Id : number) : Observable<any> {
    // debugger;
    var router = rOuter;
    var route = rOute;
    var id = Id;
    this.document = EmitterSubjectService.getDocument();
    // return new Observable(subscriber => {
      // debugger;
    let mcLoader = this.setupRoute(router, route, id);
      // debugger;
    return this.fireMcLoaderRoute(mcLoader);
    //.subscribe(data => {
    //    subscriber.next(data);
    //   subscriber.complete();
    //  })
    // })
  }
  // --------------------------------------------------------------
  public setRouteforSignedInUser (router : Router, route : string, id : number) : any {
    let mcLoader = this.setupRoute(router, route, id);
    return this.fireMcLoaderforSignedInUser(mcLoader);
  }
  // --------------------------------------------------------------
  // EmitterSubjectService.emitFireMcLoaderRoute (this.mcLoader);
  //
  // --------------------------------------------------------------
  public setupRoute (router : Router, route : string, id : number) : any {
    // debugger;
    let mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
    // debugger;
    let path = '';
    mcLoader = new ModuleComponentLoader();
    if (id > 0) {
      mcLoader.id = id;
    }
    

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(router)) {
      mcLoader.router = router;
    }
    else {
      mcLoader.router = this.router;
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(route)) {
      mcLoader.componentName = route;
      // debugger;
    }
    else {
      // debugger;
      mcLoader.moduleName = 'app';
      mcLoader.componentName = 'index';
    }
    // debugger;
    mcLoader = ModuleComponentLoaderServiceStatic.setupMcLoaderModel(mcLoader);
    return mcLoader;
  }
  // ------------------------------------------------------------------------
  //  Begin-of-Element-Rendering-Measurement-System:
  //  ref: https://dev.to/herodevs/route-fully-rendered-detection-in-angular-2nh4
  // ------------------------------------------------------------------------
  public detectNavigationStart (router : Router) : Observable<any> {
    let navStartObservable = new Observable<any>(observer => {
      let navStart$ = router.events.pipe(
        filter(event => event instanceof NavigationStart),
        startWith(null), // Start with something, because the app doesn't fire this on appload, only on subsequent route changes
        tap(event => /* Place code to track NavigationStart here */ EmitterSubjectService.emitElementFullyRendered(true)),
      ).subscribe();
      return navStart$;
    });
    return navStartObservable;
  }
  // ------------------------------------------------------------------------

  public isElementRendered (router : Router, zone : NgZone) : boolean {
    // Once NavigationStart has fired, start checking regularly until there are
    // no more pending macrotasks in the zone

    // Have to run this outside of the zone, because the call to `interval`
    // is itself a macrotask. Running that `interval` inside the zone will
    // prevent the macrotasks from ever reaching zero. Running this outside
    // of Angular will not track this `interval` as a macrotask. IMPORTANT!!
    let isStateStable = false;
    let timer : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(zone) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(router)) {
      debugger;
      zone.runOutsideAngular(() => {
        // Check very regularly to see if the pending macrotasks have all cleared
        timer = interval(50)
          .pipe(
            startWith(0), // So that we don't initially wait
            // To prevent a memory leak on two closely times route changes, take until the next nav start
            // takeUntil(this.navigationStart$),
            takeUntil(this.detectNavigationStart(router)),
            // Turn the interval number into the current state of the zone
            map(() => !zone.hasPendingMacrotasks),
            // Don't emit until the zone state actually flips from `false` to `true`
            distinctUntilChanged(),
            // Filter out unstable event. Only emit once the state is stable again
            filter(stateStable => stateStable === true),
            // Complete the observable after it emits the first result
            take(1),
            tap(stateStable => {
              // FULLY RENDERED!!!!
              // Add code here to report Fully Rendered
              debugger;
              isStateStable = true;
              // EmitterSubjectService.emitElementFullyRendered(true);
            })
          ).subscribe();

      });

      // clearTimeout(timer);
    }
    return isStateStable;
  }
  // ------------------------------------------------------------------------
  public detectElementRendered2 (zone : NgZone) : Observable<any> {
    debugger;
    let isStableObservable = new Observable<any>(observer => {
      let stableSub : Subscription;
      let unstableSub : Subscription;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(zone)) {
        zone.runOutsideAngular(() => {
          stableSub = zone.onStable.subscribe(() => {
            NgZone.assertNotInAngularZone();
            if (!this.stable && !zone.hasPendingMacrotasks &&
              !zone.hasPendingMicrotasks) {
              this.stable = true;
              observer.next(true);
            }
          });
        });

        unstableSub = zone.onUnstable.subscribe(() => {
          NgZone.assertInAngularZone();
          if (this.stable) {
            this.stable = false;
            zone.runOutsideAngular(() => {
              observer.next(false);
            });
          }
        });
      }
      return () => {
        stableSub.unsubscribe();
        unstableSub.unsubscribe();
        debugger;
        isStableObservable;
      };
    });
    debugger;
    return isStableObservable;
  }
  /*
   * usage:
   * this.isStableObservable.pipe(debounceTime(1000)).subscribe(val => {
      console.log('completely stable');
     });
   */

  // -----------------------------------------------------------
  public nullPromise (data : any) : any {
    let timer = setTimeout(() => {
      // debugger;
      clearTimeout(timer);
      return data;
    }, 50);
  }
  // -----------------------------------------------------------
  public nullObservable () : Observable<any> {
    return new Observable<any>(observer => {
      observer.next(null);
      observer.complete();
    });
  }
  // ---------------------------------------------------------------
}

