import { FrequentlyUsedFunctionsServiceStatic } from "./../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";
import { Chat } from "./chat.model";
import { ChatMessage } from "./chatMessage.model";

export class ChatActivity {
  public chatActivityId : number = 0;
  public chatMessageId : number = 0;
  public contents : string = '';
  public date : any;
  public dateFormatted : any;
  public groupName : string = '';
  public isAdministrative : boolean = false;
  public isReceiver : boolean = false;
  public isSender : boolean = false;
  public messageBody: string = '';
  public note: string = '';
  public receiverAction : string = '';
  public receiverSITUserKey : string = '';
  public replyToId : number = 0;
  public senderAction : string = '';
  public senderSITUserKey : string = '';
  public sentPic : string = '';
  public timeLapsed: string = '';
  public timeLapsedPlaceholder: string = '';
  public chatMessage : ChatMessage = new ChatMessage();


  // -----------------------------------------------------
  constructor () {
  }
  // ----------------------------------------------------------------------------------
  public Copy (destination : ChatActivity, source : ChatActivity) : ChatActivity  {
    if (source != null && destination != null) {
      destination.contents = source.contents;
      destination.chatMessage = source.chatMessage;
      destination.chatMessageId = source.chatMessageId;
      destination.date = source.date;
      destination.isSender = source.isSender;
      destination.isReceiver = source.isReceiver;
      destination.timeLapsed = source.timeLapsed;
      destination.groupName = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(source.groupName) ? source.groupName : '';
      destination.isAdministrative = source.isAdministrative != null ? source.isAdministrative : false;
      destination.messageBody = source.messageBody;
      destination.note = source.note;
      destination.receiverAction = source.receiverAction;
      destination.receiverSITUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(source.receiverSITUserKey) ? source.receiverSITUserKey : '';
      destination.replyToId = source.replyToId != null ? source.replyToId : 0;
      destination.senderAction = source.senderAction;
      destination.senderSITUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(source.senderSITUserKey) ? source.senderSITUserKey : '';
      destination.sentPic = source.sentPic;
      return destination;
    }
    return source;
  }
  // ----------------------------------------------------------------------------------
  public CopyFromChat (source : Chat) : ChatActivity {
    var destination : ChatActivity = new ChatActivity();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(source)) {
      destination.chatMessageId = source.chatMessageId;
      destination.date = source.date;
      destination.isSender = source.isSender;
      destination.isReceiver = source.isReceiver;
      destination.timeLapsed = source.timeLapsed;
      destination.groupName = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(source.groupName) ? source.groupName : '';
      destination.isAdministrative = source.isAdministrative != null ? source.isAdministrative : false;
      destination.messageBody = source.messageBody;
      destination.receiverAction = source.receiverAction;
      destination.receiverSITUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(source.receiverSITUserKey) ? source.receiverSITUserKey : '';
     // destination.replyToId = source.replyToId != null ? source.replyToId : 0;
      destination.senderAction = source.senderAction;
      destination.senderSITUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(source.senderSITUserKey) ? source.senderSITUserKey : '';
      destination.sentPic = source.sentPic;
    }
    return destination;
  }
}
