import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { KvMemberActivityGlyph } from '../keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../keyValue/kvPhoto.model';
import { BasicPagerModel } from './basicPagerModel.model';

// --------------------------------------------------------
// Note:  There are two basic types of KV based pagers:
//        1) KvPhoto  2) KvGlyph
// --------------------------------------------------------

export class BasicPagerKvGlyphModel extends BasicPagerModel {
  public isPhotoMgmt = false;
 
  constructor () { super();}


  // ---------------------
  // kvPhotoArr vairables:
  // ---------------------
  public firstItem : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public lastItem : KvMemberActivityGlyph = new KvMemberActivityGlyph();  
  // ----------------------------------
  // the PagedItems variables on items:
  // Note: item (from modrel) === index (from view)
  // ----------------------------------
  
  public pagedKvGlyphItems : KvMemberActivityGlyph[] = [];
  public currentKvGlyphItem : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public endIKvGlyphtem : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public nextKvGlyphItem : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public previousKvGlyphItem : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public startKvGlyphItem : KvMemberActivityGlyph = new KvMemberActivityGlyph();

  copyToPagerPhotoMgmt (ppMgmt :  any) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ppMgmt)) {
      ppMgmt.pageSize = this.pageSize;
      ppMgmt.totalItems = this.totalItems;
      ppMgmt.totalPages = this.totalPages;

      // ---------------------
      // kvPhotoArr vairables:
      // ---------------------
      let kvPhotoArr : KvPhoto[] = [];
      //this.kvGlyphArr.forEach(e => {
      //  kvPhotoArr.push(e.kvPhoto);
      //});
      ppMgmt.kvPhotoArr = kvPhotoArr;

      ppMgmt.firstItemNo = this.startItemNo;
      ppMgmt.totalItems = this.totalItems;
      ppMgmt.firstItem = this.firstItem; //.kvPhoto;
      ppMgmt.lastItemNo = this.endItemNo;
      ppMgmt.lastItem = this.lastItem; //.kvPhoto;

      // ------------------
      // pages variables:
      // ------------------
      ppMgmt.pages = this.pages;
      ppMgmt.currentPage = this.currentPage;
      ppMgmt.firstPage = this.startPage;
      ppMgmt.lastPage = this.endPage;
      ppMgmt.nextPage = this.nextPage;
      ppMgmt.previousPage = this.previousPage;
      // ----------------------------------
      // the PagedItems variables on items:
      // Note: item (from modrel) === index (from view)
      // ----------------------------------
      kvPhotoArr = [];
      //this.pagedItems.forEach(e => {
      //  kvPhotoArr.push(e.kvPhoto);
      //});
      ppMgmt.pagedItems = kvPhotoArr;
      ppMgmt.kvPhotoArr = kvPhotoArr;
      ppMgmt.currentItemNo = this.currentItemNo;
      ppMgmt.currentItem = this.currentItem; //.kvPhoto;
      ppMgmt.endItemNo = this.endItemNo;
      ppMgmt.endItem = this.endItem; //.kvPhoto;
      ppMgmt.nextItemNo = this.nextItemNo;
      ppMgmt.nextItem = this.nextItem; //.kvPhoto;
      ppMgmt.previousItemNo = this.previousItemNo
      ppMgmt.previousItem = this.previousItem; //.kvPhoto;
      ppMgmt.startItemNo = this.startItemNo;
      ppMgmt.startItem = this.startItem; //.kvPhoto;
     
    }
    return ppMgmt;
  }
}
