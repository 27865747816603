import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../../services/staticServices/stringServiceStatic.service';
import { KvPhoto } from '../keyValue/kvPhoto.model';
import { Photo } from './photo.model';
var _ = require('lodash');

export class PhotoBn extends Photo {
  public action:any = '';
  public box: any;
  public nonce: any; 
  public distance: any = 0;
  public enctype : any = '';
  public fileBytes : any;
  public message : any = '';
  public photoBnId : any = 0;
  public value : any = '';
  // ---------------
  constructor ()
  {
    super();
  }
  // ---------------------------------------------
  public copy2 (a : PhotoBn) : void {
    super.copy(a);
    debugger;
    this.action = a.action;
    this.box = a.box;
    this.distance = a.distance;
    this.enctype = a.enctype;
    this.fileBytes = a.fileBytes;
    this.message = a.message;
    this.nonce = a.nonce;
    this.photoBnId = a.photoBnId;
    debugger;
  }
  // ---------------------------------------------
  public override copy (a : PhotoBn) : PhotoBn {
    // const that = new KvPhoto();
    this.action = a.action;
    this.approval = a.approval;
    this.box = a.box;
    this.contentType = a.contentType;
    this.cryptUserKey = a.cryptUserKey;
    this.date = a.date;
    this.description = a.description;
    this.distance = a.distance;
    this.enctype = a.enctype;
    this.file = a.file;
    this.fileBytes = a.fileBytes;
    this.formData = a.formData;
    this.id = a.id;
    this.image = a.image;
    this.imageBytes = a.imageBytes;
    this.imageString = a.imageString;
    this.isUnlocked = a.isUnlocked;
    this.isPrimary = a.isPrimary;
    this.itemNum = a.itemNum;
    this.latitude = a.latitude;
    this.location = a.location;
    this.longitude = a.longitude;
    this.message = a.message;
    this.nonce = a.nonce;
    this.path = a.path;
    this.photoId = a.photoId;
    this.photoBnId = a.photoBnId;
    this.pic = a.pic;
    this.privacy = a.privacy;
    this.profileId = a.profileId;
    this.signedInUserId = a.signedInUserId;
    this.sitUserId = a.sitUserId;
    this.sitUserKey = a.sitUserKey;
    this.sourceId = a.sourceId;
    this.sourceType = a.sourceType;
    this.title = a.title;
    this.url = a.url;
    this.value = a.value;
    return this;
  }
  // ---------------------------------------------
  public copySourceToDestination (pbn :PhotoBn, a : PhotoBn) : PhotoBn|any {
    // const that = new KvPhoto();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a)) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn)) {
        pbn = new PhotoBn();
      }
      pbn.action = a.action;
      pbn.approval = a.approval;
      pbn.box = a.box;
      pbn.contentType = a.contentType;
      pbn.cryptUserKey = a.cryptUserKey;
      pbn.date = a.date;
      pbn.description = a.description;
      pbn.distance = a.distance;
      pbn.enctype = a.enctype;
      pbn.file = a.file;
      pbn.fileBytes = a.fileBytes;
      pbn.formData = a.formData;
      pbn.id = a.id;
      pbn.image = a.image;
      pbn.imageBytes = a.imageBytes;
      pbn.imageString = a.imageString;
      pbn.isUnlocked = a.isUnlocked;
      pbn.isPrimary = a.isPrimary;
      pbn.itemNum = a.itemNum;
      pbn.latitude = a.latitude;
      pbn.location = a.location;
      pbn.longitude = a.longitude;
      pbn.message = a.message;
      pbn.nonce = a.nonce;
      pbn.path = a.path;
      pbn.photoId = a.photoId;
      pbn.photoBnId = a.photoBnId;
      pbn.pic = a.pic;
      pbn.privacy = a.privacy;
      pbn.profileId = a.profileId;
      pbn.signedInUserId = a.signedInUserId;
      pbn.sitUserId = a.sitUserId;
      pbn.sitUserKey = a.sitUserKey;
      pbn.sourceId = a.sourceId;
      pbn.sourceType = a.sourceType;
      pbn.title = a.title;
      pbn.url = a.url;
      pbn.value = a.value;
      return pbn;
    }
    else return null;
  }
  // ---------------------------------------------
  public clone (a : PhotoBn) : PhotoBn {
    let that = new PhotoBn();
    that.copy(a);
    return that;
  }
  // ---------------------------------------------
  public override copyIfExists (a : PhotoBn) : PhotoBn {
    if (a.action) {
      this.action = a.action;
    }
    if (a.approval) {
      this.approval = a.approval;
    }
    if (a.box) {
      this.box = a.box;
    }
    if (a.contentType) {
      this.contentType = a.contentType;
    }

    if (a.cryptUserKey) {
      this.cryptUserKey = a.cryptUserKey;
    }

    if (a.date) {
      this.date = a.date;
    }

    if (a.description) {
      this.description = a.description;
    }

    if (a.distance) {
      this.distance = a.distance;
    }

    if (a.enctype) {
      this.enctype = a.enctype;
    }

    if (a.file) {
      this.file = a.file;
    }
    if (a.fileBytes) {
      this.fileBytes = a.fileBytes;
    }
    if (a.formData) {
      this.formData = a.formData;
    }

    if (a.id > 0) {
      this.id = a.id;
    }

    if (a.image) {
      this.image = a.image;
    }

    if (a.imageString) {
      this.imageString = a.imageString;
    }

    if (a.imageBytes) {
      this.imageBytes = a.imageBytes;
    }

    if (a.isPrimary) {
      this.isPrimary = a.isPrimary;
    }

    if (a.isUnlocked) {
      this.isUnlocked = a.isUnlocked;
    }

    if (a.itemNum) {
      this.itemNum = a.itemNum;
    }

    if (a.latitude) {
      this.latitude = a.latitude;
    }

    if (a.location) {
      this.location = a.location;
    }

    if (a.longitude) {
      this.longitude = a.longitude;
    }

    if (a.message) {
      this.message = a.message;
    }

    if (a.nonce) {
      this.nonce = a.nonce;
    }

    if (a.path) {
      this.path = a.path;
    }

    if (a.photoId > 0) {
      this.photoId = a.photoId;
    }

    if (a.photoBnId > 0) {
      this.photoBnId = a.photoBnId;
    }

    if (a.pic) {
      this.pic = a.pic;
    }

    if (a.profileId > 0) {
      this.profileId = a.profileId;
    }

    if (a.privacy) {
      this.privacy = a.privacy;
    }

    if (a.signedInUserId > 0) {
      this.signedInUserId = a.signedInUserId;
    }

    if (a.sitUserId > 0) {
      this.sitUserId = a.sitUserId;
    }

    if (a.sitUserKey) {
      this.sitUserKey = a.sitUserKey;
    }

    if (a.sourceId > 0) {
      this.sourceId = a.sourceId;
    }

    if (a.sourceType) {
      this.sourceType = a.sourceType;
    }

    if (a.title) {
      this.title = a.title;
    }

    if (a.url) {
      this.url = a.url;
    }
    if (a.value) {
      this.value = a.value;
    }
    return this;
  }
  // ---------------------------------------------
  public override cloneIfExists (a : PhotoBn) : PhotoBn {
    const that = new PhotoBn();
    that.copyIfExists(a);
    return that;
  }
  // ---------------------------------------------
  public toCamelCase (a : any) : PhotoBn {
    let that = new PhotoBn();
    // let p : Photo = new Photo();
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a)) {
      // debugger;
      // Note: This scheme does not work!
      // ---------------------------------
      // p = p.cloneIfExists(a);
      // debugger;
      // that = that.cloneIfExists(p as KvPhoto);
      // ---------------------------------
      // debugger;
      that.action = a.Action; // !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.Action) ? a.Action : "none";
      that.approval = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.Approval) ? a.Approval : "none";
      that.box = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.Box) ? a.Box : [];
      that.contentType = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.ContentType) ? a.ContentType : "none";
      that.cryptUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.CryptUserKey) ? a.CryptUserKey : '';
      that.date = a.Date;
      that.description = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.Description) ? a.Description : '';
      that.distance = a.Distance;
      that.enctype = a.Enctype;
      that.file = (a.Files != null) ? a.Files : undefined;
      that.fileBytes = a.FileBytes;
      //that.formData = a.FormData;
      that.id = a.Id;
      that.image = a.Image;
      that.imageBytes = a.ImageBytes;
      that.imageString = a.ImageString;
      that.isUnlocked = a.IsUnlocked;
      that.isPrimary = a.IsPrimary;
      that.itemNum = a.ItemNum;
      that.latitude = a.Latitude;
      that.location = a.Location;
      that.longitude = a.Longitude;
      that.message = a.message;
      that.nonce = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.Nonce) ? a.Nonce : [];
      that.path = a.Path;
      that.photoId = a.PhotoId;
      that.photoBnId = a.PhotoBnId;
      that.pic = a.pic; // Note: the serverside uses the lower-case `pic`
      // debugger;
      that.privacy = a.Privacy;
      /// debugger;
      that.profileId = a.ProfileId;
      that.signedInUserId = a.SignedInUserId;
      that.sitUserId = a.SitUserId;
      that.sitUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.SitUserKey) ? a.SitUserKey : '';
      that.sourceType = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.SourceType) ? a.SourceType : '';
      that.sourceId = a.SourceId;
      that.title = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.Title) ? a.Title : '';
      that.url = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.Url) ? a.Url : '';
      that.value = a.value;
      // debugger;
      //that.glyphName = a.IsPrimary === true ? 'primary' : a.Privacy; // Note: important!! // to hold the glyphName for privacy
      //that.isPrivate = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.Privacy) && a.Privacy.toLowerCase().indexOf('private') !== -1 ? true : false;
      //that.key = a.PhotoBnId > 0 ? a.PhotoBnId.toString() : '';
      //that.value = a.Pic && a.Pic.length > 5 ? a.Pic
      //  : a.Image && a.Image.length > 5 ? a.Image
      //    : a.ImageString.length > 5 ? a.ImageString
      //      : that.pic;
    }
    // debugger;
    return that;
  }
  // ---------------------------------------------
  // Note: does not work, looses data 20231013
  public cloneToCamelCasedKvPhoto (pbn : PhotoBn) : KvPhoto {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn)) {
      // debugger;
      let a = this.toCamelCase(pbn);
      // debugger;
      // ---------------------------------
      return this.cloneToKvPhoto(a);
    }
  }
  // ---------------------------------------------
  public toKvPhoto (pbn : PhotoBn) : KvPhoto {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn)) {
      // debugger;
      // ---------------------------------
      return this.cloneToKvPhoto(pbn);
    }
  }
  // ---------------------------------------------
  public cloneToPhoto (to : Photo, that : PhotoBn) : Photo {    
    to.approval = that.approval;
    to.contentType = that.contentType;
    to.cryptUserKey = that.cryptUserKey;
    to.date = that.date;
    to.description = that.description;
    to.file = that.file;
    to.imageBytes = that.fileBytes.length > 0 ? that.fileBytes : that.imageBytes;
    to.formData = that.formData;
    to.id = that.id;
    to.image = that.image;
    to.imageString = that.imageString;
    to.isPrimary = that.isPrimary;
    to.isSelected = that.isSelected;
    to.isUnlocked = that.isUnlocked;
    to.latitude = that.latitude;
    to.location = that.location;
    to.longitude = that.longitude;
    to.itemNum = that.itemNum;
    to.path = that.path;
    to.pic = that.pic;
    to.photoId = that.photoId;
    to.privacy = that.privacy;
    to.profileId = that.profileId;
    to.sitUserId = that.sitUserId;
    to.sitUserKey = that.sitUserKey;
    to.signedInUserId = that.signedInUserId;
    to.sourceId = that.sourceId;
    to.sourceType = that.sourceType;
    to.title = that.title;
    to.url = that.url;
    return to;
  }
  // ---------------------------------------------
  public cloneToKvPhoto (pbn : PhotoBn) : KvPhoto {
    let that = new KvPhoto();
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn)) {
      // debugger;
      let a = pbn;
      // ---------------------------------
      //debugger;
      that.action = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.action) ? a.action : "none";
      that.approval = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.approval) ? a.approval : "none";
      that.contentType = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.contentType) ? a.contentType : "none";
      that.cryptUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.cryptUserKey) ? a.cryptUserKey : '';
      that.date = a.date;
      that.description = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.description) ? a.description : '';
      // that.distance = a.distance;
      // that.enctype = a.enctype;
      that.file = a.file;
      that.formData = a.formData;
      that.id = a.id;
      that.image = a.image;
      that.imageBytes = a.imageBytes;
      that.imageString = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.imageString) ? a.imageString : '';
      that.isUnlocked = a.isUnlocked;
      that.isPrimary = a.isPrimary;
      that.itemNum = a.itemNum;
      that.latitude = a.latitude;
      that.location = a.location;
      that.longitude = a.longitude;
      // that.message = a.message;
      // that.nonce = a.nonce;
      that.path = a.path;
      that.photoId = a.photoId;
      that.photoBnId = a.photoBnId;
      that.pic = a.pic;
      // debugger;
      that.privacy = a.privacy;
      /// debugger;
      that.profileId = a.profileId;
      that.signedInUserId = a.signedInUserId;
      that.sitUserId = a.sitUserId;
      that.sitUserKey = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.sitUserKey) ? a.sitUserKey : '';
      that.sourceType = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.sourceType) ? a.sourceType : '';
      that.sourceId = a.sourceId;
      that.title = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.title) ? a.title : '';
      that.url = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.url) ? a.url : '';
      // debugger;
      that.glyphName = a.isPrimary === true ? 'primary' : a.privacy; // Note: important!! // to hold the glyphName for privacy
      that.isPrivate = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.privacy) && a.privacy.toLowerCase().indexOf('private') !== -1 ? true : false;
      that.key = a.photoBnId > 0 ? a.photoBnId.toString() : '';
      that.value = a.pic && a.pic.length > 5 ? a.pic
        : a.image && a.image.length > 5 ? a.image
          : !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a.imageString) && a.imageString.length > 5 ? a.imageString
            : that.pic;
    }
    // debugger;
    return that;
  }
  // ---------------------------------------------
}
