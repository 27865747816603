<app-message-board *ngIf="messageModel?.message?.length > 0 || isMessageBoardVisible" [messageModel]="messageModel"></app-message-board>

<app-avatar [loginSuccess]="loginSuccess" style="float:right;z-index:13;"></app-avatar>

<!--==================================================================================-->
<app-logo *ngIf="!isSidebarOpen"  class="flex-item"></app-logo>
<!--<div class="flex-container" style="top: 0;left:10%;height: auto; width: 80%;">
</div>-->
<!--==================================================================================-->
<!--position: static === no-z-index-effect | *ngIf="isVsScroll"-->
<!--=============================Begin OF CONTENT SECTION========================================-->
<!--

(pan)="onPan($event)"
     (panstart)="onPanStart($event)"
     (panmove)="onPanMove($event)"
     (panend)="onPanEnd($event)"
     (pancancel)="onPanCancel($event)"
     (panleft)="onPanLeft($event)"
     (panright)="onPanRight($event)"
     (panup)="onPanUp($event)"
     (pandown)="onPanDown($event)"

  (tap)="onTap($event)"
       (press)="onPress($event)"
  -->
<!--NOTE:!IMPORTANT #wrapper and #page-content-wrapper's 'margin and padding' are NOT to be altered here. They are set in styles.scss and they are used for menu-toggling-->
<!--[ngStyle]='min-height': 'isMobilevar ? 55rem : 40rem'-->
<div id="wrapper" role="main"
     [ngClass]="ref?.click ? 'toggled' : ''"
     [ngStyle]="{'z-index':ref?.click ? '11' : '10', 'padding-bottom':!isMobilevar ? '0.5rem' : '1rem', 'margin-bottom':!isMobilevar ? '0.5rem' : '1rem'}"
     style="top: 0rem; bottom: 0; left: 0; right: 0; height: 100%; width: 100%; overflow: auto; border: 1.75px solid transparent; background: transparent; opacity: 1;">


  <!--===============================Begin of Hamburger Section==============================-->
  <!-- #ref="appSidebar"
        Attention!: the Hamburger (=) Menu on the left-hand-side of-the page is actually placed here
        for the reason that having it placed here in the content section achieves the desired
        look and functionalities of the heading and page, instead of placing it at thge heading section.
  -->
  <!--Note: class='hamburger' makes an element sticky-->
  <button app-sidebar #ref="appSidebar" (click)="this.isSidebarOpen = !this.isSidebarOpen;" class="hamburger is-closed increase row" type="button" [ngStyle]="{'padding-left': 'isMobilevar ? -1.5rem : -5px', 'padding-right':'', 'margin-top':'10px', 'z-index':'14'}">
    <span class="hamb-top"></span>
    <span class="hamb-middle"></span>
    <span class="hamb-bottom"></span>
    <span class="hamb-menu" [ngStyle]="{'z-index':'14'}">menu</span>
  </button>
  <!--=================================End of Hamburger Section===============================-->

  <app-nav-sidebar [loginSuccess]="loginSuccess" [signedInUserId]="signedInUserId" style="z-index:14;width:0;height:0rem;padding-left:-220px;border:1.5px solid transparent;"></app-nav-sidebar>

  <!--============================Begin of Main Content of the page============================-->
  <div id="page-content-wrapper"
       #appElementRef
       class="flex-text-center"
       style="padding:0.5rem 0 0rem 0;top:5rem; bottom: 0; left: 0; right: 0; position: absolute; height:100%;width:100%; z-index: 8; justify-content: flex-start; background-color: rgb(255, 255, 255, 0); opacity: 1; border: 1.75px solid transparent; overflow: visible;">

    <!--Empty div to create space between Header and Main-Contents-->
    <div id="allScrollToTopId" style="top: 0; height: 100px; width: 100%; position: fixed; background: transparent; border-bottom: 1.5px solid transparent;"> </div>
    <!--=========================================================================================-->
    <router-outlet id="routerOutletId" style="height:2px; width: 100%; position: relative;"></router-outlet>
    <!--==============================Router-outlet=======================================-->

  </div><!-- /#page-content-wrapper -->
  <!--=============================END OF CONTENT SECTION==========================================-->

  <app-spinner *ngIf="spinnerModel?.isSpinner? true : false" [spinnerModel]="spinnerModel" style="z-index: 13"></app-spinner>

  <!--==============================Arro navigation=======================================-->
  <!--<app-log-reg-buttons-and-nav-arrows [loginSuccess]="loginSuccess"></app-log-reg-buttons-and-nav-arrows>-->
</div><!-- /#wrapper -->
