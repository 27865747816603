import { CommunicationActivity } from "../communication/communicationActivity.model";

export class MemberActivity {
  public action = '';
  public actionLabel = '';
  public date = '';
  public dateStr = '';
  public jsDateForCs = '';
  public doeeCryptUserKey = '';
  public doerCryptUserKey = '';
  public doeeSitUserKey = '';
  public doerSitUserKey = '';
  public doeeSitUserId? = 0;
  public doerSitUserId? = 0;
  public expiryDate = '';
  public id? = 0;
  public isBlocked = false;
  public isBuddied = false;
  public isFriend = false;
  public isLiked = false;
  public isStarred = false;
  public isTracked = false;
  public isUnlocked = false;
  public isWinked = false;
  public index? = 0;
  public key = '';
  public memberActivityId? = 0;
  public memberLockingActivityId? = 0;
  public memberTrackWinkActivityId? = 0;
  public message: any;
  public model: any;
  public modelName = '';
  public photoBnId? = 0;
  public profileId? = 0;
  public profileTile : any; // ProfileTile model
  public receiverSitUserId? = 0;
  public senderSitUserId? = 0;
  public signedInUserId? = 0;
  public value: any;
  constructor() {
    this.jsDateForCs = this.jsDateToCsDate(this.date);
    this.dateStr = new Date(this.date).toLocaleTimeString();
    // alert ('toLocalTimeString: ' + this.dateStr);
  }
  // ref:https:// stackoverflow.com/questions/6702705/how-to-convert-javascript-datetime-to-c-sharp-datetime
  /*
   * C# code to convert: DateTime.ParseExact(YourString, 'dd/MM/yyyy HH:mm:ss', CultureInfo.InvariantCulture);
   */

  jsDateToCsDate(inDate: string): string {
    if (inDate) {
      const date = new Date(inDate);
      const day = date.getDate(); // yields date
      const month = date.getMonth() + 1; // yields month (add one as '.getMonth()' is zero indexed)
      const year = date.getFullYear(); // yields year
      const hour = date.getHours(); // yields hours
      const minute = date.getMinutes(); // yields minutes
      const second = date.getSeconds(); // yields seconds
      const ampm = date.toLocaleTimeString();
      /*
       * ampm = dateStringService.convertToLocal(date); // outputs: mm-dd-yyyy hh:mm:ss AM/PM
       * alert ('toLocalTimeString: ' + ampm);
       * After this construct a string with the above results as below
       */
      const time = day + '/' + month + '/' + year + ' ' + hour + ':' + minute + ':' + second;
      // alert ('jsDate2CsDate: ' + time);

      return (this.jsDateForCs = time);
    }
    return '';
  }

  public copy2CommunicationActivity (mAct : MemberActivity) : CommunicationActivity {
    let cAct = new CommunicationActivity();
    cAct.action = mAct.action;
    cAct.actionLabel = mAct.actionLabel;
    cAct.date = mAct.date;
    cAct.dateStr = mAct.dateStr;
    cAct.dateFormatted = mAct.dateStr;
    cAct.receiverSitUserKey = mAct.doeeSitUserKey;
    cAct.receiverSitUserId = mAct.doeeSitUserId;
    cAct.senderSitUserId = mAct.doerSitUserId;
    cAct.senderSitUserKey = mAct.doerSitUserKey;

    cAct.expiryDate = mAct.expiryDate;
    cAct.id = mAct.id.toString();
    cAct.isBlocked = mAct.isBlocked;
    cAct.isBuddied = mAct.isBuddied;
    cAct.isFriend = mAct.isFriend;
    cAct.isLiked = mAct.isLiked;
    cAct.isStarred = mAct.isStarred;
    cAct.isUnlocked = mAct.isUnlocked;
    cAct.isWinked = mAct.isWinked;

    cAct.index = mAct.index;
    cAct.photoBnId = mAct.photoBnId;
    cAct.profileTile = mAct.profileTile;
    cAct.memberActivity = mAct;
    return cAct;
  } 
}
