'use-strict'
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
    Component, Inject, Input, OnDestroy, OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { Photo } from '../../../models/common/photo.model';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { MemberActivity } from '../../../models/member/memberActivity.model';
import { PagerMemberActivity } from '../../../models/pagination/pagerMemberActivity.model';
import { GlyphiconMenuService } from '../../../services/GlyphiconService/glypgiconMenuService.service';
import { GlyphiconServiceService } from '../../../services/GlyphiconService/glyphiconServiceService.service';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { SitUserService } from '../../../services/commonServiceService/sitUserService.service';
import { ActivityService } from '../../../services/memberServiceService/activityService.service';
import { MemberServiceService } from '../../../services/memberServiceService/memberServiceService.service';
import { PagerServiceService } from '../../../services/pagerServiceService/pagerServiceService.service';
import { PagerSupportService } from '../../../services/pagerServiceService/pagerSupportService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { BreadcrumServiceStatic } from '../../../services/staticServices/breadcrumServiceStatic.service';
import { CopyServiceStatic } from '../../../services/staticServices/commonStaticServices/copyServiceStatic.service';
import { DateStringServiceStatic } from '../../../services/staticServices/commonStaticServices/dateStringServiceStatic.service';
import { ModuleComponentLoaderServiceStatic } from '../../../services/staticServices/commonStaticServices/moduleComponentLoaderServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ArraySupportServiceStatic } from '../../../services/staticServices/arraySupportServiceStatic.service';
import { CommunicationActivity } from '../../../models/communication/communicationActivity.model';
import { StringServiceStatic } from '../../../services/staticServices/stringServiceStatic.service';

// -------------------------------------------------------------------------------------------------------
// Note : pagerVerticle glyphicon-menu-system serve navigation/actions for two scenarios:
//     1.  memberView path during viewing any member.
//           Under this option the all but signedInMember can:
//         1.1 Select  memberActicities which are
//           {block, dislike, like, lock, star, track, unblock, unlock, unstar}
//         1.2 Can start a chat conversation with the viewee or
//         1.3 Send email notification to the viewee.
//         1.4 Send notification to the viewee.
//     2.  myPhoto path when signedInUser chooses to add/update personal photos.
//           Under this option the signedInUser can:
//           2.1 Take new pics or upload new pics
//           2.2 Change the privacy of any pic
//           2.3 Make current PrimaryPic a private pic in whihc case it will not longer be a primary pic
//           2.4 Make any pic the PrimaryPic. PrimaryPic is always public.
// -------------------------------------------------------------------------------------------------------

@Component({
  selector : 'app-pager-vertical-glyph-for-view-member',
  templateUrl: './pagerVerticalGlyphForViewMember.component.html',
  styleUrls: ['./pagerVerticalGlyphForViewMember.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush // default
})
export class PagerVerticalGlyphForViewMemberComponent implements OnInit, OnDestroy, AfterViewInit
{
  public bias! : BackgroundImageAnimationService;
  public glyphiconMenuService!: GlyphiconMenuService;
  public activityService! : ActivityService;
  public pagerSupportService! : PagerSupportService;
  public redirectionService! : RedirectionService;
  public sitUserService!: SitUserService;
  // -----------------------------------------------
  public breadcrumRing : ModuleComponentLoader[] = [];
  public commActivity : CommunicationActivity = new CommunicationActivity();
  public currentKvPhoto: KvPhoto = new KvPhoto();
  public currentPage : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  public currentPhoto : Photo = new Photo();
  public displayMenuCount = 0;
  public doeeSitUser : SitUser = new SitUser();
  public doerSitUser : SitUser = new SitUser();
  @Input() kvGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  @Input() pagerVMemActGlyphForViewMember : PagerMemberActivity = new PagerMemberActivity(); // for various memeberActivities such as like/dislike/friend/unfriend....etc.
  public pagerBrdCrm: PagerBreadcrum = new PagerBreadcrum();
  public emitterDestroyed$: Subject<boolean> = new Subject();
  // ----------------------------------------------------------
  public isCurrentlyBlocked = false;
  public isCurrentlyUnlocked = false;
  public isMobilevar = false;
  public isPhotoMgmt = false;
  public isViewMember = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public mcLoader: ModuleComponentLoader = new ModuleComponentLoader();
  private opened  = false;
  public returnUrl = '';
  public signedInUserId = 0;
  @Input() sitUser: SitUser = new SitUser();
  // ----------------------------------------------------------
  constructor (
    private router : Router,
    private cdr : ChangeDetectorRef,
    private commonServiceService : CommonServiceService,
    private glyphiconServiceService : GlyphiconServiceService,
    private memberServiceService : MemberServiceService,
    private pagerServiceService : PagerServiceService,
    private rendererServiceService : RendererServiceService,
    @Inject(Document) document : any )
  {
    if ( this.commonServiceService )
    {
      this.redirectionService = this.commonServiceService.getRedirectionService();
      this.sitUserService = this.commonServiceService.getSitUserService() as SitUserService;
    }

    if ( this.memberServiceService )
    {
      this.activityService = this.memberServiceService.getActivityService();
    }
    this.glyphiconMenuService = this.glyphiconServiceService.getGlyphiconMenuService() as GlyphiconMenuService;
    if ( this.pagerServiceService )
    {
     
      this.pagerSupportService = this.pagerServiceService.getPagerSupportService();
    }

    if (this.rendererServiceService) {
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
    }
    this.isMobilevar = EmitterSubjectService.getIsMobile() as boolean;
    // ---------------------------------------------------
    this.isViewMember = EmitterSubjectService.getIsViewMember();
    // debugger;
    // ----------------------------------------------------------
    // Note:  before creating glyphs & collecting the actedGlyphNames,
    //        clear the `glyphiconMenuService.actedGlyphNames` array
    this.glyphiconMenuService.actedGlyphNames = [];
  }
  // ----------------------------------------------------------
  ngOnInit () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.signedInUserId = this.loginSuccess.signedInUserId;

    // debugger;
    this.breadcrumRing = BreadcrumServiceStatic.getRing();
    this.pagerBrdCrm = BreadcrumServiceStatic.getPagerBreadcrum();

    // debugger;
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.signedInUserId = this.loginSuccess.signedInUserId;

    // if provided KvGlyphArr is not empty, make it unique:
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvGlyphArrForViewMember) && this.kvGlyphArrForViewMember.length > 0) {
      // debugger;
      this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(this.kvGlyphArrForViewMember);
    }

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
    }
    /*this.pagerBrdCrm = ( this.emitterService as EmitterService  ).getPagerBreadcrum();*/

    // if pagerVMemActGlyphForViewMember is not provided by the parent component, this should save the day:
    // ----------------------------------------------------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvGlyphArrForViewMember) && this.kvGlyphArrForViewMember.length > 0) {
      this.pagerVMemActGlyphForViewMember = this.executeViewMemberGlyphiconMenuTasks(this.kvGlyphArrForViewMember);

      // this.executeGlyphMenuArr(this.kvGlyphArrForViewMember);
    }
    //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyphForViewMember)
    //  || this.pagerVMemActGlyphForViewMember.pageSize === 0 || this.pagerVMemActGlyphForViewMember.pagedItems.length === 0) {
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvGlyphArrForViewMember) && this.kvGlyphArrForViewMember.length > 0) {
    //    this.pagerVMemActGlyphForViewMember = this.executeViewMemberGlyphiconMenuTasks(this.kvGlyphArrForViewMember);

    //    this.executeGlyphMenuArr(this.kvGlyphArrForViewMember);
    //  }
    //}
    // ==========================================================
    //  Begin of DisplayMemberViewGlyphicons:
    //  Note: there is a component called `ActedGlyphsComponent` that
    //        is also part of the DisplayMemberViewGlyphicons.
    // ==========================================================

    // ----------------------------------------------------------
    // Create Star, Friend, Like and Buddy glyphicons:
    // ----------------------------------------------------------
    // debugger;
    if (!StringServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
      this.glyphiconMenuService.createMemberViewGlyphiconArray(this.sitUser.sitUserId).subscribe(data => {
        // debugger;
        this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(data);

        this.glyphiconMenuService.actedUnlockAndBlockGlyphNames = ArraySupportServiceStatic.makeArrayOfUniqElements(this.glyphiconMenuService.actedUnlockAndBlockGlyphNames);
        var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.glyphiconMenuService.actedUnlockAndBlockGlyphNames, 'Unlock');
        // debugger;
        if (index > -1) {
          this.isCurrentlyUnlocked = true;
        }
        else {
          this.isCurrentlyUnlocked = false;
        }
        // debugger;
        index = -1;
        index = ArraySupportServiceStatic.getIndexOfStringInArray(this.glyphiconMenuService.actedUnlockAndBlockGlyphNames, 'Block');
        // debugger;
        if (index > -1) {
          this.isCurrentlyBlocked = true;
        }

        this.ngAfterViewInit();
      })
    }
    
    // ----------------------------------------------------------
    EmitterSubjectService.actedUnlockAndBlockGlyphNamesEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.glyphiconMenuService.actedUnlockAndBlockGlyphNames)
          && this.glyphiconMenuService.actedUnlockAndBlockGlyphNames.length > 0) {

          this.glyphiconMenuService.actedUnlockAndBlockGlyphNames = ArraySupportServiceStatic.makeArrayOfUniqElements(this.glyphiconMenuService.actedUnlockAndBlockGlyphNames);

          var index = ArraySupportServiceStatic.getIndexOfStringInArray(this.glyphiconMenuService.actedUnlockAndBlockGlyphNames, 'Unlock');
          // debugger;
          if (index > -1) {
            this.isCurrentlyUnlocked = true;
          }
          // debugger;
          index = -1;
          index = ArraySupportServiceStatic.getIndexOfStringInArray(this.glyphiconMenuService.actedUnlockAndBlockGlyphNames, 'Block');
          // debugger;
          if (index > -1) {
            this.isCurrentlyBlocked = true;
          }
          this.ngAfterViewInit();
        }
        // debugger;
      });
    // ==========================================================
    // End of DisplayMemberViewGlyphicons:
    // ==========================================================
    
    // ----------------------------------------------------------
    EmitterSubjectService.loginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        this.loginSuccess = result;
        this.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
      });
    // ---------------------------------------------------
    // Note : The View does not require to programatically draw the images, just setting the glyph/image name will suffice
    // this.executeDefaultGlyphMenu();
    // ---------------------------------------------------
    // Note : to achieve this simple objective of clearing all glyphicons from the dynamyc-graphicon-menu,
    //       it requires these following to be complied:
    //       1. have the ChangeDetection run in here at the PagerVerticalComponent.
    //       2. have the ChangeDetection run at the AppComponent.ts which the PagerVerticalComponent.ts is the child of,
    //       3. have the component which wants to clear the glyphicon menu such as the LandingPageComponent.ts,
    //       emit a message to crear the glyphicon menu.
    // ---------------------------------------------------
    EmitterSubjectService.clearGlyphiconMenuEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(data => {
        // debugger;
        this.executeClearGlyphAction();
      });    
    // -------------------------------------------------------------------------
    //EmitterSubjectService.isViewMemberEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //    .subscribe(result => {
    //      // debugger;
    //      this.isViewMember = result;
    //      if (this.isViewMember) {
    //        this.sitUser = EmitterSubjectService.getSitUserModel();
    //        this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0
    //          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.sitUser.sitUserId !== this.sitUser.sitUserId) {

    //          // This method also check if the sitUserId === signedInUserId, and if true, displays signedInUser's PrimaryGlyphsMenu:
    //          this.kvGlyphArrForViewMember = this.glyphiconMenuService.displayViewMemberGlyphMenu(this.sitUser.sitUserId);
    //          this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(this.kvGlyphArrForViewMember);
    //        }
    //        else {
    //          this.kvGlyphArrForViewMember = [];
    //        }
    //      }
    //    });
    // ---------------------------------------------------
    EmitterSubjectService.currentMemActGlyphForViewMemberEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( result =>
      {
        // debugger;
        let sitUser : SitUser = EmitterSubjectService.getSitUserModel();
        this.currentPage = EmitterSubjectService.getCurrentPage();

        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentPage)) {
          this.currentPage = new KvMemberActivityGlyph();
        }

        if (typeof result === 'number' && result > 0) {
          // debugger;
          // this.currentPage.pageNum = result;
          this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setKvGlyphPager(result);
          this.kvGlyphArrForViewMember = this.pagerVMemActGlyphForViewMember.kvMemActGlyphArrForViewMember;
          this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(this.kvGlyphArrForViewMember);
        }
        else if(result instanceof KvMemberActivityGlyph){
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) && result.sitUserId > 0) {
            this.kvGlyphArrForViewMember = this.glyphiconMenuService.displayViewMemberGlyphMenu(result.sitUserId);
            this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(this.kvGlyphArrForViewMember);
            // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(result.sitUserId); 
          }
        }
        this.ngAfterViewInit();
      });
    // -------------------------------------------------------------------------
    EmitterSubjectService.runNgAfterViewInitEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
        .subscribe(result => {
          // debugger;
          this.ngAfterViewInit();
        });
    // -------------------------------------------------------------
    // EmitterSubjectService.emitKvMemberActivityGlyph(kvg);
    EmitterSubjectService.kvMemberActivityGlyphEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.currentPage = result as KvMemberActivityGlyph;
        this.setGlyphAction(this.currentPage.pageNum, this.currentPage);
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.sitUserEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        // debugger;
        this.sitUser = result as SitUser;
        if (!StringServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
          EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.sitUser.sitUserId);
        }
        this.ngAfterViewInit();
    } );
    // ------------------------------------------------------------
    // ===========================================================================
    //  Emitter for 'app-pager-vertical-glyph-for-view-member'
    // ===========================================================================
    //EmitterSubjectService.displayViewMemberGlyphiconMenuEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe(data => {
    //     // debugger;

    //    this.sitUser = EmitterSubjectService.getSitUserModel();

    //    this.isViewMember = EmitterSubjectService.getIsViewMember();
       
    //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0) {
    //      // debugger;
    //      this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(data);
    //      this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvGlyphArrForViewMember, 1);
    //      this.ngAfterViewInit();
    //    }
    //  });
   
  // ---------------------------------------------------------------------------------
    EmitterSubjectService.displayViewMemberGlyphiconMenuForSitUserEmitter
      .pipe (takeUntil(this.emitterDestroyed$))
      .subscribe(sitId => {
         // debugger;

        this.sitUser = EmitterSubjectService.getSitUserModel();

        this.isViewMember = EmitterSubjectService.getIsViewMember();

        if (sitId > 0) {
          // debugger;
          this.sitUser.sitUserId = sitId;
          this.glyphiconMenuService.createMemberViewGlyphiconArray(sitId).subscribe(data => {
            this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(data);
            this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvGlyphArrForViewMember, 1);
            // debugger;
            this.ngAfterViewInit();
          })
          //// this.kvGlyphArrForViewMember = data;
         
        }
      });
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit(): void {
    // this.pagerV = this.getPagerVForCurrentPage();
    this.cdr.detectChanges();
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
    //       (2) https:// www.concretepage.com/angular-2/angular-4-renderer2-example
    //       (3) https:// stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
    //       (4) https:// stackblitz.com/edit/timer-with-pdestroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.executeClearGlyphAction();
  }
  // ---------------------------------------------------------------------------------
  // This method will update the status of most recently acted upon glyphicon menu
  // ---------------------------------------------------------------------------------
  determineResultOfGlyphAction (mAct: CommunicationActivity) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mAct ) && mAct.receiverSITUserId > 0 && mAct.senderSITUserId > 0 )
    {     
      let index = 0;
      // Note:  the order of the above code and the below code is very important for the
      //        Member-Glyphicon-Menu to function. The above will read the respective dictionary
      // ---------------------------------------------------------------------------------------
      for (var e of this.kvGlyphArrForViewMember)
      {
        // debugger;
        if ( e.senderSitUserId === mAct.senderSITUserId
          && e.receiverSitUserId === mAct.receiverSITUserId
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e.key )
          && e.key.toLowerCase().indexOf( mAct.memberActivity.toLowerCase() ) !== -1 )
        {
          index = ArraySupportServiceStatic.getIndexOfKvGlyphInArray(this.kvGlyphArrForViewMember, mAct);// do nothing
          // debugger;
          if (index > -1) {
            // debugger;
            var removedGlyph = this.kvGlyphArrForViewMember.splice(index, 1);
            // debugger;
            EmitterSubjectService.emitActedGlyph(removedGlyph); // important!
          }
          switch ( e.action.toLowerCase() )
          {
            case 'friend': case 'unfriend':
              // debugger;
              e.isFriend = mAct.isFriend;
              
              break;
            case 'like': case 'dislike':
              // debugger;
              e.isLiked = mAct.isLiked;
              break;
            case 'star':
              // debugger;
              e.isStarred = mAct.isStarred;
              break;
            case 'wink':
              e.isWinked = mAct.isWinked;
              break;
          }
        }
      }
    }
  }
 // ---------------------------------------------------------------------------------
  executeClearGlyphAction(): any {
    this.kvGlyphArrForViewMember = [];
    this.glyphiconMenuService.setGlyphs(this.kvGlyphArrForViewMember );
    this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setKvGlyphPager( 1 );
    this.pagerVMemActGlyphForViewMember.isPhotoMgmt = false;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyphForViewMember)) {
      this.pagerVMemActGlyphForViewMember.kvGlyphArr = this.pagerVMemActGlyphForViewMember.pagedItems = [];
      // debugger;
    }
    this.ngAfterViewInit();
    // debugger;
    return true;
  }
 // ---------------------------------------------------------------------------------
  executeGlyphMenuArr(kvAnyArr: KvMemberActivityGlyph[]): any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAnyArr) && kvAnyArr.length > 0) {
      this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(kvAnyArr);
      // debugger;
      // this.glyphiconMenuService.setGlyphs(this.kvGlyphArrForViewMember );
      // debugger;
      this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvGlyphArrForViewMember, 1);
      if (this.kvGlyphArrForViewMember.length > 10 ) { this.glyphiconMenuService.rotateArrowGlyphicons(); }
      // debugger;
      return true;
    }
    return false;
  }
  // ---------------------------------------------------------------------------------
  // TODO:This is most likely not correct. 
  executeViewMemberGlyphiconMenuTasks (data: KvMemberActivityGlyph[]): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0) {
      this.kvGlyphArrForViewMember = this.getUniqueKvGlyphArrForViewMember(data);
      // Note : The View does not require to programatically draw the images,
      //        just setting the glyph/image name will suffice
      this.executeGlyphMenuArr(this.kvGlyphArrForViewMember);
      // debugger;
      this.currentKvPhoto = EmitterSubjectService.getCurrentKvPhoto();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto)) {
        if (this.currentKvPhoto.pageNum <= 0) {
          if (this.currentKvPhoto.index >= 0) {
            this.currentKvPhoto.pageNum = this.currentKvPhoto.index + 1;
          }
          else if (this.currentKvPhoto.itemNum >= 0) {
            this.currentKvPhoto.pageNum = this.currentKvPhoto.itemNum + 1;
          }
        }
        this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvGlyphArrForViewMember, this.currentKvPhoto.pageNum);
      }
      else {
        this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setPagerWithKvGlyphs(this.kvGlyphArrForViewMember, 1);
        // or
        // this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setKvGlyphPager(1);
      }

      // debugger;
      this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemActGlyphForViewMember.pagedKvGlyphItems, this.pagerVMemActGlyphForViewMember.currentKvGlyphItem, "pagerVGlyphMgmt-");

      
      this.ngAfterViewInit();
      return this.pagerVMemActGlyphForViewMember;
    }
    else return null;
  }
  // ---------------------------------------------------------------------------------
  isKvGlyphFoundInArr (arr : KvMemberActivityGlyph[], kvg : KvMemberActivityGlyph) : boolean {
    var isFound = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && arr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvg)) {      
      for (var i = 0; i < arr.length; i++){
        // debugger;
        if (arr[ i ].key.toLocaleLowerCase().indexOf(kvg.key.toLocaleLowerCase()) !== -1) {
          isFound = true;
          // debugger;
          break;
        }
      }
    }
    return isFound;
  }
  // ---------------------------------------------------------------------------------
  getUniqueKvGlyphArrForViewMember (arr : KvMemberActivityGlyph[]) : KvMemberActivityGlyph[] {
    var outArr = []; 
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && arr.length > 0) {      
      for (var i = 0; i < arr.length; i++) {
        if (!this.isKvGlyphFoundInArr(outArr, arr[ i ])) {
          // debugger;
          outArr.push(arr[ i ]);
        }
      }
    }
    // debugger;
    return outArr;
  }
  // ---------------------------------------------------------------------------------
  getPagerVForDefaultPage () : any
  {
    this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setKvGlyphPager( 1 );  // default-case

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemActGlyphForViewMember)) {
      this.pagerVMemActGlyphForViewMember.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      // debugger;
    }
    this.ngAfterViewInit();
    return this.pagerVMemActGlyphForViewMember;
  }
  // ---------------------------------------------------------------------------------
  stateName(): any {
    return this.opened ? 'show'  : 'hide';
  }
  // ---------------------------------------------------------------------------------
  setChatAction () : void {
    this.returnUrl = window.location.pathname + window.location.search;
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
      this.mcLoader.id = this.sitUser.sitUserId;
      // debugger;
    }
    else {
      // debugger;
      var tpTile = EmitterSubjectService.getSelectedProfileTile();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile)) {
        this.mcLoader.id = tpTile.sitUserId;
        // debugger;
      }
    }

    this.mcLoader.router = this.router;

    this.mcLoader.componentName = 'chathistory';
    this.mcLoader.moduleName = 'chatm';
    // debugger;
    EmitterSubjectService.setMCLoader(this.mcLoader);

    this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
      this.pagerBrdCrm = data;
    });
  }
  // ---------------------------------------------------------------------------------
  setEmailAction () : void {
    this.returnUrl = window.location.pathname + window.location.search;
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
      this.mcLoader.id = this.sitUser.sitUserId;
      // debugger;
    }
    else {
      // debugger;
      var tpTile = EmitterSubjectService.getSelectedProfileTile();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile)) {
        this.mcLoader.id = tpTile.sitUserId;
        // debugger;
      }
    }

    this.mcLoader.router = this.router;

    this.mcLoader.componentName = 'composeEmail';
    this.mcLoader.moduleName = 'communicationm';
    // debugger;
    EmitterSubjectService.setMCLoader(this.mcLoader);

    this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
      this.pagerBrdCrm = data;
    });
  }
  // ---------------------------------------------------------------------------------
  setUnlockAction (action: string) : void {
    // debugger;
    this.returnUrl = window.location.pathname + window.location.search;
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
      this.mcLoader.id = this.sitUser.sitUserId;
      // debugger;
    }
    else {
      // debugger;
      var tpTile = EmitterSubjectService.getSelectedProfileTile();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile)) {
        this.mcLoader.id = tpTile.sitUserId;
        // debugger;
      }
    }

    this.mcLoader.router = this.router;
    // debugger;
    if (action.indexOf('unlock') !== -1) {
      this.isCurrentlyUnlocked = !this.isCurrentlyUnlocked;
    }
    else if (action.indexOf('lock') !== -1) {
      this.isCurrentlyUnlocked = !this.isCurrentlyUnlocked;
    }
    // debugger;
    this.ngAfterViewInit();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.commActivity.receiverSitUserId = this.sitUser.sitUserId;
    this.commActivity.senderSitUserId = this.loginSuccess.signedInUserId;
    this.commActivity.action = action;
    this.commActivity.date = DateStringServiceStatic.getTicks(new Date()).toString();
    // debugger;
    if (this.sitUser.sitUserId > 0 && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
      // debugger;
      this.activityService.sendMemberLockingActivity(this.commActivity).subscribe(mAct => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct)) {
          // debugger;
          this.isCurrentlyUnlocked = mAct?.action.toLowerCase('unlock') !== -1 ? true : false;
          this.ngAfterViewInit();
          // debugger;
        }
      });      
    }    
  }
  // ---------------------------------------------------------------------------------
  setBlockAction (action : string) : void {
    // debugger;
    this.returnUrl = window.location.pathname + window.location.search;
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
      this.mcLoader.id = this.sitUser.sitUserId;
      // debugger;
    }
    else {
      // debugger;
      var tpTile = EmitterSubjectService.getSelectedProfileTile();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile)) {
        this.mcLoader.id = tpTile.sitUserId;
        // debugger;
      }
    }

    this.mcLoader.router = this.router;
    // debugger;
    if (action.indexOf('unblock') !== -1) {
      this.isCurrentlyBlocked = !this.isCurrentlyBlocked;
    }
    else if (action.indexOf('block') !== -1) {
      this.isCurrentlyBlocked = !this.isCurrentlyBlocked;
    }
    // debugger;
    this.ngAfterViewInit();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.commActivity.receiverSitUserId = this.sitUser.sitUserId;
    this.commActivity.senderSitUserId = this.loginSuccess.signedInUserId;
    this.commActivity.action = action;
    this.commActivity.date = DateStringServiceStatic.getTicks(new Date()).toString();
    // debugger;
    if (this.sitUser.sitUserId > 0 && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
      // debugger;
      this.activityService.sendMemberActivity(this.commActivity).subscribe(mActArr => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActArr) && mActArr.length > 0) {
          // debugger;
          mActArr.map(e => {
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.action)) {
              if (e.action.toLowerCase('unblock') !== -1 || e.action.toLowerCase('block') !== -1) {
                this.isCurrentlyBlocked = e.action.toLowerCase('block') !== -1 ? true : false;
                this.ngAfterViewInit();
                // debugger;
              }
            }
          })
        }
      });
    }
  }
  // ---------------------------------------------------------------------------------
  //  Note: MemberTrackingAction is not sent from here since Track-glyphicon is not
  //        part of the memberGlyphiconForViewMgmt.
  //        It is rather sent from profileTileComponent when the signedInUser clicks
  //        a profileTile to view his profile.
  // ---------------------------------------------------------------------------------
  setGlyphAction (page : number, kvg : KvMemberActivityGlyph ): void {
    // debugger;
    this.returnUrl = window.location.pathname + window.location.search;
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
      this.mcLoader.id = this.sitUser.sitUserId;
      // debugger;
    }
    else {
      // debugger;
      var tpTile = EmitterSubjectService.getSelectedProfileTile();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tpTile)) {
        this.mcLoader.id = tpTile.sitUserId;
        // debugger;
      }
    }

    this.mcLoader.router = this.router;

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( kvg ) )
    {     
      if ( page > 0 )
      {
        this.pagerVMemActGlyphForViewMember = this.glyphiconMenuService.setKvGlyphPager( page );
      }
      // debugger;
      let actionName = kvg?.key?.toString()?.toLowerCase();
      switch ( actionName )
      {
        case 'friend':
        case 'unfriend':
        case 'like':
        case 'dislike':
        case 'star':
        case 'wink':
          // Note: track is done through MemberView
          // debugger;
          // note: activities do not open a new page. these are tasks that are done on the background, and may update the current page's information
           EmitterSubjectService.setIsPhotoMgmt( false );
           EmitterSubjectService.setIsViewMember( true );
          this.commActivity = CopyServiceStatic.copyFromKvGlyphToCommunicationActivity(kvg);
          this.sitUser = EmitterSubjectService.getSitUserModel();
          this.loginSuccess = EmitterSubjectService.getLoginSuccess();
          this.commActivity.receiverSitUserId = this.sitUser.sitUserId;
          this.commActivity.senderSitUserId = this.loginSuccess.signedInUserId;
          this.commActivity.receiverSitUserKey = this.sitUser.sitUserKey;
          this.commActivity.senderSitUserKey = this.loginSuccess.signedInUserKey;
          this.commActivity.date = DateStringServiceStatic.getTicks(new Date()).toString();
          // debugger;
          if (this.sitUser.sitUserId > 0 &&  this.sitUser.sitUserId !== this.loginSuccess.signedInUserId )
          {
            // debugger;
            this.activityService.sendMemberActivity( this.commActivity ).subscribe( mAct =>
            {
              // debugger;
              this.determineResultOfGlyphAction(mAct);
              // debugger;
              this.ngAfterViewInit();
            });            
          }          
          break;

        default:
          // debugger;
          // this.router.navigate(['/' + key]);//TODO : set to ['/' + key] after testing
          this.mcLoader.componentName = 'index';
          this.mcLoader.moduleName = 'app';
          this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
            this.pagerBrdCrm = data;
          });
          break;
      }
    }
  }
}

// ---------------------------------------------------------------

// ---------------------------------------------------------------

