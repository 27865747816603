'use-strict'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveFilterPipeModule } from '../directiveFilterPipeModule/directiveFilterPipe.module';
import { ImageUploadModuleComponent } from './component/imageUploadModule.component';
import { FileUploadComponent } from './fileUpload/fileUpload.component';
import { ImageUploadRoutingModule } from './imageUpload-routing.module';
import { UploadFilesComponent } from './uploadFiles/uploadFilesComponent.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';



@NgModule({
  declarations: [
    FileUploadComponent,
    UploadFilesComponent,
    ImageUploadModuleComponent,
    ImageUploadComponent
  ],
  imports: [
    CommonModule, // to get ngIf, ngFor, etc.
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DirectiveFilterPipeModule,
    ImageUploadRoutingModule
  ],
  exports: [
    FileUploadComponent,
    UploadFilesComponent,
    ImageUploadRoutingModule,
    ImageUploadComponent
  ]
})
export class ImageUploadModule { }
