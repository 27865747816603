'use strict'
// import * as ab2str from 'arraybuffer-to-string'; // ref:https:// www.npmjs.com/package/arraybuffer-to-string
// import { decode, encode } from 'base64-arraybuffer-es6'; // ref:https:// www.npmjs.com/package/base64-arraybuffer
// import * as base64js from 'base64-js';
// import * as clone from 'clone';
import { interval, Observable, Subject, Subscription, } from 'rxjs';
import { map, takeUntil, distinctUntilChanged, filter, take, startWith, tap } from 'rxjs/operators';
// import { Preference } from '../../models/profile/preference.model';
// import * as str2ab from 'string-to-arraybuffer'; // ref:https:// www.npmjs.com/package/string-to-arraybuffer
//import * as textEncoder from 'text-encoder-lite';
import { KV } from '../../models/keyValue/kv.model';
import { deserialize, serialize } from '@ungap/structured-clone'; // for native deep-clone
import { decode, encode } from 'sourcemap-codec';
// import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
// import { CopyServiceStatic } from './copyServiceStatic.service';
var Buffer = require('buffer/').Buffer;  // note: the trailing slash is important!
// import { Buffer } from ('buffer/').Buffer; 
import * as naclUtil from 'tweetnacl-util';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

//
/*
 * fetchAsBlob(`https:// fonts.gstatic.com/s/roboto/v16/d-6IYplOFocCacKzxwXSOJBw1xU1rKptJj_0jans920.woff2`)
 *   .then(convertBlobToBase64)
 *   .then(console.log)
 * -------------------------------------------------------------------
 * ------------------------------------------------------------------
 * ref:https:// stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript
 * encode Uint8Array to string, and decode string to Uint8Array.
 */
// const base_64 = {
//  decode: (s) => Uint8Array.from(atob(s), (c) => c.charCodeAt(0)),
//  encode: (b) => btoa(String.fromCharCode(...new Uint8Array(b))),
// };
//// for Node.js
// const base_64_njs = {
//  decode: (s) => Buffer.from(s, 'base64'),
//  encode: (b) => Buffer.from(b).toString('base64'),
// };

// -------------------------------------------------------------------
// This is based on the lib clone ( as promulgated by Dan Wahlin)
export abstract class StringServiceStatic {
  private emitterDestroyed$ : Subject<boolean> = new Subject();

  public static strings : string[] = [];
  //  -------------------------------------------------------------------------
  constructor () {
    // debugger;
    // EmitterSubjectService.toSetMemberViewMgmtModelEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe((result) => {
    //     debugger;
    //    EmitterSubjectService.emitMemberViewMgmtModel(result);;
    //  });  
  }
  //  -------------------------------------------------------------------------
  public mimeTypes : KV[] = [];
  public jsTypes = [ 'Boolean', 'BigInt', 'Function', 'NaN', 'Null', 'Number', 'Object', 'object', 'String', 'Symbol', 'Undefined' ];
  public Sys = {};

  // static emitMemberViewMgmtModel (model : MemberViewMgmtModel): void {
  //  EmitterSubjectService.setMemberViewMgmtModel(model);
  // }
  // static toSetMemberViewMgmtModel (model : MemberViewMgmtModel) {
  //  debugger;
  //  EmitterSubjectService.setMemberViewMgmtModel(model);
  // }
  // ---------------------------------------------------------------
  //  Tested on 20220324 and it works!
  // ---------------------------------------------------------------
  static CountCharInString (charRegExp : RegExp, strToSearch : string) : number {
    // debugger;
    return ((strToSearch || '').match(charRegExp) || []).length;

  }
  // ---------------------------------------------------------------
  static getUserNameFromEmail (email : string) : string {
    if (!this.isNullOrEmpty(email)) {
      let parts = email.split('@');
      if (parts.length > 0) { return parts[ 0 ]; }
      else return email;
    }
    else return email;
  }
  // ---------------------------------------------------------------
  static string2Number (input : string) : number {
    if (!this.isNullOrEmpty(input)) {
      return parseInt(input, 10);
    }
    else return -999;
  }
  // ---------------------------------------------------------------
  // Note: to test the performace time of any function:
  // ref: https://stackoverflow.com/questions/313893/how-to-measure-time-taken-by-a-function-to-execute
  // ---------------------------------------------------------------
  static performanceTimed = (f : any) => (...args : any) => {
    let start = performance.now();
    let ret = f(...args);
    let message = `StringServiceStatic.function ${ f.name } took ${ (performance.now() - start).toFixed(3) }ms`;
    // console.log(`function ${ f.name } took ${ (performance.now() - start).toFixed(3) }ms`);
    console.log(message);
    alert(message);
    return ret;
  }
  // Usage:
  //  let test = () => { /* does something */ }
  //  test = timed(test)   // turns the function into a timed function in one line
  //  test ()
  // ---------------------------------------------------------------
  // Deep-Clone without _lodash:
  static deepCloneJson (obj : any) : any {
    return JSON.parse(JSON.stringify(obj));
  }
  //  --------------------------------------------------------------
  // Native-JS-deep-clone:
  // import structuredClone from '@ungap/structured-clone'; // for native deep-clone
  static deepClone (obj : any) : any {
    // return structuredClone(obj);
    // the result can be stringified as JSON without issues
    // even if there is recursive data, bigint values,
    // typed arrays, and so on
    // const serialized = serialize({ any: 'serializable' });
    const serialized = serialize(obj);
    debugger;
    // the result will be a replica of the original object
    const deserialized = deserialize(serialized);
    debugger;
    return deserialized;
  }
  //  --------------------------------------------------------------
  /*
   * --------------------------------------------------------------
   * Ref;https:// stackoverflow.com/questions/1179366/is-there-a-javascript-strcmp#comment64419826_17765169
   */
  static strcmp (str1 : any, str2 : any) : any {
    /*
     * http:// kevin.vanzonneveld.net
     * +   original by: Waldo Malqui Silva
     * +      input by: Steve Hilder
     * +   improved by: Kevin van Zonneveld (http:// kevin.vanzonneveld.net)
     * +    revised by: gorthaur
     * *     example 1: strcmp( 'waldo', 'owald' );
     * *     returns 1: 1
     * *     example 2: strcmp( 'owald', 'waldo' );
     * *     returns 2: -1
     */

    return str1 === str2 ? 0 : str1 > str2 ? 1 : -1;
  }
  /*
   * --------------------------------------------------------------
   * Note: Begin of REVERSE-string-methods:
   * Ref: https:// medium.com/better-programming/5-ways-to-reverse-a-string-in-javascript-466f62845827
   * --------------------------------------------------------------
   */
  static reverse (str : any) : any {
    return str.split('').reverse().join('');
  }
  // --------------------------------------------
  static reverseES6 (str : any) : any {
    return [ ...str ].reverse().join('');
  }
  // --------------------------------------------
  static reverseUsingReduce (str : any) : any {
    return str.split('').reduce((rev : any, char : any) => char + rev, '');
  }
  // --------------------------------------------
  static reverseUsingRecursion (str : any) : any {
    return str ? this.reverseUsingRecursion(str.substr(1)) + str[ 0 ] : str;
  }
  // --------------------------------------------
  static reverseUsingForLoop (str : any) : any {
    let reversed = '';

    for (const char of str) {
      reversed = char + reversed;
    }
    return reversed;
  }
  /*
   * --------------------------------------------
   * Note: Tested on 2020/12/20. Works!
   * --------------------------------------------
   */
  static reverseOnItself (inArr : any[]) : any[] {
    const len = inArr.length;
    let middleIndex = len / 2;
    let temp : any;

    if (len % 2 === 0) {
      // even-case
      for (let i = 0; i <= middleIndex; i++) {
        temp = inArr[ i ];
        inArr[ i ] = inArr[ len - i - 1 ];
        inArr[ len - i - 1 ] = temp;
      }
    } else if (len % 2 === 1) {
      // odd-case
      const middleV = inArr[ len / 2 ];

      middleIndex = len / 2;
      for (let i = 0; i <= middleIndex || inArr[ i ] === middleV; i++) {
        // swap up to middle or  middleValue
        temp = inArr[ i ];
        inArr[ i ] = inArr[ len - i - 1 ];
        inArr[ len - i - 1 ] = temp;
      }
    }
    return inArr;
  }
  // ---------------------------------------------------------------------------------
  // ref: https://www.googlecloudcommunity.com/gc/Apigee/Convert-each-field-in-JSON-Object-from-Camel-Case-to-Pascal-Case/m-p/73887
  // recursive function that will properly camelCase all of a JavaScript object's properties:
  // Tested, works on 20231013
  static pascalCasePattern = new RegExp("^([A-Z])([a-z]+)");

  static pascalCaseToCamelCase (propname) {
    if (StringServiceStatic.pascalCasePattern.test(propname)) {
      return propname.charAt(0).toLowerCase() + propname.slice(1);
    }
    else {
      return propname;
    }
  }

  static convertPropertyNames (obj, converterFn) {
    var r, value, t = Object.prototype.toString.apply(obj);
    if (t == "[object Object]") {
      r = {};
      for (var propname in obj) {
        value = obj[ propname ];
        r[ converterFn(propname) ] = StringServiceStatic.convertPropertyNames(value, converterFn);
      }
      return r;
    }
    else if (t == "[object Array]") {
      r = [];
      for (var i = 0, L = obj.length; i < L; ++i) {
        value = obj[ i ];
        r[ i ] = StringServiceStatic.convertPropertyNames(value, converterFn);
      }
      return r;
    }
    // debugger;
    return obj;
  }

  static testCamelCase () : void {
    const originalObject = {
      "IsSuccess": true,
      "ResponseDate": "2019-02-20T11:42:11.963Z",
      "Result": {
        "RecordCount": "abc123",
        "BillDetailsList": [
          {
            "SourceSystem": "Abc123",
            "BillAmount": "Abc123",
            "BillCreationDate": "2019-02-19T09:16:04Z"
          },
          {
            "SourceSystem": "abc123",
            "BillAmount": "XyzAbc",
            "BillCreationDate": "abc123"
          }
        ]
      }
    };
    var converted = StringServiceStatic.convertPropertyNames(originalObject, StringServiceStatic.pascalCaseToCamelCase);

    console.log(converted);
    alert(converted);
  }


  /* Use Case:
  var obj = {
    'FirstName': 'John',
    'LastName': 'Smith',
    'BirthDate': new Date(),
    'ArrayTest': ['one', 'TWO', 3],
    'ThisKey': {
      'This-Sub-Key': 42
    }
  }
  console.log(JSON.stringify(toCamel(obj)))
   */
  // ---------------------------------------------------------------------------------
  /*
   * ---------------------------------------------------------------------------------
   * ref: https://www.javacodeexamples.com/convert-first-character-of-string-to-lowercase-in-java-example/636
   * Note: This method does not work:( tested on 20220422
   *       if it cannot be fixed, delete this.
   * ---------------------------------------------------------------------------------
   */
  static firstCharToLowerCase (str : string) : string {
    if (str === null || str.length === 0) {
      return '';
    }
    var charArr = str.split('');
    if (charArr.length > 0) {
      charArr[ 0 ] = charArr[ 0 ].toString().toLowerCase();
      var outStr = charArr.join();
      debugger;
      return outStr;
     // return str.substring(0, 1).toLowerCase() + str.substring(1);
    }
    
  }
  /*
   * --------------------------------------------------------------
   * Testing the ArrayReverseOnItsef:
   * Note: Tested, works! 2020/12/20
   * --------------------------------------------------------------
   */
  static testReverseOnItself(inArray: any[]): any {
    let arrStr = '';

    for (let i = 0; i < 11; i++) {
      inArray.push(i);
      arrStr += i + ', ';
    }
    const revArr = this.reverseOnItself(inArray); // Tested and works!
    let revArrStr = '';

    if (!this.isNullOrEmpty(revArr)) {
      for (let i = 0; i < 11; i++) {
        revArrStr += revArr[i] + ', ';
      }
    }
    alert('in-Array: ' + arrStr + '\n reversed-Array: ' + revArrStr);
  }
  // --------------------------------------------------------------
  static getEmailFromSitUserKey (key : string): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(key)) {
      var parts = key.split('&');

      if (parts.length === 2) {
        return parts[ 1 ];
      }
    }
  }
  // --------------------------------------------------------------
  static getTicksFromSitUserKey (key : string) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(key)) {
      var parts = key.split('&');

      if (parts.length === 2) {
        return parts[ 0 ];
      }
    }
  }
  // --------------------------------------------------------------
  // ref: https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
  // --------------------------------------------------------------
  static camelToPascalize (str) {
    return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, function (match, chr) {
      return chr.toUpperCase();
    });
  }
  /*
   * --------------------------------------------------------------
   * Note: END of REVERSE-string-methods:
   * --------------------------------------------------------------
   */

  
  /*
   * --------------------------------------------------------------
   * Dan Bernstein’s popular “times 33” hash function in TypeScript
   * Author: Sayeed Rahman
   * Date: November 19, 2020
   * Location: Ottawa, Canada
   *
   * TODO: test.
   * --------------------------------------------------------------
   */
  /*
   * times33Hash(str: string): number {
   * let h = 5381;
   * for (let i = 0, j = str.length; i < j; i++)
   * {
   *  //Shifting h left by 5 bits is a quick way to multiply by 32
   *  h += (h << 5) + parseInt(str[i], 10);
   *  //Only keep the lower 32 bits of h
   *  h = h & 0xFFFFFFFF;
   * }
   * //debugger;
   * return h;
   * }
   */
  /*
   * -------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/4459928/how-to-deep-clone-in-javascript/
   * Note: failed during cloning on 20210611
   */
  // static cloneDeepFailed(item): any {
  //  if (!item) {
  //    return item;
  //  } // null, undefined values check

  //  const types = [Number, String, Boolean];
  //  let result: any;

  //  // normalizing primitives if someone did new String('aaa'), or new Number('444');
  //  types.forEach((type) => {
  //    if (item instanceof type) {
  //      result = type(item);
  //    }
  //  });

  //  if (typeof result === 'undefined') {
  //    if (Object.prototype.toString.call(item) === '[object Array]') {
  //      result = [];
  //      if (item && item.llength > 0) {
  //        item.forEach((child, index) => {
  //          result[ index ] = this.cloneDeepFailed(child);
  //        });
  //      }
  //    } else if (typeof item === 'object') {
  //      // testing that this is DOM
  //      if (item.nodeType && typeof item.cloneNode === 'function') {
  //        result = item.cloneNode(true);
  //      } else if (!item.prototype) {
  //        // check that this is a literal
  //        if (item instanceof Date) {
  //          result = new Date(item);
  //        } else {
  //          // it is an object literal
  //          result = { };
  //          let i = 0;

  //          item.map((e) => {
  //            result[ i ] = this.cloneDeepFailed(e);
  //            i++;
  //          });
  //        }
  //      } else {
  //        /*
  //         * depending what you would like here,
  //         * just keep the reference, or create new object
  //         */
  //        if (item.constructor) {
  //          // would not advice to do that, reason? Read below
  //          result = new item.constructor();
  //        } else {
  //          result = item;
  //        }
  //      }
  //    } else {
  //      result = item;
  //    }
  //  }

  //  return result;
  // }

  /*
   * Usage Example:
   * ==============
   * var copy = this.clone({
   * one: {
   *   'one-one': new String('hello'),
   *   'one-two': [
   *     'one', 'two', true, 'four'
   *   ]
   * },
   * two: document.createElement('div'),
   * three: [
   *   {
   *     name: 'three-one',
   *     number: new Number('100'),
   *     obj: new function () {
   *       this.name = 'Object test';
   *     }
   *   }
   * ]
   * })
   * -------------------------------------------------------------------
   * Does not work, it reached Maximum Call Stack size.
   * ref:https:// stackoverflow.com/questions/4459928/how-to-deep-clone-in-javascript/
   */
  // static deepCopy(aObject): any {
  //  if (!aObject) {
  //    return aObject;
  //  }

  //  let v;
  //  const bObject = Array.isArray(aObject) ? [] : { };
  //  let i = 0;

  //  aObject.map((e) => {
  //    v = e;
  //    bObject[i] = typeof v === 'object' ? this.deepCopy(v) : v;
  //    i++;
  //  });

  //  return bObject;
  // }
  /*
   * -------------------------------------------------------------------
   * Note: Could not clone ProfileTilesArr. Encountered errors
   * -------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/4459928/how-to-deep-clone-in-javascript/
   *  Note: failed on 20210611
   */
   // static deeepClone(obj, hash = new WeakMap()): any {
   // if (Object(obj) !== obj) {
   //   return obj; // primitives
   // }
   // if (hash.has(obj)) {
   //   return hash.get(obj); // cyclic reference
   // }
   // const result =
   //   obj instanceof Set
   //     ? new Set(obj) // See note about this!
   //     : obj instanceof Map
   //     ? new Map(Array.from(obj, ([key, val]) => [key, this.deeepClone(val, hash)]))
   //     : obj instanceof Date
   //     ? new Date(obj)
   //     : obj instanceof RegExp
   //     ? new RegExp(obj.source, obj.flags)
   //     : // ... add here any specific treatment for other classes ...
   //     // and finally a catch-all:
   //     obj.constructor
   //     ? new obj.constructor()
   //     : Object.create(null);

   // hash.set(obj, result);
   // return Object.assign(result, ...Object.keys(obj).map((key) => ({ [key]: this.deeepClone(obj[key], hash)})));
   // }
  /*
   * Usage Example:
   * ==============
   * Sample data
   * var p = {
   * data: 1,
   * children: [{
   *   data: 2,
   *   parent: null
   * }]
   * };
   * p.children[0].parent = p;
   */

  // var q = this.deeepClone(p);

  /*
   * console.log(q.children[0].parent.data); //1
   * --------------------------------------------------------------
   */
  // --------------------------------------------------------------
  static getCircularReplacer = () =>
  {
    const seen = new WeakSet();
    return (key : any, value : any ) =>
    {
      if ( typeof value === 'object' && value !== null )
      {
        if ( seen.has( value ) )
        {
          return;
        }
        seen.add( value );
      }
      return value;
    };
  }

  // problem: JSON.stringify(circularReference);
              // TypeError: cyclic object value
  // solution:
  // usage: JSON.stringify(circularReference, getCircularReplacer());
  // ---------------------------------------------------------------------------------
  public static firstIndexOf(input: string, pattern: string): any {
    if (!this.isNullOrEmpty(input)) {
      return input.indexOf(pattern);
    }
  }
  // ---------------------------------------------------------------------------------
  public static trimQuotes(input: string): any {
    if (!this.isNullOrEmpty(input)) {
      let temp = input.trim().replace('\"', '');

      if (!this.isNullOrEmpty(temp)) {
        temp = temp.trim().replace('\'', '');
      }
      if (!this.isNullOrEmpty(temp)) {
        temp = temp.trim().replace('"', '');
      }
      return temp;
    }
    return input;
  }
  // ---------------------------------------------------------------------------------
  // Ref: https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
  //
  // Note:  CAUTION!!!:
  //        this method stripped/deleted all dividers of < kay: value > paired JSON string of the `Photo` model.
  //        this is not a desirable outcome :( !
  //        this may NOT be good for a JSON string of any model!!!
  // ---------------------------------------------------------------------------------
  public static toCamelCaseNotUsingLodash (str:string): string {
     //return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
     // return index === 0 ? word.toLowerCase() : word.toUpperCase();
     //}).replace(/\s+/g, '');

    // OR using lodash
    // return _lodash.camelCase(str);

    // OR
     if (!this.isNullOrEmpty(str)) {
      return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+/g, '');
     }
     else return '';
  }
  // ---------------------------------------------------------------------------------
  // ref: https://www.codegrepper.com/code-examples/javascript/lowercase+first+character+javascript
  // ---------------------------------------------------------------------------------
  public static capitalizeFirstLetter (string : any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // ---------------------------------------------------------------------------------
  // ref: https://www.codegrepper.com/code-examples/javascript/lowercase+first+character+javascript
  //  Note: Tested & works! on 20220423
  // ---------------------------------------------------------------------------------
  public static lowercaseFirstLetter (str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }
  
  
  // ---------------------------------------------------------------------------------
  public static toPascalCase (str: string): string {
    ;// TODO
    return '';
  }
  // ---------------------------------------------------------------------------------
  public static preparePageTitle(pageTitle: string): any {
    /*
     * a typical pageTitle = '-:Members <span style=\'font-size:0.75rem;\'>(</span> <span class=\'blueBeigexxS neonText\'>
     *                       ordered by distance in meters</span>' + '<span style=\'font-size:0.75rem;\'>)</span>' + ':-'
     * Objective: split and merge to create the following: <br/>-:Members:-<br/><span style...>
     * step-1: replace first occurance of '<' with '-<' of pageTitle.
     * step-2: parts = pageTitle.split('-');
     * step-3: postfixes = parts[1].split(':');
     * step-5: <br/> + parts[0] + ':' + postfixes[1] +  <br/> + postfixes[0]
     * ----------------------------------------------------------------
     * var index = pageTitle.firstIndexOf('<');
     * if(index > 0) pageTitle[index] = '-<'; //Note: string is readonly so cannot do that
     * The above two lines of code is accomplished by the following section of while loop:
     * ----------------------------------------------------------------
     */
    if (!this.isNullOrEmpty(pageTitle)) {
      const chars = new Array(...pageTitle); // ==toCharArray()
      let temp = '';
      let i = 0;

      while (i < chars.length && chars[i] !== '<') {
        temp += chars[i];
        i++;
      }
      temp += '-';
      temp += chars[i];
      i++;
      while (i < chars.length) {
        temp += chars[i];
        i++;
      }
      /*
       * debugger;
       * now temp contains the value of 'pageTitle' with first and only occurance of '-<' in 'pageTitle';
       * ==> if(index > 0) pageTitle[index] = '-<';
       * ----------------------------------------------------------------
       */
      const parts = temp.split('-<'); // =>need to reattach '<'
      // debugger;

      if (!this.isNullOrEmpty(parts) && parts.length === 2) {
        if (!this.isNullOrEmpty(parts[1])) {
          const secondPart = '<' + parts[1];
          // debugger;
          const postFixes = secondPart.split(':-'); // =>need to reattach ':-'
          // debugger;

          if (!this.isNullOrEmpty(postFixes) && postFixes.length > 0) {
            // debugger;
            return (pageTitle = parts[0].trim() + ':- <br /> ' + postFixes[0]);
          }
        }
      }
    }
    return pageTitle;
  }
  // ----------------------------------------------------------------
  static setRawImageData(rawImageData: string): string {
    // debugger;
    if (!this.isNullOrEmpty(rawImageData)) {
      if (rawImageData.indexOf('data:image/png/svg;base64,') === -1) {
        // check if it has the prefix-of-raw-image-data
        return 'data:image/png/svg;base64,' + rawImageData;
      }
    }
    return rawImageData;
  }
  // --------------------------------------------------------------
  static isNullOrEmpty ( input : any ) : boolean
  {
    if (input === null || input === '' || input === undefined || (input !== null && (input.toString().toLowerCase() === 'null' || input.toString().toLowerCase() === 'undefined'))) {
      return true;
    }
    return false;
  }
  // ---------------------------------------------------------------
  // Ref: https://stackoverflow.com/questions/2087522/does-javascript-have-a-built-in-stringbuilder-class
  // TODO: test
  // ---------------------------------------------------------------
  static stringBuilder (value : any) : string {
    // debugger;
    var outStr = '';
    this.strings = [];
    if (!this.isNullOrEmpty(value) || value === 0) {
      // debugger;
      this.strings.push(value);
      this.strings.join("");
    }
    else this.strings += value;
    outStr = this.strings.join("");
    return outStr;
  }

  // ---------------------------------------------------------------
  static createRegExpFromString (input : string) : any {
    if (typeof input !== 'string') {
      return 0;
    }
    input = (input) === '.' ? ('\\' + input) : input;
    debugger;
    const regexp = new RegExp(input, 'g');
    return regexp;
  }
  // ---------------------------------------------------------------
  //  Tested on 20220324 and it works!
  // ---------------------------------------------------------------
  static countCharInString (charRegExp : RegExp, strToSearch : string) : number {
    // debugger;
    return ((strToSearch || '').match(charRegExp) || []).length;

  }
  // ---------------------------------------------------------------
  static string2Unit8Arr(s: string): any {
    return Uint8Array.from(atob(this.strTobase64(s)), (c) => c.charCodeAt(0));
  }
  static unit8Arr2Str(b: []): any {
    return btoa(String.fromCharCode(...new Uint8Array(b)));
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/4456336/finding-variable-type-in-javascript
   * ---------------------------------------------------------------
   */
  static typeCheck(obj: any): any {
    if (!this.isNullOrEmpty(obj)) {
      // debugger;
      switch (typeof obj) {
        // object prototypes
        case 'undefined':
          return 'Undefined';
        case 'boolean':
          return 'Boolean';
        case 'number':
          return 'Number';
        case 'string':
          return 'String';
        case 'object':
          if (obj instanceof Array) {
            return 'Array';
          }
          else if (obj instanceof String) {
            return 'String';
          }
          else if (obj instanceof Number) {
            return 'Number';
          }
          else if (obj instanceof Date) {
            return 'Date';
          }                        
          else if (obj instanceof Object) {
            return 'Object';
          }
          else if (obj instanceof RegExp) {
            return 'RegExp';
          }
          else if (obj instanceof Function) {
            return 'Function';
          }
          else if (obj instanceof Symbol) {
            return 'Symbol';
          }
          else return 'Object';
        // object literals
        default:
          return typeof obj;
      }
    }
  }
  // ---------------------------------------------------------------
  static ConvertStringToNumber(inStr: string): number {
    if (!this.isNullOrEmpty(inStr)) {
      return parseInt(inStr, 10);
    }
    return -1;
  }
  /*
   * ---------------------------------------------------------------
   * Note: dob is sent in yyyy/mm/dd format
   */
  static ageFromDobString(dob: string): number {
    let age = 0;

    if (!this.isNullOrEmpty(dob)) {
      const now = new Date();
      const nowYear = now.getFullYear();
      const nowMonth = now.getMonth() + 1;
      const nowDay = now.getDate();
      const dobParts = dob.split('/');

      if (dobParts.length > 2) {
        const dobYYYY = parseInt(dobParts[0], 10);
        const dobMM = parseInt(dobParts[1], 10);
        const dobDD = parseInt(dobParts[2], 10);

        age = nowYear - dobYYYY;
        if (nowMonth < dobMM && age > 0) {
          age--;
        }
        if (nowMonth === dobMM && nowDay < dobDD && age > 0) {
          age--;
        }
      }
    }
    return age;
  }
  // ---------------------------------------------------------------
  static ageFromDobTicks(ticks: number): number {
    if (!this.isNullOrEmpty(ticks) && ticks > 0) {
      return this.ageFromDobString(this.dateFromTicks(ticks));
    }
    return 0;
  }
  /*
   * --------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
   */
  static dataURLtoFile (data : any, filename : any): any {
    if (!this.isNullOrEmpty(data)) {
      /*
       * var arr = dataurl.split(',');
       * if (!this.isNullOrEmpty(arr) && arr.length > 1) {
       */
      const mimeMatch = data.match(/:(.*?);/); // [1];

      if (!this.isNullOrEmpty(mimeMatch) && mimeMatch.length > 1) {
        const mime = mimeMatch[1];
        const bstr = atob(btoa(data));
        let n = bstr.length;
        const u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime});
      }
    }
  }
  // --------------------------------------------------------------
  static dateFromTicks(inTicks: any): any {
    const ticks = parseInt(inTicks, 10);
    /*
     * original code
     * var ss = ticks % 60;
     * var mm = Math.floor((ticks % 3600) / 60);
     * var hh = Math.floor(ticks / 3600);
     * var dd = Math.floor(hh / 24);
     * var months = Math.floor(dd / 30);
     * var yyyy = Math.floor(months / 12);
     */

    /*
     * changed to 10*tick = millisec
     * const ss = Math.floor((ticks / 10 / 1000) % 60);
     * const mm = Math.floor(((ticks / 10 / 100) % 3600) / 60);
     */
    const hh = Math.floor(ticks / 10 / 1000 / 3600);
    const dd = Math.floor(hh / 24);
    const months = Math.floor(dd / 30);
    const yyyy = Math.floor(months / 12);
    const result = this.padYear(yyyy) + '/' + this.padMMDD(months) + '/' + this.padMMDD(dd);
    /*
     * + this.pad2(hh) + ':' + this.pad2(mm) + ':' + this.pad2(ss);
     * alert ('age: ' + this.ageFromDobString(result) + '=>' + this.pad(yyyy, 2) + '/' + this.pad(months, 2) +
     * '/' + this.pad(dd, 2) + this.pad(hh, 2) + ':' + this.pad(mm, 2) + ':' + this.pad(ss, 2));
     * debugger;
     */

    return result;
  }
  // --------------------------------------------------------------
  static pad (n : any, wdth : any): any {
    n = n + '';
    return n.length >= wdth ? n : new Array(wdth - n.length + 1).join('0') + n;
  }
  static padMMDD (num : any): any {
    num = '0' + num;
    return num.substr(num.length - 2);
  }
  static padYear (num : any): any {
    num = '0' + num;
    return num.substr(num.length - 4);
  }
  // --------------------------------------------------------------
  static getDate (date : any): string {
    // debugger;
    if (date) {
      const dateParts = date.split('T');

      if (dateParts.length > 0) {
        return dateParts[0].toString();
      }
      return '';
    }
    return '';
  }
  // --------------------------------------------------------------
  static getMDYDate (date : any): any {
    if (date) {
      const parts = date.split('/'); // date=mm/dd/yyyy

      if (parts.length > 2) {
        return this.getMonthName(parseInt(parts[0], 10)) + ' ' + parts[1] + ', ' + parts[2];
      }
    }
    return date;
  }
  // ------------------------------------------------------------------------
  static getMonthName (month : any): any {
    if (month > 0) {
      switch (month) {
        case 1:
          return 'January';
        case 2:
          return 'February';
        case 3:
          return 'March';
        case 4:
          return 'April';
        case 5:
          return 'May';
        case 6:
          return 'June';
        case 7:
          return 'July';
        case 8:
          return 'August';
        case 9:
          return 'September';
        case 10:
          return 'October';
        case 11:
          return 'November';
        case 12:
          return 'December';
        default:
          return '';
      }
    }
    return '';
  }
  // -------------------------------------------------------------
  static generateFileType (fileExtension : any): any {
    let fileType = { };

    switch (fileExtension.toLowerCase()) {
      case 'doc':
      case 'docx':
        fileType = 'application/msword';
        break;
      case 'html':
        return 'text/html';
      case 'xls':
      case 'xlsx':
        fileType = 'application/vnd.ms-excel';
        break;
      case 'pps':
      case 'ppt':
        fileType = 'application/vnd.ms-powerpoint';
        break;
      case 'txt':
        fileType = 'text/plain';
        break;
      case 'rtf':
        fileType = 'application/rtf';
        break;
      case 'pdf':
        fileType = 'application/pdf';
        break;
      case 'msg':
      case 'eml':
        fileType = 'application/vnd.ms-outlook';
        break;
      case 'gif':
      case 'bmp':
      case 'png':
      case 'jpg':
        fileType = 'image/JPEG';
        break;
      case 'dwg':
        fileType = 'application/acad';
        break;
      case 'zip':
        fileType = 'application/x-zip-compressed';
        break;
      case 'rar':
        fileType = 'application/x-rar-compressed';
        break;
    }
    return fileType;
  }
  /*
   * --------------------------------------------------------------
   * Begin of linebreak
   * --------------------------------------------------------------
   */
  static lineBreak(): any {
    return this.filterFilter;
  }
  static filterFilter (text : any): any {
    if (!text || !text.length) {
      return text;
    }

    return text.replace(/(\\r\\n)|([\r\n])/gim, '<br/>');
  }
  /*
   * --------------------------------------------------------------
   * End of linebreak
   * --------------------------------------------------------------
   * --------------------------------------------------------------
   * ref: https:// developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
   * TODO: test
   */
 

  /*
   * -------------------------------------------------------------
   * Note: a similar method exists in copyService.ts//copyFromProfilePicsToKvPhotoDictionary
   * ---------------------------------------------------------------
   * profilePicsToKvPhotoDictionary(profilePics: ProfilePics): KvPhoto[]{}
   */

  // -------------------------------------------------------------
  // getContentToKvArr(content: Content): any {
  //  const kvArr: KV[] = [];

  //  if (content) {
  //    // debugger;
  //    let kv: KV = new KV();

  //    if (content.heading && content.heading.length > 0) {
  //      kv = new KV();
  //      kv.key = 'Prfile name';
  //      kv.value = content.heading;
  //      kvArr.push(kv);
  //    }

  //    if (content.aBstract && content.aBstract.length > 0) {
  //      kv = new KV();
  //      kv.key = 'Highlight';
  //      kv.value = content.aBstract;
  //      kvArr.push(kv);
  //    }
  //    if (content.contents && content.contents.length > 0) {
  //      kv = new KV();
  //      kv.key = 'Description';
  //      kv.value = content.contents;
  //      kvArr.push(kv);
  //    }
  //  }
  //  return kvArr;
  // }
  /*
   * -------------------------------------------------------------
   * Note: Order of the entries reflects as is on the view
   * -------------------------------------------------------------
   */
  // getProfileContentToKvArrArr(profileContent: Content): any {
  //  const arrKvAny: KvAny[] = [];

  //  if (!this.isNullOrEmpty(profileContent) && !this.isNullOrEmpty(profileContent.contents)) {
  //    // debugger;
  //    let kvAany: KvAny = new KvAny();

  //    if (profileContent.heading && profileContent.heading.length > 0) {
  //      kvAany = new KvAny();
  //      kvAany.key = 'ProfileName';
  //      kvAany.value = profileContent.heading;
  //      arrKvAny.push(kvAany);
  //    }
  //    if (profileContent.aBstract && profileContent.aBstract.length > 0) {
  //      kvAany = new KvAny();
  //      kvAany.key = 'Highlight';
  //      kvAany.value = [profileContent.aBstract];
  //      arrKvAny.push(kvAany);
  //    }
  //    if (profileContent.contents && profileContent.contents.length > 0) {
  //      kvAany = new KvAny();
  //      kvAany.key = 'Description';
  //      kvAany.value = [profileContent.contents];
  //      arrKvAny.push(kvAany);
  //    }
  //  }
  //  // debugger;
  //  return arrKvAny;
  // }
  /*
   * -------------------------------------------------------------
   * Note: Order of the entries reflects as is on the view
   * -------------------------------------------------------------
   */
  // getPeferenceToKvArrArr(preference: Preference): any {
  //  const arrKvAnyr: KvAny[] = [];

  //  if (preference) {
  //    // debugger;
  //    let kvAny: KvAny = new KvAny();

  //    if (preference.selectedRelationshipPreferencesString && preference.selectedRelationshipPreferencesString.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'My relationship preference';
  //      kvAny.value = this.getArrayFromCommaSeparatedData(preference.selectedRelationshipPreferencesString);
  //      arrKvAnyr.push(kvAny);
  //    }

  //    if (preference.selectedIntosString && preference.selectedIntosString.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'I am into';
  //      kvAny.value = this.getArrayFromCommaSeparatedData(preference.selectedIntosString);
  //      arrKvAnyr.push(kvAny);
  //    }
  //    if (preference.selectedLookingForsString && preference.selectedLookingForsString.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'I am looking for';
  //      kvAny.value = this.getArrayFromCommaSeparatedData(preference.selectedLookingForsString);
  //      arrKvAnyr.push(kvAny);
  //    }
  //    if (preference.selectedMeetingLocationsString && preference.selectedMeetingLocationsString.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Meeting place';
  //      kvAny.value = this.getArrayFromCommaSeparatedData(preference.selectedMeetingLocationsString);
  //      arrKvAnyr.push(kvAny);
  //    }
  //    if (preference.selectedHobbiesString && preference.selectedHobbiesString.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'My hobbies';
  //      kvAny.value = this.getArrayFromCommaSeparatedData(preference.selectedHobbiesString);
  //      arrKvAnyr.push(kvAny);
  //    }
  //    if (preference.selectedPetsString && preference.selectedPetsString.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'What about pet';
  //      kvAny.value = this.getArrayFromCommaSeparatedData(preference.selectedPetsString);
  //      arrKvAnyr.push(kvAny);
  //    }
  //    if (preference.selectedPetPeevesString && preference.selectedPetPeevesString.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'My pet peeves';
  //      kvAny.value = this.getArrayFromCommaSeparatedData(preference.selectedPetPeevesString);
  //      arrKvAnyr.push(kvAny);
  //    }
  //  }
  //  return arrKvAnyr;
  // }
  /*
   * array = Array.from(Object.keys(object), k => object[k]);
   * -------------------------------------------------------------
   * Note: Order of the entries reflects as is on the view
   * -------------------------------------------------------------
   */
  static getObjectModelToArrKv(model: any): any {
    const arrKv: KV[] = [];
    let kv: KV = new KV();

    if (!this.isNullOrEmpty(model)) {
      const arrayKeys = Array.from(Object.keys(model), (k) => {
        kv = new KV();
        kv.key = k;
        arrKv.push(kv);
      });
      /*
       * arrayKeys = Array.from(Object.values(model), k => {
       * kv = new KV();
       * kv.key = k;
       * arrKv.push(kv);
       * });
       */
    }
    return arrKv;
  }
  /*
   * -------------------------------------------------------------
   * Note: Order of the entries reflects as is on the view
   * -------------------------------------------------------------
   */
  // getProfileInfoToKvArrArr(profileInfo: ProfileInfo): any {
  //  const arrKvAny: KvAny[] = [];

  //  if (profileInfo) {
  //    // debugger;
  //    let kvAny: KvAny = new KvAny();

  //    if (profileInfo.gender && profileInfo.gender.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Gender';
  //      kvAny.value = profileInfo.gender;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.age > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Age';
  //      kvAny.value = profileInfo.age;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.sexuality && profileInfo.sexuality.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Sexuality';
  //      kvAny.value = profileInfo.sexuality;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.position && profileInfo.position.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Position';
  //      kvAny.value = profileInfo.position;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.dickSize && profileInfo.dickSize.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Dick Size';
  //      kvAny.value = profileInfo.dickSize;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.cutUncut && profileInfo.cutUncut.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Cut/Uncut';
  //      kvAny.value = profileInfo.cutUncut;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.highestEducation && profileInfo.highestEducation.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'My highest education level';
  //      kvAny.value = profileInfo.highestEducation;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.relationshipStatus && profileInfo.relationshipStatus.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'My relationship status';
  //      kvAny.value = profileInfo.relationshipStatus;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.smokingStatus && profileInfo.smokingStatus.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Smoking/Vaping status';
  //      kvAny.value = profileInfo.smokingStatus;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.distance && profileInfo.distance.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Distance';
  //      kvAny.value = profileInfo.distance;
  //      arrKvAny.push(kvAny);
  //    }

  //    if (profileInfo.neighborhood && profileInfo.neighborhood.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Neighborhood';
  //      kvAny.value = profileInfo.neighborhood;
  //      arrKvAny.push(kvAny);
  //    }
  //    if (profileInfo.city && profileInfo.city.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'City';
  //      kvAny.value = profileInfo.city;
  //      arrKvAny.push(kvAny);
  //    }
  //    if (profileInfo.regionCode && profileInfo.regionCode.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'State/Profince/Territory';
  //      kvAny.value = profileInfo.regionCode;
  //      arrKvAny.push(kvAny);
  //    }
  //    if (profileInfo.countryCode && profileInfo.countryCode.length > 0) {
  //      kvAny = new KvAny();
  //      kvAny.key = 'Country';
  //      kvAny.value = profileInfo.countryCode;
  //      arrKvAny.push(kvAny);
  //    }
  //  }
  //  return arrKvAny;
  // }

  // ---------------------------------------------------------------
  static maskPassword (model : any) : any {
    if (!this.isNullOrEmpty(model)
      && !this.isNullOrEmpty(model.password)
      && model.password.length > 0) {
      let mask = '';

      for (const item of model.password) {
        mask = mask + '*';
      }
      model.password = mask;
    }
    return model;
  }
 
  /*
   * -------------------------------------------------------------
   * ref:https:// bitcoin.stackexchange.com/questions/52727/byte-array-to-hexadecimal-and-back-again-in-javascript
   * byteArray = new Uint8Array([181, 143, 16, 173, 231, 56, 63, 149, 181, 185, 224, 124, 84, 230, 123, 36]);
   */
  /*
   * tohexString ( byteArray )  : any {
   * return Array.prototype.map.call(byteArray, (byte) => {
   *  return ('0' + (byte & 0xFF).toString(16)).slice(-2);
   * }).join('');
   * }
   */
  // -------------------------------------------------------------
  static toByteArray(hexString: string): any {
    const result : any = [];

    for (let i = 0; i < hexString.length; i += 2) {
      // result.push(parseInt(hexString.substr(i, 2), 16)); //TODO: resotre
    }
    return result;
  }
  /*
   * ------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript
   * string-2-b64, and decode b64-2-string
   */
  static strTobase64 (str : any): any {
    return btoa(unescape(encodeURIComponent(str)));
  }
  static base64ToStr (b64Str : any): any {
    return decodeURIComponent(escape(window.atob(b64Str)));
  }
  /*
   * ------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript
   * b64EncodeUnicode(str): any {
   *  // returnbtoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
   *  // returnString.fromCharCode('0x' + p1); //TODO: test by (parseInt('0x' + p1))
   *  // }));
   * }
   * Usage example:
   * b64EncodeUnicode('✓ à la mode');  //'4pyTIMOgIGxhIG1vZGU='
   * b64EncodeUnicode('\n'); //'Cg=='
   */

  static b64DecodeUnicode (str : any): any {
    return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  }
  /*
   * Usage example:
   * b64DecodeUnicode('4pyTIMOgIGxhIG1vZGU='); //'✓ à la mode'
   * b64DecodeUnicode('Cg=='); //'\n'
   * ==================================================================
   * Note: this is incomplete!!
   */
  // static stringToBase64(str): any {
  //   if (!this.isNullOrEmpty(str)) {
  //     return str;
  //   }
  // }
  // ------------------------------------------------------------------
  /*
   * parseString (el : any, name : any, value : any, namespace: any)  : any {
   * let str : any;
   * const parseString = (el, name, value, namespace) => {
   *  const format = /[ `!@#$%^&*()_+\-=\[\]{};':'\\|,.<>\/?~]/;
   *  if (format.test(value) === true) {
   *    str = 'el==>' + el + '\n name==>' + name + '\n value ==>' + value + '\n namespace ==>' + namespace + '\n';
   *    console.error(str);
   *  }
   * };
   * return str;
   * }
   */
  /*
   * ref:https:// asecuritysite.com/encryption/js10
   * JavaScript StringHelper
   * ------------------------------------------------------------------
   * Note: this method has somepossible flaws. !!!
   */
  /*
   * bytes2hex(blk, dlm)  : any {
   * const s =  new Uint8Array(blk.buffer || blk);
   * return Array.prototype.map.call ( s,
   *  (s) =>  {
   *    return ('00' + s.toString(16)).slice(-2);
   *  }).join(dlm || '');
   * }
   * toHexString(byteArray)  : any {
   * return Array.from(byteArray, (byte:any) => {
   *  return ('0' + (byte & 0xFF).toString(16)).slice(-2);
   * }).join('');
   * }
   */
  // ------------------------------------------------------------------
  /*
   * from_Hex(h)  : any {
   * h.replace(' ', '');
   * const out = [];
   * const len = h.length;
   * const w = '';
   * for (let i = 0; i < len; i += 2) {
   *  w = h[i];
   *  if (((i + 1) >= len) || typeof h[i + 1] === 'undefined') {
   *    w += '0';
   *  } else {
   *    w += h[i + 1];
   *  }
   *  out.push(parseInt(w, 16));
   * }
   * return out;
   * }
   */
  // ------------------------------------------------------------------
  /*
   * bytesEqual(a, b)  : any {
   * let dif = 0;
   * if (a.length !== b.length) {
   *  return 0;
   * }
   * for (let i = 0; i < a.length; i++) {
   *  dif |= (a[i] ^ b[i]);
   * }
   * dif = (dif - 1) >>> 31;
   * return (dif & 1);
   * }
   */
  // ------------------------------------------------------------------
  /*
   * hexStringToByte(str)  : any {
   * if (!str) {
   *  return new Uint8Array(null, 0, 0);
   * }
   *
   * const a = [];
   * for (let i = 0, len = str.length; i < len; i += 2) {
   *  a.push(parseInt(str.substr(i, 2), 16));
   * }
   *
   * return new Uint8Array(a);
   * }
   */
  // ------------------------------------------------------------------
  // asciiToHexa(str): any {
  //  const arr1 = [];

  //  for (let n = 0, l = str.length; n < l; n++) {
  //    const hex = Number(str.charCodeAt(n)).toString(16);

  //    arr1.push(hex);
  //  }
  //  return arr1.join('');
  // }

  // ------------------------------------------------------------------
  /*
   * xor(a, b)  : any {
   *
   * const res = [];
   * if (a.length > b.length) {
   *  for (let i = 0; i < b.length; i++) {
   *    res.push(a[i] ^ b[i]);
   *  }
   * } else {
   *  for (let i = 0; i < a.length; i++) {
   *    res.push(a[i] ^ b[i]);
   *  }
   * }
   * return res;
   * }
   */
  // ------------------------------------------------------------------
  // copied from TweetNaCl:
  // ------------------------------------------------------------------
  static validateBase64 (s : any) : boolean {
    let isValidB64 : boolean = /^(?:[A-Za-z0-9+\/]{2}[A-Za-z0-9+\/]{2})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/.test(s);
    if (!isValidB64) {
      throw new TypeError('invalid encoding');
    }
    else return isValidB64;
  }
  /*
   * ------------------------------------------------------------------
   * ==================================================================
   * String-to-ArrayBuffer-to-String
   * ==================================================================
   * NOTE: Use these two only for this purpose!
   * Tested. Works without stackoverflow exception!
   * ******************************************************************/   
   /* ------------------------------------------------------------------
   * ref:https:// www.npmjs.com/package/base64-arraybuffer
   */
  static arrBufferToB64 (buff : any): any {
    // return encode(buff);
    return naclUtil.encodeBase64(buff);
  }
  static b64ToArrBuff (b64Str : any): any {
    // return decode(b64Str);
    return decode(b64Str);
  }
  /*
   * ==================================================================
   * ******************************************************************
   * Note: Did NOT work using Unit8Array to convert from salted to unsalted.
   *      May 19, 2020
   *      Also todo: try 'utf8' and regular buffer instead of arrayBuffer
   * ------------------------------------------------------------------
   */
  static getAbStr (str : any): any {
    return str;
  }
  // bToBase64Str2(buf): any {
  //  return this.bufferToString2(buf, 'base64', this.getAbStr);
  // }
  // bToUtf8Str2(buf): any {
  //  return this.bufferToString2(buf, 'utf-8', this.getAbStr);
  // }
  /*
   * ------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/37234333/arraybuffer-to-string-string-to-arraybuffer-methods
   */
  // bufferToString2(buffer, encoding, callback): any {
  //  const blob = new Blob([buffer], { type: 'text/plain'});
  //  const reader = new FileReader();

  //  reader.onload = (evt) => {
  //    callback(evt.target.result);
  //  };
  //  return reader.readAsText(blob, encoding);
  // }
  /*
   * -------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/8609289/convert-a-binary-nodejs-buffer-to-javascript-arraybuffer
   */
  static buffertoUnit8Array (buf : any): any {
    const ab = new ArrayBuffer(buf.length);
    const view = new Uint8Array(ab);

    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }
  /*
   * -------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/8609289/convert-a-binary-nodejs-buffer-to-javascript-arraybuffer
   */
  static Unit8ArraytoBuffer (ab : any): any {
    const buf = Buffer.alloc(ab.byteLength);
    const view = new Uint8Array(ab);

    for (let i = 0; i < buf.length; ++i) {
      buf[i] = view[i];
    }
    return buf;
  }

  /*
   * -------------------------------------------------------------
   * FileReader with a Promise to read text
   * ref:https:// blog.shovonhasan.com/using-promises-with-filereader/
   */
  static readUploadedFileAsArrayBuffer = (inputFile : any) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsArrayBuffer(inputFile);
    });
  }
  /*
   * -------------------------------------------------------------
   * FileReader with a Promise to read ArrayBuffer
   * ref:https:// blog.shovonhasan.com/using-promises-with-filereader/
   */
  static readStringUsingFileReaderAsArrayBuffer = (inputstring : any, encoding : any) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };
      const blob = new Blob([inputstring], { type: 'text/plain;charset=' + encoding});

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsArrayBuffer(blob);
    });
  }

  /*
   * -------------------------------------------------------------
   * FileReader with a Promise to read ArrayBuffer
   * ref:https:// blog.shovonhasan.com/using-promises-with-filereader/
   */
  static getImageFileAsBlob = (inputstring : any, encoding : any) => {
    const temporaryFileReader = new FileReader();
    const blob = new Blob([inputstring], { type: 'image/jpeg;charset=' + encoding});

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsArrayBuffer(blob);
    });
  }
  // ------------------------------------------------------------------
  static readFile (file : any, onLoadCallback : any): any {
    const reader = new FileReader();

    reader.onload = onLoadCallback;
    reader.readAsText(file);
    return true;
  }
  /*
   * ------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/37234333/arraybuffer-to-string-string-to-arraybuffer-methods
   */
  static stringToArrayBuffer (str : any, enc : any, callback : any): any {
    const blob = new Blob([str], { type: 'text/plain;charset=' + enc});
    const reader = new FileReader();

    reader.onload = ( evt ) =>
    {
      if ( this.isNullOrEmpty( evt ) )
      {
         // callback( evt.target.result ); // TODO : restore
      }
    };
    reader.readAsArrayBuffer(blob);
    return true;
  }

  /*
   * Usage example:
   * var buf = new Uint8Array([65, 66, 67]);
   * arrayBufferToString(buf, 'UTF-8', console.log.bind(console)); //'ABC'
   */

  /*
   * stringToArrayBuffer('ABC', 'UTF-8', console.log.bind(console)); //[65,66,67]
   * ------------------------------------------------------------------
   * ref:https:// www.npmjs.com/package/arraybuffer-to-string
   * Tested: works!
   * Note:: Throws Maximum Call Stack exceeded on ArrayBufferToString
   * ------------------------------------------------------------------
   */
  static ab2stBase64 (u8arr : any, type: any) : any {
    let typ : any = 'utf8'
    let tByff = Buffer.from(u8arr, this.isNullOrEmpty(type)? typ : type);
    return tByff.toString()// ab2str(u8arr, 'base64');
  }
  static ab2str (u8arr : any): any {
    const result = this.ab2stBase64(u8arr, 'utf8');
    // debugger;

    return result;
  }
  static ab2stHex (u8arr : any): any {
    return this.ab2stBase64(u8arr, 'hex');
  }
  static ab2stISO88592 (u8arr : any): any {
    return this.ab2stBase64(u8arr, 'iso-8859-2');
  }
  /*
   * ------------------------------------------------------------------
   * ref:https:// www.npmjs.com/package/string-to-arraybuffer
   * TODO:Test
   */
  static str2Ab (st : any): any {
    return new Buffer(st);
  }
  static base64Str2Ab (st : any): any {
    return new Buffer(st);
  }
  static dataUriStr2Ab (st : any): any {
    return new Buffer(st);
  }
  static base64DataUriStr2Ab (st : any): any {
    return new Buffer(st);
  }
  // ------------------------------------------------------------------------------------------------
  // ref:https:// ourcodeworld.com/articles/read/164/how-to-convert-an-uint8array-to-string-in-javascript#:~:
  //   text=An%20Uint8Array%20is%20a%20typed%20array%20that%20represents,an%20Uint8Array%20to%20a%20regular%20string%20in%20javascript.
  // ---------------------------------------------------------
  // Note: did not work. 2020/09/14
  // **
  // * Convert an Uint8Array into a string.
  // *
  // * @returns {String}
  // */
  static Decodeuint8arr (uint8array : any): any {
    return new TextDecoder('utf-8').decode(uint8array); // input should be ArrayBuffer or ArrayBufferView
  }

  /**
   * Convert a string into a Uint8Array.
   *
   * @returns { Uint8Array}
   */
  static Encodeuint8arr (myString : any): any {
    return new TextEncoder().encode(myString);
  }

  // ---------------------------------------------------------
  // Note: did not work. 2020/09/14
  // **
  // * Converts an array buffer to a string
  // *
  // * @param {Uin8} uint8arr | The buffer to convert
  // * @param {Function} callback | The function to call when conversion is complete
  // */
  // largeuint8ArrToString(uint8arr, callback): any {
  //  const bb = new Blob([uint8arr]);
  //  const f = new FileReader();

  //  f.onload = (e) => {
  //    callback(e.target.result);
  //  };

  //  f.readAsText(bb);
  // }

  /*
   * Usage example
   * 'Hello' in Uint8Array format
   */
  // usageExample(): any {
  //  const myuint8Arr = new Uint8Array([72, 101, 108, 108, 111, 32, 33]);

  //  this.largeuint8ArrToString(myuint8Arr, (text) => {
  //    // Hello
  //    console.log(text);
  //  });
  // }
  /*
   * ----------------------------------------------------------
   * http:// www.onicos.com/staff/iz/amuse/javascript/expert/utf.txt
   */

  // * utf.js - UTF-8 <=> UTF-16 convertion
  // *
  // * Copyright (C) 1999 Masanao Izumo <iz@onicos.co.jp>
  // * Version: 1.0
  // * LastModified: Dec 25 1999
  // * This library is free.  You can redistribute it and/or modify it.
  // */
  /*
   * unit8ArrayToStr(array)  : any {
   * let out : any;
   * const i = 0;
   * const len = array.length;
   * const c;
   * let char2;
   * let char3;
   *
   * while (i < len) {
   *  c = array[i++];
   *  switch (c >> 4) {
   *    case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
   *      //0xxxxxxx
   *      out += String.fromCharCode(c);
   *      break;
   *    case 12: case 13:
   *      //110x xxxx   10xx xxxx
   *      char2 = array[i++];
   *      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
   *      break;
   *    case 14:
   *      //1110 xxxx  10xx xxxx  10xx xxxx
   *      char2 = array[i++];
   *      char3 = array[i++];
   *      out += String.fromCharCode(((c & 0x0F) << 12) |
   *        ((char2 & 0x3F) << 6) |
   *        ((char3 & 0x3F) << 0));
   *      break;
   *  }
   * }
   *
   * return out;
   * }
   */
  // ------------------------------------------------------------------------------------------------
  static createMimeType(): any {
    let mimeTypes: any[] = [];
    let kv = new KV();

    kv.key = '.txt';
    kv.value = 'text/plain';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.pdf';
    kv.value = 'application/pdf';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.doc';
    kv.value = 'application/vnd.ms-word';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.docx';
    kv.value = 'application/vnd.ms-word';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.xls';
    kv.value = 'application/vnd.ms-excel';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.xlsx';
    kv.value = 'application/vnd.openxmlformats  officedocument.spreadsheetml.sheet';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.png';
    kv.value = 'image/png';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.jpg';
    kv.value = 'image/jpeg';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.jpeg';
    kv.value = 'image/jpeg';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.gif';
    kv.value = 'image/gif';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.csv';
    kv.value = 'text/csv';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.css';
    kv.value = 'text/css';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.js';
    kv.value = 'javascript';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.html';
    kv.value = 'text/html';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.xml';
    kv.value = 'text/xml';
    mimeTypes.push(kv);
    return mimeTypes;
  }
  // ------------------------------------------------------------------------------------------------
  static getMimeType(typ: string): any {
    // debugger;
    let type = '';

    if (typ && typ.length >= 3) {
      let typArr : any[] = [];

      if (typ.indexOf('/') !== -1) {
        typArr = typ.split('/'); // incase mime-type is provided
      } else if (typ.indexOf('.') !== -1) {
        typArr = typ.split('.'); // incase file-name-with-extension is provided
      } else {
        typ = '.' + typ; // incase plain-file-extension is provided (without a '.'), create a file-extension with a '.'
      }
      if (typArr.length > 1) {
        type = '.' + typArr[1]; // create a file-extension with a '.'
      }
    }
    return this.createMimeType().mimeTypes.find((k : any) => k?.key === type).value; // TODO: restore
    // debugger;
    // return ''; // 
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// developers.google.com/web/updates/2012/06/Don-t-Build-Blobs-Construct-Them
   */
  static createBlob(data: any, typ: string): Blob {
    return new Blob([this.base64Str2Ab(data.toString())], { type: typ && typ.length > 0 ? typ : 'application/pdf'});
  }
  // ------------------------------------------------------------------------------------------------
  static createBlobElement(data: any, typ: string): any {
    const blob = this.createBlob(data, typ);
    const link = document.createElement('link');

    link.rel = this.getMimeType(typ);
    link.href = window.URL.createObjectURL(blob);
    return link;
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/23024460/javascript-i-created-a-blob-from-a-string-how-do-i-get-the-string-back-out
   */
  static blobToString (b : any): any {
    let u;
    let x;

    u = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open('GET', u, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(u);
    return x.responseText;
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * TODO: test
   * Note: Not sure if it works. 2020/09/09
   */
  // blobToBase64(blob: Blob): any {
  //  return new Promise((resolve, reject) => {
  //    const reader = new FileReader();

  //    reader.onerror = reject;
  //    reader.onload = () => {
  //      resolve(reader.result);
  //    };
  //    reader.readAsDataURL(blob as Blob);
  //    return reader.result.toString();
  //  });
  // }
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/18650168/convert-blob-to-base64
   */
  static BlobToBase64 (blob : any): Promise<any> {
    const reader = new FileReader();

    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
  /*
   * // Usage Example:
   *BlobToBase64(blobData).then(res => {
   *   //do what you wanna do
   *   console.log(res); //res is base64 now
   * });
   */
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// blobfolio.com/2019/10/better-binary-batter-mixing-base64-and-uint8array/
   */
  /**
   * Base64 to Blob
   *
   * @param { string} data Data.
   * @param { string} type Content type.
   * @return { !Blob} Blob.
   */
  static base64toBlob (data : any, typ : any): any {
    const bytes = atob(btoa(data));
    let length = bytes.length;

    if (length > 0) {
      const out = new Uint8Array(length);

      // Loop and convert.
      while (length > 0 && length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: typ});
    }
  }
  // ------------------------------------------------------------------------------------------------
  static base64toUint8Array (data : any): any {
    const bytes = atob(data);
    let length = bytes.length;
    const out = new Uint8Array(length);

    // Loop and convert.
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    return out;
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// blobfolio.com/2019/10/better-binary-batter-mixing-base64-and-uint8array/
   */
  static base64toImageUrl(base64Str: any): any {
    return URL.createObjectURL(this.base64toBlob(base64Str, 'image/png'));
  }
  // ------------------------------------------------------------------------------------------------
  //static Base64Encode(str, encoding = 'utf-8'): any {
  //  const bytes = new (typeof TextEncoder === 'undefined' ? textEncoder.TextEncoderLite : TextEncoder)(encoding).encode(str);

  //  return base64js.fromByteArray(bytes);
  //}

  //static Base64Decode(str, encoding = 'utf-8'): any {
  //  const bytes = base64js.toByteArray(str);

  //  return new (typeof TextDecoder === 'undefined' ? textEncoder : TextDecoder)(encoding).decode(bytes);
  //}
  /*
   * ------------------------------------------------------------------
   * getAbStr(str) : any {
   * return str;
   * }
   */
  static abToStr (buf : any): any {
    this.arrayBufferToString(buf, this.getAbStr);
  }
  // ------------------------------------------------------------------
  // TODO: test
  // **
  // * Converts an array buffer to a string
  // *
  // * @private
  // * @param {ArrayBuffer} buf The buffer to convert
  // * @param {Function} callback The function to call when conversion is complete
  // */
  static arrayBufferToString (buf : any, callback : any): any {
    const bb = new Blob([new Uint8Array(buf)]);
    const f = new FileReader();

    f.onload = (e) => {
      // debugger;
      callback(e.target?.result);
    };
    f.readAsText(bb);
  }
  /*
   * ------------------------------------------------------------------
   * TODO: test
   * Did not work for boxNonce
   */
  /*
   * utf8ArrayToStr(array)  : any {
   *  const charCache = new Array(128);  //Preallocate the cache for the common single byte chars
   *  const charFromCodePt = String.fromCodePoint || String.fromCharCode;
   *  const result = [];
   *  let codePt;
   *  let byte1;
   *  const buffLen = array.length;
   *
   *  result.length = 0;
   *
   *  for (let i = 0; i < buffLen;) {
   *    byte1 = array[i++];
   *
   *    if (byte1 <= 0x7F) {
   *      codePt = byte1;
   *    } else if (byte1 <= 0xDF) {
   *      codePt = ((byte1 & 0x1F) << 6) | (array[i++] & 0x3F);
   *    } else if (byte1 <= 0xEF) {
   *      codePt = ((byte1 & 0x0F) << 12) | ((array[i++] & 0x3F) << 6) | (array[i++] & 0x3F);
   *    } else if (String.fromCodePoint) {
   *      codePt = ((byte1 & 0x07) << 18) | ((array[i++] & 0x3F) << 12) | ((array[i++] & 0x3F) << 6) | (array[i++] & 0x3F);
   *    } else {
   *      codePt = 63;    //Cannot convert four byte code points, so use '?' instead
   *      i += 3;
   *    }
   *
   *    result.push(charCache[codePt] || (charCache[codePt] = charFromCodePt(codePt)));
   *  }
   *
   *  return result.join('');
   * }
   */
  /*
   * ------------------------------------------------------------------
   * TODO: test
   * Did not work for boxNonce
   */
  static unit8ArayToString (uint8array : any): any {
    let myString = '';

    uint8array.map((e : any) => {
      myString += String.fromCharCode(e);
    });
    return myString;
  }
  /*
   * ------------------------------------------------------------------
   * TODO: test
   * Did not work for boxNonce
   */
  /*
   * bytesToSring(bytes)  : any {
   * const chars = [];
   * bytes.map(e => {
   *  chars.push(((e & 0xff) << 8) | (e & 0xff));
   * });
   * return String.fromCharCode.apply(null, chars);
   * }
   */

  /*
   * https:// codereview.stackexchange.com/a/3589/75693
   * TODO: test
   */
  /*
   * stringToBytes(str)  : any {
   * const bytes = [];
   * let i = 0;
   * str.map(e => {
   *  const char = str.charCodeAt(i);
   *  bytes.push(char >>> 8, char & 0xFF);
   *  i++;
   * });
   * return bytes;
   * }
   */
  /*
   * ------------------------------------------------------------------
   * TODO: test
   * Did not work for boxNonce
   */
  /*
   * Utf8ArrayToStr(array)  : any {
   * let out;
   * const i;
   * const len;
   * const c;
   * let char2;
   * let char3;
   *
   * out = '';
   * len = array.length;
   * i = 0;
   * while (i < len) {
   *  c = array[i++];
   *  switch (c >> 4) {
   *    case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
   *      //0xxxxxxx
   *      out += String.fromCharCode(c);
   *      break;
   *    case 12: case 13:
   *      //110x xxxx   10xx xxxx
   *      char2 = array[i++];
   *      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
   *      break;
   *    case 14:
   *      //1110 xxxx  10xx xxxx  10xx xxxx
   *      char2 = array[i++];
   *      char3 = array[i++];
   *      out += String.fromCharCode(((c & 0x0F) << 12) |
   *        ((char2 & 0x3F) << 6) |
   *        ((char3 & 0x3F) << 0));
   *      break;
   *  }
   * }
   *
   * return out;
   * }
   */
  /*
   * ------------------------------------------------------------------
   * TODO: test
   */
  static stringArray(str: any): any {
    const ret = new Uint8Array(str.length);

    for (let i = 0; i < str.length; i++) {
      ret[i] = str.charCodeAt(i);
    }
    return ret;
  }
  /*
   * ------------------------------------------------------------------
   * TODO: test
   */
  static jsonToArray (json : any): any {
    const str = JSON.stringify(json, null, 0);

    return this.stringArray(str);
  }
  /*
   * ------------------------------------------------------------------
   * TODO: test
   */
  static binArrayToJson(binArray: []): any {
    let str = '';
    let i = 0;

    binArray.map((e) => {
      str += String.fromCharCode(parseInt(binArray[i], 10));
      i++;
    });
    return JSON.parse(str);
  }
  /*
   * ------------------------------------------------------------------
   * TODO: test
   */
  static binArrayToString (binArray : any): any {
    return JSON.stringify(this.binArrayToJson(binArray));
  }
  // ------------------------------------------------------------------
  // ref:https:// stackoverflow.com/questions/8936984/uint8array-to-string-in-javascript
  // TODO: test
  // Did not work for boxNonce
  // utf8ArrayToString(aBytes)  : any {
  //  let sView = '';
  //
  // for (let nPart, nLen = aBytes.length, nIdx = 0; nIdx < nLen; nIdx++) {
  //   nPart = aBytes[nIdx];
  //
  //   sView += String.fromCharCode(
  //     nPart > 251 && nPart < 254 && nIdx + 5 < nLen ? /* six bytes */
  //       /* (nPart - 252 << 30) may be not so safe in ECMAScript! So...: */
  //       (nPart - 252) * 1073741824 + (aBytes[++nIdx] - 128 << 24) + (aBytes[++nIdx] - 128 << 18) +
  //           (aBytes[++nIdx] - 128 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
  //       : nPart > 247 && nPart < 252 && nIdx + 4 < nLen ? /* five bytes */
  //         (nPart - 248 << 24) + (aBytes[++nIdx] - 128 << 18) + (aBytes[++nIdx] - 128 << 12) +
  //               (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
  //         : nPart > 239 && nPart < 248 && nIdx + 3 < nLen ? /* four bytes */
  //           (nPart - 240 << 18) + (aBytes[++nIdx] - 128 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
  //           : nPart > 223 && nPart < 240 && nIdx + 2 < nLen ? /* three bytes */
  //             (nPart - 224 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
  //             : nPart > 191 && nPart < 224 && nIdx + 1 < nLen ? /* two bytes */
  //               (nPart - 192 << 6) + aBytes[++nIdx] - 128
  //               : /* nPart < 127 ? */ /* one byte */
  //               nPart
  //   );
  // }
  //
  // return sView;
  // }

  // let str = utf8ArrayToString([50, 72, 226, 130, 130, 32, 43, 32, 79, 226, 130, 130, 32, 226, 135, 140, 32, 50, 72, 226, 130, 130, 79]);

  // // Must show 2H₂ + O₂ ⇌ 2H₂O
  // console.log(str);
  // ------------------------------------------------------------------
  // NOTE: The following are Array-related-functions
  // ------------------------------------------------------------------

  // ---------------------------------------------------------------
  static arrayToCsvString(inArr: any[]): any {
    if (inArr.length > 0) {
      let csvStr = '';

      for (let i = 0; i < inArr.length - 1; i++) {
        csvStr += csvStr + inArr[i].toString() + ', ';
      }
      csvStr += csvStr + inArr[inArr.length - 1].toString();
      return csvStr;
    }
    return '';
  }

  // ---------------------------------------------------------------
  static getArrayFromCommaSeparatedData (data : any): any {
    let tArray = [];

    if (data) {
      tArray = data.split(',');
      if (tArray.length > 0) {
        // truncate the last empty index due to the last ',' in the data-string
        tArray.splice(tArray.length - 1, 1);
      }
    }
    return tArray;
  }
  
   // ------------------------------------------------------------------
   // Tested, works!
  //  On 20220328
   // ------------------------------------------------------------------
  static createArrayFromMap(inmap: Map<any, any>): any[] {
    const arr: any[] = [];

    if (inmap && inmap.size > 0) {
      inmap.forEach((value, key) =>
      {
        arr.push(value);
      });
    }
    return arr;
   }
  // ------------------------------------------------------------------
  //  Tested, works!
  // ------------------------------------------------------------------
  static createMapFromArray(arr: any[]): Map<number, any> {
    const map = new Map<number, any>();

    if (arr && arr.length > 0) {
      arr.forEach(function callback (key, value) {
        map.set(value, key);
      });
    }
    return map;
  }
  // ------------------------------------------------------------------
  b2Str(b: []): any {
    return String.fromCharCode(...new Uint8Array(b));
  }
  /*
   * -------------------------------------------------------------
   * FileReader with a Promise to read text
   * ref:https:// blog.shovonhasan.com/using-promises-with-filereader/
   */
  readUploadedFileAsText = (inputFile : any) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  }
  /*
   * -------------------------------------------------------------
   * A Simplified API with Async/Await
   * ref:https:// blog.shovonhasan.com/using-promises-with-filereader/
   * Note:an example onchange handler we could pass to a <input type='file /> element
   */
  handleUpload = async (event : any) => {
    const file = event.target.files[0];

    try {
      const fileContents = await this.readUploadedFileAsText(file);

      console.log(fileContents);
    } catch (e) {
      console.warn(e);
    }
  }
  // ---------------------------------------------------------------------------------
  //  Do NOT Delete this!
  // --------------------
  //  This method is not complete, but it has enough functionalities for the following
  //  objective:
  //
  //    Server-sent images may arrive in following three form:
  //      1) as an array of Photo model within it has image and pic properties
  //      2) as raw-image-data
  //      3) as file-name-of the data which is used with url() to render the image
  //    Until the backend is streamlined choosing only one type of data to send to the
  //    client, we have to parse the string-format-data to create the Photo model.
  //    this proess is very complicated since C# model parameters are PascalCased,
  //    but the client uses camelCase. Hence inorder to convert these properties' names,
  //    the string-data needs to be parsed, and this method attempted to do just that.
  // ---------------------------------------------------------------------------------
  //public static photoModelStringToCamelCase (str : string) : string {
  //  let camelCased = "{"
  //  let camelKey = "";
  //  let k = "";
  //  let v = "";
  //  let camelValue = "";
  //  if (!this.isNullOrEmpty(str) && str.length > 2) {
  //    let kvs = str.split(",");
  //    // debugger;
  //    let j = 0;
  //    if (!this.isNullOrEmpty(kvs) && kvs.length > 0) {
  //      kvs.forEach(kv => {
  //        if (!this.isNullOrEmpty(kv)) {
  //          // debugger;
  //          if (kv.indexOf("{") !== -1 || kv.indexOf("}") !== -1 )  {
  //            kv = JsRegExpServiceStatic.trimBrackets(kv, "{");
  //          }
  //          // debugger;
  //          // if (!this.isNullOrEmpty(kv)) {
  //          //  // Note:  1: will trim the prefix \" JSON quote,
  //          //  //        2: will trim both prefix and  postfix \" JSON quote.
  //          //  // -----------------------------------------------------------
  //          //  kv = JsRegExpServiceStatic.trimQuote(kv, 2);
  //          // }
  //          if (!this.isNullOrEmpty(kv)) {

  //            let kvParts = kv.split(":");
  //            // debugger;
  //            if (!this.isNullOrEmpty(kvParts) && kvParts.length > 0) {
  //              k = kvParts[ 0 ].trim();

  //              // Note: important!!" remove JSON style \" quote at the front at least so that lowerFirstLetter() will work.

  //              // Note:  1: will trim the prefix \" JSON quote,
  //              //        2: will trim both prefix and  postfix \" JSON quote.
  //              //        3: will trim the postfix \" JSON quote,
  //              // -----------------------------------------------------------
  //              k = JsRegExpServiceStatic.trimQuote(k, 2); 
  //               debugger;
  //              if (!this.isNullOrEmpty(k) && k.length > 0) {
  //                camelKey = this.lowercaseFirstLetter(k);
  //                // debugger;
  //              }
  //              if (!this.isNullOrEmpty(camelKey)) {
  //                // Note: reattach JSON style \" quotes so that JSON will work.
  //                // -----------------------------------------------------------

  //                if (kvParts[ 1 ].charAt(0) === '"') {
  //                  camelValue = "\"" + kvParts[ 1 ] + "\",";
  //                }
  //                else /*if (kvParts[ 1 ].charAt(0) === '\\')*/ {
  //                  camelValue = kvParts[ 1 ] + ",";
  //                }
  //                debugger;
  //                camelCased += "\"" + camelKey + "\"" + v;
  //                 debugger;
  //              }
  //              else {
  //                camelCased += "\"" + k + "\"" + camelValue;
  //                 debugger;
  //              }
  //            } // end of kvParts.length > 0
  //          } // end of innder !this.isNullOrEmpty(kv)
  //        } // end of outer !this.isNullOrEmpty(kv)
  //      });        
  //    } // end of  kvs.length > 0
  //  } // end of str.length > 2
  //  debugger;
  //  camelCased.length > 1 ? camelCased += "}" : camelCased = "";
  //  alert('camelCased : \n' + JSON.parse(camelCased));
  //  debugger;
  //  return camelCased;
  //}
  // ---------------------------------------------------------------------------------
  // here we create a Photo model from the string and then cameCase the model:
  // ---------------------------------------------------------------------------------

  // --------------------------------------------------------------
}
