import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

export class Name {
  public given_name = '';
  public surname = '';
  public fullName = '';
  // ----------------------------------------------------------------------------------
  constructor() {
  }
  // ----------------------------------------------------------------------------------
  public copy ( from : Name ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.given_name = from.given_name;
      this.surname = from.surname;
      this.fullName = from.fullName;
    }
  }
  // ----------------------------------------------------------------------------------
  public captureNameData ( name : Name ) : Name
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name )
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name.given_name )
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name.surname ) )
    {
      this.given_name = name.given_name;
      this.surname = name.surname;
      this.fullName = this.given_name + ' ' + this.surname;
    }
    return this;
  }
  // ----------------------------------------------------------------------------------
  public getFullName (name: Name) : string
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name )
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name.given_name )
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name.surname ) )
    {
      this.fullName = this.given_name + ' ' + this.surname;      
    }
    return this.fullName;
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n' +
      ',\n given_name: ' +
      this.given_name +

      ',\n' +
      'surname: ' +
      this.surname +

      ',\n' +
      'fullName: ' +
      this.fullName +

      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
