import { Directive, ElementRef, forwardRef, HostListener, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

// ref: https://coryrylan.com/blog/using-html5-date-input-with-date-objects-and-angular
@Directive({
  selector: 'input[type=date][ngModel], input[type=date][formControl]',
  providers: [ {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => DateInputFormControlDirective),
    multi: true
  } ]
})
export class DateInputFormControlDirective implements ControlValueAccessor {
  @HostListener('show', []) onTouched : any = () => { };
  @HostListener('input', [ '$event' ]) onChange : any = () => { };

  private valueType : 'value' | 'valueAsDate' = 'value';

  constructor (private renderer : Renderer2, private elementRef : ElementRef) { }

  registerOnTouched (fn : () => void) { this.onTouched = fn; }

  registerOnChange (fn : (value : any) => void) {
    this.onChange = (event : any) => fn(event.target[ this.valueType ])
  }

  writeValue (value : Date | string) {
    this.valueType = typeof value === 'string' ? 'value' : 'valueAsDate';
    this.renderer.setProperty(this.elementRef.nativeElement, this.valueType, value);
  }
}