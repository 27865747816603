// Note:  MessageModel will be used to display plain messages as well as spinner if necessary.

import { FrequentlyUsedFunctionsServiceStatic } from "../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";
import { DateStringServiceStatic } from "../../services/staticServices/commonStaticServices/dateStringServiceStatic.service";

//        Hence IsBusy Model will not be used for Spinner-System.
export class MessageModel {
  public message: any;
  public isSpinner = false;
  public isSuccess = false;
  public callerName = '';
  public id = 0;
  public date = '';
  public startTime = new Date().getTime();
  public endTime = this.startTime + 20000; // spinner will be displayed for 20 seconds
  public interval = 20000; // message will be displayed for 20 seconds
  constructor () { }

  getTimeDiff () : any {
    let diff = this.endTime - this.startTime;
    let dAssay = DateStringServiceStatic.dateInYYYYMMDDhhmmss(diff);
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dAssay)) {
      return dAssay;
    }
  }

// TODO: intend to depracate
  getTimeoutInterval () : any {

    if (this.endTime && this.startTime) {
      let eTime = this.endTime;
      let sTime = this.startTime;
      if (eTime > sTime) {
        this.interval = eTime - sTime + 5000;
      }
    }

    return this.interval;
  }
  setTimeoutInterval (interval : number) : void {
    this.interval = interval;
  }


}
