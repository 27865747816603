import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Name } from './name.model';
import { PayPalAddress } from './payPalAddress.model';

export class PayPalShipping {
  public address = new PayPalAddress();
  public addressStr = '';
  public PayPalAddressId = 0;
  public fullName = '';
  public date! : Date;
  public orderID = '';
  public PayPalOrderId = 0;
  public sitUserKey = '';
  // ----------------------------------------------------------------------------------
  constructor() {
    this.date = new Date;
  }
  // ----------------------------------------------------------------------------------
  public copy ( from: PayPalShipping ): void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {

      this.address.copy( from.address );
      this.addressStr = from.addressStr;
      this.PayPalAddressId = from.PayPalAddressId;
      this.fullName = from.fullName;
      this.date = from.date;
      this.orderID = from.orderID;
      this.PayPalOrderId = from.PayPalOrderId;
      this.sitUserKey = from.sitUserKey;
    }
  }
  // ----------------------------------------------------------------------------------
  public captureName ( name : Name ) : Name
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name ) )
    {
      let name = new Name();
      name = name.captureNameData( name );
      this.fullName = name.fullName;
    }
    return name;
  }
  // ----------------------------------------------------------------------------------
  public getFulName (name: Name) : string
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( name.fullName ))
    {
      this.fullName = name.fullName;
    }    
  return this.fullName;
  }
  // ----------------------------------------------------------------------------------
  public toStringCsv ()
  {
    let output = '';
    output += this.address.toString() + ',' + this.addressStr + ',' + this.date + ', ';
    output += this.fullName + ',' + this.PayPalOrderId + ',' + this.PayPalAddressId + ',' + this.sitUserKey;
    return output;
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n addressStr: ' +
      this.addressStr +

      ',\n' +
      'address:' +
      this.address.toString() +

      ',\n date: ' +
      this.date +

      ',\n fullName: ' +
      this.fullName +

      ',\n PayPalOrderId: ' +
      this.PayPalOrderId +
      
      ',\n PayPalAddressId: ' +
      this.PayPalAddressId +     

      ',\n sitUserKey: ' +
      this.sitUserKey +

      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
