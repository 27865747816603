<!--Tested and works! (Date:2020/June/17) (this shold be the template for other pagers)-->
<!--recreated on 20220429, 20221220, 20231012--> <!--inline-block-->
<!--===================================================================================-->

<ul id="pagerVerticalPhotoNavComponentId"
    class="flex-pagerV-element"
    style="top:2rem; left: 0rem; width: auto; height: 100%; position: relative; margin: 0rem; padding: 0rem; list-style: none; opacity: 1;z-index: 11; background: transparent; background-color: rgb(255, 255, 255, 0); overflow: visible;">
  <!--flex-pagerV-container -->
  <!----------------------------------------------------------------------------------------->
  <!--First & Prev glyph Arrows-->
  <!-- <li *ngIf="pagerVKvPhotoNav?.currentPageNo > 1"
      id="pagerVKvPhotoNav-F"
      class="text-center"
      style="display: inline-block; line-height: 0.74rem; margin-left: 0.75rem; padding: 0.1rem; cursor: pointer; ">
    <button (click)="setPage('F')"
            [disabled]="pagerVKvPhotoNav?.currentPageNo === 1"
            class="bttn-xxs-transparent hvr-underline-from-center text-center increase" type="button"
            style="display: inline-block; cursor: pointer;">
      <span *ngIf="pagerVKvPhotoNav?.currentPageNo <= 1" [ngClass]="{'glyphicon glyphicon-ban-circle errxSB': 'pagerVKvPhotoNav.currentPageNo <= 1'}"></span>
      <span class="glyphicon glyphicon-step-backward text-center hvr-icon bluGrnGldxS"
            style="vertical-align: middle;margin-top: -0.15rem;"
            aria-hidden="true"></span>
    </button>
  </li>
  <li *ngIf="pagerVKvPhotoNav?.currentPageNo > 1"
      id="pagerVKvPhotoNav-prev"
      class="text-center"
      style="display: inline-block; line-height: 0.74rem; margin-left: 0.75rem; padding: 0.1rem; cursor: pointer; ">
    <button (click)="setPage('prev')"
            [disabled]="pagerVKvPhotoNav?.currentPageNo === 1"
            class="bttn-xxs-transparent hvr-underline-from-center text-center increase" type="button"
            style="display: inline-block; cursor: pointer;">
      <span *ngIf="pagerVKvPhotoNav?.currentPageNo === 1" [ngClass]="{'glyphicon glyphicon-ban-circle errxSB': 'pagerVKvPhotoNav.currentPageNo <= 1'}" aria-hidden="true"></span>
      <span class="glyphicon glyphicon-backward text-center hvr-icon bluGrnGldxS"
            style="vertical-align:middle;margin-top:-0.15rem;"
            aria-hidden="true"></span>
    </button>
  </li> -->
  
  <!------------------------------------------------------------------------------------------->
  <!--Repeater Pages-->
  <li *ngFor="let kvPhoto of pagerVKvPhotoNav?.kvPhotoArr; let i = index;" class="flex-container text-center"
      style="left: 0.75rem; width: 3rem; height: 3rem; margin: 0 0 0 -0.3rem; padding: 0rem; cursor: pointer; z-index: 11; list-style: none;">
    <!----------------------------------------------------------------------------------------->

    <div *ngIf="isInPagedItems(kvPhoto)"
         (click)="setPhotoNavItem(kvPhoto)"
         class="flex-item-main text-center"
         style="width: 3rem; height: 3rem; margin: 0rem; padding: 0rem; border-radius: 100%; cursor: pointer;">

      <!-------------------------------------------------------------------->
      <!--primary-pic-raw-data-withUrl-->
      <img *ngIf="kvPhoto.isPrimary && getIsKvDataWithUrl(kvPhoto)"
           class="imgBg bttn-sm-transparent"
           [ngClass]="{'smallerPrimary': 'kvPhoto.isPrimary', 'active': 'kvPhoto.isSelected'}"
           style="margin: 0rem; padding: 0rem; border-radius: 50%; "
           [src]="kvPhoto.value | safe : 'url'"
           id="pagerVKvPhotoNav-{{kvPhoto.index}}" />

      <!--primary-pic-raw-data-withUrl-->
      <img *ngIf="kvPhoto.isPrimary && getIsKvDataWithUrl(kvPhoto)"
           class="imgBg bttn-sm-transparent"
           [ngClass]="{'smallerPrimary': 'kvPhoto.isPrimary', 'active': 'kvPhoto.isSelected'}"
           style="margin: 0rem; padding: 0rem; border-radius: 51%; "
           src={{kvPhoto.value}}
           id="pagerVKvPhotoNav-{{kvPhoto.index}}" />

      <!--primary-pic-raw-data-withoutUrl: Displays-->
      <img *ngIf="kvPhoto.isPrimary && getIsKvDataWithoutUrl(kvPhoto)"
           class="imgBg bttn-sm-transparent"
           [ngClass]="{'smallerPrimary': 'kvPhoto.isPrimary', 'active': 'kvPhoto.isSelected'}"
           style="margin: 0rem; padding: 0rem; border-radius: 52%; "
           [src]="kvPhoto.value | safe : 'url'"
           id="pagerVKvPhotoNav-{{kvPhoto.index}}" />

      <!------------------------------- || isPrivateAndUnlocked(kvPhoto)------------------------------------->
      <!--non-primary-or-unlocked-pic-raw-data-without-url-->
      <img *ngIf="!kvPhoto.isPrimary && getIsKvDataWithoutUrl(kvPhoto) && (!kvPhoto.isPrivate || (kvPhoto.isPrivate && isUnlocked)) "
           class="imgBg bttn-sm-transparent"
           [ngClass]="{'smallerCircle active': kvPhoto.isSelected}"
           style="margin: 0rem; padding: 0rem; border-radius: 53%; "
           src={{kvPhoto.value}}
           id="pagerVKvPhotoNav-{{kvPhoto.index}}" />

      <!--non-primary-or-unlocked-image-file-name-case-withoutUrl: Displays-->
      <img *ngIf="!kvPhoto.isPrimary && getIsKvFileWithoutUrl (kvPhoto) "
           class="imgBg bttn-sm-transparent"
           [ngClass]="{'smallerCircle active': kvPhoto.isSelected}"
           style="margin: 0rem; padding: 0rem; border-radius: 54%; "
           [src]="kvPhoto?.value | safe : 'url'"
           id="pagerVKvPhotoNav-{{kvPhoto.index}}" />

      <!-- private case-->
      <img *ngIf="!kvPhoto?.isPrimary && getIsKvDataWithoutUrl(kvPhoto)  && kvPhoto.isPrivate && !isUnlocked"
           class="imgBg bttn-sm-transparent"
           [ngClass]="{'smallerCircle active': kvPhoto.isSelected}"
           style="margin: 0rem; padding: 0rem; border-radius: 55%;"
           src="assets/photos/private.png"
           id="pagerVKvPhotoNav-{{kvPhoto.index}}" />
      <!-------------------------------------------------------------------->
      <!--<div class=" text-center badge badge-light" style="align-self:center; color:orange;background:transparent;z-index: 9; margin:0.05rem; opacity:1;">{{kvPhoto.index + 1}}</div>-->
    </div>
    <!----------------------------------------------------------------------------------------->

  </li>
  <!-------------------------------------------------------------------------------->
  <!--Next & Last glyph Arrows || [disabled]="pagerVKvPhotoNav.currentPage === pagerVKvPhotoNav.totalPages"-->
  <!-- <li *ngIf="pagerVKvPhotoNav?.currentPageNo < pagerVKvPhotoNav?.endPageNo"
      id="pagerVKvPhotoNav-next"
      class="text-center"
      style="display: inline-block; line-height: 0.74rem; margin-left: 0.75rem; margin-top: 0.5rem; padding: 0.1rem; cursor: pointer;">
    <button (click)="setPage('next')"
            [ngClass]="{'disabled': pagerVKvPhotoNav?.currentPageNo + 1 >= pagerVKvPhotoNav?.endPageNo}"
            class="bttn-xxs-transparent hvr-underline-from-center text-center increase" type="button"
            style="display: inline-block;cursor: pointer;border:none;">

      <span class="glyphicon glyphicon-forward text-center hvr-icon bluGrnGldxS"
            aria-hidden="true"></span>
      <span *ngIf="pagerVKvPhotoNav?.currentPageNo + 1 >= pagerVKvPhotoNav?.endPageNo"
            [ngClass]="{'glyphicon glyphicon-ban-circle errxSB': 'pagerVKvPhotoNav?.currentPageNo === pagerVKvPhotoNav?.endPageNo'}" aria-hidden="true"></span>
    </button>
  </li>
  <li *ngIf="pagerVKvPhotoNav?.currentPageNo < pagerVKvPhotoNav?.totalPages"
      id="pagerVKvPhotoNav-L"
      class="text-center"
      style="display: inline-block; line-height: 0.74rem; margin-left: 0.75rem; padding: 0.1rem; cursor: pointer; ">
    <button (click)="setPage('L')"
            [disabled]="pagerVKvPhotoNav?.currentPageNo >= pagerVKvPhotoNav?.totalPages"
            class="bttn-xxs-transparent hvr-underline-from-center text-center increase" type="button"
            style="display: inline-block; cursor: pointer;">

      <span class="glyphicon glyphicon-step-forward text-center hvr-icon bluGrnGldxS"
            aria-hidden="true"></span>
      <span *ngIf="pagerVKvPhotoNav?.currentPageNo >= pagerVKvPhotoNav?.totalPages"
            [ngClass]="{'glyphicon glyphicon-ban-circle errxSB': 'pagerVKvPhotoNav.currentPageNo >= pagerVKvPhotoNav.totalPages'}" aria-hidden="true"></span>
    </button>
  </li> -->
  <!----------------------------------------------------------------------------------------->
</ul>
<!----------------------------------------------------------------------------------------->
<!--
There are 4 types of bindings:

    Property binding i.e. [] required to evaluate values
    Model binding i.e. [()] required nothing special
    Interpolation binding i.e. {{}} could be used with general attributes
    Event binding i.e. () worked great with functions
-->
<!----------------------------------------------------------------------------------------->
