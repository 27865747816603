
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { PagerMemberActivity } from '../../../models/pagination/pagerMemberActivity.model';
import { PagerPhotoMgmt } from '../../../models/pagination/pagerPhotoMgmt.model';
import { ProfilePics } from '../../../models/profile/profilePics.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { DisplayProfileService } from '../../../services/displayProfileServiceService/displayProfileService.service';
import { AbstractPagerVPhotoNavService } from '../../../services/pagerServiceService/abstractPagerVPhotoNavService.service';
import { GlyphiconMenuService } from '../../../services/GlyphiconService/glypgiconMenuService.service';
import { PagerPhotoMgmtService } from '../../../services/pagerServiceService/pagerPhotoMgmtService.service';
import { PagerSupportService } from '../../../services/pagerServiceService/pagerSupportService.service';
import { PhotoPrivacyService } from '../../../services/photoServiceService/photoPrivacyService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../../../services/staticServices/jsRegExpServiceStatic.service';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { PhotoBnService } from '../../../services/photoServiceService/photoBnService.service';


// ------------------------------------------------------------------
//  This pager-service is the most recently updated one. If other
//  pagers need to be updated, this one should be used as a model for
//  them. Updated on 20220623.
// ------------------------------------------------------------------
// This service handles photo-mgmt for both signedInUser and non-signedInUser
// This has separate signedInUser's pagerVKvPhotoNav and kvPhotoArr for fast rendering.
// But the non-signedInUser's pagerVKvPhotoNav and kvPhotoArr are erased during profile-change
// ------------------------------------------------------------------

// ref: http://xahlee.info/js/javascript_range_array.html
// const xah_range = ((min, max, step = 1,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)));

@Injectable({
  providedIn: 'any'
})
export class MyPagerVPhotoNavService extends AbstractPagerVPhotoNavService implements OnDestroy {
  // public override renderer = Renderer2;
  // ----------------------------------------------------------------
  public currentKvPhoto : KvPhoto = new KvPhoto(); 
  public isKvData = false;
  public isKvFile = false;
  public isKvNext = false;
  public isKvPrev = false;
  public isKvUrlData = false;
  public isMobilevar = false;
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isStripUrl = true;
  public isViewMember = false;
  public isWithoutUrl = false; 
  public kvPhotoArr : KvPhoto[] = [];
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public message = '';
  public pageSize = 6;
  public pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public pagerVMemberActivityForPhotoMgmt : PagerMemberActivity = new PagerMemberActivity(); // for primary/private/public photo 
  public pagerVMemberActivityForViewMember : PagerMemberActivity = new PagerMemberActivity();
  public profileTile : ProfileTile = new ProfileTile();
  public profilePics : ProfilePics = new ProfilePics();
  public signedInUser : SitUser = new SitUser();
  public signedInUserKvPhotoArr : KvPhoto[] = []; // to persis the signedInUser kvPhotos
  public signedInUserPagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt(); // to persis singedInUser pagerVKvPhotoNav
  public sitUser : SitUser = new SitUser();
  public spinnerModel : SpinnerModel = new SpinnerModel();
  
  // ---------------------------------------------------------------
  constructor (
    public bias : BackgroundImageAnimationService,
    public displayProfileService : DisplayProfileService,
    public glyphiconMenuService : GlyphiconMenuService,
    public pagerPhotoMgmtService : PagerPhotoMgmtService,
    public pagerSupportService : PagerSupportService,
    public photoBnService : PhotoBnService,
    public photoPrivacyService : PhotoPrivacyService,
    public redirectionService : RedirectionService,
    public rendererServiceService : RendererServiceService,
    public rendererService : RendererService,
    public router : Router,
    @Inject(DOCUMENT) document : any,
    @Inject(Window) window : any
  ) {
    super(
      bias,
      glyphiconMenuService,
      pagerPhotoMgmtService,
      pagerSupportService,
      photoPrivacyService,
      redirectionService,
      rendererServiceService,
      rendererService,
      router,
      document,
      window
    );
  }
  // ---------------------------------------------------------------
  public getSignedInUserKvPhotoArr () : KvPhoto[] {
    return this.signedInUserKvPhotoArr;
  }
  // ---------------------------------------------------------------
  public getSignedInUserPagerVKvPhotoNav () : PagerPhotoMgmt {
    return this.signedInUserPagerVKvPhotoNav;
  }
  // ===============================================================
  public getMyPhotoBnsFromServer () : Observable<any>  {
    // debugger;
    // start spinner:
    // --------------
    this.spinnerModel = new SpinnerModel();
    this.spinnerModel.message = 'Getting your profile and pics...';
    EmitterSubjectService.emitShowSpinner(this.spinnerModel);

    // get userPics from the backend:
    // ------------------------------
    // debugger;
    return new Observable<any>((subscriber) => {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      // if the signedInUser's photos were not previoiusly fetched, then get them from the server:
      // -----------------------------------------------------------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUserPagerVKvPhotoNav)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUserPagerVKvPhotoNav.pagedItems)
        && this.signedInUserPagerVKvPhotoNav.pagedItems.length === 0) {

        this.getPhotoBnsFromServer(this.loginSuccess.signedInUserId).subscribe(data => {
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
            // this.profilePics = data;
            // debugger;
            this.pagerVKvPhotoNav = data; //TODO: do something with data
            this.signedInUserPagerVKvPhotoNav = JSON.parse(JSON.stringify(this.pagerVKvPhotoNav));
            this.signedInUserKvPhotoArr = JSON.parse(JSON.stringify(this.pagerVKvPhotoNav.kvGlyphArr));

            ////this.pagerVKvPhotoNav.pagedItems.forEach(e => {

            ////  // raw-image-data-case
            ////  //if (e.value.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
            ////  //  // debugger;
            ////  //  e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //will be without url
            ////  //}
            ////  //// file-name-case
            ////  //else if (e.value.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
            ////  //  // debugger;
            ////  //  e.value = JsRegExpServiceStatic.normalizeImageFileNameWithoutUrl(e.value);
            ////  //}
            ////})

            // debugger;
            console.log(':timestamp: ' + new Date().getTime() + ' : myProfileViewMgmt: @fromServer.');

            // stop the spinner:
            // -----------------
            EmitterSubjectService.emitHideSpinner("");
            EmitterSubjectService.emitRunNgAfterViewInit(true);
            subscriber.next(this.signedInUserPagerVKvPhotoNav);
          }
        });
      }
      else {
        subscriber.next(this.signedInUserPagerVKvPhotoNav);
      }
    })
  }
  // ---------------------------------------------------------------
  public getPhotoBnsFromServer (situserId : number) : Observable<any> {
    // ---------------------------------
    // Begin: get user pics from server:
    // ---------------------------------
    EmitterSubjectService.emitShowSpinner('Getting user pics...');
    return new Observable<any>((subscriber) => {
      let tpKvPhotoArr : KvPhoto[] = [];

      let sitUserId = situserId;
      // debugger;
      if (sitUserId > 0) {
         debugger;
        this.kvPhotoArr = [];
        this.photoBnService?.getAllPhotosOfUserBySitUserIdObservable(sitUserId)?.subscribe(result => {
           debugger;

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
            this.profilePics = result as ProfilePics;

               debugger;
              this.kvPhotoArr = this.profilePics.unFilteredKvPhotoArr;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvPhotoArr) && this.kvPhotoArr.length > 0) {
                this.executeKvPhotoNavTasks(this.kvPhotoArr[ 0 ], this.kvPhotoArr).subscribe(data2 => { // executePagerVKvPhotoNavCurrentKvPhotoTasks
                // this.executePagerVKvPhotoNavCurrentKvPhotoTasks(this.kvPhotoArr[ 0 ], this.kvPhotoArr).subscribe(data2 => {
                  // debugger;
                  this.pagerVKvPhotoNav = data2;
                  subscriber.next(this.pagerVKvPhotoNav);
                })
              }
            }
        })
      }
    })
    // ---------------------------------
    // End: get user pics from server:
    // ---------------------------------
  }
  // ---------------------------------------------------------------
  public clearPagerVKvPhotoNavService (): void {
    this.kvPhotoArr = [];
    this.pagerVKvPhotoNav = new PagerPhotoMgmt();
    // debugger;
  }
  // ----------------------------------------------------------------
  public executeOnCurrentPictureSelection (currentKvP:KvPhoto) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvP)) {

		}
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getKvPhotoUrl (kvPhoto : KvPhoto) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
      // debugger;
      return 'url(\'' + kvPhoto.value + '\')';
    }
  }
  // --------------------------------------------------------------------
  public getKvPhotoWithoutUrl (kvPhoto : KvPhoto) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
      // debugger;
      kvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(kvPhoto.value);
      return kvPhoto.value;
    }
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public setPagerVerticalPhotoNav (value : PagerPhotoMgmt) : void {
    this.pagerVKvPhotoNav = value;
  }
  // --------------------------------------------------------------------
  // API:
  // ------------------------------------------------------------
  public isInPagedItems (kvPhoto : KvPhoto, pagerVKvPhotoNav: PagerPhotoMgmt) : boolean {
    let isFound = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)
      && pagerVKvPhotoNav.pagedItems.length > 0) {
      for (let i = 0; i < pagerVKvPhotoNav.pagedItems.length; i++) {
        if (pagerVKvPhotoNav.pagedItems[ i ].photoBnId === kvPhoto.photoBnId) {
          isFound = true;
          break;
        }
      }
    }
    // debugger;
    return isFound;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvFileWithUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    this.isKvFile = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') !== -1
      && (kvPhoto.value.toString().toLowerCase().indexOf('.png') !== -1 || kvPhoto.value.toString().toLowerCase().indexOf('.jpg') !== -1);
    return this.isKvFile;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvFileWithoutUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    this.isKvFile = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') === -1
      && (kvPhoto.value.toString().toLowerCase().indexOf('.png') !== -1 || kvPhoto.value.toString().toLowerCase().indexOf('.jpg') !== -1);
    return this.isKvFile;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvDataWithUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    this.isKvUrlData = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') !== -1;
    //&& kvPhoto.value.toString().toLowerCase().indexOf('.png') === -1
    //&& kvPhoto.value.toString().toLowerCase().indexOf('.jpg') === -1;
    return this.isKvUrlData;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvDataWithoutUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    this.isKvData = kvPhoto
      && kvPhoto.value
      && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1
      && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('.png') === -1
      && kvPhoto.value.toString().toLowerCase().indexOf('.jpg') === -1;
    return this.isKvData;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public isNextOrLastPage (pagerVKvPhotoNav : PagerPhotoMgmt) : boolean {
    this.isKvNext = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav?.endPage)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav?.nextPage)
      && pagerVKvPhotoNav.currentPage >= pagerVKvPhotoNav?.startPage
      && (pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav?.nextPage
        || pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav?.endPage)
    return this.isKvNext;
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public isPreviousOrFirstPage (pagerVKvPhotoNav : PagerPhotoMgmt) : boolean {
    this.isKvPrev = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.startPage)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.previousPage)
      && (pagerVKvPhotoNav.currentPage >= pagerVKvPhotoNav.startPage
        || pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav.previousPage)
    return this.isKvPrev;
  }
  // --------------------------------------------------------------------
  public getPagerVKvPhotoNavOnInit (kvPhotoArr : KvPhoto[], pageSize : number) : PagerPhotoMgmt {
    // debugger;
    let currentPageNum : number = 0;
    let currentItemKvPhoto : KvPhoto = new KvPhoto();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      debugger;

      // mask the private photos if not unlocked:
      for (var i = 0; i < kvPhotoArr.length; i++) {
        if (kvPhotoArr[ i ].isPrivate && !kvPhotoArr[ i ].isUnlocked) {
          kvPhotoArr[ i ].value = 'assets/photos/private.png';
        }
      }
      this.pageSize = EmitterSubjectService.getPageSize();
      currentItemKvPhoto = this.kvPhotoArr[ 0 ];

      // First get the default pager for page#1:
      // ---------------------------------------
      this.pagerVKvPhotoNav = this.getPagerForVKvPhotoNav(this.kvPhotoArr, currentPageNum, pageSize); //get pager

      this.pagerVKvPhotoNav = this.updatePagerVPhotoNav(this.pagerVKvPhotoNav); // Note: may update the KvPhotoArr !!!important

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)
          && (this.pagerVKvPhotoNav.currentItem.photoBnId > 0
            || this.pagerVKvPhotoNav.currentItem.photoId > 0
            || this.pagerVKvPhotoNav.currentItem.sitUserId > 0)) {
          // debugger;
          this.executePagerVKvPhotoNavCurrentKvPhotoTasks(this.pagerVKvPhotoNav.currentItem, kvPhotoArr).subscribe(data => {
            // debugger;
            this.pagerVKvPhotoNav = data;
            EmitterSubjectService.setPagerPhotoMgmt(this.pagerVKvPhotoNav);

            // this should call the following method regarless:
            // ---------------------------------------------------------
            // this.pagerVKvPhotoNav.pagedItems = this.pagerVKvPhotoNavService.prepareKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems, this.isStripUrl);
            // this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavWithoutUrl(this.pagerVKvPhotoNav);
            // debugger;
            return this.pagerVKvPhotoNav;
          });
        }
      }

      EmitterSubjectService.setPagerPhotoMgmt(this.pagerVKvPhotoNav);
    }
    return this.pagerVKvPhotoNav;
  }

  // --------------------------------------------------------------------
  // --------------------------------------------------------------------
  public getPagerVKvPhotoNavOnInitOld (kvpArr: KvPhoto[], pSize: number) : Observable<PagerPhotoMgmt> {
    // debugger;
    let currentPageNum : number = 0;
    let currentItemKvPhoto : KvPhoto = new KvPhoto();
    let kvPhotoArr = kvpArr;
    let pageSize = pSize;

    return new Observable(subscriber => {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
        // debugger;
        this.pageSize = EmitterSubjectService.getPageSize();
        currentItemKvPhoto = this.kvPhotoArr[ 0 ];

        // First get the default pager for page#1:
        // ---------------------------------------
        this.pagerVKvPhotoNav = this.getPagerForVKvPhotoNav(this.kvPhotoArr, currentPageNum, pageSize); //get pager

        this.pagerVKvPhotoNav = this.updatePagerVPhotoNav(this.pagerVKvPhotoNav); // Note: may update the KvPhotoArr !!!important

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)
            && (this.pagerVKvPhotoNav.currentItem.photoBnId > 0
              || this.pagerVKvPhotoNav.currentItem.photoId > 0
              || this.pagerVKvPhotoNav.currentItem.sitUserId > 0)) {
            // debugger;
            this.executePagerVKvPhotoNavCurrentKvPhotoTasks(this.pagerVKvPhotoNav.currentItem, kvPhotoArr).subscribe(data => {
              // debugger;
              this.pagerVKvPhotoNav = data;
              EmitterSubjectService.setPagerPhotoMgmt(this.pagerVKvPhotoNav);

              // this should call the following methods regarless:
              // ---------------------------------------------------------
              // this.pagerVKvPhotoNav.pagedItems = this.pagerVKvPhotoNavService.prepareKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems, this.isStripUrl);
              // this.pagerVKvPhotoNav = this.getPagerVKvPhotoNavWithoutUrl(this.pagerVKvPhotoNav);
              // debugger;
              subscriber.next(this.pagerVKvPhotoNav);
              subscriber.complete();
            });
          }
        }
      }
      
    })
  }
  // --------------------------------------------------------------------
  setMcLoader (sitUserId?: number) {   
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.mcLoader.moduleName = "mym";
    this.mcLoader.componentName = "myPhotoMgmt";
    this.mcLoader.id = this.loginSuccess.signedInUserId;   
    
  }
  // --------------------------------------------------------------------
  // API:
  // Note : View calls this method.
  //        Stable and works; on 20220429 & 20220514 & 20220622 & 20230108
  // --------------------------------------------------------------------
  public setPhotoNavItem (Item : KvPhoto, pagerVPhotoNav : PagerPhotoMgmt) : Observable<any> {
    // debugger;    
    let item = Item;
    let pagerVKvPhotoNav = pagerVPhotoNav;

    return new Observable<any>(subscriber => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(item) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)) {
        // debugger;
        this.setMcLoader(item.sitUserId);
        item.returnUrl = '/' + this.mcLoader.moduleName + '/' + this.mcLoader.componentName + '/' + item.sitUserId;
        var selectedKvPhoto = null;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)
          && pagerVKvPhotoNav.pagedItems.length > 0) {
          pagerVKvPhotoNav.pagedItems.map(e => {
            if ((e.photoBnId === item.photoBnId)) {
              e.isSelected = true;
              // debugger;
              selectedKvPhoto = e;
            }
            else {
              e.isSelected = false;
            }
          });

          // debugger;
          this.executeKvPhotoNavTasks(selectedKvPhoto, pagerVKvPhotoNav.pagedItems).subscribe(data => { // executePagerVKvPhotoNavCurrentKvPhotoTasks
            pagerVKvPhotoNav = data;
            this.bias.setKvPhotoBackgroundImage(selectedKvPhoto);
            this.kvMemActGlyphArrForPhotoMgmt = this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt;
            subscriber.next(pagerVKvPhotoNav);
            subscriber.complete();
            // debugger;
          })
        }
        else {
          subscriber.next(pagerVKvPhotoNav);
          subscriber.complete();
        }
      }
      else {
        subscriber.next(pagerVKvPhotoNav);
        subscriber.complete();
      }
    })
  }
  // ----------------------------------------------------------------
  executePagerVKvPhotoNavSystem (pagerVKvPhotoNav : PagerPhotoMgmt, currentKvPhoto: KvPhoto) : PagerPhotoMgmt{
    var currentItemKvPhoto : any;
    // =============================================
    //  PagerVKvPhoto-display-system: begin
    // =============================================
    // if the images need to be normalized befor rendering:
    // ----------------------------------------------------
    //this.pagerVKvPhotoNav.pagedItems.forEach(e => {
    //  // raw-image-data-case
    //  //if (e.value.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
    //  //  // debugger;
    //  //  e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //will be without url
    //  //}
    //  //// file-name-case
    //  //else if (e.value.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
    //  //  // debugger;
    //  //  e.value = JsRegExpServiceStatic.normalizeImageFileNameWithoutUrl(e.value);
    //  //}
    //})

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems)) {
      // ------------------------------------------------------
      // Note: backend data has only privacy,
      //       no isPrime / isPrivate / isPublic is determined
      // ------------------------------------------------------
      for (var i = 0; i < pagerVKvPhotoNav.pagedItems.length; i++) {
        pagerVKvPhotoNav.pagedItems[ i ] = this.determinePrivacyStatusOfKvPhoto(pagerVKvPhotoNav.pagedItems[ i ]);
      }
      //this.pagerVKvPhotoNav.pagedItems.forEach(e => {
      //  e = this.myPagerVPhotoNavService.determinePrivacyStatusOfKvPhoto(e);
      //})

      // debugger;
      // select the last item for page rendering and glyphicon display:
      // --------------------------------------------------------------
      // this.pagerVKvPhotoNav.pagedItems[ this.pagerVKvPhotoNav.pagedItems.length - 1 ];
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto)) {
        currentItemKvPhoto = JSON.parse(JSON.stringify(currentKvPhoto));
      }
      else {
        currentItemKvPhoto = this.pagerVKvPhotoNav.pagedItems[ 0 ];
      }

      EmitterSubjectService.emitOnKvPhotoSelectionChange(currentItemKvPhoto);
      // debugger;
      this.bias.setKvPhotoBackgroundImage(currentItemKvPhoto);

      // debugger;
      //this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentItemKvPhoto.glyphName)
      //  && (currentItemKvPhoto.isPrimary && currentItemKvPhoto.glyphName.toLocaleLowerCase().indexOf('primary') !== -1)) {
      //  // debugger;
      //  this.loginSuccess.primaryPhotoBnId = currentItemKvPhoto.photoBnId;
      //  this.loginSuccess.primaryImage = currentItemKvPhoto.value;
      //  EmitterSubjectService.setLoginSuccess(this.loginSuccess);
      //  this.bias.setPrimaryImage('primaryPhotoBnId', true);
      //}

      // debugger;
      // The view changes the halo, still need to call this:
      // ---------------------------------------------------
      this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, currentItemKvPhoto);
      // debugger;
      return pagerVKvPhotoNav;
      // =============================================
      //  PagerVKvPhoto-display-system: end
      // =============================================
    }
  }
  // ------------------------------------------------------------
  turnOffCurrentPrimaryStatusOfRespectiveKvPhoto (pagerVKvPhotoNav : PagerPhotoMgmt) : PagerPhotoMgmt {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)) {
      pagerVKvPhotoNav.pagedItems.forEach(e => {
        if (e.privacy.toLocaleLowerCase().indexOf('primary') !== -1) {
          e.glyphName = e.key = e.privacy = 'public';
          e = this.determinePrivacyStatusOfKvPhoto(e);
        }
      })
      pagerVKvPhotoNav.kvPhotoArr.forEach(e => {
        if (e.privacy.toLocaleLowerCase().indexOf('primary') !== -1) {
          e.glyphName = e.key = e.privacy = 'public';
          e = this.determinePrivacyStatusOfKvPhoto(e);
        }
      })
    }
    return pagerVKvPhotoNav;
  }
  // ------------------------------------------------------------
}
