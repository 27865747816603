
import { Injectable, OnDestroy } from '@angular/core';
import { Dictionary } from 'dictionaryjs';
import { Subject } from 'rxjs';
import { ProfilePicsServiceInterface } from '../../interfaces/serviceInterfaces/profileServiceInterfaces/profilePicsServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Counters } from '../../models/common/counters.model';
import { Photo } from '../../models/common/photo.model';
import { PhotoMgmtAction } from '../../models/common/photoMgmtAction.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { ProfileImage } from '../../models/profile/profileImage.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { HttpService } from '../../services/coreServiceService/httpService.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { MemberService } from '../memberServiceService/memberService.service';
import { PagerSupportService } from '../pagerServiceService/pagerSupportService.service';
import { PhotoPrivacyService } from '../photoServiceService/photoPrivacyService.service';
import { BackgroundImageAnimationService } from '../rendererServiceService/backgroundImageAnimationService.service';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';

@Injectable( {
  providedIn: 'root',
} )

// ------------------------------------------------------------------------------------------------
// Note:  DictionaryModule will populate all the dictionaries from the indexedDb if data exists.
//        Therefore, this service will NOT deal with indexedDb at all!
// TODO:  Move all indexedDb related functionalities into the DictionaryModuleComponent
//
// ------------------------------------------------------------------------------------------------
// Disclaimer:  this service accomplishes all activities regarding signedInUser's ProfilePics only,
//              i.e. creating the profilePics for the signedInUser (1st  person user), and all of
//              the activities regarding managing the profilePics such as making a photo private
//              if it is public and vice versa, and generating the PhotoMgmtNavigation buttons.
// ------------------------------------------------------------------------------------------------
export class ProfilePicsService implements OnDestroy, ProfilePicsServiceInterface
{
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public counters: Counters = new Counters();
  public currentBn: BoxNonceEntity = new BoxNonceEntity();
  public currentKvPhoto: KvPhoto = new KvPhoto();
  public currentStateOfPhoto: Photo = new Photo();
  public emitterDestroyed$: Subject<boolean> = new Subject();
  public filteredKvPhotoArr: KvPhoto[] = [];
  public glyphNames: string[] = [ 'camera', 'primary', 'private', 'public' ];
  public isBlocked = false;
  public isBuddy = false;
  public isFriend = false;
  public isIndexedDbData = true;
  public isLiked = false;
  public isMobilevar = false;
  public isOnLine = false;
  public isPhotoMgmt = false;
  public isPrivate = false;
  public isToggleCamera = false;
  public isBlockedByDictionaryData = false;
  public isBlockedByServerData = false;
  public isFriendByDictionaryData = false;
  public isFriendByServerData = false;
  public isLikedByDictionaryData = false;
  public isLikedByServerData = false;
  public isUnlockedByDictionaryData = false;
  public isUnlockedByServerData = false;
  public isViewMember = false;
  public isWaitingOnCurrStateOfUser = false;
  public kvPhotoArr: KvPhoto[] = [];
  public kvPhotoLock: KvPhoto = new KvPhoto();
  public kvPhotoUnLock: KvPhoto = new KvPhoto();
  public kvPhotoPrivate: KvPhoto = new KvPhoto();
  public kvPhotoPublic: KvPhoto = new KvPhoto();
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public message = '';
  public memberViewGlyphNames: string[] = [];
  public memberViewMenuNames: string[] = [ 'chat', 'email', 'note', 'buddy', 'like', 'private', 'public', 'tala', 'block', 'unblock', 'dislike' ];
  public photoMgmtMenuNames: string[] = [ 'camera' ];
  public photoMgmtGlyphNames: string[] = [];
  public photoMgmtAction: PhotoMgmtAction = new PhotoMgmtAction();
  public photoMgmtServiceSitUser: SitUser = new SitUser();
  public primaryKvPhoto: KvPhoto = new KvPhoto();
  public profileImageArr: ProfileImage[] = [];
  public profileImageKvPhotos: KvPhoto[] = [];
  public profilePics: ProfilePics = new ProfilePics();
  public profilePicsArr: ProfilePics[] = [];
  public profilePicsBoxNonceArr: BoxNonceEntity[] = [];
  // will contain member-selection of KvPhoto as final state of currentStateOfPhoto
  public selectedKvPhoto: KvPhoto = new KvPhoto();
  public sitUser: SitUser = new SitUser();
  public timer: any;
  public timerArray: any[] = [];
  public unsaltedIndexedDbData: any;
  public userPhotoBnIds: number[] = [];
  public userPhotos: Photo[] = [];

  constructor (
    public bias : BackgroundImageAnimationService,
    public dictionaryService : DictionaryService,
    public httpService: HttpService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public memberService : MemberService,
    public pagerSupportService : PagerSupportService,
    public photoPrivacyService : PhotoPrivacyService,
  )
  {
    this.initialize();   
  }
  // -----------------------------------------------------------
  //  Note: this process is deprecated since we have a new system for
  //        indexedDb save and retrieval of all data.
  // -----------------------------------------------------------
  // public getIndexedDbProfilePics ( sitUserId: number ): ProfilePics
  // {
  //  let tProfilePics: ProfilePics = new ProfilePics();
  //  // Note:ref:indexDbProfilePicsEmitter will receive data via emitter
  //  // debugger;
  //  if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics )
  //    || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics?.profileImageDictionary )
  //    || this.profilePics?.profileImageDictionary?.size() === 0 )
  //  {
  //    // debugger;
  //    this.indexedDbService.getStoreData( 'ProfilePics' ).then( data =>
  //    {
  //      let tProfilePicsArr = data;
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePicsArr ) && tProfilePicsArr.length > 0 )
  //      {
  //        tProfilePicsArr.map( e =>
  //        {
  //          if ( e.sitUserId == sitUserId )
  //            tProfilePics = e;
  //        } );
  //      }
  //    } );
  //  }
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics )
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics.profileImageArr )
  //    && tProfilePics.profileImageArr.length > 0 )
  //  {
  //    // debugger;
  //    this.memberService.profilePics = this.profilePics = tProfilePics;
  //  }
  //  return this.profilePics;
  // }
  // ------------------------------------------------------------
  public initialize (): void
  {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.sitUser = EmitterSubjectService.getSitUserModel();
  }
  // ---------------------------------------------------------------
  public ngOnDestroy (): any
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.profilePics = new ProfilePics(); // clear the profilePics on exit
    this.profileImageArr = [];
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );
    return true;
  }
  // ---------------------------------------------------------------------------------
  clearCounters (): Promise<any>
  {
    this.counters = new Counters();
    return new Promise( ( resolve ) =>
    {
      resolve( this.counters );
    } );
  }
  
  
  // ---------------------------------------------------------------
  // Note:  This method gets the PhotoBnIds of a user, and on the
  //        basis of the photoBnIds, it gets the corresponding Photos
  //        from the PhotoDictionary so that no decryption is required.
  //        PhotoDictionary is not persisted onto the indexedDb.
  // ---------------------------------------------------------------
  public createProfilePicsFromPhotoDictionary (sitUserId : number) : Promise<any> {
    let fKvPhoto : any;
    let userPhotos : Photo[] = [];
    let profilePics : ProfilePics = new ProfilePics();
    return new Promise((resolve, reject) => {
      if (sitUserId > 0) {

        let userPhotoBnIds = this.dictionaryService.userPhotoBnIdDictionary.get(sitUserId) as number[];

        let dPhotoArr = this.dictionaryService.photoDictionary.size > 0 ? this.dictionaryService.photoDictionary.values() : [];
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dPhotoArr) && dPhotoArr.length > 0) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userPhotoBnIds) && userPhotoBnIds.length > 0) {
            userPhotoBnIds.map(e => {
              dPhotoArr.map((dp : any) => {
                if (dp.photoBnId == e && dp.sitUserId == sitUserId) {
                  userPhotos.push(dp);
                }
              })
            })
          }
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userPhotos) && userPhotos.length > 0) {
            userPhotos.map(p => {
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p.imageString) && p.imageString.length > 0) { // TODO: watch here, it could be imageBytes or something else
                
                profilePics.profilePhotoDictionary.set(p.photoId, p);

                // Note: create kvPhoto, filter kvPhoto, setKvPhotoArr, and filteredKvPhotoArr:
                // ----------------------------------------------------------------------------
                let kvPhoto = this.bias.setPhotoToKvPhoto(p);
                profilePics.profileKvPhotoArr.push(kvPhoto);

                this.photoPrivacyService.filterKvPhoto(kvPhoto).subscribe(data => {
                  fKvPhoto = data;
                  profilePics.filteredKvPhotoArr.push(fKvPhoto);

                  resolve(profilePics);
                });
              }
            })
          }
          else reject(null);
        }
        else reject(null);
      }
      else {
        reject(null);
      }
    });
  }

  // ---------------------------------------------------------------
  // Note:  this method will produce a complete ProfilePics model
  //        from a ProfileImageArray model that can is ready to be
  //        displyed by calling
  //        EmitterServiceStatic.emitProfileImages (profilePics.filteredKvPhotoArr);
  // ---------------------------------------------------------------
  // TODO: Intend to deprecate:
  // ------------------------------------------------------------------
  public createProfilePicsFromProfileImageArray ( inArr: ProfileImage[] ): any
  {
    const profilePic = new ProfilePics();
    let image: any;
    let inDFirst: any;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inArr ) && inArr.length > 0 )
    {
      profilePic.profileImageArr = inArr as ProfileImage[];
      // ----------------------------------
      inDFirst = inArr[ 0 ];
      profilePic.cryptUserKey = inDFirst.cryptUserKey;
      profilePic.date = inDFirst.date;
      // profilePic.id = inDFirst.Id;
      profilePic.entityName = inDFirst.entityName;
      profilePic.profileId = inDFirst.profileId;
      profilePic.profileImageArr = inArr;
      profilePic.sitUserId = inDFirst.sitUserId;
      profilePic.sitUserKey = inDFirst.sitUserKey;
      // debugger;
      profilePic.profileImageArr.map( e =>
      {
        if ( e.image.toString().indexOf( 'data:image/png;base64,' ) !== -1 )
        {
          // debugger;
          image = 'url(\'' + e.image.toString() + '\')';
          // e.image = image;
          profilePic.images.push( e.image );
        }
        else
        {
          image = 'url(\'data:image/png;base64,' + e.image.toString() + '\')';
          // e.image = image;
          profilePic.images.push( e.image );
        }
        let kvPhoto = CopyServiceStatic.copyFromProfileImageToKvPhoto(e);
        profilePic.profileKvPhotoArr.push(kvPhoto);
        profilePic.filteredKvPhotoArr.push(StringServiceStatic.deepCloneJson(this.photoPrivacyService.filterKvPhoto(kvPhoto)));
      } );
      this.memberService.profilePics = profilePic;
      return this.memberService.profilePics;
    }
    return null;
  }

  // ---------------------------------------------------------------
  // TODO: Intend to deprecate:
  // ------------------------------------------------------------------
  public createProfilePicsFromProfileImageDictionary ( inDictionary: Dictionary<number, ProfileImage> ): any
  {
    const profilePic = new ProfilePics();
    let image: any;
    let inDFirst: any;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( inDictionary ) && inDictionary.size > 0 )
    {
      profilePic.profileImageDictionary = inDictionary as Dictionary<number, ProfileImage>;
      // ----------------------------------
      inDFirst = inDictionary.size > 0 ? inDictionary.values()[ 0 ] : null;
      profilePic.cryptUserKey = inDFirst.cryptUserKey;
      profilePic.date = inDFirst.date;
      // profilePic.id = inDFirst.Id;
      profilePic.entityName = inDFirst.entityName;
      profilePic.profileId = inDFirst.profileId;
      profilePic.profileImageDictionary = inDictionary;
      profilePic.sitUserId = inDFirst.sitUserId;
      profilePic.sitUserKey = inDFirst.sitUserKey;
      // debugger;
      profilePic.profileImageDictionary.size > 0 && profilePic.profileImageDictionary.values().map( e =>
      {
        if ( e.image.toString().indexOf( 'data:image/png;base64,' ) !== -1 )
        {
          // debugger;
          image = 'url(\'' + e.image.toString() + '\')';
          // e.image = image;
          profilePic.images.push( e.image );
        }
        else
        {
          image = 'url(\'data:image/png;base64,' + e.image.toString() + '\')';
          // e.image = image;
          profilePic.images.push( e.image );
        }
      } );
      this.memberService.profilePics = profilePic;
      return this.memberService.profilePics;
    }
    return null;
  }
  
  // ---------------------------------------------------------------
  public createProfilePicsFromSitUser ( sitUser: SitUser ): any
  {
    const profilePics = new ProfilePics();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sitUser ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sitUser.primaryImage ) && sitUser.primaryImage.length > 10 )
    {
      profilePics.sitUserId = sitUser.sitUserId;

      //  debugger;
      let tProfileImage = new ProfileImage();
      tProfileImage.image = sitUser.primaryImage; // '/assets/photos/DefaultImageEmoji.jpg';
      tProfileImage.isPrimary = true;
      tProfileImage.sitUserId = sitUser.sitUserId;
      tProfileImage.privacy = 'public';
      profilePics.profileImageArr.push( tProfileImage );

      let kvPhoto = new KvPhoto();
      kvPhoto.isPrimary = true;
      kvPhoto.isPrivate = false;
      kvPhoto.isUnlocked = true;
      kvPhoto.key = 'primaryImage'; // TODO; check for the appropriate key
      kvPhoto.value = sitUser.primaryImage; // '/assets/photos/DefaultImageEmoji.jpg';
      kvPhoto.sitUserId = sitUser.sitUserId;
      profilePics.filteredKvPhotoArr.push( kvPhoto );
    }
    return profilePics;
  }
  // ---------------------------------------------------------------
  public createProfilePicsFromLoginSuccess (loginSuccess : LoginSuccess) : any {
    const profilePics = new ProfilePics();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(loginSuccess) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(loginSuccess.primaryImage) && loginSuccess.primaryImage.length > 10) {
      profilePics.sitUserId = loginSuccess.signedInUserId;

      //  debugger;
      let tProfileImage = new ProfileImage();
      tProfileImage.image = loginSuccess.primaryImage; // '/assets/photos/DefaultImageEmoji.jpg';
      tProfileImage.isPrimary = true;
      tProfileImage.sitUserId = loginSuccess.signedInUserId;
      tProfileImage.privacy = 'public';
      profilePics.profileImageArr.push(tProfileImage);

      let kvPhoto = new KvPhoto();
      kvPhoto.isPrimary = true;
      kvPhoto.isPrivate = false;
      kvPhoto.isUnlocked = true;
      kvPhoto.key = 'primaryImage'; // TODO; check for the appropriate key
      kvPhoto.value = loginSuccess.primaryImage; // '/assets/photos/DefaultImageEmoji.jpg';
      kvPhoto.sitUserId = loginSuccess.signedInUserId;
      profilePics.filteredKvPhotoArr.push(kvPhoto);
    }
    return profilePics;
  }
  // ---------------------------------------------------------------
  // TODO:  Intend to deprecate:
  //        Not in use
  // ------------------------------------------------------------------
  public executeOnProfileImageArrayComplete ( userPhotoIds: number[], profilePics: ProfilePics ): ProfilePics
  {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userPhotoIds)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.profileImageArr))
    {
      // debugger;
      let tKvPhotoArr = CopyServiceStatic.copyProfilePicsToKvPhotoArr( profilePics );
      // debugger;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tKvPhotoArr ) && tKvPhotoArr.length > 0 )
      {
        // debugger;
        profilePics.profileKvPhotoArr = tKvPhotoArr;
      }
       // debugger;
      this.profilePics = this.executeServerProfilePicsTasks( profilePics );

      EmitterSubjectService.setProfilePics( this.profilePics );

      // debugger;
      return this.profilePics;
    }
    else return profilePics;
  }
  // ------------------------------------------------------------------
  // executeProfilePicsNavigationForPhotoMgmt (profilePics : ProfilePics) : any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics) && profilePics.unFilteredKvPhotoArr.length > 0
  //  ) {
  //    // debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)) {
  //      this.profilePics = profilePics;
  //      debugger;
  //      this.photoPrivacyService.displayUnFilteredKvPhotosOfProfilePics(this.profilePics);

  //      // set the primaryPhoto only if the signedInUser changed the primary photo:     
  //      // Note: Listener at PhotoMgmtComponent will execute executePhotoMgmtPrimaryAction();
  //      // ------------------------------------------------------------------------
  //      if (this.profilePics.isPhotoUploadSuccessful) {
  //        EmitterSubjectService.emitPhotoMgmtPrimaryAction(true);
  //      }
  //    }
  //    return this.profilePics;
  //  }
  //  return this.profilePics;
  // }
  // ----------------------------------------------------------------
  // Note:  This method will display the paged-photo-navigation,
  //        as well as display them on the background
  // TODO: Intend to deprecate:
  // ------------------------------------------------------------------
  public executeProfilePicsNavigation (profilePics : ProfilePics) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics) && profilePics.unFilteredKvPhotoArr.length > 0
    ) {

      // if filteredKvPhoto is not present or empty, create them:
      // --------------------------------------------------------      
      debugger;
      let filteredPPics = this.photoPrivacyService.filterAndSortProfilePics(profilePics);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)) {
        profilePics = filteredPPics;
      }
    }
    // if filteredData exists, then just display:
    // ------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics))
    {
      if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.profileKvPhotoArr) && profilePics.profileKvPhotoArr.length > 0) ||
        (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.filteredKvPhotoArr) && profilePics.filteredKvPhotoArr.length > 0))
      {
        // debugger;
        this.profilePics = this.executeProfilePicsNavigationCoreTasks(profilePics);
      }
    }
    return this.profilePics;
  }
  // ------------------------------------------------------------------
  // TODO: Intend to deprecate:
  // ------------------------------------------------------------------
  public executeProfilePicsNavigationCoreTasks (profilePics : ProfilePics) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)) {
      this.profilePics = profilePics;
       debugger;
      this.photoPrivacyService.displayFilteredKvPhotosOfProfilePics(this.profilePics);

      
      // set the primaryPhoto only if the signedInUser changed the primary photo:     
      // Note: Listener at PhotoMgmtComponent will execute executePhotoMgmtPrimaryAction();
      // ------------------------------------------------------------------------
      EmitterSubjectService.emitPhotoMgmtPrimaryAction(true);
     
    }

    return this.profilePics;
  }
  
  
  // ------------------------------------------------------------
  // Note: this should be called for when ProfileImageArr is completed with data.
  // ------------------------------------------------------------
  // TODO: Intend to deprecate:
  // ------------------------------------------------------------------

  public executeServerProfilePicsTasks (profilePics : ProfilePics) : any {
    // debugger;
    let filteredPPics : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)) {
      this.photoPrivacyService.filterAndSortProfilePics(profilePics).then(result => {
        filteredPPics = result;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filteredPPics)) {
          this.profilePics = filteredPPics;

          debugger;
          // The following will display both navigation and background pics:
          // ---------------------------------------------------------------
          let rPPics = this.executeProfilePicsNavigation(this.profilePics);

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(rPPics)) {
            this.profilePics = rPPics;

            // debugger;
            this.dictionaryService.profilePicsDictionary.set(this.profilePics.sitUserId, this.profilePics);

            this.dbDexieToDictionaryService
              .saveDictionaryToDbDexie(
                this.dictionaryService.profilePicsDictionary, 'profilePics', this.loginSuccess.signedInUserId);

            // Note: create boxNonce from this.profilePics
            // Following is to avoid the error: TypeError: cyclic object value
            // JSON.stringify( circularReference, getCircularReplacer() );

            //let salted = SlakezSaltServiceStatic.boxSalt( JSON.stringify( this.profilePics, StringServiceStatic.getCircularReplacer() ) );

            // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( salted ) )
            // {
            //  this.boxNonceEntity = new BoxNonceEntity();
            //  this.boxNonceEntity.box = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( salted.box );
            //  this.boxNonceEntity.nonce = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( salted.nonce );
            //  this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
            //  this.boxNonceEntity.id = this.profilePics.sitUserId;
            //  this.boxNonceEntity.key = this.boxNonceEntity.id.toString();
            //  this.boxNonceEntity.entityName = 'ProfilePic';
            //  this.boxNonceEntity.sitUserId = this.profilePics.sitUserId;
            //  this.triageAllDataForIndexedDbAndDictionaryService.saveProfilePicsDictionaryToIndexedDb( this.boxNonceEntity );

            // }
            return this.profilePics;
          }
          else return null;
        }
        else return null;
      })
    }
    else return null;
  }
  // ----------------------------------------------------------------------------------------------
  public getProfilePicsFromProfilePicsDictionary (situserId : number) : Promise<any> {
    // debugger;
    let date = new Date();
    let pPics = new ProfilePics();
    let sitUserId = situserId;
    return new Promise<any>((resolve, reject) => {
      if (sitUserId > 0) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dictionaryService.profilePicsDictionary) && this.dictionaryService.profilePicsDictionary.size > 0) {
          pPics = this.dictionaryService.profilePicsDictionary.get(sitUserId) as ProfilePics;
        }
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId === sitUserId) {
        resolve(pPics);
      }
      else {
        reject(date.getTime() + ': createProfilePicsFromDictionary : ' + sitUserId + ') result returned a null or undefined.');
      }
    }).catch((err) => {
      this.message = ': promise rejected for createProfilePicsFromDictionary(sitUserId:\'' + sitUserId + '\');\n Error message:  ' + err;
      console.log(date.getTime() + this.message);
    });
  }
  // ---------------------------------------------------------------
  //  Note: this process is deprecated since we have a new system for
  //        indexedDb save and retrieval of all data.
  // ---------------------------------------------------------------
  public getProfilePicsFromDictionaryOrSitUser ( sitUserId: number ): ProfilePics
  {
    let tProfilePics: ProfilePics = new ProfilePics();
    if ( sitUserId > 0 )
    {
      // TODO: uncomment after implementing myProfilePicsDictionary
      // ----------------------------------------------------------
      // at first get the signedInUser profilePics if applicable:
      // if ( sitUserId === this.loginSuccess.signedInUserId )
      // {
      //  if ( this.dictionaryService.myProfilePicsDictionary.size() > 0 )
      //  {
      //     debugger;
      //    tProfilePics = this.dictionaryService.myProfilePicsDictionary.getValue( this.loginSuccess.signedInUserId ) as ProfilePics;
      //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics )
      //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics.profileImageArr )
      //      && tProfilePics.profileImageArr.length > 0 )
      //    {
      //      // debugger;
      //      return this.profilePics = this.memberService.profilePics = tProfilePics;
      //    }
      //  }
      // }
      // ----------------------------------------------------------

      // debugger;
      if ( this.profilePics.profileImageArr.length === 0 && this.dictionaryService.profilePicsDictionary.size > 0 )
      {
        // debugger;
        tProfilePics = this.dictionaryService.profilePicsDictionary.get( sitUserId ) as ProfilePics;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics.profileImageArr )
          && tProfilePics.profileImageArr.length > 0 )
        {
          // debugger;
          this.profilePics = this.memberService.profilePics = tProfilePics;
          return this.profilePics;
        }
        else
        {
          let tSitUser = EmitterSubjectService.getSitUserModel();
          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tSitUser ) )
          {
            if ( tSitUser.sitUserId === sitUserId )
            {
              // debugger;
              return this.createProfilePicsFromSitUser( tSitUser );
            }
          }
          return new ProfilePics();
        }
      }
      // debugger;
      //  if the profilePicsDictionary does not contain user's pics,
      //  get the sitUser.primaryImage and set it to profilePics's KvPhotoArr as well as ProfileImageArr
      else
      {
        let tSitUser = EmitterSubjectService.getSitUserModel();
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tSitUser ) )
        {
          if ( tSitUser.sitUserId === sitUserId )
          {
            // debugger;
            return this.createProfilePicsFromSitUser( tSitUser );
          }
        }
        return new ProfilePics();
      }
    }
    else return new ProfilePics();
  }
  // ----------------------------------------------------------------------------------------------
  //public getProfilePicsFromServer (situserId : number) : Observable<any> {
  //  // debugger;
  //  let sitUserId = situserId;
  //  let date = new Date();
  //  return new Observable<any>((subscriber) => {
  //    if (sitUserId > 0) {
  //      // this.executeAfterGettingSitUser(sitUserId).then(result => {
  //      // this.photoBnService.getAllPhotoOfUsersBySitUserIdPromise(sitUserId).then(result => {
  //      // debugger;
  //      this.photoBnService.getAllPhotosOfUserBySitUserIdObservable(sitUserId).subscribe(result => {
  //        if (result) {

  //          // debugger;
  //          this.profilePics = result as ProfilePics;
  //          this.profilePics.sitUserId = sitUserId;
  //          // debugger;
  //          // this.isMyProfile = true;
  //          // EmitterSubjectService.emitIsMyProfile(this.isMyProfile);

  //          // this.profilePics = this.displayProfilePicsView(this.profilePics);

  //          // debugger;
  //          /* return this.profilePics;*/

  //          // OR
  //          // this.pagerVKvPhotoNav = this.pagerVKvPhotoNavService.getPagerVKvPhotoNavForKvPhotoInKvPhotoArr(this.profilePics.unFilteredKvPhotoArr[ 0 ], this.profilePics.unFilteredKvPhotoArr);
  //          //  debugger;
  //          // // this.pagerVKvPhotoNavService.executePagedItemsAndGlyphMenuTasks(this.pagerVKvPhotoNav).then(data => {
  //          // this.pagerVKvPhotoNavService.executePagerVKvPhotoNavKvPhotoTasks(this.pagerVKvPhotoNav).then(data => {
  //          //  this.pagerVKvPhotoNav = data;
  //          //  debugger;
  //          //  this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.pagerVKvPhotoNav.currentItem);

  //          // this.glyphiconMenuService.displayGlyphiconMenus(this.profilePics.sitUserId).then(data1 => {
  //          //  debugger;

  //          //  this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = data1;
  //          // });
  //          // -----------------------------------------------------------
  //          // NOTE: the following displays the PhotoMgmtGlyphiconMenu:
  //          // -----------------------------------------------------------
  //          // debugger;
  //          // this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displaySignInUserPrimaryKvPhotoGlyphs();
  //          // EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.kvMemActGlyphArrForPhotoMgmt);
  //          // -----------------------------------------------------------
  //          // debugger;
  //          // return this.profilePics;
  //          // });
  //          // debugger;
  //          subscriber.next(this.profilePics);

  //        }
  //        else {
  //          console.log(date.getTime() + ': createProfilePicsFromServer(sitUserId : ' + sitUserId + ') result returned a null value: ' + result);
  //        }
  //      }) //.catch((err) => {
  //      //  this.message = date.getTime() + ': Error occured in createProfilePicsFromServer.getAllPhotosOfUserBySitUserIdObservable(sitUserId:' + sitUserId + '); Error message:  ' + err;
  //      //  console.log(this.message);
  //      //});
  //    }
  //    else {
  //      console.log(date.getTime() + ': createProfilePicsFromServer(sitUserId : ' + sitUserId + ') could not be executed since sitUserId==0;');
  //    }
  //  })// .catch((err) => console.error(date.getTime() + ': promise of createProfilePicsFromServer(sitUserId : ' + sitUserId + ') has error: ' + err));
  //}
  // ---------------------------------------------------------------
  // Note: This method gets the profilePics unencrypted from the server
  // ---------------------------------------------------------------
  // TODO: Intend to deprecate:
  // ------------------------------------------------------------------
  public getProfilePicsOvertly ( sitId: number ): any
  {
    if ( sitId > 0 )
    {
      this.httpService.postObservable( '/Member/GetProfilePicsOpenly', { sitUserId: sitId }, 'json2text' ).subscribe( result =>
      {
        if ( result )
        {
          // debugger;
          const profilePicFiles = result;
          // debugger;
          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profilePicFiles )
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( profilePicFiles.files )
            && profilePicFiles.files.length > 0 )
          {

            // let images = [];
            // profilePicFiles.files.map(e => {
            // let imageString = 'data:image/png;base64,' + e.FileContents;
            // images.push(imageString);
            // });
            // this.profilePics.images = images;
            // return this.executeProfilePicFilesTasks(profilePicFiles);
          }
        }
      } );
    }
  }

  // ---------------------------------------------------------------------------------
  public getCounters () : Counters {
    return this.counters;
  }
  // ---------------------------------------------------------------------------------
  public getProfileImageArr () : ProfileImage[] {
    return this.profileImageArr;
  }
  // ---------------------------------------------------------------------------------
  public setProfileImageArr (value : ProfileImage[]) : any {
    this.profileImageArr = value;
    return true;
  }
  // ---------------------------------------------------------------------------------
  public getProfilePics () : ProfilePics {
    return this.profilePics;
  }

  // ---------------------------------------------------------------------------------
  public setCounters (value : Counters) : any {
    this.counters = value;
    return true;
  }
  // ---------------------------------------------------------------------------------
  public setProfilePics (value : ProfilePics) : any {
    this.profilePics = value;
    return true;
  }
  // ----------------------------------------------------------------
  setupDictionaryProfilePicsForViewMember (pPics : ProfilePics, sitUser : SitUser) : ProfilePics | any {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) || pPics.sitUserId === 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) || sitUser.sitUserId > 0) {
        this.profilePics = CopyServiceStatic.createProfilePicsModelFromSitUser(sitUser);
      }
    }
    else this.profilePics = pPics;

    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)) {
      let dKvPhotoArr = this.dictionaryService.getUserKvPhotoArrFromKvPhotoDictionary(sitUser.sitUserId);
      // debugger;

      // filter the unFiltered kvPhotoArr:
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dKvPhotoArr) && dKvPhotoArr.length > 0) {
        let tFilteredKvPhotoArr = this.photoPrivacyService.filterAndSortProfileKvPhotoArr(dKvPhotoArr);
        // debugger;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tFilteredKvPhotoArr) && tFilteredKvPhotoArr.length > 0) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.filteredKvPhotoArr)
            && this.profilePics.filteredKvPhotoArr.length === 0) {
            this.profilePics.filteredKvPhotoArr = tFilteredKvPhotoArr;
            // debugger;
          }
          else {
            // debugger;
            this.profilePics.filteredKvPhotoArr = [ this.profilePics.filteredKvPhotoArr, ...tFilteredKvPhotoArr ];
            // debugger;
            this.profilePics.filteredKvPhotoArr = ArraySupportServiceStatic.makeArrayOfUniqElements(this.profilePics.filteredKvPhotoArr);
            // debugger;
          }

          // debugger;
          this.dictionaryService.updateDictionary(this.profilePics, "ProfilePics", this.loginSuccess.signedInUserId);
          // if primaryPhotoBnId was not found on KvPhotoArr, so add it:
          // -----------------------------------------------------------
          // debugger;
          this.profilePics = this.pagerSupportService.updateProfilePicsArraysWithPrimaryKvPhotoIfMissing(this.profilePics);
          // debugger;// watch-
        }
      }
    }
    return this.profilePics;
  }

  // -----------------------------------------------------------
  
  // ---------------------------------------------------------------
  

  // -----------------------------------------------------------
  
  // ==================================================================
}
// --------------------------------------------------------------------
 // --------------------------------------------------------------------------------------
  // public getUserPicsOld ( sitUserId: number, userPhotoBnIds: number[] ): Promise<any>
  // {
  //  //  TODO: remove after testing/before deployment:
  //  // --------------------------------------------------------------------------------------
  //  // Note:  20211224: As of this date, services will not load profilePics from dictionary.
  //  //      The components will get the profilePics directly from the dictionary if needed.
  //  // --------------------------------------------------------------------------------------
  //  tProfilePics = this.getDictionaryProfilePics( this.sitUser.sitUserId );

  //  // debugger;
  //  // Case of Dictionary ProfilePics:
  //  // -------------------------------
  //  // get the user's profilePics from the dictionary if exists
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics )
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tProfilePics.profileImageArr )
  //    && tProfilePics.profileImageArr.length > 0 )
  //  {
  //  // debugger;
  //  this.profilePics = tProfilePics;

  //  // debugger;
  //  if ( this.profilePics.sitUserId === this.sitUser.sitUserId )
  //  {
  //    // debugger;
  //    EmitterSubjectService.setProfilePics( this.profilePics );
  //    this.profilePics = this.createUserAlbumService.displayFilteredKvPhotosOfProfilePics( this.profilePics );
  //    // debugger;
  //  }
  // }
  // debugger;
  // Note: Old: returned data was ProfileImage
  // -----------------------------------------
  // this.photoBnService.getUserPhotoBn( e, sitUserId ).then( pImage =>
  //  {
  //    // debugger;
  //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pImage ) )
  //    {
  //      this.profilePics = this.dictionaryService.profilePicsDictionary.getValue( pImage.sitUserId ) as ProfilePics;
  //      // debugger;
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics.profileImageArr )
  //        && this.profilePics.profileImageArr.length > 0 )
  //      {
  //        // debugger;
  //        this.profilePics.sitUserId = sitUserId;
  //        this.profilePics = this.executeOnProfileImageArrayComplete( this.userPhotoBnIds, this.profilePics );
  //      }
  //      else
  //      {
  //        if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics ) )
  //        {
  //          this.profilePics = new ProfilePics();
  //          this.profilePics.sitUserId = sitUserId;
  //        }
  //        if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics.profileImageArr ) )
  //        {
  //        this.profilePics.profileImageArr = [];
  //        }
  //        // find the index of entry
  //        let index = this.createUserAlbumService.getIndexOfProfileImageInArr( this.profilePics.profileImageArr, pImage );

  //        if ( pImage.sitUserId === sitUserId )
  //        {
  //          // debugger;
  //          if ( index < 0 )
  //          {
  //            // debugger;
  //            this.profilePics.profileImageArr.push( pImage );
  //          } else
  //          {
  //            // debugger;
  //            this.profilePics.profileImageArr.splice( index, 1, pImage );
  //          }
  //        }
  //        // debugger;
  //        this.profilePics = this.executeOnProfileImageArrayComplete( this.userPhotoBnIds, this.profilePics );
  //      }
  //    }
  //    // debugger;
  //    for ( let e of this.profilePics.filteredKvPhotoArr)
  //    {
  //      e.value = JsRegExpServiceStatic.stripUrlForImageData( e.value );
  //      // debugger;
  //    }
  //    // debugger;
  //    EmitterSubjectService.setProfilePics(this.profilePics);
  //    resolve( this.profilePics );
  //  } );
  // });  
  // ---------------------------------------------------------------
/* public executeProfilePicFilesTasks ( ppFiles:ProfilePicFiles ):ProfilePics {
  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ppFiles)
    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ppFiles.files)
    && ppFiles.files.length > 0) {
      //debugger;
      //sort the images
      this.memberService.profilePics.profileImageArr = this.memberService.sortProfileImageArr(this.memberService.profilePics.profileImageArr);

      //ProfilePics - new Scheme (with KvPhoto model):
      //prepare the keyValue-data:
      //----------------------------------------------

      let profilePics = this.createProfilePicsFromProfilePicFiles(ppFiles);

      //let tKvPhotoArr = this.copyService.copyProfilePicsToKvPhotoArr(profilePics);
      if (this.memberService.profilePics.profileImageArr.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)) {
        const tKvPhotoArr = this.copyService.copyProfilePicsToKvPhotoArr(profilePics);

        this.photoPrivacyService.filterKvPhotoArrayByMemberLockingActivityDictionaryData(tKvPhotoArr).then(data => {
          this.memberService.profilePics.filteredKvPhotoArr = data;
        });
        if (this.filteredKvPhotoArr && this.filteredKvPhotoArr.length > 0) {
          //debugger;
          EmitterSubjectService.emitProfileImages(this.filteredKvPhotoArr);
          //debugger;
          return profilePics;
        }
    }
  }
  else {
    return null;
  }
}
*/

  // ---------------------------------------------------------------
  // public createProfilePicsFromProfilePicFiles ( ppFiles:ProfilePicFiles ):ProfilePics
  // {
  //  const profilePic = new ProfilePics();
  //  let image:any;
  //  let tTitle:any;
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( ppFiles ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( ppFiles.files ) && ppFiles.files.length > 0 )
  //  {
  //    // ----------------------------------
  //    profilePic.cryptUserKey = ppFiles.cryptUserKey;
  //    profilePic.date = ppFiles.date;
  //    profilePic.id = ppFiles.id;
  //    profilePic.entityName = ppFiles.entityName;
  //    profilePic.profileId = ppFiles.profileId;
  //    profilePic.sitUserId = ppFiles.sitUserId;
  //    profilePic.sitUserKey = ppFiles.sitUserKey;
  //    // debugger;
  //    ppFiles.files.map( e =>
  //    {
  //      if ( e.length > 0 )
  //      {
  //        tTitle = JSON.parse( JSON.stringify( e ) );
  //        // if (tTitle && tTitle.fileDownloadName) { tTitle.fileDownloadName.toString().Replace(' ', '') };
  //        // debugger;
  //        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e ) && e.toString().length > 0 )
  //        {
  //          image = '/profilePics/' + e.toString();
  //          // debugger;
  //          // let image = 'url(\'' + e.image.toString() + '\')';
  //          // e.image = image;
  //          profilePic.images.push( image );
  //        }
  //        else if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tTitle ) && tTitle.toString().length > 0 )
  //        {
  //          {
  //            image = '/profilePics/' + tTitle.toString();
  //            // debugger;
  //            // let image = 'url(\'' + e.image.toString() + '\')';
  //            // e.image = image;
  //            profilePic.images.push( image );
  //          }
  //        }
  //      }
  //    } );
  //  }
  //  return profilePic;
  // }
// Note: Do not call or uncomment this. The ServerDataMethod takes care of the same task.
// --------------------------------------------------------------------------------------

//  //debugger;
//  let emitterUserPhotoIds = EmitterSubjectService.getUserPhotoIds();
//  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tUserPhotoIds) && tUserPhotoIds.length > 0) {
//    this.userPhotoIds = emitterUserPhotoIds;
//  }
//  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.userPhotoIds)
//  && this.userPhotoIds.length > 0
//  && this.sitUser?.sitUserId > 0) {
//    let tPPics = this.fetchUserPhotoBns(this.userPhotoIds, this.sitUser);
//    //debugger;
//    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPPics)) {
//      this.profilePics = tProfilePics;
//      this.profilePics = this.createUserAlbumService.executeMemberViewProfilePicsTasks(this.profilePics);

//      isDictionaryProfPicsDataGood = true;
//      //debugger;
//    }
//  }
