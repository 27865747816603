'use-strict'
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreferenceComponent } from './preference/preference.component';
import { ProfileComponent } from './profile/profile.component';
import { ProfileContentComponent } from './profileContent/profileContent.component';

export const profileRoutes: Routes = [
  {
    path: '',
    children: [
      { path: 'memberEdit', component: ProfileComponent },
      { path: 'preference', component: PreferenceComponent },
      { path: 'profileContent', component: ProfileContentComponent },
      { path: 'profile', component: ProfileComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(profileRoutes)],
  exports: [RouterModule],
})
export class ProfileRoutingModule { }
