
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { RedirectionService } from '../../commonServiceService/redirectionService.service';
import { EmitterSubjectService } from '../emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ScrollTopServiceStatic } from './scrollTopServiceStatic.service';
import { WhenScrollEndsServiceStatic } from './whenScrollEndsServiceStatic.service';



@Injectable({
  providedIn: 'root'
})
export abstract class HammerServiceStatic implements OnDestroy {
  overrides = <any> {
    // I will only use the swap gesture so 
    // I will deactivate the others to avoid overlaps
    'pinch': { enable: false },
    'rotate': { enable: false }
  }
  static redirectionService : RedirectionService;
  static currentPage : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  static elemId = '';
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  static eventAction : string = '';
  static hammer : any;
  static isActive = true;
  static isPhotoMgmt = true;
  static isViewMember = true;
  static loginSuccess : LoginSuccess = new LoginSuccess();
  static mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  static message = '';
  static myElement : any;
  static mc : any;
  static profileImageKvPhotos : KvPhoto[] = [];
  static router : any;
  static timer : any;
  static timerArray : any[] = [];
  //mc.add(new Hammer.Swipe({ direction: Hammer.DIRECTION_HORIZONTAL }));
  //mc.add(new Hammer.Pan({ direction: Hammer.DIRECTION_HORIZONTAL }));

  constructor (
    @Inject(Document) document : any,
    private _redirectionService: RedirectionService,
    @Inject(Window) window : any,
    public Hammer : HammerGestureConfig
  ) {
    HammerServiceStatic.hammer = Hammer;
    HammerServiceStatic.redirectionService = _redirectionService;
      // debugger;
  }
  
  
 
  // ---------------------------------------------------------------
  ngOnDestroy () {
    // prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
    //       (2) https:// www.concretepage.com/angular-2/angular-4-renderer2-example
    //       (3) https:// stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
    //       (4) https:// stackblitz.com/edit/timer-with-pdestroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    HammerServiceStatic.timerArray.forEach(timer => clearInterval(timer));
    // debugger;
  }
  // --------------------------------------------------------------
  static executeAction (evType: string, elemId: string): void {
    // debugger;
    let currentPage : any;
    let pageNo = -1;
    switch (evType.toLowerCase()) {
      case 'swipeleft':  /*next*/
      case 'panleft':
        if (elemId.indexOf('navbarComponentId') !== -1) {
          EmitterSubjectService.emitGesture(evType);
        }
        else {
          //debugger;
          EmitterSubjectService.emitPagerBreadcrumDirection('next');
          ScrollTopServiceStatic.scrollToTop('allScrollToId');
        }
        break;
      case 'swiperight': /*prev*/
      case 'panright':
        if (elemId.indexOf('navbarComponentId') !== -1) {
          EmitterSubjectService.emitGesture(evType);
        }
        else {
          // debugger;
          EmitterSubjectService.emitPagerBreadcrumDirection('prev');
          ScrollTopServiceStatic.scrollToTop('allScrollToId');
        }
        break;
      case 'swipedown':
      case 'pandown':
        debugger;
        if (elemId.indexOf('appRootId') !== -1) {
          debugger;
          // let returnUrl = window.location.pathname + window.location.search;
          // RedirectionServiceStatic.appStartUrlStringParser(this.router);
          this.redirectionService.redirectToAction(HammerServiceStatic.router).subscribe(data => {
            console.log(data); // do nothing
          });
        }
        // ScrollTopServiceStatic.scrollToBottom(elemId);
        // debugger;
        break;
      case 'swipeup':
      case 'panup':
        debugger;
        ScrollTopServiceStatic.scrollToTop(elemId);
        // debugger;
        break;
      // case 'whenscrollends':
      //  this.whenScrollEndsService.whenScrollEnds();
      //  debugger;
      //  break;
      case 'swipe':
        // debugger;
        if (elemId.indexOf('navbarComponentId') !== -1) {
          EmitterSubjectService.emitGesture(evType);
        }
        else {
          EmitterSubjectService.emitPagerBreadcrumDirection('next');
        }
        // debugger;
        break;
      default:
        debugger;
        // RedirectionServiceStatic.appStartUrlStringParser(this.router);
        this.redirectionService.redirectToAction(this.router).subscribe(data => {
          console.log(data); // do nothing
        });

        //if (elemId.indexOf('navbarComponentId') !== -1) {
        //  EmitterSubjectService.emitGesture(evType);
        //}
        //else {
        //  EmitterSubjectService.emitPagerBreadcrumDirection('next');
        //}
        break;
    }
  }
  // --------------------------------------------------------------
  private static executePanAction (evType : string, elemId : string) : void {
    // debugger;
    let currentPage : any;
    let pageNo = -1;
    switch (evType.toLowerCase()) {
      case 'panleft': /*next*/
        if (elemId.indexOf('navbarComponentId') !== -1) {
          EmitterSubjectService.emitGesture(evType);
        }
        else {
          currentPage = EmitterSubjectService.getCurrentPage();
          pageNo = parseInt(currentPage.pageNo, 0);
          pageNo++;
          EmitterSubjectService.emitCurrentPage(pageNo);
          EmitterSubjectService.emitSetPage(pageNo);
          debugger;
        }
        break;
      case 'panright': /*prev*/
        if (elemId.indexOf('navbarComponentId') !== -1) {
          EmitterSubjectService.emitGesture(evType);
        }
        else {
          currentPage = EmitterSubjectService.getCurrentPage();
          pageNo = currentPage.pageNo;
          pageNo--;
          EmitterSubjectService.emitCurrentPage(pageNo);
          EmitterSubjectService.emitSetPage(pageNo);
          debugger;
        }
        break;
      case 'pandown':
        ScrollTopServiceStatic.scrollToBottom(elemId);
        debugger;
        break;
      case 'pandup':
        ScrollTopServiceStatic.scrollToTop(elemId);
        debugger;
        break;
      case 'whenscrollends':
        WhenScrollEndsServiceStatic.whenScrollEnds();
        debugger;
        break;
      case 'pan':
      case 'tap':
      case 'press':
        if (elemId.indexOf('navbarComponentId') !== -1) {
          EmitterSubjectService.emitGesture(evType);
        }
        // debugger;
        break;
      default:
        if (elemId.indexOf('navbarComponentId') !== -1) {
          EmitterSubjectService.emitGesture(evType);
        }
        break;
    }
  }
  // ---------------------------------------------------------------------------------
  //  API:
  //  ref: https://codepen.io/jtangelder/pen/bGLmMw
  // ---------------------------------------------------------------------------------
  static hammeredElement (elemId : string, evt: Event, router: Router) : any {
    // debugger;
    this.router = router;
    let message = '';
    let actionType = '';
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      var element = document.getElementById(elemId) as HTMLElement;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(element)) {
        actionType = evt.type;
      }
      // create a simple instance
      // by default, it only adds horizontal recognizers
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(element)) {
        var mc = this.hammer.buildHammer(element); // use HammerGestureConfig-provided method here 

        // let the pan gesture support all directions.
        // this will block the vertical scrolling on a touch-device while on the element
        mc.get('pan').set({ direction: this.hammer.DIRECTION_ALL });

        // let the swipe gesture support all directions.
        // this will block the vertical scrolling on a touch-device while on the element
        mc.get('swipe').set({ direction: this.hammer.DIRECTION_ALL });

        if (actionType.indexOf('pan') !== -1 || actionType.indexOf('tap') !== -1 || actionType.indexOf('paress') !== -1 ) {
          // debugger;
          this.executeAction(actionType, elemId);
        }
        else if (actionType.indexOf('swipe') !== -1) {
          // debugger;
          this.executeAction(actionType, elemId);
        }
        this.message = message;
        // debugger;
        // EmitterSubjectService.emitMessage(this.message);
        element.remove();
      }
    }
  }
  // --------------------------------------------------------------
}
