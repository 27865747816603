
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LandingPageComponent } from './appComponentsModule/landingPage/landingPage.component';

/*
 *  ----------------------------------------------------------------------------------------------------
 *  Note: the following is the initial attempt to lazy-load-modules, and dynamically loading components,
 *        which fails in case of nested - child - components of any parent - component
 *  Attempt#1:
 *  ref:https://stackblitz.com/github/mokkapps/angular-manual-lazy-load-demo?file=src%2Fapp%2Flazy-loader.service.ts
 *  ----------------------------------------------------------------------------------------------------
 */

let lChildren: any;
const appRoutes : Routes = [


  //  --------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------
  { path: 'index', component: LandingPageComponent, data: { preload: true } },
  //  --------------------------------------------------------------------------------------
  {
    path: 'appcomponentsm',
    loadChildren: () => import('./appComponentsModule/appComponents.module').then(m => { return m.AppComponentsModule })
  },
  // ---------------------------------------------------------------------------------------
  {
    path: 'publicm',
    loadChildren: () => import('./publicModule/public.module').then(m => { return m.PublicModule; })
  },
  // ---------------------------------------------------------------------------------------
  {
     path: 'loginm',
    loadChildren: () => import('./loginModule/login.module').then((m) => {  return m.LoginModule; })
  },
  //  --------------------------------------------------------------------------------------
  //{
  //  path: 'membersm',
  //  loadChildren: () => import("./membersModule/members.module").then(m => { return m.MembersModule; })
  //},
  // ---------------------------------------------------------------------------------------
  //{
  //  path: 'memberscrollm',
  //  loadChildren: () => import("./memberInfiniteScrollModule/memberInfiniteScroll.module").then(m => { return m.MemberInfiniteScrollModule; })
  //},
  // ---------------------------------------------------------------------------------------
  {
    path: 'profiletilescrollm',
    loadChildren: () => import("./profileTileScrollModule/profileTileScroll.module").then(m => { return m.ProfileTileScrollModule; })
  },
  // ---------------------------------------------------------------------------------------
  {
    path: 'memberViewAndUpgradedm',
    loadChildren: () =>
      import("./memberViewAndUpgradedModule/memberViewAndUpgraded.module").then(m => { return m.MemberViewAndUpgradedModule; })
  },
  //  --------------------------------------------------------------------------------------  
  {
    path: 'mym',
    loadChildren: () => import('./myModule/my.module').then(m => { return m.MyModule; }) 
  },
  //  --------------------------------------------------------------------------------------
  {
    path: 'profilem',
    loadChildren: () => import('./profileModule/profile.module').then(m => { return m.ProfileModule; }) 
  },
  //  --------------------------------------------------------------------------------------
  //{
  //  path: 'registerm',
  //  loadChildren: () => import('./registerModule/register.module').then(m => { return m.RegisterModule; }) 
  //},
  //  --------------------------------------------------------------------------------------
  {
    path: 'chatm',
    loadChildren: () => import('./chatModule/chat.module').then(m => { return m.ChatModule; })
  },
  //  --------------------------------------------------------------------------------------
  {
    path: 'communicationm',
    loadChildren: () => import('./communicationModule/communication.module').then(m => { return m.CommunicationModule; })
  },
  //  --------------------------------------------------------------------------------------
  {
    path: 'activitym',
    loadChildren: () => import('./memberActivityModule/memberActivity.module').then(m => { return m.ActivityModule; }) 
  },
  //  --------------------------------------------------------------------------------------
  {
    path: 'signupm',
    loadChildren: () => import('./signupModule/signup.module').then(m => { return m.SignupModule; })
  },
  //  --------------------------------------------------------------------------------------
  {
    path: 'salesm',
    loadChildren: () => import('./salesModule/sales.module').then(m => { return m.SalesModule; })
  },
  //  --------------------------------------------------------------------------------------
  {
    path: 'salestestm',
    loadChildren: () => import('./salesTestModule/salesTest.module').then(m => { return m.SalesTestModule; })
  },
  // ---------------------------------------------------------------------------------------
  {
    path: 'sandboxm',
    loadChildren: () => import('./sandBoxModule/sandBox.module').then(m => { return m.SandBoxModule; })
  },
  //  --------------------------------------------------------------------------------------
  // {
  //  path: 'modalm',
  //  loadChildren: async () => await import( './modalModule/modal.module' ).then( m => m.ModalModule )
  // },
  //  --------------------------------------------------------------------------------------
  // {
  // path: 'gesture',
  // loadChildren: async () => await import( './gestureModule/gesture.module' ).then( m => m.GestureModule )
  // },
  //  --------------------------------------------------------------------------------------
   {
   path: 'appadminm',
   loadChildren: () => import( './appAdminModule/app-admin.module' ).then( m => m.AppAdminModule )
   },
  
  //  --------------------------------------------------------------------------------------
  
  // { path: '', redirectTo: 'index', pathMatch: 'full' },
  //  --------------------------------------------------------------------------------------
  //{
  //  path: '',
  //  redirectTo: '/index',
  //  pathMatch: 'full',
  //},
  //  --------------------------------------------------------------------------------------
  { path: '**', component: LandingPageComponent, data: { preload: true } },
  //  --------------------------------------------------------------------------------------
];


@NgModule({ 
  imports: [
    RouterModule.forRoot(appRoutes, {
      // initialNavigation: true || 'enabled',
      // relativeLinkResolution: 'corrected', // ref: https://github.com/angular/angular/issues/17957
    }),
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
