import { Dictionary } from 'dictionaryjs';
// import * as Collections from 'typescript-collections';
import { ProfilePicsInterface } from '../../interfaces/modelInterfaces/profilePics.model.interface';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../../services/staticServices/stringServiceStatic.service';
import { BoxNonceEntity } from '../boxNonce/boxNonceEntity.model';
import { Photo } from '../common/photo.model';
import { PhotoBn } from '../common/photoBn.model';
import { KV } from '../keyValue/kv.model';
import { KvAny } from '../keyValue/kvAny.model';
import { KvMemberActivityGlyph } from '../keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../keyValue/kvPhoto.model';
import { ProfileImage } from './profileImage.model';
// BSTreeKV<{order: number}, {order: number, data: string}>
export class ProfilePics implements ProfilePicsInterface {
  public arrKv: KV[] = [];
  public arrKvAny: KvAny[] = [];
  public cryptUserKey = '';
  public date = '';
  public entityName = '';
  // will most likely contain modified data depending on for example, Locking/Unlocking activities or isUnlocked
  // public filteredKvPhotoBST: BSTreeKV<{ order: number}, { order: number; data: KvPhoto}> = new BSTreeKV<{ order: number}, { order: number; data: KvPhoto}>();
  public filteredKvPhotoDictionary: Dictionary<number, KvPhoto> = new Dictionary<number, KvPhoto>();
  public filteredKvPhotoArr : KvPhoto[] = [];
  // public filteredKvPhotoDictionary : Dictionary<number, KvPhoto> = new Dictionary<number, KvPhoto>();
  public unFilteredKvPhotoArr : KvPhoto[] = [];
  public unFilteredKvPhotoMap : Map<number,KvPhoto> = new Map<number, KvPhoto>(); // !!! intend to deprecate !!!
  public unFilteredKvPhotoDictionary : Dictionary<number, KvPhoto> = new Dictionary<number, KvPhoto>();
  /*
   * Note: this is just a dummy variable, which should be disrgarded, and not used.
   * Note: should contain server-send-data that will not be changed unless acted duing PhotoMgmtActivities.
   */
  public profileImageDictionary : Dictionary<number, ProfileImage> = new Dictionary<number, ProfileImage>();
  public profilePhotoDictionary : Dictionary<number, Photo> = new Dictionary<number, Photo>();
  // --------------------------------------------------------------
  public id = 0;
  public isPhotoUploadSuccessful = false;
  public images: string[] = [];
  public model !: any[];
  public pagedProfilePics: string[] = [];
  public pagedGlyphArr : KvMemberActivityGlyph[] = [];
  // will most likely contain modified data depending on for example, Locking/Unlocking activities or isUnlocked
  public pagedKvPhotoArr : KvPhoto[] = [];
  public primaryKvPhoto : KvPhoto = new KvPhoto();
  public profilePhotoBnArr : PhotoBn[] = [];
  public profileImageArr: ProfileImage[] = [];
  public profileImageBnArr: BoxNonceEntity[] = [];
  public profileGlyphArr : KvMemberActivityGlyph[] = [];
  public profileKvPhotoArr: KvPhoto[] = [];

  /*
   * Note: should contain server-send-data that will not be changed unless acted duing PhotoMgmtActivities.
   * public profileImageBST: Collections.BSTreeKV<{ order: number }, { order: number, data: ProfileImage }> =
   *   new Collections.BSTreeKV<{ order: number }, { order: number, data: ProfileImage }>();
   * public profileGlyphBST: Collections.BSTreeKV<{ order: number }, { order: number, data: KvGlyph }> =
   *   new Collections.BSTreeKV<{ order: number }, { order: number, data: KvGlyph }>();
   * public profileKvPhotoBST: Collections.BSTreeKV<{ order: number }, { order: number, data: KvPhoto }> =
   *   new Collections.BSTreeKV<{ order: number }, { order: number, data: KvPhoto }>();
   */

  /*
   * Note: should contain server-send-data that will not be changed unless acted duing PhotoMgmtActivities.
   * public profileImageDictionary: Collections.Dictionary<number, ProfileImage> =
   *   new Collections.Dictionary <number, ProfileImage>();
   * public profileGlyphDictionary: Collections.Dictionary<number, KvGlyph> =
   *   new Collections.Dictionary<number, KvGlyph>();
   * public profileKvPhotoDictionary: Collections.Dictionary<number, KvPhoto> =
   *   new Collections.Dictionary<number, KvPhoto>();
   */

  public profileId = 0;
  public sitUserId = 0;
  public sitUserKey = '';
  public unsalted: any;

  constructor () { }
   // ---------------------------------------------------------------

  public compareNumbers (a : any, b : any) {
    return a - b;
  }
  public sort(): any {
    return (a: any, b: any) => {
      if (a && a.id && b && b.id) {
        return a.id - b.id; // sort it in ascending order
      }
      return a.localeCompare(b);
    };
  }
  // ---------------------------------------------------------------
  public isModelKvPhotoArraysEmpty (pPics: ProfilePics) : boolean {
    let result = true;
    if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr) && pPics.filteredKvPhotoArr.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr) && pPics.unFilteredKvPhotoArr.length > 0))
    {
      result = false;
    }

    // debugger;
    return result;
  }
  // ---------------------------------------------------------------
  public isKvPhotoDictionariesEmpty (pPics:ProfilePics) : boolean {
    let result = true;
    if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoDictionary) && pPics.filteredKvPhotoDictionary?.values?.length > 0)
      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoDictionary) && pPics.unFilteredKvPhotoDictionary?.values?.length > 0)) {
      result = false;
    }

    // debugger;
    return result;
  }
  // ---------------------------------------------------------------
  public getIndexOfProfileImageInArr ( pImage: ProfileImage ): number
  {
    // debugger;
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profileImageArr ) && this.profileImageArr.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pImage ) && pImage.photoId > 0 )
    {
      for ( let i = 0; i < this.profileImageBnArr.length; i++ )
      {
        // TODO: change the condition to find this bn, may be by photoId alone or with sitUserId
        if ( this.profileImageArr[ i ].photoId === pImage.photoId && this.profileImageArr[ i ].sitUserId === pImage.sitUserId )
        {
          // debugger;
          index = i;
          break;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  public getIndexOfProfileImageBnInArr ( bn: BoxNonceEntity ): number
  {
    // debugger;
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profileImageBnArr ) && this.profileImageBnArr.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( bn ) && bn.sitUserId > 0 )
    {
      for ( let i = 0; i < this.profileImageBnArr.length; i++ )
      {
        // TODO: change the condition to find this bn, may be by photoId or bnId
        if ( this.profileImageBnArr[ i ].id === bn.id && this.profileImageBnArr[ i ].sitUserId === bn.sitUserId && this.profileImageBnArr[ i ].entityName === bn.entityName )
        {
          // debugger;
          index = i;
          break;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  // Note:  array.splice(index, howmany, item1, ....., itemX)
  //
  //        The first argument specifies the location at which to begin adding or removing elements.
  //        The second argument specifies the number of elements to remove.;
  //        The third and subsequent arguments are optional; they specify elements to be added to the array.
  // ---------------------------------------------------------------
  public updateProfileImageBnInArr ( bn: BoxNonceEntity ): void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( bn ) && bn.sitUserId > 0 )
    {
      let index = this.getIndexOfProfileImageBnInArr( bn );
      if ( index === -1 )
      {
        this.profileImageBnArr.push( bn );
      }
      else
      {
        this.profileImageBnArr.splice(index, 1, bn);
      }
    }
  }
  // ---------------------------------------------------------------
  public updateProfileImageInArr ( pImage: ProfileImage ): void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pImage ) && pImage.photoId > 0 )
    {
      let index = this.getIndexOfProfileImageInArr( pImage );
      if ( index === -1 )
      {
        this.profileImageArr.push( pImage );
      }
      else
      {
        this.profileImageArr.splice( index, 1, pImage );
      }
    }
  }
  // ---------------------------------------------------------------
  public setKvPhoto (id: number, dictionaryName: string,  kvp: KvPhoto) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp) && kvp.photoBnId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryName)) {
      let kvpd : any;
      
      switch (dictionaryName) {
        case 'filteredKvPhotoDictionary':
          kvpd = this.filteredKvPhotoDictionary.set(kvp.photoBnId, kvp);
          return JSON.parse(JSON.stringify(this.filteredKvPhotoDictionary));
        case 'unFilteredKvPhotoDictionary':
          kvpd = this.unFilteredKvPhotoDictionary.set(kvp.photoBnId, kvp);
          debugger;
          return JSON.parse(JSON.stringify(this.unFilteredKvPhotoDictionary));

      }
    }
  }
  // ---------------------------------------------------------------
  public getKvPhoto (photoBnId : number, dictionaryName : string) : any {
    if (photoBnId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryName)) {
      let kvp : KvPhoto = null;
      switch (dictionaryName) {
        case 'filteredKvPhotoDictionary':
          kvp = this.filteredKvPhotoDictionary.get(photoBnId);
          debugger;
          return kvp;
        case 'unFilteredKvPhotoDictionary':
          kvp = this.unFilteredKvPhotoDictionary.get(photoBnId);
          return kvp;

      }
    }
  }
  // ---------------------------------------------------------------
  public populateDictionaries (): void {
    if (!StringServiceStatic.isNullOrEmpty(this.filteredKvPhotoArr) && this.filteredKvPhotoArr.length > 0) {
      this.filteredKvPhotoArr.forEach(e => {
        this.filteredKvPhotoDictionary.set(e.photoBnId, e);
      })
    }
    if (!StringServiceStatic.isNullOrEmpty(this.unFilteredKvPhotoArr) && this.unFilteredKvPhotoArr.length > 0) {
      this.unFilteredKvPhotoArr.forEach(e => {
        this.unFilteredKvPhotoDictionary.set(e.photoBnId, e);
      })
    }
  }
  // ---------------------------------------------------------------

}
