'use strict'
import { HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { ChatFileUpload } from '../../models/chat/chatFileUpload.model';
import { Content } from '../../models/common/content.model';
import { Photo } from '../../models/common/photo.model';
import { PhotoBn } from '../../models/common/photoBn.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ImageResizerService } from '../commonServiceService/imageResizerService.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { CopyServiceStatic } from './commonStaticServices/copyServiceStatic.service';
import { DateStringServiceStatic } from './commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from './commonStaticServices/slakezSaltServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from './jsRegExpServiceStatic.service';

// NOTE: Caution!! This service relies on DictionaryServiceStatic, which is deprecated. Instead user DictionaryServive,
//                  and change the necessary commented-lines of codes in the following functions:
//                  getProfilePicsModel() , sendWithGeoLocation(), & SendModel()

const url = '/FileUpload/ProfilePhotoUpload';

@Injectable({ providedIn: 'root' })
export abstract class UploadServiceStatic {
  public static loginSuccess : LoginSuccess = new LoginSuccess();
  
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  constructor (
    public dictionaryService : DictionaryService) {
  }

  
  // ------------------------------------------------------------------------------------------------
  // ref:https:// www.htmlgoodies.com/html5/tutorials/determine-an-images-type-using-the-javascript-filereader.html
  // ------------------------------------------------------------------------------------------------
  static fileChunk (File : any) : any {
    if (!File.prototype.slice) {
      const newSlice = File.prototype.mozSlice || File.prototype.webkitSlice;

      if (newSlice) {
        File.prototype.slice = (() => (startingByte : any, length : any) => newSlice.call(this, startingByte, length + startingByte))();
      } else {
        alert('File.slice() not supported.');
      }
    }
  }
  // ---------------------------------------------------------------
  // TODO: test
  // ---------------------------------------------------------------
  static fileToString (file : File) : string {
    const myReader : FileReader = new FileReader();
    let tempPicStr : string = '';
    myReader.onerror = () => {
      myReader.abort();
      return new DOMException('Problem parsing input file.');
    };

    myReader.onloadend = (e) => {
       debugger;
      let tempPicStr = e?.target?.result as string; // JSON.stringify(fileStr);

      EmitterSubjectService.emitFileImage(tempPicStr);
       debugger;
      return e;
    };
    // debugger;
    myReader.readAsDataURL(file);
    debugger;
    return tempPicStr;
  }
  // ---------------------------------------------------------------
  static fileToStringArr (file : File) : string[] {
    const myReader : FileReader = new FileReader();
    let tempStringFiles : string[] = [];
    myReader.onerror = () => {
      myReader.abort();
      return new DOMException('Problem parsing input file.');
    };

    myReader.onloadend = (e) => {
      // debugger;
      let imageData = e?.target?.result as string;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
        tempStringFiles.push(imageData);
      }


      EmitterSubjectService.emitFileImages(tempStringFiles);
      return tempStringFiles;
      // debugger;
    };
    // debugger;
    myReader.readAsDataURL(file);
    return tempStringFiles;
  }
  // ---------------------------------------------------------------
  // Not in use:
  // ---------------------------------------------------------------
  static formDataToString (formData : FormData) : string[] {
    let tempStringFiles : string[] = [];
    formData.forEach(e => {
      tempStringFiles.push(JSON.stringify(e.valueOf));
    });
    return tempStringFiles;
  }
  // ---------------------------------------------------------------
   // Not in use:
  // ---------------------------------------------------------------
  static formDataToFileMap (formData : FormData) : Map<string, File> {
    let fileMap : Map<any, any> = new Map();
    formData.forEach((v, k) => {
      fileMap.set(k, v)
    });
    return fileMap;
  }
  // --------------------------------------------------------------
  static getUrl (data : any) : any {
    if (data.length > 0) {
      return 'url(\'data:image/jpeg;base64,' + data + '\')';
    }
  }
  // ---------------------------------------------------------------
  //  TODO: Test
  // ---------------------------------------------------------------
  static readFileContent (file : File) : Promise<string> {
    return new Promise<string>((resolve, reject) => {
      if (!file) {
        resolve('');
      }

      const reader = new FileReader();

      reader.onload = (e) => {
        const text = reader.result.toString();
        // debugger;
        resolve(text);

      };
      // debugger;
      reader.readAsText(file);
    });
  }
  // ---------------------------------------------------------------
  // readAsArrayBuffer()
  // ---------------------------------------------------------------

  static getFileTypeWhileUploading (file : any) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const buffer = 1024; // = this.fileChunk(reader.result);
      const int32View = new Int32Array(buffer);

      switch (int32View[ 0 ]) {
        case 1196314761:
          file.verified_type = 'image/png';
          break;
        case 944130375:
          file.verified_type = 'image/gif';
          break;
        case 544099650:
          file.verified_type = 'image/bmp';
          break;
        case -520103681:
          file.verified_type = 'image/jpg';
          break;
        default:
          file.verified_type = 'unknown';
          break;
      }
    };
  }
  // ---------------------------------------------------------------
  static saltFile (model : any) : any {
    let boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      // debugger;
      const bn = SlakezSaltServiceStatic.saltModel(model);
      // debugger;
      boxNonceEntity = bn
      return boxNonceEntity;
    }
    return null;
  }
  // ---------------------------------------------------------------
  static salt (model : any) : any {
    let boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      // debugger;
      const bn = SlakezSaltServiceStatic.boxSalt(JSON.stringify(JSON.parse(JSON.stringify(model))));
      // debugger;

      boxNonceEntity = bn
      return boxNonceEntity;
    }
    return null;
  }
  // -----------------------------------------------
  unsubscribed () : void{
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
	}
  // -----------------------------------------------
  static sendWithGeoLocation (content : Content, httpService : HttpService) : Observable<any> {
    return new Observable<any>((subscriber) => {
      let loginSuccess = EmitterSubjectService.getLoginSuccess();
      let chatFileUpload : ChatFileUpload = new ChatFileUpload();
      let boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
      let readFileDefArr : any[] = [];
   
      // let profilePics = this.getProfilePicsModel(this.loginSuccess.signedInUserId);

      // var formData = new FormData();
      const nvAnyArr : KvAny[] = [];
      let kvAny : KvAny = new KvAny();

      kvAny.key = 'contentType';
      kvAny.value = 'image/jpeg';
      nvAnyArr.push(kvAny);

      kvAny = new KvAny();
      kvAny.key = 'date';
      kvAny.value = DateStringServiceStatic.getTicks(new Date()).toString();
      nvAnyArr.push(kvAny);
      // debugger;

      kvAny = new KvAny();
      kvAny.key = 'description';
      kvAny.value = content.contents;
      nvAnyArr.push(kvAny);

      kvAny = new KvAny();
      kvAny.key = 'modelName';
      kvAny.value = 'keyValueAnyArray';
      nvAnyArr.push(kvAny);
      // debugger;

      // just profide the property for  the backend
      kvAny = new KvAny();
      kvAny.key = 'photoBnId';
      kvAny.value = 0;
      nvAnyArr.push(kvAny);

      // just profide the property for  the backend
      kvAny = new KvAny();
      kvAny.key = 'photoId';
      kvAny.value = 0;
      nvAnyArr.push(kvAny);

      kvAny = new KvAny();
      kvAny.key = 'fileArr';
      // debugger;
      kvAny.value = content.chatFileUpload.fileArr;
      nvAnyArr.push(kvAny);

      //kvAny = new KvAny();
      //kvAny.key = 'picArr';
      //kvAny.value = content.chatFileUpload.picArr;
      //nvAnyArr.push(kvAny);

      kvAny = new KvAny();
      kvAny.key = 'signedInUserId';
      kvAny.value = loginSuccess.signedInUserId.toString();
      nvAnyArr.push(kvAny);

      kvAny = new KvAny();
      kvAny.key = 'formData';
      var tempFileStrArr = ImageResizerService.formDataToString(content.chatFileUpload.formData);
      // debugger;
      kvAny.value = tempFileStrArr;
      nvAnyArr.push(kvAny);

      // debugger;
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position : any) => {
            if (position) {
              console.log('Latitude: ' + position.coords.latitude + '; Longitude: ' + position.coords.longitude);
              kvAny = new KvAny();
              kvAny.key = 'latitude';
              kvAny.value = position.coords.latitude;
              nvAnyArr.push(kvAny);

              kvAny = new KvAny();
              kvAny.key = 'longitude';
              kvAny.value = position.coords.longitude;
              nvAnyArr.push(kvAny);

              kvAny = new KvAny();
              kvAny.key = 'signedInSituserId';
              kvAny.value = this.loginSuccess.signedInUserId;
              nvAnyArr.push(kvAny);
              // debugger;

              // boxNonceEntity = this.saltFile(tempFileStr); // 1st salting of individual file
              for (let i = 0; i < content?.chatFileUpload?.fileMap?.size; i++) {
                // debugger;
                kvAny = new KvAny();
                kvAny.key = 'fileContents-' + i;

                this.readFileContent(content.chatFileUpload.fileArr[i]).then(fileContents => {

                  // debugger;
                  kvAny.value = JSON.stringify(fileContents);
                  nvAnyArr.push(kvAny);

                  // if (content?.chatFileUpload?.fileMap?.size == i) {
                    boxNonceEntity = SlakezSaltServiceStatic.saltModel(nvAnyArr); // 2nd salting
                    // debugger;
                    this.sendModel(boxNonceEntity, httpService).subscribe(data => {
                      // debugger;
                      subscriber.next(data);
                    });
                  // }
                })
                // debugger;
              }
            }
          },
          (error) => {
            // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag: ' + error);
            let message = 'Error occured in geolocation.getCurrentPosition;\n Error-mag: ' + error.message;
            EmitterSubjectService.emitMyErrorLog({ feedbackMessage: message });
          },
        );
      }   
           
      // this.sendFile(this.formData);  
    })
  }
  // ---------------------------------------------------------------
  // Note: This method should be in the calling component:
  // -----------------------------------------------
  static sendModel (boxNonce : any, httpService : HttpService) : Observable<any>
  {
    const files : any[] = [];
    let pPics : any;
    let photo : Photo = new Photo();
    let tPhoto : Photo = new Photo();
    let kvPhoto : KvPhoto = new KvPhoto();
    let kvPhotoArr : KvPhoto[] = [];
    let isSubmitted = true;
    let message = '';
    let signedInSitUser : SitUser = new SitUser();
     // debugger;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce))
    {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(boxNonce.nonce)) {
        // debugger;

        return new Observable<any>((subscriber) => {
          // Note: single or  multiple imges are uploaded in an array:
          // ---------------------------------------------------------
          // debugger;
          httpService
            .postObservable(
              '/api/FileUpload/ProfilePhotoUploadAsync',
              {
                box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(boxNonce.box),
                nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(boxNonce.nonce),
              },
              'json2text',
            )
            .subscribe((result) => {
              // debugger;
              if (result) {
                const bn = result as BoxNonceEntity;
                // debugger;
                if (bn && bn.box.length > 0 && bn.nonce.length > 0) {

                  // Note: backend/server will always send an array/list:
                  // ----------------------------------------------------
                  const pBnArr = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as any;
                  var pbn : any;
                  // debugger;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBnArr)) {
                    if (!(pBnArr instanceof Array)) {
                      pbn = JSON.parse(pBnArr) as PhotoBn;
                      // debugger;
                      pbn.pic = JsRegExpServiceStatic.setUrlForImageData(pbn.fileBytes);

                      kvPhoto = this.prepareUploadedKvPhotoBn(pbn);
                      // debugger;
                      // UploadServiceStatic.dictionaryService.updateDictionary(kvPhoto, 'unFilteredKVPhotoArr', kvPhoto.photoBnId);
                      subscriber.next(kvPhoto);
                    }
                    //else if (pBnArr.length > 0 && pBnArr[ 0 ] instanceof PhotoBn) {
                    //   debugger;
                    //  for (let e of pBnArr) {
                    //    debugger;
                    //    kvPhoto = this.updateProfilePicsWithNewPhotoBn(e);
                    //    // kvPhotoArr.push(kvPhoto);
                    //    debugger;
                    //    subscriber.next(kvPhoto);
                    //  } // end of for(let e of pBnArr)
                    //}

                    //pPics.isPhotoUploadSuccessful = true;
                    // debugger;
                    //EmitterSubjectService.emitUploadedProfilePics(pPics);
                    
                    // ----------------------------------------------------

                  }
                }
              }
            },
            (error) => {
              // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag: ' + error);
              message = 'Error occured in submitImageUpload()->sendModel();\n Error-mag: ' + error.message;
              // EmitterSubjectService.emitMyErrorLog({ feedbackMessage: message });
              // EmitterSubjectService.emitMessage({ feedbackMessage: message });
          });
        })
     }
     else return null;
   }
   else return null;
  }
  // ---------------------------------------------------------------
  // Note:  Old Scheme had the following:
  //        Server-PhotoBn stored just the pic encrypted into 
  //        box & nonce of the PhotoBn.
  // ---------------------------------------------------------------
  static prepareUploadedKvPhotoBnOld (pbn : PhotoBn) : KvPhoto | any {
    var kvPhoto = new KvPhoto();
    var pPics = new ProfilePics();
    var tPhoto = new Photo();
    var tPic = '';
    // if the backend sends photoBns:
    // -----------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn.box) && pbn.box.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn.nonce) && pbn.nonce.length > 0) {

      let tBn = new BoxNonceEntity();
      tBn.box = pbn.box;
      tBn.nonce = pbn.nonce;
      tPic = SlakezSaltServiceStatic.boxUnsalt(tBn) as string;

      // debugger;
      pbn.imageString = tPic;
      // debugger;
      kvPhoto = new PhotoBn().cloneToKvPhoto(pbn);
      // debugger;
      kvPhoto.value = tPic;
      kvPhoto.image = tPic;
      kvPhoto.pic = tPic;

      // Note: Uploaded pics are always Public by default.
      kvPhoto.privacy = 'Public';
      kvPhoto.isPrimary = false;
      kvPhoto.isPrivate = false;
      kvPhoto.isUnlocked = false;

      // save Photo from kvPhoto to profilePhotoDictionary of ProfilePics:
      // -----------------------------------------------------------------
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)) {
        // pPics.unFilteredKvPhotoArr.push(kvPhoto);
        kvPhoto = this.prepareKvPhotoForDisplay(kvPhoto, true);
        // kvPhoto.value = JsRegExpServiceStatic.prefixUrlToImageData(kvPhoto.value);
      }
    }
    else {
      // if the backend sends photo:
      // ---------------------------
      // debugger;
      kvPhoto = CopyServiceStatic.copyFromPhotoToKvPhoto(pbn);
      // kvP.value = JsRegExpServiceStatic.normalizeImageData(kvP.value);

      kvPhoto = this.prepareKvPhotoForDisplay(kvPhoto, false);
      kvPhoto.value = JsRegExpServiceStatic.prefixUrlToImageData(kvPhoto.value);
    }
    
    // debugger;
    return kvPhoto;
  }
  // ---------------------------------------------------------------
  // Note:  New scheme: The fileBytes are stored in PhotoBn, and then 
  //        all the other properties of the photoBn is captuered
  //        from the incoming model on the server, then the whole
  //        PhotoBn is encrypted and stored into the PhotoBn's
  //        box & nonce properties.
  // ----------------------------------------------------------------
  static prepareUploadedKvPhotoBn (pbn : PhotoBn) : KvPhoto | any {
    var kvPhoto = new KvPhoto();
    var pPics = new ProfilePics();
    var tPhoto = new Photo();
    var tPic = '';
    
      // debugger;
    kvPhoto = new PhotoBn().cloneToKvPhoto(pbn);
      // debugger;
    kvPhoto.value = pbn.pic;
    kvPhoto.image = pbn.pic;
    kvPhoto.pic = pbn.pic;

    // Note: Uploaded pics are always Public by default.
    kvPhoto.privacy = 'Public';
    kvPhoto.isPrimary = false;
    kvPhoto.isPrivate = false;
    kvPhoto.isUnlocked = false;

    // save Photo from kvPhoto to profilePhotoDictionary of ProfilePics:
    // -----------------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)) {
      // pPics.unFilteredKvPhotoArr.push(kvPhoto);
      kvPhoto = this.prepareKvPhotoForDisplay(kvPhoto, true);
      // kvPhoto.value = JsRegExpServiceStatic.prefixUrlToImageData(kvPhoto.value);
    }
    
    // if the backend sends photo:
    // ---------------------------
    // debugger;
    kvPhoto = CopyServiceStatic.copyFromPhotoToKvPhoto(pbn);
    // kvP.value = JsRegExpServiceStatic.normalizeImageData(kvP.value);

    kvPhoto = this.prepareKvPhotoForDisplay(kvPhoto, false);
    kvPhoto.value = JsRegExpServiceStatic.prefixUrlToImageData(kvPhoto.value);
    

    // debugger;
    return kvPhoto;
  }
  // ---------------------------------------------------------------
  //static getProfilePicsModel (sitUserId : number) : ProfilePics {
  //  let pPics: ProfilePics = new ProfilePics();

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(DictionaryServiceStatic.profilePicsDictionary) && DictionaryServiceStatic.profilePicsDictionary.size() > 0) {
  //    pPics = DictionaryServiceStatic.profilePicsDictionary.getValue(sitUserId) as ProfilePics;
  //  }

  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) || pPics.sitUserId === 0) {
  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    let signedInSitUser = CopyServiceStatic.copyLoginSuccessToSitUser(this.loginSuccess);
  //    pPics = CopyServiceStatic.createProfilePicsModelFromSitUser(signedInSitUser);
  //  }
  //  return pPics
  //}

  // ---------------------------------------------------------------
  //  FileUploadComponent's methods:
  // ---------------------------------------------------------------
 
  // Ref;https:// stackoverflow.com/questions/39272970/angular-2-encode-image-to-base64/39275214
  // ---------------------------------------------------------------
  static changeListener ($event : any) : void {
    this.readThisFile($event.target.files);
  }
  // ---------------------------------------------------------------
  // This method normalizes and image's raw data for displating on
  // an html element. It can be prefixed with a 'url()' around the
  // image-data, or can strip the 'url()' from the image-data.
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------  
  static prepareKvPhotoForDisplay (kvPhoto : KvPhoto, isStripUrl : boolean) : KvPhoto | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
        if (kvPhoto.value.length > 0) {
          // debugger;
          if (kvPhoto.value.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
            kvPhoto.value = JsRegExpServiceStatic.replaceJpegOrSvgWithPngInRawImageData(kvPhoto.value);
					}          
        }
      }
    }
    return kvPhoto;
  }
  // ---------------------------------------------------------------------
  //  TODO: Delete before deployment:
  // ---------------------------------------------------------------------
  //static prepareKvPhotoForDisplay (e : KvPhoto, isStripUrl : boolean) : KvPhoto | any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e) ) {     
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) && e.value.length > 0) {
  //      if (JsRegExpServiceStatic.photoUrlPrefixPattern.test(e.value)) {
  //        // debugger;
  //        if (isStripUrl) {
  //          // debugger;
  //          if (JsRegExpServiceStatic.allImageTypesFilePattern.test(e.value)) {
  //            // debugger;
  //            e.value = JsRegExpServiceStatic.normalizeImageFileName(e.value);
  //          }
  //          else if (JsRegExpServiceStatic.allImageTypesDataPattern4.test(e.value)
  //            || JsRegExpServiceStatic.allImageTypesDataPattern5.test(e.value)
  //            || JsRegExpServiceStatic.allImageTypesDataPattern6.test(e.value)) {
  //            // debugger;
  //            e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //  .normalizeImageData(e.value);
  //          }
  //        }
  //        else {
  //          // debugger;
  //          /*e.value = JsRegExpServiceStatic.normalizeImageData(e.value);*/
  //          if (JsRegExpServiceStatic.allImageTypesFilePattern.test(e.value)) {
  //            // debugger;
  //            e.value = JsRegExpServiceStatic.normalizeImageFileName(e.value);
  //          }
  //          else if (JsRegExpServiceStatic.allImageTypesDataPattern4.test(e.value)
  //            || JsRegExpServiceStatic.allImageTypesDataPattern5.test(e.value)
  //            || JsRegExpServiceStatic.allImageTypesDataPattern6.test(e.value)) {
  //            // debugger;
  //            e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //  .normalizeImageData(e.value);
  //          }
  //        }
  //      }
  //      else {
  //        // debugger;
  //        e.value = JsRegExpServiceStatic.normalizeImageData(e.value);
  //      }
  //    }
  //  }
  //  return e;
  //}
  // ---------------------------------------------------------------
  static prepareKvPhotoArrForDisplay (kvPhotoArr : KvPhoto[], isStripUrl : boolean) : KvPhoto[] | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      kvPhotoArr.forEach(e => {
        e = this.prepareKvPhotoForDisplay(e, isStripUrl);


        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) && e.value.length > 0) {
        //  if (JsRegExpServiceStatic.photoUrlPrefixPattern.test(e.value)) {
        //    // debugger;
        //    if (isStripUrl) {
        //      // debugger;
        //      if (JsRegExpServiceStatic.allImageTypesFilePattern.test(e.value)) {
        //        // debugger;
        //        e.value = JsRegExpServiceStatic.normalizeImageFileName(e.value);
        //      }
        //      else if (JsRegExpServiceStatic.allImageTypesDataPattern4.test(e.value)
        //        || JsRegExpServiceStatic.allImageTypesDataPattern5.test(e.value)
        //        || JsRegExpServiceStatic.allImageTypesDataPattern6.test(e.value)) {
        //        // debugger;
        //        e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //  .normalizeImageData(e.value);
        //      }
        //    }
        //    else {
        //      // debugger;
        //      /*e.value = JsRegExpServiceStatic.normalizeImageData(e.value);*/
        //      if (JsRegExpServiceStatic.allImageTypesFilePattern.test(e.value)) {
        //        // debugger;
        //        e.value = JsRegExpServiceStatic.normalizeImageFileName(e.value);
        //      }
        //      else if (JsRegExpServiceStatic.allImageTypesDataPattern4.test(e.value)
        //        || JsRegExpServiceStatic.allImageTypesDataPattern5.test(e.value)
        //        || JsRegExpServiceStatic.allImageTypesDataPattern6.test(e.value)) {
        //        // debugger;
        //        e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //  .normalizeImageData(e.value);
        //      }
        //    }
        //  }
        //  else {
        //    // debugger;
        //    e.value = JsRegExpServiceStatic.normalizeImageData(e.value);
        //  }
        //}
      });
    }
    return kvPhotoArr;
  }
   // ---------------------------------------------------------------
  static saveFile (files : File[]) {
    if (files.length > 0) {
      console.log(files);
      let formData = new FormData();
      let content = new Content();
      let loginSuccess = EmitterSubjectService.getLoginSuccess();
      // Array.from(files).forEach(f => this.formData.append('file', f));
      // formData.append('authorSitUserId', this.content.authorSitUserId ? this.content.authorSitUserId.toString() : this.loginSuccess.signedInUserId.toString());
      formData.append('profileId', content.profileId.toString());
      formData.append('contentId', content.contentId.toString());
      formData.append('heading', content.heading);
      /*
       * this.formData.append('writePadAction', this.content.writePadAction);
       * Add the latitude & longitude
       */
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position : any) => {
            if (position) {
              console.log('Latitude : ' + position.coords.latitude + '; Longitude : ' + position.coords.longitude);
              formData.append('latitude', position.coords.latitude.toString());
              formData.append('longitude', position.coords.longitude.toString());
              formData.append('file', files[ files.length - 1 ]);

              // TODO: Save the file:

              // this.articleCompositionService.submitFile(this.formData);
            }
          },
          (error : any) => console.log(error)
        );
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    }
  }
  // ---------------------------------------------------------------
  static readThisFile (files : any) : string[] {
    let tempStringFiles: string[] = []
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(files) && files.length > 0) {
      const file : File = files[ 0 ];
      const myReader : FileReader = new FileReader();

      myReader.onerror = () => {
        myReader.abort();
        return new DOMException('Problem parsing input file.');
      };

      myReader.onloadend = (e) => {
        const fileStr = myReader.result;
        // debugger;

        tempStringFiles.push(JSON.stringify(fileStr));
      };
      myReader.readAsDataURL(file);
    }
    return tempStringFiles;
  }
  
  // ---------------------------------------------------------------
  static toggleIsUpload () {
    EmitterSubjectService.emitToggleIsUpload();
  }
  // ------------------------------------------------------------------------------------------------
  //  Note: this method though the original version may have worked,
  //        this altered version does not work as of 20220328
  // ------------------------------------------------------------------------------------------------
  static getFormDataAndUploadStatus ($event : any, httpService : HttpService) : { [ key : string ] : Observable<any> } {

    // this will be the our resulting map
    const status: any = {};
    let percentDone : any;
    let fileArr = $event.target.files as File[];
    debugger;
    //fileArr.map(file => {
    for (var file of fileArr) {
      // create a new multipart-form for every file
      const formData : FormData = new FormData();
      formData.append('file', file, file.name);

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', url, formData, {
        reportProgress: true
      });

      // create a new progress-subject for every file
      const progress = new Subject<any>();

      // send the http-request and subscribe for progress-updates
      httpService.httpClient.request(req).subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {

          // calculate the progress percentage
          if (event.total) {
            percentDone = Math.round(100 * event.loaded / event.total);
          }
          // Save every progress-observable in a map of all observables
          status[ file.name ] = {
            progress: progress.asObservable(),
            percentDone: percentDone
          };
          debugger;
          // pass the percentage into the progress-stream
          // progress.next(percentDone);
          progress.next(status);
        } else if (event instanceof HttpResponse) {

          // Close the progress-stream if we get an answer form the API
          // The upload is complete
          progress.complete();
        }
      });
    }

    // return the map of progress.observables
    return status;
  }
  // ---------------------------------------------------------------
  //  UploadFilesComponent's methods:
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------
}
