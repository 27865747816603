'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { AppFeature } from '../../../models/common/appFeture.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Animations, slideInOutAnimation } from '../../animation';

@Component({
  selector: 'app-upgrade-popup',
  templateUrl: './upgradePopup.component.html',
  styleUrls: ['./upgradePopup.component.scss'],
  animations: [Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush, //  default //
})
export class UpgradePopupComponent implements OnInit, OnDestroy, AfterViewInit
{
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isLoggedIn = false;
  public loginSuccess : LoginSuccess = new LoginSuccess();

    @Input() appFeature = new AppFeature(); // TODO: remove
  // -----------------------------------------------

  constructor (
    private cdr : ChangeDetectorRef,
    private router : Router,
  ){ }

  ngOnInit ()
  {

      this.isMobilevar = EmitterSubjectService.getIsMobile();
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( this.loginSuccess.signedInUserId > 0 )
      {
        this.isLoggedIn = true;
      }
      else
      {
        this.isLoggedIn = false;
      }
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
        this.loginSuccess = result; // JSON.parse(JSON.stringify(result));
        // debugger;
        if ( this.loginSuccess.signedInUserId > 0 )
        {
          this.isLoggedIn = true;
        }
        else
        {
          this.isLoggedIn = false;
        }
      } );
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngAfterViewInit() {
  }
  // ---------------------------------------------------------------------------------
  ngOnDestroy ()
  {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
  }
}
