
import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PagerBreadcrum } from '../../models/breadcrum/pagerBreadcrum.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { BasicPagerModel } from '../../models/pagination/basicPagerModel.model';
import { ArrayServiceStatic } from '../staticServices/arrayServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

// ------------------------------------------------------------------
//  This pager-service is the most recently updated one. If other
//  pagers need to be updated, this one should be used as a model for
//  them. Updated on 20220420.
// ------------------------------------------------------------------

// ref: http://xahlee.info/js/javascript_range_array.html
const xah_range = ((min : any, max : any, step = 1,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)));

@Injectable({
  providedIn: 'any',
})
export abstract class BasicPagerService implements OnDestroy {
  public renderer! : Renderer2;
  // ----------------------------------------------------------------
  public basicPagerModel : BasicPagerModel = new BasicPagerModel();
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public kvPhotoArr : KvPhoto[] = [];
  public pageSize = 5;

  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public timer : any;
  // ---------------------------------------------------------------
  constructor (
  ) {
  }
  ngAfterViewInit () : void {
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy () {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    clearInterval(this.timer);
  }
  // ===============================================================
  // TODO : Separate out PagerBreadCrum into its own PagerServiceBreadCrum,
  // ---------------------------------------------------------------
  //  API:
  // ---------------------------------------------------------------
  public getPagerBreadcrum(): PagerBreadcrum {
    return this.pagerBrdCrm;
  }
  // ---------------------------------------------------------------
  public getBasicPager (page : number) : any {
    // returnthis.setPagerUpTo15(this.kvPhotoArr, page);
    return this.getBasicPagerDynamic(this.kvPhotoArr, page, this.pageSize);
  }
  // ---------------------------------------------------------------
  public getBasicPagerDynamicObservable (kvPhotoArr : KvPhoto[], page : number, pageSize : number) : Observable<any> {
    return new Observable<any>((subscriber) => {
      // debugger;
      this.basicPagerModel = this.getBasicPagerDynamic(kvPhotoArr, page, this.pageSize);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.basicPagerModel)) {
        subscriber.next(this.basicPagerModel);
      }
    })
  }
  // ---------------------------------------------------------------
  //setBasicPagerVKvPhoto (page : number) : any {
  //  // returnthis.setPagerUpTo15(this.kvPhotoArr, page);
  //  return this.setPagerVKvPhotoDynamic(this.kvPhotoArr, page, this.pageSize);
  //}
  // ---------------------------------------------------------------
  public getBasicPagerDynamic (kvPhotoArr : KvPhoto[], page : number, pageSize : number) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      this.basicPagerModel.kvPhotoArr = JSON.parse(JSON.stringify(kvPhotoArr)); // deep copy
      // debugger;
      if (this.basicPagerModel.kvPhotoArr && this.basicPagerModel.kvPhotoArr.length > 0) {
        this.basicPagerModel.totalItems = kvPhotoArr.length;
      }
      this.basicPagerModel.pageSize = pageSize > 0 ? pageSize : 10;

      this.basicPagerModel.currentPageNo = page > 0 ? page : 1;
      // get pager object
      // debugger;
      this.basicPagerModel = this.GetPagerChatDynamic(kvPhotoArr.length, page, pageSize);     

      // debugger;
      this.basicPagerModel.pagedItems = this.basicPagerModel.kvPhotoArr.slice(this.basicPagerModel.startIndex, this.basicPagerModel.endIndex + 1);
      this.basicPagerModel.pagedItems.forEach(e => {
        e.pageNum = page;
      });
      this.basicPagerModel.currentItem = this.basicPagerModel.pagedItems[ this.basicPagerModel.pagedItems.length - 1 ]; //TODO: or could be the last item
      // debugger;
      this.basicPagerModel = this.setupBasicPagerModel(this.basicPagerModel.currentItem, this.basicPagerModel);

      EmitterSubjectService.setBasicPager(this.basicPagerModel);

      // TODO: remove beofore deployment:
      // --------------------------------
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.basicPagerModel)
      //  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.basicPagerModel.kvPhotoArr)
      //  && this.basicPagerModel.kvPhotoArr.length > 0) {
      //   debugger;
      //  this.basicPagerModel.pagedItems = this.basicPagerModel.kvPhotoArr.slice(this.basicPagerModel.startIndex, this.basicPagerModel.endIndex + 1); // important!!
      //  // debugger;
        
      //}
      // debugger;
      


      // ------------------------------------------------------------------------------------------------------
      // get current items of page
      // NOTE : We do not follow these steps. Instead we reverse the kvPhotoArr upon constructing a conversation.
      // Note : because we want to see the most-recent-messages near the Chat Message Console, we do the following steps:
      //     Step-1 : We sort the messages in descending order(with isAsc == false)
      //     Step-2 : Then we take a slice for PagedChatArr
      //     Step-3 : Then the pagedChatArr are sorted in Ascending order (isAsc==true)
      //             for the most - recent - message to appear near the Chat Message Console.
      // let reversedChats = this.reverseChats(this.pagerPhotoMgmt.kvPhotoArr, false); // Step-1
      // debugger;
      // this.pagedChatArr = reversedChats.slice(this.pagerPhotoMgmt.startIndex, this.pagerPhotoMgmt.endIndex); // Step-2
      // debugger;
      // this.pagedChatArr = this.reverseChats(this.pagedChatArr, true); // Step-3

      // ------------------------------------------------------------------------------------------------------

      // this.pagerPhotoMgmt.currentBrdCrmPage = this.breadcrumRing[this.currentPage - 1];
      // this.pagerPhotoMgmt.previousPage = this.currentPage - 1 >= 1 ? this.currentPage - 1  : this.currentPage;
      // if (this.pagerPhotoMgmt.previousPage >= 1) {
      //   this.pagerPhotoMgmt.previousBrdCrmPage = this.breadcrumRing[this.pagerPhotoMgmt.previousPage - 1];
      // }
      // this.pagerPhotoMgmt.nextPage = this.currentPage + 1 <= totalItems ? this.currentPage + 1  : this.currentPage;
      // if (this.pagerPhotoMgmt.nextPage <= this.totalItems) {
      //   this.pagerPhotoMgmt.nextBrdCrmPage = this.breadcrumRing[this.pagerPhotoMgmt.nextPage - 1];
      // }
      // ------------------------------------------------------------------------------------------------------



      // debugger;
      // EmitterSubjectService.emitPager(this.pagerPhotoMgmt);
      return this.basicPagerModel;
    }
    else return null;
  }
  // --------------------------------------------------
  public range (min : any, max : any, delta ?: any) {
    const arr : any[] = [];
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(delta)) {
      delta = 0;
    }
    let stepCount;
    if (min && !max && !delta) {
      for (let i = 0; i < min; i++) {
        arr[ i ] = i + 1;
      }
    } else {
      if (min && max && !delta) {
        stepCount = (max - min);
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i + min);
        }
      } else {
        stepCount = Math.floor((max - min) / delta);
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i * delta + min + 1);
        }
      }
    }
    return arr;
  }
  // --------------------------------------------------
  // Can be used for any array. 
  // just the array's totalItems, currentPage and pageSize are required.
  // will give a pager from 1 upto 10.
  // Note:  this algorithm implicitely equates pageSize to pagedItems, 
  //        i.e.pageSize === itemSize
  //
  //  Also, the pagedItems shoild be computed by the calling method.
  //  Because this service may not have KvPhotoArray to slice the pagedItems. !!!
  // --------------------------------------------------
  protected GetPagerChatDynamic (totalItems : any, page : any, pageSize ?: any) : BasicPagerModel | any {
    let tItems : number;
    if (totalItems > 0) {
      tItems = totalItems;
      this.basicPagerModel.totalItems = tItems;

      // default page size is 10
      let pSize = pageSize > 0 ? pageSize : 10;
      this.basicPagerModel.pageSize = pSize;

      // calculate total pages
      let totalPageNo = 0;
      if (tItems < pSize) {
        totalPageNo = 1;
      }
      else {
        totalPageNo = Math.abs(Math.ceil(tItems / pSize));
      }
      // default to first page
      let cPage = page > 0 && page < totalPageNo ? page : 1;
      this.basicPagerModel.currentPageNo = cPage;

      // debugger;
      // calculate start and end item indexes
      // updated version-2: Date: 20230116:
      // ----------------------------------
      let startPageItemNo;
      let endPageItemNo;
      if (tItems <= 10) {
        // less than 10 total pages so show all
        startPageItemNo = 1;
        endPageItemNo = tItems;
      } else {
        // more than 10 total pages so calculate start and end pages
        //if (tItems <= 6) {
        //  startPageItemNo = 1;
        //  endPageItemNo = 10;
        //} else 
        if (cPage + 4 >= tItems) {
          startPageItemNo = totalPageNo - 9;
          endPageItemNo = totalPageNo;
        } else {
          startPageItemNo = cPage - 5;
          endPageItemNo = cPage + 4;
        }
      }
      // this.startPage = startPageNo;
      // this.endPage = endPageNo;

      //let startIndex = (cPageNo - 1) * this.pageSize;
      //let endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1
      //  : this.pageSize), (this.totalItems > 1 ? this.totalItems - 1 : this.totalItems));

      var startIndex = (Math.floor(cPage / pSize) - 1 >= 0 ? (cPage - 1) : 0) * pSize;
      let min = Math.min(startIndex + pSize - 1, tItems - 1);
      var endIndex = min >= 0 ? min : 0;
      this.basicPagerModel.startIndex = startIndex;
      this.basicPagerModel.endIndex = endIndex;

      // debugger;
      // create an array of pages to ng-repeat in the pager control
      // this.pages = xah_range(startPageItemNo, endPageItemNo, 1);
      this.basicPagerModel.pages = this.range(startIndex, endIndex, pSize);
      // debugger;
      //this.basicPagerModel.startPageNo = 1;
      //this.basicPagerModel.endPageNo = this.basicPagerModel.pages.length;

      this.basicPagerModel.pageSize = pSize;
      this.basicPagerModel.previousPageNo = cPage - 1 > 0 ? cPage - 1 : 1;
      this.basicPagerModel.nextPageNo = cPage + 1 <= totalPageNo ? cPage + 1 : totalPageNo;
      this.basicPagerModel.totalPages = totalPageNo;


      // debugger;
      // var startIndex = (cPageNo - 1);
      // var endIndex = Math.min(startIndex + pSize - 1, totalItems - 1);

      this.basicPagerModel.endIndex = endIndex;
      this.basicPagerModel.startIndex = startIndex;

      this.basicPagerModel.startItemNo = startIndex;
      this.basicPagerModel.endItemNo = endIndex;
      this.basicPagerModel.currentItemNo = 0;
      this.basicPagerModel.previousItemNo = this.basicPagerModel.currentItemNo - 1 >= 0 ? this.basicPagerModel.currentItemNo - 1 : 0;
      this.basicPagerModel.nextItemNo = this.basicPagerModel.currentItemNo + 1 <= this.basicPagerModel.totalItems ? this.basicPagerModel.currentItemNo + 1 : this.basicPagerModel.totalItems;

      this.basicPagerModel.totalItems = tItems;
      // debugger;

      // get the rest of the property's value:
      this.pagerBrdCrm = this.setupPagerBrdCrm();
      EmitterSubjectService.setBasicPager(this.basicPagerModel);
      // debugger;
      return this.basicPagerModel;
    }
    else return null;
  }
  // --------------------------------------------------

  // ---------------------------------------------------------------
  //  API:
  // ---------------------------------------------------------------
  public setKvPhotoArr (kvpArr : KvPhoto[]) : void {
    this.kvPhotoArr = kvpArr;
  }
  // ---------------------------------------------------------------
  protected setupBasicPagerModel (currItem ?: KvPhoto | any, inPager ?: BasicPagerModel) : BasicPagerModel | any {
     this.basicPagerModel = new BasicPagerModel();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inPager)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inPager.kvPhotoArr)
      && inPager.kvPhotoArr.length > 0) {
      this.basicPagerModel = inPager;
		}

    this.basicPagerModel.pageSize = this.pageSize;

    // kvPhotoArr varoiables:
    // ----------------------
    this.basicPagerModel.kvPhotoArr = inPager.kvPhotoArr;
    this.basicPagerModel.startItem = inPager.kvPhotoArr[ 0 ];
    this.basicPagerModel.endItem = inPager.kvPhotoArr[ inPager.kvPhotoArr.length - 1 ];
  

    // pagedItems variables:
    // ---------------------
    let index = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currItem)) {
      if (currItem.pageNum > 0 && currItem.pageNum <= this.kvPhotoArr.length + 1
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvPhotoArr[ currItem.pageNum - 1 ])) {
        // debugger;
        this.basicPagerModel.currentItem = new KvPhoto().copySourceToDestination(this.basicPagerModel.currentItem, this.kvPhotoArr[ currItem.pageNum - 1 ]);
      }
      else {
        index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdOrPhotoIdInKvPhotoArr(this.kvPhotoArr, currItem.photoBnId, currItem.photoId);
        
        if (index > -1) {
          // debugger;
          this.basicPagerModel.currentItem = new KvPhoto().copySourceToDestination(this.basicPagerModel.currentItem, this.kvPhotoArr[ index  ]);
        }
        else {
          this.basicPagerModel.currentItem = new KvPhoto().copySourceToDestination(this.basicPagerModel.currentItem, this.kvPhotoArr[ this.kvPhotoArr.length - 1 ]);
				}
      }
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.basicPagerModel.currentItem)) {
      this.basicPagerModel.currentItemNo = this.basicPagerModel.currentItem.index;
		}

    // debugger;

    if (this.basicPagerModel.endIndex < this.basicPagerModel.totalItems
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvPhotoArr[ this.basicPagerModel.endIndex ])) {
      this.basicPagerModel.endItem = this.kvPhotoArr[ this.basicPagerModel.endIndex ];
    }

    // the lowest value for currentPageNo==1, so  nextPageNo==2
    if (this.basicPagerModel.nextPageNo >= 2 && this.basicPagerModel.nextPageNo - 1 >= 1
      && this.basicPagerModel.nextPageNo - 1 <= this.kvPhotoArr.length) {
      this.basicPagerModel.nextItem = this.kvPhotoArr[ this.basicPagerModel.nextPageNo - 1 ];
      this.basicPagerModel.nextItemNo = this.kvPhotoArr[ this.basicPagerModel.nextPageNo - 1 ].index;
    }
    else {
      if (this.basicPagerModel.totalItems - 1 <= this.kvPhotoArr.length) {
        this.basicPagerModel.nextItem = this.kvPhotoArr[ this.basicPagerModel.totalItems - 1 ];
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.basicPagerModel.nextItem)) {
        this.basicPagerModel.nextItemNo = this.basicPagerModel.nextItem.index;
      }
    }

    if (this.basicPagerModel.previousPageNo >= 1) {
      this.basicPagerModel.previousItem = this.kvPhotoArr[ this.basicPagerModel.previousPageNo - 1 ];
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.basicPagerModel.previousItem)) {
        this.basicPagerModel.previousItemNo = this.basicPagerModel.previousItem.index;
      }
    }
    else {
      this.basicPagerModel.previousItem = this.kvPhotoArr[ 0 ];
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.basicPagerModel.previousItem)) {
        this.basicPagerModel.previousItemNo = this.basicPagerModel.previousItem.index;
      }
    }

    if (this.basicPagerModel.startIndex >= 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvPhotoArr[ this.basicPagerModel.startIndex ])) {
      this.basicPagerModel.startItem = this.kvPhotoArr[ this.basicPagerModel.startIndex ];
      this.basicPagerModel.startItemNo = this.kvPhotoArr[ this.basicPagerModel.startIndex ].index;
    }
    else {
      this.basicPagerModel.startItem = this.kvPhotoArr[ 0 ];
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.basicPagerModel.startItem)) {
        this.basicPagerModel.startItemNo = this.basicPagerModel.startItem.index;
      }
    }

    // debugger;
    return this.basicPagerModel;
  }
  // ---------------------------------------------------------------
  //  API:
  // ---------------------------------------------------------------
  protected setupPagerBrdCrm () :any {
    // return object with all the pager properties required by the view
    this.pagerBrdCrm = new PagerBreadcrum();
    this.pagerBrdCrm.totalItems = this.basicPagerModel.totalItems;
    this.pagerBrdCrm.currentPage = this.basicPagerModel.currentPageNo;
    // this.pagerBrdCrm.currentBrdCrmPage = this.breadcrumRing[this.currentPage - 1];
    this.pagerBrdCrm.previousPage = this.basicPagerModel.currentPageNo - 1 >= 1 ? this.basicPagerModel.currentPageNo - 1 : this.basicPagerModel.currentPageNo;
    // if (this.pagerBrdCrm.previousPage >= 1) {
    //   this.pagerBrdCrm.previousBrdCrmPage = this.breadcrumRing[this.pagerBrdCrm.previousPage - 1];
    // }
    this.pagerBrdCrm.nextPage = this.basicPagerModel.nextPageNo + 1 <= this.basicPagerModel.totalItems ? this.basicPagerModel.nextPageNo + 1 : this.basicPagerModel.nextPageNo;
    // if (this.pagerBrdCrm.nextPage <= this.totalItems) {
    //   this.pagerBrdCrm.nextBrdCrmPage = this.breadcrumRing[this.pagerBrdCrm.nextPage - 1];
    // }

    this.pagerBrdCrm.pageSize = this.pageSize;
    this.pagerBrdCrm.totalPagedBlocks = this.basicPagerModel.totalPages;
    // this.pagerBrdCrm.startPage = this.basicPagerModel.startPage;
    // this.pagerBrdCrm.pagedEndPage = this.basicPagerModel.endPage;
    this.pagerBrdCrm.pagedStartIndex = this.basicPagerModel.startIndex;
    this.pagerBrdCrm.pagedEndIndex = this.basicPagerModel.endIndex;
    this.pagerBrdCrm.pagedPages = this.basicPagerModel.pages;

    EmitterSubjectService.setPagerBreadcrum(this.pagerBrdCrm);
    return this.pagerBrdCrm;
  }
  
  // --------------------------------------------------
  // Note:  Even though this method eliminates the need
  //        of it's counterParts = [GetPagerOfSize10(), GetPagerOfSize5()]
  //        we DO NOT delete it's counterParts
  // ---------------------------------------------------------------
  //    Deprecared
  //--------------------------------------------------   
  // ref: http://xahlee.info/js/javascript_range_array.html
  // const xah_range = ((min, max, step = 1,) => (Array(Math.floor((max - min) / step) + 1).fill(min).map(((x, i) => (x + i * step)),)));  
    // ----------------------------------------------------------------
  // Note : The following methods are optional giving more page selections:
  //
  //       The setPagerUpTo10() method returns a 'Pager' of size 5 if the total elements are <=5
  //       or a pager of size 10 if the elements are >5.
  //       Also if it produces a pager of size 10, it also produces a 'PagerBreadcrum' of sixe 10.
  //
  //       You can obtain the 'PagerBreadcrum' via the api : getPagerBreadcrum();
  // ---------------------------------------------------------------
  //    Deprecared
  // ---------------------------------------------------------------

  //protected getPagerUpTo10 (kvpArr : KvPhoto[], page : number, pageSize : number) : any {
  //  if (kvpArr && kvpArr.length > 0) {
  //    this.totalItems = kvpArr.length;
  //    this.kvPhotoArr = kvpArr;
  //  }
  //  if (page < 1 && page > this.totalItems) {
  //    return null;
  //  }

  //  // default to first page
  //  this.page = (page > 0 && page <= this.totalItems) ? page : 1;

  //  // this.pageSize = (pageSize > 0 && pageSize < 10 && this.totalItems < 10) ? 5 : 10;

  //  if (this.kvPhotoArr && this.kvPhotoArr.length > 0) {

  //    // default page size is 5
  //    if (pageSize > 0 && pageSize <= 10) {
  //      this.pageSize = pageSize;
  //    }

  //    // debugger;
  //    // calculate total pages
  //    this.totalPages = Math.ceil(this.totalItems / this.pageSize);

  //    // debugger;
  //    this.getPagerOfAnySizeUpto10(this.totalItems, this.page);

  //    // Note:  Though this method returns only this.pagerPhotoMgmt,
  //    //        the this.pagerBrdCrm can be accessed by it's getter:
  //    // ---------------------------------------------------------- 
  //    this.pagerBrdCrm = this.setupPagerBrdCrm();

  //    // debugger;      
  //    this.pagerPhotoMgmt.kvPhotoArr = this.kvPhotoArr;

  //    // capture the values before creating pagedItems:
  //    //-----------------------------------------------
  //    // debugger;
  //    this.pagerPhotoMgmt = this.setupPagerPhotoMgmt();

  //    // debugger;
  //    return this.pagerPhotoMgmt;
  //  }
  //  else return null;

  //}
  // --------------------------------------------------
  // updated on 20220416
  // --------------------------------------------------
  //protected rangeOld (min: number, max: number, delta: number) : any {
  //  var arr: any[] = [];
  //  var stepCount = 0;
  //  // debugger;
  //  if (arguments.length === 1) {
  //    for (var i = 0; i < min; i++) {
  //      arr.push(i + 1);
  //    };
  //  } else {
  //    if (arguments.length === 2) {
  //      stepCount = Math.floor((max - min));
  //      if (stepCount > 0) {
  //        for (var i = 0; i <= stepCount; i++) {
  //          arr.push(i + min);
  //        };
  //      }
  //      else {
  //        arr.push(1);
  //      }
  //    } else {
  //       debugger;
  //      stepCount = Math.floor((max - min) / delta);
  //      if (stepCount >= 0) {
  //        for (var i = 0; i <= stepCount; i++) {
  //          arr.push(i * delta + min);
  //        };
  //      }        
  //    }      
  //  }
  //  // debugger;
  //  return arr;
  //}
  // --------------------------------------------------
  // range2(min, max, delta): any {
  //  const arr : any[] = [];
  //  let stepCount;
  //  if (min && !max && !delta) {
  //    for (let i = 0; i < min; i++) {
  //      arr[i] = i + 1;
  //    }
  //  } else {
  //    if (min && max && !delta) {
  //      stepCount = (max - min);
  //      for (let i = 0; i <= stepCount; i++) {
  //        arr.push(i + min);
  //      }
  //    } else {
  //      stepCount = Math.floor((max - min) / delta);
  //      for (let i = 0; i <= stepCount; i++) {
  //        arr.push(i * delta + min);
  //      }
  //    }
  //  }
  //  return arr;
  // }
  
  // ------------------------------------------------------------
}


