
import { KvMemberActivityGlyph } from '../keyValue/kvMemberActivityGlyph.model';
import { BasicPagerKvGlyphModel } from './basicPagerKvGlyphModel.model';

// --------------------------------------------------------
// Note:  There are two basic types of KV based pagers:
//        1) KvPhoto  2) KvGlyph
//
//        PagerMemberActiviy relates to KvGlyph category.
// --------------------------------------------------------

export class PagerMemberActivity extends BasicPagerKvGlyphModel {

  // Note: the following two variables are for hodling the glyphArr for viewMember:
  //       (during ViewMember, there will be a set of glyphs representing various activities
  //        that the signedInUser can take on other non-signedInUser's profile such as:
  //        [like, dislike, friend, unfriend, buddy (managed by the slakes's logic), wink, track, etc.])
  // ---------------------------------------------------------------------------------------------------

  // Note: the following properties are used to accomphish represenging these glyphs for various activities:
  // -------------------------------------------------------------------------------------------------------
  
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  // public currentItemKvGlyph : KvPhoto = new KvPhoto(); n// Note: there is no model for Glyph, so a KvPhoto model is used which inherits Photo model
  constructor () {
    super();
  }
}
