/*
 * ref: https:// www.freakyjolly.com/angular-4-5-typescript-create-filter-list-with-check-boxes-to-select-from-list/
 * ref: https:// codepen.io/ryandrewjohnson/pen/VaPRNY
 */
'use-strict'
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'textFilter',
})
export class TextFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    const filteredItems : any[] = [];

    for (const item of items) {
      filteredItems.push(this.linebreakFilter(item.toLowerCase()) as string);
    }
    return filteredItems;
  }
  // -------------------------------------------------------
  linebreakFilter(text: any) {
    if (!text || !text.length) {
      return text;
    }
    return text.replace(/(\\r\\n)|([\r\n])/gim, '&ltbr/&gt');
  }
  // -------------------------------------------------------
}
