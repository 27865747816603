<ul id="keyValueAnyComponentId" [@blindsDown]="{value: isDropdownOpen, params: {heightInRem: arrKvAny.length * 1.5}}" style="margin:0;padding:0;list-style: none;width:99%;">
  <!--ref:https:// www.syncfusion.com/kb/10416/how-to-get-started-easily-with-syncfusion-angular-7-accordion -->
  <li expandMenu style="width:100%;margin:0;padding:0;">
    <a (click)="isClosedBool.isClosed=!isClosedBool.isClosed">
      <p class="kvBttnxS" style="width:100%;margin:0.5px 0rem 0.2rem 0.2rem;">
        <span *ngIf="kvName[0].length > 0" style="float:left;padding:0.2rem 1rem 0.1rem 1rem;">
          {{kvLegend[0]}} of {{arrKvAnyName}}
        </span>
        <span *ngIf="isClosedBool.isClosed" class="glyphicon glyphicon-plus-sign hvr-icon" style="float:right;font-size:0.9rem;padding:0.2rem 1rem 0.1rem 1rem;"></span>
        <span *ngIf="!isClosedBool.isClosed" class="glyphicon glyphicon-minus-sign hvr-icon" style="float:right;font-size:0.9rem;padding:0.2rem 1rem 0.1rem 1rem;"></span>
      </p>
    </a>
  </li>

  <li style="margin:0;padding:0;">
    <ul *ngIf="!isClosedBool.isClosed" [ngClass]="{'submenu':true}" style="width:100%;list-style:none;margin:0;padding:0;">
      <li style="margin:0;padding:0;">
        <div *ngFor="let kvAny of arrKvAny let i=index">

          <!--NOTE: this section of the UI is NOT TO BE Changed anyway in the future!! It will display key-value paired data adjacent to each other in the center.-->
          <div *ngIf=" kvAny.value" class="increase row" style="width:90%;font-size:0.6rem;padding:0;margin:0;">
            <div class="kvxxSk" style="width:45%;float:left;text-align:right;">
              {{kvAny.key}}:
            </div>
            <div *ngIf="kvAny.key.indexOf('image') === -1 && kvAny?.value" class="grnDrkRedxxSB" style="width:45%;float:right;text-align:left;text-transform:capitalize;">
              {{kvAny.value}}
            </div>
            <div *ngIf="kvAny.key.indexOf('image') !== -1 && kvAny?.value" style="width: 45%;float: right;text-align: left;">
              <div [ngStyle]="{'background-image': kvAny.value | safe: 'pic'}" class="img-responsive img-rounded tilePic-desktop smaller"
                   style="height: 9rem;
                          width: 9rem;
                          float: right;
                          text-align: left;
                          z-index: 999;">
              </div>
              <div id="kvAnyId-{{sitUserId}}-{{id}}-{{i}}" class="img-responsive img-rounded tilePic-desktop smaller"
                   style="height: 9rem;
                          width: 9rem;
                          float: right;
                          text-align: left;
                          z-index: 999;">
              </div>
            </div>
          </div>        
          <div *ngIf=" kvAny.value" style="display:inline-block;width:100%;padding:0;margin:0 0 0 0.009rem;border-bottom:1.5px solid silver;"></div>
        </div>
      </li>
    </ul>
  </li>
</ul>

  <!--<li expandMenu class="flex-text kvBttnxS" style="margin:0;padding:0.2rem;">
    <a *ngIf="arrKvName?.length > 0" (click)="isClosedBool.isClosed=!isClosedBool.isClosed">
      <span class="text-left;" style=";vertical-align:middle;">
        {{kvLegend[0]}} of {{arrKvName}}
        <span *ngIf="arrKvLegend?.length > 0 && isRequired" class="errSub" style="margin-left:-1px;">
          *
        </span>
      </span>
      <span style="float:right;width:10%;vertical-align:central;" class="text-right">
        <span *ngIf="isClosedBool?.isClosed" class="glyphicon glyphicon-plus-sign hvr-icon" style="vertical-align:middle;font-size: 1rem;"></span>
        <span *ngIf="!isClosedBool?.isClosed" class="glyphicon glyphicon-minus-sign hvr-icon" style="vertical-align:middle;font-size:1rem;"></span>
      </span>
    </a>
  </li>-->
<!--<li style="margin:0;padding:0;">
  <ul *ngIf="!isClosedBool?.isClosed" [ngClass]="{'submenu':true}" style="margin:0;padding:0;">
    <li style="margin:0rem;padding:0;">
      <div *ngFor="let kv of arrKv let i=index">
        <div *ngIf="kv?.value">
          <div class="col-md-12 row increase" style="vertical-align:central;">
            <div class="kvxxSk text-left wordWrap" style="width:40%;text-transform:capitalize;">
              {{kv.key}}:
            </div>
            <div *ngIf="kv?.key?.indexOf('image') === -1 && kv?.value" class="kvxxSv wordWrap text-right" style="float:right;width:40%">
              {{kv.value}}
            </div>
            <div *ngIf="kv?.key?.indexOf('image') !== -1 && kv?.value" class="kvxxSv text-right" style="width:40%">
              <img [src]="kv.value | safeFilter: 'pic'" style="height:7rem; width: 9rem;" class="img-responsive img-rounded" />
            </div>
          </div>
          <div class="col-md-12" style="padding: 0.05rem; display: inline; border-bottom: 1.5px solid silver;"></div>
        </div>
      </div>
    </li>
  </ul>
</li>-->

