
import { Injectable } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';

@Injectable({
  providedIn: 'any',
})
export class CheckConnectionService {
  public isconnected : boolean = navigator.onLine;
  // ------------------------------------------------------------
  constructor ( private connectionService : ConnectionService ) { }
  // ------------------------------------------------------------
  public isOffline(): boolean {
    this.connectionService.monitor().subscribe((isConnected : any) => {
      this.isconnected = isConnected;
    });
    return this.isconnected;
  }
  // ------------------------------------------------------------
  public isConnected(): any {
    return this.connectionService.monitor().toPromise();
  }
  // ------------------------------------------------------------
  public isOnline(): any {
    // debugger;
    const promise = this.isConnected().then((c : any) => {
      this.isconnected = c;
      EmitterSubjectService.setIsOnLine(this.isconnected);
    });

    return this.isconnected;
  }
}
