
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { PagerServiceInterface } from '../../interfaces/serviceInterfaces/pagerServicesInterfaces/pagerServiceInterface';
import { PagerBreadcrum } from '../../models/breadcrum/pagerBreadcrum.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { Pager } from '../../models/pagination/pager.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { ArrayServiceStatic } from '../staticServices/arrayServiceStatic.service';
import { BasicPagerService } from './basicPagerService.service';

declare let jQuery: any;

declare let $: any;

@Injectable({ providedIn: 'any'})
export class PagerService extends BasicPagerService implements OnDestroy, PagerServiceInterface {
  public breadcrumArr: KvAny[] = [];
  public emitterDestroyed$: Subject<boolean> = new Subject();
  public pics: string[] = [];
  // public profilePics: ProfilePics = new ProfilePics();
  public pager: Pager = new Pager();
  public pagerBrdCrm: PagerBreadcrum = new PagerBreadcrum();
  // public rendererService : RendererService;
  // public renderer!: Renderer2;
  // public stringService : StringService;
  public pagedKvPhotoArr: KvPhoto[] = [];
  public pagedBreadcrumArr: KvAny[] = [];
  public kvPhotoArr: KvPhoto[] = [];
  //public pageSize = 10;
  //public totalItems = 0;
  // ---------------------------------------------------------------
  constructor(
    private rendererService: RendererService,
    private router: Router,
    @Inject(DOCUMENT) document : any,
    @Inject(Window) window : any
  ) {
    super();
    if (this.rendererService) {
      this.renderer = this.rendererService.getRenderer();
    }
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  /*
   * ===============================================================
   * ---------------------------------------------------------------
   */
  public getImageKvArr(): KvPhoto[] {
    return this.kvPhotoArr;
  }
  // ---------------------------------------------------------------
  public setImageKvArr(imageArr: KvPhoto[]): void {
    this.kvPhotoArr = imageArr;
  }
  // ---------------------------------------------------------------
  public getPagedKvPhotoArr(): KvPhoto[] {
    return this.pagedKvPhotoArr;
  }
  // ---------------------------------------------------------------
  public setPagedImagesKvArr(pagedImageArr: KvPhoto[]): void {
    this.pagedKvPhotoArr = pagedImageArr;
    // this.setGlyphicons(this.pagedGlyphsArr);
  }
  // ---------------------------------------------------------------
  public getPager(): Pager {
    return this.pager;
  }
  // ---------------------------------------------------------------
  public getPagerBreadcrum(): PagerBreadcrum {
    return this.pagerBrdCrm;
  }
  // ---------------------------------------------------------------
  public setPager(page: number): any {
    return this.setPagerWithImageKvArr(this.kvPhotoArr, page);
  }
  // ---------------------------------------------------------------------------------
  public setPagerWithImageKvArr(pics: KvPhoto[], page: number): any {
    if (page < 1) {
      return;
    }
    this.pager.currentPageNo = page > 0 ? page : 1;

    if (pics && pics.length > 0) {
      this.pager.totalItems = pics.length;
      this.kvPhotoArr = this.pager.kvPhotoArr = pics;
    }
    this.pageSize = 10;

    /*
     * get pager object
     * (totalItems, currentPage, pageSize)
     */
    this.pager = this.GetPagerOfSize10(this.pager.totalItems, this.pager.currentPageNo, this.pageSize > 0 ? this.pageSize : 10);
    /*
     * debugger;
     * get current items of page
     */
    if (this.kvPhotoArr && this.kvPhotoArr.length > 0) {
      this.pagedKvPhotoArr = this.kvPhotoArr.slice(this.pager.startIndex, this.pager.endIndex + 1);
      if (this.pagedKvPhotoArr && this.pagedKvPhotoArr.length > 0) {
        // this.profilePics.pagedKvPhotoArr = this.pagedKvPhotoArr; //TODO: restore
        this.pager.pagedKvPhotoArr = this.pagedKvPhotoArr;
      }
    }
    /*
     * debugger;
     * this.emitterService.emitSetPage(page);
     * debugger;
     */
    return this.pager;
    /*
     * this.emitterService.emitPager(this.pager);
     * this.emitterService.emitPagedProfilePics(this.profilePics.pagedProfilePics);
     */
  }
  
  // ---------------------------------------------------------------
  public setPageNumAndIndex (kvpArr : KvPhoto[]) : KvPhoto[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {

      for (var i = 0; i < kvpArr.length; i++) {
        kvpArr[ i ].itemNum = i + 1;
        kvpArr[ i ].index = i;
      }
    }
    return kvpArr;
  }
   // ---------------------------------------------------------------
   
  public setPagerOld(pics: string[], page: number): any {
    if (page < 1) {
      return;
    }
    this.pager.currentPageNo = page > 0 ? page : 1;

    if (pics && pics.length > 0) {
      this.pager.totalItems = pics.length;
    }
    /*
     * get pager object
     * (totalItems, currentPage, pageSize)
     */

    this.pager = this.GetPager(this.pager.totalItems, this.pager.currentPageNo, this.pageSize > 0 ? this.pageSize : 10);
    /*
     * debugger;
     * get current items of page
     */
    if (this.kvPhotoArr && this.kvPhotoArr.length > 0) {
      this.pagedKvPhotoArr = this.kvPhotoArr.slice(this.pager.startIndex, this.pager.endIndex + 1);
      if (this.pagedKvPhotoArr && this.pagedKvPhotoArr.length > 0) {
        // this.profilePics.pagedKvPhotoArr = this.pagedKvPhotoArr; // TODO: restore
        this.pager.pagedKvPhotoArr = this.pagedKvPhotoArr;
        // this.setPageImagesByKvImageArr(this.pagedKvPhotoArr);
      }
    }

    /*
     * let pElem = document.getElementById('page-' + page);
     * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pElem)) this.setPagerPic(this.pics, pElem, page);
     * debugger;
     */
    return this.pager;
    /*
     * this.emitterService.emitPager(this.pager);
     * this.emitterService.emitPagedProfilePics(this.profilePics.pagedProfilePics);
     */
  }

  // ---------------------------------------------------------------
  public setPagerKv(imageArr: KvPhoto[], page: number):any {
    // if (page < 1 || page > this.pager.totalPages) {
    if (page < 1) {
      return null;
    }
    if (imageArr && imageArr.length > 0) {
      this.kvPhotoArr = imageArr;
    }
    this.pager.currentPageNo = page;
    const totalItems = this.kvPhotoArr ? this.kvPhotoArr.length : 0;
    // get pager object from service

    this.kvPhotoArr = this.setPageNumAndIndex(this.kvPhotoArr);

    this.pager = this.setPagerUpTo10(imageArr, page); // this.GetPager(totalItems, page, this.pageSize);

    // get current page of items
    if (this.kvPhotoArr.length > 0) {
      this.pagedKvPhotoArr = this.kvPhotoArr.slice(this.pager.startIndex, this.pager.endIndex + 1);
      if (this.pagedKvPhotoArr && this.pagedKvPhotoArr.length > 0) {
      // this.profilePics.pagedKvPhotoArr = this.pagedKvPhotoArr; // TODO: restore
        this.pager.pagedKvPhotoArr = this.pagedKvPhotoArr;
        // this.setPageImagesByKvImageArr(this.pagedKvPhotoArr);
      }
    }
    
    this.pager.startPage = this.kvPhotoArr[ 0 ];
    this.pager.endPage = this.kvPhotoArr[ this.kvPhotoArr.length - 1 ];

    let index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo);
    if (index > -1) {
      this.pager.currentPage = this.kvPhotoArr[ index ];
    }
  
    index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo - 1);
    
    this.pager.nextPage = this.kvPhotoArr[ this.pager.currentPageNo + 1 ];
    index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo + 1);
    if (index > -1) {

      this.pager.previousPage = this.kvPhotoArr[ this.pager.currentPageNo - 1 ];
    }
    /*
     * this.emitterService.emitPager(this.pager);
     * this.emitterService.emitPagedProfileImages(this.pagedKvPhotoArr);
     */
    return this.pager;
  }
  // --------------------------------------------------
  public GetPager (totalItems : any, currentPage : any, pageSize : any): any {
    // default to first page
    currentPage = currentPage || 1;

    // default page size is 10
    pageSize = pageSize || 10;

    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = this.range(startPage, endPage, 1);

    // return object with all pager properties required by the view
    this.pager.totalItems = totalItems;
    this.pager.currentPageNo = currentPage;
    this.pager.pageSize = pageSize;
    this.pager.totalPages = totalPages;
    this.pager.startPageNo = startPage;
    this.pager.endPageNo = endPage;
    this.pager.startIndex = startIndex;
    this.pager.endIndex = endIndex;
    this.pager.pages = pages;
    this.pager.startPage = this.kvPhotoArr[ 0 ];
    this.pager.endPage = this.kvPhotoArr[ this.kvPhotoArr.length - 1 ];

    let index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo);
    if (index > -1) {
      this.pager.currentPage = this.kvPhotoArr[ index ];
    }
    if (index > -1) {
      index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo - 1);
    }
    this.pager.nextPage = this.kvPhotoArr[ this.pager.currentPageNo - 1 ];
    index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo + 1);
    if (index > -1) {

      this.pager.previousPage = this.kvPhotoArr[ this.pager.currentPageNo + 1 ];
    }
    
    return this.pager;
  }
  // --------------------------------------------------
  public range (min : any, max : any, delta : any): any {
    const arr : any[] = [];
    let stepCount;

    if (min && !max && !delta) {
      for (let i = 0; i < min; i++) {
        arr[i] = i + 1;
      }
    } else {
      if (min && max && !delta) {
        stepCount = max - min;
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i + min);
        }
      } else {
        stepCount = Math.floor((max - min) / delta);
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i * delta + min);
        }
      }
    }
    return arr;
  }
  /*
   * ---------------------------------------------------------------
   * Note : The following methods are optional giving more page selections:
   *
   *       The setPagerUpTo10() method returns a 'Pager' of size 5 if the total elements are <=5
   *       or a pager of size 10 if the elements are >5.
   *       Also if it produces a pager of size 10, it also produces a 'PagerBreadcrum' of sixe 10.
   *
   *       You can obtain the 'PagerBreadcrum' via the api : getPagerBreadcrum();
   * ---------------------------------------------------------------
   */
  public setPagerUpTo10(pics: KvPhoto[], page: number): any {
    if (page < 1) {
      return;
    }
    this.pager.currentPageNo = page > 0 ? page : 1;

    if (pics && pics.length > 0) {
      this.pager.totalItems = pics.length;
      this.kvPhotoArr = this.pager.kvPhotoArr = pics;
    }
    let i = 0;
    for (let kv of this.kvPhotoArr) {
      kv.itemNum = (i + 1);
      i++;
    }
    this.pageSize = 10;

    // get pager object
    if (this.pager.totalItems <= 5) {
      this.pager = this.GetPagerOfSize5(this.pager.totalItems, this.pager.currentPageNo, this.pageSize > 0 ? this.pageSize : 5);
    } else {
      // (totalItems, currentPage, pageSize)
      this.pager = this.GetPagerOfSize10(this.pager.totalItems, this.pager.currentPageNo, this.pageSize > 0 ? this.pageSize : 10);
    }
    /*
     * debugger;
     * get current items of page
     */
    if (this.kvPhotoArr && this.kvPhotoArr.length > 0) {
      this.pagedKvPhotoArr = this.kvPhotoArr.slice(this.pager.startIndex, this.pager.endIndex + 1);
      if (this.pagedKvPhotoArr && this.pagedKvPhotoArr.length > 0) {
        // this.profilePics.pagedKvPhotoArr = this.pagedKvPhotoArr; // TODO: restore
        // this.setPageImagesByKvImageArr(this.pagedKvPhotoArr);
        this.pager.pagedKvPhotoArr = this.pagedKvPhotoArr;
      }
    }
    /*
     * this.pager.pagedImageKvArr = this.pagedKvPhotoArr;
     * this.setPageClass(page);
     * debugger;
     */
    return this.pager;
    /*
     * this.emitterService.emitPager(this.pager);
     * this.emitterService.emitPagedProfilePics(this.profilePics.pagedProfilePics);
     */
  }
  // --------------------------------------------------
  public GetPagerOfSize10 (totalItems : any, currentPage : any, pageSize : any): any {
    // default to first page
    this.pager.currentPageNo = currentPage > 0 ? currentPage : 1;
    this.pager.totalItems = totalItems > 0 ? totalItems : 1;
    // default page size is 10
    this.pageSize = pageSize > 0 ? pageSize : 10;

    // calculate total pages
    const totalPages = Math.ceil(this.pager.totalItems / this.pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (this.pager.currentPageNo <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (this.pager.currentPageNo + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = this.pager.currentPageNo - 5;
        endPage = this.pager.currentPageNo + 4;
      }
    }

    /*
     * calculate start and end item indexes
     * Updated version:
     * ------------------
     */
    const startIndex = totalItems <= this.pageSize ? this.pager.currentPageNo - 1 : this.pager.currentPageNo > this.pageSize && this.pageSize < totalPages ? (this.pager.currentPageNo - 1) * this.pageSize : 0;
    const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize), this.pager.totalItems > 1 ? this.pager.totalItems - 1 : this.pager.totalItems);

    /*
     * original version:
     * ------------------
     * let startIndex = (this.currentPage - 1) * this.pageSize;
     * let endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1
     *               : this.pageSize), (this.totalItems > 1 ? this.totalItems - 1  : this.totalItems));
     * create an array of pages to ng-repeat in the pager control
     */
    const pages = this.range(startPage, endPage, 1);

    // return object with all the pager properties required by the view
    this.pagerBrdCrm = new PagerBreadcrum();
    this.pagerBrdCrm.totalItems = this.pager.totalItems;
    this.pagerBrdCrm.currentPage = pages[ this.pager.currentPageNo];
    // this.pagerBrdCrm.currentBrdCrmPage = this.breadcrumRing[this.currentPage - 1];
    this.pagerBrdCrm.previousPage = pages[ this.pager.currentPageNo - 1 >= 1 ? this.pager.currentPageNo - 1 : this.pager.currentPageNo];
    // if (this.pagerBrdCrm.previousPage >= 1) this.pagerBrdCrm.previousBrdCrmPage = this.breadcrumRing[this.pagerBrdCrm.previousPage - 1];
    this.pagerBrdCrm.nextPage = pages[ this.pager.currentPageNo + 1 <= totalItems ? this.pager.currentPageNo + 1 : this.pager.currentPageNo];
    // if (this.pagerBrdCrm.nextPage <= this.totalItems) this.pagerBrdCrm.nextBrdCrmPage = this.breadcrumRing[this.pagerBrdCrm.nextPage - 1];
    this.pagerBrdCrm.pageSize = this.pageSize;
    this.pagerBrdCrm.totalPagedBlocks = totalPages;
    this.pagerBrdCrm.pagedStartPage = startPage;
    this.pagerBrdCrm.pagedEndPage = endPage;
    this.pagerBrdCrm.pagedStartIndex = startIndex;
    this.pagerBrdCrm.pagedEndIndex = endIndex;
    this.pagerBrdCrm.pagedPages = pages;

    return this.pager; // (this.pager = this.copyService.copyFromPagerBreadcrumToPager(this.pagerBrdCrm)); // TODO: restore
  }

  // --------------------------------------------------
  public GetPagerOfSize5 (totalItems : any, currentPage : any, pageSize : any): any {
    // default to first page
    this.pager.currentPageNo = currentPage > 0 ? currentPage : 1;
    this.pager.totalItems = totalItems > 0 ? totalItems : 1;
    // default page size is 10
    this.pageSize = pageSize > 0 ? pageSize : 5;

    // calculate total pages
    const totalPages = Math.ceil(this.pager.totalItems / this.pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 5) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (this.pager.currentPageNo <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (this.pager.currentPageNo + 2 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = this.pager.currentPageNo - 3;
        endPage = this.pager.currentPageNo + 2;
      }
    }

    /*
     * calculate start and end item indexes
     * Updated version:
     * ------------------
     */
    const startIndex = totalItems <= this.pageSize ? this.pager.currentPageNo - 1 : this.pager.currentPageNo > this.pageSize && this.pageSize < totalPages ? (this.pager.currentPageNo - 1) * this.pageSize : 0;
    const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize), this.pager.totalItems > 1 ? this.pager.totalItems - 1 : this.pager.totalItems);

    /*
     * original version:
     * ------------------
     * let startIndex = (this.currentPage - 1) * this.pageSize;
     * let endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1  : this.pageSize), (this.totalItems > 1 ?
     *               this.totalItems - 1  : this.totalItems));
     */

    // create an array of pages to ng-repeat in the pager control
    const pages = this.range(startPage, endPage, 1);

    // return object with all pager properties required by the view
    this.pager.totalItems = this.pager.totalItems;
    this.pager.currentPageNo = this.pager.currentPageNo;
    this.pager.pageSize = this.pageSize;
    this.pager.totalPages = totalPages;
    this.pager.startPageNo = startPage;
    this.pager.endPageNo = endPage;
    this.pager.startIndex = startIndex;
    this.pager.endIndex = endIndex;
    this.pager.pages = pages;
    this.pager.startPage = this.kvPhotoArr[ 0 ];
    this.pager.endPage = this.kvPhotoArr[ this.kvPhotoArr.length - 1 ];

    let index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo);
    if (index > -1) {
      this.pager.currentPage = this.kvPhotoArr[ index ];
    }
    if (index > -1) {
      index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo - 1);
    }
    this.pager.nextPage = this.kvPhotoArr[ this.pager.currentPageNo - 1 ];
    index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo + 1);
    if (index > -1) {

      this.pager.previousPage = this.kvPhotoArr[ this.pager.currentPageNo + 1 ];
    }
    
    return this.pager;
  }
  /*
   * --------------------------------------------------
   * NOTE : All the image-setter methods are here:
   * Note : this method will simply set the background-image of all the numbered-pages (1...10 ->)
   * --------------------------------------------------
   * TODO : Test with setTimeout
   */
  public setPagerImagesByKvImageArr(kvImageArr: KvAny[]): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvImageArr) && kvImageArr.length > 0) {
      // debugger;
      kvImageArr.map((e) => {
        // debugger;
        const image = 'url(\'' + e.value + '\')';

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
          const pageId = 'pagePmgmt-' + (e.id + 1);
          // debugger;
          const pElem = document.getElementById(pageId);

          if (pElem) {
            // debugger;
            this.renderer?.setStyle(pElem, 'backgroumd-image', image);
            pElem.remove();
          }
        }
        // debugger;
      });
    }
  }
  // ---------------------------------------------------------------------------------
  public setStyleProperties(properties:any = { }):void {
    Object.keys(properties).forEach((k : any) => document.documentElement.style.setProperty(`--${ k }`, properties[ k ])); // Sayeed: Experimental: 'k.id'
  }
  // ---------------------------------------------------------------------------------
  public setPagerImage(page: any): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(page)) {
      let pageId;

      if (page > -1) {
        pageId = 'page-' + page;
      } else if (page.length > 5 && page.indexOf('page') !== -1) {
        pageId = page;
      }
      const pElem = document.getElementById(pageId);
      // debugger;

      if (pElem) {
        switch (pageId.toLowerCase()) {
          // Note :
          case 'page-F':
          case 'page-prev':
          case 'page-next':
          case 'page-L':
            /*
             * this.pagedGlyphsArr.map(e => {
             * if (!this.elementHasThisClass(pElem, 'pagerRotate')) {
             * debugger;
             * this.renderer?.addClass(pElem, 'pagerRotate');
             * }
             * });
             * Do nothing
             */
            break;
          default:
            let image;
            let pNum : any;

            if (page > -1) {
              pNum = page;
            } else {
              const parts = pageId.split('-');

              if (parts?.length === 2) {
                pNum = parseInt(parts[1], 10);
              }
            }
            this.pagedKvPhotoArr.map((e) => {
              if (e.photoId === pNum) {
                image = e.value;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
                  // debugger;
                  this.renderer?.setStyle(pElem, 'backgroumd-image', 'url(\'' + image + '\')');
                }
              }
            });
        }
        // this.renderer?.setStyle(pElem, 'transform', 'rotate(45deg)');
      }
      if (pElem) {
        pElem.remove();
			}
    }
  }
  /*
   * ---------------------------------------------------------------
   * ***************************************************************
   * ---------------------------------------------------------------
   * Begin of Breadcrum functionalities:
   *
   * Note : These following methods were part of the early-scheme,
   *       which did not work 100%, and was longer-code-base.
   *
   *       Some of these methods are used/based to build the new
   *       Breadcrum-System.
   * ---------------------------------------------------------------
   * ***************************************************************
   * ---------------------------------------------------------------
   */
  public setPagerBreadcrum(brdcrmArr: KvAny[], page: number): any {
    // if (page < 1 || page > this.pager.totalPages) {
    if (page < 1) {
      return null;
    }
    if (brdcrmArr && brdcrmArr.length > 0) {
      this.breadcrumArr = brdcrmArr;
    }
    this.pager.currentPageNo = page;
    const totalItems = this.breadcrumArr ? this.breadcrumArr.length : 0;
    // get pager object from service

    this.pagerBrdCrm = this.GetPagerBreadcrum(totalItems, this.pager.currentPageNo, this.pageSize);

    /*
     * this.emitterService.emitPager(this.pager);
     * this.emitterService.emitPagedProfileImages(this.pagedKvPhotoArr);
     */
    return this.pagerBrdCrm;
  }
  // --------------------------------------------------
  GetPagerBreadcrum (totalItems : any, currentPage : any, pageSize : any): PagerBreadcrum {
    // default to first page
    this.pager.currentPageNo = currentPage > 0 ? currentPage : 1;
    this.pager.totalItems = totalItems > 0 ? totalItems : 1;
    // default page size is 10
    this.pageSize = pageSize > 0 ? pageSize : 10;

    // calculate total pages
    const totalPages = Math.ceil(this.pager.totalItems / this.pageSize);

    let startPage;
    let endPage;

    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (this.pager.currentPageNo <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (this.pager.currentPageNo + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = this.pager.currentPageNo - 5;
        endPage = this.pager.currentPageNo + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex =
      this.pager.currentPageNo - 1 <= this.pageSize - 1 ? this.pager.currentPageNo - 1 : this.pager.currentPageNo > this.pageSize && this.pager.currentPageNo < totalPages ? this.pager.currentPageNo - 1 : (this.pager.currentPageNo - 1) % this.pageSize;
    const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize), this.pager.totalItems > 1 ? this.pager.totalItems - 1 : this.pager.totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = this.range(startPage, endPage, 1);

    // return object with all pager properties required by the view
    this.pagerBrdCrm.totalItems = this.pager.totalItems;
    this.pagerBrdCrm.currentPage = pages[this.pager.currentPageNo];
    this.pagerBrdCrm.pageSize = this.pageSize;
    this.pagerBrdCrm.totalPagedBlocks = totalPages;
    this.pagerBrdCrm.pagedStartPage = startPage;
    this.pagerBrdCrm.pagedEndPage = endPage;
    this.pagerBrdCrm.pagedStartIndex = startIndex;
    this.pagerBrdCrm.pagedEndIndex = endIndex;
    this.pagerBrdCrm.pagedPages = pages;
    return this.pagerBrdCrm;
  }
  // --------------------------------------------------
  public displayBreadcrumButtons(kvArr: KvAny[]): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvArr) && kvArr.length > 0) {
      /*
       * this.pagerService.setImageKvArr(kvArr);
       * this.pagerService.setPagerImagesByKvImageArr(kvArr); //TODO : Test with setTimeout
       * debugger;
       */
      kvArr.map((e) => {
        setTimeout(() => {
          // debugger;
          if (e && e.value && e.id > -1) {
            const elemId = 'pageBrdcrm-' + (e.id + 1);

            this.setButtonImage(e.value, elemId);
          }
        }, 500);
      });
    }
  }
  // ---------------------------------------------------------------------------------
  public setButtonImage(image: string, elemId: string): void {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      const tempElem = document.getElementById(elemId);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
        // debugger;
        const tImage = 'url(\'' + image + '\')';

        this.renderer?.setStyle(tempElem, 'background-image', tImage);
        tempElem.remove();
        /*
         * this.renderer?.setStyle(tempElem, 'transform', 'rotate(45deg)');
         * debugger;
         */
      }
    }
  }
  /*
   * ***************************************************************
   * ---------------------------------------------------------------
   * End of Breadcrum functionalities:
   * ---------------------------------------------------------------
   * ***************************************************************
   * ===============================================================
   * Note : Not used at the moment, and may need to be updated in accordance with setPage()
   * ---------------------------------------------------------------
   */
  public setPageOfProfilePics (profilePics : any, page : any): any {
    if (profilePics && profilePics.profileImageArr && profilePics.profileImageArr?.length > 0) {
      // this.profilePics = profilePics; // TODO: restore
    }
    if (page < 0) {
      return;
    }

    this.pager.currentPageNo = page;
    const totalItems = profilePics ? profilePics?.profileImageArr?.length : 0;
    // get pager object from service

    this.pager = this.GetPager(totalItems, page, 10); // (totalItems, currentPage, pageSize)

    // get current page of items
    if (profilePics && profilePics?.profileImageArr && profilePics?.profileImageArr?.length > 0) {
      const profileImageArr = profilePics.profileImageArr.slice(this.pager.startIndex, this.pager.endIndex + 1);

      if (profileImageArr && profileImageArr.length > 0) {
        profileImageArr.map((e : any) => {
          // this.profilePics.pagedProfilePics.push(e.image); // TODO: restore
        });
      }
    }
    /*
     * let pElem = document.getElementById('page-' + page);
     * if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pElem)) this.setPagerPic(this.pics, pElem, page);
     */
    return this.pager;
    /*
     * this.emitterService.emitPager(this.pager);
     * this.emitterService.emitPagedProfilePics(this.profilePics.pagedProfilePics);
     */
  }
  // ---------------------------------------------------------------------------------
  public elementHasThisClass(pElem: any, glyph: string): boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(glyph)) {
      if ($(this).hasClass(glyph)) {
        return true;
      }
    }
    return false;
  }
  // ---------------------------------------------------------------------------------
  public setPagerPic(pics: string[], pElem: any, page: number): any {
    if (page > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pics) && pics.length > 0) {
      let i = 0;

      this.pics.map((e) => {
        ++i;
        if (i + 1 === page) {
          this.renderer?.setStyle(pElem, 'background-image', 'url(\'' + pics[i] + '\')');
        }
      });
    }
  }
  /*
   * ==================================================
   * --------------------------------------------------
   * Deprecated!
   * --------------------------------------------------
   */
  // GetPagerOriginal(totalItems, currentPage, pageSize) {
  //  // default to first page
  //  this.pager.currentPageNo = currentPage > 0 ? currentPage : 1;
  //  this.totalItems = totalItems > 0 ? totalItems : 1;
  //  // default page size is 10
  //  this.pageSize = pageSize > 0 ? pageSize : 10;

  //  // calculate total pages
  //  const totalPages = Math.ceil(this.totalItems / this.pageSize);

  //  let startPage;
  //  let endPage;

  //  if (totalPages <= 10) {
  //    // less than 10 total pages so show all
  //    startPage = 1;
  //    endPage = totalPages;
  //  } else {
  //    // more than 10 total pages so calculate start and end pages
  //    if (this.pager.currentPageNo <= 6) {
  //      startPage = 1;
  //      endPage = 10;
  //    } else if (this.pager.currentPageNo + 4 >= totalPages) {
  //      startPage = totalPages - 9;
  //      endPage = totalPages;
  //    } else {
  //      startPage = this.pager.currentPageNo - 5;
  //      endPage = this.pager.currentPageNo + 4;
  //    }
  //  }

  //  // calculate start and end item indexes
  //  const startIndex = (this.pager.currentPageNo - 1) * this.pageSize;
  //  const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1 : this.pageSize), this.totalItems > 1 ? this.totalItems - 1 : this.totalItems);

  //  // create an array of pages to ng-repeat in the pager control
  //  const pages = this.range(startPage, endPage, 1);

  //  // return object with all pager properties required by the view
  //  this.pager.totalItems = this.totalItems;
  //  this.pager.currentPageNo = this.pager.currentPageNo;
  //  this.pager.pageSize = this.pageSize;
  //  this.pager.totalPages = totalPages;
  //  this.pager.startPageNo = startPage;
  //  this.pager.endPageNo = endPage;
  //  this.pager.startIndex = startIndex;
  //  this.pager.endIndex = endIndex;
  //  this.pager.pages = pages;
  //  this.pager.pages.firstPage = this.kvPhotoArr[ 0 ];
  //  this.pager.pages.lastPage = this.kvPhotoArr[ this.kvPhotoArr.length - 1 ];

  //  let index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo);
  //  if (index > -1) {
  //    this.pager.pages.currentPage = this.kvPhotoArr[ index ];
  //  }
  //  if (index > -1) {
  //    index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo - 1);
  //  }
  //  this.pager.pages.nextPage = this.kvPhotoArr[ this.pager.currentPageNo - 1 ];
  //  index = ArrayServiceStatic.getIndexOfItemByPageNumInKvPhotoArr(this.kvPhotoArr, this.pager.currentPageNo + 1);
  //  if (index > -1) {

  //    this.pager.pages.previousPage = this.kvPhotoArr[ this.pager.currentPageNo + 1 ];
  //  }
  //  return this.pager;
  // }
  /*
   * --------------------------------------------------
   * Deprecated!
   * --------------------------------------------------
   */
  // rangeOriginal(min, max, delta) {
  //  const arr : any[] = [];
  //  let stepCount;

  //  if (min && !max && !delta) {
  //    for (let i = 0; i < min; i++) {
  //      arr[i] = i + 1;
  //    }
  //  } else {
  //    if (min && max && !delta) {
  //      stepCount = max - min;
  //      for (let i = 0; i <= stepCount; i++) {
  //        arr.push(i + min);
  //      }
  //    } else {
  //      stepCount = Math.floor((max - min) / delta);
  //      for (let i = 0; i <= stepCount; i++) {
  //        arr.push(i * delta + min);
  //      }
  //    }
  //  }
  //  return arr;
  // }
  // --------------------------------------------------
}
