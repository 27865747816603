'use-strict'
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveFilterPipeModule } from '../directiveFilterPipeModule/directiveFilterPipe.module';
import { ImageUploadModule } from '../imageUploadModule/imageUpload.module';
import { SharedRoutingModule } from '../sharedModule/shared-routing.module';
import { KeyValueModuleComponent } from './component/keyValueModule.component';
import { ContentKeyValueArrComponent } from './contentKeyValueArr/contentKeyValueArr.component';
import { KeyValueRoutingModule } from './keyValue-routing.module';
import { KeyValueComponent } from './keyValue/keyValue/keyValue.component';
import { KeyValueAnyComponent } from './keyValue/keyValueAny/keyValueAny.component';
import { KeyValueAnyArrComponent } from './keyValue/keyValueAnyArr/keyValueAnyArr.component';
import { KeyValueAnyTemplateComponent } from './keyValue/keyValueAnyTemplate/keyValueAnyTemplate.component';
import { KeyValueArrComponent } from './keyValue/keyValueArr/keyValueArr.component';
import { ProfileImageKvComponent } from './keyValue/profileImageKv/profileImageKv.component';
import { ProfilePicsKeyValueArrComponent } from './keyValue/profilePicsKeyValueArr/profilePicsKeyValueArr.component';
import { PreferenceKeyValueArrComponent } from './preferenceKeyValueArr/preferenceKeyValueArr.component';
import { ProfileInfoKeyValueArrComponent } from './profileInfoKeyValueArr/profileInfoKeyValueArr.component';
import { UploadFilesKeyValueArrComponent } from './uploadFilesKeyValueArr/uploadFilesKeyValueArr.component';

@NgModule({
  declarations: [
    ContentKeyValueArrComponent,
    KeyValueComponent,
    KeyValueAnyComponent,
    KeyValueAnyArrComponent,
    KeyValueAnyTemplateComponent,
    KeyValueArrComponent,
    PreferenceKeyValueArrComponent,
    ProfileImageKvComponent,
    ProfileInfoKeyValueArrComponent,
    ProfilePicsKeyValueArrComponent,
    KeyValueModuleComponent,
    UploadFilesKeyValueArrComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    DirectiveFilterPipeModule,
    ImageUploadModule,
    SharedRoutingModule,
    KeyValueRoutingModule,
  ],
  exports: [
    ContentKeyValueArrComponent,
    ProfileInfoKeyValueArrComponent,
    ProfilePicsKeyValueArrComponent,
    PreferenceKeyValueArrComponent,
    UploadFilesKeyValueArrComponent,
    KeyValueRoutingModule,
  ],

  providers: [
    // { provide: ToNumberPipe },
  ],

})
export class KeyValueModule {
}
