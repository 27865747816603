<ul id="profileImageKvComponentId" style="list-style:none;width:94.5%;margin:0.15rem 1rem 0.15rem 1rem;padding:0px;">
  <li expandMenu style="margin:0;padding:0;">
    <a (click)="isClosedBool.isClosed=!isClosedBool.isClosed">
      <p class="kvBttnxS" style="margin:0.01rem;padding:0.15rem 1rem 0.2rem 1rem;">
        <span *ngIf="kvAnyName.length > 0" style="float:left;">
          {{id }}/{{kvAnyName}}/{{profileImage.sitUserKey }}
        </span>
        <span *ngIf="isClosedBool.isClosed" class="glyphicon glyphicon-plus-sign hvr-icon" style="float:right;font-size:0.9rem;padding:0.2rem 1rem 0.1rem 1rem;"></span>
        <span *ngIf="!isClosedBool.isClosed" class="glyphicon glyphicon-minus-sign hvr-icon" style="float:right;font-size:0.9rem;padding:0.2rem 1rem 0.1rem 1rem;"></span>
      </p>
    </a>
  </li>
  <li style="margin:0;padding:0;">
    <ul *ngIf="!isClosedBool.isClosed" [ngClass]="{'submenu':true}" align="left" style="list-style:none;width:100%;margin:0;padding:0;">
      <li style="margin: 0;padding: 0;">        
          <!--<div *ngIf="kvAny.value" style="width:95%;float:left;">-->
            <!--First-level of key-value-->
            <div class="increase flex-container">
              <!--<div class="kvxxSk wordWrap" style="width:40%;float:left;text-align:right;">
                {{kvAny.id}}) {{kvAny.key}}::
              </div>

              <div *ngIf="kvAny.value.image?.length > 0" style="width:100%;font-size:0.6rem;margin:0;padding:0;">
                <div  *ngFor="let imageV of kvAny.value.image; let i=index" style="text-decoration:none;border:none;background:transparent;">
                  <div id="{{kvAny.value?.sitUserId}}" class="tilePic-desktop smaller img-responsive" [ngStyle]="{ 'background': imageV}">
                  </div>
                </div>
              </div>-->                                     
                  
              <!--<div [ngClass]="{'profileName':!isMobilevar, 'profileNameMobile':isMobilevar}">
                <div *ngIf="kvAny.value.date > 0">{{kvAny.value?.date}}</div>
                <div *ngIf="kvAny.value.isPrimary">Primary Image</div>
                <div *ngIf="kvAny.value.privacy.length == 0">
                  {{kvAny.value.privacy}}</div>
                <div *ngIf="kvAny.value.profileId > 0">ProfileId: {{kvAny.value.profileId}}</div>
                <div *ngIf="kvAny.value.sitUserId > 0">SitUserId: {{kvAny.value.sitUserId }}</div>
                <div *ngIf="kvAny.value.sitUserkey.length > 0">SitUserKey: {{kvAny.value.sitUserkey }}</div>
              </div>                                  
            </div>
          </div>-->

          <div style="width:95%;float:left;">
            <div class="kvxxSk wordWrap flex-button">
              PhotoId: {{profileImage.photoId}}::
            </div>

            <div *ngIf="profileImage.date.length > 0" class="flex-button">Date: {{profileImage.date}}</div>
            <div *ngIf="profileImage.isPrimary" class="flex-button">Primary Image: {{profileImage.isPrimary}}</div>
            <div *ngIf="profileImage.privacy.length == 0" class="flex-button">Privacy: 
              {{profileImage.privacy}}
            </div>
            <div *ngIf="profileImage.profileId > 0" class="flex-button">ProfileId: {{profileImage.profileId}}</div>
            <div *ngIf="profileImage.sitUserId > 0" class="flex-button">SitUserId: {{profileImage.sitUserId }}</div>
            <div *ngIf="profileImage.sitUserKey.length > 0" class="flex-button">SitUserKey: {{profileImage.sitUserKey }}</div>
            <div class="flex-container">
              <div id="pImage-{{profileImage.sitUserId}}-{{id}}" class="flex-button tilePic-desktop smaller img-responsive">
              </div>
              <img class="tilePic-desktop smaller img-responsive"  src="{{profileImage.image | safe: 'url'}}" />
            </div>                    
          </div>
        </div>
        <div class="flex-container">
          <span class="flex-button errxxS" style="text-transform: capitalize;color:orangered;"><em>{{kvAnyLegend}}</em> is required, please select at leaset one value.</span>
        </div>
      </li>
    </ul>
  </li>
</ul>
