'use-strict'
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Animations } from '../../../app/animation';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { Register } from '../../../models/account/register.model';
import { BoxNonceEntity } from '../../../models/boxNonce/boxNonceEntity.model';
import { Address } from '../../../models/common/address.model';
import { EmitDatePickerResult } from '../../../models/common/emitDatePicResult.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { CheckConnectionService } from '../../../services/coreServiceService/checkConnectionService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { DateStringServiceStatic } from '../../../services/staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../../../services/staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../../../services/staticServices/stringServiceStatic.service';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
  animations: [
    Animations,
  ],
})
export class AddressComponent implements OnInit, OnDestroy, AfterViewInit {
  public checkConnectionService! : CheckConnectionService;
  public httpService! : HttpService;
  // public inMemoryDataService: InMemoryDataService;
  // ----------------------------------------------------------

  @ViewChild( 'firstName', { read: ElementRef, static: true } )
  private firstNameFocus! : ElementRef;
  @ViewChild( 'lastName', { read: ElementRef, static: true } )
  private lastNameFocus! : ElementRef;
  @ViewChild( 'addressStr', { read: ElementRef, static: true } )
  private addressStrFocus! : ElementRef;
  @ViewChild( 'city', { read: ElementRef, static: true } )
  private cityFocus! : ElementRef;
  @ViewChild( 'state', { read: ElementRef, static: true } )
  private stateFocus! : ElementRef;
  @ViewChild( 'country', { read: ElementRef, static: true } )
  private countryFocus! : ElementRef;
  @ViewChild( 'zipCode', { read: ElementRef, static: true } )
  private zipCodeFocus! : ElementRef;
  @ViewChild( 'email', { read: ElementRef, static: true } )
  private emailFocus! : ElementRef;
  @ViewChild( 'addBttn', { read: ElementRef, static: true } )
  private submitBttnFocus! : ElementRef;
  // ----------------------------------------------------------
  public address: Address = new Address();
  public accountMessages: any;
  public age = 0;
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public centerHeight = 0;
  public centerWidth = 0;
  public datePickerResult: EmitDatePickerResult = new EmitDatePickerResult();
  public emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isAddressComplete = false;
  public isMobilevar = false;
  public isOnLine = false;
  public isSuccess = false;
  public intervalMsg: any;
  public isDobErr = false;
  public isPasswordsMatch = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public mcLoader: ModuleComponentLoader = new ModuleComponentLoader();
  public message : any = '';
  public msg: any;
  public passwordPattern = '^[a-zA-Z0-9.,;_%+-@#*&$]{8,30}$';
  public register: Register = new Register();
  @ViewChild( 'addressForm' ) addressForm : any;
  public addrTabIndexNum = 0;
  public titleDatePicker = 'DatePicker';
  public userAndPageTitle: UserAndPageTitle = new UserAndPageTitle();
  // ------------------------------------------------
  constructor (

    // private dbServiceService: DbServiceService,
    private coreServiceService: CoreServiceService,
    // private spinnerModalService: SpinnerModalService,
    private router: Router,
  ) {
    // to avoid DI circular-referencing
    if (coreServiceService) {
      this.checkConnectionService = coreServiceService.getCheckConnectionService();
    }
    if ( this.coreServiceService ) this.httpService = this.coreServiceService.getHttpService();
    // if (dbServiceService) {
    //  this.inMemoryDataService = dbServiceService.getInMemoryDataService();
    // }
    // this.isOnline = this.checkConnectionService.isOnline();
    // this.accountMessages = this.inMemoryDataService.accountMessages;  // TPDO: restore
  }

  ngOnInit ()
  {
    // this.setPageTitle();
    //  ----------------------------------------
    EmitterSubjectService.isMobileEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      this.isMobilevar = result;
    } );
    //  ----------------------------------------
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    // ----------------------------------------
    EmitterSubjectService.datePickerEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      this.datePickerResult = result;
      // debugger;
      this.register.dob = this.datePickerResult.pickedDate;
      this.register = this.determineAge(this.register.dob);
      // let event = this.datePickerResult.pickedDateEvent;
    } );
    // ----------------------------------------
    let point = EmitterSubjectService.getCenteringHeightWidth( 'registerComponentId' );
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( point ) )
    {
      this.centerHeight = point.height;
      this.centerWidth = point.width;
      // alert( 'in registerComponent! \n centerWidth: ' + this.centerWidth + '\n centerHeight: ' + this.centerHeight );
    }
  }
  // ---------------------------------------------------------------
  ngAfterViewInit ()
  {
    this.checkIsAddressComplete();
    // this.setPageTitle();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  // ---------------------------------------------------------------
  public onKeyDown ( event : KeyboardEvent )
  {
    switch ( event.key )
    {
      case 'Tab':
        if ( this.addrTabIndexNum === 1 )
        {
          this.firstNameFocus.nativeElement.focus();
        }
        if ( this.addrTabIndexNum === 2 )
        {
          this.lastNameFocus.nativeElement.focus();
        }
        if ( this.addrTabIndexNum === 3 )
        {
          this.addressStrFocus.nativeElement.focus();
        }
        if ( this.addrTabIndexNum === 4 )
        {
          this.cityFocus.nativeElement.focus();
        }
        if ( this.addrTabIndexNum === 5 )
        {
          this.stateFocus.nativeElement.focus();
        }
        if ( this.addrTabIndexNum === 6 )
        {
          this.countryFocus.nativeElement.focus();
        }
        if ( this.addrTabIndexNum === 7 )
        {
          this.zipCodeFocus.nativeElement.focus();
        }
        if ( this.addrTabIndexNum === 8 )
        {
          this.emailFocus.nativeElement.focus();
        }
        if ( this.addrTabIndexNum === 9 )
        {
          this.submitBttnFocus.nativeElement.focus();
        }

        this.addrTabIndexNum++;
        // if ( this.addrTabIndexNum > 3 ) this.addrTabIndexNum = 0;
        break;
      case 'Enter':
        // this.menuOpened = true;
        // make sure that the menu is open before setting focus
        // setTimeout( () => this.multiLevelDropDown.setFocusOnFirstMenuItem(), 1 );
        // this.sendWithGeoLocation();
        break;
      case 'Escape':
        // this.menuOpened = false;
        // this.menuTrigger.nativeElement.focus();
        break;
      case 'ArrowUp':
        // event.stopPropagation();
        // ( index === 0 ? this.menuItemsRef.las : this.menuItemsRef.get( index - 1 )).nativeElement.focus();
        break;
      case 'ArrowDown':
        // event.stopPropagation();
        // ( index === this.menuItemsRef.length - 1 ? this.menuItemsRef.first : this.menuItemsRef.get( index + 1 )
        // ).nativeElement.focus();
        break;
    }
  }
  // ---------------------------------------------------------------
  login () : void
  {
    this.mcLoader = new ModuleComponentLoader();
    this.mcLoader.componentName = 'login';
    this.mcLoader.moduleName = 'loginm';

    // EmitterSubjectService.emitLoadModule(this.mcLoader);
    this.router.navigate(['/loginm/login']);
  }
  // ---------------------------------------------------------------
  salt () : void
  {
    this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(this.address));
  }
  // ---------------------------------------------------------------
  setPageTitle(): void {
    this.userAndPageTitle.pageTitle = '-:Add Address:-';

    this.userAndPageTitle.isPrepared = true;
    EmitterSubjectService.emitUserAndPageTitle(this.userAndPageTitle);
  }
  // ---------------------------------------------------------------
  submitAddressForm () : void
  {
    // debugger;
    if ( this.checkIsAddressComplete() && this.addressForm.valid) {
      // debugger;
      // this.sendWithoutGeoLocation();
    }
  }
  // ---------------------------------------------------------------
  sendWithoutGeoLocation() {
    if (this.addressForm.valid && this.isPasswordsMatch && this.age >= 18) {
      this.salt();
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.box )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.nonce ) )
      {
        this.register = StringServiceStatic.maskPassword(this.register);
        EmitterSubjectService.emitResetPickedDate(true);

        // this.spinnerModalService.startSpinner( '<span class=\'neonGldText\'>Registration is in progress...</span>' );
        // this.spinnerModel.message = '<span class=\'neonGldText\'>Registration is in progress...</span>';
        // this.spinnerModel.isOpen = true;
        // EmitterSubjectService.emitShowSpinner(this.spinnerModel);

        this.addressForm.reset();
        this.httpService
          .postObservable(
            '/Address/UpdateAddress',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json',
          )
          .subscribe(
            (result) => {
              // debugger;
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                const loginResult = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity));
                // debugger;

                this.loginSuccess = loginResult as LoginSuccess;

                EmitterSubjectService.emitHideSpinner(true);

                if (this.loginSuccess.signedInUserId > 0) {
                  this.isSuccess = true;
                  this.message = this.loginSuccess.message;
                  this.setMessageTimer();
                  // this.httpService.navigateTo('/login');
                } else {
                  this.message = this.loginSuccess.message;
                  this.setMessageTimer();
                  this.msg = 'Error occured while registering! ;\n Error-mag : ' + this.loginSuccess.message;
                  EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.msg});
                }
              } else {
                this.message = this.loginSuccess.message;
                this.setMessageTimer();
                if (result) {
                  this.msg = 'Error occured while registering! \n Error-mag : ' + result.message;
                  EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.msg});
                } else {
                  EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured while registering!'});
                }
              }
            },
            (error) => {
              // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag : ' + error);
              this.message = this.loginSuccess.message;
              this.setMessageTimer();
              EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured while registering! \n Error-mag : ' + error.message});
            },
          );
      }
    }
  }
  // ---------------------------------------------------------------
  sendWithGeoLocation() {
    // debugger;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position) {
          console.log('Latitude : ' + position.coords.latitude + '; Longitude : ' + position.coords.longitude);
          this.register.latitude = position.coords.latitude;
          this.register.longitude = position.coords.longitude;

          // this.isSubmitted = true;
          if ( this.addressForm.valid && this.isPasswordsMatch && this.age >= 18) {
            this.salt();
            // debugger;
            if (
              this.isOnLine &&
              !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) &&
              !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) &&
              !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)
            ) {
              this.register = StringServiceStatic.maskPassword(this.register);
              this.addressForm.reset();
              EmitterSubjectService.emitResetPickedDate(true);

              // this.spinnerModalService.startSpinner( '<span class=\'neonGldText\'>Registration is in progress...</span>' );
              // this.spinnerModel.message = '<span class=\'neonGldText\'>Registration is in progress...</span>';
              // this.spinnerModel.isBusy = true;
              // EmitterSubjectService.emitShowSpinner(this.spinnerModel);

              this.httpService
                .postObservable(
                  '/Address/UpdateAddress',
                  {
                    box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
                    nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
                  },
                  'json',
                )
                .subscribe(
                  (result) => {
                    // debugger;
                    // this.spinnerModalService.stopSpinner();  // TPDO: restore
                    // this.isBusy = new IsBusy();

                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                      const loginResult = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity));
                      // debugger;

                      this.loginSuccess = loginResult as LoginSuccess;

                      EmitterSubjectService.emitHideSpinner(true);

                      if (this.loginSuccess.signedInUserId > 0) {
                        this.isSuccess = true;
                        this.message = '<span class=\'neonGrnText\'>' + this.loginSuccess.message + '</span>';
                        // EmitterSubjectService.emitMessage(this.message);
                        /*
                         * this.setMessageTimer();
                         * this.httpService.navigateTo('/login');
                         */
                      } else {
                        this.message = this.loginSuccess.message;
                        this.setMessageTimer();
                        // EmitterSubjectService.emitMessage(this.message);
                        this.msg = 'Error occured while registering! ;\n Error-mag : ' + this.loginSuccess.message;
                        // EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.msg});
                      }
                    } else {
                      this.message = this.loginSuccess.message;
                      this.setMessageTimer();
                      if (result) {
                        this.msg = 'Error occured while registering! \n Error-mag : ' + result.message;
                        // EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.msg});
                      } else {
                        // EmitterSubjectService.emitMyErrorLog({ feedbackMessage: 'Error occured while registering!'});
                      }
                    }
                  },
                  (error) => {
                    // alert ('Error occured in GetArticleContent(' + idArr[i] + ');\n Error-mag : ' + error);
                    this.message = this.loginSuccess.message;
                    // EmitterSubjectService.emitMessage(this.message);
                    this.msg = 'Error occured while registering! \n Error-mag : ' + error.message;
                    // EmitterSubjectService.emitMyErrorLog({ feedbackMessage: this.msg});
                  },
                );
            }
          }
        } else {
          console.log('Geolocation is not supported by this browser.');
          this.sendWithoutGeoLocation();
        }
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
      this.sendWithoutGeoLocation();
    }
  }
  // ---------------------------------------------------------------
  getUrl() {
    return 'url(\'assets/photos/glyph-members-transparent.png\')';
  }
  // ---------------------------------------------------------------
  onDateChanged(event: Register): void {
    this.register = this.determineAge(this.register.dob);
  }
  // ---------------------------------------------------------------
  checkPasswords() {
    if (this.register && this.register.password && this.register.confirmPassword) {
      this.isPasswordsMatch = this.register.confirmPassword.localeCompare(this.register.password) === 0;
    }
  }
  // ---------------------------------------------------------------
  checkIsAddressComplete (): boolean
  {
    debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.address )
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.address.firstName ) && this.address.firstName.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.address.lastName ) && this.address.lastName.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.address.addressStr ) && this.address.addressStr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.address.city ) && this.address.city.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.address.state ) && this.address.state.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.address.country ) && this.address.country.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.address.zipCode ) && this.address.zipCode.length > 0
    )
    {
      this.isAddressComplete = true;
      debugger;
    }
    else this.isAddressComplete = false;
    debugger;
    EmitterSubjectService.emitIsAddressComplete( this.isAddressComplete );
    return this.isAddressComplete;
  }
  // ---------------------------------------------------------------
  editAddress ()
  {
    this.isAddressComplete = false;
  }
  /*
   * ---------------------------------------------------------------
   * Note : dob is sent in yyyy/mm/dd format
   */
  calculateAge(dob: any): any {
    let age = 0;

    if (dob) {
      const now = new Date();
      const nowYear = now.getFullYear();
      const nowMonth = now.getMonth() + 1;
      const nowDay = now.getDate();
      const dobParts = dob.split('/');

      if (dobParts.length > 2) {
        age = nowYear - dobParts[0];
        if (nowMonth < dobParts[1] && age > 0) {
          age--;
        }
        if (nowMonth === dobParts[1] && nowDay < dobParts[2] && age > 0) {
          age--;
        }
      }
    }
    return age;
  }
  /*
   * ---------------------------------------------------------------
   * ref : https:// stackoverflow.com/questions/22982647/how-to-set-an-input-field-to-ng-invalid-in-angularjs
   */
  determineAge(dob: any): any {
    if (dob) {
      const ymd = dob.replace(/-/g, '/'); // tranaform the dob from yyyy-mm-dd to yyyy/mm/dd form

      if (ymd) {
        // debugger;
        this.age = this.calculateAge(ymd); // profileFactory.calculateAge() expects input in yyyy/mm/dd format
        this.register.age = this.age;
        if (this.age >= 18) {
          this.register.dob = ymd;
          this.register.dobTicks = DateStringServiceStatic.getJsDateMinusJsOffsetToNetTicks(new Date(dob));
        } else {
          if (this.register.dob.length > 5) {
            this.isDobErr = true;
            this.register.message = this.getDisclaimerAgeAccountMessage(this.age);
          }
        }
        return this.register;
      } else if (this.register.dob.length > 5) {
        this.isDobErr = true;
      }
    } else if (this.register.dob.length > 5) {
      this.isDobErr = true;
    }
  }
  // ---------------------------------------------------------------
  getDisclaimerAgeAccountMessage(age: number) {
    let yourAgeMsg = '';
    let disaclaimerMsg = '';

    this.accountMessages.map((e: any) => {
      if (e.Key.toLowercase().indexOf('yourAge') !== -1) {
        yourAgeMsg = e.Value;
      }
      if (e.Key.toLowercase().indexOf('disclaimerAge') !== -1) {
        disaclaimerMsg = e.Value;
      }
    });
    this.msg = ' ' + yourAgeMsg + age + '. ' + disaclaimerMsg;
    return this.msg;
  }
  // ---------------------------------------------------------------------------------
  setMessageTimer() {
    this.intervalMsg = setInterval(() => {
      this.message = '';
      this.httpService.navigateTo('/login');
      clearInterval(this.intervalMsg);
    }, 30000);

    clearInterval(this.intervalMsg);
  }
  // ---------------------------------------------------------------
}
