<!--NOTE: this section of the UI is NOT TO BE Changed anyway in the future!! It will display key-value paired data adjacent to each other in the center.-->
  <div id="keyValueAnyTemplateComponentId" *ngFor="let kvAny of arrKvAny; let r=index" style="width:100%;font-size:0.6rem;padding:0;margin:0;">
    <div *ngIf="kvAny.value" style="width:95%;float:left;">
      <div class="increase">
        <div class="kvxxSk" style="width:40%;float:left;text-align:right;">
          {{kvAny.id}}) {{kvAny.key}}:::
        </div>
        <div *ngIf="!isImageValue(kvAny) && !isArray(kvAny?.value)" class="kvxxSv" style="width:55%;float:right;text-align:left;text-transform:capitalize;">
          {{kvAny.value}}
        </div>
        <div *ngIf="!isImageValue(kvAny) && isArray(kvAny?.value)" class="kvxxSv" style="width:55%;float:right;text-align:left;text-transform:capitalize;">
          "Array Data Below:"
        </div>
        <div *ngIf="isImageValue(kvAny)" class="kvxxSv" style="width:55%;float:right;text-align:left;text-transform:capitalize;">
          <img [src]="kvAny.value | safe: 'pic'" style="height:9rem; width: 9rem;" class="img-responsive img-rounded" />
        </div>
      </div>
    </div>
    <div *ngIf="kvAny.value ||  isArray(kvAny.value)" style="display:inline-block;width:100%;padding:0 0 0 1rem;margin:0.2rem;border-bottom:1.5px solid silver;"></div>
    <!--if array-->
    <div *ngIf="isArray(kvAny.value)">
      <app-key-value-any-template [arrKvAny]="kvAny.value"></app-key-value-any-template>
    </div>
  </div>
