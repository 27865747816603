
import { Injectable, InjectionToken } from '@angular/core';
// import { Idle } from '@ng-idle/core';
// import { Keepalive } from '@ng-idle/keepalive';
// import { DBConfig, NgxIndexedDBService } from 'ngx-indexed-db';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from './stringServiceStatic.service';

// export const NGXSERVICE_TOKEN = new InjectionToken<NgxIndexedDBService>('NgxIndexedDBService'); // custon-injector-token
// export const IDLE_TOKEN = new InjectionToken<Idle>('IdleToken'); // custon-injector-token
// export const KEEPALIVE_TOKEN = new InjectionToken<Keepalive>('KeepAliveToken'); // custon-injector-token

// ref:https:// www.npmjs.com/package/ngx-indexed-db
/* export const dbConfig: DBConfig = {
  name: 'SlakezIndexedDb',
  version: 1,
  objectStoresMeta: [
    {
      store: 'chatHistory', // uses BoxNonce model
      storeConfig: { keyPath: 'key', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'chatHistoryId', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },    
    // -------------------------------------------------------------------------
    // {
    //  store: 'DefaultPic', // uses BoxNonce model
    //  storeConfig: { keyPath: 'id', autoIncrement: false },
    //  storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
    //                 { name: 'key', keypath: 'key', options: { unique: true } } ],
    // },
    // -------------------------------------------------------------------------
    // For actual email tiles:
    
    {
      store: 'emailTile', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'emailTileId', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },    
    {
      store: 'enoteTile', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'enoteTileId', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'errorLog', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'kvPhoto', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'loginSuccess', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'myChat', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'myChatId', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
    store: 'myEmailTile', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                    { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
    store: 'myEmailTileId', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                    { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
    store: 'myNotification', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                    { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'myNotificationId', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
    storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                    { name: 'key', keypath: 'key', options: { unique: true } } ],
     },    
    {
      store: 'myMemberActivity', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'myMemberLockingActivity', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'myMemberStarTrackWinkActivity', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'photo', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'photoBn', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'preference', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    {
      store: 'profileInfo', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    // -------------------------------------------------------------------------
     {
      store: 'profilePics', // uses BoxNonce model
       storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    // -------------------------------------------------------------------------
    {
      store: 'profileTile', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    // -------------------------------------------------------------------------
    // Note: profileTiles are fetched using sitUserId, hence we save the sitUserIds
    //{
    //  store: 'ProfileTileIds', // uses BoxNonce model
    //  storeConfig: { keyPath: 'id', autoIncrement: false },
    //  storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
    //                 { name: 'key', keypath: 'key', options: { unique: true } } ],
    //},
    // -------------------------------------------------------------------------
    {
      store: 'sitUserId', // uses BoxNonce model
      storeConfig: { keyPath: 'key', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    // -------------------------------------------------------------------------
    {
      store: 'sitUser', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
                     { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    // -------------------------------------------------------------------------
    // {
    //  store: 'TempFile', // uses BoxNonce model
    //  storeConfig: { keyPath: 'id', autoIncrement: false },
    //  storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
    //                 { name: 'key', keypath: 'key', options: { unique: true } } ],
    // },
    // -------------------------------------------------------------------------
    // NOTE: intend to deprecate PhotoId store and photo store
    //       however, Photo
    // {
    //  store: 'PhotoId', // uses BoxNonce model
    //  storeConfig: { keyPath: 'id', autoIncrement: false },
    //  storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
    //  { name: 'key', keypath: 'key', options: { unique: true } } ],
    // },
    // -------------------------------------------------------------------------
    // {
    // store: 'Photo', // uses BoxNonce model
    // storeConfig: { keyPath: 'id', autoIncrement: false },
    // storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
    // { name: 'key', keypath: 'key', options: { unique: true } } ],
    // },
    // -------------------------------------------------------------------------
    // {
    //  store: 'PhotoBnId', // uses BoxNonce model
    //  storeConfig: { keyPath: 'id', autoIncrement: false },
    //  storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
    //                 { name: 'key', keypath: 'key', options: { unique: true } } ],
    // },
    // -------------------------------------------------------------------------
    
    {
      store: 'YourMemberActivity', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    // -------------------------------------------------------------------------
    {
      store: 'yourMemberLockingActivity', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    // -------------------------------------------------------------------------
    {
      store: 'yourMemberStarTrackWinkActivity', // uses BoxNonce model
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [ { name: 'bne', keypath: 'bne', options: { unique: true } },
      { name: 'key', keypath: 'key', options: { unique: true } } ],
    },
    // -------------------------------------------------------------------------
  ],
}; */

// const IndexedDbDelimiter = "<%&%>";
const dbName : string = "slakezDexieDb";
const version : number = 5;
const dbInitialVersion = 1;
const dbNewVersion = 4;

export enum ConstantEnums
{
  IndexedDbDelimiter = 0,
  IndexDbTiles = 1,
  IndexDbTileIds = 2,
  ServerTilesIndividually = 3,
  ServerTilesAll = 4,
}

export enum KEY_CODE
{
  UP_ARROW = 0,
  DOWN_ARROW = 0,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ENTER = 13
}

export enum MemberActionEnums
{
  buddy = 0,
  block = 1,
  dislike = 2,
  friend = 3,
  like = 4,
  lock = 5,
  inblock = 6,
  track = 7,
  unfriend = 8,
  unlock = 9,

  Buddy = 10,
  Block = 11,
  Dislike = 12,
  Friend = 13,
  Like = 14,
  Lock = 15,
  Track = 16,
  Unblock = 17,
  Unfriend = 18,
  Unlock = 19,

  buddies = 20,
  iblocked = 21,
  iliked = 22,
  ifriended = 23,
  istarred = 24,
  itracked = 25,
  iunlocked = 26,
  iwinked = 27,
  myfriends = 28,

}

export enum StoreNameEnum
{
  chatHistory = 0,
  chatHistoryId =1,
  defaultPic = 2,
  emailTileId = 3,
  emailTile = 4,
  enoteTile = 5,
  enoteTileId = 6,
  errorLog = 7,
  loginSuccess = 8,
  myChat = 9,
  myEmailTile = 10,
  myEmailTileId = 11,
  myMemberActivity = 12,
  myMemberLockingActivity = 13,
  myMemberStarTrackWinkActivity = 14,
  myNotification = 15,
  myNotificationId = 16,
  preference = 17,
  profileInfo = 18,
  profilePic = 19,
  profileTile = 20,
  profileTileId = 21,
  photo = 22,
  photoId = 23,
  photoBn = 24,
  photoBnId = 25,
  sitUser = 26,
  sitUserId = 31,
  tempFile = 27,
  yourMemberActivity = 28,
  yourMemberLockingActivity = 29,
  yourMemberStarTrackWinkActivity = 30,
}

@Injectable({ providedIn: 'any'})
export abstract class ConstantServiceStatic {
  static db : any;
  static dbName = 'SlakezIndexedDb';
  static version = 5;
  static initialVersion = 1;
  static newVersion = 6;
  static storeNames = [ 'chatHistory', 'chatHistoryId', 'emailTile', 'emailTileId',
    'enoteTile', 'enoteTileId', 'errorLog', 'eoginSuccess', 'myChat', 'myChatId', 'myEmailTile', 'myEmailTileId', 'myMemberActivity', 'myMemberLockingActivity',
    'myMemberStarTrackWinkActivity', 'myNotification', 'myNotificationId', 'preference', 'profileInfo', 'profilePic', 'profileTile',
    'profileTileId', 'photoBn', 'photoBnId', 'photo', 'photoId', 'sitUser', 'sitUserId', 'tempFile', 'yourMemberActivity', 'yourMemberLockingActivity',
    'yourMemberStarTrackWinkActivity'];
  public server = 'https:// localhost:44382/';
  public apiUrl = 'api/';
  public serverWithApiUrl = this.server + this.apiUrl;
  static encrDecrKey = 'HU9CQ9Ahrp9vHx0GFxIQkzE5NzE=';
  static loginSuccessCount = 0;

  static dbJsSchema : {
    name : 'SlakezIndexedDb',
    version : 2,
    initialVersion : 1,
    newVersion : 2,
    key : {
      keyPath : [ 'chatHistory', 'chatHistoryId', 'emailTile', 'emailTileId',
        'enoteTile', 'enoteTileId', 'errorLog', 'eoginSuccess', 'myChat', 'myChatId', 'myEmailTile', 'myEmailTileId', 'myMemberActivity', 'myMemberLockingActivity',
        'myMemberStarTrackWinkActivity', 'myNotification', 'myNotificationId', 'preference', 'profileInfo', 'profilePic', 'profileTile',
        'profileTileId', 'photoBn', 'photoBnId', 'photo', 'photoId', 'sitUser', 'sitUserId', 'tempFile', 'yourMemberActivity', 'yourMemberLockingActivity',
        'yourMemberStarTrackWinkActivity' ]
    },
    indexes : {
      name : {
        keyPath : [ 'id' ]
      }

    }
  };
  
  //static chatHistoryObjectStore : any;
  //static chatHistoryIdObjectStore : any;

  //static emailTileIdObjectStore : any;
  //static emailTileObjectStore : any;

  //static enoteTileIdObjectStore : any;
  //static enoteTileObjectStore : any;

  //static errorLogObjectStore : any;
  //static kvPhotoObjectStore : any;
  //static loginSuccessObjectStore : any;

  //static myBlockObjectStore : any;

  //static myChatIdObjectStore : any;
  //static myChatObjectStore : any;

  //static myEmailIdObjectStore : any;
  //static myEmailObjectStore : any;

  //static myFriendObjectStore : any;
  //static myLikeObjectStore : any;

  //static myMemberActivityObjectStore : any;
  //static myMemberLockingActivityObjectStore : any;
  //static myMemberStarTrackWinkActivityObjectStore : any;
  //static myStarObjectStore : any;
  //static myTrackObjectStore : any;
  //static myWinkObjectStore : any;
  //static myUnlockObjectStore : any;
  //static myNotificationIdObjectStore : any;
  //static myNotificationObjectStore : any;

  //static photoObjectStore : any;
  //static photoBnIdObjectStore : any;
  //static photoBnObjectStore : any;

  //static preferenceObjectStore : any;
  //static profileTileObjectStore : any;
  //static profileInfoObjectStore : any;
  //static profilePicsObjectStore : any;

  //static yourBlockObjectStore : any;
  //static yourFriendObjectStore : any;
  //static yourLikeObjectStore : any;

  //static yourMemberActivityObjectStore : any;
  //static yourMemberLockingActivityObjectStore : any;
  //static yourMemberStarTrackWinkActivityObjectStore : any;
  //static yourStarObjectStore : any;
  //static yourTrackObjectStore : any;
  //static yourWinkObjectStore : any;
  //static yourUnlockObjectStore : any;




  // ---------------------------------------------------------------------------
  // Note:  The following two lines is for a create-promise-template that
  //        does not casue errors of delay by certain time:
  // ---------------------------------------------------------------------------
 static resolvedPromise = typeof Promise == 'undefined' ? null : Promise.resolve();
 static nextTick = ConstantServiceStatic.resolvedPromise ? function (fn : any)
  {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ConstantServiceStatic.resolvedPromise))
    {
      ConstantServiceStatic.resolvedPromise?.then(fn);
    }
 } : function (fn : any) { let timer = setTimeout(fn); clearTimeout(this.timer);};
  // ---------------------------------------------------------------------------
  //  Usage:
  //  ------
  //  nextTick(function() {
  //      your code...
  //  });
  // ---------------------------------------------------------------------------
  constructor () {
    // ConstantServiceStatic.createObjectStoresInDB(ConstantServiceStatic.db)
  }
  // ---------------------------------------------------------------------------

  // static getDbConfig(): DBConfig {
  //  return dbConfig;
  // }
  // static getKeyCode () : KEY_CODE
  // {
  //  return KEY_CODE;
  // }
  static getConstantEnums () : any {
    return ConstantEnums;
  }


  // -----------------------------------------------------
  // ref: https://stackoverflow.com/questions/36016862/indexeddb-dataerror-data-provided-to-an-operation-does-not-meet-requirements
  // Create object store in indexedDB:
  // ---------------------------------
  //static createObjectStoresInDB (db) : any {
  //  // this.db.version(ConstantServiceStatic.version = 2);
  //  // example: static s4 = db.createObjectStore('s4', { keyPath: 'id', autoIncrement: true });
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(db)) {
  //    this.chatHistoryObjectStore = db.createObjectStore('chatHistoryObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.chatHistoryIdObjectStore = db.createObjectStore('chatHistoryIdObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.emailTileIdObjectStore = db.createObjectStore('emailTileIdObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.emailTileObjectStore = db.createObjectStore('emailTileObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.enoteTileIdObjectStore = db.createObjectStore('enoteTileIdObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.enoteTileObjectStore = db.createObjectStore('enoteTileObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.errorLogObjectStore = db.createObjectStore('errorLogObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.kvPhotoObjectStore = db.createObjectStore('kvPhotoObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.loginSuccessObjectStore = db.createObjectStore('loginSuccessObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.myBlockObjectStore = db.createObjectStore('myBlockObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.myChatIdObjectStore = db.createObjectStore('myChatIdObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myChatObjectStore = db.createObjectStore('myChatObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.myEmailIdObjectStore = db.createObjectStore('myEmailIdObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myEmailObjectStore = db.createObjectStore('myEmailObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.myFriendObjectStore = db.createObjectStore('myFriendObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myLikeObjectStore = db.createObjectStore('myLikeObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.myMemberActivityObjectStore = db.createObjectStore('myMemberActivityObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myMemberLockingActivityObjectStore = db.createObjectStore('myMemberLockingActivityObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myMemberStarTrackWinkActivityObjectStore = db.createObjectStore('myMemberStarTrackWinkActivityObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myStarObjectStore = db.createObjectStore('myStarObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myTrackObjectStore = db.createObjectStore('myTrackObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myWinkObjectStore = db.createObjectStore('myWinkObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myUnlockObjectStore = db.createObjectStore('myUnlockObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myNotificationIdObjectStore = db.createObjectStore('myNotificationIdObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.myNotificationObjectStore = db.createObjectStore('myNotificationObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.photoObjectStore = db.createObjectStore('photoObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.photoBnIdObjectStore = db.createObjectStore('photoBnIdObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.photoBnObjectStore = db.createObjectStore('photoBnObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.preferenceObjectStore = db.createObjectStore('preferenceObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.profileTileObjectStore = db.createObjectStore('profileTileObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.profileInfoObjectStore = db.createObjectStore('profileInfoObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.profilePicsObjectStore = db.createObjectStore('profilePicsObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.yourBlockObjectStore = db.createObjectStore('yourBlockObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.yourFriendObjectStore = db.createObjectStore('yourFriendObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.yourLikeObjectStore = db.createObjectStore('yourLikeObjectStore', { keyPath: 'id', autoIncrement: false });

  //    this.yourMemberActivityObjectStore = db.createObjectStore('yourMemberActivityObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.yourMemberLockingActivityObjectStore = db.createObjectStore('yourMemberLockingActivityObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.yourMemberStarTrackWinkActivityObjectStore = db.createObjectStore('yourMemberStarTrackWinkActivityObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.yourStarObjectStore = db.createObjectStore('yourStarObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.yourTrackObjectStore = db.createObjectStore('yourTrackObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.yourWinkObjectStore = db.createObjectStore('yourWinkObjectStore', { keyPath: 'id', autoIncrement: false });
  //    this.yourUnlockObjectStore = db.createObjectStore('yourUnlockObjectStore', { keyPath: 'id', autoIncrement: false });
  //  }
  //}

}

export interface AssetGroup
{
  name: string;
  installMode: 'prefetch' | 'lazy';
  updateMode: 'prefetch' | 'lazy';
  resources: {
    files: string[];
    urls: string[];
  };
}

export interface DataGroup
{
  name: string;
  urls: string[];
  version?: number;
  cacheConfig: {
    maxSize: number;
    maxAge: string;
    timeout?: string;
    strategy?: 'freshness' | 'performance';
  };
}
