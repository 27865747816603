
import { EventEmitter, Injectable, Output } from '@angular/core';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';


@Injectable({ providedIn: 'root' })
export class BinarySearchService {
  @Output() productChange: EventEmitter<boolean> = new EventEmitter();
  // private loginSuccess: LoginSuccess = new LoginSuccess();
  protected bIndex = 0;
  protected eIndex = 0;
  protected middle = 0;
  constructor() {
  }

  // Note: Needs to be tested/redone
  // -------------------------------
  public binarySearch(arr: any[], beginIndex: number, mid: number, endIndex: number, id: number): any {
    if (arr.length > 0 && beginIndex > -1 && endIndex > -1 && id) {
      this.bIndex = beginIndex;
      this.eIndex = endIndex;
      let midCeil = Math.ceil(mid);
      if (midCeil > beginIndex && midCeil < endIndex) { // find the middle of the right half; will happen only after first-pass
        this.middle = Math.ceil(Math.round(beginIndex === endIndex ? endIndex : ((endIndex + 1) / 2))); // NOTE: in case of 0.5 parseInt will return 1
      }
      else if (midCeil > endIndex) { // find the middle of the right half; will happen only after first-pass
        this.middle = Math.round(endIndex > beginIndex ? ((endIndex - beginIndex) / 2) + beginIndex : endIndex);
      }
      else this.middle = Math.ceil(Math.round(beginIndex === endIndex ? endIndex : ((endIndex + 1) / 2)));
      if (arr[ Math.ceil(this.middle) ] && arr[ Math.ceil(this.middle)].id === id) { // here 'id' should be replaced with the appropriate comparator type
        return Math.ceil(this.middle);
      }
      else if (arr[ Math.ceil(this.middle) ] && arr[ Math.ceil(this.middle)].id > id) { // similar to above 'id'
        this.eIndex = Math.ceil(this.middle) - 1; // to search the left-half
        if (this.eIndex >= this.bIndex) {
          return this.binarySearch(arr, this.bIndex, this.middle, this.eIndex, id); // search the left half
        }
      }
      else if (arr[ Math.ceil(this.middle) ] && arr[ Math.ceil(this.middle)].id < id) { // similar to above 'id'
        this.bIndex = Math.ceil(this.middle) + 1; // to search the right-half
        if (this.eIndex >= this.bIndex) {
          return this.binarySearch(arr, this.bIndex, this.middle, this.eIndex, id); // search the right half
        }
      }
      return -1;
    }
    return -1;
  }
  // ---------------------------------------------------------------
  // here 'id' should be replaced with the appropriate comparator type
  public isFound(arr: any[], el: any, compareFn): number {
    if (el && arr.length > 0) {
      // arr = this.sortAscending(arr, id);//It will assume a sorted input array
      // Now call the binarySearch //Note: this function if recursive
      //  return this.binarySearch(arr, 0, (arr.length - 1) / 2, arr.length - 1, id);
       return this.nonRecursiveBinarySearch(arr, el, compareFn);
    }
    return -1;
  }
  // ---------------------------------------------------------------
  public sortAscending(items: any[]) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(items) && items.length > 0) {
      items
        .sort((a: any, b: any) => {
          // return a.localCompare(b)
          if (a && a.id && b && b.id) return a.id - b.id;  // sort it in ascending order
          else return a.localeCompare(b);
        });
      // debugger;
    }
    return items;

  }
  // ---------------------------------------------------------------
  public sortDescending(items: any[]) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(items) && items.length > 0) {
      items
        .sort((a: any, b: any) => {
          if (a && a.id && b && b.id) return b.id - a.id; // sort it in descending order
          else return b.localeCompare(a);
        });
      // debugger;
    }
    return items;
  }
  // ---------------------------------------------------------------
  public sort(items: any[]) {
    return this.sortAscending(items);
  }
  // ---------------------------------------------------------------
  public compare_fn(a: any, b: any): number {
    return a.id - b.id;
  }
  // ---------------------------------------------------------------
  public compare_sitUserId (a : any, el : number) : number {
    let cmpr;
    if (a.sitUserId === el) {
      cmpr = 0;
    }
    else if (a.sitUserId > el) {
      cmpr = 1;
    }
    else cmpr = -1;
    return cmpr;
  }
  
  // ---------------------------------------------------------------
  // Note: did not return the correct value for compare_sitUserId
  // ---------------------------------------------------------------
  // Ref:https://stackoverflow.com/questions/22697936/binary-search-in-javascript
  public nonRecursiveBinarySearch(ar: any[], el: any, compare_fn: any): any {
    var m = 0;
    var n = ar.length - 1;
    while (m <= n) {
      var k = (n + m) >> 1;
      var cmp = compare_fn;
      if (cmp > 0) {
        m = k + 1;
      } else if (cmp < 0) {
        n = k - 1;
      } else {
        return k;
      }
    }
    return -m - 1;
  }
  // ---------------------------------------------------------------
  // Ref:https://stackoverflow.com/questions/22697936/binary-search-in-javascript
  public nonRecursiveBinarySearchProfileTile (ar : any[], el : any) : any {
    var m = 0;
    var n = ar.length - 1;
    let k = (n + m) >> 1;
    var cmpr;
    if (ar) {
     if( ar[ k ].sitUserId === el) {
        cmpr = 0;
      }
      else if (ar[ k ].sitUserId < el) {
        cmpr = 1;
      }
     else cmpr = -1;
    }
    else cmpr = -1;

    while (m <= n) {
      let k = (n + m) >> 1;
      if (ar[ k ].sitUserId === el) {
        cmpr = 0;
      }
      else if (ar[ k ].sitUserId < el) {
        cmpr = 1;
      }
      else cmpr = -1;

      if (cmpr > 0) {
        m = k + 1;
      } else if (cmpr < 0) {
        n = k - 1;
      } else {
        return k;
      }     
    }
    return -m - 1;
  }
  // ---------------------------------------------------------------
}
