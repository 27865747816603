export class ModalModel {
  public message = '';
  public date = '';
  public sitUserId = 0;
  public inputs: any;
  public outputs: any;
  public componentName = '';
  public parent = '';
  public isModalVisible = false;
  constructor() { }
}
