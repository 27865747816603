
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { PreferenceServiceInterface } from '../../interfaces/serviceInterfaces/profileServiceInterfaces/preferenceServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Communication } from '../../models/communication/communication.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { MemberViewMgmtModel } from '../../models/member/memberViewMgmtModel.model';
import { Preference } from '../../models/profile/preference.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { KvArrayService } from '../coreServiceService/kvArrayService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { SitUser } from '../../models/account/sitUser.model';

@Injectable({
  providedIn: 'root',
} )

// ------------------------------------------------------------------------------------------------
// Disclaimer:  this service accomplishes all activities regarding signedInUser's ProfilePics only,
//              i.e. creating the profilePics for the signedInUser (1st  person user), and all of
//              the activities regarding managing the profilePics such as making a photo private
//              if it is public and vice versa, and generating the PhotoMgmtNavigation buttons.
// ------------------------------------------------------------------------------------------------
export class PreferenceService implements OnDestroy, PreferenceServiceInterface {
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isOnLine = false; 
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public memberViewMgmtModel : MemberViewMgmtModel = new MemberViewMgmtModel();
  public message = '';
  public preference : Preference = new Preference();
  public preferenceKvAnyArr : KvAny[] = [];

  constructor (
    public dictionaryService : DictionaryService,
    public httpService : HttpService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public kvArrayService : KvArrayService,
  )
  {    
    // -----------------------------------------------------------
    this.initialize();
  }
  // ------------------------------------------------------------
  initialize(): void {
    // debugger;
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.memberViewMgmtModel = EmitterSubjectService.getMemberViewMgmtModel();
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.preference = new Preference();
    return true;
  }
  // ---------------------------------------------------------------
  public executePreferenceTasks (situserId : number) : Observable<any> {
    let sitUserId : number = situserId;
    return new Observable<any>((subscriber) => {
      if (sitUserId > 0) {
        // debugger;
        let tPref : any;
        tPref = this.dictionaryService.preferenceDictionary.get(sitUserId);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPref)) {
          this.preference = tPref;
          // debugger;
          this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);

          // debugger;
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel) || this.memberViewMgmtModel.sitUserId === 0) {
            this.memberViewMgmtModel = new MemberViewMgmtModel();
          }
          this.memberViewMgmtModel.populateModelData(this.preference, this.preferenceKvAnyArr);
          EmitterSubjectService.setMemberViewMgmtModel(this.memberViewMgmtModel);

          EmitterSubjectService.emitPreferenceKvAnyArr(this.preferenceKvAnyArr);
          subscriber.next(this.preferenceKvAnyArr);
          subscriber.complete();
        }
        else {
          this.getPreference(sitUserId).subscribe(data => {
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
              this.preference = data;
              // debugger;
              this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);
              
              // debugger;
              this.memberViewMgmtModel.populateModelData(this.preference, this.preferenceKvAnyArr);

              EmitterSubjectService.emitPreferenceKvAnyArr(this.preferenceKvAnyArr);
              EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);

              subscriber.next(this.preferenceKvAnyArr);
              subscriber.complete();
            }
          })
        }
      }
    })
  }
  // ---------------------------------------------------------------
  getPreferenceFromDictionary (sitUserId : number) : KvAny[] {
    if (sitUserId > 0) {
      // debugger;
      let tPref : any;
      tPref = this.dictionaryService.preferenceDictionary.get(sitUserId);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPref)) {
        this.preference = tPref;
        // debugger;
        this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);

        EmitterSubjectService.emitPreferenceKvAnyArr(this.preferenceKvAnyArr);
        return this.preferenceKvAnyArr;
      }
    }
  }
  // ---------------------------------------------------------------
  public getPreference (situserid : number) : Observable<any> {
    let sitUserId : number = situserid;
    return Observable.create((observer : any) => {
      if (sitUserId > 0) {
        // debugger;
        let sitUser = new SitUser();

        this.loginSuccess = EmitterSubjectService.getLoginSuccess();

        if (this.loginSuccess.signedInUserId > 0) {
          sitUser.sitUserId = sitUserId;
          sitUser.signedInUserId = this.loginSuccess.signedInUserId;
          this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(sitUser);
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            this.httpService.postObservable('/api//Member/GetPreference', {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            }, 'json2text').subscribe(result => {
              // debugger;
              
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result))
              {
                this.preference = result as Preference;

                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference))
                {
                  // debugger;
                  this.dictionaryService.updateDictionary(this.preference, 'preference', sitUserId);                   

                  this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);
                  // debugger;
                  EmitterSubjectService.emitPreferenceKvAnyArr(this.preferenceKvAnyArr);
                  // debugger;
                  observer.next(this.preference);
                  observer.complete();
                }
                else {
                  // debugger;
                  this.message = 'Error occured after unsalting result in GetPreference(profileId:\'' + sitUserId + '\');\n unsalted-preference: ' + this.preference;
                  console.log(this.message);
                }
              } else {
                // debugger;
                this.message = 'unsalted preference (profileId:\'' + sitUserId + '\'); was null or empty.';
                console.log(this.message);
              }              
            }, (error) => {
              // debugger;
              this.message = 'Error occured in GetPreference(profileId:\'' + sitUserId + '\');\n Error-mag: ' + error.message;  
              console.log(this.message);
            });
          }          
        }       
      }      
    })
  }
  // ---------------------------------------------------------------
  public processPreferenceResult (sitUserId : number, result : any) : any {
    let bn : BoxNonceEntity = new BoxNonceEntity();
    if (result) {
      bn.box = result.box;
      bn.nonce = result.nonce;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bn)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bn.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bn.nonce)
        && bn.box.length > 0 && bn.nonce.length > 0) {
        //  debugger;
        this.preference = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Preference;
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference)) {         
          // debugger;
          this.dictionaryService.updateDictionary(this.preference, 'preference', sitUserId);

          this.dbDexieToDictionaryService
            .saveDictionaryToDbDexie(
              this.dictionaryService.preferenceDictionary, 'preference', this.loginSuccess.signedInUserId);
          // debugger;

          this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);          
          // debugger;
          return this.preference;
        }
      }
    }
    else {
      this.message = 'Error occured in GetPreference(sitUserId:\'' + sitUserId + '\');\n result: ' + result;
      return null;
    }
  }  
  // ==================================================================
}
