'use strict'
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Counters } from '../../models/common/counters.model';
import { Photo } from '../../models/common/photo.model';
import { PhotoBn } from '../../models/common/photoBn.model';
import { PhotoOmniModel } from '../../models/common/photoOmni.model';
import { Communication } from '../../models/communication/communication.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { ProfileImage } from '../../models/profile/profileImage.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { SignedInUserService } from '../commonServiceService/signedInUserService.service';
import { SitUserService } from '../commonServiceService/sitUserService.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
import { PhotoPrivacyService } from './photoPrivacyService.service';
import { camelCase } from 'lodash';


@Injectable({
  providedIn: 'root',
})

// ------------------------------------------------------------------------------------------------
// Disclaimer:  this service accomplishes all activities regarding PhotoBns only,
//              the photoBn is a model in which user's info as well as a box and nonce value of
//              encrypted( salted) photo.
//               
//              this service will contain all the methods for getting photoBns from indexedDb,
//              have them available to photoBnDictionary with photo being unsalted and ready for display.
// ------------------------------------------------------------------------------------------------
export class PhotoBnService implements OnDestroy {
  public signedInUserService! : SignedInUserService;
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public counters : Counters = new Counters();
  public currentBn : BoxNonceEntity = new BoxNonceEntity();
  public currentKvPhoto : KvPhoto = new KvPhoto();
  public currentStateOfPhoto : Photo = new Photo();
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public filteredKvPhotoArr : KvPhoto[] = [];
  public isBlocked = false;
  public isBuddy = false;
  public isFriend = false;
  public isIndexedDbData = true;
  public isLiked = false;
  public isMobilevar = false;
  public isMyProfile = false;
  public isOnLine = false;
  public isPhotoMgmt = false;
  public isPrivate = false;
  public isToggleCamera = false;
  public isBlockedByDictionaryData = false;
  public isBlockedByServerData = false;
  public isFriendByDictionaryData = false;
  public isFriendByServerData = false;
  public isLikedByDictionaryData = false;
  public isLikedByServerData = false;
  public isUnlockedByDictionaryData = false;
  public isUnlockedByServerData = false;
  public isViewMember = false;
  public isWaitingOnCurrStateOfUser = false;
  public kvPhoto : KvPhoto = new KvPhoto();
  public fKvPhoto : KvPhoto = new KvPhoto();
  public kvPhotoArr : KvPhoto[] = [];
  public kvPhotoLock : KvPhoto = new KvPhoto();
  public kvPhotoUnLock : KvPhoto = new KvPhoto();
  public kvPhotoPrivate : KvPhoto = new KvPhoto();
  public kvPhotoPublic : KvPhoto = new KvPhoto();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public photoOmni : PhotoOmniModel = new PhotoOmniModel();
  public primaryKvPhoto : KvPhoto = new KvPhoto();
  public profileImageArr : ProfileImage[] = [];
  public profileImageKvPhotos : KvPhoto[] = [];
  public profilePics : ProfilePics = new ProfilePics();
  public profilePicsArr : ProfilePics[] = [];
  public profilePicsBoxNonceArr : BoxNonceEntity[] = [];
  // will contain member-selection of KvPhoto as final state of currentStateOfPhoto
  public selectedKvPhoto : KvPhoto = new KvPhoto();
  public sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();
  public timer : any;
  public timerArray : any[] = [];
  public unsaltedIndexedDbData : any;
  public userPhotoBnIds : number[] = [];
  public userPhotos : Photo[] = [];
  public unFilteredKvPhotoArr : KvPhoto[] = [];

  constructor (
    public dictionaryService : DictionaryService,
    public httpService : HttpService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public photoPrivacyService : PhotoPrivacyService,
    public sitUserService : SitUserService,
  ) {
    this.initialize();
  }
  // ------------------------------------------------------------
  protected initialize () : void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.getSingedInAndNonSignedInSitUsers(0);
  }
  // ---------------------------------------------------------------
  public ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.profilePics = new ProfilePics(); // clear the profilePics on exit
    this.profileImageArr = [];
    this.timerArray.forEach((timer) => clearInterval(timer));
    return true;
  }
  // ---------------------------------------------------------------------------------
  public clearCounters () : Promise<any> {
    this.counters = new Counters();
    return new Promise((resolve) => {
      resolve(this.counters);
    });
  }
  // ---------------------------------------------------------------------------------
  //  remove before deployment
  // ---------------------------------------------------------------------------------
  // public createPhotoDictionaryFromPhotoBnDictionary () : any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dictionaryService.photoBnDictionary)) {
  //    if (this.dictionaryService.photoBnDictionary.size() > 0) {
  //      let photoBnArr = this.dictionaryService.photoBnDictionary.values();
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBnArr) && photoBnArr.length > 0) {

  //        for (var pbn of photoBnArr) {
  //          if (pbn.sitUserId > 0 && pbn.photoBnId > 0) {
  //            this.photoOmni.kvPhoto = this.processPhotoBnResult(pbn.sitUserId, pbn, pbn.photoBnId);
  //            // TODO: finish this !!!
  //          }
  //        }
  //        return true;
  //      }
  //      else return false;
  //    }
  //    else return false;
  //  }
  //  else return false;
  // }
  // ---------------------------------------------------------------
  public creteProfilePicsUsingPhotoBnIdsFromDictionary (sitUserId : number) : any {
    this.profilePics = this.dictionaryService.profilePicsDictionary.get(sitUserId) as ProfilePics;
    let pbnArr : any[]
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)) {
      let profileImageArr = this.processServerSentPhotoBnArr(sitUserId, pbnArr)
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr) && profileImageArr.length > 0) {
        this.profilePics = CopyServiceStatic.createProfilePicsFromProfileImageArr(profileImageArr);
        // debugger;

        // NOTE:  since we cannot inject services,
        //        the caller of this method need to filter profileKvPhotoArr
        // -----------------------------------------------------------------
        // this.profilePics.filteredKvPhotoArr = this.photoPrivacyService.filterKvPhotoArr(this.profilePics.profileKvPhotoArr);
      }
      this.dictionaryService.profilePicsDictionary.set(sitUserId, this.profilePics);
    }
    return this.profilePics;
  }

  // ---------------------------------------------------------------
  //public createProfilePicsFromTupleKeyPhotoBnIdValuePhoto (kvAnyArr : KvAny[]) : any {
  //  let photoBn : PhotoBn = new PhotoBn();
  //  let profilePics = EmitterSubjectService.getProfilePics();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAnyArr) && kvAnyArr.length > 0) {
  //    for (var kv of kvAnyArr) {
  //      let index = ArrayServiceStatic.getIndexOfKvPhotoInKvPhotoArr(profilePics.profileKvPhotoArr, kv.value);
  //      if (index > -1) {
  //        let pPicsKvPhoto = profilePics.profileKvPhotoArr[ index ];

  //        profilePics.profileImageArr = this.memberService.addPhotoToProfileImageArr(pPicsKvPhoto.value, profilePics.profileImageArr);

  //        // Note: filter kvPhoto and filteredKvPhotoArr:
  //        // --------------------------------------------
  //        let fKvPhoto : any;
  //        this.photoPrivacyService.filterKvPhoto(pPicsKvPhoto).then(data => {
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //            if (typeof data === 'object') {              
  //              fKvPhoto = data
  //              profilePics.filteredKvPhotoArr.push(fKvPhoto);
  //            }
  //            else {
  //              console.log(data);
  //            }
  //          }
  //        });          
  //      }
  //    }
  //    EmitterSubjectService.setProfilePics(profilePics);
  //    return profilePics;
  //  }
  //  else return null;
  //}
  // ---------------------------------------------------------------
  public escapeQuoteFromPic (picData : string) : string {
    if (JsRegExpServiceStatic.escapeQuotePattern.test(picData)) {
      // debugger;
      picData = picData.replace(/\"/, '');
      // debugger;
    }
    return picData;
  }

  // ---------------------------------------------------------------
  public extractPhotoModelDataFromUnsalted (unsalted : any) : any {
    let tServerPhotoResult = unsalted; // JSON.parse(unsalted) as any;
    let pbnResult : PhotoBn = new PhotoBn();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tServerPhotoResult)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tServerPhotoResult.Image) && tServerPhotoResult.Image.length > 0) {
        pbnResult.image = tServerPhotoResult.Image; // original server-sent-data
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tServerPhotoResult.pic) && tServerPhotoResult.pic.length > 0) {
        // debugger;
        // tServerPhotoResult.pic may need to use normalizeImage... methods instead:
        // -------------------------------------------------------------------------
        // pbnResult.pic = JsRegExpServiceStatic.normalizeImageData(tServerPhotoResult.pic);

        pbnResult.pic = JsRegExpServiceStatic.normalizeImageDataWithRegExp(tServerPhotoResult.pic);
        // debugger;
        // Note: Experimantal: 20220527 : remove url from the pic:
        pbnResult.pic = JsRegExpServiceStatic.stripUrlForImageData(pbnResult.pic);
      }
      // may be necessary later on:
      // --------------------------
      if (pbnResult.photoId === 0 && tServerPhotoResult.photoId > 0) {
        pbnResult.photoId = tServerPhotoResult.photoId;
      }
      if (pbnResult.photoBnId === 0 && tServerPhotoResult.photoBnId > 0) {
        pbnResult.photoBnId = tServerPhotoResult.photoBnId;
      }
    }
    return pbnResult;
  }
  // -----------------------------------------------------------
  //public filterAndSortProfilePics (pPics : ProfilePics) : Promise<ProfilePics|any> {
  //  let fKvPhoto : any;
  //  return new Promise((resolve, reject) => {   
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.profileImageArr)
  //      && pPics.profileImageArr.length > 0) {
  //      pPics.profileKvPhotoArr = CopyServiceStatic.copyFromProfileImageArrToKvPhotoArr(pPics.profileImageArr);
  //      pPics.profileKvPhotoArr.map(pKv => {
  //        this.photoPrivacyService.filterKvPhoto(pKv).subscribe(data => {
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //            if (typeof data === 'object') {
  //              fKvPhoto = data
  //              pPics.filteredKvPhotoArr.push(fKvPhoto);

  //              resolve( pPics);
  //            }
  //            else {
  //              console.log(data);
  //              reject(data);
  //            }
  //          }
  //        })
  //      })
  //    }
  //  })
  //}
  // ----------------------------------------------------------------
  isPrivateAndUnlocked (kvp : KvPhoto) {
    var isPrivate = false;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      if (kvp.privacy.toLowerCase().indexOf('private') !== -1) {
        isPrivate = true;
      }
      return isPrivate && kvp.isUnlocked;
    }
    else return false;
  }
  // ----------------------------------------------------------------
  getPrimaryPicFromPhotoBn (pBn : PhotoBn) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.image) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.pic)) {
          pBn.pic = pBn.image;
          this.loginSuccess.primaryImage = pBn.image;
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.imageString)) {
            pBn.imageString = pBn.image
            // debugger;
          }
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.imageString) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.pic)) {
          pBn.pic = pBn.imageString;
          this.loginSuccess.primaryImage = pBn.imageString;
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.image)) {
            pBn.image = pBn.pic
            // debugger;
          }
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.pic) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.image)) {
          pBn.image = pBn.pic;
          this.loginSuccess.primaryImage = pBn.pic;
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.imageString)) {
            pBn.imageString = pBn.pic
            // debugger;
          }
        }
      }
      // debugger;
      return pBn.pic;
    }
  }
  // ----------------------------------------------------------------
  unsaltPrimaryPicPhotoBnJson (photoBn : PhotoBn) : any {
    let pic = '';
    var tPhotoBn : PhotoBn = new PhotoBn;
    var tPhotoBnJson : any;
    var kvPhoto : KvPhoto = null;
    let boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.box)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.nonce)
      && photoBn.box.length > 0 && photoBn.nonce.length > 0) {

      boxNonceEntity.box = photoBn.box;
      boxNonceEntity.nonce = photoBn.nonce;
      tPhotoBnJson = SlakezSaltServiceStatic.boxUnsalt(boxNonceEntity);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBnJson)) {

        tPhotoBn = JSON.parse(tPhotoBnJson);
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBn)
        && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBn.image)
          || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBn.imageString)
          || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBn.pic))) {
        kvPhoto = new PhotoBn().cloneToCamelCasedKvPhoto(tPhotoBn);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
          photoBn.pic = photoBn.image = photoBn.imageString = kvPhoto.value;
        }
        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.pic)) {
          photoBn.pic = photoBn.image = photoBn.imageString = kvPhoto.pic;
        }
        return photoBn;
      }
      else return null;
    }
    // debugger;

  }
  // ---------------------------------------------------------------
  public unsaltPhotoBnArr (pbnArr : any[]) : PhotoBn[] {
    var outPhotoBnArr : PhotoBn[] = [];
    let tPhotoBn : PhotoBn = new PhotoBn();
    try {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbnArr)) {

        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbnArr) && pbnArr.length > 0) {
          let i = 0;
          pbnArr.forEach(e => {
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
              // debugger;
              var bne = new BoxNonceEntity();
              bne.box = e.box;
              bne.nonce = e.nonce;
              try {
                var unsaltedUnknown = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bne as BoxNonceEntity)) as unknown;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsaltedUnknown)) {
                  // debugger;
                  tPhotoBn = new PhotoBn().toCamelCase(unsaltedUnknown);
                  tPhotoBn.photoBnId = e.photoBnId > 0 ? e.photoBnId : tPhotoBn.photoBnId;
                  tPhotoBn.photoId = e.photoId > 0 ? e.photoId : tPhotoBn.photoId;
                  outPhotoBnArr.push(tPhotoBn);
                }
              }
              catch {
                this.message = 'timestamp:  ' + new Date().getTime() + ' : in unsaltPhotoBnAr(pbnArr)-> pbnArr.index=' + i + ' has some corrupt box-data or nonce-data';
                console.log(this.message);
              }
              finally { }
              // debugger;
              i++;
            }
          })
        }
      }
    } catch (err) {
      // debugger;
      console.log(err)
    } finally {
      // debugger;
      //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBns) || tPhotoBns.length === 0) {
      //  tPhotoBns = JSON.parse(result) as PhotoBn[];
      //}
    }
    return outPhotoBnArr;
  }
  // ---------------------------------------------------------------
  public unsaltDelimiteredData (data : any) : PhotoBn[] {
    var outPhotoBnArr : PhotoBn[] = [];
    let tPhotoBn : PhotoBn = new PhotoBn();
    try {
      debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
        debugger;
        var pbnStrArr = data.split('%#%');
        debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbnStrArr) && pbnStrArr.length > 0) {
          let i = 0;
          pbnStrArr.forEach(e => {
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e) && e.length > 1) {
              debugger;
              tPhotoBn = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(JSON.parse(e) as BoxNonceEntity)) as PhotoBn;

              debugger;
              outPhotoBnArr.push(tPhotoBn);
              i++;
            }
          })
        }
      }
    } catch (err) {
      // debugger;
      console.log(err)
    } finally {
      // debugger;
      //if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBns) || tPhotoBns.length === 0) {
      //  tPhotoBns = JSON.parse(result) as PhotoBn[];
      //}
    }
    return outPhotoBnArr;
  }
  // ---------------------------------------------------------------
  // Note: This method is part of the preparing member view (pics and data)
  //       that gets the pictures of a user all at once as opposed to individually.
  // ---------------------------------------------------------------
  public getAllPhotosOfUser (sitUser : SitUser) : Observable<any> {
    // let clonedArr : any;
    // let index = 0;
    // let pPics = new ProfilePics();
    // let filteredKvPhotoArr : KvPhoto[] = [];
    // let unFilteredKvPhotoArr : KvPhoto[] = [];

    // this.isViewMember = EmitterSubjectService.getIsViewMember();
    // this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();

    let date = new Date();
    return new Observable((subscriber) => {
      // debugger;   
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0) {
        // pPics = CopyServicestatic.createProfilePicsModelFromSitUser(sitUser);
        debugger;
        this.getAllPhotosOfUserBySitUserIdObservable(sitUser.sitUserId).subscribe(result => {
          debugger;
          subscriber.next(result);
          subscriber.complete();
        })
      }
      else {
        console.log('getAllPhotosOfUser (sitUser.sitUserId: ' + sitUser.sitUserId + ') sitUser was null or sitUserId == 0.');
      }
    })//.catch((error) => {
    //  console.log(date.getTime() + ' getAllPhotosOfUser (sitUser.sitUserId: ' + sitUser.sitUserId + ') retrned a null promise.');
    //})
  }
  // -----------------------------------------------------------
  public getPrimaryPhoto (sitUserId : number) : Promise<any> {
    // debugger;
    let showSpinnerComp : any;
    const bnComm : Communication = new Communication();

    bnComm.sitUserId = sitUserId;
    this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)) {
      return new Promise((resolve, reject) => {
        // debugger;
        this.httpService.postObservable('/api/Photo/PostUserPrimaryPhoto',
          {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          },
          'json2text',
        ).subscribe((result) => {
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
            this.boxNonceEntity = new BoxNonceEntity();
            this.boxNonceEntity.box = result.box;
            this.boxNonceEntity.nonce = result.nonce;

            let tempPhoto : any;
            /*
              * do not directly assing to profileTile!! capture it in a let first
              */
            let unboxedPhoto = SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity);
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unboxedPhoto)) {
              tempPhoto = unboxedPhoto as Photo;
            }

            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempPhoto)) {
              tempPhoto = JSON.parse(tempPhoto); // convert string to model
              // debugger;
            }

            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempPhoto)) {
              resolve(tempPhoto);
            }
          }
          else {
            reject(null); //message for result being null 
          }
        })
      });
    }
    else {
      return FrequentlyUsedFunctionsServiceStatic.nullPromise();
    }
  }
  // ---------------------------------------------------------------
  //  Note: this method is used by PhotoMgmtComponent:
  //  Option: observable-to-promise
  //
  //  Important!!
  //  -----------
  //  There are two ways the server can send the data to the client here:
  //  1. an Array of PhotoBn is directly sent to the client.
  //  2. the array/list of PhotoBn is encrypted and then sent (double encryption)
  // 
  //  This version is transformed for the former, i.e. (1) 
  //  The previous version (0.8.6) is transformed for latter (2)
  // ---------------------------------------------------------------
  public getAllPhotosOfUserBySitUserIdObservable (siTUserId : number) : Observable<any> {

    let scopedDictionaryService = this.dictionaryService;
    let sitUserId : number = siTUserId;
    this.profilePics = new ProfilePics();
    this.profilePics.sitUserId = siTUserId;
    let tSitUserId = siTUserId;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (sitUserId > 0) {
      this.sitUser = scopedDictionaryService.sitUserDictionary.get(sitUserId) as SitUser;
      this.profilePics.sitUserId = sitUserId;
    }
    return new Observable<any>((subscriber) => {
      let userPhotoBnCount = 0;
      let filteredKvPhotoArr : any[] = [];
      let unFilteredKvPhotoArr : any[] = [];
      let tPhotoBns : PhotoBn[] = []

      // debugger;
      this.boxNonceEntity = this.prepareToBeSentModelForGetAllPhotoBnOfUser(sitUserId);
      EmitterSubjectService.emitShowSpinner('Loading pics....');
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        // debugger;
        this.httpService.postObservable('/api/Photo/GetAllPhotoBnOfUser',
          {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          },
          'json2text',
        ).subscribe((result) => {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
            // debugger;              
            try {
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                tPhotoBns = result as [ PhotoBn ]

                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBns) && tPhotoBns.length > 0)
                {
                  // debugger;

                  scopedDictionaryService.resetDictionary('unFilteredKvPhoto');

                  for (var i = 0; i < tPhotoBns.length; i++)
                  {
                     // debugger;

                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBns[ i ])) {
                      // debugger;
                      let tPhotoBn = tPhotoBns[ i ];
                      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPhotoBn)) {
                        this.photoOmni = this.processPhotoBnResult(sitUserId, tPhotoBn, tPhotoBn.photoBnId) as PhotoOmniModel;

                        // debugger;

                        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoOmni)) {
                          if (tPhotoBn.photoBnId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoOmni.photoBn)) {
                            if ((FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoOmni.photoBn.photoBnId) || this.photoOmni.photoBn.photoBnId === 0)
                              || (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoOmni.kvPhoto.photoBnId) || this.photoOmni.kvPhoto.photoBnId === 0)) {
                              this.photoOmni.photoBn.photoBnId = tPhotoBn.photoBnId;
                              this.photoOmni.kvPhoto.photoBnId = tPhotoBn.photoBnId;
                            }
                            if ((FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoOmni.photoBn.photoId) || this.photoOmni.photoBn.photoId === 0)
                              || (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoOmni.kvPhoto.photoId) || this.photoOmni.kvPhoto.photoId === 0)) {
                              this.photoOmni.photoBn.photoId = tPhotoBn.photoId;
                              this.photoOmni.kvPhoto.photoId = tPhotoBn.photoId;
                              // debugger;
                            }
                          }
                          this.saveKvPhoto(this.photoOmni);
                          // debugger;
                          this.profilePics.unFilteredKvPhotoArr.push(this.photoOmni.kvPhoto);
                        }
                      } // end of if this.photoOmni != null)
                    }
                  } // end of for-loop
                  // debugger;                  
                }
                else {
                  // debugger;
                  console.log("error occured while getting data for 'getAllPhotosOfUserBySitUserIdObservable.GetAllPhotoBnOfUser' of sitUserId: ");
                }
              }
            }
            catch (e) {
              // debugger;
              console.log(e)
            }
            finally {
              this.loginSuccess = EmitterSubjectService.getLoginSuccess();

              // debugger;
              this.profilePics.populateDictionaries();
              this.dictionaryService.updateDictionary(this.profilePics, 'profilePics', this.profilePics.sitUserId);
              // debugger;
              this.dbDexieToDictionaryService
                .saveDictionaryToDbDexie(
                  this.dictionaryService.profilePicsDictionary, 'profilePics', this.loginSuccess.signedInUserId);

              // debugger;
              
              //if (this.dictionaryService.kvPhotoDictionary.size > 0) {
              //  // debugger;
              //  this.dbDexieToDictionaryService
              //    .saveDictionaryToDbDexie(
              //      scopedDictionaryService.kvPhotoDictionary,
              //      "kvPhoto",
              //      this.loginSuccess.signedInUserId);
              //  // debugger;

              //}
              // save to dictioanry and indexedDb:
              // ---------------------------------
              if (this.dictionaryService.userPhotoBnIdDictionary.size > 0) {
                this.dbDexieToDictionaryService
                  .saveDictionaryToDbDexie(
                    scopedDictionaryService.userPhotoBnIdDictionary,
                    "photoBnId",
                    this.loginSuccess.signedInUserId);
                // debugger;
              }
              //if (this.dictionaryService.photoBnDictionary.size > 0) {
              //  this.dbDexieToDictionaryService
              //    .saveDictionaryToDbDexie(
              //      scopedDictionaryService.photoBnDictionary,
              //      "photoBn",
              //      this.loginSuccess.signedInUserId);
              //  // debugger;
              //}
            }
            subscriber.next(this.profilePics);

            subscriber.complete();
            EmitterSubjectService.emitHideSpinner('');
          }
          
        })
      }
      else {
        console.log("error occured before sending data for 'GgetAllPhotosOfUserBySitUserIdObservable.getAllPhotoBnOfUser' of sitUserId: ");
        return null;
      }
    })
  }
  // ---------------------------------------------------------------------------------
  public saveKvPhoto (pom : PhotoOmniModel) : any {
    let scopedDictionaryService = this.dictionaryService;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pom)) {
      scopedDictionaryService.updateDictionary(pom.kvPhoto, 'unFilteredKvPhoto', pom.kvPhoto.photoBnId);
      // debugger;

      //if (!this.dictionaryService.kvPhotoDictionary.has(pom.kvPhoto.photoBnId)) {
      //  scopedDictionaryService.updateDictionary(pom.kvPhoto, "kvPhoto", pom.kvPhoto.photoBnId);
      //  // debugger;
      //}

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pom.photoBn) && pom.photoBn.photoBnId > 0) {
        // debugger;
        if (!this.dictionaryService.photoBnDictionary.has(pom.kvPhoto.photoBnId)) {
          scopedDictionaryService.updateDictionary(pom.photoBn, 'photoBn', pom.photoBn.photoBnId);
        }
        if (!this.dictionaryService.userPhotoBnIdDictionary.has(pom.kvPhoto.photoBnId)) {
          scopedDictionaryService.updateDictionary(pom.photoBn.photoBnId, 'photoBnId', pom.photoBn.photoBnId);
        }
      }      
      return true;
    }
    else return false;
  }
  // ---------------------------------------------------------------------------------
  public getCounters () : Counters {
    return this.counters;
  }
  // ---------------------------------------------------------------------------------
  public getProfilePics () : ProfilePics {
    return this.profilePics;
  }
  // ---------------------------------------------------------------
  public getSingedInAndNonSignedInSitUsers (sitUserId : number) : void {
    this.sitUser = EmitterSubjectService.getSitUserModel();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserId)
      && sitUserId > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)
      && this.sitUser.sitUserId > 0
      && this.sitUser.sitUserId !== sitUserId) {
      debugger;
      console.log('calling fetchSitUser() from photoBnService@ln726')
      this.sitUserService.getSitUserFromDictionaryOrServer(sitUserId).subscribe(data => {
        this.sitUser = data;
        debugger;
      })
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUserService)) {
      this.signedInUser = EmitterSubjectService.getSignedInUser();
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUser)
        || this.signedInUser.sitUserId === 0
        || (this.signedInUser.sitUserId > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.signedInUser.sitUserId !== this.loginSuccess.signedInUserId)) {
        this.signedInUserService?.getSingedInSitUser()?.subscribe(data => {
          this.signedInUser = data;
        })
      }
    }
  }
  // ---------------------------------------------------------------
  /*
   * ---------------------------------------------------------------
   * Note: Tested, works! 2020/September/30.
   * Note: New Scheme for generating ProfilePics of a user:
   *       1) Get user photoIds and save to indexedDb
   *       2) Get photoBn with each photoId
   *       3) Accumulate photoBns
   *       4) After getting all photoBns of the user, create a ProfilePics model
   *          with the photoBns and save to indexedDb like previous scheme of saving ProfilePics
   * ---------------------------------------------------------------
   */
  // ---------------------------------------------------------------
  public getUserPhotoBnIds (sitUserId : any) : Promise<any> {
    // debugger;
    return new Promise<any>((resolve, reject) => {
      if (sitUserId > 0) {
        // debugger;
        const photoBn : PhotoBn = new PhotoBn();

        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;
        if (this.loginSuccess.signedInUserId > 0) {
          photoBn.signedInUserId = this.loginSuccess.signedInUserId;
          if (this.loginSuccess.signedInUserId == sitUserId) {
            photoBn.sitUserId = this.loginSuccess.signedInUserId;
          }
        }
        else photoBn.sitUserId = sitUserId;

        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(photoBn);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          // debugger; // '/api/Member/GetUserPhotoIds',
          this.httpService.postObservable('/api/Member/GetUserPhotoBnIds',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text',
          ).subscribe((result) => {
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
              this.userPhotoBnIds = result;

              // save to dictioanry and indexedDb:
              // ---------------------------------
              this.dictionaryService.updateDictionary(this.userPhotoBnIds, 'photoBnId', sitUserId);
              this.dbDexieToDictionaryService
                .saveDictionaryToDbDexie(
                  this.dictionaryService.userPhotoBnIdDictionary,
                  "photoBnId",
                  this.loginSuccess.signedInUserId);

              // debugger;
              EmitterSubjectService.setUserPhotoBnIds(this.userPhotoBnIds);
              // debugger;
              resolve(this.userPhotoBnIds);
            }
            else {
              this.message = 'GetUserPhotoBnIds=>result was null for (sitUserId:\'' + sitUserId + '\')';
              console.log(this.message);
              // EmitterSubjectService.emitMessage( this.message );
              reject(this.message);
            }
          }, (error) => {
            this.message = 'Error occured in GetUserPhotoBnIds(sitUserId:\'' + sitUserId + '\');\n Error message:  ' + error.message;
            console.log(this.message);
            // EmitterSubjectService.emitMessage( this.message );
            // EmitterSubjectService.emitMyErrorLog( this.message );
            reject(this.message);
          });
        }
        else {
          reject('Error occured in GetUserPhotoBnIds. boxNonce was null');
        }
      }
      else {
        reject('Error occured in GetUserPhotoBnIds.sitUserId = 0');
      }
    });
  }
  // ===============================================================
  //  This method gets a backend's  PhotoBn model.
  //  This method unsalts the model's box and nonce only.
  //  The rest of the data is unsalted.
  // ===============================================================
  public getUserPhotoByPhotoBnId (photoBnId : number, sitUserId : number) : Promise<Photo> {
    // debugger;
    let tpPics : any;
    const photoBn : PhotoBn = new PhotoBn();
    debugger; // TODO: check if in use?

    return new Promise<any>((resolve, reject) => {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      this.isOnLine = EmitterSubjectService.getIsOnLine();
      this.sitUser = EmitterSubjectService.getSitUserModel();
      if (sitUserId > 0) {
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)) {
          this.sitUser = new SitUser();
        }
        this.sitUser.sitUserId = sitUserId; // Note: important!
      }
      if (photoBnId > 0) {
        photoBn.photoBnId = photoBnId;
      }
      // debugger;        
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
        photoBn.signedInUserId = this.loginSuccess.signedInUserId;
        if (this.loginSuccess.signedInUserId === sitUserId) {
          this.sitUser.sitUserId = this.loginSuccess.signedInUserId;
          photoBn.sitUserId = this.loginSuccess.signedInUserId;
        }
      }
      else {
        photoBn.sitUserId = sitUserId;
      }

      this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(photoBn);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        // debugger;
        this.httpService
          .postObservable(
            '/api/Photo/GetUserPhotoBn',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text',
          )
          .subscribe(
            (result) => {
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                // debugger;
                let tPhotoBn = JSON.parse(result);
                // debugger;
                // tPhotoBn = StringServiceStatic.toCamelCase(tPhotoBn);
                // debugger;
                // let photo = this.processPhotoBnResult(this.sitUser.sitUserId, tPhotoBn, photoBnId) as Photo;
                this.photoOmni = this.processPhotoBnResult(this.sitUser.sitUserId, tPhotoBn, photoBnId) as PhotoOmniModel;
                // debugger;

                // save it in dictionary and indexedDb:
                // ------------------------------------
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoOmni)
                  && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.photoOmni.kvPhoto)) {
                  // debugger;
                  resolve(this.photoOmni.kvPhoto);
                }
                else {
                  this.message = 'image was null for getPhotoBn(sitUserId:' + this.sitUser.sitUserId + ', photoBnId:' + photoBnId + ');';
                  console.log(this.message);
                  reject(this.message);
                }
              }
              else {
                this.message = 'result was null for getPhotoBn(sitUserId:' + this.sitUser.sitUserId + ');';
                console.log(this.message);
                reject(this.message);
              }
            },
            (error) => {
              this.message = 'Error occured in getPhotoBn(sitUserId:' + this.sitUser.sitUserId + ');\n Error message:  ' + error.message;
              console.log(this.message);
              reject(this.message);
            },
          );
      } else {
        this.message = 'The data-to-be-sent \'boxNonceEntity\' is null or its box/nonce are null for (signedInUserId:';
        this.message += this.loginSuccess.signedInUserId + '; sitUserId:' + this.sitUser.sitUserId + '; photoId:' + photoBnId + ').';
        console.log(this.message);
        reject(this.message);
      }
    });
  }
  // ---------------------------------------------------------------
  // Usage
  // observable.subscribe ( value => console.log( value ));
  // Created on 20211010
  // ---------------------------------------------------------------
  public getUserPhotosByPhotoBnIds (photoBnIds : number[], sitUserId : any) : Observable<any> {
    // debugger;
    const observable = new Observable(observer => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBnIds) && photoBnIds.length > 0) {
        // debugger;
        photoBnIds.map(e => {
          // debugger;
          // will store the photoBn in dictionary and return a promise:
          // ----------------------------------------------------------
          this.getUserPhotoByPhotoBnId(e, sitUserId).then(data => {
            // debugger;
            // TODO: accumulate here before sending
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
              observer.next(data);
            }
          });
        });
      }
    });
    return observable;
  }
  // ---------------------------------------------------------------
  /*
   * ---------------------------------------------------------------
   * Note: Tested, works! 2020/September/30.
   * Note: UserPhotoBn is unsalted twice:
   *       1) Unsalt to get the PhotoBn that is obtained from the server DB
   *       2) Take the Box and Nonce of the unsalted PhotoBn and create a BoxNonceEntity
   *       3) Use the BoxNonceEntity and Unsalt to get the Photo model
   *       4) After getting all photos of the user, create a ProfilePics model:
   *       4.1) Create a ProfileImageArray
   *       4.2) User the ProfileImageArray to Create the desired ProfilePics model
   *       4.3) Salt the ProfilePics Model and save it in the indexedDb like previous Scheme
   *       4.4) Now we can use the existing code to render the ProfilePics as usual.
   * ---------------------------------------------------------------
   */
  // ---------------------------------------------------------------
  public getUserSaltedPhotoBn (photoBnId : number, sitUserId : number) : Promise<any> {
    // debugger;
    let tpPics : any;
    return new Promise<any>((resolve, reject) => {
      if (photoBnId > 0) {
        // debugger;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        this.isOnLine = EmitterSubjectService.getIsOnLine();
        this.sitUser = EmitterSubjectService.getSitUserModel();
        if (sitUserId > 0) {
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)) {
            this.sitUser = new SitUser();
          }
          this.sitUser.sitUserId = sitUserId; // Note: important!
        }
        if (/*this.isOnLine && */!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
          const photoBn : PhotoBn = new PhotoBn();

          photoBn.photoBnId = photoBnId;
          photoBn.sitUserId = sitUserId;
          photoBn.signedInUserId = this.loginSuccess.signedInUserId;
          this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(photoBn);

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            // debugger;
            this.httpService
              .post(
                '/api/Member/GetUserSaltedPhotoBn',
                {
                  box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
                  nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
                },
                'json2text',
              )
              .subscribe(
                (result) => {
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                    let tProfileImage = this.processSaltedPhotoBnResult(this.sitUser.sitUserId, result, photoBnId);
                    // debugger;
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tProfileImage)) {
                      resolve(tProfileImage);
                    }
                    else {
                      this.message = 'image was null for getSaltedPhotoBn(sitUserId:' + this.sitUser.sitUserId + ', photoBnId:' + photoBnId + ');';
                      console.log(this.message);
                      return FrequentlyUsedFunctionsServiceStatic.nullPromise();
                    }
                  }
                  else {
                    this.message = 'result was null for getSaltedPhotoBn(sitUserId:' + this.sitUser.sitUserId + ');';
                    console.log(this.message);
                    return FrequentlyUsedFunctionsServiceStatic.nullPromise();
                  }
                },
                (error) => {
                  this.message = 'Error occured in getSaltedPhotoBn(sitUserId:' + this.sitUser.sitUserId + ');\n Error message:  ' + error.message;
                  console.log(this.message);
                  return FrequentlyUsedFunctionsServiceStatic.nullPromise();
                },
              );
          } else {
            this.message = 'The data-to-be-sent \'boxNonceEntity\' is null or its box/nonce are null for (signedInUserId:';
            this.message += this.loginSuccess.signedInUserId + '; sitUserId:' + this.sitUser.sitUserId + '; photoId:' + photoBnId + ').';
            console.log(this.message);
            return FrequentlyUsedFunctionsServiceStatic.nullPromise();
          }
        } else {
          this.message = 'The user in getSaltedPhotoBn(' + this.loginSuccess.signedInUserId + ') is not online.';
          console.log(this.message);
          return FrequentlyUsedFunctionsServiceStatic.nullPromise();
        }
      } else {
        this.message = 'could not send data in getSaltedPhotoBn(photoId:' + photoBnId + ');\n';
        console.log(this.message);
        return FrequentlyUsedFunctionsServiceStatic.nullPromise();
      }
    });
  }
  // ---------------------------------------------------------------
  public prepareToBeSentModelForGetAllPhotoBnOfUser (sitUserId : any) : BoxNonceEntity | any {
    let photoBn : PhotoBn = new PhotoBn();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (this.loginSuccess && this.loginSuccess.signedInUserId > 0) {
      photoBn.signedInUserId = this.loginSuccess.signedInUserId;
    }
    if (sitUserId > 0) {
      // debugger;
      photoBn.sitUserId = sitUserId;
    }
    this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(photoBn);
    return this.boxNonceEntity;
  }
  // ---------------------------------------------------------------
  public processUnsaltedImageData (pbnResult : PhotoBn, unsalted : string) : any {
    // --------------------------------------------------------------------------------
    // checks if the data contians raw-image-data then will prefix "url('" to the data:
    // --------------------------------------------------------------------------------
    // pbnResult.pic = JsRegExpServiceStatic.normalizeImageData(camelCasedUnsalted);

    // this will strip the "url('" && "')", producing the normalized-image-data:
    // -------------------------------------------------------------------------
    // pbnResult.pic = JsRegExpServiceStatic.stripUrlForImageData(pbnResult.pic);

    // OR // Testing complete:
    // pbnResult.pic = JsRegExpServiceStatic.normalizeImageData(camelCasedUnsalted); // tested & works!

    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbnResult)) {
      pbnResult = new PhotoBn()
    }
    // debugger;
    // pbnResult.pic = JsRegExpServiceStatic.normalizeImageDataWithRegExp(unsalted);
    // pbnResult.pic = JsRegExpServiceStatic.normalizeImageData(unsalted);
    // debugger;
    // Note: Experimantal: 20220527 : remove url from the pic:
    pbnResult.pic = JsRegExpServiceStatic.stripUrlForImageData(pbnResult.pic);

    // debugger;
    if (JsRegExpServiceStatic.escapeQuotePattern.test(pbnResult.pic)) {
      // debugger;
      pbnResult.pic = pbnResult.pic.replace(/\"/, '');

    }

    if (unsalted.indexOf('\"') === -1) {
      pbnResult.image = unsalted; // original server sent data
    }
    else {
      pbnResult.image = unsalted.replace(/\"/, '');
    }
    // debugger;
    return pbnResult;
  }

  // ---------------------------------------------------------------------------------
  // Note:  this needs to send profileImage only, not profilePics.
  //        this method unsalts the received data only once.
  // ---------------------------------------------------------------------------------
  public processUnsaltedObject (unsalted : string) : any {
    let pbnResult : PhotoBn = new PhotoBn();
    let tPbnResult : PhotoBn = new PhotoBn();
    let camelCasedPhotoBn = new PhotoBn();
    let mcLoader = EmitterSubjectService.getMCLoader();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
      // try geting the photo model if exists:
      // -------------------------------------
      // debugger;
      let unsaltedPhotoBn = JSON.parse(unsalted) as PhotoBn;
      // debugger;
      // extract pic and image data from the unsaltedPhoto model:
      // after the Photo model is unsalted successfully: (tested & works!)
      // -----------------------------------------------------------------
      // debugger;
      camelCasedPhotoBn = CopyServiceStatic.copyFromServerPhotoBnToClientPhotoBn(camelCasedPhotoBn, unsaltedPhotoBn);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelCasedPhotoBn) && (camelCasedPhotoBn.photoId > 0 || camelCasedPhotoBn.photoBnId > 0)) {

        // important to use camerCasedPhoto for extracting data:
        // -----------------------------------------------------
        // debugger;
        pbnResult = pbnResult.copy(camelCasedPhotoBn as PhotoBn);
        // debugger;
        // important to use camerCasedUnsalted for extracting data:
        // --------------------------------------------------------
        let tPic : any;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelCasedPhotoBn.pic) /*&& JsRegExpServiceStatic.photoUrlPrefixPattern.test(pbnResult.pic)*/) {
          if (mcLoader.url.toLowerCase().indexOf('memberscroll') === -1) {
            // debugger;
          }
          tPic = JsRegExpServiceStatic.normalizeImageDataWithRegExp(camelCasedPhotoBn.pic);
          // tPic = JsRegExpServiceStatic.normalizeImageData(camelCasedPhotoBn.pic);
          // debugger;
          // Note: Experimantal: 20220527 : remove url from the pic:
          tPic = JsRegExpServiceStatic.stripUrlForImageData(tPic);
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPic) && tPic.length > 5) {
          pbnResult.pic = tPic;
          // debugger;
        }
        // debugger;
        // pbnResult.pic = this.escapeQuoteFromPic(pbnResult.pic);
      }
    }
    // debugger;
    return pbnResult;
  }
  // ---------------------------------------------------------------------------------
  // Note:  this needs to send profileImage only, not profilePics.
  //        this method unsalts the received data only once.
  // ---------------------------------------------------------------------------------
  public processPhotoBnResult (sitUserId : number, inPbnResult : any, photoBnId : number) : any {
    let tPbnResult : PhotoBn = new PhotoBn();
    let photoOmni : PhotoOmniModel = new PhotoOmniModel();
    let camelCasedPhotoBn = new PhotoBn();
    let unsalted : PhotoBn = new PhotoBn();
    // debugger;
    // Note: for testing purpose only, we check the value of mcLoader.url if it is not members page:
    // ---------------------------------------------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inPbnResult)) {
      try {
        /* tPbnResult = new PhotoBn().toCamelCase(inPbnResult);*/
        // debugger;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inPbnResult)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inPbnResult.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inPbnResult.nonce)
          && inPbnResult.box.length > 0 && inPbnResult.nonce.length > 0) {
          // debugger;      

          // Note: Single Decryption scheme:
          // -------------------------------
          let tBn = new BoxNonceEntity();
          tBn.box = inPbnResult.box;
          tBn.nonce = inPbnResult.nonce;
          // debugger;

          unsalted = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(tBn)) as PhotoBn | undefined;
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
            camelCasedPhotoBn = new PhotoBn().toCamelCase(unsalted);
            // debugger;
           

            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelCasedPhotoBn.fileBytes) && camelCasedPhotoBn.fileBytes.length > 0) {
              camelCasedPhotoBn.pic = JsRegExpServiceStatic.prefixPngToImageData(camelCasedPhotoBn.fileBytes);
              camelCasedPhotoBn.image = camelCasedPhotoBn.pic;
              // debugger;
            }
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inPbnResult.photoBnId) && inPbnResult.photoBnId > 0
              && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelCasedPhotoBn.photoBnId)
              || camelCasedPhotoBn.photoBnId === 0) {
              camelCasedPhotoBn.photoBnId = inPbnResult.photoBnId;
            }
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inPbnResult.photoId) && inPbnResult.photoId > 0
              && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelCasedPhotoBn.photoId)
              || camelCasedPhotoBn.photoId === 0) {
              camelCasedPhotoBn.photoId = inPbnResult.photoId;
            }
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelCasedPhotoBn)) {
              camelCasedPhotoBn.privacy = inPbnResult.privacy; //important!!
              if (camelCasedPhotoBn.privacy.toLocaleLowerCase().indexOf('primary') !== -1) {
                camelCasedPhotoBn.isPrimary = true;
              }
              else {
                camelCasedPhotoBn.isPrimary = false;
              }
              photoOmni.kvPhoto = new PhotoBn().cloneToKvPhoto(camelCasedPhotoBn);
              photoOmni.photoBn = JSON.parse(JSON.stringify(camelCasedPhotoBn)); // deep clone
              // debugger;
            }

            // debugger;
            return photoOmni;
          }
        }
        else {
          // debugger;
          return null;
        }
      }
      catch (e) {
        // debugger;
        console.log(e)
      }
      finally {
      }
    }
  }
  // ---------------------------------------------------------------------------------
  // Note:  this needs to send profileImage only, not profilePics.
  //        this method unsalts the received data twice.
  // ---------------------------------------------------------------------------------
  public processSaltedPhotoBnResult (sitUserId : number, result : any, photoBnId : number) : any {
    let tpPics : any;
    let userPhoto : any;

    if (sitUserId > 0) {
      this.sitUser.sitUserId = sitUserId;
    }

    if (result) {
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) && result.box.length > 0 && result.nonce.length > 0) {
        // Note: Double Encrypted scheme:
        // ------------------------------
        const pBn = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result));
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.Box)
          && pBn.Box.length > 0
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.Nonce)
          && pBn.Nonce.length > 0) {
          let tBn = new BoxNonceEntity();
          tBn.box = pBn.Box;
          tBn.nonce = pBn.Nonce;

          const pbn = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(tBn));
          // debugger;
          pBn.ImageString = JsRegExpServiceStatic.normalizeImageData(pbn.Image);
          // debugger;
          // Experimental: 20220527
          pBn.ImageString = JsRegExpServiceStatic.stripUrlForImageData(pBn.ImageString);

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pBn.ImageString)) {
            // We create the photo inorder to creat ProfileImage:
            // --------------------------------------------------
            let photo = new Photo();

            // save in dictionary and indexedDb:
            // ---------------------------------
            this.dictionaryService.updateDictionary(photo, 'photo', photo.photoId);

            // -------------------------------------------------
            // Note:  we do not presist photo in indexedDb.
            //        If that changes, uncomment the below code:
            // -------------------------------------------------
            // this.indexedDbToDictionaryService
            //  .saveDictionaryToDbDexie(
            //    this.dictionaryService.photoDictionary,
            //    "Photo",
            //    this.loginSuccess.signedInUserId);
            // -------------------------------------------------

            photo = CopyServiceStatic.copyFromPhotoBnToClientPhoto(photo, pBn);
            // debugger;
            let pImage = CopyServiceStatic.convertUserPhotoToProfileImage(photo) as ProfileImage;
            // debugger;
            pImage.sitUserId = pImage.sitUserId > 0 ? pImage.sitUserId : this.sitUser.sitUserId; // Note: important!
            return pImage;
          }
          else return null;
        }
        else return null;
      }
      else return null;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  public processUnsaltedPhotoBnIdResult (sitUserId : number, result : number[]) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) && result.length > 0) {
      this.userPhotoBnIds = result;

      // save in the dictionary and indexedDb:
      // -------------------------------------
      this.dictionaryService.updateDictionary(this.userPhotoBnIds, 'photoBnId', sitUserId);
      this.dbDexieToDictionaryService
        .saveDictionaryToDbDexie(
          this.dictionaryService.userPhotoBnIdDictionary, 'photoBnId', this.loginSuccess.signedInUserId);

      EmitterSubjectService.setUserPhotoBnIds(this.userPhotoBnIds);

      // debugger; // TODO: check to see the values
      return this.userPhotoBnIds;
      // debugger;
    }
  }
  // ---------------------------------------------------------------
  public processPhotoBnIdResult (sitUserId : number, result : any) : any {
    if (sitUserId > 0) {
      this.sitUser.sitUserId = sitUserId;
    }

    if (result) {
      const bn = result as BoxNonceEntity;

      // debugger;

      if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
        // debugger;
        this.userPhotoBnIds = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as number[];
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.userPhotoBnIds) && this.userPhotoBnIds.length > 0) {
          EmitterSubjectService.setUserPhotoBnIds(this.userPhotoBnIds);

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
            // debugger;
            this.sitUser = EmitterSubjectService.getSitUserModel();
          }
          // save it to dictionary and indexedDb
          this.dictionaryService.updateDictionary(this.userPhotoBnIds, 'photoBnId', sitUserId);
          this.dbDexieToDictionaryService
            .saveDictionaryToDbDexie(
              this.dictionaryService.userPhotoBnIdDictionary, 'photoBnId', this.loginSuccess.signedInUserId);

          // debugger; // TODO: check to see the values
          return this.userPhotoBnIds;
          // debugger;
        }
      }
      else {
        // debugger;
        return null;
      }
    }
    else {
      // debugger;
      return null;
    }
  }
  // ---------------------------------------------------------------------------------
  public processServerSentPhotoBnArr (sitUserId : number, pbnArr : any[]) : any {
    let profileImageArr : ProfileImage[] = [];
    let tPi : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbnArr) && pbnArr.length > 0) {
      pbnArr.map(e => {
        tPi = this.processSaltedPhotoBnResult(sitUserId, e, e.photoBnId);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPi) && tPi.image.length > 0) {
          profileImageArr.push(tPi);
        }
      });
    }
    return profileImageArr;
  }
  // ---------------------------------------------------------------
  public savePhotoBnToDictionary (pbn : PhotoBn) : void {
    this.dictionaryService.updateDictionary(pbn, 'photoBn', pbn.photoBnId);
  }
  // ---------------------------------------------------------------------------------
  public setCounters (value : Counters) : any {
    this.counters = value;
    return true;
  }
  // ---------------------------------------------------------------------------------
  public setProfilePics (value : ProfilePics) : void {
    this.profilePics = value;
  }
  // ---------------------------------------------------------------------------------
  public setUserPhotoBnIds (value : number[]) : any {
    this.userPhotoBnIds = value;
    return true;
  }
  // ---------------------------------------------------------------------------------
  public unsaltPhotoBn (pbn : PhotoBn) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn.box)
      && pbn.box.length > 0) {
      this.boxNonceEntity = new BoxNonceEntity();
      this.boxNonceEntity.box = pbn.box;
      this.boxNonceEntity.nonce = pbn.nonce;

      let tPbn = SlakezSaltServiceStatic.boxUnsalt(this.boxNonceEntity);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPbn)) {
        tPbn = CopyServiceStatic.copyFromPhotoBnToClientPhoto(pbn, tPbn);
        return tPbn;
      }
      else return null;
    }
    else return null;
  }
  // ---------------------------------------------------------------
}

