import {EPLStyleEventEmitter, Event} from "./EPLStyleEventEmitter";
import { Logger } from "./Logger";

export class ListEvent extends Event {

  public static get ITEM_ADDED():string{ return "itemAdded";}
  public static get ITEM_REMOVED():string{ return "itemRemoved";}
  public static get ITEMS_ADDED():string{ return "itemsAdded";}
  public static get REMOVE_ALL():string{ return "removeAll";}
  public static get BEFORE_REMOVE_ALL():string{ return "removeAll";}

  public item:any;
  public items:Array<any> = new Array<any>();
  public index:number = -1;

  constructor(type:string, targetObj:any)
  {
    super(type, targetObj);
  }

}


export class List<T> extends EPLStyleEventEmitter{

  private _list:Array<any> = [];


  constructor() {

    super();

  }

  public removeDuplicates():void
  {
    let outList:List<T> = new List<T>();
    let j:number;
    let found:Boolean = false;

    this.forEach((item:T, index:number)=>{
      let i:number = outList.indexOf(item);
      if(i != -1)
        this.removeItemAt(index);
      else
        outList.addItem(item);
    }, true);

    return ;

  }


  public getUniqueValues():List<T>
  {
    let outList:List<T> = new List<T>();
    let j:number;
    let found:Boolean = false;

    this.forEach((item:T)=>{
      let i:number = outList.indexOf(item);
      if(i == -1)
        outList.addItem(item);
    });

    return outList;

  }

  public override toString():string
  {
    let result:string = ""
    this.forEach((item:any)=>{
      result += item.toString() +",";
    })
    result = result.substr(0,result.length-1);

    return result;
  }


  public get length():number
  {
    return this._list.length;
  }

  public get count():number
  {
    return this._list.length;
  }

  public removeItemAt(index:number):void {
    // remove from the list

    if(index < 0 && index > (this._list.length - 1))
    {
      Logger.debug("Index is out of range. index: " + index + ".")
      return;
    }

    let rslt: Array<any> = this._list.splice(index, 1);

    if (rslt.length == 0)
    {
      Logger.debug("Unable to remove item at " + index + ". Nothing returned by the splice method!")
      return;
    }

    let item = rslt[0];

    let event:ListEvent = new ListEvent(ListEvent.ITEM_REMOVED, this);
    event.item = item;
    event.index = index;
    this.emit(event);

    return item;

  }

  public removeAll():void{

    let event:ListEvent = new ListEvent(ListEvent.BEFORE_REMOVE_ALL, this);
    this.emit(event);

    this._list = [];

    let event2:ListEvent = new ListEvent(ListEvent.REMOVE_ALL , this);
    this.emit(event2);

  }

  public itemAt(index:number):T
  {
    return this._list[index];
  }

  public find(callback:Function, data:any = null):number
  {
    let flag;
    let index:number = 0

    for(index; index<this.length; index++)
    {
      flag = callback(this.itemAt(index), data);
      if(flag)
        break;
    }

    if(flag)
      return index;
    else
      return -1;
  }

  public forEach(callback:Function, reverse:boolean = false):void
  {

    if(reverse){
      for (let index: number = this.length -1; index >=0; index--)
      {
        let flag: string = callback(this.itemAt(index), index);
        if (flag == "break")
          break;
      }
    } else {
      for (let index: number = 0; index < this.length; index++) {
        let flag:string = callback(this.itemAt(index), index);
        if (flag == "break")
          break;
      }
    }
  }

  public async forEachAsync(callback:Function):Promise<any>
  {
    for(let index:number = 0; index<this.length; index++)
      await callback(this.itemAt(index), index);
  }


  // This class is to make it compatible with Dojo's framework.
  // Dojo uses a "Deferred" class when doing async activities.

  public async forEachDeferred(callback:Function):Promise<any>
  {
    let scope:List<T> = this;
    let index = 0;

    return new Promise((resolve, reject)=> {

      let onFail:Function = function(error:any):void{
        reject(error);
      }

      let onSuccess: Function = function () {
        index++;
        if (index < scope.length)
          callback(scope.itemAt(index), index).then(onSuccess, onFail);
        else
          resolve(true);
      };

      // Start the iteration
      callback(this.itemAt(index), index).then(onSuccess);

    });
  }

  public updateItemAt(item:T, index:number = -1):T
  {
    this._list[index] = item;

    let event:ListEvent = new ListEvent(ListEvent.CHANGE, this);
    event.item = item;
    event.index = index;
    this.emit(event);

    return item;
  }

  public update(f:Function):void{
    for(let index:number = 0; index<this.length; index++)
    {
      let newItem = f(this.itemAt(index), index);
      this.updateItemAt(newItem, index);
    }
  }

  public async updateAsync(f:Function):Promise<any>{
    for(let index:number = 0; index<this.length; index++)
    {
      let newItem = await f(this.itemAt(index), index);
      this.updateItemAt(newItem, index);
    }
  }

  public addItemAt(item:T, index:number = -1):T
  {
    // if the index is invalid, append to the end.
    if(index < 0  && index > this._list.length)
      this._list.push(item);
    else
      this._list.splice(index, 0, item);

    let event:ListEvent = new ListEvent(ListEvent.ITEM_ADDED, this);
    event.item = item;
    event.index = index;
    this.emit(event);

    return item;
  }

  public addItem(item:T):T
  {
    this.addItemAt(item, -1);

    return item;
  }

  public addItemsByList(items:List<T>):void
  {
    items.forEach((item:T)=>{
      this._list.push(item);
    });

    let event:ListEvent = new ListEvent(ListEvent.ITEMS_ADDED, this);
    event.items = items.toArray();
    this.emit(event);

  }

  public toArray():Array<T>
  {
    let arr:Array<T> = [];

    this.forEach((item:T)=>{
      arr.push(item);
    });

    return arr;
  }


  public addItems(items:Array<T>):void
  {
    if(items == null || items.length == 0)
      return;

    for(let item of items )
      this._list.push(item);

    let event:ListEvent = new ListEvent(ListEvent.ITEMS_ADDED, this);
    event.items = items;
    this.emit(event);
  }

  public removeItem(item:T):void
  {
    let i:number = this.indexOf(item);
    if(i == -1)
      return;

    this.removeItemAt(i);

  }

  public removeAllItem(item:T):void
  {
    for(let i:number = this.length-1; i >= 0; i--)
    {
      if(this.itemAt(i) == item)
        this.removeItemAt(i);
    }

  }

  public indexOf(item:T):number
  {
    return this._list.indexOf(item);
  }

  public contains(item:T):boolean
  {
    return (this._list.indexOf(item) != -1);
  }


  public lastIndexOf(item:T):number
  {
    return this._list.lastIndexOf(item);
  }

  public getItemById(id:String, propertyName:string = "id", ignoreCase:boolean = false):any
  {
    for(let i:number = 0; i < this.length; i++)
    {
      let item:any = this.itemAt(i);
      if(item != null)
      {
        if(item[propertyName] == id)
          return item;
        else if(ignoreCase && item[propertyName].toLowerCase() == id.toLowerCase())
          return item;
      }
    }

    return null;
  }

  public get list():Array<T>
  {
    return this._list;
  }

}
