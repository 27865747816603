
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Chat } from '../../models/chat/chat.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { Pager } from '../../models/pagination/pager.model';
import { PagerChat } from '../../models/pagination/pagerChat.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ChatService } from '../communicationServiceService/chatService';
import { CommunicationServiceService } from '../communicationServiceService/communicationServiceService.service';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';

declare let jQuery: any;
declare let $: any;

@Injectable({
  providedIn : 'any'
})

// ---------------------------------------------------------------
// Note : For any future Pager Reference, this should be used.
// ---------------------------------------------------------------
export class PagerServiceChatHistory implements OnDestroy {
  // public chatService! : ChatService;
  public chat: Chat = new Chat();
  public chats: Chat[] = [];
  public currentPage = 1;
  public emitterDestroyed$: Subject<boolean> = new Subject();
  public lastPage = 1;
  public isLastPage = false;
  public pagedChats : Chat[] = [];
  public pagedChatArr : Chat[] = [];
  public pagerChat: PagerChat = new PagerChat();
  public pageSize = 10;
  public tempElem: any;
  public totalItems = 0;
  // ---------------------------------------------------------------
  constructor (/*private communicationServiceService: CommunicationServiceService,*/
  ) {
    // if (this.communicationServiceService) this.chatService = this.communicationServiceService.getChatService();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ===============================================================  
  getPagedChats () : Chat[] {
    return this.pagedChats;
  }
  // ---------------------------------------------------------------
  getPagerChat(): PagerChat {
    this.pagerChat = this.setPagerChat(1);
    return this.pagerChat;
  }

  // --------------------------------------------
  public sortChats (chats : Chat[], isAsc : boolean) : Chat[] {
    if (isAsc) {
      return chats.sort((a, b) => (a.communicationActivityId as number) - (b.communicationActivityId as number));
    }
    else {
      return chats.sort((a, b) => (b.communicationActivityId as number) - (a.communicationActivityId as number));
    }
  }
  // ---------------------------------------------------------------
  setChats(chats: Chat[]): void {
    this.chats = chats;
  }
  // ---------------------------------------------------------------
  setPager(page: number): any {
    // returnthis.setPagerUpTo15(this.chats, page);
    return this.setPagerChatDynamic(this.chats, page, 10);
  }
  // ---------------------------------------------------------------
  setPagerChat (page : number) : any {
    // returnthis.setPagerUpTo15(this.chats, page);
    return this.setPagerChatDynamic(this.chats, page, 15);
  }
  // ---------------------------------------------------------------
  public setPagerChatDynamic (chats : Chat[], page : number, pageSize: number) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chats) && chats.length > 0) {
      this.chats = chats;
      if (this.chats && this.chats.length > 0) {
        this.totalItems = this.chats.length;
      }
      this.pageSize = pageSize > 0 ? pageSize : 15;

      this.currentPage = page > 0 ? page : 1;
      // ---------------
      // backup:
      // -------
      //chats = chats.sort((a, b) => b.chatActivityId - a.chatActivityId);

      //// get pager object
      //this.pagerChat = this.GetPagerChatDynamic(this.totalItems, this.currentPage, this.pageSize);

      //var isAsc = false;
      //this.pagerChat.chats = this.sortChats(this.chats, isAsc);
      //this.pagerChat.pagedChats = this.chats.slice(this.pagerChat.startIndex, this.pagerChat.endIndex);
      // ---------------
      chats = chats.sort((a, b) => b.chatActivityId - a.chatActivityId);

      // get pager object
      this.pagerChat = this.GetPagerChatDynamic(this.totalItems, this.currentPage, this.pageSize);

      var isAsc = false;
      this.pagerChat.chats = this.chats = chats;
      this.pagerChat.pagedChats = this.chats.slice(this.pagerChat.startIndex, this.pagerChat.endIndex);
      
      // debugger;
           

      // ------------------------------------------------------------------------------------------------------
      // get current items of page
      // NOTE : We do not follow these steps. Instead we reverse the chats upon constructing a conversation.
      // Note : because we want to see the most-recent-messages near the Chat Message Console, we do the following steps:
      //     Step-1 : We sort the messages in descending order(with isAsc == false)
      //     Step-2 : Then we take a slice for PagedChatArr
      //     Step-3 : Then the pagedChatArr are sorted in Ascending order (isAsc==true)
      //             for the most - recent - message to appear near the Chat Message Console.
      // let reversedChats = this.reverseChats(this.pagerChat.chats, false); // Step-1
      // debugger;
      // this.pagedChatArr = reversedChats.slice(this.pagerChat.startIndex, this.pagerChat.endIndex); // Step-2
      // debugger;
      // this.pagedChatArr = this.reverseChats(this.pagedChatArr, true); // Step-3

      // ------------------------------------------------------------------------------------------------------

      // this.pagerChat.currentBrdCrmPage = this.breadcrumRing[this.currentPage - 1];
      // this.pagerChat.previousPage = this.currentPage - 1 >= 1 ? this.currentPage - 1  : this.currentPage;
      // if (this.pagerChat.previousPage >= 1) {
      //   this.pagerChat.previousBrdCrmPage = this.breadcrumRing[this.pagerChat.previousPage - 1];
      // }
      // this.pagerChat.nextPage = this.currentPage + 1 <= totalItems ? this.currentPage + 1  : this.currentPage;
      // if (this.pagerChat.nextPage <= this.totalItems) {
      //   this.pagerChat.nextBrdCrmPage = this.breadcrumRing[this.pagerChat.nextPage - 1];
      // }
      // ------------------------------------------------------------------------------------------------------

      

      // debugger;
      EmitterSubjectService.emitPager(this.pagerChat);
      return this.pagerChat;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  // Note : The following methods are optional giving more page selections:
  //
  //       The setPagerUpTo15() method returns a 'Pager' of size 5 if the total elements are <=5
  //       or a pager of size 10 if the total elements are >5 but < 15,  and 15 otherwise.
  //       Also if it produces a pager of size 10, it also produces a 'PagerBreadcrum' of sixe 10.
  //
  //       You can obtain the 'PagerBreadcrum' via the api : getPagerBreadcrum();
  // ---------------------------------------------------------------
  // public setChatPagerUpTo15(chats: Chat[], page: number): any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chats) && chats.length > 0) {
  //    this.chats = chats;
  //    if (this.chats && this.chats.length > 0) {
  //      this.totalItems = this.chats.length;
  //    }
  //    this.pageSize = 15;
  //    this.currentPage = page > 0 ? page  : 1;
  //    // get pager object
  //    if (this.totalItems <= 5) {
  //      this.pagerChat = this.GetPagerOfSize5(this.totalItems, this.currentPage, this.pageSize > 0 ? this.pageSize  : 5);
  //    }
  //    //else if (this.totalItems <= 10) {
  //    //  // (totalItems, currentPage, pageSize)
  //    //  // this.pager = this.GetPagerOfSize10(this.totalItems, this.currentPage, this.pageSize > 0 ? this.pageSize  : 10);
  //    //}
  //    else {
  //      // (totalItems, currentPage, pageSize)
  //      this.pagerChat = this.GetPagerOfSize15(this.totalItems, this.currentPage, this.pageSize > 0 ? this.pageSize  : 15);
  //    }
      
      // ------------------------------------------------------------------------------------------------------
      // get current items of page
      // NOTE : We do not follow these steps. Instead we reverse the chats upon constructing a conversation.
      // Note : because we want to see the most-recent-messages near the Chat Message Console, we do the following steps:
      //     Step-1 : We sort the messages in descending order(with isAsc == false)
      //     Step-2 : Then we take a slice for PagedChatArr
      //     Step-3 : Then the pagedChatArr are sorted in Ascending order (isAsc==true)
      //             for the most - recent - message to appear near the Chat Message Console.
      // let reversedChats = this.reverseChats(this.pagerChat.chats, false); // Step-1
      // debugger;
      // this.pagedChatArr = reversedChats.slice(this.pagerChat.startIndex, this.pagerChat.endIndex); // Step-2
      // debugger;
      // this.pagedChatArr = this.reverseChats(this.pagedChatArr, true); // Step-3
      // ------------------------------------------------------------------------------------------------------
      // debugger;
      
  //    this.pagerChat.chats = this.chats;
  //    // debugger;
  //    this.pagedChats = this.chats.slice(this.pagerChat.startIndex, this.pagerChat.endIndex + 1);
  //    // debugger;

  //    //  the pagedChats are reversed because
  //    //  we want to display the latest message 
  //    //  closer to the chat-message-typing-UI.
  //    // --------------------------------------
  //    this.pagerChat.pagedChats = ArraySupportServiceStatic.reverseArrayOnItself(this.pagedChats);

  //    EmitterSubjectService.emitPager(this.pagerChat);
  //    return this.pagerChat;
  //  }
  // }
  // --------------------------------------------------
  private range (min : any, max : any, delta? : any) {
    const arr : any[] = [];
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(delta)) {
      delta = 0.5;
		}
    let stepCount;
    if (min && !max && !delta) {
      for (let i = 0; i < min; i++) {
        arr[i] = i + 1;
      }
    } else {
      if (min && max && !delta) {
        stepCount = (max - min);
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i + min);
        }
      } else {
        stepCount = Math.floor((max - min) / delta);
        for (let i = 0; i <= stepCount; i++) {
          arr.push(i * delta + min);
        }
      }
    }
    return arr;
  }
  // --------------------------------------------------
  // Can be used for any array. 
  // just the array's totalItems, currentPage and pageSize are required.
  // will give a pager from 1 upto 10.
  // Note:  this algorithm implicitely equates pageSize to pagedItems, 
  //        i.e.pageSize === itemSize
  // --------------------------------------------------
  public GetPagerChatDynamic (totalItems : any, currentPageNo : any, pageSize ?: any) : any {
    // default to first page
    let cPageNo = currentPageNo > 0 ? currentPageNo : 1;
    let tItems = totalItems > 0 ? totalItems : 1;
    // default page size is 10
    let pSize = pageSize > 0 ? pageSize : 10;

    // calculate total pages
    let totalPageNo = 0;
    if (tItems < pSize) {
      totalPageNo= 1;
    }
    else {
      totalPageNo = Math.ceil(tItems / pSize);
    }
    // debugger;
    // calculate start and end item indexes
    // updated version-2: Date: 20231016:
    // ----------------------------------
    let startPageNo;
    let endPageNo;
    if (totalPageNo <= 10) {
      // less than 10 total pages so show all
      startPageNo = 1;
      endPageNo = totalPageNo;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (cPageNo <= 6) {
        startPageNo = 1;
        endPageNo = 10;
      } else if (this.currentPage + 4 >= totalPageNo) {
        startPageNo = totalPageNo - 9;
        endPageNo = totalPageNo;
      } else {
        startPageNo = this.currentPage - 5;
        endPageNo = this.currentPage + 4;
      }
    }

     let startIndex = (cPageNo - 1) * this.pageSize;
     let endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1
        : this.pageSize), (this.totalItems > 1 ? this.totalItems - 1  : this.totalItems));

    // debugger;
    // create an array of pages to ng-repeat in the pager control
    const pages = this.range(startPageNo, endPageNo, 1);

    this.pagerChat.totalItems = tItems;
    this.pagerChat.currentPageNo = cPageNo;
    this.pagerChat.pageSize = pSize;
    this.pagerChat.startPageNo = startPageNo;
    this.pagerChat.endPageNo = endPageNo;
    this.pagerChat.startIndex = startIndex;
    this.pagerChat.endIndex = endIndex;
    this.pagerChat.pages = pages;
    this.pagerChat.totalPageNo = totalPageNo;
    this.pagerChat.blockStartIndex = startIndex;
    this.pagerChat.blockEndIndex = endIndex;

    this.pagerChat.previousPageNo = cPageNo - 1 > 0 ? cPageNo - 1 : 1;
    this.pagerChat.nextPageNo = cPageNo + 1 <= totalPageNo ? cPageNo + 1 : totalPageNo;
    this.pagerChat.firstPageNo = 1;
    this.pagerChat.lastPageNo = totalPageNo;
    // debugger;
    EmitterSubjectService.setPagerChat(this.pagerChat);
    return this.pagerChat;
  }
  // --------------------------------------------------
  // Can be used for any array. 
  // just the array's totalItems, currentPage and pageSize are required.
  // Note:  the startPage, endPage, startIndex and endIndex values need attention!
  //        These can be refactored/computed according to GatPagerDynamic() method's 
  //        algorithm to get the desired values for these variables.
  // --------------------------------------------------
  // public GetPagerOfSize15 (totalItems : any, currentPage : any, pageSize? : any): any {
  //  // default to first page
  //  this.currentPage = currentPage > 0 ? currentPage  : 1;
  //  this.totalItems = totalItems > 0 ? totalItems  : 1;
  //  // default page size is 15
  //  this.pageSize = pageSize > 0 ? pageSize  : 15;

  //  // calculate total pages
  //  const totalPages = Math.ceil(this.totalItems / this.pageSize);

  //  let startPage;
  //  let endPage;
  //  if (totalPages <= 15) {
  //    // less than 15 total pages so show all
  //    startPage = 1;
  //    endPage = totalPages;
  //  } else {
  //    // more than 15 total pages so calculate start and end pages
  //    if (this.currentPage <= 15) {
  //      startPage = 1;
  //      endPage = 15;
  //    } else if (this.currentPage + 7 >= totalPages) {
  //      startPage = totalPages - 14;
  //      endPage = totalPages;
  //    } else {
  //      startPage = this.currentPage - 8;
  //      endPage = this.currentPage + 7;
  //    }
  //  }

  //  // calculate start and end item indexes
  //  // Updated version: found to be incorrect on 20230113
  //  // --------------------------------------------------
  //  // const startIndex = totalItems <= this.pageSize ? (this.currentPage - 1)
  //  //        : this.currentPage > this.pageSize && this.pageSize < totalPages ? (this.currentPage - 1) * this.pageSize  : 0;
  //  // const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1  : this.pageSize), (this.totalItems > 1 ?
  //  //                  this.totalItems - 1  : this.totalItems));

  //  // original version:
  //  // ------------------
  //  // let startIndex = (this.currentPage - 1) * this.pageSize;
  //  // let endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1
  //  //     : this.pageSize), (this.totalItems > 1 ? this.totalItems - 1  : this.totalItems));


  //  // updated version-2: Date: 20231013:
  //  // ----------------------------------
  //  let startIndex = this.currentPage - Math.floor(this.pageSize / 2) - 1;
  //  let endIndex = this.currentPage + Math.floor(this.pageSize / 2) - 1;

  //  // create an array of pages to ng-repeat in the pager control
  //  const pages = this.range(startPage, endPage, 1) ;

  //  // return object with all the pager properties required by the view
  //  this.pagerChat = new PagerChat();
  //  this.pagerChat.totalItems = this.totalItems;
  //  this.pagerChat.currentPage = this.currentPage;
  //  // this.pagerChat.currentBrdCrmPage = this.breadcrumRing[this.currentPage - 1];
  //  // this.pagerChat.previousPage = this.currentPage - 1 >= 1 ? this.currentPage - 1  : this.currentPage;
  //  // if (this.pagerChat.previousPage >= 1) {
  //  //   this.pagerChat.previousBrdCrmPage = this.breadcrumRing[this.pagerChat.previousPage - 1];
  //  // }
  //  // this.pagerChat.nextPage = this.currentPage + 1 <= totalItems ? this.currentPage + 1  : this.currentPage;
  //  // if (this.pagerChat.nextPage <= this.totalItems) {
  //  //   this.pagerChat.nextBrdCrmPage = this.breadcrumRing[this.pagerChat.nextPage - 1];
  //  // }
  //  this.pagerChat.pageSize = this.pageSize;
  //  this.pagerChat.totalPages = totalPages;
  //  this.pagerChat.startPage = startPage;
  //  this.pagerChat.endPage = endPage;
  //  this.pagerChat.startIndex = startIndex;
  //  this.pagerChat.endIndex = endIndex;
  //  this.pagerChat.pages = pages;

  //  // return this.pagerChat;
  //  return {
  //    totalItems: totalItems,
  //    currentPage: currentPage,
  //    pageSize: pageSize,
  //    totalPages: totalPages,
  //    startPage: startPage,
  //    endPage: endPage,
  //    startIndex: startIndex,
  //    endIndex: endIndex,
  //    pages: pages
  //  };
  //}

  // --------------------------------------------------
  // Can be used for any array. 
  // just the array's totalItems, currentPage and pageSize are required.
  // Note:  the startPage, endPage, startIndex and endIndex values need attention!
  //        These can be refactored/computed according to GatPagerDynamic() method's 
  //        algorithm to get the desired values for these variables.
  // --------------------------------------------------
  // --------------------------------------------------
  //public GetPagerOfSize10 (totalItems : any, currentPage : any, pageSize : any) {
  //  // default to first page
  //  currentPage = currentPage || 1;

  //  // default page size is 10
  //  pageSize = pageSize || 10;

  //  // calculate total pages
  //  const totalPages = Math.ceil(totalItems / pageSize);

  //  let startPage;
  //  let endPage;
  //  if (totalPages <= 10) {
  //    // less than 10 total pages so show all
  //    startPage = 1;
  //    endPage = totalPages;
  //  } else {
  //    // more than 10 total pages so calculate start and end pages
  //    if (currentPage <= 6) {
  //      startPage = 1;
  //      endPage = 10;
  //    } else if (currentPage + 4 >= totalPages) {
  //      startPage = totalPages - 9;
  //      endPage = totalPages;
  //    } else {
  //      startPage = currentPage - 5;
  //      endPage = currentPage + 4;
  //    }
  //  }

  //  // calculate start and end item indexes:
  //  // Found to be incorrect on 20221213:
  //  // ------------------------------------
  //  // const startIndex = (currentPage - 1) * pageSize;
  //  // const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  //  // updated version-2: Date: 20231013:
  //  // ----------------------------------
  //  let startIndex = this.currentPage - Math.floor(this.pageSize / 2) - 1;
  //  let endIndex = this.currentPage + Math.floor(this.pageSize / 2) - 1;


  //  // create an array of pages to ng-repeat in the pager control
  //  const pages = this.range(startPage, endPage, 1);

  //  // return object with all pager properties required by the view
  //  this.pagerChat.totalItems = totalItems;
  //  this.pagerChat.currentPage = currentPage;
  //  this.pagerChat.pageSize = pageSize;
  //  this.pagerChat.totalPages = totalPages;
  //  this.pagerChat.startPage = startPage;
  //  this.pagerChat.endPage = endPage;
  //  this.pagerChat.startIndex = startIndex;
  //  this.pagerChat.endIndex = endIndex;
  //  this.pagerChat.pages = pages;
  //  // return this.pagerChat;
  //  return {
  //    totalItems: totalItems,
  //    currentPage: currentPage,
  //    pageSize: pageSize,
  //    totalPages: totalPages,
  //    startPage: startPage,
  //    endPage: endPage,
  //    startIndex: startIndex,
  //    endIndex: endIndex,
  //    pages: pages
  //  };
  //}
  // --------------------------------------------------
  // Can be used for any array. 
  // just the array's totalItems, currentPage and pageSize are required.
  // Note:  the startPage, endPage, startIndex and endIndex values need attention!
  //        These can be refactored/computed according to GatPagerDynamic() method's 
  //        algorithm to get the desired values for these variables.
  // --------------------------------------------------
  // --------------------------------------------------
  // public GetPagerOfSize5 (totalItems : any, currentPage : any, pageSize : any): any {
  //  // default to first page
  //  this.currentPage = currentPage > 0 ? currentPage  : 1;
  //  this.totalItems = totalItems > 0 ? totalItems  : 1;
  //  // default page size is 5
  //  this.pageSize = pageSize > 0 ? pageSize  : 5;

  //  // calculate total pages
  //  const totalPages = Math.ceil(this.totalItems / this.pageSize);

  //  let startPage;
  //  let endPage;
  //  if (totalPages <= 5) {
  //    // less than 5 total pages so show all
  //    startPage = 1;
  //    endPage = totalPages;
  //  } else {
  //    // more than 5 total pages so calculate start and end pages
  //    if (this.currentPage <= 3) {
  //      startPage = 1;
  //      endPage = 5;
  //    } else if (this.currentPage + 2 >= totalPages) {
  //      startPage = totalPages - 4;
  //      endPage = totalPages;
  //    } else {
  //      startPage = this.currentPage - 3;
  //      endPage = this.currentPage + 2;
  //    }
  //  }

  //  // calculate start and end item indexes
  //  // Updated version: Found to be incorrect on 20231013:
  //  // ----------------------------------------------------
  //  // const startIndex = totalItems <= this.pageSize ? (this.currentPage - 1)
  //  //      : this.currentPage > this.pageSize && this.pageSize < totalPages ? (this.currentPage - 1) * this.pageSize  : 0;
  //  // const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1  : this.pageSize), (this.totalItems > 1 ?
  //  //                  this.totalItems - 1  : this.totalItems));

  //  // original version:
  //  // ------------------
  //  // let startIndex = (this.currentPage - 1) * this.pageSize;
  //  // let endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1
  //  //               : this.pageSize), (this.totalItems > 1 ? this.totalItems - 1  : this.totalItems));

  //  // updated version-2: Date: 20231013:
  //  // ----------------------------------
  //  let startIndex = this.currentPage - Math.floor(this.pageSize / 2) - 1;
  //  let endIndex = this.currentPage + Math.floor(this.pageSize / 2) - 1;

  //  // create an array of pages to ng-repeat in the pager control
  //  const pages = this.range(startPage, endPage, 1);

  //  // return object with all pager properties required by the view
  //  this.pagerChat.totalItems = this.totalItems;
  //  this.pagerChat.currentPage = this.currentPage;
  //  this.pagerChat.pageSize = this.pageSize;
  //  this.pagerChat.totalPages = totalPages;
  //  this.pagerChat.startPage = startPage;
  //  this.pagerChat.endPage = endPage;
  //  this.pagerChat.startIndex = startIndex;
  //  this.pagerChat.endIndex = endIndex;
  //  this.pagerChat.pages = pages;
  //  // return this.pagerChat;
  //  return {
  //    totalItems: totalItems,
  //    currentPage: currentPage,
  //    pageSize: pageSize,
  //    totalPages: totalPages,
  //    startPage: startPage,
  //    endPage: endPage,
  //    startIndex: startIndex,
  //    endIndex: endIndex,
  //    pages: pages
  //  };
  //}
  // ==================================================
  // --------------------------------------------------
  // Deprecated!/ Use as a reference
  // --------------------------------------------------
  //public getPagerOriginal (totalItems : any, currentPage : any, pageSize : any): any {
  //  // default to first page
  //  this.currentPage = currentPage > 0 ? currentPage  : 1;
  //  this.totalItems = totalItems > 0 ? totalItems  : 1;
  //  // default page size is 10
  //  this.pageSize = pageSize > 0 ? pageSize  : 10;

  //  // calculate total pages
  //  const totalPages = Math.ceil(this.totalItems / this.pageSize);

  //  let startPage;
  //  let endPage;
  //  if (totalPages <= 10) {
  //    // less than 10 total pages so show all
  //    startPage = 1;
  //    endPage = totalPages;
  //  } else {
  //    // more than 10 total pages so calculate start and end pages
  //    if (this.currentPage <= 6) {
  //      startPage = 1;
  //      endPage = 10;
  //    } else if (this.currentPage + 4 >= totalPages) {
  //      startPage = totalPages - 9;
  //      endPage = totalPages;
  //    } else {
  //      startPage = this.currentPage - 5;
  //      endPage = this.currentPage + 4;
  //    }
  //  }

  //  // calculate start and end item indexes
  //  const startIndex = (this.currentPage - 1) * this.pageSize;
  //  const endIndex = Math.min(startIndex + (this.pageSize > 1 ? this.pageSize - 1
  //                    : this.pageSize), (this.totalItems > 1 ? this.totalItems - 1  : this.totalItems));

  //  // create an array of pages to ng-repeat in the pager control
  //  const pages = this.rangeOriginal(startPage, endPage, 0.5);

  //  // return object with all pager properties required by the view
  //  this.pagerChat.totalItems = this.totalItems;
  //  this.pagerChat.currentPage = this.currentPage;
  //  this.pagerChat.pageSize = this.pageSize;
  //  this.pagerChat.totalPages = totalPages;
  //  this.pagerChat.startPage = startPage;
  //  this.pagerChat.endPage = endPage;
  //  this.pagerChat.startIndex = startIndex;
  //  this.pagerChat.endIndex = endIndex;
  //  this.pagerChat.pages = pages;
  //  return {
  //    totalItems: totalItems,
  //    currentPage: currentPage,
  //    pageSize: pageSize,
  //    totalPages: totalPages,
  //    startPage: startPage,
  //    endPage: endPage,
  //    startIndex: startIndex,
  //    endIndex: endIndex,
  //    pages: pages
  //  };
  //}
  // --------------------------------------------------
  // Deprecated!/ Use as a reference
  // --------------------------------------------------
  //public rangeOriginal (min : any, max : any, delta : any) {
  //  const arr :any[] = [];
  //  let stepCount;
  //  if (min && !max && !delta) {
  //    for (let i = 0; i < min; i++) {
  //      arr[i] = i + 1;
  //    }
  //  } else {
  //    if (min && max && !delta) {
  //      stepCount = (max - min);
  //      for (let i = 0; i <= stepCount; i++) {
  //        arr.push(i + min);
  //      }
  //    } else {
  //      stepCount = Math.floor((max - min) / delta);
  //      for (let i = 0; i <= stepCount; i++) {
  //        arr.push(i * delta + min);
  //      }
  //    }
  //  }
  //  return arr;
  //}
  // --------------------------------------------------
}
