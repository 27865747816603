
import { Injectable } from '@angular/core';
import { DisplayProfileService } from './displayProfileService.service';

@Injectable({
  providedIn: 'root',
})
export class DisplayProfileServiceService  {
  
  constructor(
    public displayProfileService : DisplayProfileService
  ) {    
  }
  // ------------------------------------------------------------------------------------------------
  public setDisplayProfileService (pbs : DisplayProfileService): void {
    this.displayProfileService = pbs;
  }
  public getDisplayProfileService () : DisplayProfileService {
    return this.displayProfileService;
  }
  // ------------------------------------------------------------------------------------------------
  
}
