<div class="flex-container spinner-wrapper" id="spinnerComponentId" style="margin:0.25rem; background-color: rgb(255, 255, 255, 0);">
  <div class="row flex-button" style="background-color: rgb(255, 255, 255, 0);" (click)="stopSpinner()">
    <div id="loader" class="spinnerBorder" style="background-color: rgb(255, 255, 255, 0);">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="loading"></div>
    </div>
    <div class="cssload-spinner"></div>
    <div *ngIf="spinnerModel?.message?.length > 0" [innerHTML]="spinnerModel?.message | safe: 'html'" class="spinner-message text-center" style="color:blue;font-size: 14px;">
      {{spinnerModel.message}}
    </div>    
  </div>
</div>
 
    

<!--Ref:ref:https://danielk.tech/home/angular-how-to-add-a-loading-spinner -->
<!--<div *ngIf="this.spinnerLoader.getLoading()" class="cssload-container">
  <div class="cssload-speeding-wheel"></div>
</div>-->
