<!--*ngIf="distanceUnit?.distance >= 0"-->
<div *ngIf="distanceUnit?.distance >= 0"
     id="distanceComponentId"
     #distanceunit
     class="profileName text-left decrease"
     style="font-size:0.65rem;font-weight:bold;display:inline;">
  {{distanceUnit?.distance  | toNumber | number:'1.2-2'}} {{distanceUnit?.unit}}
  <span *ngIf="!distanceUnit && profileTile?.distanceStr?.length > 0"
        style="width: auto; font-size: 0.65rem; font-weight: bold; display: inline;"
        [innerHTML]="profileTile?.distanceStr | safe : 'html'">
    {{ profileTile?.distanceStr }}
  </span>
</div>

<div *ngIf="isDefaultDistanceUnit"
     id="distanceComponentId"
     #distanceunit
     class="profileName text-left decrease"
     style="font-size:0.65rem;font-weight:bold;display:inline;">
      {{distanceUnit?.unit}}
  <span *ngIf="!distanceUnit && profileTile?.distanceStr?.length > 0"
        style="width: auto; font-size: 0.65rem; font-weight: bold; display: inline;"
        [innerHTML]="profileTile?.distanceStr | safe : 'html'">
    {{ profileTile?.distanceStr }}
  </span>
</div>
