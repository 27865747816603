'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { Bool } from '../../../models/common/bool.model';
import { KvAny } from '../../../models/keyValue/kvAny.model';
import { MemberViewMgmtModel } from '../../../models/member/memberViewMgmtModel.model';
import { ProfileInfo } from '../../../models/profile/profileInfo.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { GlyphiconMenuService } from '../../../services/GlyphiconService/glypgiconMenuService.service';
import { GlyphiconServiceService } from '../../../services/GlyphiconService/glyphiconServiceService.service';
import { KvArrayService } from '../../../services/coreServiceService/kvArrayService.service';
import { DisplayProfileService } from '../../../services/displayProfileServiceService/displayProfileService.service';
import { MemberService } from '../../../services/memberServiceService/memberService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { ProfileServiceService } from '../../../services/profileServiceService/profileServiceService.service';
import { ProfileInfoService } from '../../../services/profileServiceService/profileInfoService.service';
import { DictionaryServiceService } from '../../../services/dictionaryServiceService/dictionaryServiceService.service';
import { DictionaryService } from '../../../services/dictionaryServiceService/dictionaryService.service';
import { DbDexieToDictionaryService } from '../../../services/dbServiceService/dbDexieToDictionaryService.service';
import { DbServiceService } from '../../../services/dbServiceService/dbServiceService.service';

@Component({
  selector: 'app-profile-info-key-value-arr',
  templateUrl: './profileInfoKeyValueArr.component.html',
  styleUrls: [ './profileInfoKeyValueArr.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [Animations, slideInOutAnimation ],
})
export class ProfileInfoKeyValueArrComponent implements AfterViewInit, OnInit, OnDestroy {
  public dictionaryService! : DictionaryService;
  public dbDexieToDictionaryService! : DbDexieToDictionaryService;
  public kvArrayService!: KvArrayService;
  // public gestureService: GestureService;
  public glyphiconMenuService! : GlyphiconMenuService;
  public memberService! : MemberService;
  public profileInfoService! : ProfileInfoService;
  public rendererService!: RendererService;
  public renderer!: Renderer2;
  // -----------------------------------------------
  public askMe = 'Ask Me :)';
  @Input() profileInfoKvAnyArr : KvAny[] = [];
  public IsOpen = new Bool();
  public isProfInfoClosedBool: Bool = new Bool();
  public kvLegend: string[] = ['Profile description', 'About me', 'My preferences', 'What I am looking for', 'What I am into'];
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public loginSuccess! : LoginSuccess;  
  public profileInfo : ProfileInfo = new ProfileInfo();
  public sitUser : SitUser = new SitUser();
  @Input() sitUserId: number = 0;

  constructor(
    private cdr : ChangeDetectorRef,
    private dbServiceService: DbServiceService,
    private dictionaryServiceService : DictionaryServiceService,
    private profileServiceService : ProfileServiceService,
    private glyphiconServiceService : GlyphiconServiceService,
  ) {
    this.glyphiconMenuService = this.glyphiconServiceService.getGlyphiconMenuService();
    this.profileInfoService = this.profileServiceService.getProfileInfoService();
    if (this.dictionaryServiceService) {
      this.dictionaryService = this.dictionaryServiceService.getDictionaryService();
    }
    this.dbDexieToDictionaryService = this.dbServiceService.getDbDexieToDictionaryService();
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    
    if (this.sitUserId > 0) {
      // get dictionary-value first:
      // ---------------------------
      this.profileInfo = this.dictionaryService.profileInfoDictionary.get(this.sitUserId);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)) {
        this.profileInfoKvAnyArr = this.profileInfoService.createKVModelArrFromProfileInfoView(this.profileInfo);
        this.ngAfterViewInit();
        // debugger;
      }
      // get backend-server value:
      // ---------------------------
      this.getProfileInfoData(this.sitUserId);
    }

    EmitterSubjectService.profileInfoKvAnyArrEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result) && result.length > 0) {
          this.profileInfoKvAnyArr = result as KvAny[];
          // debugger;
          this.ngAfterViewInit();
        }
      });
  }
  // -------------------------------------------------------------
  public ngAfterViewInit () : void {
    // EmitterSubjectService.emitHideSpinner(true);    
    // debugger;
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.profileInfoKvAnyArr = [];

    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.dbDexieToDictionaryService
      .saveDictionaryToDbDexie(
        this.dictionaryService.profileInfoDictionary, 'profileInfo', this.loginSuccess.signedInUserId);
    // debugger;
  }
  // ---------------------------------------------------------------------------------
  //  Note: isOpen is opposite of isClosed.
  // ---------------------------------------------------------------------------------
  isOpen (isClosed : any)
  {
    // debugger;
    this.IsOpen.isClosed = isClosed;
    this.IsOpen.name = 'profileInfoKeyValueArrComponentId';
    // debugger;
    if (!this.IsOpen.isClosed) {
      // debugger;
      this.getProfileInfoData(this.sitUserId);
    }

    EmitterSubjectService.setIsViewMember(true);  // TODO: may need to set instead of emitting
    
  }
  // ---------------------------------------------------------------------------------
  public getProfileInfoData (sitUserId : number):void {
    if (sitUserId > 0) {
      // debugger;
      this.profileInfoService.getProfileInfo(sitUserId).subscribe(data => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
          this.profileInfo = data;
          this.profileInfoKvAnyArr = this.profileInfoService.createKVModelArrFromProfileInfoView(this.profileInfo);
        }
      });
    }
  }
  // ---------------------------------------------------------------
 //public getProfileInfoData (sitUserId : number) {
 //  if (sitUserId > 0) { 
 //    // debugger;
 //    this.displayProfileService.displayUserDataFromDictionary(sitUserId).subscribe(data => {
 //      // debugger;
 //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
 //        this.extractDataFromMemberViewMgmtModel(data);
 //        return this.memberViewMgmtModel = data;
 //      }
 //      else return null;
 //    });
 //   }
 // }
  // ---------------------------------------------------------------
  //public extractDataFromMemberViewMgmtModel (data : MemberViewMgmtModel) : void {
  //  // debugger;
  //  this.memberViewMgmtModel = data; // EmitterSubjectService.getMemberViewMgmtModel(); // Watch: location-View -4

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel)) {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.profileInfoView) && this.memberViewMgmtModel.profileInfoView.sitUserId > 0) {
  //      this.profileInfoView = this.memberViewMgmtModel.profileInfoView;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.kvAnyArr) && this.memberViewMgmtModel.kvAnyArr.length > 0) {
  //      this.profileInfoKvAnyArr = this.memberViewMgmtModel.kvAnyArr;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.profileContentKvAnyArr) && this.memberViewMgmtModel.profileContentKvAnyArr.length > 0) {
  //      this.profileContentKvAnyArr = this.memberViewMgmtModel.profileContentKvAnyArr;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.preferenceKvAnyArr) && this.memberViewMgmtModel.preferenceKvAnyArr.length > 0) {
  //      this.preferenceKvAnyArr = this.memberViewMgmtModel.preferenceKvAnyArr;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.profileTile)
  //      && this.memberViewMgmtModel.profileTile.sitUserId > 0) {
  //      this.profileTile = this.memberViewMgmtModel.profileTile;
  //    }
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.sitUser)
  //      && this.memberViewMgmtModel.sitUser.sitUserId > 0) {
  //      this.sitUser = this.memberViewMgmtModel.sitUser;
  //    }
  //  }
  //}
  // ---------------------------------------------------------------------------------
}
