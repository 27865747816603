'use-strict'
import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../../../models/account/loginSuccess.model';
import { Bool } from '../../../../models/common/bool.model';
import { KeyValueResult } from '../../../../models/keyValue/keyValueResult.model';
import { KvAnyArr } from '../../../../models/keyValue/kvAnyArr.model';
import { ProfileInfo } from '../../../../models/profile/profileInfo.model';
import { CoreServiceService } from '../../../../services/coreServiceService/coreServiceService.service';
import { KvArrayService } from '../../../../services/coreServiceService/kvArrayService.service';
import { EmitterSubjectService } from '../../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Animations, slideInOutAnimation } from '../../../animation';

@Component({
  selector: 'app-key-value-any-arr',
  templateUrl: './keyValueAnyArr.component.html',
  styleUrls: ['./keyValueAnyArr.component.scss'],
  animations: [
    Animations,
    trigger('growInShrinkOut', [
      transition(':enter', [style({ transform: 'scale(0.1)'}), animate('900ms ease-in', style({ transform: 'scale(1)'}))]),
      transition(':leave', [animate('700ms ease-in', style({ transform: 'scale(0.1)'}))]),
    ]),
  ],
})
export class KeyValueAnyArrComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  public kvArrayService!: KvArrayService;
  @Input() arrKvAnyArr: KvAnyArr[] = [];
  @Input() arrKvLegend = '';
  @Input() arrKvName = '';
  @Input() isFormSubmitted = false;
  @Input() isRequired = false;
  public kvLegend: string[] = ['Profile description', 'About me', 'My Pictures', 'My preferences', 'What I am looking for', 'What I am into'];
  public kvName: string[] = ['Description', 'ProfileInfo', 'Preferences'];
  public isImage = false;
  public imageData = '';
  public images: string[] = [];
  public arrKvForm!: FormGroup;
  public arrKvResult: KeyValueResult = new KeyValueResult();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isClosedBool: Bool = new Bool();
  public isMobilevar = false;
  public isClosed = true;
  public loginSuccess!: LoginSuccess;
  public profileInfo: ProfileInfo = new ProfileInfo();
  public selectedArrKvAny!: FormControl;
  public selectedArrKvAnyIndex = -1;
  // ---------------------------------------------------------------
  constructor (
    private coreServiceService : CoreServiceService,
    private cdr : ChangeDetectorRef,
    @Inject( DOCUMENT ) document: any )
  {
    if (coreServiceService) {
      this.kvArrayService = coreServiceService.getKvArrayService();
    }

    EmitterSubjectService.modelEmitter.subscribe((result) => {
      // debugger;
      this.profileInfo = result as ProfileInfo;
      if (this.profileInfo) {
        this.arrKvAnyArr = this.kvArrayService.getObjectModelToArrKvAny(this.profileInfo);
        // debugger;
      }
      // EmitterSubjectService.emitMyErrorLog(result);
    });

    this.arrKvResult.kvName = this.arrKvName;
    // debugger;
  }
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    // debugger;
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit() {
    /*
     * this.cdr.detectChanges();
     * debugger;
     */
  }

  // ---------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges): void {
    /*
     * only run when property 'data' changed
     * debugger;
     */
    if (this.isImageFound() === true) {
      this.isImageData(this.imageData);
    }
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  /*
   * ---------------------------------------------------------------
   *  Note: IMPORTANT!!
   *  Keep this as its is used to fix linting error that results in the following two functions.
   * ---------------------------------------------------------------
   * isImageFound(): boolean {
   *  if (this.arrKvAnyArr) {
   *    let i = 0;
   *    const arKeys = Array.from(Object.keys(this.arrKvAnyArr));
   *    const arValues = Array.from(Object.values(this.arrKvAnyArr), v => {
   *      // debugger;
   *      if (arKeys[i].toString().toLowerCase().indexOf('image') !== -1) {
   *        this.images.push(v.toString());
   *        return true;
   *      }
   *      else if (this.arrKvAnyArr && this.arrKvAnyArr.values[i] && this.arrKvAnyArr.values[i].key
   *        && this.arrKvAnyArr.values[i].key.toString().toLowerCase().indexOf('image') !== -1) {
   *        this.images.push(this.arrKvAnyArr.values[i].toString());
   *        return true;
   *      }
   *      else if (this.arrKvAnyArr && this.arrKvAnyArr.values[i] && this.arrKvAnyArr.values[i].key && this.arrKvAnyArr.values[i].value
   *        && this.arrKvAnyArr.values[i].value.toString().toLowerCase().indexOf('image') !== -1) {
   *        this.images.push(this.arrKvAnyArr.values[i].value.toString());
   *        return true;
   *      }
   *      i++;
   *    });
   *  }
   *  // debugger;
   *  return false;
   * }
   * ---------------------------------------------------------------
   */
  isImageFound(): boolean {
    if (this.arrKvAnyArr) {
      const arKeys = Array.from(Object.keys(this.arrKvAnyArr));
      const arValues = Array.from(
        Object.values(this.arrKvAnyArr),
        (v) => this.findImageForIsImageFound(arKeys, this.arrKvAnyArr, v)
        // debugger;
      );
    }
    // debugger;
    return false;
  }
  // ---------------------------------------------------------------
  findImageForIsImageFound(arKeys: any, arrKvAnyArr: any, v: any): any {
    let i = 0;

    for (const e of arKeys) {
      if (e.toString().toLowerCase().indexOf('image') !== -1) {
        this.images.push(v.toString());
        return true;
      } else if (arrKvAnyArr && arrKvAnyArr.length > 0) {
        for (const f of arrKvAnyArr) {
          if (f.values[i] && f.values[i].key && f.values[i].key.toString().toLowerCase().indexOf('image') !== -1) {
            this.images.push(f.values[i].toString());
            return true;
          } else if (f.values[i] && f.values[i].key && f.values[i].value && f.values[i].value.toString().toLowerCase().indexOf('image') !== -1) {
            this.images.push(f.values[i].value.toString());
            return true;
          }
        }
      }
      i++;
    }
  }
  // ---------------------------------------------------------------
  isImageData (data : any): boolean {
    // var dataUriPattern = '/^data\:(?<type>image\/(png|tiff|jpg|gif));base64,(?<data>[A-Z0-9\+\/\=]+)$/g';
    const dataUriPattern = '/data:(?image/(png|tiff|jpg|gif));base64,(?[A-Z0-9+/=]+)$/ig';

    if (data.match(dataUriPattern).length > 0) {
      this.isImage = true;
      // debugger;
      return true;
    }
    return false;
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// www.positronx.io/angular-checkbox-tutorial/
   */
  onKeyValueChange (e : any) {
    if (e.target.checked) {
      /*
       * this.selectedRadiobox = this.radioboxForm.get('radioSelection') as FormControl;
       * this.selectedRadiobox.patchValue('');
       * this.selectedRadiobox.patchValue(e.target.value);
       */

      this.arrKvResult.kvName = this.arrKvName;
      this.arrKvResult.kvSelection = e.target.value;
      this.arrKvResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
      EmitterSubjectService.emitKeyValueResult(this.arrKvResult);
    }
  }
  /*
   * --------------------------------------------------------------
   * Ref;https:// bytenota.com/display-base64-image-in-html-using-javascript-and-jquery/
   */
  displayBase64Image (placeholder : any, base64Image : any) {
    const image = document.createElement('img');

    image.onload = function () {
      placeholder.innerHTML = '';
      placeholder.appendChild(this);
    };
    image.src = base64Image;
  }
  // --------------------------------------------------------------
  getUrl (data : any): any {
    // image placeholder where the image will be displayed
    let imagePlaceholder = document.getElementById('image-placeholder-1');

    // display the image in placeholder
    if (imagePlaceholder) {
      this.displayBase64Image(imagePlaceholder, data);
    }

    imagePlaceholder = document.getElementById('image-placeholder-2');

    // display the image in placeholder
    if (imagePlaceholder) {
      this.displayBase64Image(imagePlaceholder, data);
    }
    imagePlaceholder.remove();
    // return'url('' + data + '')';
    return data;
  }
  // ---------------------------------------------------------------
  isRequiredPassed() {
    return this.arrKvResult.kvSelection.length > 0;
  }
  // ---------------------------------------------------------------
}
