export class Communicator {
  public communicationActivityId = 0;
  public communicatorType = ''; // {receiver, sender}
  public cryptUserKey = '';
  public date = '';
  public email = '';
  public id = 0;
  public latitude = 0;
  public longitude = 0;
  public profileId = 0;
  public profileName = '';
  public primaryImage = '';
  public primaryPhotoBnId = 0;
  public sitUserId = 0;
  public sitUserKey = '';
  constructor() { }
}
