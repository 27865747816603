
import { Injectable } from '@angular/core';
import { Filter, Sorter } from '../../models/common/genericSearchSortFilter.model';

@Injectable({
  providedIn: 'root'
})


// Ref:https://medium.com/javascript-in-plain-english/react-and-typescript-generic-search-sort-and-filter-879c5c3e2f0e


export class GenericSearchSortFilterService {
  constructor() {
  }
  // ---------------------------------------------------------------
  // case insensitive search of n-number properties of type T
  // returns true if at least one of the property values includes the query value
  // ---------------------------------------------------------------
  genericSearch<T>(
    object: T,
    properties: Array<keyof T>,
    query: string
  ): boolean {
    if (query === '') {
      return true;
    }
    return properties.some((property) => {
      const value = object[property];
      if (typeof value === 'string' || typeof value === 'number') {
        return value.toString().toLowerCase().includes(query.toLowerCase());
      }
      return false;
    });
  }

  // ---------------------------------------------------------------
  // comparator function for any property within type T
  // works for: strings, numbers, and Dates (and is typed to accept only properties which are those types)
  // could be extended for other types but would need some custom comparison function here
  // ---------------------------------------------------------------
  genericSort<T>(
    objectA: T,
    objectB: T,
    sorter: Sorter<T>
  ) {
    const result = () => {
      if (objectA[sorter.property] > objectB[sorter.property]) {
        return 1;
      } else if (objectA[sorter.property] < objectB[sorter.property]) {
        return -1;
      } else {
        return 0;
      }
    };
    return sorter.isDescending ? result() * -1 : result();
  }
  // --------------------------------------------------------------
  // filter n properties for truthy or falsy values on type T (no effect if no filter selected)
  // ---------------------------------------------------------------
  genericFilter<T>(object: T, filters: Array<Filter<T>>) {
    // no filters; no effect - return true
    if (filters.length === 0) {
      return true;
    }
    return filters.every((filter) => {
      return filter.isTruthyPicked ?
        object[filter.property] :
        !object[filter.property];
    });
  }
  // ---------------------------------------------------------------
  // Usage example:
  // ---------------------------------------------------------------
  /*
   * return (
    <>
        {widgets
            .filter((widget) =>
                genericSearch<IWidget>(widget, ["title", "description"], query)
            )
            .sort((widgetA, widgetB) =>
                genericSort<IWidget>(widgetA, widgetB, activeSorter)
            )
            .filter((widget) =>
                genericFilter<IWidget>(widget, activeFilters)
            ).map(widget =>
                return <SomeComponentToRenderYourWidget {...widget}/>
            )
        }
    </>
)
   */
  // ---------------------------------------------------------------
}
