'use-strict'
import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Animations, slideInOutAnimation } from '../../../../app/animation';
import { LoginSuccess } from '../../../../models/account/loginSuccess.model';
import { KeyValueResult } from '../../../../models/keyValue/keyValueResult.model';
import { KVArr } from '../../../../models/keyValue/kvArr.model';
import { CoreServiceService } from '../../../../services/coreServiceService/coreServiceService.service';
import { EmitterSubjectService } from '../../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';

@Component({
  selector: 'app-key-value-arr',
  templateUrl: './keyValueArr.component.html',
  styleUrls: ['./keyValueArr.component.scss'],
  animations: [
    Animations,
    trigger('slideInOut', [
      transition(':enter', [style({ transform: 'scale(0.1)'}), animate('500ms ease-in', style({ transform: 'scale(1)'}))]),
      transition(':leave', [animate('500ms ease-in', style({ transform: 'scale(0.1)'}))]),
    ]),
  ],
})
export class KeyValueArrComponent implements OnInit, OnDestroy {
  public emitterSubjectService!: EmitterSubjectService;
  @Input() arrkVArr: KVArr[] = [];
  @Input() kVArrLegend = '';
  @Input() kVArrName = '';
  @Input() isFormSubmitted = false;
  @Input() isRequired = false;
  public kvArrForm!: FormGroup;
  public kvArrResult: KeyValueResult = new KeyValueResult();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isClosed = true;
  public loginSuccess!: LoginSuccess;
  public selectedKvArr!: FormControl;
  public selectedKvArrIndex = -1;
  // ---------------------------------------------------------------
  constructor(
    private coreServiceService: CoreServiceService,
    private fb: FormBuilder,
    private router: Router,
    @Inject(DOCUMENT) document : any
  ) {
    if (this.isRequired) {
      this.kvArrForm = this.fb.group({
        /*
         * 'radioSelection': this.fb.control(this.radioboxName, [
         * Validators.required])
         */
      });
    }
    /*
     * else this.radioboxForm = this.fb.group({
     * 'radioSelection': this.fb.control(this.radioboxName)
     * });
     */
    this.kvArrResult.kvName = this.kVArrName;
    // debugger;
  }
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// www.positronx.io/angular-checkbox-tutorial/
   */
  onKeyValueChange (e : any) {
    if (e.target.checked) {
      /*
       * this.selectedRadiobox = this.radioboxForm.get('radioSelection') as FormControl;
       * this.selectedRadiobox.patchValue('');
       * this.selectedRadiobox.patchValue(e.target.value);
       */

      this.kvArrResult.kvName = this.kVArrName;
      this.kvArrResult.kvSelection = e.target.value;
      this.kvArrResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
      EmitterSubjectService.emitKeyValueResult(this.kvArrResult);
    }
  }
  // ---------------------------------------------------------------
  isRequiredPassed() {
    return this.kvArrResult.kvSelection.length > 0;
  }
  // ---------------------------------------------------------------
}
