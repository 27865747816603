// import { ModuleComponentLoader } from '../common/moduleComponentLoader.model';
import { KvAny } from '../keyValue/kvAny.model';

// Note: the Kv name where the first leter is capital and the second letter is lowercase, implies that the value is of type 'any'
export class BrdCrmKvAny {
  public date = '';
  public id: any;
  // to be used by programmer during search to store transient value such as index of the item in array; not to store any value to persist
  public index = 0;
  public key: any;
  public modelName = '';
  public pageNo = 0;
  public returnUrl = '';
  public sitUserId = 0;
  public value: any;
  constructor() {
  }
  copy(a: BrdCrmKvAny): BrdCrmKvAny {
    const that = new BrdCrmKvAny();
    if (a.date) { that.date = a.date; }
    if (a.id) { that.id = a.id; }
    if (a.key) { that.key = a.key; }
    if (a.modelName) { that.modelName = a.modelName; }
    if (a.pageNo) { that.pageNo = a.pageNo; }
    if (a.sitUserId) { that.sitUserId = a.sitUserId; }
    if (a.value) { that.value = a.value; }
    return that;
  }

  copyFromKvAny(a: KvAny): BrdCrmKvAny {
    const that = new BrdCrmKvAny();
    if (a.date) { that.date = a.date; }
    if (a.id) { that.id = a.id; }
    if (a.index) { that.pageNo = a.index; }
    if (a.key) { that.key = a.key; }
    if (a.modelName) { that.modelName = a.modelName; }
    if (a.sitUserId) { that.sitUserId = a.sitUserId; }
    if (a.value) { that.value = a.value; }
    return that;
  }
}
