<ul id="preferenceKeyValueArrComponentId" [@slideInOut] class="flex-item-main text-center shadowSilverInner"
    style="list-style: none; width: 90%; margin: 0.5rem 1rem 1rem 1rem; padding: 0px; border-radius: 5px; opacity: 1; background-color: midnightblue; overflow: visible;">
  <li expandMenu style="margin:0;padding:0;opacity:1;" (click)="isPreferenceClosedBool.isClosed=!isPreferenceClosedBool.isClosed;isOpen(isPreferenceClosedBool.isClosed);">   
      <!--Note: the classes: (neonBlueText, kvBttnUpload) their order is important!-->
    <p class="goldDrkRedSB kvBttnUpload" style="margin:0.01rem;padding:0.15rem 2rem 0.2rem 2rem;opacity:1;">
        <span *ngIf="kvAnyName.length > 0" style="float: left; opacity: 1; font-size: 13px; font-weight: bold;">
          {{kvAnyName}}
        </span>
        <span *ngIf="isPreferenceClosedBool.isClosed" class="glyphicon glyphicon-plus-sign hvr-icon"
              style="float: right; padding-top: 0.2rem; font-size: 13px; opacity: 1;" aria-hidden="true"></span>
        <span *ngIf="!isPreferenceClosedBool.isClosed" class="glyphicon glyphicon-minus-sign hvr-icon"
              style="float: right; padding-top: 0.2rem; font-size: 13px; opacity: 1;" aria-hidden="true"></span>
    </p>   
    <hr class="hrGray" style="display:inline-block;width:100%;padding:0;margin:0rem;" />
  </li>
  <li>
    <ul *ngIf="!isPreferenceClosedBool.isClosed" [ngClass]="{'submenu':true}" align="left" style="width: 100%;margin-left: -1.5rem;">
      <li style="padding: 0 0 0 -0.25rem;opacity:1;">
        <div *ngIf="kvAnyLegend.length > 0" class="kvBttnSk">{{kvAnyLegend}} <span *ngIf="kvAnyLegend.length > 0 && isRequired" class="errSub" style="margin-left: -2px;opacity:1;">*</span></div>
        <div *ngFor="let kvAny of preferenceKvAnyArr; let i=index">
          <div *ngIf="kvAny.value">
            <div *ngIf="kvAny.value.length > 0" class="increase">
              <div class="kvBttnSk text-center" [ngStyle]="{padding: 'i===0? 0 0 0.5rem 0:0'}"
                   style="width: 90%; text-transform: capitalize; opacity: 1; font-size: 12px; ">{{kvAny?.key}}:</div>
              <div class="kvBttnxSv text-center wordWrap" style="width:90%;text-transform:capitalize;opacity:1;">
                <span *ngFor="let v of kvAny.value; let j=index">
                  <span *ngIf="v.toLowerCase().indexOf('askme') !== -1" [ngStyle]="{padding: 'i===0? 0 0 0.5rem 0:0'}" style="text-transform: capitalize; opacity: 1; font-size: 12px;">AskMe</span>
                  <span *ngIf="v.toLowerCase().indexOf('askme') === -1" [ngStyle]="{padding: 'i===0? 0 0 0.5rem 0:0'}" style="text-transform: capitalize; opacity: 1; font-size: 12px;">{{v}}</span>
                  <span *ngIf="j != v?.value?.length -1" style="color:gold;font-weight:600;padding:2px 2.5px 2px 2.5px">
                    |
                  </span>
                </span>
              </div>
            </div>
            <hr *ngIf="kvAny.value.length > 0" class="hrGray" style="display:inline-block;width:98%;padding:0;margin:0 0 0 .75rem;" />
          </div>
        </div>

        <div *ngIf="isRequired && ! isRequiredPassed() && isFormSubmitted" ass="flex-container">
          <span class="flex-text errS" style="text-transform: capitalize;color:orangered;opacity:1;"><em>{{kvAnyName}}</em> is required, please select at leaset one value.</span>
        </div>
      </li>
    </ul>
  </li>
</ul>
<!--==========================================================================================================-->
