<div id="pagerButtonComponentId">
  <div *ngIf="isFirst || isAll" [ngClass]="{'disabled':pager.currentPageNo === 1}" style="display:inline-block">
    <button id="pageFirstBttnId" (click)="setPage(1)" [ngClass]="{'flex-button  bttn-xxs-transparent':!isMobilevar, 'flex-button  bttn-sm-transparent':isMobilevar, 'hvr-underline-from-center':true}">
      <span class="glyphicon glyphicon-step-backward hvr-icon" aria-hidden="true"></span>
    </button>
  </div>

  <div *ngIf="isFirst || isAll" [ngClass]="{'disabled':pager.currentPageNo === 1}" style="display:inline-block">
    <button id="pageBackwardBttnId" (click)="setPage(pager.currentPageNo - 1 >= pager.startPageNo ? pager.currentPageNo - 1: pager.startPageNo)" [ngClass]="{'flex-button  bttn-xxs-transparent':!isMobilevar, 'flex-button  bttn-sm-transparent':isMobilevar, 'hvr-underline-from-center':true}">
      <span class="glyphicon glyphicon-backward hvr-icon" aria-hidden="true"></span>
    </button>
  </div>

  <button *ngIf="isPageButton || isAll"
          [ngClass]="{ 'flex-button  bttn-xxs-transparent':!isMobilevar, ' flex-button bttn-sm-transparent':isMobilevar, 'active':pager.currentPageNo === page, 'hvr-underline-from-center hvr-icon':true }"
          style="display:inline-block;"
          aria-hidden="true"
          (click)="setPage(page)"
          id="pageBttnId{{page}}">
    {{page}}
  </button>

  <div *ngIf="isLast || isAll" [ngClass]="{'disabled':pager.currentPageNo === pager.endPageNo}" style="display:inline-block">
    <button id="pageForwardBttnId" (click)="setPage(pager.currentPageNo + 1 <= pager.endPageNo ? pager.currentPageNo + 1 : pager.endPageNo)" [ngClass]="{'flex-button  bttn-xxs-transparent':!isMobilevar, 'flex-button  bttn-sm-transparent':isMobilevar, 'hvr-underline-from-center':true}">
      <span class="glyphicon glyphicon-forward hvr-icon" aria-hidden="true"></span>
    </button>
  </div>

  <div *ngIf="isLast || isAll" [ngClass]="{'disabled':pager.currentPageNo === pager.totalPages}" style="display:inline-block">
    <button id="pageLastBttnId" (click)="setPage(pager.totalPages)" [ngClass]="{'flex-button  bttn-xxs-transparent':!isMobilevar, 'flex-button  bttn-sm-transparent':isMobilevar, 'hvr-underline-from-center':true}">
      <span class="glyphicon glyphicon-step-forward hvr-icon" aria-hidden="true"></span>
    </button>
  </div>
</div>
