import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { Bool } from '../../../models/common/bool.model';
import { PhotoBn } from '../../../models/common/photoBn.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { HttpService } from '../../../services/coreServiceService/httpService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { JsRegExpServiceStatic } from '../../../services/staticServices/jsRegExpServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { ImageUploadService } from './services/imageUpload.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { Router } from '@angular/router';
import { StringServiceStatic } from '../../../services/staticServices/stringServiceStatic.service';
import { DictionaryService } from '../../../services/dictionaryServiceService/dictionaryService.service';
import { DictionaryServiceService } from '../../../services/dictionaryServiceService/dictionaryServiceService.service';
import { DbDexieToDictionaryService } from '../../../services/dbServiceService/dbDexieToDictionaryService.service';
import { DbServiceService } from '../../../services/dbServiceService/dbServiceService.service';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ Animations, slideInOutAnimation ],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class ImageUploadComponent {
  public dictionaryService! : DictionaryService;
  public dbDexieToDictionaryService! : DbDexieToDictionaryService;
  public redirectionService! : RedirectionService;
  public currentFile ?: File;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public fileInfos ?: Observable<any>;
  // public isCameraVisible = true;
  public isMobilevar = false;
  // public isOnLine = false;
  // public isFormValid = false;
  // public isMyProfile = false;
  // public isNewPhotoUploaded : boolean = false;
  // public isPhotoMgmt = false;
  // public isSubmitted = false;
  public isImageUploadFormVisible = false;
  public isShowPhotoUploadUi : Bool = new Bool();
  public isViewMember = false;
  public  kvPhoto : KvPhoto = new KvPhoto();
  public  photoBn : PhotoBn = new PhotoBn();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public progress = 0;
  public selectedFiles ?: FileList;
  public toggleCameraGlyphCounter = 0;

 

  constructor (
    private cdr : ChangeDetectorRef,
    private commonServiceService : CommonServiceService,
    public coreServiceService : CoreServiceService,
    private dbServiceService: DbServiceService,
    private dictionaryServiceServive: DictionaryServiceService,
    private uploadService : ImageUploadService,
    private router: Router) {
    if (this.commonServiceService) {
      this.redirectionService = this.commonServiceService.getRedirectionService();
    }
    if (this.dictionaryServiceServive) {
      this.dictionaryService = this.dictionaryServiceServive.getDictionaryService();
    }
    if (this.dbServiceService) {
      this.dbDexieToDictionaryService = this.dbServiceService.getDbDexieToDictionaryService();
    }
  }

  ngOnInit () : void {
    // debugger;

    EmitterSubjectService.httpEventEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe({ 
        next: (event : any) => {
          // debugger;
          if (event.type === HttpEventType.UploadProgress) {
            this.progress = Math.round(100 * event.loaded / event.total);
            this.ngAfterViewInit();
            // debugger;
          } else if (event instanceof HttpResponse) {
            this.message = event.body.message;
            // debugger;
            this.photoBn = event.body as PhotoBn;
            // debugger;
            if (!StringServiceStatic.isNullOrEmpty(this.photoBn) && this.photoBn.photoBnId > 0) {
              if (!StringServiceStatic.isNullOrEmpty(this.photoBn.fileBytes) && this.photoBn.fileBytes.length > 0) {
                this.photoBn.pic = JsRegExpServiceStatic.prefixPngToImageData(this.photoBn.fileBytes);
                this.photoBn.image = this.photoBn.pic;
              }
              // debugger;
              this.kvPhoto = new PhotoBn().toKvPhoto(this.photoBn);
              // debugger;

              this.dictionaryService.updateDictionary(this.photoBn, 'photoBn', this.photoBn.photoBnId);
              this.dbDexieToDictionaryService
                .saveDictionaryToDbDexie(
                  this.dictionaryService.photoBnDictionary, 'photoBn', this.loginSuccess.signedInUserId);

              if (!StringServiceStatic.isNullOrEmpty(this.kvPhoto) && this.kvPhoto.photoBnId > 0) {
                this.dictionaryService.updateDictionary(this.kvPhoto, 'kvPhoto', this.kvPhoto.photoBnId);
                this.dbDexieToDictionaryService
                  .saveDictionaryToDbDexie(
                    this.dictionaryService.kvPhotoDictionary, 'kvPhoto', this.loginSuccess.signedInUserId);
                // debugger;
                this.selectedFiles = undefined;
                EmitterSubjectService.emitUploadedKvPhoto(this.kvPhoto);
              }
            }
          }
        },
        error: (err : any) => {
          console.log(err);
          this.progress = 0;

          if (err.error && err.error.message) {
            this.message = err.error.message;
          } else {
            this.message = 'Could not upload the file!';
          }

          this.currentFile = undefined;
        }
      });
  }
  // ---------------------------------------------------------------
  ngAfterViewInit () : void {
    // debugger;
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  selectFile (event : any) : void {
    this.selectedFiles = event.target.files;
    // debugger;
  }
  // ---------------------------------------------------------------
  upload () : void {
    this.progress = 0;
    EmitterSubjectService.emitShowSpinner("uploading your pic...");
    if (!StringServiceStatic.isNullOrEmpty(this.selectedFiles) && this.selectedFiles.length > 0) {
      const file : File | null = this.selectedFiles.item(0);
      var kvPhoto : KvPhoto = new KvPhoto();
      if (!StringServiceStatic.isNullOrEmpty(file)) {
        this.currentFile = file;
        // debugger;
        this.uploadService.upload(this.currentFile);;
      }     
    }
  }
  // ---------------------------------------------------------------
  cancel () : void {
    this.cancelFileUpload();
    // debugger;
    this.isImageUploadFormVisible = true;
    // Note:   isCameraVisible===true :camera-gluph-menu is visible && UploadFilesComponent is hidden.
    // ------------------------------------------------------------------------------------------------

    this.toggleCameraGlyphCounter++;
    EmitterSubjectService.emitToggleCameraGlyph(this.toggleCameraGlyphCounter);
    EmitterSubjectService.emitIsCameraClosedBool(true);
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.redirectionService.setRoute(this.router, 'profileTileScroll', this.loginSuccess.signedInUserId).subscribe(data => {
      // this.pagerBrdCrm = data;
    });
  }
  // ---------------------------------------------------------------
  cancelFileUpload () : void {
    // this.content = new Content();  // TPDO: restore
    this.selectedFiles = undefined;
  }
  // ---------------------------------------------------------------
}
