'use-strict'
import {
    AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef,
    Input, OnDestroy, OnInit, ViewChild, ViewContainerRef
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Subject } from 'rxjs';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';


@Component({
  selector: 'app-key-value-module-component',
  templateUrl: './keyValueModule.component.html',
  styleUrls: ['./keyValueModule.component.scss'],
})
export class KeyValueModuleComponent implements OnInit, OnDestroy, AfterViewInit {
  // ref:https:// medium.com/angular-in-depth/lazy-loading-
  @ViewChild('anchor', { read: ViewContainerRef }) keyValueAnchor!: ViewContainerRef;
  private activeComponentIds: string[] = [];
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public keyValueFactory: any;
  @Input() mcLoader: ModuleComponentLoader = new ModuleComponentLoader();

  constructor(
    private factoryResolver: ComponentFactoryResolver,
    public cdr: ChangeDetectorRef,
    private router: Router,
    private unloadConponentRef: ElementRef, ) {
  }

  ngOnInit(): void {
  }

 // ---------------------------------------------------------------------------------
  ngAfterViewInit() {
    // debugger;
    // this.activateGesture(this.gestureDivId, '');
    this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    EmitterSubjectService.emitDisplayDefaultGlyphiconMenu();
  }
 // ---------------------------------------------------------------------------------
  public prepareRoute(outlet: RouterOutlet): any {
    const anim = 'animation';
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData[anim];
  }
 // ----------------------------------------------------------------------------------
}
