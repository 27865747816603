import { ProfileTile } from '../profile/profileTile.model';

export class ProfileTileArrEmitterModel {
  public type = ''; // indexDb/serverDb
  public arr : ProfileTile[] = [];
  public sitUserId = 0;
  public date !: Date;
  constructor() {
  }
}
