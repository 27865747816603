<div style="position: relative; display: flex; width: 100%; height: 1.5rem; margin: 98% 0 2% 0;z-index:12; position:fixed; justify-content: center; padding: 0; opacity: 1; background-color: transparent; ">

  <!--============================================================================================-->
  <button *ngIf="pagerBrdCrm !==  (null ||  undefined) && (pagerBrdCrm.pagedBrdCrmArr.length >= 2 || (pagerBrdCrm.breadcrumRing && pagerBrdCrm.breadcrumRing.length >=2))"
          id="pageBrdcrm-prev" type="button"
          (click)="setBreadcrumPage('prev')"
          class="hvr-underline-from-center whiteBlueInvSB increase"
          [ngClass]="{'bttn-transparent':'!isMobilevar', 'bttn-transparent':'isMobilevar'}"
          style="display: inline-block; z-index: 9; right: 4.5rem; padding: 0 0.15rem 0.15rem 0.15rem; margin: 0 1.5rem 0 0.5rem;border:none;background:transparent;">
    <span class="glyphicon glyphicon-arrow-left  hvr-icon drkRedGrnB"
          style="vertical-align: central; align-self: center; margin-top: -0.1rem; font-size: 30px;" aria-hidden="true">
    </span>
  </button>
  <!--============================================================================================-->
  <div style="width: 3.5rem; height: 1.25rem; background-color: transparent;position:fixed; "></div>
  <!--============================================================================================-->
  <button *ngIf="(pagerBrdCrm !==  (null ||  undefined)  && (pagerBrdCrm.pagedBrdCrmArr.length >= 3 || pagerBrdCrm.breadcrumRing.length >= 3) && pagerBrdCrm.currentPage < pagerBrdCrm.lastPage)"
          id="pageBrdcrm-next" type="button"
          (click)="setBreadcrumPage('next')"
          class="hvr-underline-from-center  whiteBlueInvSB increase"
          [ngClass]="{'bttn-s-transparent':!isMobilevar, 'bttn-s-transparent':isMobilevar}"
          style="display: inline-block; z-index: 9; left: 4.5rem; padding: 0 0.15rem 0.15rem 0.15rem; margin: 0 1rem 0 1.5rem; border: none; background: transparent; ">
    <span class="glyphicon glyphicon-arrow-right hvr-icon drkRedGrnB"
          style="vertical-align: central; align-self:center; margin-top: -0.1rem; font-size: 30px;" aria-hidden="true"></span>
  </button>
  <!--============================================================================================-->
 
</div>
