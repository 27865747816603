
import { ElementRef, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
// import { Dictionary } from 'dictionaryjs';
import { Login } from '../../models/account/login.model';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { LogRegModel } from '../../models/account/logReg.model';
import { Register } from '../../models/account/register.model';
import { ModuleComponentLoader } from '../../models/common/moduleComponentLoader.model';
import { KV } from '../../models/keyValue/kv.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { EmailService } from '../communicationServiceService/emailService';
import { BackgroundImageAnimationService } from '../rendererServiceService/backgroundImageAnimationService.service';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { DomUtilsServiceStatic } from '../staticServices/domUtilsServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';

@Injectable({ providedIn: 'root'})
export class LogRegService {
  // public emailService : EmailService;
  public age = 0;
  public accountMessages : any;
  public blackListCredentialKvArr: KV[] = [];
  private container : any;
  public counter = 0;
  public emailPattern = /(([^<>()[\]\\.,;:\s@""]+(\.[^<>()[\]\\.,;:\s@""]+)*)|("".+""))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g;
  public emojis = [ 0x1F600, 0x1F604, 0x1F34A, 0x1F344, 0x1F37F, 0x1F363, 0x1F370, 0x1F355,
  0x1F354, 0x1F35F, 0x1F6C0, 0x1F48E, 0x1F5FA, 0x23F0, 0x1F579, 0x1F4DA,
    0x1F431, 0x1F42A, 0x1F439, 0x1F424 ];
  public emojiMap : Map<any, any> = new Map<any, any>();
  public idleState : any = 'Not started.';
  public isAllowed = false;
  public isDobErr = false;
  public isEmailValid$ : Observable<boolean>;
  public isFormValid$ : Observable<boolean>;
  public isEmailValid = false;
  public isFormValid = false;
  public initEmail = '';
  public initPassword = '';
  public isPasswordsMatch = false;
  public isPasswordValid$ : Observable<boolean>;
  public isBlkPasswordValid$ : Observable<boolean>;
  public isPasswordValid = false;
  public isBlkPasswordValid = false;
  public timedOut = false;
  public lastPing! : Date;
  public login : Login = new Login();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public logRegModel : LogRegModel = new LogRegModel();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public passwordPattern = /^(?=.*[0-9])(?=.*[- ?!@#$%^&*\/\\])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9- ?!@#$%^&*\/\\]{8,30}$/g;
  public message = '';
  //public regExpFailedPasswordDictionary  = new Dictionary<any, any>();
  //public regExpPassedPasswordDictionary  = new Dictionary<any, any>();
  public register : Register = new Register();
  public scrollY = new BehaviorSubject( 0 );
  public scrollY$ = this.scrollY.asObservable();
  public signedInUserId = 0;

  constructor (public bias: BackgroundImageAnimationService,
    @Inject(Window) window : Window,
    @Inject(Document) document : Document,
    public emailService : EmailService,  ) {
    this.emojis.map(e => {
      this.emojiMap.set(this.counter++, String.fromCodePoint(e));
    });
    this.counter = 0;
    // this.emailService = communicationServiceService.getEmailService();
    // debugger;
    this.blackListCredentialKvArr = this.emailService.getBlackListCredentialKvArr();
  }
  
  /*
   * ---------------------------------------------------------------
   * Note : dob is sent in yyyy/mm/dd format
   */
  calculateAge (dob : any) : any {
    let age = 0;

    if (dob) {
      const now = new Date();
      const nowYear = now.getFullYear();
      const nowMonth = now.getMonth() + 1;
      const nowDay = now.getDate();
      var dobParts = dob.split('/');
      // debugger;
      if (dobParts.length === 1) {
        dobParts = dob.split('-');
      }

      if (dobParts.length > 2) {
        age = nowYear - dobParts[ 0 ];
        if (nowMonth < dobParts[ 1 ] && age > 0) {
          age--;
        }
        if (nowMonth === dobParts[ 1 ] && nowDay < dobParts[ 2 ] && age > 0) {
          age--;
        }
      }
    }
    return age;
  }
  // ---------------------------------------------------------
  _checkValidity (model : Login | Register) : any {
    // debugger;
    let tModel : any;
    this.logRegModel = new LogRegModel();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.email)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.password)) {
      tModel = model as (Login | Register);
      //this.isAllowedBlackListCredential(model as Login).subscribe(data => {

      //  tModel.isAllowed = model.isAllowed = this.isAllowed = data;
      //  this.logRegModel.isAllowed = this.isAllowed;
      //});
      tModel = this._isEmailValid(tModel);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
        this.isEmailValid = tModel.isEmailValid;
        this.logRegModel.isEmailValid = this.isEmailValid;
        this.logRegModel.login = tModel;
        // debugger;
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
        tModel = this._isPasswordValid(tModel);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
          this.isPasswordValid = tModel.isPasswordValid;
          this.logRegModel.isPasswordValid = this.isPasswordValid;
          // debugger;
        }
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
        tModel.isFormValid = tModel.isEmailValid && tModel.isPasswordValid;
        this.logRegModel.isFormValid = tModel.isFormValid;
        this.isFormValid = tModel.isFormValid;
        // debugger;
      }     

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
        this.logRegModel.login = tModel;
         EmitterSubjectService.emitLogRegModel(this.logRegModel);
        // debugger;
        return tModel;
      }
      else return null;
    }
    else return null;
  }

  // ---------------------------------------------------------------
  //  Tested on 20220324 and it works!
  // ---------------------------------------------------------------
  countCharInString (charRegExp : RegExp, strToSearch : string) : number {
    // debugger;
    return ((strToSearch || '').match(charRegExp) || []).length;

  }
  // ---------------------------------------------------------------
  createRegExpFromString (input : string) : any {
    if (typeof input !== 'string') {
      return 0;
    }
    input = (input) === '.' ? ('\\' + input) : input;
    // debugger;
    const regexp = new RegExp(input, 'g');
    return regexp;
  }
   // ---------------------------------------------------------------
   public executeLogoutSuccessTasks () : void {

    // this.bias.setPrimaryImage('primaryPhotoBnId', false);
    this.signedInUserId = 0;
   }
  // ---------------------------------------------------------------
  getUrl () {
    return 'url(\'assets/photos/glyph-members-transparent.png\')';
  }
  // --------------------------------------------------------------
  isAllowedBlackListCredential (login : Login) : Observable<boolean> {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.blackListCredentialKvArr)
      || this.blackListCredentialKvArr.length === 0) {
      this.blackListCredentialKvArr = this.emailService.getBlackListCredentialKvArr();
      this.blackListCredentialKvArr = ArraySupportServiceStatic.makeArrayOfUniqElements(this.blackListCredentialKvArr);
      // debugger;
    }
    this.isAllowed = false;
    var allowedPassword = '';
    return new Observable<boolean>((subscriber) => {
      this.blackListCredentialKvArr.map(e => {
        if (e.key.indexOf('testCredential-' + login.email) !== -1) {
          var parts = e.value.split('%#%');
          if (parts.length === 2) {
            allowedPassword = parts[ 1 ];
            // debugger;
          }
          if (allowedPassword.indexOf(login.password)) {
            this.isAllowed = true;
            // debugger;           
          }
        }
      });
      subscriber.next(this.isAllowed);
    })
  }

  // ---------------------------------------------------------------
  // Note: This method should only varify backupPassword: SOLID principal
  // ---------------------------------------------------------------
  _isBackupPasswordValid (model : Login | Register) : boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.backupPassword)
      && model.backupPassword.length >= 8) {
      // console.log('searchPasswordRegExpResult: ' + searchPasswordRegExpResult);          
      this.isPasswordValid = model.isPasswordValid = true;
      // debugger;
      return this.isPasswordValid;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  _isEmailValid (model : Login | Register) : any {
    // debugger;
    let tModel = null;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.email)) {
      // debugger;      
      tModel = model
      tModel = this.testEmailByRegExp(tModel);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel.email)) {
        tModel.isEmailValid = true;
        // debugger;
        this.isEmailValid = tModel.isEmailValid;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
          this.isEmailValid = tModel.isEmailValid;
          this.logRegModel.isEmailValid = this.isEmailValid;
          this.logRegModel.login = tModel;
          EmitterSubjectService.emitLogRegModel(this.logRegModel);
        // debugger;
          this.logRegModel.login = tModel;
          // debugger;
        }
        return tModel;
      }
      else return null;
    }
    else return null;
  }
  
  // ---------------------------------------------------------------
  // Note: This method is not used but keep it
  // ---------------------------------------------------------------
  _isLoginFormValid (login : Login) : any {
    this.logRegModel = new LogRegModel();
    let tModel = null;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(login)) {
      let modelL = this._isEmailValid(login);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelL)) {
        this.isEmailValid = login.isEmailValid = modelL.isEmailValid;
        this.logRegModel.isEmailValid = login.isEmailValid;
      }

      let modelP = this._isPasswordValid(login);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelP)) {
        this.isPasswordValid = login.isPasswordValid = modelP.isPasswordValid;
        this.logRegModel.isPasswordValid = login.isPasswordValid;
      }

      this.isFormValid = login.isFormValid = login.isEmailValid && login.isPasswordValid;
      this.logRegModel.isFormValid = login.isFormValid;

      this.logRegModel.login = login;
      EmitterSubjectService.emitLogRegModel(this.logRegModel);

      return login;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  _isPasswordValid (model : Login | Register) : any {
    // debugger;
    // we are checking a list of Credentials that are in a List, 
    // TODO: explain about a list such as a black list of emails addresses.
    //
    //--------------------------------------------------------------
    let tModel : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.password)
      && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.backupPassword)
      && model.password.indexOf('***') === -1) {
      // debugger;
      model.backupPassword = FrequentlyUsedFunctionsServiceStatic.deepClone(model.password);
    }
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.password)) {
      tModel = model;
      //this.isAllowedBlackListCredential(model).subscribe(data => {
      //  tModel.isAllowed = model.isAllowed = data;
      //})      
      
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
        tModel.backupPassword = FrequentlyUsedFunctionsServiceStatic.deepClone(model.password);
      
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel.backupPassword) && tModel.backupPassword.indexOf('***') === -1) {
          // debugger;
          tModel.isPasswordValid = this._isBackupPasswordValid(tModel);

          // NOTE: masking of password should happen only before salting the model. important!
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel.password) && tModel.password.indexOf('***') === -1) {
            // debugger;
            tModel.password = this.maskPassword(tModel);
          }
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tModel)) {
            this.isPasswordValid = tModel.isPasswordValid;
            this.logRegModel.isPasswordValid = this.isPasswordValid;
            this.logRegModel.login = tModel;
            EmitterSubjectService.emitLogRegModel(this.logRegModel);
            // debugger;
          }
          return tModel;
        }
        else return null;
      }
      else return null;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  //  This method will not only mask the passord but also save the 
  //  original password in backupPassword field.
  // ---------------------------------------------------------------
  // Note:  we are keeping a copy of the password in a 
  //        local (scoped to function) vairable just in case
  //        in the future we need to caputre the original password,
  //        we can add code to capture it.
  // ---------------------------------------------------------------
  maskPassword (model : any) : string {
    let mask = '';
    let tMask = '';
    for (const item of (model.password)) {
      if (item !== '*') {
        // debugger;
        tMask = tMask + item;
      }
      else {
        if (model.password[ 7 ] === item) {
          tMask += item;
				}
			}
      mask = mask + '*';
    }
    model.password = mask;
    tMask += '*'
    return model.password;
  }
  // ---------------------------------------------------------------
  readElementRef (model : Login, emailElem : ElementRef, passwordElem : ElementRef) : any {
    //  !!!Important Note: the initvalues and method need to be here as well as ngAfterViewInit()
    //  in order to have propar-validation during the following scenarios:
    //  1) very first time a user logs in.
    //  2) a user logs out and returns and logs in with browser kept credentials to work with password mask
    //  3) a user clears the cash and the either (1) or (2) of the above scenarios take place
    // -------------------------------------------------------------------------------
    model.email = emailElem.nativeElement.value;

    // debugger;
    model.backupPassword = model.password = passwordElem.nativeElement.value;
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.email)
      || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.password)) {
      model = this.readElementOutsideAngular(model);
    }
    // debugger;
    return model;
  }
  // ---------------------------------------------------------------
  //  After logout(), the login model is reset, and if the user saved
  //  the credentials, the browser loads it and populates in the UI,
  //  but the angular does not read it until user interacts with the UI.
  //  Therefore, we force read any input from the browser and test them.
  // ---------------------------------------------------------------
  //  Note: this initial-read() method is to be deployed at ngAfterViewInit
  //        in order to check the browser-saved credential without any event-trigger.
  // ---------------------------------------------------------------
  readElementOutsideAngular (login: Login) : any {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(login) ) {
      login = new Login();
      // debugger;
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(login)) {
      if(FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(login.email)){
        this.initEmail = DomUtilsServiceStatic.getElementOutsideAngular('emailId').value;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.initEmail)) {
          login.email = this.initEmail;
          // debugger;
        }
        // debugger;
      }
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(login.password)) {
        this.initPassword = DomUtilsServiceStatic.getElementOutsideAngular('passwordId').value;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.initPassword)) {
          login.backupPassword = login.password = this.initPassword;
          // debugger;
        }
      }      
      return login;
    }
    else return null;
  }

  // ---------------------------------------------------------------
  onDateChanged (register : Login) : any {
    register = this.determineAge(register);
    return register
  }
  // ---------------------------------------------------------------
  testEmailByRegExp (model : Login | Register) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.email)
      && model.email.length > 0) {
      let testEmailRegExpResult = this.emailPattern.test(model.email);
      // console.log('searchEmailRegExpResult: ' + searchEmailRegExpResult);
       // debugger;
      if (testEmailRegExpResult) {
        // debugger;
        this.isEmailValid = model.isEmailValid = true;
        
      }
      
    }
    return model;
  }
  // ---------------------------------------------------------------
  testPasswordByRegExp (model : Login | Register) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model.email)
      && model.email.length > 0) {
      let testPasswordRegExpResult = this.passwordPattern.test(model.email);
      // console.log('searchEmailRegExpResult: ' + searchEmailRegExpResult);
      // debugger;
      if (testPasswordRegExpResult) {
        // debugger;
        this.isPasswordValid = model.isPasswordValid = true;

      }

    }
    return model;
  }
  // ---------------------------------------------------------------
  
  /*
   * ---------------------------------------------------------------
   * ref : https:// stackoverflow.com/questions/22982647/how-to-set-an-input-field-to-ng-invalid-in-angularjs
   */
  determineAge (login : Login) : any {
    debugger;
    var inDob = login.dob;
    if (login.dob) {
      
      // const ymd = login.dob.replace(/-/g, '/'); // tranaform the dob from yyyy-mm-dd to yyyy/mm/dd form
      var ymd = login.dob;
      if (ymd) {
         debugger;
        this.age = this.calculateAge(ymd); // profileFactory.calculateAge() expects input in yyyy/mm/dd format
        debugger;
        login.age = this.age;
        if (this.age >= 18) {
          login.dob = ymd;
          login.dobTicks = DateStringServiceStatic.getTicks(new Date(ymd));
          // = DateStringServiceStatic.getJsDateMinusJsOffsetToNetTicks(new Date(login.dob));
          // debugger;
        } else {
          if (login.dob.length > 5) {
            login.isDobErr = this.isDobErr = true;
            login.message = this.getDisclaimerAgeAccountMessage(this.age);
          }
        }
        return login;
      } else if (login?.dob?.length > 5) {
        login.isDobErr = this.isDobErr = true;
      }
    } else if (login?.dob?.length > 5) {
      login.isDobErr = this.isDobErr = true;
    }
    return login; // todo: add property: isDobErr
  }
  // ---------------------------------------------------------------
  getDisclaimerAgeAccountMessage (age : number): any {
    let yourAgeMsg = '';
    let disaclaimerMsg = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.accountMessages) && this.accountMessages.length > 0) {
      for (let e of this.accountMessages) {
        if (e.Key.toLowercase().indexOf('yourAge') !== -1) {
          yourAgeMsg = e.Value;
        }
        if (e.Key.toLowercase().indexOf('disclaimerAge') !== -1) {
          disaclaimerMsg = e.Value;
        }
      }
    }
    this.message = ' ' + yourAgeMsg + age + '. ' + disaclaimerMsg;
    return this.message;
  }
  // ---------------------------------------------------------------
 
  // ---------------------------------------------------------------
}
