'use-strict'
import { Pipe, PipeTransform } from '@angular/core';
import { UpgradedTile } from '../../../models/profile/upgradedTile.model';

@Pipe({
  name: 'upgradedTilesPipe',
})
export class UpgradedTilesPipe implements PipeTransform {

  transform ( uTileArr : UpgradedTile[])
  {
    return uTileArr.map( value =>
    {
      return value;
    });
  }
}
