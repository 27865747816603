export class Photo {
  public approval = '';
  public contentType = '';
  public cryptUserKey = '';
  public date = '';
  public description = '';
  public file = File;
  public formData : FormData = new FormData();
  public id = 0;
  public imageBytes : [] = [];
  public image: any;
  public imageString = '';
  public isPrimary = false;
  public isSelected = false;
  public isUnlocked = false;
  public itemNum = 0;
  public latitude = '';
  public longitude = '';
  public location = {};
  public path = '';
  public pic = '';
  public photoId = 0;
  public privacy = '';
  public profileId = 0;
  public sitUserId = 0;
  public sitUserKey = '';
  public signedInUserId = 0;
  public sourceId = 0;
  public sourceType = '';
  public title = '';
  public url = '';
  constructor() { }

  copy(that: Photo): Photo {
    this.approval = that.approval;
    this.contentType = that.contentType;
    this.cryptUserKey = that.cryptUserKey;
    this.date = that.date;
    this.description = that.description;
    this.file = that.file;
    this.formData = that.formData;
    this.id = that.id;
    this.image = that.image;
    this.imageBytes = that.imageBytes;
    this.imageString = that.imageString;
    this.isPrimary = that.isPrimary;
    this.isSelected = that.isSelected;
    this.isUnlocked = that.isUnlocked;
    this.itemNum = that.itemNum;
    this.latitude = that.latitude;
    this.location = that.location;
    this.longitude = that.longitude;
    this.path = that.path;
    this.photoId = that.photoId;
    this.pic = that.pic;
    this.privacy = that.privacy;
    this.profileId = that.profileId;
    this.sitUserId = that.sitUserId;
    this.sitUserKey = that.sitUserKey;
    this.signedInUserId = that.signedInUserId;
    this.sourceId = that.sourceId;
    this.sourceType = that.sourceType;
    this.title = that.title;
    this.url = that.url;
    return this;
  }

  cloneTo(to: Photo, that: Photo): Photo {
    to.approval = that.approval;
    to.contentType = that.contentType;
    to.cryptUserKey = that.cryptUserKey;
    to.date = that.date;
    to.description = that.description;
    to.file = that.file;
    to.formData = that.formData;
    to.id = that.id;
    to.image = that.image;
    to.imageBytes = that.imageBytes;
    to.imageString = that.imageString;
    to.isPrimary = that.isPrimary;
    to.isSelected = that.isSelected;
    to.isUnlocked = that.isUnlocked;
    to.latitude = that.latitude;
    to.location = that.location;
    to.longitude = that.longitude;
    to.itemNum = that.itemNum;
    to.path = that.path;
    to.pic = that.pic;
    to.photoId = that.photoId;
    to.privacy = that.privacy;
    to.profileId = that.profileId;
    to.sitUserId = that.sitUserId;
    to.sitUserKey = that.sitUserKey;
    to.signedInUserId = that.signedInUserId;
    to.sourceId = that.sourceId;
    to.sourceType = that.sourceType;
    to.title = that.title;
    to.url = that.url;
    return to;
  }
  
  // ---------------------------------------------
  copyIfExists (a : Photo) : Photo {
    if (a.cryptUserKey) {
      this.cryptUserKey = a.cryptUserKey;
    }

    if (a.date) {
      this.date = a.date;
    }

    if (a.description) {
      this.description = a.description;
    }    

    if (a.file) {
      this.file = a.file;
    }

    if (a.formData) {
      this.formData = a.formData;
    }

    if (a.id) {
      this.id = a.id;
    }

    if (a.image) {
      this.image = a.image;
    }

    if (a.imageString) {
      this.imageString = a.imageString;
    }

    if (a.imageBytes) {
      this.imageBytes = a.imageBytes;
    }

    if (a.isPrimary) {
      this.isPrimary = a.isPrimary;
    }

    if (a.isSelected) {
      this.isSelected = a.isSelected;
    }
    if (a.isUnlocked) {
      this.isUnlocked = a.isUnlocked;
    }

    if (a.itemNum) {
      this.itemNum = a.itemNum;
    }

    if (a.latitude) {
      this.latitude = a.latitude;
    }

    if (a.location) {
      this.location = a.location;
    }

    if (a.longitude) {
      this.longitude = a.longitude;
    }

    if (a.path) {
      this.path = a.path;
    }

    if (a.photoId) {
      this.photoId = a.photoId;
    }

    if (a.pic) {
      this.pic = a.pic;
    }
    if (a.profileId) {
      this.profileId = a.profileId;
    }
    if (a.privacy) {
      this.privacy = a.privacy;
    }

    if (a.signedInUserId) {
      this.signedInUserId = a.signedInUserId;
    }

    if (a.sitUserId) {
      this.sitUserId = a.sitUserId;
    }

    if (a.sitUserKey) {
      this.sitUserKey = a.sitUserKey;
    }

    if (a.title) {
      this.title = a.title;
    }

    if (a.url) {
      this.url = a.url;
    }
    return this;
  }
  // ---------------------------------------------
  cloneIfExists (a : Photo) : Photo {
    const that = new Photo();
    that.copyIfExists(a);
    return that;
  }
  // ---------------------------------------------
}
