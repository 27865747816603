'use-strict'
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Register } from '../../../models/account/register.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';

// ref: https://coryrylan.com/blog/using-html5-date-input-with-date-objects-and-angular

@Component({
  selector: 'app-date-input',
  templateUrl: './dateInput.component.html',
  styleUrls: ['./dateInput.component.scss'],
  // 1)OnPush->requires an Observable to refresh the view; 2)Default->automatic refresh
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DateInputComponent implements OnInit, OnDestroy, AfterViewInit
{
  private emitterDestroyed$: Subject<boolean> = new Subject();
  // ---------------------------------------------------------------
  date = new Date();
  dateObj : any;
 //  dateObjectControl = new FormControl(new Date());
 // @Input() register : Register = new Register();
 // ----------------------------------------------------------------
  constructor (private cdr : ChangeDetectorRef) {
  }
 // ----------------------------------------------------------------
  ngOnInit () : void
  {    
  }
  // ---------------------------------------------------------------
  ngAfterViewInit (): void
  {
    // debugger;
    // DO NOT get ProfileTile from Emitter here!
    this.cdr.detectChanges();
  }
  // ----------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    // debugger;
  }
  // ----------------------------------------------------------------
  updateDate (event) {
    debugger;
    this.date = event.target.valueAsDate;
    EmitterSubjectService.emitDob(this.date);
  }
}

