
<div id="addressComponentId"
     class="flex-container"
     style="/*Caution! this style works as is to center its self and its contents*/
            width: 100%;
            height: auto;
            max-height: 7rem;
            padding: 0.2rem 0.2rem 0.5rem 0.2rem;
            margin: 3.5rem 0rem 0.25rem -2%;
            align-content: center;
            align-items: flex-start;
            opacity: 1;
            border-radius: 7px;
            background: transparent;
            border-radius: 7px;
            z-index: 1;
            overflow: visible;
            clear: both;">

  <div class="flex-container" style="margin:0;">
    <div class="flex-text grnDrkRedxxSB" style="margin-top:0.25rem;z-index: 9;">Card Holder Explanation:</div>
  </div>

  <div *ngIf="message?.length > 0" class="flex-container">
    <div class="flex-text" [ngClass]="{'errS':isMobilevar && !isSuccess, 'errxS':!isMobilevar && !isSuccess, 'grnS':isMobilevar && isSuccess, 'grnxS':!isMobilevar && isSuccess}">
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
      <span>{{message}}</span>
    </div>
  </div>
  <form *ngIf="!isAddressComplete" #addressForm="ngForm" novalidate>
    <!--First Name-->
    <div class="flex-container" style="margin: -0.5rem 0 -0.5rem 0;">
      <div class="flex-text" style="margin:-0.25rem 0;opacity:1;">
        <div class="form-group flex-button" [ngClass]="{
        'has-danger': firstName.invalid && firstName.dirty,
        'has-success': firstName.valid && firstName.dirty }">
          <div>
            <span class="whiteBluexxxS">First Name<span class="errSup" style="margin-left:2px;">*</span> :</span>
          </div>
          <div>
            <input [(ngModel)]="address.firstName" class="form-control" name="firstName" type="text" #firstName="ngModel"
                   style="border-radius: 5px; width: 5rem; height: 0.6rem;" tabindex="{{addrTabIndexNum}}" required autofocus />
          </div>
          <div *ngIf="firstName.errors && (firstName.dirty || firstName.touched)">
            <span *ngIf="[firstName.errors.required]" class="errxxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> First Name is required</span>
          </div>
        </div>
      </div>
      <!--Last Name-->
      <div class="flex-text" style="margin: -0.25rem 0; opacity: 1;">
        <div class="form-group flex-button" [ngClass]="{
        'has-danger': lastName.invalid && lastName.dirty,
        'has-success': lastName.valid && lastName.dirty}">
          <div>
            <span class="whiteBluexxxS">Last Name<span class="errSup" style="margin-left:2px;">*</span> :</span>
          </div>
          <div>
            <input [(ngModel)]="address.lastName" class="form-control" name="lastName" type="text" #lastName="ngModel"
                   style="border-radius: 5px; width: 5rem; height: 0.6rem;" tabindex="{{addrTabIndexNum}}" required />
          </div>
          <div *ngIf="lastName.errors && (lastName.dirty || lastName.touched)">
            <span *ngIf="lastName.errors.required" class="errxxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> Last Name is required</span>
          </div>
        </div>
      </div>
    </div>
    <!--Address Ref:https:// www.freakyjolly.com/angular-datepicker-timepicker-using-ng-pick-datetime-tutorial-by-example/-->
    <div class="flex-container" style="margin: -0.5rem 0 -0.5rem 0;">
      <div class="flex-text" style="margin: -0.25rem 0; opacity: 1;">
        <div class="form-group flex-button" [ngClass]="{
          'has-danger': addressStr.invalid && addressStr.dirty,
        'has-success': addressStr.valid && addressStr.dirty}">
          <div>
            <span class="whiteBluexxxS">Address <span class="errSup" style="margin-left:2px;">*</span> :</span>
          </div>
          <div>
            <input [(ngModel)]="address.addressStr" class="form-control" name="addressStr" type="text" #addressStr="ngModel"
                   style="border-radius: 5px; width: 5rem; height: 0.6rem;" tabindex="{{addrTabIndexNum}}" required />
          </div>
          <div *ngIf="addressStr.errors && (addressStr.dirty || addressStr.touched)">
            <span *ngIf="[addressStr.errors.required]" class="errxxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>Complete address with street name and number is required.</span>
          </div>
        </div>
      </div>
      <!--City-->
      <div class="flex-text" style="margin: -0.25rem 0; opacity: 1;">
        <div class="form-group flex-button" [ngClass]="{
      'has-danger': city.invalid && city.dirty,
      'has-success': city.valid && city.dirty }">
          <div>
            <span class="whiteBluexxxS">City<span class="errSup" style="margin-left:2px;">*</span> :</span>
          </div>
          <div>
            <input [(ngModel)]="address.city" class="form-control" name="city" type="text" #city="ngModel"
                   style="border-radius: 5px; width: 5rem; height: 0.6rem;" tabindex="{{addrTabIndexNum}}" required />
          </div>
          <div *ngIf="city.errors && (city.dirty || city.touched)">
            <span *ngIf="[city.errors.required]" class="errxxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> City is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-container" style="margin: -0.5rem 0 -0.5rem 0;">
      <div class="flex-text" style="margin: -0.25rem 0; opacity: 1;">
        <!--State-->
        <div class="form-group flex-button" [ngClass]="{
        'has-danger': state.invalid && state.dirty,
        'has-success': state.valid && state.dirty }">
          <div>
            <span class="whiteBluexxxS">State/Province<span class="errSup" style="margin-left:2px;">*</span> :</span>
          </div>
          <div>
            <input [(ngModel)]="address.state" class="form-control" name="state" type="text" #state="ngModel" tabindex="{{addrTabIndexNum}}" style="border-radius: 5px; width: 5rem; height: 0.6rem;" required />
          </div>
          <div *ngIf="(state.dirty || state.touched) && (state.errors)">
            <span *ngIf="[state.errors?.required]" class="errxxS">
              <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
              <span class="errxxxS">State/Province is required.</span>
            </span>
          </div>
        </div>
      </div>

      <!--Country-->
      <div class="flex-text" style="margin: -0.25rem 0; opacity: 1;">
        <div class="form-group flex-button" [ngClass]="{
          'has-danger': country.invalid && country.dirty,
          'has-success': country.valid && country.dirty }">
          <div>
            <span class="whiteBluexxxS">Country<span class="errSup" style="margin-left:2px;">*</span> :</span>
          </div>
          <div>
            <input [(ngModel)]="address.country" class="form-control" name="country" type="text" #country="ngModel"
                   style="border-radius: 5px; width: 5rem; height: 0.6rem;" tabindex="{{addrTabIndexNum}}" required />
          </div>
          <div *ngIf="country.errors && (country.dirty || country.touched)">
            <span *ngIf="[country.errors.required]" class="errxxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> Country is required</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-container" style="margin:-0.5rem 0 -0.5rem 0;">
      <!--ZipCode-->
      <div class="flex-text" style="margin: -0.25rem 0; opacity: 1;">
        <div class="form-group flex-button" [ngClass]="{
        'has-danger': zipCode.invalid && zipCode.dirty,
        'has-success': zipCode.valid && zipCode.dirty }">
          <div>
            <span class="whiteBluexxxS">ZipCode/PostalCode<span class="errSup" style="margin-left:2px;">*</span> :</span>
          </div>
          <div>
            <input [(ngModel)]="address.zipCode" class="form-control" name="zipCode" type="text" #zipCode="ngModel" style="border-radius: 5px; width: 5rem; height: 0.6rem;" tabindex="{{addrTabIndexNum}}" required />
          </div>
          <div *ngIf="(zipCode.dirty || zipCode.touched) && (zipCode.errors )">
            <span class="errxxS">
              <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
              <span *ngIf="[zipCode.errors?.required]" class="errxxxS"> Postal/Zip Code is required.</span>
            </span>
          </div>
        </div>
      </div>
      <!--Email-->
      <div class="flex-text" style="margin: -0.25rem 0; opacity: 1;">
        <div class="form-group flex-button" [ngClass]="{
      'has-danger': email.invalid && email.dirty,
      'has-success': email.valid && email.dirty}">
          <div>
            <span class="whiteBluexxxS">Email ( <span class="glyphicon glyphicon-envelope" style="margin:5px -3px -5px -3px;" aria-hidden="true"></span> )<span class="errSup" style="margin-left:2px;">*</span> :</span>
          </div>
          <div>
            <input [(ngModel)]="address.email" class="form-control" name="email" type="email" #email="ngModel" [pattern]="emailPattern"
                   style="border-radius: 5px;width:5rem;height: 0.6rem;" tabindex="{{addrTabIndexNum}}" required />
          </div>
          <div *ngIf="email.errors && (email.dirty || email.touched)">
            <span *ngIf="[email.errors.required]" class="errxxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> Valid email is required</span>
            <span *ngIf="email.errors.pattern" class="errxxxS"><span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span> The email address must contain at least the @ character and format should be <i>john@doe.com</i>.</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex-container" style="margin:-0.5rem 0 0 0;">
      <div class="flex-text" style="opacity:1;">
        <button type="button" (click)="submitAddressForm()" [ngClass]="{'bttn-md-info':!isMobilevar, 'bttn-lg-info mBttnFontWeight':isMobilevar, 'flex-text':true}" style="margin:5px;padding: 5px 10px 5px 10px;" name="addBttn" tabindex="{{addrTabIndexNum}}">Add</button><!--[disabled]="f.invalid"-->
      </div>
    </div>
    <hr class="hrSilver" style="margin:0 1.5rem 0 0rem;"/>
  </form>

  <div *ngIf="isAddressComplete" class="flex-container" style="width: 90%;margin:0.25rem 0 -1rem 0%;">
    <div class="flex-text-center addrDisplay increase" style="width: 60%;margin:0 0 0.25rem 0%">
      <div class="itemValue"><span class="addrItemLabel">Name: </span><span class="addrItemValue">{{address.firstName}} {{address.lastName}}</span></div>
      <div class="itemValue"><span class="addrItemLabel">Address: </span><span class="addrItemValue">{{address.addressStr}}</span></div>
      <div class="itemValue"><span class="addrItemLabel">City: </span><span class="addrItemValue">{{address.city}}</span></div>
      <div class="itemValue"><span class="addrItemLabel">State/Province: </span><span class="addrItemValue">{{address.state}}</span></div>
      <div class="itemValue"><span class="addrItemLabel">Country: </span><span class="addrItemValue">{{address.country}}</span></div>
      <div class="itemValue"><span class="addrItemLabel">Zip/Postal Code: </span><span class="addrItemValue">{{address.zipCode}}</span></div>
      <div class="itemValue"><span class="addrItemLabel">Email ( <span class="glyphicon glyphicon-envelope" style="margin:5px -3px -5px -3px;" aria-hidden="true"></span> ): </span><span class="addrItemValue">{{address.email}}</span></div>

      <div class="flex-button" style="opacity:1;">
        <button type="button" (click)="editAddress()" [ngClass]="{'bttn-md-info':!isMobilevar, 'bttn-lg-info mBttnFontWeight':isMobilevar, 'flex-text':true}" style="margin:5px;padding: 5px 10px 5px 10px;">Edit</button><!--[disabled]="f.invalid"-->
      </div>
    </div>
    <hr class="hrSilver" />
  </div>
</div>
  <!--DO NOT DELETE THIS!!-->
  <!--<div *ngIf="isAddressComplete" class="flex-container" style="width: 90%;margin:0.5rem 0 0rem 0%;">
    <div class="flex-text text-center addrDisplay increase" style="width: 60%;margin:0 0 0.25rem 0%">
      <div class="addrKey">Name: </div> <div class="addrValue">{{address.firstName}} {{address.lastName}}</div>
      <div class="addrKey">Address: </div> <div class="addrValue">{{address.addressStr}}</div>
      <div class="addrKey">City: </div><div class="addrValue">{{address.city}}</div>
      <div class="addrKey">State/Province: </div><div class="addrValue">{{address.state}}</div>
      <div class="addrKey">Country: </div><div class="addrValue">{{address.country}}</div>
      <div class="addrKey">Zip/Postal Code: </div><div class="addrValue">{{address.zipCode}}</div>

      <button type="button" (click)="editAddress()" [ngClass]="{'bttn-md-info':!isMobilevar, 'bttn-lg-info mBttnFontWeight':isMobilevar, 'flex-button':true}" style="margin: 5px; padding: 5px 10px 5px 10px; opacity: 1;">Edit</button>--><!--[disabled]="f.invalid"-->
    <!--</div>
  </div>
  <hr class="hrSilver" />
</div>-->
