'use-strict'
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KeyValueModuleComponent } from './component/keyValueModule.component';
import { ContentKeyValueArrComponent } from './contentKeyValueArr/contentKeyValueArr.component';
import { KeyValueComponent } from './keyValue/keyValue/keyValue.component';
import { KeyValueAnyComponent } from './keyValue/keyValueAny/keyValueAny.component';
import { KeyValueAnyArrComponent } from './keyValue/keyValueAnyArr/keyValueAnyArr.component';
import { KeyValueAnyTemplateComponent } from './keyValue/keyValueAnyTemplate/keyValueAnyTemplate.component';
import { KeyValueArrComponent } from './keyValue/keyValueArr/keyValueArr.component';
import { ProfileImageKvComponent } from './keyValue/profileImageKv/profileImageKv.component';
import { ProfilePicsKeyValueArrComponent } from './keyValue/profilePicsKeyValueArr/profilePicsKeyValueArr.component';
import { PreferenceKeyValueArrComponent } from './preferenceKeyValueArr/preferenceKeyValueArr.component';
import { ProfileInfoKeyValueArrComponent } from './profileInfoKeyValueArr/profileInfoKeyValueArr.component';

export const sharedRoutes: Routes = [
  {
    path: '',
    // component: KeyValueModuleComponent,
    children: [
      { path: 'contentkeyvaluearr', component: ContentKeyValueArrComponent },
      { path: 'keyvalue', component: KeyValueComponent },
      { path: 'keyvalueany', component: KeyValueAnyComponent },
      { path: 'keyvalueanyarr', component: KeyValueAnyArrComponent },
      { path: 'keyvalueanytemplate', component: KeyValueAnyTemplateComponent },
      { path: 'keyvaluearr', component: KeyValueArrComponent },
      { path: 'preferencekeyvaluearr', component: PreferenceKeyValueArrComponent },
      { path: 'profilleimagekv', component: ProfileImageKvComponent },
      { path: 'profileinfokeyvaluearr', component: ProfileInfoKeyValueArrComponent },
      { path: 'profilepicskeyvaluearr', component: ProfilePicsKeyValueArrComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(sharedRoutes)],
  exports: [RouterModule]
})
export class KeyValueRoutingModule { }
