
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ViewportDimension } from '../../models/common/viewportDimension.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';


// TODO: Turn this class into a Static class. Follow the example of DomUtilServiceStatic & RedirectionServiceStatic

// https:// stackoverflow.com/questions/58989990/error-staticinjectorerrorappmodulesomecomponent-renderer2-when-creating
@Injectable({ providedIn: 'root'})
export class RendererService {
  public renderer : Renderer2;
  public componentViewportDimension : ViewportDimension = new ViewportDimension();
  public viewportDimension : ViewportDimension = new ViewportDimension();
  public document: any;
  public constructor (
    private renderer2Factory : RendererFactory2,
  )
  {
    this.renderer = renderer2Factory.createRenderer(null, null);
  }
  // ------------------------------------------------------------------
  public setRenderer(r2s: Renderer2): void {
    this.renderer = r2s;
  }
  public getRenderer(): Renderer2 {
    if (!this.renderer) {
      this.renderer = this.renderer2Factory.createRenderer(null, null);
    }
    return this.renderer;
  }
  // ------------------------------------------------------------------
  public setDocument( doc : Document ) : void
  {
    this.document = doc;
  }
  // ------------------------------------------------------------------
  public getViewport (): ViewportDimension
  {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.document))
    {
      this.viewportDimension.vw = Math.max( this.document.documentElement.clientWidth || 0, window.innerWidth || 0 );
      this.viewportDimension.vh = Math.max( this.document.documentElement.clientHeight || 0, window.innerHeight || 0 );
      this.viewportDimension.centerX = Math.floor( this.viewportDimension.vw / 2 );
      this.viewportDimension.centerY = Math.floor( this.viewportDimension.vh / 2 );
    }
    return this.viewportDimension;
  }
  // ------------------------------------------------------------------
  public getComponentViewport ( id : string ) : ViewportDimension
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.document ) )
    {
      let elem = this.document.getElementById( id );
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( elem ) )
      {
        this.componentViewportDimension.vh = elem.offsetHeight;
        this.componentViewportDimension.vw = elem.offsetWidth;
        this.componentViewportDimension.centerX = Math.floor( this.componentViewportDimension.vw / 2 );
        this.componentViewportDimension.centerY = Math.floor( this.componentViewportDimension.vh / 2 );
      }      
    }
    return this.componentViewportDimension;
  }
  // ----------------------------------------------------------------
  // .centerdElement in style.scss:
  //centerThisElement () {
  //  // step-1: get the elements by class

  //  // step-2: get the HtmlElemWidth and HtmlElemHeight

  //  // step-3: calculate margin by : calc((100 - var(--anyHtmlElemWidth))/2)vh auto;
  //}

  // ----------------------------------------------------------------
  centerThisComponent ( id : string ) : void
  {
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( id ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.document ))
    {
      this.viewportDimension = this.getViewport();
      let componentElem = this.document.getElementById( id );
      this.componentViewportDimension = this.getComponentViewport( id );
      let margin = Math.floor((this.viewportDimension.vw - this.componentViewportDimension.vw) / 2) + 'vh auto'; // original
      /* let margin = Math.floor(100 - (this.viewportDimension.vw - this.componentViewportDimension.vw) / 2) + 'vh auto';*/
      // debugger;
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( componentElem))
      {
        // debugger;
        this.renderer.setStyle( componentElem, 'width', this.componentViewportDimension.vw );
        this.renderer.setStyle( componentElem, 'height', this.componentViewportDimension.vh );
        this.renderer.setStyle( componentElem, 'margin', margin );
      }
    }
  }
  // ----------------------------------------------------------------
  // How to center the demoDiv on any screen-size?
  /*
  .demoDivCentered {
    width: demoDivWidth( vw );
    height: demoDivHeight( vh );
    margin: calc( [ ( 100 - demoDivHeight ) / 2 ]vh auto;
  }
  */
  /*
   * ------------------------------------------------------------------
   * Usage example:
   * const s = this.renderer.createElement('script');
   * ------------------------------------------------------------------
   */
}

function Inject(DOCUMENT: any) {
    throw new Error('Function not implemented.');
}
