
import { DOCUMENT } from '@angular/common';
import { ApplicationRef, ComponentFactoryResolver, ElementRef, EmbeddedViewRef, Inject, Injectable, Injector } from '@angular/core';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';

@Injectable({
  providedIn: 'root',
})
export class DomService {
  public childComponentRef : any;
  public document ! : Document;
  public window ! : Window;
  public unloadConponentRef: ElementRef;
  public isModalVisible = false;
  constructor(
    private componentFactoryResolver : ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector : Injector,
    @Inject( DOCUMENT ) Document : Document,
    @Inject( Window ) Window : Window,
  )
  {
    this.document = Document;
    this.window = Window;
    this.unloadConponentRef = EmitterSubjectService.getElementRef();
  }

  public appendComponentTo ( parentElem : HTMLElement, childElem : any, childConfig?: ChildConfig) {
    // Create a component reference from the component
    const childComponentRef = this.componentFactoryResolver.resolveComponentFactory(childElem).create(this.injector);

    // Attach the config to the child (inputs and outputs)
    this.attachConfig(childConfig, childComponentRef);

    this.childComponentRef = childComponentRef;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(childComponentRef.hostView);

    // Get DOM element from component
    const childDomElem = (childComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // Append DOM element to the body
    parentElem.appendChild(childDomElem);
  }

  public removeComponent(): any {
    // this.isModalVisible = EmitterSubjectService.getIsModalVisible();
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.childComponentRef) && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.childComponentRef.hostView)) {
      this.appRef.detachView(this.childComponentRef.hostView);
      this.childComponentRef.destroy();
    }
    return true;
  }

  /*
   *  ----------------------------------------------------------------------------------
   *   Note: Tested, works! 2020/12/20
   *   REF:https:// stackoverflow.com/questions/35412526/angular2-remove-component-programmatically
   *  ----------------------------------------------------------------------------------
   */
  unloadComponent(componentId: string): any {
    //  debugger;
    let tElem: any;
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(componentId) && componentId.toLowerCase().indexOf('module') === -1) {

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(componentId)) {
      //  debugger;
      tElem = document.getElementById(componentId);
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem)) {
        componentId = '#' + componentId;
      }
      //  debugger;
      tElem = document.getElementById(componentId);
      //  debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.unloadConponentRef) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.unloadConponentRef.nativeElement)) {
        /*
         *  debugger;
         *  this.unloadConponentRef.nativeElement.querySelector('#' + componentId)?.destroy; // Does NOT work
         *  ref:https:// stackoverflow.com/questions/34449112/how-to-conditionally-insert-remove-host-dom-element-in-angular-2-directive
         */
        const el = this.unloadConponentRef.nativeElement.querySelector('#' + componentId);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(el) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(el.parentNode)) {
          el.parentNode.removeChild(el); //  Tested, works!
        }
        //  debugger;

        // the caller should run after executing unloadComponent()
        EmitterSubjectService.emitRunNgAfterViewInit(true);
        tElem.remove();
      }
    }
    return true;
  }

  private attachConfig (config : any, componentRef : any) {
    const inputs = config.inputs;
    const outputs = config.outputs;
    let i = 0;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inputs)) {
      inputs.map((e : any) => {
        componentRef.instance[i] = inputs[i];
        i++;
      });
    }
    i = 0;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outputs)) {
      outputs.map((f : any) => {
        componentRef.instance[i] = outputs[i];
        i++;
      });
    }
  }
}

export interface ChildConfig {
  inputs: object;
  outputs: object;
}
