import { ModuleComponentLoader } from '../common/moduleComponentLoader.model';

export class PagerBreadcrum {
  public actionName: any;
  public breadcrumRing : ModuleComponentLoader[] = [];
  public currentPage = 0;
  public currentBrdCrmPage : ModuleComponentLoader = new ModuleComponentLoader();
  public lastPage = 0; // very last page
  public pagedEndIndex = 0;
  public pagedEndPage = 0; // paged==currently-displayed-pages
  public nextBrdCrmPage : ModuleComponentLoader = new ModuleComponentLoader();
  public nextPage = 0; // AppComponent.html's Arrow does not use it, but BreadcrumComponent.html may use it
  public pagedBrdCrmArr : ModuleComponentLoader[] = [];
  public pagedPages : number[] = [];
  public pageSize = 0;
  public previousBrdCrmPage : ModuleComponentLoader = new ModuleComponentLoader();
  public previousPage = 0; // AppComponent.html's Arrow may not use it, but BreadcrumComponent.html may use it
  public firstPage = 0; // very first page
  public pagedStartPage = 0;
  public pagedStartIndex = 0;
  public totalItems = 0; // breadcrumRing.length
  public totalPagedBlocks = 0; // paged-blocks-Total==how many blocks of paged-items
  constructor() { }
}
