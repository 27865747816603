
import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Dictionary } from 'dictionaryjs';
import { Observable, Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Sorter } from '../../models/common/genericSearchSortFilter.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { ClientHttpDataModel } from '../../models/data/clientHttpDataModel.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { MemberActivitiesAndAction } from '../../models/member/memberActivitesAndAction.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { PhotoPrivacyService } from '../photoServiceService/photoPrivacyService.service';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { RendererServiceService } from '../rendererServiceService/rendererServiceService.service';
import { GenericSearchSortFilterService } from '../searchSortServiceService/genericSearchSortFilterService.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';


// ===============================================================================================
//  Note: This service has a Dictionary-Version of itself named MemberActivityDictionaryService.ts
//        in the UsusedSlakezCoreParts/services folder with identical functionalities.
// ===============================================================================================


export enum activityArrayEnum
{
  myBlockArray = 1,
  myDislikeArray = 2,
  myFriendArray = 3,
  myLikeArray = 4,
  myLockArray = 5,
  myStarArray = 6,
  myTrackArray = 7,
  myUnblockArray = 8,
  myUnfriendArray = 9,
  myUnlockArray = 10,
  myWinkArray = 11,
  yourBlockArray = 21,
  yourDislikeArray = 22,
  yourFriendArray = 23,
  yourLikeArray = 24,
  yourLockArray = 25,
  yourStarArray = 26,
  yourTrackArray = 27,
  yourUnfriendArray = 28,
  yourUnblockArray = 29,
  yourUnlockArray = 30,
  yourWinkArray = 31
}

export enum staticDictionaryEnum {
  myBlockDictionary = 1,
  myFriendDictionary = 2,
  myLikeDictionary = 3,
  myMemberActivityDictionary = 4,
  myStarDictionary = 5,
  myTrackDictionary = 6,
  myUnlockDictionary = 7,
  myWinkDictionary = 8,
  myProfilePicsDictionary = 9,

  buddyDictionary = 10,
  heartbeatDictionary = 11,
  preferenceDictionary = 12,
  profileImageDictionary = 13,
  profileInfoDictionary = 14,
  profilePicsDictionary = 15,
  profileTileIdDictionary = 16,
  profileTileDictionary = 17,
  sitUserDictionary = 18,
  sitUserIdDictionary = 19,

  yourBlockDictionary = 20,
  yourFriendDictionary = 21,
  yourLikeDictionary = 22,
  yourMemberActivityDictionary = 23,
  yourStarDictionary = 24,
  yourTrackDictionary = 25,
  yourUnlockDictionary = 26,
  yourWinkDictionary = 27,

  preferenceBnDictionary = 30,
  profileInfoBnDictionary = 31,
  photoBnArrDictionary = 32,
  profileTileIdBnDictionary = 33,
  profileTileBnDictionary = 34,
  sitUserBnDictionary = 35,


  chatHistoryIdictionary = 40,
  chatHistoryictionary = 41,
  myChatDictionary = 42,
  emailTileIdDictionary = 43,
  emailTileDictionary = 44,
  notificationIdDictionary = 45,
  notificationDictionary = 46,


  // ------------------------------------------------------------------------------------------------------------------------
  //  New PhotoToPhotoBnToPhotoSystem:
  // ---------------------------------
  //  When a user uploads a photo, it will be saved in the backendDB first, then the client-sent
  //  Photo's box and nonce along with the saved photo's data will be populated in a PhotoBn Model and be saved in backendDB.
  //  This is a one-time-one-way transaction.
  //
  //  Subsequestly, whenever the client requests for a photo, the respective PhotoBn will be sent by the photoId/PhotoBnId.
  //
  //  The client will always get a photoBn or a set of PhotoBns when a photo or set of photos are requested.
  //  Upon receiving the photoBn(s), client will decrypt to get photoBn/PhotoBns to get the respective Photo/Photos,
  //  and populate them to the PhotoDictionary for the view to use.
  //  This photoDictionary will NOT be saved in indexedDb, neither any PhotoIds.
  //
  //  The client will also populate the received PhotoBn(s) to the PhotoBnDictionary that will be saved to the indexedDb,
  //  which the client will use to create the PhotoDictionary for the View upon launching or as needed basis.
  //
  //  The client will save a UserPhotoBnIdArr(all the photoBnIds of one user in an array) in the UserPhotoBnIdDictionary
  //  that will be saved in the indexedDb. Therefore, wheneve the user saves a new Photo, or whenever the user gets a
  //  PhotoBn from the server, the respective PhotoBnId will be first saved to the UserPhotoBnIdArray, and update the
  //  UserPhotoBnIdDictionary<sitUserId, UserPhotoBnIdsArr> and the dictionary will be saved in the indexedDb.
  //  It's noteworthy that the signedInUser's photoBnIdArr will contain the signedInUser's data, and any other user's
  //  photoBnIds will be in an array saved using the respective sitUserId, such that signedInUserId != sitUserId.
  //  Hence the PhotoBnIdDitionary will contain any/all phootoBnIds of a member viewed by the signedInUser.
  //
  //  This UserPhotoBnIdDictionary will be used to create/lookup a specific user's Photo(s). 
  //  
  // ------------------------------------------------------------------------------------------------------------------------

  // photoIdDictionary = 50, // will be deprecated since photoDictionary will contain PhotoId
  photoDictionary = 51, // will be used by View Only, and will not presist in indexedDb
  userPhotoBnIdDictionary = 52, //will be renamed to UserPhotoBnIds since a user may have more than one photo,
  photoBnDictionary = 53, // will NOT be used by the View since PhotoDictionary will be used by the View, but will persist in indexedDb
  pageNumItemArrDictionary = 54,

}

@Injectable( {
  providedIn: 'any',
} )

export class ActivityService implements OnDestroy {
  [ x : string ] : any;
  public renderer! : Renderer2;
  public activeSorter : Sorter<CommunicationActivity> = new Sorter<CommunicationActivity>(false); // isDescending===true;
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public filteredKvPhotoArr : KvPhoto[] = [];
  public isLiked = false;
  public isMobilevar = false;
  public isOnLine = false;
  public isPhotoMgmt = false;
  public isPrivate = false;
  public isToggleCamera = false;
  public isBlockedByDictionaryData = false;
  public isBlockedByServerData = false;
  public isFriendByDictionaryData = false;
  public isFriendByServerData = false;
  public isFriendByMyDictionaryData = false;
  public isLikedByDictionaryData = false;
  public isLikedByServerData = false;
  public isStarredByDictionaryData = false;
  public isUnlocked = false;
  public isUnlockedByDictionaryData = false;
  public isUnlockedByServerData = false;
  public isViewMember = false;
  public isWinkedByDictionaryData = false;
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public kvGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public commActivity : CommunicationActivity = new CommunicationActivity();
  public myCommActivity : CommunicationActivity = new CommunicationActivity();
  public profileImageKvPhotos: KvPhoto[] = [];
  public profilePics: ProfilePics = new ProfilePics();
  public tempUniqueMemberActivities : CommunicationActivity[] = [];
  public yourCommActivity : CommunicationActivity = new CommunicationActivity();
  public message = '';
  public sitUser: SitUser = new SitUser();
  public timer: any;
  public timerArray: any[] = [];
  public trackCount = 0;

  public destinationD = new Dictionary<number, CommunicationActivity>();
  public sourceD = new Dictionary<number, CommunicationActivity>();

  // ============================================================================================================
  //  NOTE: Instead of passing the whole dictionary as a parameter, we pass the name of the respective dictionary
  //        from the Enum. The target methods should use the name-enum and act on the actual dictionary.
  //        This is intended to speed up computation and thresore responsiveness.
  // ============================================================================================================


  public myBlockArray: CommunicationActivity[] = [];
  public myUnblockArray: CommunicationActivity[] = [];
  public myFriendArray: CommunicationActivity[] = [];
  public myUnfriendArray: CommunicationActivity[] = [];
  public myLikeArray : CommunicationActivity[] = [];
  public myLikeDictionary  = new Dictionary<number, CommunicationActivity>();
  public myDislikeArray: CommunicationActivity[] = [];
  public myLockArray: CommunicationActivity[] = [];
  public myUnlockArray: CommunicationActivity[] = [];
  public myStarArray: CommunicationActivity[] = [];
  public myTrackArray: CommunicationActivity[] = [];
  public myWinkArray: CommunicationActivity[] = [];

  public yourBlockArray: CommunicationActivity[] = [];
  public yourUnblockArray: CommunicationActivity[] = [];
  public yourFriendArray: CommunicationActivity[] = [];
  public yourUnfriendArray: CommunicationActivity[] = [];
  public yourLikeArray: CommunicationActivity[] = [];
  public yourDislikeArray: CommunicationActivity[] = [];
  public yourLockArray: CommunicationActivity[] = [];
  public yourUnlockArray: CommunicationActivity[] = [];
  public yourStarArray: CommunicationActivity[] = [];
  public yourTrackArray: CommunicationActivity[] = [];
  public yourWinkArray: CommunicationActivity[] = [];

  constructor (
    public dictionaryService : DictionaryService,
    private httpService: HttpService,
    private genericSearchSortFilterService: GenericSearchSortFilterService,
    private photoPrivacyService : PhotoPrivacyService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    private rendererServiceService : RendererServiceService,
  ) {
    this.initialize();
  }
  // ------------------------------------------------------------
  protected initialize(): void {
    // debugger; //TODO: check the value!!!!
    if (this.rendererServiceService) { this.rendererService = this.rendererServiceService.getRendererService() as RendererService; }
    if (this.rendererService) { this.renderer = this.rendererService.getRenderer() as Renderer2; }
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    this.myLikeDictionary = this.dictionaryService.getMyLikeDictionary();
    // debugger;
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.timerArray.forEach((timer) => clearInterval(timer));
  }
  /*
   * ============================================================================================================
   * Begin of Dictionary based CommunicationActivity-Toggle-and-NonToggle-Behavior-System:
   * ============================================================================================================
   * Note: This system is for creating a 'unique-CommunicationActivity-array' in order to implement the
   *       Toggle and non-Toggle behaviors.
   *
   *       This 'unique-CommunicationActivity-array' contains the latest CommunicationActivity data of a certain behavior's
   *       action between a tuple of two users.
   *
   *       MemberActiviy is the model wich is used to hold these behaviors' model-state-data.
   *
   *       For example, for 'Tracking activity/action' it should contain
   *       only the latest 'Track' action's CommunicationActivity model-data.
   *       Wink-action is similar to track-action.
   *
   *       The array contains such data of many tuples where the 'Doer' is the 'signedInUser',
   *       and the 'Doee' is any other user other than the 'signedInuser'.
   *
   *       The 'Doer' is the user who initiates/performs these actions and
   *       the 'Doee' is the one on whom these actions are performed.
   *
   *       Two opposing actions constitutes a Toggle-behavior, and a single action constitues a
   *       non-Toggle-Behavior.
   *
   *       For example: lock and unlock actions constitue an Unlocking-Toggle-Behavior to unlock
   *       a certain user's (has to be signed-in/logged-in, hence signedInUser) PRIVATE pictures
   *       to be viewable by the another user (only one user, hence 1-to-1 relatinship),
   *       constituting a tuple of <Doer, Doee> or <signedInUser, some-other-user>.
   *
   *       And viewing a member's profile constitues a non-Toggle-Tracking-Behavior by the single
   *       action of clicking the profile-tile of that member from the Members page.
   *
   *       The Toggle-Behaviours and their respective actions are the following:
   *         1:  Blocking: (behavior)
   *           1.1:  block (action)
   *           1.2:  unblock (action)
   *         2:  Friending:
   *           2.1:  friend
   *           2.2:  unfriend
   *         3:  Liking:
   *           3.1:  like
   *           3.2:  dislike
   *         4:  Unlocking:
   *           4.1:  lock
   *           4.2:  unlock
   *
   *       The non-Toggle-Behaviors and the respective actions are the following:
   *         5: Tracking:
   *           5.1: track
   *         6: Winking:
   *           6.1: wink
   *
   *       The 'tempUniqueMemberActivities' array will be used to produce the respective
   *       'action-based-dictionaries' which are used to implement these behaviors and maintain their states.
   *
   * Note: Toggle-Behaviour-actions (block/unblock, friend/unfriend, like/dislike, lock/unlock)
   *       have no expiry dates.
   *       Hence, their behavior is the same.
   *
   *       But unlock-action has an expiry date, hence lock/unlock behave slightly differently while
   *       maintaining the general toggle-behavior.
   *
   * Note: General Toggle-Behavior:
   *       The back-end database never updates (edits) an existing Toggle-Behavior's data, it just adds.
   *       Each time a user acts, it is recorded as adding a new record. Therefore, for a specific user,
   *       there can be more than one entry for each action such as a 'tala' action.
   *       The back-end server may not group a similar action-entries, and send the latest one for a user.
   *       Therefore, this routine assumes that a array/list of a certain action-entries may be presented for a user,
   *       and it should only keep the latest action of a behavior for a certain user.
   *
   *       The 'unique-CommunicationActivity-array' will cotain all of these 'latest-behavior-action-data' for all visited profiles,
   *       by actions taken on each user when the signedInUser visits the profile of another user (not his own),
   *       Hence it contains all of the CommunicationActivity data which is used to produce the respective behavior-dictionary.
   *
   *       A toggle-behavior consits of two actions such as 'tala' and 'unlock'. So, for
   *       a certain toggle-action, there can be two lists to consider, and there will be two
   *       latest entries, one for each list. Amongst these two latest entries, the latest-one wins,
   *       meaning the final toggle-state will be of that entry.
   *
   *       For example, if latest lock-action's entry key is 'lock.15' and latest unlock-action entry key
   *       is 'unlock.27', the final toggle-action-state is that of 'unlock.27' for that user,
   *       and in the myUnlockDictionary, only the 'unlock.27' entry should exist.
   *       It is noteworty that Lock-Unlock has its own entity(table) on the back-end-db, and the other
   *       toggle-actions have their sigle entity/table.
   *
   *       Special Lock/Unlcok behavior:
   *       Unlock-action has an expiry date. If a user has a valid (unexpired) unlcok action in a dictionary (my or your),
   *       and if the user subsiquently takes an opposite action, which will have a latest 'key'
   *       (key is composed of 'actionName.id'), this method will not alter the expiry date of the unlock-action.
   *       Instead, this method will just simply remove the 'unlock' entry from the respective dictionary,
   *       and add the 'tala' entry into the respective unlock-dictionary to reflect the current toggle-state.
   *
   *       CommunicationActivity Toggle Bevahior are maintained via a set of Dictionaries on the front-end (client) which are:
   *       1. MyDictionaries:
   *         1.1: myBlockDictionary (for  block/unblock)
   *         1.2: myfriendDictionary (for friend/unfriend)
   *         1.3: myLikeDictionary (for like/dislike)
   *         1.4: myTrackDictionary (for Track) (non-toggle-behavior)
   *         1.5: myUnlockDictionary (for lock/unlock)
   *         1.6: myWinkDictionary (for Wink) (non-toggle-behavior)
   *       2. YourDictionaries:
   *         2.1: yourBlockDictionary (for  block/unblock)
   *         2.2: yourfriendDictionary (for friend/unfriend)
   *         2.3: yourLikeDictionary (for like/dislike)
   *         2.4: yourTrackDictionary (for Track) (non-toggle-behavior)
   *         2.5: yourUnlockDictionary (for lock/unlock)
   *         2.6: yourWinkDictionary (for Wink) (non-toggle-behavior)
   *
   * Note: On various member-actions (Block, Friend, Like, Unlock, Trac, Wink):
   *
   *       The others' (yourDictionary) action on these are computed behind the scene
   *       when the signedInUser visits another user's profile.
   *       The signedInUser never intentionally acts to get/collect these data.
   *       Therefore he never gets to see this data being collected and hence,
   *       this process is completely abstracted away for him and done behind the scene.
   *       However, his actions are effected by these values that are collected in
   *        'yourDictionaries'.
   *
   *       For example, when others blocked the signedInUser, the signedInUser
   *       should never know that it is being done to him. However, the result is
   *       that the blockers' profiles are never seen by the signedInUser, untill
   *       they unblock him.
   * ============================================================================================================
   * API:
   * Process#1:
   * In order to produce the above mentioned objective, a set of ActionArrayDictionaries created
   * (my and your) as holder of all data sent by the backe-end server, one dictionary for each
   * action, therefore, two dictionaries for each behavior, for example, myBlockArray
   * and yourBlockArray for Blocking behaviour of 'block' and 'unblock' actions.
   *
   * The entries of the dictionaries are sitUserId based key, and ActionArray value.
   * Each ActionArray is kept sorted in descending order, therefore, providing the latest value
   * from the beginning of each ActionArray.
   *
   * There are also some helper methods and behavior based processing methods
   * to manipulate and create these ActionArrays, and dictionaries.
   * ------------------------------------------------------------------------------------------------
   * Begin of Core-Helper-Methods:
   * ------------------------------------------------------------------------------------------------
   */
  addActivityToUserActionArray ( arrayName : activityArrayEnum, mAct : CommunicationActivity, sitId: number ) :  CommunicationActivity[]
  {
    let actionArr: CommunicationActivity[] = [];

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arrayName) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct) && sitId > 0) {

      actionArr = this.callActivityArray( arrayName ) as [];

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionArr) &&  actionArr.length > 0 )
      {
        let index = this.isActivityFoundInArrayByName( arrayName, mAct );
        if ( index === -1 )
        {
          actionArr.push( mAct );
        }
        else
        {
          actionArr.splice( index, 1, mAct );
        }
      } else {
        // debugger;
        actionArr.push( mAct );
      }
    }
    // debugger;
    return actionArr;
  }
  // ------------------------------------------------------------------------------------------------
  callActivityArray ( arrayName : any) :  CommunicationActivity[]
  {
    let returnActivityArr : any[] = [];
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arrayName ) )
    {
      switch ( arrayName.toString() )
      {
        case 'myBlockArray':
          returnActivityArr = this.myBlockArray;
          break;
        case 'myUnblockArray':
          returnActivityArr = this.myUnblockArray;
          break;
        case 'myFriendArray':
          returnActivityArr = this.myFriendArray;
          break;
        case 'myUnfriendArray':
          returnActivityArr = this.myUnfriendArray;
          break;
        case 'myLikeArray':
          returnActivityArr = this.myLikeArray;
          break;
        case 'myDislikeArray':
          returnActivityArr = this.myDislikeArray;
          break;
        case 'myLockArray':
          returnActivityArr = this.myLockArray;
          break;
        case 'myUnlockArray':
          returnActivityArr = this.myUnlockArray;
          break;
        case 'myStarArray':
          returnActivityArr = this.myStarArray;
          break;
        case 'myTrackArray':
          returnActivityArr = this.myTrackArray;
          break;
        case 'myWinkArray':
          returnActivityArr = this.myWinkArray;
          break;
        case 'yourBlockArray':
          returnActivityArr = this.yourBlockArray;
          break;
        case 'yourUnblockArray':
          returnActivityArr = this.yourUnblockArray;
          break;
        case 'yourFriendArray':
          returnActivityArr = this.yourFriendArray;
          break;
        case 'yourUnfriendArray':
          returnActivityArr = this.yourUnfriendArray;
          break;
        case 'yourLikeArray':
          returnActivityArr = this.yourLikeArray;
          break;
        case 'yourDislikeArray':
          returnActivityArr = this.yourDislikeArray;
          break;
        case 'yourLockArray':
          returnActivityArr = this.yourLockArray;
          break;
        case 'yourUnlockArray':
          returnActivityArr = this.yourLockArray;
          break;
        case 'yourStarArray':
          returnActivityArr = this.yourStarArray;
          break;
        case 'yourTrackArray':
          returnActivityArr = this.yourTrackArray;
          break;
        case 'yourWinkArray':
          returnActivityArr = this.yourWinkArray;
          break;
        default:
          break;
      }
    }
    return returnActivityArr;
  }

  // ------------------------------------------------------------------------------------------------
  callStaticDictionary ( dictionaryName : any ) : Dictionary<number, CommunicationActivity>
  {
    let returnActivityD : any;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dictionaryName ) )
    {
      // debugger; // TODO: check the dictionaryName
      switch ( dictionaryName.toString() )
      {
        case 'myBlockDictionary':
          returnActivityD = this.dictionaryService.myBlockDictionary;
          break;
        case 'myFriendDictionary':
          returnActivityD = this.dictionaryService.myFriendDictionary;
          break;
        case 'myLikeDictionary':
          returnActivityD = this.dictionaryService.myLikeDictionary;
          break;
        case 'myUnlockDictionary':
          returnActivityD = this.dictionaryService.myUnlockDictionary;
          break;
        case 'myStarDictionary':
          returnActivityD = this.dictionaryService.myStarDictionary;
          break;
        case 'myTrackDictionary':
          returnActivityD = this.dictionaryService.myTrackDictionary;
          break;
        case 'myWinkDictionary':
          returnActivityD = this.dictionaryService.myWinkDictionary;
          break;
        case 'yourBlockDictionary':
          returnActivityD = this.dictionaryService.yourBlockDictionary;
          break;
        case 'yourFriendDictionary':
          returnActivityD = this.dictionaryService.yourFriendDictionary;
          break;
        case 'yourLikeDictionary':
          returnActivityD = this.dictionaryService.yourLikeDictionary;
          break;
        case 'yourUnlockDictionary':
          returnActivityD = this.dictionaryService.yourUnlockDictionary;
          break;
        case 'yourStarDictionary':
          returnActivityD = this.dictionaryService.yourStarDictionary;
          break;
        case 'yourTrackDictionary':
          returnActivityD = this.dictionaryService.yourTrackDictionary;
          break;
        case 'yourWinkDictionary':
          returnActivityD = this.dictionaryService.yourWinkDictionary;
          break;
        default:
          break;
      }
    }
    return returnActivityD;
  }
  // ------------------------------------------------------------------------------------------------
  compareKeys (thisKey : any, thatKey : any): number {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(thisKey) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(thatKey)) {
      let strCmpVal: any;
      let numCmpVal: any;
      const thisKeyParts = this.parseMemberActivityKey(thisKey);
      const thatKeyParts = this.parseMemberActivityKey(thatKey);

      strCmpVal = StringServiceStatic.strcmp(thatKeyParts[0], thisKeyParts[0]);
      numCmpVal = thisKeyParts[1] - thatKeyParts[1];

      const cmpVal = parseInt(strCmpVal, 10) * parseInt(numCmpVal, 10);
      // debugger;

      return cmpVal;
    }
    return -1; // TODO: check to see if other default value should be returned
  }
  // ------------------------------------------------------------------------------------------------
  copyArray(destinationArr: CommunicationActivity[], sourceArr: CommunicationActivity[]): CommunicationActivity[] {
    // let outD = new Dictionary<string, CommunicationActivity>();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationArr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceArr) && sourceArr.length > 0) {
      // debugger;
      sourceArr.map((e) => {
        let index = this.isActivityFoundInArray( destinationArr, e);
        if ( index === -1 )
        {
          destinationArr.push( e );
        }
        else
        {
          destinationArr.splice( index, 1, e );
        }
      });
    }
    // debugger;
    return destinationArr;
  }

  // ------------------------------------------------------------------------------------------------
  copyMemberActivityDictionary (destinationD : Dictionary<number, CommunicationActivity>, sourceD : Dictionary<number, CommunicationActivity>) : Dictionary<number, CommunicationActivity>
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destinationD ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceD ))
    {
      // debugger;
      for (var c in sourceD) {
        var e = sourceD[ c ];
        destinationD.set(Number(e.key), e);
      } 
    }
    // debugger;
    return destinationD;
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * Note: Create Dictionay should be computed from myActionArrayDictionay
   * ------------------------------------------------------------------------------------------------
   */
  createActionDictionaryWithLatestValueFromActionArray (destinationDName : any, sourceDName : any) : Dictionary<number, CommunicationActivity> {

    this.destinationD = new Dictionary<number, CommunicationActivity>();
    // debugger;
    //if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( destinationDName ) )
    //{
    //  this.destinationD = this.callStaticDictionary( destinationDName );
    //}


    let sourceArr :CommunicationActivity[] = [];
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceDName ) )
    {
      sourceArr = this.callActivityArray(sourceDName);
    }
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.destinationD ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sourceArr ) && sourceArr.length > 0) {
      // debugger;
        // debugger;
      sourceArr.map((e) => {
        const latestAct = this.getLatestActivityFromActionArray( sourceArr);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(latestAct)) {
          this.destinationD.set(latestAct.receiverSitUserId, latestAct);
        }
      });
      // debugger;
    }
    // debugger;
    return this.destinationD;
  }
  // ------------------------------------------------------------------------------------------------
  createAllActionDictionariesWithLatestValueFromActionArrayDictionaries(mact: CommunicationActivity): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact)) {
      // debugger;
      let mAction;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact?.action)) {
        mAction = mact?.action;
      } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact?.memberActivity)) {
        mAction = mact?.memberActivity;
      }
      switch (mAction?.toLowerCase()) {

        case 'block':
        case 'unblock':
          // debugger;
          this.dictionaryService.myBlockDictionary = this.createActionDictionaryWithLatestValueFromActionArray('myBlockDictionary', 'myBlockArray');
          this.dictionaryService.myBlockDictionary = this.updateActionDictionaryAgainstOppositeActionArray('myBlockDictionary', 'myUnblockArray');

          this.dictionaryService.yourBlockDictionary = this.createActionDictionaryWithLatestValueFromActionArray('yourBlockDictionary', 'yourBlockArray');
          this.dictionaryService.yourBlockDictionary = this.updateActionDictionaryAgainstOppositeActionArray('yourBlockDictionary', 'yourUnblockArray');
          break;

        case 'friend':
        case 'disfriend':
        case 'unfriend':
          // debugger;
          this.dictionaryService.myFriendDictionary = this.createActionDictionaryWithLatestValueFromActionArray('myFriendDictionary', 'myFriendArray');
          this.dictionaryService.myFriendDictionary = this.updateActionDictionaryAgainstOppositeActionArray('myFriendDictionary', 'myUnfriendArray');

          this.dictionaryService.yourFriendDictionary = this.createActionDictionaryWithLatestValueFromActionArray('yourFriendDictionary', 'yourFriendArray');
          this.dictionaryService.yourFriendDictionary = this.updateActionDictionaryAgainstOppositeActionArray('yourFriendDictionary', 'yourUnfriendArray');
          break;
        case 'like':
        case 'dislike':
          // debugger;
          this.dictionaryService.myLikeDictionary = this.createActionDictionaryWithLatestValueFromActionArray('myLikeDictionary', 'myLikeArray');
          this.dictionaryService.myLikeDictionary = this.updateActionDictionaryAgainstOppositeActionArray('myLikeDictionary', 'myDislikeArray');

          this.dictionaryService.yourLikeDictionary = this.createActionDictionaryWithLatestValueFromActionArray('yourLikeDictionary', 'yourLikeArray');
          this.dictionaryService.yourLikeDictionary = this.updateActionDictionaryAgainstOppositeActionArray('yourLikeDictionary', 'yourDislikeArray');
          break;
        case 'tala':
        case 'unlock':
          // debugger;
          this.dictionaryService.myUnlockDictionary = this.createActionDictionaryWithLatestValueFromActionArray('myUnlockDictionary', 'myUnlockArray');
          this.dictionaryService.myUnlockDictionary = this.updateActionDictionaryAgainstOppositeActionArray('myUnlockDictionary', 'myLockArray');

          this.dictionaryService.yourUnlockDictionary = this.createActionDictionaryWithLatestValueFromActionArray('yourUnlockDictionary', 'yourUnlockArray');
          this.dictionaryService.yourUnlockDictionary = this.updateActionDictionaryAgainstOppositeActionArray('yourUnlockDictionary', 'yourLockArray');
          break;
        case 'star':
          // debugger;
          this.dictionaryService.myStarDictionary = this.createActionDictionaryWithLatestValueFromActionArray('myStarDictionary', 'myStarArray' );
          this.dictionaryService.yourStarDictionary = this.createActionDictionaryWithLatestValueFromActionArray('yourStarDictionary', 'yourStarArray' );
          break;
        case 'track':
          /*
           * this.trackCount++;
           */
          // debugger;
          this.dictionaryService.myTrackDictionary = this.createActionDictionaryWithLatestValueFromActionArray('myTrackDictionary', 'myTrackArray');
          this.dictionaryService.yourTrackDictionary = this.createActionDictionaryWithLatestValueFromActionArray('yourTrackDictionary', 'yourTrackArray');
          break;
        case 'wink':
         // debugger;
          this.dictionaryService.myWinkDictionary = this.createActionDictionaryWithLatestValueFromActionArray('myWinkDictionary', 'myWinkArray');
          this.dictionaryService.yourWinkDictionary = this.createActionDictionaryWithLatestValueFromActionArray('yourWinkDictionary', 'yourWinkArray');
          break;
      }
    }
    // debugger;
  }
  // ------------------------------------------------------------------------------------------------
  // Note:  This method accumulates the following brhavior into asingle MemberActivityDictionary:
  //        1. block/unblock  2. friend/unfriend  3. like/dislike
  // ------------------------------------------------------------------------------------------------
  createMemberActivityDictionariesFromAllDictionaries (mact : any): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact)) {
      // debugger;
      let mAction;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact.action)) {
        mAction = mact.action;
      } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact.value)) {
        mAction = mact.value.MemberAction;
      }
      switch (mAction?.toLowerCase()) {
        
        case 'block':
        case 'unblock':
          // debugger;
          this.dictionaryService.myMemberActivityDictionary = this.copyMemberActivityDictionary(this.dictionaryService.myMemberActivityDictionary, this.dictionaryService.myBlockDictionary);
          this.dictionaryService.yourMemberActivityDictionary = this.copyMemberActivityDictionary(this.dictionaryService.yourMemberActivityDictionary, this.dictionaryService.yourBlockDictionary);
          break;
        
        case 'friend':
        case 'disfriend':
        case 'unfriend':
          // debugger;
          this.dictionaryService.myMemberActivityDictionary = this.copyMemberActivityDictionary(this.dictionaryService.myMemberActivityDictionary, this.dictionaryService.myFriendDictionary);
          this.dictionaryService.yourMemberActivityDictionary = this.copyMemberActivityDictionary(this.dictionaryService.yourMemberActivityDictionary, this.dictionaryService.yourFriendDictionary);
          break;
       
        case 'like':
        case 'dislike':
          // debugger;
          this.dictionaryService.myMemberActivityDictionary = this.copyMemberActivityDictionary(this.dictionaryService.myMemberActivityDictionary, this.dictionaryService.myLikeDictionary);
          this.dictionaryService.yourMemberActivityDictionary = this.copyMemberActivityDictionary(this.dictionaryService.yourMemberActivityDictionary, this.dictionaryService.yourLikeDictionary);
          break;
          // ----------------------------
         
        case 'tala':
        case 'lock':
        case 'unlock':
          // debugger;
            this.dictionaryService.myMemberLockingActivityDictionary =
          this.copyMemberActivityDictionary( this.dictionaryService.myMemberLockingActivityDictionary, this.dictionaryService.myUnlockDictionary );
            this.dictionaryService.yourMemberLockingActivityDictionary =
          this.copyMemberActivityDictionary( this.dictionaryService.yourMemberLockingActivityDictionary, this.dictionaryService.yourUnlockDictionary );
          break;
         case 'star':
          // debugger;
          this.dictionaryService.myMemberTrackingActivityDictionary =
            this.copyMemberActivityDictionary(this.dictionaryService.myMemberTrackingActivityDictionary, this.dictionaryService.myStarDictionary );
          this.dictionaryService.yourMemberTrackingActivityDictionary =
            this.copyMemberActivityDictionary(this.dictionaryService.yourMemberTrackingActivityDictionary, this.dictionaryService.yourStarDictionary );
          break;
         case 'track':
          // debugger;
          this.trackCount++;
          this.dictionaryService.myMemberTrackingActivityDictionary =
            this.copyMemberActivityDictionary(this.dictionaryService.myMemberTrackingActivityDictionary, this.dictionaryService.myTrackDictionary );
          this.dictionaryService.yourMemberTrackingActivityDictionary =
            this.copyMemberActivityDictionary(this.dictionaryService.yourMemberTrackingActivityDictionary, this.dictionaryService.yourTrackDictionary );
          break;
         case 'wink':
          // debugger;
          this.dictionaryService.myMemberTrackingActivityDictionary =
            this.copyMemberActivityDictionary(this.dictionaryService.myMemberTrackingActivityDictionary, this.dictionaryService.myWinkDictionary );
          this.dictionaryService.yourMemberTrackingActivityDictionary =
            this.copyMemberActivityDictionary(this.dictionaryService.yourMemberTrackingActivityDictionary, this.dictionaryService.yourWinkDictionary );
          break;
        default:
           break;
      }
    }
    // debugger;
    return true;
  }
  // ------------------------------------------------------------------------------------------------
  createAndSetMemberViewGlyphMenu(): any{
    EmitterSubjectService.setIsViewMember( true );
    EmitterSubjectService.emitSetMemberViewGlyphMenu(true); // TODO: No listeneres yet
  }
  // ------------------------------------------------------------------------------------------------
  // this method is primarily concerned with creating glyph menu on te basis of memberActivities
  // NOT in use currently
  // ------------------------------------------------------------------------------------------------
  filterMemberViewGlyphByMemberActivities ( result : any, profilePics: ProfilePics ) : any
  {
    let today: any;
    let pPics: any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics))
    {
      this.profilePics = profilePics;
    }

    // diplay the MemberView glyphicon menu only:
    // ------------------------------------------
    if ( result === false )
    {
      // debugger;
      // case-2
      return this.createAndSetMemberViewGlyphMenu();
    }
    // filter against server data on myUnlockingDictionary:
    // ----------------------------------------------------
    else if ( result === true )
    {
      // debugger;
      this.dictionaryService.myUnlockDictionary.get( this.sitUser.sitUserId );

      const serverMActs = this.getMyUnlockingDictionaryActivities();
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( serverMActs ) && serverMActs.length > 0 )
      {
        // debugger;
        serverMActs.map((e : any) =>
        {
          if ( e.senderSitUserId === this.sitUser.sitUserId
            && e.receiverSitUserId === this.loginSuccess.signedInUserId
            && DateStringServiceStatic.compareDate( e.expiryDate, today.toLocaleString() ) >= 0 )
          {
            // debugger; //TODO : Check filtration process
            this.photoPrivacyService.filterKvPhotoArrayByServerData(this.profilePics.profileKvPhotoArr, e).then((data : any) =>
            {
              this.profilePics.unFilteredKvPhotoArr = data;
            } );
            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics.unFilteredKvPhotoArr ) )
            {
              // debugger;
              this.profileImageKvPhotos = this.profilePics.unFilteredKvPhotoArr;
              this.profilePics =  this.photoPrivacyService.displayFilteredKvPhotosOfProfilePics( this.profilePics );
            }
          }
        } );
      }
      else if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics.profileKvPhotoArr )
        && this.profilePics.profileKvPhotoArr.length > 0 )
      {
        // debugger; //TODO : Check filtration process
        let tfiltered : any;
        this.photoPrivacyService.filterKvPhotoArrayByMemberLockingActivityDictionaryData(
          this.profilePics.profileKvPhotoArr).then((data : any) =>
          {
            tfiltered = data;

            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tfiltered ) )
            {
              this.profilePics.unFilteredKvPhotoArr = this.filteredKvPhotoArr = tfiltered;
            }
            // TODO : try to eliminate the 'this.filteredKvPhotoArr' variable:
            else if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics )
              && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics.profileKvPhotoArr ) )
            {
              this.profilePics.unFilteredKvPhotoArr = this.profilePics.profileKvPhotoArr;
            }
            // debugger;
            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.profilePics )
              && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoArr ) )
            {
              // debugger;
              this.profileImageKvPhotos = this.profilePics.unFilteredKvPhotoArr;
              this.photoPrivacyService.displayFilteredKvPhotosOfProfilePics( this.profilePics );
            }
          } );
      }

      // debugger;
      return this.createAndSetMemberViewGlyphMenu();
    }
    // result==MemberActicity[]:
    // -------------------------
    else if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) && result.length > 0 )
    {
      // debugger;
      this.profilePics = EmitterSubjectService.getProfilePics();
      today = DateStringServiceStatic.getTicks( new Date() );
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      result.map((e : any) =>
      {
        if ( e.doereSitUserId === this.sitUser.sitUserId
          && e.senderSitUserId === this.loginSuccess.signedInUserId
          && e.action.toLowerCase().indexOf( 'unlock' ) !== -1
          && parseInt( e, 10 ) - today >= 0 )
        {
          // debugger;//TODO : Check filtration process
          this.photoPrivacyService.filterKvPhotoArrayByServerData(this.profilePics.profileKvPhotoArr, e).then((data : any) =>
          {
            this.profilePics.unFilteredKvPhotoArr = data;
          } );
          this.profilePics = this.photoPrivacyService.displayFilteredKvPhotosOfProfilePics( this.profilePics );
          return this.createAndSetMemberViewGlyphMenu();
        }
        else if ( e.action.toLowerCase().indexOf( 'track' ) === -1 && e.action.toLowerCase().indexOf( 'wink' ) === -1 )
        {
          // debugger;
          // case-3
          return this.createAndSetMemberViewGlyphMenu();
        }
      } );
    }
    else
    {
      // debugger;
      return this.createAndSetMemberViewGlyphMenu();
    }
  }
  // ------------------------------------------------------------------------------------------------
  getLastValueOfDictionary (dictionary : Dictionary<any, any>) : any {
    var values : any[] = [];
    var length = 0;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionary)) {
      for (var c in dictionary) {
        var v = dictionary[ c ];
        values.push(v);
        length++;
      }
      const last = values[length - 1];

      return last;
    }
    else return null;
  }
  // ------------------------------------------------------------------------------------------------
  getLatestActivityFromActionArray(actionArr: CommunicationActivity[]): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionArr) && actionArr.length > 0) {
      // debugger;
      actionArr = actionArr.sort((a, b) => b.memberActivity.toString().localeCompare(a.memberActivity.toString()));
      // debugger;
      return actionArr[0];
    }
    return null;
  }
  /*
   * ------------------------------------------------------------------------------------------------
   *   Note: this may be not used
   * ------------------------------------------------------------------------------------------------
   */
  getLatestActivityFromActionDictionary (actionD : Dictionary<number, CommunicationActivity>, sitId: number, action: string): CommunicationActivity {
    let outAct : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionD) && sitId > 0) {
      if (actionD.has(sitId)) {
        let userAct = actionD.get(sitId) as CommunicationActivity;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userAct) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userAct.action) && userAct.action.toLowerCase().indexOf(action) !== -1) {
          if (userAct.receiverSitUserId === sitId) {
            outAct = userAct;
          }
        }
      }
    }
    // debugger;
    return outAct;
  }

  // ---------------------------------------------------------------
  // NOTE: all the data upon arrival are sequestered into its respective dictionaries and indexedDB
  // ---------------------------------------------------------------
  public getMemberActivitiesOfSitUser (sitid : number, act: string) : Observable<MemberActivitiesAndAction> {
    let sitId = sitid;
    let action = act;
    let memberActivitesAndAction : MemberActivitiesAndAction = new MemberActivitiesAndAction();
    return new Observable<any>(subscriber => {
      if (sitId > 0)
      {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();

        if (sitId !== this.loginSuccess.signedInUserId)
        {
          switch (action.toLowerCase())
          {
            case 'like':
            case 'dislike':
            case 'friend':
            case 'unfriend':
            case 'block':
            case 'unblock':
            case 'star':
            case 'wink':
              this.getMyActivities(sitId, 'MemberActivity').subscribe(data => {
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
                  memberActivitesAndAction.action = data.action;
                  memberActivitesAndAction.memberActivities.push(...data);
                  // debugger;
                  subscriber.next(memberActivitesAndAction);
                  // subscriber.complete();
                  // debugger;
                }
              });
              break;

            case 'tala':
            case 'lock':
            case 'unlock':
              // debugger;
              this.getMyActivities(sitId, 'unlock').subscribe(data => {
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
                  memberActivitesAndAction.action = data.action;
                  memberActivitesAndAction.memberActivities.push(...data);
                  subscriber.next(memberActivitesAndAction);
                  // subscriber.complete();
                }
              });
              break;

            case 'track':
              this.getMyActivities(sitId, 'track').subscribe(data => {
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
                  memberActivitesAndAction.action = data.action;
                  memberActivitesAndAction.memberActivity = data;
                  memberActivitesAndAction.memberActivities.push(data);
                  subscriber.next(memberActivitesAndAction);
                  // subscriber.complete();
                }
              });
              break;
          }          
          
        }         
      }
    })
  }

  // ---------------------------------------------------------------
  getMyActivities ( sitId : number, act : string ) : Observable<any>
  {
    // debugger;
    var sitUserId = sitId;
    var action = act;
    return new Observable<any>(subscriber => {
      if (sitUserId > 0) {
        // debugger;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;

        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)) {
          this.sitUser = new SitUser();
        }
        this.sitUser.sitUserId = sitUserId; // Note: important!

        this.memberActivity = new CommunicationActivity();
        this.memberActivity.action = action;
        this.memberActivity.modelName = action;
        this.memberActivity.senderSitUserId = this.loginSuccess.signedInUserId;
        this.memberActivity.receiverSitUserId = sitUserId;
        this.memberActivity.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
        this.getMemberActivities(this.memberActivity, sitUserId).subscribe(data => {
          // debugger;
          if (data instanceof Array && data.length > 0) {
            subscriber.next(...data);
          }
          else {
            subscriber.next(data);
          }
          subscriber.complete();
        })
      }
      else return null;
    })
  }
  // ---------------------------------------------------------------
  //  Currently in use:
  // ---------------------------------------------------------------
  getINotificationsOfAction (action : string): Observable<CommunicationActivity[]>{
    // debugger;
    return new Observable<any>((subscriber) => {
      // debugger;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action)) {
        let url = 'api/Enote/GetINotificationsOfAction/';
        // debugger;
        let model : ClientHttpDataModel = new ClientHttpDataModel();
        model.action = action;
        model.sitUserKey = this.loginSuccess.signedInUserKey;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(model);
        this.httpService.postObservable(url, {
          box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
          nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce), }, 'json2text')
        .subscribe((result) => {
          // debugger;
          subscriber.next(result);
        })
      }
    })
  }
  // ---------------------------------------------------------------
  //  Currently in use:
  // ---------------------------------------------------------------
  getINotificationsOfLockingAction (action : string) : Observable<CommunicationActivity[]> {
    // debugger;
    return new Observable<any>((subscriber) => {
      // debugger;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action)) {
        let url = 'api/Enote/GetINotificationsOfLockingAction/';
        // debugger;
        let model : ClientHttpDataModel = new ClientHttpDataModel();
        model.action = action;
        model.sitUserKey = this.loginSuccess.signedInUserKey;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(model);
        this.httpService.postObservable(url, {
          box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
          nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
        }, 'json2text')
          .subscribe((result) => {
            // debugger;
            subscriber.next(result);
          })
      }
    })
  }
  // ---------------------------------------------------------------
  //  Currently in use:
  // ---------------------------------------------------------------
  getINotificationsOfTrackingAction (action : string) : Observable<CommunicationActivity[]> {
    // debugger;
    return new Observable<any>((subscriber) => {
      // debugger;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action)) {
        let url = 'api/Enote/GetINotificationsOfTrackingAction/';
        // debugger;
        let model : ClientHttpDataModel = new ClientHttpDataModel();
        model.action = action;
        model.sitUserKey = this.loginSuccess.signedInUserKey;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(model);
        this.httpService.postObservable(url, {
          box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
          nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
        }, 'json2text')
          .subscribe((result) => {
            // debugger;
            subscriber.next(result);
          })
      }
    })
  }
  // ---------------------------------------------------------------
  //getYourActivities ( sitId : number, act : string ) : Observable<any>
  //{
  //  debugger;
  //  var sitUserId = sitId;
  //  var action = act;
  //  return new Observable<any>(subscriber => {
  //    if (sitUserId > 0) {
  //      // debugger;
  //      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //      // debugger;

  //      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)) {
  //        this.sitUser = new SitUser();
  //      }
  //      this.sitUser.sitUserId = sitUserId; // Note: important!

  //      this.memberActivity = new CommunicationActivity();
  //      this.memberActivity.action = action;
  //      this.memberActivity.modelName = action;
  //      this.memberActivity.senderSitUserId = this.sitUser.sitUserId;
  //      this.memberActivity.receiverSitUserId = this.loginSuccess.signedInUserId;
  //      this.memberActivity.signedInUserId = this.sitUser.sitUserId;

  //      this.getMemberActivities(this.memberActivity, sitUserId).subscribe(data => {
  //        debugger;
  //        subscriber.next(data);
  //        subscriber.complete();
  //      })
  //    }
  //    else {
  //      debugger;
  //      subscriber.next(null);
  //      subscriber.complete();
  //    }
  //  })
  //}
  // ---------------------------------------------------------------
  // ---------------------------------------------------------------
  // Currently In Use:
  // ---------------------------------------------------------------
  getMemberActivities ( mActivity: CommunicationActivity, sitId: number ): Observable<any> {
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mActivity ) && sitId > 0)
    {
      this.memberActvity = mActivity;
      let action = mActivity.action;
      let url = '/Enote/GetMemberActivities';
      let cActs : any;
      let uniqueActs : CommunicationActivity[] = [];
      let mAct : CommunicationActivity = mActivity;;
      let sitUserId = sitId;
      // will get activities with respect to the sitUser:
      if (sitUserId !== this.loginSuccess.signedInUserId) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action)) {
          // debugger;
          if (action.toLowerCase().indexOf('lock') !== -1
            || action.toLowerCase().indexOf('unlock') !== -1
            || action.toLowerCase().indexOf('tala') !== -1) {
            url = 'api/Enote/GetLockingActivity';
          }
          else if (action.toLowerCase().indexOf('track') !== -1) {
            url = 'api/Enote/GetTrackingActivity';
          }
          else {
            // debugger;
            url = 'api/Enote/GetMemberActivities';
          }
        }
        else {
          // debugger;
          url = 'api/Enote/GetMemberActivities';
        }
      }
      // debugger;
      this.boxNonceEntity = SlakezSaltServiceStatic.saltModel( this.memberActivity );
      return new Observable<any>( ( subscriber ) =>
      {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce))
        {
          // debugger;

          this.httpService.postObservable( url,
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
            }, 'json2text' )
            .subscribe( ( result ) =>
            {
              // debugger;
              if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
              {
                cActs = result;
                // debugger;

                // Note: GetMemberActivities sends an array:
                //-----------------------------------------
                if (cActs instanceof Array && cActs.length > 0) {
                  // debugger;
                  cActs.map((e) => {
                    // debugger;

                    // update dictionary and indexedDb:
                    // --------------------------------
                    if (sitUserId !== this.loginSuccess.signedInUserId) {
                      e.senderSitUserId = mAct.senderSitUserId;
                      e.receiverSitUserId = mAct.receiverSitUserId;
                      // debugger;
                      this.populateDictionary(e);

                    };
                  })
                }
                else {
                  // Note: GetLockingActivity sends one CommuniationActivity:
                  // --------------------------------------------------------
                  // debugger;
                  cActs.senderSitUserId = mAct.senderSitUserId;
                  cActs.receiverSitUserId = mAct.receiverSitUserId;
                  this.populateDictionary(cActs);
                }
                // debugger;
                
              }

              // debugger;
              this.saveDictionaryToIndexedDb(action);
              if (cActs instanceof Array && cActs.length > 0) {
                subscriber.next(...cActs);
              }
              else {
                subscriber.next(cActs);
              }              
            },
              ( error ) =>
              {
                // debugger;
                this.message = 'Error occured in getMemberActivities(senderSitUserId:' + mAct.senderSitUserId;
                this.message += '; receiverSitUserId:' + mAct.receiverSitUserId + '; action: ' + action;
                this.message += ');\n Error message:  ' + error.message;
              }
            );
        }
        if ( this.message.length > 10 )
        {
          console.log( this.message );
          // EmitterSubjectService.emitMyErrorLog( this.message );
        }
       
      } );
    } else {
      // debugger;
      this.message = 'Could not sent method getMemberActivities(senderSitUserId:' + this.memberActivity.senderSitUserId;
      this.message += '; receiverSitUserId:' + sitId + '; action: ' + this.memberActivity.action + ');\n';
      if ( this.message.length > 10 )
      {
        console.log( this.message );
        // EmitterSubjectService.emitMyErrorLog( this.message );
      }
      // debugger;
      
    }
  }

  // -----------------------------------------------------------
  nullPromise ( data : any ) : any
  {
    this.timer = setTimeout( () =>
    {
      // debugger;
      return data;
    }, 500);
    clearTimeout(this.timer);
    if ( this.timer )
    {
      this.timerArray.push( this.timer );
    }
  }
  // -----------------------------------------------------------
  createUniqueLockingUsers (commActivityArr : CommunicationActivity[]) : CommunicationActivity[]{
    let uniqueUsers : CommunicationActivity[] = [];
    this.commActivityDictionary = new Dictionary<string, CommunicationActivity>();
    commActivityArr.map(e => {
      // debugger;
      this.commActivityDictionary.set(e.receiverSITUserKey, e);
    })
    uniqueUsers = this.commActivityDictionary.values();
    return uniqueUsers;
  }
  // -----------------------------------------------------------
  populateDictionary (e: CommunicationActivity): void {
    // update dictionary and indexedDb:
    // --------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
      //e.senderSitUserId = mAct.senderSitUserId;
      //e.receiverSitUserId = mAct.receiverSitUserId;
      // debugger;
      switch (e?.memberActivity?.toLowerCase()) {
        case 'mymembertrackingactivity':
          // debugger;
          this.dictionaryService.updateDictionary(e, "myMemberTrackingActivity", e.lockingActivityId);
          // debugger;
          break;
        case 'tala':
        case 'lock':
        case 'unlock':
        case 'lockingactivity':
        case 'memberlockingactivity':
          // debugger;
          this.dictionaryService.updateDictionary(e, "myMemberLockingActivity", e.lockingActivityId);
          // debugger;
          break;
        case 'like':
        case 'dislike':
        case 'friend':
        case 'unfriend':
        case 'block':
        case 'unblock':
        case 'star':
        case 'wink':
        case 'memberactivity':
          // debugger;
          this.dictionaryService.updateDictionary(e, "myMemberActivity", e.notificationActivityId);
          // debugger;
          break;
      }
    }
  }
  // -----------------------------------------------------------
  // update indexedDb for dictionaries:
  // -----------------------------------------------------------
  saveDictionaryToIndexedDb (action : string) : void {    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(action)) {
      // debugger;
      switch (action.toLowerCase()) {
        case 'mymembertrackingactivity':
           debugger;
          if (this.dictionaryService.myMemberTrackingActivityDictionary?.length > 0) {
            // debugger;
            this.dbDexieToDictionaryService
              .saveDictionaryToDbDexie(
                this.dictionaryService.myMemberTrackingActivityDictionary,
                "myMemberTrackingActivity",
                this.loginSuccess.signedInUserId);
          }
          break;
        case 'tala':
        case 'lock':
        case 'unlock':
        case 'lockingactivity':
        case 'memberlockingactivity':
          // debugger;
          if (this.dictionaryService.myMemberLockingActivityDictionary?.length > 0) {
            // debugger;
            this.dbDexieToDictionaryService
              .saveDictionaryToDbDexie(
                this.dictionaryService.myMemberLockingActivityDictionary,
                "myMemberLockingActivity",
                this.loginSuccess.signedInUserId);
          }
          break;
        case 'like':
        case 'dislike':
        case 'friend':
        case 'unfriend':
        case 'block':
        case 'unblock':
        case 'star':
        case 'wink':
        case 'memberactivity':
          // debugger;
          if (this.dictionaryService.myMemberActivityDictionary?.length > 0) {
            // debugger;
            this.dbDexieToDictionaryService
              .saveDictionaryToDbDexie(
                this.dictionaryService.myMemberActivityDictionary,
                "myMemberActivity",
                this.loginSuccess.signedInUserId);
          }
          break;
      }
    }
  }
  
  // ------------------------------------------------------------------------------------------------
  updateActionDictionaryAgainstOppositeActionArray (actionDName : any, arrayName : any) : Dictionary<number, CommunicationActivity> {
    var outActionDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
    // debugger;
    var actionD = new Dictionary<number, CommunicationActivity>();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( actionDName ) )
    {
      actionD = this.callStaticDictionary( actionDName );
    }

    let oppositeActionArr : CommunicationActivity[] = [];
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( actionD ) )
    {
      oppositeActionArr = this.callActivityArray( arrayName);
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionD) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(oppositeActionArr)) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      this.sitUser = EmitterSubjectService.getSitUserModel();
      // debugger;
      // it works
      outActionDictionary = JSON.parse(JSON.stringify(actionD)); // will be mutable copy of immutable source
      // debugger;

      if (actionD.size > 0) {
        if (oppositeActionArr.length > 0) {
          // get the keys of actionD and keep it immutable:
          const actionKeys = actionD.keys();
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionKeys) && actionKeys.length > 0) {
            actionKeys.map((sitId) => {
              let actionValue = actionD.get( sitId ) as CommunicationActivity;
              const latestActionArrayValue = this.getLatestActivityFromActionArray(oppositeActionArr);
              // debugger;

              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionValue) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(latestActionArrayValue)) {
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionValue.action) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(latestActionArrayValue.action)) {
                  if (
                    actionValue.senderSitUserId === latestActionArrayValue.senderSitUserId && // same Doer
                    actionValue.receiverSitUserId === latestActionArrayValue.receiverSitUserId
                  ) {
                    // same Doee
                    if (
                      (actionValue.action.toLowerCase().indexOf('unblock') !== -1 && latestActionArrayValue.action.toLowerCase().indexOf('block') !== -1) || // opposite actions
                      (actionValue.action.toLowerCase().indexOf('block') !== -1 && latestActionArrayValue.action.toLowerCase().indexOf('unblock') !== -1)
                    ) {
                      /*
                       * opposite actions
                       * opposite-action id is more recent than action id on same Entity/Table
                       */
                      if (actionValue.id < latestActionArrayValue.id && outActionDictionary.has(sitId)) {
                        outActionDictionary.remove(sitId);
                        // debugger;
                      }
                    } else if (
                      (actionValue.action.toLowerCase().indexOf('unlock') !== -1
                        && (latestActionArrayValue.action.toLowerCase().indexOf('tala') !== -1
                        || latestActionArrayValue.action.toLowerCase().indexOf('lock') !== -1)) || // opposite actions
                      ((actionValue.action.toLowerCase().indexOf('tala') !== -1
                        || latestActionArrayValue.action.toLowerCase().indexOf('lock') !== -1)
                        &&
                        // opposite actions  { // lock/unlock action => same Entity/Table of backend-DB
                        latestActionArrayValue.action.toLowerCase().indexOf('unlock') !== -1)
                    ) {
                      // un/dis-action should contain action
                      if (actionValue.action.toLowerCase().indexOf(latestActionArrayValue.action.toLowerCase()) !== -1) {
                        /*
                         * Note: no need to act upon expired unlocked activity here
                         * opposite-action id is more recent than action id on same Entity/Table
                         */
                        if (actionValue.id < latestActionArrayValue.id && outActionDictionary.has(sitId)) {
                          outActionDictionary.remove(sitId);
                          // debugger;
                        }
                      }
                    }
                    // all other cases; un/dis-action should contain action
                    else if (latestActionArrayValue.action.toLowerCase().indexOf(actionValue.action.toLowerCase()) !== -1) {
                      // opposite-action id is more recent than action id on same Entity/Table
                      if (actionValue.id < latestActionArrayValue.id && outActionDictionary.has(sitId)) {
                        outActionDictionary.remove(sitId);
                        // debugger;
                      }
                    }
                  }
                }
              }
            });
          }
        }
        // else nothing to do in action-Dictionary  since opposite-ActionArray is empty, hence nothing to remove against
      }
      // else nothing to do since ActionDictionary is empty, hence nothing to remove
    }
    // debugger;
    return outActionDictionary;
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * Note: most likely this will not be used
   * ------------------------------------------------------------------------------------------------
   */
  removeActivityFromActionArray(actionArr: CommunicationActivity[], removeAct: CommunicationActivity, sitId: number):  CommunicationActivity[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(actionArr) && actionArr.length > 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(removeAct) && sitId > 0) {
      let index = this.isActivityFoundInArray( actionArr, removeAct );
      if ( index > -1 )
      {
        actionArr = actionArr.splice( index, 1 );
      }
    }
    // debugger;
    return actionArr;
  }
  
  // ---------------------------------------------------------------
  // Note: used by SendMemberActivity()
  // ---------------------------------------------------------------
  processMemberActivityResult (mAct : CommunicationActivity, result : any, action : string) : any
  {
    let tMAct : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result))
    {
      tMAct = JSON.parse(JSON.stringify(result) ) as CommunicationActivity;
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tMAct))
      {
        if (tMAct.senderSITUserId === this.loginSuccess.signedInUserId)
        {
          this.myMemberActivity = tMAct;
          // debugger;
          EmitterSubjectService.setMemberActivity(this.myMemberActivity);
          // debugger;

          switch (tMAct.modelName) {
            case 'LockingActivity':
              // debugger;
              this.dictionaryService.updateDictionary(tMAct, 'myMemberLockingActivity', tMAct.lockingActivityId); 
              break;

            case 'MemberActivity':
              // debugger;
              this.dictionaryService.updateDictionary(tMAct, 'myMemberActivity', tMAct.notificationActivityId);
              // debugger;
              break;
          }
        }
        return tMAct;
      }
      else return null;
    }
    else {
      this.message = 'No CommunicationActivity to record. Result is null/undefined on sendMemberActivity(senderSitUserId:';
      this.message += this.memberActivity.senderSitUserId + '; receiverSitUserId:' + this.sitUser.sitUserId + ');\n result: ' + result;
      return null;
    }    
  }
  // ---------------------------------------------------------------
  //  Note: Even though TrackingActivity is part of glyphicons,
  //        it is rather a separate system with it's own entity/table
  //        in the database. Hence a parallel system for tracking is
  //        created.
  //        The main reason for this is that there will be lot more
  //        tracking activity than any other member activities.
  // ---------------------------------------------------------------
  sendTrackingActivity (cAct : CommunicationActivity) : void  {
    // let outMAct = new CommunicationActivity();
    // debugger;
    
    let commAct : CommunicationActivity = cAct;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.action)) {
      // debugger;      
      this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(commAct);
      let url = '/api/Enote/SendTrackingActivity';          
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        this.httpService
          .postObservable(
            url,
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text',
          )
          .subscribe(
            (result) => {
              console.log(result.message + ' for sitUserId=' + cAct.sitUserId);
              // debugger;
            },
            (error) => {
              this.sitUser = EmitterSubjectService.getSitUserModel();
              this.message = 'Error occured in sendTrackingActivity(senderSitUserId:' + commAct.senderSitUserId;
              this.message += '; receiverSitUserId:' + this.sitUser.sitUserId + ');\n Error message:  ' + error.message;
              console.log(this.message);
              // EmitterSubjectService.emitMyErrorLog(this.message);

            });
      }        
    }
  }
  // ---------------------------------------------------------------
  sendMemberActivity (mAct : CommunicationActivity) : Observable<any> {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    let outMAct = new CommunicationActivity();
    let action = '';
    // debugger;
    let commAct : CommunicationActivity = mAct;
    this.sitUser = EmitterSubjectService.getSitUserModel();
    // debugger;
    return new Observable((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.action)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)) {
        // debugger;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();

        if (this.loginSuccess.signedInUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId) {
          this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(commAct);
          let url = '';
          
          if (commAct.action.toLowerCase().indexOf('tala') !== -1 ||
            commAct.action.toLowerCase().indexOf('lock') !== -1 ||
            commAct.action.toLowerCase().indexOf('unlock') !== -1) {
            url = '/api/Enote/UpdateLockingActivity';
            action = 'LockingActivity';
          }
          else if (commAct.action.toLowerCase().indexOf('wink') !== -1 || 
            commAct.action.toLowerCase().indexOf('star') !== -1 ||  
            commAct.action.toLowerCase().indexOf('block') !== -1 ||
            commAct.action.toLowerCase().indexOf('unblock') !== -1 ||
            commAct.action.toLowerCase().indexOf('like') !== -1 ||
            commAct.action.toLowerCase().indexOf('dislike') !== -1 ||
            commAct.action.toLowerCase().indexOf('friend') !== -1 ||
            commAct.action.toLowerCase().indexOf('unfriend') !== -1 
          ) {
            url = '/api/Enote/UpdateMemberActivity';
            action = 'MemberActivity';
          }
          // debugger;

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            this.httpService
              .postObservable(
                url,
                {
                  box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
                  nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
                },
                'json2text',
              )
              .subscribe(
                (result) => {
                  // debugger;
                  outMAct = this.processMemberActivityResult(mAct, result, action);
                  // debugger;
                  subscriber.next(outMAct);
                  // subscriber.complete();
                },
                (error) => {
                  this.message = 'Error occured in sendMemberActivity(senderSitUserId:' + this.memberActivity.senderSitUserId;
                  this.message += '; receiverSitUserId:' + this.sitUser.sitUserId + ');\n Error message:  ' + error.message;
                  console.log(this.message);
                  // EmitterSubjectService.emitMyErrorLog(this.message);

                });
          }
        }
      }
    });
  }
  // ---------------------------------------------------------------
  populateRespectiveActionArray (mact : CommunicationActivity): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact)) {
      // debugger;
      let mAction;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact?.action)) {
        mAction = mact?.action;
      } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact?.memberActivity)) {
        mAction = mact?.memberActivity;
      }
      // debugger;
      switch (mAction?.toLowerCase()) {
        
        case 'block':
          // debugger;
          this.processBlockingBehavior(mact);
          break;
        case 'unblock':
          // debugger;
          this.processUnblockingBehavior(mact);
          break;
        case 'friend':
          // debugger;
          this.processFriendingBehavior(mact);
          break;
        case 'unfriend':
          // debugger;
          this.processUnfriendingBehavior(mact);
          break;
        case 'like':
          // debugger;
          this.processLikingBehavior(mact);
          break;
        case 'dislike':
          // debugger;
          this.processDislikingBehavior(mact);
          break;
        
        case 'lock':
          // debugger;
          this.processLockingBehavior(mact);
          break;
        case 'tala':
          // debugger;
          this.processLockingBehavior(mact);
          break;
        case 'unlock':
          // debugger;
          this.processUnlockingBehavior(mact);
          break;
        case 'star':
          // debugger;
          this.processStarringBehavior( mact );
          break;
        //case 'track':
        //  // debugger;
        //  this.processTrackingBehavior(mact);
        //  break;
        case 'wink':
          // debugger;
          this.processWinkingBehavior(mact);
          break;
      }
    }
    return true;
  }

  // ------------------------------------------------------------------------------------------------
  quickSortArray (arr : any): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && arr.length > 0) {
      /*
       * arr = arr.sort((a, b) => a.key.toString().localeCompare(b.key.toString()));
       * arr = arr.sort((a, b) => this.compareKeys(a.key, b.key));
       */
      arr = arr.sort(((a : any, b : any) => this.genericSearchSortFilterService.genericSort<CommunicationActivity>(a, b, this.activeSorter))); // TODO: restore
    }
    return arr;
  }
  // -----------------------------------------------------------
  prefetchMyMemberActivities (sitId : number, action : string) {
    // NOTE: though this methods gets the memberActivities, it does not have to return any obtained data to any other component/service.
    //       all the data upon arrival are sequestered into its respective dictionary and indexedDB
    //
    //       Also, this will get the group of activities such as lock/unlock, star/track/wink, or like/friend/block/etc.
    //------------------------------------------------------------------------------------------------------------------------------
    //  Deprecated! Not in use:
    // ----------------------------------------------------------------
    // debugger;
    let myActivities : CommunicationActivity[] = [];
    let sitUserId = sitId;
    switch (action.toLocaleLowerCase()) {
      case 'ilock': case 'iunlock': case 'itala': case 'lock': case 'unlock': case 'tala':
        this.getMyActivities(sitId, 'unlock').subscribe(data => { // note: important to strip `i` 
          myActivities = data;

        });
        break;
      case 'ilike': case 'ifriend': case 'like': case 'friend':
      case 'istar': case 'iwink': case 'star': case 'wink':
      case 'iblock': case 'iunblock': case 'block': case 'unblock':
      case 'idislike': case 'iunfriend': case 'dislike': case 'unfriend':

        this.getMyActivities(sitId, 'MemberActivity').subscribe(data => { // note: important to strip `i` 
          myActivities = data;

        });
        break;
      default:
        this.getMyActivities(sitId, 'MemberActivity').subscribe(data => { // note: important to strip `i` 
          myActivities = data;
          // debugger;
        });
        break;
    }

    EmitterSubjectService.emitMyActivityArr(myActivities);
    //-------------------------------------
    myActivities.map((e) => {
      // debugger;
      this.populateRespectiveActionArray(e);
      // debugger;
      this.createAllActionDictionariesWithLatestValueFromActionArrayDictionaries(e);
      // debugger;
      // Note: will accumulate block/unblock, friend/unfriend & like/dislike into MemberActivities:
      //       and will accumulate Star/Track/Wink into MemberStarTrackWinkActivities
      //       and Unlock into MemberLockingActivities
      this.createMemberActivityDictionariesFromAllDictionaries(e);
      // debugger;
    });
    // debugger;

    // update dictionary and indexedDb:
    // --------------------------------
    if (sitUserId === this.loginSuccess.signedInUserId) {
      // debugger;
      switch (action.toLowerCase()) {

        // debugger;
        //this.dbDexieToDictionaryService
        //  .saveDictionaryToDbDexie(
        //    this.dictionaryService.myMemberStarTrackWinkActivityDictionary,
        //    "myMemberStarTrackWinkActivity",
        //    this.loginSuccess.signedInUserId);
        //break;
        case 'tala':
        case 'lock':
        case 'unlock':
        case 'LockingActivity':
        case 'MemberLockingActivity':
          // debugger;
          this.dbDexieToDictionaryService
            .saveDictionaryToDbDexie(
              this.dictionaryService.myMemberLockingActivityDictionary,
              "myMemberLockingActivity",
              this.loginSuccess.signedInUserId);
          break;
        case 'like':
        case 'dislike':
        case 'friend':
        case 'unfriend':
        case 'block':
        case 'unblock':
        case 'star':
        case 'Wink':
        case 'CommunicationActivity':
        case 'MemberActivity':
          // debugger;
          this.dbDexieToDictionaryService
            .saveDictionaryToDbDexie(
              this.dictionaryService.myMemberActivityDictionary,
              "myMemberActivity",
              this.loginSuccess.signedInUserId);
          break;
      }
    }
    else {
      debugger;
      switch (action) {

        //case 'MemberStarWinkActivity':
        //  debugger;
        //  this.dbDexieToDictionaryService
        //    .saveDictionaryToDbDexie(
        //      this.dictionaryService.yourMemberStarTrackWinkActivityDictionary,
        //      "yourMemberStarTrackWinkActivity",
        //      sitUserId);

        //  break;
        case 'tala':
        case 'lock':
        case 'unlock':
        case 'LockingActivity':
        case 'MemberLockingActivity':
          // debugger;
          this.dbDexieToDictionaryService
            .saveDictionaryToDbDexie(
              this.dictionaryService.yourMemberLockingActivityDictionary,
              "yourMemberLockingActivity",
              sitUserId);

          break;
        case 'like':
        case 'dislike':
        case 'friend':
        case 'unfriend':
        case 'block':
        case 'unblock':
        case 'star':
        case 'Wink':
        case 'CommunicationActivity':
        case 'MemberActivity':
          // debugger;
          this.dbDexieToDictionaryService
            .saveDictionaryToDbDexie(
              this.dictionaryService.yourMemberActivityDictionary,
              "yourMemberActivity",
              sitUserId);
          break;
      }
    }
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * End of Core-Helper-Methods:
   * ------------------------------------------------------------------------------------------------
   */

  /*
   * ------------------------------------------------------------------------------------------------
   * Begin of Behavior-based-processing-methods:
   * ------------------------------------------------------------------------------------------------
   * ------------------------------------------------------------------------------------------------
   */
  processBlockingBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myBlockArray = this.addActivityToUserActionArray( activityArrayEnum.myBlockArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourBlockArray = this.addActivityToUserActionArray( activityArrayEnum.yourBlockArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }
  // ------------------------------------------------------------------------------------------------
  processUnblockingBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myUnblockArray = this.addActivityToUserActionArray( activityArrayEnum.myUnblockArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourUnblockArray = this.addActivityToUserActionArray( activityArrayEnum.yourUnblockArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }
  // ------------------------------------------------------------------------------------------------
  processBuddyingBehavior ( mact : CommunicationActivity ) : void
  {
    let didIFriend = false;
    let didYouFriend = false;
    let serverSentMemberActivity : any;
    let buddyActivity = new CommunicationActivity();
    buddyActivity.senderSitUserId = this.loginSuccess.signedInUserId;
    // buddyActivity.receiverCryptUserKey = this.loginSuccess.cryptUserKey;
    buddyActivity.action = 'buddy';

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) && mact.action.toLowerCase().indexOf( 'friend' ) !== -1 )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess ) )
      {
        if ( mact.senderSitUserId > 0 && mact.senderSitUserId === this.loginSuccess.signedInUserId )
        {
          didIFriend = true;

         this.getYourActivities( mact.receiverSitUserId, 'freind' ).subscribe( data =>
          {
           if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( data ) && data.length > 0 ) // TODO: check if data==[] or model?
           {
             data.map((f : any) =>
             {
               if ( f.senderSitUserId === mact.receiverSitUserId )
               {
                 buddyActivity.receiverSitUserId = f.senderSitUserId;
                 // buddyActivity.doeeCryptUserKey = f.doerCryptUserKey;
                 didYouFriend = true;
               }
             });
           }
          });

        }
        else if ( mact.receiverSitUserId > 0 && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          didYouFriend = true;

          this.dictionaryService.myFriendDictionary.size > 0 && this.dictionaryService.myFriendDictionary.values().map((e : any) =>
          {
            if ( e.receiverSitUserId == mact.senderSitUserId )
            {
              // Note: since buddy.doer==signedInSitUserId, e.doer's value is put on buddy.doee which is also the key for buddydictionary.
              buddyActivity.receiverSitUserId = e.receiverSitUserId;
              // buddyActivity.doeeCryptUserKey = e.doeeCryptUserKey;
              didIFriend = true;
            }
          });
        }
      }
    }

    if ( didIFriend && didYouFriend )
    {
      this.dictionaryService.buddyDictionary.set( buddyActivity.receiverSitUserId, buddyActivity );
    }
  }
  // ------------------------------------------------------------------------------------------------

  processFriendingBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myFriendArray = this.addActivityToUserActionArray( activityArrayEnum.myFriendArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
         // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourFriendArray = this.addActivityToUserActionArray( activityArrayEnum.yourFriendArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }
  // ------------------------------------------------------------------------------------------------
  processUnfriendingBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myUnfriendArray = this.addActivityToUserActionArray( activityArrayEnum.myUnfriendArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourUnfriendArray = this.addActivityToUserActionArray( activityArrayEnum.yourUnfriendArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }
  // ------------------------------------------------------------------------------------------------
  processLikingBehavior ( mact : CommunicationActivity ) : void
  {
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myLikeArray = this.addActivityToUserActionArray( activityArrayEnum.myLikeArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourLikeArray = this.addActivityToUserActionArray( activityArrayEnum.yourLikeArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }
  // ------------------------------------------------------------------------------------------------
  processDislikingBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myDislikeArray = this.addActivityToUserActionArray( activityArrayEnum.myDislikeArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourDislikeArray = this.addActivityToUserActionArray( activityArrayEnum.yourDislikeArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }

  // ------------------------------------------------------------------------------------------------
  processLockingBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myLockArray = this.addActivityToUserActionArray( activityArrayEnum.myLockArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourLockArray = this.addActivityToUserActionArray( activityArrayEnum.yourLockArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }
  // ------------------------------------------------------------------------------------------------
  processUnlockingBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myUnlockArray = this.addActivityToUserActionArray( activityArrayEnum.myUnlockArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourUnlockArray = this.addActivityToUserActionArray( activityArrayEnum.yourUnlockArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }

  // ------------------------------------------------------------------------------------------------
  processStarringBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myStarArray = this.addActivityToUserActionArray( activityArrayEnum.myStarArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourStarArray = this.addActivityToUserActionArray( activityArrayEnum.yourStarArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }

  // ------------------------------------------------------------------------------------------------
  processTrackingBehavior(mact: CommunicationActivity): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact)) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myTrackArray = this.addActivityToUserActionArray( activityArrayEnum.myTrackArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourTrackArray = this.addActivityToUserActionArray( activityArrayEnum.yourTrackArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }
  }

  // ------------------------------------------------------------------------------------------------
  processWinkingBehavior ( mact : CommunicationActivity ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mact ) )
    {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        && mact.senderSitUserId > 0
        && mact.senderSitUserId === this.loginSuccess.signedInUserId )
      {
        this.myWinkArray = this.addActivityToUserActionArray( activityArrayEnum.myWinkArray, mact, mact.receiverSitUserId );
        // debugger;
      }
      else
      {
        this.sitUser = EmitterSubjectService.getSitUserModel();
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.sitUser ) && this.sitUser.sitUserId > 0
          && this.sitUser.sitUserId !== this.loginSuccess.signedInUserId
          && mact.receiverSitUserId === this.loginSuccess.signedInUserId )
        {
          this.yourWinkArray = this.addActivityToUserActionArray( activityArrayEnum.yourWinkArray, mact, mact.senderSitUserId );
          // debugger;
        }
      }
    }

    // ==================================================
    // TODO: remove after testing
    // --------------------------------------------------
    // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact)) {
    //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //  this.sitUser = EmitterSubjectService.getSitUserModel();

    //  /*
    //   * --------------------------------------------------------------------
    //   * myWinkArray: for Wink
    //   * --------------------------------------------------------------------
    //   */
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
    //    /*
    //     * debugger;
    //     * if I am the doer in this tMAct
    //     */
    //    if (mact.senderSitUserId === this.loginSuccess.signedInUserId && mact.receiverSitUserId === this.sitUser.sitUserId) {
    //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.myWinkArray)) {
    //        /*
    //         * make sure that the dictionary exists
    //         * debugger;
    //         */
    //        this.myWinkArray = this.addActivityToUserActionDictionary(this.myWinkArray, mact, mact.receiverSitUserId);
    //      }
    //    } else if (mact.senderSitUserId === this.sitUser.sitUserId && mact.receiverSitUserId === this.loginSuccess.signedInUserId) {
    //      /*
    //       * --------------------------------------------------------------------
    //       * yourWinkArray: for Wink
    //       * --------------------------------------------------------------------
    //       * if I am the doee in this tMAct
    //       */
    //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.yourWinkArray)) {
    //        // make sure that the dictionary exists
    //        this.yourWinkArray = this.addActivityToUserActionDictionary(this.yourWinkArray, mact, mact.senderSitUserId);
    //      }
    //    }
    //  } else {
    //    /*
    //     * debugger;
    //     * --------------------------------------------------------------------
    //     * myWinkArray: for Wink
    //     * --------------------------------------------------------------------
    //     */
    //    if (mact.senderSitUserId === this.loginSuccess.signedInUserId) {
    //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.myWinkArray)) {
    //        /*
    //         * make sure that the dictionary exists
    //         * debugger;
    //         */
    //        this.myWinkArray = this.addActivityToUserActionDictionary(this.myWinkArray, mact, mact.receiverSitUserId);
    //      }
    //    } else if (mact.senderSitUserId > 0 && mact.receiverSitUserId === this.loginSuccess.signedInUserId) {
    //      /*
    //       * --------------------------------------------------------------------
    //       * yourTrackArray: for Track
    //       * --------------------------------------------------------------------
    //       */
    //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.yourWinkArray)) {
    //        // make sure that the dictionary exists
    //        this.yourWinkArray = this.addActivityToUserActionDictionary(this.yourWinkArray, mact, mact.senderSitUserId);
    //      }
    //    }
    //  }
    // }
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * End of Behavior-based-processing-methods:
   * ------------------------------------------------------------------------------------------------
   */

  // ---------------------------------------------------------------
  // Note:  This method has a side-effect which is it also finds a
  //        matching activity of the sitUserId provided.
  //        Essentially it does two things:
  //        1. it gets the array of values of a dictionary
  //        2. it also finds an entry into the array for the sitUserId
  // ---------------------------------------------------------------
  getDictionaryActivities ( dictionaryName : any) : any {
    var outArr : any[] = [];   
   
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    try {
      switch (dictionaryName) {
        case 'iBlocked':
        case 'myBlockDictionary':
          outArr = this.dictionaryService?.myBlockDictionary?.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.myBlockDictionary) {
          //  act = this.dictionaryService.myBlockDictionary[ c ];
          //  outArr.push(act);
          //    if (act.receiverSitUserId === sitUserId && act.senderSitUserId === this.loginSuccess.signedInUserId) {
          //      result.isFound = true;
          //      debugger;
          //    }
          //}
          break;
        case 'iFriended':
        case 'myFriendDictionary':
          outArr = this.dictionaryService.myFriendDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.myFriendDictionary) {
          //  act = this.dictionaryService.myFriendDictionary[ c ];
          //  outArr.push(act);            
          //}
          break;
        case 'iLiked':
        case 'myLikeDictionary':
          debugger;
          //this.myLikeDictionary = this.dictionaryService.getMyLikeDictionary();
          outArr = this.myLikeDictionary?.values() as CommunicationActivity[];
          var tOutArr = this.dictionaryService.getMyLikeDictionaryValues();
          debugger;
          outArr = tOutArr;
          debugger;
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.outArr) && result.outArr.length > 0) {
          //  result.outArr.map(e => {
          //    if (e.receiverSitUserId === sitUserId && e.senderSitUserId === this.loginSuccess.signedInUserId) {
          //      result.isFound = true;
          //      debugger;
          //    }
          //  })
          //}
          break;
        case 'iStarred':
        case 'myStarDictionary':
          outArr = this.dictionaryService.myStarDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.myStarDictionary) {
          //  act = this.dictionaryService.myStarDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.receiverSitUserId === sitUserId && act.senderSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          //}
          break;
        case 'iUnlocked':
        case 'myUnlockDictionary':
          outArr = this.dictionaryService.myUnlockDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.myUnlockDictionary) {
          //  act = this.dictionaryService.myUnlockDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.receiverSitUserId === sitUserId && act.senderSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          //}
          break;
        case 'iWinked':
        case 'myWinkDictionary':
          outArr = this.dictionaryService.myWinkDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.myWinkDictionary) {
          //  act = this.dictionaryService.myWinkDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.receiverSitUserId === sitUserId && act.senderSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          //}
          break;
        // ------------------------------------
        case 'youBlocked':
        case 'yourBlockDictionary':
          outArr = this.dictionaryService.myLikeDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.yourBlockDictionary) {
          //  act = this.dictionaryService.yourBlockDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.senderSitUserId === sitUserId && act.receiverSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          //}
          break;
        case 'youFriended':
        case 'yourFriendDictionary':
          outArr = this.dictionaryService.yourFriendDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.yourFriendDictionary) {
          //  act = this.dictionaryService.yourFriendDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.senderSitUserId === sitUserId && act.receiverSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          //}
          break;
        case 'youLiked':
        case 'yourLikeDictionary':
          outArr = this.dictionaryService.yourLikeDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.yourLikeDictionary) {
          //  act = this.dictionaryService.yourLikeDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.senderSitUserId === sitUserId && act.receiverSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          // }
          break;
        case 'youStarred':
        case 'yourStarDictionary':
          outArr = this.dictionaryService.yourStarDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.yourStarDictionary) {
          //  act = this.dictionaryService.yourStarDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.senderSitUserId === sitUserId && act.receiverSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          //}
          break;
        case 'youUnlocked':
        case 'yourUnlockDictionary':
          outArr = this.dictionaryService.yourUnlockDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.yourUnlockDictionary) {
          //  act = this.dictionaryService.yourUnlockDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.senderSitUserId === sitUserId && act.receiverSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          //}
          break;
        case 'youWinked':
        case 'yourWinkDictionary':
          outArr = this.dictionaryService.yourWinkDictionary.values() as CommunicationActivity[];
          //for (var c in this.dictionaryService.yourWinkDictionary) {
          //  act = this.dictionaryService.yourWinkDictionary[ c ];
          //  result.outArr.push(act);

          //  if (act.senderSitUserId === sitUserId && act.receiverSitUserId === this.loginSuccess.signedInUserId) {
          //    result.isFound = true;
          //    debugger;
          //  }
          //}
          break;
      }
      // debugger;
      return outArr;
    }
    catch (e) {
      console.log('error in try-catch block: ' + e);
    }
  }
  // ---------------------------------------------------------------
  getMyLikeDictionaryActivities(): any {
    
      var myLikingActivities: CommunicationActivity[] = [];
      myLikingActivities = this.getDictionaryActivities('mylikeDictionary');
      
      return myLikingActivities;
    
  }
  // ---------------------------------------------------------------
  getMyUnlockingDictionaryActivities(): any {
    
      var myUnlockingActivities: CommunicationActivity[] = [];

      myUnlockingActivities = this.getDictionaryActivities('myUnlockDictionary');
      

      return myUnlockingActivities;
    
  }

  /*
   * =========================================================================================
   * All the verifier methods:
   * =========================================================================================
   */
  isActivityFoundInArray ( arr : CommunicationActivity[], activity : CommunicationActivity ) : number
  {
    let isFound = false;
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arr ) && arr.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( activity ) )
    {
      let i = 0;
      arr.map( ( e ) =>
      {
        if ( ( e as CommunicationActivity ).id === activity.id )
        {
          isFound = true;
          index = i;
        }
        i++;
      } );
    }
    return index;
  }

  // ---------------------------------------------------------------
  isActivityFoundInArrayByName ( arrName : activityArrayEnum, activity : CommunicationActivity ) : number
  {
    let isFound = false;
    let index = -1;
    let actionArr = [];
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arrName ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( activity ) )
    {
      actionArr = this.callActivityArray( arrName ) as [];

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( actionArr ) && actionArr.length > 0 )
      {
        let i = 0;
        actionArr.map( ( e ) =>
        {
          if ( ( e as CommunicationActivity ).id === activity.id )
          {
            isFound = true;
            index = i;
          }
          i++;
        } );
      }
    }
    return index;
  }

  // ---------------------------------------------------------------
  isBlockedByMyDictionary(sitUserId: number): boolean {
    if (sitUserId > 0) {
      this.isBlockedByDictionaryData = false;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      const bActs = this.getDictionaryActivities('myBlockDictionary');
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bActs)) {
        const isBlocked = (this.isBlockedByDictionaryData = bActs.isFound);
      }      
    }
    // debugger;
    return this.isBlockedByDictionaryData;
  }
  // ---------------------------------------------------------------
  isBlockedByYourDictionary(sitUserId: number): boolean {
    if (sitUserId > 0) {
      this.isBlockedByDictionaryData = false;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      const bActs = this.getDictionaryActivities('yourBlockDictionary');

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( bActs )) {
        const isBlocked = (this.isBlockedByDictionaryData = bActs.isFound);
      }
    }
    // debugger;
    return this.isBlockedByDictionaryData;
  }
  /*
   * ---------------------------------------------------------------
   * Note: searches by CommunicationActivity.senderSitUserId
   * ---------------------------------------------------------------
   */
  isDoeeSitUserActivityFoundInArr(mActs: CommunicationActivity[], sitId: number): CommunicationActivity {
    let foundMAct : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActs) && mActs.length > 0) {
      mActs.map((e) => {
        if (e.receiverSitUserId === sitId) {
          foundMAct = e;
        }
      });
    }
    return foundMAct;
  }

  /*
   * ---------------------------------------------------------------
   * Note: searches by CommunicationActivity.senderSitUserId
   * ---------------------------------------------------------------
   */
  isDoerSitUserActivityFoundInArr(mActs: CommunicationActivity[], sitId: number): CommunicationActivity {
    let foundMAct : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActs) && mActs.length > 0) {
      mActs.map((e) => {
        if (e.senderSitUserId === sitId) {
          foundMAct = e;
        }
      });
    }
    return foundMAct;
  }
  /*
   * ---------------------------------------------------------------
   * Note: searches by CommunicationActivity.receiverSitUserId
   * ---------------------------------------------------------------
   */
  isDoeeSitUserActivityFoundInDictionary ( mActsDictionaryName : staticDictionaryEnum, sitId: number, action: string): CommunicationActivity {
    let foundMAct : any;
    let mActs = new Dictionary<number, CommunicationActivity>();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mActsDictionaryName ) )
    {
      mActs = this.callStaticDictionary( mActsDictionaryName );
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActs)) {
      for (var c in mActs) {
        var e = mActs[ c ];
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.receiverSitUserId) && e.receiverSitUserId === sitId && e.action.toLowerCase().indexOf(action) !== -1) {
            foundMAct = e;
          } else if (
            !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) &&
            !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value.receiverSitUserId) &&
            e.value.receiverSitUserId === sitId &&
            e.value.MemberAction.toLowerCase().indexOf(action) !== -1
          ) {
            foundMAct = e.value;
          }
        }
      }
    }
    return foundMAct;
  }
  /*
   * ---------------------------------------------------------------
   * Note: searches by CommunicationActivity.senderSitUserId
   * ---------------------------------------------------------------
   */
  isDoerSitUserActivityFoundInDictionary(mActsDictionaryName: staticDictionaryEnum, sitId: number, action: string): CommunicationActivity {
    let foundMAct: any;
    let mActs = new Dictionary<number, CommunicationActivity>();

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mActsDictionaryName ) )
    {
      mActs = this.callStaticDictionary( mActsDictionaryName );
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActs)) {
      for (var c in mActs) {
        var e = mActs[ c ];
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.senderSitUserId) && e.senderSitUserId === sitId && e.action.toLowerCase().indexOf(action) !== -1) {
            foundMAct = e;
          } else if (
            !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) &&
            !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value.senderSitUserId) &&
            e.value.senderSitUserId === sitId &&
            e.value.MemberAction.toLowerCase().indexOf(action) !== -1
          ) {
            foundMAct = e.value;
          }
        }
      }
    }
    return foundMAct;
  }


  // ---------------------------------------------------------------
  isFoundInDictionary ( dic : any, item : any ) : boolean
  {
    let isFound = false;

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dic ) && dic.size() > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( item ) )
    {
      for (var c in dic) {
        var itm = dic[ c ];
        if (itm.id === item.id) {
          isFound = true;
          // debugger;
          break;
        }
      } 
    }
    return isFound;
  }
  // ---------------------------------------------------------------
  isFriendByMyDictionaryPromise ( sitUserId : number ) : Promise<boolean>
  {
    this.isFriendByDictionaryData = false;
    // debugger;
    return new Promise( ( resolve, reject ) =>
    {
      if ( sitUserId > 0 )
      {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        var bActs = this.getDictionaryActivities('myFriendDictionary');
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bActs)) 
        {
          this.isFriendByDictionaryData = bActs.isFound;
          resolve(this.isFriendByDictionaryData);
        }          
      }
      else
      {
        resolve( this.isFriendByDictionaryData );
      }
    } );
  }
  // ---------------------------------------------------------------
  isFriendByMyDictionary ( sitUserId : number ) : boolean
  {
    if ( sitUserId > 0 )
    {
      this.isFriendByDictionaryData = false;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      for (var c in this.dictionaryService.myFriendDictionary) {
        var fAct = this.dictionaryService.myFriendDictionary[ c ];
        if (fAct.receiverSitUserId === sitUserId && fAct.senderSitUserId === this.loginSuccess.signedInUserId) {
          this.isLikedByDictionaryData = true;
          // debugger;
        }
      } 
    }
    return this.isFriendByDictionaryData;
  }
  // ---------------------------------------------------------------
  isFriendByYourDictionary ( sitUserId : number ) : boolean
  {
    if ( sitUserId > 0 )
    {
      this.isFriendByDictionaryData = false;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      for (var c in this.dictionaryService.yourFriendDictionary) {
        var fAct = this.dictionaryService.yourFriendDictionary[ c ];
        if (fAct.receiverSitUserId === sitUserId && fAct.senderSitUserId === this.loginSuccess.signedInUserId) {
          this.isLikedByDictionaryData = true;
          // debugger;
        }
      }     
    }
    return this.isFriendByDictionaryData;
  }
  // ---------------------------------------------------------------
  isLikedByMyDictionary(sitUserId: number): boolean {
    if (sitUserId > 0) {
      this.isLikedByDictionaryData = false;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      // debugger;
      for (var c in this.dictionaryService.myLikeDictionary) {
        var lAct = this.dictionaryService.myLikeDictionary[ c ];
        if (lAct.receiverSitUserId === sitUserId && lAct.senderSitUserId === this.loginSuccess.signedInUserId) {
          this.isLikedByDictionaryData = true;
          // debugger;
        }
      }
    }
    return this.isLikedByDictionaryData;
  }
  // ---------------------------------------------------------------
  isLikedByYourDictionary(sitUserId: number): boolean {
    if (sitUserId > 0) {
      this.isLikedByDictionaryData = false;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      // debugger;
      for (var c in this.dictionaryService.yourLikeDictionary) {
        var lAct = this.dictionaryService.yourLikeDictionary[ c ];
        if (lAct.receiverSitUserId === sitUserId && lAct.senderSitUserId === this.loginSuccess.signedInUserId) {
          this.isLikedByDictionaryData = true;
          // debugger;
        }
      }
    }
    return this.isLikedByDictionaryData;
  }
  // ---------------------------------------------------------------
  isStarredByMyDictionary (sitUserId : any) : boolean
  {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty (this.loginSuccess) &&  this.loginSuccess.signedInUserId > 0 && sitUserId > 0)
    {
      this.isStarredByDictionaryData = false;
      const bActs = this.getDictionaryActivities('myStarDictionary' );

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bActs)) {
        this.isStarredByDictionaryData = bActs.isFound;
      } 
    }
    return this.isStarredByDictionaryData;
  }

  // ---------------------------------------------------------------
  isStarredByYourDictionary ( sitUserId : number ) : boolean
  {
    if ( sitUserId > 0 )
    {
      this.isStarredByDictionaryData = false;
      const bActs = this.getDictionaryActivities('yourStarDictionary');

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bActs)) {
        this.isStarredByDictionaryData = bActs.isFound;
      } 
    }
    return this.isStarredByDictionaryData;
  }
  // ---------------------------------------------------------------
  isWinkedByMyDictionary (sitUserId: number)
  {
    if ( sitUserId > 0 )
    {
      this.isWinkedByDictionaryData = false;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      const bActs = this.getDictionaryActivities('myWinkDictionary');

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bActs)) {
        this.isWinkedByDictionaryData = bActs.isFound;
      } 
    }
    return this.isWinkedByDictionaryData;
  }
  // ---------------------------------------------------------------
  isWinkedByYourDictionary ( sitUserId : number ) : boolean
  {
    if ( sitUserId > 0 )
    {
      this.isWinkedByDictionaryData = false;
      const bActs = this.getDictionaryActivities('yourWinkDictionary');

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(bActs)) {
        this.isWinkedByDictionaryData = bActs.isFound;
      } 
    }
    return this.isWinkedByDictionaryData;
  }
  /*
   * ---------------------------------------------------------------
   * Note: searches by MemberActivityId or id
   * ---------------------------------------------------------------
   */
  isMemberActivityFoundInArr(mActs: CommunicationActivity[], item: CommunicationActivity): CommunicationActivity {
    let foundMAct : any;
    // debugger;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActs) && mActs.length > 0) {
      mActs.map((e : any) => {
        // debugger;
        if (e?.memberActivity === item?.memberActivity) {
          foundMAct = e;
        }
      });
    }
    return foundMAct;
  }
  // ---------------------------------------------------------------
  isMemberActionFoundInArr (mActs : CommunicationActivity[], item : any) : CommunicationActivity {
    let foundMAct : any;
    // debugger;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActs) && mActs.length > 0) {
      mActs.map((e : any) => {
        // debugger;
        if (e.action.toLowerCase().indexOf(item.toLowerCase() !== -1)) {
          foundMAct = e;
        }
      });
    }
    return foundMAct;
  }
  // ---------------------------------------------------------------
  isMyDictionaryMemberActivityEmptyForSitUser(sitId: number): boolean {
    let isEmpty = true;

    if (sitId > 0) {
      for (var c in this.dictionaryService.myMemberActivityDictionary) {
        var mAct = this.dictionaryService.myMemberActivityDictionary[ c ];
        if (mAct.receiverSitUserId === sitId || mAct.senderSitUserId === sitId) {
          isEmpty = true;
          // debugger;
        }
      }
    }
    return isEmpty;
  }

  /*
   * ---------------------------------------------------------------
   * Note: searches by CommunicationActivity.senderSitUserId and receiverSitUserId and action
   * ---------------------------------------------------------------
   */
  isSitUserActivityFoundInDictionary (mActsD : Dictionary<string, CommunicationActivity>, mact: CommunicationActivity): CommunicationActivity {
    let foundMAct : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActsD)) {
      for (var c in mActsD) {
        var e = mActsD[ c ];
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact)) {
          if (
            e.senderSitUserId === mact.senderSitUserId &&
            e.receiverSitUserId === mact.receiverSitUserId &&
            (e.action?.toLowerCase().indexOf(mact.action.toLowerCase()) !== -1 || e?.memberActivity?.toLowerCase().indexOf(mact.memberActivity?.toLowerCase()) !== -1)
          ) {
            foundMAct = e;
          }
        } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact)) {
          if (
            e.senderSitUserId === mact.senderSitUserId &&
            // && e.receiverSitUserId === mact.receiverSitUserId
            (e.action?.toLowerCase().indexOf(mact.action?.toLowerCase()) !== -1 || e?.memberActivity?.toLowerCase().indexOf(mact.memberActivity?.toLowerCase()) !== -1)
          ) {
            foundMAct = e;
          }
        }
      }
    }
    return foundMAct;
  }

  /*
   * ---------------------------------------------------------------
   * Note: searches by CommunicationActivity.senderSitUserId and receiverSitUserId and action
   * ---------------------------------------------------------------
   */
  isSitUserActivityFoundInArray(mActs: CommunicationActivity[], mact: CommunicationActivity): CommunicationActivity {
    let foundMAct : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActs) && mActs.length > 0) {
      mActs.map((e : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
          if (
            e.senderSitUserId === mact.senderSitUserId &&
            e.receiverSitUserId === mact.receiverSitUserId &&
            (e.action?.toLowerCase().indexOf(mact.action?.toLowerCase()) !== -1 || e?.memberActivity?.toLowerCase().indexOf(mact.memberActivity?.toLowerCase()) !== -1)
          ) {
            foundMAct = e;
          }
        
          else if (
            e.senderSitUserId === mact.senderSitUserId &&
            // && e.receiverSitUserId === mact.receiverSitUserId
            (e.action?.toLowerCase().indexOf(mact.action?.toLowerCase()) !== -1 || e?.memberActivity?.toLowerCase().indexOf(mact.memberActivity?.toLowerCase()) !== -1)
          ) {
            foundMAct = e;
          }
        }
      });
    }
    return foundMAct;
  }
  /*
   * ---------------------------------------------------------------
   * Note: Tested, works! 20202/November/04
   * ---------------------------------------------------------------
   */
  isUnlockedExpired(mact: CommunicationActivity): boolean {
    let isExpired = true;
    let expDateTicks: any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mact)) {
      // debugger;
      const todaysTicks = new Date().getTime();
      const expDate = DateStringServiceStatic.convertNetTicsToJsDate(mact.expiryDate);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(expDate)) {
        expDateTicks = new Date(DateStringServiceStatic.addDate(expDate, 'day', 7)).getTime();
      }
      // debugger;
      isExpired = !(expDateTicks - todaysTicks >= 0);

      /*
       * let jsExpDate = DateStringServiceStatic.convertNetTicsToJsDate(mact.expiryDate);
       * let jsExpDateTicks = DateStringServiceStatic.getTicks(jsExpDate);
       * debugger;
       */
    }
    // debugger;
    return isExpired;
  }
  // ---------------------------------------------------------------
  isUnlockedByMyDictionaryPromise(sitUserId: number): Promise<boolean> {
    // debugger;
    this.isUnlockedByDictionaryData = false;
    return new Promise<boolean>( ( resolve, reject ) =>
    {
      if (sitUserId > 0) {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dictionaryService.myUnlockDictionary)) {
          // debugger;
          for (var c in this.dictionaryService.myUnlockDictionary) {
            var e = this.dictionaryService.myUnlockDictionary[ c ];
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
              {
                if (e.receiverSitUserId === sitUserId && e.senderSitUserId === this.loginSuccess.signedInUserId) {
                  // debugger;
                  this.isUnlocked = (this.isUnlockedByDictionaryData = !this.isUnlockedExpired(e)); // important!!

                  EmitterSubjectService.setIsUnlocked(this.isUnlockedByDictionaryData);
                  // debugger;
                  resolve(this.isUnlockedByDictionaryData);
                }
              };
            }
          }
        }
        else {
          // debugger;
          resolve(this.isUnlockedByDictionaryData);
        }
      }
    });
  }

  // ---------------------------------------------------------------
  isUnlockedByMyDictionary ( sitUserId : number ) : boolean
  {
    if ( sitUserId > 0 )
    {
      this.isUnlockedByDictionaryData = false;
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dictionaryService.myUnlockDictionary)) {
        // debugger;
        for (var c in this.dictionaryService.myUnlockDictionary) {
          var e = this.dictionaryService.myUnlockDictionary[ c ];
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
            {
              if (e.receiverSitUserId === sitUserId && e.senderSitUserId === this.loginSuccess.signedInUserId) {
                // debugger;
                this.isUnlocked = (this.isUnlockedByDictionaryData = !this.isUnlockedExpired(e)); // important!!

                EmitterSubjectService.setIsUnlocked(this.isUnlockedByDictionaryData);
                // debugger;                
              }
            };
          }
        }
      }
    }
    // debugger;
    return this.isUnlockedByDictionaryData;
  }
  // ---------------------------------------------------------------
  isUnlockedByYourDictionary(sitUserId: number): boolean {
    if (sitUserId > 0)
    {
      this.isUnlockedByDictionaryData = false;
      // debugger;
      const today = new Date();
      const lActs = this.getDictionaryActivities('yourUnlockDictionary');

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( lActs )) {
        // debugger;
        const isUnlocked = (this.isUnlockedByDictionaryData = lActs.isFound);
      }
    }
    // debugger;
    return this.isUnlockedByDictionaryData;
  }
  // ---------------------------------------------------------------
  isYourDictionaryMemberActivityEmptyForSitUser (sitId : number) : boolean {
    let isEmpty = true;

    if (sitId > 0) {
      for (var c in this.dictionaryService.yourMemberActivityDictionary) {
        var e = this.dictionaryService.yourMemberActivityDictionary[ c ];
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
          {
            if (e.receiverSitUserId === sitId || e.senderSitUserId === sitId) {
              isEmpty = false;
            }
          };
        }
      }
      return isEmpty;
    }
  }
  // ---------------------------------------------------------------
  //  Note: Independent-Image-Setting-System (IISS): Begin
  //
  //  This system will display any image centered and fully covered
  // ---------------------------------------------------------------
  setProfileTileImage (pTile : ProfileTile, elem: any) : any {
     // debugger;    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)     
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.primaryImage)) {
      // debugger;
      let imageData : any;

      // raw-image-data-case
      if (pTile.primaryImage.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
        // debugger;
        imageData = JsRegExpServiceStatic.normalizeImageDataWithUrl(pTile.primaryImage);
      }
      // file-name-case
      else if (pTile.primaryImage.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
        // debugger;
        imageData = JsRegExpServiceStatic.normalizeImageFileNameWithUrl(pTile.primaryImage);
      }

      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {        
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
          //bdebugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.renderer)) {
            // debugger;
            this.renderer.setStyle(elem, 'background-image', imageData);
            this.renderer.setStyle(elem, 'background-position', 'center center');
            this.renderer.setStyle(elem, 'background-repeat', 'no-repeat');
            this.renderer.setStyle(elem, 'background-size', 'cover');
            // this.renderer.setStyle(elem, 'animation', 'fadeInOut 3s');
          }
          else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem.nativeElement)) {
            // debugger;
            elem.nativeElement.style.background = imageData;
          }
          else {
            // debugger;
            elem.nativeElement.style.backgroundImage = imageData;
          }
        }
      }
    }
  }
  /*
   * =========================================================================================
   * All the unused methods:
   * =========================================================================================
   * =======================================================================
   * Begin of CommunicationActivity-Key-Methods:
   * =======================================================================
   * ---------------------------------------------------------------
   */
  createMemberActivityKey(mAct: CommunicationActivity): string {
    let key = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.action)) {
        key = mAct.action + '.' + mAct.id;
      } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mAct.memberActivity)) {
        key = mAct.memberActivity + '.' + mAct.id;
      } 
    }
    return key;
  }
  /*
   * ---------------------------------------------------------------
   * Note: this method returns an array of strings of length 2
   *       where the 'first index'===action and 'second index'===id
   * ---------------------------------------------------------------
   * This method is not used
   * ---------------------------------------------------------------
   */
  parseMemberActivityKey(inKey: string): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inKey) && inKey.indexOf('.') !== -1) {
      const parts = inKey.split('.');

      return parts;
    }
    return null;
  }
  /*
   * =======================================================================
   * End of CommunicationActivity-Key-Methods:
   * =======================================================================
   */
}

/*
 * =========================================================================================
 * All the getter methods:
 * =========================================================================================
 */
  //getIsBlockedByYourServerData(sitId: number): boolean {
  //  if (sitId > 0 && this.dictionaryService.yourBlockDictionary.size() > 0) {
  //    for (const e of this.dictionaryService.yourBlockDictionary.values()) {
  //      if (e.senderSitUserId === sitId && e.receiverSitUserId === this.loginSuccess.signedInUserId && e.isBlocked === true) {
  //        // debugger;
  //        this.isBlockedByServerData = true;
  //        break;
  //      }
  //    }
  //    return this.isBlockedByServerData;
  //  }
  //  return false;
  //}

  // -------------------------------------------------------------------------------
  //getIsFriendByYourServerData(sitId: number): boolean {
  //  if (sitId > 0 && this.dictionaryService.yourMemberActivityDictionary.size() > 0) {
  //    for (const e of this.dictionaryService.yourMemberActivityDictionary.values()) {
  //      if (e.senderSitUserId === sitId && e.receiverSitUserId === this.loginSuccess.signedInUserId && e.isFriend === true) {
  //        // debugger;
  //        this.isFriendByServerData = true;
  //        break;
  //      }
  //    }
  //    return this.isFriendByServerData;
  //  }
  //  return false;
  //}

  // -------------------------------------------------------------------------------
  //getIsLikedByYourServerData(sitId: number): boolean {
  //  if (sitId > 0 && this.dictionaryService.yourMemberActivityDictionary.size() > 0) {
  //    for (const e of this.dictionaryService.yourMemberActivityDictionary.values()) {
  //      if (e.senderSitUserId === sitId && e.receiverSitUserId === this.loginSuccess.signedInUserId && e.isLiked === true) {
  //        // debugger;
  //        this.isLikedByServerData = true;
  //        break;
  //      }
  //    }
  //    return this.isLikedByServerData;
  //  }
  //  return false;
  //}
/*
 * -------------------------------------------------------------------------------
 * Note: these should be called at the listener of 'dictionariesUpdatedEmitter'
 * -------------------------------------------------------------------------------
 */
  //getIsUnlockedByYourServerData(sitId: number): boolean {
  //  if (sitId > 0 && this.dictionaryService.yourUnlockDictionary.size() > 0) {
  //    // debugger;
  //    for (const e of this.dictionaryService.yourUnlockDictionary.values()) {
  //      if (e.isUnlocked === true || (e.senderSitUserId === sitId && e.receiverSitUserId === this.loginSuccess.signedInUserId && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.expiryDate) && !this.isUnlockedExpired(e))) {
  //        // debugger;
  //        this.isUnlockedByServerData = true;
  //        break;
  //      }
  //    }
  //    return this.isUnlockedByServerData;
  //  }
  //  return false;
  //}
/*
 * ---------------------------------------------------------------
 * Note: returns an array or null
 * ---------------------------------------------------------------
 */
  //getSitUserBlockDictionaryActivities(sitUserId: number): any {
  //  if (sitUserId > 0) {
  //    const sitUserBlockingActivities: CommunicationActivity[] = [];

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    const blockedValues = this.dictionaryService.yourBlockDictionary.size() > 0 ? this.dictionaryService.yourBlockDictionary.values() : [];

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(blockedValues)) {
  //      blockedValues.map((e : any) => {
  //        if (e.senderSitUserId === sitUserId && e.receiverSitUserId === this.loginSuccess.signedInUserId) {
  //          sitUserBlockingActivities.push(e);
  //        }
  //      });
  //    }
  //    return sitUserBlockingActivities;
  //  }
  //  return null;
  //}
  // ---------------------------------------------------------------
  //getSitUserFriendDictionaryActivities(sitUserId: number): any {
  //  if (sitUserId > 0) {
  //    const sitUserFreindingActivities: CommunicationActivity[] = [];

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    const friendValues = this.dictionaryService.yourFriendDictionary.size() > 0 ? this.dictionaryService.yourFriendDictionary.values() : [];

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(friendValues)) {
  //      friendValues.map((e : any) => {
  //        if (e.senderSitUserId === sitUserId && e.receiverSitUserId === this.loginSuccess.signedInUserId) {
  //          sitUserFreindingActivities.push(e);
  //        }
  //      });
  //    }
  //    return sitUserFreindingActivities;
  //  }
  //  return null;
  //}
  // ---------------------------------------------------------------
  //getSitUserLikeDictionaryActivities(sitUserId: number): any {
  //  if (sitUserId > 0) {
  //    const sitUserLikingActivities: CommunicationActivity[] = [];

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    const likedValues = this.dictionaryService.yourLikeDictionary.size() > 0 ? this.dictionaryService.yourLikeDictionary.values() : [];

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(likedValues)) {
  //      likedValues.map((e : any) => {
  //        if (e.senderSitUserId === sitUserId && e.receiverSitUserId === this.loginSuccess.signedInUserId) {
  //          sitUserLikingActivities.push(e);
  //        }
  //      });
  //    }
  //    return sitUserLikingActivities;
  //  }
  //  return null;
  //}

  // ---------------------------------------------------------------
  //getSitUserStarDictionaryActivities ( sitUserId : number ) : any
  //{
  //  if ( sitUserId > 0 )
  //  {
  //    const sitUserStarringActivities : CommunicationActivity[] = [];

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    const starredValues = this.dictionaryService.yourStarDictionary.size() > 0 ? this.dictionaryService.yourStarDictionary.values() : [];

  //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( starredValues ) )
  //    {
  //      starredValues.map((e : any ) =>
  //      {
  //        if ( e.senderSitUserId === sitUserId && e.receiverSitUserId === this.loginSuccess.signedInUserId )
  //        {
  //          sitUserStarringActivities.push( e );
  //        }
  //      } );
  //    }
  //    return sitUserStarringActivities;
  //  }
  //  return null;
  //}
  // ---------------------------------------------------------------
  //getSitUserUnlockingDictionaryActivities(sitUserId: number): any {
  //  if (sitUserId > 0) {
  //    // debugger;
  //    const sitUserUnlockingActivities: CommunicationActivity[] = [];

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    const ulockedValues = this.dictionaryService.yourUnlockDictionary.size() > 0 ? this.dictionaryService.yourUnlockDictionary.values() : [];
  //    // debugger;

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(ulockedValues)) {
  //      ulockedValues.map((e : any) => {
  //        if (e.senderSitUserId === sitUserId && e.receiverSitUserId === this.loginSuccess.signedInUserId) {
  //          // debugger;
  //          sitUserUnlockingActivities.push(e);
  //        }
  //      });
  //    }
  //    return sitUserUnlockingActivities;
  //  }
  //  return null;
  //}

  // ---------------------------------------------------------------
  //getSitUserWinkingDictionaryActivities ( sitUserId : number ) : any
  //{
  //  if ( sitUserId > 0 )
  //  {
  //    // debugger;
  //    const sitUserWinkingActivities : CommunicationActivity[] = [];

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    const winkedValues = this.dictionaryService.myWinkDictionary.size() > 0 ?this.dictionaryService.yourWinkDictionary.values() : [];
  //    // debugger;

  //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( winkedValues ) )
  //    {
  //      winkedValues.map((e : any ) =>
  //      {
  //        if ( e.senderSitUserId === sitUserId && e.receiverSitUserId === this.loginSuccess.signedInUserId )
  //        {
  //          // debugger;
  //          sitUserWinkingActivities.push( e );
  //        }
  //      } );
  //    }
  //    return sitUserWinkingActivities;
  //  }
  //  return null;
  //}
  // ---------------------------------------------------------------
  //getMyBlockingDictionaryActivities(sitUserId: number): any {
  //  if (sitUserId > 0) {
  //    // debugger;
  //    const myBlockingActivities: CommunicationActivity[] = [];

  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //    const blockedValues = this.dictionaryService.myBlockDictionary.size() > 0 ?this.dictionaryService.myBlockDictionary.values() : [];

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(blockedValues)) {
  //      blockedValues.map((e : any) => {
  //        if (e.senderSitUserId === this.loginSuccess.signedInUserId && e.receiverSitUserId === sitUserId) {
  //          // debugger;
  //          myBlockingActivities.push(e);
  //        }
  //      });
  //    }
  //    return myBlockingActivities;
  //  }
  //  return null;
  //}
  // ---------------------------------------------------------------
  //getMyFriendDictionaryActivities(sitUserId: number): any {
  //  if (sitUserId > 0) {
  //    const myFriendingActivities: CommunicationActivity[] = [];
  //    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

  //    for (var c in this.dictionaryService.myFriendDictionary) {
  //      var fAct = this.dictionaryService.myFriendDictionary[ c ];
  //      if (fAct.senderSitUserId === sitUserId && fAct.receiverSitUserId === this.loginSuccess.signedInUserId) {
  //        myFriendingActivities.push(fAct);
  //        debugger;
  //      }
  //    }
  //    return myFriendingActivities;
  //  }
  //  return null;
  //}
  // ------------------------------------------------------------------------------------------------
  // callMyEnumDictionary ( dictionaryName : activityArrayEnum ) : Dictionary<number, CommunicationActivity[]>
  // {
  //  let returnActivityArr = new Dictionary<number, CommunicationActivity[]>();
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dictionaryName ) )
  //  {
  //    switch ( dictionaryName.toString() )
  //    {
  //      case 'myBlockArray':
  //        returnActivityArr =  this.myBlockArray;
  //        break;
  //      case 'myUnblockArray':
  //        returnActivityArr = this.myUnblockArray;
  //        break;
  //      case 'myFriendArray':
  //        returnActivityArr = this.myFriendArray;
  //        break;
  //      case 'myUnfriendArray':
  //        returnActivityArr = this.myUnfriendArray;
  //        break;
  //      case 'myLikeArray':
  //        returnActivityArr = this.myLikeArray;
  //        break;
  //      case 'myDislikeArray':
  //        returnActivityArr = this.myDislikeArray;
  //        break;
  //      case 'myLockArray':
  //        returnActivityArr = this.myLockArray;
  //        break;
  //      case 'myUnlockArray':
  //        returnActivityArr = this.myUnlockArray;
  //        break;
  //      case 'myStarArray':
  //        returnActivityArr = this.myStarArray;
  //        break;
  //      case 'myTrackArray':
  //        returnActivityArr = this.myTrackArray;
  //        break;
  //      case 'myWinkArray':
  //        returnActivityArr = this.myWinkArray;
  //        break;
  //      default:
  //        break;
  //    }
  //  }
  //  return returnActivityArr;
  // }
  // ------------------------------------------------------------------------------------------------
  // callYourEnumDictionary ( dictionaryName : activityArrayEnum ) : Dictionary<number, CommunicationActivity[]>
  // {
  //  let returnActivityArr = new Dictionary<number, CommunicationActivity[]>();
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dictionaryName ) )
  //  {
  //    switch ( dictionaryName.toString() )
  //    {
  //      case 'yourBlockArray':
  //        returnActivityArr = this.yourBlockArray;
  //        break;
  //      case 'yourUnblockArray':
  //        returnActivityArr = this.yourUnblockArray;
  //        break;
  //      case 'yourFriendArray':
  //        returnActivityArr = this.yourFriendArray;
  //        break;
  //      case 'yourUnfriendArray':
  //        returnActivityArr = this.yourUnfriendArray;
  //        break;
  //      case 'yourLikeArray':
  //        returnActivityArr = this.yourLikeArray;
  //        break;
  //      case 'yourDislikeArray':
  //        returnActivityArr = this.yourDislikeArray;
  //        break;
  //      case 'yourLockArray':
  //        returnActivityArr = this.yourLockArray;
  //        break;
  //      case 'yourUnlockArray':
  //        returnActivityArr = this.yourLockArray;
  //        break;
  //      case 'yourStarArray':
  //        returnActivityArr = this.yourStarArray;
  //        break;
  //      case 'yourTrackArray':
  //        returnActivityArr = this.yourTrackArray;
  //        break;
  //      case 'yourWinkArray':
  //        returnActivityArr = this.yourWinkArray;
  //        break;
  //      default:
  //        break;
  //    }
  //  }
  //  return returnActivityArr;
  // }

/*
   * ------------------------------------------------------------------------------------------------
   * Note: Updatae Dictionay should be computed by removing the latest activity of a tuple (<Doer, Doee>)
   *       from ActionDictionary by comparing this latest-activiy against the latest activity of the
   *       opposite-ArrayDictionay (dis/un-ActionArrayDictionay) of the same tuple (<Doer, Doee>).
   * ------------------------------------------------------------------------------------------------
   * Note: This is mostlikely not going to be used.
   * ------------------------------------------------------------------------------------------------
   */
  // updateActionDictionaryFromActionArray(destinationD: Dictionary<number, CommunicationActivity>, sourceArrD: Dictionary<number, CommunicationActivity[]>): Dictionary<number, CommunicationActivity> {
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationD)) {
  //    destinationD = new Dictionary<number, CommunicationActivity>();
  //  }

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destinationD) && destinationD.size() > 0) {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceArrD) && sourceArrD.size() > 0) {
  //      const sitUserArr = destinationD.keys();
  //      // debugger;

  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserArr) && sitUserArr.length > 0) {
  //        sitUserArr.sort((a, b) => a - b);
  //        // debugger;

  //        // loop-of-destination-ActionDictionary:
  //        sitUserArr.map((e) => {
  //          // get the latest-action of source-action-array-dictionary:
  //          const lSrcArrAct = this.getLatestActivityFromActionArray(sourceArrD, e);
  //          // debugger;

  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(lSrcArrAct) && lSrcArrAct.id > e) {
  //            /*
  //             * this.myUnlockArray =
  //             * this.removeActivityFromUserActionDictionary(destinationD, tAct, mact.receiverSitUserId);
  //             */
  //          }
  //        });
  //      }
  //    }
  //    // else nothing to update destinationDictionary with since the sourceArray is empty
  //  }
  //  /*
  //   * else nothing to update since the destinationDictionary is empty
  //   * debugger;
  //   */
  //  return destinationD;
  // }

