<!-- && isCameraVisible-->
<div *ngIf="signedInUserId > 0"
     id="imageUploadComponentId"
     align="center"
     style="margin: 0.5rem 0; width: 100%;"
     [@growXshrink] class="flex-container"
     [ngStyle]="{'margin-top': 'marginTop'}">
  <!--message-->
  <div *ngIf="message.length > 0" class="flex-item-plain notexS">
    <span class="errxS alert alert-secondary" tyle="padding:0.05rem;width:100%;" role="alert"
          [innerHtml]="message  | safe: 'html'">
    </span>
  </div>
  <div class="flex-text-center" style="margin: 0.5rem 0.05rem; width: 100%;left:33%;right:33%;">
    <div class="whiteBlueInvSB" style="width: auto;margin: 0.05rem;">
      Upload Pictures:
    </div>

  </div>

  <form #imageUploadForm="ngForm" novalidate name="picUploadForm" enctype="multipart/form-data" class="flex-container" style="width: 100%;margin:0.15rem 0.05rem;">
    <div class="whiteBlueInvxSB"
         style="text-shadow: 0 -.1em .1em gold; width: auto; left: 20%; right: 5%; margin:1rem 0.05rem;">
      Select File :
      <input type="file" (change)="uploadFiles($event)" class="flex-text-center notexS"
             name="chatfiles"
             [ngClass]="{'borderLeftGreen': chatFileUpload.fileMap.size > 0, 'borderleftRed': !(chatFileUpload.fileMap.size > 0)}"
             style="height: 1rem;
                    width: 11rem;
                    border-radius: 7px;"
             autofocus multiple required />
      <div *ngIf="isSubmitted && chatFileUpload.fileMap.size === 0" class="flex-text-center errxxS" style="margin:0rem 0.25rem 0.5rem 0.25rem;width:95%;">
        Image required!
      </div>
    </div>

    <div class="flex-button form-group" [ngClass]="{
            'has-danger': heading.invalid && heading.dirty,
            'has-success': heading.valid && heading.dirty
          }"
         style="margin:1rem 0.05rem 0.5rem 0.05rem; width: 100%; left: 15%; right: 2%;">
      <div class="whiteBluexSB" style="margin:0.05rem;">
        Description(<span class="errxS">optional</span>):
      </div>
      <textarea name="description" type="text"
                [(ngModel)]="content.contents"
                [ngClass]="{'borderLeftGreen': content.contents.length > 0, 'borderleftRed': !(content.contents.length > 0)}"
                class="textarea;"
                style="width:12rem;height:4rem;color:lightslategray;border-radius:7px;"
                #heading="ngModel"
                (change)="getDescription($event)">
        </textarea>

      <!--<div *ngIf="chatFileUpload.description.length == 0 && description.touched" class="flex-button errxxS" style="margin:0.25rem;width:95%;">
        <span class="flex-text" style="color:#e80c4d;">
          <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
          A file description is required
        </span>
      </div>-->
    </div>

    <div class="flex-container text-center"
         style="margin:1rem 0.05rem 0rem 0.05rem; left: 5%; right: 10%; width: 100%; display: inline-block;">
      <!--[disabled]="content.contents.length === 0"-->
      <button type="submit" (click)="upload(content)"
              [ngClass]="{'flex-button bttn-md-info':true, 'mBttnFontWeight':isMobilevar}" style="margin:5px;display:inline-block;">
        <span class="glyphicon glyphicon-cloud-upload" aria-hidden="true"
              style="vertical-align:middle;padding:5px 10px 5px 10px;">
          Upload Picture
        </span>
      </button>

      <button type="button" (click)="cancel();"
              class="flex-button"
              [ngClass]="{'bttn-md-invDefault':!isMobilevar, 'bttn-sm-invDefault mBttnFontWeight':isMobilevar}"
              style="margin:5px 5px 5px 0.5rem;display:inline-block;">
        <span class="glyphicon glyphicon-ban-circle" aria-hidden="true" style="vertical-align:middle;padding:5px 10px 5px 10px;">
          Cancel
        </span>
      </button>
    </div>

    <!--<div *ngIf="currentFile" class="flex-container col-lg-6">
      <div class="flex-item-plain progress" style="width:100%;">
        <div class="progress-bar progress-bar-info progress-bar-striped"
             role="progressbar"
             attr.aria-valuenow="{{ progress }}"
             aria-valuemin="0"
             aria-valuemax="100"
             [ngStyle]="{ width: progress + '%' }">
          {{ progress }}%
        </div>
      </div>
    </div>-->
    <!--<div *ngIf="fileInfo" class="flex-container card">
      <div class="flex-text-center card-header whiteBlueSB">List of Files</div>
      <ul class="list-group list-group-flush flex-item-plain whiteBlueInvS"
          *ngFor="let file of fileInfos | async">
        <li class="list-group-item">
          <a href="{{ file.url }}">{{ file.name }}</a>
        </li>
      </ul>
    </div>-->
  </form>
</div>
<!--==============-->
<!--(click)="submitImageUploadForm();"
[disabled]="(imageUploadForm.invalid || chatFileUpload.files.length == 0)"-->
<!--<div class="col-4">
  <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload()">
    Upload
  </button>
</div>-->
<!--=================================================-->
<!--<style>
  .my-drop-zone {
    border: dotted 3px lightgray;
  }

  .nv-file-over {
    border: dotted 3px red;
  }
  /* Default class applied to drop zones on over */
  .another-file-over-class {
    border: dotted 3px green;
  }

  html, body {
    height: 100%;
  }
</style>

<div class="container">

  <div class="navbar navbar-default">
    <div class="navbar-header">
      <a class="navbar-brand" href>Angular2 File Upload</a>
    </div>
  </div>

  <div class="row">

    <div class="col-md-3">

      <h3>Select files</h3>

      <div ng2FileDrop
           [ngClass]="{'nv-file-over': hasBaseDropZoneOver}"
           (fileOver)="fileOverBase($event)"
           [uploader]="uploader"
           class="well my-drop-zone">
        Base drop zone
      </div>

      <div ng2FileDrop
           [ngClass]="{'another-file-over-class': hasAnotherDropZoneOver}"
           (fileOver)="fileOverAnother($event)"
           [uploader]="uploader"
           class="well my-drop-zone">
        Another drop zone
      </div>

      Multiple
      <input type="file" ng2FileSelect [uploader]="uploader" multiple /><br />

      Single
      <input type="file" ng2FileSelect [uploader]="uploader" />
    </div>

    <div class="col-md-9" style="margin-bottom: 40px">

      <h3>Upload queue</h3>-->
<!--<p>Queue length: {{ uploader?.queue?.length }}</p>-->
<!--<table class="table">
  <thead>
    <tr>
      <th width="50%">Name</th>
      <th>Size</th>
      <th>Progress</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of uploader?.queue">
      <td><strong>{{ item?.file?.name }}</strong></td>
      <td *ngIf="uploader?.options?.isHTML5" nowrap>{{ item?.file?.size/1024/1024 | number:'.2' }} MB</td>
      <td *ngIf="uploader?.options?.isHTML5">
        <div class="progress" style="margin-bottom: 0;">
          <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item?.progress + '%' }"></div>
        </div>
      </td>
      <td class="text-center">
        <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
        <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
        <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
      </td>
      <td nowrap>
        <button type="button" class="btn btn-success btn-xs"
                (click)="item?.upload()" [disabled]="item?.isReady || item?.isUploading || item?.isSuccess">
          <span class="glyphicon glyphicon-upload"></span> Upload
        </button>
        <button type="button" class="btn btn-warning btn-xs"
                (click)="item?.cancel()" [disabled]="!item?.isUploading">
          <span class="glyphicon glyphicon-ban-circle"></span> Cancel
        </button>
        <button type="button" class="btn btn-danger btn-xs"
                (click)="item?.remove()">
          <span class="glyphicon glyphicon-trash"></span> Remove
        </button>
      </td>
    </tr>
  </tbody>
</table>-->
<!--<div>
  <div>
    Queue progress:
    <div class="progress" style="">
      <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': uploader?.progress + '%' }"></div>
    </div>
  </div>
  <button type="button" class="btn btn-success btn-s"
          (click)="uploader?.uploadAll()" [disabled]="!uploader?.getNotUploadedItems()?.length">
    <span class="glyphicon glyphicon-upload"></span> Upload all
  </button>
  <button type="button" class="btn btn-warning btn-s"
          (click)="uploader?.cancelAll()" [disabled]="!uploader?.isUploading">
    <span class="glyphicon glyphicon-ban-circle"></span> Cancel all
  </button>
  <button type="button" class="btn btn-danger btn-s"
          (click)="uploader?.clearQueue()" [disabled]="!uploader?.queue?.length">
    <span class="glyphicon glyphicon-trash"></span> Remove all
  </button>
</div>-->
<!--</div>

  </div>

  <br><br>

  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-default">
        <div class="panel-heading">Response</div>
        <div class="panel-body">
          {{ response }}
        </div>
      </div>
    </div>
  </div>
</div>-->
