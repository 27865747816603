
// //import { Injectable, OnDestroy, OnInit } from '@angular/core';
// //import { Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Dictionary } from 'dictionaryjs';
// import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Chat } from '../../models/chat/chat.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { LogError } from '../../models/common/logError.model';
import { Photo } from '../../models/common/photo.model';
import { SpinnerModel } from '../../models/common/spinnerModel.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { MemberActivity } from '../../models/member/memberActivity.model';
import { Preference } from '../../models/profile/preference.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
// import { IndexedDbService } from '../dictionaryServiceService/indexedDbService.service';
import { BinarySearchService } from '../searchSortServiceService/binarySearchService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { ArrayServiceStatic } from './arrayServiceStatic.service';
import { ArraySupportServiceStatic } from './arraySupportServiceStatic.service';
import { CopyServiceStatic } from './commonStaticServices/copyServiceStatic.service';
import { SlakezSaltServiceStatic } from './commonStaticServices/slakezSaltServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';


export enum staticDictionaryEnum
{
  myBlockDictionary = 1,
  myFriendDictionary = 2,
  myLikeDictionary = 3,
  myMemberActivityDictionary = 4,
  myStarDictionary = 5,
  myTrackDictionary = 6,
  myUnlockDictionary = 7,
  myWinkDictionary = 8,
  myProfilePicsDictionary = 9,

  buddyDictionary = 10,
  heartbeatDictionary = 11,
  preferenceDictionary = 12,
  profileImageDictionary = 13,
  profileInfoDictionary = 14,
  profilePicsDictionary = 15,
  profileTileIdDictionary = 16,
  profileTileDictionary = 17,
  sitUserDictionary = 18,
  sitUserIdDictionary = 19,

  yourBlockDictionary = 20,
  yourFriendDictionary = 21,
  yourLikeDictionary = 22,
  yourMemberActivityDictionary = 23,
  yourStarDictionary = 24,
  yourTrackDictionary = 25,
  yourUnlockDictionary = 26,
  yourWinkDictionary = 27,

  preferenceBnDictionary = 30,
  profileInfoBnDictionary = 31,
  photoBnArrDictionary = 32,
  profileTileIdBnDictionary = 33,
  profileTileBnDictionary = 34,
  sitUserBnDictionary = 35,
 

  chatHistoryIdictionary = 40,
  chatHistoryictionary = 41,
  myChatDictionary = 42,
  emailTileIdDictionary = 43,
  emailTileDictionary = 44,
  notificationIdDictionary = 45,
  notificationDictionary = 46,


  // ------------------------------------------------------------------------------------------------------------------------
  //  New PhotoToPhotoBnToPhotoSystem:
  // ---------------------------------
  //  When a user uploads a photo, it will be saved in the backendDB first, then the client-sent
  //  Photo's box and nonce along with the saved photo's data will be populated in a PhotoBn Model and be saved in backendDB.
  //  This is a one-time-one-way transaction.
  //
  //  Subsequestly, whenever the client requests for a photo, the respective PhotoBn will be sent by the photoId/PhotoBnId.
  //
  //  The client will always get a photoBn or a set of PhotoBns when a photo or set of photos are requested.
  //  Upon receiving the photoBn(s), client will decrypt to get photoBn/PhotoBns to get the respective Photo/Photos,
  //  and populate them to the PhotoDictionary for the view to use.
  //  This photoDictionary will NOT be saved in indexedDb, neither any PhotoIds.
  //
  //  The client will also populate the received PhotoBn(s) to the PhotoBnDictionary that will be saved to the indexedDb,
  //  which the client will use to create the PhotoDictionary for the View upon launching or as needed basis.
  //
  //  The client will save a UserPhotoBnIdArr(all the photoBnIds of one user in an array) in the UserPhotoBnIdDictionary
  //  that will be saved in the indexedDb. Therefore, wheneve the user saves a new Photo, or whenever the user gets a
  //  PhotoBn from the server, the respective PhotoBnId will be first saved to the UserPhotoBnIdArray, and update the
  //  UserPhotoBnIdDictionary<sitUserId, UserPhotoBnIdsArr> and the dictionary will be saved in the indexedDb.
  //  It's noteworthy that the signedInUser's photoBnIdArr will contain the signedInUser's data, and any other user's
  //  photoBnIds will be in an array saved using the respective sitUserId, such that signedInUserId != sitUserId.
  //  Hence the PhotoBnIdDitionary will contain any/all phootoBnIds of a member viewed by the signedInUser.
  //
  //  This UserPhotoBnIdDictionary will be used to create/lookup a specific user's Photo(s). 
  //  
  // ------------------------------------------------------------------------------------------------------------------------

  // photoIdDictionary = 50, // will be deprecated since photoDictionary will contain PhotoId
  photoDictionary = 51, // will be used by View Only, and will not presist in indexedDb
  userPhotoBnIdDictionary = 52, //will be renamed to UserPhotoBnIds since a user may have more than one photo,
  photoBnDictionary = 53, // will NOT be used by the View since PhotoDictionary will be used by the View, but will persist in indexedDb
  pageNumItemArrDictionary = 54,
  
}


@Injectable({
  providedIn: 'root',
})
/*
 * ref:https:// github.com/basarat/typescript-collections/blob/release/README.md#a-sample-on-dictionary
 * TODO: This service should read data from the indexedDB and save it to indexedDb periodically.
 */
export abstract class DictionaryServiceStatic implements OnDestroy {
  static allDictionaries = new Dictionary<string, Dictionary<number | string | any, any>>();
  static allDictionaryDataCount = new Dictionary<string, number>();
  static binarySearchService : BinarySearchService = new BinarySearchService();

  static buddyDictionary = new Dictionary<number, MemberActivity> ();

  static chatHistoryDictionary = new Dictionary<number, Chat>();
  static count : number = 0;
  static chatHistoryIdDictionary = new Dictionary<number, number>();

  static dexieTableDictionary : any;

  static eMailTileDictionary = new Dictionary<number, CommunicationActivity>(); // DO NOT save Picture in Email
  static eMailTileIdDictionary = new Dictionary<number, number[]>();
  static eNoteTileDictionary = new Dictionary<number, CommunicationActivity>();
  static eNoteTileIdDictionary = new Dictionary<number, number[]>();
  static errorLogDictionary = new Dictionary<number, any>();
  static heartbeatDictionary = new Dictionary<number, Heartbeat>();
  static idbStoreIndexDictionary = new Dictionary<string, any>();
  static idbStoreTransactionDictionary = new Dictionary<string, any>();
  static kvPhotoDictionary = new Dictionary<number, KvPhoto>();
  static loginSuccessDictionary = new Dictionary<number, LoginSuccess>();


  static masterErrorLogMap = new Map(); // key==JsDate.Ticks
  // ================================================================================
  // MVC (Model-View-Controller) Dictionaries:
  // -----------------------------------------
  
  static componentDictionary = new Dictionary<string, any>();
  public static filteredKvPhotoDictionary = new Dictionary<number, KvPhoto>();
  static serviceDictionary = new Dictionary<string, any>();
  static moduleDictionary = new Dictionary<string, any>();  
  static scssClassDictionary = new Dictionary<string, any>();
  static scssFileDictionary = new Dictionary<string, any>();
  static scssIdDictionary = new Dictionary<string, any>();  
  static modelDictionary = new Dictionary<string, Dictionary<number | string | any, any>>();

  public static unFilteredKvPhotoDictionary = new Dictionary<number, KvPhoto>();
  static viewDictionary = new Dictionary<string, Dictionary<number | string | any, any>>();
  static controllerDictionary = new Dictionary<string, Dictionary<number | string | any, any>>();


  // communication dictionaries:
  static myChatIdDictionary = new Dictionary<number, number[]>();
  static myChatDictionary = new Dictionary<number, Chat>();
  static myEmailIdDictionary = new Dictionary<number, number[]>();
  static myEmailDictionary = new Dictionary<number, CommunicationActivity>();
  static myNotificationIdDictionary = new Dictionary<number, number[]>();
  static myNotificationDictionary = new Dictionary<number, CommunicationActivity>();

  static myErrorLogMap = new Map(); // key==JsDate.Ticks

  // first group of memberActivities: Block/Friend/Like: //for indexedDb
  static myMemberActivityDictionary = new Dictionary<number, MemberActivity>();
  static myBlockDictionary = new Dictionary<number, MemberActivity>();
  static myFriendDictionary = new Dictionary<number, MemberActivity>();
  static myLikeDictionary = new Dictionary<number, MemberActivity>();

  // second group of memberStarTrackWinkActivities: Star/Track/Wink: //for indexedDb
  static myMemberStarTrackWinkActivityDictionary = new Dictionary<number, MemberActivity>();
  static myStarDictionary = new Dictionary<number, MemberActivity>();
  static myTrackDictionary = new Dictionary<number, MemberActivity>();
  static myWinkDictionary = new Dictionary<number, MemberActivity>();

  // third group of memberLockingActivities: Unlock: //for indexedDb
  static myMemberLockingActivityDictionary = new Dictionary<number, MemberActivity>();
  static myUnlockDictionary = new Dictionary<number, MemberActivity>();

  // Note: These dictionaries has a matching counterparts in MemberActivitiesService where used to populate these dictionaries:
  // --------------------------------------------------------------------------------------------------------------------------
  static myProfilePicsDictionary : any; 

  static preferenceDictionary = new Dictionary<number, Preference>();
  static photoDictionary = new Dictionary<number, Photo>();

  // static profileImageDictionary = new Dictionary<number, ProfileImage>(); // key=sitUserId, value=ProfilePics
  static profileInfoDictionary = new Dictionary<number, ProfileInfo>();
  static profilePicsDictionary = new Dictionary<number, ProfilePics>();
  static profileTileIdDictionary = new Dictionary<number, number[]>();
  static profileTileDictionary = new Dictionary<number, ProfileTile>();
  static sitUserDictionary = new Dictionary<number, SitUser>();

  // SitUserIds are used to create ProfileTiles at MembersIndividually
  static sitUserIdDictionary = new Dictionary<number, number[]>();
  static profileTileDexieDictionary = new Dictionary<number, ProfileTile>(); // for testing purpose onloy. delete before deployment
  static storeNameDictionary = new Dictionary<number, string>();
  
  // Note: These dictionaries has a matching counterparts in MemberActivitiesService where used to populate these dictionaries:
  // --------------------------------------------------------------------------------------------------------------------------

  // first group of memberActivities: Block/Friend/Like: //for indexedDb
  static yourMemberActivityDictionary = new Dictionary<number, MemberActivity>();
  static yourBlockDictionary = new Dictionary<number, MemberActivity>();
  static yourFriendDictionary = new Dictionary<number, MemberActivity>();
  static yourLikeDictionary = new Dictionary<number, MemberActivity>();

  // second group of memberStarTrackWinkActivities: Star/Track/Wink: //for indexedDb
  static yourMemberStarTrackWinkActivityDictionary = new Dictionary<number, MemberActivity>();
  static yourStarDictionary = new Dictionary<number, MemberActivity>();
  static yourTrackDictionary = new Dictionary<number, MemberActivity>();
  static yourWinkDictionary = new Dictionary<number, MemberActivity>();


  // third group of memberLockingActivities: Unlock: //for indexedDb
  static yourMemberLockingActivityDictionary = new Dictionary<number, MemberActivity>();
  static yourUnlockDictionary = new Dictionary<number, MemberActivity>();

  // --------------------------------------------------------------------------------
  // Note:  Bn dictionaries are used to hold encrypted data that when complete for a certain user, will be saved in indexedDb.
  //        When indexedDb data is read, it will be unencrypted and stored in respected regular-unencrypted-dictionary (non-Bn-dictionary).
  // -------------------------------------------------------------------------------
  static preferenceBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=Preference
  static profileInfoBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=ProfileInfo
  // static photoBnArrDictionary = new Dictionary<number, BoxNonceEntity[]>(); // key=sitUserId, value=BoxNonceEntity[]


  static profileTileIdBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=ProfileTileId[]
  static profileTileBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=ProfileTile
  // static sitUserBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=SitUser

  // static photoBnIdDictionary = new Dictionary<number, number[]>(); // key=sitUserId, value=PhotoBnIds[]
  static photoBnDictionary = new Dictionary<number, BoxNonceEntity>(); // key=sitUserId, value=BoxNonceEntity[]
  // static photoDictionary = new Dictionary<number, Photo>(); // key=photoId, value=Photo 
  static userPhotoBnIdDictionary = new Dictionary<number, number[]>(); // key=sitUserId, value=PhotoBnId[]
  // static photoBnDictionary = new Dictionary<number, PhotoBn>(); // key=photoBnId, value=PhotoBn
  static loginSuccess : LoginSuccess = new LoginSuccess();
  static sitUser : SitUser = new SitUser();

  // static pageNumKvPhotoDictionary = new Dictionary<number, KvPhoto[]>(); // key=pageNum, value=KvPhoto[]
  static spinnerTimerDictionary = new Dictionary<number, SpinnerModel>(); // key=sitUserId, value=PhotoBn
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  // --------------------------------------------------------------------------------
  
  // ================================================================================
  // All components' dictonaries:
  static myAllEnoteDictionary : Dictionary<number, CommunicationActivity> = new Dictionary<number, CommunicationActivity>();
  // --------------------------------------------------------------------------------
  constructor () {
      // this.inInitialize();
    // DictionaryServiceStatic.allDictionaries = new Dictionary<string, Dictionary<number | string | any, any>>(); // = new Dictionary<string, Dictionary<number | string | any, any>>();
    // DictionaryServiceStatic.allDictionaryDataCount = new Dictionary<string, number>();
    DictionaryServiceStatic.storeNameDictionary = new Dictionary<number, string>();
    DictionaryServiceStatic.populateStoreNameDictionary();
    DictionaryServiceStatic.populateAllDictionaries();
  }
  // --------------------------------------------------------------------------------

  static populateAllDictionaries () : void {
    // debugger;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(DictionaryServiceStatic.storeNameDictionary) || DictionaryServiceStatic.storeNameDictionary.size === 0) {
      DictionaryServiceStatic.populateStoreNameDictionary();
      // debugger;
    }
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(DictionaryServiceStatic.allDictionaries) || DictionaryServiceStatic.allDictionaries.size === 0) {
      DictionaryServiceStatic.allDictionaries = new Dictionary<string, Dictionary<number | string | any, any>>();
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(DictionaryServiceStatic.allDictionaries)) {
      DictionaryServiceStatic.storeNameDictionary?.values()?.map((k : any) => {
        // debugger;

        // TODO: add each dictionary to one of the three dictionaries: i) modelDictionary ii) viewDictionary iii) controllerDictionary
        //        i.e categorize each doctionary to the appropriate one:  i) modelDictionary ii) viewDictionary iii) controllerDictionary
        switch (k.toLowerCase()) {
          case 'buddy':
            // this.buddyDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('buddyDictionary', this.buddyDictionary);
            break;
          case 'chathistoryid':
            // this.chatHistoryIdDictionary = new Dictionary<number, number>(); // DO NOT save Picture in Chat
            this.allDictionaries.set('chatHistoryIdDictionary', this.chatHistoryIdDictionary);
            break;
          case 'chathistory':
            // this.chatHistoryDictionary = new Dictionary<number, Chat>(); // DO NOT save Picture in Chat
            this.allDictionaries.set('chatHistoryDictionary', this.chatHistoryDictionary);
            break;
          case 'dexietable':
            this.dexieTableDictionary = new Dictionary<string, Dictionary<any, any>>(); // key=storeName, value=objecStore === dexieTable // (indexedDb.objectStore)
            this.allDictionaries.set('dexieTableDictionary', this.dexieTableDictionary);
            break;
          case 'emailtileid':
            // this.eMailTileIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('eMailTileIdDictionary', this.eMailTileIdDictionary);
            break;
          case 'emailtile':
            // this.eMailTileDictionary = new Dictionary<number, CommunicationActivity>();
            this.allDictionaries.set('eMailTileDictionary', this.eMailTileDictionary);
            break;
          case 'enotetileid':
            // this.eNoteTileIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('eNoteTileIdDictionary', this.eNoteTileIdDictionary);
            break;
          case 'enotetile':
            // this.eNoteTileDictionary = new Dictionary<number, CommunicationActivity>(); // DO NOT save Picture in Notification
            this.allDictionaries.set('eNoteTileDictionary', this.eNoteTileDictionary);
            break;
          case 'errorlog':
            // this.errorLogDictionary = new Dictionary<number, any>();
            this.allDictionaries.set('errorLogDictionary', this.errorLogDictionary);
            break;
          case 'heartbeat':
            // this.heartbeatDictionary = new Dictionary<number, Heartbeat>();
            this.allDictionaries.set('heartbeatDictionary', this.heartbeatDictionary);
            break;
          case 'idbstoreindex':
            // this.idbStoreIndexDictionary = new Dictionary<string, any>(); // key=storeName, value=objectStore // (indexedDb.objectStore)
            this.allDictionaries.set('idbStoreIndexDictionary', this.idbStoreIndexDictionary);
            break;
          case 'idbstoretransaction':
            // this.idbStoreTransactionDictionary = new Dictionary<string, any>(); // key=storeName, value=objectStoreTransaction
            this.allDictionaries.set('idbStoreTransactionDictionary', this.idbStoreTransactionDictionary);
            break;
          case 'kvphoto':
           //  this.kvPhotoDictionary = new Dictionary<number, KvPhoto>();
            this.allDictionaries.set('kvPhotoDictionary', this.kvPhotoDictionary);
            break;
          case 'loginsuccess':
            // this.loginSuccessDictionary = new Dictionary<number, LoginSuccess>();
            this.allDictionaries.set('loginSuccessDictionary', this.loginSuccessDictionary);
            break;
          case 'myblock':
            // this.myBlockDictionary = new Dictionary<number, MemberActivity>(); // for view
            this.allDictionaries.set('myBlockDictionary', this.myBlockDictionary);
            break;
          case 'mychatid':
            // this.myChatIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('myChatIdDictionary', this.myChatIdDictionary);
            break;
          case 'mychat':
            // this.myChatDictionary = new Dictionary<number, Chat>();
            this.allDictionaries.set('myChatDictionary', this.myChatDictionary);
            break;
          case 'myemailid':
            // this.myEmailIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('myEmailIdDictionary', this.myEmailIdDictionary);
            break;
          case 'myemail':
            // this.myEmailDictionary = new Dictionary<number, CommunicationActivity>();
            this.allDictionaries.set('myEmailDictionary', this.myEmailDictionary);
            break;
          case 'myfriend':
            // this.myFriendDictionary = new Dictionary<number, MemberActivity>(); // for view
            this.allDictionaries.set('myFriendDictionary', this.myFriendDictionary);
            break;
          case 'mylike':
            // this.myLikeDictionary = new Dictionary<number, MemberActivity>(); // for view
            this.allDictionaries.set('myLikeDictionary', this.myLikeDictionary);
            break;
          case 'mymemberactivity':
            // this.myMemberActivityDictionary = new Dictionary<number, MemberActivity>(); // key==(action-name).(member-activity-id) //for indexedDb
            this.allDictionaries.set('myMemberActivityDictionary', this.myMemberActivityDictionary);
            break;
          case 'mymemberlockingactivity':
            // this.myMemberLockingActivityDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('myMemberLockingActivityDictionary', this.myMemberLockingActivityDictionary);
            break;
          case 'mymemberstartrackwinkactivity':
            // this.myMemberStarTrackWinkActivityDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('myMemberStarTrackWinkActivityDictionary', this.myMemberStarTrackWinkActivityDictionary);
            break;
          case 'mynotificationid':
            // this.myNotificationIdDictionary = new Dictionary<number, number[]>();
            this.allDictionaries.set('myNotificationIdDictionary', this.myNotificationIdDictionary);
            break;
          case 'mynotification':
            // this.myNotificationDictionary = new Dictionary<number, CommunicationActivity>();
            this.allDictionaries.set('myNotificationDictionary', this.myNotificationDictionary);
            break;
          case 'mystar':
            // this.myStarDictionary = new Dictionary<number, MemberActivity>(); // for view
            this.allDictionaries.set('myStarDictionary', this.myStarDictionary);
            break;
          case 'mytrack':
            // this.myTrackDictionary = new Dictionary<number, MemberActivity>(); // for view
            this.allDictionaries.set('myTrackDictionary', this.myTrackDictionary);
            break;
          case 'myunlock':
            // this.myUnlockDictionary = new Dictionary<number, MemberActivity>(); // for view
            this.allDictionaries.set('myUnlockDictionary', this.myUnlockDictionary);
            break;
          case 'mywink':
            // this.myWinkDictionary = new Dictionary<number, MemberActivity>(); // for view
            this.allDictionaries.set('myWinkDictionary', this.myWinkDictionary);
            break;
          case 'photo':
            // this.photoDictionary = new Dictionary<number, Photo>(); // key=photoId, value=Photo;
            this.allDictionaries.set('photoDictionary', this.photoDictionary);
            break;
          case 'photobn':
            // this.photoBnDictionary = new Dictionary<number, PhotoBn>(); // key=photoId, value=Photo;
            this.allDictionaries.set('photoBnDictionary', this.photoBnDictionary);
            break;
          case 'photobnid':
            this.userPhotoBnIdDictionary = new Dictionary<number, number[]>(); // key=photoId, value=Photo;
            this.allDictionaries.set('userPhotoBnIdDictionary', this.userPhotoBnIdDictionary);
            break;
          case 'preference':
            // this.preferenceDictionary = new Dictionary<number, Preference>(); // key=sitUserId, value=Preference
            this.allDictionaries.set('preferenceDictionary', this.preferenceDictionary);
            break;
          case 'profileinfo':
            // this.profileInfoDictionary = new Dictionary<number, ProfileInfo>(); // key=sitUserId, value=Preference
            this.allDictionaries.set('profileInfoDictionary', this.profileInfoDictionary);
            break;
          case 'profilepics':
            // this.profilePicsDictionary = new Dictionary<number, ProfilePics>(); // key=sitUserId, value=Preference
            this.allDictionaries.set('profilePicsDictionary', this.profilePicsDictionary);
            break;
          case 'profiletile':
            // this.profileTileDictionary = new Dictionary<number, ProfileTile>(); // key=sitUserId, value=Preference
            this.allDictionaries.set('profileTileDictionary', this.profileTileDictionary);
            break;
          case 'situserid':
            // this.sitUserIdDictionary = new Dictionary<number, number[]>(); // key=sitUserId, value=sitUserId
            this.allDictionaries.set('sitUserIdDictionary', this.sitUserIdDictionary);
            break;
          case 'situser':
            // this.sitUserDictionary = new Dictionary<number, SitUser>(); // key=sitUserId, value=sitUserId
            this.allDictionaries.set('sitUserDictionary', this.sitUserDictionary);
            break;
          case 'yourblock':
            // this.yourBlockDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourBlockDictionary', this.yourBlockDictionary);
            break;
          case 'yourfriend':
            // this.yourFriendDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourFriendDictionary', this.yourFriendDictionary);
            break;
          case 'yourlike':
            // this.yourLikeDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourLikeDictionary', this.yourLikeDictionary);
            break;
          case 'yourmemberactivity':
            // this.yourMemberActivityDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourMemberActivityDictionary', this.yourMemberActivityDictionary);
            break;
          case 'yourmemberlockingactivity':
            // this.yourMemberLockingActivityDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourMemberLockingActivityDictionary', this.yourMemberLockingActivityDictionary);
            break;
          case 'yourmemberstartrackwinkactivity':
            // this.yourMemberStarTrackWinkActivityDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourMemberStarTrackWinkActivityDictionary', this.yourMemberStarTrackWinkActivityDictionary);
            break;
          case 'yourstar':
            // this.yourStarDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourStarDictionary', this.yourStarDictionary);
            break;
          case 'yourtrack':
            // this.yourTrackDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourTrackDictionary', this.yourTrackDictionary);
            break;
          case 'yourunlock':
            // this.yourUnlockDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourUnlockDictionary', this.yourUnlockDictionary);
            break;
          case 'yourwink':
            // this.yourWinkDictionary = new Dictionary<number, MemberActivity>();
            this.allDictionaries.set('yourWinkDictionary', this.yourWinkDictionary);
            break;
        }
        // debugger;
      })
      // debugger;
    }
  }
  // ---------------------------------------------------------------------------------
  static populateStoreNameDictionary () {
    // debugger;
    let i = 0;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.storeNameDictionary) || this.storeNameDictionary.size === 0) {
      this.storeNameDictionary = new Dictionary<number, string>();
    }
    this.storeNameDictionary.set(i++, "buddy");
    this.storeNameDictionary.set(i++, "chatHistory");
    this.storeNameDictionary.set(i++, "chatHistoryId");
    this.storeNameDictionary.set(i++, "dexieTable");
    this.storeNameDictionary.set(i++, "emailTile");
    this.storeNameDictionary.set(i++, "emailTileId");
    this.storeNameDictionary.set(i++, "enoteTile");
    this.storeNameDictionary.set(i++, "enoteTileId");
    this.storeNameDictionary.set(i++, "errorLog");
    this.storeNameDictionary.set(i++, "heartbeat");
    this.storeNameDictionary.set(i++, "idbStoreIndex");
    this.storeNameDictionary.set(i++, "idbStoreTransaction");
    this.storeNameDictionary.set(i++, "kvPhoto");
    this.storeNameDictionary.set(i++, "loginSuccess");

    this.storeNameDictionary.set(i++, "myBlock");
    this.storeNameDictionary.set(i++, "myChat");
    this.storeNameDictionary.set(i++, "myChatId");
    this.storeNameDictionary.set(i++, "myEmail");
    this.storeNameDictionary.set(i++, "myEmailId");
    this.storeNameDictionary.set(i++, "myFriend");
    this.storeNameDictionary.set(i++, "myLike");
    this.storeNameDictionary.set(i++, "myMemberActivity");
    this.storeNameDictionary.set(i++, "myMemberLockingActivity");
    this.storeNameDictionary.set(i++, "myMemberStarTrackWinkActivity");
    this.storeNameDictionary.set(i++, "myNotification");
    this.storeNameDictionary.set(i++, "myNotificationId");
    this.storeNameDictionary.set(i++, "myStar");
    this.storeNameDictionary.set(i++, "myTrack");
    this.storeNameDictionary.set(i++, "myUnlock");
    this.storeNameDictionary.set(i++, "myWink");
    
    this.storeNameDictionary.set(i++, "photo");
    this.storeNameDictionary.set(i++, "photoBn");
    this.storeNameDictionary.set(i++, "preference");
    this.storeNameDictionary.set(i++, "profileInfo");
    this.storeNameDictionary.set(i++, "profilePics");
    this.storeNameDictionary.set(i++, "profileTile");

    this.storeNameDictionary.set(i++, "sitUser");
    this.storeNameDictionary.set(i++, "sitUserId");

    this.storeNameDictionary.set(i++, "yourBlock");
    this.storeNameDictionary.set(i++, "yourFriend");
    this.storeNameDictionary.set(i++, "yourLike");
    this.storeNameDictionary.set(i++, "yourMemberActivity");
    this.storeNameDictionary.set(i++, "yourMemberLockingActivity");
    this.storeNameDictionary.set(i++, "yourMemberStarTrackWinkActivity");
    this.storeNameDictionary.set(i++, "yourStar");
    this.storeNameDictionary.set(i++, "yourTrack");
    this.storeNameDictionary.set(i++, "yourUnlock");
    this.storeNameDictionary.set(i++, "yourWink");
  }
  // ---------------------------------------------------------------
  //  Note: this method gets only unFilteredKvPhotoArray of ProfilePics.
  // ---------------------------------------------------------------
  static getProfilePicsFromKvPhotoDictionary (sitUserId : number) : ProfilePics | any {
    let outKvPhotoArr : KvPhoto[] = [];
    let profilePics = new ProfilePics();
    if (sitUserId > 0) {
      profilePics.unFilteredKvPhotoArr = this.getUserKvPhotoArrFromKvPhotoDictionary(sitUserId);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.unFilteredKvPhotoArr) && profilePics.unFilteredKvPhotoArr.length > 0) {
        // debugger;
        profilePics.unFilteredKvPhotoArr.forEach(e => {
          if (e) {
            profilePics.unFilteredKvPhotoDictionary.set(e.photoBnId, e);
          }
        });

        // Note: the calling function should filter the KvPhotos:
        // ------------------------------------------------------
        //this.photoPrivacyService.filterKvPhotoArr(profilePics.unFilteredKvPhotoArr).then(result => {
        //  profilePics.filteredKvPhotoArr = result;
        //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.filteredKvPhotoArr) && profilePics.filteredKvPhotoArr.length > 0) {
        //    // debugger;
        //    profilePics.filteredKvPhotoArr.forEach(e => {
        //      if (e) {
        //        profilePics.filteredKvPhotoDictionary.set(e.photoBnId, e);
        //      }
        //    })
        //  }
        //});
      }
      return profilePics;
    }
    else return null;
  }
  // ---------------------------------------------------------------
  static getUserKvPhotoArrFromKvPhotoDictionary (sitUserId : number) : KvPhoto[] | any {
    let outKvPhotoArr : KvPhoto[] = [];
    if (sitUserId > 0 && this.kvPhotoDictionary.size > 0) {
      this.kvPhotoDictionary.values().forEach((e : any) => {
        if (e.sitUserId === sitUserId) {
          outKvPhotoArr.push(e);
        }
      })
    }
    return outKvPhotoArr;
  }
  // ---------------------------------------------------------------
  //  Note: this method gets a profileTile from the dictionary by
  //        a non-key propertiy==sitUserId where the key=profileTileId
  //
  //        This is a quentasential example of how to get a dictionary's
  //        value by a non-key-property of the model.
  // ---------------------------------------------------------------
  static getProfileTileBySitUserId (sitUserId : number) : any {
    if (sitUserId > 0) {
      let pTileArr = this.profileTileDictionary.size > 0 ? this.profileTileDictionary.values() : [];
      pTileArr = pTileArr.sort((a : any, b : any) => a.sitUserId - b.sitUserId);
      // debugger;
      let index = this.binarySearchService.nonRecursiveBinarySearchProfileTile(pTileArr, sitUserId);
      let tpTile = pTileArr[ index ];
      // debugger;
      return tpTile;
    }
    else return null;
  }

  // --------------------------------------------------------------------------------
  static getMasterErrorLogMap () : Map<number, LogError> {
    // debugger;
    return this.masterErrorLogMap;
  }
  static setMasterErrorLogMap (bDic : Map<number, LogError>) : void {
    this.masterErrorLogMap = bDic;
  }
  static resetMasterErrorLogMap () : void {
    this.masterErrorLogMap = new Map<number, LogError>();
  }
  // ---------------------------------------------------------------
  static getMyErrorLogMap () : Map<number, LogError> {
    // debugger;
    return this.myErrorLogMap;
  }
  static setMyErrorLogMap (bDic : Map<number, LogError>) : void {
    this.myErrorLogMap = bDic;
  }
  static resetMyErrorLogMap () : void {
    this.myErrorLogMap = new Map<number, LogError>();
  }
  /*
  * ---------------------------------------------------------------
  * Begin of Communication Dictionaries:
  * ---------------------------------------------------------------
  */
  // ---------------------------------------------------------------
  static getChatHistoryDictionary () : Dictionary<number, Chat> {
    // debugger;
    return this.chatHistoryDictionary;
  }
  static setChatHistoryDictionary (chDic : Dictionary<number, Chat>) : void {
    this.chatHistoryDictionary = chDic;
  }

  // ---------------------------------------------------------------
  static getChatHistoryIdDictionary () : Dictionary<number, number> {
    // debugger;
    return this.chatHistoryIdDictionary;
  }
  static setChatHistoryIdDictionary (chiDic : Dictionary<number, number>) : void {
    this.chatHistoryIdDictionary = chiDic;
  }
  // ---------------------------------------------------------------
  static getEmailTileIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.eMailTileIdDictionary;
  }
  static setEmailTileIdDictionary (emDic : Dictionary<number, number[]>) : void {
    this.eMailTileIdDictionary = emDic;
  }
  // ---------------------------------------------------------------
  static getEnoteTileIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.eNoteTileIdDictionary;
  }
  static setEnoteTileIdDictionary (chiDic : Dictionary<number, number[]>) : void {
    this.eNoteTileIdDictionary = chiDic;
  }
  // ---------------------------------------------------------------
  static getEmailTileDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.eMailTileDictionary;
  }
  static setEmailTileDictionary (emDic : Dictionary<number, CommunicationActivity>) : void {
    this.eMailTileDictionary = emDic;
  }
  // ---------------------------------------------------------------
  static getEnoteTileDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.eNoteTileDictionary;
  }
  static setEnoteTileDictionary (enDic : Dictionary<number, CommunicationActivity>) : void {
    this.eNoteTileDictionary = enDic;
  }
  // ---------------------------------------------------------------
  static getMyChatDictionary () : Dictionary<number, Chat> {
    // debugger;
    return this.myChatDictionary;
  }
  static setMyChatDictionary (cDic : Dictionary<number, Chat>) : void {
    this.myChatDictionary = cDic;
  }
  // ---------------------------------------------------------------
  static getMyEmailTileDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myEmailDictionary;
  }
  static setMyEmailTileDictionary (eDic : Dictionary<number, CommunicationActivity>) : void {
    this.myEmailDictionary = eDic;
  }
  // ---------------------------------------------------------------
  static getMyNotificationIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.myNotificationIdDictionary;
  }
  static setMyNotificationIdDictionary (nIdDic : Dictionary<number, number[]>) : void {
    this.myNotificationIdDictionary = nIdDic;
  }
  // ---------------------------------------------------------------
  static getMyNotificationDictionary () : Dictionary<number, CommunicationActivity> {
    // debugger;
    return this.myNotificationDictionary;
  }
  static setMyNotificationDictionary (nDic : Dictionary<number, CommunicationActivity>) : void {
    this.myNotificationDictionary = nDic;
  }
  /*
   * ---------------------------------------------------------------
   * End of Communication Dictionaries:
   * ---------------------------------------------------------------
   */
  /*
   * ---------------------------------------------------------------
   * Begin of MemberAcvitiy Dictionaries:
   * ---------------------------------------------------------------
   */
  static getBuddyDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.buddyDictionary;
  }
  static setBuddyDictionary (bDic : Dictionary<number, MemberActivity>) : void {
    this.buddyDictionary = bDic;
  }
  // ---------------------------------------------------------------
  static getMyBlockDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.myBlockDictionary;
  }
  static setMyBlockDictionary (bDic : Dictionary<number, MemberActivity>) : void {
    this.myBlockDictionary = bDic;
  }

  // ---------------------------------------------------------------
  static getMyFriendDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.myFriendDictionary;
  }
  static setMyFriendDictionary (fDic : Dictionary<number, MemberActivity>) : void {
    this.myFriendDictionary = fDic;
  }
  // ---------------------------------------------------------------
  static getHeartbeatDictionary () : Dictionary<number, Heartbeat> {
    // debugger;
    return this.heartbeatDictionary;
  }
  static setHeartbeatDictionary (hbDic : Dictionary<number, Heartbeat>) : void {
    this.heartbeatDictionary = hbDic;
  }
  // ---------------------------------------------------------------
  static getMyLikeDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.myLikeDictionary;
  }
  static setMyLikeDictionary (lDic : Dictionary<number, MemberActivity>) : void {
    this.myLikeDictionary = lDic;
  }

  // ---------------------------------------------------------------
  static getMyUnlockDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.myUnlockDictionary;
  }
  static setMyUnlockDictionary (uDic : Dictionary<number, MemberActivity>) : void {
    this.myUnlockDictionary = uDic;
  }

  // ---------------------------------------------------------------
  static getMyStarDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.myStarDictionary;
  }
  static setMyStarDictionary (tDic : Dictionary<number, MemberActivity>) : void {
    this.myStarDictionary = tDic;
  }
  // ---------------------------------------------------------------
  static getMyTrackDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.myTrackDictionary;
  }
  static setMyTrackDictionary (tDic : Dictionary<number, MemberActivity>) : void {
    this.myTrackDictionary = tDic;
  }
  // ---------------------------------------------------------------
  static getMyWinkDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.myWinkDictionary;
  }
  static setMyWinkDictionary (wDic : Dictionary<number, MemberActivity>) : void {
    this.myWinkDictionary = wDic;
  }
  /*
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
  static getYourBlockDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.yourBlockDictionary;
  }
  static setYourBlockDictionary (bDic : Dictionary<number, MemberActivity>) : void {
    this.yourBlockDictionary = bDic;
  }
  // ---------------------------------------------------------------
  static getYourFriendDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.yourFriendDictionary;
  }
  static setYourFriendDictionary (fDic : Dictionary<number, MemberActivity>) : void {
    this.yourFriendDictionary = fDic;
  }
  // ---------------------------------------------------------------
  static getMyYourLikeDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.yourLikeDictionary;
  }
  static setYourLikeDictionary (lDic : Dictionary<number, MemberActivity>) : void {
    this.yourLikeDictionary = lDic;
  }
  // ---------------------------------------------------------------
  static getYourUnlockDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.yourUnlockDictionary;
  }
  static setYourUnlockDictionary (uDic : Dictionary<number, MemberActivity>) : void {
    this.yourUnlockDictionary = uDic;
  }
  // ---------------------------------------------------------------
  static getYourStarDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.yourStarDictionary;
  }
  static setYourStarDictionary (tDic : Dictionary<number, MemberActivity>) : void {
    this.yourStarDictionary = tDic;
  }
  // ---------------------------------------------------------------
  static getYourTrackDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.yourTrackDictionary;
  }
  static setYourTrackDictionary (tDic : Dictionary<number, MemberActivity>) : void {
    this.yourTrackDictionary = tDic;
  }
  // ---------------------------------------------------------------
  static getYourWinkDictionary () : Dictionary<number, MemberActivity> {
    // debugger;
    return this.yourWinkDictionary;
  }
  static setYourWinkDictionary (wDic : Dictionary<number, MemberActivity>) : void {
    this.yourWinkDictionary = wDic;
  }
  /*
   * ---------------------------------------------------------------
   * End of MemberAcvitiy Dictionaries:
   * ---------------------------------------------------------------
   * ---------------------------------------------------------------
   */
  /*
   * ---------------------------------------------------------------
   * Begin of Member(View/Profile) Dictionaries:
   * ---------------------------------------------------------------
   */
  
  // ---------------------------------------------------------------
 
  // ---------------------------------------------------------------
  static getMyProfilePicsDictionary () : Dictionary<number, ProfilePics> {
    // debugger;
    return this.myProfilePicsDictionary;
  }
  static setMyProfilePicsDictionary (profPicDic : Dictionary<number, ProfilePics>) : void {
    this.myProfilePicsDictionary = profPicDic;
  }
  // ---------------------------------------------------------------
  static getKvPhotoDictionary () : Dictionary<number, KvPhoto> {
    // debugger;
    return this.kvPhotoDictionary;
  }
  static setKvPhotoDictionary (pnumKvpDic : Dictionary<number, KvPhoto>) : void {
    this.kvPhotoDictionary = pnumKvpDic;
  }
  // ---------------------------------------------------------------
  static getPreferenceDictionary () : Dictionary<number, Preference> {
    // debugger;
    return this.preferenceDictionary;
  }
  static setPreferenceDictionary (prefDic : Dictionary<number, Preference>) : void {
    this.preferenceDictionary = prefDic;
  }
  // ---------------------------------------------------------------
  static getProfilePicsDictionary () : Dictionary<number, ProfilePics> {
    // debugger;
    return this.profilePicsDictionary;
  }
  static setProfilePicsDictionary (profPicDic : Dictionary<number, ProfilePics>) : void {
    this.profilePicsDictionary = profPicDic;
  }
  // ---------------------------------------------------------------
  static getProfileInfoDictionary () : Dictionary<number, ProfileInfo> {
    // debugger;
    return this.profileInfoDictionary;
  }
  static setProfileInfoDictionary (profInfoDic : Dictionary<number, ProfileInfo>) : void {
    this.profileInfoDictionary = profInfoDic;
  }
  // ---------------------------------------------------------------
  static getProfileTileIdsDictionary () : Dictionary<number, number[]> {
    return this.profileTileIdDictionary;
  }
  static setProfileTileIdDictionary (pTileIdDic : Dictionary<number, number[]>) : void {
    this.profileTileIdDictionary = pTileIdDic;
  }
  // ---------------------------------------------------------------
  static getProfileTileDictionary () : Dictionary<number, ProfileTile> {
    return this.profileTileDictionary;
  }
  static setProfileTileDictionary (pTileDic : Dictionary<number, ProfileTile>) : void {
    this.profileTileDictionary = pTileDic;

    this.profileTileDexieDictionary = pTileDic;
    // debugger;
  }
  // ---------------------------------------------------------------
  static getSitUserDictionary () : Dictionary<number, SitUser> {
    // debugger;
    return this.sitUserDictionary;
  }
  static setSitUserDictionary (sUserDic : Dictionary<number, SitUser>) : void {
    this.sitUserDictionary = sUserDic;
  }
  // ---------------------------------------------------------------
  static getSitUserIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.sitUserIdDictionary;
  }
  static setSitUserIdDictionary (sUserDic : Dictionary<number, number[]>) : void {
    this.sitUserIdDictionary = sUserDic;
  }
  // ---------------------------------------------------------------
  // static getPhotoIdDictionary () : Dictionary<number, number[]>
  // {
  //  // debugger;
  //  return this.photoIdDictionary;
  // }
  // static setPhotoIdDictionary ( userPhIdDic : Dictionary<number, number[]> ) : void
  // {
  //  this.photoIdDictionary = userPhIdDic;
  // }
  // ---------------------------------------------------------------
  static getPhotoDictionary () : Dictionary<number, Photo> {
    // debugger;
    return this.photoDictionary;
  }
  static setPhotoDictionary (userPhDic : Dictionary<number, Photo>) : void {
    this.photoDictionary = userPhDic;
  }
  // ---------------------------------------------------------------
  static getUserPhotoBnIdDictionary () : Dictionary<number, number[]> {
    // debugger;
    return this.userPhotoBnIdDictionary;
  }
  static setUserPhotoBnIdDictionary (userPhBnIdDic : Dictionary<number, number[]>) : void {
    this.userPhotoBnIdDictionary = userPhBnIdDic;
  }

  // ---------------------------------------------------------------
  //static getPhotoBnDictionary () : Dictionary<number, PhotoBn> {
  //  // debugger;
  //  return this.photoBnDictionary;
  //}
  //static setPhotoBnDictionary (userPhBnDic : Dictionary<number, PhotoBn>) : void {
  //  this.photoBnDictionary = userPhBnDic;
  //}

  // ---------------------------------------------------------------
  static getSpinnerTimerDictionary () : Dictionary<number, SpinnerModel> {
    // debugger;
    return this.spinnerTimerDictionary;
  }
  static setSpinnerTimerDictionary (spinnerDic : Dictionary<number, SpinnerModel>) : void {
    this.spinnerTimerDictionary = spinnerDic;
  }

  /*
   * ---------------------------------------------------------------
   * End of Member(View/Profile) Dictionaries:
   * ---------------------------------------------------------------
   */

  // ---------------------------------------------------------------
  //  Note: Utility Methods for Dictionary Service:
  // ---------------------------------------------------------------
  static populateMasterErrorLogMap (bn : BoxNonceEntity) : any {
    // MemberActivity Dictionaries:
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.sitUser = EmitterSubjectService.getSitUserModel();

    if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
      // debugger;
      const mErrorLogs = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Map<any, LogError>;
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mErrorLogs) && mErrorLogs.size > 0) {
        mErrorLogs.forEach((e) => {
          this.masterErrorLogMap.set(e.key, e);
        });
        return true;
      }
    }
    return false;
  }
  // ---------------------------------------------------------------
  static populateMyErrorLogMapFromIndexedDb (errLogArr : LogError[]) : void {
    /*
     * MyErrorLog Dictionary:
     * debugger;
     */
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(errLogArr) && errLogArr.length > 0) {
      errLogArr.map((e) => {
        this.myErrorLogMap.set(e.key, e);
      });
    }
  }
  // ---------------------------------------------------------------
  // Not Used under current scheme:
  // ---------------------------------------------------------------
  static modelArrayToDictionary (modelArr : any[], modelDic : Dictionary<number, any>) : void {
    if (!!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelArr) && modelArr.length > 0) {
      modelArr.map((e) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.doerSitUserId)) {
          modelDic.set(e.doerSitUserId, e);
        } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.sitUserId)) {
          modelDic.set(e.sitUserId, e);
        }
      });
    }
  }
  // ---------------------------------------------------------------
  // Not Used under current scheme:
  // ---------------------------------------------------------------
  static modelActivityArrayToDictionary (modelArr : any[], modelDic : Dictionary<number, any>) : void {
    if (!!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelArr) && modelArr.length > 0) {
      modelArr.map((e) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.doerSitUserId)) {
          modelDic.set(e.doerSitUserId, e);
        } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.sitUserId)) {
          modelDic.set(e.sitUserId, e);
        }
      });
    }
  }
  // ---------------------------------------------------------------
  // Not Used under current scheme:
  // ---------------------------------------------------------------
  static modelArrayToMemberActivityDictionary (modelArr : any[], modelDictionary : Dictionary<number, any>) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelArr) && modelArr.length > 0) {
      modelArr.map((e) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.key)) {
          modelDictionary.set(e.key, e);
        } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.id)) {
          modelDictionary.set(e.id.toString(), e);
        }
      });
    }
  }

  // ----------------------------------------------------------------
  static populateKvPhotoOfUserInProfilePicsDictionary (data : KvPhoto, pPics : ProfilePics) : any {
    let index : number = -99;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && (data.photoId > 0 || data.photoBnId > 0)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId > 0) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.value) || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.image)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoDictionary) && pPics.unFilteredKvPhotoDictionary.size >= 0) {
            if (data.photoBnId > 0) {
              if (!pPics.unFilteredKvPhotoDictionary.has(data.photoBnId)) {
                pPics.unFilteredKvPhotoDictionary.set(data.photoBnId, data);
                // debugger;
              }
            }
          }

          if (data.photoId > 0) {
            let tPhoto = CopyServiceStatic.copyFromKvPhotoToPhoto(data);
            if (!pPics.profilePhotoDictionary.has(data.photoId)) {
              pPics.profilePhotoDictionary.set(data.photoId, tPhoto);
              // debugger;
            }
          }
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoDictionary) && pPics.filteredKvPhotoDictionary?.values?.length >= 0) {
            // debugger;
            index = ArrayServiceStatic.getIndexOfKvPhotoInKvPhotoArr(pPics.filteredKvPhotoArr, data);
            if (index !== -1) {
              pPics.filteredKvPhotoArr = ArrayServiceStatic.mergeArraysUniq(pPics.filteredKvPhotoArr, [ data ]);
            }
            else {
              pPics.filteredKvPhotoArr.push(data);
            }

            if (data.photoBnId > 0) {
              if (!pPics.filteredKvPhotoDictionary.has(data.photoBnId)) {
                pPics.filteredKvPhotoDictionary.set(data.photoBnId, data);
                // debugger;
              }
            }
          }
        }
        return pPics;
      }
      else return null;
    }
    else return null;
  }

  /*
   * ---------------------------------------------------------------
   * NOTE: This can be used as general template for
   *       all other dictionaries with case -statement:
   * ---------------------------------------------------------------
   *  TODO: Test,
   * ---------------------------------------------------------------
   */
  static updateDictionary (inModel : any, modelName : string, id? : number) : any | void {
    
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inModel) ) {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelName)) {
        // debugger;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        // debugger;
        // let model = FrequentlyUsedFunctionsServiceStatic.deepCloneJson(inModel) as any; //deepCloneJson, deepCloneJson

        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inModel)) {
          let model = inModel; // JSON.parse(JSON.stringify(inModel)); // <--deep clone
          // let modelNameLowerCased = modelName.toLowerCase();
          // debugger;
          switch (modelName.toLowerCase()) {

            // Note: This does not have a store in indexedDb:
            // ----------------------------------------------
            case 'buddy':
              this.count = 0;
              debugger;
              this.buddyDictionary.set(id, model);
              break;

            // -------------------------------------------------------------------------
            // Note: chathistoryId  must appear beore chathistory
            // -------------------------------------------------------------------------
            case 'chathistoryid':
              this.chatHistoryIdDictionary.set(id, model);
              // debugger;

              break;
            // -------------------------------------------------------------------------
            // NOTE: 
            //        if (id !== null | undefined ) { return id; }else {return model.id; } 
            //        the above expression is equal to this expression == id? id : model.id
            // -------------------------------------------------------------------------
            case 'chathistory':
              // this.chatHistoryDictionary.set(id, model);
              // debugger;
              if (model instanceof Array) {
                // debugger;
                model.map(e => {
                  this.chatHistoryDictionary.set(e.communicationActivityId, e);
                })
              }
              else if (typeof (model) === 'object') {
                // debugger;
                this.chatHistoryDictionary.set(model.communicationActivityId, model);
                // this.saveDictionaryToDbDexie( this.photoIdDictionary, 'PhotoId', this.loginSuccess.signedInUserId );
              }
              break;
            // -------------------------------------------------------------------------
            // Note: emailtileId  must appear beore emailtile
            // -------------------------------------------------------------------------
            case 'emailtileid':
              debugger;
              this.eMailTileIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'emailtile':
              debugger;
              this.eMailTileDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: enotetileId  must appear beore enotetile
            // -------------------------------------------------------------------------
            case 'enotetileid':
              // debugger;
              this.eNoteTileIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'enotetile':
              // debugger;
              this.eNoteTileDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'errorlog':
              // debugger;
              this.errorLogDictionary.set(id, model);
              break;
            // ----------------------------------------------
            case 'kvphoto':
              // debugger;
              this.kvPhotoDictionary?.set(id, model);
              break;
            // ----------------------------------------------
            case 'loginsuccess':
              // debugger;
              this.loginSuccessDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'myblock':
            case 'yourblock':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myBlockDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourBlockDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'myfriend':
            case 'yourfriend':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myFriendDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourFriendDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mylike':
            case 'yourlike':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myLikeDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourLikeDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mystar':
            case 'yourstar':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myStarDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourStarDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mytrack':
            case 'yourtrack':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myTrackDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourTrackDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mywink':
            case 'yourwink':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myTrackDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourTrackDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'myunlock':
            case 'yourunlock':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myUnlockDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourUnlockDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mymemberactivity':
            case 'yourmemberactivity':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myMemberActivityDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourMemberActivityDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mymemberlockingactivity':
            case 'yourMemberLockingActivity':
              // determine myMemberActivity and yourMemberActivity then update:
              // ---------------------------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myMemberLockingActivityDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourMemberLockingActivityDictionary.set(model.senderUserId, model);
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'mymemberstartrackwinkactivity':
            case 'yourMemberStarTrackWinkActivity':
              // determine myMemberActivity and yourMemberActivity then update:
              // --------------------------------------------------------------
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
                if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
                  this.myMemberStarTrackWinkActivityDictionary.set(model.receiverUserId, model);
                }
                else if (id == model.senderUserId) {
                  this.yourMemberStarTrackWinkActivityDictionary.set(model.senderUserId, model);
                }
              }
              break;

            // -------------------------------------------------------------------------
            // Note: mychatId  must appear beore mychat
            // -------------------------------------------------------------------------
            case 'mychatid':
              // debugger;
              this.myChatIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'mychat':
              // debugger;
              this.myChatDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: myEmailId  must appear beore myEmail
            // -------------------------------------------------------------------------
            case 'myemailid':
              // debugger;
              // this.myEmailIdDictionary.set(id, model);
              // debugger;
              let eArr : any[] = [];
              if (this.myEmailIdDictionary?.size > 0) {
                eArr = this.myEmailIdDictionary?.get(this.loginSuccess.signedInUserId) as number[];
              }
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
                if (typeof (model) === 'number') {
                  // debugger;
                  eArr = ArraySupportServiceStatic.mergeArraysUniq(eArr, [ model ]);
                  this.myEmailIdDictionary?.set(this.loginSuccess.signedInUserId, eArr);
                }
                else if (model instanceof Array) {
                  eArr = ArraySupportServiceStatic.mergeArraysUniq(eArr, model);
                  this.myEmailIdDictionary?.set(this.loginSuccess.signedInUserId, eArr);
                  // debugger;
                }
              }
              break;
            // -------------------------------------------------------------------------
            case 'myemail':
              // debugger;
              this.myEmailDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: myNotificationId  must appear beore myNotificationr
            // -------------------------------------------------------------------------
            case 'mynotificationid':
              // debugger;
              this.myNotificationIdDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'mynotification':
              // debugger;
              this.myNotificationDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: photoBnId  must appear beore photoBn
            // -------------------------------------------------------------------------
            case 'photobnid':
              // debugger;
              if (typeof (model) === 'number') {
                // debugger;
                this.userPhotoBnIdDictionary.set(id, [ model ]);
              }
              else if (model instanceof Array) {
                // debugger;
                this.userPhotoBnIdDictionary.set(id, model);
              }
              break;
            // -------------------------------------------------------------------------
            case 'photobn':
              // debugger;
              // this.photoBnDictionary.set(id, model);
              if (model instanceof Array) {
                // debugger;
                model.map(e => {
                  this.photoBnDictionary.set(e.photoBnId, e);
                })
              }
              else if (typeof (model) === 'object' || model instanceof BoxNonceEntity) {
                // debugger;
                this.photoBnDictionary.set(model.photoBnId, model);
              }
              break;

            // ---------------------------------------------------------------------------------------------------------
            case 'photo':
              // debugger;
              this.photoDictionary.set(model.id, model);
              break;
            // ---------------------------------------------------------------------------------------------------------
            case 'preference':
              // debugger;
              this.preferenceDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // Note: This does not have a store in indexedDb:
            // ----------------------------------------------
            //case 'profileimage':
            //  // debugger;
            //  this.profileImageDictionary.set(model.sitUserId, model);
            //  // this.saveDictionaryToDbDexie( this.profileImageDictionary, 'ProfileImage', this.loginSuccess.signedInUserId );
            //  break;
            // -------------------------------------------------------------------------
            case 'profileinfo':
              // debugger;
              this.profileInfoDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            case 'profilepics':
              // debugger;
              this.profilePicsDictionary.set(id, model);
              break;
            // -------------------------------------------------------------------------
            // model == number[]
            case 'profiletileid':
              // debugger;
              this.profileTileIdDictionary.set(id, model);

              // if ( typeof ( model ) === 'number' )
              // {
              //  // debugger;
              //  this.profileTileIdsDictionary.set( model, model );
              //  // this.saveDictionaryToDbDexie( this.profileTileIdDictionary, 'ProfileTileId', this.loginSuccess.signedInUserId );
              // }
              // else if ( model instanceof Array )
              // {
              //  // debugger;
              //  model.map( e =>
              //  {
              //    // debugger;
              //    this.profileTileIdsDictionary.set( e, e );
              //    // this.saveDictionaryToDbDexie( this.profileTileIdDictionary, 'ProfileTileId', this.loginSuccess.signedInUserId );
              //  } );
              // }
              break;

            // -------------------------------------------------------------------------
            case 'profiletile':
              // debugger;
              this.profileTileDictionary.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : model.id, model);     
              break;
            // -------------------------------------------------------------------------
            // Note: sitUserId  must appear beore sitUser
            // -------------------------------------------------------------------------
            // NOte: model == number[]
            case 'situserid':
              // debugger;
              let tArr : any[] = [];
              if (this.sitUserIdDictionary?.values().length > 0) {
                tArr = this.sitUserIdDictionary?.get(this.loginSuccess.signedInUserId) as number[];
              }

              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tArr)) {
                if (typeof (model) === 'number') {
                  // debugger;
                  tArr = ArraySupportServiceStatic.mergeArraysUniq(tArr, [ model ]);
                  this.sitUserIdDictionary?.set(id, tArr);
                }
                else if (model instanceof Array) {
                  tArr = ArraySupportServiceStatic.mergeArraysUniq(tArr, model);
                  this.sitUserIdDictionary?.set(id, tArr);
                  // debugger;
                }
              }
              else {
                this.sitUserIdDictionary?.set(id, model);
              }
              break;
            // -------------------------------------------------------------------------
            case 'situser':
              // debugger;
              if (model instanceof Array) {
                // debugger;

                model.map(e => {
                  // debugger;
                  this.sitUserDictionary?.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : e.id, e);
                });
              }
              else if (model) {
                if (id > 0) {
                  // debugger;
                  this.sitUserDictionary?.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : model.id, model);
                }
                else if (model.sitUserId > 0) {
                  // debugger;
                  this.sitUserDictionary?.set(model.sitUserId, model);
                }
              }
              break;

            // -------------------------------------------------------------------------
            case 'filteredkvphoto':
              // debugger;
              this.filteredKvPhotoDictionary.set(model.photoBnId, model);

              break;
            // -------------------------------------------------------------------------
            case 'unfilteredkvphoto':
              // debugger;
              this.unFilteredKvPhotoDictionary.set(model.photoBnId, model);

              break;
            // -------------------------------------------------------------------------
            case 'spinnertimer':
              // debugger;
              this.spinnerTimerDictionary.set(model.startTimer, model);
              // this.saveDictionaryToDbDexie( this.spinnerTimerDictionary, 'SpinnerTimer', this.loginSuccess.signedInUserId );

              break;
            default:
              // ;
              break;
            // -------------------------------------------------------------------------
          }
          // debugger;
          // Note: TODO: figure out if it is still in use:
          // ----------------------------------------------
          //this.count = this.allDictionaryDataCount.getValue(modelName);
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.count) && this.count >= 0) {
          //  this.count++;
          //}
          //else this.count = 1;

          //this.allDictionaryDataCount.set(modelName, this.count);
        }
      }
    }
  }
  // -------------------------------------------------------------------------------------
  static resetDictionary (modelName : string) : any | void {    
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(modelName)) {        
      // debugger;
      switch (modelName.toLowerCase()) {

        // Note: This does not have a store in indexedDb:
        // ----------------------------------------------
        case 'buddy':
          this.buddyDictionary = new Dictionary<number, MemberActivity>();
          break;

        // -------------------------------------------------------------------------
        // Note: chathistoryId  must appear beore chathistory
        // -------------------------------------------------------------------------
        case 'chathistoryid':
          this.chatHistoryIdDictionary = new Dictionary<number, number>();
          // debugger;

          break;
        // -------------------------------------------------------------------------
        // NOTE: 
        //        if (id !== null | undefined ) { return id; }else {return model.id; } 
        //        the above expression is equal to this expression == id? id : model.id
        // -------------------------------------------------------------------------
        case 'chathistory':
          // this.chatHistoryDictionary.set(id, model);
          // debugger;
          this.chatHistoryDictionary = new Dictionary<number, Chat>();
              
          break;
        // -------------------------------------------------------------------------
        // Note: emailtileId  must appear beore emailtile
        // -------------------------------------------------------------------------
        case 'emailtileid':
          debugger;
          this.eMailTileIdDictionary = new Dictionary<number, number[]>();
          break;
        // -------------------------------------------------------------------------
        case 'emailtile':
          debugger;
          this.eMailTileDictionary = new Dictionary<number, CommunicationActivity>();
          break;
        // -------------------------------------------------------------------------
        // Note: enotetileId  must appear beore enotetile
        // -------------------------------------------------------------------------
        case 'enotetileid':
          // debugger;
          this.eNoteTileIdDictionary = new Dictionary<number, number[]>();
          break;
        // -------------------------------------------------------------------------
        case 'enotetile':
          // debugger;
          this.eNoteTileDictionary = new Dictionary<number, CommunicationActivity>();
          break;
        // -------------------------------------------------------------------------
        case 'errorlog':
          // debugger;
          this.errorLogDictionary = new Dictionary<number, any>();
          break;
        // ----------------------------------------------
        case 'kvphoto':
          // debugger;
          this.kvPhotoDictionary = new Dictionary<number, KvPhoto>();
          break;
        // ----------------------------------------------
        case 'loginsuccess':
          // debugger;
          this.loginSuccessDictionary = new Dictionary<number, LoginSuccess>();
          break;
        // -------------------------------------------------------------------------
        case 'myblock':
          this.myBlockDictionary = new Dictionary<number, MemberActivity>();
          break;
        case 'yourblock':
          this.yourBlockDictionary = new Dictionary<number, MemberActivity>();
          break;
        // -------------------------------------------------------------------------
        case 'myfriend':
          this.myFriendDictionary = new Dictionary<number, MemberActivity>();              
          break;
        case 'yourfriend':
          this.yourFriendDictionary = new Dictionary<number, MemberActivity>();
          break;
        // -------------------------------------------------------------------------
        case 'mylike':
          this.myLikeDictionary = new Dictionary<number, MemberActivity>();
          break;
        case 'yourlike':
          this.yourLikeDictionary = new Dictionary<number, MemberActivity>();
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mystar':
        case 'yourstar':
          // determine myMemberActivity and yourMemberActivity then update:
          // ---------------------------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myStarDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourStarDictionary.set(model.senderUserId, model);
          //  }
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mytrack':
        case 'yourtrack':
          // determine myMemberActivity and yourMemberActivity then update:
          // ---------------------------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myTrackDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourTrackDictionary.set(model.senderUserId, model);
          //  }
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mywink':
        case 'yourwink':
          // determine myMemberActivity and yourMemberActivity then update:
          // ---------------------------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myTrackDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourTrackDictionary.set(model.senderUserId, model);
          //  }
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'myunlock':
        case 'yourunlock':
          // determine myMemberActivity and yourMemberActivity then update:
          // ---------------------------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myUnlockDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourUnlockDictionary.set(model.senderUserId, model);
          //  }
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'myMemberActivity':
        case 'yourmemberactivity':
          // determine myMemberActivity and yourMemberActivity then update:
          // ---------------------------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myMemberActivityDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourMemberActivityDictionary.set(model.senderUserId, model);
          //  }
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mymemberlockingactivity':
        case 'yourMemberLockingActivity':
          // determine myMemberActivity and yourMemberActivity then update:
          // ---------------------------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myMemberLockingActivityDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourMemberLockingActivityDictionary.set(model.senderUserId, model);
          //  }
          // }
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mymemberstartrackwinkactivity':
        case 'yourMemberStarTrackWinkActivity':
          // determine myMemberActivity and yourMemberActivity then update:
          // --------------------------------------------------------------
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
          //  if (this.loginSuccess.signedInUserId === id && id == model.receiverSitUserId) {
          //    this.myMemberStarTrackWinkActivityDictionary.set(model.receiverUserId, model);
          //  }
          //  else if (id == model.senderUserId) {
          //    this.yourMemberStarTrackWinkActivityDictionary.set(model.senderUserId, model);
          //  }
          //}
          break;

        // -------------------------------------------------------------------------
        // Note: mychatId  must appear beore mychat
        // -------------------------------------------------------------------------
        // TODO:
        case 'mychatid':
          // debugger;
          // this.myChatIdDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'mychat':
          // debugger;
          // this.myChatDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: myEmailId  must appear beore myEmail
        // -------------------------------------------------------------------------
        // TODO:
        case 'myemailid':
          // debugger;
          // this.myEmailIdDictionary.set(id, model);
          // debugger;
          //let eArr : any[] = [];
          //if (this.myEmailIdDictionary?.size() > 0) {
          //  eArr = this.myEmailIdDictionary?.getValue(this.loginSuccess.signedInUserId) as number[];
          //}
          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
          //  if (typeof (model) === 'number') {
          //    // debugger;
          //    eArr = ArraySupportServiceStatic.mergeArraysUniq(eArr, [ model ]);
          //    this.myEmailIdDictionary?.set(this.loginSuccess.signedInUserId, eArr);
          //  }
          //  else if (model instanceof Array) {
          //    eArr = ArraySupportServiceStatic.mergeArraysUniq(eArr, model);
          //    this.myEmailIdDictionary?.set(this.loginSuccess.signedInUserId, eArr);
          //    // debugger;
          //  }
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'myemail':
          // debugger;
          // this.myEmailDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: myNotificationId  must appear beore myNotificationr
        // -------------------------------------------------------------------------
        // TODO:
        case 'mynotificationid':
          // debugger;
          // this.myNotificationIdDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        case 'mynotification':
          // debugger;
          // this.myNotificationDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: photoBnId  must appear beore photoBn
        // -------------------------------------------------------------------------
        // TODO:
        case 'photobnid':
          // debugger;
          //if (typeof (model) === 'number') {
          //  // debugger;
          //  this.userPhotoBnIdDictionary.set(id, [ model ]);
          //}
          //else if (model instanceof Array) {
          //  // debugger;
          //  this.userPhotoBnIdDictionary.set(id, model);
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'photobn':
          // debugger;
          // this.photoBnDictionary.set(id, model);
          //if (model instanceof Array) {
          //  // debugger;
          //  model.map(e => {
          //    this.photoBnDictionary.set(e.photoBnId, e);
          //  })
          //}
          //else if (typeof (model) === 'object' || model instanceof BoxNonceEntity) {
          //  // debugger;
          //  // this.photoBnDictionary.set(model.photoBnId, model);
          //}
          break;

        // ---------------------------------------------------------------------------------------------------------
        // TODO:
        case 'photo':
          // debugger;
          // this.photoDictionary.set(model.id, model);
          break;
        // ---------------------------------------------------------------------------------------------------------
        // TODO:
        case 'preference':
          // debugger;
          // this.preferenceDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // Note: This does not have a store in indexedDb:
        // ----------------------------------------------
        //case 'profileimage':
        //  // debugger;
        //  this.profileImageDictionary.set(model.sitUserId, model);
        //  // this.saveDictionaryToDbDexie( this.profileImageDictionary, 'ProfileImage', this.loginSuccess.signedInUserId );
        //  break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'profileinfo':
          // debugger;
          // this.profileInfoDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'profilepics':
          // debugger;
          // this.profilePicsDictionary.set(id, model);
          break;
        // -------------------------------------------------------------------------
        // TODO:
        // model == number[]
        case 'profiletileid':
          // debugger;
          // this.profileTileIdDictionary.set(id, model);

          // if ( typeof ( model ) === 'number' )
          // {
          //  // debugger;
          //  this.profileTileIdsDictionary.set( model, model );
          //  // this.saveDictionaryToDbDexie( this.profileTileIdDictionary, 'ProfileTileId', this.loginSuccess.signedInUserId );
          // }
          // else if ( model instanceof Array )
          // {
          //  // debugger;
          //  model.map( e =>
          //  {
          //    // debugger;
          //    this.profileTileIdsDictionary.set( e, e );
          //    // this.saveDictionaryToDbDexie( this.profileTileIdDictionary, 'ProfileTileId', this.loginSuccess.signedInUserId );
          //  } );
          // }
          break;

        // -------------------------------------------------------------------------
        // TODO:
        case 'profiletile':
          // debugger;
          // this.profileTileDictionary.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : model.id, model)

          //if (this.profileTileDictionary.size() == 0 || !this.profileTileDictionary.has(model.sitUserId)) {
          //  this.profileTileDictionary.set(model.sitUserId, model);
          //}
          //else {
          //  // debugger;
          //  let dTile : ProfileTile = this.profileTileDictionary.getValue(model.sitUserId) as ProfileTile;
          //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dTile)) {
          //     // debugger;
          //    // CopyServiceStatic.copyProfileTileIfExists(dTile, model);
          //    dTile.copyIfExists(model);
          //    this.profileTileDictionary.set(dTile.sitUserId, dTile);
          //  }

          //}
          // debugger;

          // debugger;
          break;
        // -------------------------------------------------------------------------
        // Note: sitUserId  must appear beore sitUser
        // -------------------------------------------------------------------------
        // TODO:
        // NOte: model == number[]
        case 'situserid':
          // debugger;
          //let tArr : any[] = [];
          //if (this.sitUserIdDictionary?.values().length > 0) {
          //  tArr = this.sitUserIdDictionary?.getValue(this.loginSuccess.signedInUserId) as number[];
          //}

          //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tArr)) {
          //  if (typeof (model) === 'number') {
          //    // debugger;
          //    tArr = ArraySupportServiceStatic.mergeArraysUniq(tArr, [ model ]);
          //    this.sitUserIdDictionary?.set(id, tArr);
          //  }
          //  else if (model instanceof Array) {
          //    tArr = ArraySupportServiceStatic.mergeArraysUniq(tArr, model);
          //    this.sitUserIdDictionary?.set(id, tArr);
          //    // debugger;
          //  }
          //}
          //else {
          //  this.sitUserIdDictionary?.set(id, model);
          //}
          break;
        // -------------------------------------------------------------------------
        // TODO:
        case 'situser':
          // debugger;
          //if (model instanceof Array) {
          //  // debugger;

          //  model.map(e => {
          //    // debugger;
          //    this.sitUserDictionary?.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : e.id, e);
          //  });
          //}
          //else if (model) {
          //  if (id > 0) {
          //    // debugger;
          //    this.sitUserDictionary?.set(!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id) ? id : model.id, model);
          //  }
          //  else if (model.sitUserId > 0) {
          //    // debugger;
          //    this.sitUserDictionary?.set(model.sitUserId, model);
          //  }
          //}
          break;

        // -------------------------------------------------------------------------
        // TODO:
        case 'spinnertimer':
          // debugger;
         // this.spinnerTimerDictionary.set(model.startTimer, model);
          // this.saveDictionaryToDbDexie( this.spinnerTimerDictionary, 'SpinnerTimer', this.loginSuccess.signedInUserId );

          break;
        case 'filteredkvphoto':
          // debugger;
          this.filteredKvPhotoDictionary = new Dictionary<number, KvPhoto>();

          break;
        case 'unfilteredkvphoto':
          this.unFilteredKvPhotoDictionary = new Dictionary<number, KvPhoto>();

          break;
        default:
          // ;
          break;
        // -------------------------------------------------------------------------
      }
      // debugger;
      // Note: TODO: figure out if it is still in use:
      // ----------------------------------------------
      //this.count = this.allDictionaryDataCount.getValue(modelName);
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.count) && this.count >= 0) {
      //  this.count++;
      //}
      //else this.count = 1;

      //this.allDictionaryDataCount.set(modelName, this.count);
    }
  }
  // ---------------------------------------------------------------
  // TODO: comment/remove before deployment. Keep until testing.
  // ---------------------------------------------------------------
  //static updateChatHistoryDictionary ( chatArr: Chat[] ): void
  //{
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( chatArr ) && chatArr.length > 0 )
  //  {
  //    chatArr.map( e =>
  //    {
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e )
  //        && e.receiverSitUserId === this.loginSuccess.signedInUserId // make sure that I am the recipient
  //        && this.chatHistoryDictionary.has( e.senderSitUserId ) ) // Note: dictionary key==senderSitUserId
  //      {
  //        this.chatHistoryDictionary.remove( e.senderSitUserId );
  //      }
  //      this.chatHistoryDictionary.set( e.senderSitUserId, e );
  //    } );
  //  }
  //}
  // ---------------------------------------------------------------
  // Intend to deprecate.
  // ---------------------------------------------------------------
  static updateMyChatDictionary ( chatarr : any ): void
  {
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( chatarr ) && chatarr.length > 0)
    {
      let chatArr = JSON.parse(chatarr);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatArr) && chatArr.length > 0) {
        for (var i = 0; i < chatArr.length; i++) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(chatArr[ i ])
            && chatArr[ i ].receiverSitUserId === this.loginSuccess.signedInUserId // make sure that I am the recipient
            && this.myChatDictionary.has(chatArr[ i ].senderSitUserId)) // Note: dictionary key==senderSitUserId
          {
            this.myChatDictionary.remove(chatArr[ i ].senderSitUserId);
          }
          this.myChatDictionary.set(chatArr[ i ].senderSitUserId, chatArr[ i ]);
        };
      }
    }
  }
  // ---------------------------------------------------------------
  // static updateMyEmailTileDictionary ( commActArr: CommunicationActivity[] ): void
  // {
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commActArr ) && commActArr.length > 0 )
  //  {
  //    // debugger;
  //    commActArr.map( e =>
  //    {
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e.communicationType )
  //        && ( e.communicationType.toLowerCase().indexOf( 'email' ) !== -1) // TODO: check the magic-string
  //        && ( e.receiverSitUserId === this.loginSuccess.signedInUserId // make sure that I am the recipient
  //        || e.senderSitUserId === this.loginSuccess.signedInUserId)) // or sender
  //      {

  //        // if ( this.myEmailTileDictionary.has( e.senderSitUserId ) ) // Note: dictionary key==senderSitUserId
  //        // {
  //        //  // debugger;
  //        //  this.myEmailTileDictionary.remove( e.senderSitUserId );
  //        // }
  //        // debugger;
  //        this.myEmailTileDictionary.set( e.senderSitUserId, e );
  //        // debugger;
  //      }
  //    } );
  //  }
  // }
  // ---------------------------------------------------------------
  // static updateMyNotificationDictionary ( commActArr: CommunicationActivity[] ): void
  // {
  //  // debugger;
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( commActArr ) && commActArr.length > 0 )
  //  {
  //    commActArr.map( e =>
  //    {
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e )
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( e.communicationType )
  //        && ( e.communicationType.toLowerCase().indexOf( 'note' ) !== -1
  //          || e.communicationType.toLowerCase().indexOf( 'notification' ) !== -1 ) // TODO: check the magic-string
  //        && e.receiverSitUserId === this.loginSuccess.signedInUserId )// make sure that I am the recipient
  //      {
  //        if (this.myNotificationDictionary.has( e.senderSitUserId ) ) // Note: dictionary key==senderSitUserId
  //        {
  //          // debugger;
  //          let dictEnote = this.myNotificationDictionary.getValue( e.senderSitUserId ) as CommunicationActivity;
  //          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dictEnote ) && dictEnote.communicationSubType.toLowerCase().indexOf( e.communicationSubType.toLowerCase() ) !== -1 )
  //          {
  //            // debugger;
  //            this.myNotificationDictionary.remove( e.senderSitUserId );
  //          }
  //        }
  //        // debugger;
  //        this.myNotificationDictionary.set( e.senderSitUserId, e );
  //      }
  //    } );
  //  }
  // }
  /*
 * ---------------------------------------------------------------
 * ===============================================================
 *  BoxNonceEntity(Bn) Dictionaries:
 * ---------------------------------------------------------------
 */
  static getPreferenceDicBn () : Dictionary<number, BoxNonceEntity>
  {
    return this.preferenceBnDictionary;
  }
  static setPreferenceDictionaryBn ( prefDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    this.preferenceBnDictionary = prefDic;
  }
  // ---------------------------------------------------------------
  // static getPhotoBnArrDictionarytionary () : Dictionary<number, BoxNonceEntity[]>
  // {
  //  return this.photoBnArrDictionary;
  // }
  // static setPhotoBnArrDictionarytionary ( profPicDic : Dictionary<number, BoxNonceEntity[]> ) : void
  // {
  //  this.photoBnArrDictionary = profPicDic;
  // }
  // ---------------------------------------------------------------
  static getProfileInfoDictionaryBn () : Dictionary<number, BoxNonceEntity>
  {
    return this.profileInfoBnDictionary;
  }
  static setProfileInfoDictionaryBn ( profInfoDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    this.profileInfoBnDictionary = profInfoDic;
  }
  // ---------------------------------------------------------------
  static getProfileTileIdDictionaryBn () : Dictionary<number, BoxNonceEntity>
  {
    return this.profileTileIdBnDictionary;
  }
  static setProfileTileIdDictionaryBn ( pTileIdDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    this.profileTileIdBnDictionary = pTileIdDic;
  }
  // ---------------------------------------------------------------
  static getProfileTileDictionaryBn () : Dictionary<number, BoxNonceEntity>
  {
    return this.profileTileBnDictionary;
  }
  static setProfileTileDictionaryBn ( pTileDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    this.profileTileBnDictionary = pTileDic;
  }
 
  // ---------------------------------------------------------------
  //static populateBnDictionariesFromIndexedDb (): void
  //{
  //  // debugger;
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  this.indexedDbService.getAllStoreData( 'Preference', this.loginSuccess.signedInUserId.toString()).then( modelArr =>
  //  {
  //    this.modelArrayToDictionary( modelArr, this.preferenceDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('ProfileInfo', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //      this.modelArrayToDictionary( modelArr, this.profileInfoDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('ProfilePics', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //      this.modelArrayToDictionary( modelArr, this.profilePicsDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('ProfileTile', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //    this.modelArrayToDictionary( modelArr, this.profileTileDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('ProfileTileId', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //    this.modelArrayToDictionary( modelArr, this.profileTileIdsDictionary );
  //  } );
  //  this.indexedDbService.getAllStoreData('SitUser', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //    this.modelArrayToDictionary( modelArr, this.sitUserDictionary );
  //  } );
  //  // this.indexedDbService.getAllStoreData( 'PhotoId', this.loginSuccess.signedInUserId.toString()  ).then( modelArr =>{
  //  //  this.modelArrayToDictionary( modelArr, this.photoIdDictionary );
  //  // } );
  //  this.indexedDbService.getAllStoreData('Photo', this.loginSuccess.signedInUserId.toString() ).then( modelArr => {
  //    this.modelArrayToDictionary( modelArr, this.photoDictionary );
  //  } );
  //  // this.indexedDbService.getAllStoreData( 'PhotoBnId', this.loginSuccess.signedInUserId.toString()  ).then( modelArr =>{
  //  //  this.modelArrayToDictionary( modelArr, this.photoBnIdDictionary );
  //  // } );
  //  this.indexedDbService.getAllStoreData('PhotoBn', this.loginSuccess.signedInUserId.toString() ).then( modelArr =>{
  //    this.modelArrayToDictionary( modelArr, this.photoBnDictionary );
  //  } );

  //  // debugger;
  //}
  // ---------------------------------------------------------------
  static modelArrayToBnDictionary ( modelArr : any[], modelDic : Dictionary<number, BoxNonceEntity> ) : void
  {
    if ( !!modelArr && modelArr.length > 0 )
    {
      modelArr.map( ( e ) =>
      {
        modelDic.set( e.sitUserId, e );
      } );
    }
  }
  // ---------------------------------------------------------------------------
  // private inInitialize (): void
  // {
  //  DictionaryServiceStatic.httpService = new HttpService(this.httpClient, this.router);
  //  DictionaryServiceStatic.photoPrivacyService = new PhotoPrivacyService(DictionaryServiceStatic.httpService);
  // }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    /*
     * prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *       (2) https:// www.concretepage.com/angular-2/angular-4-renderer2-example
     *       (3) https:// stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *       (4) https:// stackblitz.com/edit/timer-with-pdestroyed
     */
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    /*
     * this.timerArray.forEach(timer => clearInterval(timer));
     * debugger;
     */
  }
  // ---------------------------------------------------------------
}

// =================================================================

  // ---------------------------------------------------------------
  // savePhotoIdsInDictionaryAndIndexedDb ( sitUserId: number, result: number[] ) : void
  // {
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) && result.length > 0 )
  //  {
  //    this.userPhotoIds = result;
  //    EmitterSubjectService.setUserPhotoIds( this.userPhotoIds );

  //    DictionaryServiceStatic.updateDictionary( this.userPhotoIds, 'UserPhotoId', sitUserId );

  //    this.indexedDbToDictionaryService
  //      .saveDictionaryToDbDexie(
  //        DictionaryServiceStatic.photoIdDictionary, 'UserPhotoId', this.loginSuccess.signedInUserId );

  //    // since successfully unsalted UserPhotoIds, we can save the BoxNonce of it in indexedDb:
  //    // --------------------------------------------------------------------------------------
  //    // this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt( result.toString() );
  //    // this.boxNonceEntity.box = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box );
  //    // this.boxNonceEntity.nonce = FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce );
  //    // this.boxNonceEntity.date = DateStringServiceStatic.getTicks( new Date() ).toString();
  //    // this.boxNonceEntity.id = sitUserId;
  //    // this.boxNonceEntity.key = this.boxNonceEntity.id.toString();
  //    // this.boxNonceEntity.entityName = 'PhotoId';
  //    // this.boxNonceEntity.sitUserId = sitUserId;
  //    // debugger;
  //    // this.triageAllDataForIndexedDbAndDictionaryService.savePhotoIdDictionaryToIndexedDb( this.boxNonceEntity );

  //    // debugger; // TODO: check to see the values
  //    // return this.userPhotoIds;
  //    // debugger;
  //  }
  // }



