
import { Injectable, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { EmitOffOnResult } from '../../models/common/emitOffOnResult.model';
import { Photo } from '../../models/common/photo.model';
import { KvAction } from '../../models/keyValue/kvAction.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { Preference } from '../../models/profile/preference.model';
import { ProfileImage } from '../../models/profile/profileImage.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { ImageResizerService } from '../commonServiceService/imageResizerService.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { RendererService } from '../rendererServiceService/rendererService.service';
import { BreadcrumServiceStatic } from '../staticServices/breadcrumServiceStatic.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { DefaultSetterServiceStatic } from '../staticServices/defaultSetterServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';

import { MemberServiceInterface } from '../../interfaces/serviceInterfaces/memberServicesInterfaces/memberServiceInterface';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { RedirectionService } from '../commonServiceService/redirectionService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
import { Communication } from '../../models/communication/communication.model';

@Injectable({
  providedIn: 'any',
})
export class MemberService implements OnDestroy {
  public boxNonceEntity: BoxNonceEntity = new BoxNonceEntity();
  public emitterDestroyed$: Subject<boolean> = new Subject();
  public isFormSubmitted = false;
  public isTileInitialized = false;
  public isMobile = false;
  public isMouseIn = false;
  public isOnLine = false;
  public isLoaded = false;
  public kvPhotoLock: KvPhoto = new KvPhoto();
  public kvPhotoUnLock: KvPhoto = new KvPhoto();
  public kvPhotoPrivate: KvPhoto = new KvPhoto();
  public kvPhotoPublic: KvPhoto = new KvPhoto();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public messages: string[] = [];
  
  // UserData-letiables:
  public preference: Preference = new Preference();
  public profileInfo: ProfileInfo = new ProfileInfo();
  public profilePics: ProfilePics = new ProfilePics();
  public renderer!: Renderer2;
  public sitUser: SitUser = new SitUser();
  public sitUserIdArr: number[] = [];
  public tempElem!: HTMLElement;
  public timer: any;
  public timerArray: any[] = [];

  constructor (
    public dictionaryService : DictionaryService,
    public httpService : HttpService,
    public imageResizeService : ImageResizerService,
    public redirectionService: RedirectionService,
    public rendererService: RendererService,
    public router: Router,
  ) {
    this.initialize();
  }
  // ---------------------------------------------------------------
  initialize(): void {
    // debugger;
    this.renderer = this.rendererService.getRenderer();

    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // --------------------------------------------------------
    EmitterSubjectService.loginSuccessEmitter.subscribe((result) => {
      this.loginSuccess = result;
      // this.executeLoginSuccessTasks();
      this.emitterDestroyed$.next( true );
      this.emitterDestroyed$.complete();
      this.emitterDestroyed$.unsubscribe();
    });
    // --------------------------------------------------------
    this.isLoaded = true;
    /*
     * --------------------------------------------------------
     * debugger;
     * --------------------------------------------------------
     */
    EmitterSubjectService.photoLockUnLockEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      const kvPhoto = result as KvPhoto;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.cryptUserKey)) {
        if (kvPhoto.key.toLowerCase().indexOf('tala') !== -1) {
          this.kvPhotoLock = kvPhoto;
        } else if (kvPhoto.key.toLowerCase().indexOf('unlock') !== -1) {
          this.kvPhotoUnLock = kvPhoto;
        } else if (kvPhoto.key.toLowerCase().indexOf('private') !== -1) {
          this.kvPhotoPrivate = kvPhoto;
        } else if (kvPhoto.key.toLowerCase().indexOf('public') !== -1) {
          this.kvPhotoPublic = kvPhoto;
        }
        }
    });
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.timerArray.forEach((timer) => clearInterval(timer));
    return true;
  }
  /*
   * =====================All Getter-Setters of vailables are placed hare===========
   * ---------------------------------------------------------------
   */
  getMessages(): string[] {
    return this.messages;
  }
  // ---------------------------------------------------------------
  getSitUserIds(): number[] {
    return this.sitUserIdArr;
  }
  // ---------------------------------------------------------------
  resetMessages(): void {
    this.messages = [];
  }
  // --------------------------------------------------------------
  setMessage(msg: string): void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(msg)) {
      this.messages.push(msg);
    }
  }

  // -----------------------------------------------------------
  nullPromise () : any
  {
    this.timer = setTimeout( () =>
    {
      clearTimeout(this.timer);
      return 'undefined'; 
    }, 500);
    clearTimeout(this.timer);
  }

  /*
   * --------------------------------------------------------------
   * Promise for ProfileImage/DefaultImage
   * xxx-new-dictionary-based-computation:
   * --------------------------------------------------------------
   */
  processProfileImagePromise(pTile: ProfileTile): Promise<any> {
    let imageUrl;

    return new Promise((resolve) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.primaryImage)) {
          /*
           * debugger;
           * setUrlForImageData(pTile.image); //normalizeImageData(pTile.image);
           */
          imageUrl = JsRegExpServiceStatic.normalizeImageData(pTile.primaryImage);
        } else {
          /*
           * debugger;
           * imageUrl = '/photos/avatar3.png';
           */
          imageUrl = 'url(\'/photos/avatar3.png\')';
        }
        // ====================================

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageUrl)) {
          pTile.primaryImage = imageUrl;
        }
        // debugger;
        resolve(pTile as ProfileTile);
      }
    });
  }
  // ---------------------------------------------------------------
  public clearGlyphiconMenueOnDifferentMember (isOnExit : boolean) : any {
    // -------------------------------------------------------------------------------------
    // Note:  if current-view-member is signedInUser, and the next page is myphotos,
    //        then save the profilePics in EmitterSubjectService and profilePicsDictionary
    // -------------------------------------------------------------------------------------
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
    }

    // -------------------------------------------------//
    // Back-to-Back-memberView-pges-recognition-system: //
    // -------------------------------------------------//
    if (this.redirectionService.isSignedInUserPagesBackToBack()) {
      EmitterSubjectService.setProfilePics(this.profilePics);

      // TODO: if necessary display the glyphicon menu here: 
      // if (!isOnExit) {
      //  EmitterSubjectService.emitDisplayProfileView(this.profilePics);
      // }
      // debugger;

      // -------------------------------------------------------------------------------------
      // Note:  There is only one profilePicsDictionary that is not persisted to the indexedDb:
      //        and this dictionary should contain only signedInUser's profilePics..
      // -------------------------------------------------------------------------------------
      this.dictionaryService.profilePicsDictionary.set(this.loginSuccess.signedInUserId, this.profilePics);
    }
    // if the signedInUser jumps from myPhotos to myProfileView or vice-versa, we do not clear the userData, but otherwise we do
    else {
      // debugger;

      DefaultSetterServiceStatic.clearUserData();
      EmitterSubjectService.emitClearGlyphiconMenu(true); // will clear the kvGlyphArr on PagerServiceVerticalComponent
    }

  }
  // ---------------------------------------------------------------
  public clearUserDataOnDifferentMember (isOnExit : boolean) : any {
    // -------------------------------------------------------------------------------------
    // Note:  if current-view-member is signedInUser, and the next page is myphotos,
    //        then save the profilePics in EmitterSubjectService and myProfilePicsDictionary
    // -------------------------------------------------------------------------------------
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
    }

    // -------------------------------------------------//
    // Back-to-Back-memberView-pges-recognition-system: //
    // -------------------------------------------------//
    if (this.redirectionService.isSignedInUserPagesBackToBack()) {
      EmitterSubjectService.setProfilePics(this.profilePics);

      if (!isOnExit) {
        EmitterSubjectService.emitDisplayProfileView(this.profilePics);
      }
      // debugger;

      // -------------------------------------------------------------------------------------
      // Note:  There is only one profilePicsDictionary that is not persisted to the indexedDb:
      //        and this dictionary should contain only signedInUser's profilePics..
      // -------------------------------------------------------------------------------------
      this.dictionaryService.profilePicsDictionary.set(this.loginSuccess.signedInUserId, this.profilePics);
    }
    // if the signedInUser jumps from myPhotos to myProfileView or vice-versa, we do not clear the userData, but otherwise we do
   else {
      // debugger;

      DefaultSetterServiceStatic.clearUserData();
    }

  }
  
  // ----------------------------------------------------------------
  executeBackToBackMemberViewMyPhotosPages (): void {
    // Note:  if nextpage of view-member is signedInUser, and the current page is myphotos,
    //        then save the profilePics to EmitterSubjectService and myProfilePicsDictionary
    // -------------------------------------------------------------------------------------
    // -------------------------------------------------------------------------------------
    // Note:  if current page is page is myphotos of signedInUser, and the next page is 
    //        memberView of the signedInUser, then save the profilePics
    //        in EmitterSubjectService and myProfilePicsDictionary
    // -------------------------------------------------------------------------------------
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
    }

    // ---------------------------------------------------//
    // Back-to-Back-memberView-pges-recognition-system:   //
    // TODO:Converge all of these Back-to-Back..system    //
    //      into a single location of RedirectinService/  //
    //      or ModuleComponentLoaderServiceStatic.        //
    // ---------------------------------------------------//
    let prevPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 2 ].componentName;
    let currentPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 1 ].componentName;
    if ((currentPage.toLowerCase().indexOf('myphotos') !== -1   // if current page is myPhotos
      && prevPage.toLowerCase().indexOf('mymeemberviewmgmt') !== -1)   // and if previous page was memberView
      ||
      (currentPage.toLowerCase().indexOf('mymeemberviewmgmt') !== -1  // if current page is memberView
        && prevPage.toLowerCase().indexOf('myphotos') !== -1)   // and if previous page was myPhotos
    ) {
      EmitterSubjectService.setProfilePics(this.profilePics);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoArr) && this.profilePics.unFilteredKvPhotoArr.length > 0) {
        EmitterSubjectService.emitCurrentPage(this.profilePics.unFilteredKvPhotoArr[ 0 ]);
        // EmitterSubjectService.emitDisplayGlyphMenuForKvPhotoMgmtPage(this.profilePics.filteredKvPhotoArr[ 0 ]); // no listener of this emitter
      }
      // debugger;

      // -------------------------------------------------------------------------------------
      // Note:  There is only one profilePicsDictionary that is not persisted to the indexedDb:
      //        and this dictionary should contain only signedInUser's profilePics..
      // -------------------------------------------------------------------------------------
      this.dictionaryService.profilePicsDictionary.set(this.loginSuccess.signedInUserId, this.profilePics);
    }
    else {
      // debugger;
      let currentP : any;
      let currentKvp = EmitterSubjectService.getCurrentKvPhoto();
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvp)) {
        currentP = EmitterSubjectService.getCurrentPhoto();
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentP)) {
          currentKvp = CopyServiceStatic.copyFromPhotoToKvPhoto(currentP);
        }
      }
      EmitterSubjectService.emitCurrentPage(currentKvp);
    }
  }
  // ------------------------------------------------------------------------------------------------
  /*
   * -----------------------------------------------------------
   * Note : Server-sent userPhoto's properties are in UpperCase letter
   *     We convert them to lowerCase letter using the copyUserPhotoIfExists().
   * -----------------------------------------------------------
   */
  addUpdateUserPhoto(userPhotos: Photo[], userPhoto: any): Photo[] {
    let isFound = false;
    let tPhoto: any;

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userPhotos)) {
      userPhotos = [];
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userPhoto)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(userPhotos) && userPhotos.length > 0) {
        userPhotos.map((e) => {
          if (e.photoId === userPhoto.PhotoId) {
            isFound = true;
            e = CopyServiceStatic.copyFromServerPhotoToClientPhoto(e, userPhoto);
            // debugger;
          }
        });
        if (!isFound) {
          tPhoto = new Photo();
          tPhoto = CopyServiceStatic.copyFromServerPhotoToClientPhoto(tPhoto, userPhoto);
          // debugger;
          userPhotos.push(tPhoto);
        }
      } else {
        tPhoto = new Photo();
        tPhoto = CopyServiceStatic.copyFromServerPhotoToClientPhoto(tPhoto, userPhoto);
        // debugger;
        userPhotos.push(tPhoto);
      }
    }
    return userPhotos;
  }
  // -----------------------------------------------------------
  //addUpdateProfileImage(profileImages: ProfileImage[], profileImage: ProfileImage): ProfileImage[] {
  //  let isFound = false;

  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImages)) {
  //    profileImages = [];
  //  }
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImage)) {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImages) && profileImages.length >= 0) {
  //      profileImages.map((e) => {
  //        if (e.photoId === profileImage.photoId) {
  //          isFound = true;
  //          e = CopyServiceStatic.copyProfileImageIfExists(e, profileImage);
  //          // debugger;
  //        }
  //      });
  //      // debugger;
  //      if (!isFound) {
  //        profileImages.push(profileImage);
  //      }
  //    } else {
  //      // debugger;
  //      profileImages.push(profileImage);
  //    }
  //  }
  //  return profileImages;
  //}

  // -----------------------------------------------------------
  addUpdateProfileKvPhotoArr(profileKvPhotoArr: KvPhoto[], kvPhoto: KvPhoto): KvPhoto[] {
    let isFound = false;

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileKvPhotoArr)) {
      profileKvPhotoArr = [];
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileKvPhotoArr) && profileKvPhotoArr.length >= 0) {
        profileKvPhotoArr.map((e) => {
          if (e.photoId === kvPhoto.photoId) {
            isFound = true;
            e = CopyServiceStatic.copyKvPhotoIfExists(e, kvPhoto);
            // debugger;
          }
        });
        // debugger;
        if (!isFound) {
          profileKvPhotoArr.push(kvPhoto);
        }
      } else {
        // debugger;
        profileKvPhotoArr.push(kvPhoto);
      }
    }
    return profileKvPhotoArr;
  }
  // --------------------------------------------------------------
  // TODO: inestigate if in user or can be used in future
  // --------------------------------------------------------------
  determineMemberAction(memberAction: KvAction): any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberAction)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberAction.value)) {
        // debugger;
        switch (memberAction.value.toLowerCase()) {
          /*
           * TODO : figure out which array the action will go to as well as action-specific return value such as 'isUnlcoked';
           * case 'block':
           * return true;
           */
          case 'like':
            this.loginSuccess.likedArr.push(memberAction);
            return true;
          /*
           * case 'tala':
           * return true;
           */
          case 'track':
            this.loginSuccess.trackedArr.push(memberAction);
            return true;
          /*
           * case 'unblock':
           * return true;
           */
          case 'unlock':
            this.loginSuccess.unlockedArr.push(memberAction);
            return true;
        }
      }
    }
    else return false;
  }
  
  // ---------------------------------------------------------------
  getImageTagId(pTile: ProfileTile): string {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile)) {
      let elemId : any;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.pElemId) && pTile.pElemId.length > 0) {
        elemId = pTile.pElemId.toString();
      } else if (pTile.index > -1) {
        elemId = pTile.index.toString();
      }
      return elemId;
    }
    else return '';
  }
  // ---------------------------------------------------------------
  getIsUnlocked (sitUserId : number) : Observable<any> {
    var url = 'Member/IsUnlocked'
    var commModel : Communication = new Communication();
    var kvAction : KvAction = new KvAction();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (sitUserId > 0) {
      commModel.sitUserId = sitUserId;
      commModel.signedInUserId = this.loginSuccess.signedInUserId
    }
    this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(commModel);

    return new Observable<any>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        // debugger;

        this.httpService.postObservable(url,
          {
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
          }, 'json2text')
          .subscribe((result) => {
            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
              kvAction = SlakezSaltServiceStatic.boxUnsalt(result) as KvAction;
              // debugger;
              subscriber.next(kvAction);
            }
            else {
              // debugger;
              subscriber.next(null);
            }
            // subscriber.complete();
          })
      }
    })
  }
  /*
   * ---------------------------------------------------------------
   * UserData getters and setters:
   * Note : will be used to determine if user's profile is complete or not.
   * ---------------------------------------------------------------
   */
  getPreference(): Preference {
    return this.preference;
  }
  setPreference(value: Preference): any {
    this.preference = value;
    return true;
  }
  // ---------------------------------------------------------------
  getProfileInfo(): ProfileInfo {
    return this.profileInfo;
  }
  setProfileInfo(value: ProfileInfo): any {
    this.profileInfo = value;
    return true;
  }
  // ---------------------------------------------------------------
  getProfilePics(): ProfilePics {
    return this.profilePics;
  }
  setProfilePics(value: ProfilePics): any {
    this.profilePics = value;
    return true;
  }
  // ---------------------------------------------------------------
  isLinkSet(pTile: ProfileTile): boolean {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pTile.link)) {
      const parts = pTile.link.split('-');

      if (parts.length > 0) {
        const sitId = parseInt(parts[1], 10);

        if ( sitId > 0 )
        {
          return true;
        }
        else return false;
      }
      else return false;
    }
    else return false;
  }
  // ---------------------------------------------------------------
  isProfileComplete (sitUserId : any): boolean {
    let isComplete = false;

    if (sitUserId > 0) {
      if (
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference) &&
        this.preference.sitUserId === sitUserId &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo) &&
        this.profileInfo.sitUserId === sitUserId &&
        !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics) &&
        this.profilePics.sitUserId === sitUserId
      ) {
        // debugger;
        let isPrefComplete = false;
        let isProfInfoComplete = false;
        let isProfPicsComplete = false;

        if (
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.hobbies) &&
          this.preference.hobbies.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.intos) &&
          this.preference.intos.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.lookingFors) &&
          this.preference.lookingFors.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.meetingLocations) &&
          this.preference.meetingLocations.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.pets) &&
          this.preference.pets.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.petPeeves) &&
          this.preference.petPeeves.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference.whenLookingToMeets) &&
          this.preference.whenLookingToMeets.length > 0
        ) {
          // debugger;
          isPrefComplete = true;
        }
        if (
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.gender) &&
          this.profileInfo.gender.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.highestEducation) &&
          this.profileInfo.highestEducation.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.relationshipStatus) &&
          this.profileInfo.relationshipStatus.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.religion) &&
          this.profileInfo.religion.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.sexuality) &&
          this.profileInfo.sexuality.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.smokingStatus) &&
          this.profileInfo.smokingStatus.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.aBstract) &&
          this.profileInfo.aBstract.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.contents) &&
          this.profileInfo.contents.length > 0 &&
          !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo.heading) &&
          this.profileInfo.heading.length > 0
        ) {
          // debugger;
          isProfInfoComplete = true;
        }
        //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.profileImageArr) && this.profilePics.profileImageArr.length > 0) {
        //  isProfPicsComplete = true;
        //}
        if (isPrefComplete && isProfInfoComplete && isProfPicsComplete) {
          // debugger;
          isComplete = true;
        }
      }
    }
    // debugger;
    return isComplete;
  }
  
  // ---------------------------------------------------------------
  salt (model : any): BoxNonceEntity {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(model)) {
      // debugger;
      return (this.boxNonceEntity = SlakezSaltServiceStatic.boxSalt(JSON.stringify(model)));
    }
    else
    {
      return new BoxNonceEntity();
    }
  }
  // --------------------------------------------------------------
  setLink ( tTile : ProfileTile ) : EmitOffOnResult
  {
    let offOnResult !: any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tTile)) {
      if (tTile.sitUserId > 0 && !this.isLinkSet(tTile)) {
        // debugger;
        offOnResult = new EmitOffOnResult();

        offOnResult.link = 'profile/view/sitUser-' + tTile.sitUserId;
        // this will be dom-element-id which will be used to render profileImage
        offOnResult.pTileElemId = 's-' + tTile.sitUserId;
        return offOnResult;
      }
      else
      {
        return new EmitOffOnResult();
      }
    }
    else
    {
      return new EmitOffOnResult();
    }
  }
  // ----------------------------------------------------------------
  serializeMessages(msgArr: string[]): string {
    let message = '';

    msgArr.map((e) => {
      message = ' <br/> ' + message + ' <br/> ' + e.replace(')  : ==>', '):') + ' <br/> ';
    });
    return (this.message = message);
  }
  /*
   * --------------------------------------------------------------
   * this.renderer.setStyle(this.tempElem, 'background-image', this.getdbgi(pTile));
   * --------------------------------------------------------------
   */
  setThisImage(elemId: string, src: string): boolean {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elemId)) {
      const tElem = document.getElementById(elemId) as HTMLElement;

      this.tempElem = document.getElementById(elemId) as HTMLElement;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(src)) {
        this.renderer.setStyle(tElem, 'background', src);
        tElem.remove();
        return true;
      } else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.tempElem) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(src)) {
        this.renderer.setStyle(this.tempElem, 'background', src);
        this.tempElem.remove();
        return true;
      }
      return false;
    }
    return false;
  }
  
  // -----------------------------------------------------------
  //sortProfileImageArr(items: any[]): any[] {
  //  items.sort((a: any, b: any) => this.findDiffOf(a, b));
  //  // debugger;
  //  return items;
  //}
  // ---------------------------------------------------------------
  findDiffOf(a: any, b: any): any {
    if (a && a.photoId && b && b.photoId) {
      return a.photoId - b.photoId; // sort it in ascending order
    } else if (a && a.date && b && b.date) {
      return parseInt(a.date, 10) - parseInt(b.date, 10);
    } else if (a && a.index && b && b.index) {
      return parseInt(a.index, 10) - parseInt(b.index, 10);
    }
  }

  // -----------------------------------------------------------
  uniquelyMergeKvPhotoArrays(dKvPhotos: KvPhoto[], sKvPhotos: KvPhoto[]): KvPhoto[] {
    let outKvPhotos: KvPhoto[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dKvPhotos) && dKvPhotos.length > 0) {
      outKvPhotos = dKvPhotos;
      sKvPhotos.map((e) => {
        outKvPhotos.map((f) => {
          if (e.key.toString().toLowerCase().indexOf(f.key.toString().toLowerCase()) !== -1) {
            CopyServiceStatic.copyKvPhotoIfExists(f, e);
          } else {
            outKvPhotos.push(e);
          }
        });
      });
    } else {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sKvPhotos) && sKvPhotos.length > 0) {
        outKvPhotos = sKvPhotos;
      }
    }
    return outKvPhotos;
  }
  // -----------------------------------------------------------
  //uniqueMergeProfileImageArrays(dProfileImages: ProfileImage[], sProfileImages: ProfileImage[]): ProfileImage[] {
  //  // sort and unique-merge (no-duplicate-merge)
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sProfileImages) && sProfileImages.length > 0) {
  //    sProfileImages = this.sortProfileImageArr(sProfileImages);
  //    // debugger;
  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dProfileImages)) {
  //      dProfileImages = sProfileImages;
  //      return dProfileImages;
  //    }
  //    let isFound = false;
  //    let index = -1;
  //    let i = 0;

  //    sProfileImages.map((e) => {
  //      dProfileImages.map((f) => {
  //        if (e.photoId === f.photoId) {
  //          isFound = true;
  //          index = i;
  //        }
  //        i++;
  //      });
  //      // debugger;
  //      if (!isFound) {
  //        dProfileImages.push(e);
  //      } else {
  //        dProfileImages[index] = CopyServiceStatic.copyProfileImageIfExists(dProfileImages[index], e);
  //      }
  //    });
  //    // debugger;
  //    return dProfileImages;
  //  }
  //  // sProfilePics is just image-data
  //  const tProfileImage = new ProfileImage();

  //  tProfileImage.image = sProfileImages[0].image.toString();
  //  dProfileImages.push(tProfileImage);
  //  return dProfileImages;
  //}
  // -----------------------------------------------------------
  //addUpdateProfileImageToProfilePics(profilePics: ProfilePics, profileImage: ProfileImage): ProfilePics {
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)) {
  //    profilePics = new ProfilePics();
  //  }
  //  let tArr: any;

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.profileImageArr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImage)) {
  //    tArr = profilePics.profileImageArr;
  //    tArr.map((e : any) => {
  //      if (profileImage.photoId === e.photoId) {
  //        e.date = profileImage.date;
  //        e.image = profileImage.image;
  //        e.imageFile = profileImage.imageFile;
  //        e.imageBytes = profileImage.imageBytes;
  //        e.isPrimary = profileImage.isPrimary;
  //        e.privacy = profileImage.privacy;
  //        e.profileId = profileImage.profileId;
  //        e.sitUserId = profileImage.sitUserId;
  //      } else {
  //        profilePics.profileImageArr.push(profileImage);
  //      }
  //    });
  //  }
  //  return profilePics;
  //}
  
  // -----------------------------------------------------------
  // Note : this method is not so desirable because it lacks a photoId
  // -----------------------------------------------------------
   
  //addImageToProfileImageArr(image: string, profileImageArr: ProfileImage[]): ProfileImage[] {
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr)) {
  //    profileImageArr = [];
  //  }

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(image)) {
  //    const pImage = new ProfileImage();

  //    pImage.image = image;
  //    if (profileImageArr.length > 0) {
  //      pImage.profileId = profileImageArr[0].profileId;
  //      pImage.sitUserId = profileImageArr[0].sitUserId;
  //      pImage.sitUserKey = profileImageArr[0].sitUserKey;
  //      profileImageArr.push(pImage);
  //    } else {
  //      profileImageArr.push(pImage);
  //    }
  //  }
  //  return profileImageArr;
  //}
  // -----------------------------------------------------------

  //addPhotoToProfileImageArr (p : Photo, profileImageArr : ProfileImage[]) : ProfileImage[] {
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileImageArr)) {
  //    profileImageArr = [];
  //  }
  //  const pImage = new ProfileImage();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p.imageString)) {
  //    pImage.image = p.imageString;
  //  }
  //  else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(p.imageBytes) && p.imageBytes.length > 0) {
  //    p.imageString = this.imageResizeService.createImageFromByteArray(new Uint8Array(p.imageBytes));
  //    pImage.image = p.imageString;
  //  }
  //  if (profileImageArr.length > 0) {
  //    pImage.profileId = profileImageArr[ 0 ].profileId;
  //    pImage.sitUserId = profileImageArr[ 0 ].sitUserId;
  //    pImage.sitUserKey = profileImageArr[ 0 ].sitUserKey;
  //    profileImageArr.push(pImage);
  //  } else {
  //    profileImageArr.push(pImage);
  //  }
    
  //  return profileImageArr;

  //}
  /*
   * -----------------------------------------------------------
   * End of Uniquely-Merge-ProfilePics-System
   * -----------------------------------------------------------
   */
  unSalt (model : any): any {
    // debugger;
    if (model) {
      return SlakezSaltServiceStatic.boxUnsalt(model);
    }
    return null;
  }
  // ==============================================================
  //  BEGIN of Members methods:
 
  // ---------------------------------------------------------------
  // Note: This has been moved to PhotoService and renamed to getPrimaryPhoto!!
  // --------------------------------------------------------------
  // getUserPrimaryPhoto (sitUserId : number) : Promise<any> {
  //  // debugger;

  //  const bnComm : Communication = new Communication();

  //  bnComm.sitUserId = sitUserId;
  //  this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)) {
  //    return new Promise((resolve, reject) => {
  //      // debugger;
  //      this.httpService.post('/api/Photo/GetUserPrimaryPhoto',
  //        {
  //          box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
  //          nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
  //        },
  //        'json',
  //      ).subscribe((result) => {
  //         debugger;
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
  //          this.boxNonceEntity = new BoxNonceEntity();
  //          this.boxNonceEntity.box = result.box;
  //          this.boxNonceEntity.nonce = result.nonce;

  //          let tempPhoto;
  //          /*
  //            * do not directly assing to profileTile!! capture it in a let first
  //            */
  //          let unboxedPhoto = SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity);
  //          debugger;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unboxedPhoto)) {
  //            tempPhoto = unboxedPhoto as Photo;
  //          }

  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempPhoto)) {
  //            tempPhoto = JSON.parse(tempPhoto); // convert string to model
  //            debugger;
  //          }

  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tempPhoto)) {
  //            resolve(tempPhoto);
  //          }
  //        }
  //        else {
  //          reject(null); //message for result being null 
  //        }
  //      });
  //    });
  //  }
  //  else {
  //    return this.nullPromise();
  //  }
  // }
  
  // ---------------------------------------------------------------
  // -----------------------------------------------------------
  // End of Creating-MemberTile-from-ProfileTile-System
  // -----------------------------------------------------------
  // ---------------------------------------------------------------
  // Note : This method will get 100 SitUserIds at a time
  //        This method is been moved to SitUserService
  // TODO:  Delete before deployment!!!
  // ---------------------------------------------------------------
  // getBatchOfSitUserIds (beginIndex : number) : Promise<any>
  // {
  //  EmitterSubjectService.emitStartSpinner('Getting profile ids....');
  //  // debugger;
  //  let batchOfSitUserIds = [];
  //  return new Promise((resolve, reject) =>
  //  {
  //    if ( beginIndex > -1 )
  //    {
  //      const bnComm : Communication = new Communication();

  //      bnComm.beginIndex = beginIndex;
  //      bnComm.sitUserId = 0;
  //      bnComm.signedInUserId = this.loginSuccess.signedInUserId;
  //      // debugger;
  //      this.boxNonceEntity = this.salt( bnComm );

  //      // debugger;
  //      if ( this.boxNonceEntity )
  //      {
  //        this.httpService.post( '/api/Member/GetBatchOfSitUserIds', JSON.stringify( {
  //          box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
  //          nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
  //        } ),
  //          'json',
  //        ).pipe(takeUntil(this.emitterDestroyed$))
  //         .subscribe((result) =>
  //        {
  //          batchOfSitUserIds = this.processBatchOfMemberIdsResult(result);
  //           DictionaryServiceStatic.updateDictionary(batchOfSitUserIds, 'sitUserId', this.loginSuccess.signedInUserId);

  //           // Note: the sitUserId is saved on the indexedDb by the caller method:
  //           // -------------------------------------------------------------------
  //           this.indexedDbToDictionaryService
  //            .saveDictionaryToDbDexie(
  //              DictionaryServiceStatic.sitUserDictionary, 'sitUserId', this.loginSuccess.signedInUserId);

  //         resolve( batchOfSitUserIds );
  //          // debugger;
  //        }, ( error ) =>
  //        {
  //          // debugger;
  //          this.message = 'Error occured in GetBatchOfUserIds();\n Error-msg : ' + error.message;
  //          console.log( this.message );
  //          // EmitterSubjectService.emitMyErrorLog( this.message );
  //          // debugger;
  //          reject(this.message);
  //        } );
  //      }
  //    }
  //    else return this.nullPromise();
  //  } );
  // }
  // ---------------------------------------------------------------
  // Note : This method is been moved to SitUserService
  //        
  // TODO:  Delete before deployment!!!
  // ---------------------------------------------------------------
  // processBatchOfMemberIdsResult ( result : any ) : any
  // {
  //  let tBatchOfIds: number[] = [];
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
  //  {
  //    const bn = result;
  //    tBatchOfIds = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(result as BoxNonceEntity)) as number[];
  //    // debugger;
  //  }
  //  return tBatchOfIds;
  // }
  // ---------------------------------------------------------------

   
  // ---------------------------------------------------------------
  // --------------------------------------------------------------
  // ==============================================================
  //  END of Members methods:
  // ==============================================================
  // --------------------------------------------------------------
  // ==============================================================
  //  BEGIN of MemberTile methods:
  // ==============================================================

 
  // ==============================================================
  //  END of MemberTile methods:
  // ==============================================================
  // --------------------------------------------------------------
  // ---------------------------------------------------------------
}
