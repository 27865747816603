
export class DexieBne {
  public box: any;
  public key = '';
  public id = 0;
  public nonce : any;
  public entityName : string = '';
  constructor() {
  }
}
