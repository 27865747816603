'use-strict'
import { Directive, HostBinding, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appExpandMenu]',
  exportAs: 'appExpandMenu',
})
export class ExpandMenuDirective {
  @HostBinding('class.active') isOpen = false;
  constructor() { }

  @HostListener('click') toggleOpen() {
    this.isOpen = !this.isOpen;
  }
}
// ref:https://medium.com/@alok.lko631/submenu-or-dropdown-menu-without-jquery-in-angular-2-4-5-6-using-only-bootstrap-and-custom-fd716db511be
