'use strict'
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';
import { Communicator } from '../../models/communication/communicator.model';
import { KV } from '../../models/keyValue/kv.model';
import { HttpService } from '../coreServiceService/httpService.service';
import { MemberService } from '../memberServiceService/memberService.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Injectable({
  providedIn: 'any',
})
// ------------------------------------------------------------------
export class CommunicatorService {
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public commActivity : CommunicationActivity = new CommunicationActivity();
  public distUnit = '';
  public isMobilevar = false;
  public isOnLine : false;
  public kv : KV;
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public otherCommunicator : Communicator = new Communicator();
  public rootScopeTs : Set<KV>;
  public signedInCommunicator : Communicator = new Communicator();
  constructor (
    private httpService : HttpService,
    private memberService : MemberService,
    // private inMemoryDataService: InMemoryDataService //public quickSort: Quicksort
  ) {
  }

  // ---------------------------------------------------------------
  // Note:  GetChatCommunicator does not get the PrimaryImage and PrimaryImageId
  //        but the rest of Communicator data
  // ---------------------------------------------------------------
  public getCommunicatorWithoutImage (sitUserid : any) : Observable<any> | any {
    var sitUserId = sitUserid;
    return new Observable<any>(subscriber => {
      if (sitUserId > 0) {
        // debugger;
        this.fetchCommunicatorWithGeoLocation(sitUserId, 'api/Communicator/GetCommunicatorWithoutImage').subscribe(data => {
          // debugger;
          subscriber.next(data);
          subscriber.complete();
        })
        // this.sendCommunicatorWithoutGeoLocation();
      }
      else {
        subscriber.next(null);
        subscriber.complete();
      }
    })
  }
  // ---------------------------------------------------------------
  // Note:  GetEmailEnoteCommunicator will get the complete Communicator data.
  // ---------------------------------------------------------------
  public getCommunicatorWithImage (commCator : Communicator) : Observable<any> | any {
    // debugger;
    var communicator = commCator;
    return new Observable<any>(subscriber => {
      if (communicator.sitUserId > 0 && communicator.communicationActivityId > 0) {
        // debugger;
        this.fetchCommunicatorWithGeoLocation(communicator, '/api/Communicator/GetCommunicator').subscribe(data => {
          // debugger;
          subscriber.next(data);
          // subscriber.complete();
        })
        // this.sendCommunicatorWithoutGeoLocation();
      }
      else {
        subscriber.next(null);
        // subscriber.complete();
      }
    })
  }
  // ---------------------------------------------------------------
  //  Note: communication is always from the prospective of the signedInUser.
  //        therefore, the receiver in most cases is the signedInUser.
  // ---------------------------------------------------------------
  public fetchCommunicatorWithGeoLocation (communiCator : Communicator, uRl: any) : Observable<any> {

    var url = uRl;
    var tCommCator = communiCator;
    var unsalted : any;
    var bn : BoxNonceEntity;

    // debugger;
    return new Observable<any>(subscriber => {
      if (tCommCator.sitUserId > 0) {
        
        // debugger;

        if (navigator.geolocation)
        {
          navigator.geolocation.getCurrentPosition((position : any) => {
            if (position) {
              console.log('Latitude: ' + position.coords.latitude + '; Longitude: ' + position.coords.longitude);
              tCommCator.latitude = position.coords.latitude;
              tCommCator.longitude = position.coords.longitude;
            }
          });

          // debugger;
          this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(tCommCator);

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            // debugger;
            this.httpService.postObservable(url, JSON.stringify({
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            }), 'json2text')
              .subscribe(result => {
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result))
                {
                  // debugger;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.box) && result.box.length > 0
                    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.nonce) && result.nonce.length > 0) {
                    unsalted = SlakezSaltServiceStatic.boxUnsalt(result) as unknown;
                    // debugger;
                    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unsalted)) {
                      tCommCator = JSON.parse(unsalted) as Communicator;
                      // debugger;
                      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tCommCator)) {
                        // debugger;
                        subscriber.next(tCommCator);
                        subscriber.complete();
                        // debugger;
                      }
                    }

                  }
                }
                else {
                  this.message = 'Error occured in GetCommunicator(sitUserId:"' + tCommCator.sitUserId + '");\n result: ' + result;
                  console.log(this.message);
                  return this.sendCommunicatorWithoutGeoLocation(tCommCator.sitUserId, url);
                  // EmitterSubjectService.emitMyErrorLog(this.message);

                }
              }, error => {
                this.message = 'Error occured in GetCommunicator(sitUserId:"' + tCommCator.sitUserId + '");\n<br/> Error-message: ' + error.message;
                console.log(this.message);
                // EmitterSubjectService.emitMyErrorLog(this.message);
              });
          }
          else {
            // debugger;
            return this.sendCommunicatorWithoutGeoLocation(tCommCator.sitUserId, url);
          }
        }
      }
    });
  }
  // ---------------------------------------------------------------
  // TODO:Test
  public sendCommunicatorWithoutGeoLocation (sitId : any, uRl : any) : Observable<any> | any {
    var sitUserId = sitId;
    var url = uRl;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    return new Observable<any>(subscriber => {
      if (sitUserId > 0) {
        let commCator : Communicator = new Communicator();
        commCator.sitUserId = sitUserId;

        // debugger;
        this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(commCator);

        if (this.isOnLine && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
          this.httpService.postObservable(url, JSON.stringify({
            box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
            nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce)
          }), 'json2text')
            .subscribe(result => {
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                debugger;
                let bn = result;
                if (bn && bn.box.length > 0 && bn.nonce.length > 0) {
                  let tCommCator = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as Communicator;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commCator)) {
                    if (tCommCator.sitUserId === this.commActivity.signedInUserId) {
                       debugger;
                      this.commActivity.sender = CopyServiceStatic.copyCommunicatorIfExists(this.commActivity.sender, tCommCator);
                    }
                    else {
                      this.commActivity.receiver = CopyServiceStatic.copyCommunicatorIfExists(this.commActivity.receiver, tCommCator);
                      this.commActivity.receiverSitUserId = this.commActivity.receiver.sitUserId;
                      debugger;
                    }
                    subscriber.next(this.commActivity);
                  }
                }
              }
              else {
                this.message = 'Error occured in GetCommunicator(sitUserId:' + sitUserId + ');\n<br/> result: ' + result;
                console.log(this.message);
                EmitterSubjectService.emitMyErrorLog(this.message);
              }
            }, error => {
              this.message = 'Error occured in GetCommunicator(sitUserId:"' + sitUserId + '");\n<br/> Error-message: ' + error.message;
              console.log(this.message);
              EmitterSubjectService.emitMyErrorLog(this.message);
            });
        }
      }
       
    })
  }
}
