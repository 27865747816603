'use-strict'
import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { EmitRadioboxResult } from '../../../models/common/emitRadioboxResult.model';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
/*
 * ref:1) https:// www.positronx.io/angular-checkbox-tutorial/
 * ref:2) https:// www.sitepoint.com/replacing-radio-buttons-without-replacing-radio-buttons/
 */

@Component({
  selector: 'app-radio-box',
  templateUrl: './radioBox.component.html',
  styleUrls: ['./radioBox.component.scss'],
})
export class RadioBoxComponent implements OnInit, OnDestroy {
  public askMe = 'Ask Me :)';
  @Input() radioboxArr: string[] = [];
  @Input() radioboxLegend = '';
  @Input() radioboxName = '';
  @Input() isFormSubmitted!: boolean;
  @Input() isRequired = false;
  public radioboxForm!: FormGroup;
  public radioboxResult: EmitRadioboxResult = new EmitRadioboxResult();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar!: boolean;
  public loginSuccess!: LoginSuccess;
  public selectedRadiobox!: FormControl;
  @Input() selectedRadioIndex = -1;
  // ---------------------------------------------------------------
  constructor(
    private fb: FormBuilder,
    private router: Router,
    @Inject(DOCUMENT) document: any,
    private coreServiceService: CoreServiceService,
  ) {
    if (this.isRequired) {
      this.radioboxForm = this.fb.group({
        /*
         * 'radioSelection': this.fb.control(this.radioboxName, [
         * Validators.required])
         */
      });
    }
    /*
     * else this.radioboxForm = this.fb.group({
     * 'radioSelection': this.fb.control(this.radioboxName)
     * });
     */
    this.radioboxResult.radioboxName = this.radioboxName;
  }
  ngOnInit() {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }
  // ---------------------------------------------------------------
  getIndex(i: number) {
    // debugger;
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// www.positronx.io/angular-checkbox-tutorial/
   */
  onRadioboxChange (e : any, i:any) {
    // debugger;
    if (e.target.checked)
    {
      this.radioboxResult.index = i;
      this.radioboxResult.radioboxName = this.radioboxName;
      this.radioboxResult.radioboxSelection = e.target.value;
      // debugger;
      this.radioboxResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
      EmitterSubjectService.emitRadioboxResult(this.radioboxResult);
    }
  }
  // ---------------------------------------------------------------
  isRequiredPassed() {
    return this.radioboxResult.radioboxSelection.length > 0;
  }
  // ---------------------------------------------------------------
}
