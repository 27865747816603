'use-strict'
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImageUploadModuleComponent } from './component/imageUploadModule.component';
import { FileUploadComponent } from './fileUpload/fileUpload.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { UploadFilesComponent } from './uploadFiles/uploadFilesComponent.component';

const imageUploadroutes : Routes = [
  {
    path: '',
    // component: ImageUploadModuleComponent,
    children: [
      { path: 'fileUp/:id', component: FileUploadComponent },
      { path: 'imageUpload/:id', component: ImageUploadComponent },
      { path: 'uploadFiles/:id', component: UploadFilesComponent },
    ]
  }
];

@NgModule({
  imports: [ RouterModule.forChild( imageUploadroutes)],
  exports: [RouterModule]
})
export class ImageUploadRoutingModule { }
