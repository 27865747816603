import { CommunicationActivity } from './communicationActivity.model';
import { EnoteBox } from './enoteBox.model';

export class MailBox {
  public enoteBox : EnoteBox = new EnoteBox();
  public boxType = '';
  public communicationId = 0;
  public isInbox = true;
  public myEmails: CommunicationActivity[] = [];
  public myInboxEmails: CommunicationActivity[] = [];
  public mySavedEmails: CommunicationActivity[] = [];
  public mySentEmails: CommunicationActivity[] = [];
  public myTrashedEmails: CommunicationActivity[] = [];


  public myAllEnotes : CommunicationActivity[] = [];
  public myBuddyEnotes : CommunicationActivity[] = [];
  public myDeletedEnotes : CommunicationActivity[] = [];
  public myFriendEnotes : CommunicationActivity[] = [];
  public myLikeEnotes : CommunicationActivity[] = [];
  public myStarEnotes : CommunicationActivity[] = [];
  public myTrackEnotes : CommunicationActivity[] = [];
  public myUnlockEnotes : CommunicationActivity[] = [];
  public myWinkEnotes : CommunicationActivity[] = [];

  public sitUserId = 0;
  public signedInUserId = 0;

  public inboxTotal = 0;
  public inboxNewTotal = 0;
  public sentTotal = 0;
  public sentNewTotal = 0;
  public savedTotal = 0;
  public savedNewTotal = 0;
  public deletedNewTotal = 0;

  public allTotal = 0;
  public buddyTotal = 0;
  public deletedTotal = 0;
  public friendTotal = 0;
  public likeTotal = 0;
  public starTotal = 0;
  public trackTotal = 0;
  public unlockTotal = 0;
  public winkTotal = 0;

  public allNewTotal = 0;
  public newBuddyTotal = 0;
  public newDeletedTotal = 0;
  public newFriendTotal = 0;
  public newLikeTotal = 0;
  public newStarTotal = 0;
  public newTrackTotal = 0;
  public newUnlockTotal = 0;
  public newWinkTotal = 0;
  constructor() { }
}
