import { FR } from 'angular-mydatepicker';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ProfilePics } from './profilePics.model';
import { Unit } from '../common/unit.model';

export class ProfileInfo {
  public age = 0;
  public aBstract = '';
  public away = '';
  public box : any;
  public city = '';
  public contentId = 0;
  public contents = '';
  public countryCode = '';
  public cryptUserKey = '';
  public cutUncut = '';
  public date = '';
  public dickSize = '';
  public distance : any;
  public distanceStr = '';
  public distanceUnit: Unit = new Unit();
  public dob: any;
  public email = '';
  public eyes = '';
  public ethnicity = '';
  public gender = '';
  public hair = '';
  public heading = '';
  public height = 0;
  public heightStr = '';
  public heartbeatTime = '';
  public highestEducation = '';
  public image = '';
  public isOnline = false;
  public isServer = false;
  public isProfileComplete = false;
  public isSuccess = false;
  public link = '';
  public longitude = '';
  public latitude = '';
  public memberId = 0;
  public message = '';
  public name = '';
  public neighborhood = '';
  public nonce : any;
  public pagedProfilePics: string[] = [];
  public photoId = 0;
  public photoBnId = 0;
  public primaryPhotoBnId = 0;
  public position = '';
  public profileId = 0;
  public profileName = '';
  public profilePics: ProfilePics = new ProfilePics();
  public regionCode = '';
  public relationshipStatus = '';
  public religion = '';
  public sexuality = '';
  public signedInUserId = 0;
  public signedInUserKey = '';
  public sitUserId = 0;
  public sitUserKey = '';
  public SITUserId = 0;
  public SITUserKey = '';
  public smokingStatus = '';
  public unit = '';
  public weight = 0;
  public weightStr = '';

  constructor () { }

  copy (a : ProfileInfo): void {
    this.age = a.age;
    this.aBstract = a.aBstract;
    this.away = a.away;
    this.box = a.box;
    this.city = a.city;
    this.contentId = a.contentId;
    this.contents = a.contents;
    this.countryCode = a.countryCode;
    this.cryptUserKey = a.cryptUserKey;
    this.cutUncut = a.cutUncut;
    this.dickSize = a.dickSize;
    this.distance = a.distance;
    this.distanceStr = a.distanceStr;
    this.distanceUnit = a.distanceUnit;
    this.dob = a.dob;
    this.email = a.email;
    this.eyes = a.eyes;
    this.ethnicity = a.ethnicity;
    this.gender = a.gender;
    this.hair = a.hair;
    this.heading = a.heading;
    this.heartbeatTime = a.heartbeatTime;
    this.highestEducation = a.highestEducation;
    this.height = a.height;
    this.image = a.image;
    this.isOnline = a.isOnline;
    this.isServer = a.isServer;
    this.isProfileComplete = a.isProfileComplete;
    this.isSuccess = a.isSuccess;
    this.link = a.link;
    this.longitude = a.longitude;
    this.latitude = a.latitude;
    this.memberId = a.memberId;
    this.message = a.message;
    this.name = a.name;
    this.neighborhood = a.neighborhood;
    this.nonce = a.nonce;
    this.pagedProfilePics  = a.pagedProfilePics;
    this.photoId = a.photoId;
    this.photoBnId = a.photoBnId;
    this.position = a.position;
    this.primaryPhotoBnId = a.primaryPhotoBnId;
    this.profileId = a.profileId;
    this.profileName = a.profileName;
    this.profilePics  = a.profilePics;
    this.regionCode = a.regionCode;
    this.relationshipStatus = a.relationshipStatus;
    this.religion = a.religion;
    this.sexuality = a.sexuality;
    this.signedInUserId = a.signedInUserId;
    this.signedInUserKey = a.signedInUserKey;
    this.sitUserId = a.sitUserId;
    this.sitUserKey = a.sitUserKey;
    this.SITUserId = a.sitUserId;
    this.SITUserKey = a.sitUserKey;
    this.smokingStatus = a.smokingStatus;
    this.unit = a.unit;
    this.weight = a.weight;
  }
// ---------------------------------------
  public copySourceToDestination (d : ProfileInfo, a : ProfileInfo) : ProfileInfo {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(a)) {
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(d)) {
        d = new ProfileInfo();
      }
      d.age = a.age;
      d.aBstract = a.aBstract;
      d.away = a.away;
      d.box = a.box;
      d.city = a.city;
      d.contentId = a.contentId;
      d.contents = a.contents;
      d.countryCode = a.countryCode;
      d.cryptUserKey = a.cryptUserKey;
      d.cutUncut = a.cutUncut;
      d.dickSize = a.dickSize;
      d.distance = a.distance;
      d.distanceStr = a.distanceStr;
      d.distanceUnit = a.distanceUnit;
      d.dob = a.dob;
      d.email = a.email;
      d.eyes = a.eyes;
      d.ethnicity = a.ethnicity;
      d.gender = a.gender;
      d.hair = a.hair;
      d.heading = a.heading;
      d.heartbeatTime = a.heartbeatTime;
      d.highestEducation = a.highestEducation;
      d.height = a.height;
      d.image = a.image;
      d.isOnline = a.isOnline;
      d.isServer = a.isServer;
      d.isProfileComplete = a.isProfileComplete;
      d.isSuccess = a.isSuccess;
      d.link = a.link;
      d.longitude = a.longitude;
      d.latitude = a.latitude;
      d.memberId = a.memberId;
      d.message = a.message;
      d.name = a.name;
      d.neighborhood = a.neighborhood;
      d.nonce = a.nonce;
      d.pagedProfilePics = a.pagedProfilePics;
      d.photoId = a.photoId;
      d.photoBnId = a.photoBnId;
      d.position = a.position;
      d.primaryPhotoBnId = a.primaryPhotoBnId;
      d.profileId = a.profileId;
      d.profileName = a.profileName;
      d.profilePics = a.profilePics;
      d.regionCode = a.regionCode;
      d.relationshipStatus = a.relationshipStatus;
      d.religion = a.religion;
      d.sexuality = a.sexuality;
      d.signedInUserId = a.signedInUserId;
      d.signedInUserKey = a.signedInUserKey;
      d.sitUserId = a.sitUserId;
      d.sitUserKey = a.sitUserKey;
      d.SITUserId = a.sitUserId;
      d.SITUserKey = a.sitUserKey;
      d.smokingStatus = a.smokingStatus;
      d.unit = a.unit;
      d.weight = a.weight;
      return d;
    }
  }
// ---------------------------------------
  copyIfExists (a : ProfileInfo) {
    this.age = a.age > 0 ? a.age : this.age;
    this.aBstract = a.aBstract?.length > 0 ? a.aBstract: this.aBstract;
    this.away = a.away?.length > 0 ? a.away : this.away
    this.box = a.box?.length > 0 ? a.box : this.box;
    this.city = a.city?.length > 0 ? a.city : this.city;
    this.contentId = a.contentId > 0 ? a.contentId : this.contentId;
    this.contents = a.contents?.length > 0 ? a.contents : this.contents;
    this.countryCode = a.countryCode?.length > 0 ? a.countryCode : this.countryCode
    this.cryptUserKey = a.cryptUserKey?.length > 0 ? a.cryptUserKey: this.cryptUserKey;
    this.cutUncut = a.cutUncut?.length > 0 ? a.cutUncut : this.cutUncut
    this.dickSize = a.dickSize?.length > 0 ? a.dickSize : this.dickSize;
    this.distance = a.distance > 0 ? a.distance : this.distance;
    this.distanceStr = a.distanceStr?.length > 0 ? a.distanceStr : this.distanceStr;
    this.distanceUnit = a.distanceUnit !== null  ? a.distanceUnit : this.distanceUnit;
    this.dob = a.dob?.length > 0 ? a.dob : this.dob;
    this.email = a.email?.length > 0 ? a.email : this.email;
    this.eyes = a.eyes?.length > 0 ? a.eyes : this.eyes;
    this.ethnicity = a.ethnicity?.length > 0 ? a.ethnicity : this.ethnicity;
    this.hair = a.hair?.length > 0 ? a.hair : this.hair;
    this.heartbeatTime = a.heartbeatTime?.length > 0 ? a.heartbeatTime : this.heartbeatTime;
    this.highestEducation = a.highestEducation?.length > 0 ? a.highestEducation : this.highestEducation;
    this.height = a.height > 0 ? a.height : this.height;
    this.image = a.image?.length > 0 ? a.image : this.image;
    this.isOnline = a.isOnline;
    this.isServer = a.isServer;
    this.isProfileComplete = a.isProfileComplete;
    this.isSuccess = a.isSuccess;
    this.link = a.link?.length > 0 ? a.link : this.link;
    this.longitude = a.longitude;
    this.latitude = a.latitude;
    this.memberId = a.memberId;
    this.message = a.message?.length > 0 ? a.message : this.message;
    this.name = a.name?.length > 0 ? a.name : this.name;
    this.neighborhood = a.neighborhood?.length > 0 ? a.neighborhood : this.neighborhood;
    this.nonce = a.nonce?.length > 0 ? a.nonce : this.nonce;
    this.pagedProfilePics = a.pagedProfilePics?.length > 0 ? a.pagedProfilePics : this.pagedProfilePics;
    this.photoId = a.photoId;
    this.photoBnId = a.photoBnId;
    this.position = a.position?.length > 0 ? a.position : this.position;
    this.primaryPhotoBnId = a.primaryPhotoBnId > 0 ? a.primaryPhotoBnId : this.primaryPhotoBnId;
    this.profileId = a.profileId;
    this.profileName = a.profileName?.length > 0 ? a.profileName : this.profileName;
    this.profilePics = a.profilePics !==  null ? a.profilePics : this.profilePics;
    this.regionCode = a.regionCode?.length > 0 ? a.regionCode : this.regionCode;
    this.relationshipStatus = a.relationshipStatus?.length > 0 ? a.relationshipStatus : this.relationshipStatus;
    this.religion = a.religion?.length > 0 ? a.religion : this.religion;
    this.sexuality = a.sexuality?.length > 0 ? a.sexuality : this.sexuality;
    this.signedInUserId = a.signedInUserId;
    this.signedInUserKey = a.signedInUserKey?.length > 0 ? a.signedInUserKey : this.signedInUserKey;
    this.sitUserId = a.sitUserId;
    this.sitUserKey = a.sitUserKey?.length > 0 ? a.contents : this.sitUserKey
    this.SITUserId = a.sitUserId;
    this.SITUserKey = a.sitUserKey?.length > 0 ? a.sitUserKey : this.SITUserKey;
    this.smokingStatus = a.smokingStatus?.length > 0 ? a.smokingStatus : this.smokingStatus;
    this.unit = a.unit?.length > 0 ? a.unit : this.unit;
    this.weight = a.weight;
  }
}
