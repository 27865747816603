import { Heartbeat } from '../common/heartbeat.model';

export class SitUser {
  public callerName = '';
  public cryptUserKey = '';
  public date = '';
  public email = '';
  public fullName = '';
  public heartbeat : Heartbeat = new Heartbeat();
  public id = 0;
  public isEmailConfirmed = false;
  public isPremiumMembership = false;
  public longitude = '';
  public latitude = '';
  public passwordHash = '';
  public primaryPhotoBnId = 0;
  public profileName = '';
  public primaryImage = ''; 
  public profileId = 0;
  public primaryPhotoBnJson = '';
  public sitUserId = 0;
  public sitUserKey = '';
  public signedInUserId = 0;
  public signedInUserKey = '';
  public upgradeExpDate = '';
  public userName = '';
  constructor() { }

  copy(from: SitUser): any {
    return () => {
      this.cryptUserKey = from.cryptUserKey;
      this.date = from.date;
      this.email = from.email;
      this.fullName = from.fullName;
      this.heartbeat = from.heartbeat;
      this.id = from.id;
      this.isEmailConfirmed = from.isEmailConfirmed;
      this.latitude = from.latitude;
      this.longitude = from.longitude;
      this.passwordHash = from.passwordHash;
      this.profileId = from.profileId;
      this.profileName = from.profileName;
      this.primaryPhotoBnId = from.primaryPhotoBnId;
      this.primaryPhotoBnJson = from.primaryPhotoBnJson;
      this.sitUserId = from.sitUserId;
      this.sitUserKey = from.sitUserKey;
      this.userName = from.userName;
      this.signedInUserId = from.signedInUserId;
      this.signedInUserKey = from.signedInUserKey;

    };
  }
  copyIfExists(from: SitUser): any {
    return () => {
      this.cryptUserKey = from.cryptUserKey ? from.cryptUserKey : this.cryptUserKey;
      this.date = from.date ? from.date : this.date;
      this.email = from.email ? from.email : this.email;
      this.fullName = from.fullName ? from.fullName : this.fullName;
      this.heartbeat = from.heartbeat;
      this.id = from.id > 0 ? from.id : this.id;
      this.isEmailConfirmed = from.isEmailConfirmed;
      this.latitude = from.latitude  ? from.latitude : this.latitude;
      this.longitude = from.longitude ? from.longitude : this.longitude;
      this.passwordHash = from.passwordHash ? from.passwordHash : this.passwordHash;
      this.profileId = from.profileId > 0 ? from.profileId : this.profileId;
      this.profileName = from.profileName ? from.profileName : this.profileName;
      this.primaryPhotoBnId = from.primaryPhotoBnId > 0 ? from.primaryPhotoBnId : this.primaryPhotoBnId; // 
      this.primaryPhotoBnJson = from.primaryPhotoBnJson ? from.primaryPhotoBnJson : this.primaryPhotoBnJson;
      this.sitUserId = from.sitUserId > 0 ? from.sitUserId : this.sitUserId;
      this.sitUserKey = from.sitUserKey ? from.sitUserKey : this.sitUserKey;
      this.userName = from.userName ? from.userName : this.userName;
      this.signedInUserId = from.signedInUserId;
      this.signedInUserKey = from.signedInUserKey?.length > 0 ? from.signedInUserKey : this.signedInUserKey;
    };
  }
  // --------------------------------------
  copyTo (from : SitUser) : any {
    let to = new SitUser();
    to.copy(from);
    return to;
  }
  // --------------------------------------
  toString(): string {
    return (
      '{id:' +
      this.id +
      'isEmailConfirmed:' +
      this.isEmailConfirmed +
      ',' +
      'date:' +
      this.date +
      ',' +
      'fullName:' +
      this.fullName +
      ',' +
      'latitude:' +
      this.latitude +
      ',' +
      'longitude:' +
      this.longitude +
      ',' +
      'cryptUserKey:' +
      this.cryptUserKey +
      ',' +
      'email:' +
      this.email +
      ',' +
      'passwordHash:' +
      ',' +
      'profileId:' +
      this.profileId +
      ',' +
      'profileName:' +
      this.profileName +
      ',' +
      'primaryImage:' +
      this.primaryImage +
      ',' +
      'primaryPhotoBnId:' +
      this.primaryPhotoBnId +
      ',' +
      'primaryPhotoBnId:' +
      this.primaryPhotoBnJson +
      ',' +
      'sitUserId:' +
      this.sitUserId +
      ',' +
      'sitUserKey:' +
      this.sitUserKey +
      ',' +
      'signedInUserId:' +
      this.signedInUserId +
      ',' +
      'signedInUserKey:' +
      this.signedInUserKey +
      ',' +
      'userName:' +
      this.userName +
      '}'
    );
  }
}
