import { Heartbeat } from '../common/heartbeat.model';
import { PhotoBn } from '../common/photoBn.model';
import { KvAction } from '../keyValue/kvAction.model';
import { SitUser } from './sitUser.model';

export class LoginSuccess {
  public action = '';
  public blogId = 0;
  public businessKey = '';
  public code = '';
  public controller = '';
  public cryptUserKey = '';
  public date : '';
  public email = '';
  public heading = '';
  public gender = '';
  public hasJoinedSignalR = false;
  public heartbeat: Heartbeat = new Heartbeat();
  public id = 0;
  public imagePath = '';
  public isAuthorized = false;
  public isEditProfile = false;
  public isEmailConfirmed = false;
  public isEmployee = false;
  public isForgotPassword = false;
  public isLoggedIn = false;
  public isLogout = false;
  public isMgmt = false;
  public isOnLine = false;
  public isPic = false;
  public isPreScreenComplete = false;
  public isProfileComplete = false;
  public isProfileInfoComplete = false;
  public isPreferenceComplete = false;
  public isPremium = false;
  public isProfileContentComplete = false;
  public isReviewer = false;
  public isSuccess = false;
  public isSuperUser = false;
  public isValued = false;
  public iv = '';
  public key = '';
  public latitude = 0;
  public likedArr: KvAction[] = []; // others who liked me
  public longitude = 0;
  public membership : any = '';
  public message : any = '';
  public myBlockedArr: KvAction[] = []; // whom I blocked
  public myBuddyArr: KvAction[] = []; // whom I am buddied with, meaning we liked each other
  public myLikedArr: KvAction[] = []; // whom I liked
  public myTrackedArr: KvAction[] = []; // who I tracked
  public myUnlockedArr : KvAction[] = []; // who unlocked for me
  public premiumExpiryDate = '';
  public primaryImage = ''; // Note: on view it is renamed as 'primaryImage'; primaryImage==raw-image-data
  public primaryImageUrl = ''; // "url('" + primaryImage + "')";
  public primaryPhotoBnId = 0;
  public primaryPhotoBnJson : any;
  public profileName = '';
  public returnUrl = '';
  public sexuality = '';
  public signedInUserId = 0;
  public signedInUserKey = '';
  public trackedArr: KvAction[] = []; // others who tracked me
  public unlockedArr: KvAction[] = []; // others who unlocked for me

  constructor() { }

  public reset(): any {
    this.action = '';
    this.blogId = 0;
    this.businessKey = '';
    this.code = '';
    this.controller = '';
    this.email = '';
    this.heading = '';
    this.isAuthorized = false;
    this.isEmailConfirmed = false;
    this.isEmployee = false;
    this.isForgotPassword = false;
    this.isLoggedIn = false;
    this.isMgmt = false;
    this.isOnLine = false;
    this.isPic = false;
    this.isReviewer = false;
    this.isSuperUser = false;
    this.imagePath = '';
    this.iv = '';
    this.id = 0;
    this.latitude = 0;
    this.longitude = 0;
    this.likedArr = []; // who liked me
    this.myBlockedArr = []; // whom I blocked
    this.myBuddyArr = [];
    this.myTrackedArr = []; // whom I tracked
    this.myUnlockedArr = []; // from whom I unlocked
    this.message = '';
    this.premiumExpiryDate = ''
    this.profileName = '';
    this.primaryImage = '';
    this.primaryImageUrl = '';
    this.primaryPhotoBnId = 0;
    this.primaryPhotoBnJson = '';
    this.signedInUserId = 0;
    this.signedInUserKey = '';
    this.trackedArr = []; // who tracked me
    this.unlockedArr = []; // who unlocked for me
    return this;
  }
  public resetThat (that: LoginSuccess) : any {
    that.action = '';
    that.blogId = 0;
    that.businessKey = '';
    that.code = '';
    that.controller = '';
    that.email = '';
    that.heading = '';
    that.isAuthorized = false;
    that.isEmailConfirmed = false;
    that.isEmployee = false;
    that.isForgotPassword = false;
    that.isLoggedIn = false;
    that.isMgmt = false;
    that.isOnLine = false;
    that.isPic = false;
    that.isReviewer = false;
    that.isSuperUser = false;
    that.imagePath = '';
    that.iv = '';
    that.id = 0;
    that.latitude = 0;
    that.longitude = 0;
    that.likedArr = []; // who liked me
    that.myBlockedArr = []; // whom I blocked
    that.myBuddyArr = [];
    that.myTrackedArr = []; // whom I tracked
    that.myUnlockedArr = []; // from whom I unlocked
    that.message = '';
    that.premiumExpiryDate = ''
    that.profileName = '';
    that.primaryImage = '';
    that.primaryImageUrl = '';
    that.primaryPhotoBnId = 0;
    that.primaryPhotoBnJson = '';
    that.signedInUserId = 0;
    that.signedInUserKey = '';
    that.trackedArr = []; // who tracked me
    that.unlockedArr = []; // who unlocked for me
    return that;
  }

  public copyFromSitUser (that : LoginSuccess, s:SitUser) : any {
    that.action = '';
    that.blogId = 0;
    that.businessKey = '';
    that.code = '';
    that.controller = '';
    that.email = s.email;;
    that.heading = '';
    that.isAuthorized = false;
    that.isEmailConfirmed = s.isEmailConfirmed;
    that.isEmployee = false;
    that.isForgotPassword = false;
    that.isLoggedIn = false;
    that.isMgmt = false;
    that.isOnLine = false;
    that.isPic = false;
    that.isReviewer = false;
    that.isSuperUser = false;
    that.imagePath = '';
    that.iv = '';
    that.id = 0;
    that.latitude = Number(s.latitude);
    that.longitude = Number(s.longitude);
    that.likedArr = []; // who liked me
    that.myBlockedArr = []; // whom I blocked
    that.myBuddyArr = [];
    that.myTrackedArr = []; // whom I tracked
    that.myUnlockedArr = []; // from whom I unlocked
    that.message = '';
    that.premiumExpiryDate = ''
    that.profileName = s.profileName;
    that.primaryImage = s.primaryImage;
    that.primaryImageUrl = '';
    that.primaryPhotoBnId = s.primaryPhotoBnId;
    that.primaryPhotoBnJson = s.primaryPhotoBnJson;
    that.signedInUserId = s.sitUserId;
    that.signedInUserKey = s.sitUserKey;
    that.trackedArr = []; // who tracked me
    that.unlockedArr = []; // who unlocked for me
    return that;
  }
}
