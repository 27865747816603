import { KvMemberActivityGlyph } from '../keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../keyValue/kvPhoto.model';

export class BasicPagerModel {
  public isPhotoMgmt = false;
  public totalItems = 0;
  public currentPageNo = 0;
  public nextPageNo = 0;
  public previousPageNo = 0;
  public pageSize = 0;
  public totalPages = 0;
  public startPageNo = 0;
  public endPageNo = 0;
  public startIndex = 0;
  public endIndex = 0;
  public kvPhotoArr: KvPhoto[] = [];
  public kvGlyphArr : KvMemberActivityGlyph[] = [];
  public page : number = 0;
  public pages : number[] = [];

  public pagedItems : KvPhoto[] = [];
  public currentItemNo = 0;
  public currentItem : KvPhoto = new KvPhoto();
  public nextItemNo = 0;
  public nextItem : KvPhoto = new KvPhoto();
  public previousItemNo = 0;
  public previousItem : KvPhoto = new KvPhoto();
  public startItemNo = 0;
  public startItem : KvPhoto = new KvPhoto();
  public endItemNo = 0;
  public endItem : KvPhoto = new KvPhoto();

  public pagedKvPhotoArr : KvPhoto[] = [];
  public pagedGlyphArr : KvMemberActivityGlyph[] = [];
  public currentPage : KvPhoto = new KvPhoto();
  public startPage : KvPhoto = new KvPhoto();
  public endPage : KvPhoto = new KvPhoto();
  public nextPage : KvPhoto = new KvPhoto();
  public previousPage : KvPhoto = new KvPhoto();
  constructor() { }
}
