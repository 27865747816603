
import { Injectable } from '@angular/core';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { ProfileTileKv } from '../../models/profile/profileTileKv.model';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';

@Injectable({
  providedIn: 'root',
})
export class QuicksortService {
  constructor() {
  }
  // ---------------------------------------------------------------
  partition (numbers : any, left : any, right : any, pivotIndex : any) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(numbers) && left >= 0 && right >= 0 && pivotIndex >= 0) {
      let pivotValue = numbers[pivotIndex];
      // move pivot to the end
      let temp = numbers[right];
      numbers[right] = numbers[pivotIndex];
      numbers[pivotIndex] = temp;

      // newPivot stores the index of the first number bigger than pivot
      let newPivot = left;
      for (let i = left; i < right; i++) {
        if (numbers[i] <= pivotValue) {
          temp = numbers[newPivot];
          numbers[newPivot] = numbers[i];
          numbers[i] = temp;
          newPivot++;
        }
      }

      // move pivot element to its sorted position
      temp = numbers[right];
      numbers[right] = numbers[newPivot];
      numbers[newPivot] = temp;
      return newPivot;
    }
    return null;
  }
  // ---------------------------------------------------------------
  quickSort (numbers : any, left : any, right : any )
  {
    let pivotIndex : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(numbers) && left >= 0 && right >= 0 && pivotIndex >= 0) {
      if (right > left) {
        let pivotIndex = left + (right - left) / 2;
        // partition the array
        pivotIndex = this.partition(numbers, left, right, pivotIndex);
        // Sort the left partition
        this.quickSort(numbers, left, pivotIndex - 1);
        // sort the right partition
        this.quickSort(numbers, pivotIndex + 1, right);
      }
    }
    return numbers;
  }
  // ---------------------------------------------------------------
  sortProfileTileKvArrUsingMap(list: ProfileTileKv[]) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(list)) {
      // temporary array holds objects with position and sort-value
      let mapped = list.map( (el, i) => {
        return { index: i, value: el };
      });

      // sorting the mapped array containing the reduced values
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mapped)) {
        mapped.sort( (a, b) => {
          if (a.value.value.sitUserId > b.value.value.sitUserId) {
            return 1;
          }
          if (a.value.value.sitUserId < b.value.value.sitUserId) {
            return -1;
          }
          return 0;
        });

        // container for the resulting order
        let result = mapped.map( (el) => {
          return list[el.index];
        });
        return result;
      }
    }
    return null;
  }
  // --------------------------------------------------------------
  // Tested, works!
  createArrayFromMap(inmap: Map<number, any>): any[] {
    let arr : any[] = [];
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inmap)) {
      inmap.forEach((value, key, map) => {
        arr.push(value);
      });
    }
    return arr;
  }
  // ---------------------------------------------------------------
  sortProfileTilesMap(map: Map<number, ProfileTile>): any{
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(map)) {
      let mapList = this.createArrayFromMap(map);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mapList)) {
        mapList.sort((a, b) => {
          if (a && a.value && b && b.value && a.value.sitUserId && b.value.sitUserId && a.value.sitUserId > b.value.sitUserId) {
            return 1;
          }
          if (a && a.value && b && b.value && a.value.sitUserId && b.value.sitUserId && a.value.sitUserId < b.value.sitUserId) {
            return -1;
          }
          return 0;
        });
      }
      debugger;
      return mapList;
    }
    debugger;
    return null;
  }
  // ---------------------------------------------------------------
  // Ref:https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
  sortProfileTilesArr (list : any): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(list)) {
      let mapped = list.map((el : any, i : any) => {
        return { index: i, value: el };
      });
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mapped)) {
        mapped.sort((a : any, b : any) => {
          if (a.value.sitUserId > b.value.sitUserId) {
            return 1;
          }
          if (a.value.sitUserId < b.value.sitUserId) {
            return -1;
          }
          return 0;
        });
        // container for the resulting order
        let result = mapped.map((el : any) => {
          return list[el.index];
        });
        return result;
      }
    }
    return null;
  }
  // ---------------------------------------------------------------
  // Ref:http://jsfiddle.net/Glutamat/m4Aq7/
  quick_sort (unsorted : any) : any {
    if (unsorted.length <= 1)
      return unsorted;

    let pivot = unsorted.pop();
    let less: any[] = [];
    let greater: any[] = [];

    unsorted.forEach((element : any) => {
      if (element > pivot)
        less.push(element as any);
      else
        greater.push(element as any);
    });

    return this.quick_sort(less).concat([pivot]).concat(this.quick_sort(greater));
  }
  // Usage example
  // let unsorted = [8, 2, 10, 5, 4, 9, 7, 1, 6, 3];
  // console.log(quick_sort(unsorted));
  // ---------------------------------------------------------------
}
