
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DbServiceService } from '../dbServiceService/dbServiceService.service';
import { PhotoPrivacyService } from '../photoServiceService/photoPrivacyService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';
import { DictionaryService } from './dictionaryService.service';

// =====================================================================

// =====================================================================

@Injectable({ providedIn: 'root'})
export class DictionaryIOService
{
  public randomNumberGenerator$ : Observable<number>;
  public randomNumber = 0;
  public dbDexieToDictionaryService : DbDexieToDictionaryService;
  public loginSuccess : LoginSuccess;
  constructor (public photoPrivacyService : PhotoPrivacyService,
    public dbServiceService : DbServiceService,
    private dictionaryService : DictionaryService,) {
    this.dbDexieToDictionaryService = this.dbServiceService.getDbDexieToDictionaryService();
    
	}
	// ---------------------------------------------------------------
	public createProfilePicsFromKvPhotoDictionary (sitUserId : number) : ProfilePics | any {
		// debugger;
		let profilePics = new ProfilePics();
		if (sitUserId > 0) {
			profilePics.sitUserId = sitUserId;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dictionaryService.kvPhotoDictionary)
        && this.dictionaryService.kvPhotoDictionary.size > 0) {
				// debugger;
        profilePics = this.dictionaryService.getProfilePicsFromKvPhotoDictionary(sitUserId); // KvPhotos are not filtered yet.
				// debugger;
				if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)
					&& !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.unFilteredKvPhotoArr)
					&& profilePics.unFilteredKvPhotoArr.length > 0) {
					// debugger;
					this.photoPrivacyService.filterKvPhotoArr(profilePics.unFilteredKvPhotoArr).subscribe(result => {
						// debugger;
						if (result) {
							profilePics.filteredKvPhotoArr = result;
							// debugger;
							if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.filteredKvPhotoArr) && profilePics.filteredKvPhotoArr.length > 0) {
								// debugger;
								profilePics.filteredKvPhotoArr.forEach(e => {
									if (e) {
										profilePics.filteredKvPhotoDictionary.set(e.photoBnId, e);
									}
								})
							}
						}
					})
				}
			}
			return profilePics;
		}
		else return null;
	}
  // ------------------------------------------------------------------------------
  public  getProfilePicsFromDictionary (sitUserId : number) : ProfilePics {
    // debugger;
    let date = new Date();
		let pPics : ProfilePics;    
    
    if (sitUserId > 0) {
      pPics = this.createProfilePicsFromKvPhotoDictionary(sitUserId);
			return pPics;
    }
  }
  // ------------------------------------------------------------------------------
  public populateSitUserIdDictionaryFromIndexedDb () : any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.dbDexieToDictionaryService.getDictionary('sitUserId', this.loginSuccess.signedInUserId.toString())
      .subscribe((sitUserIdDictionary : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserIdDictionary) && sitUserIdDictionary.size() > 0) {
          this.dictionaryService.sitUserIdDictionary = StringServiceStatic.deepCloneJson(sitUserIdDictionary);
          console.log('sitUserIdDictionary.size: : ', sitUserIdDictionary.size());
        }
        else {
          console.log('getDictionaryFromIndexedDb returned a null or undefined result for sitUserIdDictionary ');
        }
      })
  }
  // ---------------------------------------------------------------
  public createDictionaryFromDbDexieDb (entityName : string) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)) {
      switch (entityName.toLowerCase()) {
        case 'all':
          this.dbDexieToDictionaryService.createAllDictionariesFromDbDexie();
          break;
        case 'chatConversation':
        case 'chatHistory':
        case 'chat':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('ChatConversation') && this.dbDexieToDictionaryService.doesStoreHaveData('ChatConversation')) {
            this.dbDexieToDictionaryService.createAllChatDictionariesFromDbDexie();
          }
          break;
        case 'email': case 'emailtile':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('emailTile') && this.dbDexieToDictionaryService.doesStoreHaveData('emailTile')) {
            this.dbDexieToDictionaryService.createAllEmailDictionariesFromDbDexie();
          }
          break;
        case 'enote': case 'enotetile': case 'note': case 'notification':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('enoteTile') && this.dbDexieToDictionaryService.doesStoreHaveData('enoteTile')) {
            this.dbDexieToDictionaryService.createAllEnoteDictionariesFromDbDexie();
          }
          break;
        case 'kvPhoto':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('kvPhoto') && this.dbDexieToDictionaryService.doesStoreHaveData('kvPhoto')) {
            this.dbDexieToDictionaryService.createKvPhotoDictionaryFromDbDexie();
          }
          break;
        case 'preference':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('preference') && this.dbDexieToDictionaryService.doesStoreHaveData('preference')) {
            this.dbDexieToDictionaryService.createPreferenceDictionaryFromDbDexie();
          }
          break;
        case 'profilecontent':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('profileContent') &&     this.dbDexieToDictionaryService.doesStoreHaveData('profileContent')) {
            this.dbDexieToDictionaryService.createProfileContentDictionaryFromDbDexie();
          }
          break;
        case 'profileinfo':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('profileInfo') && this.dbDexieToDictionaryService.doesStoreHaveData('profileInfo')) {
            this.dbDexieToDictionaryService.createProfileInfoDictionaryFromDbDexie();
          }
          break;
        case 'profiletile':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('profileTile') && this.dbDexieToDictionaryService.doesStoreHaveData('profileTile')) {
            this.dbDexieToDictionaryService.createProfileTileDictionaryFromDbDexie();
          }
          break;
        case 'profilepics':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('profilePics') && this.dbDexieToDictionaryService.doesStoreHaveData('profilePics')) {
            this.dbDexieToDictionaryService.createProfilePicsDictionaryFromDbDexie();
          }
          break;
        case 'situserid':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('sitUserId') && this.dbDexieToDictionaryService.doesStoreHaveData('sitUserId')) {
            this.dbDexieToDictionaryService.createSitUserIdDictionaryFromDbDexie();
          }
          break;
        case 'situser':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('sitUser') && this.dbDexieToDictionaryService.doesStoreHaveData('sitUser')) {
            this.dbDexieToDictionaryService.createSitUserDictionaryFromDbDexie();
          }
          break;
        case 'memberactivity':
          if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('memberActivity') && this.dbDexieToDictionaryService.doesStoreHaveData('memberActivity')) {
            this.dbDexieToDictionaryService.createAllMemberActivityDictionariesFromDbDexie();
          }
          break;
        default:

          break;
      }
      return true;
    }
    else return false;
  }
  // ----------------------------------------------------------------------

  // ----------------------------------------------------------------------

  // ------------------------------------------------------------------------
}
