
import { Injectable } from '@angular/core';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Photo } from '../../models/common/photo.model';
import { SpinnerModel } from '../../models/common/spinnerModel.model';
import { KV } from '../../models/keyValue/kv.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { ProfileImage } from '../../models/profile/profileImage.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { CopyServiceStatic } from './commonStaticServices/copyServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { StringServiceStatic } from './stringServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from './frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

@Injectable({
  providedIn: 'any',
})
// ------------------------------------------------------------------
export abstract class ArrayServiceStatic {
 public static rootScopeTs: Set<KV>;
 public static distUnit = '';
 public static isMobilevar = false;
 public static height: any;
 public static width: any;
 public static heightUnit: any;
 public static weightUnit: any;
 public static kv: KV;
  constructor(
    // private inMemoryDataService: InMemoryDataService //public static quickSort: Quicksort
  ) {
    ArrayServiceStatic.rootScopeTs = new Set<KV>();
  }
  // ------------------------------------------------------------------
  // ------------------------------------------------------------------
  // Tested, works!
  //  On 20220328
  // ------------------------------------------------------------------
  static createArrayFromMap2 (inmap : Map<any, any>) : any[] {
    const arr : any[] = [];

    if (inmap && inmap.size > 0) {
      inmap.forEach((value, key) => {
        arr.push(value);
      });
    }
    return arr;
  }
  // ------------------------------------------------------------------
  //  Tested, works!
  // ------------------------------------------------------------------
  static createMapFromArray2 (arr : any[]) : Map<number, any> {
    const map = new Map<number, any>();

    if (arr && arr.length > 0) {
      arr.forEach(function callback (key, value) {
        map.set(value, key);
      });
    }
    return map;
  }
  // ------------------------------------------------------------------
  // ------------------------------------------------------------------
  // Tested, works!
  //  On 20220411
  // ------------------------------------------------------------------
  static createArrayFromMap (inmap : Map<any, any>) : any {
    const arr:any[] = [];
    
    if (inmap && inmap.size > 0) {
      arr.push([ ...inmap.values() ])
    }
    // debugger;
    return arr;
  }
  // ------------------------------------------------------------------
  //  Tested, works!
  // ------------------------------------------------------------------
  static createMapFromArray (arr : any) : Map<any, any> {
    const map = new Map<any, any>();

    if (arr && arr.length > 0) {
      arr.forEach(function callback (key : any, value : any) {
        map.set(value, key);
      });
    }
    return map;
  }
  // --------------------------------------------------------------
  // ref: https://stackoverflow.com/questions/4775722/how-can-i-check-if-an-object-is-an-array
  // Only implement if no native implementation is available
  // --------------------------------------------------------------
  static isTypeOfArray (obj : any) : boolean {
    let isArray: boolean = false;
    if (typeof Array.isArray === 'undefined') {
      (obj : any) =>{
        isArray = Object.prototype.toString.call(obj) === '[object Array]';
      }
    };
    // debugger;
    return isArray;
  }
  // --------------------------------------------------------------
  static QueuePop ( inArray: any[] ): any
  {
    for ( let i = 0; i < inArray.length; i++ )
    {
      // this.unloadComponent(this.activeComponentIds[i]); //Do something;
      inArray.splice( i--, 1 );
    }
  }
  // --------------------------------------------------------------
  static RemoveFromBottom ( inArray: any[] ): any
  {
    // bottom=0th-index
    for ( let i = 0; i < inArray.length; i++ )
    {
      // this.unloadComponent(this.activeComponentIds[i]); //do something;
      inArray.splice( i--, 1 );
    }
  }
  // --------------------------------------------------------------
  static StackPop ( inArray: any[] ): any
  {
    for ( let i = inArray.length - 1; i >= 0; i-- )
    {
      // this.unloadComponent(this.activeComponentIds[i]); //do something
      inArray.splice( i, 1 );
      // debugger;
    }
  }
  // ---------------------------------------------------------------
  static mergeMapsViaSpread (first : Map<any, any>, second : Map<any, any>) : Map<any, any> {
    let merged = new Map<any, any>([ ...first, ...second ]) as Map<any, any>;
    return merged;
  }
  // ---------------------------------------------------------------
  // Note: there is a similar function called createMapFromArray()
  //       and its counterpart createArrayFromMap()
  // ---------------------------------------------------------------
  static mapFromArray (list : any[]) : any {
    const mapped = list.map((el, i) => ({ index: i, value: el }));

    return mapped;
  }
  // ---------------------------------------------------------------
  static arrayFromMap (list : Map<any, any>) : any {
    return this.createArrayFromMap(list);
  }
  // ---------------------------------------------------------------
  static mergeArrays (dArr : any[], sArr : any[]) : any[] {
    let index = -1;
    let dMap = new Map<any, any>();
    let sMap = new Map<any, any>();
    let merged = new Map<any, any>();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dArr)
      || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sArr)) {
      if (dArr.length > 0) {
        dMap = new Map<any, any>(dArr);
      }
      if (sArr.length > 0) {
        sMap = new Map<any, any>(sArr);
      }
    }
    merged = new Map([ ...dMap, ...sMap ]);
    return new Array(merged);
  }
  // --------------------------------------------------------------
  //  Note: makeUniq(arr[]) method makes an array
  //        unique-valued, ==> there is no duplicate element in it.
  //  
  //        Using the Set constructor and the spread syntax:
  // --------------------------------------------------------------
  public static makeUniq (arr : any[]) : any {
    let uniq : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && arr.length > 0) {
      uniq = [ ...new Set(arr) ];

      return uniq;
    }
    else return arr;
  }
  // --------------------------------------------------------------
  //  Note: Tested on 20220411. Works!
  // --------------------------------------------------------------
  public static mergeArraysUniq (dArr : any[], sArr : any[]) : any[] {
    // merge
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sArr) && sArr.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dArr)) {
      dArr.push(...sArr);
    }
    // debugger;
    //uniq
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dArr) && dArr.length > 0) {
      dArr = [ ...new Set(dArr) ];
    }
    // debugger;
    return dArr;
  }
  // ---------------------------------------------------------------
  static mergeMaps (dMap : Map<any, any>, sMap : Map<any, any>) : Map<any, any> {
    let merged = new Map<any, any>();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dMap) || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sMap)) {
      merged = new Map([ ...dMap, ...sMap ]);
    }
    return merged;
  }
  // ---------------------------------------------------------------
  // static sortMap (first : Map<any, any>) : Map<any, any> {
  //  let m = this.sortMap(first);
  //  return m;
  // }
  // --------------------------------------------------------------
  static RemoveFromTop ( inArray: any[] ): any
  {
    // top=last-index
    for ( let i = inArray.length - 1; i >= 0; i-- )
    {
      // this.unloadComponent(this.activeComponentIds[i]); // do something
      inArray.splice( i, 1 );
      // debugger;
    }
  }
  // --------------------------------------------------------------
  static RemoveFromArray ( inArray: any[], index: number ): any
  {
    // top=last-index
    inArray.splice( index, 1 );
  }
  // ==============================================================
  // --------------------------------------------------------------
  static profileTileArrayToMap (inArr : any[]) : any {
    let keyValueMap = inArr.reduce((mapAccumulator, obj) => {
      // either one of the following syntax works
      // mapAccumulator[obj.key] = obj.val;
      mapAccumulator.set(obj.sitUserId, obj);

      return mapAccumulator;
    }, new Map());
    debugger;
    return keyValueMap;
  }
  // ---------------------------------------------------------------------------------
  static getIndexOfItemInSpinnerTimerArr ( spinnerModelArr: SpinnerModel[], item: number ): any
  {
    let index = -1;
    let i = 0;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( item ) )
    {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spinnerModelArr) && spinnerModelArr.length )
      {
        for (let e of spinnerModelArr )
        {
          if ( item === e.startTime )
          {
            index = i;
            break;
          }
          i++;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------------------------
  static getIndexOfKvPhotoInKvPhotoArr(kvpArr: KvPhoto[], item: KvPhoto): any {
    let index = -1;
    let i = 0;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( item ) )
    {
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( kvpArr ) && kvpArr.length > 0)
      {
        for (let e of kvpArr)
        {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e) && (item.photoBnId === e.photoBnId || item.photoId === e.photoId))
          {
            index = i;
            break;
          }
          i++;
        }       
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfTileInArr ( tiles: ProfileTile[], pt: ProfileTile ): number
  {
    // debugger;
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tiles ) && tiles.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pt ) && pt.sitUserId > 0 )
    {
      let i = 0;
      for ( const e of tiles )
      {
        if ( e.sitUserId === pt.sitUserId )
        {
          // debugger;
          index = i;
          break;
        }
        i++;
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  static mergeProfileTileArrays ( dTileArr: ProfileTile[], sTileArr : ProfileTile[] ) : ProfileTile[]
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dTileArr ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sTileArr ) && sTileArr.length > 0 )
    {
      // debugger;
      if ( dTileArr.length === 0 )
      {
        dTileArr = sTileArr;
      }
      // else
      // dTileArr = dTileArr.push(...sTileArr);
      // dTileArr = [...new Set(dTileArr)];
      // OR
      else
      {
        // dTileArr.push(...sTileArr);
        for ( let src of sTileArr )
        {
          let index = this.getIndexOfTileInArr( dTileArr, src );
          if ( index > 0 )
          {
            dTileArr[index] = CopyServiceStatic.copyProfileTileIfExists(dTileArr[index], src);
          }
          else
          {
            dTileArr.push( src );
          }
        }
      }
    }
    return dTileArr;

    // TODO: remove before deployment
    // ------------------------------
      // dTileArr.map( d =>
      // {
      //  let indx = this.getIndexOfTileInArr( sTileArr, d)
        // sTileArr.map(  =>
        // {
        //  if ( d.sitUserId == s.sitUserId )
        //  {
        //    debugger;
        //    CopyServiceStatic.copyProfileTileIfExists( d, s );
        //  }
        //  else
        //  {
        //    debugger;
        //    dTileArr.push( s );
        //  }
        // } );
      // } );
    //}
    //return dTileArr;
  }
  // ---------------------------------------------------------------
  static getIndexOfBnInBoxNonceArr ( bns : BoxNonceEntity[], bn : BoxNonceEntity ) : number
  {
    // debugger;
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( bns ) && bns.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( bn ) && bn.sitUserId > 0 )
    {
      for ( let i = 0; i < bns.length; i++ )
      {
        if ( bns[ i ].id === bn.id && bns[ i ].sitUserId === bn.sitUserId && bns[ i ].entityName === bn.entityName )
        {
          // debugger;
          index = i;
          break;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfItemByPageNumInKvPhotoArr (arr : KvPhoto[], itemNum : number) : number {
    let index = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && arr.length > 0  && itemNum > 0) {
      let isFound = false;

      for (let i = 0; i < arr.length; i++) {
        if (arr[ i ].itemNum === itemNum) {
          isFound = true;
          if (isFound) {
            index = i;
            break;
          }
        }
      }
    }
    return index;
  }

  // ---------------------------------------------------------------
  static getIndexOfItemByPhotoIdInKvPhotoArr (arr : KvPhoto[], photoId : any) : number {
    let index = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && arr.length > 0 && photoId > 0) {
      let isFound = false;

      for (let i = 0; i < arr.length; i++) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr[ i ]) && arr[ i ].photoId === photoId) {
          isFound = true;
          index = i;
          break;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfItemByPhotoBnIdInKvPhotoArr ( arr : KvPhoto[], photoBnId : any ) : number
  {
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arr ) && arr.length > 0 && photoBnId > 0 )
    {
      for ( let i = 0; i < arr.length; i++ )
      {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr[i]) &&  arr[ i ].photoBnId === photoBnId )
        {
          index = i;
          break;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfItemByPhotoBnIdOrPhotoIdInKvPhotoArr (arr : KvPhoto[], photoBnId : any, photoId: any) : number {
    let index = -1;
    if (photoId > 0) {
      index = this.getIndexOfItemByPhotoIdInKvPhotoArr(arr, photoId);
    }
    else if (photoBnId > 0) {
      // debugger;
      index = this.getIndexOfItemByPhotoBnIdInKvPhotoArr(arr, photoBnId);
    }
    
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfItemByProfileIdInKvPhotoArr (arr : KvPhoto[], profileId: number) {
    let index = -1;
    if (profileId > 0) {
      index = this.getIndexOfItemByPhotoIdInKvPhotoArr(arr, profileId);
    }    
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfItemInArr ( arr : any[], item : any ) : number
  {
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arr ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( item ) )
    {
      let isFound = false;

      for ( let i = 0; i < arr.length; i++ )
      {
        if ( arr[ i ].toString().toLowerCase().indexOf( item.toString().toLowerCase() ) !== -1 )
        {
          isFound = true;
          if ( isFound )
          {
            index = i;
            break;
          }
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfKvGlyphInArr (arr : any[], kvg : KvMemberActivityGlyph) : number {
    let isFound = false;
    let index = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(arr) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvg.sitUserId) && kvg.sitUserId > 0) {
      for (let i = 0; i < arr.length; i++) {
        if ((arr[ i ].sitUserId && parseInt(arr[ i ].sitUserId, 10) === kvg.sitUserId) || parseInt(arr[ i ], 10) === kvg.sitUserId) {
          // debugger;
          isFound = true;
          if (isFound) {
            index = i;
            break;
          }
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfSitUserIdInArr ( arr : any[], sitUserId : number ) : number
  {
    let isFound = false;
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arr ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sitUserId ) && sitUserId > 0 )
    {
      for ( let i = 0; i < arr.length; i++ )
      {
        if ( ( arr[ i ].sitUserId && parseInt( arr[ i ].sitUserId, 10 ) === sitUserId ) || parseInt( arr[ i ], 10 ) === sitUserId )
        {
          // debugger;
          isFound = true;
          if ( isFound )
          {
            index = i;
            break;
          }
        }
      }
    }
    return index;
  }

  // ---------------------------------------------------------------
  static getIndexOfProfileImageInArr ( tiles : ProfileImage[], pt : ProfileImage ) : number
  {
    // debugger;
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tiles ) && tiles.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pt ) && pt.sitUserId > 0 )
    {
      let i = 0;
      for ( const e of tiles )
      {
        if ( e.photoId === pt.photoId )
        {
          // debugger;
          index = i;
          break;
        }
        i++;
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  static getIndexOfNumberInArr ( arr: number[], id: number ): number
  {
    let index = -1;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( arr ) && arr.length > 0 )
    {
      for ( let i = 0; i < arr.length; i++ )
      {
        if ( arr[ i ] === id )
        {
          index = i;
          break;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------
  getIndexOfProfileImageInArr (tiles : ProfileImage[], pt : ProfileImage) : number {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tiles) && tiles.length > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pt) && pt.sitUserId > 0) {
      let i = 0;

      for (const e of tiles) {
        if (e.photoId === pt.photoId) {
          // debugger;
          return i;
        }
        i++;
      }
      return -1;
    }
    return -1;
  }
 
  // ---------------------------------------------------------------
  static mergeIdArrays ( dTileIdArr : number[], sTileIdArr : number[] ) : number[]
  {
    let index = -1;

    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dTileIdArr )
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sTileIdArr )
      && sTileIdArr.length > 0 )
    {
      // debugger;
      if ( dTileIdArr.length === 0 )
      {
        dTileIdArr = StringServiceStatic.deepCloneJson(sTileIdArr);
      }

      sTileIdArr.map( e =>
      {
        index = this.getIndexOfNumberInArr( dTileIdArr, e );
        if ( index === -1 )
        {
          dTileIdArr.push( e );
        }
      });
    }
    return dTileIdArr;
  }
  
  // --------------------------------------------------------------------------------
  // Note: This method removes the very first image on ProfileImageArr of ProfilePics
  //       since it is created from ProfileTile to quicky show the user's primary-pic
  //       and the server sent ProfilePics already contains the primary-pic
  // --------------------------------------------------------------------------------
  removeProfileImageFromProfileImageArr (pPics : ProfilePics) : ProfilePics {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.profileImageArr)
      && pPics.profileImageArr.length > 1) // Note: there has to be at least 2 pics to start
    {
      let index = 0;
      pPics.profileImageArr.map(e => {
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.sitUserKey) || e.sitUserId === 0) // Note: this criteria was used because ProfileImage created from ProfileTile lacks sitUserKey
        {
          // debugger;
          pPics.profileImageArr.splice(index, 1);
        }
        index++;
      });
    }
    return pPics;
  }
  // ---------------------------------------------------------------
  static removeDuplicateProfileTiles ( a : ProfileTile[] ) : any
  {
    let result = a;
    let isFound = false;
    for ( let item of a)
    {
      isFound = false;
      let i = 0;
      for ( let rItem of result)
      {
        if ( rItem.sitUserId === item.sitUserId )
        {
          isFound = true;
          break;
        }
        i++;
      }
      if (isFound)
      {
        result.splice( i, 1 );
      }
    }
    return result;
  }
  // ---------------------------------------------------------------
  // Note: Tested, works on 20220410
  // ---------------------------------------------------------------
  static removeDuplicates(a: any): any
  {
    // debugger;
    let result: any[] = [];
    a.forEach(function (item : any )
    {
      if ( result.indexOf( item ) < 0 )
      {
        result.push( item );
      }
    } );
    return result;
  }
  // ---------------------------------------------------------------
  static removeKvPhotoDuplicatesByPhotoBnId (a : any) : any {
    // debugger;
    let result = a;
    let isFound = false;
    for (let item of a) {
      isFound = false;
      let i = -1;
      for (let rItem of result) {
        if (rItem &&  rItem.photoBnId > 0 && item && rItem.photoBnId === item.photoBnId) {
          isFound = true;
          i++;
          break;
        }
      }
      if (isFound && i > -1) {
        result.splice(i, 1);
      }
    }
    // debugger;
    return result;
  }
  // ---------------------------------------------------------------
  static removePhotoDuplicatesByPhotoId (a : any) : any {
    // debugger;
    let result = a;
    let isFound = false;
    for (let item of a) {
      isFound = false;
      let i = -1;
      for (let rItem of result) {
        if (rItem && rItem.photoId > 0 && item && rItem.photoId === item.photoId) {
          isFound = true;
          i++;
          break;
        }
      }
      if (isFound && i > -1) {
        result.splice(i, 1);
      }
    }
    return result;
  }
  // ---------------------------------------------------------------
  static removeKvPhotoDuplicatesByPhotoId (a : any) : any {
    // debugger;
    let result = a;
    let isFound = false;
    for (let item of a) {
      isFound = false;
      let i = -1;
      for (let rItem of result) {
        if (rItem.photoId === item.photoId) {
          isFound = true;
          i++;
          break;
        }
      }
      if (isFound && i > -1) {
        result.splice(i, 1);
      }
    }
    return result;
  }
  // ---------------------------------------------------------------
  // ref: https://stackoverflow.com/questions/31158902/is-it-possible-to-sort-a-es6-map-object
  // So here's a simple way to sort a map by string keys:
  static sortMapByKey (inMap : Map<any, any>) : Map<any, any> {
    return new Map([ ...inMap ].sort((a, b) => String(a[ 0 ]).localeCompare(b[ 0 ])))
  }

  // ...and by string values:
  static sortMapByValue (inMap : Map<any, any>) : Map<any, any> {
    return new Map([ ...inMap ].sort((a, b) => String(a[ 1 ]).localeCompare(b[ 1 ])))
  }
  /*
  * --------------------------------------------------------------
  * Tested, works!
  */
  static sortMap (inmap : Map<number, any>) : Map<number, any> {
    return new Map(
      Array.from(inmap).sort(
        (a, b) =>
          // a[0], b[0] is the key of the
          a[ 0 ] - b[ 0 ],
      ),
    );
  }
  // --------------------------------------------------------------
  static sortMapByPhotoBnId (inmap : Map<number, any>) : Map<number, any> {
    let inArr = ArrayServiceStatic.createArrayFromMap(inmap);
    inArr.sort(
      (a : any, b : any) =>
        // a[0], b[0] is the key of the
        a.photoBnId - b.photoBnId
    );
    return new Map(inArr);
  }
  // ---------------------------------------------------------------
  static sortProfileTileArrByDistance ( pTileArr : ProfileTile[] ) : any
  {
    let profileTilesArr: ProfileTile[] = [];
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pTileArr ) && pTileArr.length > 0 )
    {

      profileTilesArr = pTileArr.sort((a, b) => b.distance - a.distance );

      EmitterSubjectService.setProfileTilesArr( profileTilesArr );
      // debugger;
      return profileTilesArr;
    }
    else return pTileArr;
  }
  // ---------------------------------------------------------------
  //  Note: Deprecated!!
  //        Instead user mergeArraysUniq()
  // ---------------------------------------------------------------
  static uniquelyUpdateKvPhotoArray (dKvPhotoArr : KvPhoto[], sKvPhoto : KvPhoto) : KvPhoto[] {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dKvPhotoArr)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sKvPhoto)
      && sKvPhoto.photoBnId > 0
      && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sKvPhoto.value))) {
      // debugger;
      if (sKvPhoto.photoBnId > 0) {
        let index = this.getIndexOfItemByPhotoBnIdInKvPhotoArr(dKvPhotoArr, sKvPhoto.photoBnId);
        if (index < 0) {
          dKvPhotoArr.push(sKvPhoto);          
          // debugger;
        }
        else {
          // debugger;
          dKvPhotoArr[ index ] = new KvPhoto().copySourceToDestination(dKvPhotoArr[ index ], sKvPhoto)// CopyServiceStatic.copyKvPhotoIfExists(dKvPhotoArr[ index ], sKvPhoto);
          // debugger;
        }
      }
    }
      
    return dKvPhotoArr;
  }
  // ---------------------------------------------------------------
  static updateProfileTileInArray ( dTileArr : ProfileTile[], sTile : ProfileTile ) : ProfileTile[]
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( dTileArr ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( sTile ) )
    {
      // debugger;
      if ( dTileArr.length === 0 )
      {
        dTileArr.push( sTile );
      }
      dTileArr.map( d =>
      {
        if ( d.sitUserId == sTile.sitUserId )
        {
          CopyServiceStatic.copyProfileTileIfExists( d, sTile );
        }
      } );
    }
    return dTileArr;
  }
  // ---------------------------------------------------------------

  // ---------------------------------------------------------------
  static updateProfileTileArrViaMutation ( tileArr : ProfileTile[] ) : ProfileTile[]
  {
    let i = 0;
    let profileTilesArr: ProfileTile[] = [];
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tileArr ) && tileArr.length > 0 )
    {
      
      tileArr.map( e =>
      {
        if ( this.getIndexOfTileInArr( profileTilesArr, e ) === -1 )
        {
          // add:
          profileTilesArr = [ ...profileTilesArr, e ];
        }
        else
        {
          // update:
          profileTilesArr[ i ] = CopyServiceStatic.copyProfileTileIfExists( profileTilesArr[ i ], e );
        }
        i++;
      } );
    }

    return profileTilesArr;
  }
  // ------------------------------------------------------------------
  //  setDateStringService(dS: DateStringServiceStatic): DateStringServiceStatic {
  //  return (this.dateStringService = dS); // TODO: check if the return type is correct
  // }

  // static getDateStringService(): any {
  //  return this.dateStringService;
  // }
  // -----------------------------------------------------------
  //static centemetersToInches(centemeters) {
  //  if (centemeters > 0) {
  //    return centemeters * 0.393701;
  //  }
  //  return 0;
  //}
  // ----------------------------------------------------------------------------------
  //static inchesToCentemeters(inches) {
  //  if (inches > 0) {
  //    return inches * 2.54;
  //  }
  //  return 0;
  //}
  /*
   * ----------------------------------------------------------------------------------
   * Convert feet to meters
   */
  //static feetToMeters(feet) {
  //  if (feet > 0) {
  //    return feet * 0.3048;
  //  }
  //  return 0;
  //}
  /*
   * ----------------------------------------------------------------------------------
   * Convert meters to feet
   */
  //static metersToFeet(meters) {
  //  if (meters > 0) {
  //    return meters * 3.28084;
  //  }
  //  return 0;
  //}
  /*
   * ----------------------------------------------------------------------------------
   * Convert meters to miles
   */
  //static metersToMiles(meters) {
  //  if (meters > 0) {
  //    return meters * 0.000621371192;
  //  }
  //  return 0;
  //}
  /*
   * ----------------------------------------------------------------------------------
   * Convert miles to meters
   */
  //static milesToMeters(miles) {
  //  if (miles > 0) {
  //    return miles * 1609.34;
  //  }
  //  return 0;
  //}
  // ----------------------------------------------------------------------------------
  //static kilosToPounds(kilos) {
  //  if (kilos > 0) {
  //    return kilos * 2.20462;
  //  }
  //  return 0;
  //}
  // ----------------------------------------------------------------------------------
  //static poundsToKilos(pounds) {
  //  if (pounds > 0) {
  //    return pounds * 0.453592;
  //  }
  //  return 0;
  //}
  /*
   * ----------------------------------------------------------------------------------
   * NOTE: Height is stored in database as string like: 6'2' (old data) or only as inches like: 74'
   *     This method handles both cases and returns the height in inches as pure number like: 74
   * ----------------------------------------------------------------------------------
   */
  //static convertHeightToNumber(height) {
  //  if (height !== null) {
  //    let hInches = height;
  //    // ref: https:// stackoverflow.com/questions/15699094/how-to-validate-a-number-field-in-javascript-using-regular-expressions

  //    if (!/^-?(\d+|\d+\.\d+|\.\d+)([eE][-+]?\d+)?$/.test(height)) {
  //      if (height.indexOf('\'') !== -1) {
  //        // '/feet exist in input
  //        const hArr = height.split('\'');

  //        if (hArr.length > 0) {
  //          hInches = parseInt(hArr[0], 10) * 12;
  //        }
  //        if (hArr[1]) {
  //          if (hArr[1].indexOf('\'') !== -1) {
  //            const iArr = hArr[1].split('\'');

  //            if (iArr.length > 0) {
  //              hInches += parseInt(iArr[0], 10);
  //            }
  //          } else {
  //            hInches += parseInt(hArr[1], 10);
  //          }
  //        }
  //      } else if (height.indexOf('\'') !== -1) {
  //        const incArr = height.split('\'');

  //        if (incArr.length > 0) {
  //          hInches += parseInt(incArr[0], 10);
  //        }
  //      }
  //    }
  //    return hInches;
  //  }
  //  return 0;
  //}
  /*
   * ---------------------------------------------------------------
   * NOTE: $rootScope.isMetric belongs to signed-in-user
   *     the parameter 'isMetric' belongs to the non-signed-in-user
   *     $rootScope.isMetric may be different than parameter 'isMetric'
   *     Both are the same when the signed-in-user views his/her own profile
   *     This method converts the height to that of signed-in-user
   * ----------------------------------------------------------------------------------
   */
  //static convertHeightUnit(hight, isMetric) {
  //  const heightUnit = { };

  //  if (hight > 0) {
  //    this.kv.key = 'isMetric';
  //    this.heightUnit = [
  //      {
  //        hUnit: 'cm',
  //      },
  //    ];
  //    if (this.rootScopeTs.has(this.kv)) {
  //      this.heightUnit = [{ hUnit: 'cm'}];
  //      if (isMetric) {
  //        // Math.round(height * 100) / 100;  //round to two decimal places
  //        this.heightUnit = [{ height: hight}];
  //      } else {
  //        // Math.round(returnObject.inchesToCentemeters(height) * 100) / 100; //round to two decimal places
  //        this.heightUnit = [{ height: this.inchesToCentemeters(hight)}];
  //      }
  //    } else {
  //      this.heightUnit = [{ hUnit: 'inches'}];
  //      if (isMetric) {
  //        // Math.round(returnObject.centemetersToInches(height) * 100) / 100; //round to two decimal places
  //        this.heightUnit = [{ hUnit: this.centemetersToInches(hight)}];
  //      } else {
  //        // Math.round(height * 100) / 100; //round to two decimal places
  //        this.heightUnit = [{ hUnit: hight}];
  //      }
  //    }
  //  }
  //  return heightUnit;
  //}
  /*
   * ---------------------------------------------------------------
   * NOTE: $rootScope.isMetric belongs to signed-in-user
   *     the parameter 'isMetric' belongs to the non-signed-in-user
   *     $rootScope.isMetric may be different than parameter 'isMetric'
   *     Both are the same when the signed-in-user views his/her own profile
   *     This method converts the weight to that of signed-in-user
   * ----------------------------------------------------------------------------------
   */
  //static convertWeightUnit(weght, isMetric) {
  //  let weightUnit : unknown[] = [{ }];

  //  if (weght > 0) {
  //    if (this.rootScopeTs.has(this.kv)) {
  //      this.weightUnit = [
  //        {
  //          wUnit: 'kg',
  //        },
  //      ];
  //    }
  //    if (this.rootScopeTs.has(this.kv)) {
  //      this.weightUnit = [{ hUnit: 'cm'}];
  //      if (isMetric) {
  //        // Math.round(weight * 100) / 100;  //round to two decimal places
  //        this.weightUnit = [{ weight: weght}];
  //      } else {
  //        // Math.round(returnObject.poundsToKilos(weight) * 100) / 100; //round to two decimal places
  //        this.weightUnit = [{ weight: this.poundsToKilos(weght)}];
  //      }
  //    } else {
  //      weightUnit = [{ wUnit: 'lbs'}];
  //      this.weightUnit = [{ wUnit: 'inches'}];
  //      if (isMetric) {
  //        // Math.round(returnObject.centemetersToInches(height) * 100) / 100; //round to two decimal places
  //        this.weightUnit = [{ wUnit: this.centemetersToInches(weght)}];
  //      } else {
  //        // Math.round(weight * 100) / 100; //round to two decimal places
  //        weightUnit = [{ weight: weght}];
  //      }
  //    }
  //  }
  //  return weightUnit;
  //}
  // ---------------------------------------------------------------
  // convertClientComputedDistance(distUnit = []) {
  //  const distanceUnit  = [
  //    {
  //      distance: 0,
  //    },
  //    {
  //      dUnit: 'km',
  //    },
  //  ];

  //  if (distUnit[0].distance > 0) {
  //    if (this.rootScopeTs.has(this.kv)) {
  //      // if the signedInUser's preference of unit is Metric
  //      distanceUnit[0].dUnit = 'km';
  //      if (distUnit[0].dUnit.toLowerCase().indexOf('km') !== -1) {
  //        // calculated unit is in KiloMeter
  //        if (distUnit[0].distance >= 1) {
  //          distanceUnit[0].distance = Math.round(distUnit[0].distance * 10) / 10; // round to two decimal places
  //        } else {
  //          distanceUnit[0].dUnit = 'meter(s)';
  //          distanceUnit[0].distance = Math.round(distUnit[0].distance * 1000 * 10) / 10; // round to two decimal places
  //        }
  //      } else {
  //        // calculated unit is in Miles, but the signedInUser's preference is in Metric, so convert to metric unit
  //        distanceUnit[0].distance = this.milesToMeters(distUnit[0].distance);
  //        if (distanceUnit[0].distance >= 1000) {
  //          distanceUnit[0].distance = Math.round((distanceUnit[0].distance / 1000) * 10) / 10; // round to two decimal places
  //        } else {
  //          distanceUnit[0].dUnit = 'meter(s)';
  //          distanceUnit[0].distance = Math.round(distanceUnit[0].distance * 10) / 10; // round to two decimal places
  //        }
  //      }
  //    } else {
  //      // the signedInUser's preference of unit is Imperial System
  //      distanceUnit[0].dUnit = 'mile(s)';
  //      // calculated unit is in KiloMeter,  but the signedInUser's preference is in Imperial System, so convert to Miles unit
  //      if (distUnit[0].dUnit.toLowerCase().indexOf('km') !== -1) {
  //        distanceUnit[0].distance = this.metersToMiles(distUnit[0].distance); // convert to miles
  //        if (distanceUnit[0].distance >= 1) {
  //          distanceUnit[0].distance = Math.round(distanceUnit[0].distance * 10) / 10; // round to one decimal places
  //        } else {
  //          distanceUnit[0].dUnit = 'feet'; // 1 mile == 5280 feet
  //          distanceUnit[0].distance = Math.round(distanceUnit[0].distance * 5280 * 10) / 10; // round to one decimal places
  //        }
  //      } else {
  //        // calculated unit is in Miles, so no need to convert
  //        if (distUnit[0].distance >= 1) {
  //          distanceUnit[0].distance = Math.round(distUnit[0].distance * 10) / 10; // round to one decimal places
  //        } else {
  //          distanceUnit[0].dUnit = 'feet'; // 1 mile == 5280 feet
  //          distanceUnit[0].distance = Math.round(distUnit[0].distance * 5280 * 10) / 10; // round to one decimal places
  //        }
  //      }
  //    }
  //  }
  //  return distanceUnit;
  // }
  /*
   * ---------------------------------------------------------------
   * NOTE: receiverInfo.distance is in Meters sent by the server
   *     This function assumes that the input-variable be a distance in Meters
   * ---------------------------------------------------------------
   */
  //static convertServerSentDistance(distanceUnit) {
  //  if (distanceUnit.distance > 0) {
  //    if (this.rootScopeTs.has(this.kv)) {
  //      if (distanceUnit.distance >= 1000) {
  //        // receiverInfo.distance is in Meters sent by the server
  //        distanceUnit.dUnit = 'km';
  //        distanceUnit.distance = Math.round((distanceUnit.distance / 1000) * 10) / 10;
  //      } else {
  //        distanceUnit.dUnit = 'm';
  //        distanceUnit.distance = Math.round(distanceUnit.distance * 10) / 10;
  //      }
  //    } else {
  //      const distance = this.metersToMiles(distanceUnit.distance);

  //      if (distance >= 1) {
  //        distanceUnit.dUnit = 'miles';
  //        distanceUnit.distance = Math.round(distance * 10) / 10;
  //      } else {
  //        distanceUnit.dUnit = 'feet';
  //        distanceUnit.distance = Math.round(distance * 5280 * 10) / 10;
  //      }
  //    }
  //  }
  //  return distanceUnit;
  //}
  // ---------------------------------------------------------------
  // convertProfileTilesUnit(profileTiles: ProfileTile[]): ProfileTile[] {
  //  if (profileTiles.length > 0) {
  //    for (let i = 0; i < profileTiles.length; i++) {
  //      let distanceUnit = [{ unit: 'km'}, { distance: 0}];

  //      distanceUnit[i].distance = profileTiles[i].distance;
  //      distanceUnit[i].unit = profileTiles[i].unit;
  //      distanceUnit = this.convertServerSentDistance(distanceUnit);

  //      profileTiles[i].distance = distanceUnit[i].distance;
  //      profileTiles[i].unit = distanceUnit[i].unit;
  //    }
  //  }
  //  return profileTiles;
  // }
  /*
   * ---------------------------------------------------------------
   * alert ('in UnitConversionService');
   * ---------------------------------------------------------------
   */
}
