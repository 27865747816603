
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PhotoAndPrimaryPhotoMgmtServiceInterface } from '../../interfaces/serviceInterfaces/photoServiceIntefaces/photoAndPrimaryPhotoMgmtServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Counters } from '../../models/common/counters.model';
import { Photo } from '../../models/common/photo.model';
import { PhotoBn } from '../../models/common/photoBn.model';
import { PhotoMgmtAction } from '../../models/common/photoMgmtAction.model';
import { KvMemberActivityGlyph } from '../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { PagerMemberActivity } from '../../models/pagination/pagerMemberActivity.model';
import { PagerPhotoMgmt } from '../../models/pagination/pagerPhotoMgmt.model';
import { ProfileImage } from '../../models/profile/profileImage.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { SignedInUserService } from '../commonServiceService/signedInUserService.service';
import { SitUserService } from '../commonServiceService/sitUserService.service';
import { HttpService } from '../coreServiceService/httpService.service';
import { DbDexieToDictionaryService } from '../dbServiceService/dbDexieToDictionaryService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { MemberService } from '../memberServiceService/memberService.service';
import { AbstractPagerVPhotoNavService } from '../pagerServiceService/abstractPagerVPhotoNavService.service';
import { GlyphiconMenuService } from '../GlyphiconService/glypgiconMenuService.service';
import { PagerSupportService } from '../pagerServiceService/pagerSupportService.service';
// import { PagerVKvPhotoNavService } from '../../app/pagerModule/pagerVPhotoNav/pagerVPhotoNavService.service';
import { BackgroundImageAnimationService } from '../rendererServiceService/backgroundImageAnimationService.service';
import { ArrayServiceStatic } from '../staticServices/arrayServiceStatic.service';
import { ArraySupportServiceStatic } from '../staticServices/arraySupportServiceStatic.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../staticServices/jsRegExpServiceStatic.service';
import { MessageBuilderServiceStatic } from '../staticServices/messgeBuilderServiceStatic.service';
import { KvPhotoService } from './kvPhotoService.service';
import { PhotoBnService } from './photoBnService.service';
import { PhotoPrivacyService } from './photoPrivacyService.service';
import { StringServiceStatic } from '../staticServices/stringServiceStatic.service';

@Injectable({
  providedIn: 'root',
})

// ------------------------------------------------------------------------------------------------
// Disclaimer:  this service accomplishes all activities regarding signedInUser's ProfilePics only,
//              i.e. creating the profilePics for the signedInUser (1st  person user), and all of
//              the activities regarding managing the profilePics such as making a photo private
//              if it is public and vice versa, and generating the PhotoMgmtNavigation buttons.
// ------------------------------------------------------------------------------------------------
export class PhotoAndPrimaryPhotoMgmtService extends PhotoBnService implements OnDestroy, PhotoAndPrimaryPhotoMgmtServiceInterface {
  
  
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public counters : Counters = new Counters();
  public currentBne : BoxNonceEntity = new BoxNonceEntity();
  public currentKvPhoto : KvPhoto = new KvPhoto();
  public currentStateOfPhoto : Photo = new Photo();
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isOnLine = false;
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isViewMember = false;
  public kvMemActGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public message = '';
  public memberViewGlyphNames : string[] = [];
  // public memberViewMenuNames: string[] = ['chat', 'email', 'note', 'buddy', 'like', 'private', 'public', 'tala', 'block', 'unblock', 'dislike'];
  public pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public pagerVMemberActivityForPhotoMgmt : PagerMemberActivity = new PagerMemberActivity(); // for primary/private/public photo selection by signedInUser
  public pagerVMemberActivityForViewMember : PagerMemberActivity = new PagerMemberActivity();
  public photoMgmtGlyphNames : string[] = [];
  public photoMgmtAction : PhotoMgmtAction = new PhotoMgmtAction();
  public primaryKvPhoto : KvPhoto = new KvPhoto();
  public profileImageArr : ProfileImage[] = [];
  public profilePics : ProfilePics = new ProfilePics();
  public signedInUser : SitUser = new SitUser();
  public sitUser : SitUser = new SitUser();
  // public timer : any;
  public timerArray : any[] = [];


  constructor (
    public abstractPagerVPhotoNavService : AbstractPagerVPhotoNavService,
    public bias : BackgroundImageAnimationService,
    public dictionaryService : DictionaryService,
    public httpService : HttpService,
    public dbDexieToDictionaryService : DbDexieToDictionaryService,
    public photoBnService : PhotoBnService,
    public glyphiconMenuService : GlyphiconMenuService,
    public kvPhotoService : KvPhotoService,
    public memberService : MemberService,
    public pagerSupportService : PagerSupportService,
    // public pagerVKvPhotoNavService : PagerVKvPhotoNavService,
    public photoPrivacyService : PhotoPrivacyService,
    public sitUserService : SitUserService,
    public signedInUserService : SignedInUserService,
  ) {
    super(dictionaryService,
      httpService,
      dbDexieToDictionaryService,
      photoPrivacyService,
      sitUserService,);
    // -----------------------------------------------------------
    //EmitterSubjectService.photoMgmtActionEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe(result => {
    //    debugger;
    //    this.photoMgmtAction = result as PhotoMgmtAction;
    //    this.sendPhotoMgmtAction(this.photoMgmtAction); //.subscribe(data => {
    //    //  let tPPics = data;
    //    //  let cPhoto = new Photo();
    //    //  cPhoto = CopyServiceStatic.copyFromPhotoBnToClientPhoto(cPhoto, data);
    //    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(cPhoto) && this.photoMgmtAction.action.toLowerCase().indexOf('primary') === -1) {
    //    //    debugger;
    //    //    let kvPhoto = CopyServiceStatic.copyFromPhotoToKvPhoto(cPhoto);

    //    //    this.executeOnNonPrimaryPhotoMgmtAction(kvPhoto).subscribe(result => {
    //    //      this.profilePics = result;
    //    //    });
    //    //  }
    //    //  else {
    //    //    debugger;
    //    //    this.executePhotoMgmtPrimaryAction();
    //    //  }
    //    //});

    //  });

    // -----------------------------------------------------------
    this.initialize();
  }
  // ------------------------------------------------------------
  initialize () : void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.signedInUser = EmitterSubjectService.getSignedInUser();
    this.sitUser = EmitterSubjectService.getSitUserModel();
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.profilePics = new ProfilePics(); // clear the profilePics on exit
    this.profileImageArr = [];
    this.timerArray.forEach((timer) => clearInterval(timer));
    return true;
  }

  // ---------------------------------------------------------------------------------
  clearCounters () : Promise<any> {
    this.counters = new Counters();
    return new Promise((resolve) => {
      resolve(this.counters);
    });
  }
  // ---------------------------------------------------------------
  clearMemberViewGlyphNames () : any {
    this.memberViewGlyphNames = [];
    this.glyphiconMenuService.clearGlyphMenu();
    return true;
  }
  // ---------------------------------------------------------------
  clearPhotoMgmtGlyphNames () : any {
    this.photoMgmtGlyphNames = [];
    this.glyphiconMenuService.clearGlyphMenu();
    return true;
  }  
  // ---------------------------------------------------------------------------------
  getCounters () : Counters {
    return this.counters;
  }
  // ----------------------------------------------------------------
  getPrimaryKvPhotoFromArr (kvPhotoArr : KvPhoto[]) : any {
    let primaryKvPhoto : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
      this.sitUser = EmitterSubjectService.getSitUserModel();
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
      this.isViewMember = EmitterSubjectService.getIsViewMember();
      if (this.isPhotoMgmt) {
        this.isViewMember = false;
      }

      kvPhotoArr.map(e => {
        if (this.isPhotoMgmt) {
          if (e.sitUserId == this.loginSuccess.signedInUserId && e.photoBnId == this.loginSuccess.primaryPhotoBnId) {
            primaryKvPhoto = e;
          }
        }
        else if (this.isViewMember) {
          if (e.sitUserId == this.sitUser.sitUserId && e.photoBnId == this.sitUser.primaryPhotoBnId) {
            primaryKvPhoto = e;
          }
        }
      })
    }
    return primaryKvPhoto;
  }

  // ---------------------------------------------------------------------------------
  getProfilePics () : ProfilePics {
    return this.profilePics;
  }

  // ----------------------------------------------------------------
  // Note: get the KvPhotoArr of the user from the KvPhotoDictionary:
  // ----------------------------------------------------------------
  getUserKvPhotosFromDictionary (sitUser : SitUser) : KvPhoto[] | any {
    let dKvpArr = this.dictionaryService.kvPhotoDictionary.size > 0 ? this.dictionaryService.kvPhotoDictionary.values() : [];
    let userKvPhotoArr : KvPhoto[] = [];
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dKvpArr) && dKvpArr.length > 0) {
      dKvpArr.map(e => {
        if (e.sitUserId === sitUser.sitUserId) {
          userKvPhotoArr.push(e);
        }
      })
    }
    return userKvPhotoArr;
  }
  // ---------------------------------------------------------------
  public getPrimaryImageFromLoginSuccess () : string {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryPhotoBnJson)
      && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryImage)) {
      // debugger;
      var photoBn = JSON.parse(this.loginSuccess.primaryPhotoBnJson) as PhotoBn;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn)) {
        var pbn = this.photoBnService.unsaltPrimaryPicPhotoBnJson(JSON.parse(this.loginSuccess.primaryPhotoBnJson) as PhotoBn);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn)) {
          this.loginSuccess.primaryImage = this.photoBnService.getPrimaryPicFromPhotoBn(pbn);
          // debugger;
        }
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryImage) && this.loginSuccess.primaryImage.length > 0) {
        EmitterSubjectService.setLoginSuccess(this.loginSuccess);
      }
    }
    return this.loginSuccess.primaryImage;
  }
  // ----------------------------------------------------------------
  // getPrimaryPicFromPhotoBn (photoBn : PhotoBn) : any {
  //  let pic = '';
  //  let boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.box)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.nonce)
  //    && photoBn.box.length > 0 && photoBn.nonce.length > 0) {
  //    boxNonceEntity.box = photoBn.box;
  //    boxNonceEntity.nonce = photoBn.nonce;
  //    pic = SlakezSaltServiceStatic.boxUnsalt(boxNonceEntity)
  //    photoBn.pic = photoBn.image = photoBn.imageString = pic
      
  //  }
  //  return photoBn.pic;
  // }
  // ----------------------------------------------------------------
  initializeMemberViewComponent (sitUser: any) : ProfilePics {
    this.profilePics = EmitterSubjectService.getProfilePics();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) && sitUser.sitUserId > 0) {
      sitUser = EmitterSubjectService.getSitUserModel();
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics) && this.profilePics.sitUserId > 0) {
      debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.filteredKvPhotoArr) && this.profilePics.filteredKvPhotoArr.length > 0) {
        debugger;
        this.currentKvPhoto = this.profilePics.filteredKvPhotoArr[ 0 ];
        this.currentKvPhoto.itemNum = 0; // Note: itemNum=fromComponentOrService === index=fromView Ref: KvPhoto model        
      }
      else {
        debugger;
        this.currentKvPhoto = CopyServiceStatic.copySitUserToKvPhoto(sitUser);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto)) {
          this.currentKvPhoto.itemNum = 0; // Note: itemNum=fromComponentOrService === index=fromView Ref: KvPhoto model
          this.profilePics.filteredKvPhotoArr.push(this.currentKvPhoto);
        }
      }
    }
    debugger;
    // set also the pagerPhotoNav and currentKvPhoto's pagerPhotoMgmt:
    // ---------------------------------------------------------------
    this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.currentKvPhoto);
    this.kvPhotoService.setCurrentPageKvPhoto(this.currentKvPhoto);
    EmitterSubjectService.emitCurrentPage(this.currentKvPhoto);
    EmitterSubjectService.emitProfileImages(this.profilePics.filteredKvPhotoArr);
    return this.profilePics;
  }

  // ------------------------------------------------------------
  initializePhotoMgmtComponent () : ProfilePics {
    this.profilePics = EmitterSubjectService.getProfilePics();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics) && this.profilePics.sitUserId > 0) {
      debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoArr) && this.profilePics.unFilteredKvPhotoArr.length > 0) {
        debugger;
        this.currentKvPhoto = this.profilePics.unFilteredKvPhotoArr[ 0 ];
        this.currentKvPhoto.itemNum = 0; // Note: itemNum=fromComponentOrService === index=fromView Ref: KvPhoto model        
      }
      else {
        debugger;
        this.currentKvPhoto = CopyServiceStatic.copyFromLoginSuccessToKvPhoto(this.loginSuccess);

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto)) {
          this.currentKvPhoto.itemNum = 0; // Note: itemNum=fromComponentOrService === index=fromView Ref: KvPhoto model
          this.profilePics.unFilteredKvPhotoArr.push(this.currentKvPhoto);
        }
      }
    }
    else {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {        
        this.signedInUser = CopyServiceStatic.copyLoginSuccessToSitUser(this.loginSuccess);

        this.profilePics = CopyServiceStatic.createProfilePicsModelFromSitUser(this.signedInUser);
        let kvp = CopyServiceStatic.copyLoginSuccessToKvPhoto(this.loginSuccess);
        this.profilePics.unFilteredKvPhotoArr.push(kvp); // first entry
        this.currentKvPhoto = this.profilePics.unFilteredKvPhotoArr[ 0 ] as KvPhoto;
        debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentKvPhoto)) {
          this.currentKvPhoto.itemNum = 0; // Note: itemNum=fromComponentOrService === index=fromView Ref: KvPhoto model       
        }
      }
    }
    debugger;
    // set also the pagerPhotoNav and currentKvPhoto's pagerPhotoMgmt:
    // ---------------------------------------------------------------
    this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.currentKvPhoto);
    this.kvPhotoService.setCurrentPageKvPhoto(this.currentKvPhoto);
    EmitterSubjectService.emitCurrentPage(this.currentKvPhoto);
    EmitterSubjectService.emitProfileImages(this.profilePics.unFilteredKvPhotoArr);
    return this.profilePics;
  }
 
  // ---------------------------------------------------------------------------------
  setCounters (value : Counters) : any {
    this.counters = value;
    return true;
  }
  // ---------------------------------------------------------------------------------
  setProfileImageArr (value : ProfileImage[]) : any {
    this.profileImageArr = value;
    return true;
  }
  // ---------------------------------------------------------------------------------
  setProfilePics (value : ProfilePics) : any {
    this.profilePics = value;
    return true;
  }
  // ---------------------------------------------------------------------------------
  updateProfilePicsWithKvPhoto (pPics : ProfilePics, kvp : KvPhoto) : any {
    // if any of the two array is missing, update the originals first:
    // ---------------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr) && pPics.unFilteredKvPhotoArr.length > 0
      && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr) || pPics.filteredKvPhotoArr.length === 0) {
      pPics.filteredKvPhotoArr = this.photoPrivacyService.filterAndSortProfileKvPhotoArr(pPics.unFilteredKvPhotoArr);
    }
    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr) && pPics.filteredKvPhotoArr.length > 0) {
      pPics.unFilteredKvPhotoArr = pPics.filteredKvPhotoArr;
      pPics.unFilteredKvPhotoArr.forEach(e => {
        if (e.sitUserId === kvp.sitUserId && !e.isUnlocked && e.isPrivate) {
          e.isUnlocked = !e.isUnlocked;
        }
      })
    }
    // now update both arrays with the new kvp:
    // ----------------------------------------
    var index = -1;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && pPics.sitUserId > 0) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr) && pPics.filteredKvPhotoArr.length > 0) {
        index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdOrPhotoIdInKvPhotoArr(pPics.filteredKvPhotoArr, kvp.photoBnId, kvp.photoId);
        if (index > -1) {
          debugger;
          pPics.filteredKvPhotoArr[ index ] = new KvPhoto().copySourceToDestination(pPics.filteredKvPhotoArr[ index ], kvp);
        }
        else {
          pPics.filteredKvPhotoArr.push(kvp);
				}
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr) && pPics.unFilteredKvPhotoArr.length > 0) {
        index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdOrPhotoIdInKvPhotoArr(pPics.filteredKvPhotoArr, kvp.photoBnId, kvp.photoId);
        if (index > -1) {
          debugger;
          pPics.filteredKvPhotoArr[ index ] = new KvPhoto().copySourceToDestination(pPics.filteredKvPhotoArr[ index ], kvp);
        }
        else {
          pPics.unFilteredKvPhotoArr.push(kvp);
        }
      }     
    }
    return pPics;
  }
  //
  // ---------------------------------------------------------------
  // Note: This alters the state of privacy of the photo.
  // Note: this should be called only after PhotoMgmtAction on 'private/public' is performed.
  //
  // ---------------------------------------------------------------
  //  1. get the kvPhotoDictionary's data for the user and put the values in the profilePics.unFilteredKvPhotoDictionary
  //  2. set the currentKvPhoto's value of isPrimary to false
  //  3. set the profilePics.unFilteredKvPhotoArr from it's own kvPhotoDictionary
  //  4. set the rest of the profilePics model's data and save it to the dictionary and indexedDb
  //  5. display the ProfilePics ( profileImage, KvPhotoTasks, and may pagerVKvPhotoNav)
  // ---------------------------------------------------------------  
  executeOnNonPrimaryPhotoMgmtAction (currKvPhoto : KvPhoto) : Observable<ProfilePics | any>
  {    
    return new Observable((subscriber) => {
      // debugger;
      let date = new Date();
      let profilePics = new ProfilePics();
      this.sitUser = EmitterSubjectService.getSitUserModel();
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
        debugger;
        console.log('calling fetchSitUser() from photoAndPrimaryPhotoMgmtServiceService@ln425')
        this.sitUserService.getSitUserFromDictionaryOrServer(currKvPhoto.sitUserId).subscribe(result => {
          this.sitUser = result;
          debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser)
            && this.sitUser.sitUserId > 0
            && this.sitUser.sitUserId === currKvPhoto.sitUserId) {
            profilePics = CopyServiceStatic.createProfilePicsModelFromSitUser(this.sitUser);
            if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)) {
              profilePics = this.updateProfilePicsWithKvPhoto(profilePics, currKvPhoto);
               debugger;
            }
          }
        })
      }
      
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currKvPhoto) && this.dictionaryService.profilePicsDictionary.has(currKvPhoto.sitUserId)) {
          debugger;      
        profilePics = this.dictionaryService.profilePicsDictionary.get(currKvPhoto.sitUserId) as ProfilePics;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics) && profilePics.sitUserId > 0) {
          profilePics = this.updateProfilePicsWithKvPhoto(profilePics, currKvPhoto);
            debugger;
        }
      }     
      else {
        profilePics = EmitterSubjectService.getProfilePics();
        if (profilePics.sitUserId === currKvPhoto.sitUserId) {
          profilePics = this.updateProfilePicsWithKvPhoto(profilePics, currKvPhoto);
           debugger;
				}
      }
      subscriber.next(profilePics);   
    })
  }
  // ------------------------------------------------------------------
  //  1. get the kvPhotoDictionary's data for the user and put the values in the profilePics.unFilteredKvPhotoDictionary
  //  2. set the currentKvPhoto's value of isPrimary to false
  //  3. set the profilePics.unFilteredKvPhotoArr from it's own kvPhotoDictionary
  // ------------------------------------------------------------------
  public populateProfilePicsFromKvPhotoDictionary(kvp: KvPhoto, profilePics: ProfilePics): ProfilePics {
    profilePics.unFilteredKvPhotoArr = this.dictionaryService.getUserKvPhotoArrFromKvPhotoDictionary(kvp.sitUserId);
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.unFilteredKvPhotoArr) && profilePics.unFilteredKvPhotoArr.length > 0) {
      profilePics.unFilteredKvPhotoArr.forEach(e => {

        if (e.photoId === kvp.photoId || e.photoBnId === kvp.photoBnId) {
          e.isPrimary = false;
          e.privacy = kvp.privacy;
        }

        profilePics.unFilteredKvPhotoDictionary.set(e.photoId, e);
        this.photoPrivacyService.filterKvPhoto(e).subscribe(data => {
          let tfkvp = data;
          profilePics.filteredKvPhotoArr.push(tfkvp);
          profilePics.filteredKvPhotoDictionary.set(tfkvp.photoId, tfkvp.copy(tfkvp));
        });
      })
      profilePics.unFilteredKvPhotoArr = ArraySupportServiceStatic.makeArrayOfUniqElements(profilePics.unFilteredKvPhotoArr);
      profilePics.filteredKvPhotoArr = ArraySupportServiceStatic.makeArrayOfUniqElements(profilePics.filteredKvPhotoArr);
    }
    return profilePics;
  }
  // ------------------------------------------------------------------
  // set the primaryPhoto only if the signedInUser changed the primary photo:  
  // ------------------------------------------------------------------
  executePhotoMgmtPrimaryAction () : void {
    const photoMgmtAction = EmitterSubjectService.getPhotoMgmtAction();
    debugger;
    if (
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.primaryKvPhoto) &&
      this.primaryKvPhoto.photoId > 0 &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoMgmtAction) &&
      !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoMgmtAction.action) &&
      this.signedInUser.primaryPhotoBnId === this.primaryKvPhoto.photoId &&
      photoMgmtAction.action.toLowerCase().indexOf('primary') !== -1 &&
      this.signedInUser.sitUserId === this.loginSuccess.signedInUserId &&
      this.loginSuccess.primaryPhotoBnId !== this.signedInUser.primaryPhotoBnId
    ) {
      debugger;
      EmitterSubjectService.setPrimaryImageKvPhoto(this.primaryKvPhoto);
      EmitterSubjectService.emitDisplayPrimaryImage(this.primaryKvPhoto);
    }
  }
  // ---------------------------------------------------------------  
  prepareKvPhotoForDisplay (kvPhoto : KvPhoto, isStripUrl : boolean) : KvPhoto | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
        if (kvPhoto.value.length > 0) {
          kvPhoto.value = JsRegExpServiceStatic.stripUrlForImageData(kvPhoto.value);
        }
      }
    }
    return kvPhoto;
  }
  // ---------------------------------------------------------------------
  // Note:  This method does not check whether the value has image-prefix:
  //        So only image-prewfixed data should call this method.
  // ---------------------------------------------------------------------
  prefixUrlToKvPhotoForDisplay (kvPhoto : KvPhoto) : KvPhoto | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
        if (kvPhoto.value.length > 0 && !JsRegExpServiceStatic.photoUrlPrefixPattern.test(kvPhoto.value)) {
          kvPhoto.value = JsRegExpServiceStatic.prefixUrlToImageData(kvPhoto.value);
        }
      }
    }
    return kvPhoto;
  }
  // ---------------------------------------------------------------
  prefixUrlToKvPhotoArrForDisplay (kvpArr : KvPhoto[]) : KvPhoto[] {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
      kvpArr?.forEach(e => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value) && e.value.length > 0) {
          e = this.prefixUrlToKvPhotoForDisplay(e);
        }
      });
    }
    return kvpArr;
  }
  // ---------------------------------------------------------------
  processPrimaryStatusOfUserAction (kvp : KvPhoto) : void {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
      let kvphoto = kvp as KvPhoto;

      if (kvphoto instanceof KvPhoto) {
        // debugger;
        this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvPhoto(kvp);
        EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.kvMemActGlyphArrForPhotoMgmt);

        // TODO: create an emitter for this:
        // this.pagerVKvPhotoNav = this.photoMgmtService.executePagerVKvPhotoNavKvPhotoTasks(kvPhoto);
        // Now update the PagerVKvPhotoNav with the updated value of KvPhoto:
        // ------------------------------------------------------------------
        this.pagerVKvPhotoNav = EmitterSubjectService.getPagerVKvPhotoNav();
        var index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdOrPhotoIdInKvPhotoArr(this.pagerVKvPhotoNav.kvPhotoArr, kvphoto.photoBnId, kvphoto.photoId)
        if (index > -1) {
          this.pagerVKvPhotoNav.kvPhotoArr[ index ] = new KvPhoto().copySourceToDestination(this.pagerVKvPhotoNav.kvPhotoArr[ index ], kvphoto);
        }
        else {
          this.pagerVKvPhotoNav.kvPhotoArr.push(kvphoto);
				}
        debugger;
        this.pagerVKvPhotoNav = this.abstractPagerVPhotoNavService.setupPagerVKvPhotoNav(this.pagerVKvPhotoNav.kvPhotoArr, kvphoto);
        this.abstractPagerVPhotoNavService.executePagerVKvPhotoNavCurrentKvPhotoTasks(kvphoto, this.pagerVKvPhotoNav.kvPhotoArr).subscribe(data => {
          this.pagerVKvPhotoNav = data;
        })
      }
    }
  }
  // ---------------------------------------------------------------
  showGlyphiconMenu (pagerVKvPhotoNav: PagerPhotoMgmt) {
    // -------------------------------------------------------------------------
    //  get the glyphicon-menu of the current-item,
    //  this will automatically display the menu:
    // --------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItem)
      && pagerVKvPhotoNav.currentItem.photoBnId > 0) {
      this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvPhoto(pagerVKvPhotoNav.currentItem);
    }
  }
  // ---------------------------------------------------------------
  hideGlyphiconMenu () {    
      this.kvMemActGlyphArrForPhotoMgmt = null;   
  }
  // ---------------------------------------------------------------
  //updatePrivatePublicStatusOfUserAction (kvp:KvPhoto) : void {
  //{
  //  debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
  //    let kvPhoto = kvp as KvPhoto;
  //    this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.createPhotoMgmtGlyphMenuArrFromKvPhoto(kvp);
  //    debugger;
  //    EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(this.kvMemActGlyphArrForPhotoMgmt);

  // //   if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVMemActGlyphForPhotoMgmt.kvGlyphArr) && pagerVMemActGlyphForPhotoMgmt.kvGlyphArr.length > 0) {
  // //     pagerVMemActGlyphForPhotoMgmt.kvMemActGlyphArrForPhotoMgmt = pagerVMemActGlyphForPhotoMgmt.kvGlyphArr;
  // //     debugger;
  // //     // EmitterSubjectService.emitDisplayPhotoMgmtGlyphiconMenu(pagerVMemActGlyphForPhotoMgmt.kvMemActGlyphArrForPhotoMgmt);
  // //     this.kvMemActGlyphArrForPhotoMgmt = pagerVMemActGlyphForPhotoMgmt.kvMemActGlyphArrForPhotoMgmt;
		//	//}

  //    // Now update the PagerVKvPhotoNav with the updated value of KvPhoto:
  //    // ------------------------------------------------------------------
  //    //this.pagerVKvPhotoNav = EmitterSubjectService.getPagerVKvPhotoNav();
  //    //var index = ArrayServiceStatic.getIndexOfItemByPhotoBnIdOrPhotoIdInKvPhotoArr(this.pagerVKvPhotoNav.kvPhotoArr, kvPhoto.photoBnId, kvPhoto.photoId)
  //    //if (index > -1) {
  //    //  this.pagerVKvPhotoNav.kvPhotoArr[ index ] = new KvPhoto().copySourceToDestination(this.pagerVKvPhotoNav.kvPhotoArr[ index ], kvPhoto);

  //    //  // now set the pager for the new currentItem:
  //    //  // ------------------------------------------
  //    //  this.pagerVKvPhotoNav = this.pagerVKvPhotoNavService.setupPagerVKvPhotoNav(this.pagerVKvPhotoNav.kvPhotoArr, this.pagerVKvPhotoNav.kvPhotoArr[ index ]);
  //    //}
  //    //else {
  //    //  this.pagerVKvPhotoNav.kvPhotoArr.push(kvPhoto);

  //    //  // now set the pager for the new currentItem:
  //    //  // ------------------------------------------
  //    //  this.pagerVKvPhotoNav = this.pagerVKvPhotoNavService.setupPagerVKvPhotoNav(this.pagerVKvPhotoNav.kvPhotoArr, kvPhoto);
  //    //}
  //    //  this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt; // Important!
  //    //  debugger;
  //    //  EmitterSubjectService.setPagerVKvPhotoNav(this.pagerVKvPhotoNav);
  //    // EmitterSubjectService.emitDisplayMyPagerVKvPhotoNav(this.pagerVKvPhotoNav);
  //    }
  //  }
	// }
  /*
   * ---------------------------------------------------------------
   * Note : Important!!
   *     For member's action on a photo's primary/privacy status server-db's 'SITUser' is the single-point-of-trurh!
   *     Therefore, a copy of the SITUser of the user must be obtained on 'primary' action before and after,
   *     and make appropriate decision for the updated state of the data.
   *
   *     Also note that this method can effect up to two (2) images.
   *     This happens when user acts on 'primary' button.
   *     In this case the former primary image will become public and the new primary image.
   *
   *     When the user acts on 'private/public' button, it effects only that image.
   *
   * ---------------------------------------------------------------
   */
  sendPhotoMgmtAction(photoMgmtAction: PhotoMgmtAction):  Observable<any> {
    // debugger;
    return new Observable<any>(subscriber => {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoMgmtAction) &&
        (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoMgmtAction.action) || photoMgmtAction.photoBnId > 0 || photoMgmtAction.photoId > 0)) {
        // debugger;
        this.photoMgmtAction = JSON.parse(JSON.stringify(photoMgmtAction));
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();

        if (this.loginSuccess.signedInUserId > 0 && (photoMgmtAction.photoBnId > 0)) {
          photoMgmtAction.kvg = null;
          this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(photoMgmtAction);

          let url = '';

          if (this.photoMgmtAction.action.toLowerCase().indexOf('primary') !== -1) {
            url = '/Photo/SetPrimaryPhoto';
          } else {
            url = '/Photo/AltPhotoPrivacy';
          }
          // debugger;

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
            this.httpService
              .postObservable(
                url,
                {
                  box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
                  nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
                },
                'json2text',
              )
              .subscribe(result => {
                // debugger;
                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
                  var kvp = this.processPhotoMgmtActionResult(result); // Note: result == photoBn
                  // debugger;
                  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
                    // debugger;
                    EmitterSubjectService.setCurrentKvPhoto(JSON.parse(JSON.stringify(kvp)));
                    subscriber.next(kvp);
                  }
                  else {
                    subscriber.next(result);
                  }
                }
                else {
                  this.message = 'Observable of sendPhotoMgmtAction(photoId:\'' + this.photoMgmtAction.photoId;
                  this.message += '\');\n returned null value';
                  // EmitterSubjectService.emitMyErrorLog(this.message);
                  MessageBuilderServiceStatic.printMessage(this.photoMgmtAction.sitUserId, this.message);
                }
              },
                (error) => {
                  this.message = 'Error occured in observable of sendPhotoMgmtAction(photoId:\'' + this.photoMgmtAction.photoId;
                  this.message += '\');\n Error message :  ' + error.message;
                  // EmitterSubjectService.emitMyErrorLog(this.message);
                  MessageBuilderServiceStatic.printMessage(this.photoMgmtAction.sitUserId, this.message);
                });
          }
        }
      }
    })
  }
  // -----------------------------------------------------------
  // Note:  The newly uploded PhotoBn contains the photo as fileBytes.
  //        but the previously uploaded/converted PhotoBn does not.
  //        Therefore, has to account for both scenarios.
  // -------------------------------------------------------------
  processPhotoMgmtActionResult (result : any) : KvPhoto
  {
    // debugger;
    var photoBn : PhotoBn = new PhotoBn();
    var pic : any;
    var kvp : any;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.fileBytes)) {
        pic = JsRegExpServiceStatic.prefixPngToImageData(result.fileBytes);
        photoBn = result;
        photoBn.pic = photoBn.imageString = photoBn.image = pic;
        kvp = new PhotoBn().cloneToKvPhoto(photoBn);
        // debugger;
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.box)) {
        this.currentBne = new BoxNonceEntity();
        this.currentBne.box = result.box;
        this.currentBne.nonce = result.nonce;
        // debugger;
        var tPhotoBn = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(this.currentBne)) as PhotoBn;
        // debugger;
        var camelPhotoBn = new PhotoBn().toCamelCase(tPhotoBn);
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelPhotoBn)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(camelPhotoBn.fileBytes)
          && camelPhotoBn.fileBytes.length > 0) {
          pic = JsRegExpServiceStatic.prefixPngToImageData(camelPhotoBn.fileBytes);

          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(photoBn.pic)) {
            // debugger;
            photoBn = new PhotoBn().copySourceToDestination(photoBn, camelPhotoBn);
            photoBn.pic = photoBn.image = photoBn.imageString = pic;
          }
          kvp = new PhotoBn().cloneToKvPhoto(photoBn);
          // debugger;
        }
      }
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
        // debugger;
        EmitterSubjectService.setCurrentKvPhoto(kvp);
        // debugger;
        return kvp;
      }
      else return null;
    }
    else return null;
  }
    
  /*
  * ---------------------------------------------------------------
  * Note: This method will search all the arrays in ProfilePics model
  *       for the target KvPhotos (previous and current PrimaryKvPhoto)
  *       and update previous-primary-photo's status to 'public and non-primary'
  *       and  current-primary-photo's status to 'primary and public'.
  *
  *       It is the most comprehensive and complete search on the model ProfilePics.
  * ---------------------------------------------------------------
  */
  updateProfilePicsArraysOnPrimaryAction (currSitUser : SitUser, pMgmtAction : PhotoMgmtAction) : any {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currSitUser)) {
      this.signedInUser = EmitterSubjectService.getSignedInUser();
    } else {
      this.signedInUser = currSitUser;
    }
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pMgmtAction)) {
      this.photoMgmtAction = EmitterSubjectService.getPhotoMgmtAction();
    } else {
      this.photoMgmtAction = pMgmtAction;
    }

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currSitUser) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pMgmtAction)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)) {
        /*
         * ----------------------------------------------------
         * update filteredKvPhotoArr:
         * ----------------------------------------------------
         */
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.filteredKvPhotoArr) && this.profilePics.filteredKvPhotoArr.length > 0) {
          this.profilePics.filteredKvPhotoArr.map((e) => {
            const elem = e;

            if (elem.photoId !== this.currentStateOfPhoto.photoId) {
              if (elem.isPrimary === true) {
                // the previous-primary-photo
                elem.isPrimary = false;
                elem.privacy = 'public';
                // debugger;
              }
              // else we do not change the previous-non-primary-photo's status
            }
            // a second verification that it is the current-primary-photo
            else if (elem.photoId === this.currentStateOfPhoto.photoId) {
              elem.isPrimary = true;
              /*
               * if the current-primary-image were private.
               * (this will happen when user makes a private-nonPrimary-photo a primaryPhoto)
               */
              if (elem.privacy.toLowerCase().indexOf('private') !== -1) {
                elem.privacy = 'public';
              }
              // debugger;
            }
            this.currentKvPhoto = elem;
          });
        }
        /*
         * ----------------------------------------------------
         * update profileKvPhotoArr:
         * ----------------------------------------------------
         */
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.profileKvPhotoArr) && this.profilePics.profileKvPhotoArr.length > 0) {
          this.profilePics.profileKvPhotoArr.map((e) => {
            const elem = e;

            if (elem.photoId !== this.currentStateOfPhoto.photoId) {
              if (elem.isPrimary === true) {
                // the previous-primary-photo
                elem.isPrimary = false;
                elem.privacy = 'public';
                // debugger;
              }
              // else we do not change the previous-non-primary-photo's status
            } else if (elem.photoId === this.currentStateOfPhoto.photoId) {
              elem.isPrimary = true;
              /*
               * if the current-primary-image were private.
               * (this will happen when user makes a private-nonPrimary-photo a primaryPhoto)
               */
              if (elem.privacy.toLowerCase().indexOf('private') !== -1) {
                elem.privacy = 'public';
              }
              // debugger;
            }

            this.currentKvPhoto = elem;
          });
        }
        /*
         * ----------------------------------------------------
         * update profileImageArr:
         * ----------------------------------------------------
         */
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.profileImageArr) && this.profilePics.profileImageArr.length > 0) {
          this.profilePics.profileImageArr.map((e) => {
            const elem = e;

            if (elem.photoId !== this.currentStateOfPhoto.photoId) {
              if (elem.isPrimary === true) {
                // the previous-primary-photo
                elem.isPrimary = false;
                elem.privacy = 'public';
                // debugger;
              }
              // else we do not change the previous-non-primary-photo's status
            } else if (elem.photoId === this.currentStateOfPhoto.photoId) {
              elem.isPrimary = true;
              const ckvp = EmitterSubjectService.getCurrentKvPhoto();

              // if the current-primary-image were private. (this will happen when user makes a private-nonPrimary-photo a primaryPhoto)
              if (elem.privacy.toLowerCase().indexOf('private') !== -1) {
                elem.privacy = 'public';
              }
              // debugger;
              if (ckvp.photoId === elem.photoId) {
                this.currentKvPhoto = ckvp;
              } else {
                // create a KvPhoto from photo
                const tckvp = CopyServiceStatic.copyFromProfileImageToKvPhoto(elem);

                if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tckvp)) {
                  this.currentKvPhoto = tckvp;
                }
              }
            }
          });
        }

        EmitterSubjectService.setCurrentKvPhoto(this.currentKvPhoto);
        return this.profilePics;
      }
    }
    return null;
  }
  // ==================================================================
}

// ====================================================================
  
  // ---------------------------------------------------------------------------------
  //executePagerVKvPhotoNavTasks (pagerVKvPhotoNav : PagerPhotoMgmt) : Promise<PagerPhotoMgmt | any> {
  //  return new Promise<any>((resolve, reject) => {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItem)) {
  //      debugger;
  //      resolve(this.executePagerVKvPhotoNavKvPhotoTasks(pagerVKvPhotoNav.currentItem, pagerVKvPhotoNav.kvPhotoArr));
  //    }
  //    else {
  //      reject(null);
  //    }
  //  })
  //}

  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // API: !!!!!!
  // ----------------------------------------------------------------
  //  1) This method will simply print the pagerVKvPhotoNav.currentItem/currentKvPhoto
  //      and select the currentKvPhoto, and deselct others.
  //  2) This will set the KvPhoto as background-image.
  //  3) This will get the pagerVKvPhotoNav from Emitter and match with currentKvPhoto with pagerVKvPhotoNad.currentItem,
  //     and if they don't match, it will create a new pagerVKvPhotoNav on the basis of CurrentKvPhoto.
  //  4) this will also cover for the primaryImage case for pagerVKvPhotoNav.
  //  5) This will print the currentKvPhoto respective glyphMenu.
  //  6) This will highlight primaryImage if it finds it.
  // ---------------------------------------------------------------------------------
  // executePagerVKvPhotoNavKvPhotoTasks (currentKvPhoto : KvPhoto, kvpArr : KvPhoto[]) : Promise<PagerPhotoMgmt | any> {
  //  return new Promise<any>((resolve, reject) => {
  //    // debugger;
  //    let pagerVKvPhotoNav = new PagerPhotoMgmt();
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto) && currentKvPhoto.itemNum >= 0 &&
  //      (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto.value) && currentKvPhoto.value.length > 0)
  //      || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentKvPhoto.image) && currentKvPhoto.image.length > 0)) {
  //      // debugger;
  //      pagerVKvPhotoNav = EmitterSubjectService.getPagerPhotoMgmt();

  //      //  check to see that the Emitter's pagerPhotoMgmt is same as currentKvPhoto's pagerPhotoMgmt,
  //      //  otherwise create a new pagerPhotoMgmt for currentKvPhoto:
  //      //  Note: PhotoId or PhotoBnId or both may be 0, so need to check for currentKvPhoto.image:
  //      // -------------------------------------------------------------------------------------------
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
  //        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItem)
  //        && (pagerVKvPhotoNav.currentItem.photoId !== currentKvPhoto.photoId
  //          || pagerVKvPhotoNav.currentItem.photoBnId !== currentKvPhoto.photoBnId)
  //          || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem.image)
  //          && this.pagerVKvPhotoNav.currentItem.image.length > 0)) {
  //        // debugger;
  //        pagerVKvPhotoNav = this.pagerVKvPhotoPhotoNavService.getPagerVKvPhotoForCurrentPageInKvPhotoArr(currentKvPhoto, kvpArr);
  //      }

  //      // debugger;
  //      // kvPhoto's image as background:
  //      // ------------------------------
  //      this.bias.updateBackgroundImageOutsideAngular(pagerVKvPhotoNav.currentItem.value);
  //      this.sitUser = EmitterSubjectService.getSitUserModel();
  //      this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
  //      this.isViewMember = EmitterSubjectService.getIsViewMember();
  //      this.isMyProfile = EmitterSubjectService.getIsMyProfile();

  //      // Note: disply the glyphMenu for currentKvPhoto:
  //      // ----------------------------------------------
  //      if (this.isPhotoMgmt || this.isMyProfile) {
  //        // debugger;
  //        // glyphMenu for PhotoMgmt.currentKvPhoto:
  //        // --------------------------------------- 
  //        this.pagerVMemberActivityForPhotoMgmt = this.glyphiconMenuService.getPagerVMemActGlyphMenuForPhotoMgmt(pagerVKvPhotoNav.currentItem);
  //        // debugger;
  //        pagerVKvPhotoNav.pagerVMemberActivityForPhotoMgmt = this.pagerVMemberActivityForPhotoMgmt;
  //        // debugger;
  //        this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(pagerVKvPhotoNav.currentItem); // will display photo primary/private/public glyphs
  //        pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;
  //        this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, pagerVKvPhotoNav.currentItem);
  //        // debugger;
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForPhotoMgmt)
  //          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForPhotoMgmt.pagedItems)
  //          && this.pagerVMemberActivityForPhotoMgmt.pagedItems.length > 0) {
  //          // glyphMenu for PhotoMgmt.currentKvPhoto:
  //          // ---------------------------------------
  //          // debugger;
  //          this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.pagerVMemberActivityForPhotoMgmt.pagedItems, this.pagerVMemberActivityForPhotoMgmt.currentItem, "pagerVGlyphMgmt-");
  //        }
  //        else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvMemActGlyphArrForPhotoMgmt) && this.kvMemActGlyphArrForPhotoMgmt.length > 0) {
  //          // glyphMenu for PhotoMgmt.currentKvPhoto:
  //          // ---------------------------------------
  //          // debugger;
  //          this.bias.selectCurrentElemDeselctOthersForKvMAGlyph(this.kvMemActGlyphArrForPhotoMgmt, this.kvMemActGlyphArrForPhotoMgmt[ 0 ], "pagerVGlyphMgmt-");
  //        }
  //      }
  //      else if (this.isViewMember) {
  //        this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.pagedItems, pagerVKvPhotoNav.currentItem);
  //        // debugger;
  //        if (this.sitUser.sitUserId > 0) {
  //          pagerVKvPhotoNav.kvMemActGlyphArrForViewMember = this.kvMemActGlyphArrForViewMember = this.glyphiconMenuService.displayViewMemberGlyphMenu(this.sitUser.sitUserId);
  //        }
  //        this.pagerVMemberActivityForViewMember = this.glyphiconMenuService.getPagerVMemActGlyphMenuForViewMember(currentKvPhoto.pageNum > 0 ? currentKvPhoto.pageNum : 1, this.sitUser);
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVMemberActivityForViewMember)) {
  //          pagerVKvPhotoNav.pagerVMemberActivityForViewMember = this.pagerVMemberActivityForViewMember;
  //          // debugger;
  //        }
  //      }
  //      EmitterSubjectService.setPagerPhotoMgmt(pagerVKvPhotoNav);
  //    }
  //    // debugger;
  //    resolve(pagerVKvPhotoNav);
  //  })
  // }
  // ----------------------------------------------------------------
  //  This method will simply print the pagerVKvPhotoNav.pagedItems
  //  and select the currentKvPhoto, and deselct others:
  //  Will also cover for the primaryImage case for pagerVKvPhotoNav.
  // ----------------------------------------------------------------
  // executePagerVKvPhotoNavPagedItems (pagerVKvPhotoNav : PagerPhotoMgmt) : Promise<PagerPhotoMgmt | any> {
  //  return new Promise<any>((resolve, reject) => {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.pagedItems) && pagerVKvPhotoNav.pagedItems.length > 0) {

  //      this.pagerVKvPhotoNav = pagerVKvPhotoNav;
  //      // debugger;
  //      this.pagerVKvPhotoNav.pagedItems.forEach(e => {
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.value)) {
  //          // debugger;
  //          this.bias.setPagerVNavKvPhotoImage(e);
  //        }
  //      });
  //      // debugger;
  //    }
  //    resolve(this.pagerVKvPhotoNav);
  //  });
  // }

  //// ----------------------------------------------------------------
  //getKvPhotoUrl (kvPhoto : KvPhoto) : any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
  //    // debugger;
  //    return 'url(\'' + kvPhoto.value + '\')';
  //  }
  //}
  //// ----------------------------------------------------------------
  //getKvPhotoWithoutUrl (kvPhoto : KvPhoto) : any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.value)) {
  //    // debugger;
  //    kvPhoto.value = JsRegExpServiceStatic.normalizeImageDataWithRegExp(kvPhoto.value);
  //    return kvPhoto.value;
  //  }
  //}
  //// ------------------------------------------------------------
  //isInPagedItems (pagerVKvPhotoNav : PagerPhotoMgmt, kvPhoto : KvPhoto) : boolean {
  //  let isFound = false;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
  //    for (let i = 0; i < pagerVKvPhotoNav.pagedItems.length; i++) {
  //      if (pagerVKvPhotoNav.pagedItems[ i ].photoBnId === kvPhoto.photoBnId) {
  //        isFound = true;
  //        break;
  //      }
  //    }
  //  }
  //  return isFound;
  //}

  //// ------------------------------------------------------------
  //getIsKvData (kvPhoto : KvPhoto) : boolean {
  //  this.isKvData = kvPhoto
  //    && kvPhoto.value
  //    && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1
  //    && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') === -1
  //    && kvPhoto.value.toString().toLowerCase().indexOf('.png') === -1
  //    && kvPhoto.value.toString().toLowerCase().indexOf('.jpg') === -1;
  //  return this.isKvData;
  //}
  //// ------------------------------------------------------------
  //getIsKvFile (kvPhoto : KvPhoto) : boolean {
  //  this.isKvFile = kvPhoto
  //    && kvPhoto.value
  //    && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') === -1
  //    && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') === -1
  //    && (kvPhoto.value.toString().toLowerCase().indexOf('.png') !== -1 || kvPhoto.value.toString().toLowerCase().indexOf('.jpg') !== -1);
  //  return this.isKvFile;
  //}
  //// ------------------------------------------------------------
  //getIsKvUrlData (kvPhoto : KvPhoto) : boolean {
  //  this.isKvUrlData = kvPhoto
  //    && kvPhoto.value
  //    && kvPhoto.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1
  //    && kvPhoto.value.toString().toLowerCase().indexOf('url(\'') !== -1
  //    && kvPhoto.value.toString().toLowerCase().indexOf('.png') === -1
  //    && kvPhoto.value.toString().toLowerCase().indexOf('.jpg') === -1;
  //  return this.isKvUrlData;
  //}


  //// ------------------------------------------------------------
  //isNextOrLastPage (pagerVKvPhotoNav : PagerPhotoMgmt) : boolean {
  //  this.isKvNext = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav?.lastPage)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav?.nextPage)
  //    && pagerVKvPhotoNav.currentPage >= pagerVKvPhotoNav?.firstPage
  //    && (pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav?.nextPage
  //      || pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav?.lastPage)
  //  return this.isKvNext;
  //}
  //// ------------------------------------------------------------
  //isPreviousOrFirstPage (pagerVKvPhotoNav : PagerPhotoMgmt) : boolean {
  //  this.isKvPrev = !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.firstPage)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.previousPage)
  //    && (pagerVKvPhotoNav.currentPage >= pagerVKvPhotoNav.firstPage
  //      || pagerVKvPhotoNav.currentPage <= pagerVKvPhotoNav.previousPage)
  //  return this.isKvPrev;
  //}
  // ------------------------------------------------------------
  // Note: This should be called when a pager is needed.
  // ------------------------------------------------------------
  // setPageOfPhotoNav (pageNum : number, kvPhotoArr : KvPhoto[]) : any {
  //  // debugger;
  //  let currentPageNum = 1;
  //  let pageSize = 3;
  //  if (pageNum >= 1) {
  //    currentPageNum = pageNum;
  //  }
  //  else {
  //    currentPageNum = 1;
  //  }
  //  if (pageSize <= 0) {
  //    pageSize = EmitterSubjectService.getPageSize();
  //  }

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {

  //    this.pagerVKvPhotoNav = (this.pagerPhotoMgmtService as PagerPhotoMgmtService).getPagerUpTo10(kvPhotoArr, currentPageNum, pageSize); //get pager

  //    // Note:  since the primaryImage can be created from sitUser/signedInUser/loginSuccess,
  //    //        and they may lack either or both PhotoBnId and PhotoId, we also need to check
  //    //        for image value for this segment of code to execute properly.
  //    // ------------------------------------------------------------------------------------
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
  //      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)
  //      && (this.pagerVKvPhotoNav.currentItem.photoBnId > 0
  //        || this.pagerVKvPhotoNav.currentItem.photoId > 0
  //        || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem.image)  
  //          && this.pagerVKvPhotoNav.currentItem.image.length > 0)
  //      )
  //    ) {
  //       debugger;

  //      this.executePagerVKvPhotoNavKvPhotoTasks(this.pagerVKvPhotoNav.currentItem, kvPhotoArr).then(data => {
  //        this.pagerVKvPhotoNav = data;
  //         debugger;

  //        this.executePagerVKvPhotoNavPagedItems(this.pagerVKvPhotoNav).then(data1 => {
  //          // debugger;
  //          this.pagerVKvPhotoNav = data1; EmitterSubjectService.setPagerPhotoMgmt(this.pagerVKvPhotoNav);
  //          EmitterSubjectService.emitRunNgAfterViewInit(true);
  //          return this.pagerVKvPhotoNav
  //        })
  //      })        
  //    }
  //    else return null;
  //  }
  //  else return null;
  // }
  // ----------------------------------------------------------------
  // setupDictionaryProfilePicsForPhotoMgmt()
  //setupProfilePicsForPhotoMgmt (data : any) : ProfilePics {
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  this.signedInUser = EmitterSubjectService.getSignedInUser();
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUser) && this.signedInUser.sitUserId > 0) {
  //    this.profilePics = CopyServiceStatic.createProfilePicsModelFromSitUser(this.signedInUser);
  //  }
  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //    if (data instanceof ProfilePics && data.sitUserId > 0 && !data.isModelKvPhotoArrEmpty()
  //      //&& ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.filteredKvPhotoArr) && data.filteredKvPhotoArr.length === 0)
  //      //  || (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.unFilteredKvPhotoArr) && data.unFilteredKvPhotoArr.length === 0)
  //    ) {
  //      // debugger;
  //      if (data.sitUserId > 0) {
  //        // debugger;
  //        this.profilePics = data;
  //        // debugger;
  //      }
  //    }
  //    else if (StringServiceStatic.typeCheck(data).indexOf('Array') !== -1) {
  //      // debugger;
  //      this.profilePics.unFilteredKvPhotoArr = data;
  //    }
  //    else if (data instanceof KvPhoto && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.value) && data.photoBnId > 0) {
  //      if (this.profilePics.unFilteredKvPhotoDictionary.containsKey(data.photoBnId)) {
  //        let dictionaryKvPhoto = this.profilePics.unFilteredKvPhotoDictionary.remove(data.photoBnId) as KvPhoto;
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryKvPhoto)) {
  //          // -----------------------------------------------------------------------------------------------
  //          // Note: there is a chance that some unexpected result may be copied into the destination such as
  //          //       dictionaryKvPhoto.photoId = 0, and data.photoId = 1; then after copyIfExists will result
  //          //       in dictionaryKvPhoto.photoId = 1.
  //          // -----------------------------------------------------------------------------------------------
  //          dictionaryKvPhoto = dictionaryKvPhoto.copy(data);
  //          this.profilePics.unFilteredKvPhotoDictionary.setValue(dictionaryKvPhoto.photoBnId, dictionaryKvPhoto);
  //        }
  //      }
  //      // debugger;
  //      this.profilePics.unFilteredKvPhotoDictionary.setValue(data.photoBnId, data);
  //    }
  //  }

  //  // debugger;
  //  // this.profilePics = this.pagerSupportService.updateProfilePicsPrimaryKvPhotoIfMissing(this.profilePics); // will update both filteredKvPhotoArr and unFilteredKvPhotoArr
  //  this.profilePics = this.pagerSupportService.updatePrimaryKvPhotoIfMissingOnProfilePics(this.profilePics); // will update unFilteredKvPhotoArr on isPhotoMgmt==true
  //  // debugger; // watch--
  //  return this.profilePics;
  //}

 // ----------------------------------------------------------------
  // executeDuringNgAfterViewInitForPagerVKvPhotoNav (pagerVKvPhotoNav : PagerPhotoMgmt) : any {
  //  debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerVKvPhotoNav.currentItemKvPhoto)) {
  //    this.bias.updateBackgroundImageOutsideAngular(pagerVKvPhotoNav.currentItemKvPhoto.value);
  //    // debugger;
  //    EmitterSubjectService.emitCurrentPage(pagerVKvPhotoNav.currentItemKvPhoto); // bias has a listener and will set the page on the background

  //    this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.kvPhotoArr, pagerVKvPhotoNav.currentItemKvPhoto);
  //    this.glyphiconMenuService.displayPhotoMgmtGlyphMenu(pagerVKvPhotoNav.currentItemKvPhoto); // will display photo primary/private/public glyphs
  //    return pagerVKvPhotoNav;
  //  }
  // }
  // ----------------------------------------------------------------
  // executeDuringNgAfterViewInitForKvPhoto (kvp : KvPhoto) : any {
  //  debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
  //    this.bias.updateBackgroundImageOutsideAngular(kvp.value);
  //    // debugger;
  //    EmitterSubjectService.emitCurrentPage(kvp); // bias has a listener and will set the page on the background
  //    let pagerVKvPhotoNav = this.pagerServicePhotoMgmt.getPagerVPhotoMgmtForCurrentPage(kvp);
  //    this.bias.selectCurrentElemDeselctOthersForKvPhoto(pagerVKvPhotoNav.kvPhotoArr, kvp);
  //    this.glyphiconMenuService.displayPhotoMgmtGlyphMenu(kvp); // will display photo primary/private/public glyphs
  //    return pagerVKvPhotoNav;
  //  }
  // }
// ----------------------------------------------------------------
  //executeProfilePicsTasks (pPics) : ProfilePics {
  //  // check first if the filtered/unfiltered kvPhotoArr exists or not:
  //  // -----------------------------------------------------------------
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)) {
  //  // debugger;
  //    this.signedInUser = EmitterSubjectService.getSignedInUser();
  //    this.sitUser = EmitterSubjectService.getSitUserModel();
  //    let kvPhotoArr : KvPhoto[] = [];
  //    if (this.isPhotoMgmt) {
  //      // debugger;
  //      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr)
  //        || pPics.unFilteredKvPhotoArr.length === 0) {
  //        pPics.unFilteredKvPhotoArr = this.getUserKvPhotosFromDictionary(this.signedInUser);
  //        // debugger; 
  //      }

  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr)
  //        && pPics.unFilteredKvPhotoArr.length > 0
  //        && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr[ 0 ].elemId)) {
  //        // debugger;

  //        pPics = this.setupDictionaryProfilePicsForPhotoMgmt(pPics);
  //        this.bias.setProfilePicsBackgroundImage(pPics);
  //        // debugger; 
  //        // EmitterSubjectService.emitProfileImages(this.profilePics.unFilteredKvPhotoArr);
  //        // debugger;
  //      }
  //    }
  //    else if (this.isViewMember) {
  //      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr)
  //        || pPics.filteredKvPhotoArr.length === 0) {
  //        kvPhotoArr = this.getUserKvPhotosFromDictionary(this.sitUser);
  //        debugger;
  //        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotoArr) && kvPhotoArr.length > 0) {
  //          debugger;
  //          pPics.filteredKvPhotoArr = this.photoPrivacyService.filterAndSortProfileKvPhotoArr(kvPhotoArr);
  //        }
  //      }
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr)
  //        && pPics.filteredKvPhotoArr.length > 0
  //        && FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr[ 0 ].elemId)) {
  //        debugger;

  //        pPics = this.setupDictionaryProfilePicsForViewMember(pPics, this.sitUser);
  //        this.bias.setProfilePicsBackgroundImage(pPics);
  //        debugger;
  //        EmitterSubjectService.emitProfileImages(pPics.filteredKvPhotoArr);
  //        debugger;
  //      }
  //    }
  //  }
  //  return pPics;
  //}
// ----------------------------------------------------------------
  //  TODO: remove this temp holder of this method:
  //  pgrVPhotoNav.kvPhotoArr = this.setIndexAndPageNumAndElemId(pgrVPhotoNav, "pagerVPhotoNav-");
  // ----------------------------------------------------------------
  //setupProfilePicsForViewMember (data : any, sitUser: SitUser) : ProfilePics {
  // // EmitterSubjectService.emitIsPhotoMgmt(true);
  //  this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
  //  this.isViewMember = EmitterSubjectService.getIsViewMember();
  //  if (this.isViewMember) {
  //    this.isPhotoMgmt = false;
  //  }
  //  let isFiltered = true;

  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //   debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
  //    if (data instanceof ProfilePics) {
  //      // debugger;
  //      if (data.sitUserId > 0) {
  //        // debugger;
  //        this.profilePics = data;
  //        // debugger;
  //      }
  //    }
  //    else if (StringServiceStatic.typeCheck(data).indexOf('Array') !== -1) {
  //      // debugger;
  //      this.profilePics.filteredKvPhotoArr = data;
  //    }
  //    else if (data instanceof KvPhoto && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data.value) && data.photoBnId > 0) {
  //      this.profilePics.filteredKvPhotoArr = ArraySupportServiceStatic.mergeArraysUniq(this.profilePics.filteredKvPhotoArr, [ data ]);
  //    }
  //  }
  //  this.profilePics = this.pagerSupportService.updateProfilePicsPrimaryKvPhotoIfMissing(this.profilePics);
  //  debugger;// watch-
  //  return this.profilePics;
  //}
// ----------------------------------------------------------------
  //setupDictionaryProfilePicsForPhotoMgmt (pPics : ProfilePics) : ProfilePics | any {
  //  // this.isPhotoMgmt =  EmitterSubjectService.getIsPhotoMgmt();
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
  //  this.signedInUser = EmitterSubjectService.getSignedInUser();
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) || pPics.sitUserId === 0) {
  //    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.signedInUser) || this.signedInUser.sitUserId === 0) {
  //      this.signedInUser = CopyServiceStatic.copyLoginSuccessToSitUser(this.loginSuccess);
  //    }
  //    this.profilePics = CopyServiceStatic.createProfilePicsModelFromSitUser(this.signedInUser);
  //  }
  //  else this.profilePics = pPics;

  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)) {        
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoArr) && this.profilePics.unFilteredKvPhotoArr.length === 0) {        
  //      this.profilePics.unFilteredKvPhotoArr = this.getUserKvPhotosFromDictionary(this.signedInUser);
  //    }
  //  }

  //  DictionaryServiceStatic.updateDictionary(this.profilePics, "ProfilePics", this.loginSuccess.signedInUserId);

  //  // if primaryPhotoBnId was not found on KvPhotoArr, so add it:
  //  // -----------------------------------------------------------
  //  // debugger;
  //  this.profilePics = this.pagerSupportService.updateProfilePicsPrimaryKvPhotoIfMissing(this.profilePics);

  //  return this.profilePics;
  //}
  // ----------------------------------------------------------------
  //setupDictionaryProfilePicsForViewMember (pPics : ProfilePics, sitUser : SitUser) : ProfilePics | any {
  //  debugger;
  //  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) || pPics.sitUserId === 0) {
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUser) || sitUser.sitUserId > 0) {
  //      this.profilePics = CopyServiceStatic.createProfilePicsModelFromSitUser(sitUser);
  //    }
  //  }
  //  else this.profilePics = pPics;

  //  // debugger;
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)) {
  //    let dKvPhotoArr = this.getUserKvPhotosFromDictionary(sitUser);
  //    debugger;

  //    // filter the unFiltered kvPhotoArr:
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dKvPhotoArr) && dKvPhotoArr.length > 0) {
  //      let tFilteredKvPhotoArr = this.photoPrivacyService.filterAndSortProfileKvPhotoArr(dKvPhotoArr);
  //      debugger;

  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tFilteredKvPhotoArr) && tFilteredKvPhotoArr.length > 0) {
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.filteredKvPhotoArr) && this.profilePics.filteredKvPhotoArr.length === 0) {
  //          this.profilePics.filteredKvPhotoArr = tFilteredKvPhotoArr;
  //          debugger;
  //        }
  //        else {
  //          debugger;
  //          this.profilePics.filteredKvPhotoArr = [ this.profilePics.filteredKvPhotoArr, ...tFilteredKvPhotoArr ];
  //          debugger;
  //          this.profilePics.filteredKvPhotoArr = ArraySupportServiceStatic.makeUniq(this.profilePics.filteredKvPhotoArr);
  //          debugger;
  //        }

  //        debugger;
  //        DictionaryServiceStatic.updateDictionary(this.profilePics, "ProfilePics", this.loginSuccess.signedInUserId);
  //        // if primaryPhotoBnId was not found on KvPhotoArr, so add it:
  //        // -----------------------------------------------------------
  //        debugger;
  //        this.profilePics = this.pagerSupportService.updateProfilePicsPrimaryKvPhotoIfMissing(this.profilePics);
  //        debugger;// watch-
  //      }
  //    }
  //  }
  //  return this.profilePics;
  //}
// ------------------------------------------------------------------------
  // Note: will setup the ProfilePics with filtered data and unfiltered data:
  //  TODO: remove after testing/before deployment
  // ------------------------------------------------------------------------
  //setupProfilePicsFromUnfilteredKvPhotoMap (pPics : ProfilePics, unfilteredKvPhotoMap: Map<any, any>) : ProfilePics {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unfilteredKvPhotoMap) && unfilteredKvPhotoMap.size > 0) {
  //    debugger;
  //    pPics.unFilteredKvPhotoMap = ArrayServiceStatic.sortMapByPhotoBnId(pPics.unFilteredKvPhotoMap);
  //    debugger;
  //    //for (let e of pPics.unFilteredKvPhotoArr) {
  //    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e)) {
  //    //    if (e.value.photoBnId > 0 && e.value.photoId >= 0) {
  //    //      pPics.unFilteredKvPhotoDictionary.setValue(e.value.photoBnId, e);
  //    //      // let dKv = pPics.unFilteredKvPhotoDictionary.getValue(parseInt(e.key)) as KvPhoto;
  //    //      // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dKv) && dKv.value.photoBnId !== e.value.photoBnId) {
  //    //      //  pPics.unFilteredKvPhotoDictionary.setValue(e.value.photoBnId, e);
  //    //      // }
  //    //      debugger;
  //    //    }
  //    //    else if (e.value.photoId > 0 && e.value.photoBnId === 0) {
  //    //      pPics.profileKvPhotoArr.push(e);
  //    //      debugger;
  //    //    }
  //    //  }
  //    //}
  //  };

  //  pPics.unFilteredKvPhotoArr = pPics.unFilteredKvPhotoArr.sort((a, b) =>
  //    b.photoBnId = a.photoBnId
  //  );
  //  return pPics;
  //}


