'use-strict'
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-disable-scroll',
  templateUrl: './disableScroll.component.html',
  styleUrls: ['./disableScroll.component.scss'],
})
export class DisableScrollComponent implements OnInit, OnDestroy {
  public body_el : any;
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public scrollPosition = 0;
  constructor(
    private router: Router
  )
  {
  }

  ngOnInit () {
    this.noPageRefreshOnSwipeDown()
  }
  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
  }

  // ---------------------------------------------------------------
  // ----------------------------------------------------------------------------------
  //  Begin-of-preventing-page-refresh-on-swipe-down/scroll-down:
  //  Ref: https://github.com/bevacqua/dragula/issues/487
  // ----------------------------------------------------------------------------------

  noPageRefreshOnSwipeDown () {
    //const container = document.querySelector(...);

    this.body_el = document.getElementById('bodyId');
    const dragula = (window as any).dragula(
      [
        this.body_el
      ]);

    /* handle scroll */
    // this.body_el.addEventListener('touchmove', event => event.preventDefault());

    var _scroll_disable = this.scroll_disable;
    var _scroll_enable = this.scroll_enable
    dragula.on('drag', function (el: any) {
      _scroll_disable();
    });

    dragula.on('dragend', function (el : any) { _scroll_enable(); });
    this.body_el.remove();
  }


  scroll_disable () {
    console.log('scroll_disable()');
    this.scrollPosition = window.pageYOffset;
    this.body_el.style.overflow = 'hidden';
    this.body_el.style.position = 'fixed';
    this.body_el.style.top = `-${ this.scrollPosition }px`;
    this.body_el.style.width = '100%';
  }

  scroll_enable () {
    console.log('scroll_enable()');
    this.body_el.style.removeProperty('overflow');
    this.body_el.style.removeProperty('position');
    this.body_el.style.removeProperty('top');
    this.body_el.style.removeProperty('width');
    window.scrollTo(0, this.scrollPosition);
  }
  // ----------------------------------------------------------------------------------
  //  End-of-preventing-page-refresh-on-swipe-down/scroll-down:
  // ----------------------------------------------------------------------------------
  // ==================================================================================

}
