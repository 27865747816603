
import { Injectable } from '@angular/core';
import { ActivityService } from './activityService.service';
import { MemberService } from './memberService.service';

@Injectable({
  providedIn: 'any',
})
export class MemberServiceService {
  constructor (
    private activityService: ActivityService,
    private memberService : MemberService,  
  ) { }
  // ------------------------------------------------------------------
  setActivityService ( as : ActivityService ) : void
  {
    this.activityService = as;
  }
  getActivityService () : ActivityService
  {
    return this.activityService;
  }
  // ------------------------------------------------------------------
  setMemberService(mes: MemberService): void {
    this.memberService = mes;
  }
  getMemberService(): MemberService {
    return this.memberService;
  }
  // ------------------------------------------------------------------
  
  // ------------------------------------------------------------------
}
