import { KvMemberActivityGlyph } from "../keyValue/kvMemberActivityGlyph.model";

export class PhotoMgmtAction {
  public action !: string;
  public date = '';
  public photoId = 0;
  public photoBnId = 0;
  public sitUserId = 0;
  public message ! : any;
  public kvg : KvMemberActivityGlyph = new KvMemberActivityGlyph();
  constructor() { }
}
