'use-strict'
import { Pipe, PipeTransform } from '@angular/core';
import { parse } from 'querystring';

@Pipe({
  name: 'toNumber',
})

// ref: https:// stackoverflow.com/questions/38461080/using-number-pipe-throws-invalidpipeargumentexception-invalid-argument-1-for
export class ToNumberPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    const retNumber = parseFloat(value);
    // debugger;

    return isNaN(retNumber) ? 0 : retNumber;
  }
}

/*
 * // You can use it like this:
 *  <h1>amount = { { amount | toNumber | number: '1.2-2' } } </h1>
 *  <input[(ngModel)]="amount" />
 */
