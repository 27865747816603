'use-strict'
import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { AccordionComponent } from '../accordion/accordion.component';
import { AccordionGroupComponent } from '../accordionGroup/accordionGroup.component';

@Component({
  selector: 'app-shared-module-component',
  templateUrl: './sharedModule.component.html',
  styleUrls: ['./sharedModule.component.scss'],
})
export class SharedModuleComponent implements OnInit, OnDestroy, AfterViewInit {
  // ref:https:// medium.com/angular-in-depth/lazy-loading-
  @ViewChild('anchor', { read: ViewContainerRef})
  sharedAnchor!: ViewContainerRef;
  private activeComponentIds: string[] = [];
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public mcLoader: ModuleComponentLoader = new ModuleComponentLoader();

  constructor(
    public cdr: ChangeDetectorRef,
    private factoryResolver: ComponentFactoryResolver,
    // private moduleServiceService: ModuleServiceService,
    private router: Router,
    private unloadConponentRef: ElementRef
  ) {
    // if (this.moduleServiceService) { this.lazyLoaderService = this.moduleServiceService.getLazyLoaderService(); }
  }

  ngOnInit(): void {
    // -------------------------------------------------------------------------
    EmitterSubjectService.loadChildrenEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      this.mcLoader = result as ModuleComponentLoader;
      if (this.mcLoader.componentName.length > 3 && this.mcLoader.moduleName.toLowerCase().indexOf('shared') !== -1) {
        this.loadSharedComponent(this.mcLoader.componentName);
      }
      // debugger;
    });
    // -------------------------------------------------------------------------
    // this.mcLoader = EmitterSubjectService..getMCLoader();
    // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.mcLoader)) {
    //  this.loadSharedComponent(this.mcLoader.componentName);
    // }
    // -------------------------------------------------------------------------
    EmitterSubjectService.loadSharedComponentEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
      {
      // debugger;
      this.mcLoader = result as ModuleComponentLoader;

      // debugger;
      if (
        this.mcLoader.componentName.length > 2 &&
        this.mcLoader.moduleName.toLowerCase().indexOf('shared') !== -1 &&
        (this.mcLoader.componentName.toLowerCase().indexOf('fileupload') !== -1 ||
          this.mcLoader.componentName.toLowerCase().indexOf('imageupload') !== -1 ||
          this.mcLoader.componentName.toLowerCase().indexOf('accordion') !== -1 ||
          this.mcLoader.componentName.toLowerCase().indexOf('accordiongroup') !== -1)
      ) {
        this.loadSharedComponent(this.mcLoader.componentName);
      }
    });
  }

  // ---------------------------------------------------------------------------------
  ngAfterViewInit() {
    /*
     * debugger;
     * this.activateGesture(this.gestureDivId, '');
     * this.cdr.detectChanges();
     */
  }

  // ---------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    EmitterSubjectService.emitDisplayDefaultGlyphiconMenu();
    // this.mcLoader = new ModuleComponentLoader();
  }
  /*
   * ---------------------------------------------------------------------------------
   * Note: if found will send the index, otherwise -1.
   * ---------------------------------------------------------------------------------
   */
  isActiveComponentIdFound(compId: string): any {
    let index = -1;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(compId)) {
      for (let i = 0; i < this.activeComponentIds.length; i++) {
        if (compId.toLowerCase().indexOf(this.activeComponentIds[i].toLowerCase()) !== -1) {
          index = i;
        }
      }
    }
    return index;
  }
  // ---------------------------------------------------------------------------------
  getMcLoaderComponentId(): any {
    let mcLoaderComponentId = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.mcLoader) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.mcLoader.componentName)) {
      mcLoaderComponentId = this.mcLoader.componentName + 'ComponentId';
    }
    return mcLoaderComponentId;
  }
  /*
   * ---------------------------------------------------------------------------------
   * ref:https:// medium.com/angular-in-depth/lazy-loading-angular-modules-with-ivy-92c3a890eae1
   */

  async loadSharedComponent(name: string) {
    const mcComId = this.getMcLoaderComponentId();
    let factory: any;

    switch (name.toLocaleLowerCase()) {
      /*
       * case 'checkbox':
       *  factory = this.factoryResolver.resolveComponentFactory(CheckboxComponent);
       *  // debugger;
       *  break;
       * case 'radiobox':
       *  factory = this.factoryResolver.resolveComponentFactory(RadioBoxComponent);
       *  break;
       */
      // case 'fileupload':
      // factory = this.factoryResolver.resolveComponentFactory(FileUploadComponent);
      // break;
      // case 'imageupload':
      //  factory = this.factoryResolver.resolveComponentFactory(ImageUploadComponent);
      //  break;
      /*
       * case 'notfound':
       *  factory = this.factoryResolver.resolveComponentFactory(NotFoundComponent);
       *  break;
       * case 'prescreen':
       *  factory = this.factoryResolver.resolveComponentFactory(PreScreenComponent);
       *  break;
       */
      case 'accordion':
        factory = this.factoryResolver.resolveComponentFactory(AccordionComponent);
        break;
      case 'accordiongroup':
        factory = this.factoryResolver.resolveComponentFactory(AccordionGroupComponent);
        break;
    }
    const mcComponentElem = document.getElementById(mcComId);

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcComponentElem)) {
      if (this.sharedAnchor) {
        this.sharedAnchor.clear();
        if (factory) {
          this.sharedAnchor.createComponent(factory);
          this.ngAfterViewInit();
          // debugger;
        }
      }
      mcComponentElem.remove();
    }
  }
  /*
   * ---------------------------------------------------------------------------------
   * ----------------------------------------------------------------------------------
   */
}
