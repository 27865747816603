export class ClientApiModel {
  public callerName = '';
  public date = '';
  public id = 0;
  public sitUserId = 0;
  public sitUserKey = '';
  public sitUserIdArr : number[] = [];
  public signedInUserId = 0;
  public signedInUserKey = '';
  public message: undefined;
  public value: undefined;
  constructor() {
    // ;
  }
}
