<!--
*ngIf="kvGlyph.isVisible && (
                 ( !kvAny.isUnlocked && kvAny.key.toString().toLowerCase().indexOf('tala') !== -1 )
                 || ( kvAny.isUnlocked && kvAny.key.toString().toLowerCase().indexOf('unlock') !== -1 )
                 || ( ! kvAny.isBlocked && kvAny.key.toString().toLowerCase().indexOf('block') !== -1 )
                 || ( ! kvAny.isFriend && kvAny.key.toString().toLowerCase().indexOf('friend') !== -1 )
                 || ( ! kvAny.isLiked && kvAny.key.toString().toLowerCase().indexOf('like') !== -1 )
                 || ( ! kvAny.isStarred && kvAny.key.toString().toLowerCase().indexOf('star') !== -1 )
                 || ( ! kvAny.isWinked && kvAny.key.toString().toLowerCase().indexOf('wink') !== -1 )
                 || ( kvAny.key.toString().toLowerCase().indexOf('chat') !== -1 )
                 || ( kvAny.key.toString().toLowerCase().indexOf('email') !== -1 )
                 )"
-->
<div id="aGlyphComponentId-{{kvGlyph.key}}"
     [ngClass]="{'active': 'kvGlyph && kvGlyph.index >= 0 && kvGlyph.index === (i+1)', 'flex-item-plain text-center bttn-xxxs-transparent': true }"
     style="width: 2.5rem; height: auto; margin: 0.1rem; padding: 0.01rem;">
  <button (click)="kvGlyph.pageNum = i + 1; setGlyphAction(kvGlyph)"
          [ngClass]="{'hvr-underline-from-center flex-pagerV-item text-center bttn-xxxs-transparent':true}"
          style="width: 2rem;
                      height: 2rem;
                      margin: 0.02rem;
                      padding: 0.02rem;
                      cursor: pointer;">
    <!--Glyphicon case:-->
    <div *ngIf="kvGlyph && kvGlyph.value && kvGlyph.value.toString().toLowerCase().indexOf('glyphicon') !== -1
                      && kvGlyph.value?.toString().toLowerCase().indexOf('camera') === -1"
         id="pageVMAGlyphView-{{i + 1}}"
         class="hvr-icon text-center drkRedGrnB glyphicon  {{kvGlyph.value}}"
         style="width: 1.5rem;
                            height: 1.5rem;
                            font-size: 1.25rem" aria-hidden="true">
    </div>

    <!--image/pic case:-->
    <img *ngIf="kvGlyph && kvGlyph.value
               && kvGlyph.value.toString().toLowerCase().indexOf('data:image/png;base64,') !== -1"
         id="pageVMAGlyphView-{{i + 1}}"
         [ngClass]="{'hvr-icon eNoteTilePic img-circle img-responsive increase': true}"
         src="{{kvGlyph.value}}"
         type="button"
         style="height: 1.55rem;
                      width: 1.55rem;
                      z-index: 80;
                      vertical-align: middle;
                      margin: 0.0rem;
                      display: inline;" />

    <!--image-file-name-case-->
    <!--an image-file-name in lieu of a glyphicon or raw image data: -->
    <img *ngIf="kvGlyph && kvGlyph.value &&
                    (kvGlyph.value.toString().toLowerCase().indexOf('.jpg') !== -1
                    || kvGlyph.value.toString().toLowerCase().indexOf('.png') !== -1)"
         id="pageVMAGlyphView-{{i + 1}}"
         class="img hvr-icon text-center drkRedGrnSB"
         src="{{kvGlyph.value}}"
         style="background: transparent;
                      vertical-align: middle;
                      margin: 0.15rem 0.01rem 0.02rem 0.01rem;
                      z-index: 100;
                      height: 1.55rem;
                      width: 1.55rem;" aria-hidden="true" />
  </button>

  <!--glyph/image/menu name:-->
  <div class="hvr-icon blueDrkRedxSB" style="margin:-0.02rem 0 0.1rem 0;cursor: pointer;">
    {{kvGlyph.key}}
  </div>
</div>
