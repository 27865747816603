import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

export class CreditCard {
  public creditCardNumber = '';
  public creditCardTransactionData : any;
  public cryptUserKey = '';
  public cvv2Number = '';
  public expiryMonth = '';
  public expiryYY = '';
  public id = 0;
  public nameOnCard = '';
  public paymentOption = '';
  public signedInUserId = 0;
  public signedInUserKey = '';
  public date = '';
  public ip = '';
  // ---------------------------------------------------
  constructor() {
  }
  // ---------------------------------------------------
  public copy ( a : CreditCard ) : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( a ) )
    {
      //let outCC = new CreditCard();
      this.creditCardNumber = a.creditCardNumber;
      this.creditCardTransactionData = a.creditCardTransactionData; // TODO: is it needed?
      this.cryptUserKey = a.cryptUserKey;
      this.cvv2Number = a.cvv2Number;
      this.date = a.date;
      this.expiryMonth = a.expiryMonth;
      this.expiryYY = a.expiryYY;
      this.id = a.id;
      this.nameOnCard = a.nameOnCard;
      this.paymentOption = a.paymentOption;
      this.signedInUserId = a.signedInUserId;
      this.signedInUserKey = a.signedInUserKey;
    }
  }

  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    let outString = '' +
      '{\n id: ' +
      this.id +      
      ',\n' +
      'date:' +
      this.date +
      ',\n creditCardNumber: ' +
      this.creditCardNumber +
      ',\n' +
      'creditCardTransactionData: ' +
      this.creditCardTransactionData +
      ',\n' +
      'cryptUserKey: ' +
      this.cryptUserKey +
      ',\n' +
      'cvv2Number: ' +
      this.cvv2Number +
      ',\n' +
      'expiryMonth: ' +
      this.expiryMonth +
      ',\n' +
      'expiryYY: ' +
      this.expiryYY +
      ',\n' +
      'nameOnCard: ' +
      this.nameOnCard +
      ',\n' +
      'paymentOption: ' +
      this.paymentOption +
      ',\n' +
      'signedInUserId: ' +
      this.signedInUserId +
      ',\n' +
      'signedInUserKey: ' +
      this.signedInUserKey +
      '\n}';
    return outString;
  }
  // ---------------------------------------------------
}
