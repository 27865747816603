import { FrequentlyUsedFunctionsServiceStatic } from "../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";
import { Photo } from "../common/photo.model";
import { PhotoBn } from "../common/photoBn.model";

// Note:  the Kv name where the first leter is capital and the second letter is lowercase, implies that the value is of type 'any'
//        there is no separate KvGlyph models because we can use KvPhoto model for it since a glyph may contain an image or icon,
//        and value 'any' can accomodate both.
// -----------------------------------------------------------------
// 
export class KvPhoto extends Photo{
  public action = '';
  public elemId = '';
  public entityName = '';
  public glyphName = ''; // to hold the glyphName for privacy
  public isPrivate = false;
  public isUploaded = false;
  public isPublic = false;
  public isPrimaryGlyph = false;
  public index = 0;
  public key = '';
  public pageNum = 0;
  public photoBnId = 0;
  public returnUrl = '';
  public value : any;
  // ---------------------------------------------
  constructor () { super(); }
  // ---------------------------------------------
  // NOte: Not sure how to make the base class to be copied automatically.
  //copySuper (a : KvPhoto) : void {
  //  this.elemId = a.elemId;
  //  this.entityName = a.entityName;
  //  this.glyphName = a.glyphName;
  //  this.isPrivate = a.isPrivate;
  //  this.index = a.index;
  //  this.key = a.key;
  //  this.pageNum = a.pageNum;
  //  this.returnUrl = a.returnUrl;
  //  this.value = a.value;
  //  this.setupPicImageAndValueOfKvPhoto();    
  //}
  // ---------------------------------------------
  public override copy (a : KvPhoto) : KvPhoto {
    if (!this.isNullOrEmpty(a)) {
      this.action = a.action;
      this.cryptUserKey = a.cryptUserKey;
      this.date = a.date;
      this.description = a.description;
      this.elemId = a.elemId;
      this.entityName = a.entityName;
      this.file = a.file;
      this.formData = a.formData;
      this.glyphName = a.glyphName;
      this.id = a.id;
      this.image = a.image;
      this.imageBytes = a.imageBytes;
      this.imageString = a.imageString;
      this.index = a.index;
      this.isPrivate = a.isPrivate;
      this.isUnlocked = a.isUnlocked;
      this.isUploaded = a.isUploaded;
      // this.isPrimary = a.isPrimary;
      // console.log('kvPhoto.isPrimary:' + this.isPrimary);
      this.isPrimary = a.isPrimary;
      // console.log('kvPhoto.isPrimary:' + this.isPrimary);
      this.isPrimaryGlyph = a.isPrimaryGlyph;
      this.isPublic = a.isPublic;
      this.itemNum = a.itemNum;
      this.key = a.key;
      this.latitude = a.latitude;
      this.location = a.location;
      this.longitude = a.longitude;
      this.path = a.path;
      this.pageNum = a.pageNum;
      this.photoId = a.photoId;
      this.photoBnId = a.photoBnId;
      this.pic = a.pic;
      this.privacy = a.privacy;
      this.profileId = a.profileId;
      this.returnUrl = a.returnUrl;
      this.signedInUserId = a.signedInUserId;
      this.sitUserId = a.sitUserId;
      this.sitUserKey = a.sitUserKey;
      this.sourceId = a.sourceId;
      this.sourceType = a.sourceType;
      this.title = a.title;
      this.url = a.url;
      this.value = a.value;
    }
    this.setupPicImageAndValueOfKvPhoto(this, a);
    return this;
  }
  // -------------------------------------------------
  public copySourceToDestination (destination : KvPhoto, source : KvPhoto) : KvPhoto {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destination)) {
      destination = new KvPhoto();
		}
    if (!this.isNullOrEmpty(source)) {
      destination.action = source.action;
      destination.cryptUserKey = source.cryptUserKey;
      destination.date = source.date;
      destination.description = source.description;
      destination.elemId = source.elemId;
      destination.entityName = source.entityName;
      destination.file = source.file;
      destination.formData = source.formData;
      destination.glyphName = source.glyphName;
      destination.id = source.id;
      destination.image = source.image;
      destination.imageBytes = source.imageBytes;
      destination.imageString = source.imageString;
      destination.index = source.index;
      destination.isPrivate = source.isPrivate;
      destination.isUnlocked = source.isUnlocked;
      destination.isUploaded = source.isUploaded;
      // console.log('kvPhoto.isPrimary:' + destination.isPrimary);
      destination.isPrimary = source.isPrimary;
      // console.log('kvPhoto.isPrimary:' + destination.isPrimary);
      destination.isPrimaryGlyph = source.isPrimaryGlyph;
      destination.isPublic = source.isPublic;
      destination.itemNum = source.itemNum;
      destination.key = source.key;
      destination.latitude = source.latitude;
      destination.location = source.location;
      destination.longitude = source.longitude;
      destination.path = source.path;
      destination.pageNum = source.pageNum;
      destination.photoId = source.photoId;
      destination.photoBnId = source.photoBnId;
      destination.pic = source.pic;
      destination.privacy = source.privacy;
      destination.profileId = source.profileId;
      destination.returnUrl = source.returnUrl;
      destination.signedInUserId = source.signedInUserId;
      destination.sitUserId = source.sitUserId;
      destination.sitUserKey = source.sitUserKey;
      destination.sourceId = source.sourceId;
      destination.sourceType = source.sourceType;
      destination.title = source.title;
      destination.value = source.value;
      destination.url = source.url;
    }
    this.setupPicImageAndValueOfKvPhoto(destination, source);
    return destination;
  }
  // ---------------------------------------------
  public clone (a : KvPhoto) : KvPhoto {
    let that = new KvPhoto();
    that.copy(a);
    return that;
  }
  // ---------------------------------------------
  public override copyIfExists (a : KvPhoto) : KvPhoto {
    if (!this.isNullOrEmpty(a)) {
      if (!this.isNullOrEmpty(a.action)) {
        this.action = a.action;
      }
      if (!this.isNullOrEmpty(a.cryptUserKey)) {
        this.cryptUserKey = a.cryptUserKey;
      }

      if (!this.isNullOrEmpty(a.date)) {
        this.date = a.date;
      }

      if (!this.isNullOrEmpty(a.description)) {
        this.description = a.description;
      }

      if (!this.isNullOrEmpty(a.elemId)) {
        this.elemId = a.elemId;
      }

      if (!this.isNullOrEmpty(a.entityName)) {
        this.entityName = a.entityName;
      }

      if (!this.isNullOrEmpty(a.file)) {
        this.file = a.file;
      }

      if (!this.isNullOrEmpty(a.formData)) {
        this.formData = a.formData;
      }

      if (!this.isNullOrEmpty(a.glyphName)) {
        this.glyphName = a.glyphName;
      }

      if (!this.isNullOrEmpty(a.id)) {
        this.id = a.id;
      }
      if (!this.isNullOrEmpty(a.image)) {
        this.image = a.image;
      }

      if (!this.isNullOrEmpty(a.imageString)) {
        this.imageString = a.imageString;
      }

      if (!this.isNullOrEmpty(a.imageBytes)) {
        this.imageBytes = a.imageBytes;
      }

      if (!this.isNullOrEmpty(a.index)) {
        this.index = a.index;
      }

     
      /*  this.isPrimary = a.isPrimary;*/
      // console.log('kvPhoto.isPrimary:' + this.isPrimary);
      this.isPrimary = a.isPrimary;
      // console.log('kvPhoto.isPrimary:' + this.isPrimary);

      this.isPrimaryGlyph = a.isPrimaryGlyph;
      this.isPublic = a.isPublic;
   
        this.isPrivate = a.isPrivate;


      
        this.isUnlocked = a.isUnlocked;

      this.isUploaded = a.isUploaded;

      if (!this.isNullOrEmpty(a.itemNum)) {
        this.itemNum = a.itemNum;
      }

      if (!this.isNullOrEmpty(a.key)) {
        this.key = a.key;
      }

      if (!this.isNullOrEmpty(a.latitude)) {
        this.latitude = a.latitude;
      }

      if (!this.isNullOrEmpty(a.location)) {
        this.location = a.location;
      }

      if (!this.isNullOrEmpty(a.longitude)) {
        this.longitude = a.longitude;
      }

      if (!this.isNullOrEmpty(a.path)) {
        this.path = a.path;
      }

      if (!this.isNullOrEmpty(a.pageNum)) {
        this.pageNum = a.pageNum;
      }

      if (!this.isNullOrEmpty(a.photoId)) {
        this.photoId = a.photoId;
      }

      if (!this.isNullOrEmpty(a.photoBnId)) {
        this.photoBnId = a.photoBnId;
      }

      if (!this.isNullOrEmpty(a.pic)) {
        this.pic = a.pic;
      }
      else if (!this.isNullOrEmpty(a.image)) {
        this.pic = a.image;
      }
      else if (!this.isNullOrEmpty(a.imageString)) {
        this.pic = a.imageString;
      }

      if (!this.isNullOrEmpty(a.profileId)) {
        this.profileId = a.profileId;
      }

      if (!this.isNullOrEmpty(a.privacy)) {
        this.privacy = a.privacy;
      }

      if (!this.isNullOrEmpty(a.returnUrl)) {
        this.returnUrl = a.returnUrl;
      }

      if (a.signedInUserId) {
        this.signedInUserId = a.signedInUserId;
      }

      if (!this.isNullOrEmpty(a.sitUserId)) {
        this.sitUserId = a.sitUserId;
      }

      if (!this.isNullOrEmpty(a.sitUserKey)) {
        this.sitUserKey = a.sitUserKey;
      }

      if (!this.isNullOrEmpty(a.sourceId)) {
        this.sourceId = a.sourceId;
      }

      if (!this.isNullOrEmpty(a.sourceType)) {
        this.sourceType = a.sourceType;
      }

      if (!this.isNullOrEmpty(a.title)) {
        this.title = a.title;
      }

      if (!this.isNullOrEmpty(a.value)) {
        this.value = a.value;
      }

      if (!this.isNullOrEmpty(a.url)) {
        this.url = a.url;
      }
    }
    this.setupPicImageAndValueOfKvPhoto(this, a);
    return this;
  }
  // ---------------------------------------------
  public override cloneIfExists (a : KvPhoto) : KvPhoto {
    const that = new KvPhoto();
    that.copyIfExists(a);
    return that;
  }
  // --------------------------------------------------------
  public setupPicImageAndValueOfKvPhoto (d: any, s: KvPhoto) : void {
    let isImageEmpty = false;
    let isPicEmpty = false;
    let isValueEmpty = false;
    if (this.isNullOrEmpty(d)) {
      d = new KvPhoto();
    }
    if (!this.isNullOrEmpty(s)) {
      if (this.isNullOrEmpty(s.pic)) {
        isPicEmpty = true;
      }

      if (this.isNullOrEmpty(s.value)) {
        isValueEmpty = true;
      }

      if (this.isNullOrEmpty(s.image)) {
        isImageEmpty = true;
      }
      if (!this.isNullOrEmpty(d)) {
        if (!isImageEmpty) {
          if (isPicEmpty) {
            d.pic = s.image;
          }
          if (isValueEmpty) {
            d.value = s.image;
          }
        }
        if (!isPicEmpty) {
          if (isImageEmpty) {
            d.image = s.pic;
          }
          if (isValueEmpty) {
            d.value = s.image;
          }
        }
        if (!isValueEmpty) {
          if (isImageEmpty) {
            d.image = s.value;
          }
          if (isPicEmpty) {
            d.pic = s.value;
          }
        }
        if (!this.isNullOrEmpty(s.value) && s.value.toLowerCase().indexOf('url(') !== -1) {
          d.value = this.stripUrlForImageData(s.value);
        }
      }
    }
  }
  // ---------------------------------------------------------------------------------
  public stripUrlForImageData (image : string) : any {
    let tImage2 : any;

    if (!this.isNullOrEmpty(image)) {
      const pattern1 = /url\('/;
      const tImage = image.replace(pattern1, '');
      const pattern2 = /'\)/;

      if (!this.isNullOrEmpty(tImage)) {
        tImage2 = tImage.replace(pattern2, '');
      }
      if (!this.isNullOrEmpty(tImage2)) {
        return tImage2;
      }
      return '';
    }
    return '';
  }
  // -------------------------------------------------
  public copyToPhotoBn (destination : PhotoBn, source : KvPhoto) : PhotoBn {
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(destination)) {
      destination = new PhotoBn();
    }
    if (!this.isNullOrEmpty(source)) {
      destination.action = source.action;
      destination.cryptUserKey = source.cryptUserKey;
      destination.date = source.date;
      destination.description = source.description;
      //destination.elemId = source.elemId;
      //destination.entityName = source.entityName;
      destination.file = source.file;
      destination.formData = source.formData;
      // destination.glyphName = source.glyphName;
      destination.id = source.id;
      destination.image = source.image;
      destination.imageBytes = source.imageBytes;
      destination.imageString = source.imageString;
      //destination.index = source.index;
      //destination.isPrivate = source.isPrivate;
      destination.isUnlocked = source.isUnlocked;
      // destination.isUploaded = source.isUploaded;
      // console.log('kvPhoto.isPrimary:' + destination.isPrimary);
      destination.isPrimary = source.isPrimary;
      // console.log('kvPhoto.isPrimary:' + destination.isPrimary);
      //destination.isPrimaryGlyph = source.isPrimaryGlyph;
      //destination.isPublic = source.isPublic;
      destination.itemNum = source.itemNum;
      // destination.key = source.key;
      destination.latitude = source.latitude;
      destination.location = source.location;
      destination.longitude = source.longitude;
      destination.path = source.path;
      // destination.pageNum = source.pageNum;
      destination.photoId = source.photoId;
      destination.photoBnId = source.photoBnId;
      destination.pic = source.pic;
      destination.privacy = source.privacy;
      destination.profileId = source.profileId;
      // destination.returnUrl = source.returnUrl;
      destination.signedInUserId = source.signedInUserId;
      destination.sitUserId = source.sitUserId;
      destination.sitUserKey = source.sitUserKey;
      destination.sourceId = source.sourceId;
      destination.sourceType = source.sourceType;
      destination.title = source.title;
      destination.value = source.value;
      destination.url = source.url;
    }
    this.setupPicImageAndValueOfKvPhoto(destination, source);
    return destination;
  }
  // --------------------------------------------------------------
  public isNullOrEmpty (input : any) : boolean {
    if (input === null || input === '' || input === undefined || (input !== null && (input.toString().toLowerCase() === 'null' || input.toString().toLowerCase() === 'undefined'))) {
      return true;
    }
    return false;
  }
  // --------------------------------------------------------
}
