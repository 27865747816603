import { ElementRef } from '@angular/core';
import { DateStringServiceStatic } from '../../services/staticServices/commonStaticServices/dateStringServiceStatic.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { EmitOffOnResult } from '../common/emitOffOnResult.model';
import { Heartbeat } from '../common/heartbeat.model';

// --------------------------------------------------------------------------------------------------------------------
// Note:  OffOn model whould indicate only whether a user is online or off line and if away for certain amount of time.
//        It does not deal with anything else such as profileName or distance from other member(s)
// --------------------------------------------------------------------------------------------------------------------
export class OffOn {
  public away = 'offline';
  public awayArr : string[] = [ 'online',  'offline',  'away-10mins' ];
  public awayMap = new Map<string, string>();
  public awayId = '';
  public awayRadioId = '';
  public awayTime = 0;
  public date = '';
  public heartbeat: Heartbeat = new Heartbeat();
  public id = '';
  public isOnline = false;
  public index = -0;
  public isStatusUpdated = false;
  public offOnLegend = '';
  public offOnResult : EmitOffOnResult = new EmitOffOnResult();
  public offOnArr: string[] = ['0'];
  public offOnId = '';
  public offOnElem !: HTMLElement;
  public offOnAwayElem ! : ElementRef | HTMLElement; 
  public offOnRadioElem ! : ElementRef | HTMLElement; 
  public offOnStatusColor = '';
  public offOnStatusEnum = -1;
  public offOnStatusColorTimer: any;
  public pElemId = '';
  public profileId = 0;
  public selectedOffOnIndex = -1;
  public sitUserId = 0;
  public sitUserKey = '';
  public signedInUserId = 0;
  public signedInUserKey = '';
  public source = '';
  public timer : any;
  public lastUpdatedOn : any;

  constructor ()
  {
    this.awayMap = new Map<string, string>();
    this.awayMap.set("online", "online");
    this.awayMap.set("offline", "offline");
    this.awayMap.set("10mins", "away-10mins");
    //this.awayMap.set("15mins", "away-15mins");
    //this.awayMap.set("20mins", "away-20mins");
  }

  //public initAwayMap (): Map<string, string> {
  //  this.awayMap = new Map<string, string>();
  //  this.awayMap.set("online", "on-line");
  //  this.awayMap.set("offline", "off-line");
  //  this.awayMap.set("10mins", "away-10mins");
  //  this.awayMap.set("15mins", "away-15mins");
  //  this.awayMap.set("20mins", "away-20mins");
  //  return this.awayMap;
  //}
  

  public copy (sourceOffOn : OffOn) : OffOn | any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.heartbeat)) {
      if (FrequentlyUsedFunctionsServiceStatic.typeCheck(this.heartbeat) === 'object' && this.heartbeat instanceof Heartbeat) {
        this.heartbeat = this.heartbeat.copy(sourceOffOn.heartbeat);
      }

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.away)
        && sourceOffOn.away.length > 0) {
        this.away = sourceOffOn.away;
      }
      debugger;
      // this.awayMap = this.offOnService.setOffOnAwayMap("");
      debugger;
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.distance) && sourceOffOn.distance > 0) {
      //  this.distance = sourceOffOn.distance;
      //}
      //if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.distanceStr) && sourceOffOn.distanceStr.length > 0) {
      //  this.distanceStr = sourceOffOn.distanceStr;
      //}
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnArr) && sourceOffOn.offOnArr.length > 0) {
        this.offOnArr = sourceOffOn.offOnArr;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnId) && sourceOffOn.offOnId.length > 0) {
        this.offOnId = sourceOffOn.offOnId;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnElem)) {
        this.offOnElem = sourceOffOn.offOnElem;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnAwayElem)) {
        this.offOnAwayElem = sourceOffOn.offOnAwayElem;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnRadioElem)) {
        this.offOnRadioElem = sourceOffOn.offOnRadioElem;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnStatusEnum) && sourceOffOn.offOnStatusEnum > 0) {
        this.offOnStatusEnum = sourceOffOn.offOnStatusEnum;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnStatusColor) && sourceOffOn.offOnStatusColor.length > 0) {
        this.offOnStatusColor = sourceOffOn.offOnStatusColor;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.offOnStatusColorTimer) && sourceOffOn.offOnStatusColorTimer.toString().length > 0) {
        this.offOnStatusColorTimer = sourceOffOn.offOnStatusColorTimer;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.selectedOffOnIndex) && sourceOffOn.selectedOffOnIndex > -1) {
        this.selectedOffOnIndex = sourceOffOn.selectedOffOnIndex;
      }
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sourceOffOn.lastUpdatedOn) && sourceOffOn.lastUpdatedOn > 0) {
        this.lastUpdatedOn = sourceOffOn.lastUpdatedOn;
      }
    }
    return this;
  }

  public isReadyForUpdate () : boolean {
    // debugger;
    let isReady = false;
    let now = new Date().getTime();
    let diff = now - new Date(this.lastUpdatedOn).getTime();
    if (DateStringServiceStatic.dateInYYYYMMDDhhmmss(diff).mm >= 5 ) {
      isReady = true;
    }
    return isReady;
  }
}
/*
 * // ref: https://howtodoinjava.com/typescript/maps/
//2. Get entries
// let age = nameAgeMapping.get("John");		// age = 40

//3. Check entry by Key
// nameAgeMapping.has("Lokesh");		        // true
// nameAgeMapping.has("Brian");		        // false

//4. Size of the Map
// let count = nameAgeMapping.size; 	        // count = 3

//5. Delete an entry
// let isDeleted = nameAgeMapping.delete("Lokesh");	        // isDeleted = true

//6. Clear whole Map
// nameAgeMapping.clear();				//Clear all entries


 */ 
