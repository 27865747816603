// Note:  MessageModel will be used to display plain messages as well as spinner if necessary.
//        Hence SpinnerModel Model will not be used for Spinner-System.

export class SpinnerModel {
  public message: any;
  public isOpen = false; // may be used is 'isOpen'
  public isSpinner = false;
  public callerName = '';
  public id = 0;
  public date = '';
  public timer : any; // to be set to start the setTimeout
  public startTime : any;
  public endTime : any;
  public timeoutInterval = 15000; // to be set to end the setTimeout
  constructor () { }

  clear () {
    // debugger;
    this.isOpen = false;
    this.isSpinner = false;
    this.message = '';
    this.callerName = '';
    this.id = 0;
    this.date = '';
    this.timer = '';
    this.startTime = '';
    this.endTime = '';
    this.timeoutInterval = 1500;
  }
  getTimeoutInterval (): any
  {

    if ( this.endTime && this.startTime)
    {
      let eTime = this.endTime.getTime();
      let sTime = this.startTime.getTime();
      if ( eTime > sTime )
      {
        this.timeoutInterval = eTime - sTime + 5000;
      }
    }
   
    return this.timeoutInterval;
  }
  setTimeoutInterval ( interval : number ) : void
  {
    this.timeoutInterval = interval;
  }
}
