'use strict'
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { SpinnerOverlayComponent } from '../../app/modalModule/spinnerOverlay/spinnerOverlay.component';
import { SpinnerModel } from '../../models/common/spinnerModel.model';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

// ref: https://christianlydemann.com/four-ways-to-create-loading-spinners-in-an-angular-app/

@Injectable( {
  providedIn: 'root',
} )
export  class SpinnerOverlayService
{
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  public isSpinner = false;
  private loading : boolean = false;
  public spinnerCounter = 0;
  public spinnerModel : SpinnerModel = new SpinnerModel();
  // public overlay : Overlay;
  public overlayComponent : SpinnerOverlayComponent;
  public overlayRef : OverlayRef = null;;
  public timer : any;
  public timerMap : Map<any, any> = new Map();
  // @ViewChild(SpinnerOverlayComponent) overlayComponent! : SpinnerOverlayComponent;

 
  
  constructor (private overlay : Overlay) {
   }
  //  ---------------------------------------------------------------
  //ngOnDestroy () : void {
  //  this.emitterDestroyed$.next(true);
  //  this.emitterDestroyed$.complete();
  //  this.timerMap.forEach((timer) => clearInterval(timer));
  //  this.timerMap.clear();
  //}
  public setLoading (loading : boolean) {
    this.loading = loading;
  }

  public getLoading () : boolean {
    return this.loading;
  }

  // ---------------------------------------------------------------
  hideSpinner () {
    this.spinnerModel.message = '';
    EmitterSubjectService.emitHideSpinner(this.spinnerModel);
  }
  // ---------------------------------------------------------------
  public showSpinner (message?: string) {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(message) && message.length > 0) {
      var spm = new SpinnerModel();
      spm.message = message;
      spm.isSpinner = true;
      return this.showSpinnerModel(spm);
		}
  }
  // ---------------------------------------------------------------
   public showSpinnerModel (spm ?: SpinnerModel) {
    this.setLoading(true);

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spm) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spm.message)) {
      spm = new SpinnerModel();
      spm.message = "Busy..."
    }

    spm.isOpen = spm.isSpinner = true;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spm)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(spm.message)) {
      this.spinnerModel = spm;
      this.isSpinner = this.spinnerModel.isSpinner;
      // debugger;
      // this.spinnerOverlayService.show(this.spinnerModel.message, this.spinnerModel.isSpinner);
      EmitterSubjectService.emitShowSpinner(this.spinnerModel);
    }
   }
  // ---------------------------------------------------------------
  // public hideSpinner (message : string) {
  //  this.setLoading(false);
  //  this.spinnerModel.message = message as string;
  //  this.isSpinner = this.spinnerModel.isSpinner = false;
  //  EmitterSubjectService.emitHideSpinner(this.spinnerModel.message);
  //  // this.spinnerOverlayService.hide(this.spinnerModel.isSpinner);
  // }
  // ------------------------------------------------------------------------
  //  ---------------------------------------------------------------
  public show ( message = '', isOpen: boolean )
  {
    debugger;
    // Returns an OverlayRef (which is a PortalHost):
    // ----------------------------------------------
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.overlayRef)) {
      this.overlayRef = this.overlay.create();
      this.spinnerCounter++;

      // debugger;
      // Create ComponentPortal that can be attached to a PortalHost:
      // ------------------------------------------------------------
      let spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);
      let component = this.overlayRef.attach(spinnerOverlayPortal); // Attach ComponentPortal to PortalHost
    }
  }
  //  ---------------------------------------------------------------
  public hide (isOpen: boolean)
  {
    debugger;
    if (!isOpen) {
      let negORef = !this.overlayRef;
      let tempORef = !negORef;
      if (tempORef) {
        this.spinnerCounter--;
      }
    }
    // debugger;
    // this.checkSpinner();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.overlayRef)) {
      this.overlayRef.detach();
    }
  }
  //  ---------------------------------------------------------------
   public checkSpinner () {
    this.timer = setTimeout(() => {
      // debugger;
      this.spinnerCounter = 0;
      this.hide(false); // isOpen == false

      if (this.timerMap.has(this.timer)) {
        // debugger;
        clearTimeout(this.timer);
        this.timerMap.delete(this.timer);
      }
    }, 100);
     clearTimeout(this.timer);

    if (this.timer > 0 && !this.timerMap.has(this.timer)) {
      this.timerMap.set(this.timer, this.timer);
    }
   }
  // -----------------------------------------------------------------------------------
}
