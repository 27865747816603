'use-strict'
import {
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component,
    ElementRef,
    Inject, Input, OnDestroy, OnInit, Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { SitUser } from '../../../models/account/sitUser.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { KvAction } from '../../../models/keyValue/kvAction.model';
import { KvMemberActivityGlyph } from '../../../models/keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../../../models/keyValue/kvPhoto.model';
import { PagerPhotoMgmt } from '../../../models/pagination/pagerPhotoMgmt.model';
import { ProfileTile } from '../../../models/profile/profileTile.model';
import { GlyphiconMenuService } from '../../../services/GlyphiconService/glypgiconMenuService.service';
import { GlyphiconServiceService } from '../../../services/GlyphiconService/glyphiconServiceService.service';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { DisplayProfileService } from '../../../services/displayProfileServiceService/displayProfileService.service';
import { DisplayProfileServiceService } from '../../../services/displayProfileServiceService/dsiplayProfileServiceService.service';
import { MemberService } from '../../../services/memberServiceService/memberService.service';
import { MemberServiceService } from '../../../services/memberServiceService/memberServiceService.service';
import { AbstractPagerVPhotoNavService } from '../../../services/pagerServiceService/abstractPagerVPhotoNavService.service';
import { PagerPhotoMgmtService } from '../../../services/pagerServiceService/pagerPhotoMgmtService.service';
import { PagerServiceService } from '../../../services/pagerServiceService/pagerServiceService.service';
import { PagerSupportService } from '../../../services/pagerServiceService/pagerSupportService.service';
import { PhotoAndPrimaryPhotoMgmtService } from '../../../services/photoServiceService/photoAndPrimaryPhotoMgmtService.service';
import { PhotoServiceService } from '../../../services/photoServiceService/photoServiceService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { DomUtilsServiceStatic } from '../../../services/staticServices/domUtilsServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { JsRegExpServiceStatic } from '../../../services/staticServices/jsRegExpServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { MyPagerVPhotoNavService } from '../../myModule/myPagerVPhotoNav/myPagerVPhotoNavService.service';
import { CopyServiceStatic } from '../../../services/staticServices/commonStaticServices/copyServiceStatic.service';
import { DictionaryService } from '../../../services/dictionaryServiceService/dictionaryService.service';
import { DictionaryServiceService } from '../../../services/dictionaryServiceService/dictionaryServiceService.service';
import { ProfilePics } from '../../../models/profile/profilePics.model';

@Component({
  selector: 'app-pager-v-photo-nav',
  templateUrl: './pagerVPhotoNav.component.html',
  styleUrls: [ './pagerVPhotoNav.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [ Animations, slideInOutAnimation],

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class PagerVPhotoNavComponent implements OnInit, OnDestroy, AfterViewInit {
  public bias ! : BackgroundImageAnimationService;
  public dictionaryService! : DictionaryService;
  public displayProfileService : DisplayProfileService;
  public glyphiconMenuService! : GlyphiconMenuService;
  public memberService! : MemberService;
  public pagerPhotoMgmtService! : PagerPhotoMgmtService;
  public pagerSupportService! : PagerSupportService;
  public photoAndPrimaryPhotoMgmtService! : PhotoAndPrimaryPhotoMgmtService;
  public redirectionService! : RedirectionService;
  public rendererService! : RendererService;
  public renderer! : Renderer2;
  // -----------------------------------
  public currentPageNum = 1;
  public currentItemNum = 0;
  public currentItemKvPhoto : KvPhoto = new KvPhoto();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isKvData = false;
  public isKvFile = false;
  public isKvNext = false;
  public isKvPrev = false;
  public isKvUrlData = false;
  public isMobilevar = false;
  public isMyProfile = false;
  public isPhotoMgmt = false;
  public isStripUrl = true;
  public isUnlocked = false;
  public isViewMember = false;
  public isWithoutUrl = false; 
  public kvAction : KvAction = new KvAction();
  public kvPhotoArr : KvPhoto[] = [];
  public kvGlyphArr : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public kvMemActGlyphArrForPhotoMgmt: KvMemberActivityGlyph[] =[]; // for trigerring the display of glyph-menu at pagerVKvGlyphForPhotoMgmtComponent
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();

  private onDestroy$ : Subject<void> = new Subject<void>();
  @Input() pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt(); // for photo browsing by both signedInUser and sitUser
  public pageSize = 6;
  public primaryPhotoBnId = 0;
  public profileTile : ProfileTile = new ProfileTile();
  public profilePics : ProfilePics = new ProfilePics();
  public signedInUserId = 0;
  @Input() sitUser : SitUser = new SitUser();
  public spinnerModel : SpinnerModel = new SpinnerModel();
  // ------------------------------------------------------
  constructor (
    private abstractPagerVPhotoNavService : AbstractPagerVPhotoNavService,
    private cdr : ChangeDetectorRef,
    private commonServiceService : CommonServiceService,
    private dictionaryServiceService: DictionaryServiceService,
    private displayProfileServiceService : DisplayProfileServiceService,
    private elementRef : ElementRef,
    private glyphiconServiceService : GlyphiconServiceService,
    private memberServiceService : MemberServiceService,
    private myPagerVPhotoNavService : MyPagerVPhotoNavService,
    private pagerServiceService : PagerServiceService,
    public  photoServiceService: PhotoServiceService,
    private rendererServiceService : RendererServiceService,
    private router : Router,
    @Inject(Document) document : any) {
    this.redirectionService = this.commonServiceService.getRedirectionService();
    if (this.rendererServiceService) {
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
      this.rendererService = this.rendererServiceService.getRendererService() as RendererService;
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
    }
    if (this.dictionaryServiceService) {
      this.dictionaryService = this.dictionaryServiceService.getDictionaryService();
    }

    if (this.rendererService) { this.renderer = this.rendererService.getRenderer() as Renderer2; }
    if (this.memberServiceService) {
      this.memberService = this.memberServiceService.getMemberService();
    }
    this.isMobilevar = EmitterSubjectService.getIsMobile() as boolean;
    this.glyphiconMenuService = this.glyphiconServiceService.getGlyphiconMenuService();
    if (this.pagerServiceService) {
      
      this.pagerPhotoMgmtService = this.pagerServiceService.getPagerPhotoMgmtService();
      this.pagerSupportService = this.pagerServiceService.getPagerSupportService();
    }
    if (this.photoServiceService) {
      this.photoAndPrimaryPhotoMgmtService = this.photoServiceService.getPhotoAndPrimaryPhotoMgmtService();
		}
    this.displayProfileService = this.displayProfileServiceService.getDisplayProfileService();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
      EmitterSubjectService.setSitUserModel(this.sitUser);
      // debugger;
    }
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.isPhotoMgmt = EmitterSubjectService.getIsPhotoMgmt();
    this.isMyProfile = EmitterSubjectService.getIsMyProfile();
  }
  // --------------------------------------------------------------
  ngOnInit () : void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();   
    this.signedInUserId = this.loginSuccess.signedInUserId;
    DomUtilsServiceStatic.setDomUtilsElementRef(this.elementRef);
   
    // -------------------------------------------------------------
    if (this.sitUser && this.sitUser.sitUserId > 0) {
      if (this.sitUser.sitUserId === this.loginSuccess.signedInUserId) {
        this.primaryPhotoBnId = this.loginSuccess.primaryPhotoBnId;
      }
      else if(this.sitUser.primaryPhotoBnId > 0) {
        this.primaryPhotoBnId = this.sitUser.primaryPhotoBnId;
        // debugger;       
      }

      // ----------------------------------------------------------------
      // Begin: ProfileTile's case:
      // debugger;
      //display the user information from profileTile:
      // ---------------------------------------------
      this.profileTile = EmitterSubjectService.getSelectedProfileTile();
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileTile.primaryImage)) {
        this.myPagerVPhotoNavService.executeProfileTileKvPhotoAndGlyphMenuTasks(this.profileTile).subscribe(data => {

          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
            this.pagerVKvPhotoNav = data;
            this.kvPhotoArr = this.pagerVKvPhotoNav.kvPhotoArr;
            // debugger;
            EmitterSubjectService.emitTotalPhotoCount(this.kvPhotoArr.length);
          }
          // debugger;
          this.ngAfterViewInit();
        });
      }

      // get the dictionary profilePics second:
      // -------------------------------------
      
      this.profilePics = this.dictionaryService.profilePicsDictionary.get(this.sitUser.sitUserId);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.unFilteredKvPhotoArr)
        && this.profilePics.unFilteredKvPhotoArr.length > 0)
      {
        this.profilePics.populateDictionaries();
        this.kvPhotoArr = this.profilePics.unFilteredKvPhotoDictionary.values();
        // debugger
        this.abstractPagerVPhotoNavService.executeKvPhotoNavTasks(this.kvPhotoArr[ 0 ], this.kvPhotoArr).subscribe(data1 =>
        {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data1))
          {
            // debugger;
            this.pagerVKvPhotoNav = data1;

            // debugger;
            EmitterSubjectService.emitTotalPhotoCount(this.pagerVKvPhotoNav.kvPhotoArr.length);

            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
              && this.pagerVKvPhotoNav.pagedItems.length > 0)
            {
              this.pagerVKvPhotoNav.pagedItems.forEach(e => {

                // raw-image-data-case
                if (e.value.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
                  // debugger;
                  e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //will be without url
                }
                // file-name-case
                else if (e.value.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
                  // debugger;
                  e.value = JsRegExpServiceStatic.normalizeImageFileNameWithoutUrl(e.value);
                }

                if (e.privacy.toLocaleLowerCase().indexOf('private') !== -1) {
                  e.isPrivate = true;
                  if (!this.isUnlocked) {
                    e.value = 'assets/photos/private.png';
                  }
                }
                else e.isPrivate = false;
              })


              this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.pagerVKvPhotoNav.currentItem);
              this.ngAfterViewInit();
              // debugger;
            }
          }
          else {
            console.log('timestamp : ' + new Date().getTime() + ': DisplayProfileService->executeKvPhotoNavTasks() returned a null or undefined promise;');
          }
        })
        this.ngAfterViewInit();
        // debugger;
      }

      // --------------------------------------------------------------
      EmitterSubjectService.emitShowSpinner('Getting user pics...');
     
      // Get profilePics from the backend-server:
      // Note: This process is faster than ohther processes of getting user pics:
      // ------------------------------------------------------------------------
      this.sitUser = EmitterSubjectService.getSitUserModel();
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
        // debugger;
        this.displayProfileService.displayUserPicsFromServer(this.sitUser.sitUserId).subscribe(data => {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
            this.pagerVKvPhotoNav = data;

            EmitterSubjectService.emitTotalPhotoCount(this.pagerVKvPhotoNav.kvPhotoArr.length);

            // debugger;
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
              && this.pagerVKvPhotoNav.pagedItems.length > 0)
            {
              this.pagerVKvPhotoNav.pagedItems.forEach(e => {

                // raw-image-data-case
                if (e.value.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
                  // debugger;
                  e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //will be without url
                }
                // file-name-case
                else if (e.value.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
                  // debugger;
                  e.value = JsRegExpServiceStatic.normalizeImageFileNameWithoutUrl(e.value);
                }

                if (e.privacy.toLocaleLowerCase().indexOf('private') !== -1) {
                  e.isPrivate = true;
                  if (!this.isUnlocked) {
                    e.value = 'assets/photos/private.png';
                  }
                }
                else e.isPrivate = false;
              })


              this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.pagerVKvPhotoNav.currentItem);
              this.ngAfterViewInit();
            }
            EmitterSubjectService.emitHideSpinner('');
          }

        })
      }

      // get the sitUser from the emitter first:
      // because we jump to the parent component and loose it's value
      // ---------------------------------------
      this.sitUser = EmitterSubjectService.getSitUserModel();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
        // debugger;
        this.memberService.getIsUnlocked(this.sitUser.sitUserId).subscribe(data => {
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
            this.kvAction = data;
            this.isUnlocked = this.kvAction.isUnlocked;
          }
        })
      }
    }
    
    
    // --------------------------------------------------------------------------
    
    // -------------------------------------------------------------------------  
    EmitterSubjectService.pagerEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger; // TODO: check kvPhotoArr
        let tResult = result as unknown;
        this.pagerVKvPhotoNav = tResult as PagerPhotoMgmt;

        // this should call the following method regarless:
        // ---------------------------------------------------------
        this.pagerVKvPhotoNav.kvPhotoArr = this.photoAndPrimaryPhotoMgmtService.prefixUrlToKvPhotoArrForDisplay(this.pagerVKvPhotoNav.kvPhotoArr);
        this.pagerVKvPhotoNav.pagedItems = this.photoAndPrimaryPhotoMgmtService.prefixUrlToKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems);
        this.displayProfileService.executeProfileImagesTasks(this.pagerVKvPhotoNav.pagedItems).subscribe(data => {
          this.pagerVKvPhotoNav = data;
        });
        // debugger;
        this.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.pagerVKvPhotoNav.currentItem);
        this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.kvMemActGlyphArrForPhotoMgmt;

        this.pagerVKvPhotoNav.currentItem = this.bias.setupKvPhotoIfPrimaryOrPrivate(this.pagerVKvPhotoNav.currentItem);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
          && this.pagerVKvPhotoNav.pagedItems.length > 0) {
          this.pagerVKvPhotoNav.currentItem = this.bias.displayBackgroundAndNavImageForKvPhoto(this.pagerVKvPhotoNav.currentItem, this.pagerVKvPhotoNav);

          this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.pagerVKvPhotoNav.currentItem);
        }
        this.ngAfterViewInit();
        // debugger;
      });
    // -------------------------------------------------------------------------  
    EmitterSubjectService.pageSizeEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger; 
        this.pageSize = result as number;
        this.currentItemKvPhoto = EmitterSubjectService.getCurrentKvPhoto();
        this.currentPageNum = EmitterSubjectService.getCurrentPage().pageNum;
        // get initial pager which willbe used to 
        this.pagerVKvPhotoNav = this.abstractPagerVPhotoNavService.getPagerForVKvPhotoNav(this.kvPhotoArr, this.currentPageNum, this.pageSize);

        if (this.currentItemKvPhoto.pageNum === this.currentPageNum) {
          // Note:  the following line must be run right after the previous line/method: getPagerForVKvPhotoNav() !!
          this.pagerVKvPhotoNav = this.abstractPagerVPhotoNavService.setupPagerVKvPhotoNav(this.kvPhotoArr, this.currentItemKvPhoto);
        }

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)) {
          // debugger;
          this.abstractPagerVPhotoNavService.executePagerVKvPhotoNavTasks(this.pagerVKvPhotoNav).then(data => {
            this.pagerVKvPhotoNav = data;
            this.pagerVKvPhotoNav.pagedItems = this.abstractPagerVPhotoNavService.prepareKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems);
            this.ngAfterViewInit();
          });
        }
        this.ngAfterViewInit();
      });
    // -------------------------------------------------------------------------
    //EmitterSubjectService.isViewMemberEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe(result => {
    //     debugger;
    //    this.isViewMember = result as boolean;
    //    this.mcLoader.componentName = "memberViewMgmt";
    //    // since this listener is independent of isPhotoMgmtEmitter,
    //    // this should call the following method regardless:
    //    // ---------------------------------------------------------
    //    // this.pagerVKvPhotoNav.pagedItems = this.pagerVKvPhotoNavService.prepareKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems, this.isStripUrl);
    //    this.pagerVKvPhotoNav.kvPhotoArr = this.photoAndPrimaryPhotoMgmtService.prefixUrlToKvPhotoArrForDisplay(this.pagerVKvPhotoNav.kvPhotoArr);
    //    this.pagerVKvPhotoNav.pagedItems = this.photoAndPrimaryPhotoMgmtService.prefixUrlToKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems);
    //    // this.displayGlyphiconMenuOnLeft(this.pagerVKvPhotoNav);
    //    // debugger;
    //  });
    // -------------------------------------------------------------------------
    EmitterSubjectService.clearPagerPhotoNavDataEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
         debugger;

        // -------------------------------------------------//
        // Back-to-Back-memberView-pges-recognition-system: //
        // -------------------------------------------------//        
        if (this.redirectionService.isSignedInUserPagesBackToBack()) {
          // debugger;
          ; //do nothing 
        }
        else {
          // debugger;
          this.myPagerVPhotoNavService.clearPagerVKvPhotoNavService();
        }
      });
    // ===========================================================================
    //  Emitter for 'app-pager-vertical-glyph-for-photo-mgmt'
    // ---------------------------------------------------------
    // Note:  This listener receives pagerVKvPhotoNav Model,
    //        and assignes its properties to the vertical-menus'
    //        properties, and triggers the changeDetection,
    //        which causes the child-vertical-menu-components
    //        to update the views accordingly.
    //    
    //        When this pageVKvPhotoNav arrives, it assumes all of
    //        it's pagedItems images are ready to be rendered.!!
    // ---------------------------------------------------------
    EmitterSubjectService.displayPagerVKvPhotoNavEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
          this.pagerVKvPhotoNav = data as PagerPhotoMgmt;


          //  since server-sent data arrives late, the user may have moved on to other page.
          //  in that case we do not want to display the background image of the selected-member, 
          //  instead we may want to display the signedInUserImage instead.
          //  that is why the 'isCurrentUrlAProfilePage' method is used to check whether we are 
          //  still on the profile-page/memberViewMgmt/mhyPhotos page or not.
          // -----------------------------------------------------------------------------------------
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.currentItem)
            && this.redirectionService.isCurrentUrlAProfilePage()) {
            // debugger;
            
            this.pagerVKvPhotoNav.currentItem = this.bias.setupKvPhotoIfPrimaryOrPrivate(this.pagerVKvPhotoNav.currentItem);
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
              && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
              && this.pagerVKvPhotoNav.pagedItems.length > 0) {
              this.pagerVKvPhotoNav.currentItem = this.bias.displayBackgroundAndNavImageForKvPhoto(this.pagerVKvPhotoNav.currentItem, this.pagerVKvPhotoNav);
              // debugger;
              this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.pagerVKvPhotoNav.currentItem);
            }
          }
          this.ngAfterViewInit();
        }
      });
    // --------------------------------------------------------------------------
    EmitterSubjectService.selectedProfileTileEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result : any) => {
         debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
            this.profileTile = JSON.parse(JSON.stringify(result));
            let kvPhoto = CopyServiceStatic.copyFromProfileTileToKvPhoto(result);
            kvPhoto = this.bias.setupKvPhotoIfPrimaryOrPrivate(kvPhoto);
            this.bias.updateBackgroundImageOutsideAngular(kvPhoto.value);
            if (kvPhoto.elemId.indexOf('bodyId') === -1 && kvPhoto.elemId.indexOf('pagerVKvPhotoNav') !== -1) {
              this.bias.updateBackgroundImageOutsideAngularById(kvPhoto.elemId, kvPhoto.value);
            }
             debugger;
            this.ngAfterViewInit();
          }
        }
      });
    // --------------------------------------------------------------
    // this should call the following method regarless:
    // --------------------------------------------------------------
    this.isStripUrl = false;  
    
    DomUtilsServiceStatic.setDomUtilsElementRef(this.elementRef);
  }  
  // ---------------------------------------------------------------
  ngAfterViewInit () : void {
    // debugger;
    this.cdr.detectChanges();   
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.clearPagerVKvPhotoNavData();
  }
  // ====================================================================
  // --------------------------------------------------------------------
  // Begin of API:
  // --------------------------------------------------------------------
  // ====================================================================
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public clearPagerVKvPhotoNavData () : any {
    // debugger;
    this.kvPhotoArr = [];
    this.sitUser = new SitUser();
    this.pagerVKvPhotoNav = new PagerPhotoMgmt();
    // Note : memberView does not inject this service, so we are clearing the data from here:
    (this.myPagerVPhotoNavService as MyPagerVPhotoNavService).clearPagerVKvPhotoNavService();
    // debugger;
    return true;
  }

  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getKvPhotoUrl (kvPhoto : KvPhoto) : any {
    return this.myPagerVPhotoNavService.getKvPhotoUrl(kvPhoto);
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getKvPhotoWithoutUrl (kvPhoto : KvPhoto) : any {
    return this.myPagerVPhotoNavService.getKvPhotoWithoutUrl(kvPhoto);
  }  
   
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public setPagerVerticalPhotoNav (value : PagerPhotoMgmt) : void {
    this.pagerVKvPhotoNav = value;
  }
  // --------------------------------------------------------------------
  // API:
  // ------------------------------------------------------------
  public isInPagedItems (kvPhoto : KvPhoto) : boolean {
    return this.myPagerVPhotoNavService.isInPagedItems(kvPhoto, this.pagerVKvPhotoNav);
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvFileWithUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    return this.myPagerVPhotoNavService.getIsKvFileWithUrl(kvPhoto);
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvFileWithoutUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    return this.myPagerVPhotoNavService.getIsKvFileWithoutUrl(kvPhoto);
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvDataWithUrl (kvPhoto : KvPhoto) : boolean {
    return this.myPagerVPhotoNavService.getIsKvDataWithUrl(kvPhoto);
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public getIsKvDataWithoutUrl (kvPhoto : KvPhoto) : boolean {
    // debugger;
    return this.myPagerVPhotoNavService.getIsKvDataWithoutUrl(kvPhoto);
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public isNextOrLastPage () : boolean {
    return this.myPagerVPhotoNavService.isNextOrLastPage(this.pagerVKvPhotoNav);
  }
  // --------------------------------------------------------------------
  // API:
  // --------------------------------------------------------------------
  public isPreviousOrFirstPage () : boolean {
    return this.myPagerVPhotoNavService.isPreviousOrFirstPage(this.pagerVKvPhotoNav);
  }
  
  // --------------------------------------------------------------------
  // API:
  // Note: This should be called when a pager is needed for a pageNum.
  // --------------------------------------------------------------------
  public setPage (page : string) : any {
    // debugger;
    this.currentPageNum = this.myPagerVPhotoNavService.setPageAndGetCurrentPageNum(page, this.pagerVKvPhotoNav);
    
    // debugger;
    // First get the default pager for page#1:
    // ---------------------------------------
    let tPagerVKvPhotoNav = this.pagerPhotoMgmtService.getBasicPagerDynamic(this.pagerVKvPhotoNav.kvPhotoArr, this.currentPageNum, this.pageSize);
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPagerVKvPhotoNav)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tPagerVKvPhotoNav.pagedItems)
      && tPagerVKvPhotoNav.pagedItems.length > 0) {
      // debugger;
      this.pagerVKvPhotoNav = tPagerVKvPhotoNav;

      // this.pagerVKvPhotoNav.pagedItems = this.pagerVKvPhotoNavService.prepareKvPhotoArrForDisplay(this.pagerVKvPhotoNav.pagedItems, this.isStripUrl);
      if (this.isPhotoMgmt || this.isMyProfile) {
        // debugger;
        this.pagerVKvPhotoNav.kvMemActGlyphArrForPhotoMgmt = this.glyphiconMenuService.displayPhotoMgmtGlyphMenuForKvPhoto(this.currentItemKvPhoto); // will display photo primary/private/public glyphs
      }

      this.currentItemKvPhoto = this.bias.setupKvPhotoIfPrimaryOrPrivate(this.currentItemKvPhoto);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.pagerVKvPhotoNav.pagedItems)
        && this.pagerVKvPhotoNav.pagedItems.length > 0) {
        this.currentItemKvPhoto = this.bias.displayBackgroundAndNavImageForKvPhoto(this.currentItemKvPhoto, this.pagerVKvPhotoNav);

        this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, this.currentItemKvPhoto);
      }
    }
    this.ngAfterViewInit();    
    return this.pagerVKvPhotoNav;
  }

  // --------------------------------------------------------------------
  // API:
  // Note : View calls this method.
  //        Stable and works; on 20220429 & 20220514 & 20220622 & 20230108
  // --------------------------------------------------------------------
  public setPhotoNavItem (item : KvPhoto) : void {
    // debugger;
    this.myPagerVPhotoNavService.setPhotoNavItemBase(item, this.pagerVKvPhotoNav).subscribe(data => {
      this.pagerVKvPhotoNav = data;
      // NO need to display glyphicon because it is for non-signedInUser:
      this.pagerVKvPhotoNav.pagedItems.forEach(e => {

        // raw-image-data-case
        if (e.value.match(JsRegExpServiceStatic.allImageTypesDataPattern3)) {
          // debugger;
          e.value = JsRegExpServiceStatic.normalizeImageData(e.value); //will be without url
        }
        // file-name-case
        else if (e.value.match(JsRegExpServiceStatic.allImageTypesFilePattern)) {
          // debugger;
          e.value = JsRegExpServiceStatic.normalizeImageFileNameWithoutUrl(e.value);
        }
        this.bias.setKvPhotoBackgroundImage(item);  
        if (e.privacy.toLocaleLowerCase().indexOf('private') !== -1) {
          e.isPrivate = true;
          if (!this.isUnlocked) {
            e.value = 'assets/photos/private.png';
          }
        }
        else e.isPrivate = false;
        this.ngAfterViewInit();
        // debugger;
        this.bias.selectCurrentElemDeselctOthersForKvPhoto(this.pagerVKvPhotoNav.pagedItems, item);
      })
    });   
  }
  // ====================================================================
  // --------------------------------------------------------------------
  // End of API:
  // --------------------------------------------------------------------
  // ====================================================================

  // ---------------------------------------------------------------------------------
  private rotateArrowGlyphOfIdPrefix (idPrefix : string) : void {
    if (this.kvPhotoArr.length > 10) { this.glyphiconMenuService.rotateArrowGlyphiconOfIdPrefix(idPrefix); }
  }
  // ---------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------
  
}
