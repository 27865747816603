'use-strict'
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { TimerService } from '../../../services/coreServiceService/timerService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { BreadcrumServiceStatic } from '../../../services/staticServices/breadcrumServiceStatic.service';
import { ScrollTopServiceStatic } from '../../../services/staticServices/commonStaticServices/scrollTopServiceStatic.service';
import { DefaultSetterServiceStatic } from '../../../services/staticServices/defaultSetterServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

// ===========================================================================================================================
// ----------------------------------------------------- 
//  Login-Register-Nav-Header-Buttons-Management-System:
// -----------------------------------------------------
//  Login-Register-Nav-Header-Buttons-Management-System is a system where the 'SignupFree', and 'Login' buttons at the
//  adjacent to the Logo of the application are controlled at LogRegButtonsAndArrows.Component via MessageEmitter emitted
//  from LandingPageComponent, LoginComponent, LogoComponent and RegisterComponet.
//
//  MessageEmitter has a pattern of messages which the LogRegButtonsAndArrows.ts parses and determines the visibility state
//  of these two buttons.
//
//  The visibility of these two button depends on the folloing conditions:
//  1) default behavior of these buttons is to show/be visible at startup of the app
//  2) if the 'Login' component is displayed by any of these following buttons the 'Login' button should disappear:
//      2.1) by clicking the LogRegButtonsAndArrowsComponent.loginButton,
//      2.2) LangingPageComponent.loginButton
//      2.3 the X(close) button of RegisterComponent
//  3) if the 'Register' component is displayed by any of these following buttons the 'Registe' button should disappear:
//      2.1) by clicking the LogRegButtonsAndArrowsComponent.registerButton,
//      2.2) LangingPageComponent.signupFreeButton
//      2.3 the X(close) button of LoginComponent
//
//  Both SignupFree button and Login button should display when LandingPage is displayed either initially or clicking the logo
//  subsequently at any time later.
//
//  The messagees has the following patters:
//   1) When loginComponent is displayed, it should send a message containing the word 'Register' in the message in order to display RegisterComponent:
//      Example: emitMessage('Register-component-display');
//   2) When registerComponent is displayed, it should send a message containing the word 'Login' in the message in order to display LoginComponent:
//      Example: emitMessage('Login-component-display');
//   3) When both login button and signUpFree button neds to be displayed, it should send a message containing the word 'Register' and 'Login',
//      in the message in order to display both SingupFree and Login button at the Header 
//      Example: emitMessage('Register-Login-buttons-display');
//    
// ===========================================================================================================================

@Component({
  selector: 'app-log-reg-buttons-and-nav-arrows',
  templateUrl: './logRegButtonsAndNavArrows.component.html',
  styleUrls: [ './logRegButtonsAndNavArrows.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogRegButtonsAndNavArrowsComponent implements OnInit, OnDestroy, AfterViewInit
{
  public bias ! : BackgroundImageAnimationService;
  public redirectionService! : RedirectionService;
  public timerService! : TimerService;
  // ----------------------------------------------
  public breadcrumRing : ModuleComponentLoader[] = [];
  public breadCrmRngLength = 0;
  public currentUrl = '';
  public deviceInfo : any;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isLoginPageRendered = false;
  public isMobilevar = false;
  public isRegisterPageRendered = false;
  public isSuccess = true;
  @Input() loginSuccess : LoginSuccess = new LoginSuccess();
  public message : string = '';
  public pageTitle = '';
  @Input() pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public signedInUserId = 0;
  public timer : any;
  public timerArray : any[] = [];
  constructor (
    private cdr : ChangeDetectorRef,
    private commonServiceService: CommonServiceService,
    public coreServiceService : CoreServiceService,
    public rendererServiceService : RendererServiceService,
    public router : Router,  )
  {
    this.redirectionService = this.commonServiceService.getRedirectionService();
    if (this.coreServiceService) {
      this.timerService = this.coreServiceService.getTimerService();
    }
    if (this.rendererServiceService) {
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
    }
  }

   //  -------------------------------------------------------------------------
  ngOnInit () : void {

    this.isMobilevar = EmitterSubjectService.getIsMobile();

    this.currentUrl = this.getCurrentUrl()
    //  -------------------------------------------------------------------------
    // debugger;
    EmitterSubjectService.loginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        this.loginSuccess = result as LoginSuccess; //  JSON.parse(JSON.stringify(result));
        // debugger;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();

      });
    //  -------------------------------------------------------------------------
    // debugger;
    EmitterSubjectService.localStorageLoginSuccessEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        this.loginSuccess = result as LoginSuccess; //  JSON.parse(JSON.stringify(result));
        // debugger;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();

      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.pagerBreadcrumDirectionEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          this.setBreadcrumPage(result);
        }
      });
    //  -----------------------------------------------------------------
    EmitterSubjectService.pagerBreadcrumEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.pagerBrdCrm = result as PagerBreadcrum;
        this.ngAfterViewInit();
      })
    // ----------------------------------------------------- 
    //  Login-Register-Nav-Header-Buttons-Management-System:
    // -----------------------------------------------------
    //  Note: this emitter is listened to only for the purpose of
    //  determining the login / register page being rendered or not
    // -----------------------------------------------------
    EmitterSubjectService.messageEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        if (typeof result === 'string' && (result.indexOf('Login') !== -1 || result.indexOf('Signup') !== -1)) {
          let url = this.checkCurrentUrl(result);
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(url)) {
            this.currentUrl = this.getCurrentUrl();
          }

          this.timerService.resetMessageTimer(2000);
        }
        this.ngAfterViewInit();
      });
  }
  //  ---------------------------------------------------------------------------------
  ngAfterViewInit () : any
  {
    this.cdr.detectChanges();
    this.breadcrumRing = BreadcrumServiceStatic.getRing();
    this.pagerBrdCrm = BreadcrumServiceStatic.getPagerBreadcrum();
    // debugger;
    this.breadCrmRngLength = this.setBreadCrumRingLength(this.pagerBrdCrm);
    this.executeLoginSuccessTasks();
    return true;
  }
  //  ---------------------------------------------------------------
  ngOnDestroy (): void
  {
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );
  }
  // ---------------------------------------------------------------------------------
  public executeLoginSuccessTasks () : any
  {
    if (this.loginSuccess.signedInUserId > 0) {
      this.isSuccess = this.loginSuccess.isSuccess;
      this.signedInUserId = this.loginSuccess.signedInUserId;
      // debugger;
      this.isLoginPageRendered = true;
      this.isRegisterPageRendered = true;
      return this.loginSuccess;
    }
  }
  // --------------------------------------------------------------
  public setRoute (route : string, id : number) : void {
    // debugger;
    this.redirectionService.setRoute(this.router, route, id).subscribe(data => {
      this.pagerBrdCrm = data;
    });

  }
  // --------------------------------------------------------------
  public setRouteBreadcrum (route : string, id : number) : void {
    // debugger;
    this.pagerBrdCrm = this.redirectionService.setRouteforSignedInUser(this.router, route, id);

  }
  // ----------------------------------------------------- 
  //  Login-Register-Nav-Header-Buttons-Management-System:
  // -----------------------------------------------------
  checkCurrentUrl (currentPage : string) : string {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(currentPage)) {
      if (currentPage?.toString().toLowerCase().indexOf('login') !== -1 && currentPage?.toString().toLowerCase().indexOf('signup') !== -1) {
        this.isLoginPageRendered = false;
        this.isRegisterPageRendered = false;
      }
      else if (currentPage?.toString().toLowerCase().indexOf('login') !== -1 && currentPage?.toString().toLowerCase().indexOf('signup') === -1) {
        this.isLoginPageRendered = true;
        this.isRegisterPageRendered = false;
      }
      else if (currentPage?.toString().toLowerCase().indexOf('signup') !== -1 && currentPage?.toString().toLowerCase().indexOf('login') === -1) {
        this.isRegisterPageRendered = true;
        this.isLoginPageRendered = false;
      }
      else {
        // debugger;
        return this.getCurrentUrl();
      }
      return currentPage;
    }
    else {
      return '';
    }
  }
  // ----------------------------------------------------- 
  //  Login-Register-Nav-Header-Buttons-Management-System:
  // -----------------------------------------------------
  getCurrentUrl () : string {
    this.currentUrl = this.redirectionService.getUrlAddress();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.currentUrl)) {
      if (this.currentUrl.toLowerCase().indexOf('login') !== -1) {
        this.isLoginPageRendered = true;
      }
      if (this.currentUrl.toLowerCase().indexOf('signup') !== -1) {
        this.isRegisterPageRendered = true;
      }
    }
    return this.currentUrl;
  }
  //  ------------------------Completed on 2021/04/---------------------------------------
  setBreadcrumPage (direction : string) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(direction)) {
      this.pagerBrdCrm = BreadcrumServiceStatic.setBreadcrumPage(direction, this.router);
      this.breadCrmRngLength = this.setBreadCrumRingLength(this.pagerBrdCrm);

      DefaultSetterServiceStatic.clearUserData();
      ScrollTopServiceStatic.scrollToTop('allScrollToId');
    }
    return true;
  }
  // ---------------------------------------------------------------------------
  setBreadCrumRingLength (pagerBrdCrm : PagerBreadcrum) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerBrdCrm)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerBrdCrm.breadcrumRing)
      && this.pagerBrdCrm?.breadcrumRing?.length > 0) {
      this.breadCrmRngLength = pagerBrdCrm?.breadcrumRing?.length;
    }
    else this.breadCrmRngLength = 0;

    return this.breadCrmRngLength;
  }
  // ----------------------------------------------------------------
}
