
import { Injectable } from '@angular/core';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { Unit } from '../../models/common/unit.model';
import { KvAny } from '../../models/keyValue/kvAny.model';
import { MemberViewMgmtModel } from '../../models/member/memberViewMgmtModel.model';
import { Preference } from '../../models/profile/preference.model';
import { ProfileInfo } from '../../models/profile/profileInfo.model';
import { MemberService } from '../memberServiceService/memberService.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { HeartbeatService } from './heartbeatService.service';
import { KvArrayService } from './kvArrayService.service';

// =====================================================================
//  Use Case:
// ----------
//  In a Component:
//      import { Subject } from 'rxjs;
//      import { takeUntil } from 'rxjs/operators';
//        ...
//      private onDestroy$: Subject<void> = new Subject<void>();
//        ...
//      this.RandomNumberGeneratorObservableServiceStatic.getRandomNumber()
//      .pipe( takeUntil( this.onDestroy$ ) )
//      .subscribe( ( luckyNumber : number ) =>
//      {
//        this.number1 = luckyNumber;
//        ...
//      public ngOnDestroy(): void {
//        this.onDestroy$.next();
//      }
// =====================================================================

@Injectable({ providedIn: 'root'})
export abstract class LiaisonService
{
  public distUnit = '';
  public isMobilevar = false;
  public height : any;
  public width : any;
  public heightUnit : any;
  public kvAnyArr : KvAny[] = [];
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public memberViewMgmtModel : MemberViewMgmtModel = new MemberViewMgmtModel();
  public preference : Preference = new Preference();
  public preferenceKvAnyArr : KvAny[] = [];
  public profileInfo : ProfileInfo = new ProfileInfo();
  public profileInfoView : ProfileInfo = new ProfileInfo();
  public weightUnit : any;

  // ------------------------------------------------------------------------------------
  // the laisonOf the services should be injected. For example,
  // here profileTileService's methods will be consumed by profileInfoService,
  // hence, profileTile's liaison-methods with profileInfoService are implemented here
  // there is a provider-service, and a consumer-service in a laision relationship.
  // here the provider-service: ProfileTileService, and
  // consumer-service: ProfileInfoService.
  // Note: no method of the consumer-service should appear in the liaisonService.
  // ------------------------------------------------------------------------------------

  constructor (
    private heartbeatService : HeartbeatService,
    private kvArrayService : KvArrayService,
    private memberService : MemberService,
  ) {
  }
  // ------------------------------------------------------------------
  // ---------------------------------------------------------------
  // create KVAny[] for the child component ProfileInfokeyValueArrComponent,
  // and emit it as well.
  // ---------------------------------------------------------------

  public createKVModelArrFromProfileInfoView (pInfoView : ProfileInfo) : any {
    let tArrKvAny : any;
    let unit : any;
    let index = -1;
    let i = 0;
    let distanceUnit = {
      distance: 0,
      dUnit: 'meter',
    };
    let model : any;

    // BEGIN of #2 !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfoView)) {
      this.profileInfoView = pInfoView;
      // debugger;
      tArrKvAny = this.kvArrayService.getObjectModelToArrKvAny(this.profileInfoView);
      // debugger;
      this.kvAnyArr = this.kvArrayService.sortArrKvAnyForView(tArrKvAny);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvAnyArr)) {
        for (let e of this.kvAnyArr) {
          /*this.arrKvAny.map(e => {*/
          if (e.key.toLowerCase().toString().indexOf('distance') !== -1) {
            index = i;
            distanceUnit.distance = e.value;
          }
          // debugger;
          if (e.key.toLowerCase().toString().indexOf('unit') !== -1) {
            distanceUnit.dUnit = e.value;
            switch (e.value.toLowerCase().toString()) {
              case 'Meters': case 'Meter': case 'M':
                unit = 'meters';
                break;
              case 'Mile': case 'Miles':
                unit = 'miles';
                break;
              case 'KM': case 'KiloMeters': case 'KiloMeter':
                unit = 'km';
                break;
              case 'Foot':
                unit = 'foot';
                break;
              case 'Feet':
                unit = 'feet';
                break;
            }
          }
          i++;
        }
        // debugger;
        if (this.kvAnyArr.length > index && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.kvAnyArr[ index ])) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unit)) {
            // debugger;
            this.kvAnyArr[ index ].value = this.kvAnyArr[ index ].value.toString() + ' ' + unit;
          }
          else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit) && (distanceUnit.distance >= 0 || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.dUnit))) {
            // debugger;
            this.kvAnyArr[ index ].value = distanceUnit.distance.toString() + ' ' + distanceUnit.dUnit.toLowerCase();
          }
        }
      }

      // if the data came from the back-end server, then check if the profile is complete and show message
      if (this.profileInfo.isServer) {
        // TODO: use emitter to do this:
        // this.displayProfileIncompleteMessage(this.profileInfo);
      }
      // END of #2 !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)

      model = {
        model: this.profileInfo,
        name: 'profileInfo',
        kvAnyArr: this.kvAnyArr,
      };
      EmitterSubjectService.emitModel(model);
      this.memberViewMgmtModel.populateModelData(this.kvAnyArr);
      // EmitterSubjectService.setMemberViewMgmtModel(this);
      return this.kvAnyArr;
    }
  }
  // ------------------------------------------------------------------
  // ---------------------------------------------------------------
  // Note: Keep the following three methods as a group:
  //       1. getDictionaryMemberProfile()
  //       2. getServerDbMemberProfile()
  // ---------------------------------------------------------------
  // Note: treat dictionary-data as all-good-or-nothing. important!!!
  // ---------------------------------------------------------------
  //public getDictionaryMemberProfile (sitUserId : number) : any {
  //  if (sitUserId > 0) {
  //    let tpPics : any;
  //    let tProfileInfo : any;
  //    let tProfilePics : any;
  //    let tPreference : any;
  //    let tUserPhotoIds : any;
  //    let emitterUserPhotoIds : any;
  //    let isDictionaryPrefDataGood = false;
  //    let isDictionaryProfInfoDataGood = false;
  //    let isDictionaryProfPicsDataGood = false;

  //    // debugger;

  //    tProfileInfo = DictionaryServiceStatic.profileInfoDictionary.getValue(sitUserId);
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(tProfileInfo)) {
  //      // debugger;
  //      this.profileInfo = tProfileInfo;
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo)) {
  //        this.executeAllNonProfileTileTasks(this.profileInfo).then(data => {
  //          // debugger;
  //          this.memberViewMgmtModel = data;

  //          // if all tasks including preference were completed:
  //          // -------------------------------------------------
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel)
  //            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.memberViewMgmtModel.preferenceKvAnyArr)
  //            && this.memberViewMgmtModel.preferenceKvAnyArr.length > 0) {
  //            // debugger;
  //            isDictionaryProfInfoDataGood = true;
  //          }
  //          else {
  //            // debugger;
  //            this.profileInfo = data;
  //            isDictionaryProfInfoDataGood = false;
  //          }
  //          EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //        });
  //      }
  //    }
  //    // debugger;

  //    // TODO: check to see if memberActivcityDictionary need to be created or not:
  //    // --------------------------------------------------------------------------
  //    // if (!this.activityService.isDictionaryMemberActivityEmptyForSitUser(sitUserId)) {
  //    // }
  //    // debugger;
  //    // this.spinnerModalService.stopSpinner();
  //  }
  //  return sitUserId;
  //}
  // ------------------------------------------------------------------
  //  Tested, works!
  // ------------------------------------------------------------------
  // ===============================================================
  // Begin of All ProfileTile/ProfileInfo methods related to:
  //  1) memberViewMgmtComponent
  //  2)myProfileViewMgmtComponent
  // ===============================================================
  // ----------------------------------------------------------------------------------------------
  // Note: this method will capture all UserData ( not pics) via the
  //        promise of executeProfileInfoTasks():
  // ----------------------------------------------------------------------------------------------
  // Note:  This does NOT violate SOLID principal in a sense that preference is obtained from the dictionary in
  //        executeAllNonProfileTileTasks()  instead of getting it from the backend-server.
  //        However, if the user's preference data is not in the dictionary, it fails at this task.
  //
  //        It is noteworthy that both server-based data as well as dictionary-based data use executeAllNonProfileTileTasks().
  //        Since server-data upon arrival is saved into the respective dictionary, it successfully executes all tasks
  //        for the server-data, as well as dictionary-data when present.
  //
  //        When executeAllNonProfileTileTasks() succesfully executes all tasks, 
  //        it retuns a ProfileInfoView, otherwise it returns ProfileInfo.
  //
  //  we check if the preference was successfully processed to determine what was the return value:
  //  if preferenceKvAnyArr was created, then it returned profileInfoView, else profileInfo:
  // ----------------------------------------------------------------------------------------------
  //            ProfileTileTasks are stand-alone and should be called only for ProfileTile-data.
  //            This method should NOT call/use executeProfileTileTasks(), which renders user's pics only
  //            via VerticalMenues.
  //
  //            In this method the following tasks are executed:
  //            profileInfoTasks, profileContentTasks and preferencesTasks.
  //
  //            executeProfileTileTasks() and exexuteasAllNonProfileTileTasks() are
  //            mutually exclusive/complementary.
  // ---------------------------------------------------------------
  // public executeAllNonProfileTileTasks (pInfo : ProfileInfo) : Promise<MemberViewMgmtModel | any> {
  //  return new Promise<any>((resolve, reject) => {
  //    // debugger;
  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pInfo)) {
  //      this.profileInfo = pInfo;
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfo) && this.profileInfo.sitUserId > 0) {
  //        // debugger;
  //        this.profileTileService.executeProfileInfoTasks(this.profileInfo).then(data => {
  //          this.memberViewMgmtModel = data;
  //          // debugger;

  //          // try to get the preference data from the dictionary only, NOT from the server:
  //          // -----------------------------------------------------------------------------
  //          this.preference = DictionaryServiceStatic.preferenceDictionary.getValue(this.profileInfo.sitUserId) as Preference;
  //          // debugger;
  //          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.preference) && this.preference.sitUserId > 0) {
  //            // debugger;
  //            this.memberService.setPreference(this.preference);
  //            this.preferenceKvAnyArr = this.kvArrayService.getPeferenceToArrKvAny(this.preference);

  //            // debugger;
  //            this.memberViewMgmtModel.populateModelData(this.preference, this.preferenceKvAnyArr);
  //            EmitterSubjectService.emitMemberViewMgmtModel(this.memberViewMgmtModel);
  //          }
  //          // debugger;

  //          resolve(this.memberViewMgmtModel);
  //        });
  //      }
  //    }
  //  })
  // }
  // ----------------------------------------------------------------------
  // ---------------------------------------------------------------
  public processProfileInfoForHeartbeat (hb : Heartbeat) : ProfileInfo {
    let distanceUnit = new Unit();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // if Heartbeat's distance is not already calculated:
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb.distance)) {
      if (hb.distance === 0) {
        // compute distance and unit:
        distanceUnit = this.heartbeatService.calculateDistance(hb, this.loginSuccess.heartbeat, true); // (dest, origin, isKm)
        // debugger;

        let tProfileInfoView = this.processDistanceUnitForProfileInfoView(distanceUnit) as ProfileInfo;
        this.profileInfoView.copyIfExists(tProfileInfoView);
      }
    }
    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
      this.profileInfoView.distance = hb.distance.toFixed(2);
      this.profileInfoView.unit = hb.unit.length > 0 ? hb.unit : 'meter';
      // debugger;
    }
    // EmitterSubjectService.emitProfileInfoView(this.profileInfoView);
    return this.profileInfoView;
  }
  // ---------------------------------------------------------------
  public processDistanceUnitForProfileInfoView (distanceUnit : Unit) : ProfileInfo {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.distance)
      && distanceUnit.distance >= 0
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(distanceUnit.unit)) {
      let result = this.heartbeatService.processDistanceUnit(distanceUnit);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profileInfoView)) {
          this.profileInfoView.distance = distanceUnit.distance.toFixed(2);
          this.profileInfoView.unit = distanceUnit.unit;
        }
      }
      // debugger;
    }
    return this.profileInfoView;
  }
  // ----------------------------------------------------------------------
  
  // ------------------------------------------------------------------------
}
