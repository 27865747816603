export class ChatConnectionInfo {
  public id = 0;
  public date!: Date;
  public cancellationToken: any;
  public channel: any;
  public connectionId: any;
  public cryptUserKey = '';
  public sitUserId = 0;
  public sitUserKey = '';
  constructor() { }
}
