
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { EmitterSubjectService } from '../../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { DomUtilsServiceStatic } from './../domUtilsServiceStatic.service';

// Note:  Important!!
//        For routing purpose, in cases where signedInUserId is required,
//        we obtain it from the EmitterServiceStatic instead of passing it as a parameter.
//
//        The sitUserId in the ModuleComponentLoader model is deprecated.!!
//
//        Hence, only non-singedInUserId and other Ids can be passed as parameter.
//        This decision is made on 20240923, and the entire application's routing is overhauled.

@Injectable({ providedIn: 'root'})
export abstract class ModuleComponentLoaderServiceStatic
{
  
  public elementRef = EmitterSubjectService.getElementRef();
  public emitterDestroyed$ : Subject<boolean> = new Subject();
  static randomNumberGenerator$ : Observable<number>;
  static randomNumber = 0;
  static loginSuccess : LoginSuccess = new LoginSuccess();
  static mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  // ---------------------------------------------------------
  constructor (private router : Router) {
    
    //  -------------------------------------------------------------------------
    EmitterSubjectService.removeComponentEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        if (result) {
          let tMcLoader = result as ModuleComponentLoader;
          let timer = setTimeout(() => {
            // debugger;

            // alert( this.mcLoader.callerName + '<-- is unloading -->' + this.mcLoader.componentName );
            DomUtilsServiceStatic.unloadComponent(tMcLoader.componentName);

            this.emitterDestroyed$.unsubscribe();
            clearInterval(timer);
          }, 5);
          clearTimeout(timer);
        }
      });
  }
  // ---------------------------------------------------------------
  ngOnDestroy () {
    /*
     * prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *       (2) https:// www.concretepage.com/angular-2/angular-4-renderer2-example
     *       (3) https:// stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *       (4) https:// stackblitz.com/edit/timer-with-pdestroyed
     */
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------
  static getRandomNumber () : Observable<number> {
    this.randomNumber++;

    if (!this.randomNumberGenerator$) {
      this.randomNumberGenerator$ = Observable.create((subject : Subject<number>) => {
        let timer = setInterval(() => {
          const num = Math.floor(Math.random() * 10);
          subject.next(num);
          clearInterval(timer);
        }, 5);

        clearInterval(timer);
      });
    }
    return this.randomNumberGenerator$;
  }
  // ---------------------------------------------------------
  // ---------------------------------------------------------
  public static isValidModuleComponentLoader (mcl : ModuleComponentLoader) : boolean {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl)
      && (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl?.componentName)
      || !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl?.moduleName))
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcl?.url)) {
      return true;
    }
    else return false;
  }
  // ----------------------------------------------------------------------

  // ---------------------------------------------------------------------------------
  static getMcLoaderComponentId (mcLoader : any) : any {
    let mcLoaderComponentId = '';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.componentName)) {
      mcLoaderComponentId = mcLoader.componentName + 'ComponentId';
    }
    return mcLoaderComponentId;
  }
  // --------------------------------------------------------------
  // Note: this method guarantees that a non-empty McLoader is returned
  //       It is used by other components & services
  // --------------------------------------------------------------
  public static setupMcLoaderModel (mcLoader : ModuleComponentLoader) : any {
    let comparator : string = '';
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader)) {
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.componentName)) {
        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.action)) {
          // debugger;
          comparator = mcLoader.action = mcLoader.componentName;
        }
      }
      else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcLoader.action)) {
        comparator = mcLoader.componentName = mcLoader.action;
      }
      mcLoader.returnUrl = window.location.pathname + window.location.search;
     
      // debugger;
      switch (comparator.toLowerCase()) {
        case 'buddies':
        case 'iblocked':
        case 'ifriended':
        case 'iliked':
        case 'istarred':
        case 'itracked':
        case 'utracked':
        case 'iwinked':
        case 'uwinked':
        case 'iunlocked':
          // debugger;
          mcLoader.moduleName = 'activitym';
          break;

        case 'memexpdate':
            mcLoader.moduleName = 'appadminm'
          break;

        case 'mychats':
        case 'chathistory':
        case 'chat':
          mcLoader.moduleName = 'chatm';
          // debugger;
          break;

        case 'myemails':
        case 'myenotes':
        case 'viewemail':
        case 'viewenote':
        case 'composeemail':
        case 'composeenote':
          mcLoader.moduleName = 'communicationm';
          break;

        case 'about':
        case 'chatui':
        case 'features':
        case 'comparememberships':
        case 'contactus':
        case 'membercontactus':
        case 'notfound':
        case 'privacy':
        case 'terms':
          mcLoader.moduleName = 'publicm';
          break;

        case 'forgot':
        case 'forgotpassword':
        case 'login':
        case 'logout':
        case 'resetpassword':
          // debugger;
          mcLoader.moduleName = 'loginm';
          break;

        case 'register':
          mcLoader.moduleName = 'registerm';
          break;

        case 'prescreen':
        case 'signup':
          mcLoader.moduleName = 'signupm';
          // debugger;
          break;

        case 'members':
        case 'membersindividually':        
          mcLoader.moduleName = 'membersm';
          break;

        case 'memberscroll':
          mcLoader.moduleName = 'memberscrollm';
          break;

        case 'profiletilescroll':
          mcLoader.moduleName = 'profiletilescrollm';
          break;

        //case 'memberviewmgmt':
        //  mcLoader.moduleName = 'memberViewMgmtm';
        //  break;

        case 'memberview':
        case 'upgradedmembers':
          mcLoader.moduleName = 'memberViewAndUpgradedm';
          break;

        case 'memberedit':
        case 'profile':
        case 'preference':
        case 'profilecontent':
          mcLoader.moduleName = 'profilem';
          break;
          
        case 'dialog':
        case 'message':
        case 'spinner':
          mcLoader.moduleName = 'modalm';
          break;

          case 'myprofileviewmgmt':
          case 'myphotomgmt':
          mcLoader.moduleName = 'mym';
          // debugger;
          break;

        case 'distance':
        case 'gayatriyantra':
        case 'sandbox':
        case 'csjstime':
          mcLoader.moduleName = 'sandboxm';
          break;

        case 'payment':
        case 'upgraded':
          mcLoader.moduleName = 'salesm';
          break;

        case 'paymenttest':
        case 'upgradetest':
          mcLoader.moduleName = 'salestestm';
          break;

        case 'signup':
          mcLoader.moduleName = 'signupm';
          break;

        case 'animation':
        case 'boxnoncetest':
        case 'errorlog':
        case 'gesturetest':
        case 'imageresizer':
          mcLoader.moduleName = 'staffm';
          break;

        //case 'chatui':
        //  mcLoader.moduleName = 'publicm';
        //  break;

        default:
          // debugger;
          mcLoader.moduleName = 'app';
          mcLoader.componentName = 'index';
          break;
      }
    }
    else {
      // debugger;
      mcLoader.moduleName = 'app';
      mcLoader.componentName = 'index';
      mcLoader.controller = mcLoader.moduleName;
      mcLoader.action = mcLoader.componentName;
    }
    mcLoader.controller = mcLoader.moduleName;
    mcLoader = this.getUrl(mcLoader);
    mcLoader.url = this.getUrlWithBackSlashPrefix(mcLoader.url);
    // debugger;
    EmitterSubjectService.setMCLoader(mcLoader);
    // debugger;
    return mcLoader;
  }
  // --------------------------------------------------------------
  public static getUrl (mcloader : ModuleComponentLoader) : any {
    let path = '';
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcloader.moduleName) && mcloader.moduleName.length > 0
      && mcloader.componentName && mcloader.componentName.length > 0) {     
       let compName = mcloader.componentName.toLowerCase();
      // debugger;

      switch (compName) {
        case 'index':
          path = mcloader.componentName;
          console.log('path: ' + path);
          break;
        case 'about':
        case 'buddies':
        case 'buddy':
        case 'chatui':
        case 'contactus':
        case 'dialog':
        case 'distance':
        case 'displayinvoice':
        case 'gayatriyantra':
        case 'features':
        case 'forgot':
        case 'forgotpassword':
        case 'iblocked':
        case 'ifriended':
        case 'iliked':
        case 'istarred':
        case 'itracked':
        case 'iunlocked':
        case 'iwinked':
        case 'login':
        case 'logout':
        case 'memexpdate': // ?
        case 'message':              
        case 'notfound':
        case 'privacy':
        case 'profiletilescroll':
        case 'register':      
        case 'spinner':
        case 'signup':
        case 'terms': 
          // debugger;
          // Note: this condition is required for finding out which path is failing to capture on console.log().
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcloader.moduleName) && mcloader.moduleName.length > 0
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mcloader.componentName) && mcloader.componentName.length > 0) {
            path = mcloader.moduleName + '/' + mcloader.componentName;
          }
          else {
            console.log('path not found: ' + path);
          }
          console.log('path: ' + path);
          break;

        // Note: these routes are initiated by and for the signedInUser:
        case 'comparememberships':
        case 'memberedit':
        case 'mychats':
        case 'myemails':
        case 'myenotes':
        case 'myfriends':
        case 'myphotomgmt':
        case 'myprofileviewmgmt':
        case 'payment':
        case 'paymenttest':
        case 'memberedit':
        case 'profile':
        case 'preference':
        case 'profilecontent':
        case 'sandbox':
        case 'upgradedmembers':
        case 'upgradetest':
          // debugger;
          if (this.loginSuccess.signedInUserId > 0) {
            path = mcloader.moduleName + '/' + mcloader.componentName;
          }
          else {
            console.log('path not found: ' + path);
          };
          console.log('path: ' + path);
          break;
        // Note: these routes are initiated about or by the non-signedInUser:
        case 'chathistory':
        case 'chat':
        case 'composeemail':
        case 'composeenote':
        case 'membercontactus':
        case 'memberview':      
        case 'viewemail':
        case 'viewenote':
          // debugger;
          if (mcloader.id > 0) {
            path = mcloader.moduleName + '/' + mcloader.componentName + '/' + mcloader.id;
          }
          else {
            console.log('path not found: ' + path);
          };
          console.log('path: ' + path);
          break;
        default:
          // debugger;
          if (mcloader.id > 0) {
            path =  mcloader.moduleName + '/' + mcloader.componentName + '/' + mcloader.id.toString();
          }
          else {
            path =  mcloader.moduleName + '/' + mcloader.componentName;
          }
          console.log('default path: ' + path);
          break;
      }
    }
    // debugger;
    mcloader.url = path;
    // debugger;
    return mcloader;
  }
  // --------------------------------------------------------------
  public static getUrlWithBackSlashPrefix (path : any) : any {
    let tPath = ''
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(path)) {
      tPath = '/' + path;
    }
    return tPath;
  }
  // --------------------------------------------------------------
  
  // ------------------------------------------------------------------------
}
