<!--=================================================Begin of User-Profile-&-Username Section=============================================-->
<!-- User-Profile-&-Username (on the right hand sode of the Heading Sction)  -->
<!-- If we use '*ngIf' here at the top div, the image does not get rendered since it does not find the id="primaryPhotoBnId" -->
<div class="flex-container text-center"
     style="position: fixed; will-change: transform; float: right; width: 4rem; top: 0.4rem; right: 1rem; height: auto; margin: 0.25rem; padding: 0rem; display: inline-block; z-index: 13; justify-content: center; text-align: center; border: 1.5px solid transparent;">

  <!--Item-1: User-Profile| *ngIf="signedInUserId > 0"-->
  <!--Item-1-a: outer square of image | -->
  <!--Note: Under the current setup, this div cannot be conditionally rendered; border is hidden before logged in, -->

  <div class="pImageSquare flex-item text-center smaller increase"
       style="margin:0rem; padding:0;clear: both;"
       (click)=" viewMyProfile();">

    <!--Item-1-b: inner circle of image  -->
    <div id="primaryPhotoBnId"
         class="smaller coin pImageCircle"
         style="height: 5.5rem; width: 5.5rem;margin: -1rem -1rem -0.5rem -0.75rem">
    </div>
  </div>

  <div class="flex-text-center increase bluGrnGldxSB"
       [innerHTML]="loginSuccess.profileName | safe: 'html'"
       style="width:100%; height: 0.5rem; margin:0.5rem 0.1rem 0.5rem -0.2rem;z-index:14;background-color:transparent;">
    {{loginSuccess.profileName}}
  </div>
  
  <!--<div *ngIf="isEyeOpen"
       (click)="toggleIsEyeOpen();"
       class="glyphicon glyphicon-folder-open flex-text-center drkRedGrnB"
       style="vertical-align: middle;margin-top: 0.15rem;padding:0.1rem;"
       aria-hidden="true">
    <div class="drkRedGrnSB text-center hvr-icon">Open</div>
  </div>-->

</div>
