// Note: the Kv name where the first leter is capital and the second letter is lowercase, implies that the value is of type 'any'
export class KvAction {
  public id: any;
  public cryptUserKey = '';
  public date = '';
  public doeeSitUserId = 0;
  public doerSitUserId = 0;
  public expDate = '';
  public index = 0;
  public isUnlocked = false;
  public key = ''; // Note: memberActivityId is a good candidate for this model
  public memberActivityId = 0;
  public profileId = 0;
  public receiverSitUserId = 0;
  public signedInUserId = 0;
  public senderSitUserId = 0;
  public value: any; // expected to hold action-name as string
  constructor() { }
  copy(a: KvAction): KvAction {
    const that = new KvAction();

    if (a.cryptUserKey) {
      that.cryptUserKey = a.cryptUserKey;
    }
    if (a.date) {
      that.date = a.date;
    }
    if (a.doeeSitUserId) {
      that.doeeSitUserId = a.doeeSitUserId;
    }
    if (a.doerSitUserId) {
      that.doerSitUserId = a.doerSitUserId;
    }
    if (a.expDate) {
      that.expDate = a.expDate;
    }
    if (a.memberActivityId) {
      that.memberActivityId = a.memberActivityId;
    }
    if (a.profileId) {
      that.profileId = a.profileId;
    }
    if (a.id) {
      that.id = a.id;
    }
    if (a.isUnlocked) {
      that.isUnlocked = a.isUnlocked;
    }
    if (a.index) {
      that.index = a.index;
    }
    if (a.key) {
      that.key = a.key;
    }
    if (a.memberActivityId) {
      that.memberActivityId = a.memberActivityId;
    }
    if (a.receiverSitUserId) {
      that.receiverSitUserId = a.receiverSitUserId;
    }
    if (a.senderSitUserId) {
      that.senderSitUserId = a.senderSitUserId;
    }
    if (a.signedInUserId) {
      that.signedInUserId = a.signedInUserId;
    }
    if (a.value) {
      that.value = a.value;
    }
    return that;
  }
}
