/*
 * Note: the Kv name where the first leter is capital and the second letter is lowercase,
 *      implies that the value is of type 'any'
 */
export class KvAnyArr {
  public cryptUserKey = '';
  public date = '';
  public id = 0;
  public key = '';
  public modelName = '';
  public sitUserId = 0;
  public value: any[] = [];
  constructor() { }
}
