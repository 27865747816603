
import { Injectable } from '@angular/core';
import { KvPhotoService } from './kvPhotoService.service';
import { PhotoAndPrimaryPhotoMgmtService } from './photoAndPrimaryPhotoMgmtService.service';
import { PhotoBnService } from './photoBnService.service';
import { PhotoPrivacyService } from './photoPrivacyService.service';

@Injectable({
  providedIn: 'root',
})
export class PhotoServiceService {
  constructor (
    private kvPhotoService: KvPhotoService,
    private photoAndPrimaryPhotoMgmtService : PhotoAndPrimaryPhotoMgmtService,
    private photoPrivacyService : PhotoPrivacyService,
    private photoBnService: PhotoBnService,
  ) { }
  // ------------------------------------------------------------------
  setKvPhotoService (kps : KvPhotoService) : void {
    this.kvPhotoService = kps;
  }
  getKvPhotoService () : KvPhotoService {
    return this.kvPhotoService;
  }
  // ------------------------------------------------------------------
  setPhotoBnService (ps : PhotoBnService) : void {
    this.photoBnService= ps;
  }
  getPhotoBnService () : PhotoBnService {
    return this.photoBnService;
  }
  // ------------------------------------------------------------------
  setPhotoAndPrimaryPhotoMgmtService (pmgs : PhotoAndPrimaryPhotoMgmtService) : void {
    this.photoAndPrimaryPhotoMgmtService = pmgs;
  }
  getPhotoAndPrimaryPhotoMgmtService () : PhotoAndPrimaryPhotoMgmtService {
    return this.photoAndPrimaryPhotoMgmtService;
  }
  // ------------------------------------------------------------------
  setPhotoPrivacyService (pss : PhotoPrivacyService) : void {
    this.photoPrivacyService = pss;
  }
  getPhotoPrivacyService () : PhotoPrivacyService {
    return this.photoPrivacyService;
  }
  // ------------------------------------------------------------------
}
