<!--References:-->
<nav class="flex-container row" role="navigation" (scroll)="logScroll($event)"
     style="top: 0;
              left: 11%;
              right: 11%;
              position: fixed;
              display: inline-block;
              width: 78%;
              height: 5.15rem;
              margin: 0;
              padding: 0;
              z-index: 9;
              will-change:transfor;
              justify-content: center;
              background-color: transparent;">
  <app-logo [loginSuccess]="loginSuccess"></app-logo>
  <app-log-reg-buttons-and-nav-arrows [loginSuccess]="loginSuccess"></app-log-reg-buttons-and-nav-arrows>

</nav>

<app-avatar [loginSuccess]="loginSuccess"></app-avatar>


<!--Ref:https://stackblitz.com/edit/angular-z6hrob?file=package.json-->
