export class Counters {
  public callDictionary = 0;
  public callIndexedDb = 0;
  public callServer = 0;
  public callerName = '';
  public date = '';
  public id = 0;
  public key = '';
  public message: any;
  public value: any;
  constructor() { }
}
