
import { Injectable, OnDestroy } from '@angular/core';
import { Dictionary } from 'dictionaryjs';
import { Observable, Subject } from 'rxjs';
// import * as Collections from 'typescript-collections';
import { PhotoPrivacyServiceInterface } from '../../interfaces/serviceInterfaces/photoServiceIntefaces/photoPrivacyServiceInterface';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { SitUser } from '../../models/account/sitUser.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { Communication } from '../../models/communication/communication.model';
import { KvAction } from '../../models/keyValue/kvAction.model';
import { KvPhoto } from '../../models/keyValue/kvPhoto.model';
import { MemberActivity } from '../../models/member/memberActivity.model';
import { ProfilePics } from '../../models/profile/profilePics.model';
import { ProfileTile } from '../../models/profile/profileTile.model';
import { HttpService } from '../coreServiceService/httpService.service';
import { DictionaryService } from '../dictionaryServiceService/dictionaryService.service';
import { ArrayServiceStatic } from '../staticServices/arrayServiceStatic.service';
import { CopyServiceStatic } from '../staticServices/commonStaticServices/copyServiceStatic.service';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { SlakezSaltServiceStatic } from '../staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { CommunicationActivity } from '../../models/communication/communicationActivity.model';

@Injectable({
  providedIn: 'root',
})
export class PhotoPrivacyService implements OnDestroy, PhotoPrivacyServiceInterface {
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public isOnLine = false;
  public kvPhotoArr : KvPhoto[] = [];
  public kvPhotoLock : KvPhoto = new KvPhoto();
  public kvPhotoUnLock : KvPhoto = new KvPhoto();
  public kvPhotoPrivate : KvPhoto = new KvPhoto();
  public kvPhotoPublic : KvPhoto = new KvPhoto();
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public memberActivity : CommunicationActivity = new CommunicationActivity();
  public message = '';
  public profilePics : ProfilePics = new ProfilePics();
  public sitUser : SitUser = new SitUser();

  constructor (
    public dictionaryService : DictionaryService,
    private httpService : HttpService,
  ) {
    // if ( this.coreServiceService ) this.httpService = this.coreServiceService.getHttpService();
    this.initialize();
  }

  // ------------------------------------------------------------
  initialize () : void {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isOnLine = EmitterSubjectService.getIsOnLine();
    this.sitUser = EmitterSubjectService.getSitUserModel();
    // -----------------------------------------------------------
  }
  // ---------------------------------------------------------------
  ngOnDestroy () : any {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    return true;
  }
  // ---------------------------------------------------------------
  displayUnFilteredKvPhotosOfProfilePics (pPics : ProfilePics) : any {
    let anyKvPhoto = new KvPhoto();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.unFilteredKvPhotoArr)
      && pPics.unFilteredKvPhotoArr.length > 0) {

      pPics.unFilteredKvPhotoArr.map((e) => {
        anyKvPhoto = e;

        if (e.photoId === this.sitUser.primaryPhotoBnId
          && this.sitUser.sitUserId === this.loginSuccess.signedInUserId
          && this.loginSuccess.primaryPhotoBnId !== e.photoId) {
          // debugger;
          EmitterSubjectService.emitDisplayPrimaryImage(e);
        }
      });

      // debugger;
      this.sitUser = EmitterSubjectService.getSitUserModel();
      if (pPics.sitUserId === 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(anyKvPhoto)) {
        pPics.sitUserId = anyKvPhoto.sitUserId;
      }
    }
    // debugger;
    // EmitterSubjectService.emitIsPhotoMgmt(true);// TODO: isPhotoNav
    // EmitterSubjectService.emitIsViewMember(false);
    // EmitterSubjectService.emitProfileImages(pPics.unFilteredKvPhotoArr);
    return pPics;
  }
  // ============================================================
  // ---------------------------------------------------------------------------------
  //  Note: For all profilePics related rendering (view), only this method should be used
  // ---------------------------------------------------------------------------------
  //  TODO: intend to deprecate
  // ---------------------------------------------------------------------------------

  displayFilteredKvPhotosOfProfilePics (pPics : ProfilePics) : any {
    let anyKvPhoto = new KvPhoto();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.filteredKvPhotoArr)
      && pPics.filteredKvPhotoArr.length === 0) {
      const filteredPPics = this.filterAndSortProfilePics(pPics);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filteredPPics)) {
        pPics = filteredPPics;
      }
    }
    EmitterSubjectService.setProfilePics(pPics);

    pPics.filteredKvPhotoArr.map((e) => {
      anyKvPhoto = e;

      if (e.photoId === this.sitUser.primaryPhotoBnId
        && this.sitUser.sitUserId === this.loginSuccess.signedInUserId
        && this.loginSuccess.primaryPhotoBnId !== e.photoId) {
        // debugger;
        EmitterSubjectService.emitDisplayPrimaryImage(e);
      }
    });

    // debugger;
    this.sitUser = EmitterSubjectService.getSitUserModel();
    if (pPics.sitUserId === 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(anyKvPhoto)) {
      pPics.sitUserId = anyKvPhoto.sitUserId;
    }

    // debugger;
    // EmitterSubjectService.emitIsPhotoMgmt(false);// TODO: isPhotoNav
    // EmitterSubjectService.emitIsViewMember(true);
    // EmitterSubjectService.emitProfileImages(pPics.filteredKvPhotoArr);
    return pPics;
  }
  /* ------------------------------------------------------------
  * BEGIN of KvPhoto-Privacy-System:
  * ------------------------------------------------------------
  * ============================================================
  * NOTE : 1)  This should be the only place where raw image-data of an image should be transformed for display
  *        2)  This is where also the 'privacy' status of an image is determined.
  * ------------------------------------------------------------
  * Note : SOLID principal : single-responsibility! Do not displayPrimaryImage!
  * ------------------------------------------------------------
  * Note : For a complete and up-to-date filtering of data, filtration
  *        shoult be done not only by MemberLockingAcivity of DictionaryData,
  *        but also by MemberLockingAcivity of server-data.
  * ============================================================
  * Note : 1) This method returns a promise:
  *        2) We never change the Photo.isUnlocked value,
  *        that is why the KvPhoto is used to render so that
  *        we change the KvPhoto.isUnlocked value. important!
  * ------------------------------------------------------------
  */
  determineKvPhotoPrivacy (kvPhoto : KvPhoto, memberActivity : CommunicationActivity) : KvPhoto {
    // Filter out the private-images on the basis of unlocked-activity
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.privacy)) {
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();
        const today = DateStringServiceStatic.getTicks(new Date());
        debugger;
        // we DO NOT change privacy here at all. Only signedInUser changes it. important!!
        if (kvPhoto.privacy.toLowerCase().indexOf('private') !== -1)
        {
          /*
           * debugger;
           * if the isUnlocked for the user or if it is signedInUser, display the image
           * and make it public on filteredKvPhoto(not the profileImgeArr).
           * 
           * important!:
           * filteredPhotoCondition: isUnlocked === true && isPrivate === false 
           * ------------------------------------------------------------------------------
           */
          // if signedInUser because he should be able to see all of his pics:
          if (kvPhoto.sitUserId === this.loginSuccess.signedInUserId) {
            // debugger;
            kvPhoto.isUnlocked = true;
            kvPhoto.isPrivate = false;
          }
          // if unlocked in memberActivity and has not expired:
          else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberActivity)) {
            if (memberActivity?.isUnlocked === true) {
              // it was unlocked, hence check expiry. if still unexpired, then make it unlockd==true and isPrivte==false 
              if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberActivity?.expiryDate) && parseInt(memberActivity.expiryDate, 10) - today >= 0) {
                // debugger;
                kvPhoto.isUnlocked = true;
                kvPhoto.isPrivate = false;
              }
              // was unlocked, but expired. make it unlockd==false and isPrivte==true 
              else {
                /*
                 * was unlocked but expired
                 * display the lock-image:
                 * -----------------------
                 * debugger;
                 */
                kvPhoto.glyphName = 'private';
                kvPhoto.isPrivate = true;
                kvPhoto.isUnlocked = false; // if expired, we automatically lock the pic. important!!
                kvPhoto.value = this.kvPhotoPrivate?.value?.length > 0 ? this.kvPhotoPrivate?.value : 'photos/private.png';
                // debugger;
              }
            }
            // it was locked, hence check expiry. if still unexpired, then make it unlockd==true and isPrivte==false 
            //else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberActivity?.expiryDate) && parseInt(memberActivity.expiryDate, 10) - today >= 0) {
            //  // debugger;
            //  kvPhoto.isUnlocked = true;
            //  kvPhoto.isPrivate = false;
            //}
            // was locked, and expired. so make it unlockd==false and isPrivte==true 
            else {
              // display the lock-image:
              // -----------------------
              debugger;
              kvPhoto.glyphName = 'private';
              kvPhoto.isPrivate = true;
              kvPhoto.isUnlocked = false; // if expired, we automatically lock the pic. important!!
              kvPhoto.value = 'photos/private.png';
              // debugger;
            }
          }
          else {
            debugger;
            kvPhoto.glyphName = 'private';
            kvPhoto.isPrivate = true;
            kvPhoto.isUnlocked = false; // if expired, we automatically lock the pic. important!!
            kvPhoto.value = 'photos/private.png';
          }
        }
        else if (kvPhoto?.privacy?.toLowerCase().indexOf('public') !== -1) {
          // debugger;
          kvPhoto.isPrivate = false;
          // isUnlocked should be false, so we check it here and set it to false:
          if (kvPhoto.isUnlocked) {
            kvPhoto.isUnlocked = false;
          }
          // kvPhoto.glyphName == no glyph image.
          // kvPhoto.value == actual image data.
        }
      }
      // default case:
      else {
        /*
         * send the kvPhoto with defaultemoji.jpg
         * debugger;
         */
        kvPhoto.glyphName = 'private';
        kvPhoto.isPrimary = false;
        kvPhoto.isPrivate = false;
        kvPhoto.value = 'photos/private.png';
      }
    }
    /*
     * retur nnew Promise((resolve) => {
     * resolve(kvPhoto);
     * });
     */
    return kvPhoto;
  }
  // ---------------------------------------------------------------
  executeMemberViewProfilePicsTasks (profilePics : ProfilePics) : ProfilePics {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.profileImageArr)
      && profilePics.profileImageArr.length > 0) {
      // debugger;

      /*
       * if filteredKvPhoto is not present, create them:
       * -----------------------------------------------
       */
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.filteredKvPhotoArr)
        || profilePics.filteredKvPhotoArr.length === 0
        || profilePics.filteredKvPhotoArr.length !== profilePics.profileImageArr.length) {
        // debugger;
        const filteredPPics = this.filterAndSortProfilePics(profilePics);
        // debugger;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filteredPPics)) {
          this.profilePics = filteredPPics;
          return this.profilePics;
        }
      }
      else {
        this.profilePics = profilePics;
      }
      // debugger;
      return this.executeMemberViewProfilePicsAfterFiltration(this.profilePics);
    }
    else {
      return new ProfilePics();
    }
  }
  // ---------------------------------------------------------------
  executeMemberViewProfilePicsAfterFiltration (profilePics : ProfilePics) : ProfilePics {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)) {
      EmitterSubjectService.setProfilePics(this.profilePics);

      /*
       * if sitUserId is missing or different:
       * -------------------------------------
       */
      if (this.profilePics.filteredKvPhotoArr
        && this.profilePics.filteredKvPhotoArr.length > 0
        && (this.profilePics.sitUserId === 0
          || this.profilePics.sitUserId !== this.profilePics.filteredKvPhotoArr[ 0 ].sitUserId)) {
        // debugger;
        this.profilePics.sitUserId = this.profilePics.filteredKvPhotoArr[ 0 ].sitUserId;
      }

      debugger;
      return this.displayFilteredKvPhotosOfProfilePics(this.profilePics);
    }
    return profilePics;
  }
  /*
   * ------------------------------------------------------------
   * API for KvPhoto-Privacy-System:
   * ------------------------------------------------------------
   */

  // ------------------------------------------------------------
  filterAfterGettingMemberLockingActivitiesFromServer (kvP : KvPhoto, memActArr : CommunicationActivity[]) : KvPhoto {
    let filteredKvPhotoArr : any = [];
    let mActArr : any = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActArr) && mActArr.length > 0) {
      mActArr.map((e : any) => {
        filteredKvPhotoArr = this.filterKvPhotoArrayByServerData([ kvP ], e as CommunicationActivity);
      });
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filteredKvPhotoArr) && filteredKvPhotoArr.length > 0) {
      filteredKvPhotoArr = this.filterKvPhotoArrayByMemberLockingActivityDictionaryData(filteredKvPhotoArr);
    }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filteredKvPhotoArr) && filteredKvPhotoArr.length > 0) {
      let index = ArrayServiceStatic.getIndexOfKvPhotoInKvPhotoArr(filteredKvPhotoArr, kvP);
      if (index > -1)
        kvP = filteredKvPhotoArr[ index ];
    }
    return kvP;
  }

  // ------------------------------------------------------------
  filterKvPhoto (kvP : KvPhoto) : Observable<KvPhoto|any>
  {
    let mActArr = [];
    let filteredKvPhotoArr = [];
    let kvp = kvP;
    // Filter-by-Server-Data:
    // ----------------------
    // Call to get the memberLockingAcvitiy from the server:
    // Note: also listen to 'dictionariesUpdatedEmitter' and filter it again.
    // ---------------------------------------------------------------------
    return new Observable<any>((subscriber) => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp)) {
        let memAct = this.dictionaryService.myMemberActivityDictionary.get(parseInt(kvp.key)) as CommunicationActivity;
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memAct)) {
          kvp = this.determineKvPhotoPrivacy(kvp, memAct);
          subscriber.next(kvp);
          subscriber.complete();
        }
        else {
          // debugger;
          this.getMemberLockingActivitiesFromServer(kvp).subscribe(data => {
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
              mActArr = data;
              // debugger;
              kvp = this.filterAfterGettingMemberLockingActivitiesFromServer(kvp, mActArr);
              // debugger;
            }
            else {
              kvp = this.determineKvPhotoPrivacy(kvp, new CommunicationActivity());
              // debugger;
            }
            subscriber.next(kvp);
            subscriber.complete();
          });
        }        
      }      
    })
  }
  // ------------------------------------------------------------
  filterKvPhotoArr (kvPArr : KvPhoto[]) : Observable<KvPhoto[]|any> {
    let filteredKvPhotoArr : KvPhoto[] = [];
    let fkvp : KvPhoto = new KvPhoto();
    return new Observable(subscriber => {
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPArr) && kvPArr.length > 0) {
        for (var kvp of kvPArr) {
          this.filterKvPhoto(kvp).subscribe(data => {
            if (typeof data == 'object') {
              fkvp = data;
              debugger;
              filteredKvPhotoArr.push(fkvp);
            }
            else {
              console.log(data);
            }
          });
        }
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filteredKvPhotoArr) && filteredKvPhotoArr.length > 0) {
          subscriber.next(filteredKvPhotoArr);
        }        
      }
    });
  }
  /*
   * ------------------------------------------------------------
   * Note : This should NOT be used for and array of KvPhotos[]
   *        because it will get memberLockingActivity multiple times,
   *        (once per array element) where as to filter an array
   *        only one memberLockingActivity is used/required.
   * ---------------------------------------------------------------
   */
  // filterKvPhotoByDictionaryData(kvPhoto: KvPhoto): Promise<KvPhoto> {
  //  this.setAndGetLocks();
  //  const today = new Date();

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
  //    const mActs = this.getMemberLockingActivitiesFromYourDictionary(kvPhoto);
  //    // debugger;

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(mActs) && mActs.length > 0) {
  //      mActs.map((e) => {
  //        // debugger;
  //        if (DateStringServiceStatic.compareDate(e.expiryDate, today.toLocaleString()) >= 0) {
  //          kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, e);
  //        }
  //      });
  //    }
  //  }
  //  return new Promise((resolve) => {
  //    resolve(kvPhoto);
  //  });
  // }

  // ---------------------------------------------------------------
  filterKvPhotoDictionaryByMemberLockingActivityDictionaryData(kvPhotos: Dictionary<number, KvPhoto>): any {
    let kvPhoto: KvPhoto = new KvPhoto();
    let filteredKvPhotoDictionary: Dictionary<number, KvPhoto> = new Dictionary<number, KvPhoto>();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotos) && kvPhotos.size > 0) {
      const dictionaryLockingActivities = this.getMemberLockingActivitiesFromYourDictionary(kvPhotos.get(1) as KvPhoto);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryLockingActivities) && dictionaryLockingActivities.length > 0) {
        // debugger;
        dictionaryLockingActivities.map((e) => {
          for (let i = 0; i < kvPhotos.size; i++) {
            kvPhoto = new KvPhoto();
            kvPhoto = CopyServiceStatic.copyKvPhotoIfExists(kvPhoto, kvPhotos.get(i) as KvPhoto);
            kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, e);
            kvPhoto.index = i;
            kvPhoto.itemNum = i + 1;
            filteredKvPhotoDictionary.set(kvPhoto.photoId, kvPhoto);
          }
        });
      } else {
        // debugger;
        for (let i = 0; i < kvPhotos.size; i++) {
          kvPhoto = new KvPhoto();
          kvPhoto = CopyServiceStatic.copyKvPhotoIfExists(kvPhoto, kvPhotos.get(i) as KvPhoto);
          kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, new CommunicationActivity());
          kvPhoto.index = i;
          kvPhoto.itemNum = i + 1;
          filteredKvPhotoDictionary.set(kvPhoto.photoId, kvPhoto);
        }
      }
    } else {
      filteredKvPhotoDictionary = kvPhotos;
    }
    return filteredKvPhotoDictionary;
    /*
     * debugger;
     * returnnew Promise((resolve) => {
     * resolve(filteredKvPhotoDictionary);
     * });
     */
  }

  // ---------------------------------------------------------------
  filterKvPhotoArrayByMemberLockingActivityDictionaryData(kvPhotos: KvPhoto[]): any {
    let kvPhoto: KvPhoto = new KvPhoto();
    let filteredKvPhotoArray: KvPhoto[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotos) && kvPhotos.length > 0) {
      const dictionaryLockingActivities = this.getMemberLockingActivitiesFromYourDictionary(kvPhotos[0]);
      // debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryLockingActivities) && dictionaryLockingActivities.length > 0) {
        // debugger;
        dictionaryLockingActivities.map((e) => {
          for (let i = 0; i < kvPhotos.length; i++) {
            kvPhoto = new KvPhoto();
            kvPhoto = CopyServiceStatic.copyKvPhotoIfExists(kvPhoto, kvPhotos[i]);
            kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, e);
            kvPhoto.index = i;
            kvPhoto.itemNum = i + 1;
            filteredKvPhotoArray.push(kvPhoto);
            // debugger;
          }
        });
      } else {
        // debugger;
        for (let i = 0; i < kvPhotos.length; i++) {
          kvPhoto = new KvPhoto();
          kvPhoto = CopyServiceStatic.copyKvPhotoIfExists(kvPhoto, kvPhotos[i]);
          // debugger;
          kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, new CommunicationActivity());
          kvPhoto.index = i;
          kvPhoto.itemNum = i + 1;
          // debugger;
          filteredKvPhotoArray.push(kvPhoto);
          // debugger;
        }
      }
    } else {
      // debugger;
      filteredKvPhotoArray = kvPhotos;
    }
    return filteredKvPhotoArray;
    /*
     * debugger;
     * returnnew Promise((resolve) => {
     * resolve(filteredKvPhotoArray);
     * });
     */
  }

  /*
   * ---------------------------------------------------------------
   * Note : get the locking activity from the server first (which takes time to arrive),
   *     and then call this method from 'dictionariesUpdatedEmitter'.
   * ---------------------------------------------------------------
   */
  filterKvPhotoDictionaryByServerData (kvPhotos : Dictionary<number, KvPhoto>, lockingActivity : CommunicationActivity): any {
    let kvPhoto: KvPhoto = new KvPhoto();
    const filteredKvPhotos: Dictionary<number, KvPhoto> = new Dictionary<number, KvPhoto>();

    const inKvPhotoValues = kvPhotos.values();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inKvPhotoValues) && inKvPhotoValues.length > 0) {
      /*
       * debugger;
       * No need to check if null, because 'determineKvPhotoPrivacy()' handles this scenario
       */
      for (let i = 0; i < inKvPhotoValues.length; i++) {
        kvPhoto = new KvPhoto();
        kvPhoto = CopyServiceStatic.copyKvPhotoIfExists(kvPhoto, inKvPhotoValues[i]);

        kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, lockingActivity);

        kvPhoto.index = i;
        kvPhoto.itemNum = i + 1;

        filteredKvPhotos.set(kvPhoto.sitUserId, kvPhoto);
      }
    }
    return filteredKvPhotos; // new Promise((resolve) => resolve(filteredKvPhotos));
  }
  /*
   * ===============================================================
   * ---------------------------------------------------------------
   */
  filterKvPhotoArrayByDictionaryData(kvPhotos: KvPhoto[]): KvPhoto[] {
    let kvPhoto: KvPhoto = new KvPhoto();
    let filteredKvPhotoArr :KvPhoto[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotos) && kvPhotos.length > 0) {
      const dictionaryLockingActivities = this.getMemberLockingActivitiesFromYourDictionary(kvPhotos[0]);

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(dictionaryLockingActivities) && dictionaryLockingActivities.length > 0) {
        // debugger;
        dictionaryLockingActivities.map((e) => {
          for (let i = 0; i < kvPhotos.length; i++) {
            kvPhoto = new KvPhoto();
            kvPhoto = CopyServiceStatic.copyKvPhotoIfExists(kvPhoto, kvPhotos[i]);
            kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, e);
            kvPhoto.index = i;
            kvPhoto.itemNum = i + 1;
            filteredKvPhotoArr.push(kvPhoto);
          }
        });
      } else {
        // debugger;
        for (let i = 0; i < kvPhotos.length; i++) {
          kvPhoto = new KvPhoto();
          kvPhoto = CopyServiceStatic.copyKvPhotoIfExists(kvPhoto, kvPhotos[i]);
          kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, new CommunicationActivity());
          kvPhoto.index = i;
          kvPhoto.itemNum = i + 1;
          filteredKvPhotoArr.push(kvPhoto);
        }
      }
    } else {
      filteredKvPhotoArr = kvPhotos;
    }
    return filteredKvPhotoArr;
    /*
     * debugger;
     * returnnew Promise((resolve) => {
     * resolve(filteredKvPhoto);
     * });
     */
  }
  /*
   * ---------------------------------------------------------------
   * Note : get the locking activity from the server first (which takes time to arrive),
   *     and then call this method from 'dictionariesUpdatedEmitter'.
   * ---------------------------------------------------------------
   */
  filterKvPhotoArrayByServerData (kvPhotos : KvPhoto[], lockingActivity : CommunicationActivity): any {
    let kvPhoto: KvPhoto = new KvPhoto();
    const filteredKvPhotos : KvPhoto[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhotos) && kvPhotos.length > 0) {
      /*
       * debugger;
       * No need to check if null, because 'determineKvPhotoPrivacy()' handles this scenario
       */
      for (let i = 0; i < kvPhotos.length; i++) {
        kvPhoto = new KvPhoto();
        kvPhoto = CopyServiceStatic.copyKvPhotoIfExists(kvPhoto, kvPhotos[i]);

        kvPhoto = this.determineKvPhotoPrivacy(kvPhoto, lockingActivity);

        kvPhoto.index = i;
        kvPhoto.itemNum = i + 1;
        filteredKvPhotos.push(kvPhoto);
      }
    }
    return filteredKvPhotos; // new Promise((resolve)=> resolve(filteredKvPhotos));
  }
  // ------------------------------------------------------------
  filterAndSortProfileKvPhotoArr (kvpArr : KvPhoto[]) : any[] {
    let mActArr : any;
    let pKvPhoto : any;
    let memAct : any;
    let filteredKvPhotoArr : KvPhoto[] = [];

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvpArr) && kvpArr.length > 0) {
      /*
      * Filter-by-Server-Data:
      * ----------------------
      * Call to get the memberLockingAcvitiy from the server:
      * Note: also listen to 'dictionariesUpdatedEmitter' and filter it again.
      */

      // mActArr = this.getMemberLockingActivitiesFromServer(kvpArr[0]);
      memAct = this.dictionaryService.myMemberActivityDictionary.get(kvpArr[0].photoId);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memAct)) {        
        // mActArr = this.memberActivityService.getYourActivities( pKvPhoto.sitUserId, 'UnLock' ); //TODO: Check if it is your or my activity getter
        filteredKvPhotoArr = this.filterKvPhotoArrayByServerData(filteredKvPhotoArr, memAct);
        // debugger;
      }
      else {
        for (let kvP of kvpArr) {
          // debugger;
          let fkvp = this.determineKvPhotoPrivacy(kvP, new CommunicationActivity());
          // debugger;
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(fkvp)) {
            // debugger;
            filteredKvPhotoArr.push(fkvp);
          }
        }
      }
      //  debugger;
      // Note:  1) this section wipes out all data of each KvPhoto. DO NOT USE THIS or fix it!
      //        2) chjeck if MemberLockingActivity can be obtained or if missing return the un-altered-filterKvPhotoArr.
      // ----------------------------------------------------------------------------------
      // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(filteredKvPhotoArr) && filteredKvPhotoArr.length > 0) {
      //  filteredKvPhotoArr = this.filterKvPhotoArrayByMemberLockingActivityDictionaryData(filteredKvPhotoArr);
      // }
      // else {
      //  debugger;
      //  filteredKvPhotoArr = this.filterKvPhotoArrayByMemberLockingActivityDictionaryData(kvpArr);
      // }
      // debugger;
      return filteredKvPhotoArr;
    }
    else return kvpArr;
  }
  // ------------------------------------------------------------
  filterAndSortProfilePics (profilePics : ProfilePics) : any {
    
    let pKvPhoto : any;
     debugger;
    // if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics) || this.profilePics.profileImageArr.length === 0) {
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)
    //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.profileImageArr)
    //    && profilePics.profileImageArr.length > 0 ) {
    //    this.profilePics = CopyServiceStatic.createProfilePicsFromProfileImageArr(profilePics.profileImageArr);
    //  } else {
    //    debugger;
    //    this.profilePics = CopyServiceStatic.copyProfilePicsIfExists(this.profilePics, profilePics);
    //  }
    //  debugger;
    // }
    
    // Filter-by-Server-Data:
    // ----------------------
    // Call to get the memberLockingAcvitiy from the server:
    // Note: also listen to 'dictionariesUpdatedEmitter' and filter it again.
    // ----------------------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.profileKvPhotoArr) && profilePics.profileKvPhotoArr.length > 0) {

      pKvPhoto = this.profilePics.profileKvPhotoArr[ 0 ];
      let tpPics = this.filterAndSortByMemberActicvities(pKvPhoto, profilePics);
      profilePics = CopyServiceStatic.copyProfilePicsIfExists(profilePics, tpPics);
      debugger;
    }
    else if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.unFilteredKvPhotoArr) && profilePics.unFilteredKvPhotoArr.length > 0) {
      debugger;
      pKvPhoto = profilePics.unFilteredKvPhotoArr[0];
      let tpPics = this.filterAndSortByMemberActicvities(pKvPhoto, profilePics);
      debugger;
      profilePics = CopyServiceStatic.copyProfilePicsIfExists(profilePics, tpPics);
      debugger;
    }
    debugger;
    return profilePics;
  }
  // ------------------------------------------------------------
  filterAndSortByMemberActicvities (kvp : KvPhoto, pPics : ProfilePics) : ProfilePics {
    let memAct : any;
    let mActArr : any;
    let pKvPhoto : any;

    memAct = this.dictionaryService.myMemberActivityDictionary.get(pKvPhoto.key);

    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memAct)) {
      let mActArr = this.getMemberLockingActivitiesFromServer(pKvPhoto);
      // mActArr = this.memberActivityService.getYourActivities( pKvPhoto.sitUserId, 'UnLock' ); //TODO: Check if it is your or my activity getter
      // debugger;

      pPics.filteredKvPhotoArr = this.filterKvPhotoArrayByMemberLockingActivityDictionaryData(pPics.profileKvPhotoArr);
      // debugger;
    } else {
      pPics.filteredKvPhotoArr = this.filterKvPhotoArrayByMemberLockingActivityDictionaryData(pPics.profileKvPhotoArr);
      // debugger;
      pPics.filteredKvPhotoArr = this.filterKvPhotoArrayByServerData(pPics.profileKvPhotoArr, memAct);
    }

   
    pPics.filteredKvPhotoArr = pPics.filteredKvPhotoArr.sort((a, b) =>
      b.photoBnId = a.photoBnId
    );
    EmitterSubjectService.setProfilePics(pPics);
    return pPics;
  }
  // ------------------------------------------------------------
  filterAndSortProfilePicsOld (profilePics : ProfilePics) : any {
    let memAct : any;
    let mActArr : any;
    let pKvPhoto : any;

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.profileImageArr)
      && profilePics.profileImageArr.length > 0) {
      // debugger;
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics)) {
        this.profilePics = CopyServiceStatic.createProfilePicsFromProfileImageArr(profilePics.profileImageArr);
      } else {
        this.profilePics = profilePics;
      }

      /*
       * extract images here
       */
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.profileKvPhotoArr) || this.profilePics.profileKvPhotoArr.length !== this.profilePics.profileImageArr.length) {
        this.profilePics.profileKvPhotoArr = CopyServiceStatic.copyFromProfileImageArrToKvPhotoArr(this.profilePics.profileImageArr);
      }

      /*
       * Filter-by-Server-Data:
       * ----------------------
       * Call to get the memberLockingAcvitiy from the server:
       * Note: also listen to 'dictionariesUpdatedEmitter' and filter it again.
       */
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.profilePics.profileKvPhotoArr) && this.profilePics.profileKvPhotoArr.length > 0) {
        pKvPhoto = this.profilePics.profileKvPhotoArr[ 0 ];
        memAct = this.dictionaryService.myMemberActivityDictionary.get(pKvPhoto.key);

        if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memAct)) {
          mActArr = this.getMemberLockingActivitiesFromServer(pKvPhoto);
          // mActArr = this.memberActivityService.getYourActivities( pKvPhoto.sitUserId, 'UnLock' ); //TODO: Check if it is your or my activity getter
          // debugger;

          this.profilePics.filteredKvPhotoArr = this.filterKvPhotoArrayByMemberLockingActivityDictionaryData(this.profilePics.profileKvPhotoArr);
          // debugger;
        } else {
          this.profilePics.filteredKvPhotoArr = this.filterKvPhotoArrayByMemberLockingActivityDictionaryData(this.profilePics.profileKvPhotoArr);
          // debugger;
          this.profilePics.filteredKvPhotoArr = this.filterKvPhotoArrayByServerData(this.profilePics.profileKvPhotoArr, memAct);
        }

        EmitterSubjectService.setProfilePics(this.profilePics);
        return this.profilePics;
      }
    }
    return null;
  }
  // ---------------------------------------------------------------
  getPrimaryKvPhotoFromFilteredKvPhotoArr (fKvPhotoArr : KvPhoto[]) : any
  {
    let anyKvPhoto;
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(fKvPhotoArr) || fKvPhotoArr.length > 0)
    {
      for (var e of fKvPhotoArr)
      {
        anyKvPhoto = e;

        if (e.photoId === this.sitUser.primaryPhotoBnId
          && this.sitUser.sitUserId === this.loginSuccess.signedInUserId
          && this.loginSuccess.primaryPhotoBnId !== e.photoId) {
          debugger;
          return e;
        }
      };
    }
  }
  // ---------------------------------------------------------------
  getIsUnlocked(signedInUserId: number, sitUserId: number): any {
    let kvAction = new KvAction();

    if (signedInUserId > 0 && sitUserId > 0) {
      let bnComm: Communication = new Communication();

      bnComm.sitUserId = sitUserId;
      bnComm.signedInUserId = signedInUserId;
      this.boxNonceEntity = SlakezSaltServiceStatic.saltModel(bnComm);
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.boxNonceEntity.nonce)) {
        this.httpService
          .postObservable(
            '/Member/IsUnlocked',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.box),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64(this.boxNonceEntity.nonce),
            },
            'json2text',
          )
          .subscribe(
            (result) => this.processIsUnlockedResult(result),
            // debugger;
            (error) => {
              EmitterSubjectService.emitMyErrorLog('Error occured in IsUnlocked();\n Error-mag : ' + error);
            },
          );
        return null;
      }
    }
    return null;
  }
  // ---------------------------------------------------------------
  processIsUnlockedResult(result: any): any {
    let kvAction = new KvAction();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
      const bn = result as BoxNonceEntity;
      // debugger;

      if (bn && bn.box && bn.box.length > 0 && bn.nonce && bn.nonce.length > 0) {
        kvAction = JSON.parse(SlakezSaltServiceStatic.boxUnsalt(bn)) as KvAction;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvAction)) {
          return new Promise((resolve) => resolve(kvAction));
        }
        return null;
      }
    }
  }
  // ---------------------------------------------------------------
  getMemberLockingActivitiesFromMyDictionary (kvPhoto : KvPhoto) : Promise<CommunicationActivity[]> {
    let memberActions : CommunicationActivity[] = [];
    const lockingActivities : CommunicationActivity[] = [];

    memberActions = this.dictionaryService.myUnlockDictionary.size > 0 ? this.dictionaryService.myUnlockDictionary.values() : [];
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberActions) && memberActions.length > 0) {
      memberActions.map((e) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e) && e.receiverSitUserId === kvPhoto.sitUserId && e.senderSitUserId === this.loginSuccess.signedInUserId) {
          lockingActivities.push(e);
        }
      });
    }
    return new Promise((resolve) => resolve(lockingActivities));
  }

  // ---------------------------------------------------------------
  getMemberLockingActivitiesFromYourDictionary (kvPhoto : KvPhoto) : CommunicationActivity[] {
    let memberActions : CommunicationActivity[] = [];
    const lockingActivities : CommunicationActivity[] = [];

    memberActions = this.dictionaryService.yourUnlockDictionary.size > 0 ? this.dictionaryService.yourUnlockDictionary.values() : [];
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(memberActions) && memberActions.length > 0) {
      memberActions.map((e) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e) && e.senderSitUserId === kvPhoto.sitUserId && e.receiverSitUserId === this.loginSuccess.signedInUserId) {
          lockingActivities.push(e);
        }
      });
    }
    return lockingActivities;
  }
  /*
  // ---------------------------------------------------------------
  // Note : This method shold be called before listening to the
  //       'dictionariesUpdatedEmitter' for memberLockingActivity
  //       to filter kvPhotoArray
  // ---------------------------------------------------------------
   */
  getMemberLockingActivitiesFromServer (kvPhoto : KvPhoto) : Observable<any> {
    let kvp : KvPhoto = kvPhoto;
    return new Observable<any>((subscriber) => {

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvp) && kvp.sitUserId > 0) {
        // debugger;
        this.memberActivity.action = 'UnLock';
        this.memberActivity.senderSitUserId = kvp.sitUserId;
        this.getMemberActivitiesForPhotoPrivacy(this.memberActivity, kvp.sitUserId).subscribe(data => {
          subscriber.next(data);
        });
        // debugger;
      }
    })
  }

  // ---------------------------------------------------------------
  // Caution! TODO: check if any caller's sitUserId === signedInSitUserId.
  //                the previous version allowed the above, but
  //                this new version does not allow the above.
  // ---------------------------------------------------------------
  getMemberActivitiesForPhotoPrivacy (mActivity : CommunicationActivity, sitUserId: number ): Observable<any>
  {
    // debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( mActivity ) && sitUserId > 0 )
    {
      this.memberActivity = mActivity;
      let action = mActivity.action;
      this.boxNonceEntity = SlakezSaltServiceStatic.saltModel( this.memberActivity );
      return new Observable<any>( ( subscriber ) =>
      {
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.box ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.boxNonceEntity.nonce ) )
        {
          // debugger;

          this.httpService.postObservable( '/Member/GetMemberActivities',
            {
              box: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.box ),
              nonce: FrequentlyUsedFunctionsServiceStatic.arrBufferToB64( this.boxNonceEntity.nonce ),
            }, 'json2text' )
            .subscribe( ( result ) =>
            {
              if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
              {
                // debugger;
                const bn = result as BoxNonceEntity;
                // debugger;

                if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( bn ) && bn.box.length > 0 && bn.nonce.length > 0 )
                {
                  // debugger;
                  const mActs = JSON.parse( SlakezSaltServiceStatic.boxUnsalt( bn ) ) as MemberActivity[];
                  // debugger;
                  /*
                    * ------------------------------------------------------------------------
                    * Note: dictionariesUpdatedEmitter will act on the three following values:
                    *     1. MemberActivity[]
                    *     2. True: to trigger filtration of ProfileKvPhotoArr
                    *     3. False: to display the MemberView glyphion menu only
                    * ------------------------------------------------------------------------
                    */
                  EmitterSubjectService.emitDictionariesUpdated( mActs );
                  // debugger;
                  subscriber.next( mActs );

                } else
                {
                  this.message = 'Result is null/undefined in getMemberActivities(doerSitUserId:' + this.memberActivity.senderSitUserId;
                  this.message += '; doeeSitUserId:' + this.memberActivity.receiverSitUserId + '; action: ' + action + ');\n result: ' + result;
                }
              }
            },
              ( error ) =>
              {
                // debugger;
                this.message = 'Error occured in getMemberActivities(doerSitUserId:' + this.memberActivity.senderSitUserId;
                this.message += '; doeeSitUserId:' + this.memberActivity.receiverSitUserId + '; action: ' + action;
                this.message += ');\n Error message:  ' + error.message;
              },
            );
        }
        if ( this.message.length > 10 )
        {
          console.log( this.message );
          // EmitterSubjectService.emitMyErrorLog( this.message );
        }
      } );
    } else
    {
      // debugger;
      this.message = 'Could not sent method getMemberActivities(doerSitUserId:' + this.memberActivity.senderSitUserId;
      this.message += '; doeeSitUserId:' + sitUserId + '; action: ' + this.memberActivity.action + ');\n';
      if ( this.message.length > 10 )
      {
        console.log( this.message );
        // EmitterSubjectService.emitMyErrorLog( this.message );
      }
    }
  }

  // -----------------------------------------------------------
  nullPromise ( data : any ) : any
  {
    let timer = setTimeout( () =>
    {
      // debugger;
      return data;
    }, 500);
    clearTimeout(timer);
    if ( timer )
    {
     () => clearInterval(timer );
    }
  }
  // ---------------------------------------------------------------
  // --------------------------------------------------------------------------------
  // Note: This method removes the very first image on ProfileImageArr of ProfilePics
  //       if it is created from ProfileTile to quicky show the user's primary-pic
  //       and the server sent ProfilePics already contains the primary-pic
  // --------------------------------------------------------------------------------
  removeProfileTileImageFromProfileImageArr (pPics : ProfilePics, pTile: ProfileTile) : ProfilePics {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pPics.profileImageArr)
      && pPics.profileImageArr.length > 1) // Note: there has to be at least 2 pics to start
    {
      let index = 0;
      pPics.profileImageArr.map(e => {
        if ((FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(e.sitUserKey) || e.sitUserId === 0) && e.sitUserId == pTile.sitUserId) // Note: this criteria was used because ProfileImage created from ProfileTile lacks sitUserKey
        {
          // debugger;
          pPics.profileImageArr.splice(index, 1);
        }
        index++;
      });
    }
    return pPics;
  }
  // ---------------------------------------------------------------
  setAndGetLocks(): any {
    EmitterSubjectService.setupLockPhotos();
    /*
     * --------------------------------------------------------
     * debugger;
     */
    this.kvPhotoLock = EmitterSubjectService.getPhotoLock();
    this.kvPhotoUnLock = EmitterSubjectService.getPhotoUnLock();
    // --------------------------------------------------------
    this.kvPhotoPrivate = EmitterSubjectService.getPhotoPrivate();
    this.kvPhotoPublic = EmitterSubjectService.getPhotoPublic();
    return true;
  }
  /*
   * ============================================================
   * ------------------------------------------------------------
   * END of KvPhoto-Privacy-System:
   * ------------------------------------------------------------
   * ============================================================
   */
}
