'use-strict'
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoginSuccess } from '../../../models/account/loginSuccess.model';
import { PagerBreadcrum } from '../../../models/breadcrum/pagerBreadcrum.model';
import { MessageModel } from '../../../models/common/messageModel.model';
import { ModuleComponentLoader } from '../../../models/common/moduleComponentLoader.model';
import { PhotoMgmtAction } from '../../../models/common/photoMgmtAction.model';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { UserAndPageTitle } from '../../../models/common/userAndPageTitle.model';
import { Pager } from '../../../models/pagination/pager.model';
import { PagerPhotoMgmt } from '../../../models/pagination/pagerPhotoMgmt.model';
import { CommonServiceService } from '../../../services/commonServiceService/commonServiceService.service';
import { RedirectionService } from '../../../services/commonServiceService/redirectionService.service';
import { SitUserService } from '../../../services/commonServiceService/sitUserService.service';
import { CoreServiceService } from '../../../services/coreServiceService/coreServiceService.service';
import { TimerService } from '../../../services/coreServiceService/timerService.service';
import { BackgroundImageAnimationService } from '../../../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';
import { DomUtilsServiceStatic } from '../../../services/staticServices/domUtilsServiceStatic.service';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from '../../animation';

@Component({
  selector: 'app-nav-header',
  templateUrl: './navHeader.component.html',
  styleUrls: [ './navHeader.component.scss' ],
  animations: [ Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavHeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  public bias! : BackgroundImageAnimationService;
  public redirectionService! : RedirectionService;
  public rendererService! : RendererService;
  public renderer! : Renderer2;
  public sitUserService! : SitUserService;
  // public spinnerOverlayService! : SpinnerOverlayService;
  public timerService! : TimerService;
  //  ------------------------------------------
  public breadcrumRing : ModuleComponentLoader[] = [];
  public breadCrmRngLength = 0;
  public deviceInfo : any;
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  public intervalMsg : any;
  public isBusy = false;
  public isHome = false;
  public isLoggedIn = false;
  public isMobilevar = false;
  public isModal = false;
  public isMouseIn = false;
  public isOnScroll = false;
  public index = -1;
  public isOnLine = false;
  public isPhotoMgmt = false;
  public isSpinner = false;
  public isSuccess = true;
  public isViewMember = false;
  @Input() loginSuccess : LoginSuccess = new LoginSuccess();
  public messageCounter = 0;
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public mcLoaderRouter : any;
  // @Input() message : any = '';
  public messageModel : MessageModel = new MessageModel();
  public pageNo = 0;
  public pageTitle = '';
  public pagerV : Pager = new Pager();
  public pagerBrdCrm : PagerBreadcrum = new PagerBreadcrum();
  public pagerPhotoMgmt : PagerPhotoMgmt = new PagerPhotoMgmt();
  public photoMgmtAction : PhotoMgmtAction = new PhotoMgmtAction();
  public signedInUserId = 0;
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public tempElem : any;
  public timer : any;
  public timerArray : any[] = [];
  public userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  public document ! : Document;
  public window ! : Window;
  // public scrollY$! : Observable<number>;

  // @HostListener( 'window:scroll' ) onScroll ( e : Event ) : void
  // {
  //  ScrollTopServiceStatic.scrollY.next( this.getYPosition( e ) );
  // }

  constructor ( private cdr : ChangeDetectorRef,
    private commonServiceService : CommonServiceService,
    private coreServiceService : CoreServiceService,
    private navHeaderElementRef : ElementRef,
    /*private modalServiceService : ModalServiceService,*/
    private rendererServiceService: RendererServiceService,
    private router : Router,
    @Inject( DOCUMENT ) Document : Document,
    @Inject( Window ) Window : Window
  )
  {
    this.document = Document;
    this.window = Window;

    // debugger;
    // -----------------------------------------------------------------
    if ( this.commonServiceService )
    {
      this.redirectionService = this.commonServiceService.getRedirectionService();
      this.sitUserService = this.commonServiceService.getSitUserService() as SitUserService;
    }
    // -----------------------------------------------------------------
    if ( this.coreServiceService )
    {
      this.timerService = this.coreServiceService.getTimerService();
    }
    // if (this.modalServiceService) {
    //  this.spinnerOverlayService = this.modalServiceService.getSpinnerOverlayService();
    // }
    // -----------------------------------------------------------------
    if (this.rendererServiceService) {
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
      this.rendererService = this.rendererServiceService.getRendererService() as RendererService;
    }
    this.renderer = this.rendererService.getRenderer();
    // -----------------------------------------------------------------

    // this.breadcrumRing = BreadcrumServiceStatic.getRing();
    // this.pagerBrdCrm = BreadcrumServiceStatic.getPagerBreadcrum();
    // debugger;
    // this.scrollY$ = ScrollTopServiceStatic.scrollY$;
  }

  ngOnInit () : void
  {
    // this.getOnScroll();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.signedInUserId = this.loginSuccess.signedInUserId;
    //  -------------------------------------------------------------------------
    // debugger;
    EmitterSubjectService.setElementRef( this.navHeaderElementRef );
    //  -------------------------------------------------------------------------
     // debugger;
    EmitterSubjectService.loginSuccessEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
    {
        this.loginSuccess = result as LoginSuccess; //  JSON.parse(JSON.stringify(result));
        this.signedInUserId = this.loginSuccess.signedInUserId;
        // debugger;
    } );

    //  -------------------------------------------------------------------------
    //EmitterSubjectService.displayPrimaryImageEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //{
    //  // debugger;
    //  this.ngAfterViewInit();
    //    this.bias.setSignedInUserBackgroundImage();
    //} );

    ////  -------------------------------------------------------------------------
    //EmitterSubjectService.updatePrimaryImageEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //{
    //  // debugger;
    //  this.sitUser = EmitterSubjectService.getSitUserModel();

    //  if (
    //    !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result )
    //    && result instanceof KvPhoto
    //    && this.sitUser.sitUserId === this.loginSuccess.signedInUserId // has to be signedInUSer
    //    && this.sitUser.primaryPhotoBnId === result.photoId // new kvPhotoId must be aligned with single-point-of-truth
    //    && result.photoId !== this.loginSuccess.primaryPhotoBnId //  loginsSuccess's primaryPhotoBnId must be different than single-point-of-truth
    //  )
    //  {
    //    this.loginSuccess.primaryImage = JsRegExpServiceStatic.normalizeImageData( result.value );
    //    this.loginSuccess.primaryPhotoBnId = result.photoId;
    //    EmitterSubjectService.setLoginSuccess( this.loginSuccess );
    //  }
    //    this.bias.setSignedInUserBackgroundImage();
    //} );
    //  -------------------------------------------------------------------------
    // EmitterSubjectService.messageEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$ ) )
    //  .subscribe( ( result: any ) =>
    //  {
    //    // debugger;
    //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
    //    {
    //      if (result instanceof MessageModel) {
    //        this.isSpinner = result.isSpinner;
    //        this.messageModel = result;
    //        debugger;
    //      }
    //      else if (typeof result === 'string') {
    //        debugger;
    //        this.messageModel.message = result as string;
    //      }
    //      this.spinnerOverlayService.hide(false); // isOpen == false
    //      // debugger;;
    //    }
    //   } );
    ////  -------------------------------------------------------------------------
    //  -------------------------------------------------------------------------
    
    //  -------------------------------------------------------------------------
    EmitterSubjectService.isHomeEmitter
      .pipe( takeUntil( this.emitterDestroyed$ ) )
      .subscribe( ( result ) =>
    {
      // debugger;
      this.isHome = result;
      this.routeToHomePage();

    } );
    //  -------------------------------------------------------------------------
    // TODO: remove before deployment:
    // --------------------------------------------------------------------------
    // EmitterSubjectService.runNgAfterViewInitEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //  {
    //    debugger;
    //    this.ngAfterViewInit();
    //  });
    // -------------------------------------------------------------------------
    //EmitterSubjectService.isPhotoMgmtEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //{
    //  //  debugger;
    //  this.isPhotoMgmt = result as boolean;
    //  if ( this.isPhotoMgmt === true )
    //  {
    //    // debugger;
    //    this.isViewMember = false;
    //  }
    //  this.sitUser = EmitterSubjectService.getSitUserModel();
    //} );
    //  -------------------------------------------------------------------------
    //EmitterSubjectService.isViewMemberEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    //{
    //  // debugger;
    //  this.isViewMember = result as boolean;
    //  if ( this.isViewMember === true ) { this.isPhotoMgmt = false; }
    //  this.sitUser = EmitterSubjectService.getSitUserModel();
    //  });
    // --------------------------------------------------------------
    //  parent provides data to the child, hence profileImages listener
    //  is at the parent component. childComponent=PagerPhotoNavComponent
    //  Note: This is a part of the PhotoNav-System.
    // --------------------------------------------------------------
      //EmitterSubjectService.profileImagesEmitter
      //.pipe(takeUntil(this.emitterDestroyed$))
      //.subscribe(data => { // data:string[]; will lisen to photoMgmgtComponent
      //  // debugger;
      //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data) && data.length > 0) {
      //    // debugger;
      //    this.kvPhotoArr = data;
      //    this.isViewMember = true;
      //    this.sitUser = EmitterSubjectService.getSitUserModel();
      //    // this.ngAfterViewInit();
      //  }
      //});
    // -------------------------------------------------------------------------
    // Note: without this some children's images do not get rendered!!
    // -------------------------------------------------------------------------
     //EmitterSubjectService.runNgAfterViewInitEmitter
     // .pipe(takeUntil(this.emitterDestroyed$))
     // .subscribe((result) => {
     //   // debugger;
     //   this.ngAfterViewInit();
     // });
    //  -------------------------------------------------------------------------
    // TODO: remove once the issue is resolved
    // NOTE: This is a temporary solution to an unsubscribe error
    //       We do not emit loginSuccess upon successful login from the LoginComponent
    //       We use this emitter to indicate that login is successful, and any subsequent
    //       computation that are necessary upon successful login is carried out here.
    // --------------------------------------------------------------------------
    // EmitterSubjectService.clearMessageEmitter.subscribe( ( result ) =>
    // {
    // // debugger;
    //  this.executeLoginSuccessTasks();
    // } );
    //  -------------------------------------------------------------------------
    // EmitterSubjectService.clearPageTitleEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    // {
    //   // debugger;
    //   this.clearPageTitle();
    // } );
    //  --------------------------------Completed on 2021/03/15-------------------
    // EmitterSubjectService.userAndPageTitleEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    // {
    //  // debugger;
    //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( result ) )
    //  {
    //    this.clearPageTitle();

    //    this.userAndPageTitle = result as UserAndPageTitle;
    //    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.userAndPageTitle ) )
    //    {
    //      if ( this.userAndPageTitle.sitUserId > 0 )
    //      {
    //        this.sitUser.sitUserId = this.userAndPageTitle.sitUserId;
    //      }
    //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.userAndPageTitle.pageTitle ) && this.userAndPageTitle.pageTitle.length > 0 )
    //      {
    //        this.pageTitle = this.userAndPageTitle.pageTitle;
    //        // debugger;
    //        if ( !this.userAndPageTitle.isPrepared )
    //        {
    //          this.pageTitle = StringServiceStatic.preparePageTitle( this.userAndPageTitle.pageTitle );
    //          //  debugger;
    //        }
    //      }
    //    }
    //    // debugger;
    //    this.ngAfterViewInit();
    //  }
    // } );
    //  -----------------------------------------------------------------
    // EmitterSubjectService.pagerBreadcrumEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    // {
    //  // debugger;
    //  this.pagerBrdCrm = result as PagerBreadcrum;
    //  this.breadCrmRngLength = this.setBreadCrumRingLength( this.pagerBrdCrm );
    // } );
    //  -----------------------------------------------------------------
    // Note: This emitter is to be used by any component that has a cancel-button.
    //       Because once cancelled, the user should go back to the previous page (if that is the desired behavior)
    // EmitterSubjectService.setBreadcrumPageEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    // {
    //  // debugger;
    //  this.setBreadcrumPage( result );
    // } );
    //  -------------------------------------------------------------------------
    // TODO: see if it is needed?
    // --------------------------------------------------------------------------
    // EmitterSubjectService.sitUserEmitter
    //  .pipe( takeUntil( this.emitterDestroyed$ ) )
    //  .subscribe( ( result ) =>
    // {
    //  // debugger;
    //  this.sitUser = result as SitUser;
    //  this.ngAfterViewInit();
    // } );
    // ------------------------------------------------------------------
    // debugger;

  //  this.breadCrmRngLength = this.setBreadCrumRingLength(this.pagerBrdCrm);
  //  -------------------------------------------------------------------------
  // EmitterSubjectService.runNgAfterViewInitEmitter
  //  .pipe( takeUntil( this.emitterDestroyed$ ) )
  //  .subscribe( ( result ) =>
  //  {
  //    debugger;
  //    this.ngAfterViewInit();
  //  } );
  // -----------------------------------------------------------------
  //  EmitterSubjectService.profileImagesEmitter
  //    .pipe( takeUntil( this.emitterDestroyed$ ) )
  //    .subscribe( data =>
  //    { // data:string[]; will lisen to photoMgmgtComponent
  //       debugger;
  //      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( data ) && data.length > 0 )
  //      {
  //        // debugger;
  //        this.kvPhotoArr = data as KvPhoto[];
  //        debugger;
  //        // ------------------------------------------------------------------------------------------
  //        // Note: if value==/*/*.png such as '/photos/DefaultImageImoji.png' we do not prepend 'url('
  //        // ------------------------------------------------------------------------------------------
  //        for ( let i = 0; i < this.kvPhotoArr.length; i++ )
  //        {                  
  //          this.kvPhotoArr[ i ].value = JsRegExpServiceStatic.stripUrlForImageData( this.kvPhotoArr[ i ].value);
  //        }
  //        // debugger;
  //        // EmitterSubjectService.emitCurrentPage( this.kvPhotoArr[ this.kvPhotoArr.length - 1 ] );
  //        // EmitterSubjectService.emitRunNgAfterViewInit(true);

  //        this.ngAfterViewInit();
  //      }
  //    } );
    //  -------------------------------------------------------------------------
    EmitterSubjectService.logoutEmitter.subscribe((result) => {
      //  debugger;
      if (result) {
        this.loginSuccess = new LoginSuccess().resetThat(this.loginSuccess);
        this.ngAfterViewInit();
      }
    });
  }
  //  ---------------------------------------------------------------------------------
  ngAfterViewInit () : any
  {
          this.cdr.detectChanges();
    return true;
  }
  //  ---------------------------------------------------------------
  ngOnDestroy (): void
  {
    this.emitterDestroyed$.next( true );
    this.emitterDestroyed$.complete();
    this.emitterDestroyed$.unsubscribe();
    this.timerArray.forEach( ( timer ) => clearInterval( timer ) );
  }
  //  ---------------------------------------------------------------
  //clearPageTitle () : any
  //{
  //  this.userAndPageTitle = new UserAndPageTitle();
  //  this.pageTitle = '';
  //  this.ngAfterViewInit();
  //  // debugger;
  //  return true;

  //}
  // ----------------------------------------------------------------
  executeLoginSuccessTasks ()
  {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess(); //  JSON.parse(JSON.stringify(result));
    this.signedInUserId = this.loginSuccess.signedInUserId;
    // debugger;
    //this.tempElem = this.getNavHeaderElementById( 'primaryPhotoBnId' );
    //this.setPrimaryImage( 'primaryPhotoBnId', this.tempElem );
  }
  // ----------------------------------------------------------------
  getNavHeaderElementById (id:string) : any
  {
    this.tempElem = document.getElementById( id );
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.tempElem ) )
    {

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.navHeaderElementRef ) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.navHeaderElementRef.nativeElement ) )
      {
        this.tempElem = this.navHeaderElementRef.nativeElement.querySelector( id );
      }
    }
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.tempElem ) )
    {
      this.tempElem = this.navHeaderElementRef.nativeElement.querySelector( '#' + id );
    }
    // debugger;
    this.tempElem.remove();
    return this.tempElem;
  }
  //// -----------------------------------------------------------------
  //public setPrimaryImage (id : string, elem : HTMLElement) : any {
  //  // debugger;
  //  let imageData : any;
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess(); //  get the latest loginSuccess value
  //  if ((!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)
  //    && this.loginSuccess.signedInUserId > 0)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(id)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {

  //    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryImage)) {
  //      // debugger;
  //      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(elem)) {
  //        imageData = JsRegExpServiceStatic.normalizeImageData(this.loginSuccess.primaryImage); // test-2

  //        // Case-1:(setting background-image)
  //        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(imageData)) {
  //          // debugger
  //          this.renderer?.setStyle(elem, 'background-image', imageData);
  //        }
  //        /*
  //         *  this.renderer?.setStyle(pElem, 'transform', 'rotate(-45deg)');
  //         *  debugger;
  //         */
  //        // EmitterSubjectService.emitRunNgAfterViewInit( true );
  //        // debugger;
  //      }
  //    }
  //  }
  //  return true;
  //}
  // -----------------------------------------------------------------
  // getYPosition ( e : Event ) : number
  // {
  //  this.isOnScroll = true;
  //  debugger;
  //  return ( e.target as Element ).scrollTop;
  // }
  // ----------------------------------------------------------------
  getOnScroll ()
  {
    // debugger;
    // TODO: Test
    const pageContentWrapper = DomUtilsServiceStatic.getElementById('page-content-wrapper'); // this.getNavHeaderElementById( 'page-content-wrapper' );

    pageContentWrapper.onscroll = this.logScroll;
    pageContentWrapper.remove();

  }
  // ---------------------------------------------------------------
  logScroll ( e: Event )
  {
    this.isOnScroll = true;
    // debugger;
  }  
  // ---------------------------------------------------------------
  routeToHomePage () : any
  {
    this.redirectionService.setRoute(this.router, 'index', 0).subscribe(data => {
      this.pagerBrdCrm = data;
    });
    return this.pagerBrdCrm;
  }
  //  ---------------------------------------------------------------
  routeToMembersPage () : any
  {
    this.redirectionService.setRoute(this.router, 'memberscroll', this.loginSuccess.signedInUserId).subscribe(data => {
      this.pagerBrdCrm = data;
    });
    return this.pagerBrdCrm;
  }
  //// ---------------------------------------------------------------------------
  //setBreadCrumRingLength (pagerBrdCrm : PagerBreadcrum) : any {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerBrdCrm)
  //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerBrdCrm.breadcrumRing)
  //    && this.pagerBrdCrm?.breadcrumRing?.length > 0) {
  //    this.breadCrmRngLength = pagerBrdCrm?.breadcrumRing?.length;
  //  }
  //  else this.breadCrmRngLength = 0;

  //  return this.breadCrmRngLength;
  //}
  ////  ------------------------Completed on 2021/04/---------------------------------------
  //setBreadcrumPage ( direction : string ) : any
  //{
  //  // debugger;
  //  if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( direction ) )
  //  {
  //    this.pagerBrdCrm = BreadcrumServiceStatic.setBreadcrumPage( direction, this.router );
  //    this.breadCrmRngLength = this.setBreadCrumRingLength( this.pagerBrdCrm );
  //  }
  //  return true;
  //}

  // --------------------------------------------------------------
  public setRoute ( route : string, id : number ) : void
  {
     debugger;
    this.redirectionService.setRoute(this.router, route, id).subscribe(data => {
      this.pagerBrdCrm = data;
    });

  }
  //  ---------------------------------------------------------------------------------
  //public viewMyProfile () : any
  //{
  //  EmitterSubjectService.emitStartSpinner('Loading......');
  //  this.mcLoaderRouter = new ModuleComponentRouter( this.router );
  //  // debugger;
  // this.sitUser = EmitterSubjectService.getSitUserModel();

  // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0) {
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.sitUser.sitUserId === this.loginSuccess.signedInUserId) {
  //    this.isPhotoMgmt = true;
  //    EmitterSubjectService.emitIsPhotoMgmt(this.isPhotoMgmt);
  //  }
  //  else {
  //    this.isViewMember = true;
  //    EmitterSubjectService.emitIsViewMember(this.isViewMember);
  //  }
  // }

  // OR
  //  EmitterSubjectService.emitIsPhotoMgmt( false );

  //  EmitterSubjectService.emitIsViewMember( true );
  //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();

  //  this.mcLoaderRouter.router = this.router;
  //  this.mcLoaderRouter.moduleName = this.mcLoader.moduleName = 'memberm';
  //  this.mcLoaderRouter.componentName = this.mcLoader.componentName = 'memberView';
  //  this.mcLoaderRouter.sitUserId = this.mcLoader.sitUserId = this.loginSuccess.signedInUserId;

  //  this.pagerBrdCrm = RedirectionServiceStatic.fireMcLoaderRoute(this.mcLoaderRouter, this.router);

  //  this.bias.setSignedInUserBackgroundImage();

  //  let returnUrl = EmitterSubjectService.getReturnUrl();

  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) || this.sitUser.sitUserId === 0) {
  //    this.sitUser = EmitterSubjectService.getSitUserModel();
  //  }

    //// -------------------------------------------------//
    //// Back-to-Back-memberView-pges-recognition-system: //
    //// -------------------------------------------------//
    //let prevPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 2 ];
    //let currentPage = BreadcrumServiceStatic.breadcrumRing[ BreadcrumServiceStatic.breadcrumRing.length - 1 ];
    //let prevPageComponentName = prevPage.componentName;
    //let currentPageComponentName = currentPage.componentName;
    //if (
    //  (currentPageComponentName.toLowerCase().indexOf('myphotos') !== -1 // if current page is myPhotos
    //    && prevPageComponentName.toLowerCase().indexOf('memberview') !== -1) // and if previous page was memberView
    //  ||
    //  (currentPageComponentName.toLowerCase().indexOf('memberview') !== -1 // if current page is memberView
    //    && prevPageComponentName.toLowerCase().indexOf('myphotos') !== -1) // and if previous page was myPhotos
    //  ||
    //  (currentPageComponentName.toLowerCase().indexOf('memberview') !== -1 // if current page is my memberView ( can happen if top-right-profileImage is clicked )
    //    && prevPageComponentName.toLowerCase().indexOf('memberview') !== -1
    //    && prevPage.sitUserId == currentPage.sitUserId
    //    && currentPage.sitUserId === this.loginSuccess.signedInUserId) // and if previous page was my memberView ( can happen if top-right-profileImage is clicked )
    //) {
    //  // debugger;
    //  ; //do nothing 
    //}
    //else {
    //  // debugger;
    //  EmitterSubjectService.emitClearPagerPhotoNavData(true);
    //}
    //return true;
  //}
  //  ---------------------------------------------------------------------------------
}
