
export class RemoteDataModel {
  public data : any;
  public headerType = '';
  public methodName = '';
  public message = '';
  public options : any;
  public id = 0;
  public isForComponent = true;
  public isInbox = true;
  public isMethodCalled = false;
  public timestamp = '';
  public url : string = '';
  constructor() {
  }
}
