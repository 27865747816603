export class EmitOffOnResult {
  public offOnName = '';
  public offOnDistance = '';
  public offOnSelection = '';
  public offOnStatusEnum = -1;
  public selectedOffOnIndex = -1;
  public isRequiredPassed = false;
  public offOnStatusColorTimer: any; // NodeJS.Timeout;
  public offOnStatusColor = '';
  public away = '';
  public link = ''; // used by setLink
  public pTileElemId = ''; // used by setLink
  public unit = 'm';
  constructor() { }
}
