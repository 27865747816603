'use-strict'
import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, OnInit, QueryList } from '@angular/core';
import { Subject } from 'rxjs';
import { AccordionGroupComponent } from '../accordionGroup/accordionGroup.component';

// ref:https:// stackblitz.com/edit/angular-component-accordion-example

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements AfterContentInit, OnInit, OnDestroy {
  private emitterDestroyed$: Subject<boolean> = new Subject();
  @Input() titleIn = '';
  private titleOut = '';
  @ContentChildren(AccordionGroupComponent) groups!: QueryList<AccordionGroupComponent>;

  constructor() { }

  ngOnInit() { }

  /**
   * Invoked when all children (groups) are ready
   */
  ngAfterContentInit() {
    /*
     * console.log (this.groups);
     * Set active to first element
     */
    this.groups.toArray()[0].opened = true;
    // Loop through all Groups
    this.groups.toArray().forEach((t) => {
      /*
       * when title bar is clicked
       * (toggle is an @output event of Group)
       */
      t.toggle.subscribe(() => {
        // Open the group
        this.openGroup(t);
      });
      /*
       * t.toggle.subscribe((group) => {
       * //Open the group
       * this.openGroup(group);
       * });
       */
    });
  }
  /*
   * remove self from modal service when component is destroyed
   * ---------------------------------------------------------------
   */
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  /**
   * Open an accordion group
   * @param group   Group instance
   */
  openGroup(group: AccordionGroupComponent) {
    // close other groups
    this.groups.toArray().forEach((t) => (t.opened = false));
    // open current group
    group.opened = true;
  }
}
