// Note: the Kv name where the first leter is capital and the second letter is lowercase, implies that the value is of type 'any'
export class KvAny {
  public cryptUserKey = '';
  public date = '';
  public id: any;
  public index = 0;
  public key = '';
  public modelName = '';
  public sitUserId = 0;
  public value: any;
  constructor () { }

  copy(a: KvAny): KvAny {
    const that = new KvAny();

    if (a.cryptUserKey) {
      that.cryptUserKey = a.cryptUserKey;
    }
    if (a.id) {
      that.id = a.id;
    }
    if (a.index) {
      that.index = a.index;
    }
    if (a.key) {
      that.key = a.key;
    }
    if (a.modelName) {
      that.modelName = a.modelName;
    }
    if (a.sitUserId) {
      that.sitUserId = a.sitUserId;
    }
    if (a.value) {
      that.value = a.value;
    }
    return that;
  }
}
