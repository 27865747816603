import { Heartbeat } from '../common/heartbeat.model';
// Note: primarily used for copying from CommunicationActivity model in CopyServieStaticService.tys

export class ENoteTile {
  public age = 0;
  public away = 'off-line';
  public awayId = '';
  public beginIndex = 0;
  public city = '';
  public communicationMessageId = 0;
  public countryCode = '';
  public date = '';
  public dateFormatted = '';
  public distance = 0;
  public distanceStr = '';
  /*
   * public dob: string = '';
   * public gender: string = '';
   */
  public heading = '';
  // public heartbeat: Heartbeat = new Heartbeat();
  public id = '';
  public isImageRendered = false;
  public isOnline = false;
  public isPremiumMember = false;
  public isTileSetupComplete = false;
  public image = '';
  public index = -0;
  public prevHeartbeat = -1;
  public isPic = false;
  public latitude = 0;
  public link = '';
  public longitude = 0;
  public messageBody: any;
  public neighborhood = '';
  public pElemId = '';
  public photoId = 0;
  // public position: string = '';
  public profileId = 0;
  public profileName = '';
  public regionCode = '';
  // public reply: ENoteTile = new ENoteTile();
  public replyId = 0;
  /*
   * public sexuality: string = '';
   * public selectedOffOnIndex: number = -1;
   */
  public senderSitUserId = 0;
  public signedInUserId = 0;
  public subject = '';
  public unit = 'm';

  constructor() { }
}
