
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';

// =====================================================================

// =====================================================================

@Injectable({ providedIn: 'root'})
export abstract class DefaultSetterServiceStatic
{
  static randomNumberGenerator$ : Observable<number>;
  static randomNumber = 0;

 
  static clearUserData () : void {
    // ----------------------------------------------------------------------------
    //  Note: if this method is called, then any user data is to be erased as well,
    //        that way the target-page does not have to clear them.
    //        If the target-pages were to clear these data, then these lines of
    //        code would have to be repeated in all the target-pages.
    // ----------------------------------------------------------------------------
    EmitterSubjectService.emitClearUserData(true);
    EmitterSubjectService.emitClearPagerPhotoNavData(true);
    EmitterSubjectService.emitClearProfileImages([]);
    EmitterSubjectService.emitRunNgAfterViewInit(true);
  }

  // ----------------------------------------------------------------------

  // ----------------------------------------------------------------------

  // ------------------------------------------------------------------------
}
