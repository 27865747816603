
import { Chat } from '../chat/chat.model';
import { KvMemberActivityGlyph } from '../keyValue/kvMemberActivityGlyph.model';

export class PagerChat {
  public blockStartPageNo = 0;
  public blockEndPageNo = 0;
  public blockStartIndex = 0;
  public blockEndIndex = 0;
  public currentPage : Chat = new Chat();
  public currentPageNo = 0;
  public endIndex = 0;
  public endPage : Chat = new Chat();
  public endPageNo = 0;
  public firstPage : Chat = new Chat();
  public firstPageNo = 0;
  public kvPhotoArr : Chat[] = [];
  public kvGlyphsArr : KvMemberActivityGlyph[] = [];
  public lastPage : Chat = new Chat();
  public lastPageNo = 0;
  public nextPage : Chat = new Chat();
  public nextPageNo = 0;
  public pagedKvPhotoArr : Chat[] = [];
  public pagedGlyphArr : KvMemberActivityGlyph[] = [];
  public pages = [];
  public pageSize = 0;
  public previousPage : Chat = new Chat();
  public previousPageNo = 0;
  public startPage : Chat = new Chat();
  public startPageNo = 0;
  public startIndex = 0;
  public totalItems = 0;
  public totalPageNo = 0;
  // specific use-case data:
  // -----------------------
  //use-case-chat:
  public chats : Array<Chat> = [];
  public pagedChats : Chat[] = [];


  constructor () { }
}
