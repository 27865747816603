
import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Login } from '../../models/account/login.model';
import { LoginSuccess } from '../../models/account/loginSuccess.model';
import { BoxNonceEntity } from '../../models/boxNonce/boxNonceEntity.model';
import { ClientData } from '../../models/common/clientData.model';
import { Heartbeat } from '../../models/common/heartbeat.model';
import { NaclPairClient } from '../../models/crypto/naclPairClient.model';
import { NaclPairLocal } from '../../models/crypto/naclPairLocal.model';
import { DateStringServiceStatic } from '../staticServices/commonStaticServices/dateStringServiceStatic.service';
import { EmitterSubjectService } from '../staticServices/emitterObserverStaticServices/emitterSubject.service';

// ===========================================================================
// Note: Tested, works! May 15, 2020
// Note: LocalStorage's max capacity is 5MB. Hence only credentials and user's profile info are stored there for Pwa.
// Note: Data is NOT encrypted/decrypted by this service-component.
//       However, most of its methods accepts a 'BoxNonce' or 'NaclPairClient' model,
//       and stores the model data after applying JSON.stringify(model),
//       and returns data after applying JSON.parse(model),
//
// Note: in===private; out===public
// ===========================================================================

@Injectable({
  providedIn: 'any'
})
export class LocalStorageService implements OnDestroy {
  public boxNonce: BoxNonceEntity = new BoxNonceEntity();
  public clientData: ClientData = new ClientData();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public heartbeat: Heartbeat = new Heartbeat();
  public isMobilevar = false;
  public login: Login = new Login();
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public message = '';
  constructor(
  )
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.loginSuccessEmitter ) )
    {
      EmitterSubjectService.loginSuccessEmitter
        .pipe( takeUntil( this.emitterDestroyed$ ) )
        .subscribe( result =>
        {
          // debugger;
          this.loginSuccess = result;
        } );
    }
    // ---------------------------------------------------
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.isMobileEmitter ) )
    {
      EmitterSubjectService.isMobileEmitter
        .pipe( takeUntil( this.emitterDestroyed$ ) )
        .subscribe( result =>
      {
        this.isMobilevar = result;
      } );
    }
  }
// ------------------------------------------------------------------
  initialize () : void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.getLoginSuccess ) ) {
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // debugger;


    this.clientData.id = this.loginSuccess.signedInUserId;
    this.clientData.key = this.loginSuccess.signedInUserId.toString();
    this.clientData.date = DateStringServiceStatic.getTicks( new Date() ).toString();
  }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( EmitterSubjectService.getIsMobile ) )
    {
      this.isMobilevar = EmitterSubjectService.getIsMobile();
    }
  }
// ------------------------------------------------------------------
  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
// ------------------------------------------------------------------
  isLocalStorage(sitUserId: number): any {
    if ( localStorage.length > 0 )
    {
      let tmpVal = localStorage.getItem( sitUserId.toString() );
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tmpVal ) )
      {
        return true;
        // let localData = JSON.parse(tmpVal );
        // if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( localData ) )
        // {
        //  return true;
        // }
      }

    }
  }
// ------------------------------------------------------------------
  getLocalSaltyPair(): any {
    // debugger;
    if ( localStorage.length > 0 )
    {
      let tmpVal = localStorage.getItem( 'saltyPair' );
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( tmpVal ) )
      {
        const clientData = JSON.parse( tmpVal as string );
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( clientData )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( clientData.in )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( clientData.out ) )
        {
          return clientData;
        }
      }
    }
    return null;
  }
// ------------------------------------------------------------------
  // Note: we save loginSuccess separately than ClientData since it's key is different and needs to be loaded before user logs in
  setSaltyPair(salty: NaclPairClient): any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(salty)) {
      const localSalty = new NaclPairLocal();
      if (localStorage.length > 0) {
        const removeSalty = localStorage.getItem('saltyPair');
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(removeSalty)) {
          localStorage.removeItem('saltyPair');
          // debugger;
        }
      }
      localSalty.out = salty.publicKey;
      localSalty.in = salty.secretKey;
      localSalty.date = salty.date;
      // debugger;
      // Note: on clientData, each model's time is when the model was saved, not the original model time that came from the server.
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(localSalty)) {
        // TODO:Test!
        localSalty.date = DateStringServiceStatic.getTicks(new Date()).toString();
        localStorage.setItem('saltyPair', JSON.stringify(localSalty));
        // debugger;
        return true;
      }
    }
    return false;
  }
// ------------------------------------------------------------------
  getLoginSuccess(): any {
    // debugger;
    if (localStorage.length > 0) {
      const clientData = JSON.parse(localStorage.getItem('loginSuccess') as string);
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData)) {
        return clientData;
      }
    }
    return null;
  }
// ------------------------------------------------------------------
  updateLoginSuccess(inBn: BoxNonceEntity): any {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inBn)) {
      const localLS = new BoxNonceEntity();
      localLS.box = inBn.box;
      localLS.nonce = inBn.nonce;
      localLS.id = this.loginSuccess.signedInUserId;
      localLS.cryptUserKey = this.loginSuccess.cryptUserKey;
      localLS.entityName = 'loginSuccess';
      // Note: on clientData, each model's time is when the model was saved, not the original model time that came from the server.
      localLS.date = DateStringServiceStatic.getTicks(new Date()).toString();
      // debugger;
      localStorage.setItem('loginSuccess', JSON.stringify(localLS));
      // debugger;
      return true;
    }
    return false;
  }
// ------------------------------------------------------------------
  // Note: we save loginSuccess separately than ClientData since it's key is different and needs to be loaded before user logs in
  setLoginSuccess(result: BoxNonceEntity): any {
    // debugger;
    if (localStorage.length > 0) {
      const localLSuccess = localStorage.getItem('loginSuccess');
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(localLSuccess)) {
        localStorage.removeItem('loginSuccess');
        // debugger;
        return this.updateLoginSuccess(result);
      }
    }
    else {
      return this.updateLoginSuccess(result);
    }
    return false;
  }
// ------------------------------------------------------------------
  getPreference(sitUserId: number): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserId) && sitUserId > 0) {
      if (localStorage.length > 0) {
        const clientData = JSON.parse(localStorage.getItem(sitUserId.toString()) as string);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.preference)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.preference.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.preference.nonce)) {
          return clientData.preference;
        }
      }
    }
    return false;
  }
// ------------------------------------------------------------------
  setPreference(preference: BoxNonceEntity): any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference)
        && preference.id > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.box)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(preference.nonce)) {
      this.clientData.preference = preference;
      // debugger;
      this.clientData.preference.id = preference.id;
      // Note: on clientData, each model's time is when the model was saved, not the original model time
      //     that came from the server.
      this.clientData.preference.date = DateStringServiceStatic.getTicks(new Date()).toString();
      localStorage.setItem(preference.id.toString(), JSON.stringify(this.clientData));
      return true;
    }
    return false;
  }
// ------------------------------------------------------------------
  getProfileInfo(sitUserId: number): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserId) && sitUserId > 0) {
      if (localStorage.length > 0) {
        const clientData = JSON.parse(localStorage.getItem(sitUserId.toString()) as string);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.profileInfo)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.profileInfo.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.profileInfo.nonce)) {
          return clientData.profileInfo;
        }
      }
    }
    return false;
  }
// ------------------------------------------------------------------
  setProfileInfo(profileInfo: BoxNonceEntity): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfo)
    && profileInfo.id > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfo.box)
    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileInfo.nonce)) {
      this.clientData.profileInfo = profileInfo;
      // debugger;
      this.clientData.profileInfo.id = profileInfo.id;
      // Note: on clientData, each model's time is when the model was saved, not the original model time
      //      that came from the server.
      this.clientData.profileInfo.date = DateStringServiceStatic.getTicks(new Date()).toString();
      localStorage.setItem(profileInfo.id.toString(), JSON.stringify(this.clientData));
      return true;
    }
    return false;
  }
// ------------------------------------------------------------------
  getProfileTiles(): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.signedInUserId) && this.loginSuccess.signedInUserId > 0) {
      if (localStorage.length > 0) {
        const clientData = JSON.parse(localStorage.getItem(this.loginSuccess.signedInUserId.toString()) as string);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData)
            && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.profileTiles)
            && clientData.profileTiles.length > 0) {
          // debugger;
          return clientData.profileTiles;
        }
      }
    }
    return false;
  }
// ------------------------------------------------------------------
  setProfileTiles(profileTiles: BoxNonceEntity[]): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profileTiles) && profileTiles.length > 0 ) {
      this.clientData.profileTiles = profileTiles;
      // debugger;
      // Note: on clientData, each model's time is when the model was saved, not the original model time that came from the server.
      // However, for array-entries such as profileTiles, we keep the server-date of each entry and
      // assume the date of the array to be that os clientDataModel
      localStorage.setItem(this.loginSuccess.signedInUserId.toString(), JSON.stringify(this.clientData));
      return true;
    }
    return false;
  }
// ------------------------------------------------------------------
  getProfilePics(sitUserId: number): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(sitUserId) && sitUserId > 0) {
      if (localStorage.length > 0) {
        const clientData = JSON.parse(localStorage.getItem(sitUserId.toString()) as string);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.profilePics)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.profilePics.box)
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(clientData.profilePics.nonce)) {
          return clientData.profilePics;
        }
      }
    }
    return false;
  }
// ------------------------------------------------------------------
  setProfilePics(profilePics: BoxNonceEntity): any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics)
      && profilePics.id > 0 && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.box)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(profilePics.nonce)) {
      this.clientData.profilePics = profilePics;
      // debugger;
      this.clientData.profilePics.id = profilePics.id;
      // Note: on clientData, each model's time is when the model was saved, not the original model time that came from the server.
      this.clientData.profilePics.date = DateStringServiceStatic.getTicks(new Date()).toString();
      localStorage.setItem(profilePics.id.toString(), JSON.stringify(this.clientData.profilePics));
      // debugger;
      return true;
    }
    return false;
  }
  // =================================================================================
}

