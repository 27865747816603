export class ActionId {
  public action = '';
  public date = '';
  public id = 0;
  public message: undefined;
  public value: undefined;
  constructor() {
    // ;
  }
}
