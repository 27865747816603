import { Login } from "./login.model";



export class Register {
  public age : any = 0;
  public backupPassword : any = ''; // real-password holder
  public confirmPassword : any = '';
  public code : any = '';
  public dob : any = 'YYYY-MM-DD';
  public dobTicks : any = 0;
  public email : any = '';
  public firstName : any = '';
  public isDobErr : any = false;
  public isAllowed = false;
  public isEmailValid = false;
  public isFormValid = false;
  public isPasswordValid = false;
  public lastName : any = '';
  public password : any = '';
  public latitude : any = '';
  public longitude : any = '';
  public message : any = '';
  public sitUserId : any = 0;
  public date : any = '';
  public jsDateForCs : any = '';

  constructor () {  }
  
}
