<div class="text-center flex-container"  
     style="text-align: center;width: 100%; line-height: 1.25rem; display: inline-block; z-index:11;">
  <ul class="flex-text-center"
      style="display: inline-block; width: 100%; line-height: 1.23rem; list-style: none; cursor: pointer; justify-content: center;">
    <li id="pageB-F" type="button"
        (click)="setPage('F')"
        [ngClass]="{'disabled': pagerChat.currentPageNo === 1}"
        class="bttn-xxs-transparent hvr-underline-from-center"
        style="display: inline-block; line-height: 1.22rem;">
      <!--span [ngClass]="{'glyphicon glyphicon-ban-circle errxSB': pagerChat && pagerChat.currentPageNo <== 1}" aria-hidden="true"></span-->
      <span class="glyphicon glyphicon-step-backward text-center hvr-icon bluGrnGldxS"
            style="vertical-align: middle;margin-top: -0.15rem;"
            aria-hidden="true"></span>

    </li>
    <li id="pageB-prev" type="button"
        (click)="setPage('prev')"
        [ngClass]="{'disabled': pagerChat?.currentPage === 1}"
        class="bttn-xxs-transparent hvr-underline-from-center"
        style="display: inline-block; line-height: 1.22rem;">
      <!--spanclass="" [ngClass]="{'glyphicon glyphicon-ban-circle errxSB': pagerChat && pagerChat.currentPageNo <== 1}" aria-hidden="true"></span-->
      <span class="glyphicon glyphicon-backward text-center hvr-icon bluGrnGldxS"
            style="vertical-align:middle;margin-top:-0.15rem;"
            aria-hidden="true"></span>

    </li>
    <!--[ngClass]="{'active': (pager.currentPageNo === page)}"-->
    <li *ngFor="let page of pagerChat.pages; let i = index;"
        style="display: inline-block;"
        id="pageB-{{page}}"
        class="bttn-xxs-transparent hvr-underline-from-center"
        type="button"
        (click)="setPage(page.toString())">
      <span class="bluGrnGldxS">
        {{page}}
      </span>
    </li>
    <li id="pageB-next"
        (click)="setPage('next')"
        [ngClass]="{'disabled': pagerChat && pagerChat.currentPageNo === pagerChat.endPageNo}"
        class="bttn-xxs-transparent hvr-underline-from-center"
        type="button"
        style="display: inline-block;">
      <span class="glyphicon glyphicon-forward text-center hvr-icon bluGrnGldxS"
            style="vertical-align: middle;margin-top:-0.15rem;" aria-hidden="true"></span>
      <!--span [ngClass]="{'glyphicon glyphicon-ban-circle errxSB': pagerChat && pagerChat.currentPageNo === pagerChat.endPageNo}" aria-hidden="true"></span-->
    </li>
    <li id="pageB-L"
        (click)="setPage('L')"
        [ngClass]="{'disabled': pagerChat && pagerChat.currentPageNo === pagerChat.totalPageNo}"
        class="bttn-xxs-transparent hvr-underline-from-center"
        type="button"
        style="display: inline-block;">
      <span class="glyphicon glyphicon-step-forward text-center hvr-icon bluGrnGldxS"
            style="vertical-align: middle;margin-top: -0.15rem;"
            aria-hidden="true"></span>
      <!--span [ngClass]="{'glyphicon glyphicon-ban-circle errxSB': pagerChat && pagerChat.currentPageNo === pagerChat.totalPageNo}" aria-hidden="true"></span-->
      </li>
    </ul>
  </div>
