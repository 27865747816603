import { KvAny } from '../keyValue/kvAny.model';
import { KvMemberActivityGlyph } from '../keyValue/kvMemberActivityGlyph.model';
import { KvPhoto } from '../keyValue/kvPhoto.model';
import { BasicPagerModel } from './basicPagerModel.model';

export class Pager extends BasicPagerModel{
  // public isPhotoMgmt = false;
  //public totalItems = 0;
  //public currentPageNo = 0;
  //public pageSize = 0;
  //public totalPages = 0;
  //public startPageNo = 0;
  //public endPageNo = 0;
  //public startIndex = 0;
  //public endIndex = 0;
  //public kvPhotoArr: KvPhoto[] = [];
  //public kvGlyphsArr : KvMemberActivityGlyph[] = [];
  //public pages: any;
  //public pagedKvPhotoArr: KvPhoto[] = [];
  //public pagedGlyphArr : KvMemberActivityGlyph[] = [];
  //public currentPage : KvPhoto = new KvPhoto();
  //public firstPage : KvPhoto = new KvPhoto();
  //public lastPage : KvPhoto = new KvPhoto();
  //public nextPage : KvPhoto = new KvPhoto();
  //public previousPage : KvPhoto = new KvPhoto();
  constructor () { super();}
}
