'use-strict'
// import { BreakpointObserver } from '@angular/cdk/layout';
import { DOCUMENT } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef, Component, ElementRef,
    Inject, NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ActivatedRoute, Route, Router, RouterOutlet } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
// import { DeviceDetectorService } from 'ngx-device-detector';
// import * as log4js from 'log4js';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PhotoBn } from '../models/common/photoBn.model';
import { SpinnerModel } from '../models/common/spinnerModel.model';
import { ViewportDimension } from '../models/common/viewportDimension.model';
import { KvMemberActivityGlyph } from '../models/keyValue/kvMemberActivityGlyph.model';
import { PagerMemberActivity } from '../models/pagination/pagerMemberActivity.model';
import { ProfileTile } from '../models/profile/profileTile.model';
import { SWUpdateService } from '../services/appServiceService/swUpdateService.service';
// import { SWUpdateService } from '../services/appServiceService/swUpdateService.service';
import { SignedInUserService } from '../services/commonServiceService/signedInUserService.service';
import { HeartbeatService } from '../services/coreServiceService/heartbeatService.service';
import { HttpService } from '../services/coreServiceService/httpService.service';
import { DbDexieToDictionaryService } from '../services/dbServiceService/dbDexieToDictionaryService.service';
import { GlyphiconMenuService } from '../services/GlyphiconService/glypgiconMenuService.service';
import { PagerPhotoMgmtService } from '../services/pagerServiceService/pagerPhotoMgmtService.service';
import { PagerSupportService } from '../services/pagerServiceService/pagerSupportService.service';
import { PhotoBnService } from '../services/photoServiceService/photoBnService.service';
import { PhotoServiceService } from '../services/photoServiceService/photoServiceService.service';
import { ProfileServiceService } from '../services/profileServiceService/profileServiceService.service';
import { RendererServiceService } from '../services/rendererServiceService/rendererServiceService.service';
import { MessageModel } from '../models/common/messageModel.model';
import { MemberActivity } from '../models/member/memberActivity.model';
import { RedirectionService } from '../services/commonServiceService/redirectionService.service';
import { LogRegService } from '../services/coreServiceService/logRegService.service';
import { GlyphiconServiceService } from '../services/GlyphiconService/glyphiconServiceService.service';
import { BreadcrumServiceStatic } from '../services/staticServices/breadcrumServiceStatic.service';
import { CopyServiceStatic } from '../services/staticServices/commonStaticServices/copyServiceStatic.service';
import { HammerServiceStatic } from '../services/staticServices/commonStaticServices/hammerServiceStatic.service';
import { SlakezSaltServiceStatic } from '../services/staticServices/commonStaticServices/slakezSaltServiceStatic.service';
import { EmitterSubjectService } from '../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { InMemoryDataServiceStatic } from '../services/staticServices/inMemoryDataServiceStatic.service';
import { LoginSuccess } from './../models/account/loginSuccess.model';
import { SitUser } from './../models/account/sitUser.model';
import { BoxNonceEntity } from './../models/boxNonce/boxNonceEntity.model';
import { BrdCrmKvAny } from './../models/breadcrum/brdcrmKvAny.model';
import { PagerBreadcrum } from './../models/breadcrum/pagerBreadcrum.model';
import { Heartbeat } from './../models/common/heartbeat.model';
import { ModuleComponentLoader } from './../models/common/moduleComponentLoader.model';
import { PhotoMgmtAction } from './../models/common/photoMgmtAction.model';
import { UserAndPageTitle } from './../models/common/userAndPageTitle.model';
import { NaclPairClient } from './../models/crypto/naclPairClient.model';
import { Salt } from './../models/crypto/salt.model';
import { KvAny } from './../models/keyValue/kvAny.model';
import { KvPhoto } from './../models/keyValue/kvPhoto.model';
import { CenterCoordinates } from './../models/misc/centerCoordinates.model';
import { PagerPhotoMgmt } from './../models/pagination/pagerPhotoMgmt.model';
import { ProfilePics } from './../models/profile/profilePics.model';
import { AppServiceService } from './../services/appServiceService/appServiceService.service';
import { BreakPointObserverService } from './../services/appServiceService/breakPointObserverService.service';
import { CommonServiceService } from './../services/commonServiceService/commonServiceService.service';
import { SitUserService } from './../services/commonServiceService/sitUserService.service';
import { CheckConnectionService } from './../services/coreServiceService/checkConnectionService.service';
import { CoreServiceService } from './../services/coreServiceService/coreServiceService.service';
import { DbServiceService } from './../services/dbServiceService/dbServiceService.service';
import { LocalStorageService } from './../services/dbServiceService/localStorageService.service';
import { ActivityService } from './../services/memberServiceService/activityService.service';
import { MemberServiceService } from './../services/memberServiceService/memberServiceService.service';
import { PagerServiceService } from './../services/pagerServiceService/pagerServiceService.service';
import { BackgroundImageAnimationService } from './../services/rendererServiceService/backgroundImageAnimationService.service';
import { RendererService } from './../services/rendererServiceService/rendererService.service';
import { FrequentlyUsedFunctionsServiceStatic } from './../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Animations, slideInOutAnimation } from './animation';
import { DictionaryIOService } from '../services/dictionaryServiceService/dictionaryIOService.service';

// import { SignalRService } from '../services/communicationServiceService/signal-r.service';

/*
 * declare let jQuery: any;
 * declare let $: any;
 */

/*
 *  ---------------------------------------------------------------------------------------
 *  ref:https://www.tektutorialshub.com/angular/angular-canonical-url/
 * Usage example: this.seoService.updateCanonicalUrl(hostName+'/first');
 */
const hostName = 'https:// localhost:44392'; // 'http:// slakez.com/slakez/'; // ;'https:// localhost:44371/'
//  --------------------------------------------------------------------------------------

// @DestroySubscribers()
@Component({
  /*moduleId: module.id,*/
  // standalone: true, // needed for hammerGestureConfig
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [Animations, slideInOutAnimation ],
  changeDetection: ChangeDetectionStrategy.OnPush, //  default //
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  loading$ : Observable<boolean> = of(false); // rout-change-spinner
  /*
   *  ref: https://medium.com/angular-in-depth/lazy-loading-angular-modules-with-ivy-92c3a890eae1
   *  partOf Lazy-Loading; Note: Ancors are always to be ViewContainerRef
   */
 // @ViewChild( 'appAnchor', { read: ViewContainerRef } ) appAnchor! : ViewContainerRef;
  @ViewChild('appElementRef', { static: false }) appElementRef! : ElementRef;
  @ViewChild( 'appSidebar', { static: false } ) appSidebar! : ElementRef;

  public subscribers: any = { }; // must be used for @DestroySubscribers()
  /*
   *  -------------------------------------------------------------
   * @ViewChild(HelloComponent, { static: false }) hello: HelloComponent;
   *  -------------------------------------------------------------
   */
  public bias !: BackgroundImageAnimationService;
  public breakpointObserverService!: BreakPointObserverService;
  public checkConnectionService! : CheckConnectionService;
  public dbDexieToDictionaryService! : DbDexieToDictionaryService;
  public dictionaryIOService! : DictionaryIOService;
  // public errorLogService!: ErrorLogService;
  // public gestureService!: GestureService;
  public glyphiconMenuService! : GlyphiconMenuService;
  public heartbeatService!: HeartbeatService;
  public httpService! : HttpService; // primarily to set at Emitter so that others can get it
  public localStorageService! : LocalStorageService;
  public logRegService : LogRegService;
  public masterData: any;
  public activityService!: ActivityService;
  public photoBnService! : PhotoBnService;
  public redirectionService! : RedirectionService;
  public rendererService!: RendererService;
  public renderer! : Renderer2;
  public pagerPhotoMgmtService! : PagerPhotoMgmtService;
  public pagerSupportService! : PagerSupportService;
  public sitUserService! : SitUserService;
  public signedInUserService! : SignedInUserService;
  public swUpdateService! : SWUpdateService;
 // public signalRService! : SignalRService;
  //  ------------------------------------------
  public activeComponentIds: string[] = [];
  public activeModuleComponentIds : string[] = [];
  public biasKvPhotoArr: KvPhoto[] = [];
  public boxNonceEntity : BoxNonceEntity = new BoxNonceEntity();
  public bottom : any;
  public breadcrumRing : ModuleComponentLoader[] = [];
  public breadCrmRngLength = 0;
  public callerName = '';
  public cart: any;
  public cartItemsCount = 0;
  public centerCoordinates : CenterCoordinates = new CenterCoordinates();
  public componentViewportDimension : ViewportDimension = new ViewportDimension();
  public currentRoute = '';
  public defaultPics : KvPhoto[] = [];
  public defaultImageKvPhotos : KvPhoto[] = [];
  // these images are in 'wwwroot/assets/photos/' folder
  public defaultImageNameArr : string[] = [];
  public defaultImageNames : string[] = [
    './assets/photos/gay-couple-1.jpg',
    './assets/photos/gay3.jpg',
    './assets/photos/leatherCouple-2.png',
    './assets/photos/gay1.1.png',
    './assets/photos/leatherMan-2.png',
    './assets/photos/gay2.1.png',
    './assets/photos/leatherMan-4.png',
    './assets/photos/leatherMasterSlave.png',
  ];
  public defaultGlyphNames: string[] = [];
  public deviceInfo :any;
  public document !: Document;
  public window ! : Window;
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public enums: any;
  public enumDataObservable: any;
  public enumDataSubscription: Subscription = new Subscription();
  public gestureDivId = 'bodyBackgroundImageId';
  public glyphPhotoMgmtMenuSubscription : Subscription = new Subscription();
  public hb : any;
  public heartbeatCounter = 0;
  public hasEmittedLoginSuccess = false;
  public hubConnection : signalR.HubConnection;
  public intervalDbg: any; // Dbg==defalt background
  public intervalMsg : any;
  public initTime = new Date().getTime();
  public endTime : any;
  public isAuthorized = false;
  public isBusy = false;
  public isConnectionEstablished = false;
  public isDefault = false;
  public isDesktopvar = false;
  public isDisabled = false;
  public isEmployee = false;
  public isEditProfile = false;
  public isHome = false;
  public isInitHeartbeat = true;
  public isInizializationComplete = false;
  public isLocalStorageProcessed = false;
  public isLoading = false;
  public isLoggedIn = false;
  public isLoggedOutByUser = false;
  public isMessageBoardVisible = false;
  public isMgmt = false;
  public isMobilevar = false;
  public isModal = false;
  public isMouseIn = false;
  public isMyProfile = false;
  public isMyStuffOpen = false;
  public isOnLine = false;
  public isOpen = false;
  public isPhotoMgmt = false;
  public isPremium = false;
  public isReviewer = false;
  public isSidebarOpen = false;
  public isSetPage = false;
  public isSingletap = true;
  public isSignup = true;
  public isSpinner = false;
  public isSpinnerRunning = false;
  public isSuccess = true;
  public isSuperUser = false;
  public isTabletvar = false;
  public isValued = false;
  public isViewMember = false;
  public isVsScroll = false;
  public kvGlyphArr : KvMemberActivityGlyph[] = [];
  public kvGlyphArrForPhotoMgmt : KvMemberActivityGlyph[] = [];
  public kvGlyphArrForViewMember : KvMemberActivityGlyph[] = [];
  public kvPhotoArr : KvPhoto[] = [];
  public kvPhotoArrBackup : KvPhoto[] = [];
  public kvPhotoLock: KvPhoto = new KvPhoto();
  public kvPhotoUnLock: KvPhoto = new KvPhoto();
  public kvPhotoPrivate: KvPhoto = new KvPhoto();
  public kvPhotoPublic: KvPhoto = new KvPhoto();
  public loginSuccess: LoginSuccess = new LoginSuccess();
  public logregFactory: any;
  public mainTop = 0; // DO NOT DELETE: this is a CSS-top-property on .mainROutlet class to be set by each component when rendered
  public mc: any;
  public mcLoader : ModuleComponentLoader = new ModuleComponentLoader();
  public memberActivities : MemberActivity[] = [];
   public messageModel: MessageModel = new MessageModel();
   public messages: string[] = [];
  public naclClient: NaclPairClient = new NaclPairClient();
  public ngAfterViwInitCounter = 0;
  private onDestroy$ : Subject<void> = new Subject<void>();

  public pageSize = 6; // default value
  public pageNo = 0;
  public pageTitle = '';
  public pagerV : PagerMemberActivity = new PagerMemberActivity();
  public pagerVKvPhotoNav : PagerPhotoMgmt = new PagerPhotoMgmt();
  public pagerVMemActGlyphForPhotoMgmt : PagerMemberActivity = new PagerMemberActivity(); // pagerVMemActGlyphForViewMember
  public pagerVMemActGlyphForViewMember : PagerMemberActivity = new PagerMemberActivity();
  public pagerBrdCrm: PagerBreadcrum = new PagerBreadcrum();
  public pagerPhotoMgmt: PagerPhotoMgmt = new PagerPhotoMgmt();
  public photoMgmtAction: PhotoMgmtAction = new PhotoMgmtAction();
  public pauseTimer: any;
  public profilePics : ProfilePics = new ProfilePics();
  public profileTile : ProfileTile = new ProfileTile();
  public profileImageKvPhotos: KvPhoto[] = [];
  public profileNameChars: any[] = [];
  public returnUrl = '';
  public routedElementId = '';
  public routes: Route[] = [];
  public signedInUserId = 0;
  public sitUser : SitUser = new SitUser();
  public signedInUser : SitUser = new SitUser();
  public selectedProfileTile : ProfileTile = new ProfileTile();
  public spinnerRequestCounter = 0;
  public spinnerModel : SpinnerModel = new SpinnerModel();
  public tempElem: any;
  public title = 'Slakez';
  public tobeRemovedComponentName = '';
  public top : any;
  public tSitUserId = 0;
  public unsaltedIndexedDbData: any;
  public urlString = '';
  public userAndPageTitle : UserAndPageTitle = new UserAndPageTitle();
  public viewportDimension : ViewportDimension = new ViewportDimension();
  public wishListCount = 0;
  public width = 0;
  public height = 0;
  
   // -------------------------
   //  Breadcrum vaiables:
   // -------------------------
  public routerKvAny: BrdCrmKvAny = new BrdCrmKvAny();
  public pagedBreadcrumArr: KvAny[] = [];
  public navEventObserver: Subscription = new Subscription();
  public numberOfTicks = 0;
  public callStackCount = 0;
  public timer: any;
  public timerMap: Map<any,any> = new Map();
  public yourLoginSuccess: LoginSuccess = new LoginSuccess();
 
   //  ------------------------------------
   //   Background-Image-Rotaion-Vars
   //  ------------------------------------

   // -------------------------------------
   // Timer Vars
   // -------------------------------------
  public idleState = 'Not started.';
  public isTimedOut = false;
  public isPaused = false;
  public lastPing!: Date;
  public intervalPeriod = 1000;
  public idlePeriod = 500;
  public timeoutPeriod = 500;
  // ----------------------------------------------------------

  public constructor (
    private activatedRoute: ActivatedRoute,
    public cdr: ChangeDetectorRef,

    // lazy-loading-injections:
    private deviceService : DeviceDetectorService,
    private elementRef: ElementRef, // partOf Lazy-UnLoading
    private vc : ViewContainerRef,

    private appServiceService: AppServiceService,
    private commonServiceService: CommonServiceService,
    private coreServiceService: CoreServiceService,
    private dbServiceService : DbServiceService,
    private glyphiconServiceService: GlyphiconServiceService,
    private pagerServiceService : PagerServiceService,
    private photoServiceService : PhotoServiceService,
    private profileServiceService: ProfileServiceService,
    private memberServiceService : MemberServiceService,
    private rendererServiceService : RendererServiceService,
    // private signalRService : SignalRService,
    private ngZone: NgZone,
    private router: Router,
    @Inject( DOCUMENT ) Document: Document,
    @Inject( Window ) Window: Window,
  ) {
    // debugger;
    this.loginSuccess = new LoginSuccess();
    this.isAuthorized = false;
    this.isEmployee = false;
    this.isMgmt = false;
    this.signedInUserId = 0;
    this.document = Document;
    this.window = Window;
     if (this.appServiceService) {
       this.swUpdateService = this.appServiceService.getSWUpdateService();
    }

    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobilevar = this.deviceService.isMobile();
    this.isTabletvar = this.deviceService.isTablet();
    this.isDesktopvar = this.deviceService.isDesktop();
    // -----------------------------------------------------------------
    if ( this.commonServiceService )
    {
      this.redirectionService = this.commonServiceService.getRedirectionService();
      this.sitUserService = this.commonServiceService.getSitUserService() as SitUserService;
      this.signedInUserService = this.commonServiceService.getSignedInUserService();
    }
    // -----------------------------------------------------------------
    if ( this.coreServiceService )
    {
      this.checkConnectionService = this.coreServiceService.getCheckConnectionService() as CheckConnectionService;
      // debugger;
      this.heartbeatService = this.coreServiceService.getHeartbeatService();
      this.httpService = this.coreServiceService.getHttpService();
      EmitterSubjectService.setHttpService(this.httpService);
      this.logRegService = this.coreServiceService.getLogRegService();
    }

    if (this.pagerServiceService) {
      this.pagerSupportService = this.pagerServiceService.getPagerSupportService();
      this.pagerPhotoMgmtService = this.pagerServiceService.getPagerPhotoMgmtService();
    }
    if (this.photoServiceService) {
       this.photoBnService = this.photoServiceService.getPhotoBnService();
     }
    if (this.rendererServiceService)
    {
      this.bias = this.rendererServiceService.getBackgroundImageAnimationService();
      this.rendererService = this.rendererServiceService.getRendererService() as RendererService;
    }
    if ( this.rendererService )
    {
      this.rendererService.setDocument( this.document);
    }
    // -----------------------------------------------------------------
    if (this.dbServiceService) {
      this.dictionaryIOService = this.dbServiceService.getDictionaryIOService();
       this.dbDexieToDictionaryService = this.dbServiceService.getDbDexieToDictionaryService() as DbDexieToDictionaryService;
       this.localStorageService = this.dbServiceService.getLocalStorageService() as LocalStorageService;
    }
    // -----------------------------------------------------------------
    // debugger;
    EmitterSubjectService.setDocument( this.document );
    EmitterSubjectService.setWindow( this.window );
    EmitterSubjectService.setIsMobile( this.isMobilevar );
    // debugger;

    EmitterSubjectService.setChangeDetectorRef( cdr );
    EmitterSubjectService.setAppElementRef( this.elementRef );
    // this.appAnchor = vc;

    // -----------------------------------------------------------------
    this.defaultImageNameArr = (this.bias as BackgroundImageAnimationService).getDefaultImageNameArr();
    this.defaultImageKvPhotos = (this.bias as BackgroundImageAnimationService).setDefaultImageNamesToKvPhotoArr(this.defaultImageNameArr);

    // -----------------------------------------------------------------
    if ( this.memberServiceService )
    {
      this.activityService = this.memberServiceService.getActivityService() as ActivityService;
    }

    // -----------------------------------------------------------------
    // debugger;
    if ( this.glyphiconServiceService )
    {
      this.glyphiconMenuService = this.glyphiconServiceService.getGlyphiconMenuService() as GlyphiconMenuService;
    }
    //  -----------------------------------------------------------------
    if ( this.commonServiceService )
    {
    }
    // ===================================================================

      this.breadcrumRing = BreadcrumServiceStatic.getRing();
      this.pagerBrdCrm = BreadcrumServiceStatic.getPagerBreadcrum();
      // debugger;
    //  -----------------------------------------------------------------
    if (this.rendererService) {
      this.renderer = (this.rendererService as RendererService).getRenderer();
    }
    //  -----------------------------------------------------------------
    this.isOnLine = this.checkConnectionService.isOnline();
    EmitterSubjectService.setIsOnLine(this.isOnLine);

    // this.isBusyModel.isBusy = false;
    //  -----------------------------------------------------------------
    this.enums = EmitterSubjectService.getEnums();
    if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.enums))
    {
      this.enums = InMemoryDataServiceStatic.getEnums;
    }

    //  debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.enums ) )
    {
      // debugger;
      this.defaultImageNames = this.enums.defaultImageNames; // TODO: watch for its impact on current defaultNameArr
      this.defaultGlyphNames = this.enums.defaultGlyphNames;
    }

    // -----------------------------------------------------------
    this.deviceInfo = this.detectDevice();
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.deviceInfo ) )
    {
      this.isMobilevar = this.deviceInfo.isMobile();
      EmitterSubjectService.setIsMobile( this.isMobilevar );
    }
    // -----------------------------------------------------------
    EmitterSubjectService.setAppElementRef( this.appElementRef );
    // -----------------------------------------------------------

    this.viewportDimension = this.rendererService.getViewport();

    // this.spinnerLoader.setLoading(true);
  }
  //  ---------------------------------------------------------------------------------
  ngOnInit () : void {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dbDexieToDictionaryService)) {
      EmitterSubjectService.setDbDexieToDictionaryService(this.dbDexieToDictionaryService);
		}
    // force update the Service Worker:
    // --------------------------------
     this.swUpdateService.forceSwUpdateActivation();

     //if (SWUpdateServiceStatic.isEnabled) {
     //  SWUpdateServiceStatic.forceSwUpdateActivation();
     //}
    // --------------------------------------------------------------------------
    // debugger;
    this.httpService = this.coreServiceService.getHttpService();
    EmitterSubjectService.setHttpService(this.httpService);
    EmitterSubjectService.setAppElementRef(this.elementRef);

    //  -------------------------------------------------------------------------
    this.returnUrl = window.location.pathname + window.location.search;
    //  -------------------------------------------------------------------------


    EmitterSubjectService.setReturnUrl(this.returnUrl);
    EmitterSubjectService.setHostName(hostName);
    //  -------------------------------------------------------------------------
    //  Begin of All-Emitters:
    //  -------------------------------------------------------------------------
    EmitterSubjectService.centerCoordinatesEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.centerCoordinates = result as CenterCoordinates;
      });
    //  -------------------------Completed on 2021/04/10------------------------------------------------
    EmitterSubjectService.checkLocalStorageForLoginSuccessEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        // debugger;
        if (!this.isLocalStorageProcessed) {
          // debugger;
          this.checkLocalStogareForLoginSuccess();
        }
      });
    // ------------------------------------------------------------
    //  Create-Dictionary-From-IndexexDb:
    // ------------------------------------------------------------
    // debugger;
    if (!this.isOnLine) {
      debugger;
      this.checkLocalStogareForLoginSuccess();
      debugger;
      this.dictionaryIOService.createDictionaryFromDbDexieDb('all' as string);
    }
    // EmitterSubjectService.emitCreateDictionaryFromDbDixie('sitUser');
    // EmitterSubjectService.emitCreateDictionaryFromDbDixie('loginSuccess');
    // EmitterSubjectService.emitCreateDictionaryFromDbDixie('profileTile');
    // EmitterSubjectService.emitCreateDictionaryFromDbDixie('profileInfo');
    // EmitterSubjectService.emitCreateDictionaryFromDbDixie('kvPhoto');
    // EmitterSubjectService.emitCreateDictionaryFromDbDixie('photoBnId');

    // this.createAllDictionariesFromDbDexieDb();
    // ----------------------------------------------------
    // All the dictionaries can be created by this emitter-listener
    // ---------------------------------------------------
     EmitterSubjectService.createDictionaryFromDbDixieEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((entityName) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(entityName)) {
          this.dictionaryIOService.createDictionaryFromDbDexieDb(entityName as string);
        }       
      });
    // ---------------------------------------------------
    EmitterSubjectService.avatarProfileTileEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        // debugger;
        this.executeAvatarTasks(data as ProfileTile);        
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.heartbeatEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        //  debugger;
        const hbeat = result as Heartbeat;

        if (hbeat.sitUserId > 0) {
          this.loginSuccess.heartbeat = hbeat;
        }
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.isHomeEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        // debugger;
        this.isHome = result as boolean;
        this.redirectionService.routeToHomePage(this.router).subscribe(data => {
          this.pagerBrdCrm = data;
        });
      });

    // ---------------------------------------------------
    EmitterSubjectService.isSidebarOpenEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        // debugger;
        this.isSidebarOpen = data as boolean;        
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.isMyStuffOpenEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        // debugger;
        this.isMyStuffOpen = result as boolean;

      });
    // --------------------------------------------------------------------------
    EmitterSubjectService.isPhotoMgmtEmitter.subscribe((result) => {
      // debugger;
      this.isPhotoMgmt = result as boolean;
      this.loginSuccess = EmitterSubjectService.getLoginSuccess();
      this.sitUser = EmitterSubjectService.getSitUserModel();

      // turn off the pagerVGlyphMenuForViewMember:
      this.kvGlyphArrForViewMember = [];
      // debugger;
      this.ngAfterViewInit();
    });
    //  -------------------------------------------------------------------------
    // TODO: check to see if it is needed!!
    // --------------------------------------------------------------------------
    //EmitterSubjectService.isViewMemberEmitter.subscribe((result) => {
    //  // debugger;
    //  this.isViewMember = result as boolean;
    //  this.sitUser = EmitterSubjectService.getSitUserModel();
    //  this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    //  // debugger;
    //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.sitUser) && this.sitUser.sitUserId > 0
    //    && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.sitUser.sitUserId !== this.sitUser.sitUserId) {

    //    // This method also check if the sitUserId === signedInUserId, and if true, displays signedInUser's PrimaryGlyphsMenu:
    //    // EmitterSubjectService.emitDisplayViewMemberGlyphiconMenuForSitUser(this.sitUser.sitUserId); 
    //  }
    //  else {
    //    this.kvGlyphArrForViewMember = [];
    //  }
    //  this.ngAfterViewInit();
    //});
    //  -------------------------------------------------------------------------
    EmitterSubjectService.loadLandingPageEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        // debugger;
        this.redirectionService.routeToHomePage(this.router).subscribe(data => {
          this.pagerBrdCrm = data;
        })
      });
    //  -------------------------------------------------------------------------
    //  Begin: isMobileVar and loginSuccess/logout emitters!!
    //  -------------------------------------------------------------------------
    EmitterSubjectService.loginSuccessEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
       // debugger;
      this.loginSuccess = result;
      this.executeLoginSuccessTasks();
      this.executeAfterLoginSuccessTasks();
    });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.isLoginSuccessEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        // debugger;
        this.executeLoginSuccessTasks();
        this.executeAfterLoginSuccessTasks();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.logoutEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        // debugger;      
        this.loginSuccess = new LoginSuccess().resetThat(this.loginSuccess);
        EmitterSubjectService.setLoginSuccess(this.loginSuccess);
        this.logRegService.executeLogoutSuccessTasks();
        this.signedInUserId = 0;
        // debugger;
        this.isLoggedIn = false;
        this.isLoggedOutByUser = true;
        this.ngAfterViewInit();
    });

    //  -------------------------------------------------------------------------
    //  Begin of Message-Emitters:
    //  -------------------------------------------------------------------------
    //  -------------------------------------------------------------------------
    EmitterSubjectService.messageEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result : any) => {
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          // debugger;
          this.isMessageBoardVisible = true;
          if (result instanceof MessageModel) {
            this.messageModel = result;
            this.ngAfterViewInit();
            
          }
          else {
            this.messageModel.message = result as string;
            this.ngAfterViewInit();
            
          }
          this.timer = setTimeout(() => {
            // debugger;
            this.messageModel.message = '';
            this.isMessageBoardVisible = false;
            clearTimeout(this.timer);
            this.ngAfterViewInit();
          }, 20000);
        }
      });
    //  -------------------------------------------------------------------------
    // TODO: remove once the issue is resolved
    // NOTE: This is a temporary solution to an unsubscribe error
    //       We do not emit loginSuccess upon successful login from the LoginComponent
    //       We use this emitter to indicate that login is successful, and any subsequent
    //       computation that are necessary upon successful login is carried out here.
    // --------------------------------------------------------------------------
    EmitterSubjectService.clearMessageEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        // debugger;
        this.messageModel.message = '';
        this.ngAfterViewInit();
      });
      //  -------------------------------------------------------------------------
      //  End of Message-Emitters:
      //  -------------------------------------------------------------------------
    //  -------------------------------------------------------------------------
    //  End: isMobileVar and loginSuccess/logout emitters!!
    //  -------------------------------------------------------------------------
    EmitterSubjectService.myErrorLogEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        /*
         *  debugger;
         *  this.message = result as string;
         *  this.setMessageTimer();
         * this.logger.silly(result);
         * this.loggerTrace.info(result);
         * this.logEngineService.error(result);
         * this.message = result;
         * this.setMessageTimer();
         * this.errorLogService.log( result );
         */
      });
    // -------------------------------------------------------------------------  
    EmitterSubjectService.pageSizeEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe(result => {
        // debugger;
        this.pageSize = result as number;
        // get initial pager which willbe used to
        this.ngAfterViewInit();
        EmitterSubjectService.emitRunNgAfterViewInit(true);
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.pagerBreadcrumEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.pagerBrdCrm = result as PagerBreadcrum;
        this.breadCrmRngLength = this.setBreadCrumRingLength(this.pagerBrdCrm);
      });
    // --------------------------------------------------------------
    // Note:  1) This should not have to update any data. It's job is to
    //        render the data only.
    //        2) pagerVKvPhotoNav component is the driver/master of
    //          2.a) pagerVerticalGlyphForPhotoMgmt &
    //          2.b) pagerVerticalGlyphForViewMember,
    //        though all three are children of appComponent.
    //        Upon receiving data for pagerVKvPhotoNav, we compute and display 2.a) or 2.b)
    //        depending on isPhotoMgmt or isViewMember values.
    //        Note: the value of isPhotoMgmt (true/false) should have no effect on isViewMember.
    //  -------------------------------------------------------------------------
    EmitterSubjectService.refreshPageEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        //  debugger;
        this.ngAfterViewInit();
      });
    //  -------------------------------------------------------------------------
    EmitterSubjectService.returnUrlEmitter
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((result) => {
        this.returnUrl = result as string;
      });
    // -------------------------------------------------------------------------
    // Note: without this some children's images do not get rendered!!
    // -------------------------------------------------------------------------
    EmitterSubjectService.runNgAfterViewInitEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        // debugger;
        this.ngAfterViewInit();
      });
    // --------------------------------------------------------------
    // this selectedProfileEmitter will be emitted before even the
    // sitUser arrives from the server. But some values of the sitUser model
    // will be needed by other components and services.
    // Therefore we create a sitUser model from the profileTile model here
    // and save it to Emitter so that everyone can have accesss to this
    // sitUser.
    // Note: when the server's sitUser model arrives, it will replace this sitUser
    // --------------------------------------------------------------
    // TODO: remove  before deployment
    // --------------------------------------------------------------
    //EmitterSubjectService.selectedProfileTileEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe(result => {
    //    // debugger;
    //    this.selectedProfileTile = result as ProfileTile;

    //    // create a sitUser from selectedProfileTile:
    //    // ------------------------------------------
    //    this.sitUser = CopyServiceStatic.copyProfileTileToSitUser(this.selectedProfileTile);
    //    EmitterSubjectService.setSitUserModel(this.sitUser);
    //  });
    //  -----------------------------------------------------------------
    // Note: This emitter is to be used by any component that has a cancel-button.
    //       Because once cancelled, the user should go back to the previous page (if that is the desired behavior)
    //  -----------------------------------------------------------------
    EmitterSubjectService.setBreadcrumPageEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        debugger;
        // this.setBreadcrumPage( result );
      });
    //  -------------------------------------------------------------------------
    //    Begin of spinner-relaed-emitters:
    //  -------------------------------------------------------------------------
     EmitterSubjectService.showSpinnerEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result : any) => {
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          this.spinnerModel = result as SpinnerModel;
          this.spinnerModel.isSpinner = true;
          this.spinnerModel.isOpen = true;
          if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.spinnerModel.message) || this.spinnerModel.message.length === 0) {
            this.spinnerModel.message = "<span class=\'grnBluGldxS\'>Busy...</span>";
          }
          this.timer = setTimeout(() => {
            // debugger;
            this.spinnerModel.isSpinner = false;
            this.spinnerModel.isOpen = false;
            this.spinnerModel.message = '';
            clearTimeout(this.timer);
            this.ngAfterViewInit();
          }, 20000);
          this.ngAfterViewInit();
        }
      });
    //  -------------------------------------------------------------------------
     EmitterSubjectService.hideSpinnerEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result : any) => {  
        // debugger;
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(result.message)) {
            this.spinnerModel.message = result.message as string;
          }
          else this.spinnerModel.message = result;          
        }
        this.spinnerModel.isSpinner = false;
        this.spinnerModel.isOpen = false;

        this.timer = setTimeout(() => {
          // debugger;
          this.spinnerModel.isSpinner = false;
          this.spinnerModel.isOpen = false;
          this.spinnerModel.message = '';
          clearTimeout(this.timer);
          this.ngAfterViewInit();
        }, 30000);
        this.ngAfterViewInit();
      });
    
    //  -------------------------------------------------------------------------
    //    End of spinner-relaed-emitters:
    //  -------------------------------------------------------------------------
    

    /*
      *  =========================================================================
      *   Begin of PhotoLock/Unlock system
      *  =========================================================================
      */
      EmitterSubjectService.setupLockPhotos();
      this.kvPhotoLock = EmitterSubjectService.getPhotoLock();
      this.kvPhotoUnLock = EmitterSubjectService.getPhotoUnLock();
      //  --------------------------------------------------------
      this.kvPhotoPrivate = EmitterSubjectService.getPhotoPrivate();
      this.kvPhotoPublic = EmitterSubjectService.getPhotoPublic();
      //  --------------------------------------------------------
    EmitterSubjectService.photoLockUnLockEmitter
      .pipe( takeUntil( this.onDestroy$ ) )
      .subscribe( ( result ) =>
      {
        const kvPhoto = result as KvPhoto;

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto)) {
          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvPhoto.key)) {
            if (kvPhoto.key.toLowerCase().indexOf('tala') !== -1) {
              this.kvPhotoLock = kvPhoto;
            } else if (kvPhoto.key.toLowerCase().indexOf('unlock') !== -1) {
              this.kvPhotoUnLock = kvPhoto;
            } else if (kvPhoto.key.toLowerCase().indexOf('private') !== -1) {
              this.kvPhotoPrivate = kvPhoto;
            } else if (kvPhoto.key.toLowerCase().indexOf('public') !== -1) {
              this.kvPhotoPublic = kvPhoto;
            }
          }
        }
      });
      /*
       *  =========================================================================
       *   End of PhotoLock/Unlock system
       *  =========================================================================
       */
    // --------------------------------------------------------------------------
    // Note: We are using upgradedEmitter to emit LoginSuccess because emitting
    //        loginSuccess causes some errors.
    // --------------------------------------------------------------------------
    EmitterSubjectService.upgradedEmitter
      .pipe(takeUntil(this.emitterDestroyed$))
      .subscribe((result) => {
        debugger;
        this.loginSuccess = result as LoginSuccess; // JSON.parse(JSON.stringify(result));
        debugger;
        this.isLoggedIn = true;
        this.executeLoginSuccessTasks();
        this.ngAfterViewInit();
      });
    //  --------------------Completed on 2021/---------------------------------------------
    // debugger;
    this.checkLocalStogareForLoginSuccess();
    
    // -----------------------------------------------------------------------
    // RedirectionServiceStatic.appStartUrlStringParser(this.router);
    // -----------------------------------------------------------------------
    // debugger;  
     if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.cdr)) {
      this.cdr.detectChanges();
     }

    // -----------------------------------------------------------------------
    // Note: All the dictionaries are populated from inxedexDb if data exists:
    // -----------------------------------------------------------------------
    // --------------------------------------------------------------
    // populate the basic-dictionaries:
    // These methods are run afterLoginSuccess:
    // -------------------------------------------------
    // debugger;
    // this.dbDexieToDictionaryService.createAllDictionariesFromDbDexie();
    // this.dbDexieToDictionaryService.createAllMemberActivityDictionariesFromDbDexie();
  } // END of ngInit()

  //  ---------------------------------------------------------------------------------
  ngAfterViewInit () : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.returnUrl)) {
      EmitterSubjectService.setReturnUrl(this.returnUrl);
    };
    // NOTE: if the memberViewMgmt even does not display besides myProileViewMgmt, uncomment this:
    // -------------------------------------------------------------------------------------------
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.cdr)) {
      this.cdr.detectChanges();
    }
    // debugger;
    //this.timer = setTimeout(() => {
    //  debugger;
    //  this.messageModel.message = '';
    //  this.isMessageBoardVisible = false;
    //  clearTimeout(this.timer);
    //}, 20000);
    return true;
  }
  //  ---------------------------------------------------------------
  ngOnDestroy() : void {
    /*
     *  prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *         (2) https://www.concretepage.com/angular-2/angular-4-renderer2-example
     *         (3) https://stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *         (4) https://stackblitz.com/edit/timer-with-pdestroyed
     */
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.emitterDestroyed$.unsubscribe();

    clearInterval(this.pauseTimer);
    this.pauseMsgTimer();
    this.timerMap.forEach( ( timer ) => clearInterval( timer ) );
    this.isInizializationComplete = true;
    EmitterSubjectService.setIsInizializationComplete( true );
    this.isHome = false;
    // debugger;
  }
  /*
   *  ===============================================================
   *   NOTE: Important coding guidilines:
   *         1.  In components, all the component specific methods appear before this notification/guidilines.
   *         1.1 The variables should be in lexicographic order.
   *         1.2 The methods should be in also lexicographic order.
   *         1.3 ngOnDestroy() shold be the last method of component-specific-methods.
   *         1.4 Emitters should be placed in ngOnItnit() in lexicographic order.
   *         2. All observables should be placed right after the ngOnDestroy(), in lexicographic order.
   *         3. All other behavioral/otherwise custom methods appear below all observables in lexicographic order.
   *         4. Any specific 'functionality/behaviour' which requires multiple functions, can be groupd together with clearly
   *            delineated 'Begin and End' marker. Notice that this breaks the guideline#3.
   *            Therefore, the guideline#4 can be considered as an exception to the guideline#3
   *            inorder to make/facilitate the understanding of the 'functionality/behaviour'.
   *  ===============================================================
   */

 //  ===============================================================
 //   *********Begin of custom functions/other methods:********
 //  ===============================================================

  
  // ---------------------------------------------------------------
  /*
   *  ---------------------------------------------------------------
   *   Note: BEGIN of LogingSuccess-Salt/Unsalt-Process:
   *  ---------------------------------------------------------------
   */
  checkLocalStogareForLoginSuccess () : void
  {
    this.mcLoader = new ModuleComponentLoader( );
    // debugger;
    if ( this.localStorageService as LocalStorageService && !this.isLocalStorageProcessed) {
      // debugger;
      const localSaltyPairClient =  this.localStorageService.getLocalSaltyPair();
      // debugger;
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(localSaltyPairClient) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(localSaltyPairClient.in)) {
        // debugger;
        this.naclClient = new NaclPairClient();
        this.naclClient.secretKey = localSaltyPairClient.in;
        this.naclClient.publicKey = localSaltyPairClient.out;
        this.naclClient.id = localSaltyPairClient.id;
        // debugger;
         EmitterSubjectService.setSaltyPair(this.naclClient);


        const salt = new Salt();

        salt.publicKey = this.naclClient.publicKey;
        salt.secretKey = this.naclClient.secretKey;
          EmitterSubjectService.setSalty(salt);
       }
       // debugger;
       const localSaltedLS = (this.localStorageService as LocalStorageService).getLoginSuccess(); // TODO: Restore getLoginSuccess()
      /*
       *  debugger;
       *  -----------------------------------------------------------------------------------------
       *  Note: During Logout(), client-side salting is done on LogingSuccess model using (JavaScript) and stored in localStorage.
       *       To unslat a client-side salted model, it may not work.
       *  TODO: Test and try to unsalt client-side-salted-model LogingSuccess (JS salted => JS unsalted).
       *
       *  Note: After Login(), the salted LoginSuccess data (boxnonce) that is salted by the server(C#), is saved in the localStorage.
       *       and we unsalt it here using JS.
       *       Hence, without logout(), it is C# - salted => JS - unsalted process and
       *       With logout(), it is JS-salted => JS-unsalted process.
       *  -----------------------------------------------------------------------------------------
       */
      const localBoxNonce = new BoxNonceEntity();

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(localSaltedLS)) {
        localBoxNonce.box = localSaltedLS.box;
        localBoxNonce.nonce = localSaltedLS.nonce;
        // debugger;
        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( localBoxNonce )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( localBoxNonce.box )
          && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( localBoxNonce.nonce ) )
        {
          const unslatedLS = SlakezSaltServiceStatic.boxUnsalt(localBoxNonce);
          // debugger;

          if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unslatedLS)) {
            this.loginSuccess = JSON.parse(unslatedLS) as LoginSuccess;
            // debugger;
          }
        }
      }

      if ( ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.loginSuccess )
        || this.loginSuccess.signedInUserId === 0 )
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( localSaltedLS ) )
      {
        this.loginSuccess = this.unsaltFromCsSaltedToJsUnsalted(localSaltedLS);
         // debugger;
      }
      this.mcLoader = new ModuleComponentLoader();
      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess)) {
        if (this.loginSuccess.signedInUserId === 0) {
          // alert ('localStorage.loginSuccess failed!')
          this.mcLoader.moduleName = 'loginm';
          this.mcLoader.componentName = 'login';
        } else {
          // alert( 'localStorage.loginSuccess successful!' )
          // EmitterSubjectService.setupLockPhotos(); // TODO: place it outside of this method
          // debugger;

          this.loginSuccess.controller = 'memberscrollm';
          this.loginSuccess.action = 'memberscroll'; // TODO: check if '/' is required eg: '/members'

          this.mcLoader.moduleName = 'memberscrollm';
          this.mcLoader.componentName = 'memberscroll';

          this.isLocalStorageProcessed = true;
          this.loginSuccess = this.executeLoginSuccessTasks();
          EmitterSubjectService.emitLocalStorageLoginSuccess(this.loginSuccess);
          // debugger;

          this.mcLoader.router = this.router;
          // debugger;
          this.redirectionService.setRoute(this.router, this.mcLoader.componentName, this.mcLoader.id).subscribe(data => {
            this.pagerBrdCrm = data;
          });
        }
      }      
    }    
  }
  // ---------------------------------------------------------------
  //public createAllSitUserProfileDictionariesFromDbDexieDb () : void {
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('sitUserId') && this.dbDexieToDictionaryService.doesStoreHaveData('sitUserId')) {
  //    this.dbDexieToDictionaryService.createSitUserIdDictionaryFromDbDexie();
  //  }
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('sitUser') && this.dbDexieToDictionaryService.doesStoreHaveData('sitUser')) {
  //    this.dbDexieToDictionaryService.createSitUserDictionaryFromDbDexie();
  //  }
  //}
  // ---------------------------------------------------------------
  //public createAllProfileDictionariesFromDbDexieDb () : void {
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('kvPhoto') && this.dbDexieToDictionaryService.doesStoreHaveData('kvPhoto')) {
  //    this.dbDexieToDictionaryService.createKvPhotoDictionaryFromDbDexie();
  //  }
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('preference') && this.dbDexieToDictionaryService.doesStoreHaveData('preference')) {
  //    this.dbDexieToDictionaryService.createPreferenceDictionaryFromDbDexie();
  //  }
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('profileInfo') && this.dbDexieToDictionaryService.doesStoreHaveData('profileInfo')) {
  //    this.dbDexieToDictionaryService.createProfileInfoDictionaryFromDbDexie();
  //  }
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('profileTile') && this.dbDexieToDictionaryService.doesStoreHaveData('profileTile')) {
  //    this.dbDexieToDictionaryService.createProfileTileDictionaryFromDbDexie();
  //  }
  //}
  // ---------------------------------------------------------------
  //public createAllCommunicationDictionariesFromDbDexieDb () : void {
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('chat') && this.dbDexieToDictionaryService.doesStoreHaveData('chat')) {
  //    this.dbDexieToDictionaryService.createAllChatDictionariesFromDbDexie();
  //  }
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('emailTile') && this.dbDexieToDictionaryService.doesStoreHaveData('emailTile')) {
  //    this.dbDexieToDictionaryService.createAllEmailDictionariesFromDbDexie();
  //  }
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('enoteTile') && this.dbDexieToDictionaryService.doesStoreHaveData('enoteTile')) {
  //    this.dbDexieToDictionaryService.createAllEnoteDictionariesFromDbDexie();
  //  }
  //}
  // ---------------------------------------------------------------
  //public createAllDictionariesFromDbDexieDb () : void {
  //  this.createAllSitUserProfileDictionariesFromDbDexieDb();
  //  this.createAllProfileDictionariesFromDbDexieDb();
  //  this.createAllCommunicationDictionariesFromDbDexieDb();
  //  if (this.dbDexieToDictionaryService.isObjectStoreNameFoundInTables('memberActivity') && this.dbDexieToDictionaryService.doesStoreHaveData('memberActivity')) {
  //    this.dbDexieToDictionaryService.createAllMemberActivityDictionariesFromDbDexie();

  //    // this.dbDexieToDictionaryService.createPhotoDictionaryFromDbDexie();
  //    // this.dbDexieToDictionaryService.createPhotoBnDictionaryFromDbDexie();   
  //    // this.dbDexieToDictionaryService.createProfilePicsDictionaryFromDbDexie();    
  //  }
  //}
  
  //  ---------------------------------------------------------------
  unsaltFromCsSaltedToJsUnsalted(input: any): any {
    const localBoxNonce = new BoxNonceEntity();

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(input) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(input.box) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(input.nonce)) {
      localBoxNonce.box = input.box;
      localBoxNonce.nonce = input.nonce;
      // debugger;
      const unslatedLS = SlakezSaltServiceStatic.boxUnsalt(localBoxNonce);
      //  debugger;

      if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(unslatedLS)) {
        this.loginSuccess = JSON.parse(unslatedLS) as LoginSuccess;
        // debugger;
        return this.loginSuccess;
      }
    }
    return null as any;
  }
  //  ---------------------------------------------------------------
  unsaltFromJsSaltedToJsUnsalted(input: any) { }
  /*
   *  ---------------------------------------------------------------
   *   Note: END of LogingSuccess-Salt/Unsalt-Process:
   *  ---------------------------------------------------------------
   */
  
  // ----------------------------------------------------------------
  centerThisComponent ( id : string ) : void
  {
    if ( FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( id ) )
    {
      let componentElem = document.getElementById( id );
      this.componentViewportDimension = this.rendererService.getComponentViewport( id );
      let margin = 'calc( [ ( 100 - ' + this.componentViewportDimension.vw + ') /2]vh auto';
      this.renderer.setStyle( componentElem, 'width', this.componentViewportDimension.vw );
      this.renderer.setStyle( componentElem, 'height', this.componentViewportDimension.vh );
      this.renderer.setStyle(componentElem, 'margin', margin);
      componentElem.remove();
    }
    /*
     * @HostBinding( 'style.width' ) componentWidth: this.componentViewportDimension.vw;
    .centeredElem
    {
      width: --var(--anyHtmlElemWidth);
        height: --var( --anyHtmlElemHeight );
        margin: calc( [ ( 100 - --var( --anyHtmlElemWidth )) /2]vh auto;
      }
     */
  }
  //  ----------------------------------------------------------------------------------
  closeModule (name : string) : any {
    debugger;
    this.router.navigate([ { outlets: { name: null } } ]);
  }
  //  ---------------------------------------------------------------
  currentFocus (to: any) : any {
    EmitterSubjectService.emitCurrentFocus(to);
    return to;
  }
  //  ---------------------------------------------------------------
  public detectDevice () : void {
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
  // ----------------------------------------------------------------------------------
  executeAfterLoginSuccessTasks () : void {
    let result = EmitterSubjectService.getLoginSuccess();
    this.loginSuccess = result as LoginSuccess; //  JSON.parse(JSON.stringify(result));
    // debugger;
    if (this.loginSuccess.signedInUserId > 0) {
      if (this.isInitHeartbeat) {
        // debugger;
        this.startSinginInUserHeartbeat(); // Important!
        this.isInitHeartbeat = false;
      }     
    }
  }
  // ------------------------------------------------------------
  // Note:  This method shold be in a Service since it gets the
  //        heartbeat of any sitUser
  // ------------------------------------------------------------
  //executeHeartBeatTasks (sitId : number) : Promise<any> {
  //  // start the user's heartbeat: TODO: This was problematic. Fix is needed.
  //  // -----------------------------
  //  if (sitId > 0) {
  //    // debugger;
  //    //  Send a heartbeat:
  //    // ------------------
  //    // Note:  startSeningHeartbeat will save the returned heartbeat in loginSuccess and set the Emitter's loginSuccess
  //    return this.heartbeatService.startSendingHeartbeat(sitId, 'AppComponent.heartbeat');
  //  }
  //  else return this.nullPromise();
  //}
  // ---------------------------------------------------------------------------------
  executeAvatarTasks (data ?: ProfileTile) : any{
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(data)) {
      EmitterSubjectService.setAvatarProfileTile(this.profileTile);
      this.ngAfterViewInit();
      return true;
		}
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess) && this.loginSuccess.signedInUserId > 0) {
      this.profileTile = CopyServiceStatic.copyLoginSuccessToProfileTile(this.loginSuccess);
      // debugger;
      EmitterSubjectService.setAvatarProfileTile(this.profileTile);

      this.ngAfterViewInit();
      return true;
    }
    else return false;
	}
  // ---------------------------------------------------------------------------------
  public executeLoginSuccessTasks () : any {
    // debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();

    if (this.loginSuccess.signedInUserId > 0 && !this.isLoggedOutByUser) {
      this.isAuthorized = this.loginSuccess.isAuthorized;
      this.isEmployee = this.loginSuccess.isEmployee;
      this.isMgmt = this.loginSuccess.isMgmt;
      this.isPremium = this.loginSuccess.isPremium;
      this.isReviewer = this.loginSuccess.isReviewer;
      this.isSuperUser = this.loginSuccess.isSuperUser;
      this.isValued = this.loginSuccess.isValued;
      this.signedInUserId = this.loginSuccess.signedInUserId;
      // debugger;
      
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryImage)
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.loginSuccess.primaryPhotoBnJson)) {
        var pbn = this.photoBnService.unsaltPrimaryPicPhotoBnJson(JSON.parse(this.loginSuccess.primaryPhotoBnJson) as PhotoBn);
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pbn)) {
          this.loginSuccess.primaryImage = this.photoBnService.getPrimaryPicFromPhotoBn(pbn);
        // debugger;
				}
        
			}
      this.isOnLine = navigator.onLine;
      this.isLoggedIn = this.loginSuccess.signedInUserId > 0 ? true : false;
      this.isSuccess = this.loginSuccess.isSuccess;
      // debugger;

      EmitterSubjectService.setLoginSuccess(this.loginSuccess);

      // initliaitze signalR.hub:
      // ------------------------
      //this.signalRService.initialize().then(data => {
      //  this.hubConnection = data;
      //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.hubConnection)) {
      //    EmitterSubjectService.setHubConnection(this.hubConnection);
      //  }
      //})

      this.executeAvatarTasks(null);
      // debugger;
      // this.executeMyDictionaryTasks();
      // this.ngAfterViewInit();
      // debugger;

      // (this.bias as BackgroundImageAnimationService).startDefaultAnimation();
      // this.ngAfterViewInit();
      // this.heartbeatService.resetHeartbeat();
      // debugger;
      // debugger;

      /*
       *  if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
       *    hb = this.dictionaryService.heartbeatDictionary.getValue( this.loginSuccess.signedInUserId );
       *  }
       *  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
       *   this.loginSuccess.heartbeat =  hb;
       *  }
       *  debugger;
       */
    }

    // Note: populate all the indexedDb data into all dictionaries:
    // TODO: move this to a service worker that works as background process
    // ------------------------------------------------------------   
    // this.createAllDictionariesFromDbDexieDb();

    // this.ngAfterViewInit();
    return this.loginSuccess;
  }
  //  ---------------------------------------------------------------------------------
  // This method gets the all of my memberActivities:
  // To get the activities with respect to specific user, 
  // MemberTile call the prefetchMyMemberActivities() in ActivityService
  // ----------------------------------------------------------------------------------
  executeMyDictionaryTasks () : any {
    //  debugger;
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    if (this.loginSuccess.signedInUserId > 0) {
      // debugger;
      let myActivities : any;
      let myLockingActivities : any;
      let myTrkWnkStrActivities : any;

      // NOTE: though this methods gets the memberActivities, it does not have to return any obtained data to any other component/service.
      //       all the data upon arrival are sequestered into its respective dictionary and indexedDB
      // ---------------------------------------------------------------------------------------------------------------------------------
      // debugger;
      (this.activityService as ActivityService).getMyActivities(this.loginSuccess.signedInUserId, '').subscribe(data => {
        myActivities = data;
      });
      (this.activityService as ActivityService).getMyActivities(this.loginSuccess.signedInUserId, 'unlock').subscribe(data => {
        myLockingActivities = data;

      });
      (this.activityService as ActivityService).getMyActivities(this.loginSuccess.signedInUserId, 'wink').subscribe(data => {
        myTrkWnkStrActivities = data;

      });
      // debugger;
      return true;
    }
    //  TODO: check the necessity of this!!
    // ( this.createUserAlbumService as CreateUserAlbumService).setMemberViewGlyphMenu();
    else return false;
  }
  //  --------------------------------------------------------------
  public gestureHub(): string {
    return 'url(\'glyphicon-glyphicon-hand-up\')';
  }
  //  --------------------------------------------------------------
  public getUrl(): string {
    return 'url(\'assets/photos/glyph-members-transparent.png\')';
  }
  // --------------------------------------------------------------------------
  //  ---------------------------------------------------------------------------------
  public isMobile () : void
  {
    this.deviceInfo = this.detectDevice();
    this.isMobilevar = this.deviceInfo.isMobile();
  }
  //  ---------------------------------------------------------------------------------
  public isTablet () : void
  {
    this.deviceInfo = this.detectDevice();
    this.isTabletvar = this.deviceInfo.isTablet();
  }
  //  ---------------------------------------------------------------------------------
  public isDesktop () : void
  {
    this.deviceInfo = this.detectDevice();
    this.isDesktopvar = this.deviceInfo.isDesktop();
  }
  // -----------------------------------------------------------
  nullPromise () : any {
    this.timer = setTimeout(() => {
      // debugger;
    }, 500);

    clearTimeout(this.timer);
    if (this.timer && !this.timerMap.has(this.timer)) {
      this.timerMap.set(this.timer, this.timer);
    }
  }
  //  ---------------------------------------------------------------------------------
  public pauseMsgTimer(): any {
    clearInterval(this.intervalMsg);
    return true;
  }
  //  ---------------------------------------------------------------------------------
  public photoMgmt(value: boolean): any {
    return (this.isPhotoMgmt = value);
  }
  //  ---------------------------------------------------------------------------------
  public prepareRoute(outlet: RouterOutlet): any {
    const anim = 'animation';

    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(outlet)) {
      return outlet && outlet.activatedRouteData && outlet.activatedRouteData[anim];
    }
  }

  // ----------------------------------------------------------------
  registerSpinnerModal () {
    // this.modalService.registerModal( this.spinnerModalComponent );
  }
  //  ---------------------------------------------------------------
  removeChildren () : any {
    let i = 0;

    for (const item of this.activatedRoute.children) {
      this.activatedRoute.children.splice(i, 1);
      i++;
    }
    return this.activatedRoute;
  }
  //  ---------------------------------------------------------------------------------
  public reRender(): any {
    /*
     * Re-rendering the view on changeDetection:
     *  ref:https:// stackoverflow.com/questions/50383003/how-to-re-render-a-component-manually-angular-5
     */
    this.timer = setTimeout(() => {
      this.numberOfTicks++;
      // the following is required, otherwise the view will not be updated
      this.cdr.markForCheck();

      //  this.setBreadcrumForPageSwitch();
      console.log('re-rendering-timer-Ticks: ' + this.numberOfTicks);
    }, 1000);

    clearTimeout(this.timer);

    this.timerMap.set(this.timer, this.timer);
    return true;
  } 
  // ----------------------------------------------------- 
  //  Login-Register-Nav-Header-Buttons-Management-System:
  // -----------------------------------------------------
  resetLogRegBttns () {
    // debugger;
   //  EmitterSubjectService.emitMessage('Render-Register-Login-Buttons');
    EmitterSubjectService.emitSetSignedInUserBackgroundImage(true);
  }
  // ---------------------------------------------------------------------------
  setBreadCrumRingLength (pagerBrdCrm : PagerBreadcrum) : any {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerBrdCrm)
      && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(pagerBrdCrm.breadcrumRing)
      && this.pagerBrdCrm?.breadcrumRing?.length > 0) {
      this.breadCrmRngLength = pagerBrdCrm?.breadcrumRing?.length;
    }
    else this.breadCrmRngLength = 0;

    return this.breadCrmRngLength;
  }
  //  ------------------------Completed on 2021/04/---------------------------------------
  setBreadcrumPage (direction : string) : any {
    // debugger;
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(direction)) {
      this.pagerBrdCrm = BreadcrumServiceStatic.setBreadcrumPage(direction, this.router);
      this.breadCrmRngLength = this.setBreadCrumRingLength(this.pagerBrdCrm);
    }
    return true;
  }
  // --------------------------------------------------------------
  public setRoute (route : string, id : number) : void {
    // debugger;
    this.redirectionService.setRoute(this.router, route, id).subscribe(data => {
      this.pagerBrdCrm = data;
    });

  }
  // ---------------------------------------------------------------------------------
  startSinginInUserHeartbeat () : void {
    if (this.loginSuccess.signedInUserId > 0) {

      this.heartbeatService.startSendingHeartbeat(this.loginSuccess.signedInUserId, 'AppComponent').subscribe(hb => {
        // debugger;
        this.loginSuccess = EmitterSubjectService.getLoginSuccess();

        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(hb)) {
          if (hb.sitUserId === this.loginSuccess.signedInUserId) {
            // this.hb.isOnline = navigator.onLine;
            // hb.prevHeartbeat = hb.heartbeatTime;
            EmitterSubjectService.emitComputeDistance(hb);
            this.loginSuccess.heartbeat = hb;
          }
          // debugger;
        }
        // TODO: see if emitting this will help update relevant values or will it create a cycle?
        EmitterSubjectService.setLoginSuccess(this.loginSuccess);
      });
    }
  }
  //  ----------------------------------------------------------------------------------
  updateApp(): any {
    document.location.reload();
    console.log('The app is updating right now');
    return true;
  }
  // -----------------------------------------------------------------------------------
  // --------------------------------------------------------------
  //  Begin of hammer/gesture recognition methods:
  // --------------------------------------------------------------
  // gestureRecognizer (event : Event) : any {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.routedElementId)) {
  //    HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  }
  // }
  // onSwipe (event : Event) : void {
  //  this.gestureRecognizer(event);
  // }
  // onSwipeLeft (event : Event) : void {  
  //  this.gestureRecognizer(event);
  // }
  // onSwipeRight (event : Event) : void {
  //  this.gestureRecognizer(event);
  // }
  // onSwipeUp (event : Event) : void {
  //  this.gestureRecognizer(event);
  // }
  // onSwipeDown (event : Event) : void {
  //  this.gestureRecognizer(event);
  // }
  onSwipeDown (event : Event) : void {
    // HammerServiceStatic.hammeredElement('wrapper', event);
    // HammerServiceStatic.hammeredElement('bodyId', event); page-content-wrapper
    HammerServiceStatic.hammeredElement('appRootId', event, this.router);
  }
  // --------------------------------------------------------------
  //onPan (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPanStart (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPanEnd (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPanMove (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPanCancel (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPanLeft (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPanRight (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPanUp (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPanDown (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onTap (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.routedElementId)) {
  //    HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  }
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  //onPress (event : Event) : void {
  //  this.routedElementId = this.getRoutedElementId(this.document);
  //  if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.routedElementId)) {
  //    HammerServiceStatic.hammeredElement(this.routedElementId, event);
  //  }
  //  // HammerServiceStatic.hammeredElement('bodyId');
  //}
  // --------------------------------------------------------------
  //  End of hammer/gesture recognition methods:
  // --------------------------------------------------------------

  // ===================================================================================
  //   *********End of custom functions/other methods:********
  // ===================================================================================
}
// ===============================END OF CLASS==========================================




// =======================================================================================

