
import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

// import { } from 'zone.js';


@Injectable( {
  providedIn: 'root',
} )
export class SWUpdateService
{
  public isEnabled = false; 
  //  ---------------------------------------------------------------
  
  constructor (
    public appRef : ApplicationRef,
    public swUpdates : SwUpdate) {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.

    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    // everySixHoursOnceAppIsStable$.subscribe(() => this.swUpdates.checkForUpdate());
  }

  //----------------------------------------------------------------
   //ngOnDestroy () : void
   //{
   // this.emitterDestroyed$.next( true );
   // this.emitterDestroyed$.complete();
   // // RunOutsideAngularServiceStatic.timerArray.forEach( ( timer ) => clearInterval( timer ) );
   //}

  // ---------------------------------------------------------------------------------
  public forceSwUpdateActivation () : void
  {
    this.swUpdates.available.subscribe(event => {
      if (confirm('A newer version of the application is available. Load the new version ?')){
        this.swUpdates.activateUpdate().then(() => document.location.reload());
      }
    });
  }
  
  // ---------------------------------------------------------------------------------
  public setIsEnabled (isEnabled : boolean) : void {
    this.isEnabled = isEnabled;
  }
  
  // ---------------------------------------------------------------------------------
}

