import { LoginSuccess } from "./loginSuccess.model";
import { Login } from "./login.model";
import { Register } from "./register.model";

export class LogRegModel {
  public login : Login = new Login();
  public register : Register = new Register(); // real-password holder
  public loginSuccess : LoginSuccess = new LoginSuccess();
  public isAllowed = false;
  public isEmailValid = false;
  public isFormValid = false;
  public isPasswordValid = false;
  
  public sitUserId : any = 0;
  public date : any = '';
  public jsDateForCs : any = '';
  constructor() { }
}
