<!--to cover the whole page-->
<div *ngIf="getSpinnerLoading()" class="spin.wrapper">
	<div class="overlay" *ngIf="showSpinner">
		<div class="spinner-wrapper">
			<app-spinner [spinnerModel]="spinnerModel"></app-spinner>
		</div>
	</div>

	<div class="loaded-content" [class.blurred]="showSpinner">
		<ng-content></ng-content>
	</div>
</div>

