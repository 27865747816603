
import { Injectable, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { deserialize, serialize } from '@ungap/structured-clone'; // for native deep-clone
import { KV } from '../../../models/keyValue/kv.model';
import { MemberViewMgmtModel } from '../../../models/member/memberViewMgmtModel.model';
import { HttpService } from '../../coreServiceService/httpService.service';
import { PhotoPrivacyService } from '../../photoServiceService/photoPrivacyService.service';
import { decode, encode } from 'sourcemap-codec';
// var Buffer = require('buffer/').Buffer;  // note: the trailing slash is important!
import * as nacl from 'tweetnacl';
import * as naclUtil from 'tweetnacl-util';

//
/*
 * fetchAsBlob(`https:// fonts.gstatic.com/s/roboto/v16/d-6IYplOFocCacKzxwXSOJBw1xU1rKptJj_0jans920.woff2`)
 *   .then(convertBlobToBase64)
 *   .then(console.log)
 * -------------------------------------------------------------------
 * ------------------------------------------------------------------
 * ref:https:// stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript
 * encode Uint8Array to string, and decode string to Uint8Array.
 */
// const base_64 = {
//  decode: (s) => Uint8Array.from(atob(s), (c) => c.charCodeAt(0)),
//  encode: (b) => btoa(String.fromCharCode(...new Uint8Array(b))),
// };
//// for Node.js
// const base_64_njs = {
//  decode: (s) => Buffer.from(s, 'base64'),
//  encode: (b) => Buffer.from(b).toString('base64'),
// };

// -------------------------------------------------------------------
@Injectable({ providedIn: 'root' })
// This is based on the lib clone ( as promulgated by Dan Wahlin)
export abstract class FrequentlyUsedFunctionsServiceStatic {
  static httpService : HttpService;  
  static photoPrivacyService : PhotoPrivacyService;

  private emitterDestroyed$ : Subject<boolean> = new Subject();
  @Output() static memberViewMgmtModelEmitter : Subject<MemberViewMgmtModel> = new Subject();
   //  -------------------------------------------------------------------------
  constructor (nacl, naclUtil
  ) {
    nacl.util = require('tweetnacl-util');
    // this.initialize();
    // debugger;
    // EmitterSubjectService.toSetMemberViewMgmtModelEmitter
    //  .pipe(takeUntil(this.emitterDestroyed$))
    //  .subscribe((result) => {
    //     debugger;
    //    EmitterSubjectService.emitMemberViewMgmtModel(result);;
    //  });  
  }
  // -------------------------------------------------------------------------
  // private initialize (): void {
  //  FrequentlyUsedFunctionsServiceStatic.httpService = new HttpService(this.httpClient, this.router);
  //  FrequentlyUsedFunctionsServiceStatic.photoPrivacyService = new PhotoPrivacyService(FrequentlyUsedFunctionsServiceStatic.httpService);
  // }
  // --------------------------------------------------------------
  static nullPromise () : any {
    let timer = setTimeout(() => {
      // debugger;
      clearTimeout(timer);      
      return null;
    }, 500);
    clearTimeout(timer);
  }
  // ---------------------------------------------------------------
  static emitMemberViewMgmtModel (model : MemberViewMgmtModel) : void {
    this.memberViewMgmtModelEmitter.next(model);
  }
  //  -------------------------------------------------------------------------
  static getUserNameFromEmail ( email: string ): string
  {
    if ( !this.isNullOrEmpty( email ) )
    {
      let parts = email.split( '@' );
      if ( parts.length > 0 )
      { return parts[ 0 ]; }
      else return email;
    }
    else return email;
  }
  //  -------------------------------------------------------------------------
  static reverse (str : any): any {
    return str.split('').reverse().join('');
  }
  // --------------------------------------------
  static reverseES6 (str : any): any {
    return [...str].reverse().join('');
  }
  // --------------------------------------------
  static reverseUsingReduce (str : any): any {
    return str.split('').reduce((rev : any, char : any) => char + rev, '');
  }
  // --------------------------------------------
  static reverseUsingRecursion (str : any): any {
    return str ? this.reverseUsingRecursion(str.substr(1)) + str[0] : str;
  }
  // --------------------------------------------
  static reverseUsingForLoop (str : any): any {
    let reversed = '';

    for (const char of str) {
      reversed = char + reversed;
    }
    return reversed;
  }
  /*
   * --------------------------------------------
   * Note: Tested on 2020/12/20. Works!
   * --------------------------------------------
   */
  static reverseOnItself(inArr: any[]): any[] {
    const len = inArr.length;
    let middleIndex = len / 2;
    let temp: any;

    if (len % 2 === 0) {
      // even-case
      for (let i = 0; i <= middleIndex; i++) {
        temp = inArr[i];
        inArr[i] = inArr[len - i - 1];
        inArr[len - i - 1] = temp;
      }
    } else if (len % 2 === 1) {
      // odd-case
      const middleV = inArr[len / 2];

      middleIndex = len / 2;
      for (let i = 0; i <= middleIndex || inArr[i] === middleV; i++) {
        // swap up to middle or  middleValue
        temp = inArr[i];
        inArr[i] = inArr[len - i - 1];
        inArr[len - i - 1] = temp;
      }
    }
    return inArr;
  }
  /*
   * ---------------------------------------------------------------------------------
   * ref: https://www.javacodeexamples.com/convert-first-character-of-string-to-lowercase-in-java-example/636
   * Note: This method does not work:( tested on 20220422
   *       if it cannot be fixed, delete this.
   * ---------------------------------------------------------------------------------
   */
  static firstCharToLowerCase (str : string) : string {
    if (str === null || str.length === 0) {
      return '';
    }
    if (str.length === 1) {
      return str.toLowerCase();
    }
    else {
      return str.substring(0, 1).toLowerCase() + str.substring(1);
    }
  }
  /*
   * --------------------------------------------------------------
   * Testing the ArrayReverseOnItsef:
   * Note: Tested, works! 2020/12/20
   * --------------------------------------------------------------
   */
  static testReverseOnItself(inArray: any[]): any {
    let arrStr = '';

    for (let i = 0; i < 11; i++) {
      inArray.push(i);
      arrStr += i + ', ';
    }
    const revArr = this.reverseOnItself(inArray); // Tested and works!
    let revArrStr = '';

    if (!this.isNullOrEmpty(revArr)) {
      for (let i = 0; i < 11; i++) {
        revArrStr += revArr[i] + ', ';
      }
    }
    alert('in-Array: ' + arrStr + '\n reversed-Array: ' + revArrStr);
  }
  /*
   * --------------------------------------------------------------
   * Note: END of REVERSE-string-methods:
   * --------------------------------------------------------------
   */

  // ---------------------------------------------------------------
  // Deep-Clone without _lodash:
  static deepCloneJson (obj : any) : any {
    return JSON.parse(JSON.stringify(obj));
  }
  //  --------------------------------------------------------------
  // Native-JS-deep-clone:
  // import structuredClone from '@ungap/structured-clone'; // for native deep-clone
  static deepClone (obj : any) : any {
    // return structuredClone(obj);
    // the result can be stringified as JSON without issues
    // even if there is recursive data, bigint values,
    // typed arrays, and so on
    // const serialized = serialize({ any: 'serializable' });
    const serialized = serialize(obj);

    // the result will be a replica of the original object
    const deserialized = deserialize(serialized);
    return deserialized;
  }
  //  --------------------------------------------------------------

  /*
   * --------------------------------------------------------------
   * Dan Bernstein’s popular “times 33” hash function in TypeScript
   * Author: Sayeed Rahman
   * Date: November 19, 2020
   * Location: Ottawa, Canada
   *
   * TODO: test.
   * --------------------------------------------------------------
   */
  /*
   * times33Hash(str: string): number {
   * let h = 5381;
   * for (let i = 0, j = str.length; i < j; i++)
   * {
   *  //Shifting h left by 5 bits is a quick way to multiply by 32
   *  h += (h << 5) + parseInt(str[i], 10);
   *  //Only keep the lower 32 bits of h
   *  h = h & 0xFFFFFFFF;
   * }
   * //debugger;
   * return h;
   * }
   */
  
  // --------------------------------------------------------------
  static getCircularReplacer = () =>
  {
    const seen = new WeakSet();
    return (key : any, value : any ) =>
    {
      if ( typeof value === 'object' && value !== null )
      {
        if ( seen.has( value ) )
        {
          return;
        }
        seen.add( value );
      }
      return value;
    };
  }

  // problem: JSON.stringify(circularReference);
              // TypeError: cyclic object value
  // solution:
  // usage: JSON.stringify(circularReference, getCircularReplacer());
  // ---------------------------------------------------------------------------------
  public static firstIndexOf(input: string, pattern: string): any {
    if (!this.isNullOrEmpty(input)) {
      return input.indexOf(pattern);
    }
  }
  // ---------------------------------------------------------------------------------
  public static trimBothQuotes(input: string): any {
    if (!this.isNullOrEmpty(input)) {
      let temp = input.trim().replace('"', '');

      if (!this.isNullOrEmpty(temp)) {
        temp = temp.trim().replace('\'', '');
      }
      return temp;
    }
    return input;
  }
  // ---------------------------------------------------------------------------------
  public static trimSingleQuotes (input : string) : any {
    if (!this.isNullOrEmpty(input)) {
      let pattern = /\'/gi;
      let replacement = '';
     let temp = input.trim().replace(pattern, replacement);
      return temp;
    }
    else return input;
  }
  // ---------------------------------------------------------------------------------
  public static trimDoubleQuotes (input : string) : any {
    if (!this.isNullOrEmpty(input)) {
      let temp = input.trim().replace('"', '');
      return temp;
    }
    return input;
  }
  // ---------------------------------------------------------------------------------
  // Ref: https://stackoverflow.com/questions/2970525/converting-any-string-into-camel-case
  //
  // Note:  CAUTION!!!:
  //        this method stripped/deleted all dividers of < kay: value > paired JSON string of the `Photo` model.
  //        this is not a desirable outcome :( !
  //        this may NOT be good for a JSON string of any model!!!
  // ---------------------------------------------------------------------------------
  public static toCamelCaseNotUsingLodash (str:string): string {
     //return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
     // return index === 0 ? word.toLowerCase() : word.toUpperCase();
     //}).replace(/\s+/g, '');

    // OR using lodash
    // return _lodash.camelCase(str);

    // OR
     if (!this.isNullOrEmpty(str)) {
      return str.toLowerCase().replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) => idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()).replace(/\s+/g, '');
     }
     else return '';
  }
  // ---------------------------------------------------------------------------------
  // ref: https://www.codegrepper.com/code-examples/javascript/lowercase+first+character+javascript
  // ---------------------------------------------------------------------------------
  public static capitalizeFirstLetter (string : any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  // ---------------------------------------------------------------------------------
  // ref: https://www.codegrepper.com/code-examples/javascript/lowercase+first+character+javascript
  //  Note: Tested & works! on 20220423
  // ---------------------------------------------------------------------------------
  public static lowercaseFirstLetter (str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }
  
  
  // ---------------------------------------------------------------------------------
  public static toPascalCase (str: string): string {
    ;// TODO
    return '';
  }
  
  // ----------------------------------------------------------------
  static setRawImageData(rawImageData: string): string {
    // debugger;
    if (!this.isNullOrEmpty(rawImageData)) {
      if (rawImageData.indexOf('data:image/png/svg;base64,') === -1) {
        // check if it has the prefix-of-raw-image-data
        return 'data:image/png/svg;base64,' + rawImageData;
      }
    }
    return rawImageData;
  }
  // --------------------------------------------------------------
  static isNullOrEmpty ( input : any ) : boolean
  {
    if (input === null || input === '' || input === undefined || (input !== null && (input.toString().toLowerCase() === 'null' || input.toString().toLowerCase() === 'undefined'))) {
      return true;
    }
    return false;
  }
  // ---------------------------------------------------------------
  static createRegExpFromString (input : string) : any {
    if (typeof input !== 'string') {
      return 0;
    }
    input = (input) === '.' ? ('\\' + input) : input;
    debugger;
    const regexp = new RegExp(input, 'g');
    return regexp;
  }
  // ---------------------------------------------------------------
  //  Tested on 20220324 and it works!
  // ---------------------------------------------------------------
  static countCharInString (charRegExp : RegExp, strToSearch : string) : number {
    // debugger;
    return ((strToSearch || '').match(charRegExp) || []).length;

  }
  // ---------------------------------------------------------------
  static string2Unit8Arr(s: string): any {
    return Uint8Array.from(atob(this.strTobase64(s)), (c) => c.charCodeAt(0));
  }
  static unit8Arr2Str(b: []): any {
    return btoa(String.fromCharCode(...new Uint8Array(b)));
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/4456336/finding-variable-type-in-javascript
   * ---------------------------------------------------------------
   */
  static typeCheck(obj: any): any {
    if (!this.isNullOrEmpty(obj)) {
      // debugger;
      switch (typeof obj) {
        // object prototypes
        case 'undefined':
          return 'Undefined';
        case 'boolean':
          return 'Boolean';
        case 'number':
          return 'Number';
        case 'string':
          return 'String';
        case 'object':
          if (obj instanceof Array) {
            return 'Array';
          }
          else if (obj instanceof String) {
            return 'String';
          }
          else if (obj instanceof Number) {
            return 'Number';
          }
          else if (obj instanceof Date) {
            return 'Date';
          }                        
          else if (obj instanceof Object) {
            return 'Object';
          }
          else if (obj instanceof RegExp) {
            return 'RegExp';
          }
          else if (obj instanceof Function) {
            return 'Function';
          }
          else if (obj instanceof Symbol) {
            return 'Symbol';
          }
          else return 'Object';
        // object literals
        default:
          return typeof obj;
      }
    }
  }
  // ---------------------------------------------------------------
  static ConvertStringToNumber(inStr: string): number {
    if (!this.isNullOrEmpty(inStr)) {
      return parseInt(inStr, 10);
    }
    return -1;
  }
  
  // ------------------------------------------------------------------------
  static getMonthName (month : any): any {
    if (month > 0) {
      switch (month) {
        case 1:
          return 'January';
        case 2:
          return 'February';
        case 3:
          return 'March';
        case 4:
          return 'April';
        case 5:
          return 'May';
        case 6:
          return 'June';
        case 7:
          return 'July';
        case 8:
          return 'August';
        case 9:
          return 'September';
        case 10:
          return 'October';
        case 11:
          return 'November';
        case 12:
          return 'December';
        default:
          return '';
      }
    }
    return '';
  }
  // -------------------------------------------------------------
  public mimeTypes : KV[] = [];
  public jsTypes = [ 'Boolean', 'BigInt', 'Function', 'NaN', 'Null', 'Number', 'Object', 'object', 'String', 'Symbol', 'Undefined' ];
  public Sys = {};
  // -------------------------------------------------------------
  static createMimeType () : any {
    let mimeTypes : any[] = [];
    let kv = new KV();

    kv.key = '.txt';
    kv.value = 'text/plain';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.pdf';
    kv.value = 'application/pdf';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.doc';
    kv.value = 'application/vnd.ms-word';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.docx';
    kv.value = 'application/vnd.ms-word';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.xls';
    kv.value = 'application/vnd.ms-excel';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.xlsx';
    kv.value = 'application/vnd.openxmlformats  officedocument.spreadsheetml.sheet';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.png';
    kv.value = 'image/png';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.jpg';
    kv.value = 'image/jpeg';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.jpeg';
    kv.value = 'image/jpeg';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.gif';
    kv.value = 'image/gif';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.csv';
    kv.value = 'text/csv';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.css';
    kv.value = 'stylesheet';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.js';
    kv.value = 'javascript';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.html';
    kv.value = 'text/html';
    mimeTypes.push(kv);
    kv = new KV();
    kv.key = '.xml';
    kv.value = 'text/xml';
    mimeTypes.push(kv);
    return mimeTypes;
  }
  // -------------------------------------------------------------
  static getMimeType (typ : string) : any {
    // debugger;
    let type = '';

    if (typ && typ.length >= 3) {
      let typArr : any[] = [];

      if (typ.indexOf('/') !== -1) {
        typArr = typ.split('/'); // incase mime-type is provided
      } else if (typ.indexOf('.') !== -1) {
        typArr = typ.split('.'); // incase file-name-with-extension is provided
      } else {
        typ = '.' + typ; // incase plain-file-extension is provided (without a '.'), create a file-extension with a '.'
      }
      if (typArr.length > 1) {
        type = '.' + typArr[ 1 ]; // create a file-extension with a '.'
      }
    }
    return this.createMimeType().mimeTypes.find((k: any) => k?.key === type).value; // TODO: restore
    // debugger;
    // return ''; // 
  }
  // -------------------------------------------------------------
  static generateFileType (fileExtension : any): any {
    let fileType : any = { };

    switch (fileExtension.toLowerCase()) {
      case 'doc':
      case 'docx':
        fileType = 'application/msword';
        break;
      case 'html':
        return 'text/html';
      case 'xls':
      case 'xlsx':
        fileType = 'application/vnd.ms-excel';
        break;
      case 'pps':
      case 'ppt':
        fileType = 'application/vnd.ms-powerpoint';
        break;
      case 'txt':
        fileType = 'text/plain';
        break;
      case 'rtf':
        fileType = 'application/rtf';
        break;
      case 'pdf':
        fileType = 'application/pdf';
        break;
      case 'msg':
      case 'eml':
        fileType = 'application/vnd.ms-outlook';
        break;
      case 'gif':
      case 'bmp':
      case 'png':
      case 'jpg':
        fileType = 'image/JPEG';
        break;
      case 'dwg':
        fileType = 'application/acad';
        break;
      case 'zip':
        fileType = 'application/x-zip-compressed';
        break;
      case 'rar':
        fileType = 'application/x-rar-compressed';
        break;
    }
    return fileType;
  }
  /*
   * --------------------------------------------------------------
   * Begin of linebreak
   * --------------------------------------------------------------
   */
  static lineBreak(): any {
    return this.filterFilter;
  }
  static filterFilter (text : any): any {
    if (!text || !text.length) {
      return text;
    }

    return text.replace(/(\\r\\n)|([\r\n])/gim, '<br/>');
  }
  /*
   * --------------------------------------------------------------
   * End of linebreak
   * --------------------------------------------------------------
   * --------------------------------------------------------------
   * ref: https:// developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
   * TODO: test
   */
 

  /*
   * -------------------------------------------------------------
   * Note: a similar method exists in copyService.ts//copyFromProfilePicsToKvPhotoDictionary
   * ---------------------------------------------------------------
   * profilePicsToKvPhotoDictionary(profilePics: ProfilePics): KvPhoto[]{}
   */

  
  // -------------------------------------------------------------
  static toByteArray(hexString: string): any {
    const result : any = [];

    for (let i = 0; i < hexString.length; i += 2) {
      // result.push(parseInt(hexString.substr(i, 2), 16)); //TODO: resotre
    }
    return result;
  }
  /*
   * ------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript
   * string-2-b64, and decode b64-2-string
   */
  static strTobase64 (str : any): any {
    return btoa(unescape(encodeURIComponent(str)));
  }
  static base64ToStr (b64Str : any): any {
    return decodeURIComponent(escape(window.atob(b64Str)));
  }
  /*
   * ------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/246801/how-can-you-encode-a-string-to-base64-in-javascript
   * b64EncodeUnicode(str): any {
   *  // returnbtoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
   *  // returnString.fromCharCode('0x' + p1); //TODO: test by (parseInt('0x' + p1))
   *  // }));
   * }
   * Usage example:
   * b64EncodeUnicode('✓ à la mode');  //'4pyTIMOgIGxhIG1vZGU='
   * b64EncodeUnicode('\n'); //'Cg=='
   */

  static b64DecodeUnicode (str : any): any {
    return decodeURIComponent(Array.prototype.map.call(atob(str), (c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
  }
  /*
   * Usage example:
   * b64DecodeUnicode('4pyTIMOgIGxhIG1vZGU='); //'✓ à la mode'
   * b64DecodeUnicode('Cg=='); //'\n'
   * ==================================================================
   * Note: this is incomplete!!
   */
  // static stringToBase64(str): any {
  //   if (!this.isNullOrEmpty(str)) {
  //     return str;
  //   }
  // }
  // ------------------------------------------------------------------
  
  /*
   * ------------------------------------------------------------------
   * ==================================================================
   * String-to-ArrayBuffer-to-String
   * ==================================================================
   * NOTE: Use these two only for this purpose!
   * Tested. Works without stackoverflow exception!
   * ******************************************************************
   * encode(buffer, byteOffset, length) - Encodes ArrayBuffer into base64 string
   * ------------------------------------------------------------------
   * ref:https:// www.npmjs.com/package/base64-arraybuffer-es6
   */
  static arrBufferToB64 (buff : Uint8Array): any {
   // return encode(buff);
   return naclUtil.encodeBase64(buff);
  }
  static b64ToArrBuff (b64Str : any): any {
    return decode(b64Str);
  }
  // ------------------------------------------------------------------
  static angArrBufferToB64 (arrBuff : Uint8Array) : any {
    // This step is only necessary if you don't already have a Buffer Object
    // Also, If you are running under Angular for example, the Buffer Class will also be made available in a Browser Environment.
    // const buffer : any = Buffer.from(arrBuff);
    const buffer : any = new Buffer(arrBuff);

    const base64String = buffer.toString('base64');

    return base64String;
  }
  // ------------------------------------------------------------------
  static readFile (file : any, onLoadCallback : any): any {
    const reader = new FileReader();

    reader.onload = onLoadCallback;
    reader.readAsText(file);
    return true;
  }
  /*
   * ------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/37234333/arraybuffer-to-string-string-to-arraybuffer-methods
   */
  static stringToArrayBuffer (str : any, enc : any, callback : any): any {
    const blob = new Blob([str], { type: 'text/plain;charset=' + enc});
    const reader = new FileReader();

    reader.onload = ( evt ) =>
    {
      if ( this.isNullOrEmpty( evt ) )
      {
         // callback( evt.target.result ); // TODO : restore
      }
    };
    reader.readAsArrayBuffer(blob);
    return true;
  }

  /*
   * Usage example:
   * var buf = new Uint8Array([65, 66, 67]);
   * arrayBufferToString(buf, 'UTF-8', console.log.bind(console)); //'ABC'
   */

  /*
   * stringToArrayBuffer('ABC', 'UTF-8', console.log.bind(console)); //[65,66,67]
   * ------------------------------------------------------------------
   * ref:https:// www.npmjs.com/package/arraybuffer-to-string
   * Tested: works!
   * Note:: Throws Maximum Call Stack exceeded on ArrayBufferToString
   * ------------------------------------------------------------------
   */
  //static ab2stBase64 (u8Arr : any[], type? : any) : any {
  //  let typ : any = 'utf8'
  //  let tByff = new Buffer(u8Arr, this.isNullOrEmpty(type) ? typ : type);
  //  return tByff.toString()// ab2str(u8arr, 'base64');
  //}
  //static ab2str (u8arr : any) : any {
  //  const result = this.ab2stBase64(u8arr, 'utf8');
  //  // debugger;

  //  return result;
  //}
 
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/23024460/javascript-i-created-a-blob-from-a-string-how-do-i-get-the-string-back-out
   */
  static blobToString(b: any): any {
    let u;
    let x;

    u = URL.createObjectURL(b);
    x = new XMLHttpRequest();
    x.open('GET', u, false); // although sync, you're not fetching over internet
    x.send();
    URL.revokeObjectURL(u);
    return x.responseText;
  }
 
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// stackoverflow.com/questions/18650168/convert-blob-to-base64
   */
  static BlobToBase64 (blob : any): Promise<any> {
    const reader = new FileReader();

    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  }
  /*
   * // Usage Example:
   *BlobToBase64(blobData).then(res => {
   *   //do what you wanna do
   *   console.log(res); //res is base64 now
   * });
   */
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// blobfolio.com/2019/10/better-binary-batter-mixing-base64-and-uint8array/
   */
  /**
   * Base64 to Blob
   *
   * @param { string} data Data.
   * @param { string} type Content type.
   * @return { !Blob} Blob.
   */
  static base64toBlob (data : any, typ : any): any {
    const bytes = atob(btoa(data));
    let length = bytes.length;

    if (length > 0) {
      const out = new Uint8Array(length);

      // Loop and convert.
      while (length > 0 && length--) {
        out[length] = bytes.charCodeAt(length);
      }

      return new Blob([out], { type: typ});
    }
  }
  // ------------------------------------------------------------------------------------------------
  static base64toUint8Array (data : any): any {
    const bytes = atob(data);
    let length = bytes.length;
    const out = new Uint8Array(length);

    // Loop and convert.
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    return out;
  }
  /*
   * ------------------------------------------------------------------------------------------------
   * ref:https:// blobfolio.com/2019/10/better-binary-batter-mixing-base64-and-uint8array/
   */
  static base64toImageUrl (base64Str : any): any {
    return URL.createObjectURL(this.base64toBlob(base64Str, 'image/png'));
  }
  // ------------------------------------------------------------------------------------------------
  //static Base64Encode(str, encoding = 'utf-8'): any {
  //  const bytes = new (typeof TextEncoder === 'undefined' ? textEncoder.TextEncoderLite : TextEncoder)(encoding).encode(str);

  //  return base64js.fromByteArray(bytes);
  //}

  //static Base64Decode(str, encoding = 'utf-8'): any {
  //  const bytes = base64js.toByteArray(str);

  //  return new (typeof TextDecoder === 'undefined' ? textEncoder : TextDecoder)(encoding).decode(bytes);
  //}
  
 
  // ------------------------------------------------------------------
  // NOTE: The following are Array-related-functions
  // ------------------------------------------------------------------

  // ---------------------------------------------------------------
  static arrayToCsvString(inArr: any[]): any {
    if (inArr.length > 0) {
      let csvStr = '';

      for (let i = 0; i < inArr.length - 1; i++) {
        csvStr += csvStr + inArr[i].toString() + ', ';
      }
      csvStr += csvStr + inArr[inArr.length - 1].toString();
      return csvStr;
    }
    return '';
  }

  // ---------------------------------------------------------------
  static getArrayFromCommaSeparatedData (data : any): any {
    let tArray = [];

    if (data) {
      tArray = data.split(',');
      if (tArray.length > 0) {
        // truncate the last empty index due to the last ',' in the data-string
        tArray.splice(tArray.length - 1, 1);
      }
    }
    return tArray;
  }
  
   // ------------------------------------------------------------------
   // Tested, works!
  //  On 20220328
   // ------------------------------------------------------------------
  static createArrayFromMap(inmap: Map<any, any>): any[] {
    const arr: any[] = [];

    if (inmap && inmap.size > 0) {
      inmap.forEach((value, key) =>
      {
        arr.push(value);
      });
    }
    return arr;
   }
  // ------------------------------------------------------------------
  //  Tested, works!
  // ------------------------------------------------------------------
  static createMapFromArray(arr: any[]): Map<number, any> {
    const map = new Map<number, any>();

    if (arr && arr.length > 0) {
      arr.forEach(function callback (key, value) {
        map.set(value, key);
      });
    }
    return map;
  }
  // ------------------------------------------------------------------
  b2Str(b: []): any {
    return String.fromCharCode(...new Uint8Array(b));
  }
  /*
   * -------------------------------------------------------------
   * FileReader with a Promise to read text
   * ref:https:// blog.shovonhasan.com/using-promises-with-filereader/
   */
  readUploadedFileAsText = (inputFile : any) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  }
  /*
   * -------------------------------------------------------------
   * A Simplified API with Async/Await
   * ref:https:// blog.shovonhasan.com/using-promises-with-filereader/
   * Note:an example onchange handler we could pass to a <input type='file /> element
   */
  handleUpload = async (event : any) => {
    const file = event.target.files[0];

    try {
      const fileContents = await this.readUploadedFileAsText(file);

      console.log(fileContents);
    } catch {
      console.warn(event.message);
    }
  }
  // --------------------------------------------------------------
}
