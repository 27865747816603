'use-strict'
import { animate, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Animations, slideInOutAnimation } from '../../../../app/animation';
import { LoginSuccess } from '../../../../models/account/loginSuccess.model';
import { Bool } from '../../../../models/common/bool.model';
import { KeyValueResult } from '../../../../models/keyValue/keyValueResult.model';
import { KvAny } from '../../../../models/keyValue/kvAny.model';
import { ProfileImage } from '../../../../models/profile/profileImage.model';
import { CoreServiceService } from '../../../../services/coreServiceService/coreServiceService.service';
import { KvArrayService } from '../../../../services/coreServiceService/kvArrayService.service';
import { EmitterSubjectService } from '../../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';

@Component({
  selector: 'app-profile-pics-key-value-arr',
  templateUrl: './profilePicsKeyValueArr.component.html',
  // styleUrls : ['./profilePicsKeyValueArr.component.scss')],
  styleUrls: ['./profilePicsKeyValueArr.component.scss'],
  animations: [
    Animations,
    trigger('slideInOut', [
      transition(':enter', [style({ transform: 'scale(0.1)'}), animate('500ms ease-in', style({ transform: 'scale(1)'}))]),
      transition(':leave', [animate('500ms ease-in', style({ transform: 'scale(0.1)'}))]),
    ]),
  ],
})
export class ProfilePicsKeyValueArrComponent implements AfterViewInit, OnInit, OnDestroy {
  public kvArrayService!: KvArrayService;
  // @Input() arrKvAny : KvAny[] = [];
  @Input() profileImages: ProfileImage[] = [];
  @Input() kvAnyLegend = '';
  @Input() kvAnyName = 'ProfilePics';
  @Input() isFormSubmitted = false;
  @Input() isRequired = false;
  public kvAnyResult: KeyValueResult = new KeyValueResult();
  private emitterDestroyed$: Subject<boolean> = new Subject();
  public isMobilevar = false;
  public isClosedBool: Bool = new Bool();
  public loginSuccess!: LoginSuccess;
  public selectedKvAny!: FormControl;
  public selectedKvAnyIndex = -1;
  // ---------------------------------------------------------------
  constructor(
    // private cdr : ChangeDetectorRef,
    private coreServiceService: CoreServiceService,
    private router: Router,
    @Inject(DOCUMENT) document : any
  ) {
    if (coreServiceService) {
      this.kvArrayService = coreServiceService.getKvArrayService();
    }

    this.kvAnyResult.kvName = this.kvAnyName;
    // debugger;
  }
  ngOnInit(): void {
    this.loginSuccess = EmitterSubjectService.getLoginSuccess();
    this.isMobilevar = EmitterSubjectService.getIsMobile();
  }
  // ---------------------------------------------------------------------------------
  ngAfterViewInit(): void {
    // this.cdr.detectChanges();
  }
  // ---------------------------------------------------------------
  ngOnDestroy(): void {
    // prevent memory leak when component destroyed
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
  }
  // ---------------------------------------------------------------
  isArray(model: any): boolean {
    return this.kvArrayService.isModelArray(model);
  }
  // ---------------------------------------------------------------
  isImageValue(model: KvAny): boolean {
    return this.kvArrayService.isImage(model);
  }
  /*
   * ---------------------------------------------------------------
   * ref:https:// www.positronx.io/angular-checkbox-tutorial/
   */
  onKeyValueChange (e : any): void {
    if (e.target.checked) {
      /*
       * this.selectedRadiobox = this.radioboxForm.get('radioSelection') as FormControl;
       * this.selectedRadiobox.patchValue('');
       * this.selectedRadiobox.patchValue(e.target.value);
       */

      this.kvAnyResult.kvName = this.kvAnyName;
      this.kvAnyResult.kvSelection = e.target.value;
      this.kvAnyResult.isRequiredPassed = this.isRequired === true ? this.isRequiredPassed() : false;
      EmitterSubjectService.emitKeyValueResult(this.kvAnyResult);
    }
  }
  // ---------------------------------------------------------------
  isRequiredPassed(): any {
    return this.kvAnyResult.kvSelection.length > 0;
  }
  // ---------------------------------------------------------------
}
