'use-strict'
import
  {
    Component, Input, OnInit,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { SpinnerModel } from '../../../models/common/spinnerModel.model';
import { EmitterSubjectService } from '../../../services/staticServices/emitterObserverStaticServices/emitterSubject.service';
import { Animations, slideInOutAnimation } from '../../animation';
import { SpinnerOverlayService } from '../../../services/modalServiceService/spinnerOverlayService';

// ref: https://christianlydemann.com/four-ways-to-create-loading-spinners-in-an-angular-app/
// ref:https://danielk.tech/home/angular-how-to-add-a-loading-spinner
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: [ './spinner.component.scss' ],
  animations: [ Animations, slideInOutAnimation, ],
  changeDetection: ChangeDetectionStrategy.OnPush, //  default //

  // attach the slide in/out animation to the host (root) element of this component
  host: { '[@slideInOutAnimation]': '' }
})
export class SpinnerComponent implements OnInit, AfterViewInit
{
  private emitterDestroyed$ : Subject<boolean> = new Subject();
  @Input() spinnerModel : SpinnerModel = new SpinnerModel();
  public message = '';

  constructor (
    public cdr : ChangeDetectorRef,
  )
  {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.spinnerModel) ) {
      this.message = this.spinnerModel.message;
		}
  }

  ngOnInit () : void
  {   
    // debugger;
     if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.cdr)) {
      this.cdr.detectChanges();
     }
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.spinnerModel)) {
      this.message = this.spinnerModel.message;
    }
    
    // ------------------------------------------------------
  }
  ngAfterViewInit () {   
  }
  //  ---------------------------------------------------------------
  ngOnDestroy () : void {
    /*
     *  prevent memory leak when component /how-can-i-select-an-element-in-a-component-template
     *         (2) https://www.concretepage.com/angular-2/angular-4-renderer2-example
     *         (3) https://stackoverflow.com/questions/7439519/setinterval-to-loop-through-array-in-javascript/7440323
     *         (4) https://stackblitz.com/edit/timer-with-pdestroyed
     */
    this.emitterDestroyed$.next(true);
    this.emitterDestroyed$.complete();
    // debugger;
  }
  //  ---------------------------------------------------------------
  formatAndStyleMessage (message:string) : string {
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(message)) {
      this.message = message;
      return this.message;
		}
  }
  //  ---------------------------------------------------------------
  stopSpinner () : void {
    this.spinnerModel = new SpinnerModel();
    EmitterSubjectService.emitHideSpinner(this.spinnerModel);
  }
  //  ---------------------------------------------------------------
}
