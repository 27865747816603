import { FrequentlyUsedFunctionsServiceStatic } from "../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";
import { SitUser } from "../account/sitUser.model";
import { Content } from "../common/content.model";
import { KvAny } from "../keyValue/kvAny.model";
import { Preference } from "../profile/preference.model";
import { ProfileInfo } from "../profile/profileInfo.model";
import { ProfileTile } from "../profile/profileTile.model";

export class MemberViewMgmtModel {
  public id = 0;
  public date!: Date;
  public kvAnyArr : KvAny[] = [];
  public kvLegend : string[] = [ 'Profile description', 'About me', 'My preferences', 'What I am looking for',
    'What I am into', 'Upload Photos' ];
  public message = '';
  public preference : Preference = new Preference();
  public preferenceKvAnyArr : KvAny[] = [];
  public profileContent : Content = new Content();
  public profileContentKvAnyArr : KvAny[] = [];
  public profileContentName = 'Something more about me';
  public profileInfo : ProfileInfo = new ProfileInfo();
  public profileInfoView : ProfileInfo = new ProfileInfo();
  public profileTile : ProfileTile = new ProfileTile();
  public profileName = '';
  public primaryImage = '';
 
  public sitUser : SitUser = new SitUser();
  public sitUserId = 0;
  public signedInUserId = 0;
  public signedInUserKey = '';
  constructor () { }

  populateModelData (
    profileContent? : Content | any,
    preference?: Preference | any,
    profileInfo?: ProfileInfo | any,
    profileInfoView?: ProfileInfo | any,
    profileTile?: ProfileTile | any,
    inSitUser?: SitUser | any,    
    preferenceKvAnyArr?: KvAny[] | any,
    profileContentKvAnyArr?: KvAny[] | any,
    kvAnyArr?: KvAny[] | any  ) {

    if (!this.isNullOrEmpty(profileContent) && profileContent.sitUserId > 0) {
      this.profileContent = profileContent as Content;
      this.profileName = profileContent.heading; // highest priority of profileName
      this.sitUserId = profileContent?.sitUserId > 0 ? profileContent.sitUserId : this.sitUserId;
    }

    if (!this.isNullOrEmpty(profileInfo) && profileInfo.sitUserId > 0) {
      this.profileInfo = profileInfo as ProfileInfo;
      this.profileInfo.profileName = !this.isNullOrEmpty(this.profileName) ? this.profileName : this.profileInfo.profileName; // profileContent.heading gets the highest priority
      this.profileName = this.isNullOrEmpty(this.profileName) ? profileInfo.profileName : this.profileName; // profileContent.heading gets the highest priority
      this.sitUserId = profileInfo && profileInfo.sitUserId > 0 ? profileInfo.sitUserId : this.sitUserId;

    }

    if (!this.isNullOrEmpty(profileInfoView) && profileInfoView.sitUserId > 0) {
      this.profileInfoView = profileInfoView as ProfileInfo;
      this.profileInfoView.profileName = !this.isNullOrEmpty(this.profileName) ? this.profileName : this.profileInfoView.profileName; // profileContent.heading gets the highest priority
      this.profileName = this.isNullOrEmpty(this.profileName) ? profileInfoView.profileName : this.profileName; // profileContent.heading gets the highest priority
      this.sitUserId = profileInfoView && profileInfoView.sitUserId > 0 ? profileInfoView.sitUserId : this.sitUserId;
    }

    if (!this.isNullOrEmpty(profileTile) && profileTile.sitUserId > 0) {
      this.profileTile = profileTile as ProfileTile;
      this.profileTile.profileName = !this.isNullOrEmpty(this.profileName) ? this.profileName : this.profileTile.profileName; // profileContent.heading gets the highest priority
      this.profileName = this.isNullOrEmpty(this.profileName) ? profileTile.profileName : this.profileName; // profileContent.heading gets the highest priority
      this.sitUserId = profileTile && profileTile.sitUserId > 0 ? profileTile.sitUserId : this.sitUserId;
    }
    
    if (!this.isNullOrEmpty(profileContentKvAnyArr) && profileContentKvAnyArr.length > 0) {
      this.profileContentKvAnyArr = profileContentKvAnyArr as KvAny[];
    }

    // let anyModel = EmitterSubjectService.getAnyModel();
    // if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(anyModel) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(anyModel.kvAnyArr) && anyModel.kvAnyArr.length > 0) {
    //  // debugger;
    //  this.kvAnyArr = anyModel.kvAnyArr;
    // }

    if (!this.isNullOrEmpty(kvAnyArr) && kvAnyArr.length > 0) {
      this.kvAnyArr = kvAnyArr;
    }

    if (!this.isNullOrEmpty(preference) && preference.sitUserId > 0) {
      this.preference = preference as Preference;
    }

    if (!this.isNullOrEmpty(preferenceKvAnyArr) && preferenceKvAnyArr.length > 0) {
      this.preferenceKvAnyArr = preferenceKvAnyArr as KvAny[];
    }

    if (!this.isNullOrEmpty(inSitUser) && inSitUser.sitUserId > 0) {
      this.sitUser = inSitUser as SitUser;
      this.sitUser.profileName = !this.isNullOrEmpty(this.profileName) ? this.profileName : this.sitUser.profileName; // profileContent.heading gets the highest priority
      this.profileName = this.isNullOrEmpty(this.profileName) ? inSitUser.profileName : this.profileName; // profileContent.heading gets the highest priority
      this.sitUserId = inSitUser.sitUserId > 0 ? inSitUser.sitUserId : this.sitUserId;
    }

    
    // debugger;
    FrequentlyUsedFunctionsServiceStatic.emitMemberViewMgmtModel(this);
  }
  // --------------------------------------------------------------
  public isNullOrEmpty (input : any) : boolean {
    if (input === null || input === '' || input === undefined || (input !== null && (input.toString().toLowerCase() === 'null' || input.toString().toLowerCase() === 'undefined'))) {
      return true;
    }
    return false;
  }  
  // ---------------------------------------------------------------
}
