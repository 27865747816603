import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';

export class PayPalSellerProtection
{
  public dispute_Categories = ''; // this will contain a csv data that to be split by (',') to get a string[]
  public disputeCategoriesStr = '';
  public status = '';
  public sitUserKey = '';
  // ----------------------------------------------------------------------------------
  constructor ()
  {
  }
  // ----------------------------------------------------------------------------------
  public copy ( from: PayPalSellerProtection ): void
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( from ) )
    {
      this.dispute_Categories = from.dispute_Categories;
      this.disputeCategoriesStr = from.disputeCategoriesStr;
      this.status = from.status;
      this.sitUserKey = from.sitUserKey;
    }
  }
  // ----------------------------------------------------------------------------------
  public getDisputeCategoriesStr (): string
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(this.dispute_Categories))
    {
      this.disputeCategoriesStr = this.dispute_Categories; //end/destination should split the csv data by (',') to get the string[]
    }
    return this.disputeCategoriesStr;
  }
  // ----------------------------------------------------------------------------------
  public toStringCsv ( ): string
  {
    this.disputeCategoriesStr = this.getDisputeCategoriesStr();
    this.disputeCategoriesStr += ',' + this.status + ',' + this.sitUserKey;
    return this.disputeCategoriesStr;
  }
  // ----------------------------------------------------------------------------------
  public toString () : string
  {
    this.disputeCategoriesStr = this.getDisputeCategoriesStr();
    let outString = '' +
      '{\n status: ' +
      this.status +

      ',\n dispute_Categories: [' +
      this.disputeCategoriesStr + ']' +

    '\n sitUserKey: ' +
    this.sitUserKey +
      '\n}';
    return outString;
  }
  // ----------------------------------------------------------------------------------
}
