'use-strict'
import { DOCUMENT } from '@angular/common';
import { Directive, Inject, Input, OnInit, Renderer2 } from '@angular/core';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { RendererService } from '../../../services/rendererServiceService/rendererService.service';
import { RendererServiceService } from '../../../services/rendererServiceService/rendererServiceService.service';

/*
* // Author: Sayeed Rahman
* // Date: 2021/03/15, 8:17pm, Ottawa, ON, Canada
* // CopyRight (C).
*/

@Directive( {
  selector: '[appCenterX]',
  exportAs: 'centerX',
} )
export class CenterXDirective implements OnInit
{
  public childElemId = '';
  // ----------------------------------------------------------------------------------------------------------------------
  // Note: this myId input array shpuld contain at most two values:
  //  1. the target element's id
  //  2. an optional isScreen==parentElement?  boolean value
  //  2.a isScreent == true, then the parent element of the target element is the width of the device
  //  2.b isScreen == false, then the parent element of the target element is not the window element.
  //  Note: there can be only one value provided to this directive, in which case it assumes that the iScreen == false.
  // ----------------------------------------------------------------------------------------------------------------------
  @Input() myId : any[] = [];

  public renderer! : Renderer2;
  constructor (
    private rendererServiceService : RendererServiceService,
    public rendererService : RendererService,
    @Inject( DOCUMENT ) document: any,
    @Inject( Window ) window: any )
  {
    if ( this.rendererServiceService )
    {
      this.rendererService = this.rendererServiceService.getRendererService() as RendererService;

    }
    if ( this.rendererService )
    {
      this.renderer = this.rendererService.getRenderer();
    }
  }

  ngOnInit ()
  {
    debugger;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.myId ) && this.myId.length > 0)
    {
      this.centerSelf( this.myId[0] );
    }
  }
  public centerSelf ( myId : any[] ) : any
  {
    let childElemWidth : any;
    let parentElem : any;
    let parentElemWidth : any;
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( myId )  && myId.length > 0)
    {
      let childElem = document.getElementById( myId[0] ) as HTMLElement;

      if ( this.myId.length > 1 )
      {
        if ( this.myId[ 1 ] === true )
        {
          parentElemWidth = window.innerWidth;
        }
        else if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( childElem ) )
        {
          parentElem = childElem.parentElement;
          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( parentElem ) )
          {
            parentElemWidth = parentElem.style.width;
          }
        }
      }
      else
      {
        this.childElemId = this.myId[ 0 ];

        if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( this.childElemId ) )
        {
          childElem = document.getElementById( this.childElemId ) as HTMLElement;

          if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( childElem ) )
          {
            parentElem = childElem.parentElement;
            if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( parentElem ) )
            {
              parentElemWidth = parentElem?.style.width; // or parentElem.clientWidth;
            }
          }
        }
      }
      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( childElem ) )
      {
        childElemWidth = childElem?.style.width;
      }

      if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( parentElemWidth ) && parentElemWidth > 0
        && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( childElemWidth ) && childElemWidth > 0 )
      {
        const diff = parentElemWidth / 2 - childElemWidth / 2;

        this.renderer?.setStyle( childElem, 'margin-left', diff );
        this.renderer?.setStyle( childElem, 'margin-right', diff );
      }
      childElem.remove();
    }
  }
}
