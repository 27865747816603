<div *ngIf="isEyeOpen" id="uploadedFilesKeyValueArrComponentId" [@rotateInSlideOutVanish]>  
  <ul class="flex-item-main text-center shadowSilverInner"
      style="list-style: none;
      width: 90%; height: auto;
      margin-top: 0.5rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      margin-left: 1rem;
      padding: 0px;
      opacity: 1;
      border: 1.75px solid gold;
      border-radius: 5px;
      color:black;
      background-color: blanchedalmond/*midnightblue*/; overflow: visible;">
    <!--ref:https:// www.syncfusion.com/kb/10416/how-to-get-started-easily-with-syncfusion-angular-7-accordion -->

    <li expandMenu style="margin:0;padding:0;opacity:1;">
      <a (click)="this.isShowAccordionUi.isOpened = !this.isShowAccordionUi.isOpened">
        <!--Note: the classes: {neonBlueText, kvBttnUpload} their order is important!-->
        <p style="margin: 0.01rem; padding: 0.1rem 2rem 0.05rem 2rem; opacity: 1; justify-content: space-between; color: black; font-size: 1.25rem;font-weight:bolder;">
          <span *ngIf="kvLegend && kvLegend[5] && kvLegend[5].length > 0" style="float: left;opacity: 1;padding-top:0.15rem;">
            {{kvLegend[5]}}
          </span>
          <span *ngIf="!isShowAccordionUi.isOpened" class="glyphicon glyphicon-plus-sign hvr-icon" style="float:right;font-size:1rem;padding-top:0.2rem;"></span>
          <span *ngIf="isShowAccordionUi.isOpened" class="glyphicon glyphicon-minus-sign hvr-icon" style="float: right; font-size:1rem; padding-top: 0.2rem;"></span>
        </p>
      </a>
      <br />
    </li>
    <li><hr class="hrGray" style="display:inline-block;width:100%;padding:0;margin:0rem;" /><br /><br /></li>
    <li *ngIf="isShowAccordionUi.isOpened"
        style="margin:0;padding:0;" [@growShrink]>
      <ul [ngClass]="{'submenu':true}" align="left" style="width: 95%; height:auto;min-height:2rem; margin-left: -1.5rem; list-style: none; opacity: 1">
        <li style="margin:0;padding:0;">
          <app-image-upload></app-image-upload>
          <!--<app-upload-files></app-upload-files>-->
          <!--<app-file-upload [isToggleFileUpload]="isToggleFileUpload"></app-file-upload>-->
          <br />
        </li>
      </ul>
    </li>
  </ul>
</div>
