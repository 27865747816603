import { FrequentlyUsedFunctionsServiceStatic } from '../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { Chat } from '../chat/chat.model';
import { ChatMessage } from '../chat/chatMessage.model';
import { KvMemberActivityGlyph } from '../keyValue/kvMemberActivityGlyph.model';
import { MemberActivity } from '../member/memberActivity.model';
import { ProfileTile } from '../profile/profileTile.model';
import { CommunicationMessage } from './communicationMessage.model';
import { Communicator } from './communicator.model';

// Note: CommunicationActivity model is used for the following acitvities/purposes:
//        1. ChatAcivity in which case we convert it to Chat via copy2Chat() if necessary
//        2. NotificationActivity in which case we use notificationActivityId
//        3. LockingActivity in whihc case we use lockingActivityId
//        4. CommunicationActivityId in all other applicable cases

export class CommunicationActivity {
  public action = '';
  public actionLabel = '';
  public boxName = '';
  public baseFileName = '';
  public communicationActivityId = 0;
  public communicationMessageId = 0;
  public chatMsg : ChatMessage = new ChatMessage();
  public communicationMessage : CommunicationMessage = new CommunicationMessage();
  public chatActivityId = 0;
  public chatMessageId = 0;
  public communicationType = ''; // Email/Notification/Chat/etc.
  public communicationSubType = ''; // Buddy, Friend, Like, Unlock, Star, Track, Wink, etc...
  public commTypeEnum = 0;
  public date = '';
  public dateFormatted = '';
  public dateStr = '';
  public elemId = '';
  public expiryDate = '';
  public fromUser = '';
  public groupName = '';
  public heading = '';
  public id = '';
  public isBlocked = false;
  public isBuddied = false;
  public isFriend = false;
  public isLiked = false;
  public isStarred = false;
  public isTracked = false;
  public isUnlocked = false;
  public isWinked = false;
  public isAdministrative = false;
  public isNew = false
  public isOnline = false;
  public isReceiver = false;
  public isSender = false;
  public isPremiumMember = false;
  public image: any[] = [];
  public index? = -0;
  public isPic = false;
  public latitude? = 0;
  public link = '';
  public longitude ?= 0;
  public lockingActivityId = 0;
  public memberActivity : any; // === communicationSubType
  public memberActivityTs: MemberActivity = new MemberActivity();
  public membership = '';
  public messageBody = '';
  public messageBodyShort = '';
  public newMessageTotal ?= 0;
  public notificationActivityId = 0;
  public receiver: Communicator = new Communicator();
  public receiverAction = '';
  public receiverCryptUserId? = 0;
  public receiverModelString = '';
  public receiverProfileName = '';
  public receiverPic = '';
  public receiverPrimaryPhotoBnId = 0;
  public receiverSITUserId? = 0;
  public receiverSitUserId? = 0;
  public receiverSITUserKey = '';
  public receiverSitUserKey = '';
  public receiverSitUserKeyBackup = '';
  public pElemId = '';
  public photoBnId ?= 0;
  public profileTile : ProfileTile = new ProfileTile();
  public replyToId? = 0;
  public sender: Communicator = new Communicator();
  public senderAction = '';
  public senderCryptUserId? = 0;
  public senderModelString = '';
  public senderProfileName = '';
  public senderPic = '';
  public senderPrimaryPhotoBnId? = 0;
  public senderSITUserId? = 0;
  public senderSitUserId? = 0;
  public senderSITUserKey = '';
  public senderSitUserKey = '';
  public senderSitUserKeyBackup = '';
  public sentPic = '';
  public signedInUserId? = 0;
  public signedInUserKey = '';
  public sitUserId? = 0;
  public subject = '';
  public subjectShort = '';
  public trackingActivityId = 0;
  public timeLapsed = '';
  public toUser = '';
  public unit = 'm';
  public kvGlyph : KvMemberActivityGlyph = new KvMemberActivityGlyph();

  constructor () {
    // this.receiverSITUserId == this.receiverSitUserId;
    // this.receiverSitUserKey == this.receiverSITUserKey;
    // this.senderSITUserId == this.senderSitUserId;
    // this.senderSITUserKey == this.senderSitUserKey;
  }

  

  public copy2Chat () {
    let chat = new Chat();
    chat.id = parseInt(this.id, 10);
    chat.baseFileName = this.baseFileName;
    chat.communicationActivityId = this.communicationActivityId;
    chat.communicationMessageId = this.communicationMessageId;
    chat.chatMessageId = this.chatMessageId;
    chat.chatActivityId = this.chatActivityId;
    chat.communicationType = this.communicationType;
    chat.commTypeEnum = this.commTypeEnum;
    chat.dateFormatted = this.dateFormatted;
    chat.date = this.date;
    chat.fromUser = this.fromUser;
    chat.groupName = this.groupName;
    chat.latitude = this.latitude;
    chat.longitude = this.longitude;
    chat.isPic = this.isPic;
    // Chat.isNew = this.isNew;
    chat.messageBody = this.messageBody;
    chat.messageBodyShort = this.messageBodyShort;

    chat.receiver = this.receiver;
    chat.receiverAction = this.receiverAction;
    chat.receiverModelString = this.receiverModelString;
    chat.receiverProfileName = this.receiverProfileName;
    chat.receiverPic = this.receiverPic;
    chat.receiverSITUserId = this.receiverSITUserId;
    chat.receiverSitUserId = this.receiverSitUserId;
    chat.receiverSITUserKey = this.receiverSITUserKey;
    chat.receiverSitUserKey = this.receiverSitUserKey;

    chat.sender = this.sender;
    chat.senderAction = this.senderAction;
    chat.senderModelString = this.senderModelString;
    chat.senderProfileName = this.senderProfileName;
    chat.senderSITUserId = this.senderSITUserId;
    chat.senderSitUserId = this.senderSitUserId;
    chat.senderSITUserKey = this.senderSITUserKey;
    chat.senderSitUserKey = this.senderSitUserKey;
    chat.senderPic = this.senderPic;

    chat.sentPic = this.sentPic;

    chat.signedInUserId = this.signedInUserId;
    chat.signedInUserKey = this.signedInUserKey;

    chat.subject = this.subject;
    chat.subjectShort = this.subjectShort;
    chat.timeLapsed = this.timeLapsed;
    chat.toUser = this.toUser;

    // to harmonize these values:
    chat.receiverSITUserId == chat.receiverSitUserId;
    chat.receiverSitUserKey == chat.receiverSITUserKey;
    chat.senderSITUserId == chat.senderSitUserId;
    chat.senderSITUserKey == chat.senderSitUserKey;

  }
  public copy2MemberActivity (mAct : CommunicationActivity) : MemberActivity {
    let cAct = new MemberActivity();
    cAct.action = mAct.action;
    cAct.actionLabel = mAct.actionLabel;
    cAct.date = mAct.date;
    cAct.dateStr = mAct.dateStr;
    cAct.doeeSitUserKey = mAct.receiverSitUserKey;
    cAct.doeeSitUserId = mAct.receiverSitUserId;
    cAct.doerSitUserId = mAct.senderSitUserId;
    cAct.doerSitUserKey = mAct.senderSitUserKey;

    cAct.expiryDate = mAct.expiryDate;
    cAct.id = parseInt(mAct.id);
    cAct.isBlocked = mAct.isBlocked;
    cAct.isBuddied = mAct.isBuddied;
    cAct.isFriend = mAct.isFriend;
    cAct.isLiked = mAct.isLiked;
    cAct.isStarred = mAct.isStarred;
    cAct.isUnlocked = mAct.isUnlocked;
    cAct.isWinked = mAct.isWinked;

    cAct.index = mAct.index;
    cAct.photoBnId = mAct.photoBnId;
    cAct.profileTile = mAct.profileTile;
    return cAct;
  } 
  // ------------------------------------------------------------
  static parseCommunicationActivity (inCommAct : string) : CommunicationActivity {
    var commAct = new CommunicationActivity();
    var patternO = "{";
    var patternC = "}";
    var patternQ = "\"";
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(inCommAct)) {
      commAct = JSON.parse(inCommAct);
      if (FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct) || FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(commAct.communicationActivityId)) {

        var parts = inCommAct.split(',');
        if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(parts) && parts.length > 0) {
          parts.map(e => {
            var kvParts = e.split(':');
            if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(kvParts) && kvParts.length > 0) {
              switch (kvParts[ 0 ]) {
                case 'boxName':
                  commAct.boxName = kvParts[ 1 ];
                  break;
                case 'baseFileName':
                  commAct.baseFileName = kvParts[ 1 ];
                  break;
                case 'communicationActivityId':
                  commAct.communicationActivityId = parseInt(kvParts[1], 10);
                  break;
                case 'communicationMessageId':
                  commAct.communicationMessageId = parseInt(kvParts[ 1 ], 10);
                  break;
                //case 'chatMsg ':
                //  commAct.chatMsg = new ChatMessage().toJson(kvParts[ 1 ]);
                //  break;
                //case 'communicationMsg':
                //  commAct.communicationMsg = new CommunicationMessage().toJson(kvParts[1]);
                //  break;
                case 'chatActivityId':
                  commAct.chatActivityId = parseInt(kvParts[ 1 ], 10);
                  break;
                case 'chatMessageId':
                  commAct.chatMessageId = parseInt(kvParts[ 1 ], 10);
                  break;
                case 'communicationType':
                  commAct.communicationType = kvParts[ 1 ];
                  break;
                case 'communicationSubType':
                  commAct.communicationSubType = kvParts[ 1 ];
                  break;
                case 'commTypeEnum':
                  commAct.commTypeEnum = parseInt(kvParts[ 1 ], 10);
                  break;
                case 'date':
                  commAct.date = kvParts[ 1 ];
                  break;
                case 'dateFormatted':
                  commAct.dateFormatted = kvParts[ 1 ];
                  break;
                case 'elemId':
                  commAct.elemId = kvParts[ 1 ];
                  break;
                case 'fromUser':
                  commAct.fromUser = kvParts[ 1 ];
                  break;
                case 'groupName':
                  commAct.groupName = kvParts[ 1 ];
                  break;
                case 'heading':
                  commAct.heading = kvParts[ 1 ];
                  break;
                case 'id':
                  commAct.id = kvParts[ 1 ];
                  break;
                case 'isAdministrative':
                  commAct.isAdministrative = kvParts[ 1 ] as boolean | any;
                  break;
                case 'isNew':
                  commAct.isNew = kvParts[ 1 ] as boolean | any;
                  break;
                case 'isOnline':
                  commAct.isOnline = kvParts[ 1 ] as boolean | any;
                  break;
                case 'isReceiver':
                  commAct.isReceiver = kvParts[ 1 ] as boolean | any;
                  break;
                case 'isSender':
                  commAct.isSender = kvParts[ 1 ] as boolean | any;
                  break;
                case 'isPremiumMember':
                  commAct.isPremiumMember = kvParts[ 1 ] as boolean | any;
                  break;
                case 'image':
                  commAct.image = kvParts[ 1 ] as any[] | any
                  break;
                case 'index':
                  commAct.index = parseInt(kvParts[ 1 ], 10);
                  break;
                case 'isPic':
                  commAct.isPic = kvParts[ 1 ] as boolean | any;
                  break;
                case 'latitude':
                  commAct.latitude = parseFloat(kvParts[ 1 ]);
                  break;
                case 'link':
                  commAct.link = kvParts[ 1 ];
                  break;
                case 'longitude':
                  commAct.longitude = parseFloat(kvParts[ 1 ]);
                  break;
                //case 'memberActivityTs':
                //  commAct.memberActivityTs = new MemberActivity().toJson(kvParts[ 1 ]);
                //  break;
                case 'membership':
                  commAct.membership = kvParts[ 1 ];
                  break;
                case 'messageBody':
                  commAct.messageBody = kvParts[ 1 ];
                  break;
                case 'messageBodyShort':
                  commAct.messageBodyShort = kvParts[ 1 ];
                  break;
                case 'newMessageTotal':
                  commAct.newMessageTotal = parseInt(kvParts[ 1 ]);
                  break;
                //case 'receiver':
                //  commAct.receiver = new Communicator().toJson(kvParts[ 1 ]);
                //  break;
                case 'receiverAction':
                  commAct.receiverAction = kvParts[ 1 ];
                  break;
                case 'receiverModelString':
                  commAct.receiverModelString = kvParts[ 1 ];
                  break;
                case 'receiverProfileName':
                  commAct.receiverProfileName = kvParts[ 1 ];
                  break;
                case 'receiverPic':
                  commAct.receiverPic = kvParts[ 1 ];
                  break;
                case 'receiverSITUserId':
                  commAct.receiverSITUserId = parseInt(kvParts[ 1 ]);
                  break;
                case 'receiverSitUserId':
                  commAct.receiverSitUserId = parseInt(kvParts[ 1 ]);
                  break;
                case 'receiverSITUserKey':
                  commAct.receiverSITUserKey = kvParts[ 1 ];
                  break;
                case 'receiverSitUserKey':
                  commAct.receiverSitUserKey = kvParts[ 1 ];
                  break;
                //case 'sender':
                //  commAct.sender = new Communicator().toJson(kvParts[ 1 ]);
                //  break;
                case 'senderAction':
                  commAct.senderAction = kvParts[ 1 ];
                  break;
                case 'senderModelString':
                  commAct.senderModelString = kvParts[ 1 ];
                  break;
                case 'senderProfileName':
                  commAct.senderProfileName = kvParts[ 1 ];
                  break;
                case 'senderSITUserId':
                  commAct.senderSITUserId = parseInt(kvParts[ 1 ]);
                  break;
                case 'senderSitUserKey':
                  commAct.senderSitUserKey = kvParts[ 1 ];
                  break;
                case 'senderPic':
                  commAct.senderPic = kvParts[ 1 ];
                  break;
                case 'sentPic':
                  commAct.sentPic = kvParts[ 1 ];
                  break;
                case 'signedInUserId':
                  commAct.signedInUserId = parseInt(kvParts[ 1 ]);
                  break;
                case 'signedInUserKey':
                  commAct.signedInUserKey = kvParts[ 1 ];
                case 'subject':
                  commAct.subject = kvParts[ 1 ];
                  break;
                case 'subjectShort':
                  commAct.subjectShort = kvParts[ 1 ];
                  break;
                case 'timeLapsed':
                  commAct.timeLapsed = kvParts[ 1 ];
                  break;
                case 'toUser':
                  commAct.toUser = kvParts[ 1 ];
                  break;
                case 'unit':
                  commAct.unit = kvParts[ 1 ];
                  break;
                //case 'kvGlyph':
                //  commAct.kvGlyph = new KvMemberActivityGlyph().toJson(kvParts[ 1 ]);
                //  break;
                default:
                  break;
              }
            }
          })
        }
      }
    }
    return commAct;
	}
}
