import { FrequentlyUsedFunctionsServiceStatic } from "../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service";
import { Chat } from "./chat.model";
import { ChatActivity } from "./chatActivity.model";

export class ChatMessage {
  public chatMessageId : number = 0;
  public date : string = '';
  public dateFormatted : string = '';
  public description : string = '';
  public isPic: boolean = false;
  public image : any;
  public messageBody: string = '';
  public replyToId: number = 0;
  public senderCryptUserKey : string = '';
  public senderSITUserKey : string = '';
  public subject : string = '';
  public isEncrypted: boolean = false;
  public sentPic: any;
  public oldMessageBody : string = '';
  constructor () { }

  copyFromChat (s : Chat) : ChatMessage {
    var cMsg : ChatMessage = new ChatMessage();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(s)) {      
      cMsg.chatMessageId = s.chatMessageId;
      cMsg.date = s.date;
      cMsg.dateFormatted = s.dateFormatted;
      cMsg.description = s.description;
      cMsg.isPic = s.isPic;
      cMsg.image = s.sentPic;
      cMsg.messageBody = s.messageBody;
      cMsg.replyToId = s.repliedToId;
      cMsg.senderCryptUserKey = s.senderCryptUserKey;
      cMsg.senderSITUserKey = s.senderSITUserKey;
      cMsg.subject = s.subject;
      cMsg.sentPic = s.sentPic;      
    }
    return cMsg;
  }
  copyFromChatActivity (s : ChatActivity) : ChatMessage {
    var cMsg : ChatMessage = new ChatMessage();
    if (!FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(s) && !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty(s.chatMessage)) {
      cMsg.chatMessageId = s.chatMessage.chatMessageId;
      cMsg.date = s.chatMessage.date;
      cMsg.dateFormatted = s.chatMessage.dateFormatted;
      cMsg.description = s.chatMessage.description;
      cMsg.isPic = s.chatMessage.isPic;
      cMsg.image = s.chatMessage.sentPic;
      cMsg.messageBody = s.chatMessage.messageBody;
      cMsg.replyToId = s.chatMessage.replyToId;
      cMsg.senderCryptUserKey = s.chatMessage.senderCryptUserKey;
      cMsg.senderSITUserKey = s.chatMessage.senderSITUserKey;
      cMsg.subject = s.chatMessage.subject;
      cMsg.sentPic = s.chatMessage.sentPic;
    }
    return cMsg;
  }
}
