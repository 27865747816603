'use-strict'
import { Pipe, PipeTransform } from '@angular/core';
import { FrequentlyUsedFunctionsServiceStatic } from '../../../services/staticServices/frequentlyUsedStaticService/frequentlyUsedFunctionsServiceStatic.service';
import { ProfileTile } from '../../../models/profile/profileTile.model';

@Pipe({
  name: 'profileTilesPipe',
})
export class ProfileTilesPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //  return null;
  // }
  transform ( pTileArr : ProfileTile[]): any
  {
    if ( !FrequentlyUsedFunctionsServiceStatic.isNullOrEmpty( pTileArr ) )
    {
      return pTileArr.filter( profileTile => profileTile );
    }
    else return null;
    //return pTileArr.map( value =>
    //{
    //  // alert( 'value.toString()=>' + value.toString());
    //  return value;
    //});
  }
}
